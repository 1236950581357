import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { calculateSessionInterestRateError, calculateSessionInterestRateRequest, calculateSessionInterestRateSuccess } from './actions';
import { CalculateSessionInterestRateTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import sessionRateApi from 'services/api/session-interest-rates-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { SessionInterestRateResponse } from 'model/session-interest-rates';

function* handleCalculateSessionInterestRate(action: ReturnType<typeof calculateSessionInterestRateRequest>) {
    try {
        const result: AxiosResponse<SessionInterestRateResponse> = yield call(sessionRateApi.calculateSessionInterestRates, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(calculateSessionInterestRateError(mapped));
            return;
        }
        yield put(calculateSessionInterestRateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(calculateSessionInterestRateError(mapped));
    }
}

function* watchCalculateSessionInterestRate() {
    yield takeLeading(CalculateSessionInterestRateTypes.CALCULATE_SESSION_INTEREST_RATE_REQUEST, handleCalculateSessionInterestRate);
}

function* calculateSessionInterestRateSaga() {
    yield all([fork(watchCalculateSessionInterestRate)]);
}

export default calculateSessionInterestRateSaga;
