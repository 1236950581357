import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOriginatorList } from 'model/originator';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListOriginatorsTypes {
    LIST_ORIGINATORS_REQUEST = '@@originators-list/LIST_ORIGINATORS_REQUEST',
    LIST_ORIGINATORS_SUCCESS = '@@originators-list/LIST_ORIGINATORS_SUCCESS',
    LIST_ORIGINATORS_ERROR = '@@originators-list/LIST_ORIGINATORS_ERROR',
    LIST_ORIGINATORS_RESET = '@@originators-list/LIST_ORIGINATORS_RESET',
}

export interface ListOriginatorsState extends BaseStoreState {
    readonly originators?: Page<ScdOriginatorList>;
}
