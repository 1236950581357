import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchOutputEndorsementState, BatchOutputEndorsementTypes } from 'reducer/batch/batch-output-endorsement/types';

const initialState: BatchOutputEndorsementState = {
    status: HttpRequestStatus.NOOP,
    outputEndorsement: undefined,
    error: undefined
}

const reducer: Reducer<BatchOutputEndorsementState> = (state = initialState, action): BatchOutputEndorsementState => {
    switch (action.type) {
        case BatchOutputEndorsementTypes.BATCH_OUTPUT_ENDORSEMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchOutputEndorsementTypes.BATCH_OUTPUT_ENDORSEMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, outputEndorsement: action.payload, error: undefined };
        }
        case BatchOutputEndorsementTypes.BATCH_OUTPUT_ENDORSEMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchOutputEndorsementTypes.BATCH_OUTPUT_ENDORSEMENT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchOutputEndorsementReducer }