import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchOriginatorsByPaymentInstitutionAccountState, SearchOriginatorsByPaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/types';

const initialState: SearchOriginatorsByPaymentInstitutionAccountState = {
    status: HttpRequestStatus.NOOP,
    originatorsByPaymentInstitutionAccount: undefined,
    error: undefined,
};

const reducer: Reducer<SearchOriginatorsByPaymentInstitutionAccountState> = (state = initialState, action): SearchOriginatorsByPaymentInstitutionAccountState => {
    switch (action.type) {
        case SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, originatorsByPaymentInstitutionAccount: action.payload, error: undefined };
        }
        case SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchOriginatorsByPaymentInstitutionAccountReducer };
