import ContentLoader from 'react-content-loader';

const PaymentTransactionInSeriesSkeletonSlide = () => {
    return (
        <>
            <ContentLoader
                width={800}
                height={50}
                viewBox="0 0 800 50"
                backgroundColor="#F0F0F0"
                foregroundColor="#ecebeb"
                style={{ marginTop: '20px' }}
            >
                <rect x="0" y="0" rx="4" ry="4" width="100" height="15" />
                <rect x="0" y="20" rx="4" ry="4" width="140" height="15" />
                <rect x="180" y="20" rx="4" ry="4" width="150" height="15" />
                <rect x="370" y="20" rx="4" ry="4" width="150" height="15" />
            </ContentLoader>
        </>
    );
};

export default PaymentTransactionInSeriesSkeletonSlide;
