import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

import { ListProgramOutputPoliciesState } from 'reducer/program-output-policies/list-program-output-policies/types';
import { listProgramOutputPoliciesReducer } from 'reducer/program-output-policies/list-program-output-policies/reducer';
import listProgramOutputPoliciesSaga from 'reducer/program-output-policies/list-program-output-policies/sagas';

export interface ProgramOutputPoliciesState {
    readonly listProgramOutputPolicies: ListProgramOutputPoliciesState
}

export const programOutputPolicyReducer: ReducersMapObject<ProgramOutputPoliciesState, AnyAction> = {
    listProgramOutputPolicies: listProgramOutputPoliciesReducer,
}

export function* programOutputPolicySagas() {
    yield all([
        fork(listProgramOutputPoliciesSaga),
    ])
}