import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { UsersToGetAll } from 'model/user';
import { markError } from 'reducer/application/error/actions';
import { listBankersError } from 'reducer/banker/list-bankers/actions';
import { listUsersRequest, listUsersError, listUsersSuccess } from 'reducer/user/list-users/actions';
import { ListUserTypes } from 'reducer/user/list-users/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import userApi from 'services/api/user-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListUsers(action: ReturnType<typeof listUsersRequest>) {
    try {
        const result: AxiosResponse<Page<UsersToGetAll>> = yield call(
            userApi.listUsers,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listBankersError(mapped));
            return;
        }
        yield put(listUsersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listUsersError(mapped));
    }
}

function* watchListUsers() {
    yield takeEvery(ListUserTypes.LIST_USER_REQUEST, handleListUsers);
}

function* listUsersSaga() {
    yield all([fork(watchListUsers)]);
}

export default listUsersSaga;