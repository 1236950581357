import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { externalTransfersPaymentInstitutionsAccountsRequest, externalTransfersPaymentInstitutionsAccountsSuccess, externalTransfersPaymentInstitutionsAccountsError } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/actions';
import { ExternalTransfersPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import externalStrategyApi from 'services/api/external-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';

function* handleExternalTransfersPaymentInstitutionsAccounts(action: ReturnType<typeof externalTransfersPaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<ExternalTransfersResponse> = yield call(
            externalStrategyApi.externalTransfersPaymentInstitutionAccount,
            action.payload
        );

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(externalTransfersPaymentInstitutionsAccountsError(mapped));
            yield put(markError(mapped))
            return;
        }
        yield put(externalTransfersPaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped))
        yield put(externalTransfersPaymentInstitutionsAccountsError(mapped));
    }
}

function* watchExternalTransfersPaymentInstitutionsAccounts() {
    yield takeEvery(ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleExternalTransfersPaymentInstitutionsAccounts);
}

function* externalTransfersPaymentInstitutionsAccountsSaga() {
    yield all([fork(watchExternalTransfersPaymentInstitutionsAccounts)]);
}

export default externalTransfersPaymentInstitutionsAccountsSaga;
