import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CalculateSessionInterestRateTypes, CalculateSessionInterestRateState } from './types';
import { Reducer } from 'redux';

export const initialState: CalculateSessionInterestRateState = {
    status: HttpRequestStatus.NOOP,
    sessionInterestRateResponse: undefined,
    error: undefined,
};

const reducer: Reducer<CalculateSessionInterestRateState> = (state = initialState, action): CalculateSessionInterestRateState => {
    switch (action.type) {
        case CalculateSessionInterestRateTypes.CALCULATE_SESSION_INTEREST_RATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CalculateSessionInterestRateTypes.CALCULATE_SESSION_INTEREST_RATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, sessionInterestRateResponse: action.payload, error: undefined };
        }
        case CalculateSessionInterestRateTypes.CALCULATE_SESSION_INTEREST_RATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CalculateSessionInterestRateTypes.CALCULATE_SESSION_INTEREST_RATE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as calculateSessionInterestRateReducer };
