import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import FormatterUtils from 'components/formatter/formatter-utils';
import ValidMonthInput from 'components/inputs/date-input/month-input/valid-month-input';
import ValidYearInput from 'components/inputs/date-input/year-input/valid-year-input';
import MonthlyProcessedOperationsReportCSV from 'entities/monthly-processed-operations-report/monthly-processed-operations-csv/monthly-processed-operations-csv';
import {
    validateMonthlyProcessedOperationReport,
    validateMonthlyProcessedOperationReportMonth,
    validateMonthlyProcessedOperationReportYear,
} from 'entities/monthly-processed-operations-report/validation/monthly-processed-operations-report.validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { YearMonthInterface, defaultYearMonth } from 'model/imported-operation';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useMonthlyProcessedOperationsReportState, useRootDispatch } from 'reducer/hooks';
import {
    monthlyProcessedOperationsReportRequest,
    monthlyProcessedOperationsReportResetState,
} from 'reducer/imported-operation/monthly-processed-operations-report/actions';
import { useToast } from 'shared/hooks/use-toast';

import './monthly-processed-operations-report.scss';

const I18N_PREFIX = 'pages.scd.monthly-processed-operations-report';

const MonthlyProcessedOperationsReport = () => {
    const { t } = useTranslation();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const dispatch = useRootDispatch();
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const [yearMonth, setYearMonth] = useState<YearMonthInterface>(defaultYearMonth);
    const [showValidation, setShowValidation] = useState<boolean>(false);

    const { status, monthlyProcessedOperationsReportData } = useMonthlyProcessedOperationsReportState();

    const handleYearMonthChange = (value: Partial<YearMonthInterface>) => {
        setYearMonth(prev => ({ ...prev, ...value }));
    };

    const handleGenerateReport = () => {
        setShowValidation(true);

        const _yearMonth: YearMonthInterface = { ...yearMonth };

        if (!validateMonthlyProcessedOperationReport(_yearMonth)) return;

        dispatch(monthlyProcessedOperationsReportRequest(FormatterUtils.formatYearMonthInterfaceToString(yearMonth)));
    };

    const handleResetInputs = () => {
        setYearMonth(defaultYearMonth);
    };

    useEffect(() => {
        if (status !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [status, toastLoading]);

    useEffect(() => {
        if (status !== HttpRequestStatus.ERROR) return;

        closeToast();
        toastError(t(`${I18N_PREFIX}.toast.error`));
        dispatch(monthlyProcessedOperationsReportResetState());
    }, [status, closeToast, toastError, t, dispatch]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        csvLinkRef.current?.link.click();
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        dispatch(monthlyProcessedOperationsReportResetState());
    }, [status, closeToast, toastSuccess, t, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(monthlyProcessedOperationsReportResetState());
        };
    }, [dispatch]);

    return (
        <>
            <main className="scd-monthly-processed-operations-report">
                <section className="scd-monthly-processed-operations-report__container">
                    <header className="scd-monthly-processed-operations-report--header">
                        <h2 className="scd-monthly-processed-operations-report--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                    </header>
                    <form className="scd-monthly-processed-operations-report--form">
                        <div className="scd-monthly-processed-operations-report--form-field">
                            <ValidMonthInput
                                label={t(`${I18N_PREFIX}.inputs.month.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.month.placeholder`)}
                                onChange={month => handleYearMonthChange({ month })}
                                value={yearMonth?.month ?? ''}
                                validate={validateMonthlyProcessedOperationReportMonth}
                                showValidation={showValidation}
                                externalUpdate
                            />
                            <ValidYearInput
                                label={t(`${I18N_PREFIX}.inputs.year.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.year.placeholder`)}
                                onChange={year => handleYearMonthChange({ year })}
                                value={yearMonth?.year ?? ''}
                                validate={validateMonthlyProcessedOperationReportYear}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-monthly-processed-operations-report--form-buttons">
                            <OutlinedButtonLegacy label={t(`${I18N_PREFIX}.buttons.clean`)} onClick={handleResetInputs} />
                            <StandardButtonLegacy label={t(`${I18N_PREFIX}.buttons.generate-csv`)} onClick={handleGenerateReport} />
                        </div>
                    </form>
                </section>
                {monthlyProcessedOperationsReportData && (
                    <MonthlyProcessedOperationsReportCSV
                        monthlyProcessedOperationsReportData={monthlyProcessedOperationsReportData}
                        yearMonth={FormatterUtils.formatYearMonthInterfaceToString(yearMonth)}
                        csvLinkRef={csvLinkRef}
                    />
                )}
            </main>
        </>
    );
};

export default MonthlyProcessedOperationsReport;
