import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';
import { userByResetKeyReducer } from 'reducer/user/reset-key/reducer';
import userByResetKeySaga from 'reducer/user/reset-key/sagas';
import { UserByResetKeyState } from 'reducer/user/reset-key/types';

// User create
import { UserCreateState } from 'reducer/user/create-user/types';
import { userCreateReducer } from 'reducer/user/create-user/reducer';
import userCreateSaga from 'reducer/user/create-user/sagas';

// User update
import { UserUpdateState } from 'reducer/user/update-user/types';
import { userUpdateReducer } from 'reducer/user/update-user/reducer';
import userUpdateSaga from 'reducer/user/update-user/sagas';

// List users
import listUsersSaga from 'reducer/user/list-users/sagas'; 
import { ListUsersState } from 'reducer/user/list-users/types'; 
import { listUsersReducer } from 'reducer/user/list-users/reducer'; 

// User detail
import { UserDetailState } from 'reducer/user/user-detail/types';
import { userDetailReducer } from 'reducer/user/user-detail/reducer';
import userDetailSaga from 'reducer/user/user-detail/sagas';

export interface UserState {
    readonly userByResetKey: UserByResetKeyState;
    readonly userCreate: UserCreateState;
    readonly userUpdate: UserUpdateState;
    readonly userDetail: UserDetailState;
    readonly listUsers: ListUsersState;
}

export const UserReducer: ReducersMapObject<UserState, AnyAction> = {
    userByResetKey: userByResetKeyReducer,
    userCreate: userCreateReducer,
    userUpdate: userUpdateReducer,
    userDetail: userDetailReducer,
    listUsers: listUsersReducer,
};

export function* userSagas() {
    yield all([
        fork(userByResetKeySaga),
        fork(userCreateSaga),
        fork(userUpdateSaga),
        fork(userDetailSaga),
        fork(listUsersSaga)
    ]);
}
