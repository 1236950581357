import InputUtils from 'components/inputs/input-utils';
import { SessionInterestRateCalculationResult } from 'model/enums/session-interest-rate-result';

export interface SessionInterestRateRequest {
    batchId?: number;
    sessionDate?: string;
    commissionAmount?: number;
    bankChargesAmount?: number;
    estimated?: {
        lowEstimatePercentage?: number;
        highEstimatePercentage?: number;
    }
}

export const defaultSessionInterestRateRequest: SessionInterestRateRequest = {
    sessionDate: InputUtils.formatToLocalDateISO(new Date()),
    commissionAmount: 0,
    bankChargesAmount: 0,
}
export interface SessionInterestRateResponse {
    result?: SessionInterestRateCalculationResult;
    sessionDate?: Date;
    commissionAmount?: number;
    bankChargesAmount?: number;
    exchangePremiumPercentage?: number;
    grossAmount?: number;
    disbursementAmount?: number
    iofAmount?: number
    exchangePremiumAmount?: number
    sessionAmount?: number
    baseEstimateInterestRatePercentage?: number
    lowEstimateInterestRatePercentage?: number
    highEstimateInterestRatePercentage?: number
    monthlyInterestRatePercentage?: number
    yearlyInterestRatePercentage?: number
    sessionPresentValue?: number

}