import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import {
    monthlyCommissionGenerateReportError,
    monthlyCommissionGenerateReportRequest,
    monthlyCommissionGenerateReportSuccess,
} from 'reducer/monthly-commission/generate-report/actions';
import { MonthlyCommissionGenerateReportTypes } from 'reducer/monthly-commission/generate-report/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleMonthlyCommissionGenerateReport(action: ReturnType<typeof monthlyCommissionGenerateReportRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionSimple> = yield call(monthlyCommissionAPI.generateReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionGenerateReportError(mapped));
            return;
        }
        yield put(monthlyCommissionGenerateReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionGenerateReportError(mapped));
    }
}

function* watchMonthlyCommissionGenerateReport() {
    yield takeLeading(
        MonthlyCommissionGenerateReportTypes.MONTHLY_COMMISSION_GENERATE_REPORT_REQUEST,
        handleMonthlyCommissionGenerateReport
    );
}

function* monthlyCommissionGenerateReportSaga() {
    yield all([fork(watchMonthlyCommissionGenerateReport)]);
}

export default monthlyCommissionGenerateReportSaga;
