import { DataChunkSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum DataChunkRetryBcoTypes {
    DATA_CHUNK_RETRY_BCO_REQUEST = '@@data-chunk-retry-bco/DATA_CHUNK_RETRY_BCO_REQUEST',
    DATA_CHUNK_RETRY_BCO_SUCCESS = '@@data-chunk-retry-bco/DATA_CHUNK_RETRY_BCO_SUCCESS',
    DATA_CHUNK_RETRY_BCO_ERROR = '@@data-chunk-retry-bco/DATA_CHUNK_RETRY_BCO_ERROR',
    DATA_CHUNK_RETRY_BCO_RESET = '@@data-chunk-retry-bco/DATA_CHUNK_RETRY_BCO_RESET',
}

export interface DataChunkRetryBcoState extends BaseStoreState {
    readonly dataChunkRetryBco?: DataChunkSimpleResponse;
}
