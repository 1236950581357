import { Grid } from '@material-ui/core';
import BatchCard from 'entities/batch/components/batch-card/batch-card';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchStatus } from 'model/enums/batch-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchOutputBco } from 'model/bco/batch-output';

interface BatchOutputsCardProps {
    batch: BatchDetailWithProgram;
    output: BatchOutputBco;
    status: HttpRequestStatus;
    handleRefresh: () => void;
}

const BatchOutputsCard = (props: BatchOutputsCardProps) => {
    const { batch, output, status, handleRefresh } = props;

    const isHiddenCard: boolean = batch.status === BatchStatus.CLOSED || batch.status === BatchStatus.CONCLUDED;
    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;
    const hasError: boolean = status === HttpRequestStatus.ERROR;

    return (
        <Grid item hidden={!isHiddenCard}>
            <BatchCard
                title={output.name ?? '-'}
                isLoading={isLoading}
                hasError={hasError}
                handleRefresh={handleRefresh}
                link={`/batches/${batch.id}/output/${output.id}/details`}
            />
        </Grid>
    );
};

export default BatchOutputsCard;
