import BankIconButton from 'components/buttons/icon-buttons/bank/bank-icon-button';
import _ from 'lodash';

interface BankIconRedirectProps {
    url: string | null | undefined;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.row';

const BankIconRedirect = (props: BankIconRedirectProps) => {
    const { url } = props;

    return (
        <>
            {!_.isNil(url) ? (
                <a href={url} target="_blank" rel="noreferrer">
                    <BankIconButton title={`${I18N_PREFIX}.tooltips.bank-receipt`} />
                </a>
            ) : (
                <BankIconButton title={`${I18N_PREFIX}.tooltips.bank-receipt`} disabled />
            )}
        </>
    );
};

export default BankIconRedirect;
