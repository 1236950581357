import { AxiosResponse } from 'axios';
import { SessionInterestRateRequest, SessionInterestRateResponse } from 'model/session-interest-rates';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN } from 'services/page';

export const sessionInterestRates = () => {
    
    const calculateSessionInterestRates = (sessionRates: SessionInterestRateRequest): Promise<AxiosResponse<SessionInterestRateResponse>> => {
        return api.post<SessionInterestRateResponse>(`${BASE_URL_ADMIN}/session-interest-rates/batches/${sessionRates.batchId}/calculate`, sessionRates, { timeout: 0 });
    };

    const recalculateSessionInterestRates = (sessionRates: SessionInterestRateRequest): Promise<AxiosResponse<SessionInterestRateResponse>> => {
        return api.patch<SessionInterestRateResponse>(`${BASE_URL_ADMIN}/session-interest-rates/batches/${sessionRates.batchId}/recalculate`, sessionRates, { timeout: 0 });
    };

    const detailSessionInterestRates = (batchId: number): Promise<AxiosResponse<SessionInterestRateResponse>> => {
        return api.get<SessionInterestRateResponse>(`${BASE_URL_ADMIN}/session-interest-rates/batches/${batchId}`);
    };

    return {
        calculateSessionInterestRates,
        recalculateSessionInterestRates,
        detailSessionInterestRates,
    };
};

export default sessionInterestRates();