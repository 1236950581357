import { ScdTaxList } from 'model/tax';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListTaxesTypes {
    LIST_TAXES_REQUEST = '@@taxes-list/LIST_TAXES_REQUEST',
    LIST_TAXES_SUCCESS = '@@taxes-list/LIST_TAXES_SUCCESS',
    LIST_TAXES_ERROR = '@@taxes-list/LIST_TAXES_ERROR',
    LIST_TAXES_RESET_STATE = '@@taxes-list/LIST_TAXES_RESET_STATE',
}

export interface ListTaxesState extends BaseStoreState {
    readonly taxes?: Page<ScdTaxList>;
}
