import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { ScdCompany } from 'model/company';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { getPersonType } from 'shared/util/person-type-utils';

export interface CompanyRowProps {
    company: ScdCompany[];
}

const CompanyRow = (props: ListChildComponentProps<CompanyRowProps>) => {
    const { url } = useRouteMatch();
    const { index, data, style } = props;
    const { company } = data;
    const { id, name, identification, type, tradeName } = company[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td title={name ?? '-'} className="page-container--table-cell-title">
                {<span>{name ?? '-'}</span>}
            </td>
            <td className="page-container--table-cell-title">
                {identification ? <IdentificationFormatter value={identification} type={type ?? getPersonType(identification)} /> : '-'}
            </td>
            <td title={tradeName && tradeName?.length > 0 ? tradeName : '-'} className="page-container--table-cell-title">
                {<span>{tradeName && tradeName?.length > 0 ? tradeName : '-'}</span>}
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default CompanyRow;
