import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import FieldBasic from 'components/inputs/login/field-basic/field-basic';
import ModalForgotPassword from 'features/forgot-password/components/modal-forgot-password/modal-forgot-password';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UserPasswordResetRequest } from 'model/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { forgotPasswordRequest, forgotPasswordResetState } from 'reducer/account/forgot-password/actions';
import { useForgotPasswordState, useRootDispatch } from 'reducer/hooks';
import { validateEmail } from 'shared/validation/login-validations';
import './forgot-password.scss';

export interface ForgotPasswordProps extends RouteComponentProps<{ key: string }> {}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const [email, setEmail] = useState<string>('');
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const { status } = useForgotPasswordState();

    const haltProgression = !validateEmail(email).isValid;

    const handleValidSubmit = () => {
        setShowValidation(true);
        const _email = email;

        if (haltProgression) return;

        const payload: UserPasswordResetRequest = { mail: _email };
        dispatch(forgotPasswordRequest(payload));
    };

    const handleCloseModal = () => {
        history.goBack();
    };

    useEffect(() => {
        return () => {
            dispatch(forgotPasswordResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            setShowModal(true);
        }
    }, [status]);

    return (
        <main className="password__forgot">
            <article className="password__forgot--container">
                <header className="password__forgot--header">
                    <div className="password__forgot--field">
                        <p className="password__forgot--header--title">{t('pages.forgot-password.title')}</p>
                    </div>
                </header>
                <div className="password__forgot--content">
                    <div className="password__forgot--field">
                        <FieldBasic
                            helpText={t('pages.forgot-password.email.help')}
                            label={t('pages.forgot-password.email.label')}
                            value={email}
                            onChange={setEmail}
                            validate={validateEmail}
                            showValidation={showValidation}
                        />
                    </div>
                    <div className="password__forgot--button">
                        <OutlinedButtonLegacy label="pages.forgot-password.action.back" onClick={() => props.history.goBack()} />
                        <StandardButtonLegacy
                            label="pages.forgot-password.action.send"
                            onClick={handleValidSubmit}
                            disabled={showValidation && haltProgression}
                        />
                    </div>
                </div>
            </article>
            <ModalForgotPassword open={showModal} closeModal={handleCloseModal} />
        </main>
    );
};

export default ForgotPassword;
