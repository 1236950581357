import { BatchOutputBcoRequest, BatchOutputDetailsBco } from 'model/bco/batch-output';
import { GiroWebappError } from 'model/error';
import { BatchOutputDetailsBcoTypes } from 'reducer-bco/batch-output/details-batch-output/types';
import { action } from 'typesafe-actions';

export const batchOutputDetailsBcoRequest = (request: BatchOutputBcoRequest) =>
    action(BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_REQUEST, request);
export const batchOutputDetailsBcoSuccess = (batchOutputDetails: BatchOutputDetailsBco) =>
    action(BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_SUCCESS, batchOutputDetails);
export const batchOutputDetailsBcoError = (error: GiroWebappError) =>
    action(BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_ERROR, error);
export const batchOutputDetailsBcoResetState = () => action(BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_RESET);
