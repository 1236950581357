import { AxiosResponse } from 'axios';
import { TIMEOUT_FIVE_MINUTES_MS } from 'config/constants';
import {
    BatchOutputBcoRequest,
    BatchOutputDetailsBco,
    BatchOutputsListBco,
    ExportRequestSummaryResultsRequestRequired,
    ExportRequestSummaryResultsResponse,
} from 'model/bco/batch-output';
import {
    CessionToResumeBco,
    CessionToResumeRequest,
    ChunkCessionFinalizeRequest,
    ChunkCessionFinalizeResponse,
    ChunkStreamPartitioningRequest,
    ChunkStreamPartitioningResponse,
    ChunkStreamProcessRequest,
    ChunkStreamProcessResponse,
    ChunkStreamRetryRequest,
    ChunkStreamSimpleResponse,
    ChunkStreamToResumeBco,
    ChunkStreamToResumeRequest,
    DataChunkRetryRequest,
    DataChunkSimpleResponse,
} from 'model/bco/batch-output-cession-integration-gtsec';
import {
    CessionOperationBco,
    CessionOperationRequest,
    CessionOperationRequestRequired,
    CessionToSimpleDTO,
} from 'model/bco/batch-output-cession-integration-webcred';
import api from 'services/api/api';
import { BASE_URL_BCO, Page } from 'services/page';

const batchOutputsBco = () => {
    const listOutputs = (batchId: number): Promise<AxiosResponse<BatchOutputsListBco>> => {
        return api.get<BatchOutputsListBco>(`${BASE_URL_BCO}/batches/${batchId}/outputs`);
    };

    const detailsOutput = (request: BatchOutputBcoRequest): Promise<AxiosResponse<BatchOutputDetailsBco>> => {
        const { batchId, outputId } = request;
        return api.get<BatchOutputDetailsBco>(`${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}`);
    };

    const chunkStreamToResume = (request: ChunkStreamToResumeRequest): Promise<AxiosResponse<ChunkStreamToResumeBco>> => {
        const { batchId, outputId } = request;
        return api.get<ChunkStreamToResumeBco>(`${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams`);
    };
    const chunkStreamPartitioning = (request: ChunkStreamPartitioningRequest): Promise<AxiosResponse<ChunkStreamPartitioningResponse>> => {
        const { batchId, outputId } = request;
        return api.post<ChunkStreamPartitioningResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams`,
            {},
            { timeout: TIMEOUT_FIVE_MINUTES_MS }
        );
    };
    const chunkStreamProcess = (request: ChunkStreamProcessRequest): Promise<AxiosResponse<ChunkStreamProcessResponse>> => {
        const { batchId, outputId, streamId } = request;
        return api.post<ChunkStreamProcessResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams/${streamId}/chunks/process`
        );
    };

    const cessionToResume = (request: CessionToResumeRequest): Promise<AxiosResponse<CessionToResumeBco>> => {
        const { batchId, outputId } = request;
        return api.get<CessionToResumeBco>(`${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/cession`);
    };

    const chunkCessionFinalize = (request: ChunkCessionFinalizeRequest): Promise<AxiosResponse<ChunkCessionFinalizeResponse>> => {
        const { batchId, outputId, streamId } = request;
        return api.post<ChunkCessionFinalizeResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams/${streamId}/finalize`,
            {},
            { timeout: TIMEOUT_FIVE_MINUTES_MS }
        );
    };

    const dataChunkRetry = (request: DataChunkRetryRequest): Promise<AxiosResponse<DataChunkSimpleResponse>> => {
        const { batchId, outputId, streamId, chunkId } = request;
        return api.post<DataChunkSimpleResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams/${streamId}/chunks/${chunkId}/retry`,
            request,
            { timeout: TIMEOUT_FIVE_MINUTES_MS }
        );
    };

    const chunkStreamRetry = (request: ChunkStreamRetryRequest): Promise<AxiosResponse<ChunkStreamSimpleResponse>> => {
        const { batchId, outputId, streamId } = request;
        return api.post<ChunkStreamSimpleResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/streams/${streamId}/retry`,
            request,
            { timeout: TIMEOUT_FIVE_MINUTES_MS }
        );
    };

    const cessionOperation = (request: CessionOperationRequest): Promise<AxiosResponse<Page<CessionOperationBco>>> => {
        const { requestRequired, pageable } = request;
        return api.get<Page<CessionOperationBco>>(
            `${BASE_URL_BCO}/batches/${requestRequired.batchId}/outputs/${requestRequired.outputId}/cession-operations`,
            { params: { ...pageable } }
        );
    };

    const processCessionOperation = (request: CessionOperationRequestRequired): Promise<AxiosResponse<CessionToSimpleDTO>> => {
        return api.post<CessionToSimpleDTO>(
            `${BASE_URL_BCO}/batches/${request.batchId}/outputs/${request.outputId}/cession/operations/process`,
            request,
            { timeout: 0 }
        );
    };

    const concludeCession = (request: CessionOperationRequestRequired): Promise<AxiosResponse<CessionToSimpleDTO>> => {
        return api.post<CessionToSimpleDTO>(
            `${BASE_URL_BCO}/batches/${request.batchId}/outputs/${request.outputId}/cession/conclude`,
            {},
            { timeout: TIMEOUT_FIVE_MINUTES_MS }
        );
    };

    const exportRequestSummaryResults = (
        request: ExportRequestSummaryResultsRequestRequired
    ): Promise<AxiosResponse<ExportRequestSummaryResultsResponse>> => {
        const { batchId, outputId, type } = request;

        return api.get<ExportRequestSummaryResultsResponse>(
            `${BASE_URL_BCO}/batches/${batchId}/outputs/${outputId}/requests/${type}/summarize`
        );
    };

    return {
        listOutputs,
        detailsOutput,
        chunkStreamToResume,
        chunkStreamPartitioning,
        cessionToResume,
        chunkStreamProcess,
        chunkCessionFinalize,
        dataChunkRetry,
        chunkStreamRetry,
        cessionOperation,
        processCessionOperation,
        exportRequestSummaryResults,
        concludeCession,
    };
};
export default batchOutputsBco();
