import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateIntegrationIssuerPolicyState, CreateIntegrationIssuerPolicyTypes } from 'reducer/issuer-policy/create-integration/types';
import { Reducer } from 'redux';

export const initialState: CreateIntegrationIssuerPolicyState = {
    status: HttpRequestStatus.NOOP,
    policyIntegrationCreate: undefined,
    error: undefined,
};

const reducer: Reducer<CreateIntegrationIssuerPolicyState> = (state = initialState, action): CreateIntegrationIssuerPolicyState => {
    switch (action.type) {
        case CreateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_CREATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CreateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_CREATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, policyIntegrationCreate: action.payload, error: undefined };
        }
        case CreateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_CREATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_CREATE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createIssuerPolicyIntegrationReducer };
