import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { Trans, useTranslation } from 'react-i18next';
import { useResumePaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import BackButton from 'components/buttons/back-button/back-button';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useHistory } from 'react-router-dom';
import { resumePaymentInstitutionAccountRequest } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';
import { useCallback } from 'react';
import StatementHeader from 'shared/external/payment-accounts-strategy/statement/components/statement-header/statement-header';

import './statement-fallback.scss';
import StatementError from 'shared/external/payment-accounts-strategy/statement/components/statement-error/statement-error';

const I18N_PREFIX = 'payment-accounts-strategy';

interface FallbackProps {
    paymentInstitutionAccountId: number;
}

const StatementFallback = (props: FallbackProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const { resumedPaymentInstitutionAccount, status } = useResumePaymentInstitutionAccountState();

    const history = useHistory();
    const dispatch = useRootDispatch();

    const handleTryAgain = useCallback(() => {
        dispatch(resumePaymentInstitutionAccountRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    const hasError: boolean = status === HttpRequestStatus.ERROR;

    return (
        <main className="statement-fallback">
            <ContextRibbon />
            {hasError ? (
                <StatementError handleRetry={handleTryAgain} />
            ) : (
                <section className="statement-fallback__container">
                    <StatementHeader resumedPaymentInstitutionAccount={resumedPaymentInstitutionAccount} handleTryAgain={handleTryAgain} />
                    <div className="statement-fallback--container">
                        <span className="statement-fallback--message">
                            <Trans
                                t={t}
                                i18nKey={`${I18N_PREFIX}.unsupported-external-statement`}
                                values={{ name: resumedPaymentInstitutionAccount?.technicalSupplierName ?? '' }}
                            />
                        </span>
                        <BackButton label={t('entity.action.back')} onClick={() => history.push('/payment-institutions-accounts')} />
                    </div>
                </section>
            )}
        </main>
    );
};

export default StatementFallback;
