import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SendBatchCnabDocumentTypes, SendBatchCnabDocumentState } from 'reducer/batch/send-cnab-document/types';

const initialState: SendBatchCnabDocumentState = {
    status: HttpRequestStatus.NOOP,
    batch: undefined,
    error: undefined,
};

const reducer: Reducer<SendBatchCnabDocumentState> = (state = initialState, action): SendBatchCnabDocumentState => {
    switch (action.type) {
        case SendBatchCnabDocumentTypes.SEND_BATCH_CNAB_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SendBatchCnabDocumentTypes.SEND_BATCH_CNAB_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batch: action.payload, error: undefined };
        }
        case SendBatchCnabDocumentTypes.SEND_BATCH_CNAB_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SendBatchCnabDocumentTypes.SEND_BATCH_CNAB_DOCUMENT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as sendBatchCnabDocumentReducer };
