import { FC, useState } from 'react';
import SimpleMenuItem from 'shared/header/sidebar/componentes/simple-menu-item/simple-menu-item';
import './opened-menu-item.scss';
import { MenuItemPropsList } from 'model/sidebar';
import SubmenuMenuList from 'shared/header/sidebar/componentes/submenu-menu-list/submenu-menu-list';

export interface MenuItemProps {
    menuList: () => MenuItemPropsList[];
}

export const OpenedMenuItem: FC<MenuItemProps> = ({ menuList }) => {
    const [isOpen, setIsOpen] = useState<number>();

    return (
        <ul className="opened-menu-item">
            {menuList()
                .filter(menuItem => !menuItem.isHidden)
                ?.map((menuItem, index) =>
                    menuItem.hasSubmenu ? (
                        <SubmenuMenuList
                            key={`${index}-${menuItem.link}`}
                            iconSource={menuItem.iconSource}
                            translation={menuItem.title}
                            submenu={menuItem.submenu}
                            action={menuItem.action}
                            setIsOpen={setIsOpen}
                            isOpen={isOpen}
                            index={index}
                        />
                    ) : (
                        <SimpleMenuItem
                            key={`${index}-${menuItem.link}`}
                            link={menuItem.link}
                            isExternalLink={menuItem.isExternalLink}
                            title={menuItem.title}
                            iconSource={menuItem.iconSource}
                            action={menuItem.action}
                        />
                    )
                )}
        </ul>
    );
};

export default OpenedMenuItem;
