import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next';

import './suitcase-icon-button.scss';

export interface SuitCaseIconButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    title?: string;
}

const SuitCaseIconButton = (props: SuitCaseIconButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--suit-case-icon">
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled}>
                <Tooltip title={t(props.title ?? 'tooltips.company-items').toString()} aria-label="visualize">
                    <Icon>
                        <div className="button__style--suit-case-icon--icon" />
                    </Icon>
                </Tooltip>
            </Button>
        </div>
    );
};

export default SuitCaseIconButton;
