import { AxiosResponse } from 'axios';
import { ScdCompanyItemSimple } from 'model/company-item';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    updateCompanyItemError,
    updateCompanyItemRequest,
    updateCompanyItemSuccess,
} from 'reducer/company-item/update-company-item/actions';
import { UpdateCompanyItemTypes } from 'reducer/company-item/update-company-item/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyItemAPI from 'services/api/company-item-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateCompanyItem(action: ReturnType<typeof updateCompanyItemRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyItemSimple> = yield call(companyItemAPI.updateCompanyItem, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateCompanyItemError(mapped));
            return;
        }
        yield put(updateCompanyItemSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateCompanyItemError(mapped));
    }
}

function* watchUpdateCompanyItem() {
    yield takeEvery(UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_REQUEST, handleUpdateCompanyItem);
}

function* updateCompanyItemSaga() {
    yield all([fork(watchUpdateCompanyItem)]);
}

export default updateCompanyItemSaga;
