import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchConcludeState, BatchConcludeTypes } from 'reducer/batch/conclude-batch/types';

const initialState: BatchConcludeState = {
    status: HttpRequestStatus.NOOP,
    batchConclude: undefined,
    error: undefined,
};

const reducer: Reducer<BatchConcludeState> = (state = initialState, action): BatchConcludeState => {
    switch (action.type) {
        case BatchConcludeTypes.BATCH_CONCLUDE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchConcludeTypes.BATCH_CONCLUDE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchConclude: action.payload, error: undefined };
        }
        case BatchConcludeTypes.BATCH_CONCLUDE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchConcludeTypes.BATCH_CONCLUDE_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchConcludeReducer };
