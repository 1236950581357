import { PaperProps } from '@material-ui/core';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import TechnicalSupplierMoreOptionsMenuItems from 'entities/technical-supplier/components/technical-supplier-more-options-menu-items/technical-supplier-more-options-menu-items';
import { TechnicalSupplierMoreOptionsType } from 'entities/technical-supplier/technical-supplier';
import { DefaultPaymentInstitution } from 'model/enums/default-payment-institution';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface TechnicalSuppliersRowProps {
    technicalSuppliers: ScdTechnicalSupplier[];
    setModalOptionType: (value: TechnicalSupplierMoreOptionsType | undefined) => void;
    setSelectedTechnicalSupplier: (value: ScdTechnicalSupplier | undefined) => void;
    selectedTechnicalSupplier: ScdTechnicalSupplier | undefined;
}

const I18N_PREFIX = 'pages.scd.technical-supplier';

const TechnicalSuppliersRow = (props: ListChildComponentProps<TechnicalSuppliersRowProps>) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { technicalSuppliers, selectedTechnicalSupplier, setModalOptionType, setSelectedTechnicalSupplier } = data;

    const { id, name, internalReference, type, status, isDefaultPaymentInstitution } = technicalSuppliers[index];

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const getIsDefaultPaymentInstitution = (isDefaultPaymentInstitution: boolean | undefined) => {
        if (isDefaultPaymentInstitution === null || isDefaultPaymentInstitution === undefined) {
            return '-';
        } else if (isDefaultPaymentInstitution) {
            return DefaultPaymentInstitution.YES;
        } else {
            return DefaultPaymentInstitution.NO;
        }
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const menuPaperStyle: PaperProps = {
        style: {
            width: 'auto',
        },
    };

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td title={name ?? '-'} className="page-container--table-cell-title">
                {name ?? '-'}
            </td>
            <td title={internalReference ?? '-'} className="page-container--table-cell-title">
                {internalReference ?? '-'}
            </td>
            <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.input.type.options.${type}`)}</td>
            <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.input.status.options.${status}`)}</td>
            <td className="page-container--table-cell-title">{getIsDefaultPaymentInstitution(isDefaultPaymentInstitution)}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-title">
                <MoreOptionsMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onClick={() => setSelectedTechnicalSupplier(technicalSuppliers[index])}
                    menuPaperStyle={menuPaperStyle}
                >
                    <TechnicalSupplierMoreOptionsMenuItems
                        handleCloseMenu={handleCloseMenu}
                        setModalOptionType={setModalOptionType}
                        selectedTechnicalSupplier={selectedTechnicalSupplier}
                    />
                </MoreOptionsMenu>
            </td>
        </tr>
    );
};

export default TechnicalSuppliersRow;
