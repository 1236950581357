import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import FormatterUtils from 'components/formatter/formatter-utils';
import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import { ExportResult } from 'model/bco/batch-output-cession-integration-gtsec';
import { ExportResultStatus } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './cession-integration-collapse.scss';

interface CessionIntegrationCollapseCollapseProps {
    filterCollapse: string[];
    filterKey: string;
    results: ExportResult[];
    setOpenModalDetail: (value: boolean) => void;
    setExportId: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.collapse';

const CessionIntegrationCollapse: React.FC<CessionIntegrationCollapseCollapseProps> = ({
    filterCollapse,
    filterKey,
    results,
    setOpenModalDetail,
    setExportId,
}) => {
    const { t } = useTranslation();
    const [anchorElSubList, setAnchorElSubList] = useState<null | HTMLElement>(null);
    const { formatDateTimeCompleteUTC } = FormatterUtils;

    const sortedResults: ExportResult[] = [...results].sort((a, b) => {
        const dateA = new Date(a.exportDate).getTime();
        const dateB = new Date(b.exportDate).getTime();
        return dateB - dateA;
    });
    return (
        <Collapse in={filterCollapse.includes(filterKey)} timeout={0} unmountOnExit>
            {results?.length > 0 ? (
                <div className="cession-integration-collapse__sublist--box">
                    {sortedResults.map(item => (
                        <div className="cession-integration-collapse__sublist" key={item.id}>
                            <div className="cession-integration-collapse__sublist--cell"></div>
                            <div className="cession-integration-collapse__sublist--cell">
                                {t(`${I18N_PREFIX}.request-type.options.${item.type}`)}
                            </div>
                            <div className="cession-integration-collapse__sublist--cell"></div>
                            <div className="cession-integration-collapse__sublist--cell"></div>
                            <div className="cession-integration-collapse__sublist--cell">
                                {formatDateTimeCompleteUTC(item.exportDate).slice(0, 18)}
                            </div>
                            <div className={clsx('cession-integration-collapse__sublist--cell')}>
                                <span
                                    className={clsx('cession-integration-collapse__sublist--cell-status', {
                                        SUCCESS: item.result === ExportResultStatus.SUCCESS,
                                        FAILURE: item.result === ExportResultStatus.FAILURE,
                                    })}
                                >
                                    {t(`${I18N_PREFIX}.result-status.${item.result}`)}
                                </span>
                            </div>

                            <div className="cession-integration-collapse__sublist--cell">
                                <MoreOptionsMenu
                                    anchorEl={anchorElSubList}
                                    setAnchorEl={setAnchorElSubList}
                                    onClick={() => setExportId(Number(item.id))}
                                >
                                    <ModalActionMenuItem
                                        titleKey={t(`${I18N_PREFIX}.detail`)}
                                        onClick={() => {
                                            setOpenModalDetail(true);
                                            setAnchorElSubList(null);
                                        }}
                                    />
                                </MoreOptionsMenu>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="scd-batch-endorsement__not-sents">
                    <span>{t(`${I18N_PREFIX}.not-found`)}</span>
                </div>
            )}
        </Collapse>
    );
};

export default CessionIntegrationCollapse;
