import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SendBatchCcbDocumentsTypes, SendBatchCcbDocumentsState } from 'reducer/batch/send-ccb-documents/types';

const initialState: SendBatchCcbDocumentsState = {
    status: HttpRequestStatus.NOOP,
    batch: undefined,
    error: undefined,
};

const reducer: Reducer<SendBatchCcbDocumentsState> = (state = initialState, action): SendBatchCcbDocumentsState => {
    switch (action.type) {
        case SendBatchCcbDocumentsTypes.SEND_BATCH_CCB_DOCUMENTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SendBatchCcbDocumentsTypes.SEND_BATCH_CCB_DOCUMENTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batch: action.payload, error: undefined };
        }
        case SendBatchCcbDocumentsTypes.SEND_BATCH_CCB_DOCUMENTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SendBatchCcbDocumentsTypes.SEND_BATCH_CCB_DOCUMENTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as sendBatchCcbDocumentsReducer };
