import Loading from 'components/loading/loading';
import BatchOutputsDetailBridge from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import { BatchOutputBcoRequest } from 'model/bco/batch-output';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { batchOutputDetailsBcoRequest } from 'reducer-bco/batch-output/details-batch-output/action';
import { useBatchOutputDetailsBco } from 'reducer-bco/hooks-bco';
import { useBatchDetailWithProgramState, useRootDispatch } from 'reducer/hooks';
import { compose } from 'redux';
import { withResumeBatchWrapper } from 'shared/provider/batchContext';
import './batch-outputs-detail.scss';
import { CessionToResumeRequest } from 'model/bco/batch-output-cession-integration-gtsec';
import { cessionToResumeBcoRequest } from 'reducer-bco/batch-output/cession-to-resume/action';

type BatchOutputDetailsProps = RouteComponentProps<{ batchId: string; outputId: string }>;

const BatchOutputs = (props: BatchOutputDetailsProps) => {
    const dispatch = useRootDispatch();

    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [outputId] = useState<number>(Number(props.match.params.outputId));

    const { batchOutputDetails, status: batchOutputStatus } = useBatchOutputDetailsBco();
    const { batchWithProgram, status: batchWithProgramStatus } = useBatchDetailWithProgramState();

    const handleCessionToResume = useCallback(() => {
        const request: CessionToResumeRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(cessionToResumeBcoRequest(request));
    }, [dispatch, batchId, outputId]);

    const handleBatchOutputDetails = useCallback(() => {
        const request: BatchOutputBcoRequest = {
            batchId,
            outputId,
        };
        dispatch(batchOutputDetailsBcoRequest(request));
    }, [dispatch, batchId, outputId]);

    useEffect(() => {
        handleBatchOutputDetails();
        handleCessionToResume();
    }, [handleBatchOutputDetails, handleCessionToResume]);

    const isLoadingBatch = batchOutputStatus !== HttpRequestStatus.SUCCESS;
    const isLoadingBatchWithProgram = batchWithProgramStatus !== HttpRequestStatus.SUCCESS;
    const isLoading = isLoadingBatch || isLoadingBatchWithProgram;

    return (
        <main className="batch-outputs-detail">
            {isLoading ? (
                <Loading />
            ) : (
                <section className="batch-outputs-detail__container">
                    <BatchOutputsDetailBridge output={batchOutputDetails} batchWithProgram={batchWithProgram} />
                </section>
            )}
        </main>
    );
};

export default compose(withResumeBatchWrapper())(BatchOutputs);
