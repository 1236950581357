import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import { OrganizationMoreOptionsType } from 'entities/organization/organization';

interface OrganizationMoreOptionsMenuItemsProps {
    handleCloseMenu: () => void;
    setModalOptionType: (value: OrganizationMoreOptionsType | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.organization.more-options';

const OrganizationMoreOptionsMenuItems = (props: OrganizationMoreOptionsMenuItemsProps) => {
    const { handleCloseMenu, setModalOptionType } = props;

    const handleModalMessageAction = (action: () => void) => () => {
        handleCloseMenu();
        action();
    };

    return (
        <>
            <ModalActionMenuItem
                titleKey={`${I18N_PREFIX}.changeToken.title`}
                onClick={handleModalMessageAction(() => setModalOptionType('changeToken'))}
            />
        </>
    );
};

export default OrganizationMoreOptionsMenuItems;
