import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionRectifyTaxesTypes, MonthlyCommissionRectifyTaxesState } from 'reducer/monthly-commission/rectify-taxes/types';

const initialState: MonthlyCommissionRectifyTaxesState = {
    status: HttpRequestStatus.NOOP,
    monthlyCommission: undefined,
    error: undefined,
};

const reducer: Reducer<MonthlyCommissionRectifyTaxesState> = (state = initialState, action): MonthlyCommissionRectifyTaxesState => {
    switch (action.type) {
        case MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, monthlyCommission: action.payload, error: undefined };
        }
        case MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as monthlyCommissionRectifyTaxesReducer };
