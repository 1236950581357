import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentTransactionToGetTransactionFailure } from 'model/payment-transaction';
import { useTranslation } from 'react-i18next';

interface PaymentTransactionFailureCardProps {
    paymentTransactionFailure?: PaymentTransactionToGetTransactionFailure;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentTransactionFailureCard = (props: PaymentTransactionFailureCardProps) => {
    const { paymentTransactionFailure } = props;

    const { t } = useTranslation();

    if (!paymentTransactionFailure) {
        return <></>;
    }

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentTransactionFailure.label`)}>
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.failureDate`}
                value={
                    paymentTransactionFailure?.failureDate ? (
                        <DateFormatter date={paymentTransactionFailure.failureDate} format={DateFormat.DATETIME_UTC_COMPLETE} />
                    ) : (
                        ''
                    )
                }
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.origin.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentTransactionFailure.origin.options.${paymentTransactionFailure?.origin}`)}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.institution`}
                value={paymentTransactionFailure?.institution}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.type.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentTransactionFailure.type.options.${paymentTransactionFailure?.type}`)}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.errorCode`}
                value={paymentTransactionFailure?.errorCode}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.errorDescription`}
                value={paymentTransactionFailure?.errorDescription}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.errorStatus`}
                value={paymentTransactionFailure?.errorStatus}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentTransactionFailure.exceptionClass`}
                value={paymentTransactionFailure?.exceptionClass}
            />
        </ReadOnlyCard>
    );
};

export default PaymentTransactionFailureCard;
