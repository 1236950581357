import { CloseButton } from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { GiroScdErrorConstants } from 'model/enums/error-constants';
import { ScdImportedOperationReexport } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './reexport-operations-modal-error.scss';

const I18N_PREFIX = 'pages.scd.ccb-operation.modal.REEXPORT_ALL_OPERATIONS_ERROR';

export interface ReexportOperationsModalErrorProps {
    title: string;
    onClose: () => void;
    onAction?: () => void;
    reexportedOperations: ScdImportedOperationReexport[];
}

export const ReexportOperationsModalError = (props: ReexportOperationsModalErrorProps) => {
    const { title, onClose, onAction, reexportedOperations } = props;
    const { t } = useTranslation();

    const verifyErrorMessage = (responseMessage: string | undefined) => {
        const errors: string[] = valuesOfObject(GiroScdErrorConstants);

        return errors.some(value => value === responseMessage);
    };

    return (
        <Modal isOpen>
            <div className="reexport-operations-modal-error">
                <article className="reexport-operations-modal-error--content">
                    <header className="reexport-operations-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="reexport-operations-modal-error--message">
                        <p className="reexport-operations-modal-error--title">{t(title)}</p>
                        <div className="reexport-operations-modal-error--errors">
                            {reexportedOperations.map(({ ccbNumber, responseMessage }) => (
                                <div className="reexport-operations-modal-error--errors__text" key={ccbNumber}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.ccbNumber`)} </strong>
                                        {ccbNumber}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong>
                                        {responseMessage && verifyErrorMessage(responseMessage)
                                            ? t(responseMessage)
                                            : !!responseMessage
                                            ? responseMessage
                                            : t(`${I18N_PREFIX}.default-error-message`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {onAction && (
                        <div className="reexport-operations-modal-error--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.back'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ReexportOperationsModalError;
