import { SessionInterestRateResponse } from './../../../model/session-interest-rates';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CalculateSessionInterestRateTypes {
    CALCULATE_SESSION_INTEREST_RATE_REQUEST = '@@session-interest-rate-calculate/CALCULATE_SESSION_INTEREST_RATE_REQUEST',
    CALCULATE_SESSION_INTEREST_RATE_SUCCESS = '@@session-interest-rate-calculate/CALCULATE_SESSION_INTEREST_RATE_SUCCESS',
    CALCULATE_SESSION_INTEREST_RATE_ERROR = '@@session-interest-rate-calculate/CALCULATE_SESSION_INTEREST_RATE_ERROR',
    CALCULATE_SESSION_INTEREST_RATE_RESET_STATE = '@@session-interest-rate-calculate/CALCULATE_SESSION_INTEREST_RATE_RESET_STATE',
}

export interface CalculateSessionInterestRateState extends BaseStoreState {
    readonly sessionInterestRateResponse?: SessionInterestRateResponse;
}
