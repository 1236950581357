import { ReactNode } from 'react';
import "./batch-detail-header.scss"

interface BatchDetailHeaderProps {
    children: ReactNode;
}

const BatchDetailHeader = (props: BatchDetailHeaderProps) => {
    const { children } = props;

    return <header className="batch-detail-header">{children}</header>;
};

export default BatchDetailHeader;
