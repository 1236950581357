import { HttpRequestStatus } from 'model/enums/http-request-status';
import { GetImportedOperationErrorsPendingTypes, GetImportedOperationErrorsPendingState } from './types';
import { Reducer } from 'redux';

export const initialState: GetImportedOperationErrorsPendingState = {
    status: HttpRequestStatus.NOOP,
    errorsPending: undefined,
    error: undefined,
};

const reducer: Reducer<GetImportedOperationErrorsPendingState> = (state = initialState, action): GetImportedOperationErrorsPendingState => {
    switch (action.type) {
        case GetImportedOperationErrorsPendingTypes.GET_IMPORTED_OPERATION_ERRORS_PENDING_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case GetImportedOperationErrorsPendingTypes.GET_IMPORTED_OPERATION_ERRORS_PENDING_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, errorsPending: action.payload, error: undefined };
        }
        case GetImportedOperationErrorsPendingTypes.GET_IMPORTED_OPERATION_ERRORS_PENDING_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GetImportedOperationErrorsPendingTypes.GET_IMPORTED_OPERATION_ERRORS_PENDING_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as getImportedOperationsErrorsPendingReducer };
