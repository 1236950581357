import { useDispatch } from 'react-redux';
import { detailBatchWithProgramResetState } from 'reducer/batch/batch-details-with-propgram/actions';

const useBatchResetState = () => {
    const dispatch = useDispatch();

    /*
    This reset state is used 
    to maintain consistency of the batch context 
    at multiple points of the new batch flow
    **/
    const resetState = () => {
        dispatch(detailBatchWithProgramResetState());
    };

    return resetState;
};

export default useBatchResetState;