import { PaymentInstitutionAccountToResume } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ResumePaymentInstitutionsAccountsTypes {
    RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-resume/RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-resume/RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-resume/RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-resume/RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface ResumePaymentInstitutionsAccountsState extends BaseStoreState {
    readonly resumedPaymentInstitutionAccount?: PaymentInstitutionAccountToResume;
}
