import RefreshIcon from '@material-ui/icons/Refresh';
import { BatchCardType } from 'entities/batch/components/batch-card/batch-card';
import { useTranslation } from 'react-i18next';
import './error-card.scss';
import { useState } from 'react';
import debounce from 'lodash/debounce';

export interface ErrorCardProps {
    type: BatchCardType.ERROR;
    title: string;
    handleRefresh?: () => void;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const ErrorCard = ({ title, handleRefresh }: ErrorCardProps) => {
    const { t } = useTranslation();

    const [isSpinning, setIsSpinning] = useState<boolean>(false);

    const activeSpin: string = isSpinning ? '--spin' : '';

    const debouncedFunction = () => {
        setIsSpinning(true);
        if (handleRefresh) {
            handleRefresh();
        }
        setTimeout(() => setIsSpinning(false), 1000);
    };

    const handleDebounce = debounce(debouncedFunction, 500);

    return (
        <div className="error-card">
            <span className="error-card--message">{title}</span>
            <div className="error-card--refresh" onClick={handleDebounce}>
                <RefreshIcon className={`error-card--refresh-icon${activeSpin}`} />
                <span className="error-card--refresh__value">{t(`${I18N_PREFIX}.buttons.refresh-card`)}</span>
            </div>
        </div>
    );
};

export default ErrorCard;
