import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List monthly commissions
import { listMonthlyCommissionsReducer } from 'reducer/monthly-commission/list-monthly-commissions/reducer';
import listMonthlyCommissionsSaga from 'reducer/monthly-commission/list-monthly-commissions/sagas';
import { ListMonthlyCommissionsState } from 'reducer/monthly-commission/list-monthly-commissions/types';

// Detail monthly commission
import { detailMonthlyCommissionReducer } from 'reducer/monthly-commission/detail-monthly-commission/reducer';
import detailMonthlyCommissionSaga from 'reducer/monthly-commission/detail-monthly-commission/sagas';
import { DetailMonthlyCommissionState } from 'reducer/monthly-commission/detail-monthly-commission/types';

// Monthly Commission Generate Report
import { monthlyCommissionGenerateReportReducer } from 'reducer/monthly-commission/generate-report/reducer';
import monthlyCommissionGenerateReportSaga from 'reducer/monthly-commission/generate-report/sagas';
import { MonthlyCommissionGenerateReportState } from 'reducer/monthly-commission/generate-report/types';

// Monthly Commission document
import { monthlyCommissionDocumentReducer } from 'reducer/monthly-commission/document/reducer';
import monthlyCommissionDocumentSaga from 'reducer/monthly-commission/document/sagas';
import { MonthlyCommissionDocumentState } from 'reducer/monthly-commission/document/types';

// Monthly Commission document
import { monthlyCommissionTaxDocumentReducer } from 'reducer/monthly-commission/tax-document/reducer';
import monthlyCommissionTaxDocumentSaga from 'reducer/monthly-commission/tax-document/sagas';
import { MonthlyCommissionTaxDocumentState } from 'reducer/monthly-commission/tax-document/types';

// Monthly Commission rectify taxes
import { monthlyCommissionRectifyTaxesReducer } from 'reducer/monthly-commission/rectify-taxes/reducer';
import monthlyCommissionRectifyTaxesSaga from 'reducer/monthly-commission/rectify-taxes/sagas';
import { MonthlyCommissionRectifyTaxesState } from 'reducer/monthly-commission/rectify-taxes/types';

// Release Monthly Commisssion
import { releaseMonthlyCommissionReducer } from 'reducer/monthly-commission/release-monthly-commission/reducer';
import releaseMonthlyCommissionSaga from 'reducer/monthly-commission/release-monthly-commission/sagas';
import { ReleaseMonthlyCommissionState } from 'reducer/monthly-commission/release-monthly-commission/types';

export interface MonthlyCommissionsState {
    readonly listMonthlyCommissions: ListMonthlyCommissionsState;
    readonly detailMonthlyCommission: DetailMonthlyCommissionState;
    readonly monthlyCommissionGenerateReport: MonthlyCommissionGenerateReportState;
    readonly monthlyCommissionDocument: MonthlyCommissionDocumentState;
    readonly monthlyCommissionTaxDocument: MonthlyCommissionTaxDocumentState;
    readonly monthlyCommissionRectifyTaxes: MonthlyCommissionRectifyTaxesState;
    readonly releaseMonthlyCommission: ReleaseMonthlyCommissionState;
}

export const MonthlyCommissionReducer: ReducersMapObject<MonthlyCommissionsState, AnyAction> = {
    listMonthlyCommissions: listMonthlyCommissionsReducer,
    detailMonthlyCommission: detailMonthlyCommissionReducer,
    monthlyCommissionGenerateReport: monthlyCommissionGenerateReportReducer,
    monthlyCommissionDocument: monthlyCommissionDocumentReducer,
    monthlyCommissionTaxDocument: monthlyCommissionTaxDocumentReducer,
    monthlyCommissionRectifyTaxes: monthlyCommissionRectifyTaxesReducer,
    releaseMonthlyCommission: releaseMonthlyCommissionReducer,
};

export function* monthlyCommissionSagas() {
    yield all([
        fork(listMonthlyCommissionsSaga),
        fork(detailMonthlyCommissionSaga),
        fork(monthlyCommissionGenerateReportSaga),
        fork(monthlyCommissionDocumentSaga),
        fork(monthlyCommissionTaxDocumentSaga),
        fork(monthlyCommissionRectifyTaxesSaga),
        fork(releaseMonthlyCommissionSaga),
    ]);
}
