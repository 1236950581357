import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanySearch } from 'model/company';
import companyAPI from 'services/api/company-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchCompanyTypes } from 'reducer/company/search-companies/types';
import { searchCompaniesError, searchCompaniesSuccess, searchCompaniesRequest } from 'reducer/company/search-companies/actions';
import { Page } from 'services/page';

function* handleSearchCompanies(action: ReturnType<typeof searchCompaniesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompanySearch>> = yield call(companyAPI.searchCompanies, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchCompaniesError(mapped));
            return;
        }
        yield put(searchCompaniesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchCompaniesError(mapped));
    }
}

function* watchSearchCompanies() {
    yield takeEvery(SearchCompanyTypes.SEARCH_COMPANIES_REQUEST, handleSearchCompanies);
}

function* searchCompaniesSaga() {
    yield all([fork(watchSearchCompanies)]);
}

export default searchCompaniesSaga;
