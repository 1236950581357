import { ChunkStreamPartitioningRequest, ChunkStreamPartitioningResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { ChunkStreamPartitioningBcoTypes } from 'reducer-bco/batch-output/chunk-stream-partitioning/types';
import { action } from 'typesafe-actions';

export const chunkStreamPartitioningBcoRequest = (request: ChunkStreamPartitioningRequest) =>
    action(ChunkStreamPartitioningBcoTypes.CHUNK_STREAM_PARTITIONING_BCO_REQUEST, request);
export const chunkStreamPartitioningBcoSuccess = (chunkStreamPartitioningBco: ChunkStreamPartitioningResponse) =>
    action(ChunkStreamPartitioningBcoTypes.CHUNK_STREAM_PARTITIONING_BCO_SUCCESS, chunkStreamPartitioningBco);
export const chunkStreamPartitioningBcoError = (error: GiroWebappError) =>
    action(ChunkStreamPartitioningBcoTypes.CHUNK_STREAM_PARTITIONING_BCO_ERROR, error);
export const chunkStreamPartitioningBcoResetState = () => action(ChunkStreamPartitioningBcoTypes.CHUNK_STREAM_PARTITIONING_BCO_RESET);
