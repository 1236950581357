import H from 'history';
import { useHistory } from 'react-router-dom';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useRootDispatch } from 'reducer/hooks';
import AuthUtil from 'services/api/auth-util';

import sidePanelCloseFilled from 'images/side-panel-close-filled.svg';
import { LinkAction, MenuItemPropsList } from 'model/sidebar';

const I18N_PREFIX = 'navbar.account';

export const accountData = () => {
    const dispatch = useRootDispatch();
    const history: H.History = useHistory();

    const handleLogout: LinkAction = () => {
        AuthUtil.removeToken();
        dispatch(loginResetState());
        dispatch(userAccountResetState());
        history.push('/');
    };

    const account: MenuItemPropsList = {
            title: `${I18N_PREFIX}.logout`,
            link: '/',
            iconSource: sidePanelCloseFilled,
            action: handleLogout
        }
    ;


    return account;
}

