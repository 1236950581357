import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IssuerPolicyIntegrationSimple } from 'model/issuer-policy';
import { markError } from 'reducer/application/error/actions';
import { createIssuerPolicyIntegrationError, createIssuerPolicyIntegrationRequest, createIssuerPolicyIntegrationSuccess } from 'reducer/issuer-policy/create-integration/actions';
import { CreateIntegrationIssuerPolicyTypes } from 'reducer/issuer-policy/create-integration/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import issuerPolicyApi from 'services/api/issuer-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateIssuerPolicyIntegration(action: ReturnType<typeof createIssuerPolicyIntegrationRequest>) {
    try {
        const result: AxiosResponse<IssuerPolicyIntegrationSimple> = yield call(issuerPolicyApi.createIntegration, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createIssuerPolicyIntegrationError(mapped));
            return;
        }
        yield put(createIssuerPolicyIntegrationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createIssuerPolicyIntegrationError(mapped));
    }
}

function* watchCreateIssuerPolicyIntegration() {
    yield takeLeading(CreateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_CREATE_REQUEST, handleCreateIssuerPolicyIntegration);
}

function* createIssuerPolicyIntegrationSaga() {
    yield all([fork(watchCreateIssuerPolicyIntegration)]);
}

export default createIssuerPolicyIntegrationSaga;
