import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GiroWebappError } from 'model/error';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';
import { JsonUtils } from 'shared/external/util/json-util';

import './transfeera-statement-error-response.scss';

interface TransfeeraStatementErrorResponseProps {
    error: GiroWebappError | undefined;
    externalStatement: ExternalStatementResponse | undefined;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.errors';

const TransfeeraStatementErrorResponse = (props: TransfeeraStatementErrorResponseProps) => {
    const { error, externalStatement } = props;

    const { t } = useTranslation();

    const parsedExternalErrorResponse = useMemo((): any => {
        return JsonUtils.parseJsonString(externalStatement?.responseError);
    }, [externalStatement?.responseError]);

    const prettifiedStatementErrorResponse = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(externalStatement?.responseError);
    }, [externalStatement]);

    const showExternalError: boolean = !!externalStatement && !externalStatement.isSuccessful;

    return (
        <>
            {error?.message && (
                <div className="transfeera-statement-error">
                    <div className="transfeera-statement-error--item">
                        <label>{t(`${I18N_PREFIX}.internalError`)}</label>
                        <span>{t(`${I18N_PREFIX}.internalErrorMessage`)}</span>
                    </div>
                    <div className="transfeera-statement-error--item">
                        <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                        <span>{t(error.message ?? 'error.unknown')}</span>
                    </div>
                </div>
            )}

            {showExternalError && (
                <div className="transfeera-statement-error">
                    <div className="transfeera-statement-error--item">
                        <label>{t(`${I18N_PREFIX}.externalError`)}</label>
                        <span>{t(`${I18N_PREFIX}.checkRawResponse`)}</span>
                    </div>
                    <div className="transfeera-statement-error--item">
                        <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                        <span>{parsedExternalErrorResponse?.message ? parsedExternalErrorResponse?.message : '-'}</span>
                    </div>
                    <div className="transfeera-statement-error--item">
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.rawResponse`)}</label>
                            </AccordionSummary>
                            <AccordionDetails>
                                <pre>{prettifiedStatementErrorResponse}</pre>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            )}
        </>
    );
};

export default TransfeeraStatementErrorResponse;
