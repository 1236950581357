import { AxiosResponse } from 'axios';
import { ScdCompanyGroupPolicy, ScdCompanyGroupPolicySimple } from 'model/company-group-policy';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { Pageable } from 'services/pageable';

const companyGroupPolicy = () => {
    const createCompanyGroupPolicy = (companyGroupPolicy: ScdCompanyGroupPolicy): Promise<AxiosResponse<ScdCompanyGroupPolicySimple>> => {
        return api.post<ScdCompanyGroupPolicySimple>(`${BASE_URL_ADMIN}/company-group-policies`, companyGroupPolicy);
    };

    const updateCompanyGroupPolicy = (companyGroupPolicy: ScdCompanyGroupPolicy): Promise<AxiosResponse<ScdCompanyGroupPolicySimple>> => {
        return api.put<ScdCompanyGroupPolicySimple>(`${BASE_URL_ADMIN}/company-group-policies`, companyGroupPolicy);
    };

    const detailCompanyGroupPolicy = (companyGroupPolicyId: string): Promise<AxiosResponse<ScdCompanyGroupPolicy>> => {
        return api.get<ScdCompanyGroupPolicy>(`${BASE_URL_ADMIN}/company-group-policies/${companyGroupPolicyId}`);
    };

    const listCompanyGroupPolicies = (request?: Pageable): Promise<AxiosResponse<Page<ScdCompanyGroupPolicy>>> => {
        return api.get<Page<ScdCompanyGroupPolicy>>(`${BASE_URL_ADMIN}/company-group-policies`, { params: { ...request } });
    };

    return {
        createCompanyGroupPolicy,
        updateCompanyGroupPolicy,
        detailCompanyGroupPolicy,
        listCompanyGroupPolicies,
    };
};

export default companyGroupPolicy();
