import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBatchSimple } from 'model/batch';

export enum BatchConcludeTypes {
    BATCH_CONCLUDE_REQUEST = '@@batch-conclude/BATCH_CONCLUDE_REQUEST',
    BATCH_CONCLUDE_SUCCESS = '@@batch-conclude/BATCH_CONCLUDE_SUCCESS',
    BATCH_CONCLUDE_ERROR = '@@batch-conclude/BATCH_CONCLUDE_ERROR',
    BATCH_CONCLUDE_RESET = '@@batch-conclude/BATCH_CONCLUDE_RESET',
}

export interface BatchConcludeState extends BaseStoreState {
    readonly batchConclude?: ScdBatchSimple;
}
