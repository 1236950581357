import range from 'lodash/range';

const downloadObject = (id: string | number, data: string, name: string) => {
    const byteChars = atob(data);
    const byteNumbers = range(0, byteChars.length).map((_value, index, _array) => byteChars.charCodeAt(index));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'text/plain' });
    const href = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.id = id?.toString();
    link.href = href;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const FileUtils = { downloadObject };

export default FileUtils;
