import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchCloseDetailsState, BatchCloseDetailsTypes } from 'reducer/batch/batch-close-details/types';

const initialState: BatchCloseDetailsState = {
    status: HttpRequestStatus.NOOP,
    batchCloseInformations: undefined,
    error: undefined,
};

const reducer: Reducer<BatchCloseDetailsState> = (state = initialState, action): BatchCloseDetailsState => {
    switch (action.type) {
        case BatchCloseDetailsTypes.BATCH_CLOSE_DETAILS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchCloseDetailsTypes.BATCH_CLOSE_DETAILS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchCloseInformations: action.payload, error: undefined };
        }
        case BatchCloseDetailsTypes.BATCH_CLOSE_DETAILS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchCloseDetailsTypes.BATCH_CLOSE_DETAILS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchCloseDetailsReducer };
