import FormatterUtils from 'components/formatter/formatter-utils';
import { ScdBatchCommissionReport } from 'model/batch';
import { ScdCommissionReport, ScdCommissionReportNormalized } from 'model/commission-report';
import {} from 'model/disbursement-report';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { NormalizationUtils } from 'shared/util/normalization-utils';
import { getPersonType } from 'shared/util/person-type-utils';

type ReportData = ScdCommissionReport;
type ReportHeader = Record<keyof ReportData, string>;

const I18N_PREFIX = 'pages.scd.batch.detail.report.commission.headers';

interface BatchDisbursementReportProps {
    batchCommissionReport: ScdBatchCommissionReport;
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
}

const BatchCommissionReport = (props: BatchDisbursementReportProps) => {
    const { batchCommissionReport, csvLinkRef } = props;
    const { t } = useTranslation();

    const commissionFilename = useMemo(() => {
        const formattedDate = FormatterUtils.formatDateByYear(batchCommissionReport?.date);
        return `RelatorioDeComissao_${batchCommissionReport?.organization?.name ?? 'giro'}_${
            batchCommissionReport?.code
        }_${formattedDate}.csv`;
    }, [batchCommissionReport]);

    const COMMISSION_REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            identification: t(`${I18N_PREFIX}.identification`),
            name: t(`${I18N_PREFIX}.name`),
            value: t(`${I18N_PREFIX}.value`),
            rate: t(`${I18N_PREFIX}.rate`),
        };
    }, [t]);

    const BATCH_COMISSION_NORMALIZED_DATA: ScdCommissionReportNormalized[] | undefined = useMemo(() => {
        return batchCommissionReport?.commissions?.map(it => ({
            ...it,
            identification: FormatterUtils.formatIdentification(getPersonType(it.identification), it.identification),
            value: NormalizationUtils.normalizeCurrencyValueToLocale(it.value),
            rate: NormalizationUtils.normalizeCurrencyValueToLocale(it.rate),
        }));
    }, [batchCommissionReport]);

    return (
        <>
            <CSVLink
                className="hidden"
                filename={commissionFilename}
                ref={csvLinkRef}
                data={BATCH_COMISSION_NORMALIZED_DATA ?? []}
                headers={Object.keys(COMMISSION_REPORT_HEADERS).map(key => ({ key, label: COMMISSION_REPORT_HEADERS[key] }))}
            />
        </>
    );
};

export default BatchCommissionReport;
