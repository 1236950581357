import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search favored bank accounts
import { searchFavoredBankAccountsReducer } from 'reducer/favored-bank-account/search-favored-bank-accounts/reducer';
import searchFavoredBankAccountsSaga from 'reducer/favored-bank-account/search-favored-bank-accounts/sagas';
import { SearchFavoredBankAccountsState } from 'reducer/favored-bank-account/search-favored-bank-accounts/types';

export interface FavoredBankAccountState {
    readonly searchFavoredBankAccounts: SearchFavoredBankAccountsState;
}

export const FavoredBankAccountReducer: ReducersMapObject<FavoredBankAccountState, AnyAction> = {
    searchFavoredBankAccounts: searchFavoredBankAccountsReducer,
};

export function* favoredBankAccountSagas() {
    yield all([fork(searchFavoredBankAccountsSaga)]);
}
