import { ExportRequestSummaryResultsResponse } from 'model/bco/batch-output';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ExportRequestSummaryResultsBcoTypes {
    EXPORT_REQUEST_SUMMARY_RESULTS_BCO_REQUEST = '@@export-request-summary-results-bco/EXPORT_REQUEST_SUMMARY_RESULTS_BCO_REQUEST',
    EXPORT_REQUEST_SUMMARY_RESULTS_BCO_SUCCESS = '@@export-request-summary-results-bco/EXPORT_REQUEST_SUMMARY_RESULTS_BCO_SUCCESS',
    EXPORT_REQUEST_SUMMARY_RESULTS_BCO_ERROR = '@@export-request-summary-results-bco/EXPORT_REQUEST_SUMMARY_RESULTS_BCO_ERROR',
    EXPORT_REQUEST_SUMMARY_RESULTS_BCO_RESET = '@@export-request-summary-results-bco/EXPORT_REQUEST_SUMMARY_RESULTS_BCO_RESET',
}

export interface ExportRequestSummaryResultsBcoState extends BaseStoreState {
    readonly exportRequestSummaryResults?: ExportRequestSummaryResultsResponse;
}
