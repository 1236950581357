import ContextRibbon from 'components/context-ribbon/context-ribbon';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDetailOrganizationState, useRootDispatch } from 'reducer/hooks';
import { detailOrganizationRequest, detailOrganizationResetState } from 'reducer/organization/detail-organizations/actions';
import './organization-detail.scss';

type ScdOrganizationDetailProps = RouteComponentProps<{ organizationId: string }>;

const I18N_PREFIX = 'pages.scd.organization.input';

const ScdOrganizationDetailPage = (props: ScdOrganizationDetailProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [organizationId] = useState<number>(Number(props.match.params.organizationId));

    const { status, organization } = useDetailOrganizationState();

    useEffect(() => {
        if (!organizationId) return;

        dispatch(detailOrganizationRequest(organizationId));
        return () => {
            dispatch(detailOrganizationResetState());
        };
    }, [dispatch, organizationId]);

    const isLoading = status !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-organization__detail">
            <ContextRibbon action={() => history.push('/organizations')} />
            <div className="scd-organization__detail--main-container">
                <header className="scd-organization__detail--header">
                    <h2 className="scd-organization__detail--header-title">{t('pages.scd.organization.detail.title')}</h2>
                </header>
                <div className="scd-organization__detail--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={organization?.name ?? '-'} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.identification.label`)}
                                value={
                                    organization?.identification ? (
                                        <IdentificationFormatter value={organization.identification} type={PersonType.CORPORATION} />
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.organizationKey.label`)}
                                value={organization?.organizationKey ?? '-'}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdOrganizationDetailPage;
