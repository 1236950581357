import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useListProgramOutputPoliciesState, useProgramToResumeState, useRootDispatch } from 'reducer/hooks';

import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ProgramOutputPoliciesHeader from 'entities/program/program-output-policies/components/program-output-policies-header/program-output-policies-header';
import ProgramOutputPoliciesList from 'entities/program/program-output-policies/components/program-output-policies-list/program-output-policies-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { programToResumeRequest, programToResumeResetState } from 'reducer/program-groups/resume-program/actions';
import {
    listProgramOutputPoliciesRequest,
    listProgramOutputPoliciesResetState,
} from 'reducer/program-output-policies/list-program-output-policies/actions';
import './program-output-policies.scss';

type ProgramOutputPolicyProps = RouteComponentProps<{ programId: string }>;

const I18N_PREFIX = 'pages.scd.programs-output-policies';

const ProgramOutputPolicies = (props: ProgramOutputPolicyProps) => {
    const { match } = props;

    const [programId] = useState<string>(match.params.programId);

    const { t } = useTranslation();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const { resumedProgram } = useProgramToResumeState();

    const { status, programOutputPolicies } = useListProgramOutputPoliciesState();

    const [isOpenModalWarning, setIsOpenModalWarning] = useState<boolean>(false);

    const handleRibbonAction = () => {
        if (!!programId && programOutputPolicies?.length === 0) {
            setIsOpenModalWarning(true);
            return;
        }
        history.push('/programs');
    };

    useEffect(() => {
        dispatch(programToResumeRequest(programId));
    }, [dispatch, programId]);

    useEffect(() => {
        dispatch(listProgramOutputPoliciesRequest(programId));
    }, [dispatch, programId]);

    useEffect(() => {
        return () => {
            dispatch(programToResumeResetState());
            dispatch(listProgramOutputPoliciesResetState());
        };
    }, [dispatch]);

    const isLoading = useMemo(() => {
        return status === HttpRequestStatus.ON_GOING;
    }, [status]);

    return (
        <main className="scd-program-output-policies">
            <ContextRibbon action={handleRibbonAction} />
            <section className="scd-program-output-policies__container">
                <header className="scd-program-output-policies__header">
                    <div className="scd-program-output-policies__header-container">
                        <h2 className="scd-program-output-policies__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                        <StandardButtonLegacy label={t(`${I18N_PREFIX}.add.title`)} onClick={undefined} disabled />
                    </div>
                    <div className="scd-program-output-policies__header-container--info">
                        <span className="scd-program-output-policies__header-container--info-item">{resumedProgram?.program?.name}</span>
                        <span className="scd-program-output-policies__header-container--info-item">
                            {t(`${I18N_PREFIX}.productType.${resumedProgram?.program?.productType}`)}
                        </span>
                        <span
                            className={clsx('scd-program-output-policies__header-container--info-status', resumedProgram?.program?.status)}
                        >
                            {t(`${I18N_PREFIX}.status.${resumedProgram?.program?.status}`).toUpperCase()}
                        </span>
                    </div>
                </header>
                <div className="scd-program-output-policies__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !programOutputPolicies?.length,
                        })}
                    >
                        <thead>
                            <ProgramOutputPoliciesHeader />
                        </thead>
                        <tbody>
                            <ProgramOutputPoliciesList programOutputPolicies={programOutputPolicies ?? []} isLoading={isLoading} />
                        </tbody>
                    </table>
                </div>
            </section>
            {isOpenModalWarning && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.warning.title`)}
                    message={t(`${I18N_PREFIX}.warning.message`)}
                    onCancel={() => setIsOpenModalWarning(false)}
                    onAction={() => {
                        setIsOpenModalWarning(false);
                        history.push('/programs');
                    }}
                    onClose={() => setIsOpenModalWarning(false)}
                />
            )}
        </main>
    );
};

export default ProgramOutputPolicies;
