import { PaymentTransactionToSimple } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreateInternalRepaymentPaymentTransactionTypes {
    CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_REQUEST = '@@payment-transaction-internal-repayment-create/CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_REQUEST',
    CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transaction-internal-repayment-create/CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_SUCCESS',
    CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_ERROR = '@@payment-transaction-internal-repayment-create/CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_ERROR',
    CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_RESET = '@@payment-transaction-internal-repayment-create/CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_RESET',
}

export interface CreateInternalRepaymentPaymentTransactionState extends BaseStoreState {
    readonly paymentTransaction?: PaymentTransactionToSimple
}
