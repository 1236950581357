import { AxiosResponse } from 'axios';
import { ScdBatchClose } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { closeBatchError, closeBatchRequest, closeBatchSuccess } from 'reducer/batch/close-batch/actions';
import { CloseBatchTypes } from 'reducer/batch/close-batch/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCloseBatch(action: ReturnType<typeof closeBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchClose> = yield call(batchAPI.closeBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(closeBatchError(mapped));
            return;
        }
        yield put(closeBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(closeBatchError(mapped));
    }
}

function* watchCloseBatch() {
    yield takeLeading(CloseBatchTypes.CLOSE_BATCH_REQUEST, handleCloseBatch);
}

function* closeBatchSaga() {
    yield all([fork(watchCloseBatch)]);
}

export default closeBatchSaga;
