import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchSignatureExportState, BatchSignatureExportTypes } from 'reducer/batch/batch-signature-export/types';

const initialState: BatchSignatureExportState = {
    status: HttpRequestStatus.NOOP,
    signatureExport: undefined,
    error: undefined
}

const reducer: Reducer<BatchSignatureExportState> = (state = initialState, action): BatchSignatureExportState => {
    switch (action.type) {
        case BatchSignatureExportTypes.BATCH_SIGNATURE_EXPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchSignatureExportTypes.BATCH_SIGNATURE_EXPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, signatureExport: action.payload, error: undefined };
        }
        case BatchSignatureExportTypes.BATCH_SIGNATURE_EXPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchSignatureExportTypes.BATCH_SIGNATURE_EXPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchSignatureExportReducer }