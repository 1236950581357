import { GiroWebappError } from 'model/error';
import { ScdImportedOperationMonthlyProcessedReport } from 'model/imported-operation';
import { MonthlyProcessedImportedOperationsReportTypes } from './types';
import { action } from 'typesafe-actions';

export const monthlyProcessedOperationsReportRequest = (yearMonth: string) =>
    action(MonthlyProcessedImportedOperationsReportTypes.MONTHLY_PROCESSED_OPERATIONS_REPORT_REQUEST, yearMonth);
export const monthlyProcessedOperationsReportSuccess = (data: ScdImportedOperationMonthlyProcessedReport[]) =>
    action(MonthlyProcessedImportedOperationsReportTypes.MONTHLY_PROCESSED_OPERATIONS_REPORT_SUCCESS, data);
export const monthlyProcessedOperationsReportError = (error: GiroWebappError) =>
    action(MonthlyProcessedImportedOperationsReportTypes.MONTHLY_PROCESSED_OPERATIONS_REPORT_ERROR, error);
export const monthlyProcessedOperationsReportResetState = () => action(MonthlyProcessedImportedOperationsReportTypes.MONTHLY_PROCESSED_OPERATIONS_REPORT_RESET_STATE);
