import {
    FailedDocumentBind,
    FileUploaded,
    OperationFileInference,
    OperationToSendBacking,
    OperationWithFileToList,
} from 'features/limelight/send-backings/domain/models';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import React, { useEffect } from 'react';
import { useProvisionalLimelightListOperations } from 'reducer/hooks';

export interface BindDocumentsToOperationProps {}
export interface BindDocumentsToOperationState {
    operations: OperationWithFileToList[];
    failedFiles: FailedDocumentBind[];

    inferDocuments: (files: FileUploaded[]) => void;
    overrideBind: (operationId: number, file: FileUploaded) => void;
    removeBind: (operationId: number, file: FileUploaded) => void;
    clearFailedFiles: () => void;
}

export const useBindDocumentsToOperations = (props: BindDocumentsToOperationProps): BindDocumentsToOperationState => {
    const [operations, setOperations] = React.useState<OperationWithFileToList[]>([]);
    const [failedFiles, setFailedFiles] = React.useState<FailedDocumentBind[]>([]);
    const { limelightListOperations, status: statusLimelight } = useProvisionalLimelightListOperations();

    useEffect(() => {
        if (statusLimelight !== HttpRequestStatus.SUCCESS) return;

        setOperations(state => [
            ...(limelightListOperations?.operations.map(it => ({ operation: it, isBinded: false, file: undefined })) ?? []),
        ]);
    }, [statusLimelight, limelightListOperations]);

    const composePotentialMatchingNames = (operation: OperationToSendBacking): string[] => {
        return [
            `ContratoAssinado_${operation.originator}_${operation.debtorIdentification}_${operation.ccbNumber}.pdf`,
            `ContratoAssinado_${operation.originator}_${operation.debtorIdentification}_${operation.ccbNumber}-CreditFlow.pdf`,
            `ContratoAssinado_${operation.originator}_${operation.debtorIdentification}_${operation.ccbNumber} - CreditFlow.pdf`
        ];
    }

    const doesOperationMatchesFileName = (operation: OperationToSendBacking, fileName: string): boolean => {
        const potentialMatchingNames = composePotentialMatchingNames(operation);
        return potentialMatchingNames.some(it => it?.toLowerCase() === fileName?.toLowerCase());
    };

    const matchOperationByFile = (file: FileUploaded): OperationFileInference => {
        const matches = operations.filter(it => doesOperationMatchesFileName(it.operation, file.fileName)) ?? [];

        if (matches.length === 0) {
            const error = GiroWebappErrorConstants.FILE_DOES_NOT_MATCH_ANY_OPERATION;
            return { file, wasInferred: false, error: error };
        }

        if (matches.length > 1) {
            const error = GiroWebappErrorConstants.FILE_HAS_MULTIPLE_MATCHES;
            return { file, wasInferred: false, error };
        }

        const match = matches[0];
        if (match.isBinded === true) {
            const error = GiroWebappErrorConstants.OPERATION_ALREADY_HAS_DOCUMENT;
            return { file, wasInferred: false, error };
        }

        return { file, wasInferred: true, operation: match.operation };
    };

    const inferDocuments = (files: FileUploaded[]): void => {
        const inferenceList: OperationFileInference[] = files.map(file => matchOperationByFile(file));

        const filesToBound: OperationWithFileToList[] = inferenceList
            .filter(it => it.wasInferred === true)
            .filter(it => it.operation !== undefined)
            .map(it => ({ operation: it.operation, file: it.file, isBinded: true } as OperationWithFileToList));

        setOperations(state => [
            ...state.map(it => ({ ...it, ...filesToBound.find(fileToBound => fileToBound.operation.id === it.operation.id) })),
        ]);

        const failed: FailedDocumentBind[] = inferenceList
            .filter(it => it.wasInferred === false)
            .map(it => ({ file: it.file, error: it.error } as FailedDocumentBind));

        setFailedFiles([...failed]);
    };

    const overrideBind = (operationId: number, _file: FileUploaded): void => {
        setOperations(state => [
            ...state.map(it => {
                if (it.operation.id !== operationId) return it;
                return { ...it, isBinded: true, file: _file };
            }),
        ]);
    };

    const removeBind = (operationId: number, file: FileUploaded): void => {
        setOperations(state => [
            ...state.map(it => {
                if (it.operation.id !== operationId) return it;
                return { ...it, isBinded: false, file: undefined };
            }),
        ]);
    };

    const clearFailedFiles = (): void => {
        return setFailedFiles([]);
    };

    return {
        operations,
        failedFiles,

        inferDocuments,
        overrideBind,
        removeBind,
        clearFailedFiles,
    };
};
