import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import './closed-menu-item.scss';
import { MenuItemPropsList } from 'model/sidebar';

export interface ClosedMenuItemProps {
    menuList: () => MenuItemPropsList[];
}

export const ClosedMenuItem: FC<ClosedMenuItemProps> = ({ menuList }) => {
    const local = useLocation().pathname;

    const renderActiveIcon = (item: MenuItemPropsList): JSX.Element => (
        <div key={item.title} className="closed-menu-item--active-icon-box">
            <img src={item.iconSource} alt={item.title} />
        </div>
    );

    const activeMenuItem = menuList()
        .filter(menuItem => !menuItem.isHidden)
        .find(menuItem => menuItem.hasSubmenu
                ? menuItem.submenu?.some(subItem => subItem.link === local)
                : menuItem.link === local
            );

    return activeMenuItem ? renderActiveIcon(activeMenuItem) : null;
};

export default ClosedMenuItem;
