import { ScdPaymentTransactionsByBatchLegacyErrorsList } from 'model/payment-transaction-by-batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreatePaymentTransactionsByBatchLegacyTypes {
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST = '@@payment-transactions-by-batch-create-legacy/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS = '@@payment-transactions-by-batch-create-legacy/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR = '@@payment-transactions-by-batch-create-legacy/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET = '@@payment-transactions-by-batch-create-legacy/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET',
}

export interface CreatePaymentTransactionsByBatchLegacyState extends BaseStoreState {
    readonly paymentTransactionsByBatchLegacyErrors?: ScdPaymentTransactionsByBatchLegacyErrorsList;
}
