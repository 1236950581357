import BackButton from 'components/buttons/back-button/back-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { detailBatchWithProgramRequest, detailBatchWithProgramResetState } from 'reducer/batch/batch-details-with-propgram/actions';
import { useBatchDetailWithProgramState, useCloseBatchState, useRootDispatch } from 'reducer/hooks';

export const BatchWrapper: React.FunctionComponent = props => {
    const { batchId } = useParams<{ batchId: string }>();
    const history = useHistory();
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { status } = useBatchDetailWithProgramState();
    const { status: statusCloseBatch } = useCloseBatchState()

    useEffect(() => {
        return () => {
            dispatch(detailBatchWithProgramResetState());
        };
    }, [dispatch]);

    const handleBatchWithProgramRequest = useCallback(() => {
        if (!batchId) return;
        dispatch(detailBatchWithProgramRequest(Number(batchId)));
    }, [dispatch, batchId])
    
    useEffect(() => {
        handleBatchWithProgramRequest();
    }, [handleBatchWithProgramRequest, statusCloseBatch]);

    if (status === HttpRequestStatus.NOOP || status === HttpRequestStatus.ERROR) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('pages.scd.batch.not-found')}</div>
                <BackButton label={t('global.try-again')} onClick={handleBatchWithProgramRequest} />
            </div>
        )
    }

    const isLoading:boolean = status === HttpRequestStatus.ON_GOING;

    return (
        <div>
            <ContextRibbon
                action={() => {
                    dispatch(detailBatchWithProgramResetState());
                    history.goBack();
                }}
            />
            {isLoading ? <Loading/> : props.children}
        </div>
    );
};

export const withResumeBatchWrapper =
    () =>
        <P extends object>(WrappedComponent: React.ComponentType<P>) =>
        (props: P) => {
            return (
                <BatchWrapper>
                    <WrappedComponent {...props} />
                </BatchWrapper>
            );
        };
