import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IssuerPolicyDetail } from 'model/issuer-policy';
import { markError } from 'reducer/application/error/actions';
import { detailIssuerPolicyError, detailIssuerPolicyRequest, detailIssuerPolicySuccess } from 'reducer/issuer-policy/detail-policy/actions';
import { DetailIssuerPolicyTypes } from 'reducer/issuer-policy/detail-policy/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import issuerPolicyApi from 'services/api/issuer-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailIssuerPolicy(action: ReturnType<typeof detailIssuerPolicyRequest>) {
    try {
        const result: AxiosResponse<IssuerPolicyDetail> = yield call(issuerPolicyApi.detailPolicy, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailIssuerPolicyError(mapped));
            return;
        }
        yield put(detailIssuerPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailIssuerPolicyError(mapped));
    }
}

function* watchDetailIssuerPolicy() {
    yield takeEvery(DetailIssuerPolicyTypes.DETAIL_ISSUER_POLICY_REQUEST, handleDetailIssuerPolicy);
}

function* detailIssuerPolicySaga() {
    yield all([fork(watchDetailIssuerPolicy)]);
}

export default detailIssuerPolicySaga;
