import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DataChunkRetryBcoState, DataChunkRetryBcoTypes } from 'reducer-bco/batch-output/retry-data-chunk/types';
import { Reducer } from 'redux';

const initialState: DataChunkRetryBcoState = {
    status: HttpRequestStatus.NOOP,
    dataChunkRetryBco: undefined,
    error: undefined,
};

const reducer: Reducer<DataChunkRetryBcoState> = (state = initialState, action): DataChunkRetryBcoState => {
    switch (action.type) {
        case DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, dataChunkRetryBco: action.payload, error: undefined };
        }
        case DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as dataChunkRetryBcoReducer };
