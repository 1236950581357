import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionTaxDocumentState, MonthlyCommissionTaxDocumentTypes } from 'reducer/monthly-commission/tax-document/types';

const initialState: MonthlyCommissionTaxDocumentState = {
    status: HttpRequestStatus.NOOP,
    uploadTaxDocument: undefined,
    downloadTaxDocument: undefined,
    error: undefined,
};

const reducer: Reducer<MonthlyCommissionTaxDocumentState> = (state = initialState, action): MonthlyCommissionTaxDocumentState => {
    switch (action.type) {
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, uploadTaxDocument: action.payload, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, downloadTaxDocument: action.payload, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, error: undefined };
        }
        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_TAX_DOCUMENT_RESET: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as monthlyCommissionTaxDocumentReducer };
