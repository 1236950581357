import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramResponse } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum UpdateProgramTypes {
    UPDATE_PROGRAM_REQUEST = '@@program-update/UPDATE_PROGRAM_REQUEST',
    UPDATE_PROGRAM_SUCCESS = '@@program-update/UPDATE_PROGRAM_SUCCESS',
    UPDATE_PROGRAM_ERROR = '@@program-update/UPDATE_PROGRAM_ERROR',
    UPDATE_PROGRAM_RESET_STATE = '@@program-update/UPDATE_PROGRAM_RESET_STATE',
}

export interface UpdateProgramState extends BaseStoreState {
    readonly programSimple?: ScdProgramResponse;
}
