import CloseButton from 'components/buttons/close-button/close-button';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './request-log-details-modal.scss';
import { RequestLogModelBrs } from 'model/brs/request-log-brs';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ReadOnlyJsonCard } from 'components/card/read-only-json-card/read-only-json-card';
import { useEffect, useState } from 'react';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import RequestLogUtils from 'entities/request-log/utils/request-log-utils';

interface RequestLogDetailsModalProps {
    requestLog: RequestLogModelBrs;
    onClose: () => void;
}

const I18N_PREFIX = 'pages.scd.request-log.detail';

const RequestLogDetailsModal = (props: RequestLogDetailsModalProps) => {
    const { requestLog, onClose } = props;

    const { t } = useTranslation();

    const [requestBody, setRequestBody] = useState<string | undefined>(undefined);
    const [responseBody, setResponseBody] = useState<string | undefined>(undefined);


    useEffect(() => {
        const rawRequestBody = requestLog?.request?.body;
        const requestBody = RequestLogUtils.tryToCleanMongoDBJson(rawRequestBody);
        setRequestBody(requestBody);

    }, [requestLog?.request?.body]);

    useEffect(() => {
        const rawResponseBody = requestLog?.response?.body;
        const responseBody = RequestLogUtils.tryToCleanMongoDBJson(rawResponseBody);
        setResponseBody(responseBody);

    }, [requestLog?.response?.body]);


    return (
        <Modal isOpen toggle={onClose}>
            <div className="request-log-details">
                <div className="request-log-details--header">
                    <label
                        className="request-log-details--header-title"> {t(`${I18N_PREFIX}.title`)} </label>
                    <CloseButton onClick={onClose} />
                </div>
                <div className="request-log-details--container">
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.id`)} value={requestLog.id} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.requestDate`)}
                                       value={<DateFormatter date={requestLog.requestDate}
                                                             format={DateFormat.DATETIME_UTC} />} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.host`)} value={requestLog?.host} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.responseStatus`)} value={requestLog?.status} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.agent`)} value={requestLog?.agent} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.route`)} value={requestLog?.route} />
                    <ReadOnlyTextField label={t(`${I18N_PREFIX}.method`)} value={requestLog?.method} />
                    <ReadOnlyTextField
                        label={t(`${I18N_PREFIX}.type.title`)}
                        value={t(`${I18N_PREFIX}.type.options.${requestLog?.type}`)}
                    />
                    {requestBody ? (
                        <div>
                            <ReadOnlyJsonCard name={t(`${I18N_PREFIX}.request`)} text={requestBody} />
                        </div>
                    ) : (
                        <ReadOnlyTextField label={t(`${I18N_PREFIX}.request`)} value={requestBody} />
                    )}
                    {responseBody ? (
                        <div>
                            <ReadOnlyJsonCard name={t(`${I18N_PREFIX}.response`)} text={responseBody} />
                        </div>
                    ) : (
                        <ReadOnlyTextField label={t(`${I18N_PREFIX}.response`)} value={responseBody} />
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default RequestLogDetailsModal;
