import { GiroWebappError } from 'model/error';
import { ScdImportedOperationReexport } from 'model/imported-operation';
import { ImportedOperationsReexportTypes } from 'reducer/imported-operation/reexport/types';
import { action } from 'typesafe-actions';

export const importedOperationsReexportRequest = (operationId: number) =>
    action(ImportedOperationsReexportTypes.IMPORTED_OPERATIONS_REEXPORT_REQUEST, operationId);
export const importedOperationsReexportSuccess = (data: ScdImportedOperationReexport) =>
    action(ImportedOperationsReexportTypes.IMPORTED_OPERATIONS_REEXPORT_SUCCESS, data);
export const importedOperationsReexportError = (error: GiroWebappError) =>
    action(ImportedOperationsReexportTypes.IMPORTED_OPERATIONS_REEXPORT_ERROR, error);
export const importedOperationsReexportResetState = () => action(ImportedOperationsReexportTypes.IMPORTED_OPERATIONS_REEXPORT_RESET_STATE);
