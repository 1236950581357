import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { SEARCH_PAGE_SIZE_DEFAULT } from 'components/modals/constants';
import { useState } from 'react';
import { Modal } from 'reactstrap';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Pageable } from 'services/pageable';

export interface ModalFilterProps {
    title: string;
    label: string;
    placeholder: string;
    action: (request: any) => AnyAction;
    onClose: () => void;
    onSelect: (value: string) => void;
    filterValue: string;
    requestParameters?: object;
}

const ModalFilter = (props: ModalFilterProps) => {
    const { action, title, label, onClose, placeholder, onSelect, filterValue, requestParameters } = props;
    const dispatch = useRootDispatch();
    const [value, setValue] = useState<string>(filterValue);

    const searchPageable = (search: string) => {
        const _pageable: Pageable = { page: 0, size: SEARCH_PAGE_SIZE_DEFAULT };
        const request = {
            search: search,
            pageable: _pageable,
            ...requestParameters,
        };

        return request;
    };

    const confirmRequest = () => {
        if (value !== '') {
            dispatch(action(searchPageable(value)));
        }
        onSelect(value);
        onClose();
    };

    const handleOnChange = (searchInput: string) => {
        setValue(searchInput);
    };

    const handleClearValue = () => {
        setValue('');
    };

    return (
        <Modal isOpen>
            <div className="select-option-modal-content-container">
                <div className="select-option-modal-content-header">
                    <label className="select-option-modal-title-style"> {title} </label>
                    <CloseButton onClick={onClose} />
                </div>
                <div className="select-option-modal-search-input-container">
                    <SimpleSearchInput label={label} value={value} placeholder={placeholder} onChange={handleOnChange} />
                </div>
                <div className="modal-message--actions">
                    <ClearButton onClick={handleClearValue} />
                    <StandardButtonLegacy label="entity.action.confirm" onClick={confirmRequest} />
                </div>
            </div>
        </Modal>
    );
};

export default ModalFilter;
