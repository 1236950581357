import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationSimple } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { retryPreprocessImportedOperationError, retryPreprocessImportedOperationRequest, retryPreprocessImportedOperationSuccess } from './actions';
import { RetryPreprocessImportedOperationTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleRetryPreprocessImportedOperation(action: ReturnType<typeof retryPreprocessImportedOperationRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationSimple> = yield call(importedOperationApi.retryPreprocess, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(retryPreprocessImportedOperationError(mapped));
            return;
        }
        yield put(retryPreprocessImportedOperationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(retryPreprocessImportedOperationError(mapped));
    }
}

function* watchRetryPreprocessImportedOperation() {
    yield takeLeading(RetryPreprocessImportedOperationTypes.RETRY_PREPROCESS_IMPORTED_OPERATION_REQUEST, handleRetryPreprocessImportedOperation);
}

function* retryPreprocessImportedOperationSaga() {
    yield all([fork(watchRetryPreprocessImportedOperation)]);
}

export default retryPreprocessImportedOperationSaga;
