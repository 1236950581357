import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanySearch } from 'model/company';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchCompanyTypes {
    SEARCH_COMPANIES_REQUEST = '@@companies-search/SEARCH_COMPANIES_REQUEST',
    SEARCH_COMPANIES_SUCCESS = '@@companies-search/SEARCH_COMPANIES_SUCCESS',
    SEARCH_COMPANIES_ERROR = '@@companies-search/SEARCH_COMPANIES_ERROR',
    SEARCH_COMPANIES_RESET = '@@companies-search/SEARCH_COMPANIES_RESET',
}

export interface SearchCompaniesState extends BaseStoreState {
    readonly companies?: Page<ScdCompanySearch>;
}
