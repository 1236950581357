import { IssuerStatus } from 'model/enums/issuer-status';

export interface ScdIssuerSimple {
    id: number;
}
export interface ScdIssuer {
    id?: number;
    name?: string;
    identification?: string;
    status?: IssuerStatus;
}

export const defaultScdIssuer: ScdIssuer = {
    id: undefined,
    name: undefined,
    identification: undefined,
    status: IssuerStatus.ACTIVE,
};

export interface ScdIssuerList {
    id: number;
    name: string;
    identification: string;
    status: IssuerStatus;
}

export interface ScdIssuerToUpdateRequest {
    issuerId: number;
    issuer: ScdIssuer;
}

export interface ScdIssuerSearch {
    id: number;
    name: string;
    identification: string;
}

export interface IssuerBatchOutputDetail {
    id: number;
    name: string;
    identification: string;
}
