import { formatIdentification } from 'components/formatter/identification/identification-formatter';
import { HemeraSendBackingRequest, OperationWithFileToList } from 'features/limelight/send-backings/domain/models';
import BackingUtils from 'features/limelight/send-backings/utils/backing-utils';
import { HemeraBacking, HemeraBackingsXmlBuilder } from 'features/limelight/send-backings/utils/hemera-backing-xml-builder';
import { PersonType } from 'model/enums/person-type';
import React from 'react';
import { useProvisionalLimelightListOperations } from 'reducer/hooks';

export interface ProcessBackingsProps {
    operations: OperationWithFileToList[];
}
export interface ProcessBackingsState {
    process: () => Promise<void>;
    processing: BackingsProcessingStatus;
    xmlDebug: string | undefined;
    request: HemeraSendBackingRequest | undefined;
}

export enum BackingsProcessingStatus {
    NOOP = 'NOOP',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export const useProcessBackings = (props: ProcessBackingsProps): ProcessBackingsState => {
    const { operations } = props;

    const { limelightListOperations } = useProvisionalLimelightListOperations();
    // TODO state dados integracao

    const [processing, setProcessing] = React.useState<BackingsProcessingStatus>(BackingsProcessingStatus.NOOP);

    const [xmlDebug, setXMLDebug] = React.useState<string | undefined>(undefined);
    const [request, setRequest] = React.useState<HemeraSendBackingRequest | undefined>(undefined);

    // TODO state hemera request
    // TODO state hemera response

    const mapBackings = (): HemeraBacking[] => {
        return operations
            .filter(it => it.isBinded)
            .map(it => ({ operation_id: it.operation.id, file_name: it.file?.fileName }))
            .filter(it => it.file_name !== undefined)
            .flatMap(it => {
                const expirations =
                    limelightListOperations?.expirations
                        .filter(expiration => expiration.operationId === it.operation_id)
                        .filter(expiration => expiration.participantControl !== undefined) ?? [];
                return expirations.map(expiration => ({ participantControl: expiration.participantControl, fileName: it.file_name ?? '' }));
            });
    };

    const process = async (): Promise<void> => {
        setProcessing(BackingsProcessingStatus.PROCESSING);
        try {
            const files: File[] = operations
                .filter(it => it.isBinded)
                .filter(it => it.file !== undefined)
                .map(it => it.file)
                .filter(it => !!it?.fileData)
                .map(it => it?.fileData as File);

            const zipped_files = await BackingUtils.zipFiles(files);
            const files_base64 = BackingUtils.bytesToBase64(zipped_files);

            const backings = mapBackings();

            const builder = new HemeraBackingsXmlBuilder();
            builder.withIssuer(limelightListOperations?.issuerIdentification ?? '');
            builder.withZipBase64(files_base64);
            builder.withBackings(backings);

            setXMLDebug(builder.getDebugInfo());
            const xml = builder.build();

            const zip_xml = await BackingUtils.zipString(xml, 'Lastros.xml');
            const xml_hash = await BackingUtils.generateHashHexUsingSha256FromBytes(crypto, zip_xml);
            const xml_base64 = BackingUtils.bytesToBase64(zip_xml);

            const issuer_identification = formatIdentification(PersonType.CORPORATION, limelightListOperations?.issuerIdentification) ?? '';

            const request: HemeraSendBackingRequest = {
                handshake: limelightListOperations?.handshake ?? '',
                tipo: 'LASTRO',
                arquivo: xml_base64,
                cnpj_fundo: issuer_identification,
                hash: xml_hash,
            };

            setRequest(request);
            setProcessing(BackingsProcessingStatus.SUCCESS);
        } catch (error) {
            // TODO handle error
            setProcessing(BackingsProcessingStatus.ERROR);
        }
    };

    return {
        process,
        processing,
        xmlDebug,
        request,
    };
};
