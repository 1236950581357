import { ScdBatchToGenerateCessionRequest } from 'model/batch';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.batch.generate-cession.validation';

const PAYMENT_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.payment-amount.required`,
    },
};

export const validatePaymentAmount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, PAYMENT_AMOUNT_VALIDATION);

const CESSION_TAX_PERCENTAGE_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.cession-tax-percentage.required`,
    },
};

export const validateCessionTaxPercentage = (value: number | undefined | null) =>
    ValidationUtils.validate(value, CESSION_TAX_PERCENTAGE_VALIDATION);


export const validateCession = (value: ScdBatchToGenerateCessionRequest | undefined | null): boolean => {
    if (!value) return false;

    return validatePaymentAmount(value.paymentAmount).isValid &&
    validateCessionTaxPercentage(value.cessionTaxPercentage).isValid
};
