import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SERVER_API_URL } from 'config/constants';
import { store } from 'index';
import HttpStatus from 'model/enums/http-status';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import AuthUtil from 'services/api/auth-util';

const createApiInstance = (): AxiosInstance => {
    const config: AxiosRequestConfig = {
        baseURL: SERVER_API_URL,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        timeout: 1 * 60 * 1000, // 1min,
    };

    const instance = axios.create(config);
    instance.interceptors.request.use(async _config => {
        const token = AuthUtil.getToken();
        if (token) {
            _config.headers.Authorization = `Bearer ${token.id_token}`;
        }
        return _config;
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            const status = error.response?.status;
            if (status !== HttpStatus.FORBIDDEN && status !== HttpStatus.UNAUTHORIZED) {
                return Promise.reject(error);
            }

            AuthUtil.removeToken();
            store.dispatch(loginResetState());
            store.dispatch(userAccountResetState());
            return Promise.reject(error);

        }
    );

    return instance;
};

export const api = createApiInstance();
export default api;
