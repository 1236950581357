import { AxiosResponse } from 'axios';
import { DataChunkSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import {
    dataChunkRetryBcoError,
    dataChunkRetryBcoRequest,
    dataChunkRetryBcoSuccess,
} from 'reducer-bco/batch-output/retry-data-chunk/action';
import { DataChunkRetryBcoTypes } from 'reducer-bco/batch-output/retry-data-chunk/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleDataChunkRetryBco(action: ReturnType<typeof dataChunkRetryBcoRequest>) {
    try {
        const result: AxiosResponse<DataChunkSimpleResponse> = yield call(batchOutputBcoApi.dataChunkRetry, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(dataChunkRetryBcoError(mapped));
            return;
        }
        yield put(dataChunkRetryBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(dataChunkRetryBcoError(mapped));
    }
}

function* watchDataChunkRetryBcoSaga() {
    yield takeEvery(DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_REQUEST, handleDataChunkRetryBco);
}

function* dataChunkRetryBcoSaga() {
    yield all([fork(watchDataChunkRetryBcoSaga)]);
}

export default dataChunkRetryBcoSaga;
