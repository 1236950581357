import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionToSearch } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { searchPaymentInstitutionsAccountsRequest, searchPaymentInstitutionsAccountsError, searchPaymentInstitutionsAccountsSuccess } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/actions';
import { SearchPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSearchPaymentInstitutionsAccounts(action: ReturnType<typeof searchPaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<Page<PaymentInstitutionToSearch>> = yield call(
            paymentInstitutionsAccountsApi.searchPaymentInstitutions,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchPaymentInstitutionsAccountsError(mapped));
            return;
        }
        yield put(searchPaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchPaymentInstitutionsAccountsError(mapped));
    }
}

function* watchSearchPaymentInstitutionsAccounts() {
    yield takeEvery(SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleSearchPaymentInstitutionsAccounts);
}

function* searchPaymentInstitutionsAccountsSaga() {
    yield all([fork(watchSearchPaymentInstitutionsAccounts)]);
}

export default searchPaymentInstitutionsAccountsSaga;
