import { UserSimple } from 'model/user';
import { BaseStoreState } from 'reducer/base-store-state';

export enum UserCreateTypes {
    USER_CREATE_REQUEST = '@@user-create/USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS = '@@user-create/USER_CREATE_SUCCESS',
    USER_CREATE_ERROR = '@@user-create/USER_CREATE_ERROR',
    USER_CREATE_RESET_STATE = '@@user-create/USER_CREATE_RESET_STATE',
}

export interface UserCreateState extends BaseStoreState {
    readonly userCreate?: UserSimple;
}
