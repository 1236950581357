import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CloseButton } from 'components/buttons/close-button/close-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import Loading from 'components/loading/loading';
import { HemeraAuthorizationForSendBackingRequest, HemeraProvisionalProxyRequest } from 'features/limelight/send-backings/domain/models';
import { BackingsProcessingStatus, ProcessBackingsState } from 'features/limelight/send-backings/hooks/use-process-backings';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import {
    useBatchOutputEndorsementState,
    useDetailBatchState,
    useProvisionalLimelightAuthorizationSendBrackings,
    useProvisionalLimelightProxySendBrackings,
    useRootDispatch,
} from 'reducer/hooks';
import {
    limelightProxySendBackingRequest,
    limelightProxySendBackingResetState,
} from 'reducer/provisional-limelight/provisional-limelight-proxy-send/action';
import { limelightAuthorizationSendBackingRequest } from 'reducer/provisional-limelight/provisional-limelight-send/action';
import { JsonUtils } from 'shared/external/util/json-util';
import { useToast } from 'shared/hooks/use-toast';
import './limelight-modal.scss';

export type ProcessSteps =
    | 'loadingAuthorization'
    | 'authorizationError'
    | 'processingBackings'
    | 'loadingProcessing'
    | 'processingBackingsError'
    | 'sendBackings'
    | 'loadingSendBackings'
    | 'responseBackings';

export interface LimelightModalProps {
    title?: string;
    onClose: () => void;
    onCancel?: () => void;
    steps: ProcessSteps;
    setSteps: (steps: ProcessSteps) => void;
    processHook: ProcessBackingsState;
}

const I18N_PREFIX = 'pages.scd.provisional-limelight';

export const LimelightModal = (props: LimelightModalProps) => {
    const { t } = useTranslation();
    const { onClose, onCancel, steps, setSteps, processHook } = props;

    const dispatch = useRootDispatch();
    const { process, processing, request, xmlDebug } = processHook;
    const { toastSuccess } = useToast();

    const { authorizationSendBacking, status } = useProvisionalLimelightAuthorizationSendBrackings();
    const { batch } = useDetailBatchState();
    const { outputEndorsement } = useBatchOutputEndorsementState();
    const { hemeraResponse, status: sendProxyStatus } = useProvisionalLimelightProxySendBrackings();
    const handleProcessStep = useCallback(() => {
        setSteps('loadingProcessing');
        process();
    }, [process, setSteps]);

    const prettifiedHemera = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByObject(request);
    }, [request]);

    const prettifiedHemeraResponse = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(hemeraResponse?.response);
    }, [hemeraResponse?.response]);

    const prettifiedHemeraResponseError = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(hemeraResponse?.responseError);
    }, [hemeraResponse?.responseError]);

    const handleCopyToClipboard = useCallback(
        async (value: string | undefined) => {
            await navigator.clipboard.writeText(value || '');
            toastSuccess('Copiado');
        },
        [toastSuccess]
    );

    const handleSendStep = useCallback(() => {
        if (!batch?.id || !outputEndorsement || !request) return;

        const _request: HemeraProvisionalProxyRequest = {
            batchId: batch?.id,
            outputId: outputEndorsement?.id,
            request: request,
        };
        dispatch(limelightProxySendBackingRequest(_request));
        setSteps('loadingSendBackings');
    }, [setSteps, batch, request, dispatch, outputEndorsement]);

    const handleAuthorizationRetry = useCallback(() => {
        if (!batch?.id || !outputEndorsement) return;

        const _request: HemeraAuthorizationForSendBackingRequest = {
            batchId: batch?.id,
            outputId: outputEndorsement?.id,
        };
        dispatch(limelightAuthorizationSendBackingRequest(_request));
        setSteps('processingBackings');
    }, [setSteps, batch, dispatch, outputEndorsement]);

    const handleProcessingRetry = useCallback(() => {
        process();
        setSteps('loadingProcessing');
    }, [setSteps, process]);

    const stepsButtons: Record<ProcessSteps, React.ReactNode> = useMemo(() => {
        return {
            loadingAuthorization: <> </>,
            authorizationError: (
                <StandardButtonLegacy label={t(`${I18N_PREFIX}.modal-send.authorization`)} onClick={handleAuthorizationRetry} />
            ),
            processingBackings: (
                <StandardButtonLegacy
                    label={'entity.action.confirm'}
                    onClick={handleProcessStep}
                    disabled={!authorizationSendBacking?.authorization}
                />
            ),
            loadingProcessing: <></>,
            processingBackingsError: (
                <StandardButtonLegacy label={t(`${I18N_PREFIX}.modal-processing.reprocess`)} onClick={handleProcessingRetry} />
            ),
            sendBackings: (
                <StandardButtonLegacy
                    label={'entity.action.confirm'}
                    onClick={handleSendStep}
                    disabled={processing !== BackingsProcessingStatus.SUCCESS}
                />
            ),
            loadingSendBackings: <></>,
            responseBackings: <></>,
        };
    }, [
        authorizationSendBacking?.authorization,
        processing,
        handleProcessStep,
        handleSendStep,
        handleAuthorizationRetry,
        t,
        handleProcessingRetry,
    ]);

    const stepsMessages: Record<ProcessSteps, React.ReactNode> = useMemo(() => {
        return {
            loadingAuthorization: (
                <>
                    {authorizationSendBacking?.authorization
                        ? authorizationSendBacking.authorization
                        : t(`${I18N_PREFIX}.modal-errors.authorization-hemera.not-found`)}
                </>
            ),
            authorizationError: t(`${I18N_PREFIX}.modal-errors.authorization-hemera.error`),
            processingBackings: t(`${I18N_PREFIX}.modal-processing.title`),
            loadingProcessing: (
                <>
                    {t(`${I18N_PREFIX}.modal-processing.loading`)}
                    <Loading />
                </>
            ),
            processingBackingsError: t(`${I18N_PREFIX}.modal-errors.processing.error`),
            sendBackings: (
                <Grid container spacing={2}>
                    {t(`${I18N_PREFIX}.modal-send.title`)}
                    <Grid item xs={12}>
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.modal-send.options.token`)}</label>
                            </AccordionSummary>
                            <AccordionDetails className="limelight-modal--summary">
                                {authorizationSendBacking?.authorization ? (
                                    <>
                                        <label
                                            onClick={event => {
                                                event.stopPropagation();
                                                handleCopyToClipboard(authorizationSendBacking?.authorization);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FileCopyIcon />
                                            {t(`${I18N_PREFIX}.modal-send.copied`)}
                                        </label>
                                        <pre>{authorizationSendBacking?.authorization}</pre>
                                    </>
                                ) : (
                                    <pre>""</pre>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    {xmlDebug && (
                        <Grid item xs={12}>
                            <Accordion variant="outlined">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <label>{t(`${I18N_PREFIX}.modal-send.options.xml`)}</label>
                                </AccordionSummary>
                                <AccordionDetails className="limelight-modal--summary">
                                    <label
                                        onClick={event => {
                                            event.stopPropagation();
                                            handleCopyToClipboard(xmlDebug);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <FileCopyIcon />
                                        {t(`${I18N_PREFIX}.modal-send.copied`)}
                                    </label>
                                    {xmlDebug ? <pre>{xmlDebug}</pre> : <pre>""</pre>}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.modal-send.options.request-hemera`)}</label>
                            </AccordionSummary>
                            <AccordionDetails className="limelight-modal--summary">
                                <label
                                    onClick={event => {
                                        event.stopPropagation();
                                        handleCopyToClipboard(prettifiedHemera);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FileCopyIcon />
                                    {t(`${I18N_PREFIX}.modal-send.copied`)}
                                </label>
                                {prettifiedHemera ? <pre>{prettifiedHemera}</pre> : <pre>""</pre>}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            ),
            loadingSendBackings: (
                <>
                    {t(`${I18N_PREFIX}.modal-send.loading`)}
                    <Loading />
                </>
            ),
            responseBackings: (
                <Grid container spacing={2}>
                    {hemeraResponse?.isSuccessful && <label>{t(`${I18N_PREFIX}.modal-response-hemera.title`)}</label>}
                    {!hemeraResponse?.isSuccessful && <label>{t(`${I18N_PREFIX}.modal-response-hemera.error-title`)}</label>}

                    <Grid item xs={12}>
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.modal-send.options.response-hemera`)}</label>
                            </AccordionSummary>
                            <AccordionDetails className="limelight-modal--summary">
                                <label
                                    onClick={event => {
                                        event.stopPropagation();
                                        handleCopyToClipboard(
                                            hemeraResponse?.isSuccessful ? prettifiedHemeraResponse : prettifiedHemeraResponseError
                                        );
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FileCopyIcon />
                                    {t(`${I18N_PREFIX}.modal-send.copied`)}
                                </label>
                                {hemeraResponse?.isSuccessful && <pre>{prettifiedHemeraResponse}</pre>}
                                {!hemeraResponse?.isSuccessful && <pre>{prettifiedHemeraResponseError}</pre>}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.modal-send.options.request-hemera`)}</label>
                            </AccordionSummary>
                            <AccordionDetails className="limelight-modal--summary">
                                <label
                                    onClick={event => {
                                        event.stopPropagation();
                                        handleCopyToClipboard(prettifiedHemera);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <FileCopyIcon />
                                    {t(`${I18N_PREFIX}.modal-send.copied`)}
                                </label>
                                {prettifiedHemera ? <pre>{prettifiedHemera}</pre> : <pre>""</pre>}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.modal-send.options.token`)}</label>
                            </AccordionSummary>
                            <AccordionDetails className="limelight-modal--summary">
                                {authorizationSendBacking?.authorization ? (
                                    <>
                                        <label
                                            onClick={event => {
                                                event.stopPropagation();
                                                handleCopyToClipboard(authorizationSendBacking?.authorization);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FileCopyIcon />
                                            {t(`${I18N_PREFIX}.modal-send.copied`)}
                                        </label>
                                        <pre>{authorizationSendBacking?.authorization}</pre>
                                    </>
                                ) : (
                                    <pre>""</pre>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            ),
        };
    }, [
        authorizationSendBacking?.authorization,
        prettifiedHemera,
        xmlDebug,
        handleCopyToClipboard,
        hemeraResponse?.isSuccessful,
        prettifiedHemeraResponse,
        prettifiedHemeraResponseError,
        t,
    ]);

    useEffect(() => {
        if (steps !== 'loadingProcessing') return;
        if (processing === BackingsProcessingStatus.SUCCESS) {
            setSteps('sendBackings');
        }
    }, [processing, setSteps, steps]);

    useEffect(() => {
        if (steps !== 'loadingProcessing') return;
        if (processing === BackingsProcessingStatus.ERROR) {
            setSteps('processingBackingsError');
        }
    }, [processing, setSteps, steps]);

    useEffect(() => {
        if (steps !== 'loadingAuthorization') return;
        if (status !== HttpRequestStatus.SUCCESS) return;
        setSteps('processingBackings');
    }, [status, setSteps, steps]);

    useEffect(() => {
        if (steps !== 'loadingSendBackings') return;
        if (sendProxyStatus !== HttpRequestStatus.SUCCESS) return;

        setSteps('responseBackings');
    }, [sendProxyStatus, setSteps, steps]);

    useEffect(() => {
        if (steps !== 'loadingAuthorization') return;
        if (status !== HttpRequestStatus.ERROR) return;
        setSteps('authorizationError');
    }, [status, setSteps, steps]);

    useEffect(() => {
        if (steps !== 'loadingSendBackings') return;
        if (sendProxyStatus !== HttpRequestStatus.ERROR) return;
        setSteps('processingBackings');
        dispatch(limelightProxySendBackingResetState());
    }, [status, setSteps, steps, sendProxyStatus, dispatch]);

    return (
        <Modal toggle={onClose} isOpen>
            <div className="limelight-modal">
                <article className="limelight-modal--content">
                    <header className="limelight-modal--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="limelight-modal--message">
                        <p className="limelight-modal--title">{stepsMessages[steps]}</p>
                    </div>
                    {
                        <div className="limelight-modal--actions">
                            {onCancel && (
                                <OutlinedButtonLegacy
                                    label={
                                        steps === 'responseBackings'
                                            ? t(`${I18N_PREFIX}.buttons.closed`)
                                            : t(`${I18N_PREFIX}.buttons.canceled`)
                                    }
                                    onClick={onCancel}
                                />
                            )}
                            {stepsButtons[steps]}
                        </div>
                    }
                </article>
            </div>
        </Modal>
    );
};

export default LimelightModal;
