import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { BatchFilterRequest } from 'model/batch';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseBatchFilterChipsProps {
    filterActive: BatchFilterRequest;
    setFilterActive: (value: BatchFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.batch';

type BatchFilterRequestType = keyof Omit<BatchFilterRequest, 'todayOnly'>;

const useBatchFilterChips = (props: UseBatchFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: BatchFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<BatchFilterRequestType, () => void> = useMemo(() => {
        return {
            product: () => clearFilterSupport({ ...filterActive, product: undefined }),
            originator: () => clearFilterSupport({ ...filterActive, originator: undefined }),
            status: () => clearFilterSupport({ ...filterActive, status: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: BatchFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filter.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );
    const batchFilterChips = useMemo(() => {
        const items: Record<BatchFilterRequestType, ChipFilterItem> = {
            product: {
                isActive: !!filterActive.product,
                onDelete: handleClearFilter['product'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('product')}
                        component={t(`${I18N_PREFIX}.product.options.${filterActive.product}`)}
                    />
                ),
            },
            originator: {
                isActive: !!filterActive.originator,
                onDelete: handleClearFilter['originator'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('originator')} component={filterActive.originator?.name} />,
            },
            status: {
                isActive: !!filterActive.status,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={t(`${I18N_PREFIX}.status.options.${filterActive.status}`)}
                    />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isBatchChipsActive: boolean = useMemo(() => {
        return batchFilterChips.some(it => !!it.isActive);
    }, [batchFilterChips]);

    return {
        batchFilterChips,
        isBatchChipsActive,
    };
};

export default useBatchFilterChips;
