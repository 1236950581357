import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentTransactionPendingList } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import {
    listPendingPaymentTransactionsError,
    listPendingPaymentTransactionsSuccess
} from 'reducer/payment-transaction/list-pending-payment-transactions/actions';
import { ListPendingPaymentTransactionsTypes } from 'reducer/payment-transaction/list-pending-payment-transactions/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListPendingPaymentTransactions() {
    try {
        const result: AxiosResponse<ScdPaymentTransactionPendingList> = yield call(
            paymentTransactionAPI.listPendingPaymentTransactions
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listPendingPaymentTransactionsError(mapped));
            return;
        }
        yield put(listPendingPaymentTransactionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listPendingPaymentTransactionsError(mapped));
    }
}

function* watchListPendingPaymentTransactions() {
    yield takeEvery(ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_REQUEST, handleListPendingPaymentTransactions);
}

function* listPendingPaymentTransactionsSaga() {
    yield all([fork(watchListPendingPaymentTransactions)]);
}

export default listPendingPaymentTransactionsSaga;
