import PaymentInstitutionAccountRepayment from 'entities/payment-institutions-accounts/components/payment-institution-account-internal-repayment/payment-institution-account-internal-repayment';
import PaymentInstitutionAccountCreateUpdate from 'entities/payment-institutions-accounts/payment-institution-account-create-update/payment-institution-account-create-update';
import PaymentInstitutionAccountDetail from 'entities/payment-institutions-accounts/payment-institution-account-detail/payment-institution-account-detail';
import PaymentInstitutionsAccounts from 'entities/payment-institutions-accounts/payment-institutions-accounts';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import PaymentInstitutionAccountConvert from 'entities/payment-institutions-accounts/payment-institution-account-convert/payment-institution-account-convert';

interface Matchparams {
    url: string;
}

const PaymentInstitutionsAccountsRoutes = ({ match }: RouteComponentProps<Matchparams>) => (
    <Switch>
        <PrivateRoute exact path={match.url} component={PaymentInstitutionsAccounts} />
         <PrivateRoute
            exact
            path={`${match.url}/new`}
            component={PaymentInstitutionAccountCreateUpdate}
        />
        <PrivateRoute
            exact
            path={`${match.url}/:paymentInstitutionAccountId/edit`}
            component={PaymentInstitutionAccountCreateUpdate}
        />
        <PrivateRoute
            exact
            path={`${match.url}/:paymentInstitutionAccountId/internal-repayment`}
            component={PaymentInstitutionAccountRepayment}
        />
        <PrivateRoute 
            exact 
            path={`${match.url}/:paymentInstitutionAccountId`} 
            component={PaymentInstitutionAccountDetail} 
        />
        <PrivateRoute 
            exact 
            path={`${match.url}/:paymentInstitutionAccountId/convert`} 
            component={PaymentInstitutionAccountConvert} 
        />
    </Switch>
);

export default PaymentInstitutionsAccountsRoutes;
