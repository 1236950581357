import { RouteComponentProps, Switch } from 'react-router-dom';
import ExternalStatement from 'shared/external/payment-accounts-strategy/statement/external-statements';
import ExternalTransfers from 'shared/external/payment-accounts-strategy/transfers/external-transfers';
import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const ExternalPaymentInstitutionAccountsRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/:paymentInstitutionAccountId/transfers`} component={ExternalTransfers} />
        <PrivateRoute exact path={`${match.url}/:paymentInstitutionAccountId/statements`} component={ExternalStatement} />
    </Switch>
);

export default ExternalPaymentInstitutionAccountsRoutes;
