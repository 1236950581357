import AddIcon from '@material-ui/icons/Add';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import CreatePaymentTransaction from 'entities/payment-transaction/components/create-payment-transaction/create-payment-transaction';
import ListPaymentTransactions from 'entities/payment-transaction/components/list-payment-transactions/list-payment-transactions';
import PaymentTransactionFilterButtons from 'entities/payment-transaction/components/payment-transaction-filter-buttons/payment-transaction-filter-buttons';
import PaymentTransactionModalFilter, {
    PaymentTransactionModalFilterType,
} from 'entities/payment-transaction/components/payment-transaction-modal-filter/payment-transaction-modal-filter';
import PendingButton from 'entities/payment-transaction/components/payment-transaction-pending-button/payment-transaction-pending-button';
import ScdPaymentTransactionHeader from 'entities/payment-transaction/components/payment-transactions-header/payment-transactions-header';
import PaymentTransactionTypesLegend from 'entities/payment-transaction/components/payment-types-legend/payment-types-legend';
import useScdPaymentTransactionFilterChips from 'entities/payment-transaction/hooks/use-scd-payment-transaction-filter-chips';
import PaymentTransactionModalInSeries from 'entities/payment-transaction/payment-transaction-in-series/components/payment-transaction-in-series-modal/payment-transaction-in-series-modal';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import {
    PaymentTransactionFilterRequest,
    ScdPaymentTransactionData,
    defaultPaymentTransactionFilterRequest,
} from 'model/payment-transaction';
import { PaymentTransactionFilterPageable } from 'model/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useListPaymentTransactionsState, useRootDispatch, useSummarizePaymentTransactionsState } from 'reducer/hooks';
import {
    listPaymentTransactionsRequest,
    listPaymentTransactionsResetState,
} from 'reducer/payment-transaction/list-payment-transactions/actions';
import {
    summarizePaymentTransactionsRequest,
    summarizePaymentTransactionsResetState,
} from 'reducer/payment-transaction/summarize-payment-transactions/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './payment-transaction.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction';

export type PaymentTransactionDataSortableProperties =
    | keyof Pick<ScdPaymentTransactionData, 'date' | 'amount' | 'status'>
    | 'paymentFavored.identification'
    | 'createdDate'
    | 'originatorName';

const ScdPaymentTransaction = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<PaymentTransactionDataSortableProperties>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<PaymentTransactionModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<PaymentTransactionFilterRequest>(defaultPaymentTransactionFilterRequest);
    const [isNewTransaction, setIsNewTransaction] = useState<boolean>(false);
    const [openModalInSerie, setOpenModalInSerie] = useState<boolean>(false);
    const [selectedPaymentTransactionId, setSelectedPaymentTransactionId] = useState<number | undefined>(undefined);

    const { url } = useRouteMatch();
    const history = useHistory();

    const { isScdPaymentTransactionChipsActive, scdPaymentTransactionFilterChips } = useScdPaymentTransactionFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const { paymentTransactions, status: listStatus } = useListPaymentTransactionsState();
    const { pending } = useSummarizePaymentTransactionsState();

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const filterPageable = useCallback((): PaymentTransactionFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };
        const _filter: PaymentTransactionFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filter,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const {
        InfiniteScrollButton,
        items: paymentTransactionsItems,
        resetInfiniteScrollListing,
        isLoading,
        handleRefresh,
    } = useInfiniteScroll<ScdPaymentTransactionData>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listPaymentTransactions.paymentTransactions,
        statusSelector: (state: IRootState) => state.listPaymentTransactions.status,
        action: listPaymentTransactionsRequest,
        filterPageable,
        setPage,
    });

    useEffect(() => {
        dispatch(summarizePaymentTransactionsRequest());
    }, [dispatch]);

    const handleSummarizePendingTransactions = useCallback(() => {
        dispatch(summarizePaymentTransactionsRequest());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(listPaymentTransactionsResetState());
        };
    }, [dispatch, filterPageable]);

    useEffect(() => {
        return () => {
            dispatch(summarizePaymentTransactionsResetState());
        };
    }, [dispatch]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.payment-transaction.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }

    const handleSort = (property: PaymentTransactionDataSortableProperties, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc' && sortedProperty !== 'createdDate') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };
    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultPaymentTransactionFilterRequest);
    };
    const handleSelectWaitingTransactions = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive({ ...filterActive, status: PaymentTransactionStatus.PENDING });
    };

    const isActiveFilter = filterActive.amount || filterActive.date || filterActive.favored || filterActive.status;

    return (
        <main className="scd-payment-transaction">
            <section className="scd-payment-transaction__container">
                <header className="scd-payment-transaction__header">
                    <div className="scd-payment-transaction__header--container-buttons">
                        <h2 className="scd-payment-transaction__header--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton
                                onClick={() => {
                                    handleSummarizePendingTransactions();
                                    handleRefresh();
                                }}
                            />
                        </h2>
                        <div className="scd-payment-transaction__header--buttons-content-align-right">
                            {pending?.numberOfTransactionsPendingManualDispatch &&
                            pending?.numberOfTransactionsPendingManualDispatch !== 0 ? (
                                <>
                                    <div className="scd-payment-transaction__header--buttons-content-pending">
                                        <PendingButton
                                            label={t(`${I18N_PREFIX}.buttons.pending`)}
                                            onClick={() => history.push(`${url}/complete-transactions`)}
                                            numberPending={pending?.numberOfTransactionsPendingManualDispatch}
                                        />
                                    </div>
                                    <div className="scd-payment-transaction__header--buttons-content-in-series">
                                        <LinkButton
                                            startIcon={<PlaylistAddIcon />}
                                            label={t(`${I18N_PREFIX}.buttons.in-series`)}
                                            onClick={() => setOpenModalInSerie(true)}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="scd-payment-transaction__header--buttons-content-new-transaction">
                                        <LinkButton
                                            startIcon={<AddIcon />}
                                            label={t(`${I18N_PREFIX}.buttons.new`)}
                                            onClick={() => setIsNewTransaction(true)}
                                            outlinedStyle
                                            disabled={!!isActiveFilter}
                                        />
                                    </div>
                                    <div className="scd-payment-transaction__header--buttons-content-in-series">
                                        <LinkButton
                                            startIcon={<PlaylistAddIcon />}
                                            label={t(`${I18N_PREFIX}.buttons.in-series`)}
                                            onClick={() => setOpenModalInSerie(true)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="scd-payment-transaction__header--buttons-content">
                        <PaymentTransactionFilterButtons
                            isNewTransaction={isNewTransaction}
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                        />
                    </div>
                    <ChipFilterActive filters={scdPaymentTransactionFilterChips} />
                </header>

                <div className="scd-payment-transaction__table">
                    <div className="transactions-container">
                        <div className="transactions-info" onClick={handleSelectWaitingTransactions}>
                            <HourglassFullIcon className="transactions-info--icon" />
                            <span>
                                {t(`${I18N_PREFIX}.waiting`, {
                                    count: pending?.numberOfPendingTransactions ?? 0,
                                })}
                            </span>
                        </div>
                        <PaymentTransactionTypesLegend />
                    </div>

                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !paymentTransactionsItems.length,
                            CHIPS_ACTIVE: isScdPaymentTransactionChipsActive,
                        })}
                    >
                        <thead>
                            <ScdPaymentTransactionHeader
                                paymentTransactions={paymentTransactions?.content ?? []}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                disabledSort={isNewTransaction}
                            />
                        </thead>
                        <tbody>
                            {isNewTransaction && (
                                <tr className="page-container--table-new">
                                    <CreatePaymentTransaction
                                        handleListTransactions={handleRefresh}
                                        handleCloseNewTransaction={() => setIsNewTransaction(false)}
                                        handleSummarizePendingTransactions={handleSummarizePendingTransactions}
                                    />
                                </tr>
                            )}
                            <ListPaymentTransactions
                                filterActive={filterActive}
                                isNewTransaction={isNewTransaction}
                                paymentTransactions={paymentTransactionsItems}
                                isLoading={isLoading}
                                selectedPaymentTransactionId={selectedPaymentTransactionId}
                                setSelectedPaymentTransactionId={setSelectedPaymentTransactionId}
                            />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
                {modalFilter && (
                    <PaymentTransactionModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                        resetInfiniteScrollListing={resetInfiniteScrollListing}
                    />
                )}
                {openModalInSerie && <PaymentTransactionModalInSeries onClose={() => setOpenModalInSerie(false)} />}
            </section>
        </main>
    );
};

export default ScdPaymentTransaction;
