import clsx from 'clsx';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import {
    useExternalStatementPaymentInstitutionAccountState,
    useResumePaymentInstitutionAccountState,
    useRootDispatch
} from 'reducer/hooks';
import {
    externalStatementPaymentInstitutionsAccountsRequest,
    externalStatementPaymentInstitutionsAccountsResetState
} from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/actions';
import StatementHeader
    from 'shared/external/payment-accounts-strategy/statement/components/statement-header/statement-header';
import TransfeeraStatementFiltersButtons
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-filters-buttons/transfeera-statement-filters-buttons';
import TransfeeraStatementFiltersModal, {
    TransfeeraStatementFiltersType
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-filters-modal/transfeera-statement-filters-modal';
import useTransfeeraStatementInfiniteScroll
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-infinite-scroll/transfeera-statement-infinite-scroll';
import TransfeeraStatementList
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-list/transfeera-statement-list';
import TransfeeraStatementShowBalance
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-show-balance/transfeera-statement-show-balance';
import TransfeeraStatementTableHeader
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-table-header/transfeera-statement-table-header';
import {
    defaultTransfeeraExternalStatementFilters,
    TransfeeraExternalStatementEntries,
    TransfeeraExternalStatementFilterPageable,
    TransfeeraExternalStatementFilterRequest,
    TransfeeraStatementPageable
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import { ExternalStatementProps } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';

import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import { TransfeeraConstants } from 'shared/external/constants/transfeera-statment-constant';
import StatementError
    from 'shared/external/payment-accounts-strategy/statement/components/statement-error/statement-error';
import TransfeeraStatementDetailsModal
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-details-modal/transfeera-statement-details-modal';
import TransfeeraStatementErrorResponse
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-error-response/transfeera-statement-error-response';
import useTransfeeraStatementFilterChips
    from 'shared/external/payment-accounts-strategy/statement/components/transfeera/hooks/use-transfeera-statement-filter-chips';
import './transfeera-statement.scss';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement';

const TransfeeraStatement = (props: ExternalStatementProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const dispatch = useRootDispatch();

    const { externalStatement, error } = useExternalStatementPaymentInstitutionAccountState();
    const { resumedPaymentInstitutionAccount } = useResumePaymentInstitutionAccountState();

    const [filterActive, setFilterActive] = useState<TransfeeraExternalStatementFilterRequest>(defaultTransfeeraExternalStatementFilters);
    const [page, setPage] = useState<number>(TransfeeraConstants.STATEMENTS_PAGINATION_FIRST_INDEX);

    const { isTransfeeraStatementChipsActive, transfeeraStatementFilterChips } = useTransfeeraStatementFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const [modalFilter, setModalFilter] = useState<TransfeeraStatementFiltersType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(false);
    const [selectedExternalStatement, setSelectedExternalStatement] = useState<TransfeeraExternalStatementEntries | undefined>(undefined);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const filterPageable = useCallback((): TransfeeraExternalStatementFilterPageable => {
        const pageable: TransfeeraStatementPageable = {
            page,
        };

        const filter: TransfeeraExternalStatementFilterRequest = filterActive;

        return {
            pageable,
            filter,
            accountId: paymentInstitutionAccountId,
        };
    }, [filterActive, page, paymentInstitutionAccountId]);

    const handleExternalStatementRequest = useCallback(() => {
        if (!paymentInstitutionAccountId) return;
        dispatch(externalStatementPaymentInstitutionsAccountsRequest(filterPageable()));
    }, [dispatch, filterPageable, paymentInstitutionAccountId]);

    useEffect(() => {
        return () => {
            dispatch(externalStatementPaymentInstitutionsAccountsResetState());
        };
    }, [dispatch]);

    const {
        InfiniteScrollButton,
        items: externalStatementItems,
        resetInfiniteScrollListing,
        handleRefresh,
        isLoading,
    } = useTransfeeraStatementInfiniteScroll({
        handlePageChange,
        statusSelector: (state: IRootState) => state.getExternalStatement.status,
        dataSelector: (state: IRootState) => state.getExternalStatement.externalStatement,
        handleAction: handleExternalStatementRequest,
        setPage,
        currentPage: page,
    });

    const handleResetFilterActive = () => {
        setPage(TransfeeraConstants.STATEMENTS_PAGINATION_FIRST_INDEX);
        resetInfiniteScrollListing();
        setFilterActive(defaultTransfeeraExternalStatementFilters);
    };

    useEffect(() => {
        if (_.isEqual(filterActive, defaultTransfeeraExternalStatementFilters)) return;

        resetInfiniteScrollListing();
    }, [resetInfiniteScrollListing, filterActive]);

    const hasError: boolean = (!!error || !externalStatement?.isSuccessful) && !isLoading;


    return (
        <main className="transfeera-statement">

            <ContextRibbon />
            <section className="transfeera-statement__container">
                <StatementHeader resumedPaymentInstitutionAccount={resumedPaymentInstitutionAccount} handleTryAgain={handleRefresh} />
                <div className="transfeera-statement__header-balance">
                    <TransfeeraStatementFiltersButtons
                        filterActive={filterActive}
                        handleResetFilterActive={handleResetFilterActive}
                        setModalFilter={setModalFilter}
                        setOpenModalFilter={setOpenModalFilter}
                    />
                    <TransfeeraStatementShowBalance />
                </div>
                <ChipFilterActive filters={transfeeraStatementFilterChips} />

                {hasError ? (
                    <StatementError
                        errorComponent={<TransfeeraStatementErrorResponse error={error} externalStatement={externalStatement} />}
                        handleRetry={handleRefresh}
                    />
                ) : (
                    <div className="transfeera-statement__table">
                        <table
                            className={clsx('page-container--table', {
                                EMPTY_COLLECTION: !externalStatementItems.length,
                                CHIPS_ACTIVE: isTransfeeraStatementChipsActive,
                            })}
                        >
                            <thead>
                                <TransfeeraStatementTableHeader />
                            </thead>
                            <tbody>
                                <TransfeeraStatementList
                                    externalStatementItems={externalStatementItems}
                                    isLoading={isLoading}
                                    setSelectedExternalStatement={setSelectedExternalStatement}
                                />
                            </tbody>
                        </table>
                        <InfiniteScrollButton />
                    </div>
                )}
            </section>
            {modalFilter && (
                <TransfeeraStatementFiltersModal
                    filterActive={filterActive}
                    modalFilter={modalFilter}
                    onClose={() => setOpenModalFilter(false)}
                    openModal={openModalFilter}
                    resetInfiniteScrollListing={resetInfiniteScrollListing}
                    setFilterActive={setFilterActive}
                    setPage={setPage}
                    title={t(`${I18N_PREFIX}.filters.buttons.${modalFilter}`)}
                />
            )}
            {selectedExternalStatement && (
                <TransfeeraStatementDetailsModal
                    selectedExternalStatement={selectedExternalStatement}
                    onClose={() => setSelectedExternalStatement(undefined)}
                />
            )}
        </main>
    );
};

export default TransfeeraStatement;
