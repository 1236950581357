import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TransfeeraRequestDetails from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/transfeera-request-details';
import { TransfeeraBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/components/transfeera/transfeera-balance.model';
import { ExternalBalanceProps } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';
import useGetExternalBalanceStateById from 'shared/external/util/state-util';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';

import './transfeera-balance.scss';
import { Tooltip } from '@material-ui/core';

const I18N_PREFIX = 'payment-accounts-strategy';

const TransfeeraBalance = (props: ExternalBalanceProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const { balance, error, status } = useGetExternalBalanceStateById(paymentInstitutionAccountId);

    const [openModal, setIsOpenModal] = useState<boolean>(false);

    const transfeeraBalanceResponse: TransfeeraBalanceResponse | undefined = useMemo(() => {
        return JsonUtils.parseJsonString(balance?.response);
    }, [balance?.response]);

    const showBalance: boolean = !!balance && !!transfeeraBalanceResponse;
    const errorWhileParsing: boolean = !!balance && !transfeeraBalanceResponse;

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isLoading && <BalanceLoading />}
            <Tooltip title={t(`${I18N_PREFIX}.visualize-balance-details`).toString()} placement="bottom-start">
                <span className="transfeera-balance--title" onClick={() => setIsOpenModal(true)}>
                    {showBalance && <CurrencyFormatter value={transfeeraBalanceResponse?.value} prefix />}
                    {errorWhileParsing && <>{t(`${I18N_PREFIX}.unavailable-balance`)}</>}
                    {error && <div className="transfeera-balance--error" />}
                </span>
            </Tooltip>
            <TransfeeraRequestDetails openModal={openModal} onClose={() => setIsOpenModal(false)} {...props} />
        </>
    );
};

export default TransfeeraBalance;
