import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import { CompletePaymentTransactionFilterData } from 'model/payment-transaction';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseCompletePaymentFilterChipsProps {
    filterActive: CompletePaymentTransactionFilterData;
    setFilterActive: (value: CompletePaymentTransactionFilterData) => void;
}

const I18N_PREFIX = 'pages.scd.complete-payment-transactions.filter';

type CompletePaymentFilterRequestType = keyof Omit<CompletePaymentTransactionFilterData, 'originatorId' | 'companyGroupId'>;

const useCompletePaymentFilterChips = (props: UseCompletePaymentFilterChipsProps) => {
    const { filterActive, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: CompletePaymentTransactionFilterData) => {
            setFilterActive(value);
        },
        [setFilterActive]
    );

    const handleClearFilter: Record<CompletePaymentFilterRequestType, () => void> = useMemo(() => {
        return {
            amount: () => clearFilterSupport({ ...filterActive, amount: undefined }),
            date: () => clearFilterSupport({ ...filterActive, date: undefined }),
            favored: () => clearFilterSupport({ ...filterActive, favored: undefined }),
            originator: () => clearFilterSupport({ ...filterActive, originator: undefined }),
            mode: () => clearFilterSupport({ ...filterActive, mode: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: CompletePaymentFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const completePaymentFilterChips = useMemo(() => {
        const items: Record<CompletePaymentFilterRequestType, ChipFilterItem> = {
            amount: {
                isActive: !!filterActive.amount,
                onDelete: handleClearFilter['amount'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('amount')}
                        component={[
                            FormatterUtils.formatNumberToCurrency(filterActive.amount?.minValue, filterActive.amount?.minValue, true),
                            FormatterUtils.formatNumberToCurrency(filterActive.amount?.maxValue, filterActive.amount?.maxValue, true),
                        ].join(' - ')}
                    />
                ),
            },
            date: {
                isActive: !!filterActive.date,
                onDelete: handleClearFilter['date'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('date')}
                        component={
                            <>
                                {filterActive.date?.initialDate && (
                                    <>
                                        <DateFormatter date={filterActive.date?.initialDate} />
                                        {' - '}
                                    </>
                                )}
                                {filterActive.date?.finalDate && <DateFormatter date={filterActive.date?.finalDate} />}
                            </>
                        }
                    />
                ),
            },
            favored: {
                isActive: !!filterActive.favored,
                onDelete: handleClearFilter['favored'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('favored')} component={filterActive.favored?.name} />,
            },
            originator: {
                isActive: !!filterActive.originator,
                onDelete: handleClearFilter['originator'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('originator')} component={filterActive.originator?.name} />,
            },
            mode: {
                isActive: !!filterActive.mode,
                onDelete: handleClearFilter['mode'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('mode')}
                        component={t(`${I18N_PREFIX}.mode.options.${filterActive.mode}`)}
                    />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    return {
        completePaymentFilterChips,
    };
};

export default useCompletePaymentFilterChips;
