import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import ScdCompanyHeader from 'entities/company/company-header/company-header';
import CompanyFilterButtons from 'entities/company/components/company-filter-buttons/company-filter-buttons';
import CompanyList from 'entities/company/components/company-list/company-list';
import CompanyModalFilter, { CompanyModalFilterType } from 'entities/company/components/company-modal-filter/company-modal-filter';
import useScdCompanyFilterChips from 'entities/company/hooks/use-scd-company-filter-chips';
import { defaultCompanyFilterRequest, ScdCompany, ScdCompanyFilterPageable, ScdCompanyFilterRequest } from 'model/company';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listCompaniesRequest } from 'reducer/company/list-companies/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './company.scss';

export interface CompanyLocation {
    filterActive: ScdCompanyFilterRequest;
}

const I18N_PREFIX = 'pages.scd.company';

export const ScdCompanyPage = () => {
    const { t } = useTranslation();

    const [page, setPage] = useState<number>(0);

    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const [filterActive, setFilterActive] = useState<ScdCompanyFilterRequest>(defaultCompanyFilterRequest);
    const [modalFilter, setModalFilter] = useState<CompanyModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);

    const { isScdCompanyChipsActive, scdCompanyFilterChips } = useScdCompanyFilterChips({ filterActive, setFilterActive, setPage });

    const location = useLocation<CompanyLocation>();
    const { url } = useRouteMatch();
    const history = useHistory();

    const filterPageable = useCallback((): ScdCompanyFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const _filters: ScdCompanyFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    useEffect(() => {
        if (location?.state?.filterActive) {
            setFilterActive(location.state.filterActive);
        }
    }, [location?.state?.filterActive]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScrollLegacy<ScdCompany>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listCompanies.companies,
        statusSelector: (state: IRootState) => state.listCompanies.status,
        action: listCompaniesRequest,
        filterPageable,
        setPage,
    });

    const handleSort = (property: keyof ScdCompany, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive({ ...defaultCompanyFilterRequest });
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.company.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-company">
            <section className="scd-company__container">
                <header className="scd-company__header">
                    <div className="scd-company__header-container">
                        <div style={{ display: 'flex' }}>
                            <h2 className="scd-company__header-container--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                            <RefreshButton onClick={handleRefresh} />
                        </div>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </div>
                    <div className="scd-company__header--buttons-content">
                        <CompanyFilterButtons
                            filterActive={filterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                            handleResetFilterActive={handleResetFilterActive}
                        />
                    </div>
                    <ChipFilterActive filters={scdCompanyFilterChips} />
                </header>
                <div className="scd-company__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isScdCompanyChipsActive,
                        })}
                    >
                        <thead>
                            <ScdCompanyHeader
                                companies={items}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                            />
                        </thead>
                        <tbody>{<CompanyList company={items} isLoading={isLoading} />}</tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
                {modalFilter && (
                    <CompanyModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdCompanyPage;
