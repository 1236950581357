import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramResponse } from 'model/program';
import { updateProgramRequest, updateProgramError, updateProgramSuccess } from 'reducer/program/update-program/actions';
import { UpdateProgramTypes } from 'reducer/program/update-program/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateProgram(action: ReturnType<typeof updateProgramRequest>) {
    try {
        const result: AxiosResponse<ScdProgramResponse> = yield call(programApi.updateProgram, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateProgramError(mapped));
            return;
        }
        yield put(updateProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateProgramError(mapped));
    }
}

function* watchUpdateProgram() {
    yield takeLeading(UpdateProgramTypes.UPDATE_PROGRAM_REQUEST, handleUpdateProgram);
}

function* updateProgramSaga() {
    yield all([fork(watchUpdateProgram)]);
}

export default updateProgramSaga;
