import SquareButton from 'components/buttons/square-button/square-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import { useDetailProgramPolicyState, useRootDispatch } from 'reducer/hooks';
import { detailProgramPolicyRequest, detailProgramPolicyResetState } from 'reducer/program-policy/detail-program-policy/actions';
import './program-policy-detail.scss';

type ScdProgramPolicyDetailProps = RouteComponentProps<{ programPolicyId: string }>;

const I18N_PREFIX = 'pages.scd.program-policy.input';

const ScdProgramPolicyDetailPage = (props: ScdProgramPolicyDetailProps) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [programPolicyId] = useState<string>(props.match.params.programPolicyId);
    const { status, programPolicy } = useDetailProgramPolicyState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailProgramPolicyRequest(programPolicyId));
        return () => {
            dispatch(detailProgramPolicyResetState());
        };
    }, [dispatch, programPolicyId]);

    const handleUpdate = () => {
        return history.push(`${url}/edit`);
    };

    const hasDisbursementAmendment = programPolicy?.disbursementAmendmentPolicy
        ? t(`${I18N_PREFIX}.disbursementAmendmentPolicy.options.${programPolicy.disbursementAmendmentPolicy}`)
        : '-';

    return (
        <main className="scd-program-policy__detail">
            <ContextRibbon>
                <SquareButton label={t('entity.action.update')} onClick={handleUpdate} />
            </ContextRibbon>
            <div className="scd-program-policy--container">
                <header className="scd-program-policy--header">
                    <h2 className="scd-program-policy--header-title">{t('pages.scd.program-policy.detail.title')}</h2>
                </header>
                <div className="scd-program-policy__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.scdProgram.label`)} value={programPolicy?.scdProgram?.name} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.policyType.label`)}
                                value={
                                    programPolicy?.policyType ? t(`${I18N_PREFIX}.policyType.options.${programPolicy?.policyType}`) : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.operationApprovalPolicy.label`)}
                                value={
                                    programPolicy?.operationApprovalPolicy
                                        ? t(`${I18N_PREFIX}.operationApprovalPolicy.options.${programPolicy?.operationApprovalPolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.commissionPolicy.label`)}
                                value={
                                    programPolicy?.commissionPolicy
                                        ? t(`${I18N_PREFIX}.commissionPolicy.options.${programPolicy?.commissionPolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.cnabTemplate.label`)}
                                value={
                                    programPolicy?.cnabTemplate
                                        ? t(`${I18N_PREFIX}.cnabTemplate.options.${programPolicy?.cnabTemplate}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.paymentFavored.label`)}
                                value={
                                    programPolicy?.paymentFavoredPolicy
                                        ? t(`${I18N_PREFIX}.paymentFavored.options.${programPolicy?.paymentFavoredPolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.operationCollateralPolicy.label`)}
                                value={t(`${I18N_PREFIX}.operationCollateralPolicy.options.${programPolicy?.operationCollateralPolicy}`)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.paymentModePolicy.label`)}
                                value={
                                    programPolicy?.paymentModePolicy
                                        ? t(`${I18N_PREFIX}.paymentModePolicy.options.${programPolicy?.paymentModePolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.transferDispatchPolicy.label`)}
                                value={
                                    programPolicy?.transferDispatchModePolicy
                                        ? t(`${I18N_PREFIX}.transferDispatchPolicy.options.${programPolicy?.transferDispatchModePolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.disbursementAmendmentPolicy.label`)}
                                value={hasDisbursementAmendment}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdProgramPolicyDetailPage;
