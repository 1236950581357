import { Checkbox } from '@material-ui/core';
import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdImportedOperationsData, ScdImportedOperationToGetAllForBatchCreation } from 'model/imported-operation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.ccb-operation.table';

interface ScdCreateBatchOperationsHeaderProps {
    operations: ScdImportedOperationToGetAllForBatchCreation[];
    handleSort: (property: ImportedOperationsDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
    isSelectedAllCheckbox: boolean;
    setIsSelectedAllCheckbox: (value: boolean) => void;
    disabledCheckboxAll: boolean;
    setSelectedOperations: (value: Set<number>) => void;
}

export type ImportedOperationsDataSortableProperties = keyof ScdImportedOperationsData | 'companyIdentificationNumber';

export const ScdCreateBatchOperationsHeader = (props: ScdCreateBatchOperationsHeaderProps) => {
    const { t } = useTranslation();
    const {
        operations,
        handleSort,
        sortedProperty,
        sortOrder,
        disabledSort,
        isSelectedAllCheckbox,
        setIsSelectedAllCheckbox,
        disabledCheckboxAll,
        setSelectedOperations,
    } = props;

    const handleChangeAllCheckbox = useCallback(() => {
        if (disabledCheckboxAll) return;

        if (isSelectedAllCheckbox) {
            setIsSelectedAllCheckbox(false);
            setSelectedOperations(new Set([]));
            return;
        }

        setIsSelectedAllCheckbox(true);
        setSelectedOperations(new Set(operations.map(it => it.id)));
    }, [isSelectedAllCheckbox, operations, setIsSelectedAllCheckbox, setSelectedOperations, disabledCheckboxAll]);

    const mapSortable = (_property: ImportedOperationsDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (operations && operations.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
            <SortableColumn label={t(`${I18N_PREFIX}.receivedDate`).toUpperCase()} sortable={mapSortable('receivedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.approvalDate`).toUpperCase()} sortable={mapSortable('approvalDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('acceptedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.originator`).toUpperCase()} sortable={mapSortable('originator')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn
                label={<Checkbox checked={isSelectedAllCheckbox} disabled={disabledCheckboxAll} onChange={handleChangeAllCheckbox} />}
            />
        </tr>
    );
};

export default ScdCreateBatchOperationsHeader;
