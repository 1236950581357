import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import { OperationStatus } from 'model/enums/operation-status';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportedOperationToCreateBatchState } from 'reducer/hooks';

const I18N_PREFIX = 'pages.scd.ccb-operation';

interface CreateBatchOperationsListProps {
    isSelectedAllCheckbox: boolean;
    setSelectedOperations: (value: Set<number>) => void;
    selectedOperations: Set<number>;
}

export const CreateBatchOperationsList = (props: CreateBatchOperationsListProps) => {
    const { isSelectedAllCheckbox, selectedOperations, setSelectedOperations } = props;
    const { t } = useTranslation();

    const { operationsToCreateBatch } = useImportedOperationToCreateBatchState();

    const hasSelectedOperation = useCallback((id: number): boolean => selectedOperations.has(id), [selectedOperations]);

    const handleChange = (id: number) => {
        if (hasSelectedOperation(id)) {
            const filteredData: number[] = Array.from(selectedOperations).filter(operationId => operationId !== id);

            setSelectedOperations(new Set(filteredData));
            return;
        }

        setSelectedOperations(new Set([...selectedOperations, id]));
    };

    const verifyCheckedCheckbox = useCallback(
        (id: number, hasBatch: boolean): boolean => {
            if (hasBatch) return hasSelectedOperation(id);
            if (isSelectedAllCheckbox) return isSelectedAllCheckbox;

            return hasSelectedOperation(id);
        },
        [isSelectedAllCheckbox, hasSelectedOperation]
    );

    return (
        <>
            {operationsToCreateBatch?.operations?.length !== 0 ? (
                operationsToCreateBatch?.operations.map(
                    ({
                        id,
                        ccbNumber,
                        acceptedDate,
                        product,
                        status,
                        originator,
                        loanerIdentification,
                        hasBatch,
                        receivedDate,
                        approvalDate
                    }) => (
                        <tr
                            className={clsx('page-container--table-cell', {
                                isSelected: verifyCheckedCheckbox(id, hasBatch),
                            })}
                            key={id}
                        >
                            <td className="page-container--table-cell-title">{ccbNumber}</td>
                            <td className="page-container--table-cell-title">
                                {receivedDate ? <DateFormatter date={receivedDate} format={DateFormat.DATETIME_UTC_COMPLETE}/> : '-'}
                            </td>
                            <td className="page-container--table-cell-title">
                            {approvalDate ? <DateFormatter date={approvalDate} format={DateFormat.DATETIME_UTC_COMPLETE}/> : '-'}
                            </td>
                            <td className="page-container--table-cell-title">
                                {acceptedDate ? <DateFormatter date={acceptedDate} /> : '00/00/00'}
                            </td>
                            <td className="page-container--table-cell-title">
                                <span> {t(`${I18N_PREFIX}.product.options.${product}`).toUpperCase()}</span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <span>{originator ? originator : <> {t(`${I18N_PREFIX}.no-originator`)} </>}</span>
                            </td>
                            <td className="page-container--table-cell-title">
                                <span className="page-container--table-status">
                                    <span
                                        className={clsx('page-container--table-status', {
                                            ERROR: status === OperationStatus.ERROR,
                                            EXPORTED: status === OperationStatus.EXPORTED,
                                            NEW: status === OperationStatus.NEW,
                                            BATCHLESS: status === OperationStatus.BATCHLESS,
                                            ARCHIVED: status === OperationStatus.ARCHIVED,
                                        })}
                                    >
                                        {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                                    </span>
                                </span>
                            </td>

                            <td className="page-container--table-cell-icon">
                                <Checkbox
                                    checked={verifyCheckedCheckbox(id, hasBatch)}
                                    onChange={() => {
                                        handleChange(id);
                                    }}
                                />
                            </td>
                        </tr>
                    )
                )
            ) : (
                <div className="scd-ccb-operation-create-batch__table-empty-filter">
                    <span className="scd-ccb-operation-create-batch__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                </div>
            )}
        </>
    );
};

export default CreateBatchOperationsList;
