import { useTranslation } from 'react-i18next';
import { ExternalBalanceProps } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';

import './fallback.scss';

const I18N_PREFIX = 'payment-accounts-strategy';

const BalanceFallback = (props: ExternalBalanceProps) => {
    const { t } = useTranslation();
    return <span className="fallback--title">{t(`${I18N_PREFIX}.unsupported-technical-supplier`)}</span>;
};

export default BalanceFallback;
