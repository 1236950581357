import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { BankFilterRequest } from 'model/bank';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface BankFilterChipsProps {
    filterActive: BankFilterRequest;
    setFilterActive: (value: BankFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.bank.filter.buttons';

type BankFilterRequestType = keyof BankFilterRequest;

const useBankFilterChips = (props: BankFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: BankFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<BankFilterRequestType, () => void> = useMemo(() => {
        return {
            name: () => clearFilterSupport({ ...filterActive, name: undefined }),
            number: () => clearFilterSupport({ ...filterActive, number: undefined }),
            identification: () => clearFilterSupport({ ...filterActive, identification: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: BankFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );
    const bankFilterChips = useMemo(() => {
        const items: Record<BankFilterRequestType, ChipFilterItem> = {
            name: {
                isActive: Boolean(filterActive?.name),
                onDelete: handleClearFilter['name'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('name')} component={filterActive.name} />,
            },
            number: {
                isActive: Boolean(filterActive?.number),
                onDelete: handleClearFilter['number'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('number')} component={filterActive.number} />,
            },
            identification: {
                isActive: Boolean(filterActive?.identification),
                onDelete: handleClearFilter['identification'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('identification')} component={filterActive.identification} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle]);

    const isBankChipsActive: boolean = useMemo(() => {
        return bankFilterChips.some(Boolean);
    }, [bankFilterChips]);

    return {
        bankFilterChips,
        isBankChipsActive,
    };
};

export default useBankFilterChips;
