import { Chip, Tooltip } from '@material-ui/core';
import { ReactElement } from 'react';

import './chip-filter-active.scss';

export interface ChipFilterItem {
    isActive: boolean;
    renderItem: JSX.Element;
    icon?: ReactElement;
    onDelete: () => void;
}

interface ChipFilterActiveProps {
    filters: ChipFilterItem[];
}

const ChipFilterActive = (props: ChipFilterActiveProps) => {
    const { filters } = props;

    return (
        <div className="chip-filter-active">
            {filters.map(
                ({ isActive, renderItem, icon, onDelete }, index) =>
                    isActive && (
                        <Tooltip title={renderItem} key={index}>
                            <Chip label={renderItem} icon={icon} onDelete={onDelete} />
                        </Tooltip>
                    )
            )}
        </div>
    );
};

export default ChipFilterActive;
