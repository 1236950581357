import { ScdCompany } from 'model/company';
import { ScdOrganizationSimple } from 'model/organization';

export interface ScdCompanyGroup {
    id?: string;
    name?: string;
    organization?: ScdOrganizationSimple;
    company?: ScdCompany;
}

export const defaultScdCompanyGroup = {
    id: undefined,
    name: undefined,
    organization: undefined,
    company: undefined,
};

export interface ScdCompanyGroupSimple {
    id?: string;
    name?: string;
    policyId?: number;
}

export interface CompanyGroupFilterRequest {
    groupName?: string;
    organizationId?: string;
    organizationName?: string;
    companyId?: string;
    companyName?: string;
}

export const defaultCompanyGroupFilterRequest: CompanyGroupFilterRequest = {
    groupName: undefined,
    organizationId: undefined,
    organizationName: undefined,
    companyId: undefined,
    companyName: undefined
};
