import { ScdTaxSimple } from 'model/tax';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CreateTaxTypes {
    CREATE_TAX_REQUEST = '@@tax-create/CREATE_TAX_REQUEST',
    CREATE_TAX_SUCCESS = '@@tax-create/CREATE_TAX_SUCCESS',
    CREATE_TAX_ERROR = '@@tax-create/CREATE_TAX_ERROR',
    CREATE_TAX_RESET_STATE = '@@tax-create/CREATE_TAX_RESET_STATE',
}

export interface CreateTaxState extends BaseStoreState {
    readonly tax?: ScdTaxSimple;
}
