import { BatchDetailWithProgram } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailBatchWithProgramTypes {
    DETAIL_BATCH_WITH_PROGRAM_REQUEST = '@@batch-detail-with-program/DETAIL_BATCH_WITH_PROGRAM_REQUEST',
    DETAIL_BATCH_WITH_PROGRAM_SUCCESS = '@@batch-detail-with-program/DETAIL_BATCH_WITH_PROGRAM_SUCCESS',
    DETAIL_BATCH_WITH_PROGRAM_ERROR = '@@batch-detail-with-program/DETAIL_BATCH_WITH_PROGRAM_ERROR',
    DETAIL_BATCH_WITH_PROGRAM_RESET = '@@batch-detail-with-program/DETAIL_BATCH_WITH_PROGRAM_RESET',
}

export interface DetailBatchWithProgramState extends BaseStoreState {
    readonly batchWithProgram?: BatchDetailWithProgram;
}
