import { ScdOrganizationList } from 'model/organization';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListOrganizationsTypes {
    LIST_ORGANIZATIONS_REQUEST = '@@organizations-list/LIST_ORGANIZATIONS_REQUEST',
    LIST_ORGANIZATIONS_SUCCESS = '@@organizations-list/LIST_ORGANIZATIONS_SUCCESS',
    LIST_ORGANIZATIONS_ERROR = '@@organizations-list/LIST_ORGANIZATIONS_ERROR',
    LIST_ORGANIZATIONS_RESET_STATE = '@@organizations-list/LIST_ORGANIZATIONS_RESET_STATE',
}

export interface ListOrganizationsState extends BaseStoreState {
    readonly organizations?: Page<ScdOrganizationList>;
}
