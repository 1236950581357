export enum FileMime {
    BPM = 'BPM',
    GIF = 'GIF',
    ICO = 'ICO',
    JPEG = 'JPEG',
    JPG = 'JPG',
    PNG = 'PNG',
    SVG = 'SVG',
    TIF = 'TIF',
    TIFF = 'TIFF',
    WEBP = 'WEBP',

    // application
    PDF = 'PDF',

    // document
    MS_WORD = 'MS_WORD',
    DOCX = 'DOCX',

    // spreadsheet
    MS_EXCEL = 'MS_EXCEL',
    XLSX = 'XLSX',

    // text
    CSV = 'CSV',
    TXT = 'TXT',

    // cnab
    CNAB_REM = 'CNAB_REM',
}
