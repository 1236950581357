import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdProgramPolicy from 'entities/program-policy/program-policy';
import ScdProgramPolicyCreateUpdate from 'entities/program-policy/program-policy-create-update/program-policy-create-update';
import ScdProgramPolicyDetail from 'entities/program-policy/program-policy-detail/program-policy-detail';

interface MatchParams {
    url: string;
}

const ProgramPolicyRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdProgramPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:programPolicyId`} component={ScdProgramPolicyDetail} />
        <PrivateRoute exact path={`${match.url}/:programPolicyId/edit`} component={ScdProgramPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}`} component={ScdProgramPolicy} />
    </Switch>
);

export default ProgramPolicyRoutes;
