import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { OriginatorToGetTransaction } from 'model/originator';
import { useTranslation } from 'react-i18next';

interface OriginatorCardProps {
    originator: OriginatorToGetTransaction | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const OriginatorCard = (props: OriginatorCardProps) => {
    const { originator } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.originator`)}>
            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.originator`} value={originator?.name} />
        </ReadOnlyCard>
    );
};

export default OriginatorCard;
