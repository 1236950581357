import { OriginatorModalFilterType } from 'entities/originator/components/originator-modal-filter/originator-modal-filter';
import { ScdOriginatorFilterRequest } from 'model/originator';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterButton from 'components/buttons/filter-button/filter-button';
import React from 'react';
import { valuesOfObject } from 'shared/util/object-utils';
import FilterListIcon from '@material-ui/icons/FilterList';

interface OriginatorFilterButtonsProps {
    filterActive: ScdOriginatorFilterRequest;
    setIsOpenModalFilter: (value: boolean) => void;
    setModalFilter: (value: OriginatorModalFilterType | undefined) => void;
    handleResetFilterActive: () => void;
}

const I18N_PREFIX = 'pages.scd.originator.filter.buttons';

const OriginatorFilterButtons = (props: OriginatorFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setModalFilter, setIsOpenModalFilter } = props;

    const { t } = useTranslation();

    const ArrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isFilterActive: boolean = valuesOfObject({ ...filterActive }).some(it => !!it);

    const handleModalFilter = (value: OriginatorModalFilterType) => () => {
        setIsOpenModalFilter(true);
        setModalFilter(value);
    };

    return (
        <>
            <div className="scd-originator__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFilterActive}
                    onClick={handleModalFilter('filters')}
                />
            </div>
            <div className="scd-originator__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.name`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.name}
                    onClick={handleModalFilter('name')}
                />
            </div>
            <div className="scd-originator__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.identification`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.identification}
                    onClick={handleModalFilter('identification')}
                />
            </div>
            <div className="scd-originator__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.companyId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.companyId}
                    onClick={handleModalFilter('companyId')}
                />
            </div>
            <div className="scd-originator__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.paymentInstitutionId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.paymentInstitutionId}
                    onClick={handleModalFilter('paymentInstitutionId')}
                />
            </div>
            {isFilterActive && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-originator__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default OriginatorFilterButtons;
