import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'components/inputs/number-mask';

const percentMask = createNumberMask({
    prefix: '',
    suffix: ' %',
    integerLimit: 3,
    includeThousandsSeparator: false,
});

export const PercentMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={percentMask} guide={false} />;
};

export default PercentMask;
