import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CnabBatchReportState, CnabBatchReportTypes } from 'reducer/cnab/cnab-batch-report/types';
import { Reducer } from 'redux';

const initialState: CnabBatchReportState = {
    status: HttpRequestStatus.NOOP,
    cnabBatchReport: undefined,
    error: undefined,
};

const reducer: Reducer<CnabBatchReportState> = (state = initialState, action): CnabBatchReportState => {
    switch (action.type) {
        case CnabBatchReportTypes.CNAB_BATCH_REPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CnabBatchReportTypes.CNAB_BATCH_REPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, cnabBatchReport: action.payload, error: undefined };
        }
        case CnabBatchReportTypes.CNAB_BATCH_REPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CnabBatchReportTypes.CNAB_BATCH_REPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as cnabBatchReportReducer };
