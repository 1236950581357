import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';

interface PaymentInstitutionsAccountsMoreOptionsMenuItemsProps {
    selectedPaymentInstitutionAccount: PaymentInstitutionAccountToGetAll | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.table.menu';

const PaymentInstitutionsAccountsMoreOptionsMenuItems = (props: PaymentInstitutionsAccountsMoreOptionsMenuItemsProps) => {
    const { selectedPaymentInstitutionAccount } = props;
    return (
        <>
            {selectedPaymentInstitutionAccount?.type === PaymentInstitutionAccountType.DIGITAL && (
                <>
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-transfers`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/transfers/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-statement`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/statements/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.internal-repayment`}
                        link={`/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/internal-repayment/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.convert`}
                        link={`/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/convert/`}
                    />
                </>
            )}
             {selectedPaymentInstitutionAccount?.type === PaymentInstitutionAccountType.EXCLUSIVE && (
                <>
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-transfers`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/transfers/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-statement`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/statements/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.internal-repayment`}
                        link={`/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/internal-repayment/`}
                    />
                </>
            )}
            {selectedPaymentInstitutionAccount?.type === PaymentInstitutionAccountType.GENERAL && (
                <>
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-transfers`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/transfers/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.visualize-statement`}
                        link={`/external/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/statements/`}
                    />
                    <RedirectMenuItem
                        titleKey={`${I18N_PREFIX}.internal-repayment`}
                        link={`/payment-institutions-accounts/${selectedPaymentInstitutionAccount?.id}/internal-repayment/`}
                    />
                </>
            )}
        </>
    );
};

export default PaymentInstitutionsAccountsMoreOptionsMenuItems;
