import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailCompanyItemState, useRootDispatch } from 'reducer/hooks';
import { detailCompanyItemRequest, detailCompanyItemResetState } from 'reducer/company-item/detail-company-item/actions';
import './company-item-detail.scss';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';
import PercentageFormatter from 'components/formatter/percentage/percentage-formatter';

type ScdCompanyItemDetailProps = RouteComponentProps<{ companyItemId: string }>;

const I18N_PREFIX = 'pages.scd.company-item.input';

const ScdCompanyItemDetailPage = (props: ScdCompanyItemDetailProps) => {
    const { t } = useTranslation();
    const [companyItemId] = useState<string>(props.match.params.companyItemId);
    const { status, companyItem } = useDetailCompanyItemState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailCompanyItemRequest(companyItemId));
        return () => {
            dispatch(detailCompanyItemResetState());
        };
    }, [dispatch, companyItemId]);

    return (
        <main className="scd-company-item__detail">
            <ContextRibbon />
            <div className="scd-company-item--container">
                <header className="scd-company-item--header">
                    <h2 className="scd-company-item--header-title">{t('pages.scd.company-item.detail.title')}</h2>
                </header>
                <div className="scd-company-item__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.company.name.label`)} value={companyItem?.company?.name} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.company.identification.label`)}
                                value={
                                    <IdentificationFormatter value={companyItem?.company?.identification} type={PersonType.CORPORATION} />
                                }
                            />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.companyGroup.label`)} value={companyItem?.companyGroup?.name} />
                            {companyItem?.commissionTax && (
                                <ReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.commisionTax.label`)}
                                    value={<PercentageFormatter value={companyItem?.commissionTax} />}
                                />
                            )}
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.type.label`)}
                                value={String(t(`${I18N_PREFIX}.type.options.${companyItem?.type}`))}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.status.label`)}
                                value={String(t(`${I18N_PREFIX}.status.options.${companyItem?.status}`))}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdCompanyItemDetailPage;
