import CloseButton from 'components/buttons/close-button/close-button';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { LIST_MODAL_WINDOW_SIZE, MODAL_SEARCH_HEIGHT, MODAL_SEARCH_WIDTH, SEARCH_ITEM_SIZE, SEARCH_PAGE_SIZE_DEFAULT } from 'components/modals/constants';
import { debounce } from 'lodash';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OriginatorsByPaymentInstitutionToSearch } from 'model/payment-institution-account';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Modal } from 'reactstrap';
import { useRootDispatch, useSearchOriginatorsByPaymentAccountState } from 'reducer/hooks';
import { searchOriginatorsByPaymentInstitutionAccountRequest, searchOriginatorsByPaymentInstitutionAccountResetState } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/actions';

import { Pageable } from 'services/pageable';
import { v4 as uuidV4 } from 'uuid';
import './payment-institution-account-search-originator-modal.scss';

export interface ModalSearchProps {
    onSelect: (value:OriginatorsByPaymentInstitutionToSearch ) => void;
    children: (handleOpen: () => void) => React.ReactNode;
    paymentInstitutionAccountId: number;
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

const ModalSearchOriginatorByPaymentInstitutionAccount = (props: ModalSearchProps) => {
    const {
        children,
        onSelect,
        paymentInstitutionAccountId
    } = props;
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [valueSearch, setValueSearch] = useState<string>('');
    const {status, originatorsByPaymentInstitutionAccount: data } = useSearchOriginatorsByPaymentAccountState();

    const [filteredData, setFilteredData] = useState(data?.content ?? []);

    const isItemLoaded = ({ index }: any) => !!filteredData[index];
    const dataCount: number = filteredData?.length ?? 0;

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const onHandleScroll = (event: ListOnScrollProps) => {
        const clientHeight = document.querySelector('.select-option-modal-div-style')?.scrollHeight;

        const bottom = Number(clientHeight) - LIST_MODAL_WINDOW_SIZE - event.scrollOffset === 0;

        if (!bottom) return;

        requestNextPage();
    };

    const searchPageable = useCallback((search: string, paymentInstitutionAccountId: number) => {
        const _pageable: Pageable = { page: 0, size: SEARCH_PAGE_SIZE_DEFAULT };
        const request = { search: search, pageable: _pageable, paymentInstitutionAccountId };
        return request;
    }, []);

    const requestNextPage = () => {
        const _pageable: Pageable = { page: (data?.number ?? 0) + 1, size: SEARCH_PAGE_SIZE_DEFAULT };

        const request = { search: valueSearch, pageable: _pageable , paymentInstitutionAccountId};
        if (data && !data.last) {
            dispatch(searchOriginatorsByPaymentInstitutionAccountRequest(request));
        }
    };

    const loadMoreItems = (_startIndex: number, _stopIndex: number): any => {
        return;
    };

    const debounceSearch = useRef(
        debounce((value, paymentInstitutionAccountId) => {
            dispatch(searchOriginatorsByPaymentInstitutionAccountRequest(searchPageable(value, paymentInstitutionAccountId)));
        }, 500)
    );

    const handleOnChange = (searchInput: string) => {
        setValueSearch(searchInput);
        setFilteredData([]);

        if (!searchInput.length) return;

        debounceSearch.current(searchInput, paymentInstitutionAccountId);
    };

    const handleClick = useMemo(() => {
        return () => {
            setOpenModal(true);
        };
    }, []);

    const handleSelect = (value: OriginatorsByPaymentInstitutionToSearch) => {
        onSelect(value);
        setOpenModal(false);
    };

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            const newData = data?.content ?? [];
            const newPage = data?.number === 0;
            setFilteredData(oldData => [...(!newPage && oldData ? oldData : []), ...newData]);
        }
    }, [status, data]);

    useEffect(() => {
        if (valueSearch.length === 0 && openModal) {
            debounceSearch.current('', paymentInstitutionAccountId);
        }
    }, [dispatch, openModal, searchPageable, valueSearch, paymentInstitutionAccountId]);

    useEffect(() => {

        return () => {
            dispatch(searchOriginatorsByPaymentInstitutionAccountResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (openModal) return;
        setFilteredData([]);
    }, [openModal]);

    return (
        <>
            {openModal && (
                <Modal isOpen>
                    <div className="select-option-modal-content-container">
                        <div className="select-option-modal-content-header">
                            <label className="select-option-modal-title-style"> {t(`${I18N_PREFIX}.inputs.originator.label`)} </label>
                            <CloseButton onClick={() => setOpenModal(false)} />
                        </div>
                        <div className="select-option-modal-search-input-container">
                            <SimpleSearchInput
                                label={t(`${I18N_PREFIX}.inputs.originator.placeholder`)} 
                                value={valueSearch}
                                placeholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)} 
                                onChange={handleOnChange}
                            />
                        </div>
                        <AutoSizer defaultHeight={MODAL_SEARCH_HEIGHT} defaultWidth={MODAL_SEARCH_WIDTH}>
                            {({ height, width }) => {
                                return (
                                    <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={dataCount}>
                                        {({ onItemsRendered, ref }) => (
                                            <FixedSizeList
                                                onItemsRendered={onItemsRendered}
                                                className="select-option-modal-div-style"
                                                ref={ref}
                                                height={height}
                                                itemKey={itemKey}
                                                itemCount={dataCount}
                                                itemSize={SEARCH_ITEM_SIZE}
                                                width={width}
                                                itemData={filteredData}
                                                onScroll={onHandleScroll}
                                            >
                                                {({ index, style }) => {
                                                    return (
                                                        <div
                                                            key={uuidV4()}
                                                            className="select-option-modal-item-container"
                                                            style={{
                                                                ...style,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            }}
                                                            onClick={() => {
                                                                handleSelect(filteredData[index]);
                                                            }}
                                                        >
                                                            {filteredData[index].name ?? ''}
                                                        </div>
                                                    );
                                                }}
                                            </FixedSizeList>
                                        )}
                                    </InfiniteLoader>
                                );
                            }}
                        </AutoSizer>
                        {filteredData.length !== 0 && <div className="select-option-modal-div-style" />}
                    </div>
                </Modal>
            )}
            {children(handleClick)}
        </>
    );
};
export default ModalSearchOriginatorByPaymentInstitutionAccount;
