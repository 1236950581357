import { AxiosResponse } from 'axios';
import FormatterUtils from 'components/formatter/formatter-utils';
import { BatchSortPageable } from 'entities/batch/batch-detail-operations/batch-operations-detail';
import { ScdDisbursementReport } from 'model/disbursement-report';
import {
    ImportedOperationsToBatchCreation,
    ListOperationsRequest,
    OperationFareReports,
    ScdImportedOperationBatch,
    ScdImportedOperationDetail,
    ScdImportedOperationDisbursementReport,
    ScdImportedOperationDocumentRequest,
    ScdImportedOperationErrorsPending,
    ScdImportedOperationFareAmountRepotyBodyRequest,
    ScdImportedOperationMonthlyProcessedReport,
    ScdImportedOperationReexport,
    ScdImportedOperationSimple,
    ScdImportedOperationToCreateBatchRequest,
    ScdImportedOperationsArchiveManyRequest,
    ScdImportedOperationsArchiveManyResponse,
    ScdImportedOperationsData,
    ScdImportedOperationsReexportAllRequest
} from 'model/imported-operation';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

const importedOperation = () => {
    const disbursementReportRequest = (request: ScdDisbursementReport): Promise<AxiosResponse<ScdImportedOperationDisbursementReport>> => {
        return api.patch<ScdImportedOperationDisbursementReport>('api/imported-operations/disbursement-report', request);
    };

    const batchRequest = (request: BatchSortPageable): Promise<AxiosResponse<Page<ScdImportedOperationBatch>>> => {
        return api.get<Page<ScdImportedOperationBatch>>(`api/imported-operations/batch/${request.batchId}`, {
            params: { ...request.pageable },
        });
    };

    const reexport = (operationId: number): Promise<AxiosResponse<ScdImportedOperationReexport>> => {
        return api.patch<ScdImportedOperationReexport>(`api/imported-operations/${operationId}/re-export`);
    };

    const reexportAll = (request: ScdImportedOperationsReexportAllRequest): Promise<AxiosResponse<ScdImportedOperationReexport[]>> => {
        return api.patch<ScdImportedOperationReexport[]>(`api/imported-operations/re-export`, request, { timeout: 0 });
    };

    const listOperations = (request: ListOperationsRequest): Promise<AxiosResponse<Page<ScdImportedOperationsData>>> => {
        const { pageable, filter } = request;

        const _requestFilters = [
            filter?.scdProgram?.id ? `programId=${filter?.scdProgram?.id}` : undefined,
            filter?.originator ? `originator=${filter?.originator}` : undefined,
            filter?.type ? `type=${filter.type}` : undefined,
            filter?.ccbNumber ? `ccbNumber=${filter?.ccbNumber}` : undefined,
            filter?.createdDate ? `createdDate=${filter.createdDate}` : undefined,
            filter?.identification ? `identification=${filter.identification}` : undefined,
            filter.batchId ? `batchId=${filter.batchId}` : undefined,
            filter?.acceptedDate?.start && filter?.acceptedDate?.end
                ? `acceptedDate.start=${filter?.acceptedDate.start}&acceptedDate.end=${filter?.acceptedDate.end}`
                : undefined,
            !!filter?.status?.length ? `status=${filter.status.filter(it => !!it).join(',')}` : undefined,
            `todayOnly=${filter?.todayOnly}`,
            filter.withBatchOnly === true && `withBatchOnly=true`,
            filter.withBatchOnly === false && `withBatchOnly=false`,
            filter.handleErrorMode === true && `handleErrorMode=true`,
            filter.handleErrorMode === false && `handleErrorMode=false`,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdImportedOperationsData>>(`api/imported-operations?${_requestFilters}`, {
            params: { ...pageable },
        });
    };

    const archiveMany = (request: ScdImportedOperationsArchiveManyRequest): Promise<AxiosResponse<ScdImportedOperationsArchiveManyResponse>> => {
        return api.patch<ScdImportedOperationsArchiveManyResponse>(`${BASE_URL_ADMIN}/imported-operations/archive`, request);
    };

    const detail = (operationId: number): Promise<AxiosResponse<ScdImportedOperationDetail>> => {
        return api.get<ScdImportedOperationDetail>(`api/imported-operations/${operationId}`);
    };

    const document = (request: ScdImportedOperationDocumentRequest): Promise<AxiosResponse<Uint8Array>> => {
        const { operationId, type } = request;

        return api.get<Uint8Array>(`api/imported-operations/${operationId}/documents/${type}/download`, { responseType: 'arraybuffer' });
    };

    const toCreateBatch = (
        request: ScdImportedOperationToCreateBatchRequest
    ): Promise<AxiosResponse<ImportedOperationsToBatchCreation>> => {
        const { filter, sort } = request;

        return api.get<ImportedOperationsToBatchCreation>(
            `api/imported-operations/to-create-batch?programId=${filter.programId}${filter.createdDate ? `&createdDate=${filter.createdDate}` : ''
            }${sort ? `&sort=${sort}` : ''}`
        );
    };

    const getErrorsPending = (): Promise<AxiosResponse<ScdImportedOperationErrorsPending>> => {
        return api.get<ScdImportedOperationErrorsPending>(`${BASE_URL_ADMIN}/imported-operations/summarize`);
    };

    const retryPreprocess = (operationId: number): Promise<AxiosResponse<ScdImportedOperationSimple>> => {
        return api.post<ScdImportedOperationSimple>(`${BASE_URL_ADMIN}/imported-operations/${operationId}/retry/preprocess`);
    };

    const retryDefinePayment = (operationId: number): Promise<AxiosResponse<ScdImportedOperationSimple>> => {
        return api.post<ScdImportedOperationSimple>(`${BASE_URL_ADMIN}/imported-operations/${operationId}/retry/define-payment`);
    };

    const retryDefineFlow = (operationId: number): Promise<AxiosResponse<ScdImportedOperationSimple>> => {
        return api.post<ScdImportedOperationSimple>(`${BASE_URL_ADMIN}/imported-operations/${operationId}/retry/define-flow`);
    };

    const archiveOne = (operationId: number): Promise<AxiosResponse<ScdImportedOperationSimple>> => {
        return api.patch<ScdImportedOperationSimple>(`${BASE_URL_ADMIN}/imported-operations/${operationId}/archive`)
    }

    const retryProcessAutoPayment = (operationId: number): Promise<AxiosResponse<ScdImportedOperationSimple>> => {
        return api.post<ScdImportedOperationSimple>(`${BASE_URL_ADMIN}/imported-operations/${operationId}/retry/auto-payment`)
    }

    const generateMonthlyProcessedOperationsReport = (yearMonth: string): Promise<AxiosResponse<ScdImportedOperationMonthlyProcessedReport[]>> => {
        return api.get<ScdImportedOperationMonthlyProcessedReport[]>(`${BASE_URL_ADMIN}/imported-operations/reports/processed/${yearMonth}`)
    }

    const recoverFareAmountReport = (params: ScdImportedOperationFareAmountRepotyBodyRequest): Promise<AxiosResponse<OperationFareReports>> => {
        return api.get<OperationFareReports>(`${BASE_URL_ADMIN}/imported-operations/reports/fare?originatorId=${params.originatorId}&batchPeriod.start=${FormatterUtils.formatDateToLocalDate(params?.batchPeriod?.start)}&batchPeriod.end=${FormatterUtils.formatDateToLocalDate(params?.batchPeriod?.end)}`)
    }


    return {
        disbursementReportRequest,
        batchRequest,
        reexport,
        reexportAll,
        listOperations,
        archiveMany,
        detail,
        document,
        toCreateBatch,
        getErrorsPending,
        retryPreprocess,
        retryDefinePayment,
        retryDefineFlow,
        archiveOne,
        retryProcessAutoPayment,
        generateMonthlyProcessedOperationsReport,
        recoverFareAmountReport
    };
};

export default importedOperation();
