import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramSimple } from 'model/program';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum SearchProgramsTypes {
    SEARCH_PROGRAMS_REQUEST = '@@programs-search/SEARCH_PROGRAMS_REQUEST',
    SEARCH_PROGRAMS_SUCCESS = '@@programs-search/SEARCH_PROGRAMS_SUCCESS',
    SEARCH_PROGRAMS_ERROR = '@@programs-search/SEARCH_PROGRAMS_ERROR',
    SEARCH_PROGRAMS_RESET_STATE = '@@programs-search/SEARCH_PROGRAMS_RESET_STATE',
}

export interface SearchProgramsState extends BaseStoreState {
    readonly programs?: Page<ScdProgramSimple>;
}
