import { MenuItem, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import { BANK_SVG_URL } from 'config/constants';
import { ScdPaymentTransactionInSeriesCreate } from 'entities/payment-transaction/payment-transaction-in-series/payment-transaction-in-series';
import { validateScdNewPaymentTransaction } from 'entities/payment-transaction/validation/new-payment-transaction-validation';
import invalidBankSvg from 'images/bank-default.svg';
import { PersonType } from 'model/enums/person-type';
import { ScdFavoredBankAccount } from 'model/favored-bank-account';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import {
    searchFavoredBankAccountsRequest,
    searchFavoredBankAccountsResetState,
} from 'reducer/favored-bank-account/search-favored-bank-accounts/actions';
import { useRootDispatch, useSearchFavoredBankAccountsState } from 'reducer/hooks';
import { searchPaymentFavoredRequest } from 'reducer/payment-favored/search-payment-favored/actions';

const I18N_PREFIX = 'pages.scd.payment-transaction.new-transaction';

interface CreatePaymentTransactionInSeriesProps {
    defaultPaymentTransactionValue: ScdPaymentTransactionInSeriesCreate;
    newPaymentTransactionsList: ScdPaymentTransactionInSeriesCreate[];
    setNewPaymentTransactionsList: (value: ScdPaymentTransactionInSeriesCreate[]) => void;
    index: number;
    disabledButton: boolean;
}

const CreatePaymentTransactionInSeries = (props: CreatePaymentTransactionInSeriesProps) => {
    const { defaultPaymentTransactionValue, newPaymentTransactionsList, setNewPaymentTransactionsList, index, disabledButton } = props;

    const [newPaymentTransaction, setNewPaymentTransaction] = useState<ScdPaymentTransactionInSeriesCreate | undefined>(
        defaultPaymentTransactionValue
    );

    const [modalDescription, setModalDescription] = useState<boolean>(false);

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { favoredBankAccounts } = useSearchFavoredBankAccountsState();

    const handleChange = (value: Partial<ScdPaymentTransactionInSeriesCreate>) => {
        setNewPaymentTransaction({ ...newPaymentTransaction, ...value });
    };

    const handleResetInputs = useCallback(() => {
        setNewPaymentTransaction(defaultPaymentTransactionValue);
    }, [defaultPaymentTransactionValue]);

    const handleSelectFavored = (favored: ScdPaymentFavoredSearch | undefined) => {
        handleChange({
            companyId: favored?.companyId,
            bankAccountId: undefined,
            favoredName: favored?.name,
            favoredIdentification: favored?.identification,
        });
        handleSelectBankAccount(
            favored?.companyId! || favored?.personId!,
            favored?.companyId ? PersonType.CORPORATION : PersonType.INDIVIDUAL
        );
    };

    const handleSelectBankAccount = (id: number, type: PersonType) => {
        if (!id) return;

        dispatch(searchFavoredBankAccountsRequest({ id, type }));
    };

    const handleSave = (value: ScdPaymentTransactionInSeriesCreate) => {
        const _bankAccountSelected: ScdFavoredBankAccount[] | undefined = favoredBankAccounts
            ?.filter(({ id }) => Number(id) === value.bankAccountId)
            .map(value => value);

        const _value: ScdPaymentTransactionInSeriesCreate = {
            ...value,
            bankAccountSelected: _bankAccountSelected ? _bankAccountSelected[0] : undefined,
        };

        setNewPaymentTransactionsList([...newPaymentTransactionsList, _value]);
        handleResetInputs();
    };

    useEffect(() => {
        return () => {
            searchFavoredBankAccountsResetState();
        };
    }, []);

    return (
        <>
            <div className="scd-payment-transaction-in-series__content-new-transaction">
                <span style={{ width: '30px' }}>
                    <strong> {String(index)} </strong>
                </span>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-value">
                    <SimpleCurrencyInput
                        label={t(`${I18N_PREFIX}.amount`)}
                        placeholder={t(`${I18N_PREFIX}.amount`)}
                        onChange={amount => handleChange({ amount })}
                        value={newPaymentTransaction?.amount}
                        externalUpdate
                        autoFocus
                        disabled={disabledButton}
                    />
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-favored">
                    <div className="scd-payment-transaction-in-series__content-new-transaction__field-favored-content">
                        <ListSearchInput<ScdPaymentFavoredSearch>
                            action={searchPaymentFavoredRequest}
                            itemSize={SEARCH_ITEM_SIZE_LARGE}
                            onSelect={handleSelectFavored}
                            renderItem={favored => `${favored?.identification} ${favored?.name}`}
                            statusSelector={(state: IRootState) => state.searchPaymentFavored.status}
                            dataSelector={(state: IRootState) => state.searchPaymentFavored.paymentFavored}
                            label={t(`${I18N_PREFIX}.favored`)}
                            placeholder={t(`${I18N_PREFIX}.favored`)}
                            disabled={!newPaymentTransaction?.amount || disabledButton}
                            value={
                                newPaymentTransaction?.favoredIdentification && newPaymentTransaction?.favoredName
                                    ? `${newPaymentTransaction?.favoredIdentification} ${newPaymentTransaction?.favoredName}`
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-account">
                    <SimpleSelectInput
                        label={t(`${I18N_PREFIX}.account`)}
                        mapperFromString={_value => _value ?? ''}
                        value={newPaymentTransaction?.bankAccountId ?? ''}
                        placeholder={t(`${I18N_PREFIX}.account`)}
                        onChange={bankAccountId => handleChange({ bankAccountId: Number(bankAccountId) })}
                        disabled={
                            favoredBankAccounts?.length === 0 ||
                            !newPaymentTransaction?.companyId ||
                            !newPaymentTransaction?.amount ||
                            disabledButton
                        }
                        externalUpdate
                    >
                        {favoredBankAccounts?.map(
                            ({ accountNumber, bankIdentification, bankName, agencyNumber, id, pixKey, agencyDigit, accountDigit }) => {
                                return (
                                    <MenuItem value={id} key={id}>
                                        {pixKey ? (
                                            <>
                                                <span> {pixKey} </span>
                                            </>
                                        ) : (
                                            <>
                                                {bankIdentification && (
                                                    <span style={{ marginRight: '4px' }}>
                                                        <Tooltip title={bankName}>
                                                            <img
                                                                src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                                                                alt={bankName ?? ''}
                                                                width={20}
                                                                height={20}
                                                                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                                    ((e.target as HTMLImageElement).src = invalidBankSvg)
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </span>
                                                )}

                                                <span style={{ marginRight: '2px' }}>
                                                    {agencyDigit ? `${agencyNumber}-${agencyDigit}` : agencyNumber}
                                                </span>
                                                <span style={{ marginRight: '2px' }}>
                                                    {accountDigit ? `${accountNumber}-${accountDigit}` : accountNumber}
                                                </span>
                                            </>
                                        )}
                                    </MenuItem>
                                );
                            }
                        )}
                    </SimpleSelectInput>
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__icons ">
                    <Tooltip title={String(t('tooltips.description'))}>
                        <div
                            className={clsx(
                                'scd-payment-transaction-in-series__content-new-transaction__icons-description scd-payment-transaction-in-series__content-new-transaction__icons-icon',
                                {
                                    active: newPaymentTransaction?.description,
                                    disabled: !validateScdNewPaymentTransaction(newPaymentTransaction),
                                }
                            )}
                            onClick={() =>
                                validateScdNewPaymentTransaction(newPaymentTransaction) ? setModalDescription(true) : undefined
                            }
                        />
                    </Tooltip>
                    <Tooltip title={String(t('tooltips.confirm'))}>
                        <div
                            className={clsx(
                                'scd-payment-transaction-in-series__content-new-transaction__icons-check scd-payment-transaction-in-series__content-new-transaction__icons-icon',
                                {
                                    active: validateScdNewPaymentTransaction(newPaymentTransaction) && !disabledButton,
                                    disabled: !validateScdNewPaymentTransaction(newPaymentTransaction) || disabledButton,
                                }
                            )}
                            onClick={() =>
                                validateScdNewPaymentTransaction(newPaymentTransaction) && newPaymentTransaction
                                    ? handleSave(newPaymentTransaction)
                                    : undefined
                            }
                        >
                            <CheckIcon />
                        </div>
                    </Tooltip>
                </div>
                <ModalTextArea
                    title={t(`${I18N_PREFIX}.description.title`)}
                    label={t(`${I18N_PREFIX}.description.label`)}
                    openModal={modalDescription}
                    defaultValue={newPaymentTransaction?.description ?? ''}
                    onClose={() => setModalDescription(false)}
                    onChange={description => handleChange({ description })}
                    isActive
                />
            </div>
        </>
    );
};

export default CreatePaymentTransactionInSeries;
