import BackDrop from 'components/backdrop/backdrop';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentTransactionDetail, PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useManualDisbursementPaymentTransactionState, useRootDispatch, useValidateManualDisbursementState } from 'reducer/hooks';
import {
    validateManualDisbursementPaymentTransactionRequest,
    validateManualDisbursementPaymentTransactionResetState,
} from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/actions';
import { PaymentTransactionManualDisbursementAvailability } from 'model/enums/manual-disbursement-availability';
import BaseDisbursementForm from 'entities/payment-transaction/payment-transaction-detail/components/manual-disbursement-form/base-disbursement-form';
import FormErrorByValidation from 'entities/payment-transaction/payment-transaction-detail/components/manual-disbursement-form/form-error-by-validation';
import ManualDisbursementValidationError from 'entities/payment-transaction/payment-transaction-detail/components/manual-disbursement-validation-error/manual-disbursement-validation-error';

export type ManualDisbursementModalType = 'cancel' | 'create-manual-disbursement';

interface ManualDisbursementFormProps {
    paymentTransaction: PaymentTransactionDetail | undefined;
    paymentTransactionToManualDisbursement: PaymentTransactionToManualDisbursement | undefined;
    setPaymentTransactionToManualDisbursement: (value: PaymentTransactionToManualDisbursement | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const ManualDisbursementForm = (props: ManualDisbursementFormProps) => {
    const { paymentTransaction, paymentTransactionToManualDisbursement, setPaymentTransactionToManualDisbursement } = props;

    const { status } = useManualDisbursementPaymentTransactionState();
    const { status: validationStatus, manualDisbursementValidation } = useValidateManualDisbursementState();

    const { t } = useTranslation();

    const dispatch = useRootDispatch();

    useEffect(() => {
        if (!paymentTransaction?.id) return;

        dispatch(validateManualDisbursementPaymentTransactionRequest(paymentTransaction?.id.toString()));
    }, [dispatch, paymentTransaction?.id]);

    useEffect(() => {
        return () => {
            dispatch(validateManualDisbursementPaymentTransactionResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (!paymentTransaction) return;

        setPaymentTransactionToManualDisbursement({
            paymentTransactionId: paymentTransaction.id,
            paymentInstitutionAccountId: paymentTransaction.paymentInstitutionAccount?.id,
            paymentInstitutionAccountName: paymentTransaction.paymentInstitutionAccount?.name,
            paymentInstitutionAccountStatus: paymentTransaction.paymentInstitutionAccount?.status,
            paymentInstitutionId: paymentTransaction.paymentInstitution?.id,
            paymentInstitutionName: paymentTransaction.paymentInstitution?.name,
            paymentInstitutionStatus: paymentTransaction.paymentInstitution?.status,
            description: paymentTransaction.description,
        });
    }, [paymentTransaction, setPaymentTransactionToManualDisbursement]);

    const isBackdropOpen: boolean = status === HttpRequestStatus.ON_GOING;

    const isValidationLoading: boolean = validationStatus === HttpRequestStatus.ON_GOING;

    const validationHasError: boolean = validationStatus === HttpRequestStatus.ERROR;

    const isManualDisbursementFormAvailable: boolean =
        manualDisbursementValidation?.availability === PaymentTransactionManualDisbursementAvailability.SUPPORTED;

    return (
        <>
            {isBackdropOpen && <BackDrop open />}
            <div className="scd-payment-transaction__detail--right-container">
                <header className="scd-payment-transaction__detail--header">
                    <h2 className="scd-payment-transaction__detail--form-title">{t(`${I18N_PREFIX}.new-transaction`)}</h2>
                </header>
                <form className="scd-payment-transaction__detail--form">
                    {isValidationLoading ? (
                        <div className="scd-payment-transaction__detail--form-loading">
                            <label>{t(`${I18N_PREFIX}.loading-validation`)}</label>
                            <Loading isTable />
                        </div>
                    ) : (
                        <>
                            {isManualDisbursementFormAvailable && (
                                <BaseDisbursementForm
                                    paymentTransaction={paymentTransaction}
                                    paymentTransactionToManualDisbursement={paymentTransactionToManualDisbursement}
                                    setPaymentTransactionToManualDisbursement={setPaymentTransactionToManualDisbursement}
                                />
                            )}
                            {!isManualDisbursementFormAvailable && (
                                <FormErrorByValidation manualDisbursementValidation={manualDisbursementValidation} />
                            )}
                            {validationHasError && <ManualDisbursementValidationError />}
                        </>
                    )}
                </form>
            </div>
        </>
    );
};

export default ManualDisbursementForm;
