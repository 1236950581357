import { TaxValueType } from 'model/enums/tax-type';
import {
    ScdMonthlyCommissionDetail,
    ScdMonthlyCommissionDetailDocument,
    ScdMonthlyCommissionDetailTaxValue,
} from 'model/monthly-commission';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const MONTHLY_COMMISSION_DOCUMENT_VALIDATION: Validations<ScdMonthlyCommissionDetailDocument | undefined | null> = {
    required: {
        predicate: ValidationUtils.OBJECT.isDefined,
    },
};
export const validateMonthlyCommissionDocument = (value: ScdMonthlyCommissionDetailDocument | undefined | null) =>
    ValidationUtils.validate(value, MONTHLY_COMMISSION_DOCUMENT_VALIDATION);

const MONTHLY_COMMISSION_TAX_DOCUMENT_VALIDATION: Validations<ScdMonthlyCommissionDetailTaxValue | undefined | null> = {
    required: {
        predicate: it => !!it?.federalRevenueCollection && !!it?.federalRevenueReceipt,
    },
};
export const validateMonthlyCommissionTaxValueDocument = (value: ScdMonthlyCommissionDetailTaxValue | undefined | null) =>
    ValidationUtils.validate(value, MONTHLY_COMMISSION_TAX_DOCUMENT_VALIDATION);

export const validateMonthlyCommissionDocumentsValidation = (value: ScdMonthlyCommissionDetail | undefined): boolean => {
    if (!value) return false;

    const _incomeTax: ScdMonthlyCommissionDetailTaxValue | undefined = value.taxValues.find(
        value => value.type === TaxValueType.INCOME_TAX
    );
    const _socialTaxes: ScdMonthlyCommissionDetailTaxValue | undefined = value.taxValues.find(
        value => value.type === TaxValueType.SOCIAL_TAXES
    );
    const _taxOverServices: ScdMonthlyCommissionDetailTaxValue | undefined = value.taxValues.find(
        value => value.type === TaxValueType.TAX_OVER_SERVICES
    );

    return (
        validateMonthlyCommissionDocument(value.companyInvoice).isValid &&
        validateMonthlyCommissionDocument(value.companyReceipt).isValid &&
        validateMonthlyCommissionDocument(value.originatorInvoice).isValid &&
        validateMonthlyCommissionDocument(value.originatorReceipt).isValid &&
        (_incomeTax ? validateMonthlyCommissionTaxValueDocument(_incomeTax).isValid : true) &&
        (_taxOverServices ? validateMonthlyCommissionTaxValueDocument(_taxOverServices).isValid : true) &&
        (_socialTaxes ? validateMonthlyCommissionTaxValueDocument(_socialTaxes).isValid : true)
    );
};
