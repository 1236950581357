import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import './footer.scss';

const DefaultFooter = () => {
    const { t } = useTranslation();
    return (
        <div className="container__footer">
            <Row>
                <Col md="12" xs="12">
                    <span className="footer--text">{t('footer.copyright')}</span>
                </Col>
            </Row>
        </div>
    );
};

export default DefaultFooter;
