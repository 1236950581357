import DocumentIconButton from 'components/buttons/icon-buttons/document/document-icon-button';
import _ from 'lodash';

interface DocumentRedirectIconProps {
    url: string | null | undefined;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.row';

const DocumentRedirectIcon = (props: DocumentRedirectIconProps) => {
    const { url } = props;

    return (
        <>
            {!_.isNil(url) ? (
                <a href={url} target="_blank" rel="noreferrer">
                    <DocumentIconButton title={`${I18N_PREFIX}.tooltips.technical-supplier-receipt`} />
                </a>
            ) : (
                <DocumentIconButton title={`${I18N_PREFIX}.tooltips.technical-supplier-receipt`} disabled />
            )}
        </>
    );
};

export default DocumentRedirectIcon;
