import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Tooltip } from '@material-ui/core';
import FilterButton from 'components/buttons/filter-button/filter-button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';
import { UserFilterRequest } from 'model/user';
import { UserModalFilterType } from 'entities/user/components/user-modal-filter/user-modal-filter';

interface UserFiltersButtonsProps {
    filterActive: UserFilterRequest;
    setIsOpenModalFilter: (value: boolean) => void;
    setModalFilter: (value: UserModalFilterType | undefined) => void;
    handleResetFilterActive: () => void;
}

const I18N_PREFIX = 'pages.scd.users.filter';

const UserFiltersButtons = (props: UserFiltersButtonsProps) => {
    const { t } = useTranslation();

    const { filterActive, handleResetFilterActive, setIsOpenModalFilter, setModalFilter } = props;

    const ArrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isFilterActive: boolean = valuesOfObject({ ...filterActive }).some(it => it !== undefined);

    const handleModalFilter = (value: UserModalFilterType) => () => {
        setIsOpenModalFilter(true);
        setModalFilter(value);
    };

    return (
        <>
            <div className="scd-users__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFilterActive}
                    onClick={handleModalFilter('filters')}
                />
            </div>
            <div className="scd-users__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.name`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.firstName}
                    onClick={handleModalFilter('name')}
                />
            </div>
            <div className="scd-users__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.activated`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={filterActive.activated !== undefined}
                    onClick={handleModalFilter('activated')}
                />
            </div>
            <div className="scd-users__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.login`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.login}
                    onClick={handleModalFilter('login')}
                />
            </div>
            <div className="scd-users__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.authority`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.authority}
                    onClick={handleModalFilter('authority')}
                />
            </div>
            {isFilterActive && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-users__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    )
}

export default UserFiltersButtons;