import { GiroWebappError } from 'model/error';
import { ScdMonthlyCommissionSimple, ScdMonthlyCommissionGenerateReport } from 'model/monthly-commission';
import { MonthlyCommissionGenerateReportTypes } from 'reducer/monthly-commission/generate-report/types';
import { action } from 'typesafe-actions';

export const monthlyCommissionGenerateReportRequest = (request: ScdMonthlyCommissionGenerateReport) =>
    action(MonthlyCommissionGenerateReportTypes.MONTHLY_COMMISSION_GENERATE_REPORT_REQUEST, request);
export const monthlyCommissionGenerateReportSuccess = (monthlyCommissionId: ScdMonthlyCommissionSimple) =>
    action(MonthlyCommissionGenerateReportTypes.MONTHLY_COMMISSION_GENERATE_REPORT_SUCCESS, monthlyCommissionId);
export const monthlyCommissionGenerateReportError = (error: GiroWebappError) =>
    action(MonthlyCommissionGenerateReportTypes.MONTHLY_COMMISSION_GENERATE_REPORT_ERROR, error);
export const monthlyCommissionGenerateReportResetState = () =>
    action(MonthlyCommissionGenerateReportTypes.MONTHLY_COMMISSION_GENERATE_REPORT_RESET);
