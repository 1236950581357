import { GiroWebappError } from 'model/error';
import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { ReleaseMonthlyCommissionTypes } from 'reducer/monthly-commission/release-monthly-commission/types';
import { action } from 'typesafe-actions';

export const releaseMonthlyCommissionRequest = (monthlyCommissionId: number) =>
    action(ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_REQUEST, monthlyCommissionId);
export const releaseMonthlyCommissionSuccess = (monthlyCommission: ScdMonthlyCommissionSimple) =>
    action(ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_SUCCESS, monthlyCommission);
export const releaseMonthlyCommissionError = (error: GiroWebappError) =>
    action(ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_ERROR, error);
export const releaseMonthlyCommissionResetState = () => action(ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_RESET);
