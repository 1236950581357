import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import requestLogAPI from 'services/api/request-log-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListRequestLogTypes } from './types';
import { listRequestLogError, listRequestLogSuccess, listRequestLogRequest } from './actions';
import { Page } from 'services/page';
import { ScdRequestLogData } from 'model/request-log';

function* handleListRequestLog(action: ReturnType<typeof listRequestLogRequest>) {
    try {
        const result: AxiosResponse<Page<ScdRequestLogData>> = yield call(requestLogAPI.listRequestLogs, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listRequestLogError(mapped));
            return;
        }
        yield put(listRequestLogSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listRequestLogError(mapped));
    }
}

function* watchListRequestLog() {
    yield takeEvery(ListRequestLogTypes.LIST_REQUEST_LOG_REQUEST, handleListRequestLog);
}

function* listRequestLogSaga() {
    yield all([fork(watchListRequestLog)]);
}

export default listRequestLogSaga;
