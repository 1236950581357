export enum OperationStatus {
    RECEIVED = 'RECEIVED',
    PENDING_APPROVAL = 'PENDING_APPROVAL',
    APPROVED = 'APPROVED',
    NEW = 'NEW',
    BATCHLESS = 'BATCHLESS',
    PROCESSED = 'PROCESSED',
    EXPORTED = 'EXPORTED',
    ARCHIVED = 'ARCHIVED',
    DISAPPROVED = 'DISAPPROVED',
    ERROR = 'ERROR',
    OPERATION_DATA_ERROR = 'OPERATION_DATA_ERROR',
    PRE_PROCESS_ERROR = 'PRE_PROCESS_ERROR',
    DEFINE_FLOW_ERROR = 'DEFINE_FLOW_ERROR',
    DEFINE_PAYMENT_ERROR = 'DEFINE_PAYMENT_ERROR',
    AUTOMATIC_PAYMENT_ERROR = 'AUTOMATIC_PAYMENT_ERROR',
}