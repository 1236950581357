import DashedButton from 'components/buttons/dashed-button/dashed-button';
import CardEdition, { ICardEdition } from 'components/card/card-edition/card-edition';
import CompanyAccountsCardCreate from 'entities/company/components/company-accounts-card/company-accounts-card-create/company-accounts-card-create';
import CompanyAccountCardDetail from 'entities/company/components/company-accounts-card/company-accounts-card-detail/company-accounts-card-detail';
import { validateCompanyAccount } from 'entities/company/validations/company-accounts-validation';
import _ from 'lodash';
import { CompanyBankAccount, defaultCompanyBankAccount } from 'model/company-bank-account';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

interface CompanyAccountsManagerProps {
    companyAccounts: CompanyBankAccount[] | undefined;
    onUpdate: (companyAccountsList: CompanyBankAccount[]) => void;
    editionUpdate?: (value: boolean) => void;
}

interface CompanyAccountEdition extends CompanyBankAccount, ICardEdition {
    uniqueKey?: string;
}

const I18N_PREFIX = 'pages.scd.company.card.accounts';

const CompanyAccountsManager = (props: CompanyAccountsManagerProps) => {
    const { companyAccounts, onUpdate, editionUpdate } = props;

    const { t } = useTranslation();

    const [editing, setEditing] = useState<boolean>(false);
    const [companyAccountsList, setCompanyAccountsList] = useState<CompanyAccountEdition[]>(
        companyAccounts?.map(it => ({
            ...it,
            isNew: false,
            uniqueKey: uuidV4(),
        })) ?? []
    );
    const [alreadyAdd, setAlreadyAdd] = useState<boolean>(false);
    const [isPixKeyDuplicated, setIsPixKeyDuplicated] = useState<boolean>(false);

    const updateEditing = (value: boolean) => {
        setEditing(value);
        if (editionUpdate) {
            editionUpdate(value);
        }
    };

    const handleAdd = (_event: any) => {
        setCompanyAccountsList(ps => [...ps, { ...defaultCompanyBankAccount, isNew: true, uniqueKey: uuidV4() }]);
        updateEditing(true);
    };

    const checkPixKey = (pixKey: string | undefined | null) => {
        const duplicated: boolean = companyAccountsList
            ?.map(it => it.pixKey)
            .filter(it => !_.isNil(it))
            .filter(it => it?.trim().length)
            .some((it, _index) => it === pixKey?.trim());

        setIsPixKeyDuplicated(duplicated);
    };

    const handleAlreadyAdd = (companyBankAccount: CompanyBankAccount, index: number) => {
        const isAlreadyAdd = companyAccountsList?.some(
            (ps, _index) =>
                !!ps.accountNumber &&
                ps.accountNumber === companyBankAccount.accountNumber &&
                !!ps.accountDigit &&
                ps.accountDigit === companyBankAccount.accountDigit &&
                !!ps.favoredIdentification &&
                ps.favoredIdentification === companyBankAccount.favoredIdentification &&
                _index !== index
        );
        setAlreadyAdd(isAlreadyAdd);
    };

    const handleSave = (_index: number, _update: CompanyAccountEdition) => {
        const update = companyAccountsList.map((it, index) => (index === _index ? { ..._update, isNew: false } : it));
        setCompanyAccountsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
        updateEditing(false);
    };

    const handleRemove = (_index: number) => {
        const update = companyAccountsList.filter((_it, index) => index !== _index);
        setCompanyAccountsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
    };

    const handleCancel = (_index: number) => {
        if (!companyAccountsList[_index]) return;
        if (companyAccountsList[_index].isNew) {
            setCompanyAccountsList(ps => ps.filter((_it, index) => index !== _index));
        }
        updateEditing(false);
        setAlreadyAdd(false);
        setIsPixKeyDuplicated(false);
    };

    return (
        <div className="scd-company-create-update__manager">
            {companyAccountsList &&
                companyAccountsList.length > 0 &&
                companyAccountsList.map((item, index) => (
                    <CardEdition
                        key={item.uniqueKey}
                        entity={item}
                        onSave={value => handleSave(index, value)}
                        validate={validateCompanyAccount}
                        onRemove={() => handleRemove(index)}
                        onCancel={() => handleCancel(index)}
                        editable={true}
                        removable={true}
                        alreadyAdd={isPixKeyDuplicated}
                        name={t(`${I18N_PREFIX}.name`, { number: index + 1 })}
                        deleteTitle={t(`${I18N_PREFIX}.modal.delete.title`)}
                        deleteMessage={t(`global.modal.delete.message`)}
                        cancelMessage={t('entity.cancel.info')}
                    >
                        {({ isEditing, onChange, entity, showValidation }) => (
                            <>
                                {isEditing ? (
                                    <CompanyAccountsCardCreate
                                        entity={entity}
                                        onChange={onChange}
                                        showValidation={showValidation}
                                        alreadyAdd={alreadyAdd}
                                        checkPixKey={checkPixKey}
                                        isPixKeyDuplicated={isPixKeyDuplicated}
                                        handleAlreadyAdd={handleAlreadyAdd}
                                        index={index}
                                    />
                                ) : (
                                    <CompanyAccountCardDetail entity={entity} />
                                )}
                            </>
                        )}
                    </CardEdition>
                ))}
            {!editing && (
                <div className="scd-company-create-update__manager--button">
                    <DashedButton label={t(`${I18N_PREFIX}.title`)} onClick={handleAdd} />
                </div>
            )}
        </div>
    );
};

export default CompanyAccountsManager;
