import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ResumePaymentInstitutionsAccountsState, ResumePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/types';

const initialState: ResumePaymentInstitutionsAccountsState = {
    status: HttpRequestStatus.NOOP,
    resumedPaymentInstitutionAccount: undefined,
    error: undefined,
};

const reducer: Reducer<ResumePaymentInstitutionsAccountsState> = (state = initialState, action): ResumePaymentInstitutionsAccountsState => {
    switch (action.type) {
        case ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, resumedPaymentInstitutionAccount: action.payload, error: undefined };
        }
        case ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as resumePaymentInstitutionsAccountsReducer };
