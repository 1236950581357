import { GiroWebappError } from 'model/error';
import { ScdIssuerSearch } from 'model/issuer';
import { SearchRequest } from 'model/reducers';
import { SearchIssuersTypes } from 'reducer/issuer/search-issuers/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchIssuersRequest = (request: SearchRequest) => action(SearchIssuersTypes.SEARCH_ISSUERS_REQUEST, request);
export const searchIssuersSuccess = (issuers: Page<ScdIssuerSearch>) => action(SearchIssuersTypes.SEARCH_ISSUERS_SUCCESS, issuers);
export const searchIssuersError = (error: GiroWebappError) => action(SearchIssuersTypes.SEARCH_ISSUERS_ERROR, error);
export const searchIssuersResetState = () => action(SearchIssuersTypes.SEARCH_ISSUERS_RESET_STATE);
