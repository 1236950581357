import RemoveIconButton from 'components/buttons/icon-buttons/remove/remove-icon-button';
import { ProgramGroupsToGetAll } from 'model/program-groups';
import { ListChildComponentProps } from 'react-window';

export interface ProgramGroupsRowProps {
    programGroupsItems: ProgramGroupsToGetAll[];
    setSelectedGroup: (value: ProgramGroupsToGetAll | undefined) => void;
}

const ProgramGroupsRow = (props: ListChildComponentProps<ProgramGroupsRowProps>) => {
    const { data, index, style } = props;

    const { programGroupsItems, setSelectedGroup } = data;

    const group = programGroupsItems[index];

    return (
        <tr className="page-container--table-cell" key={group.id} style={style}>
            <td className="page-container--table-cell-title">{group.name}</td>
            <td className="page-container--table-cell-icon">
                <RemoveIconButton onClick={() => setSelectedGroup(group)} />
            </td>
        </tr>
    );
};

export default ProgramGroupsRow;
