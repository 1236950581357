import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateTaxTypes, CreateTaxState } from 'reducer/tax/create-tax/types';
import { Reducer } from 'redux';

export const initialState: CreateTaxState = {
    status: HttpRequestStatus.NOOP,
    tax: undefined,
    error: undefined,
};

const reducer: Reducer<CreateTaxState> = (state = initialState, action): CreateTaxState => {
    switch (action.type) {
        case CreateTaxTypes.CREATE_TAX_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CreateTaxTypes.CREATE_TAX_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, tax: action.payload, error: undefined };
        }
        case CreateTaxTypes.CREATE_TAX_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateTaxTypes.CREATE_TAX_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createTaxReducer };
