import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface CompanyGroupPolicyRowProps {
    companyGroupPolicy: ScdCompanyGroupPolicy[];
}

const CompanyGroupPolicyRow = (props: ListChildComponentProps<CompanyGroupPolicyRowProps>) => {
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { companyGroupPolicy } = data;

    const { id, companyGroup } = companyGroupPolicy[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{companyGroup?.name}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default CompanyGroupPolicyRow;
