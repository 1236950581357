import { ScdTechnicalSupplier, ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { GiroWebappError } from 'model/error';
import { UpdateTechnicalSupplierTypes } from './types';
import { action } from 'typesafe-actions';

export const updateTechnicalSupplierRequest = (technicalSupplier: ScdTechnicalSupplier) =>
    action(UpdateTechnicalSupplierTypes.UPDATE_TECHNICAL_SUPPLIER_REQUEST, technicalSupplier);
export const updateTechnicalSupplierSuccess = (updatedTechnicalSupplier: ScdTechnicalSupplierSimple) =>
    action(UpdateTechnicalSupplierTypes.UPDATE_TECHNICAL_SUPPLIER_SUCCESS, updatedTechnicalSupplier);
export const updateTechnicalSupplierError = (error: GiroWebappError) =>
    action(UpdateTechnicalSupplierTypes.UPDATE_TECHNICAL_SUPPLIER_ERROR, error);
export const updateTechnicalSupplierResetState = () => action(UpdateTechnicalSupplierTypes.UPDATE_TECHNICAL_SUPPLIER_RESET_STATE);
