import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { markError } from 'reducer/application/error/actions';
import { defaultTechnicalSupplierError, defaultTechnicalSupplierRequest, defaultTechnicalSupplierSuccess } from './actions';
import { DefaultTechnicalSupplierTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import technicalSupplierApi from 'services/api/technical-supplier-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDefaultTechnicalSupplier(action: ReturnType<typeof defaultTechnicalSupplierRequest>) {
    try {
        const result: AxiosResponse<ScdTechnicalSupplierSimple> = yield call(technicalSupplierApi.defaultTechnicalSupplier, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(defaultTechnicalSupplierError(mapped));
            return;
        }
        yield put(defaultTechnicalSupplierSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(defaultTechnicalSupplierError(mapped));
    }
}

function* watchDefaultTechnicalSupplier() {
    yield takeLeading(DefaultTechnicalSupplierTypes.DEFAULT_TECHNICAL_SUPPLIER_REQUEST, handleDefaultTechnicalSupplier);
}

function* defaultTechnicalSupplierSaga() {
    yield all([fork(watchDefaultTechnicalSupplier)]);
}

export default defaultTechnicalSupplierSaga;
