import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ExternalBalancePaymentInstitutionsAccountsState, ExternalBalancePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { ExternalBalanceStateModel } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';

const initialState: ExternalBalancePaymentInstitutionsAccountsState = {
    externalBalances: {}
};

const reducer: Reducer<ExternalBalancePaymentInstitutionsAccountsState> = (state = initialState, action): ExternalBalancePaymentInstitutionsAccountsState => {
    switch (action.type) {
        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            const stateObject: ExternalBalanceStateModel = {
                status: HttpRequestStatus.ON_GOING,
                balance: undefined,
                error: undefined
            }

            return { ...state, externalBalances: { ...state.externalBalances, [action.meta.accountId]: stateObject } };
        }
        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            const stateObject: ExternalBalanceStateModel = {
                ...state.externalBalances?.[action.payload.accountId],
                status: HttpRequestStatus.SUCCESS,
                balance: action.payload
            }

            return { ...state, externalBalances: { ...state.externalBalances, [action.payload.accountId]: stateObject } };
        }
        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            const stateObject: ExternalBalanceStateModel = {
                ...state.externalBalances?.[action.meta.accountId],
                status: HttpRequestStatus.ERROR,
                error: action.payload
            }

            return { ...state, externalBalances: { ...state.externalBalances, [action.meta.accountId]: stateObject } };
        }
        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET_BY_ID: {
            const { [action.payload]: currentIdStateProperties, ...remainingState } = state?.externalBalances

            return { ...state, externalBalances: remainingState }
        }
        case ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as externalBalancePaymentInstitutionsAccountsReducer };
