import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import ScdRequestLogDetailPage from 'entities/request-log/request-log-detail/request-log-detail';
import RequestLogBrs from 'entities/request-log/request-log-brs';
import ScdRequestLog from 'entities/request-log/request-log';

interface MatchParams {
    url: string;
}

const RequestLogRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={RequestLogBrs} />
        <PrivateRoute exact path={`${match.url}/legacy`} component={ScdRequestLog} />
        <PrivateRoute exact path={`${match.url}/:requestLogId`} component={ScdRequestLogDetailPage} />
    </Switch>
);

export default RequestLogRoutes;
