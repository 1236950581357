import MenuItem from '@material-ui/core/MenuItem';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidIdentifierInput from 'components/inputs/identifier-input/valid-identifier-input';
import ValidPercentInput from 'components/inputs/percentage-input/valid-percent-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE, SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import CompanyGroupsManager, { CompanyGroupEdition } from 'entities/legacy-program/components/company-group-card/company-group-manager';
import {
    validateProgramGoodwill,
    validateProgramIdentifier,
    validateProgramIssuer,
    validateProgramName,
    validateProgramOrganization,
    validateProgramOriginator,
    validateProgramProductType,
    validateProgramStatus,
    validateScdProgram,
} from 'entities/legacy-program/validation/program-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramStatus } from 'model/enums/program-status';
import { ScdIssuerSearch } from 'model/issuer';
import { ScdOrganization } from 'model/organization';
import { ScdOriginatorSearch } from 'model/originator';
import { LegacyScdProgram, defaultLegacyScdProgram } from 'model/program';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useDetailProgramState, useLegacyCreateProgramState, useLegacyUpdateProgramState, useRootDispatch } from 'reducer/hooks';
import { searchIssuersRequest, searchIssuersResetState } from 'reducer/issuer/search-issuers/actions';
import { searchOrganizationsRequest, searchOrganizationsResetState } from 'reducer/organization/search-organizations/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import { detailProgramRequest, detailProgramResetState } from 'reducer/program/detail-program/actions';
import { legacyCreateProgramRequest, legacyCreateProgramResetState } from 'reducer/program/legacy-create-program/actions';
import { legacyUpdateProgramRequest, legacyUpdateProgramResetState } from 'reducer/program/legacy-update-program/actions';
import { v4 as uuidV4 } from 'uuid';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import './legacy-program-create-update.scss';

export interface ScdProgramUpdateProps extends RouteComponentProps<{ programId: string }> {}

enum ScdProgramEntity {
    COMPANY_GROUPS = 'COMPANY_GROUPS',
}

const I18N_PREFIX = 'pages.scd.program';

const ScdLegacyProgramCreateUpdatePage = (props: ScdProgramUpdateProps) => {
    const { t } = useTranslation();
    const [programId] = useState<string | undefined>(props.match?.params?.programId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);

    const { status: createStatus } = useLegacyCreateProgramState();
    const { status: updateStatus } = useLegacyUpdateProgramState();
    const { status: detailStatus, program: detailProgram } = useDetailProgramState();

    const [scdProgram, setScdProgram] = useState<LegacyScdProgram>(defaultLegacyScdProgram);

    const [editionRecord, setEditionRecord] = useState<Record<ScdProgramEntity, boolean>>({
        COMPANY_GROUPS: false,
    });

    const [companyGroupsList, setCompanyGroupsList] = useState<CompanyGroupEdition[]>([]);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _scdProgram: LegacyScdProgram = {
            ...scdProgram,
        };

        if (!validateScdProgram(_scdProgram)) return;

        if (programId) {
            dispatch(legacyUpdateProgramRequest(_scdProgram));
        } else {
            dispatch(legacyCreateProgramRequest(_scdProgram));
        }
    };

    const handleChange = (value: Partial<LegacyScdProgram>) => {
        setScdProgram(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (programId) {
            dispatch(detailProgramRequest(programId));
        }
    }, [programId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailProgram !== undefined) {
            setScdProgram(detailProgram);
        }
    }, [detailStatus, detailProgram]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            history.push('/legacy-programs');
        }
    }, [updateStatus, history]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push('/legacy-programs');
        }
    }, [createStatus, history]);

    useEffect(() => {
        if (!detailProgram) return;
        setCompanyGroupsList(
            detailProgram.companyGroups?.map(it => ({
                ...it,
                isNew: false,
                uniqueKey: uuidV4(),
            }))
        );
    }, [detailProgram]);

    useEffect(() => {
        if (!scdProgram.companyGroups) return;

        setCompanyGroupsList(
            scdProgram.companyGroups?.map(it => ({
                ...it,
                isNew: false,
                uniqueKey: uuidV4(),
            }))
        );
    }, [scdProgram]);

    useEffect(() => {
        return () => {
            dispatch(legacyCreateProgramResetState());
            dispatch(legacyUpdateProgramResetState());
            dispatch(detailProgramResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;
    return (
        <main className="scd-legacy-program__create">
            <ContextRibbon />
            <div className="scd-legacy-program--container">
                <header className="scd-legacy-program--header">
                    <h2 className="scd-legacy-program--header-title">
                        {!programId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && programId ? (
                    <Loading />
                ) : (
                    <form className="scd-legacy-program--form">
                        <div className="scd-legacy-program--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdProgram?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateProgramName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ValidIdentifierInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.identifier.label`)}
                                value={scdProgram?.identifier!}
                                placeholder={t(`${I18N_PREFIX}.input.identifier.placeholder`)}
                                onChange={identifier => handleChange({ identifier })}
                                validate={validateProgramIdentifier}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ValidPercentInput
                                label={t(`${I18N_PREFIX}.input.goodwill.label`)}
                                value={scdProgram?.goodwill!}
                                placeholder={t(`${I18N_PREFIX}.input.goodwill.placeholder`)}
                                onChange={goodwill => handleChange({ goodwill })}
                                validate={validateProgramGoodwill}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={scdProgram?.status ?? ''}
                                validate={validateProgramStatus}
                                mapperFromString={_value => (_value ? ScdProgramStatus[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                onChange={(status: string) => handleChange({ status: ScdProgramStatus[status] })}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramStatus.ACTIVE}>{t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}</MenuItem>
                                <MenuItem value={ScdProgramStatus.INACTIVE}>{t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.productType.label`)}
                                value={scdProgram?.productType ?? ''}
                                validate={validateProgramProductType}
                                mapperFromString={_value => (_value ? ProductType[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.productType.placeholder`)}
                                onChange={(productType: string) => handleChange({ productType: ProductType[productType] })}
                                externalUpdate
                            >
                                <MenuItem value={ProductType.CREDIT_CARD}>
                                    {t(`${I18N_PREFIX}.input.productType.options.CREDIT_CARD`)}
                                </MenuItem>
                                <MenuItem value={ProductType.DIRECT_CONSUMER_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.productType.options.DIRECT_CONSUMER_CREDIT`)}
                                </MenuItem>
                                <MenuItem value={ProductType.PERSONAL_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.productType.options.PERSONAL_CREDIT`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ModalSearch<ScdOrganization>
                                action={searchOrganizationsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.organization.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.organization.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.organization.modal.placeholder`)}
                                onSelect={organization => {
                                    handleChange({ organization, originator: undefined, companyGroups: undefined });
                                    setCompanyGroupsList([]);
                                    setEditing(false);
                                }}
                                renderItem={organization => organization.name}
                                statusSelector={(state: IRootState) => state.searchOrganizations.status}
                                dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                                resetState={searchOrganizationsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.organization.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.organization.placeholder`}
                                        validate={validateProgramOrganization}
                                        value={scdProgram.organization?.name ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!programId}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ModalSearch<ScdOriginatorSearch>
                                action={searchOriginatorsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.originator.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.originator.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.originator.modal.placeholder`)}
                                onSelect={originator => handleChange({ originator })}
                                renderItem={originator => originator?.name}
                                statusSelector={(state: IRootState) => state.searchOriginators.status}
                                dataSelector={(state: IRootState) => state.searchOriginators.originators}
                                requestParameters={{ organizationId: scdProgram.organization?.id }}
                                resetState={searchOriginatorsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.originator.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.originator.placeholder`}
                                        validate={validateProgramOriginator}
                                        value={scdProgram?.originator?.name ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!programId || !scdProgram.organization}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-legacy-program--form-field">
                            <ModalSearch<ScdIssuerSearch>
                                action={searchIssuersRequest}
                                itemSize={SEARCH_ITEM_SIZE_LARGE}
                                modalTitle={t(`${I18N_PREFIX}.input.issuer.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.issuer.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.issuer.modal.placeholder`)}
                                onSelect={issuer => handleChange({ issuer })}
                                renderItem={issuer => `${issuer.identification} - ${issuer?.name}`}
                                statusSelector={(state: IRootState) => state.searchIssuers.status}
                                dataSelector={(state: IRootState) => state.searchIssuers.issuers}
                                resetState={searchIssuersResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.issuer.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.issuer.placeholder`}
                                        validate={validateProgramIssuer}
                                        value={
                                            scdProgram?.issuer?.name && scdProgram?.issuer?.identification
                                                ? `${scdProgram.issuer.identification} - ${scdProgram.issuer?.name}`
                                                : ''
                                        }
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-legacy-program--form-card">
                            <CompanyGroupsManager
                                onUpdate={companyGroups => handleChange({ companyGroups: companyGroups })}
                                editionUpdate={_isEditing => setEditionRecord(ps => ({ ...ps, COMPANY_GROUPS: _isEditing }))}
                                scdProgram={scdProgram}
                                companyGroupsList={companyGroupsList}
                                setCompanyGroupsList={setCompanyGroupsList}
                                editing={editing}
                                setEditing={setEditing}
                            />
                        </div>
                        {showValidation && (!scdProgram?.companyGroups || !scdProgram?.companyGroups.length) && (
                            <span className="scd-legacy-program--form-card--error-message">
                                {t(`${I18N_PREFIX}.validate.companyGroups.required`)}
                            </span>
                        )}
                        <div className="scd-legacy-program--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy
                                label={t('entity.action.save')}
                                onClick={handleSave}
                                disabled={editionRecord.COMPANY_GROUPS}
                            />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={programId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/legacy-programs');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdLegacyProgramCreateUpdatePage;
