import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { PaymentTransactionModalFilterType } from 'entities/payment-transaction/components/payment-transaction-modal-filter/payment-transaction-modal-filter';
import { PaymentTransactionFilterRequest } from 'model/payment-transaction';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.filter.buttons';

interface PaymentTransactionFilterButtonsProps {
    isNewTransaction: boolean;
    setModalFilter: (value: PaymentTransactionModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: PaymentTransactionFilterRequest;
    handleResetFilterActive: () => void;
}

const PaymentTransactionFilterButtons = (props: PaymentTransactionFilterButtonsProps) => {
    const { setModalFilter, setOpenModalFilter, isNewTransaction, filterActive, handleResetFilterActive } = props;

    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const showRemoveAllIcon = valuesOfObject({ ...filterActive }).some(item => item !== undefined);

    return (
        <>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    disabled={isNewTransaction}
                    icon={<FilterListIcon />}
                    isActiveFilter={
                        valuesOfObject({ ...filterActive }).length !== 0 &&
                        valuesOfObject({ ...filterActive }).some(value => value !== undefined)
                    }
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('date');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.date`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.date ? true : false}
                    disabled={isNewTransaction}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('favored');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.favored`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.favored ? true : false}
                    disabled={isNewTransaction}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('originatorId');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.originatorId`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.originatorId ? true : false}
                    disabled={isNewTransaction}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('status');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.status ? true : false}
                    disabled={isNewTransaction}
                />
            </div>
            <div className="scd-payment-transaction__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('bankIdentification');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.bankIdentification`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.bankIdentification ? true : false}
                    disabled={isNewTransaction}
                />
            </div>
            {showRemoveAllIcon ? (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-payment-transaction__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    );
};

export default PaymentTransactionFilterButtons;
