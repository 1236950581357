const config = {
    VERSION: process.env.VERSION
};

export default config;

export const SERVER_API_URL = process.env.REACT_APP_API_URL;
export const BASE_HREF = process.env.REACT_APP_BASE_HREF;

export const EMAIL_REGEX: RegExp = /^[\w-]+(?:\.[\w-]+)*@[a-z0-9-]+(?:\.[a-z0-9]+)*(?:\.[a-z]{2,})$/i;
export const PHONE_REGEX: RegExp = /^[1-9]{2}\d?\d{8}$/;
export const PHONE_PATTERN_REGEX = /^\(?[1-9]{2}\)?\s?\d?\d{4}-?\d{4}$/;

export const GIRO_PHONE_CONTACT: string = '(41) 98875-4600';

export const CURRENCY_FIXED_DECIMAL = 2;
export const UNIT_PRICE_FIXED_DECIMAL = 8;
export const PERCENTAGE_FIXED_DECIMAL = 2;
export const PERCENTAGE_SIX_DECIMALS = 6;
export const PERCENTAGE_TWELVE_DECIMALS = 12;

export const NUMBER_DEFAULT_DECIMAL_SEPARATOR = '.'; // dot

export const NUMBER_DECIMAL_SEPARATOR = ','; // comma
export const NUMBER_THOUSAND_SEPARATOR = '.'; // dot

export const INTL_LOCALE = 'pt-BR';
export const INTL_CURRENCY = 'BRL';
export const APP_CALENDAR_LOCALE_LANGUAGE = 'pt-BR';

export const INDIVIDUAL_IDENTIFICATION_LENGTH = 11;
export const INDIVIDUAL_IDENTIFICATION_MASK_REGEX = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
export const INDIVIDUAL_IDENTIFICATION_MASK = '$1.$2.$3-$4';

export const CORPORATION_IDENTIFICATION_LENGTH = 14;
export const CORPORATION_IDENTIFICATION_MASK_REGEX = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/;
export const CORPORATION_IDENTIFICATION_MASK = '$1.$2.$3/$4-$5';

export const DATE_FNS_LOCAL_DATE_FORMAT = 'dd/MM/yyyy';

export const BANK_SVG_URL = 'https://giro-logos.s3.amazonaws.com/bancos';

export const DEFAULT_PHONE_LENGTH = 10;
export const DEFAULT_PHONE_REGEX = /(\d{2})(\d{4})(\d{4})/g;
export const DEFAULT_PHONE_MASK = '($1) $2-$3';

export const EXTENDED_PHONE_LENGTH = 11;
export const EXTENDED_PHONE_REGEX = /(\d{2})(\d{5})(\d{4})/g;
export const BRAZILIAN_PHONE_REGEX = /(\+55)(\d{2})(\d{5})(\d{4})$/;
export const EXTENDED_PHONE_MASK = '($1) $2-$3';

export const TAX_CESSION_ALERT = 15;

export const PAYMENT_ACCOUNTS_BASE_URL = process.env.REACT_APP_PAYMENT_ACCOUNTS_BASE_URL;
export const TIMEOUT_FIVE_MINUTES_MS = 5 * 60 * 1000;