import { GiroWebappError } from 'model/error';
import { ExternalBalancePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { ExternalBalanceMeta, ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { action } from 'typesafe-actions';

export const externalBalancePaymentInstitutionsAccountsRequest = (accountId: number) => {
    const externalBalanceMeta: ExternalBalanceMeta = {
        accountId
    }

    return action(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, accountId, externalBalanceMeta)
};

export const externalBalancePaymentInstitutionsAccountsSuccess = (balance: ExternalBalanceResponse) =>
    action(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, balance);

export const externalBalancePaymentInstitutionsAccountsError = (error: GiroWebappError, accountId: number) => {
    const externalBalanceMeta: ExternalBalanceMeta = {
        accountId
    }

    return action(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error, externalBalanceMeta)
};

export const externalBalancePaymentInstitutionsAccountsResetStateById = (accountId: number) => action(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET_BY_ID, accountId)

export const externalBalancePaymentInstitutionsAccountsResetState = () =>
    action(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
