import FormatterUtils from 'components/formatter/formatter-utils';

export enum DateFormat {
    DATE = 'DATE',
    YEAR_MONTH = 'YEAR_MONTH',
    DATETIME_UTC = 'DATETIME_UTC',
    DATETIME_UTC_COMPLETE = 'DATETIME_UTC_COMPLETE',
    DATE_TO_MONTH_STRING = 'DATE_TO_MONTH_STRING',
}

export interface DateProps {
    date?: Date | string;
    format?: DateFormat;
}

export const DateFormatter = (props: DateProps) => {
    return (
        <>
            {
                {
                    DATE: FormatterUtils.formatDate(props.date),
                    YEAR_MONTH: FormatterUtils.formatYearMonthDate(props.date),
                    DATETIME_UTC: FormatterUtils.formatDateTimeUTC(props.date),
                    DATETIME_UTC_COMPLETE: FormatterUtils.formatDateTimeCompleteUTC(props.date),
                    DATE_TO_MONTH_STRING: FormatterUtils.formatDateToMonthString(props.date),
                }[props.format ?? DateFormat.DATE]
            }
        </>
    );
};

export default DateFormatter;
