import { MenuItem } from '@material-ui/core';
import DescriptionIconButton from 'components/buttons/icon-buttons/description/description-icon-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import BaseCheckboxInput from 'components/inputs/checkbox-input/base-checkbox-input';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import ValidNormalizedInput from 'components/inputs/normalized-input/valid-normalized-input';
import ValidPhoneNumberInput from 'components/inputs/phone-number-input/valid-phone-number-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextNumberInput from 'components/inputs/text-number-input/valid-text-number-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import ValidModalTextArea from 'components/modals/modal-text-area/valid-modal-text-area';
import { ManualDisbursementModalType } from 'entities/payment-transaction/payment-transaction-detail/components/manual-disbursement-form/manual-disbursement-form';
import PaymentInstitutionForm from 'entities/payment-transaction/payment-transaction-detail/components/payment-institution-form/payment-institution-form';
import {
    validateManualDIsbursementBankAccount,
    validateManualDisbursementAccountDigit,
    validateManualDisbursementAccountNumber,
    validateManualDisbursementAccountType,
    validateManualDisbursementAgencyNumber,
    validateManualDisbursementBankId,
} from 'entities/payment-transaction/payment-transaction-detail/validation/bank-account-validation';
import { validateManualDisbursementDescription } from 'entities/payment-transaction/payment-transaction-detail/validation/description-validation';
import {
    validateManualDisbursementPix,
    validateManualDisbursementPixKey,
    validateManualDisbursementPixKeyType,
} from 'entities/payment-transaction/payment-transaction-detail/validation/pix-key-validation';
import { Bank } from 'model/bank';
import { BankAccountType } from 'model/enums/bank-account-type';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { PixKeyType } from 'model/enums/pix-key-type';
import { PaymentTransactionDetail, PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { searchBanksRequest, searchBanksResetState } from 'reducer/bank/search-bank/actions';
import { useManualDisbursementPaymentTransactionState, useRootDispatch } from 'reducer/hooks';
import {
    manualDisbursementPaymentTransactionRequest,
    manualDisbursementPaymentTransactionResetState,
} from 'reducer/payment-transaction/manual-disbursement-payment-transaction/actions';
import { useToast } from 'shared/hooks/use-toast';

interface BaseDisbursementFormProps {
    paymentTransaction: PaymentTransactionDetail | undefined;
    paymentTransactionToManualDisbursement: PaymentTransactionToManualDisbursement | undefined;
    setPaymentTransactionToManualDisbursement: (value: PaymentTransactionToManualDisbursement | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const BaseDisbursementForm = (props: BaseDisbursementFormProps) => {
    const { paymentTransaction, paymentTransactionToManualDisbursement, setPaymentTransactionToManualDisbursement } = props;

    const [usePixKey, setUsePixKey] = useState<boolean>(false);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState<boolean>(false);
    const [manualDisbursementModal, setManualDisbursementModal] = useState<ManualDisbursementModalType | undefined>(undefined);

    const { status } = useManualDisbursementPaymentTransactionState();

    const { t } = useTranslation();
    const { toastSuccess } = useToast();

    const dispatch = useRootDispatch();
    const history = useHistory();

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        dispatch(manualDisbursementPaymentTransactionResetState());
        history.push('/payment-transactions');
    }, [status, toastSuccess, dispatch, history, t]);

    const handleChange = useCallback(
        (value: Partial<PaymentTransactionToManualDisbursement>) => {
            setPaymentTransactionToManualDisbursement({ ...paymentTransactionToManualDisbursement, ...value });
        },
        [paymentTransactionToManualDisbursement, setPaymentTransactionToManualDisbursement]
    );

    const handleCheckbox = () => {
        if (usePixKey) {
            setUsePixKey(false);
            setPaymentTransactionToManualDisbursement({ ...paymentTransactionToManualDisbursement, pix: undefined });
            return;
        }

        setUsePixKey(true);
        setPaymentTransactionToManualDisbursement({ ...paymentTransactionToManualDisbursement, bankAccount: undefined });
    };

    const PixKeyInput = useMemo((): ReactNode => {
        const BaseInput: JSX.Element = (
            <ValidNormalizedInput
                label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                value={paymentTransactionToManualDisbursement?.pix?.pixKey ?? ''}
                onChange={pixKey => handleChange({ pix: { ...paymentTransactionToManualDisbursement?.pix, pixKey } })}
                validate={() =>
                    validateManualDisbursementPixKey({
                        pixKey: paymentTransactionToManualDisbursement?.pix?.pixKey,
                        pixKeyType: paymentTransactionToManualDisbursement?.pix?.pixKeyType,
                    })
                }
                showValidation={showValidation}
                disabled={!paymentTransactionToManualDisbursement?.pix?.pixKeyType}
                externalUpdate
            />
        );

        const inputs: Record<PixKeyType, ReactNode> = {
            CORPORATION: (
                <ValidIdentificationInput
                    label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    value={paymentTransactionToManualDisbursement?.pix?.pixKey ?? ''}
                    onChange={pixKey => handleChange({ pix: { ...paymentTransactionToManualDisbursement?.pix, pixKey } })}
                    validate={() =>
                        validateManualDisbursementPixKey({
                            pixKey: paymentTransactionToManualDisbursement?.pix?.pixKey,
                            pixKeyType: paymentTransactionToManualDisbursement?.pix?.pixKeyType,
                        })
                    }
                    showValidation={showValidation}
                    disabled={!paymentTransactionToManualDisbursement?.pix?.pixKeyType}
                    personType={PersonType.CORPORATION}
                    externalUpdate
                />
            ),
            INDIVIDUAL: (
                <ValidIdentificationInput
                    label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    value={paymentTransactionToManualDisbursement?.pix?.pixKey ?? ''}
                    onChange={pixKey => handleChange({ pix: { ...paymentTransactionToManualDisbursement?.pix, pixKey } })}
                    validate={() =>
                        validateManualDisbursementPixKey({
                            pixKey: paymentTransactionToManualDisbursement?.pix?.pixKey,
                            pixKeyType: paymentTransactionToManualDisbursement?.pix?.pixKeyType,
                        })
                    }
                    showValidation={showValidation}
                    disabled={!paymentTransactionToManualDisbursement?.pix?.pixKeyType}
                    personType={PersonType.INDIVIDUAL}
                    externalUpdate
                />
            ),
            EMAIL: BaseInput,
            EVP: BaseInput,
            PHONE_NUMBER: (
                <ValidPhoneNumberInput
                    label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`)}
                    value={paymentTransactionToManualDisbursement?.pix?.pixKey ?? ''}
                    onChange={pixKey => handleChange({ pix: { ...paymentTransactionToManualDisbursement?.pix, pixKey } })}
                    validate={() =>
                        validateManualDisbursementPixKey({
                            pixKey: paymentTransactionToManualDisbursement?.pix?.pixKey,
                            pixKeyType: paymentTransactionToManualDisbursement?.pix?.pixKeyType,
                        })
                    }
                    showValidation={showValidation}
                    disabled={!paymentTransactionToManualDisbursement?.pix?.pixKeyType}
                    externalUpdate
                />
            ),
        };

        return inputs[paymentTransactionToManualDisbursement?.pix?.pixKeyType as string] ?? BaseInput;
    }, [handleChange, t, showValidation, paymentTransactionToManualDisbursement?.pix]);

    const handleManualDisbursementByPixKey = useCallback(() => {
        if (!validateManualDisbursementPix(paymentTransactionToManualDisbursement)) return;

        setManualDisbursementModal('create-manual-disbursement');
    }, [paymentTransactionToManualDisbursement]);

    const handleManualDisbursementByBankAccount = useCallback(() => {
        if (!validateManualDIsbursementBankAccount(paymentTransactionToManualDisbursement)) return;

        setManualDisbursementModal('create-manual-disbursement');
    }, [paymentTransactionToManualDisbursement]);

    const handleModalAction: Record<ManualDisbursementModalType, () => void> = useMemo(() => {
        return {
            cancel: () => history.push('/payment-transactions'),
            'create-manual-disbursement': () => {
                if (!paymentTransactionToManualDisbursement) return;

                dispatch(manualDisbursementPaymentTransactionRequest(paymentTransactionToManualDisbursement));
            },
        };
    }, [dispatch, history, paymentTransactionToManualDisbursement]);

    const handleManualDisbursementModal = useCallback(() => {
        setShowValidation(true);

        if (!paymentTransactionToManualDisbursement?.bankAccount) {
            handleManualDisbursementByPixKey();
            return;
        }

        handleManualDisbursementByBankAccount();
    }, [handleManualDisbursementByPixKey, handleManualDisbursementByBankAccount, paymentTransactionToManualDisbursement?.bankAccount]);

    const isCreateManualDisbursementDisabled: boolean =
        !paymentTransactionToManualDisbursement?.bankAccount && !paymentTransactionToManualDisbursement?.pix;

    return (
        <>
            <div className="scd-payment-transaction__detail--form-infos">
                <div>
                    <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.label`)}>
                        <div className="scd-payment-transaction__detail--form-header">
                            <BaseCheckboxInput
                                label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.use-pix-key`)}
                                value={usePixKey}
                                onChange={handleCheckbox}
                                color="primary"
                            />
                            <DescriptionIconButton onClick={() => setIsDescriptionModalOpen(true)} />
                        </div>
                        {usePixKey ? (
                            <>
                                <div className="scd-payment-transaction__detail--form-item">
                                    <ValidSelectInput
                                        label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.type`)}
                                        placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.type`)}
                                        value={paymentTransactionToManualDisbursement?.pix?.pixKeyType ?? ''}
                                        validate={() =>
                                            validateManualDisbursementPixKeyType(paymentTransactionToManualDisbursement?.pix?.pixKeyType)
                                        }
                                        showValidation={showValidation}
                                        externalUpdate
                                    >
                                        <MenuItem
                                            value={PixKeyType.CPF}
                                            onClick={() =>
                                                handleChange({
                                                    pix: {
                                                        pixKey: undefined,
                                                        pixKeyType: PixKeyType.CPF,
                                                    },
                                                })
                                            }
                                        >
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.INDIVIDUAL`)}
                                        </MenuItem>
                                        <MenuItem
                                            value={PixKeyType.CNPJ}
                                            onClick={() =>
                                                handleChange({
                                                    pix: {
                                                        pixKey: undefined,
                                                        pixKeyType: PixKeyType.CNPJ,
                                                    },
                                                })
                                            }
                                        >
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.CORPORATION`)}
                                        </MenuItem>
                                        <MenuItem
                                            value={PixKeyType.EMAIL}
                                            onClick={() =>
                                                handleChange({
                                                    pix: {
                                                        pixKey: undefined,
                                                        pixKeyType: PixKeyType.EMAIL,
                                                    },
                                                })
                                            }
                                        >
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.EMAIL`)}
                                        </MenuItem>
                                        <MenuItem
                                            value={PixKeyType.PHONE}
                                            onClick={() =>
                                                handleChange({
                                                    pix: {
                                                        pixKey: undefined,
                                                        pixKeyType: PixKeyType.PHONE,
                                                    },
                                                })
                                            }
                                        >
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.PHONE_NUMBER`)}
                                        </MenuItem>
                                        <MenuItem
                                            value={PixKeyType.EVP}
                                            onClick={() =>
                                                handleChange({
                                                    pix: {
                                                        pixKey: undefined,
                                                        pixKeyType: PixKeyType.EVP,
                                                    },
                                                })
                                            }
                                        >
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.EVP`)}
                                        </MenuItem>
                                    </ValidSelectInput>
                                </div>
                                <div className="scd-payment-transaction__detail--form-item">{PixKeyInput}</div>
                            </>
                        ) : (
                            <div className="scd-payment-transaction__detail--form-item">
                                <ModalSearch<Bank>
                                    action={searchBanksRequest}
                                    itemSize={SEARCH_ITEM_SIZE_LARGE}
                                    modalTitle={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`)}
                                    modalLabel={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`)}
                                    onSelect={bank => {
                                        handleChange({
                                            bankAccount: {
                                                ...paymentTransactionToManualDisbursement?.bankAccount,
                                                bankId: bank.id,
                                                bankName: bank.name,
                                                bankIdentification: bank.identification,
                                            },
                                        });
                                    }}
                                    renderItem={bank => `${bank.number} - ${bank.name}`}
                                    statusSelector={(state: IRootState) => state.searchBanks.status}
                                    dataSelector={(state: IRootState) => state.searchBanks.banks}
                                    resetState={searchBanksResetState}
                                >
                                    {handleOpen => (
                                        <ValidSearchInput
                                            value={
                                                paymentTransactionToManualDisbursement?.bankAccount?.bankIdentification &&
                                                paymentTransactionToManualDisbursement?.bankAccount?.bankName
                                                    ? `${paymentTransactionToManualDisbursement?.bankAccount?.bankIdentification} - ${paymentTransactionToManualDisbursement?.bankAccount?.bankName}`
                                                    : ''
                                            }
                                            label={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`)}
                                            placeholder={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`)}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            showValidation={showValidation}
                                            validate={validateManualDisbursementBankId}
                                            readOnly
                                            externalUpdate
                                        />
                                    )}
                                </ModalSearch>
                                <div className="scd-payment-transaction__detail--form-item">
                                    <ValidTextNumberInput
                                        label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.agencyNumber`}
                                        placeholder={`${I18N_PREFIX}.inputs.paymentAccountRecipient.agencyNumber`}
                                        onChange={agencyNumber =>
                                            handleChange({
                                                bankAccount: {
                                                    ...paymentTransactionToManualDisbursement?.bankAccount,
                                                    agencyNumber,
                                                },
                                            })
                                        }
                                        value={paymentTransactionToManualDisbursement?.bankAccount?.agencyNumber ?? ''}
                                        showValidation={showValidation}
                                        validate={validateManualDisbursementAgencyNumber}
                                        externalUpdate
                                    />
                                </div>
                                <div className="scd-payment-transaction__detail--form-account-item">
                                    <ValidTextNumberInput
                                        label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountNumber`}
                                        placeholder={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountNumber`}
                                        onChange={accountNumber =>
                                            handleChange({
                                                bankAccount: {
                                                    ...paymentTransactionToManualDisbursement?.bankAccount,
                                                    accountNumber,
                                                },
                                            })
                                        }
                                        value={paymentTransactionToManualDisbursement?.bankAccount?.accountNumber ?? ''}
                                        showValidation={showValidation}
                                        validate={validateManualDisbursementAccountNumber}
                                        externalUpdate
                                    />
                                    <ValidTextNumberInput
                                        label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountDigit`}
                                        placeholder={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountDigit`}
                                        onChange={accountDigit =>
                                            handleChange({
                                                bankAccount: {
                                                    ...paymentTransactionToManualDisbursement?.bankAccount,
                                                    accountDigit,
                                                },
                                            })
                                        }
                                        value={paymentTransactionToManualDisbursement?.bankAccount?.accountDigit ?? ''}
                                        showValidation={showValidation}
                                        validate={validateManualDisbursementAccountDigit}
                                        externalUpdate
                                    />
                                </div>

                                <div className="scd-payment-transaction__detail--form-item">
                                    <ValidSelectInput
                                        label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.label`}
                                        placeholder={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.label`}
                                        mapperFromString={_value => (_value ? BankAccountType[_value] : undefined)}
                                        onChange={accountType =>
                                            handleChange({
                                                bankAccount: {
                                                    ...paymentTransactionToManualDisbursement?.bankAccount,
                                                    accountType: BankAccountType[accountType],
                                                },
                                            })
                                        }
                                        value={paymentTransactionToManualDisbursement?.bankAccount?.accountType ?? ''}
                                        showValidation={showValidation}
                                        validate={validateManualDisbursementAccountType}
                                        externalUpdate
                                    >
                                        <MenuItem value={BankAccountType.CHECKING_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.options.CHECKING_ACCOUNT`)}
                                        </MenuItem>
                                        <MenuItem value={BankAccountType.SAVINGS_ACCOUNT}>
                                            {t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.options.SAVINGS_ACCOUNT`)}
                                        </MenuItem>
                                    </ValidSelectInput>
                                </div>
                            </div>
                        )}
                    </ReadOnlyCard>
                </div>
                <div>
                    <PaymentInstitutionForm
                        handleChange={handleChange}
                        paymentTransactionToManualDisbursement={paymentTransactionToManualDisbursement}
                        showValidation={showValidation}
                        paymentTransaction={paymentTransaction}
                    />
                </div>
            </div>
            <div className="scd-payment-transaction__detail--form-buttons">
                <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setManualDisbursementModal('cancel')} />
                <StandardButtonLegacy
                    label={t(`${I18N_PREFIX}.manual-disbursement`)}
                    disabled={isCreateManualDisbursementDisabled}
                    onClick={handleManualDisbursementModal}
                />
            </div>

            {manualDisbursementModal && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal-message.${manualDisbursementModal}.title`)}
                    children={
                        <p className="scd-payment-transaction__detail--modal-message">
                            <Trans t={t} i18nKey={`${I18N_PREFIX}.modal-message.${manualDisbursementModal}.message`} />
                        </p>
                    }
                    onClose={() => setManualDisbursementModal(undefined)}
                    onCancel={() => setManualDisbursementModal(undefined)}
                    onAction={handleModalAction[manualDisbursementModal]}
                />
            )}

            {isDescriptionModalOpen && (
                <ValidModalTextArea
                    label={t(`${I18N_PREFIX}.inputs.description`)}
                    openModal={isDescriptionModalOpen}
                    onClose={() => setIsDescriptionModalOpen(false)}
                    onChange={description => handleChange({ description })}
                    defaultValue={paymentTransactionToManualDisbursement?.description ?? ''}
                    title={t(`${I18N_PREFIX}.inputs.description`)}
                    showValidation={showValidation}
                    validate={validateManualDisbursementDescription}
                />
            )}
        </>
    );
};

export default BaseDisbursementForm;
