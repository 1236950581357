import { GiroWebappError } from 'model/error';
import { ScdOriginator, ScdOriginatorSimple } from 'model/originator';
import { UpdateOriginatorTypes } from 'reducer/originator/update-originator/types';
import { action } from 'typesafe-actions';

export const updateOriginatorRequest = (originator: ScdOriginator) => action(UpdateOriginatorTypes.UPDATE_ORIGINATOR_REQUEST, originator);
export const updateOriginatorSuccess = (updatedOriginator: ScdOriginatorSimple) =>
    action(UpdateOriginatorTypes.UPDATE_ORIGINATOR_SUCCESS, updatedOriginator);
export const updateOriginatorError = (error: GiroWebappError) => action(UpdateOriginatorTypes.UPDATE_ORIGINATOR_ERROR, error);
export const updateOriginatorResetState = () => action(UpdateOriginatorTypes.UPDATE_ORIGINATOR_RESET_STATE);
