import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.table';

const TransfeeraStatementTableHeader = () => {
    const { t } = useTranslation();

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.date`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.description`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.bank`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.agency`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.account`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.value`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.balance`)} />
        </tr>
    );
};

export default TransfeeraStatementTableHeader;
