import { ScdImportedOperationDisbursementReport } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationDisbursementReportTypes {
    INPORTED_OPERATION_DISBURSEMENT_REPORT_REQUEST = '@@imported-operation-disbursement-report/INPORTED_OPERATION_DISBURSEMENT_REPORT_REQUEST',
    INPORTED_OPERATION_DISBURSEMENT_REPORT_SUCCESS = '@@imported-operation-disbursement-report/INPORTED_OPERATION_DISBURSEMENT_REPORT_SUCCESS',
    INPORTED_OPERATION_DISBURSEMENT_REPORT_ERROR = '@@imported-operation-disbursement-report/INPORTED_OPERATION_DISBURSEMENT_REPORT_ERROR',
    INPORTED_OPERATION_DISBURSEMENT_REPORT_RESET_STATE = '@@imported-operation-disbursement-report/INPORTED_OPERATION_DISBURSEMENT_REPORT_RESET_STATE',
}

export interface ImportedOperationDisbursementReportState extends BaseStoreState {
    readonly disbursementReport?: ScdImportedOperationDisbursementReport;
}
