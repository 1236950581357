import ExternalStrategyDateUtils from 'shared/external/util/external-date-utils';

export enum TransfeeraExternalStatementType {
    BATCH = 'BATCH',
    WITHDRAW = 'WITHDRAW',
    DEPOSIT = 'DEPOSIT',
    REFUND = 'REFUND'
}
export interface TransfeeraExternalStatementFilterRequest {
    initialDate: string;
    endDate: string;
}

export const defaultTransfeeraExternalStatementFilters: TransfeeraExternalStatementFilterRequest = {
    initialDate: ExternalStrategyDateUtils.getFirstDayOfMonth(),
    endDate: ExternalStrategyDateUtils.getTodayDay(),
}

export interface TransfeeraStatementPageable {
    page: number;
}

export interface TransfeeraExternalStatementFilterPageable {
    accountId?: number;
    filter?: TransfeeraExternalStatementFilterRequest;
    pageable?: TransfeeraStatementPageable;
}

export interface TransfeeraExternalStatementMetaData {
    pagination: {
        itemsPerPage: number;
        totalItems: number;
    }
}

interface Bank {
    id?: string;
    code?: string;
    ispb?: string;
    name?: string;
}

interface Person {
    name?: string;
    document?: string;
    account_type?: string;
    account?: string;
    account_digit?: string;
    agency?: string;
    bank?: Bank;
}

interface Complement {
    isPix?: boolean;
    pixKey?: string;
    pix_description?: string | null;
    isTev?: boolean;
    bankAccountId?: number;
    bank?: Bank;
    account?: string;
    agency?: string;
    txid?: string | null;
    pixQRCodeId?: string | null;
    integrationId?: string | null;
    valueDetail?: string | null;
    internalTags?: string[] | null;
    end2end_id?: string;
    timestamp?: string;
    payer?: Person;
    receiver?: Person;
    pixPayinId?: string;
}

export interface TransfeeraExternalStatementEntries {
    id?: string | null;
    company?: string | null;
    date?: Date | null;
    description?: string | null;
    type?: TransfeeraExternalStatementType | null;
    value?: number | null;
    balance?: number | null;
    complement?: Complement;
    receipt_file_name?: string;
    updated_at?: Date | null;
    created_at?: Date | null
}

interface StatementBalanceResponse {
    date?: Date | null;
    value?: number | null
}

export interface TransfeeraExternalStatementProxyResponse {
    afterBalance: StatementBalanceResponse;
    prevBalance: StatementBalanceResponse;
    metadata: TransfeeraExternalStatementMetaData;
    entries: ReadonlyArray<TransfeeraExternalStatementEntries>
}
