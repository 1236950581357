import { Button, CircularProgress } from '@material-ui/core';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import {
    TransfeeraExternalTransfersData,
    TransfeeraExternalTransfersGeneralResponse,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';
import { JsonUtils } from 'shared/external/util/json-util';

import './transfeera-transfers-infinite-scroll.scss';

interface TransfeeraInfiniteScroll {
    handlePageChange: () => void;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    dataSelector: (state: IRootState) => ExternalTransfersResponse | undefined;
    setPage: (value: number) => void;
    handleAction: () => void;
    currentPage: number;
}

const useTransfeeraInfiniteScroll = (props: TransfeeraInfiniteScroll) => {
    const { handlePageChange, dataSelector, statusSelector, setPage, handleAction, currentPage } = props;

    const { t } = useTranslation();

    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);
    const response = useSelector<IRootState, ExternalTransfersResponse | undefined>(dataSelector);

    const parsedResponse = useMemo((): TransfeeraExternalTransfersGeneralResponse | undefined => {
        return JsonUtils.parseJsonString(response?.response) as TransfeeraExternalTransfersGeneralResponse;
    }, [response]);

    const addToList = useCallback(() => {
        const newData = parsedResponse?.data ?? [];
        setItems(prev => [...prev, ...newData]);
    }, [parsedResponse]);

    const [items, setItems] = useState<ReadonlyArray<TransfeeraExternalTransfersData>>(parsedResponse?.data ?? []);

    const isLoading = useMemo(() => {
        return status === HttpRequestStatus.ON_GOING;
    }, [status]);

    const isDisabled = useMemo(() => {
        if (items.length === parsedResponse?.metadata.pagination.totalItems) {
            return true;
        }

        return false;
    }, [items, parsedResponse?.metadata.pagination.totalItems]);

    const resetInfiniteScrollListing = useCallback(() => {
        setItems([]);
    }, []);

    const nextPage = useCallback(() => {
        if (!isDisabled) handlePageChange();
    }, [handlePageChange, isDisabled]);

    const handleRefresh = useCallback(() => {
        if (currentPage === 0) return;

        resetInfiniteScrollListing();
        setPage(0);
    }, [resetInfiniteScrollListing, setPage, currentPage]);

    useEffect(() => {
        handleAction();
    }, [handleAction]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        addToList();
    }, [status, addToList]);

    const InfiniteScrollButton: React.FC = () => (
        <footer className="page-container--transfeera-transfers-infinite-scroll">
            {isLoading && !!items.length && (
                <div className="page-container--transfeera-transfers-infinite-scroll--loading">
                    <CircularProgress size="35px" style={{ color: '#3f41d1' }} />
                </div>
            )}
            {!isLoading && !!items.length && (
                <Button variant="contained" onClick={nextPage} disabled={isDisabled} disableRipple>
                    {t('infinite-scroll.more')}
                </Button>
            )}
        </footer>
    );

    return { InfiniteScrollButton, items, resetInfiniteScrollListing, isLoading, handleRefresh };
};

export default useTransfeeraInfiniteScroll;
