import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramPolicy } from 'model/program-policy';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListProgramPoliciesTypes {
    LIST_PROGRAM_POLICIES_REQUEST = '@@programs-policies-list/LIST_PROGRAM_POLICIES_REQUEST',
    LIST_PROGRAM_POLICIES_SUCCESS = '@@programs-policies-list/LIST_PROGRAM_POLICIES_SUCCESS',
    LIST_PROGRAM_POLICIES_ERROR = '@@programs-policies-list/LIST_PROGRAM_POLICIES_ERROR',
    LIST_PROGRAM_POLICIES_RESET_STATE = '@@programs-policies-list/LIST_PROGRAM_POLICIES_RESET_STATE',
}

export interface ListProgramPoliciesState extends BaseStoreState {
    readonly programPolicies?: Page<ScdProgramPolicy>;
}
