import { RequestLogModelBrs } from 'model/brs/request-log-brs';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export enum RequestLogsListTypesBrs {
    REQUEST_LOGS_LIST_BRS_REQUEST = '@@request-log-list-brs/REQUEST_LOGS_LIST_BRS_REQUEST',
    REQUEST_LOGS_LIST_BRS_SUCCESS = '@@request-log-list-brs/REQUEST_LOGS_LIST_BRS_SUCCESS',
    REQUEST_LOGS_LIST_BRS_ERROR = '@@request-log-list-brs/REQUEST_LOGS_LIST_BRS_ERROR',
    REQUEST_LOGS_LIST_BRS_STATE = '@@request-log-list-brs/REQUEST_LOGS_LIST_BRS_STATE',
}

export interface RequestLogListBrsState extends BaseStoreState {
    readonly requestLogList?: Page<RequestLogModelBrs>;
}
