import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOriginatorSimple } from 'model/originator';

export type ApiResponse = Record<string, string>;

export enum UpdateOriginatorTypes {
    UPDATE_ORIGINATOR_REQUEST = '@@originator-update/UPDATE_ORIGINATOR_REQUEST',
    UPDATE_ORIGINATOR_SUCCESS = '@@originator-update/UPDATE_ORIGINATOR_SUCCESS',
    UPDATE_ORIGINATOR_ERROR = '@@originator-update/UPDATE_ORIGINATOR_ERROR',
    UPDATE_ORIGINATOR_RESET_STATE = '@@originator-update/UPDATE_ORIGINATOR_RESET_STATE',
}

export interface UpdateOriginatorState extends BaseStoreState {
    readonly originator?: ScdOriginatorSimple;
}
