import clsx from 'clsx';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import PoliciesHeader from 'entities/issuer/components/policies-header/policies-header';
import ListPolicies from 'entities/issuer/issuer-policies/components/list-policies/list-policies';
import { IssuerListPolicies } from 'model/issuer-policy';
import { IssuerPoliciesPageable } from 'model/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { listIssuerPoliciesRequest, listIssuerPoliciesResetState } from 'reducer/issuer-policy/list-issuer-policies/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './issuer-policies.scss';

type ListIssuerPoliciesProps = RouteComponentProps<{ issuerId: string }>;

const I18N_PREFIX = 'pages.scd.issuer-policy';

const ListIssuerPolicies = (props: ListIssuerPoliciesProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [issuerId] = useState<number>(Number(props.match.params.issuerId));
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const filterPageable = useCallback((): IssuerPoliciesPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        return {
            pageable: _pageable,
            issuerId,
        };
    }, [sortedProperty, sortOrder, page, issuerId]);

    const handlePageChange = () => {
        setPage(page + 1);
    };

    const handleSort = (property: keyof IssuerListPolicies, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const {
        InfiniteScrollButton,
        items: policiesItems,
        resetInfiniteScrollListing,
        isLoading,
        handleRefresh,
    } = useInfiniteScrollLegacy<IssuerListPolicies>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listPoliciesIssuer.issuerPolicies,
        statusSelector: (state: IRootState) => state.listPoliciesIssuer.status,
        action: listIssuerPoliciesRequest,
        filterPageable,
        setPage,
    });

    useEffect(() => {
        return () => {
            dispatch(listIssuerPoliciesResetState());
        };
    }, [dispatch]);

    return (
        <main className="issuer-policies">
            <section className="issuer-policies__container">
                <ContextRibbon />
                <header className="issuer-policies__header">
                    <h2 className="issuer-policies__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.buttons.add-policy`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="issuer-policies__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !policiesItems.length,
                        })}
                    >
                        <thead>
                            <PoliciesHeader
                                policies={policiesItems}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                disabledSort={false}
                            />
                        </thead>
                        <tbody>
                            <ListPolicies policies={policiesItems} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default ListIssuerPolicies;
