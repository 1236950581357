import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateOriginatorTypes, UpdateOriginatorState } from 'reducer/originator/update-originator/types';
import { Reducer } from 'redux';

export const initialState: UpdateOriginatorState = {
    status: HttpRequestStatus.NOOP,
    originator: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateOriginatorState> = (state = initialState, action): UpdateOriginatorState => {
    switch (action.type) {
        case UpdateOriginatorTypes.UPDATE_ORIGINATOR_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateOriginatorTypes.UPDATE_ORIGINATOR_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, originator: action.payload, error: undefined };
        }
        case UpdateOriginatorTypes.UPDATE_ORIGINATOR_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateOriginatorTypes.UPDATE_ORIGINATOR_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateOriginatorReducer };
