import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramList } from 'model/program';
import { listProgramsError, listProgramsSuccess, listProgramsRequest } from 'reducer/program/list-programs/actions';
import { ListProgramsTypes } from 'reducer/program/list-programs/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';

function* handleListPrograms(action: ReturnType<typeof listProgramsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdProgramList>> = yield call(programApi.listPrograms, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listProgramsError(mapped));
            return;
        }
        yield put(listProgramsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listProgramsError(mapped));
    }
}

function* watchListPrograms() {
    yield takeEvery(ListProgramsTypes.LIST_PROGRAMS_REQUEST, handleListPrograms);
}

function* listProgramsSaga() {
    yield all([fork(watchListPrograms)]);
}

export default listProgramsSaga;
