import { all, fork } from '@redux-saga/core/effects';
import { AnyAction, ReducersMapObject } from 'redux';

// List BatchOutputs
import { batchOutputsListBcoReducer } from 'reducer-bco/batch-output/list-batch-output/reducer';
import batchOutputsListBcoSaga from 'reducer-bco/batch-output/list-batch-output/sagas';
import { BatchOutputsListBcoState } from 'reducer-bco/batch-output/list-batch-output/types';

// Details BatchOutput
import { batchOutputDetailsBcoReducer } from 'reducer-bco/batch-output/details-batch-output/reducer';
import batchOutputDetailsBcoSaga from 'reducer-bco/batch-output/details-batch-output/sagas';
import { BatchOutputsDetailsBcoState } from 'reducer-bco/batch-output/details-batch-output/types';

// ChunkStreamToResume
import { ChunkStreamToResumeBcoReducer } from 'reducer-bco/batch-output/chunk-stream-to-resume/reducer';
import chunkStreamToResumeBcoSaga from 'reducer-bco/batch-output/chunk-stream-to-resume/sagas';
import { ChunkStreamToResumeBcoState } from 'reducer-bco/batch-output/chunk-stream-to-resume/types';

// ChunkStreamPartitioning
import { ChunkStreamPartitioningBcoReducer } from 'reducer-bco/batch-output/chunk-stream-partitioning/reducer';
import chunkStreamPartitioningBcoSaga from 'reducer-bco/batch-output/chunk-stream-partitioning/sagas';
import { ChunkStreamPartitioningBcoState } from 'reducer-bco/batch-output/chunk-stream-partitioning/types';

// CessionToResume
import { CessionToResumeBcoReducer } from 'reducer-bco/batch-output/cession-to-resume/reducer';
import cessionToResumeBcoSaga from 'reducer-bco/batch-output/cession-to-resume/sagas';
import { CessionToResumeBcoState } from 'reducer-bco/batch-output/cession-to-resume/types';

// ChunkStreamProcess
import { ChunkStreamProcessBcoReducer } from 'reducer-bco/batch-output/chunk-stream-process/reducer';
import chunkStreamProcessBcoSaga from 'reducer-bco/batch-output/chunk-stream-process/sagas';
import { ChunkStreamProcessBcoState } from 'reducer-bco/batch-output/chunk-stream-process/types';

// ChunkCessionFinalize
import { ChunkCessionFinalizeBcoReducer } from 'reducer-bco/batch-output/chunk-cession-finalize/reducer';
import chunkCessionFinalizeBcoSaga from 'reducer-bco/batch-output/chunk-cession-finalize/sagas';
import { ChunkCessionFinalizeBcoState } from 'reducer-bco/batch-output/chunk-cession-finalize/types';

// DataChunkRetry
import { dataChunkRetryBcoReducer } from 'reducer-bco/batch-output/retry-data-chunk/reducer';
import dataChunkRetryBcoSaga from 'reducer-bco/batch-output/retry-data-chunk/sagas';
import { DataChunkRetryBcoState } from 'reducer-bco/batch-output/retry-data-chunk/types';

// ChunkStreamRetry
import { chunkStreamRetryBcoReducer } from 'reducer-bco/batch-output/retry-chunk-stream/reducer';
import chunkStreamRetryBcoSaga from 'reducer-bco/batch-output/retry-chunk-stream/sagas';
import { ChunkStreamRetryBcoState } from 'reducer-bco/batch-output/retry-chunk-stream/types';

// CessionOperation
import { CessionOperationBcoState } from 'reducer-bco/batch-output/cession-operation/types';
import { CessionOperationBcoReducer } from 'reducer-bco/batch-output/cession-operation/reducer';
import cessionOperationBcoSaga from 'reducer-bco/batch-output/cession-operation/sagas';

// ProcessCessionOperation
import processCessionOperationBcoSaga from 'reducer-bco/batch-output/process-cession-operation/sagas';
import { ProcessCessionOperationBcoState } from 'reducer-bco/batch-output/process-cession-operation/types';
import { ProcessCessionOperationBcoReducer } from 'reducer-bco/batch-output/process-cession-operation/reducer';

// ExportRequestSummaryResults
import exportRequestSummaryResultsBcoSaga from 'reducer-bco/batch-output/export-request-summary-results/sagas';
import { ExportRequestSummaryResultsBcoReducer } from 'reducer-bco/batch-output/export-request-summary-results/reducer';
import { ExportRequestSummaryResultsBcoState } from 'reducer-bco/batch-output/export-request-summary-results/types';

// ConcludeCession
import { ConcludeCessionBcoReducer } from 'reducer-bco/batch-output/conclude-cession/reducer';
import concludeCessionBcoSaga from 'reducer-bco/batch-output/conclude-cession/sagas';
import { ConcludeCessionBcoState } from 'reducer-bco/batch-output/conclude-cession/types';

export interface BatchOutputBcoState {
    readonly listBatchOutputs: BatchOutputsListBcoState;
    readonly detailsBatchOutput: BatchOutputsDetailsBcoState;
    readonly chunkStreamToResume: ChunkStreamToResumeBcoState;
    readonly chunkStreamPartitioning: ChunkStreamPartitioningBcoState;
    readonly cessionToResume: CessionToResumeBcoState;
    readonly chunkStreamProcess: ChunkStreamProcessBcoState;
    readonly chunkCessionFinalize: ChunkCessionFinalizeBcoState;
    readonly dataChunkRetry: DataChunkRetryBcoState;
    readonly chunkStreamRetry: ChunkStreamRetryBcoState;
    readonly listCessionOperation: CessionOperationBcoState;
    readonly processCessionOperation: ProcessCessionOperationBcoState;
    readonly exportRequestSummaryResults: ExportRequestSummaryResultsBcoState;
    readonly concludeCession: ConcludeCessionBcoState;
}

export const BatchOutputBcoReducer: ReducersMapObject<BatchOutputBcoState, AnyAction> = {
    listBatchOutputs: batchOutputsListBcoReducer,
    detailsBatchOutput: batchOutputDetailsBcoReducer,
    chunkStreamToResume: ChunkStreamToResumeBcoReducer,
    chunkStreamPartitioning: ChunkStreamPartitioningBcoReducer,
    cessionToResume: CessionToResumeBcoReducer,
    chunkStreamProcess: ChunkStreamProcessBcoReducer,
    chunkCessionFinalize: ChunkCessionFinalizeBcoReducer,
    dataChunkRetry: dataChunkRetryBcoReducer,
    chunkStreamRetry: chunkStreamRetryBcoReducer,
    listCessionOperation: CessionOperationBcoReducer,
    processCessionOperation: ProcessCessionOperationBcoReducer,
    concludeCession: ConcludeCessionBcoReducer,
    exportRequestSummaryResults: ExportRequestSummaryResultsBcoReducer
};

export function* batchOutputBcoSagas() {
    yield all([
        fork(batchOutputsListBcoSaga),
        fork(batchOutputDetailsBcoSaga),
        fork(chunkStreamToResumeBcoSaga),
        fork(chunkStreamPartitioningBcoSaga),
        fork(cessionToResumeBcoSaga),
        fork(chunkStreamProcessBcoSaga),
        fork(chunkCessionFinalizeBcoSaga),
        fork(dataChunkRetryBcoSaga),
        fork(chunkStreamRetryBcoSaga),
        fork(cessionOperationBcoSaga),
        fork(processCessionOperationBcoSaga),
        fork(concludeCessionBcoSaga),
        fork(exportRequestSummaryResultsBcoSaga)
    ]);
}
