import { ScdBatchData, ScdBatchDeleteOperation } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { DeleteBatchOperationTypes } from 'reducer/batch/delete-batch-operation/types';
import { action } from 'typesafe-actions';

export const deleteBatchOperationRequest = (request: ScdBatchDeleteOperation) =>
    action(DeleteBatchOperationTypes.DELETE_BATCH_OPERATION_REQUEST, request);
export const deleteBatchOperationSuccess = (batch: ScdBatchData) => action(DeleteBatchOperationTypes.DELETE_BATCH_OPERATION_SUCCESS, batch);
export const deleteBatchOperationError = (error: GiroWebappError) => action(DeleteBatchOperationTypes.DELETE_BATCH_OPERATION_ERROR, error);
export const deleteBatchOperationResetState = () => action(DeleteBatchOperationTypes.DELETE_BATCH_OPERATION_RESET);
