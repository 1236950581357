import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { BatchDataSortableProperties } from 'entities/batch/batch';
import { ScdBatchData } from 'model/batch';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.batch.table';

interface ScdBatchHeaderProps {
    batches: ScdBatchData[];
    handleSort: (property: BatchDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort: boolean;
}

export const ScdBatchHeader = (props: ScdBatchHeaderProps) => {
    const { t } = useTranslation();
    const { batches, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: BatchDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (batches && batches.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.date`).toUpperCase()} sortable={mapSortable('createdDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.code`).toUpperCase()} sortable={mapSortable('code')} />
            <SortableColumn label={t(`${I18N_PREFIX}.nominalValue`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.originator`).toUpperCase()} sortable={mapSortable('originatorName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.numberOfOperations`).toUpperCase()} />
            <SortableColumn
                label={t(`${I18N_PREFIX}.yearlySessionInterestRatePercentage`).toUpperCase()}
                sortable={mapSortable('yearly_session_interest_rate')}
            />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
};

export default ScdBatchHeader;
