import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum MonthlyCommissionRectifyTaxesTypes {
    MONTHLY_COMMISSION_RECTIFY_TAXES_REQUEST = '@@monthly-commission-rectify-taxes/MONTHLY_COMMISSION_RECTIFY_TAXES_REQUEST',
    MONTHLY_COMMISSION_RECTIFY_TAXES_SUCCESS = '@@monthly-commission-rectify-taxes/MONTHLY_COMMISSION_RECTIFY_TAXES_SUCCESS',
    MONTHLY_COMMISSION_RECTIFY_TAXES_ERROR = '@@monthly-commission-rectify-taxes/MONTHLY_COMMISSION_RECTIFY_TAXES_ERROR',
    MONTHLY_COMMISSION_RECTIFY_TAXES_RESET = '@@monthly-commission-rectify-taxes/MONTHLY_COMMISSION_RECTIFY_TAXES_RESET',
}

export interface MonthlyCommissionRectifyTaxesState extends BaseStoreState {
    readonly monthlyCommission?: ScdMonthlyCommissionSimple;
}
