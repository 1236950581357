import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanyItem } from 'model/company-item';
import companyItemAPI from 'services/api/company-item-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListCompanyItemsTypes } from 'reducer/company-item/list-company-items/types';
import { listCompanyItemsError, listCompanyItemsSuccess, listCompanyItemsRequest } from 'reducer/company-item/list-company-items/actions';
import { Page } from 'services/page';

function* handleListCompanyItems(action: ReturnType<typeof listCompanyItemsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompanyItem>> = yield call(companyItemAPI.listCompanyItems, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listCompanyItemsError(mapped));
            return;
        }
        yield put(listCompanyItemsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listCompanyItemsError(mapped));
    }
}

function* watchListCompanyItems() {
    yield takeEvery(ListCompanyItemsTypes.LIST_COMPANY_ITEMS_REQUEST, handleListCompanyItems);
}

function* listCompanyItemsSaga() {
    yield all([fork(watchListCompanyItems)]);
}

export default listCompanyItemsSaga;
