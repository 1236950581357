import { EnvironmentUtils } from 'shared/util/environment-utils';


const logInfo = (message?: any, ...optionalParams: any[]): void => {
    if (!EnvironmentUtils.isDevelopmentEnvironment()) return;
    console.log(message, ...optionalParams);
};

const debugInfo = (message?: any, ...optionalParams: any[]): void => {
    if (!EnvironmentUtils.isDevelopmentEnvironment()) return;
    console.debug(message, ...optionalParams);
};

const LoggingUtils = {
    logInfo,
    debugInfo,
};

export default LoggingUtils;
