import { BatchSortPageable } from 'entities/batch/batch-detail-operations/batch-operations-detail';
import { GiroWebappError } from 'model/error';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { ImportedOperationsBatchTypes } from 'reducer/imported-operation/batch/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const importedOperationsBatchRequest = (request: BatchSortPageable) =>
    action(ImportedOperationsBatchTypes.IMPORTED_OPERATIONS_BATCH_REQUEST, request);
export const importedOperationsBatchSuccess = (batchOperations: Page<ScdImportedOperationBatch>) =>
    action(ImportedOperationsBatchTypes.IMPORTED_OPERATIONS_BATCH_SUCCESS, batchOperations);
export const importedOperationsBatchError = (error: GiroWebappError) =>
    action(ImportedOperationsBatchTypes.IMPORTED_OPERATIONS_BATCH_ERROR, error);
export const importedOperationsBatchResetState = () => action(ImportedOperationsBatchTypes.IMPORTED_OPERATIONS_BATCH_RESET_STATE);
