import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateCompanyItemTypes, UpdateCompanyItemState } from 'reducer/company-item/update-company-item/types';

const initialState: UpdateCompanyItemState = {
    status: HttpRequestStatus.NOOP,
    companyItem: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateCompanyItemState> = (state = initialState, action): UpdateCompanyItemState => {
    switch (action.type) {
        case UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyItem: action.payload, error: undefined };
        }
        case UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateCompanyItemReducer };
