export interface ScdOrganization {
    id?: string;
    name?: string;
    organizationKey?: string;
    identification?: string;
}

export const defaultScdOrganization: ScdOrganization = {};

export const defaultOrganizationToCreate: ScdOrganization = {
    id: undefined,
    name: undefined,
    organizationKey: undefined,
    identification: undefined,
};

export interface ScdOrganizationList {
    id?: number;
    name?: string;
    organizationKey?: string;
    identification?: string;
}

export interface ScdOrganizationSimple {
    id?: string;
    name?: string;
    organizationKey?: string;
}

export interface ScdOrganizationChangeTokenResponse {
    id?: number;
    name?: string;
    organizationKey?: string;
    jwtToken?: string;
}

export interface ScdOrganizationToGetCompany {
    id?: number;
    identification?: string;
    name?: string;
}
