import { ScdRequestLogData } from 'model/request-log';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListRequestLogTypes {
    LIST_REQUEST_LOG_REQUEST = '@@request-log-list/LIST_REQUEST_LOG_REQUEST',
    LIST_REQUEST_LOG_SUCCESS = '@@request-log-list/LIST_REQUEST_LOG_SUCCESS',
    LIST_REQUEST_LOG_ERROR = '@@request-log-list/LIST_REQUEST_LOG_ERROR',
    LIST_REQUEST_LOG_RESET_STATE = '@@request-log-list/LIST_REQUEST_LOG_RESET_STATE',
}

export interface ListRequestLogState extends BaseStoreState {
    readonly requestLogs?: Page<ScdRequestLogData>;
}
