import Loading from 'components/loading/loading';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import TransfeeraTransfersRow, {
    TransfeeraTransfersRowProps,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-row/transfeera-transfers-row';
import { TransfeeraExternalTransfersData } from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { LIST_HEIGHT, LIST_ITEMS_SIZE } from 'shared/infinite-components/constants';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers';

interface TransfeeraTransfersListProps {
    isLoading: boolean;
    externalTransfersItems: readonly TransfeeraExternalTransfersData[];
    setExternalTransference: (value: TransfeeraExternalTransfersData) => void;
}

const TransfeeraTransfersList = (props: TransfeeraTransfersListProps) => {
    const { externalTransfersItems, isLoading, setExternalTransference } = props;

    const { t } = useTranslation();

    const transfeeraExternalTransfersObjectData: TransfeeraTransfersRowProps = {
        transfeeraExternalTransferences: externalTransfersItems,
        setExternalTransference,
    };

    return (
        <>
            {isLoading && !externalTransfersItems.length && <Loading isTable />}
            {externalTransfersItems.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={transfeeraExternalTransfersObjectData}
                            height={height}
                            width={width}
                            itemSize={LIST_ITEMS_SIZE}
                            itemCount={externalTransfersItems.length}
                        >
                            {TransfeeraTransfersRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="transfeera-transfers__table-empty-filter">
                            <span className="transfeera-transfers__table-empty-filter-image" />
                            <span className="transfeera-transfers__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TransfeeraTransfersList;
