import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidLocalDateInput from 'components/inputs/date-input/valid-local-date-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import TaxValueMask from 'entities/tax/components/inputs/masks/tax-value-mask';
import ValidTaxValueInput from 'entities/tax/components/inputs/valid-tax-value-input/valid-tax-value-input';
import { validateScdTax, validateTaxDate, validateTaxType, validateTaxValue } from 'entities/tax/validation/tax-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { TaxType } from 'model/enums/tax-type';
import { ScdTaxToCreate, defaultScdTaxToCreate } from 'model/tax';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreateTaxState, useRootDispatch } from 'reducer/hooks';
import { createTaxRequest, createTaxResetState } from 'reducer/tax/create-tax/actions';
import './tax-create.scss';

const I18N_PREFIX = 'pages.scd.tax';

const ScdTaxCreatePage = () => {
    const { t } = useTranslation();
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateTaxState();

    const [scdTax, setScdTax] = useState<ScdTaxToCreate>(defaultScdTaxToCreate);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);
        if (!validateScdTax(scdTax)) return;

        dispatch(createTaxRequest(scdTax));
    };

    const handleChange = (value: Partial<ScdTaxToCreate>) => {
        setScdTax(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push('/taxes');
        }
    }, [createStatus, history]);

    useEffect(() => {
        return () => {
            dispatch(createTaxResetState());
        };
    }, [dispatch]);

    return (
        <main className="scd-tax-create__create">
            <ContextRibbon />
            <div className="scd-tax-create--container">
                <header className="scd-tax-create--header">
                    <h2 className="scd-tax-create--header-title">{t(`${I18N_PREFIX}.create.title`)}</h2>
                </header>
                <form className="scd-tax-create--form">
                    <div className="scd-tax-create--form-field">
                        <ValidTaxValueInput
                            label={t(`${I18N_PREFIX}.input.value.label`)}
                            value={scdTax?.value ?? 0}
                            placeholder={t(`${I18N_PREFIX}.input.value.placeholder`)}
                            onChange={value => handleChange({ value })}
                            validate={validateTaxValue}
                            showValidation={showValidation}
                            MaskInputComponent={TaxValueMask}
                            externalUpdate
                        />
                    </div>
                    <div className="scd-tax-create--form-field">
                        <ValidLocalDateInput
                            label={t(`${I18N_PREFIX}.input.date.label`)}
                            value={scdTax?.date ?? ''}
                            placeholder={t(`${I18N_PREFIX}.input.date.placeholder`)}
                            onChange={date => handleChange({ date })}
                            validate={validateTaxDate}
                            showValidation={showValidation}
                            externalUpdate
                        />
                    </div>
                    <div className="scd-tax-create--form-field">
                        <ValidSelectInput
                            label={t(`${I18N_PREFIX}.input.type.label`)}
                            value={scdTax?.type ?? ''}
                            validate={validateTaxType}
                            mapperFromString={_value => (_value ? TaxType[_value] : undefined)}
                            showValidation={showValidation}
                            placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                            onChange={(type: string) => handleChange({ type: TaxType[type] })}
                            externalUpdate
                        >
                            <MenuItem value={TaxType.PERSONAL_IOF}>{t(`${I18N_PREFIX}.input.type.options.PERSONAL_IOF`)}</MenuItem>
                            <MenuItem value={TaxType.PERSONAL_ADDITIONAL_IOF}>
                                {t(`${I18N_PREFIX}.input.type.options.PERSONAL_ADDITIONAL_IOF`)}
                            </MenuItem>
                            <MenuItem value={TaxType.CORPORATION_IOF}>{t(`${I18N_PREFIX}.input.type.options.CORPORATION_IOF`)}</MenuItem>
                            <MenuItem value={TaxType.CORPORATION_ADDITIONAL_IOF}>
                                {t(`${I18N_PREFIX}.input.type.options.CORPORATION_ADDITIONAL_IOF`)}
                            </MenuItem>
                        </ValidSelectInput>
                    </div>
                    <div className="scd-tax-create--form-buttons">
                        <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                        <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                    </div>
                </form>
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/taxes');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdTaxCreatePage;
