import { AxiosResponse } from 'axios';
import { ScdCompanyGroupPolicySimple } from 'model/company-group-policy';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    updateCompanyGroupPolicyError,
    updateCompanyGroupPolicyRequest,
    updateCompanyGroupPolicySuccess,
} from 'reducer/company-group-policy/update-company-group-policy/actions';
import { UpdateCompanyGroupPolicyTypes } from 'reducer/company-group-policy/update-company-group-policy/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyGroupPolicyAPI from 'services/api/company-group-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateCompanyGroupPolicy(action: ReturnType<typeof updateCompanyGroupPolicyRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyGroupPolicySimple> = yield call(
            companyGroupPolicyAPI.updateCompanyGroupPolicy,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateCompanyGroupPolicyError(mapped));
            return;
        }
        yield put(updateCompanyGroupPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateCompanyGroupPolicyError(mapped));
    }
}

function* watchUpdateCompanyGroupPolicy() {
    yield takeEvery(UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_REQUEST, handleUpdateCompanyGroupPolicy);
}

function* updateCompanyGroupPolicySaga() {
    yield all([fork(watchUpdateCompanyGroupPolicy)]);
}

export default updateCompanyGroupPolicySaga;
