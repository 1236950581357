import Button from '@material-ui/core/Button';
import './action-button.scss';

export interface ActionButtonProps {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    isLoading?: boolean;
    endAdornment?: Element | React.ReactElement;
    startAdornment?: Element | React.ReactElement;
}

export const ActionButton = (props: ActionButtonProps): JSX.Element => {
    const { startAdornment, endAdornment, disabled, label, isLoading } = props;

    const handleClick = () => {
        if (!(props.isLoading || props.disabled)) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--action">
            <Button
                variant="contained"
                startIcon={startAdornment}
                endIcon={endAdornment}
                onClick={handleClick}
                disabled={disabled || isLoading}
            >
                <span>{label}</span>
            </Button>
        </div>
    );
};

export default ActionButton;
