import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ScdIssuerHeader from 'entities/issuer/components/issuer-header/issuer-header';
import IssuerList from 'entities/issuer/components/issuer-list/issuer-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdIssuerList } from 'model/issuer';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { listIssuersRequest } from 'reducer/issuer/list-issuers/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './issuer.scss';

const I18N_PREFIX = 'pages.scd.issuer';

const ScdIssuerPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };
    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
    } = useInfiniteScrollLegacy<ScdIssuerList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listIssuers.issuers,
        statusSelector: (state: IRootState) => state.listIssuers.status,
        action: listIssuersRequest,
        filterPageable: sortPageable,
        setPage,
    });
    const hasError = listStatus === HttpRequestStatus.ERROR;

    const handleRefresh = () => {
        dispatch(listIssuersRequest(sortPageable()));
    };

    const handleSort = (property: keyof ScdIssuerList, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.issuer.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="scd-issuer">
            <section className="scd-issuer__container">
                <header className="scd-issuer__header">
                    <h2 className="scd-issuer__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="scd-issuer__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdIssuerHeader
                                issuers={items}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                disabledSort={false}
                            />
                        </thead>
                        <tbody>
                            <IssuerList issuer={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default ScdIssuerPage;
