import { BatchOutputSignaturesResume } from 'model/batch-signature';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchSignatureResumeTypes {
    BATCH_SIGNATURE_RESUME_REQUEST = '@@batch-signature-resume/BATCH_SIGNATURE_RESUME_REQUEST',
    BATCH_SIGNATURE_RESUME_SUCCESS = '@@batch-signature-resume/BATCH_SIGNATURE_RESUME_SUCCESS',
    BATCH_SIGNATURE_RESUME_ERROR = '@@batch-signature-resume/BATCH_SIGNATURE_RESUME_ERROR',
    BATCH_SIGNATURE_RESUME_RESET = '@@batch-signature-resume/BATCH_SIGNATURE_RESUME_RESET',
}

export interface BatchSignatureResumeState extends BaseStoreState {
    readonly signatureResume?: BatchOutputSignaturesResume
}