import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { PaymentInstitutionsAccountsSortProperties } from 'entities/payment-institutions-accounts/payment-institutions-accounts';
import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

interface PaymentInstitutionAccountsHeaderProps {
    paymentInstitutionsAccounts: PaymentInstitutionAccountToGetAll[];
    sortOrder: SortOrder;
    sortedProperty: PaymentInstitutionsAccountsSortProperties;
    handleSort: (property: PaymentInstitutionsAccountsSortProperties, order: SortOrder) => void;
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.table';

const PaymentInstitutionAccountsHeader = (props: PaymentInstitutionAccountsHeaderProps) => {
    const { handleSort, paymentInstitutionsAccounts, sortOrder, sortedProperty } = props;

    const { t } = useTranslation();

    const mapSortable = (_property: PaymentInstitutionsAccountsSortProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: paymentInstitutionsAccounts && paymentInstitutionsAccounts.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status.title`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn label={t(`${I18N_PREFIX}.type.title`).toUpperCase()} sortable={mapSortable('type')} />
            <SortableColumn
                label={t(`${I18N_PREFIX}.technicalSupplierName`).toUpperCase()}
                sortable={mapSortable('technicalSupplierName')}
            />
            <SortableColumn label={t(`${I18N_PREFIX}.balance`).toUpperCase()} />
        </tr>
    );
};

export default PaymentInstitutionAccountsHeader;
