import { GiroWebappError } from 'model/error';
import { ScdMonthlyCommissionRectifyTaxRequest, ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { MonthlyCommissionRectifyTaxesTypes } from 'reducer/monthly-commission/rectify-taxes/types';
import { action } from 'typesafe-actions';

export const monthlyCommissionRectifyTaxesRequest = (request: ScdMonthlyCommissionRectifyTaxRequest) =>
    action(MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_REQUEST, request);
export const monthlyCommissionRectifyTaxesSuccess = (monthlyCommission: ScdMonthlyCommissionSimple) =>
    action(MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_SUCCESS, monthlyCommission);
export const monthlyCommissionRectifyTaxesError = (error: GiroWebappError) =>
    action(MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_ERROR, error);
export const monthlyCommissionRectifyTaxesResetState = () =>
    action(MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_RESET);
