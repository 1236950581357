import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { ScdOriginatorFilterRequest } from 'model/originator';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseOriginatorFilterChipsProps {
    filterActive: ScdOriginatorFilterRequest;
    setFilterActive: (value: ScdOriginatorFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.originator';

type OriginatorFilterRequestType = keyof Omit<ScdOriginatorFilterRequest, 'paymentInstitutionId' | 'companyId'>;

const useOriginatorFilterChips = (props: UseOriginatorFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: ScdOriginatorFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<OriginatorFilterRequestType, () => void> = useMemo(() => {
        return {
            name: () => clearFilterSupport({ ...filterActive, name: undefined }),
            identification: () => clearFilterSupport({ ...filterActive, identification: undefined }),
            companyName: () => clearFilterSupport({ ...filterActive, companyName: undefined, companyId: undefined }),
            paymentInstitutionName: () =>
                clearFilterSupport({ ...filterActive, paymentInstitutionName: undefined, paymentInstitutionId: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: OriginatorFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filter.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const originatorFilterChips = useMemo(() => {
        const items: Record<OriginatorFilterRequestType, ChipFilterItem> = {
            name: {
                isActive: !!filterActive.name,
                onDelete: handleClearFilter['name'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('name')} component={filterActive.name} />,
            },
            identification: {
                isActive: !!filterActive.identification,
                onDelete: handleClearFilter['identification'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('identification')} component={filterActive.identification} />,
            },
            companyName: {
                isActive: !!filterActive.companyName,
                onDelete: handleClearFilter['companyName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('companyId')} component={filterActive.companyName} />,
            },
            paymentInstitutionName: {
                isActive: !!filterActive.paymentInstitutionName,
                onDelete: handleClearFilter['paymentInstitutionName'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('paymentInstitutionId')}
                        component={filterActive.paymentInstitutionName}
                    />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle]);

    const isOriginatorChipsActive: boolean = useMemo(() => {
        return originatorFilterChips.some(it => !!it.isActive);
    }, [originatorFilterChips]);

    return {
        originatorFilterChips,
        isOriginatorChipsActive,
    };
};

export default useOriginatorFilterChips;
