import { Checkbox } from '@material-ui/core';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import PercentageFormatterWithTwelveDecimals from 'components/formatter/percentage/percentage-formatter-twelve-decimals';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ScdBatchToGenerateCessionResponse } from 'model/batch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './modal-preview.scss';

export interface ModalPreviewProps {
    cession?: ScdBatchToGenerateCessionResponse;
    onClose: () => void;
    onAction?: () => void;
}

const I18N_PREFIX = 'pages.scd.batch.generate-cession.modal-preview';

export const ModalPreview = (props: ModalPreviewProps) => {
    const { onClose, onAction, cession } = props;
    const [isChecked, setIsChecked] = useState(false);

    const { t } = useTranslation();

    return (
        <Modal isOpen>
            <div className="modal-preview">
                <article className="modal-preview--content">
                    <header className="modal-preview--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <p className="modal-preview--title">{t(`${I18N_PREFIX}.title`)}</p>
                    <div className="modal-preview--message">
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.ceded-amount`)}
                            value={<CurrencyFormatter value={cession?.cededAmount} prefix />}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.cession-date`)}
                            value={<DateFormatter date={cession?.cessionDate} format={DateFormat.DATE} />}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.present-amount`)}
                            value={<CurrencyFormatter value={cession?.cessionPresentAmount} prefix />}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.cession-tax-percentage`)}
                            value={<PercentageFormatterWithTwelveDecimals value={cession?.cessionTaxPercentage} />}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.number-of-installments`)}
                            value={cession?.numberOfInstallments ?? '-'}
                        />
                        <ReadOnlyTextField label={t(`${I18N_PREFIX}.label.shipping-number`)} value={cession?.shippingNumber ?? '-'} />
                        <div className="modal-preview__checkbox">
                            <Checkbox color="primary" checked={isChecked} onChange={e => setIsChecked(e.target.checked)} />{' '}
                            {t(`${I18N_PREFIX}.checkbox-preview`)}
                        </div>
                    </div>
                    {onAction && (
                        <div className="modal-preview--actions">
                            {onAction && <StandardButton label={'entity.action.confirm'} onClick={onAction} disabled={!isChecked} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ModalPreview;
