import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import { ScdMonthlyCommissionDetail } from 'model/monthly-commission';
import { useTranslation } from 'react-i18next';
import './monthly-commission-values-card.scss';

const I18N_PREFIX = 'pages.scd.monthly-commission-detail.card.values';

const fallbackValue = '-'.repeat(7);

interface MonthlyCommissionValuesCardProps {
    monthlyCommission: ScdMonthlyCommissionDetail | undefined;
}

export const MonthlyCommissionValuesCard = (props: MonthlyCommissionValuesCardProps) => {
    const { monthlyCommission } = props;

    const { t } = useTranslation();

    return (
        <div className="monthly-commission-values-card">
            <h2 className="monthly-commission-values-card--title">{t(`${I18N_PREFIX}.title`)}</h2>
            <div className="monthly-commission-values-card--item">
                <span className="monthly-commission-values-card--item__label"> {t(`${I18N_PREFIX}.grossValue`)} </span>
                <span className="monthly-commission-values-card--item__value">
                    {monthlyCommission?.grossValue ? <CurrencyFormatter value={monthlyCommission?.grossValue} prefix /> : fallbackValue}
                </span>
            </div>
            <div className="monthly-commission-values-card--item">
                <span className="monthly-commission-values-card--item__label"> {t(`${I18N_PREFIX}.taxValue`)} </span>
                <span className="monthly-commission-values-card--item__value">
                    {monthlyCommission?.taxValue ? <CurrencyFormatter value={monthlyCommission?.taxValue} prefix /> : fallbackValue}
                </span>
            </div>
            <div className="monthly-commission-values-card--item">
                <span className="monthly-commission-values-card--item__label"> {t(`${I18N_PREFIX}.netValue`)} </span>
                <span className="monthly-commission-values-card--item__value">
                    {monthlyCommission?.netValue ? (
                        <strong>
                            <CurrencyFormatter value={monthlyCommission?.netValue} prefix />
                        </strong>
                    ) : (
                        fallbackValue
                    )}
                </span>
            </div>
        </div>
    );
};

export default MonthlyCommissionValuesCard;
