import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ReleaseMonthlyCommissionTypes {
    RELEASE_MONTHLY_COMMISSION_REQUEST = '@@monthly-commission-release/RELEASE_MONTHLY_COMMISSION_REQUEST',
    RELEASE_MONTHLY_COMMISSION_SUCCESS = '@@monthly-commission-release/RELEASE_MONTHLY_COMMISSION_SUCCESS',
    RELEASE_MONTHLY_COMMISSION_ERROR = '@@monthly-commission-release/RELEASE_MONTHLY_COMMISSION_ERROR',
    RELEASE_MONTHLY_COMMISSION_RESET = '@@monthly-commission-release/RELEASE_MONTHLY_COMMISSION_RESET',
}

export interface ReleaseMonthlyCommissionState extends BaseStoreState {
    readonly monthlyCommission?: ScdMonthlyCommissionSimple;
}
