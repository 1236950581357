import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import DescriptionIconButton, {
    DescriptionIconButtonType
} from 'components/buttons/icon-buttons/description/description-icon-button';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import { ListChildComponentProps } from 'react-window';
import {
    TransfeeraExternalStatementEntries,
    TransfeeraExternalStatementType
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import './transfeera-statement-row.scss';

export interface TransfeeraStatementRowProps {
    externalStatementItems: readonly TransfeeraExternalStatementEntries[];
    setSelectedExternalStatement: (value: TransfeeraExternalStatementEntries) => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement';

const TransfeeraStatementRow = (props: ListChildComponentProps<TransfeeraStatementRowProps>) => {
    const { data, index, style } = props;

    const { externalStatementItems, setSelectedExternalStatement } = data;

    const { id, date, description, value, balance, complement, type } = externalStatementItems[index];

    const { bank, account, agency, payer } = complement ?? {};

    const isDeposit = type === TransfeeraExternalStatementType.DEPOSIT;
    const bankName = (isDeposit ? payer?.bank?.name : bank?.name) ?? '-';
    const bankAgency = (isDeposit ? payer?.agency : agency) ?? '-';
    const bankAccount = (isDeposit ? `${payer?.account}-${payer?.account_digit}` : account) ?? '-';
    const isRefund = type === TransfeeraExternalStatementType.REFUND;

    const isAlternativeIcon = isRefund ? DescriptionIconButtonType.ALTERNATIVE : DescriptionIconButtonType.DEFAULT;


    return (
        <tr
            className={clsx('page-container--table-cell', { REFUND: isRefund })}
            style={style}
            key={id}
        >
            <Tooltip title={date ? <DateFormatter date={date} /> : '00/00/00'} interactive>
                <td className="page-container--table-cell-title">{date ? <DateFormatter date={date} /> : '00/00/00'}</td>
            </Tooltip>
            <Tooltip title={description ?? '-'} interactive>
                <td className="page-container--table-cell-title">
                    <span>{description ?? '-'}</span>
                </td>
            </Tooltip>
            <Tooltip title={bankName} interactive>
                <td className="page-container--table-cell-title">{bankName}</td>
            </Tooltip>
            <Tooltip title={bankAgency} interactive>
                <td className="page-container--table-cell-title">{bankAgency}</td>
            </Tooltip>
            <Tooltip title={bankAccount} interactive>
                <td className="page-container--table-cell-title">{bankAccount}</td>
            </Tooltip>
            <Tooltip title={value ? <CurrencyFormatter value={value} prefix /> : '-'} interactive>
                <td className="page-container--table-cell-title">
                    <span>{value ? <CurrencyFormatter value={value} prefix /> : '-'}</span>
                </td>
            </Tooltip>
            <Tooltip title={balance ? <CurrencyFormatter value={balance} prefix /> : '-'} interactive>
                <td className="page-container--table-cell-title">
                    <span>{balance ? <CurrencyFormatter value={balance} prefix /> : '-'}</span>
                </td>
            </Tooltip>
            <td className="page-container--table-cell-title">
                <DescriptionIconButton
                    onClick={() => setSelectedExternalStatement(externalStatementItems[index])}
                    title={`${I18N_PREFIX}.statement-details`}
                    iconType={isAlternativeIcon}
                />
            </td>
        </tr>
    );
};

export default TransfeeraStatementRow;
