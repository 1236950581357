import { useTranslation } from 'react-i18next';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import './page-not-found.scss';

export const PageNotFound = () => {
    const { t } = useTranslation();
    const I18N_PREFIX = 'global.page-not-found';
    return (
        <div className="body__page-not-found">
            <Row style={{ height: '84vh' }}>
                <Col md={{ size: '8', offset: '2' }}>
                    <div className="page-not-found--itens">
                        <div className="page-not-found--image" />
                        <div className="page-not-found--text">
                            <span>{t(`${I18N_PREFIX}.title`)}</span>
                            <div className="page-not-found--subtitle">{t(`${I18N_PREFIX}.subtitle`)}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default PageNotFound;
