import { IssuerPolicyDetail } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum DetailIssuerPolicyTypes {
    DETAIL_ISSUER_POLICY_REQUEST = '@@issuer-detail-policies/DETAIL_ISSUER_POLICY_REQUEST',
    DETAIL_ISSUER_POLICY_SUCCESS = '@@issuer-detail-policies/DETAIL_ISSUER_POLICY_SUCCESS',
    DETAIL_ISSUER_POLICY_ERROR = '@@issuer-detail-policies/DETAIL_ISSUER_POLICY_ERROR',
    DETAIL_ISSUER_POLICY_RESET_STATE = '@@issuer-detail-policies/DETAIL_ISSUER_POLICY_RESET_STATE',
}

export interface DetailIssuerPolicyState extends BaseStoreState {
    readonly issuerPolicyDetail?: IssuerPolicyDetail;
}