import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Limelight list operations
import { limelightListOperationsReducer } from './provisional-limelight-list/reducer'
import limelightListOperationsSaga from 'reducer/provisional-limelight/provisional-limelight-list/sagas';
import { ProvisionalLimelightListState } from 'reducer/provisional-limelight/provisional-limelight-list/types';

// Limelight send brackings
import { limelightAuthorizationSendBackingReducer } from 'reducer/provisional-limelight/provisional-limelight-send/reducer';
import limelightAuthorizationSendBackingsSaga from 'reducer/provisional-limelight/provisional-limelight-send/sagas';
import { limelightAuthorizationSendBackingState } from 'reducer/provisional-limelight/provisional-limelight-send/types';

// Limelight send proxy brackings
import { LimelightProxySendBackingState } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/types';
import { limelightProxySendBackingReducer } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/reducer';
import limelightProxySendBackingsSaga from 'reducer/provisional-limelight/provisional-limelight-proxy-send/sagas';


export interface ProvisionalLimelightState {
    readonly listOperationsLimelight: ProvisionalLimelightListState;
    readonly authorizationSendBackings: limelightAuthorizationSendBackingState;
    readonly hemeraResponse: LimelightProxySendBackingState;
}

export const ProvisionalLimelightReducer: ReducersMapObject<ProvisionalLimelightState, AnyAction> = {
    listOperationsLimelight: limelightListOperationsReducer,
    authorizationSendBackings: limelightAuthorizationSendBackingReducer,
    hemeraResponse : limelightProxySendBackingReducer
}

export function* provisionalLimelightSagas() {
    yield all([
        fork(limelightListOperationsSaga),
        fork(limelightAuthorizationSendBackingsSaga),
        fork(limelightProxySendBackingsSaga)  
    ])
}