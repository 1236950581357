import { Button, CircularProgress } from '@material-ui/core';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import {
    TransfeeraExternalStatementEntries,
    TransfeeraExternalStatementProxyResponse,
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import { ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';
import { JsonUtils } from 'shared/external/util/json-util';

import { TransfeeraConstants } from 'shared/external/constants/transfeera-statment-constant';
import './transfeera-statement-infinite-scroll.scss';

interface TransfeeraInfiniteScroll {
    handlePageChange: () => void;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    dataSelector: (state: IRootState) => ExternalStatementResponse | undefined;
    setPage: (value: number) => void;
    handleAction: () => void;
    currentPage: number;
}

const useTransfeeraStatementInfiniteScroll = (props: TransfeeraInfiniteScroll) => {
    const { handlePageChange, dataSelector, statusSelector, setPage, handleAction, currentPage } = props;

    const { t } = useTranslation();

    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);
    const response = useSelector<IRootState, ExternalStatementResponse | undefined>(dataSelector);

    const parsedResponse = useMemo((): TransfeeraExternalStatementProxyResponse | undefined => {
        return JsonUtils.parseJsonString(response?.response) as TransfeeraExternalStatementProxyResponse;
    }, [response]);

    const addToList = useCallback(() => {
        const newData = parsedResponse?.entries ?? [];
        setItems(prev => [...prev, ...newData]);
    }, [parsedResponse]);

    const [items, setItems] = useState<ReadonlyArray<TransfeeraExternalStatementEntries>>(parsedResponse?.entries ?? []);

    const isLoading = useMemo(() => status === HttpRequestStatus.ON_GOING, [status]);

    const isDisabled = useMemo(() => {
        if (items.length === parsedResponse?.metadata.pagination.totalItems) {
            return true;
        }

        return false;
    }, [items, parsedResponse?.metadata.pagination.totalItems]);

    const resetInfiniteScrollListing = useCallback(() => {
        setItems([]);
    }, []);

    const nextPage = useCallback(() => {
        if (!isDisabled) handlePageChange();
    }, [handlePageChange, isDisabled]);

    const handleRefresh = useCallback(() => {
        if (currentPage === TransfeeraConstants.STATEMENTS_PAGINATION_FIRST_INDEX) return;
        resetInfiniteScrollListing();
        setPage(TransfeeraConstants.STATEMENTS_PAGINATION_FIRST_INDEX);
    }, [resetInfiniteScrollListing, setPage, currentPage]);

    useEffect(() => {
        handleAction();
    }, [handleAction]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;

        addToList();
    }, [status, addToList]);

    const InfiniteScrollButton: React.FC = () => (
        <footer className="page-container--transfeera-statement-infinite-scroll">
            {isLoading && !!items.length && (
                <div className="page-container--transfeera-statement-infinite-scroll--loading">
                    <CircularProgress size="35px" style={{ color: '#3f41d1' }} />
                </div>
            )}

            {!isLoading && !!items.length && (
                <Button variant="contained" onClick={nextPage} disabled={isDisabled} disableRipple>
                    {t('infinite-scroll.more')}
                </Button>
            )}
        </footer>
    );

    return { InfiniteScrollButton, items, resetInfiniteScrollListing, isLoading, handleRefresh };
};

export default useTransfeeraStatementInfiniteScroll;
