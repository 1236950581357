import { GiroWebappError } from 'model/error';
import { ExportRequestSummaryResultsRequestRequired, ExportRequestSummaryResultsResponse } from 'model/bco/batch-output';
import { action } from 'typesafe-actions';
import { ExportRequestSummaryResultsBcoTypes } from 'reducer-bco/batch-output/export-request-summary-results/types';

export const exportRequestSummaryResultsBcoRequest = (request: ExportRequestSummaryResultsRequestRequired) =>
    action(ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_REQUEST, request);
export const exportRequestSummaryResultsBcoSuccess = (exportRequestSummaryResultsResponse: ExportRequestSummaryResultsResponse) =>
    action(ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_SUCCESS, exportRequestSummaryResultsResponse);
export const exportRequestSummaryResultsBcoError = (error: GiroWebappError) =>
    action(ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_ERROR, error);
export const exportRequestSummaryResultsBcoResetState = () =>
    action(ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_RESET);
