import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProvisionalLimelightListState, ProvisionalLimelightTypes } from 'reducer/provisional-limelight/provisional-limelight-list/types';

const initialState: ProvisionalLimelightListState = {
    status: HttpRequestStatus.NOOP,
    limelightListOperations: undefined,
    error: undefined
}

const reducer: Reducer<ProvisionalLimelightListState> = (state = initialState, action): ProvisionalLimelightListState => {
    switch (action.type) {
        case ProvisionalLimelightTypes.LIMELIGHT_LIST_OPERATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ProvisionalLimelightTypes.LIMELIGHT_LIST_OPERATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, limelightListOperations: action.payload, error: undefined };
        }
        case ProvisionalLimelightTypes.LIMELIGHT_LIST_OPERATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProvisionalLimelightTypes.LIMELIGHT_LIST_OPERATIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as limelightListOperationsReducer }