import { BatchOutputHemera } from 'model/batch-output-hemera';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum OutputHemeraTypes {
    BATCH_OUTPUT_HEMERA_REQUEST = '@@batch-output-hemera/BATCH_OUTPUT_HEMERA_REQUEST',
    BATCH_OUTPUT_HEMERA_SUCCESS = '@@batch-output-hemera/BATCH_OUTPUT_HEMERA_SUCCESS',
    BATCH_OUTPUT_HEMERA_ERROR = '@@batch-output-hemera/BATCH_OUTPUT_HEMERA_ERROR',
    BATCH_OUTPUT_HEMERA_RESET = '@@batch-output-hemera/BATCH_OUTPUT_HEMERA_RESET',
}

export interface OutputHemeraState extends BaseStoreState {
    readonly batchOutput?: BatchOutputHemera;
}