import { AxiosResponse } from 'axios';
import { BatchSignatureExportResponse } from 'model/batch-signature';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchSignatureExportError, batchSignatureExportRequest, batchSignatureExportSuccess } from 'reducer/batch/batch-signature-export/action';
import { BatchSignatureExportTypes } from 'reducer/batch/batch-signature-export/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchSignatureApi from 'services/api/batch-signature-api';
import ErrorUtils from 'shared/error/error-utils';


function* handleBatchSignatureExport(action: ReturnType<typeof batchSignatureExportRequest>) {
    try {
        const result: AxiosResponse<BatchSignatureExportResponse> = yield call(batchSignatureApi.exportSignatures, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchSignatureExportError(mapped));
            return;
        }
        yield put(batchSignatureExportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchSignatureExportError(mapped));
    }
}

function* watchBatchSignatureExport() {
    yield takeLeading(BatchSignatureExportTypes.BATCH_SIGNATURE_EXPORT_REQUEST, handleBatchSignatureExport);
}

function* batchSignatureExportSaga() {
    yield all([fork(watchBatchSignatureExport)]);
}

export default batchSignatureExportSaga;