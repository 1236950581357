import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListCompanyGroupPoliciesTypes {
    LIST_COMPANY_GROUP_POLICIES_REQUEST = '@@company-group-policies-list/LIST_COMPANY_GROUP_POLICIES_REQUEST',
    LIST_COMPANY_GROUP_POLICIES_SUCCESS = '@@company-group-policies-list/LIST_COMPANY_GROUP_POLICIES_SUCCESS',
    LIST_COMPANY_GROUP_POLICIES_ERROR = '@@company-group-policies-list/LIST_COMPANY_GROUP_POLICIES_ERROR',
    LIST_COMPANY_GROUP_POLICIES_RESET = '@@company-group-policies-list/LIST_COMPANY_GROUP_POLICIES_RESET',
}

export interface ListCompanyGroupPoliciesState extends BaseStoreState {
    readonly companyGroupPolicies?: Page<ScdCompanyGroupPolicy>;
}
