import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdOriginatorSimple } from 'model/originator';
import { updateOriginatorError, updateOriginatorSuccess, updateOriginatorRequest } from 'reducer/originator/update-originator/actions';
import { UpdateOriginatorTypes } from 'reducer/originator/update-originator/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import originatorApi from 'services/api/originator-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateOriginator(action: ReturnType<typeof updateOriginatorRequest>) {
    try {
        const result: AxiosResponse<ScdOriginatorSimple> = yield call(originatorApi.updateOriginator, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateOriginatorError(mapped));
            return;
        }
        yield put(updateOriginatorSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateOriginatorError(mapped));
    }
}

function* watchUpdateOriginator() {
    yield takeLeading(UpdateOriginatorTypes.UPDATE_ORIGINATOR_REQUEST, handleUpdateOriginator);
}

function* updateOriginatorSaga() {
    yield all([fork(watchUpdateOriginator)]);
}

export default updateOriginatorSaga;
