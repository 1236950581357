import { Checkbox } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import FormatterUtils from 'components/formatter/formatter-utils';
import ValidCurrencyInput from 'components/inputs/currency-input/valid-currency-input';
import SimpleDatePicker from 'components/inputs/date-input/simple-data-picker/simple-date-picker';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { TAX_CESSION_ALERT } from 'config/constants';
import { isEqual } from 'date-fns';
import ModalPreview from 'entities/batch/batch-generate-cession/components/modal-preview/modal-preview';
import ModalResponse from 'entities/batch/batch-generate-cession/components/modal-response/modal-response';
import {
    validateCession,
    validateCessionTaxPercentage,
    validatePaymentAmount,
} from 'entities/batch/batch-generate-cession/validation/batch-generate-cession-validation';
import FinancialPercentageInput from 'entities/batch/batch-session-interest-rate-calculate-recalculate/components/inputs/financial-percentage-input';
import { defaultCessionRequest, ScdBatchToGenerateCessionRequest } from 'model/batch';
import { BatchStatus } from 'model/enums/batch-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { detailBatchRequest, detailBatchResetState } from 'reducer/batch/detail-batch/actions';
import { batchGenerateCessionnResetState, batchGenerateCessionRequest } from 'reducer/batch/generate-cession/actions';
import { batchPreviewCessionRequest } from 'reducer/batch/preview-cession/actions';
import { useBatchGenerateCessionState, useBatchPreviewCessionState, useDetailBatchState, useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './generate-cession.scss';

export interface ScdBatchGenerateCessionProps extends RouteComponentProps<{ batchId: string }> {}

const I18N_PREFIX = 'pages.scd.batch.generate-cession';

const ScdBatchGenerateCession = (props: ScdBatchGenerateCessionProps) => {
    const { t } = useTranslation();
    const { toastLoading, toastSuccess, closeToast } = useToast();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const [batchId] = useState<number | undefined>(props?.match?.params?.batchId ? Number(props?.match?.params?.batchId) : undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [cessionRequest, setCessionRequest] = useState<ScdBatchToGenerateCessionRequest>(defaultCessionRequest);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [isDateMismatchAccepted, setIsDateMismatchAccepted] = useState<boolean>(false);

    const { batch, status: batchDetailStatus } = useDetailBatchState();

    const { status, cession } = useBatchGenerateCessionState();

    const { cessionPreview } = useBatchPreviewCessionState();
    const [showModalResponse, setShowModalResponse] = useState(false);
    const [showModalPreview, setShowModalPreview] = useState(false);

    const handlePreview = () => {
        setModalConfirm(true);
    };

    const handleGenerate = () => {
        const _cessionRequest: ScdBatchToGenerateCessionRequest = {
            ...cessionRequest,
            batchId: batchId,
        };

        if (!batchId) return;
        if (!validateCession(_cessionRequest)) return;

        setShowModalResponse(true);

        return dispatch(batchGenerateCessionRequest(_cessionRequest));
    };

    const handleConfirm = () => {
        setShowValidation(true);

        const _cessionRequest: ScdBatchToGenerateCessionRequest = {
            ...cessionRequest,
            batchId: batchId,
        };

        if (!batchId) return;
        if (!validateCession(_cessionRequest)) return;
        if (!cessionRequest.cessionDate) return;

        if (batch?.date) {
            const batchDate = FormatterUtils.dateWithoutTimezone(batch.date);
            compareDates(batchDate, cessionRequest.cessionDate);
        }

        setShowModalPreview(true);

        return dispatch(batchPreviewCessionRequest(_cessionRequest));
    };

    const handleCancel = () => {
        setModalConfirm(false);
        setIsChecked(false);
        setIsDateMismatchAccepted(false);
        setShowDateMismatchModal(false);
    };

    const handleChange = (value: Partial<ScdBatchToGenerateCessionRequest>) => {
        setCessionRequest(prev => ({ ...prev, ...value }));
    };

    const handleDetailBatchRequest = useCallback(() => {
        if (!batchId) return;
        dispatch(detailBatchRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        handleDetailBatchRequest();
    }, [handleDetailBatchRequest]);

    useEffect(() => {
        if (status !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [status, toastLoading]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.createSuccess`));
    }, [status, closeToast, toastSuccess, history, t]);

    useEffect(() => {
        if (status !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [status, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(batchGenerateCessionnResetState());
            dispatch(detailBatchResetState());
        };
    }, [dispatch]);

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING || batchDetailStatus === HttpRequestStatus.ON_GOING;

    const isAlertTax = Number(cessionRequest.cessionTaxPercentage) >= TAX_CESSION_ALERT;

    const [showDateMismatchModal, setShowDateMismatchModal] = useState(false);

    const isDisable = (!isChecked && isAlertTax) || (showDateMismatchModal && !isDateMismatchAccepted);

    useEffect(() => {
        if (!batch?.date) return;

        setCessionRequest(prev => ({
            ...prev,
            cessionDate: FormatterUtils.dateWithoutTimezone(batch?.date),
        }));
    }, [batch?.date]);

    const compareDates = (batchDate: Date, cessionDate: Date) => {
        if (!isEqual(batchDate, cessionDate)) {
            setShowDateMismatchModal(true);
            setIsDateMismatchAccepted(false);
        }
    };

    const handleDateChange = (date: Date | null) => {
        if (!date || !batch?.date) return;

        const batchDate = FormatterUtils.dateWithoutTimezone(batch?.date);

        const selectedDateToLocalDate = FormatterUtils.formatDateToLocalDate(date);

        const selectedDate = FormatterUtils.dateWithoutTimezone(selectedDateToLocalDate);

        compareDates(batchDate, selectedDate);

        setCessionRequest(prev => ({
            ...prev,
            cessionDate: selectedDate,
        }));
    };

    const modalContentLookup = {
        showTaxAlertAndDateMismatch: {
            condition: isAlertTax && showDateMismatchModal,
            content: (
                <div className="scd-batch-generate-cession__modal-alert">
                    <div className="scd-batch-generate-cession__modal-alert__message">
                        <Trans
                            t={t}
                            i18nKey={`${I18N_PREFIX}.message-alert`}
                            values={{
                                taxCessionAlert: TAX_CESSION_ALERT,
                            }}
                        />
                    </div>
                    <div className="scd-batch-generate-cession__modal-alert__message">
                        {t(`${I18N_PREFIX}.modal-date-mismatch.cession-date-divergent`)}
                        <div>
                            {t(`${I18N_PREFIX}.modal-date-mismatch.batch-date`, {
                                batchDate: FormatterUtils.formatDate(batch?.date),
                            })}
                        </div>
                        <div>
                            {t(`${I18N_PREFIX}.modal-date-mismatch.cession-date`, {
                                cessionDate: cessionRequest.cessionDate ? FormatterUtils.formatNewDate(cessionRequest.cessionDate) : '-',
                            })}
                        </div>
                    </div>
                    <div className="scd-batch-generate-cession__checkbox-container">
                        <Checkbox
                            color="primary"
                            onChange={() => {
                                setIsChecked(state => !state);
                                setIsDateMismatchAccepted(state => !state);
                            }}
                        />
                        {t(`${I18N_PREFIX}.checkbox`)}
                    </div>
                </div>
            ),
        },
        showTaxAlertOnly: {
            condition: isAlertTax && !showDateMismatchModal,
            content: (
                <div className="scd-batch-generate-cession__modal-alert">
                    <div className="scd-batch-generate-cession__modal-alert__message">
                        <Trans
                            t={t}
                            i18nKey={`${I18N_PREFIX}.message-alert`}
                            values={{
                                taxCessionAlert: TAX_CESSION_ALERT,
                            }}
                        />
                    </div>
                    <div>
                        <Checkbox color="primary" onChange={() => setIsChecked(state => !state)} />
                        {t(`${I18N_PREFIX}.checkbox`)}
                    </div>
                </div>
            ),
        },
        showDateMismatchOnly: {
            condition: showDateMismatchModal && !isAlertTax,
            content: (
                <div className="scd-batch-generate-cession__modal-alert">
                    <h5>{t(`${I18N_PREFIX}.modal-date-mismatch.title`)}</h5>
                    <div>{t(`${I18N_PREFIX}.modal-date-mismatch.cession-date-divergent`)}</div>
                    <div>
                        {t(`${I18N_PREFIX}.modal-date-mismatch.batch-date`, {
                            batchDate: FormatterUtils.formatDate(batch?.date),
                        })}
                    </div>
                    <div>
                        {t(`${I18N_PREFIX}.modal-date-mismatch.cession-date`, {
                            cessionDate: cessionRequest.cessionDate ? FormatterUtils.formatNewDate(cessionRequest.cessionDate) : '-',
                        })}
                    </div>
                    <div>
                        <Checkbox color="primary" onChange={() => setIsDateMismatchAccepted(!isDateMismatchAccepted)} />
                        {t(`${I18N_PREFIX}.checkbox`)}
                    </div>
                </div>
            ),
        },
    };

    const renderModalContent = () => {
        const lookupEntry = Object.values(modalContentLookup).find(entry => entry.condition);
        return lookupEntry ? lookupEntry.content : null;
    };

    return (
        <main className="scd-batch-generate-cession__create">
            <ContextRibbon />
            <div className="scd-batch-generate-cession--container">
                <header className="scd-batch-generate-cession--header">
                    <h2 className="scd-batch-generate-cession--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                </header>
                {isLoading && batchId ? (
                    <Loading />
                ) : (
                    <form className="scd-batch-generate-cession--form">
                        <div className="scd-batch-generate-cession--form-field">
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.batch-date.label`)}
                                value={FormatterUtils.formatDate(batch?.date) ?? '-'}
                            />
                        </div>
                        <div className="scd-batch-generate-cession--form-field">
                            <SimpleDatePicker
                                selectedDate={cessionRequest.cessionDate ?? null}
                                setSelectedDate={handleDateChange}
                                label={t(`${I18N_PREFIX}.input.cession-date.label`)}
                            />
                        </div>
                        <div className="scd-batch-generate-cession--form-field">
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.batch-code.label`)} value={batch?.code ?? '-'} />
                        </div>
                        <div className="scd-batch-generate-cession--form-field">
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.batch-originator.label`)} value={batch?.originator ?? '-'} />
                        </div>
                        <div className="scd-batch-generate-cession--form-field">
                            <FinancialPercentageInput
                                value={cessionRequest.cessionTaxPercentage}
                                label={t(`${I18N_PREFIX}.input.cession-tax-percentage.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.cession-tax-percentage.placeholder`)}
                                onChange={cessionTaxPercentage => handleChange({ cessionTaxPercentage })}
                                showValidation={showValidation}
                                validate={validateCessionTaxPercentage}
                            />
                        </div>
                        <div className="scd-batch-generate-cession--form-field">
                            <ValidCurrencyInput
                                value={cessionRequest.paymentAmount}
                                label={t(`${I18N_PREFIX}.input.payment-amount.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.payment-amount.placeholder`)}
                                onChange={paymentAmount => handleChange({ paymentAmount })}
                                showValidation={showValidation}
                                validate={validatePaymentAmount}
                                zeroValue
                                externalUpdate
                            />
                        </div>
                        <div className="scd-batch-generate-cession--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy
                                label={t('entity.action.calculate')}
                                onClick={handleConfirm}
                                disabled={batch?.status !== BatchStatus.CLOSED && batch?.status !== BatchStatus.CONCLUDED}
                            />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={t('entity.cancel.cession')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/batches');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
            {modalConfirm && (
                <ModalMessage
                    title={t(`${I18N_PREFIX}.modal-confirm.title`)}
                    onCancel={handleCancel}
                    onAction={() => {
                        if (isDisable || (showDateMismatchModal && !isDateMismatchAccepted)) return;
                        handleGenerate();
                        setModalConfirm(false);
                    }}
                    onClose={handleCancel}
                    disabled={isDisable || (showDateMismatchModal && !isDateMismatchAccepted)}
                    isWarningModal={isAlertTax || showDateMismatchModal}
                >
                    {renderModalContent()}
                </ModalMessage>
            )}
            {showModalResponse && cession && (
                <ModalResponse
                    cession={cession}
                    onClose={() => setShowModalResponse(false)}
                    onAction={() => {
                        setShowModalResponse(false);
                        history.push('/batches');
                    }}
                />
            )}
            {showModalPreview && cessionPreview && (
                <ModalPreview
                    cession={cessionPreview}
                    onAction={() => {
                        setShowModalPreview(false);
                        handlePreview();
                    }}
                    onClose={() => setShowModalPreview(false)}
                />
            )}
        </main>
    );
};

export default ScdBatchGenerateCession;
