import { AxiosResponse } from 'axios';
import { ScdBatchExpirationsReport } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    batchExpirationsReportError,
    batchExpirationsReportRequest,
    batchExpirationsReportSuccess,
} from 'reducer/batch/expirations-report/actions';
import { BatchExpirationsReportTypes } from 'reducer/batch/expirations-report/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchExpirationsReport(action: ReturnType<typeof batchExpirationsReportRequest>) {
    try {
        const result: AxiosResponse<ScdBatchExpirationsReport> = yield call(batchAPI.getExpirationsReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchExpirationsReportError(mapped));
            return;
        }
        yield put(batchExpirationsReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchExpirationsReportError(mapped));
    }
}

function* watchBatchExpirationsReport() {
    yield takeLeading(BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_REQUEST, handleBatchExpirationsReport);
}

function* batchExpirationsReportSaga() {
    yield all([fork(watchBatchExpirationsReport)]);
}

export default batchExpirationsReportSaga;
