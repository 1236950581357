import { BatchDocumentSimple } from 'model/batch-output-hemera';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchDocumentProcessTypes {
    BATCH_DOCUMENT_PROCESS_REQUEST = '@@batch-document-process/BATCH_DOCUMENT_PROCESS_REQUEST',
    BATCH_DOCUMENT_PROCESS_SUCCESS = '@@batch-document-process/BATCH_DOCUMENT_PROCESS_SUCCESS',
    BATCH_DOCUMENT_PROCESS_ERROR = '@@batch-document-process/BATCH_DOCUMENT_PROCESS_SUCCESS',
    BATCH_DOCUMENT_PROCESS_RESET = '@@batch-document-process/BATCH_DOCUMENT_PROCESS_RESET',
}

export interface BatchDocumentState extends BaseStoreState {
    readonly document?: BatchDocumentSimple;
}