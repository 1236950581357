import { HemeraAuthorizationForSendBackingResponse } from 'features/limelight/send-backings/domain/models';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ProvisionalLimelightSendBackingTypes {
    LIMELIGHT_AUTHORIZATION_SEND_BACKING_REQUEST = '@@limelight-authorization-send-backing/LIMELIGHT_AUTHORIZATION_SEND_BACKING_REQUEST',
    LIMELIGHT_AUTHORIZATION_SEND_BACKING_SUCCESS = '@@limelight-authorization-send-backing/LIMELIGHT_AUTHORIZATION_SEND_BACKING_SUCCESS',
    LIMELIGHT_AUTHORIZATION_SEND_BACKING_ERROR = '@@limelight-authorization-send-backing/LIMELIGHT_AUTHORIZATION_SEND_BACKING_ERROR',
    LIMELIGHT_AUTHORIZATION_SEND_BACKING_RESET = '@@limelight-authorization-send-backing/LIMELIGHT_AUTHORIZATION_SEND_BACKING_RESET'
}

export interface limelightAuthorizationSendBackingState extends BaseStoreState {
    readonly authorizationSendBacking?: HemeraAuthorizationForSendBackingResponse
}