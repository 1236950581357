import { GiroWebappError } from 'model/error';
import { ScdOriginatorDetail } from 'model/originator';
import { DetailOriginatorTypes } from 'reducer/originator/detail-originator/types';
import { action } from 'typesafe-actions';

export const detailOriginatorRequest = (originatorId: string) => action(DetailOriginatorTypes.DETAIL_ORIGINATOR_REQUEST, originatorId);
export const detailOriginatorSuccess = (originator: ScdOriginatorDetail) =>
    action(DetailOriginatorTypes.DETAIL_ORIGINATOR_SUCCESS, originator);
export const detailOriginatorError = (error: GiroWebappError) => action(DetailOriginatorTypes.DETAIL_ORIGINATOR_ERROR, error);
export const detailOriginatorResetState = () => action(DetailOriginatorTypes.DETAIL_ORIGINATOR_RESET_STATE);
