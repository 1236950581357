import { PaymentInstitutionAccountToCreateUpdate } from 'model/payment-institution-account';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.validate';


const PAYMENT_INSTITUTION_ACCOUNT_ORIGINATOR_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.originator.required`
    }
}

export const validatePaymentInstitutionAccountOriginator = (value: number | undefined | null) => ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_ORIGINATOR_VALIDATION );
  

const PAYMENT_INSTITUTION_ACCOUNT_ISSUER_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.issuer.required`
    }
}

export const validatePaymentInstitutionAccountIssuer = (value: number | undefined | null) =>  ValidationUtils.validate(value, PAYMENT_INSTITUTION_ACCOUNT_ISSUER_VALIDATION );


export const validatePaymentInstitutionAccountConvert = (value: PaymentInstitutionAccountToCreateUpdate) => {
    if (!value) {
        return false
    }

    return (
        validatePaymentInstitutionAccountOriginator(value.originatorId).isValid &&
        validatePaymentInstitutionAccountIssuer(value.issuerId).isValid
    )
}