import ContentLoader from 'react-content-loader';

const PaymentTransactionInSeriesSkeleton = () => {
    return (
        <>
            {Array.from({ length: 7 }).map((_, index) => (
                <ContentLoader
                    width={1150}
                    height={50}
                    viewBox="0 0 1150 50"
                    backgroundColor="#F0F0F0"
                    foregroundColor="#ecebeb"
                    key={index}
                    style={{ marginTop: '20px' }}
                >
                    <rect x="0" y="10" rx="4" ry="4" width="15" height="15" />
                    <rect x="28" y="0" rx="4" ry="4" width="210" height="50" />
                    <rect x="260" y="0" rx="4" ry="4" width="340" height="50" />
                    <rect x="620" y="0" rx="4" ry="4" width="300" height="50" />

                    {index === 7 && (
                        <>
                            <rect x="900" y="0" rx="4" ry="4" width="50" height="50" />
                            <rect x="1000" y="0" rx="4" ry="4" width="80" height="80" />
                        </>
                    )}
                </ContentLoader>
            ))}
        </>
    );
};

export default PaymentTransactionInSeriesSkeleton;
