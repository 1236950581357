import { AxiosResponse } from 'axios';
import { ScdCnabDownload, ScdCnabGenerate, ScdCnabSimple } from 'model/cnab';
import { api } from 'services/api/api';

export const cnab = () => {
    const getBatchCnabFile = (batchId: number): Promise<AxiosResponse<ScdCnabDownload>> => {
        return api.get<ScdCnabDownload>(`api/cnabs/batch/${batchId}`);
    };

    const generateCnaBatch = (request: ScdCnabGenerate): Promise<AxiosResponse<ScdCnabSimple>> => {
        return api.post<ScdCnabSimple>(`api/cnabs/batches/${request.batchId}`, request);
    };

    return {
        getBatchCnabFile,
        generateCnaBatch,
    };
};

export default cnab();
