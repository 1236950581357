import { ScdOrganizationChangeTokenResponse } from 'model/organization';
import { GiroWebappError } from 'model/error';
import { ChangeOrganizationTokenTypes } from './types';
import { action } from 'typesafe-actions';

export const changeTokenOrganizationRequest = (organizationId: number) => action(ChangeOrganizationTokenTypes.CHANGE_TOKEN_ORGANIZATION_REQUEST, organizationId);
export const changeTokenOrganizationSuccess = (ScdOrganizationChangeTokenResponse: ScdOrganizationChangeTokenResponse) =>
    action(ChangeOrganizationTokenTypes.CHANGE_TOKEN_ORGANIZATION_SUCCESS, ScdOrganizationChangeTokenResponse);
export const changeTokenOrganizationError = (error: GiroWebappError) => action(ChangeOrganizationTokenTypes.CHANGE_TOKEN_ORGANIZATION_ERROR, error);
export const changeTokenOrganizationResetState = () => action(ChangeOrganizationTokenTypes.CHANGE_TOKEN_ORGANIZATION_RESET_STATE);
