import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { OperationsModalMessageActions } from 'entities/ccb-operation/ccb-operation';
import { OperationStatus } from 'model/enums/operation-status';
import { useRouteMatch } from 'react-router-dom';

interface OperationsMoreOptionsMenuItemsProps {
    handleCloseMenu: () => void;
    operationId: number | undefined;
    hasBatch: boolean;
    canBeArchived: boolean;
    setModalMessage: (value: OperationsModalMessageActions) => void;
    activeOperationStatus: OperationStatus | undefined;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

const OperationsMoreOptionsMenuItems = (props: OperationsMoreOptionsMenuItemsProps) => {
    const { handleCloseMenu, operationId, canBeArchived, hasBatch, setModalMessage, activeOperationStatus } = props;

    const { url } = useRouteMatch();

    const handleModalMessageAction = (action: OperationsModalMessageActions) => () => {
        handleCloseMenu();
        setModalMessage(action);
    };

    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.menu.show-detail`} link={`${url}/${operationId}`} />
            <ModalActionMenuItem
                show={!hasBatch && canBeArchived}
                titleKey={`${I18N_PREFIX}.menu.archive`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.ARCHIVE_ONE)}
            />
            <ModalActionMenuItem
                show={activeOperationStatus === OperationStatus.EXPORTED}
                titleKey={`${I18N_PREFIX}.menu.reexport-webcred`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.REEXPORT_OPERATION)}
            />
            <ModalActionMenuItem
                show={activeOperationStatus === OperationStatus.PRE_PROCESS_ERROR}
                titleKey={`${I18N_PREFIX}.menu.retry-preprocess`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.PRE_PROCESS_ERROR)}
            />
            <ModalActionMenuItem
                show={activeOperationStatus === OperationStatus.DEFINE_PAYMENT_ERROR}
                titleKey={`${I18N_PREFIX}.menu.retry-define-payment`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.DEFINE_PAYMENT_ERROR)}
            />
            <ModalActionMenuItem
                show={activeOperationStatus === OperationStatus.DEFINE_FLOW_ERROR}
                titleKey={`${I18N_PREFIX}.menu.retry-define-flow`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.DEFINE_FLOW_ERROR)}
            />
            <ModalActionMenuItem
                show={activeOperationStatus === OperationStatus.AUTOMATIC_PAYMENT_ERROR}
                titleKey={`${I18N_PREFIX}.menu.retry-process-auto-payment`}
                onClick={handleModalMessageAction(OperationsModalMessageActions.PROCESS_AUTOMATIC_PAYMENT)}
            />
        </>
    );
};

export default OperationsMoreOptionsMenuItems;
