import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentTransactionsImportByBatch } from 'model/payment-transaction-by-batch';
import { markError } from 'reducer/application/error/actions';
import {
    listDisbursementTransactionsByBatchError,
    listDisbursementTransactionsByBatchRequest,
    listDisbursementTransactionsByBatchSuccess,
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/actions';
import { ListPaymentTransactionsByBatchTypes } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionByBatchAPI from 'services/api/payment-transaction-by-batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListPaymentTransactionsByBatch(action: ReturnType<typeof listDisbursementTransactionsByBatchRequest>) {
    try {
        const result: AxiosResponse<PaymentTransactionsImportByBatch> = yield call(
            paymentTransactionByBatchAPI.listPaymentTransactionsByBatch,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listDisbursementTransactionsByBatchError(mapped));
            return;
        }
        yield put(listDisbursementTransactionsByBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listDisbursementTransactionsByBatchError(mapped));
    }
}

function* watchListPaymentTransactionsByBatch() {
    yield takeEvery(ListPaymentTransactionsByBatchTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST, handleListPaymentTransactionsByBatch);
}

function* listPaymentTransactionsByBatchSaga() {
    yield all([fork(watchListPaymentTransactionsByBatch)]);
}

export default listPaymentTransactionsByBatchSaga;
