import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TransfeeraExternalTransfersFilterType } from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-filters-modal/transfeera-transfers-filters-modal';
import {
    defaultTransfeeraExternalTransfersFilter,
    TransfeeraExternalTransfersFilterRequest,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';

interface TransfeeraTransfersFiltersButtonsProps {
    filterActive: TransfeeraExternalTransfersFilterRequest;
    handleResetFilterActive: () => void;
    setModalFilter: (value: TransfeeraExternalTransfersFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
}
const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.filters';

const TransfeeraTransfersFiltersButtons = (props: TransfeeraTransfersFiltersButtonsProps) => {
    const { filterActive, handleResetFilterActive, setModalFilter, setOpenModalFilter } = props;

    const { t } = useTranslation();

    const handleModalFilter = (value: TransfeeraExternalTransfersFilterType) => () => {
        setModalFilter(value);
        setOpenModalFilter(true);
    };

    const isActiveFilter: boolean = !_.isEqual(filterActive, defaultTransfeeraExternalTransfersFilter);

    const isDateFilterActive: boolean =
        !_.isEqual(filterActive.initialDate, defaultTransfeeraExternalTransfersFilter.initialDate) ||
        !_.isEqual(filterActive.endDate, defaultTransfeeraExternalTransfersFilter.endDate);

    return (
        <>
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('filters')}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isActiveFilter}
                />
            </div>
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('date')}
                    label={t(`${I18N_PREFIX}.date`)}
                    icon={<ArrowDropDownIcon />}
                    isActiveFilter={isDateFilterActive}
                />
            </div>
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('search')}
                    label={t(`${I18N_PREFIX}.search`)}
                    icon={<ArrowDropDownIcon />}
                    isActiveFilter={!!filterActive.search}
                />
            </div>
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('status')}
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={<ArrowDropDownIcon />}
                    isActiveFilter={!!filterActive.status}
                />
            </div>
            {isActiveFilter && (
                <Tooltip title={t('global.remove-filter.title').toString()}>
                    <div className="transfeera-transfers__header-buttons-content__error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default TransfeeraTransfersFiltersButtons;
