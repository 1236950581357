import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Technical Suppliers
import { listTechnicalSuppliersReducer } from 'reducer/technical-supplier/list-technical-suppliers/reducer';
import { ListTechnicalSuppliersState } from 'reducer/technical-supplier/list-technical-suppliers/types';
import listTechnicalSuppliersSaga from 'reducer/technical-supplier/list-technical-suppliers/sagas';

// Create Technical Supplier
import { CreateTechnicalSupplierState } from 'reducer/technical-supplier/create-technical-supplier/types';
import { createTechnicalSupplierReducer } from 'reducer/technical-supplier/create-technical-supplier/reducer';
import createTechnicalSupplierSaga from 'reducer/technical-supplier/create-technical-supplier/sagas';

// Detail TechnicalSupplier
import { DetailTechnicalSupplierState } from 'reducer/technical-supplier/detail-technical-supplier/types';
import { detailTechnicalSupplierReducer } from 'reducer/technical-supplier/detail-technical-supplier/reducer';
import detailTechnicalSupplierSaga from 'reducer/technical-supplier/detail-technical-supplier/sagas';

// Update Technical Supplier
import { UpdateTechnicalSupplierState } from 'reducer/technical-supplier/update-technical-supplier/types';
import { updateTechnicalSupplierReducer } from 'reducer/technical-supplier/update-technical-supplier/reducer';
import updateTechnicalSupplierSaga from 'reducer/technical-supplier/update-technical-supplier/sagas';

// Search Technical Suppliers
import { SearchTechnicalSuppliersState } from 'reducer/technical-supplier/search-originators/types';
import { searchTechnicalSuppliersReducer } from 'reducer/technical-supplier/search-originators/reducer';
import searchTechnicalSuppliersSaga from 'reducer/technical-supplier/search-originators/sagas';

// Default Technical Supplier
import { DefaultTechnicalSupplierState } from 'reducer/technical-supplier/default-technical-supplier/types';
import { defaultTechnicalSupplierReducer } from 'reducer/technical-supplier/default-technical-supplier/reducer';
import defaultTechnicalSupplierSaga from 'reducer/technical-supplier/default-technical-supplier/sagas';

export interface TechnicalSupplierState {
    readonly listTechnicalSuppliers: ListTechnicalSuppliersState;
    readonly createdTechnicalSupplier: CreateTechnicalSupplierState;
    readonly detailTechnicalSupplier: DetailTechnicalSupplierState;
    readonly updateTechnicalSupplier: UpdateTechnicalSupplierState;
    readonly defaultTechnicalSupplier: DefaultTechnicalSupplierState;
    readonly searchTechnicalSuppliers: SearchTechnicalSuppliersState;
}

export const TechnicalSupplierReducer: ReducersMapObject<TechnicalSupplierState, AnyAction> = {
    listTechnicalSuppliers: listTechnicalSuppliersReducer,
    createdTechnicalSupplier: createTechnicalSupplierReducer,
    detailTechnicalSupplier: detailTechnicalSupplierReducer,
    updateTechnicalSupplier: updateTechnicalSupplierReducer,
    searchTechnicalSuppliers: searchTechnicalSuppliersReducer,
    defaultTechnicalSupplier: defaultTechnicalSupplierReducer,
};

export function* technicalSupplierSagas() {
    yield all([
        fork(listTechnicalSuppliersSaga),
        fork(createTechnicalSupplierSaga),
        fork(detailTechnicalSupplierSaga),
        fork(updateTechnicalSupplierSaga),
        fork(searchTechnicalSuppliersSaga),
        fork(defaultTechnicalSupplierSaga),
    ]);
}
