import { ScdCompanyItem } from 'model/company-item';
import { GiroWebappError } from 'model/error';
import { ListCompanyItemsTypes } from 'reducer/company-item/list-company-items/types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listCompanyItemsRequest = (request?: Pageable) => action(ListCompanyItemsTypes.LIST_COMPANY_ITEMS_REQUEST, request);
export const listCompanyItemsSuccess = (companyItems: Page<ScdCompanyItem>) =>
    action(ListCompanyItemsTypes.LIST_COMPANY_ITEMS_SUCCESS, companyItems);
export const listCompanyItemsError = (error: GiroWebappError) => action(ListCompanyItemsTypes.LIST_COMPANY_ITEMS_ERROR, error);
export const listCompanyItemsResetState = () => action(ListCompanyItemsTypes.LIST_COMPANY_ITEMS_RESET);
