import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchPaymentInstitutionsAccountsState, SearchPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/types';

const initialState: SearchPaymentInstitutionsAccountsState = {
    status: HttpRequestStatus.NOOP,
    searchPaymentInstitutionsAccounts: undefined,
    error: undefined,
};

const reducer: Reducer<SearchPaymentInstitutionsAccountsState> = (state = initialState, action): SearchPaymentInstitutionsAccountsState => {
    switch (action.type) {
        case SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, searchPaymentInstitutionsAccounts: action.payload, error: undefined };
        }
        case SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchPaymentInstitutionsAccountsReducer };
