import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';

import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useEffect, useState } from 'react';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import ClosedDrawer from 'shared/header/sidebar/componentes/closed-drawer/closed-drawer';
import OpenedDrawer from 'shared/header/sidebar/componentes/opened-drawer/opened-drawer';
import './sidebar.scss';

interface SidebarProps {}

const Sidebar: FC<SidebarProps> = () => {
    const dispatch = useRootDispatch();
    const [sidebarIsOpen, setSidebarIsOpen] = useState<boolean>(true);
    const userAccountState = useUserAccountState();

    useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.NOOP) {
            dispatch(userAccountRequest());
        }
    }, [userAccountState.status, dispatch]);

    return (
        <div>
            <div className={clsx('menu-header', { isOpen: sidebarIsOpen })} />
            <Drawer
                variant="permanent"
                classes={{ paper: clsx('drawer', { isOpen: sidebarIsOpen }) }}
            >
                <OpenedDrawer openedDrawer={sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} />
                <ClosedDrawer closedDrawer={!sidebarIsOpen} setSidebarIsOpen={setSidebarIsOpen} />
            </Drawer>
        </div>
    );
};
export default Sidebar;
