import { IssuerPolicySimple } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CreateIssuerPolicyTypes {
    ISSUER_CREATE_POLICY_REQUEST = '@@issuer-create-policy/ISSUER_CREATE_POLICY_REQUEST',
    ISSUER_CREATE_POLICY_SUCCESS = '@@issuer-create-policy/ISSUER_CREATE_POLICY_SUCCESS',
    ISSUER_CREATE_POLICY_ERROR = '@@issuer-create-policy/ISSUER_CREATE_POLICY_ERROR',
    ISSUER_CREATE_POLICY_RESET_STATE = '@@issuer-create-policy/ISSUER_CREATE_POLICY_RESET_STATE',
}

export interface CreateIssuerPolicyState extends BaseStoreState {
    readonly issuerCreatePolicy?: IssuerPolicySimple;
}