import { CloseButton } from 'components/buttons/close-button/close-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { Modal } from 'reactstrap';
import './modal-message-legacy.scss';

export interface ModalMessageLegacyProps {
    title?: string;
    children?: React.ReactNode;
    message?: React.ReactNode;
    onClose: () => void;
    onAction?: () => void;
    onCancel?: () => void;
    outlinedButtonLabel?: string;
    outlinedButtonSize?: 'small' | 'medium' | 'large';
    standardButtonLabel?: string;
    disabled?: boolean;
    icon?: React.ReactNode;
}

export const ModalMessageLegacy = (props: ModalMessageLegacyProps) => {
    const {
        message,
        title,
        onClose,
        onAction,
        onCancel,
        outlinedButtonLabel,
        standardButtonLabel,
        children,
        disabled,
        icon,
        outlinedButtonSize,
    } = props;

    return (
        <Modal isOpen>
            <div className="modal-message-legacy">
                <article className="modal-message-legacy--content">
                    <header className="modal-message-legacy--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="modal-message-legacy--message">
                        <div className="modal-message-legacy--icon"> {icon} </div>
                        <p className="modal-message-legacy--title">{title}</p>
                        {message && <p className="modal-message-legacy--text">{message}</p>}
                        {children && children}
                    </div>
                    {(onCancel ?? onAction) && (
                        <div className="modal-message-legacy--actions">
                            {onCancel && (
                                <OutlinedButtonLegacy
                                    label={outlinedButtonLabel ?? 'entity.action.cancel'}
                                    onClick={onCancel}
                                    size={outlinedButtonSize}
                                />
                            )}
                            {onAction && (
                                <StandardButtonLegacy
                                    label={standardButtonLabel ?? 'entity.action.confirm'}
                                    onClick={onAction}
                                    disabled={disabled ?? false}
                                />
                            )}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ModalMessageLegacy;
