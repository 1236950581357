import { BatchCardType } from 'entities/batch/components/batch-card/batch-card';
import './internal-error-card.scss';

export interface InternalErrorCardProps {
    type: BatchCardType.INTERNAL_ERROR;
    title: string;
    message?: string;
}

const InternalErrorCard = ({ title, message }: InternalErrorCardProps) => {
    return (
        <div className="internal-error-card">
            <span className="internal-error-card--title">{title}</span>
            <div className="internal-error-card--message">
                <span className="internal-error-card--message__value">{message}</span>
            </div>
        </div>
    );
};

export default InternalErrorCard;
