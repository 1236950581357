import { AxiosResponse } from 'axios';
import { ScdBatchToRelocateResponse } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    relocateToExistingBatchRequest, relocateToExistingBatchError, relocateToExistingBatchSuccess
} from 'reducer/batch/relocate-operation-to-existing-batch/actions';
import { RelocateToExistingBatchTypes } from 'reducer/batch/relocate-operation-to-existing-batch/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleRelocateToExistingBatch(action: ReturnType<typeof relocateToExistingBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchToRelocateResponse> = yield call(batchAPI.batchToRelocateToExisting, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(relocateToExistingBatchError(mapped));
            return;
        }
        yield put(relocateToExistingBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(relocateToExistingBatchError(mapped));
    }
}

function* watchRelocateToExistingBatch() {
    yield takeLeading(RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_REQUEST, handleRelocateToExistingBatch);
}

function* relocateToExistingBatchSaga() {
    yield all([fork(watchRelocateToExistingBatch)]);
}

export default relocateToExistingBatchSaga;
