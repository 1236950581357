import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Payment Institutions Accounts
import listPaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/sagas'
import { ListPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/types'
import { listPaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/reducer'

// Search Payment institutions Accounts
import searchPaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/sagas'
import { SearchPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/types'
import { searchPaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/reducer'

// Create Payment Institution Account
import createPaymentInstitutionAccountSaga from 'reducer/payment-institutions-accounts/create-payment-institution-account/sagas'
import { CreatePaymentInstitutionsAccountState } from 'reducer/payment-institutions-accounts/create-payment-institution-account/types'
import { createPaymentInstitutionAccountReducer } from 'reducer/payment-institutions-accounts/create-payment-institution-account/reducer'

// Detail Payment Institution Account
import detailPaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/detail-payment-institution-account/sagas'
import { DetailPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/types'
import { detailPaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/reducer'

// Update Payment Institution Account
import updatePaymentInstitutionAccountSaga from 'reducer/payment-institutions-accounts/update-payment-institution-account/sagas'
import { UpdatePaymentInstitutionsAccountState } from 'reducer/payment-institutions-accounts/update-payment-institution-account/types'
import { updatePaymentInstitutionAccountReducer } from 'reducer/payment-institutions-accounts/update-payment-institution-account/reducer'

// External Balance Payment Institution Account
import externalBalancePaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/sagas';
import { ExternalBalancePaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { externalBalancePaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/reducer';

// External transfers payment institution account
import externalTransfersPaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/sagas';
import { ExternalTransfersPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/types';
import { externalTransfersPaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/reducer';

// Resume payment institution account
import resumePaymentInstitutionAccountSaga from 'reducer/payment-institutions-accounts/resume-payment-institution-account/sagas';
import { ResumePaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/types';
import { resumePaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/reducer';

// External statement payment institution account
import { ExternalStatementPaymentInstitutionsAccountsState } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/types';
import { externalStatementPaymentInstitutionsAccountsReducer } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/reducer';
import externalStatementPaymentInstitutionsAccountsSaga from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/sagas';

// Withdraw external balance
import { WithdrawExternalBalanceState } from 'reducer/payment-institutions-accounts/withdraw-external-balance/types';
import { withdrawExternalBalanceReducer } from 'reducer/payment-institutions-accounts/withdraw-external-balance/reducer';
import withdrawExternalBalanceSaga from 'reducer/payment-institutions-accounts/withdraw-external-balance/sagas';

// Search Originators By Payment Institution Account
import { SearchOriginatorsByPaymentInstitutionAccountState } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/types';
import { searchOriginatorsByPaymentInstitutionAccountReducer } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/reducer';
import searchOriginatorsByPaymentInstitutionAccountSaga from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/sagas';
import { ConvertPaymentInstitutionAccountState } from 'reducer/payment-institutions-accounts/convert-payment-institution-account/types';
import { convertPaymentInstitutionAccountReducer } from 'reducer/payment-institutions-accounts/convert-payment-institution-account/reducer';
import convertPaymentInstitutionAccountSaga from 'reducer/payment-institutions-accounts/convert-payment-institution-account/sagas';


export interface PaymentInstitutionAccountsState {
    readonly listPaymentInstitutionsAccounts: ListPaymentInstitutionsAccountsState;
    readonly searchPaymentInstitutionsAccounts: SearchPaymentInstitutionsAccountsState;
    readonly createPaymentInstitutionAccount: CreatePaymentInstitutionsAccountState;
    readonly detailPaymentInstitutionAccount: DetailPaymentInstitutionsAccountsState;
    readonly updatePaymentInstitutionAccount: UpdatePaymentInstitutionsAccountState;
    readonly getExternalBalance: ExternalBalancePaymentInstitutionsAccountsState;
    readonly getExternalTransfers: ExternalTransfersPaymentInstitutionsAccountsState;
    readonly resumePaymentInstitutionAccount: ResumePaymentInstitutionsAccountsState;
    readonly getExternalStatement: ExternalStatementPaymentInstitutionsAccountsState;
    readonly withdrawExternalBalance: WithdrawExternalBalanceState;
    readonly searchOriginatorsByPaymentInstitutionAccount: SearchOriginatorsByPaymentInstitutionAccountState;
    readonly convertPaymentInstitutionAccount: ConvertPaymentInstitutionAccountState;
}

export const PaymentInstitutionAccountsReducer: ReducersMapObject<PaymentInstitutionAccountsState, AnyAction> = {
    listPaymentInstitutionsAccounts: listPaymentInstitutionsAccountsReducer,
    searchPaymentInstitutionsAccounts: searchPaymentInstitutionsAccountsReducer,
    createPaymentInstitutionAccount: createPaymentInstitutionAccountReducer,
    detailPaymentInstitutionAccount: detailPaymentInstitutionsAccountsReducer,
    updatePaymentInstitutionAccount: updatePaymentInstitutionAccountReducer,
    getExternalBalance: externalBalancePaymentInstitutionsAccountsReducer,
    getExternalTransfers: externalTransfersPaymentInstitutionsAccountsReducer,
    resumePaymentInstitutionAccount: resumePaymentInstitutionsAccountsReducer,
    getExternalStatement: externalStatementPaymentInstitutionsAccountsReducer,
    withdrawExternalBalance: withdrawExternalBalanceReducer,
    searchOriginatorsByPaymentInstitutionAccount: searchOriginatorsByPaymentInstitutionAccountReducer,
    convertPaymentInstitutionAccount: convertPaymentInstitutionAccountReducer
}

export function* paymentInstitutionAccountsSagas() {
    yield all([
        fork(listPaymentInstitutionsAccountsSaga),
        fork(searchPaymentInstitutionsAccountsSaga),
        fork(createPaymentInstitutionAccountSaga),
        fork(detailPaymentInstitutionsAccountsSaga),
        fork(updatePaymentInstitutionAccountSaga),
        fork(externalBalancePaymentInstitutionsAccountsSaga),
        fork(externalTransfersPaymentInstitutionsAccountsSaga),
        fork(resumePaymentInstitutionAccountSaga),
        fork(externalStatementPaymentInstitutionsAccountsSaga),
        fork(withdrawExternalBalanceSaga),
        fork(searchOriginatorsByPaymentInstitutionAccountSaga),
        fork(convertPaymentInstitutionAccountSaga)
    ])
}