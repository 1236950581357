import { Authority } from 'model/enums/authority';

export interface UserAccount {
    id?: number;
    login?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    activated?: boolean;
    authorities?: string[];
}

export interface UserLogin {
    login: string;
}

export interface UserSimple {
    id: number;
}

export interface UserNewPassword {
    key: string;
    newPassword: string;
    password: string;
}

export interface UserPasswordResetRequest {
    mail: string;
}

export interface UserToCreateAnalyst {
    login?: string;
    email?: string;
    langKey?: string;
}

export interface UserToCreateOrUpdate {
    id?: number;
    login?: string;
    firstName?: string;
    lastName?: string;
    activated?: boolean;
    authorities?: Authority[];
}

export const defaultUserToCreate: UserToCreateOrUpdate = {
    id: undefined,
    login: undefined,
    firstName: undefined,
    lastName: undefined,
    activated: undefined,
    authorities: undefined
}

export interface UsersToGetAll {
    id?: number
    login?: string
    firstName?: string
    lastName?: string
    activated?: boolean
}

export interface UserDetailData {
    id: number;
    login: string;
    firstName: string;
    lastName: string;
    activated: boolean;
    createdBy: string;
    lastModifiedBy: string;
    createdDate: Date;
    lastModifiedDate: Date;
    authorities: Authority[];
}

export interface UserFilterRequest {
    login?: string;
    firstName?: string;
    lastName?: string;
    activated?: boolean;
    authority?: Authority;
}

export const defaultUserFilterRequest: UserFilterRequest = {
    login: undefined,
    firstName: undefined,
    authority: undefined,
    activated: undefined
};
