import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    archivePaymentTransactionsError,
    archivePaymentTransactionsRequest,
    archivePaymentTransactionsSuccess,
} from 'reducer/payment-transaction/archive-payment-transactions/actions';
import { ArchivePaymentTransactionsTypes } from 'reducer/payment-transaction/archive-payment-transactions/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleArchivePaymentTransactions(action: ReturnType<typeof archivePaymentTransactionsRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(paymentTransactionAPI.archivePaymentTransactions, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(archivePaymentTransactionsError(mapped));
            return;
        }
        yield put(archivePaymentTransactionsSuccess());
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(archivePaymentTransactionsError(mapped));
    }
}

function* watchArchivePaymentTransactions() {
    yield takeLeading(ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_REQUEST, handleArchivePaymentTransactions);
}

function* archivePaymentTransactionsSaga() {
    yield all([fork(watchArchivePaymentTransactions)]);
}

export default archivePaymentTransactionsSaga;
