import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { UserDetailData } from 'model/user';
import { markError } from 'reducer/application/error/actions';
import { userDetailError, userDetailRequest, userDetailSuccess } from 'reducer/user/user-detail/actions';
import { UserDetailTypes } from 'reducer/user/user-detail/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import userApi from 'services/api/user-api';

import { ErrorUtils } from 'shared/error/error-utils';

function* handleUserDetail(action: ReturnType<typeof userDetailRequest>) {
    try {
        const result: AxiosResponse<UserDetailData> = yield call(
            userApi.detailUser,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(userDetailError(mapped));
            return;
        }
        yield put(userDetailSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userDetailError(mapped));
    }
}

function* watchUserDetail() {
    yield takeEvery(UserDetailTypes.USER_DETAIL_REQUEST, handleUserDetail);
}

function* userDetailSaga() {
    yield all([fork(watchUserDetail)]);
}

export default userDetailSaga;