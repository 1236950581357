import { ScdCompany, ScdCompanyCreateUpdateRequest } from 'model/company';
import { PersonType } from 'model/enums/person-type';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.company.validate';

const COMPANY_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};

export const validateCompanyName = (value: string | undefined | null) => ValidationUtils.validate(value, COMPANY_NAME_VALIDATION);

const COMPANY_CNPJ_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    isValid: {
        predicate: ValidationUtils.STRING.isValidCNPJ,
        errorMessage: `${I18N_PREFIX}.identification.invalidCNPJ`,
    },
};

const COMPANY_CPF_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    isValid: {
        predicate: ValidationUtils.STRING.isValidCPF,
        errorMessage: `${I18N_PREFIX}.identification.invalidCPF`,
    },
};

export const validateCompanyIdentification = (value: string | undefined | null, personType: PersonType | undefined) => {
    if (personType === PersonType.CORPORATION) {
        return ValidationUtils.validate(value, COMPANY_CNPJ_VALIDATION);
    }

    return ValidationUtils.validate(value, COMPANY_CPF_VALIDATION);
}

const COMPANY_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};

export const validateCompanyType = (value: string | undefined | null) => ValidationUtils.validate(value, COMPANY_TYPE_VALIDATION);





const COMPANY_ORGANIZATIONS_VALIDATION: Validations<readonly ScdCompany[] | undefined> = {
    required: {
        predicate: it => it !== null && it !== undefined && it.length !== 0,
        errorMessage: `${I18N_PREFIX}.organizations.required`,
    },
};

export const validateCompanyOrganizations = (value: readonly ScdCompany[] | undefined) =>
    ValidationUtils.validate(value, COMPANY_ORGANIZATIONS_VALIDATION);

export const validateScdCompany = (value: ScdCompanyCreateUpdateRequest | undefined) => {
    if (!value) {
        return false;
    }

    return (
        validateCompanyName(value.name).isValid &&
        validateCompanyIdentification(value.identification, value.type).isValid &&
        validateCompanyType(value.type).isValid &&
        validateCompanyOrganizations(value.organizations).isValid
    );
};
