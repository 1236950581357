import { PaymentInstitutionAccountSimpleToUpdate } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum UpdatePaymentInstitutionAccountTypes {
    UPDATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST = '@@payment-institution-account-update/UPDATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST',
    UPDATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS = '@@payment-institution-account-update/UPDATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS',
    UPDATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR = '@@payment-institution-account-update/UPDATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR',
    UPDATE_PAYMENT_INSTITUTION_ACCOUNT_RESET = '@@payment-institution-account-update/UPDATE_PAYMENT_INSTITUTION_ACCOUNT_RESET',
}

export interface UpdatePaymentInstitutionsAccountState extends BaseStoreState {
    readonly paymentInstitutionAccount?: PaymentInstitutionAccountSimpleToUpdate
}
