import { ScdBatchToGenerateCessionResponse } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchGenerateCessionTypes {
    BATCH_GENERATE_CESSION_REQUEST = '@@batch-generate-cession/BATCH_GENERATE_CESSION_REQUEST',
    BATCH_GENERATE_CESSION_SUCCESS = '@@batch-generate-cession/BATCH_GENERATE_CESSION_SUCCESS',
    BATCH_GENERATE_CESSION_ERROR = '@@batch-generate-cession/BATCH_GENERATE_CESSION_ERROR',
    BATCH_GENERATE_CESSION_RESET = '@@batch-generate-cession/BATCH_GENERATE_CESSION_RESET',
}

export interface BatchGenerateCessionState extends BaseStoreState {
    readonly cession?: ScdBatchToGenerateCessionResponse;
}
