import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { recalculateSessionInterestRateError, recalculateSessionInterestRateRequest, recalculateSessionInterestRateSuccess } from './actions';
import { RecalculateSessionInterestRateTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import sessionRateApi from 'services/api/session-interest-rates-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { SessionInterestRateResponse } from 'model/session-interest-rates';

function* handleRecalculateSessionInterestRate(action: ReturnType<typeof recalculateSessionInterestRateRequest>) {
    try {
        const result: AxiosResponse<SessionInterestRateResponse> = yield call(sessionRateApi.recalculateSessionInterestRates, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(recalculateSessionInterestRateError(mapped));
            return;
        }
        yield put(recalculateSessionInterestRateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(recalculateSessionInterestRateError(mapped));
    }
}

function* watchRecalculateSessionInterestRate() {
    yield takeLeading(RecalculateSessionInterestRateTypes.RECALCULATE_SESSION_INTEREST_RATE_REQUEST, handleRecalculateSessionInterestRate);
}

function* recalculateSessionInterestRateSaga() {
    yield all([fork(watchRecalculateSessionInterestRate)]);
}

export default recalculateSessionInterestRateSaga;
