import { BatchExport } from 'model/batch-output-hemera';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchExportTypes {
    BATCH_EXPORT_REQUEST = '@@batch-export/BATCH_EXPORT_REQUEST',
    BATCH_EXPORT_SUCCESS = '@@batch-export/BATCH_EXPORT_SUCCESS',
    BATCH_EXPORT_ERROR = '@@batch-export/BATCH_EXPORT_ERROR',
    BATCH_EXPORT_RESET = '@@batch-export/BATCH_EXPORT_RESET',
}

export interface BatchExportState extends BaseStoreState {
    readonly batchExport?: BatchExport;
}