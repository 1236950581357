import { ScdPaymentTransactionSummarize } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum SummarizePaymentTransactionsTypes {
    SUMMARIZE_PAYMENT_TRANSACTIONS_REQUEST = '@@payment-transactions-summarize/SUMMARIZE_PAYMENT_TRANSACTIONS_REQUEST',
    SUMMARIZE_PAYMENT_TRANSACTIONS_SUCCESS = '@@payment-transactions-summarize/SUMMARIZE_PAYMENT_TRANSACTIONS_SUCCESS',
    SUMMARIZE_PAYMENT_TRANSATIONS_ERROR = '@@payment-transactions-summarize/SUMMARIZE_PAYMENT_TRANSATIONS_ERROR',
    SUMMARIZE_PAYMENT_TRANSACTIONS_RESET = '@@payment-transactions-summarize/SUMMARIZE_PAYMENT_TRANSACTIONS_RESET',
}

export interface SummarizePaymentTransactionsState extends BaseStoreState {
    readonly pending?: ScdPaymentTransactionSummarize
}
