import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateOrganizationTypes, CreateOrganizationState } from './types';
import { Reducer } from 'redux';

export const initialState: CreateOrganizationState = {
    status: HttpRequestStatus.NOOP,
    organizationToken: undefined,
    error: undefined,
};

const reducer: Reducer<CreateOrganizationState> = (state = initialState, action): CreateOrganizationState => {
    switch (action.type) {
        case CreateOrganizationTypes.CREATE_ORGANIZATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CreateOrganizationTypes.CREATE_ORGANIZATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, organizationToken: action.payload, error: undefined };
        }
        case CreateOrganizationTypes.CREATE_ORGANIZATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateOrganizationTypes.CREATE_ORGANIZATION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createOrganizationReducer };
