import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { listProgramGroupsRequest, listProgramGroupsError, listProgramGroupsSuccess } from 'reducer/program-groups/list-program-groups/actions';
import { ListProgramGroupsTypes } from 'reducer/program-groups/list-program-groups/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programGroupsApi from 'services/api/program-groups-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { ProgramGroupsToGetAll } from 'model/program-groups';

function* handleListProgramGroups(action: ReturnType<typeof listProgramGroupsRequest>) {
    try {
        const result: AxiosResponse<Page<ProgramGroupsToGetAll>> = yield call(programGroupsApi.listProgramGroups, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listProgramGroupsError(mapped));
            return;
        }
        yield put(listProgramGroupsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listProgramGroupsError(mapped));
    }
}

function* watchListProgramGroups() {
    yield takeEvery(ListProgramGroupsTypes.LIST_PROGRAM_GROUPS_REQUEST, handleListProgramGroups);
}

function* listProgramGroupsSaga() {
    yield all([fork(watchListProgramGroups)]);
}

export default listProgramGroupsSaga;
