import { ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DefaultTechnicalSupplierTypes {
    DEFAULT_TECHNICAL_SUPPLIER_REQUEST = '@@technical-supplier-detail/DEFAULT_TECHNICAL_SUPPLIER_REQUEST',
    DEFAULT_TECHNICAL_SUPPLIER_SUCCESS = '@@technical-supplier-detail/DEFAULT_TECHNICAL_SUPPLIER_SUCCESS',
    DEFAULT_TECHNICAL_SUPPLIER_ERROR = '@@technical-supplier-detail/DEFAULT_TECHNICAL_SUPPLIER_ERROR',
    DEFAULT_TECHNICAL_SUPPLIER_RESET_STATE = '@@technical-supplier-detail/DEFAULT_TECHNICAL_SUPPLIER_RESET_STATE',
}

export interface DefaultTechnicalSupplierState extends BaseStoreState {
    readonly defaultTechnicalSupplier?: ScdTechnicalSupplierSimple;
}
