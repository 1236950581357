import { BatchSignatureReExportResponse } from 'model/batch-signature';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchSignatureReExportTypes {
    BATCH_SIGNATURE_RE_EXPORT_REQUEST = '@@batch-signature-re-export/BATCH_SIGNATURE_RE_EXPORT_REQUEST',
    BATCH_SIGNATURE_RE_EXPORT_SUCCESS = '@@batch-signature-re-export/BATCH_SIGNATURE_RE_EXPORT_SUCCESS',
    BATCH_SIGNATURE_RE_EXPORT_ERROR = '@@batch-signature-re-export/BATCH_SIGNATURE_RE_EXPORT_ERROR',
    BATCH_SIGNATURE_RE_EXPORT_RESET = '@@batch-signature-re-export/BATCH_SIGNATURE_RE_EXPORT_RESET'
}

export interface BatchSignatureReExportState extends BaseStoreState {
    readonly signatureReExport?: BatchSignatureReExportResponse;
}