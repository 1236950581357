import clsx from 'clsx';
import DateFormatter from 'components/formatter/date/date-formatter';
import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import BatchModalAction, { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sendBatchCcbDocumentsResetState } from 'reducer/batch/send-ccb-documents/actions';
import { useBatchDetailWithProgramState, useExportBatchState, useRootDispatch, useSendBatchCcbDocumentsState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './batch-send-documents.scss';
import BatchSendDocumentsSlideButtons from 'entities/batch/batch-send-documents/components/batch-send-documents-slide-buttons/batch-send-documents-slide-buttons';
import { compose } from 'redux';
import { withResumeBatchWrapper } from 'shared/provider/batchContext';

const I18N_PREFIX = 'pages.scd.batch';

const BatchSendDocuments = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { toastLoading, closeToast, toastSuccess } = useToast();

    const { batchWithProgram } = useBatchDetailWithProgramState();
    const { status: sendCcbDocumentsStatus } = useSendBatchCcbDocumentsState();
    const { status: exportBatchStatus } = useExportBatchState();

    const [modalAction, setModalAction] = useState<BatchModalActionOptions | undefined>(undefined);

    useEffect(() => {
        if (sendCcbDocumentsStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
            toastSuccess(t(`${I18N_PREFIX}.send-ccb-documents.toast.success`));
        }
    }, [sendCcbDocumentsStatus, dispatch, toastLoading, closeToast, toastSuccess, t]);

    const isDisabledModalButton = exportBatchStatus === HttpRequestStatus.ON_GOING;

    return (
        <main className="batch-send-documents">
            <section className="batch-send-documents__container">
                <h1 className="batch-send-documents__container-title">{t(`${I18N_PREFIX}.send-ccb-documents.title`)}</h1>
                <BatchDetailHeader>
                    <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.batch`)}>
                        {batchWithProgram?.code ?? '-'}
                    </BatchDetailHeaderContent>
                    <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.type`)} className="header-content__type-content">
                        {t(`${I18N_PREFIX}.product.options.${batchWithProgram?.product}`).toUpperCase()}
                    </BatchDetailHeaderContent>
                    <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.originator`)} isTooltip>
                        {batchWithProgram?.originator?.name ?? '-'}
                    </BatchDetailHeaderContent>
                    <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.batch-date`)}>
                        {batchWithProgram?.date ? <DateFormatter date={batchWithProgram?.date} /> : '-'}
                    </BatchDetailHeaderContent>
                    <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.status`)} isTooltip>
                        <span className={clsx('batch-send-documents__container-status', batchWithProgram?.status)}>
                            {t(`${I18N_PREFIX}.status.options.${batchWithProgram?.status}`).toUpperCase()}
                        </span>
                    </BatchDetailHeaderContent>
                </BatchDetailHeader>
                <BatchSendDocumentsSlideButtons setModalAction={setModalAction} />
            </section>
            <BatchModalAction
                batchId={batchWithProgram?.id}
                modalAction={modalAction}
                setModalAction={setModalAction}
                disabled={isDisabledModalButton}
            />
        </main>
    );
};

export default compose(withResumeBatchWrapper())(BatchSendDocuments);
