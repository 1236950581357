import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramPolicy } from 'model/program-policy';

export type ApiResponse = Record<string, string>;

export enum DetailProgramPolicyTypes {
    DETAIL_PROGRAM_POLICY_REQUEST = '@@program-policy-detail/DETAIL_PROGRAM_POLICY_REQUEST',
    DETAIL_PROGRAM_POLICY_SUCCESS = '@@program-policy-detail/DETAIL_PROGRAM_POLICY_SUCCESS',
    DETAIL_PROGRAM_POLICY_ERROR = '@@program-policy-detail/DETAIL_PROGRAM_POLICY_ERROR',
    DETAIL_PROGRAM_POLICY_RESET_STATE = '@@program-policy-detail/DETAIL_PROGRAM_POLICY_RESET_STATE',
}

export interface DetailProgramPolicyState extends BaseStoreState {
    readonly programPolicy?: ScdProgramPolicy;
}
