import DescriptionIcon from '@material-ui/icons/Description';
import { PopperProps, Tooltip } from '@material-ui/core';

interface ListReportsItemProps {
    action: () => void;
    title: string;
}

const ListReportsItem = (props: ListReportsItemProps) => {
    const { action, title } = props;

    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    return (
        <div className="batch-reports-list__container--content">
            <div className="batch-reports-list__container--content-items">
                <DescriptionIcon />
                <Tooltip title={title} PopperProps={popperProps}>
                    <span>{title}</span>
                </Tooltip>
            </div>
            <span className="batch-reports-list__container--content-icon" onClick={action} />
        </div>
    );
};

export default ListReportsItem;
