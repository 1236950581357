import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';
import { PaymentInstitutionAccountFilterPageable } from 'model/reducers';
import { ListPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listPaymentInstitutionsAccountsRequest = (request: PaymentInstitutionAccountFilterPageable) =>
    action(ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, request);
export const listPaymentInstitutionsAccountsSuccess = (paymentAccounts: Page<PaymentInstitutionAccountToGetAll>) =>
    action(ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, paymentAccounts);
export const listPaymentInstitutionsAccountsError = (error: GiroWebappError) =>
    action(ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error);
export const listPaymentInstitutionsAccountsResetState = () =>
    action(ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
