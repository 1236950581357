import ActionButton from 'components/buttons/action-button/action-button';
import { OperationsModalMessageActions } from 'entities/ccb-operation/ccb-operation';
import { useTranslation } from 'react-i18next';
import './create-batch-operations-slide.scss';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import LinkButton from 'components/buttons/link-button/link-button';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';

interface CreateBatchOperationsSlideProps {
    setModalMessage: (value: OperationsModalMessageActions | undefined) => void;
    selectedOperationsAmount: number;
    totalAmountOperations: number;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

const CreateBatchOperationsSlide = (props: CreateBatchOperationsSlideProps) => {
    const { setModalMessage, selectedOperationsAmount, totalAmountOperations } = props;

    const { t } = useTranslation();

    return (
        <div className="scd-create-batch-operations-slide">
            <div className="scd-create-batch-operations-slide--container">
                <div className="scd-create-batch-operations-slide--content">
                    <div className="scd-create-batch-operations-slide--content__item">
                        <span className="scd-create-batch-operations-slide--content__item-label"> {t(`${I18N_PREFIX}.slide.total`)} </span>
                        <span className="scd-create-batch-operations-slide--content__item-value">
                            <CurrencyFormatter value={totalAmountOperations} prefix />
                        </span>
                    </div>
                </div>
                <div className="scd-create-batch-operations-slide--content__selected-operations">
                    {t(`${I18N_PREFIX}.selected-operation`, {
                        selectedOperationsAmount,
                        count: selectedOperationsAmount,
                    }).toLocaleLowerCase()}
                </div>
                <div className="scd-create-batch-operations-slide--content">
                    <span style={{ width: '145px', marginRight: '30px' }}>
                        <LinkButton
                            label={t('entity.action.cancel')}
                            onClick={() => setModalMessage(OperationsModalMessageActions.CANCEL)}
                            disabled={selectedOperationsAmount === 0}
                            outlinedStyle
                        />
                    </span>
                    <ActionButton
                        label={t(`${I18N_PREFIX}.filter.buttons.createBatch`)}
                        onClick={() => setModalMessage(OperationsModalMessageActions.CREATE_BATCH)}
                        startAdornment={<CreateNewFolderIcon />}
                        disabled={selectedOperationsAmount === 0}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateBatchOperationsSlide;
