import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentInstitutionToGetTransaction } from 'model/payment-institution-account';
import { useTranslation } from 'react-i18next';

interface PaymentInstitutionCardProps {
    paymentInstitution: PaymentInstitutionToGetTransaction | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentInstitutionCard = (props: PaymentInstitutionCardProps) => {
    const { paymentInstitution } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}>
            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.paymentInstitution.label`} value={paymentInstitution?.name} />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentInstitution.status`}
                value={t(`${I18N_PREFIX}.inputs.paymentInstitution.statusOptions.${paymentInstitution?.status}`)}
            />
        </ReadOnlyCard>
    );
};

export default PaymentInstitutionCard;
