import { ScdBatchSimple } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DeleteBatchOperationTypes {
    DELETE_BATCH_OPERATION_REQUEST = '@@batch-operation-delete/DELETE_BATCH_OPERATION_REQUEST',
    DELETE_BATCH_OPERATION_SUCCESS = '@@batch-operation-delete/DELETE_BATCH_OPERATION_SUCCESS',
    DELETE_BATCH_OPERATION_ERROR = '@@batch-operation-delete/DELETE_BATCH_OPERATION_ERROR',
    DELETE_BATCH_OPERATION_RESET = '@@batch-operation-delete/DELETE_BATCH_OPERATION_RESET',
}

export interface DeleteBatchOperationState extends BaseStoreState {
    readonly deletedBatch?: ScdBatchSimple;
}
