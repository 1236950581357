import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import { PhoneNumberMask } from 'components/inputs/phone-number-input/phone-number-mask';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import clsx from 'clsx';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

interface ValidPhoneNumberInputProps extends Base {
    onChange?: (value: string) => void;
}

const ValidPhoneNumberInput = (props: ValidPhoneNumberInputProps) => {
    const { validate, showValidation, value, onChange, ...otherProps } = props;

    const handleChange = (_value: string) => {
        const normalized = InputUtils.formatPhoneNumber(_value);

        if (onChange) {
            onChange(normalized);
        }
    };

    return (
        <ValidInput value={value} validate={validate} showValidation={showValidation} className={clsx('base-input', { dirty: value })}>
            {({ error }) => (
                <BaseMaskInput {...otherProps} error={error} value={value} onChange={handleChange} MaskInputComponent={PhoneNumberMask} />
            )}
        </ValidInput>
    );
};

export default ValidPhoneNumberInput;
