import { ScdIssuerList } from 'model/issuer';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListIssuersTypes {
    LIST_ISSUERS_REQUEST = '@@issuer-list/LIST_ISSUERS_REQUEST',
    LIST_ISSUERS_SUCCESS = '@@issuer-list/LIST_ISSUERS_SUCCESS',
    LIST_ISSUERS_ERROR = '@@issuer-list/LIST_ISSUERS_ERROR',
    LIST_ISSUERS_RESET_STATE = '@@issuer-list/LIST_ISSUERS_RESET_STATE',
}

export interface ListIssuersState extends BaseStoreState {
    readonly issuers?: Page<ScdIssuerList>;
}
