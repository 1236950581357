import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export type ModalActionMenuItemType = {
    titleKey: string;
    onClick: () => void;
    show?: boolean;
};

const ModalActionMenuItem = (props: ModalActionMenuItemType) => {
    const { titleKey, onClick, show } = props;

    const { t } = useTranslation();

    if (show === false) {
        return <></>;
    }

    return <MenuItem onClick={onClick}>{t(titleKey)}</MenuItem>;
};

export default ModalActionMenuItem;
