import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create Tax
import { createTaxReducer } from 'reducer/tax/create-tax/reducer';
import createTaxSaga from 'reducer/tax/create-tax/sagas';
import { CreateTaxState } from 'reducer/tax/create-tax/types';

// List Taxes
import { listTaxesReducer } from 'reducer/tax/list-taxes/reducer';
import listTaxesSaga from 'reducer/tax/list-taxes/sagas';
import { ListTaxesState } from 'reducer/tax/list-taxes/types';

export interface TaxState {
    readonly createTax: CreateTaxState;
    readonly listTaxes: ListTaxesState;
}

export const TaxReducer: ReducersMapObject<TaxState, AnyAction> = {
    createTax: createTaxReducer,
    listTaxes: listTaxesReducer,
};

export function* taxSagas() {
    yield all([fork(createTaxSaga), fork(listTaxesSaga)]);
}
