import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';
import ScdBankerPage from 'entities/banker/banker';
import ScdBankerDetailPage from 'entities/banker/banker-detail/banker-detail';
import ScdBankerCreateUpdatePage from 'entities/banker/banker-create-update/banker-create-update';

interface MatchParams {
    url: string;
}

const BankerRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdBankerPage} />
        <PrivateRoute exact path={`${match.url}/new`} component={ScdBankerCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/edit/:bankerId`} component={ScdBankerCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:bankerId`} component={ScdBankerDetailPage} />
    </Switch>
);

export default BankerRoutes;
