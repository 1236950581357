import { Checkbox } from '@material-ui/core';
import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdImportedOperationsData } from 'model/imported-operation';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.ccb-operation.table';

interface ScdOperationHeaderProps {
    operations: ScdImportedOperationsData[];
    handleSort: (property: ImportedOperationsDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
    showCheckBox?: boolean;
    isSelectedAllCheckbox: boolean;
    setIsSelectedAllCheckbox: (value: boolean) => void;
    disabledCheckboxAll: boolean;
    setSelectedOperations: (value: number[]) => void;
}

export type ImportedOperationsDataSortableProperties = keyof ScdImportedOperationsData | 'companyIdentificationNumber';

export const ScdOperationHeader = (props: ScdOperationHeaderProps) => {
    const { t } = useTranslation();
    const {
        operations,
        handleSort,
        sortedProperty,
        sortOrder,
        disabledSort,
        showCheckBox,
        isSelectedAllCheckbox,
        setIsSelectedAllCheckbox,
        disabledCheckboxAll,
        setSelectedOperations,
    } = props;

    useEffect(() => {
        if (!isSelectedAllCheckbox) {
            setSelectedOperations([]);
        }
    }, [isSelectedAllCheckbox, setSelectedOperations]);

    const mapSortable = (_property: ImportedOperationsDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (operations && operations.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
            <SortableColumn label={t(`${I18N_PREFIX}.batchCode`).toUpperCase()} sortable={mapSortable('batchCode')} />
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('acceptedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.product`).toUpperCase()} sortable={mapSortable('product')} />
            <SortableColumn label={t(`${I18N_PREFIX}.originator`).toUpperCase()} sortable={mapSortable('originator')} />
            <SortableColumn
                label={t(`${I18N_PREFIX}.loanerIdentification`).toUpperCase()}
                sortable={mapSortable('companyIdentificationNumber')}
            />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn label={t(`${I18N_PREFIX}.contracts`).toUpperCase()} />

            {showCheckBox && (
                <SortableColumn
                    label={
                        <Checkbox
                            checked={isSelectedAllCheckbox}
                            disabled={disabledCheckboxAll}
                            onChange={() => (!disabledCheckboxAll ? setIsSelectedAllCheckbox(!isSelectedAllCheckbox) : undefined)}
                        />
                    }
                />
            )}
        </tr>
    );
};

export default ScdOperationHeader;
