import { GiroWebappError } from 'model/error';
import {
    ScdPaymentTransactionsByBatchLegacyErrorsList,
    ScdPaymentTransactionsCreateLegacyByBatch,
} from 'model/payment-transaction-by-batch';
import { CreatePaymentTransactionsByBatchLegacyTypes } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/types';
import { action } from 'typesafe-actions';

export const createPaymentTransactionsByBatchLegacyRequest = (request: ScdPaymentTransactionsCreateLegacyByBatch) =>
    action(CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST, request);
export const createPaymentTransactionsByBatchLegacySuccess = (
    paymentTransactionByBatcLegacyhErrors: ScdPaymentTransactionsByBatchLegacyErrorsList
) =>
    action(
        CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS,
        paymentTransactionByBatcLegacyhErrors
    );
export const createPaymentTransactionsByBatchLegacyError = (error: GiroWebappError) =>
    action(CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR, error);
export const createPaymentTransactionsByBatchLegacyResetState = () =>
    action(CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET);
