import { Popover } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from 'clsx';
import { ReactComponent as DownloadIcon } from 'images/arrow-download.svg';
import UploadIcon from 'images/upload.svg';
import { isNull } from 'lodash';
import { FileMime } from 'model/enums/file-mime';
import { FileStatus } from 'model/enums/file-status';
import { MonthlyCommissionStatus } from 'model/enums/monthly-commission-status';
import { TaxValueDocumentType } from 'model/enums/tax-value-document-type';
import {
    ScdMonthlyCommissionDeleteTaxDocumentRequest,
    ScdMonthlyCommissionDetailDocument,
    ScdMonthlyCommissionDownloadTaxDocumentRequest,
    ScdMonthlyCommissionUploadTaxDocumentRequest,
} from 'model/monthly-commission';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks/use-toast';
import './monthly-commission-tax-file-input.scss';

interface MonthlyCommissionTaxFileInputProps {
    documentFile: ScdMonthlyCommissionDetailDocument | undefined;
    taxValueId: number | undefined;
    disabled?: boolean;
    label: string;
    documentType: TaxValueDocumentType;
    monthlyCommissionId: number | undefined;
    monthlyCommissionStatus: MonthlyCommissionStatus | undefined;
    onUpload: (value: ScdMonthlyCommissionUploadTaxDocumentRequest) => void;
    onDownload?: (value: ScdMonthlyCommissionDownloadTaxDocumentRequest) => void;
    onDelete?: (value: ScdMonthlyCommissionDeleteTaxDocumentRequest) => void;
}

interface Event<T = EventTarget> {
    target: T;
}

const I18N_PREFIX = 'pages.scd.monthly-commission-detail';

const findFileMime = {
    'application/pdf': FileMime.PDF,
    'image/jpeg': FileMime.JPEG,
} as Record<string, FileMime>;

const FILE_SIZE_LIMIT_IN_MB = 1;

export const MonthlyCommissionTaxFileInput = (props: MonthlyCommissionTaxFileInputProps) => {
    const {
        documentFile,
        disabled,
        label,
        documentType,
        monthlyCommissionId,
        onUpload,
        onDownload,
        onDelete,
        monthlyCommissionStatus,
        taxValueId,
    } = props;

    const { t } = useTranslation();

    const { toastInfo } = useToast();

    const [messageError, setShowMessageError] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (messageError) {
            toastInfo(t(`${I18N_PREFIX}.toast.upload-file.info`));
            setShowMessageError(false);
        }
    }, [messageError, toastInfo, t]);

    const fileToByteArray = (file: File): Promise<number[]> => {
        return new Promise((resolve, reject) => {
            try {
                const reader = new FileReader();
                const fileByteArray: number[] = [];
                reader.readAsArrayBuffer(file);
                reader.onloadend = evt => {
                    if (evt?.target?.readyState === FileReader.DONE) {
                        const arrayBuffer = evt.target.result as ArrayBuffer;
                        const array = new Uint8Array(arrayBuffer);
                        for (const byte of array) {
                            fileByteArray.push(byte);
                        }
                    }
                    resolve(fileByteArray);
                };
            } catch (e) {
                reject(e);
            }
        });
    };

    const handleOnUpload = async (event: Event<HTMLInputElement>) => {
        if (isNull(event.target.files)) return;
        if (!event.target.files[0]) return;
        if (!monthlyCommissionId) return;
        if (!taxValueId) return;

        setShowMessageError(false);

        const file = event.target.files[0];
        const fileName = file.name;
        const fileType = file.type;
        const fileSize = file.size / 1024 / 1024; // MB

        if (fileSize > FILE_SIZE_LIMIT_IN_MB) {
            setShowMessageError(true);
            return;
        }

        const byteArray = await fileToByteArray(file);

        const _proposeUploadDocumentFile: ScdMonthlyCommissionUploadTaxDocumentRequest = {
            commissionId: monthlyCommissionId,
            type: documentType,
            taxValueId,
            document: {
                taxValueId,
                data: byteArray,
                fileName,
                type: documentType,
                monthlyCommissionId,
                fileMime: findFileMime[fileType],
            },
        };

        onUpload(_proposeUploadDocumentFile);
    };

    const handleOnDowload = () => {
        if (!monthlyCommissionId) return;
        if (!onDownload) return;
        if (!taxValueId || !documentFile?.id) return;

        const _proposeDownloadDocumentFile: ScdMonthlyCommissionDownloadTaxDocumentRequest = {
            commissionId: monthlyCommissionId,
            taxValueId: taxValueId,
            type: documentType,
            documentId: documentFile?.id,
        };

        onDownload(_proposeDownloadDocumentFile);
    };

    const handleOnDelete = () => {
        if (!monthlyCommissionId) return;
        if (!documentFile?.id || !taxValueId) return;
        if (!onDelete) return;

        const _proposeDownloadDocumentFile: ScdMonthlyCommissionDeleteTaxDocumentRequest = {
            taxValueId: taxValueId,
            commissionId: monthlyCommissionId,
            documentId: documentFile?.id,
            type: documentType,
        };

        onDelete(_proposeDownloadDocumentFile);
    };

    return (
        <div className="monthly-commission-tax-file-input">
            {documentFile?.fileStatus === FileStatus.UPLOADED ? (
                <div className="monthly-commission-tax-file-input__file--selected">
                    {monthlyCommissionStatus === MonthlyCommissionStatus.WAITING ? (
                        <>
                            <AttachFileIcon fontSize="small" className="monthly-commission-tax-file-input__file--selected-file-icon" />
                            <span className="monthly-commission-tax-file-input__file--selected-value">{documentFile?.fileName}</span>
                            <CancelIcon
                                fontSize="small"
                                className="monthly-commission-tax-file-input__file--selected-button-cancel"
                                onClick={handleOnDelete}
                            />
                        </>
                    ) : (
                        <>
                            <DownloadIcon
                                className={clsx('monthly-commission-tax-file-input__file--selected-file-icon', { isDownload: true })}
                            />
                            <span
                                className={clsx('monthly-commission-tax-file-input__file--selected-value', { isDownload: true })}
                                onClick={handleOnDowload}
                            >
                                {documentFile.fileName}
                            </span>
                        </>
                    )}
                </div>
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label>
                            {!disabled && (
                                <input style={{ display: 'none' }} type="file" onChange={handleOnUpload} accept=".jpg, .jpeg, .pdf" />
                            )}
                            <span className={clsx('monthly-commission-tax-file-input__file', { disabled: disabled })}>
                                <img src={UploadIcon} alt="upload" className="monthly-commission-tax-file-input__file--icon" />
                                <span className="monthly-commission-tax-file-input__file--label">{label}</span>
                            </span>
                        </label>
                        <div className="monthly-commission-tax-file-input__description-icon" onClick={e => handleClick(e)}>
                            <span>
                                <InfoOutlinedIcon fontSize="small" />
                            </span>
                        </div>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 15,
                                horizontal: 20,
                            }}
                        >
                            <div className="monthly-commission-tax-file-input__description-icon--popover">
                                {t(`${I18N_PREFIX}.popover.upload-file.info`)}
                            </div>
                        </Popover>
                    </div>
                </>
            )}
        </div>
    );
};

export default MonthlyCommissionTaxFileInput;
