import { ChunkStreamSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ChunkStreamRetryBcoTypes {
    CHUNK_STREAM_RETRY_BCO_REQUEST = '@@chunk-stream-retry-bco/CHUNK_STREAM_RETRY_BCO_REQUEST',
    CHUNK_STREAM_RETRY_BCO_SUCCESS = '@@chunk-stream-retry-bco/CHUNK_STREAM_RETRY_BCO_SUCCESS',
    CHUNK_STREAM_RETRY_BCO_ERROR = '@@chunk-stream-retry-bco/CHUNK_STREAM_RETRY_BCO_ERROR',
    CHUNK_STREAM_RETRY_BCO_RESET = '@@chunk-stream-retry-bco/CHUNK_STREAM_RETRY_BCO_RESET',
}

export interface ChunkStreamRetryBcoState extends BaseStoreState {
    readonly chunkStreamRetryBco?: ChunkStreamSimpleResponse;
}
