import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { listOrganizationsError, listOrganizationsSuccess, listOrganizationsRequest } from './actions';
import { ListOrganizationsTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import organizationApi from 'services/api/organization-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { ScdOrganizationList } from 'model/organization';

function* handleListOrganizations(action: ReturnType<typeof listOrganizationsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdOrganizationList>> = yield call(organizationApi.listOrganizations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listOrganizationsError(mapped));
            return;
        }
        yield put(listOrganizationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listOrganizationsError(mapped));
    }
}

function* watchListOrganizations() {
    yield takeEvery(ListOrganizationsTypes.LIST_ORGANIZATIONS_REQUEST, handleListOrganizations);
}

function* listOrganizationsSaga() {
    yield all([fork(watchListOrganizations)]);
}

export default listOrganizationsSaga;
