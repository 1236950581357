import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdCcbOperations from 'entities/ccb-operation/ccb-operation';
import ScdCcbOperationDetail from 'entities/ccb-operation/ccb-operation-detail/ccb-operation-detail';
import ScdCcbOperationCreateBatch from 'entities/ccb-operation/ccb-operation-create-batch/ccb-operation-create-batch';

import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const CcbOperationRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/create-batch`} component={ScdCcbOperationCreateBatch} />

        <PrivateRoute exact path={`${match.url}/:operationId`} component={ScdCcbOperationDetail} />

        <PrivateRoute exact path={`${match.url}`} component={ScdCcbOperations} />
    </Switch>
);

export default CcbOperationRoutes;
