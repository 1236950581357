import { AxiosResponse } from 'axios';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    detailCompanyGroupPolicyError,
    detailCompanyGroupPolicyRequest,
    detailCompanyGroupPolicySuccess,
} from 'reducer/company-group-policy/detail-company-group-policy/actions';
import { DetailCompanyGroupPolicyTypes } from 'reducer/company-group-policy/detail-company-group-policy/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyGroupPolicyAPI from 'services/api/company-group-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailCompanyGroupPolicy(action: ReturnType<typeof detailCompanyGroupPolicyRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyGroupPolicy> = yield call(companyGroupPolicyAPI.detailCompanyGroupPolicy, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailCompanyGroupPolicyError(mapped));
            return;
        }
        yield put(detailCompanyGroupPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailCompanyGroupPolicyError(mapped));
    }
}

function* watchDetailCompanyGroupPolicy() {
    yield takeEvery(DetailCompanyGroupPolicyTypes.DETAIL_COMPANY_GROUP_POLICY_REQUEST, handleDetailCompanyGroupPolicy);
}

function* detailCompanyGroupPolicySaga() {
    yield all([fork(watchDetailCompanyGroupPolicy)]);
}

export default detailCompanyGroupPolicySaga;
