import { HttpRequestStatus } from 'model/enums/http-request-status';

import { Reducer } from 'redux';
import { ConcludeCessionBcoState, ConcludeCessionBcoTypes } from 'reducer-bco/batch-output/conclude-cession/types';

const initialState: ConcludeCessionBcoState = {
    status: HttpRequestStatus.NOOP,
    concludeCession: undefined,
    error: undefined,
};

const reducer: Reducer<ConcludeCessionBcoState> = (state = initialState, action): ConcludeCessionBcoState => {
    switch (action.type) {
        case ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, concludeCession: action.payload, error: undefined };
        }
        case ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as ConcludeCessionBcoReducer };
