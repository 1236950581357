import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import InputUtils from 'components/inputs/input-utils';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../base-mask-input';
import ValidInput, { ValidInputProps } from '../validation/valid-input';
import CurrencyMask from './currency-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<number | undefined>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface CurrencyInput extends Base {
    onChange?: (value: number | undefined) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    zeroValue?: boolean;
}

const ValidCurrencyInput = (props: CurrencyInput) => {
    const { validate, showValidation, value, onChange, MaskInputComponent, zeroValue, externalUpdate, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = InputUtils.currencyStringToNumber(_value);

        if (onChange && !isNaN(normalized)) {
            onChange(normalized);
        }
    };

    return (
        <ValidInput
            value={value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('base-input', { dirty: zeroValue ?? value })}
        >
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    externalUpdate={externalUpdate}
                    error={error}
                    value={InputUtils.numberToCurrecyLikeString(value)}
                    onChange={handleOnChange}
                    MaskInputComponent={MaskInputComponent ?? CurrencyMask}
                    defaultValue={zeroValue ? '0.00' : ''}
                />
            )}
        </ValidInput>
    );
};

export default ValidCurrencyInput;
