import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationReexport } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsReexportError,
    importedOperationsReexportRequest,
    importedOperationsReexportSuccess,
} from 'reducer/imported-operation/reexport/actions';
import { ImportedOperationsReexportTypes } from 'reducer/imported-operation/reexport/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsReexport(action: ReturnType<typeof importedOperationsReexportRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationReexport> = yield call(importedOperationApi.reexport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsReexportError(mapped));
            return;
        }
        yield put(importedOperationsReexportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsReexportError(mapped));
    }
}

function* watchImportedOperationsReexport() {
    yield takeEvery(ImportedOperationsReexportTypes.IMPORTED_OPERATIONS_REEXPORT_REQUEST, handleImportedOperationsReexport);
}

function* importedOperationsReexportSaga() {
    yield all([fork(watchImportedOperationsReexport)]);
}

export default importedOperationsReexportSaga;
