import { BaseStoreState } from 'reducer/base-store-state';
import { ScdSearchPaymentImportBatch } from 'model/batch';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchPaymentImportBatchesTypes {
    SEARCH_PAYMENT_IMPORT_BATCHES_REQUEST = '@@payment-import-batches-search/SEARCH_PAYMENT_IMPORT_BATCHES_REQUEST',
    SEARCH_PAYMENT_IMPORT_BATCHES_SUCCESS = '@@payment-import-batches-search/SEARCH_PAYMENT_IMPORT_BATCHES_SUCCESS',
    SEARCH_PAYMENT_IMPORT_BATCHES_ERROR = '@@payment-import-batches-search/SEARCH_PAYMENT_IMPORT_BATCHES_ERROR',
    SEARCH_PAYMENT_IMPORT_BATCHES_RESET = '@@payment-import-batches-search/SEARCH_PAYMENT_IMPORT_BATCHES_RESET',
}

export interface SearchPaymentImportBatchesState extends BaseStoreState {
    readonly paymentImportBatches?: Page<ScdSearchPaymentImportBatch>;
}
