import { ScdCompanyToSimple } from './../../../model/company';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import companyAPI from 'services/api/company-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { UpdateCompanyTypes } from './types';
import { updateCompanyRequest, updateCompanyError, updateCompanySuccess } from './actions';

function* handleUpdateCompany(action: ReturnType<typeof updateCompanyRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyToSimple> = yield call(companyAPI.updateCompany, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateCompanyError(mapped));
            return;
        }
        yield put(updateCompanySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateCompanyError(mapped));
    }
}

function* watchUpdateCompany() {
    yield takeLeading(UpdateCompanyTypes.UPDATE_COMPANY_REQUEST, handleUpdateCompany);
}

function* updateCompanySaga() {
    yield all([fork(watchUpdateCompany)]);
}

export default updateCompanySaga;
