import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SummarizePaymentTransactionsTypes, SummarizePaymentTransactionsState } from 'reducer/payment-transaction/summarize-payment-transactions/types';

const initialState: SummarizePaymentTransactionsState = {
    status: HttpRequestStatus.NOOP,
    pending: undefined,
    error: undefined,
};

const reducer: Reducer<SummarizePaymentTransactionsState> = (state = initialState, action): SummarizePaymentTransactionsState => {
    switch (action.type) {
        case SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, pending: action.payload, error: undefined };
        }
        case SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as summarizePaymentTransactionsReducer };
