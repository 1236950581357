import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useResumePaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import {
    resumePaymentInstitutionAccountRequest,
    resumePaymentInstitutionAccountResetState,
} from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';
import StatementFallback from 'shared/external/payment-accounts-strategy/statement/components/fallback/statement-fallback';
import { ExternalStatementProps } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';
import StatementBridge from 'shared/external/payment-accounts-strategy/statement/statement-bridge/statement-bridge';

type ExternalStatementRouteProps = RouteComponentProps<{ paymentInstitutionAccountId: string }>;

const ExternalStatement = (props: ExternalStatementRouteProps) => {
    const { match } = props;

    const [paymentInstitutionAccountId] = useState<number>(Number(match.params.paymentInstitutionAccountId));

    const { resumedPaymentInstitutionAccount, status } = useResumePaymentInstitutionAccountState();

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    const dispatch = useRootDispatch();

    const handleResumeRequest = useCallback(() => {
        dispatch(resumePaymentInstitutionAccountRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        if (!paymentInstitutionAccountId) return;

        handleResumeRequest();
    }, [handleResumeRequest, paymentInstitutionAccountId]);

    useEffect(() => {
        return () => {
            dispatch(resumePaymentInstitutionAccountResetState());
        };
    }, [dispatch]);

    if (isLoading) {
        return <Loading />;
    }

    if (!resumedPaymentInstitutionAccount) {
        return <StatementFallback paymentInstitutionAccountId={paymentInstitutionAccountId} />;
    }

    const bridgeProps: ExternalStatementProps = {
        paymentInstitutionAccountId: resumedPaymentInstitutionAccount?.id,
        technicalSupplierReference: resumedPaymentInstitutionAccount?.technicalSupplierReference,
    };

    return <StatementBridge {...bridgeProps} />;
};

export default ExternalStatement;
