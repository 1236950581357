import { AxiosResponse } from 'axios';
import { BatchOutputEndorsementToResume } from 'model/batch-output-endorsement';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchOutputEndorsementError, batchOutputEndorsementRequest, batchOutputEndorsementSuccess } from 'reducer/batch/batch-output-endorsement/action';
import { BatchOutputEndorsementTypes } from 'reducer/batch/batch-output-endorsement/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import outputApi from 'services/api/batch-output-api'
import ErrorUtils from 'shared/error/error-utils';


function* handleBatchOutputEndorsement(action: ReturnType<typeof batchOutputEndorsementRequest>) {
    try {
        const result: AxiosResponse<BatchOutputEndorsementToResume> = yield call(outputApi.outputEndorsement, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOutputEndorsementError(mapped));
            return;
        }
        yield put(batchOutputEndorsementSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOutputEndorsementError(mapped));
    }
}

function* watchBatchOutputEndorsement() {
    yield takeEvery(BatchOutputEndorsementTypes.BATCH_OUTPUT_ENDORSEMENT_REQUEST, handleBatchOutputEndorsement);
}

function* batchOutputEndorsementSaga() {
    yield all([fork(watchBatchOutputEndorsement)]);
}

export default batchOutputEndorsementSaga;