import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import userApi from 'services/api/user-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { userCreateError, userCreateRequest, userCreateSuccess } from 'reducer/user/create-user/actions';
import { UserSimple } from 'model/user';
import { UserCreateTypes } from 'reducer/user/create-user/types';

function* handleUserCreate(action: ReturnType<typeof userCreateRequest>) {
    try {
        const result: AxiosResponse<UserSimple> = yield call(userApi.create, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(userCreateError(mapped));
            return;
        }
        yield put(userCreateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userCreateError(mapped));
    }
}

function* watchUserCreate() {
    yield takeLeading(UserCreateTypes.USER_CREATE_REQUEST, handleUserCreate);
}

function* userCreateSaga() {
    yield all([fork(watchUserCreate)]);
}

export default userCreateSaga;
