import Loading from 'components/loading/loading';
import ProgramsOutputPoliciesRow, {
    ProgramsOutputPoliciesRowProps,
} from 'entities/program/program-output-policies/components/program-output-policies-row/programs-output-policies-row';
import { ProgramOutputPoliciesToGetAll } from 'model/program-output-policy';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface ProgramOutputProliciesProps {
    programOutputPolicies: ProgramOutputPoliciesToGetAll[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.programs-output-policies';

const ProgramOutputPoliciesList = (props: ProgramOutputProliciesProps) => {
    const { programOutputPolicies, isLoading } = props;

    const { t } = useTranslation();

    const programOutputPoliciesData: ProgramsOutputPoliciesRowProps = {
        programOutputPolicies,
    };

    return (
        <>
            {isLoading && !programOutputPolicies.length && <Loading isTable />}
            {programOutputPolicies.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT}>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={programOutputPoliciesData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={programOutputPolicies.length}
                            className="page-container--table-container"
                        >
                            {ProgramsOutputPoliciesRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-program-output-policies__table-not-found">
                            <span className="scd-program-output-policies__table-not-found-image" />
                            <span className="scd-program-output-policies__table-not-found-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ProgramOutputPoliciesList;
