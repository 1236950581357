import { Grid } from '@material-ui/core';
import { GiroWebappError } from 'model/error';
import { useTranslation } from 'react-i18next';

interface InternalErrorResponseProps {
    error: GiroWebappError | undefined;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.balance-details';

const InternalErrorResponse = (props: InternalErrorResponseProps): JSX.Element => {
    const { error } = props;

    const { t } = useTranslation();

    if (!error) {
        return <></>;
    }

    return (
        <>
            {error.message && (
                <>
                    <Grid item xs={12}>
                        <label>{t(`${I18N_PREFIX}.internalError`)}</label>
                        <span>{t(`${I18N_PREFIX}.internalErrorMessage`)}</span>
                    </Grid>
                    <Grid item xs={12}>
                        <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                        <span>{t(error?.message ?? 'error.unknown')}</span>
                    </Grid>
                </>
            )}
        </>
    );
};

export default InternalErrorResponse;
