import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountToWithdraw, SimplePaymentInstitutionAccountToWithdraw } from 'model/payment-institution-account';
import { WithdrawExternalBalanceTypes } from 'reducer/payment-institutions-accounts/withdraw-external-balance/types';
import { action } from 'typesafe-actions';

export const withdrawExternalBalanceRequest = (request: PaymentInstitutionAccountToWithdraw) => action(WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_REQUEST, request)

export const withdrawExternalBalanceSuccess = (paymentInstitutionAccount: SimplePaymentInstitutionAccountToWithdraw) => action(WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_SUCCESS, paymentInstitutionAccount);

export const withdrawExternalBalanceError = (error: GiroWebappError) => action(WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_ERROR, error)

export const withdrawExternalBalanceResetState = () => action(WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_RESET);
