import { ScdMonthlyCommissionData } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListMonthlyCommissionsTypes {
    LIST_MONTHLY_COMMISSIONS_REQUEST = '@@monthly-commissions-list/LIST_MONTHLY_COMMISSIONS_REQUEST',
    LIST_MONTHLY_COMMISSIONS_SUCCESS = '@@monthly-commissions-list/LIST_MONTHLY_COMMISSIONS_SUCCESS',
    LIST_MONTHLY_COMMISSIONS_ERROR = '@@monthly-commissions-list/LIST_MONTHLY_COMMISSIONS_ERROR',
    LIST_MONTHLY_COMMISSIONS_RESET = '@@monthly-commissions-list/LIST_MONTHLY_COMMISSIONS_RESET',
}

export interface ListMonthlyCommissionsState extends BaseStoreState {
    readonly monthlyCommissions?: Page<ScdMonthlyCommissionData>;
}
