import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import { ScdMonthlyCommissionDetail } from 'model/monthly-commission';
import { useTranslation } from 'react-i18next';

interface MonthlyCommissionDetailInfoProps {
    monthlyCommission: ScdMonthlyCommissionDetail | undefined;
}

const fallbackValue = '-'.repeat(7);

const I18N_PREFIX = 'pages.scd.monthly-commission-detail';

export const MonthlyCommissionDetailInfo = (props: MonthlyCommissionDetailInfoProps) => {
    const { monthlyCommission } = props;

    const { t } = useTranslation();

    return (
        <div>
            <div className="scd-monthly-commission-detail__commission-info">
                <div className="scd-monthly-commission-detail__commission-info--item">
                    <span className="scd-monthly-commission-detail__commission-info--item-label">
                        {t(`${I18N_PREFIX}.card.header.yearMonth`)}
                    </span>
                    <span className="scd-monthly-commission-detail__commission-info--item-value">
                        {monthlyCommission?.yearMonth ? (
                            <DateFormatter date={monthlyCommission.yearMonth} format={DateFormat.YEAR_MONTH} />
                        ) : (
                            fallbackValue
                        )}
                    </span>
                </div>
                <div className="scd-monthly-commission-detail__commission-info--item">
                    <span className="scd-monthly-commission-detail__commission-info--item-label">
                        {t(`${I18N_PREFIX}.card.header.releaseDate`)}
                    </span>
                    <span className="scd-monthly-commission-detail__commission-info--item-value">
                        {monthlyCommission?.releaseDate ? <DateFormatter date={monthlyCommission.releaseDate} /> : fallbackValue}
                    </span>
                </div>
                <div className="scd-monthly-commission-detail__commission-info--item">
                    <span className="scd-monthly-commission-detail__commission-info--item-label">
                        {t(`${I18N_PREFIX}.card.header.paymentDate`)}
                    </span>
                    <span className="scd-monthly-commission-detail__commission-info--item-value">
                        {monthlyCommission?.paymentDate ? <DateFormatter date={monthlyCommission.paymentDate} /> : fallbackValue}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MonthlyCommissionDetailInfo;
