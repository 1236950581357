import FormatterUtils from 'components/formatter/formatter-utils';
import { DateRange, ScdImportedOperationFareAmountReport } from 'model/imported-operation';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

interface FareAmountReportCSVProps {
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
    fareAmountReportData: ScdImportedOperationFareAmountReport[];
    batchPeriod: DateRange;
}

type ReportHeader = Omit<Record<keyof ScdImportedOperationFareAmountReport, string>, 'operationId'>;

const I18N_PREFIX = 'pages.scd.fare-amount-report.headers';

const FareAmountReportCSV = (props: FareAmountReportCSVProps) => {
    const { csvLinkRef, fareAmountReportData, batchPeriod } = props;
    const { t } = useTranslation();

    const reportFileName: string = useMemo(() => {
        return `RelatorioDeTarifas_${FormatterUtils.formatDateToLocalDate(batchPeriod.start)}-${FormatterUtils.formatDateToLocalDate(
            batchPeriod.end
        )}.csv`;
    }, [batchPeriod]);

    const REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            batchDate: t(`${I18N_PREFIX}.batchDate`),
            batchCode: t(`${I18N_PREFIX}.batchCode`),
            debtorIdentification: t(`${I18N_PREFIX}.debtorIdentification`),
            debtorName: t(`${I18N_PREFIX}.debtorName`),
            ccbNumber: t(`${I18N_PREFIX}.ccbNumber`),
            fareAmount: t(`${I18N_PREFIX}.fareAmount`),
        };
    }, [t]);

    return (
        <CSVLink
            className="hidden"
            ref={csvLinkRef}
            data={fareAmountReportData}
            filename={reportFileName}
            headers={Object.keys(REPORT_HEADERS).map(key => ({ key, label: REPORT_HEADERS[key] }))}
        />
    );
};

export default FareAmountReportCSV;
