import React from 'react';
import './read-only-card.scss';

export interface ReadOnlyCardProps {
    children: React.ReactNode;
    name: string;
}

export const ReadOnlyCard = (props: ReadOnlyCardProps) => {
    return (
        <article className="card__container list">
            <header className="card__container--header">
                <span className="card__container--header-name">{props.name}</span>
            </header>
            <div>{props.children}</div>
        </article>
    );
};
