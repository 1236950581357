import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search payment favored
import { searchPaymentFavoredReducer } from 'reducer/payment-favored/search-payment-favored/reducer';
import searchPaymentFavoredSagas from 'reducer/payment-favored/search-payment-favored/sagas';
import { SearchPaymentFavoredState } from 'reducer/payment-favored/search-payment-favored/types';

export interface PaymentFavoredState {
    readonly searchPaymentFavored: SearchPaymentFavoredState;
}

export const PaymentFavoredReducer: ReducersMapObject<PaymentFavoredState, AnyAction> = {
    searchPaymentFavored: searchPaymentFavoredReducer,
};

export function* paymentFavoredSagas() {
    yield all([fork(searchPaymentFavoredSagas)]);
}
