import { ScdBatchDisbursementReport } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchDisbursementReportTypes {
    BATCH_DISBURSEMENT_REPORT_REQUEST = '@@batch-disbursement-report/BATCH_DISBURSEMENT_REPORT_REQUEST',
    BATCH_DISBURSEMENT_REPORT_SUCCESS = '@@batch-disbursement-report/BATCH_DISBURSEMENT_REPORT_SUCCESS',
    BATCH_DISBURSEMENT_REPORT_ERROR = '@@batch-disbursement-report/BATCH_DISBURSEMENT_REPORT_ERROR',
    BATCH_DISBURSEMENT_REPORT_RESET = '@@batch-disbursement-report/BATCH_DISBURSEMENT_REPORT_RESET',
}

export interface BatchDisbursementReportState extends BaseStoreState {
    readonly batchDisbursementReport?: ScdBatchDisbursementReport;
}
