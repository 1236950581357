import { Grid } from '@material-ui/core';
import BatchCard, { BatchCardType } from 'entities/batch/components/batch-card/batch-card';

interface BatchInternalErrorCardProps {
    title: string;
    message: string;
}

const BatchInternalErrorCard = ({ title, message }: BatchInternalErrorCardProps) => {
    return (
        <Grid item>
            <BatchCard type={BatchCardType.INTERNAL_ERROR} title={title} message={message} />
        </Grid>
    );
};

export default BatchInternalErrorCard;
