import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import companyGroupPolicyAPI from 'services/api/company-group-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListCompanyGroupPoliciesTypes } from 'reducer/company-group-policy/list-company-group-policies/types';
import {
    listCompanyGroupPoliciesError,
    listCompanyGroupPoliciesSuccess,
    listCompanyGroupPoliciesRequest,
} from 'reducer/company-group-policy/list-company-group-policies/actions';
import { Page } from 'services/page';

function* handleListCompanyGroupPolicies(action: ReturnType<typeof listCompanyGroupPoliciesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompanyGroupPolicy>> = yield call(
            companyGroupPolicyAPI.listCompanyGroupPolicies,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listCompanyGroupPoliciesError(mapped));
            return;
        }
        yield put(listCompanyGroupPoliciesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listCompanyGroupPoliciesError(mapped));
    }
}

function* watchListCompanyGroupPolicies() {
    yield takeEvery(ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_REQUEST, handleListCompanyGroupPolicies);
}

function* listCompanyGroupPoliciesSaga() {
    yield all([fork(watchListCompanyGroupPolicies)]);
}

export default listCompanyGroupPoliciesSaga;
