import { Collapse } from '@material-ui/core';
import clsx from 'clsx';
import FormatterUtils from 'components/formatter/formatter-utils';
import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import { BatchExportForSignatureOutput } from 'model/batch';
import { BatchExportResult } from 'model/enums/batch-export-result';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface ScdBatchEndorsementCollapseProps {
    exports: BatchExportForSignatureOutput[];
    filterCollapse: number[];
    signatureId: number;
    setExportId: (value: number) => void;
    setOpenModalDetail: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementCollapse = (props: ScdBatchEndorsementCollapseProps) => {
    const { t } = useTranslation();
    const { exports, filterCollapse, signatureId, setExportId, setOpenModalDetail } = props;

    const [anchorElSubList, setAnchorElSubList] = useState<null | HTMLElement>(null);

    const { formatDateTimeCompleteUTC } = FormatterUtils;

    return (
        <>
            <Collapse in={filterCollapse.includes(signatureId)} timeout={0} unmountOnExit>
                {exports?.length > 0 ? (
                    <div className="scd-batch-endorsement__sublist">
                        {exports.map(item => (
                            <tr className="scd-batch-endorsement__sublist-content" key={item.id}>
                                <td>{formatDateTimeCompleteUTC(item.exportDate).slice(0, 18)}</td>
                                <td>
                                    <span
                                        className={clsx('scd-batch-endorsement__sublist-status', {
                                            FAILURE: item.result === BatchExportResult.FAILURE,
                                            SUCCESS: item.result === BatchExportResult.SUCCESS,
                                        })}
                                    >
                                        {t(`${I18N_PREFIX}.collapse-list.result.options.${item.result}`)}
                                    </span>
                                </td>
                                <td>
                                    <MoreOptionsMenu
                                        anchorEl={anchorElSubList}
                                        setAnchorEl={setAnchorElSubList}
                                        onClick={() => setExportId(Number(item.id))}
                                    >
                                        <ModalActionMenuItem
                                            titleKey="Detalhes"
                                            onClick={() => {
                                                setOpenModalDetail(true);
                                                setAnchorElSubList(null);
                                            }}
                                        />
                                    </MoreOptionsMenu>
                                </td>
                            </tr>
                        ))}
                    </div>
                ) : (
                    <div className="scd-batch-endorsement__not-sents">
                        <span>{t(`${I18N_PREFIX}.collapse-list.not-found`)}</span>
                    </div>
                )}
            </Collapse>
        </>
    );
};

export default ScdBatchEndorsementCollapse;
