import { ScdBatchCommissionReport } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchCommissionReportTypes } from 'reducer/batch/commission-report/types';
import { action } from 'typesafe-actions';

export const batchCommissionReportRequest = (batchId: number) =>
    action(BatchCommissionReportTypes.BATCH_COMMISSION_REPORT_REQUEST, batchId);
export const batchCommissionReportSuccess = (batchCommissionReport: ScdBatchCommissionReport) =>
    action(BatchCommissionReportTypes.BATCH_COMMISSION_REPORT_SUCCESS, batchCommissionReport);
export const batchCommissionReportError = (error: GiroWebappError) =>
    action(BatchCommissionReportTypes.BATCH_COMMISSION_REPORT_ERROR, error);
export const batchCommissionReportResetState = () => action(BatchCommissionReportTypes.BATCH_COMMISSION_REPORT_RESET);
