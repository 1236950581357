import { ScdOrganization, ScdOrganizationSimple } from 'model/organization';
import { GiroWebappError } from 'model/error';
import { UpdateOrganizationTypes } from './types';
import { action } from 'typesafe-actions';

export const updateOrganizationRequest = (request: ScdOrganization) => action(UpdateOrganizationTypes.UPDATE_ORGANIZATION_REQUEST, request);
export const updateOrganizationSuccess = (updatedOrganization: ScdOrganizationSimple) =>
    action(UpdateOrganizationTypes.UPDATE_ORGANIZATION_SUCCESS, updatedOrganization);
export const updateOrganizationError = (error: GiroWebappError) => action(UpdateOrganizationTypes.UPDATE_ORGANIZATION_ERROR, error);
export const updateOrganizationResetState = () => action(UpdateOrganizationTypes.UPDATE_ORGANIZATION_RESET_STATE);
