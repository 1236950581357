import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ArchiveBatchOperationTypes, ArchiveBatchOperationState } from 'reducer/batch/archive-batch-operation/types';

const initialState: ArchiveBatchOperationState = {
    status: HttpRequestStatus.NOOP,
    archivedOperation: undefined,
    error: undefined,
};

const reducer: Reducer<ArchiveBatchOperationState> = (state = initialState, action): ArchiveBatchOperationState => {
    switch (action.type) {
        case ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, archivedOperation: action.payload, error: undefined };
        }
        case ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as archiveBatchOperationReducer };
