import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { listPaymentInstitutionsAccountsRequest, listPaymentInstitutionsAccountsError, listPaymentInstitutionsAccountsSuccess } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/actions';
import { ListPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListPaymentInstitutionsAccounts(action: ReturnType<typeof listPaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<Page<PaymentInstitutionAccountToGetAll>> = yield call(
            paymentInstitutionsAccountsApi.listPaymentInstitutionsAccounts,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listPaymentInstitutionsAccountsError(mapped));
            return;
        }
        yield put(listPaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listPaymentInstitutionsAccountsError(mapped));
    }
}

function* watchListPaymentInstitutionsAccounts() {
    yield takeEvery(ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleListPaymentInstitutionsAccounts);
}

function* listPaymentInstitutionsAccountsSaga() {
    yield all([fork(watchListPaymentInstitutionsAccounts)]);
}

export default listPaymentInstitutionsAccountsSaga;
