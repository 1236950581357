import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyItemSearch } from 'model/company-item';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchCompanyItemsTypes {
    SEARCH_COMPANY_ITEMS_REQUEST = '@@company-items-search/SEARCH_COMPANY_ITEMS_REQUEST',
    SEARCH_COMPANY_ITEMS_SUCCESS = '@@company-items-search/SEARCH_COMPANY_ITEMS_SUCCESS',
    SEARCH_COMPANY_ITEMS_ERROR = '@@company-items-search/SEARCH_COMPANY_ITEMS_ERROR',
    SEARCH_COMPANY_ITEMS_RESET = '@@company-items-search/SEARCH_COMPANY_ITEMS_RESET',
}

export interface SearchCompanyItemsState extends BaseStoreState {
    readonly companyItems?: Page<ScdCompanyItemSearch>;
}
