import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import PercentageFormatterWithSixDecimals from 'components/formatter/percentage/percentage-formatter-six-decimals';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import BatchMoreOptionsMenuItems from 'entities/batch/components/batch-more-options-menu-items/batch-more-options-menu-items';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { ScdBatchData } from 'model/batch';
import { BatchStatus } from 'model/enums/batch-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';
import { reprocessBatchRequest } from 'reducer/batch/reprocess-batch/actions';
import { useRootDispatch } from 'reducer/hooks';

export interface BatchesRowProps {
    batches: ScdBatchData[];
    isDisabledMenu: boolean;
    setModalAction: (value: BatchModalActionOptions | undefined) => void;
    selectedBatch: ScdBatchData;
    setSelectedBatch: (value: ScdBatchData) => void;
    setIsOpenReportModal: (value: boolean) => void;
    setModalCloseDetails: (value: boolean) => void;
    handleOpenGenerateCnabModal: () => void;
}

const I18N_PREFIX = 'pages.scd.batch';

const BatchesRow = ({ data, index, style }: ListChildComponentProps<BatchesRowProps>) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const {
        batches,
        isDisabledMenu,
        setModalAction,
        selectedBatch,
        setSelectedBatch,
        setIsOpenReportModal,
        setModalCloseDetails,
        handleOpenGenerateCnabModal,
    } = data;

    const { id, date, product, code, nominalValue, originatorName, yearlySessionInterestRatePercentage, numberOfOperations, status } =
        batches[index];

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleReprocessBatch = (batchId: number | undefined) => {
        if (!batchId) return;
        dispatch(reprocessBatchRequest(batchId));
    };

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{date ? <DateFormatter date={date} /> : '00/00/00'}</td>
            <td className="page-container--table-cell-title">
                <span> {t(`${I18N_PREFIX}.product.options.${product}`).toUpperCase()}</span>
            </td>
            <td className="page-container--table-cell-title">{code}</td>
            <td className="page-container--table-cell-title">{nominalValue ? <CurrencyFormatter value={nominalValue} prefix /> : '-'}</td>
            <td className="page-container--table-cell-title" title={originatorName ?? ''}>
                <span>{originatorName ?? t(`${I18N_PREFIX}.no-originator`)}</span>
            </td>
            <td className="page-container--table-cell-title"> {numberOfOperations ?? '-'} </td>
            <td className="page-container--table-cell-title">
                {yearlySessionInterestRatePercentage ? (
                    <PercentageFormatterWithSixDecimals value={yearlySessionInterestRatePercentage} />
                ) : (
                    '-'
                )}
            </td>
            <td className="page-container--table-cell-title">
                <span className="page-container--table-status">
                    <span
                        className={clsx('page-container--table-status', {
                            CLOSED: status === BatchStatus.CLOSED,
                            CONCLUDED: status === BatchStatus.CONCLUDED,
                            OPEN: status === BatchStatus.OPEN,
                            ERROR: status === BatchStatus.ERROR,
                            PROCESSING: status === BatchStatus.PROCESSING,
                        })}
                    >
                        {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                    </span>
                </span>
            </td>
            <td className="page-container--table-cell-icon">
                <MoreOptionsMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    disabled={isDisabledMenu}
                    onClick={() => setSelectedBatch(batches[index])}
                >
                    <BatchMoreOptionsMenuItems
                        selectedBatch={selectedBatch}
                        handleCloseMenu={handleCloseMenu}
                        setModalAction={setModalAction}
                        handleOpenGenerateCnabModal={handleOpenGenerateCnabModal}
                        handleReprocessBatch={handleReprocessBatch}
                        setIsOpenReportModal={setIsOpenReportModal}
                        setModalCloseDetails={setModalCloseDetails}
                    />
                </MoreOptionsMenu>
            </td>
        </tr>
    );
};

export default BatchesRow;
