import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UserDetailState, UserDetailTypes } from 'reducer/user/user-detail/types';

const initialState: UserDetailState = {
    status: HttpRequestStatus.NOOP,
    userDetail: undefined,
    error: undefined
}

const reducer: Reducer<UserDetailState> = (state = initialState, action): UserDetailState => {
    switch (action.type) {
        case UserDetailTypes.USER_DETAIL_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UserDetailTypes.USER_DETAIL_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, userDetail: action.payload, error: undefined };
        }
        case UserDetailTypes.USER_DETAIL_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UserDetailTypes.USER_DETAIL_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as userDetailReducer };