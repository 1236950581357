import { CloseButton } from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import PercentageFormatterWithTwelveDecimals from 'components/formatter/percentage/percentage-formatter-twelve-decimals';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ScdBatchToGenerateCessionResponse } from 'model/batch';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './modal-response.scss';

export interface ModalResponseProps {
    cession: ScdBatchToGenerateCessionResponse;
    onClose: () => void;
    onAction?: () => void;
}

const I18N_PREFIX = 'pages.scd.batch.generate-cession.modal-response';

export const ModalResponse = (props: ModalResponseProps) => {
    const { onClose, onAction, cession } = props;

    const { t } = useTranslation();

    return (
        <Modal isOpen>
            <div className="modal-response">
                <article className="modal-response--content">
                    <header className="modal-response--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <p className="modal-response--title">{t(`${I18N_PREFIX}.title`)}</p>
                    <div className="modal-response--message">
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.ceded-amount`)}
                            value={<CurrencyFormatter value={cession?.cededAmount} prefix /> ?? '-'}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.cession-date`)}
                            value={<DateFormatter date={cession?.cessionDate} format={DateFormat.DATE} /> ?? '-'}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.present-amount`)}
                            value={<CurrencyFormatter value={cession?.cessionPresentAmount} prefix /> ?? '-'}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.cession-tax-percentage`)}
                            value={<PercentageFormatterWithTwelveDecimals value={cession?.cessionTaxPercentage} /> ?? '-'}
                        />
                        <ReadOnlyTextField
                            label={t(`${I18N_PREFIX}.label.number-of-installments`)}
                            value={cession?.numberOfInstallments ?? '-'}
                        />
                        <ReadOnlyTextField label={t(`${I18N_PREFIX}.label.shipping-number`)} value={cession?.shippingNumber ?? '-'} />
                    </div>
                    {onAction && (
                        <div className="modal-response--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.confirm'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ModalResponse;
