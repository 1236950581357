import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchCompanyTypes, SearchCompaniesState } from 'reducer/company/search-companies/types';

const initialState: SearchCompaniesState = {
    status: HttpRequestStatus.NOOP,
    companies: undefined,
    error: undefined,
};

const reducer: Reducer<SearchCompaniesState> = (state = initialState, action): SearchCompaniesState => {
    switch (action.type) {
        case SearchCompanyTypes.SEARCH_COMPANIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchCompanyTypes.SEARCH_COMPANIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companies: action.payload, error: undefined };
        }
        case SearchCompanyTypes.SEARCH_COMPANIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchCompanyTypes.SEARCH_COMPANIES_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchCompaniesReducer };
