import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';
import {CessionOperationBco} from "model/bco/batch-output-cession-integration-webcred";

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-webcred.table.header';

interface CessionIntegrationWebcredHeaderListProps {
    cessionOperations: CessionOperationBco[];
    handleSort: (property: keyof CessionOperationBco, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const CessionIntegrationWebcredHeaderList = (props: CessionIntegrationWebcredHeaderListProps) => {
    const { t } = useTranslation();
    const { cessionOperations, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: keyof CessionOperationBco) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: cessionOperations && cessionOperations.length <= 1,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.id`).toUpperCase()} sortable={mapSortable('id')} />
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
        </tr>
    );
};

export default CessionIntegrationWebcredHeaderList;
