import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './cession-integration-list-header.scss';

interface HeaderList {
    title: string;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.header';

const HEADER_LIST: HeaderList[] = [
    { title: `${I18N_PREFIX}.part` },
    { title: `${I18N_PREFIX}.operations` },
    { title: `${I18N_PREFIX}.installments` },
    { title: `${I18N_PREFIX}.date` },
    { title: `${I18N_PREFIX}.status` },
];
const EMPTY_HEADER_FOR_ICON: ReactNode = <th className="cession-integration-list-header__table-header" />;

interface CessionIntegrationListHeaderProps {}

const CessionIntegrationListHeader: React.FC<CessionIntegrationListHeaderProps> = () => {
    const { t } = useTranslation();

    return (
        <thead className="cession-integration-list-header">
            <tr>
                {EMPTY_HEADER_FOR_ICON}
                {HEADER_LIST.map((header, index) => (
                    <th className="cession-integration-list-header__table-header" key={`${header.title}-${index}`}>
                        {t(header.title)}
                    </th>
                ))}
                {EMPTY_HEADER_FOR_ICON}
            </tr>
        </thead>
    );
};

export default CessionIntegrationListHeader;
