import BackButton from 'components/buttons/back-button/back-button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import './statement-error.scss';

interface StatementErrorProps {
    handleRetry: () => void;
    errorComponent?: ReactNode;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement';

const StatementError = (props: StatementErrorProps) => {
    const { handleRetry, errorComponent: ErrorComponent } = props;

    const { t } = useTranslation();

    return (
        <div className="statement-error">
            {ErrorComponent}
            <div className="statement-error--container">
                <div className="statement-error--message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleRetry} />
            </div>
        </div>
    );
};

export default StatementError;
