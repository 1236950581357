import { TechnicalSupplierStatus } from './../../../model/enums/technical-supplier-status';
import { OriginatorPaymentInstitutionStatus } from 'model/enums/originator-payment-institution-status';
import { OriginatorPaymentInstitution } from 'model/originator-payment-institution';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.originator.input.originatorPaymentInstitution.validate';

const ORIGINATOR_PAYMENT_INSTITUTION_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateOriginatorPaymentInstitutionName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORIGINATOR_PAYMENT_INSTITUTION_NAME_VALIDATION);

interface OriginatorPaymentInstitutionStatusToValidate {
    uniqueKey?: string;
    originatorPaymentInstitutions: ReadonlyArray<OriginatorPaymentInstitution> | undefined;
    status: OriginatorPaymentInstitutionStatus | undefined;
    technicalSupplierStatus?: TechnicalSupplierStatus;
}

const ORIGINATOR_PAYMENT_INSTITUTION_STATUS_VALIDATION: Validations<OriginatorPaymentInstitutionStatusToValidate | undefined | null> = {
    required: {
        predicate: it => it !== undefined && it !== null && it.status !== undefined && it.status !== null,
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
    technicalSupplierActive: {
        predicate: it =>
            !!it && it?.status === OriginatorPaymentInstitutionStatus.ACTIVE && !!it.technicalSupplierStatus
                ? it.technicalSupplierStatus === TechnicalSupplierStatus.ACTIVE
                : true,
        errorMessage: `${I18N_PREFIX}.status.technicalSupplierActive`,
    },
    onlyOneActive: {
        predicate: it =>
            !!it && it?.status === OriginatorPaymentInstitutionStatus.ACTIVE
                ? !!it.originatorPaymentInstitutions &&
                !it?.originatorPaymentInstitutions.some(
                    originatorPaymentInstitution =>
                        originatorPaymentInstitution.status === OriginatorPaymentInstitutionStatus.ACTIVE &&
                        it.uniqueKey !== originatorPaymentInstitution.uniqueKey
                )
                : true,
        errorMessage: `${I18N_PREFIX}.status.onlyOneActive`,
    },
};
export const validateOriginatorPaymentInstitutionStatus = (value: OriginatorPaymentInstitutionStatusToValidate | undefined | null) =>
    ValidationUtils.validate(value, ORIGINATOR_PAYMENT_INSTITUTION_STATUS_VALIDATION);

interface OriginatorPaymentInstitutionTechnicalSupplierToValidate {
    uniqueKey?: string;
    originatorPaymentInstitutions: ReadonlyArray<OriginatorPaymentInstitution> | undefined;
    technicalSupplierId: number | undefined;
    isEditing?: boolean;
}

const ORIGINATOR_PAYMENT_INSTITUTION_TECHNICAL_SUPPLIER_VALIDATION: Validations<
    OriginatorPaymentInstitutionTechnicalSupplierToValidate | undefined | null
> = {
    required: {
        predicate: it => it !== undefined && it !== null && it.technicalSupplierId !== undefined && it.technicalSupplierId !== null,
        errorMessage: `${I18N_PREFIX}.technicalSupplier.required`,
    },
    onlyOne: {
        predicate: it =>
            !!it &&
            !!it.originatorPaymentInstitutions &&
            !it?.originatorPaymentInstitutions.some(
                originatorPaymentInstitution =>
                    originatorPaymentInstitution.technicalSupplierId === it.technicalSupplierId &&
                    it.uniqueKey !== originatorPaymentInstitution.uniqueKey
            ),
        errorMessage: `${I18N_PREFIX}.technicalSupplier.onlyOne`,
    },
};
export const validateOriginatorPaymentInstitutionTechnicalSupplier = (
    value: OriginatorPaymentInstitutionTechnicalSupplierToValidate | undefined | null
) => ValidationUtils.validate(value, ORIGINATOR_PAYMENT_INSTITUTION_TECHNICAL_SUPPLIER_VALIDATION);

interface OriginatorPaymentInstitutionAccountToValidate {
    uniqueKey?: string;
    originatorPaymentInstitutions: ReadonlyArray<OriginatorPaymentInstitution> | undefined;
    accountId: number | undefined;
}

const ORIGINATOR_PAYMENT_INSTITUTION_ACCOUNT_VALIDATION: Validations<OriginatorPaymentInstitutionAccountToValidate | undefined | null> = {
    required: {
        predicate: it => it !== undefined && it !== null && it.accountId !== undefined && it.accountId !== null,
        errorMessage: `${I18N_PREFIX}.account.required`,
    },
    onlyOne: {
        predicate: it =>
            !!it &&
            !!it.originatorPaymentInstitutions &&
            !it?.originatorPaymentInstitutions.some(
                originatorPaymentInstitution =>
                    originatorPaymentInstitution.accountId === it.accountId &&
                    it.uniqueKey !== originatorPaymentInstitution.uniqueKey
            ),
        errorMessage: `${I18N_PREFIX}.account.onlyOne`,
    },
}

export const validateOriginatorPaymentInstitutionAccount = (value: OriginatorPaymentInstitutionAccountToValidate | undefined | null) => ValidationUtils.validate(value, ORIGINATOR_PAYMENT_INSTITUTION_ACCOUNT_VALIDATION)

interface OriginatorPaymentInstitutionToValidateAll {
    uniqueKey?: string;
    originatorPaymentInstitutions: ReadonlyArray<OriginatorPaymentInstitution> | undefined;
    originatorPaymentInstitution: OriginatorPaymentInstitution;
}

export const validateScdOriginatorPaymentInstitution = (value: OriginatorPaymentInstitutionToValidateAll | undefined): boolean => {
    if (!value) return true;

    const { originatorPaymentInstitution, originatorPaymentInstitutions } = value;

    return (
        validateOriginatorPaymentInstitutionName(originatorPaymentInstitution.name).isValid &&
        validateOriginatorPaymentInstitutionStatus({
            uniqueKey: originatorPaymentInstitution.uniqueKey,
            status: originatorPaymentInstitution.status,
            originatorPaymentInstitutions,
            technicalSupplierStatus: originatorPaymentInstitution.technicalSupplierStatus,
        }).isValid &&
        validateOriginatorPaymentInstitutionTechnicalSupplier({
            uniqueKey: originatorPaymentInstitution.uniqueKey,
            technicalSupplierId: originatorPaymentInstitution.technicalSupplierId,
            originatorPaymentInstitutions,
        }).isValid &&
        validateOriginatorPaymentInstitutionAccount({
            uniqueKey: originatorPaymentInstitution.uniqueKey,
            accountId: originatorPaymentInstitution.accountId,
            originatorPaymentInstitutions
        }).isValid
    );
};
