import { AxiosResponse } from 'axios';
import { BatchDocumentDownloadRequest } from 'model/batch-document';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN } from 'services/page';

const batchDocumentApi = () => {
    const download = (request: BatchDocumentDownloadRequest): Promise<AxiosResponse<Uint8Array>> => {
        return api.get<Uint8Array>(`${BASE_URL_ADMIN}/batches/${request.batchId}/documents/${request.documentId}/download`, { responseType: 'arraybuffer' })
    }

    return {
        download
    }
}

export default batchDocumentApi();