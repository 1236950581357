import { ReactNode } from 'react';

interface ChipRenderItemComponentProps {
    chipTitle: string;
    component: ReactNode;
}

const ChipRenderItemComponent = (renderComponentProps: ChipRenderItemComponentProps): JSX.Element => {
    const { chipTitle, component: Component } = renderComponentProps;
    return (
        <>
            {chipTitle}
            {Component}
        </>
    );
};

export default ChipRenderItemComponent;
