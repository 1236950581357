import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import SimplePercentInput from 'components/inputs/percentage-input/simple-percent-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateCompanyItemCompany,
    validateCompanyItemStatus,
    validateCompanyItemType,
    validateScdCompanyItem,
} from 'entities/company-group/company-item/validation/company-item-validation';
import { ScdCompany } from 'model/company';
import { ScdCompanyItem, defaultScdCompanyItem } from 'model/company-item';
import { CompanyItemStatus, CompanyItemType } from 'model/enums/company-item';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { createCompanyItemRequest, createCompanyItemResetState } from 'reducer/company-item/create-company-item/actions';
import { detailCompanyItemRequest, detailCompanyItemResetState } from 'reducer/company-item/detail-company-item/actions';
import { updateCompanyItemRequest, updateCompanyItemResetState } from 'reducer/company-item/update-company-item/actions';
import { searchCompaniesRequest, searchCompaniesResetState } from 'reducer/company/search-companies/actions';
import { useCreateCompanyItemState, useDetailCompanyItemState, useRootDispatch, useUpdateCompanyItemState } from 'reducer/hooks';
import './company-item-create-update.scss';

export interface ScdCompanyItemUpdateProps extends RouteComponentProps<{ companyGroupId: string; companyItemId: string }> {}

const I18N_PREFIX = 'pages.scd.company-item';

const ScdCompanyItemCreateUpdatePage = (props: ScdCompanyItemUpdateProps) => {
    const { t } = useTranslation();
    const [companyGroupId] = useState<string | undefined>(props.match?.params?.companyGroupId ?? undefined);
    const [companyItemId] = useState<string | undefined>(props.match?.params?.companyItemId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateCompanyItemState();
    const { status: updateStatus } = useUpdateCompanyItemState();
    const { status: detailStatus, companyItem: detailCompanyItem } = useDetailCompanyItemState();

    const [scdCompanyItem, setScdCompanyItem] = useState<ScdCompanyItem>(defaultScdCompanyItem);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _scdCompanyItem: ScdCompanyItem = {
            ...scdCompanyItem,
            companyGroup: {
                id: companyGroupId,
            },
        };

        if (!validateScdCompanyItem(_scdCompanyItem)) return;

        if (companyItemId) {
            dispatch(updateCompanyItemRequest(_scdCompanyItem));
        } else {
            dispatch(createCompanyItemRequest(_scdCompanyItem));
        }
    };

    const handleChange = (value: Partial<ScdCompanyItem>) => {
        setScdCompanyItem(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (companyItemId) {
            dispatch(detailCompanyItemRequest(companyItemId));
        }
    }, [companyItemId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailCompanyItem !== undefined) {
            setScdCompanyItem(detailCompanyItem);
        }
    }, [detailStatus, detailCompanyItem]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            history.push(`/company-groups/${companyGroupId}/company-items`);
        }
    }, [updateStatus, history, companyGroupId]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push(`/company-groups/${companyGroupId}/company-items`);
        }
    }, [createStatus, history, companyGroupId]);

    useEffect(() => {
        return () => {
            dispatch(createCompanyItemResetState());
            dispatch(updateCompanyItemResetState());
            dispatch(detailCompanyItemResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-company-item-create-update__create">
            <ContextRibbon />
            <div className="scd-company-item-create-update--container">
                <header className="scd-company-item-create-update--header">
                    <h2 className="scd-company-item-create-update--header-title">
                        {!companyItemId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && companyItemId ? (
                    <Loading />
                ) : (
                    <form className="scd-company-item-create-update--form">
                        <div className="scd-company-item-create-update--form-field">
                            <ModalSearch<ScdCompany>
                                action={searchCompaniesRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.company.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.company.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.company.modal.placeholder`)}
                                onSelect={company => handleChange({ company })}
                                renderItem={company => `${company?.identification} - ${company?.name}`}
                                statusSelector={(state: IRootState) => state.searchCompanies.status}
                                dataSelector={(state: IRootState) => state.searchCompanies.companies}
                                resetState={searchCompaniesResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.company.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.company.placeholder`}
                                        validate={validateCompanyItemCompany}
                                        value={
                                            scdCompanyItem?.company?.name || scdCompanyItem?.company?.identification !== undefined
                                                ? `${scdCompanyItem?.company?.name ?? ''} - ${
                                                      scdCompanyItem?.company?.identification ?? ''
                                                  }`
                                                : ''
                                        }
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-company-item-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                value={scdCompanyItem?.type ?? ''}
                                validate={validateCompanyItemType}
                                mapperFromString={_value => (_value ? CompanyItemType[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                                onChange={(type: string) => handleChange({ type: CompanyItemType[type] })}
                                externalUpdate
                            >
                                <MenuItem value={CompanyItemType.LEVEL_ONE}>{t(`${I18N_PREFIX}.input.type.options.LEVEL_ONE`)}</MenuItem>
                                <MenuItem value={CompanyItemType.LEVEL_TWO}>{t(`${I18N_PREFIX}.input.type.options.LEVEL_TWO`)}</MenuItem>
                                <MenuItem value={CompanyItemType.LEVEL_THREE}>
                                    {t(`${I18N_PREFIX}.input.type.options.LEVEL_THREE`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-company-item-create-update--form-field">
                            <SimplePercentInput
                                label={t(`${I18N_PREFIX}.input.commisionTax.label`)}
                                value={scdCompanyItem?.commissionTax ?? 0}
                                placeholder={t(`${I18N_PREFIX}.input.commisionTax.placeholder`)}
                                onChange={commisionTax => handleChange({ commissionTax: commisionTax })}
                                externalUpdate
                            />
                        </div>

                        <div className="scd-company-item-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={scdCompanyItem?.status ?? ''}
                                validate={validateCompanyItemStatus}
                                mapperFromString={_value => (_value ? CompanyItemStatus[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                onChange={(status: string) => handleChange({ status: CompanyItemStatus[status] })}
                                externalUpdate
                            >
                                <MenuItem value={CompanyItemStatus.ACTIVE}>{t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}</MenuItem>
                                <MenuItem value={CompanyItemStatus.INACTIVE}>{t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}</MenuItem>
                            </ValidSelectInput>
                        </div>

                        <div className="scd-company-item-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={companyItemId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push(`/company-groups/${companyGroupId}/company-items`);
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdCompanyItemCreateUpdatePage;
