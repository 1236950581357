import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionDetail } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import {
    detailMonthlyCommissionError,
    detailMonthlyCommissionRequest,
    detailMonthlyCommissionSuccess,
} from 'reducer/monthly-commission/detail-monthly-commission/actions';
import { DetailMonthlyCommissionTypes } from 'reducer/monthly-commission/detail-monthly-commission/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailMonthlyCommission(action: ReturnType<typeof detailMonthlyCommissionRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionDetail> = yield call(monthlyCommissionAPI.detailMonthlyCommission, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailMonthlyCommissionError(mapped));
            return;
        }
        yield put(detailMonthlyCommissionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailMonthlyCommissionError(mapped));
    }
}

function* watchDetailMonthlyCommission() {
    yield takeEvery(DetailMonthlyCommissionTypes.DETAIL_MONTHLY_COMMISSION_REQUEST, handleDetailMonthlyCommission);
}

function* detailMonthlyCommissionSaga() {
    yield all([fork(watchDetailMonthlyCommission)]);
}

export default detailMonthlyCommissionSaga;
