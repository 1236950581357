import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { useBatchConcludeState, useBatchDetailWithProgramState, useRootDispatch } from 'reducer/hooks';
import 'entities/batch/batch-detail/batch-detail-footer/batch-conclude-modal/batch-conclude-modal.scss';
import { BatchStatus } from 'model/enums/batch-status';
import { batchConcludeRequest, batchConcludeResetState } from 'reducer/batch/conclude-batch/actions';

const I18N_PREFIX = 'pages.scd.batch.detail.footer.batch-conclude.modal.conclude';

interface SendStreamModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const BatchConcludeModal: FC<SendStreamModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useBatchConcludeState();
    const { batchWithProgram } = useBatchDetailWithProgramState();

    const batchDetail = batchWithProgram?.status;

    const handleConcludeBatch = () => {
        if (batchDetail !== BatchStatus.CLOSED) return;
        const request: number = Number(batchId);

        dispatch(batchConcludeRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(batchConcludeResetState());
        };
    }, [dispatch]);

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleConcludeBatch}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.conclude`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="send-stream-modal__text">{t(`${I18N_PREFIX}.message.conclude`)}</div>
        </ModalMessage>
    );
};

export default BatchConcludeModal;
