import { BatchDocumentSimple } from 'model/batch-output-hemera';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchDocumentExportTypes {
    BATCH_DOCUMENT_EXPORT_REQUEST = '@@batch-document-export/BATCH_DOCUMENT_EXPORT_REQUEST',
    BATCH_DOCUMENT_EXPORT_SUCCESS = '@@batch-document-export/BATCH_DOCUMENT_EXPORT_SUCCESS',
    BATCH_DOCUMENT_EXPORT_ERROR = '@@batch-document-export/BATCH_DOCUMENT_EXPORT_ERROR',
    BATCH_DOCUMENT_EXPORT_RESET = '@@batch-document-export/BATCH_DOCUMENT_EXPORT_RESET',
}

export interface BatchDocumentExportState extends BaseStoreState {
    readonly document?: BatchDocumentSimple;
}