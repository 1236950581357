import { TechnicalSupplierStatus } from './enums/technical-supplier-status';
import { OriginatorPaymentInstitutionStatus } from './enums/originator-payment-institution-status';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
export interface OriginatorPaymentInstitution {
    id?: number;
    name?: string;
    status?: OriginatorPaymentInstitutionStatus;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
    uniqueKey?: string;
    technicalSupplierStatus?: TechnicalSupplierStatus;
    accountId?: number
    accountName?: string
    accountStatus?: PaymentInstitutionAccountStatus
}

export const defaultOriginatorPaymentInstitution: OriginatorPaymentInstitution = {
    id: undefined,
    name: undefined,
    status: undefined,
    technicalSupplierId: undefined,
    technicalSupplierName: undefined,
    accountId: undefined,
    accountName: undefined
};

export interface OriginatorPaymentInstitutionDetail {
    id?: number;
    name?: string;
    status?: OriginatorPaymentInstitutionStatus;
    technicalSupplierId?: number;
    technicalSupplierName?: string;
    technicalSupplierStatus?: TechnicalSupplierStatus;
    accountId?: number
    accountName?: string
    accountStatus?: PaymentInstitutionAccountStatus
}
