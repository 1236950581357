import { PopperProps, Tooltip } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import { BatchSignatureDocumentsList } from 'model/batch-signature';
import { OperationDocumentType } from 'model/enums/operation-document-type';
import { FileStatus } from 'model/enums/file-status';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch } from 'reducer/hooks';
import { importedOperationDocumentRequest } from 'reducer/imported-operation/document/actions';
import ScdBatchEndorsementImportsOptionsItems from 'entities/batch/batch-endorsement/components/batch-endorsement-imports-options-items/batch-endorsement-imports-options-items';

interface ScdBatchEndorsementDocumentListProps {
    documents: BatchSignatureDocumentsList[];
    isCheckedBox: boolean;
    handleChangeImport: (document: BatchSignatureDocumentsList) => void;
    selectAllCheckBox: boolean;
    hasSelectedImport: (document: BatchSignatureDocumentsList) => boolean;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementDocumentList = (props: ScdBatchEndorsementDocumentListProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { documents, isCheckedBox, handleChangeImport, selectAllCheckBox, hasSelectedImport } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [operationId, setOperationId] = useState<number>();
    const [ccbNumber, setCcbNumber] = useState<number | undefined>(undefined);
    const [selectContract, setSelectContract] = useState<BatchSignatureDocumentsList | undefined>(undefined);


    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    const isDisableCheckBox = useCallback((document: BatchSignatureDocumentsList): boolean => {
        if (document.isAlreadyImported || document.fileStatus !== FileStatus.UPLOADED) return true;
        return false;
    }, []);

    const verifyCheckedBox = useCallback(
        (document: BatchSignatureDocumentsList): boolean => {
            if (isDisableCheckBox(document)) return hasSelectedImport(document);
            if (selectAllCheckBox) return selectAllCheckBox;

            return hasSelectedImport(document);
        },
        [hasSelectedImport, selectAllCheckBox, isDisableCheckBox]
    );

    const handleGenerateContract = () => {
        if (!operationId) return;
        if (!ccbNumber) return;

        const type = OperationDocumentType.SIGNED_CONTRACT;
        dispatch(importedOperationDocumentRequest({ operationId, type, ccbNumber }));

        setAnchorEl(null);
    };
    
    const handeSelects = (document: BatchSignatureDocumentsList) => {
        setSelectContract(document);
        setOperationId(document.operationId);
        setCcbNumber(Number(document.ccbNumber));
    }

    return (
        <>
            {documents.length !== 0 &&
                documents?.map(document => (
                    <tr className="page-container--table-cell" key={document.operationDocumentId}>
                        <td className="page-container--table-cell-title"> {document.ccbNumber ?? '-'} </td>
                        <td className="page-container--table-cell-title">
                            {t(`${I18N_PREFIX}.table.type.options.${document.operationDocumentType}`)}
                        </td>
                        <td className="page-container--table-cell-title">
                            <Tooltip title={document.fileName ?? '-'} PopperProps={popperProps}>
                                <div>{document.fileName ?? '-'}</div>
                            </Tooltip>
                        </td>
                        <td className="page-container--table-cell-title">
                            {' '}
                            {t(`${I18N_PREFIX}.table.status.options.${document.fileStatus}`)}{' '}
                        </td>
                        <td className="page-container--table-cell-title">
                            {t(
                                `${I18N_PREFIX}.table.isAlreadyImported.options.${document.isAlreadyImported
                                    .toString()
                                    .toLocaleUpperCase()}`
                            )}
                        </td>
                        <td className="page-container--table-cell-icon">
                            {isCheckedBox ? (
                                <span>
                                    <Checkbox
                                        checked={verifyCheckedBox(document)}
                                        disabled={isDisableCheckBox(document)}
                                        onChange={() => handleChangeImport(document)}
                                        color="primary"
                                    />
                                </span>
                            ) : (
                                <MoreOptionsMenu
                                    anchorEl={anchorEl}
                                    setAnchorEl={setAnchorEl}
                                    onClick={() => handeSelects(document)}
                                >
                                    <ScdBatchEndorsementImportsOptionsItems
                                        operationId={operationId}
                                        handleGenerateContract={handleGenerateContract}
                                        selectContract={selectContract}
                                    />
                                </MoreOptionsMenu>
                            )}
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default ScdBatchEndorsementDocumentList;
