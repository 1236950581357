import { HemeraProvisionalProxyResponse } from 'features/limelight/send-backings/domain/models';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ProvisionalLimelightProxySendBackingTypes {
    LIMELIGHT_PROXY_SEND_BACKING_REQUEST = '@@limelight-proxy-send-backing/LIMELIGHT_PROXY_SEND_BACKING_REQUEST',
    LIMELIGHT_PROXY_SEND_BACKING_SUCCESS = '@@limelight-proxy-send-backing/LIMELIGHT_PROXY_SEND_BACKING_SUCCESS',
    LIMELIGHT_PROXY_SEND_BACKING_ERROR = '@@limelight-proxy-send-backing/LIMELIGHT_PROXY_SEND_BACKING_ERROR',
    LIMELIGHT_PROXY_SEND_BACKING_RESET = '@@limelight-proxy-send-backing/LIMELIGHT_PROXY_SEND_BACKING_RESET'
}

export interface LimelightProxySendBackingState extends BaseStoreState {
    readonly hemeraResponse?: HemeraProvisionalProxyResponse
}