import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CessionToResumeBcoState, CessionToResumeBcoTypes } from 'reducer-bco/batch-output/cession-to-resume/types';
import { Reducer } from 'redux';

const initialState: CessionToResumeBcoState = {
    status: HttpRequestStatus.NOOP,
    cessionToResume: undefined,
    error: undefined,
};

const reducer: Reducer<CessionToResumeBcoState> = (state = initialState, action): CessionToResumeBcoState => {
    switch (action.type) {
        case CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, cessionToResume: action.payload, error: undefined };
        }
        case CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as CessionToResumeBcoReducer };
