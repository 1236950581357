import MenuItem from '@material-ui/core/MenuItem';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidIdentifierInput from 'components/inputs/identifier-input/valid-identifier-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE, SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateProgramGoodwill,
    validateProgramIdentifier,
    validateProgramIssuer,
    validateProgramName,
    validateProgramOrganization,
    validateProgramOriginator,
    validateProgramProductType,
    validateProgramStatus,
    validateScdProgram,
} from 'entities/program/validation/program-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramStatus } from 'model/enums/program-status';
import { ScdIssuerSearch } from 'model/issuer';
import { ScdOrganization } from 'model/organization';
import { ScdOriginatorSearch } from 'model/originator';
import { ScdProgramToCreateUpdate, defaultScdProgram } from 'model/program';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useCreateProgramState, useDetailProgramState, useRootDispatch, useUpdateProgramState } from 'reducer/hooks';
import { searchIssuersRequest, searchIssuersResetState } from 'reducer/issuer/search-issuers/actions';
import { searchOrganizationsRequest, searchOrganizationsResetState } from 'reducer/organization/search-organizations/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import { createProgramRequest, createProgramResetState } from 'reducer/program/create-program/actions';
import { detailProgramRequest, detailProgramResetState } from 'reducer/program/detail-program/actions';
import { updateProgramRequest, updateProgramResetState } from 'reducer/program/update-program/actions';
import { useToast } from 'shared/hooks/use-toast';

import PercentMaskAgio from 'components/inputs/percentage-input/percent-mask-agio';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ValidEstimatedPercentInput from 'entities/batch/batch-session-interest-rate-calculate-recalculate/components/inputs/valid-estimated-percent-input';
import './program-create-update.scss';

export interface ScdProgramUpdateProps extends RouteComponentProps<{ programId: string }> {}

const I18N_PREFIX = 'pages.scd.program';

const ScdProgramCreateUpdatePage = (props: ScdProgramUpdateProps) => {
    const { t } = useTranslation();
    const [programId] = useState<string | undefined>(props.match?.params?.programId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus, programSimple: createdProgram } = useCreateProgramState();
    const { status: updateStatus } = useUpdateProgramState();
    const { status: detailStatus, program: detailProgram } = useDetailProgramState();

    const { toastSuccess, closeToast, toastLoading } = useToast();

    const [scdProgram, setScdProgram] = useState<ScdProgramToCreateUpdate>(defaultScdProgram);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _scdProgram: ScdProgramToCreateUpdate = {
            ...scdProgram,
        };

        if (!validateScdProgram(_scdProgram)) return;

        if (programId) {
            dispatch(updateProgramRequest(_scdProgram));
        } else {
            dispatch(createProgramRequest(_scdProgram));
        }
    };

    const handleChange = (value: Partial<ScdProgramToCreateUpdate>) => {
        setScdProgram(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (programId) {
            dispatch(detailProgramRequest(programId));
        }
    }, [programId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailProgram !== undefined) {
            setScdProgram({
                ...detailProgram,
                originatorId: detailProgram.originator?.id,
                originatorName: detailProgram.originator?.name,
                organizationId: detailProgram.organization?.id,
                organizationName: detailProgram.organization?.name,
                issuerId: detailProgram.issuer?.id,
                issuerName: detailProgram.issuer?.name,
                issuerIdentification: detailProgram.issuer?.identification,
            });
        }
    }, [detailStatus, detailProgram]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [updateStatus, toastLoading]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.update.success`));
        history.push('/programs');
    }, [updateStatus, history, toastSuccess, t, closeToast]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [updateStatus, closeToast]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [createStatus, toastLoading]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS && createdProgram) {
            toastSuccess(t(`${I18N_PREFIX}.toast.create.success`));
            closeToast();
            history.push(`/programs/${createdProgram.id}/program-policies/new`);
        }
    }, [createStatus, history, createdProgram, toastSuccess, t, closeToast]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [createStatus, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(createProgramResetState());
            dispatch(updateProgramResetState());
            dispatch(detailProgramResetState());
        };
    }, [dispatch]);

    const loading = detailStatus === HttpRequestStatus.ON_GOING;

    return (
        <main className="scd-program__create">
            <ContextRibbon />
            <div className="scd-program__create--container">
                <header className="scd-program__create--header">
                    <h2 className="scd-program__create--header-title">
                        {!programId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && programId ? (
                    <Loading />
                ) : (
                    <form className="scd-program__create--form">
                        <div className="scd-program__create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdProgram?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateProgramName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-program__create--form-field">
                            <ValidIdentifierInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.identifier.label`)}
                                value={scdProgram?.identifier!}
                                placeholder={t(`${I18N_PREFIX}.input.identifier.placeholder`)}
                                onChange={identifier => handleChange({ identifier })}
                                validate={validateProgramIdentifier}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-program__create--form-field">
                            <ValidEstimatedPercentInput
                                label={t(`${I18N_PREFIX}.input.goodwill.label`)}
                                value={scdProgram?.goodwill!}
                                placeholder={t(`${I18N_PREFIX}.input.goodwill.placeholder`)}
                                onChange={goodwill => handleChange({ goodwill })}
                                validate={validateProgramGoodwill}
                                showValidation={showValidation}
                                externalUpdate
                                MaskInputComponent={PercentMaskAgio}
                            />
                        </div>
                        <div className="scd-program__create--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={scdProgram?.status ?? ''}
                                validate={validateProgramStatus}
                                mapperFromString={_value => (_value ? ScdProgramStatus[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                onChange={(status: string) => handleChange({ status: ScdProgramStatus[status] })}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramStatus.ACTIVE}>{t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}</MenuItem>
                                <MenuItem value={ScdProgramStatus.INACTIVE}>{t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program__create--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.productType.label`)}
                                value={scdProgram?.productType ?? ''}
                                validate={validateProgramProductType}
                                mapperFromString={_value => (_value ? ProductType[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.productType.placeholder`)}
                                onChange={(productType: string) => handleChange({ productType: ProductType[productType] })}
                                externalUpdate
                            >
                                <MenuItem value={ProductType.CREDIT_CARD}>
                                    {t(`${I18N_PREFIX}.input.productType.options.CREDIT_CARD`)}
                                </MenuItem>
                                <MenuItem value={ProductType.DIRECT_CONSUMER_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.productType.options.DIRECT_CONSUMER_CREDIT`)}
                                </MenuItem>
                                <MenuItem value={ProductType.PERSONAL_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.productType.options.PERSONAL_CREDIT`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program__create--form-field">
                            <ModalSearch<ScdOrganization>
                                action={searchOrganizationsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.organization.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.organization.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.organization.modal.placeholder`)}
                                onSelect={organization =>
                                    handleChange({
                                        organizationId: organization.id,
                                        organizationName: organization.name,
                                        originatorId: undefined,
                                        originatorName: undefined,
                                    })
                                }
                                renderItem={organization => organization.name}
                                statusSelector={(state: IRootState) => state.searchOrganizations.status}
                                dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                                resetState={searchOrganizationsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.organization.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.organization.placeholder`}
                                        validate={validateProgramOrganization}
                                        value={scdProgram.organizationName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!programId}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-program__create--form-field">
                            <ModalSearch<ScdOriginatorSearch>
                                action={searchOriginatorsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.originator.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.originator.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.originator.modal.placeholder`)}
                                onSelect={originator => handleChange({ originatorId: originator.id, originatorName: originator.name })}
                                renderItem={originator => originator?.name}
                                statusSelector={(state: IRootState) => state.searchOriginators.status}
                                dataSelector={(state: IRootState) => state.searchOriginators.originators}
                                requestParameters={{ organizationId: scdProgram.organizationId }}
                                resetState={searchOriginatorsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.originator.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.originator.placeholder`}
                                        validate={validateProgramOriginator}
                                        value={scdProgram?.originatorName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        disabled={!!programId || !scdProgram.organizationId}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-program__create--form-field">
                            <ModalSearch<ScdIssuerSearch>
                                action={searchIssuersRequest}
                                itemSize={SEARCH_ITEM_SIZE_LARGE}
                                modalTitle={t(`${I18N_PREFIX}.input.issuer.modal.title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.issuer.modal.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.issuer.modal.placeholder`)}
                                onSelect={issuer =>
                                    handleChange({
                                        issuerId: issuer.id,
                                        issuerName: issuer.name,
                                        issuerIdentification: issuer.identification,
                                    })
                                }
                                renderItem={issuer => `${issuer.identification} - ${issuer?.name}`}
                                statusSelector={(state: IRootState) => state.searchIssuers.status}
                                dataSelector={(state: IRootState) => state.searchIssuers.issuers}
                                resetState={searchIssuersResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.issuer.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.issuer.placeholder`}
                                        validate={validateProgramIssuer}
                                        value={
                                            scdProgram?.issuerName && scdProgram?.issuerIdentification
                                                ? `${scdProgram.issuerIdentification} - ${scdProgram.issuerName}`
                                                : ''
                                        }
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-program__create--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={programId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/programs');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdProgramCreateUpdatePage;
