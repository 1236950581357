import { HttpRequestStatus } from 'model/enums/http-request-status';
import { RetryDefinePaymentImportedOperationTypes, RetryDefinePaymentImportedOperationState } from './types';
import { Reducer } from 'redux';

export const initialState: RetryDefinePaymentImportedOperationState = {
    status: HttpRequestStatus.NOOP,
    importedOperationSimple: undefined,
    error: undefined,
};

const reducer: Reducer<RetryDefinePaymentImportedOperationState> = (state = initialState, action): RetryDefinePaymentImportedOperationState => {
    switch (action.type) {
        case RetryDefinePaymentImportedOperationTypes.RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case RetryDefinePaymentImportedOperationTypes.RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, importedOperationSimple: action.payload, error: undefined };
        }
        case RetryDefinePaymentImportedOperationTypes.RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case RetryDefinePaymentImportedOperationTypes.RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as retryDefinePaymentImportedOperationReducer };
