import { HeaderType } from 'model/enums/header-type';
import SimpleHeaderLegacy from 'shared/header-legacy/simple-header-legacy';
import NavbarLegacy from 'shared/navbar/navbar-legacy';

export interface HeaderPropsLegacy {
    type: HeaderType;
}

export const HeaderLegacy = (props: HeaderPropsLegacy) => {
    switch (props.type) {
        case HeaderType.ADMIN:
            // TODO: AB#546
            return <NavbarLegacy />;
        case HeaderType.SIMPLE:
            return <SimpleHeaderLegacy />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};

export default HeaderLegacy;
