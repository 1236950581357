import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBatchToRelocate } from 'model/batch';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBatchesToRelocateTypes {
    LIST_BATCHES_TO_RELOCATE_REQUEST = '@@batches-list-to-relocate/LIST_BATCHES_TO_RELOCATE_REQUEST',
    LIST_BATCHES_TO_RELOCATE_SUCCESS = '@@batches-list-to-relocate/LIST_BATCHES_TO_RELOCATE_SUCCESS',
    LIST_BATCHES_TO_RELOCATE_ERROR = '@@batches-list-to-relocate/LIST_BATCHES_TO_RELOCATE_ERROR',
    LIST_BATCHES_TO_RELOCATE_RESET = '@@batches-list-to-relocate/LIST_BATCHES_TO_RELOCATE_RESET',
}

export interface ListBatchesToRelocateState extends BaseStoreState {
    readonly batches?: Page<ScdBatchToRelocate>;
}
