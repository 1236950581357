import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React, { useEffect } from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../base-mask-input';
import InputUtils from '../input-utils';
import { ValidationResult } from '../validation/input-validation';
import ValidInput, { ValidInputProps } from '../validation/valid-input';
import LocalDateInputMask from './local-date-mask-input';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children' | 'validate' | 'value'>;
type Base = BaseInput & BaseValidation;

export interface ValidLocalDateInputProps extends Base {
    value: string | undefined;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    defaultDate?: Date;
    validate: (value: string) => ValidationResult;
    placeholder?: string;
    onChange: (value?: string) => void;
}

export const ValidLocalDateInput = (props: ValidLocalDateInputProps) => {
    const { value: propsValue, placeholder, validate, showValidation, MaskInputComponent, onChange, ...otherProps } = props;

    const [refDate, setRefDate] = React.useState<Date | undefined>(undefined);

    const handleChange = (value: string) => {
        if (value.length !== 10) {
            onChange(undefined);
            return;
        }

        const _date = InputUtils.parseLocalDate(value);

        setRefDate(_date);

        if (onChange && InputUtils.isValidDate(_date)) {
            onChange(InputUtils.formatToLocalDateISO(_date));
        }
    };

    useEffect(() => {
        setRefDate(propsValue && InputUtils.isValidDateISO(propsValue) ? InputUtils.parseDateFromISO(propsValue) : undefined);
    }, [propsValue]);

    return (
        <ValidInput
            className={clsx('base-input', { dirty: propsValue })}
            value={InputUtils.formatToLocalDate(refDate)}
            validate={validate}
            showValidation={showValidation}
        >
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    value={InputUtils.formatToLocalDate(refDate)}
                    error={error}
                    onChange={handleChange}
                    MaskInputComponent={MaskInputComponent ?? LocalDateInputMask}
                    placeholder={placeholder}
                />
            )}
        </ValidInput>
    );
};

export default ValidLocalDateInput;
