import { AxiosResponse } from 'axios';
import { BatchSignaturePreviewDocumentsImport } from 'model/batch-signature';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchSignatureError, batchSignatureRequest, batchSignatureSuccess } from 'reducer/batch/batch-signature/action';
import { BatchSignatureTypes } from 'reducer/batch/batch-signature/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchSignatureApi from 'services/api/batch-signature-api';
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchSignature(action: ReturnType<typeof batchSignatureRequest>) {
    try {
        const result: AxiosResponse<BatchSignaturePreviewDocumentsImport> = yield call(batchSignatureApi.previewDocumentsImport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchSignatureError(mapped));
            return;
        }
        yield put(batchSignatureSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchSignatureError(mapped));
    }
}

function* watchBatchSignature() {
    yield takeEvery(BatchSignatureTypes.BATCH_SIGNATURE_REQUEST, handleBatchSignature);
}

function* batchSignatureSaga() {
    yield all([fork(watchBatchSignature)]);
}

export default batchSignatureSaga;