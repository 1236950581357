import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import OriginatorFilterButtons from 'entities/originator/components/originator-filter-buttons/originator-filter-buttons';
import ScdOriginatorHeader from 'entities/originator/components/originator-header/originator-header';
import OriginatorModalFilter, {
    OriginatorModalFilterType,
} from 'entities/originator/components/originator-modal-filter/originator-modal-filter';
import OriginatorsList from 'entities/originator/components/originators-list/originators-list';
import useOriginatorFilterChips from 'entities/originator/hooks/use-originator-filter-chips';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { defaultOriginatorFilterRequest, ScdOriginatorFilterRequest, ScdOriginatorList } from 'model/originator';
import { OriginatorFilterPageable } from 'model/reducers';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listOriginatorsRequest } from 'reducer/originator/list-originators/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './originator.scss';

const I18N_PREFIX = 'pages.scd.originator';

export type ScdOriginatorListType = keyof ScdOriginatorList | 'createdDate';

const ScdOriginatorPage = () => {
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<ScdOriginatorListType>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [filterActive, setFilterActive] = useState<ScdOriginatorFilterRequest>(defaultOriginatorFilterRequest);
    const [modalFilter, setModalFilter] = useState<OriginatorModalFilterType | undefined>(undefined);
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);

    const { isOriginatorChipsActive, originatorFilterChips } = useOriginatorFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const history = useHistory();

    const filterPageable = useCallback((): OriginatorFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        const _filter: ScdOriginatorFilterRequest = filterActive ?? {};
        return {
            filter: _filter,
            pageable: _pageable,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScrollLegacy<ScdOriginatorList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listOriginators.originators,
        statusSelector: (state: IRootState) => state.listOriginators.status,
        action: listOriginatorsRequest,
        filterPageable: filterPageable,
        setPage,
    });

    const handleSort = (property: ScdOriginatorListType, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultOriginatorFilterRequest);
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.originator.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-originator">
            <section className="scd-originator__container">
                <header className="scd-originator__header">
                    <div className="scd-originator__header-container">
                        <h2 className="scd-originator__header-container--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton onClick={handleRefresh} />
                        </h2>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </div>
                    <div className="scd-originator__header--buttons-content">
                        <OriginatorFilterButtons
                            filterActive={filterActive}
                            setModalFilter={setModalFilter}
                            setIsOpenModalFilter={setIsOpenModalFilter}
                            handleResetFilterActive={handleResetFilterActive}
                        />
                    </div>
                    <ChipFilterActive filters={originatorFilterChips} />
                </header>

                <div className="scd-originator__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isOriginatorChipsActive,
                        })}
                    >
                        <thead>
                            <ScdOriginatorHeader
                                originators={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <OriginatorsList originators={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
                {modalFilter && (
                    <OriginatorModalFilter
                        filterActive={filterActive}
                        isOpenModalFilter={isOpenModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setIsOpenModalFilter(false)}
                        setFilterActive={setFilterActive}
                        setPage={setPage}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdOriginatorPage;
