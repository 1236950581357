import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import MonthlyCommissionTaxFileInput from 'entities/monthly-commission/monthly-commission-detail/components/inputs/monthly-commission-tax-file-input/monthly-commission-tax-file-input';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionStatus } from 'model/enums/monthly-commission-status';
import { TaxValueType } from 'model/enums/tax-type';
import { TaxValueDocumentType } from 'model/enums/tax-value-document-type';
import {
    ScdMonthlyCommissionDeleteTaxDocumentRequest,
    ScdMonthlyCommissionDetail,
    ScdMonthlyCommissionDetailTaxValue,
    ScdMonthlyCommissionDownloadTaxDocumentRequest,
    ScdMonthlyCommissionUploadTaxDocumentRequest,
} from 'model/monthly-commission';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMonthlyCommissionTaxDocumentState, useRootDispatch } from 'reducer/hooks';
import {
    monthlyCommissionDeleteTaxDocumentRequest,
    monthlyCommissionDownloadTaxDocumentRequest,
    monthlyCommissionUploadTaxDocumentRequest,
} from 'reducer/monthly-commission/tax-document/actions';

interface MonthlyCommissionDetailTaxInfoProps {
    monthlyCommission: ScdMonthlyCommissionDetail | undefined;
}

const MONTHLY_COMMISSION_TAX_VALUES_RECORD = {
    INCOME_TAX: taxValues => taxValues.find(value => value.type === TaxValueType.INCOME_TAX),
    TAX_OVER_SERVICES: taxValues => taxValues.find(value => value.type === TaxValueType.TAX_OVER_SERVICES),
    SOCIAL_TAXES: taxValues => taxValues.find(value => value.type === TaxValueType.SOCIAL_TAXES),
} as Record<TaxValueType, (taxValues: ScdMonthlyCommissionDetailTaxValue[]) => ScdMonthlyCommissionDetailTaxValue>;

const I18N_PREFIX = 'pages.scd.monthly-commission-detail';

const fallbackValue = '-'.repeat(7);

export const MonthlyCommissionDetailTaxInfo = (props: MonthlyCommissionDetailTaxInfoProps) => {
    const { monthlyCommission } = props;

    const [openTax, setOpenTax] = useState<TaxValueType | undefined>(undefined);

    const dispatch = useRootDispatch();
    const { status: taxDocumentStatus } = useMonthlyCommissionTaxDocumentState();

    const { t } = useTranslation();

    const isDisabledFileInput: boolean = useMemo(() => {
        return taxDocumentStatus === HttpRequestStatus.ON_GOING;
    }, [taxDocumentStatus]);

    const handleOpenTax = (tax: TaxValueType) => {
        if (tax === openTax) {
            setOpenTax(undefined);
            return;
        }

        setOpenTax(tax);
    };

    const handleUploadTaxDocument = (request: ScdMonthlyCommissionUploadTaxDocumentRequest) => {
        dispatch(monthlyCommissionUploadTaxDocumentRequest(request));
    };

    const handleDownloadTaxDocument = (request: ScdMonthlyCommissionDownloadTaxDocumentRequest) => {
        dispatch(monthlyCommissionDownloadTaxDocumentRequest(request));
    };

    const handleDeleteTaxDocument = (request: ScdMonthlyCommissionDeleteTaxDocumentRequest) => {
        dispatch(monthlyCommissionDeleteTaxDocumentRequest(request));
    };

    const incomeTax =
        monthlyCommission?.taxValues && MONTHLY_COMMISSION_TAX_VALUES_RECORD[TaxValueType.INCOME_TAX](monthlyCommission?.taxValues);
    const socialTaxes =
        monthlyCommission?.taxValues && MONTHLY_COMMISSION_TAX_VALUES_RECORD[TaxValueType.SOCIAL_TAXES](monthlyCommission?.taxValues);
    const taxOverServices =
        monthlyCommission?.taxValues && MONTHLY_COMMISSION_TAX_VALUES_RECORD[TaxValueType.TAX_OVER_SERVICES](monthlyCommission?.taxValues);

    return (
        <div className="scd-monthly-commission-detail__tax-info">
            <div className="scd-monthly-commission-detail__tax-info--item">
                <div
                    className={clsx('scd-monthly-commission-detail__tax-info--item__content', {
                        active: openTax === TaxValueType.INCOME_TAX,
                    })}
                >
                    <span className="scd-monthly-commission-detail__tax-info--item__content-label">
                        {!!incomeTax?.federalRevenueCollection && !!incomeTax.federalRevenueReceipt && <CheckCircleOutlineIcon />}
                        {t(`${I18N_PREFIX}.card.header.taxValue.INCOME_TAX`)}
                    </span>
                    <span className="scd-monthly-commission-detail__tax-info--item__content-value">
                        {incomeTax?.value ? <CurrencyFormatter value={incomeTax.value} prefix /> : fallbackValue}
                    </span>
                    {monthlyCommission?.status !== MonthlyCommissionStatus.NEW && incomeTax?.value && (
                        <div
                            className={clsx('scd-monthly-commission-detail__tax-info--item__content-icon', {
                                active: openTax === TaxValueType.INCOME_TAX,
                            })}
                            onClick={() => handleOpenTax(TaxValueType.INCOME_TAX)}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    )}
                    {openTax === TaxValueType.INCOME_TAX && (
                        <>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={incomeTax?.id}
                                    documentFile={incomeTax?.federalRevenueCollection}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_COLLECTION}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={incomeTax?.id}
                                    documentFile={incomeTax?.federalRevenueReceipt}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_RECEIPT}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="scd-monthly-commission-detail__tax-info--item">
                <div
                    className={clsx('scd-monthly-commission-detail__tax-info--item__content', {
                        active: openTax === TaxValueType.TAX_OVER_SERVICES,
                    })}
                >
                    <span className="scd-monthly-commission-detail__tax-info--item__content-label">
                        {!!taxOverServices?.federalRevenueCollection && !!taxOverServices?.federalRevenueReceipt && (
                            <CheckCircleOutlineIcon />
                        )}
                        {t(`${I18N_PREFIX}.card.header.taxValue.TAX_OVER_SERVICES`)}
                    </span>
                    <span className="scd-monthly-commission-detail__tax-info--item__content-value">
                        {taxOverServices?.value ? <CurrencyFormatter value={taxOverServices.value} prefix /> : fallbackValue}
                    </span>
                    {monthlyCommission?.status !== MonthlyCommissionStatus.NEW && taxOverServices?.value && (
                        <div
                            className={clsx('scd-monthly-commission-detail__tax-info--item__content-icon', {
                                active: openTax === TaxValueType.TAX_OVER_SERVICES,
                            })}
                            onClick={() => handleOpenTax(TaxValueType.TAX_OVER_SERVICES)}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    )}
                    {openTax === TaxValueType.TAX_OVER_SERVICES && (
                        <>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={taxOverServices?.id}
                                    documentFile={taxOverServices?.federalRevenueCollection}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_COLLECTION}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={taxOverServices?.id}
                                    documentFile={taxOverServices?.federalRevenueReceipt}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_RECEIPT}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="scd-monthly-commission-detail__tax-info--item">
                <div
                    className={clsx('scd-monthly-commission-detail__tax-info--item__content', {
                        active: openTax === TaxValueType.SOCIAL_TAXES,
                    })}
                >
                    <span className="scd-monthly-commission-detail__tax-info--item__content-label">
                        {!!socialTaxes?.federalRevenueCollection && !!socialTaxes?.federalRevenueReceipt && <CheckCircleOutlineIcon />}
                        {t(`${I18N_PREFIX}.card.header.taxValue.SOCIAL_TAXES`)}
                    </span>
                    <span className="scd-monthly-commission-detail__tax-info--item__content-value">
                        {socialTaxes?.value ? <CurrencyFormatter value={socialTaxes.value} prefix /> : fallbackValue}{' '}
                    </span>
                    {monthlyCommission?.status !== MonthlyCommissionStatus.NEW && socialTaxes?.value && (
                        <div
                            className={clsx('scd-monthly-commission-detail__tax-info--item__content-icon', {
                                active: openTax === TaxValueType.SOCIAL_TAXES,
                            })}
                            onClick={() => handleOpenTax(TaxValueType.SOCIAL_TAXES)}
                        >
                            <KeyboardArrowDownIcon />
                        </div>
                    )}
                    {openTax === TaxValueType.SOCIAL_TAXES && (
                        <>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={socialTaxes?.id}
                                    documentFile={socialTaxes?.federalRevenueCollection}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_COLLECTION}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                            <div className="scd-monthly-commission-detail__tax-info--item__content-file-input">
                                <MonthlyCommissionTaxFileInput
                                    label={t('entity.action.attach')}
                                    taxValueId={socialTaxes?.id}
                                    documentFile={socialTaxes?.federalRevenueReceipt}
                                    documentType={TaxValueDocumentType.FEDERAL_REVENUE_RECEIPT}
                                    monthlyCommissionId={monthlyCommission?.id}
                                    monthlyCommissionStatus={monthlyCommission?.status}
                                    onUpload={handleUploadTaxDocument}
                                    onDelete={handleDeleteTaxDocument}
                                    onDownload={handleDownloadTaxDocument}
                                    disabled={isDisabledFileInput}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MonthlyCommissionDetailTaxInfo;
