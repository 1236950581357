import { GiroWebappError } from 'model/error';
import { ScdOrganizationList } from 'model/organization';
import { ListOrganizationsTypes } from './types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listOrganizationsRequest = (request: Pageable) => action(ListOrganizationsTypes.LIST_ORGANIZATIONS_REQUEST, request);
export const listOrganizationsSuccess = (organizations: Page<ScdOrganizationList>) =>
    action(ListOrganizationsTypes.LIST_ORGANIZATIONS_SUCCESS, organizations);
export const listOrganizationsError = (error: GiroWebappError) => action(ListOrganizationsTypes.LIST_ORGANIZATIONS_ERROR, error);
export const listOrganizationsResetState = () => action(ListOrganizationsTypes.LIST_ORGANIZATIONS_RESET_STATE);
