import { UsersToGetAll } from 'model/user';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListUserTypes {
    LIST_USER_REQUEST = '@@users-list/LIST_USER_REQUEST',
    LIST_USER_SUCCESS = '@@users-list/LIST_USER_SUCCESS',
    LIST_USER_ERROR = '@@users-list/LIST_USER_ERROR',
    LIST_USER_RESET = '@@users-list/LIST_USER_RESET',
}

export interface ListUsersState extends BaseStoreState {
    readonly users?: Page<UsersToGetAll>;
}