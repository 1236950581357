import CloseButton from 'components/buttons/close-button/close-button';
import RemoveButton from 'components/buttons/remove-button/remove-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleTextArea from 'components/inputs/text-area/simple-text-area';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import './modal-text-area.scss';

export interface ModalTextAreaProps {
    onClose: () => void;
    onChange?: (value?: string) => void;
    openModal: boolean;
    isActive?: boolean;
    defaultValue?: string;
    title: string;
    label: string;
}

const ModalTextArea = (props: ModalTextAreaProps) => {
    const { onClose, openModal, onChange, isActive = true, defaultValue, label, title } = props;

    const [text, setText] = useState<string | undefined>(defaultValue ?? undefined);

    useEffect(() => {
        if (_.isNil(defaultValue)) return;

        setText(defaultValue);
    }, [defaultValue]);

    if (!openModal) {
        return <> </>;
    }

    const handleConfirm = () => {
        onClose();

        if (!onChange) return;
        onChange(text);
    };

    const handleChange = (value: string | undefined) => {
        setText(value);
    };

    const handleRemove = () => {
        setText(undefined);
        onClose();

        if (!onChange) return;
        onChange(undefined);
    };

    return (
        openModal && (
            <Modal isOpen>
                <div className="modal-text-area--container">
                    <div className="modal-text-area--header">
                        <label className="modal-text-area--header-title">{title}</label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <div>
                        <SimpleTextArea
                            value={text}
                            label={label}
                            placeholder={label}
                            onChange={handleChange}
                            externalUpdate
                            disabled={!isActive}
                        />
                    </div>
                    {isActive && (
                        <div className="modal-text-area--buttons-actions">
                            {text ? <RemoveButton onClick={handleRemove} /> : <div className="modal-text-area--buttons-actions-blank" />}
                            <StandardButtonLegacy label="entity.action.confirm" onClick={handleConfirm} />
                        </div>
                    )}
                </div>
            </Modal>
        )
    );
};

export default ModalTextArea;
