import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import { ScdRequestLogFilterRequest } from 'model/request-log';
import { Link, useRouteMatch } from 'react-router-dom';
import { useListRequestLogState } from 'reducer/hooks';

const I18N_PREFIX = 'pages.scd.request-log';

interface ScdRequestLogListProps {
    filterActive: ScdRequestLogFilterRequest;
}

const ScdRequestLogList = (props: ScdRequestLogListProps) => {
    const { url } = useRouteMatch();
    const { filterActive } = props;

    const { requestLogs } = useListRequestLogState();

    return (
        <EmptyCollectionAware
            collection={requestLogs?.content ?? []}
            isTable
            label={{
                key: `${I18N_PREFIX}.not-found`,
            }}
        >
            {requestLogs?.content?.map(({ id, createdDate, organizationKey, responseStatus, route }) => (
                <>
                    <tr className="page-container--table-cell" key={id}>
                        <td className="page-container--table-cell-title">
                            {createdDate ? <DateFormatter date={createdDate} format={DateFormat.DATETIME_UTC} /> : '00/00/0000'}
                        </td>
                        <td className="page-container--table-cell-title">{responseStatus ?? '-'}</td>
                        <td className="page-container--table-cell-title">{organizationKey ?? '-'}</td>
                        <td className="page-container--table-cell-title">{route ?? '-'}</td>
                        <td className="page-container--table-cell-icon">
                            <Link to={{ pathname: `${url}/${id}`, state: { filterActive } }}>
                                <VisualizeIconButton />
                            </Link>
                        </td>
                    </tr>
                </>
            ))}
        </EmptyCollectionAware>
    );
};

export default ScdRequestLogList;
