import CloseButton from 'components/buttons/close-button/close-button';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorHandler from 'shared/error/error-handler';

// Routes
import PrivateRoute from 'shared/routes/private-route';
import PublicRoute from 'shared/routes/public-route';

// Pages
import BankRoutes from 'entities/bank';
import BankerRoutes from 'entities/banker';
import BatchRoutes from 'entities/batch';
import CcbOperationRoutes from 'entities/ccb-operation';
import CompanyRoutes from 'entities/company';
import CompanyGroupRoutes from 'entities/company-group';
import CompanyGroupPolicyRoutes from 'entities/company-group-policy';
import DisbursementReportRoutes from 'entities/disbursement-report';
import FareAmountReportRoutes from 'entities/fare-amount-report';
import IssuerRoutes from 'entities/issuer';
import LegacyProgramRoutes from 'entities/legacy-program';
import MonthlyCommissionRoutes from 'entities/monthly-commission';
import MonthlyProcessedOperationsReportRoutes from 'entities/monthly-processed-operations-report';
import OrganizationRoutes from 'entities/organization';
import OriginatorRoutes from 'entities/originator';
import PaymentInstitutionsAccountsRoutes from 'entities/payment-institutions-accounts';
import PaymentTransactionRoutes from 'entities/payment-transaction';
import ProgramRoutes from 'entities/program';
import ProgramPolicyRoutes from 'entities/program-policy';
import RequestLogRoutes from 'entities/request-log';
import TaxRoutes from 'entities/tax';
import TechnicalSupplierRoutes from 'entities/technical-supplier';
import UserRoutes from 'entities/user';
import ForgotPassword from 'features/forgot-password/forgot-password';
import Home from 'features/home/home';
import ProvisionalLimelightRoutes from 'features/limelight';
import Login from 'features/login/login';
import PageNotFound from 'features/page-not-found/page-not-found';
import PasswordReset from 'features/password-reset/password-reset';
import ExternalPaymentInstitutionAccountsRoutes from 'shared/external/payment-accounts-strategy';

export interface AppRoutesProps {
    basename: string;
}

const provisionalDisable: boolean = false;

const AppRoutes = (props: AppRoutesProps) => {
    return (
        <BrowserRouter basename={props.basename}>
            <ToastContainer
                position={toast.POSITION.BOTTOM_CENTER}
                className="toastify-container"
                toastClassName="toastify-toast"
                hideProgressBar={true}
                pauseOnHover={true}
                closeButton={<CloseButton />}
            />
            <ErrorHandler />
            <Switch>
                <Route exact path="/" component={Login} />

                <PublicRoute exact path="/forgot-password" component={ForgotPassword} />
                <PublicRoute exact path="/password-reset/:key" component={PasswordReset} />

                <PrivateRoute exact path="/home" component={Home} />

                <Route path={'/legacy-programs'} component={LegacyProgramRoutes} />
                <Route path={'/programs'} component={ProgramRoutes} />
                <Route path={'/originators'} component={OriginatorRoutes} />
                <Route path={'/company-groups'} component={CompanyGroupRoutes} />
                <Route path={'/company-group-policies'} component={CompanyGroupPolicyRoutes} />
                <Route path={'/program-policies'} component={ProgramPolicyRoutes} />
                <Route path={'/disbursement-report'} component={DisbursementReportRoutes} />
                <Route path={'/payment-transactions'} component={PaymentTransactionRoutes} />
                <Route path={'/batches'} component={BatchRoutes} />
                <Route path={'/ccb-operations'} component={CcbOperationRoutes} />
                {provisionalDisable && <Route path={'/monthly-commissions'} component={MonthlyCommissionRoutes} />}
                <Route path={'/taxes'} component={TaxRoutes} />
                <Route path={'/issuers'} component={IssuerRoutes} />
                <Route path={'/technical-suppliers'} component={TechnicalSupplierRoutes} />
                <Route path={'/organizations'} component={OrganizationRoutes} />
                <Route path={'/request-log'} component={RequestLogRoutes} />
                <Route path={'/companies'} component={CompanyRoutes} />
                <Route path={'/fare-amount-report'} component={FareAmountReportRoutes} />
                <Route path={'/monthly-processed-operations-report'} component={MonthlyProcessedOperationsReportRoutes} />
                <Route path={'/bankers'} component={BankerRoutes} />
                <Route path={'/payment-institutions-accounts'} component={PaymentInstitutionsAccountsRoutes} />
                <Route path={'/external/payment-institutions-accounts'} component={ExternalPaymentInstitutionAccountsRoutes} />
                <Route path={'/users'} component={UserRoutes} />
                <Route path={'/provisional/limelight'} component={ProvisionalLimelightRoutes} />
                <Route path={'/banks'} component={BankRoutes} />
                <PublicRoute component={PageNotFound} />
            </Switch>
        </BrowserRouter>
    );
};

export default AppRoutes;
