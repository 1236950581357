import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchDocumentDownloadError, batchDocumentDownloadRequest, batchDocumentDownloadSuccess } from 'reducer/batch/batch-document-download/action';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { ErrorUtils } from 'shared/error/error-utils';
import batchDocumentApi from "services/api/batch-document"
import { BatchDocumentDownloadTypes } from 'reducer/batch/batch-document-download/types';

function* handleBatchDocumentDownload(action: ReturnType<typeof batchDocumentDownloadRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(batchDocumentApi.download, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchDocumentDownloadError(mapped));
            return;
        }
        yield put(batchDocumentDownloadSuccess(result.data));

        const { batchId, documentId } = action.payload;

        const _file_name = result.headers['content-disposition']?.split('filename=')[1] ?? `${batchId}_${documentId}.pdf`;
        const _type = result.headers['content-type'] ?? 'application/pdf';
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _file_name);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchDocumentDownloadError(mapped));
    }
}

function* watchBatchDocumentDownload() {
    yield takeEvery(BatchDocumentDownloadTypes.BATCH_DOCUMENT_DOWNLOAD_REQUEST, handleBatchDocumentDownload);
}

function* batchDocumentDownloadSaga() {
    yield all([fork(watchBatchDocumentDownload)]);
}

export default batchDocumentDownloadSaga;