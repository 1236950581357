import { AxiosResponse } from 'axios';
import { IssuerListPolicies, IssuerPoliciesList, IssuerPolicyCreateRequest, IssuerPolicyDetail, IssuerPolicyDetailRequest, IssuerPolicyIntegrationRequest, IssuerPolicyIntegrationSimple, IssuerPolicyResumeAllActivesRequest, IssuerPolicySimple, IssuerPolicyUpdateRequest } from 'model/issuer-policy';
import { IssuerPoliciesPageable } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

export const issuerPolicy = () => {
    const listPolicies = (request: IssuerPoliciesPageable): Promise<AxiosResponse<Page<IssuerListPolicies>>> => {
        return api.get<Page<IssuerListPolicies>>(`${BASE_URL_ADMIN}/issuers/${request.issuerId}/policies`, { params: { ...request.pageable } })
    }

    const createPolicy = (request: IssuerPolicyCreateRequest): Promise<AxiosResponse<IssuerPolicySimple>> => {
        return api.post<IssuerPolicySimple>(`${BASE_URL_ADMIN}/issuers/${request.issuerId}/policies`, request.policy);
    }

    const updatePolicy = (request: IssuerPolicyUpdateRequest): Promise<AxiosResponse<IssuerPolicySimple>> => {
        return api.patch<IssuerPolicySimple>(`${BASE_URL_ADMIN}/issuers/${request.issuerId}/policies/${request.id}`, request.policy);
    }

    const detailPolicy = (request: IssuerPolicyDetailRequest): Promise<AxiosResponse<IssuerPolicyDetail>> => {
        return api.get<IssuerPolicyDetail>(`${BASE_URL_ADMIN}/issuers/${request.issuerId}/policies/${request.id}`)
    }

    const createIntegration = (request: IssuerPolicyIntegrationRequest): Promise<AxiosResponse<IssuerPolicyIntegrationSimple>> => {
        return api.post<IssuerPolicyIntegrationSimple>(`${BASE_URL_ADMIN}/issuer-policies/${request.policyId}/integrations`, { ...request })
    }

    const updateIntegration = (request: IssuerPolicyIntegrationRequest): Promise<AxiosResponse<IssuerPolicyIntegrationSimple>> => {
        return api.patch<IssuerPolicyIntegrationSimple>(`${BASE_URL_ADMIN}/issuer-policies/${request.policyId}/integrations/${request.id}`, { ...request })
    }

    const activePoliciesSummary = (request: IssuerPolicyResumeAllActivesRequest): Promise<AxiosResponse<IssuerPoliciesList>> => {
        return api.get<IssuerPoliciesList>(`${BASE_URL_ADMIN}/issuers/${request.issuerId}/policies/ACTIVE/resume`);
    }

    return {
        listPolicies,
        createPolicy,
        updatePolicy,
        detailPolicy,
        createIntegration,
        updateIntegration,
        activePoliciesSummary
    }
}

export default issuerPolicy();