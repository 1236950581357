import BackButton from 'components/buttons/back-button/back-button';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import LinkButton from 'components/buttons/link-button/link-button';
import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import Loading from 'components/loading/loading';
import ScdProgramHeader from 'entities/legacy-program/components/program-header/program-header';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProgramFilterPageable, ScdProgramList } from 'model/program';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { useListProgramsState, useRootDispatch } from 'reducer/hooks';
import { listProgramsRequest, listProgramsResetState } from 'reducer/program/list-programs/actions';
import { LIST_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';

import './legacy-program.scss';

const I18N_PREFIX = 'pages.scd.program';

const ScdLegacyProgramPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const { programs, status: listStatus } = useListProgramsState();

    const sortPageable = useCallback((): ProgramFilterPageable => {
        const pageable: Pageable = {
            page: page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        return {
            pageable,
            filter: {},
        };
    }, [sortedProperty, sortOrder, page]);

    useEffect(() => {
        dispatch(listProgramsRequest(sortPageable()));
    }, [dispatch, sortPageable]);

    useEffect(() => {
        return () => {
            dispatch(listProgramsResetState());
        };
    }, [dispatch, sortPageable]);

    const handleTryAgain = () => {
        dispatch(listProgramsRequest(sortPageable()));
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.program.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleTryAgain} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: keyof ScdProgramList, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    return (
        <>
            <main className="scd-legacy-program">
                <section className="scd-legacy-program__container">
                    <header className="scd-legacy-program__header">
                        <h2 className="scd-legacy-program__header--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </header>
                    <div className="scd-legacy-program__table">
                        <table className="page-container--table">
                            <thead>
                                <ScdProgramHeader
                                    programs={programs?.content ?? []}
                                    handleSort={handleSort}
                                    sortedProperty={sortedProperty}
                                    sortOrder={sortOrder}
                                />
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <Loading isTable />
                                ) : (
                                    <EmptyCollectionAware
                                        collection={programs?.content ?? []}
                                        isTable
                                        label={{
                                            key: `${I18N_PREFIX}.not-found`,
                                        }}
                                    >
                                        {programs?.content?.map(({ id, name }) => (
                                            <tr className="page-container--table-cell" key={id}>
                                                <td className="page-container--table-cell-title">{name}</td>
                                                <td className="page-container--table-cell-icon">
                                                    <Link to={`${url}/${id}`}>
                                                        <VisualizeIconButton />
                                                    </Link>
                                                </td>
                                                <td className="page-container--table-cell-icon">
                                                    <Link to={`${url}/${id}/edit`}>
                                                        <EditIconButton />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </EmptyCollectionAware>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {!isLoading && <Pagination page={page} totalPages={programs?.totalPages ?? 0} onChange={handlePageChange} />}
                </section>
            </main>
        </>
    );
};

export default ScdLegacyProgramPage;
