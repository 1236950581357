import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Cnab Batch Report
import { cnabBatchReportReducer } from 'reducer/cnab/cnab-batch-report/reducer';
import cnabBatchReportSaga from 'reducer/cnab/cnab-batch-report/sagas';
import { CnabBatchReportState } from 'reducer/cnab/cnab-batch-report/types';

// Generate Cnab Batch
import { generateCnabBatchReducer } from 'reducer/cnab/generate-cnab-batch/reducer';
import generateCnabBatchSaga from 'reducer/cnab/generate-cnab-batch/sagas';
import { GenerateCnabBatchState } from 'reducer/cnab/generate-cnab-batch/types';

export interface CnabState {
    readonly cnabBatchReport: CnabBatchReportState;
    readonly generateCnabBatch: GenerateCnabBatchState;
}

export const CnabReducer: ReducersMapObject<CnabState, AnyAction> = {
    cnabBatchReport: cnabBatchReportReducer,
    generateCnabBatch: generateCnabBatchReducer,
};

export function* cnabSagas() {
    yield all([fork(cnabBatchReportSaga), fork(generateCnabBatchSaga)]);
}
