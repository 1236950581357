import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import paymentFavoredAPI from 'services/api/payment-favored-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchPaymentFavoredTypes } from 'reducer/payment-favored/search-payment-favored/types';
import {
    searchPaymentFavoredError,
    searchPaymentFavoredSuccess,
    searchPaymentFavoredRequest,
} from 'reducer/payment-favored/search-payment-favored/actions';
import { Page } from 'services/page';

function* handleSearchPaymentFavored(action: ReturnType<typeof searchPaymentFavoredRequest>) {
    try {
        const result: AxiosResponse<Page<ScdPaymentFavoredSearch>> = yield call(paymentFavoredAPI.searchPaymentFavored, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchPaymentFavoredError(mapped));
            return;
        }
        yield put(searchPaymentFavoredSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchPaymentFavoredError(mapped));
    }
}

function* watchSearchPaymentFavored() {
    yield takeEvery(SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_REQUEST, handleSearchPaymentFavored);
}

function* searchPaymentFavoredSaga() {
    yield all([fork(watchSearchPaymentFavored)]);
}

export default searchPaymentFavoredSaga;
