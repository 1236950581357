import FormControl from '@material-ui/core/FormControl';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from '../base-mask-input';
import InputUtils from '../input-utils';
import LocalDateInputMask from './local-date-mask-input';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;

export interface SimpleLocalDateInputProps extends Base {
    value?: Date;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    defaultDate?: Date;
    placeholder?: string;
    onChange: (value?: Date) => void;
}

export const SimpleLocalDateInput = (props: SimpleLocalDateInputProps) => {
    const { value: propsValue, defaultDate, placeholder, MaskInputComponent, onChange, ...otherProps } = props;

    const [refDate, setRefDate] = React.useState<Date | undefined>(propsValue);

    const handleChange = (value: string) => {
        if (value.length !== 10) return;

        const _date = InputUtils.parseLocalDate(value);

        setRefDate(_date);

        if (InputUtils.isValidDate(_date)) {
            if (!onChange) return;

            onChange(_date);
        }

        if (!value && onChange) {
            onChange(undefined);
        }
    };

    React.useEffect(() => {
        if (!propsValue && !defaultDate) {
            setRefDate(undefined);
        }
    }, [propsValue, defaultDate]);

    return (
        <FormControl className={clsx('base-input', { dirty: refDate })}>
            <BaseMaskInput
                {...otherProps}
                value={InputUtils.formatToLocalDate(refDate) ?? propsValue}
                onChange={handleChange}
                MaskInputComponent={MaskInputComponent ?? LocalDateInputMask}
                placeholder={placeholder}
            />
        </FormControl>
    );
};

export default SimpleLocalDateInput;
