import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import { OriginatorPaymentInstitution } from 'model/originator-payment-institution';
import { useTranslation } from 'react-i18next';
import {
    validateOriginatorPaymentInstitutionAccount,
    validateOriginatorPaymentInstitutionName,
    validateOriginatorPaymentInstitutionStatus,
    validateOriginatorPaymentInstitutionTechnicalSupplier,
} from 'entities/originator/validation/originator-payment-institution-validation';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import { OriginatorPaymentInstitutionStatus } from 'model/enums/originator-payment-institution-status';
import MenuItem from '@material-ui/core/MenuItem';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { IRootState } from 'reducer';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import { searchTechnicalSuppliersRequest, searchTechnicalSuppliersResetState } from 'reducer/technical-supplier/search-originators/actions';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import { OriginatorPaymentInstitutionEdition } from 'entities/originator/components/originator-payment-institution-card/originator-payment-institution-manager';
import { PaymentInstitutionToSearch } from 'model/payment-institution-account';
import {
    searchPaymentInstitutionsAccountsRequest,
    searchPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/actions';

export interface OriginatorPaymentInstitutionCardCreateProps {
    entity: OriginatorPaymentInstitutionEdition;
    originatorPaymentInstitutions?: readonly OriginatorPaymentInstitution[];
    originatorPaymentInstitutionIndex: number;
    isNewPaymentInstitution?: boolean;
    onChange: (originatorPaymetInstitution: Partial<OriginatorPaymentInstitution>) => void;
    isEditing: boolean;
    showValidation: boolean;
}

const I18N_PREFIX = 'pages.scd.originator.input.originatorPaymentInstitution';

export const OriginatorPaymentInstitutionCardCreate = (props: OriginatorPaymentInstitutionCardCreateProps) => {
    const {
        originatorPaymentInstitutionIndex,
        entity,
        originatorPaymentInstitutions,
        onChange,
        isNewPaymentInstitution,
        isEditing,
        showValidation,
    } = props;

    const { t } = useTranslation();

    return (
        <article className="scd-originator__card-manager">
            <header className="card-edition__container--header">
                <span className="card-edition__container--header-name">
                    {t(`${I18N_PREFIX}.name`, { number: originatorPaymentInstitutionIndex + 1 })}
                </span>
            </header>
            <div className="card-edition__container--content">
                <div className="scd-originator--form-card-field">
                    <ValidTextInput
                        type="text"
                        label={t(`${I18N_PREFIX}.input.name.label`)}
                        value={entity?.name ?? ''}
                        placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                        onChange={name => onChange({ name })}
                        validate={validateOriginatorPaymentInstitutionName}
                        showValidation={showValidation}
                    />
                </div>
                <div className="scd-originator--form-card-field">
                    <ValidSelectInput
                        label={t(`${I18N_PREFIX}.input.status.label`)}
                        value={entity?.status ?? ''}
                        validate={() =>
                            validateOriginatorPaymentInstitutionStatus({
                                uniqueKey: entity.uniqueKey,
                                originatorPaymentInstitutions: originatorPaymentInstitutions,
                                status: entity.status,
                                technicalSupplierStatus: entity.technicalSupplierStatus,
                            })
                        }
                        mapperFromString={_value => (_value ? OriginatorPaymentInstitutionStatus[_value] : undefined)}
                        showValidation={showValidation}
                        placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                        onChange={(status: string) => onChange({ status: OriginatorPaymentInstitutionStatus[status] })}
                        externalUpdate
                    >
                        <MenuItem value={OriginatorPaymentInstitutionStatus.ACTIVE}>
                            {t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}
                        </MenuItem>
                        <MenuItem value={OriginatorPaymentInstitutionStatus.INACTIVE}>
                            {t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}
                        </MenuItem>
                    </ValidSelectInput>
                </div>

                <div className="scd-originator--form-card-field">
                    <ModalSearch<ScdTechnicalSupplier>
                        action={searchTechnicalSuppliersRequest}
                        itemSize={SEARCH_ITEM_SIZE}
                        modalTitle={t(`${I18N_PREFIX}.input.technicalSupplier.title`)}
                        modalLabel={t(`${I18N_PREFIX}.input.technicalSupplier.label`)}
                        modalPlaceholder={t(`${I18N_PREFIX}.input.technicalSupplier.placeholder`)}
                        onSelect={technicalSupplier =>
                            onChange({
                                technicalSupplierId: Number(technicalSupplier.id),
                                technicalSupplierName: technicalSupplier.name,
                            })
                        }
                        renderItem={technicalSupplier => technicalSupplier.name}
                        statusSelector={(state: IRootState) => state.searchTechnicalSuppliers.status}
                        dataSelector={(state: IRootState) => state.searchTechnicalSuppliers.technicalSuppliers}
                        resetState={searchTechnicalSuppliersResetState}
                    >
                        {handleOpen => (
                            <>
                                <ValidSearchInput
                                    label={t(`${I18N_PREFIX}.input.technicalSupplier.label`)}
                                    placeholder={t(`${I18N_PREFIX}.input.technicalSupplier.placeholder`)}
                                    value={entity.technicalSupplierName ?? ''}
                                    onClick={handleOpen}
                                    onFocus={handleOpen}
                                    disabled={isEditing && !isNewPaymentInstitution}
                                    readOnly
                                    externalUpdate
                                    showValidation={showValidation}
                                    validate={() =>
                                        validateOriginatorPaymentInstitutionTechnicalSupplier({
                                            uniqueKey: entity.uniqueKey,
                                            originatorPaymentInstitutions: originatorPaymentInstitutions,
                                            technicalSupplierId: entity.technicalSupplierId,
                                        })
                                    }
                                />
                            </>
                        )}
                    </ModalSearch>
                </div>
                <div className="scd-originator--form-card-field">
                    <ModalSearch<PaymentInstitutionToSearch>
                        action={searchPaymentInstitutionsAccountsRequest}
                        requestParameters={{ technicalSupplierId: entity.technicalSupplierId }}
                        itemSize={SEARCH_ITEM_SIZE}
                        modalTitle={t(`${I18N_PREFIX}.input.account.title`)}
                        modalLabel={t(`${I18N_PREFIX}.input.account.label`)}
                        modalPlaceholder={t(`${I18N_PREFIX}.input.account.placeholder`)}
                        onSelect={account =>
                            onChange({
                                accountId: Number(account.id),
                                accountName: account.name,
                            })
                        }
                        renderItem={account => account.name}
                        statusSelector={(state: IRootState) => state.searchPaymentInstitutionsAccounts.status}
                        dataSelector={(state: IRootState) => state.searchPaymentInstitutionsAccounts.searchPaymentInstitutionsAccounts}
                        resetState={searchPaymentInstitutionsAccountsResetState}
                    >
                        {handleOpen => (
                            <>
                                <ValidSearchInput
                                    label={t(`${I18N_PREFIX}.input.account.label`)}
                                    placeholder={t(`${I18N_PREFIX}.input.account.placeholder`)}
                                    value={entity.accountName ?? ''}
                                    onClick={handleOpen}
                                    onFocus={handleOpen}
                                    disabled={!entity.technicalSupplierId}
                                    readOnly
                                    externalUpdate
                                    showValidation={showValidation}
                                    validate={() =>
                                        validateOriginatorPaymentInstitutionAccount({
                                            uniqueKey: entity.uniqueKey,
                                            originatorPaymentInstitutions: originatorPaymentInstitutions,
                                            accountId: entity.accountId,
                                        })
                                    }
                                />
                            </>
                        )}
                    </ModalSearch>
                </div>
            </div>
        </article>
    );
};
