import clsx from 'clsx';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import { ScdBatchDetail } from 'model/batch';
import { BatchOutputEndorsementToResume } from 'model/batch-output-endorsement';
import { BatchSignatureDocumentsList, BatchSignatureForOutputList } from 'model/batch-signature';
import { BatchStatus } from 'model/enums/batch-status';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const I18N_PREFIX = 'pages.scd.batch';

interface ScdBatchEndorsementHeaderProps {
    batch?: ScdBatchDetail;
    outputEndorsement?: BatchOutputEndorsementToResume;
    handleSwitch?: (value: boolean) => void;
    setIsChecked?: (value: boolean) => void;
    isChecked?: boolean;
    handleList: () => void;
    listEndorsementOrImports?: BatchSignatureForOutputList[] | BatchSignatureDocumentsList[];
}

const ScdBatchEndorsementHeader = (props: ScdBatchEndorsementHeaderProps) => {
    const { batch, outputEndorsement, handleSwitch, isChecked, handleList, listEndorsementOrImports } = props;
    const { t } = useTranslation();

    const location = useLocation().pathname.includes('/endorsement/imports');

    return (
        <header className="scd-batch-endorsement__header">
            <div className="scd-batch-endorsement__header-container">
                <div className="scd-batch-endorsement__header-container--content">
                    <h2 className="scd-batch-endorsement__header-container--title">
                        {`${t(`${I18N_PREFIX}.product.options.${batch?.product || '.not-found'}`).toUpperCase()} ${batch?.code}`}
                        <RefreshButton onClick={handleList} />
                    </h2>
                    <span className="page-container--table-status">
                        <span
                            className={clsx('page-container--table-status', {
                                CLOSED: batch?.status === BatchStatus.CLOSED,
                                CONCLUDED: batch?.status === BatchStatus.CONCLUDED,
                            })}
                        >
                            {batch?.status === BatchStatus.CLOSED || batch?.status === BatchStatus.CONCLUDED
                                ? t(`${I18N_PREFIX}.status.options.${batch?.status}`).toUpperCase()
                                : '-'}
                        </span>
                    </span>
                </div>
                <div className="scd-batch-endorsement__header-container--sub-itens">
                    <div className="scd-batch-endorsement__header-container--subtitle">
                        <span style={{ marginRight: '10px' }}>
                            {outputEndorsement?.policyName || t(`${I18N_PREFIX}.batch-endorsement.title`)}
                        </span>
                        <span style={{ marginRight: '10px' }}>
                            {t(`${I18N_PREFIX}.batch-endorsement.model.policy.${outputEndorsement?.programOutputModel}`)}
                        </span>
                        <span style={{ marginRight: '10px' }}>
                            {t(`${I18N_PREFIX}.batch-endorsement.model.method.${outputEndorsement?.programOutputMethod}`)}
                        </span>
                    </div>
                    <div className="scd-batch-endorsement__header-container--buttons">
                        {!isChecked && listEndorsementOrImports?.length !== 0 && (
                            <div className="scd-batch-endorsement__header--select-operations" onClick={() => handleSwitch?.(true)}>
                                <span className="scd-batch-endorsement__header--select-operations__icon" />
                                {location ? (
                                    <span className="scd-batch-endorsement__header--select-operations__value">
                                        {t(`${I18N_PREFIX}.batch-endorsement.buttons.select.action.selected`).toLocaleUpperCase()}
                                    </span>
                                ) : (
                                    <span className="scd-batch-endorsement__header--select-operations__value">
                                        {t(`${I18N_PREFIX}.batch-endorsement.buttons.select.action.select-all`).toLocaleUpperCase()}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default ScdBatchEndorsementHeader;
