import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import { DataChunk } from 'model/bco/batch-output-cession-integration-gtsec';
import { ExportRequestStatus, ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { BatchExportType } from 'model/enums/batch-export-type';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface CessionIntegrationOptionsChunkProps {
    setSelectExportRequestType: (value: ExportRequestType) => void;
    setOpenModal: (value: boolean) => void;
    setAnchorElSubTable: Dispatch<SetStateAction<HTMLElement | null>>;
    chunk: DataChunk;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.options-menu';

const CessionIntegrationOptionsChunk = (props: CessionIntegrationOptionsChunkProps) => {
    const { t } = useTranslation();

    const { setSelectExportRequestType, setOpenModal, setAnchorElSubTable, chunk } = props;

    const handleChunkSend = (type: ExportRequestType) => {
        setSelectExportRequestType(type);
        setOpenModal(true);
        setAnchorElSubTable(null);
    };

    const hasInstallmentFailures = chunk.exports.some(
        exportItem => exportItem.type === BatchExportType.CHUNK_INSTALLMENTS && exportItem.status === ExportRequestStatus.ERROR
    );
    const hasOperationFailures = chunk.exports.some(
        exportItem => exportItem.type === BatchExportType.CHUNK_OPERATIONS && exportItem.status === ExportRequestStatus.ERROR
    );

    return (
        <>
            <ModalActionMenuItem
                titleKey={t(`${I18N_PREFIX}.installments`)}
                onClick={() => handleChunkSend(ExportRequestType.CHUNK_INSTALLMENTS)}
                show={hasInstallmentFailures}
            />
            <ModalActionMenuItem
                titleKey={t(`${I18N_PREFIX}.operations`)}
                onClick={() => handleChunkSend(ExportRequestType.CHUNK_OPERATIONS)}
                show={hasOperationFailures}
            />
        </>
    );
};

export default CessionIntegrationOptionsChunk;
