import { AxiosResponse } from 'axios';
import { ScdBatchToRelocateResponse } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    createBatchToRelocateRequest, createBatchToRelocateSuccess, createBatchToRelocateError
} from 'reducer/batch/create-batch-to-relocate/actions';
import { CreateBatchToRelocateTypes } from 'reducer/batch/create-batch-to-relocate/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateBatchToRelocate(action: ReturnType<typeof createBatchToRelocateRequest>) {
    try {
        const result: AxiosResponse<ScdBatchToRelocateResponse> = yield call(batchAPI.batchToRelocateCreateNew, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createBatchToRelocateError(mapped));
            return;
        }
        yield put(createBatchToRelocateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createBatchToRelocateError(mapped));
    }
}

function* watchCreateBatchToRelocate() {
    yield takeLeading(CreateBatchToRelocateTypes.CREATE_BATCH_TO_RELOCATE_REQUEST, handleCreateBatchToRelocate);
}

function* createBatchToRelocateSaga() {
    yield all([fork(watchCreateBatchToRelocate)]);
}

export default createBatchToRelocateSaga;
