import { InputBaseComponentProps } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const YEAR_MASK = (value: string) => {
    if (value?.startsWith('1')) {
        return [/1/, /9/, /\d/, /\d/];
    } else if (value?.startsWith('2')) {
        return [/2/, /0/, /[0-5]/, /\d/];
    }
    return [/\[12]/, /\d/, /\d/, /\d/];
};

export const YearMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={YEAR_MASK} guide={false} />;
};

export default YearMask;
