import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { CessionOperationBcoTypes } from 'reducer-bco/batch-output/cession-operation/types';
import { Page } from 'services/page';
import { CessionOperationBco, CessionOperationRequest } from 'model/bco/batch-output-cession-integration-webcred';

export const listCessionOperationBcoRequest = (request: CessionOperationRequest) =>
    action(CessionOperationBcoTypes.CESSION_OPERATION_BCO_REQUEST, request);
export const listCessionOperationBcoSuccess = (cessionOperationBco: Page<CessionOperationBco>) =>
    action(CessionOperationBcoTypes.CESSION_OPERATION_BCO_SUCCESS, cessionOperationBco);
export const listCessionOperationBcoError = (error: GiroWebappError) => action(CessionOperationBcoTypes.CESSION_OPERATION_BCO_ERROR, error);
export const listCessionOperationBcoResetState = () => action(CessionOperationBcoTypes.CESSION_OPERATION_BCO_RESET);
