import { ScdBatchClose } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ExportBatchTypes {
    EXPORT_BATCH_REQUEST = '@@export-batch/EXPORT_BATCH_REQUEST',
    EXPORT_BATCH_SUCCESS = '@@export-batch/EXPORT_BATCH_SUCCESS',
    EXPORT_BATCH_ERROR = '@@export-batch/EXPORT_BATCH_ERROR',
    EXPORT_BATCH_RESET = '@@export-batch/EXPORT_BATCH_RESET',
}

export interface ExportBatchState extends BaseStoreState {
    readonly exportedBatch?: ScdBatchClose;
}
