import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramSimple } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum LegacyCreateProgramTypes {
    LEGACY_CREATE_PROGRAM_REQUEST = '@@legacy-program-create/LEGACY_CREATE_PROGRAM_REQUEST',
    LEGACY_CREATE_PROGRAM_SUCCESS = '@@legacy-program-create/LEGACY_CREATE_PROGRAM_SUCCESS',
    LEGACY_CREATE_PROGRAM_ERROR = '@@legacy-program-create/LEGACY_CREATE_PROGRAM_ERROR',
    LEGACY_CREATE_PROGRAM_RESET_STATE = '@@legacy-program-create/LEGACY_CREATE_PROGRAM_RESET_STATE',
}

export interface LegacyCreateProgramState extends BaseStoreState {
    readonly program?: ScdProgramSimple;
}
