import { GiroWebappError } from 'model/error';
import { ScdTaxList } from 'model/tax';
import { ListTaxesTypes } from 'reducer/tax/list-taxes/types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listTaxesRequest = (request: Pageable) => action(ListTaxesTypes.LIST_TAXES_REQUEST, request);
export const listTaxesSuccess = (taxes: Page<ScdTaxList>) => action(ListTaxesTypes.LIST_TAXES_SUCCESS, taxes);
export const listTaxesError = (error: GiroWebappError) => action(ListTaxesTypes.LIST_TAXES_ERROR, error);
export const listTaxesResetState = () => action(ListTaxesTypes.LIST_TAXES_RESET_STATE);
