import { Grid } from '@material-ui/core';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { OperationApprovalStatus } from 'model/enums/operation-approval-status';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface ApprovalOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.operationApproval';

const OperationApprovalCard = (props: ApprovalOperationCardProps) => {
    const {
        operation: { operationApproval },
    } = props;

    const { t } = useTranslation();

    return (
        <>
            {operationApproval && (
                <BaseOperationCard title={t(`${I18N_PREFIX}.title.options.${operationApproval?.status}`)} isOpenable>
                    <Grid item xs={6}>
                        <OperationReadOnlyTextField
                            label={t(`${I18N_PREFIX}.fields.requestDate`)}
                            value={
                                operationApproval?.requestDate ? (
                                    <DateFormatter
                                        date={operationApproval?.requestDate as Date}
                                        format={DateFormat.DATETIME_UTC_COMPLETE}
                                    />
                                ) : (
                                    '00/00/0000 - 00:00:00'
                                )
                            }
                        />
                    </Grid>
                    {operationApproval?.status !== OperationApprovalStatus.PENDING && (
                        <>
                            <Grid item xs={6}>
                                <OperationReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.fields.approvalDate`)}
                                    value={
                                        operationApproval?.approvalDate ? (
                                            <DateFormatter
                                                date={operationApproval?.approvalDate as Date}
                                                format={DateFormat.DATETIME_UTC_COMPLETE}
                                            />
                                        ) : (
                                            '00/00/0000 - 00:00:00'
                                        )
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <OperationReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.fields.approverName`)}
                                    value={operationApproval?.approverName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <OperationReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.fields.approverEmail`)}
                                    value={operationApproval?.approverEmail}
                                />
                            </Grid>
                        </>
                    )}
                    {operationApproval?.status === OperationApprovalStatus.DISAPPROVED && (
                        <Grid item xs>
                            <OperationReadOnlyTextField
                                label={t(`${I18N_PREFIX}.fields.disapprovalReason`)}
                                value={operationApproval?.disapprovalReason}
                            />
                        </Grid>
                    )}
                </BaseOperationCard>
            )}
        </>
    );
};

export default OperationApprovalCard;
