import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationDocumentError,
    importedOperationDocumentRequest,
    importedOperationDocumentSuccess,
} from 'reducer/imported-operation/document/actions';
import { ImportedOperationDocumentTypes } from 'reducer/imported-operation/document/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationDocument(action: ReturnType<typeof importedOperationDocumentRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(importedOperationApi.document, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationDocumentError(mapped));
            return;
        }
        yield put(importedOperationDocumentSuccess(result.data));

        const { type, ccbNumber } = action.payload;

        const _file_name = result.headers['content-disposition']?.split('filename=')[1] ?? `${ccbNumber}_${type}.pdf`;
        const _type = result.headers['content-type'] ?? 'application/pdf';
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _file_name);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationDocumentError(mapped));
    }
}

function* watchImportedOperationDocument() {
    yield takeEvery(ImportedOperationDocumentTypes.IMPORTED_OPERATION_DOCUMENT_REQUEST, handleImportedOperationDocument);
}

function* importedOperationDocumentSaga() {
    yield all([fork(watchImportedOperationDocument)]);
}

export default importedOperationDocumentSaga;
