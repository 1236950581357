import { AxiosResponse } from 'axios'
import { ScdProgramResponse } from 'model/program'
import { ManageCompanyGroupToProgramRequest, ProgramGroupsRequest, ProgramGroupsToGetAll, ProgramToResume } from 'model/program-groups'
import api from 'services/api/api'
import { BASE_URL_ADMIN, Page } from 'services/page'

const BASE_URL = `${BASE_URL_ADMIN}/scd-programs`

const programGroupsApi = () => {
    const listProgramGroups = (request: ProgramGroupsRequest): Promise<AxiosResponse<Page<ProgramGroupsToGetAll>>> => {
        const { pageable, programId } = request
        return api.get<Page<ProgramGroupsToGetAll>>(`${BASE_URL}/${programId}/company-groups`, {
            params: { ...pageable }
        })
    }

    const addCompanyGroupToProgram = (request: ManageCompanyGroupToProgramRequest): Promise<AxiosResponse<ScdProgramResponse>> => {
        return api.post<ScdProgramResponse>(`${BASE_URL}/${request.programId}/company-groups/${request.companyGroupId}`)
    }

    const removeCompanyGroupFromProgram = (request: ManageCompanyGroupToProgramRequest): Promise<AxiosResponse<ScdProgramResponse>> => {
        return api.delete<ScdProgramResponse>(`${BASE_URL}/${request.programId}/company-groups/${request.companyGroupId}`)
    }

    const resumeProgram = (programId: string): Promise<AxiosResponse<ProgramToResume>> => {
        return api.get<ProgramToResume>(`${BASE_URL}/${programId}/company-groups/resume`)
    }

    return {
        listProgramGroups,
        addCompanyGroupToProgram,
        removeCompanyGroupFromProgram,
        resumeProgram
    }
}

export default programGroupsApi()