import { ScdOrganization } from 'model/organization';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.company.validate.organization';

const COMPANY_ORGANIZATIONS_ID: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.organizationId.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.organizationId.required`,
    },
};

export const validateCompanyOrganizationId = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ORGANIZATIONS_ID);

export const validateCompanyOrganization = (value: ScdOrganization | undefined) => {
    if (!value) {
        return false;
    }

    return validateCompanyOrganizationId(value.id?.toString()).isValid;
};
