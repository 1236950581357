import { ScdBatchToRelocateCreateNewRequest, ScdBatchToRelocateResponse } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { CreateBatchToRelocateTypes } from 'reducer/batch/create-batch-to-relocate/types';
import { action } from 'typesafe-actions';

export const createBatchToRelocateRequest = (request: ScdBatchToRelocateCreateNewRequest) =>
    action(CreateBatchToRelocateTypes.CREATE_BATCH_TO_RELOCATE_REQUEST, request);
export const createBatchToRelocateSuccess = (newBatch: ScdBatchToRelocateResponse) =>
    action(CreateBatchToRelocateTypes.CREATE_BATCH_TO_RELOCATE_SUCCESS, newBatch);
export const createBatchToRelocateError = (error: GiroWebappError) =>
    action(CreateBatchToRelocateTypes.CREATE_BATCH_TO_RELOCATE_ERROR, error);
export const createBatchToRelocateResetState = () => action(CreateBatchToRelocateTypes.CREATE_BATCH_TO_RELOCATE_RESET);
