import { BaseStoreState } from 'reducer/base-store-state';
import { ProgramToResume } from 'model/program-groups';

export type ApiResponse = Record<string, string>;

export enum ProgramToResumeTypes {
    PROGRAM_TO_RESUME_REQUEST = '@@program-to-resume/PROGRAM_TO_RESUME_REQUEST',
    PROGRAM_TO_RESUME_SUCCESS = '@@program-to-resume/PROGRAM_TO_RESUME_SUCCESS',
    PROGRAM_TO_RESUME_ERROR = '@@program-to-resume/PROGRAM_TO_RESUME_ERROR',
    PROGRAM_TO_RESUME_RESET_STATE = '@@program-to-resume/PROGRAM_TO_RESUME_RESET_STATE',
}

export interface ProgramToResumeState extends BaseStoreState {
    readonly resumedProgram?: ProgramToResume;
}
