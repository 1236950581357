const TAB_SPACES = 2

const parseJsonString = (value: string | undefined | null): object | undefined => {
    if (!value) {
        return undefined
    }

    try {
        const parsedValue = JSON.parse(value)

        return parsedValue
    } catch (error) {
        return undefined
    }
}

const prettifyJsonByString = (value: string | undefined | null): string | undefined => {
    if (!value) {
        return undefined
    }

    try {
        const parsedValue: object = JSON.parse(value)

        const prettifiedJsonString: string = JSON.stringify(parsedValue, null, TAB_SPACES)

        return prettifiedJsonString
    } catch (error) {
        return value
    }
}

const prettifyJsonByObject = <T extends object>(value: T | null | undefined): string => {
    if (!value) {
        return ""
    }

    return JSON.stringify(value, null, TAB_SPACES)
}

export const JsonUtils = {
    parseJsonString,
    prettifyJsonByString,
    prettifyJsonByObject
}