import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { OriginatorPaymentInstitutionDetail } from 'model/originator-payment-institution';
import { useTranslation } from 'react-i18next';

export interface OriginatorPaymentInstitutionCardDetailProps {
    originatorPaymentInstitution: OriginatorPaymentInstitutionDetail;
}

const I18N_PREFIX = 'pages.scd.originator.input.originatorPaymentInstitution.input';

export const OriginatorPaymentInstitutionCardDetail = (props: OriginatorPaymentInstitutionCardDetailProps): JSX.Element => {
    const { originatorPaymentInstitution } = props;
    const { t } = useTranslation();

    return (
        <div>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={originatorPaymentInstitution?.name} />
            <ReadOnlyTextField
                label={t(`${I18N_PREFIX}.status.label`)}
                value={t(`${I18N_PREFIX}.status.options.${originatorPaymentInstitution.status}`)}
            />
            <ReadOnlyTextField
                label={t(`${I18N_PREFIX}.technicalSupplier.title`)}
                value={originatorPaymentInstitution?.technicalSupplierName}
            />
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.account.label`)} value={originatorPaymentInstitution.accountName} />
        </div>
    );
};
