import { ImportedOperationsToBatchCreation } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsToCreateBatchTypes {
    IMPORTED_OPERATIONS_TO_CREATE_BATCH_REQUEST = '@@imported-operations-to-create-batch/IMPORTED_OPERATIONS_TO_CREATE_BATCH_REQUEST',
    IMPORTED_OPERATIONS_TO_CREATE_BATCH_SUCCESS = '@@imported-operations-to-create-batch/IMPORTED_OPERATIONS_TO_CREATE_BATCH_SUCCESS',
    IMPORTED_OPERATIONS_TO_CREATE_BATCH_ERROR = '@@imported-operations-to-create-batch/IMPORTED_OPERATIONS_TO_CREATE_BATCH_ERROR',
    IMPORTED_OPERATIONS_TO_CREATE_BATCH_RESET_STATE = '@@imported-operations-to-create-batch/IMPORTED_OPERATIONS_TO_CREATE_BATCH_RESET_STATE',
}

export interface ImportedOperationsToCreateBatchState extends BaseStoreState {
    readonly operationsToCreateBatch?: ImportedOperationsToBatchCreation;
}
