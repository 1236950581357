import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentValueToGetTransaction } from 'model/payment-favored';
import { useTranslation } from 'react-i18next';

interface PaymentValueCardProps {
    paymentValue: PaymentValueToGetTransaction | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentValueCard = (props: PaymentValueCardProps) => {
    const { paymentValue } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentValue.label`)}>
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentValue.type`}
                value={t(`${I18N_PREFIX}.inputs.paymentValue.options.${paymentValue?.favoredType}`)}
            />
        </ReadOnlyCard>
    );
};

export default PaymentValueCard;
