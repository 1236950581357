import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchDocumentDownloadState, BatchDocumentDownloadTypes } from 'reducer/batch/batch-document-download/types';

const initialState: BatchDocumentDownloadState = {
    status: HttpRequestStatus.NOOP,
    batchDocumentDownload: undefined,
    error: undefined
}

const reducer: Reducer<BatchDocumentDownloadState> = (state = initialState, action): BatchDocumentDownloadState => {
    switch (action.type) {
        case BatchDocumentDownloadTypes.BATCH_DOCUMENT_DOWNLOAD_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchDocumentDownloadTypes.BATCH_DOCUMENT_DOWNLOAD_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchDocumentDownload: action.payload, error: undefined };
        }
        case BatchDocumentDownloadTypes.BATCH_DOCUMENT_DOWNLOAD_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchDocumentDownloadTypes.BATCH_DOCUMENT_DOWNLOAD_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchDocumentDownloadReducer };