import { ScdBatchExpirationsReport } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchExpirationsReportTypes {
    BATCH_EXPIRATIONS_REPORT_REQUEST = '@@batch-expirations-report/BATCH_EXPIRATIONS_REPORT_REQUEST',
    BATCH_EXPIRATIONS_REPORT_SUCCESS = '@@batch-expirations-report/BATCH_EXPIRATIONS_REPORT_SUCCESS',
    BATCH_EXPIRATIONS_REPORT_ERROR = '@@batch-expirations-report/BATCH_EXPIRATIONS_REPORT_ERROR',
    BATCH_EXPIRATIONS_REPORT_RESET = '@@batch-expirations-report/BATCH_EXPIRATIONS_REPORT_RESET',
}

export interface BatchExpirationsReportState extends BaseStoreState {
    readonly batchExpirationsReport?: ScdBatchExpirationsReport;
}
