import { GiroWebappError } from 'model/error';
import { PaymentTransactionDetail, } from 'model/payment-transaction';
import { DetailPaymentTransactionTypes } from 'reducer/payment-transaction/detail-payment-transaction/types';
import { action } from 'typesafe-actions';

export const detailPaymentTransactionRequest = (paymentTransactionId: string) =>
    action(DetailPaymentTransactionTypes.DETAIL_PAYMENT_TRANSACTION_REQUEST, paymentTransactionId);
export const detailPaymentTransactionSuccess = (paymentTransaction: PaymentTransactionDetail) =>
    action(DetailPaymentTransactionTypes.DETAIL_PAYMENT_TRANSACTION_SUCCESS, paymentTransaction);
export const detailPaymentTransactionError = (error: GiroWebappError) =>
    action(DetailPaymentTransactionTypes.DETAIL_PAYMENT_TRANSACTION_ERROR, error);
export const detailPaymentTransactionResetState = () =>
    action(DetailPaymentTransactionTypes.DETAIL_PAYMENT_TRANSACTION_RESET);
