import { AxiosResponse } from 'axios';
import { ScdBatchDetail } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { detailBatchError, detailBatchRequest, detailBatchSuccess } from 'reducer/batch/detail-batch/actions';
import { DetailBatchTypes } from 'reducer/batch/detail-batch/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailBatch(action: ReturnType<typeof detailBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchDetail> = yield call(batchAPI.detailBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailBatchError(mapped));
            return;
        }
        yield put(detailBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailBatchError(mapped));
    }
}

function* watchDetailBatch() {
    yield takeEvery(DetailBatchTypes.DETAIL_BATCH_REQUEST, handleDetailBatch);
}

function* detailBatchSaga() {
    yield all([fork(watchDetailBatch)]);
}

export default detailBatchSaga;
