import { FormControl, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import _ from 'lodash';
import { ChangeEvent } from 'react';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;

export interface SimpleTextAreaInputProps extends Base {
    value?: string;
    placeholder?: string;
    onChange: (value?: string) => void;
}

const SimpleTextArea = (props: SimpleTextAreaInputProps) => {
    const { value: propsValue, label, placeholder, onChange, disabled } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) return;

        const _value = event?.target?.value;

        if (_.isEmpty(_.trim(_value)) && onChange) {
            onChange(undefined);
            return;
        }

        onChange(_value);
    };

    return (
        <FormControl className={clsx('base-input', { dirty: propsValue })}>
            <TextField
                label={label}
                placeholder={placeholder}
                multiline
                rows={5}
                variant="filled"
                value={propsValue}
                onChange={handleChange}
                disabled={disabled}
            />
        </FormControl>
    );
};

export default SimpleTextArea;
