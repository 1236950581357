import LinkButton from 'components/buttons/link-button/link-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import CreatePaymentTransactionInSeries from 'entities/payment-transaction/payment-transaction-in-series/components/create-payment-transaction-in-series/create-payment-transaction-in-series';
import DetailPaymentTransactionInSeries from 'entities/payment-transaction/payment-transaction-in-series/components/detail-payment-transaction-in-series/detail-payment-transaction-in-series';
import PaymentTransactionInSeriesModalError from 'entities/payment-transaction/payment-transaction-in-series/components/payment-transaction-in-series-modal-error/payment-transaction-in-series-modal-error';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdFavoredBankAccount } from 'model/favored-bank-account';
import { defaultPaymentTransactionInSeriesCreate, ScdPaymentTransactionInSeriesToCreate } from 'model/payment-transaction-in-series';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useCreatePaymentTransactionsInSeriesState, useRootDispatch } from 'reducer/hooks';
import {
    createPaymentTransactionsInSeriesRequest,
    createPaymentTransactionsInSeriesResetState,
} from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/actions';
import { useToast } from 'shared/hooks/use-toast';
import './payment-transaction-in-series.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction-in-series';

export interface ScdPaymentTransactionInSeriesCreate extends ScdPaymentTransactionInSeriesToCreate {
    bankAccountSelected?: ScdFavoredBankAccount | undefined;
    favoredName?: string;
    favoredIdentification?: string;
}

type ModalMessageType = 'send' | 'back' | 'cancel';

const ScdPaymentTransactionInSeries = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [newPaymentTransactionsList, setNewPaymentTransactionsList] = useState<ScdPaymentTransactionInSeriesCreate[]>([]);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [modalMessage, setModalMessage] = useState<ModalMessageType | undefined>(undefined);
    const [openModalError, setOpenModalError] = useState<boolean>(false);

    const { toastSuccess, toastLoading, closeToast } = useToast();

    const { status: createStatus, paymentTransactionsInSeriesErrors: paymentTransactionsInSerieErrors } =
        useCreatePaymentTransactionsInSeriesState();

    const handleCreatePaymentTransaction = () => {
        dispatch(createPaymentTransactionsInSeriesRequest({ paymentTransactions: newPaymentTransactionsList }));
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        history.push('/payment-transactions');
        dispatch(createPaymentTransactionsInSeriesResetState());
    };

    useEffect(() => {
        if (createStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
            return;
        }

        if (createStatus === HttpRequestStatus.ERROR) {
            closeToast();
            return;
        }

        if (createStatus === HttpRequestStatus.SUCCESS) {
            setModalMessage(undefined);

            if (paymentTransactionsInSerieErrors?.errors.length === 0) {
                closeToast();
                setNewPaymentTransactionsList([]);
                toastSuccess(t('pages.scd.payment-transaction.new-transaction.toast.create', { count: newPaymentTransactionsList.length }));
                history.push('/payment-transactions');
                dispatch(createPaymentTransactionsInSeriesResetState());
                return;
            }

            closeToast();

            setOpenModalError(true);
        }
    }, [
        createStatus,
        toastLoading,
        closeToast,
        toastSuccess,
        dispatch,
        history,
        paymentTransactionsInSerieErrors,
        t,
        newPaymentTransactionsList.length,
    ]);

    useEffect(() => {
        const _lastIndex = newPaymentTransactionsList.length + 1;
        setActiveIndex(_lastIndex);
    }, [newPaymentTransactionsList]);

    const totalAmountPaymentTransaction: number | undefined =
        newPaymentTransactionsList.length === 0
            ? 0
            : newPaymentTransactionsList.map(({ amount }) => (amount !== undefined ? amount : 0)).reduce((acc, value) => value + acc);

    const disabledButton = createStatus === HttpRequestStatus.ON_GOING;

    const modalTitle = {
        send: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`, {
            transactions: newPaymentTransactionsList.length,
            count: newPaymentTransactionsList.length,
        }),
        back: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`),
        cancel: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`),
    } as Record<ModalMessageType, string>;

    const modalOnAction = {
        send: () => {
            handleCreatePaymentTransaction();
        },
        cancel: () => {
            setModalMessage(undefined);
            setNewPaymentTransactionsList([]);
        },
        back: () => {
            setModalMessage(undefined);
            history.push('/payment-transactions');
            setNewPaymentTransactionsList([]);
        },
    } as Record<ModalMessageType, () => void>;

    return (
        <>
            <main className="scd-payment-transaction-in-series">
                <ContextRibbon
                    action={() =>
                        newPaymentTransactionsList.length === 0 ? history.push('/payment-transactions') : setModalMessage('back')
                    }
                />
                <section className="scd-payment-transaction-in-series__container">
                    <header className="scd-payment-transaction-in-series__header">
                        <h2 className="scd-payment-transaction-in-series__header--title">{t(`${I18N_PREFIX}.title-legacy`)}</h2>
                    </header>
                    <div className="scd-payment-transaction-in-series__content">
                        {newPaymentTransactionsList.length !== 0 &&
                            newPaymentTransactionsList.map((value, key) => {
                                return (
                                    <DetailPaymentTransactionInSeries
                                        detailPaymentTransaction={value}
                                        newPaymentTransactionsList={newPaymentTransactionsList}
                                        setNewPaymentTransactionsList={setNewPaymentTransactionsList}
                                        index={key}
                                        key={key}
                                    />
                                );
                            })}
                    </div>
                    <CreatePaymentTransactionInSeries
                        defaultPaymentTransactionValue={defaultPaymentTransactionInSeriesCreate}
                        newPaymentTransactionsList={newPaymentTransactionsList}
                        setNewPaymentTransactionsList={setNewPaymentTransactionsList}
                        index={activeIndex}
                        disabledButton={disabledButton}
                    />
                    <div className="scd-payment-transaction-in-series__content-slide">
                        <div className="scd-payment-transaction-in-series__content-slide--container">
                            <div className="scd-payment-transaction-in-series__content-slide--item">
                                <div className="scd-payment-transaction-in-series__content-slide--item__title">
                                    {`${activeIndex - 1} ${t(`${I18N_PREFIX}.slide.transfer`, {
                                        count: activeIndex - 1,
                                    }).toLocaleLowerCase()}`}
                                </div>
                                <div className="scd-payment-transaction-in-series__content-slide--item__value">
                                    <strong>
                                        <CurrencyFormatter value={totalAmountPaymentTransaction} prefix />
                                    </strong>
                                </div>
                            </div>
                            <div className="scd-payment-transaction-in-series__content-slide--buttons">
                                <LinkButton
                                    onClick={() =>
                                        newPaymentTransactionsList.length === 0
                                            ? history.push('/payment-transactions')
                                            : setModalMessage('cancel')
                                    }
                                    outlinedStyle
                                    label={t('entity.action.cancel')}
                                    disabled={disabledButton}
                                />
                                <span style={{ marginLeft: '12px', width: '145px' }}>
                                    <LinkButton
                                        label={t('entity.action.create')}
                                        onClick={() => setModalMessage('send')}
                                        disabled={disabledButton || newPaymentTransactionsList.length === 0}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {modalMessage && (
                <ModalMessageLegacy
                    title={modalTitle[modalMessage]}
                    message={t(`${I18N_PREFIX}.modal-message.${modalMessage}.message`, {
                        count: newPaymentTransactionsList.length,
                    })}
                    onClose={() => setModalMessage(undefined)}
                    onAction={modalOnAction[modalMessage]}
                    onCancel={() => setModalMessage(undefined)}
                    standardButtonLabel={`entity.action.${modalMessage}`}
                    outlinedButtonLabel={
                        modalMessage === 'send' || modalMessage === 'cancel' ? 'entity.action.back' : 'entity.action.cancel'
                    }
                    disabled={disabledButton}
                />
            )}
            {openModalError && (
                <PaymentTransactionInSeriesModalError
                    title={t(`${I18N_PREFIX}.modal-message.error.title`, { count: paymentTransactionsInSerieErrors?.errors.length })}
                    onClose={handleCloseModalError}
                    onAction={handleCloseModalError}
                    errors={paymentTransactionsInSerieErrors?.errors ?? []}
                    paymentTransactions={newPaymentTransactionsList ?? []}
                />
            )}
        </>
    );
};

export default ScdPaymentTransactionInSeries;
