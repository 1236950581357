import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { BankModalFilterType } from 'entities/bank/components/bank-modal-filter/bank-modal-filter';
import { BankFilterRequest } from 'model/bank';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.bank.filter.buttons';

interface BankFilterButtonsProps {
    setModalFilter: (value: BankModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: BankFilterRequest;
    handleResetFilterActive: () => void;
}

export const BankFilterButtons = (props: BankFilterButtonsProps) => {
    const { setModalFilter, setOpenModalFilter, filterActive, handleResetFilterActive } = props;

    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isActiveFilter = valuesOfObject({ ...filterActive }).some(Boolean);

    return (
        <>
            <div className="scd-bank__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={isActiveFilter}
                />
            </div>
            <div className="scd-bank__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('name');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.name`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.name}
                />
            </div>
            <div className="scd-bank__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('number');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.number`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.number}
                />
            </div>
            <div className="scd-bank__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('identification');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.identification`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={!!filterActive.identification}
                />
            </div>
            {isActiveFilter && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-bank__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default BankFilterButtons;
