import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Policies
import { listIssuerPoliciesReducer } from 'reducer/issuer-policy/list-issuer-policies/reducer';
import listIssuerPoliciesSaga from 'reducer/issuer-policy/list-issuer-policies/sagas';
import { ListIssuerPoliciesState } from 'reducer/issuer-policy/list-issuer-policies/types';

// Create Policy
import { createIssuerPolicyReducer } from 'reducer/issuer-policy/create-policy/reducer';
import createIssuerPolicySaga from 'reducer/issuer-policy/create-policy/sagas';
import { CreateIssuerPolicyState } from 'reducer/issuer-policy/create-policy/types';

// Update Policy
import { updateIssuerPolicyReducer } from 'reducer/issuer-policy/update-policy/reducer';
import updateIssuerPolicySaga from 'reducer/issuer-policy/update-policy/sagas';
import { UpdateIssuerPolicyState } from 'reducer/issuer-policy/update-policy/types';

// Detail Policy
import { detailIssuerPolicyReducer } from 'reducer/issuer-policy/detail-policy/reducer';
import detailIssuerPolicySaga from 'reducer/issuer-policy/detail-policy/sagas';
import { DetailIssuerPolicyState } from 'reducer/issuer-policy/detail-policy/types';

// Integration create
import { createIssuerPolicyIntegrationReducer } from 'reducer/issuer-policy/create-integration/reducer';
import createIssuerPolicyIntegrationSaga from 'reducer/issuer-policy/create-integration/sagas';
import { CreateIntegrationIssuerPolicyState } from 'reducer/issuer-policy/create-integration/types';

// Integration Update
import { updateIssuerPolicyIntegrationReducer } from 'reducer/issuer-policy/update-integration/reducer';
import updateIssuerPolicyIntegrationSaga from 'reducer/issuer-policy/update-integration/sagas';
import { UpdateIntegrationIssuerPolicyState } from 'reducer/issuer-policy/update-integration/types';

// Active Policies Summary
import { activePoliciesSummaryReducer } from 'reducer/issuer-policy/active-policies-summary/reducer';
import activePoliciesSummarySaga from 'reducer/issuer-policy/active-policies-summary/sagas';
import { ActivePoliciesSummaryState } from 'reducer/issuer-policy/active-policies-summary/types';

export interface IssuerPolicyState {
    readonly listPoliciesIssuer: ListIssuerPoliciesState;
    readonly createPolicyIssuer: CreateIssuerPolicyState;
    readonly detailPolicyIssuer: DetailIssuerPolicyState;
    readonly updatePolicyIssuer: UpdateIssuerPolicyState;
    readonly integrationCreate: CreateIntegrationIssuerPolicyState;
    readonly integrationUpdate: UpdateIntegrationIssuerPolicyState;
    readonly activePoliciesSummary: ActivePoliciesSummaryState;
}

export const IssuerPolicyReducer: ReducersMapObject<IssuerPolicyState, AnyAction> = {
    listPoliciesIssuer: listIssuerPoliciesReducer,
    createPolicyIssuer: createIssuerPolicyReducer,
    detailPolicyIssuer: detailIssuerPolicyReducer,
    updatePolicyIssuer: updateIssuerPolicyReducer,
    integrationCreate: createIssuerPolicyIntegrationReducer,
    integrationUpdate: updateIssuerPolicyIntegrationReducer,
    activePoliciesSummary: activePoliciesSummaryReducer,
}

export function* issuerPolicySagas() {
    yield all([
        fork(listIssuerPoliciesSaga),
        fork(createIssuerPolicySaga),
        fork(detailIssuerPolicySaga),
        fork(updateIssuerPolicySaga),
        fork(createIssuerPolicyIntegrationSaga),
        fork(updateIssuerPolicyIntegrationSaga),
        fork(activePoliciesSummarySaga),
    ])
}