import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionsInSeriesErrorsList, ScdPaymentTransactionsInSeriesToCreateList } from 'model/payment-transaction-in-series';
import { CreatePaymentTransactionsInSeriesTypes } from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/types';
import { action } from 'typesafe-actions';

export const createPaymentTransactionsInSeriesRequest = (request: ScdPaymentTransactionsInSeriesToCreateList) =>
    action(CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_REQUEST, request);
export const createPaymentTransactionsInSeriesSuccess = (paymentTransactionInSeriesErrors: ScdPaymentTransactionsInSeriesErrorsList) =>
    action(CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_SUCCESS, paymentTransactionInSeriesErrors);
export const createPaymentTransactionsInSeriesError = (error: GiroWebappError) =>
    action(CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSATIONS_IN_SERIES_ERROR, error);
export const createPaymentTransactionsInSeriesResetState = () =>
    action(CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_RESET);
