import { AxiosResponse } from 'axios';
import { BatchOutputSignaturesResume } from 'model/batch-signature';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchSignatureResumeError, batchSignatureResumeRequest, batchSignatureResumeSuccess } from 'reducer/batch/batch-signature-resume/action';
import { BatchSignatureResumeTypes } from 'reducer/batch/batch-signature-resume/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchSignatureApi from 'services/api/batch-signature-api';
import ErrorUtils from 'shared/error/error-utils';


function* handleBatchSignatureResume(action: ReturnType<typeof batchSignatureResumeRequest>) {
    try {
        const result: AxiosResponse<BatchOutputSignaturesResume> = yield call(batchSignatureApi.resumeAllByBatchOutput, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchSignatureResumeError(mapped));
            return;
        }
        yield put(batchSignatureResumeSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchSignatureResumeError(mapped));
    }
}

function* watchBatchSignatureResume() {
    yield takeLeading(BatchSignatureResumeTypes.BATCH_SIGNATURE_RESUME_REQUEST, handleBatchSignatureResume);
}

function* batchSignatureResumeSaga() {
    yield all([fork(watchBatchSignatureResume)]);
}

export default batchSignatureResumeSaga;