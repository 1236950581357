import { TaxType } from 'model/enums/tax-type';

export interface ScdTaxSimple {
    id: number;
}

export interface ScdTaxList {
    id: number;
    value: number;
    date: Date;
    type: TaxType;
}

export interface ScdTaxToCreate {
    value?: number;
    date?: string;
    type?: TaxType;
}

export const defaultScdTaxToCreate: Readonly<ScdTaxToCreate> = {};
