import { AxiosResponse } from 'axios';
import { ScdFavoredBankAccount, ScdFavoredBankAccountRequest } from 'model/favored-bank-account';
import { api } from 'services/api/api';

const favoredBankAccount = () => {
    const searchFavoredBankAccounts = (request: ScdFavoredBankAccountRequest): Promise<AxiosResponse<ScdFavoredBankAccount[]>> => {
        return api.get<ScdFavoredBankAccount[]>(`api/bank-accounts/favored/${request.type}/${request.id}`);
    };

    return {
        searchFavoredBankAccounts,
    };
};

export default favoredBankAccount();
