import { PaymentInstitutionAccountModalFilterType } from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-modal-filter/payment-institutions-accounts-modal-filter';
import { PaymentInstitutionAccountFilterRequest } from 'model/payment-institution-account';
import { useTranslation } from 'react-i18next';
import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterButton from 'components/buttons/filter-button/filter-button';
import FilterListIcon from '@material-ui/icons/FilterList';
import { valuesOfObject } from 'shared/util/object-utils';
import { Tooltip } from '@material-ui/core';

interface PaymentInstitutionAccountsFilterButtonsProps {
    filterActive: PaymentInstitutionAccountFilterRequest;
    setIsOpenModalFilter: (value: boolean) => void;
    setModalFilter: (value: PaymentInstitutionAccountModalFilterType | undefined) => void;
    handleResetFilterActive: () => void;
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.filter.buttons';

const PaymentInstitutionAccountsFilterButtons = (props: PaymentInstitutionAccountsFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setIsOpenModalFilter, setModalFilter } = props;

    const { t } = useTranslation();

    const ArrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isFilterActive: boolean = valuesOfObject({ ...filterActive }).some(it => !!it);

    const handleModalFilter = (value: PaymentInstitutionAccountModalFilterType) => () => {
        setIsOpenModalFilter(true);
        setModalFilter(value);
    };

    return (
        <>
            <div className="scd-payment-institutions-accounts__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFilterActive}
                    onClick={handleModalFilter('filters')}
                />
            </div>
            <div className="scd-payment-institutions-accounts__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.name`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.name}
                    onClick={handleModalFilter('name')}
                />
            </div>
            <div className="scd-payment-institutions-accounts__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.status}
                    onClick={handleModalFilter('status')}
                />
            </div>
            <div className="scd-payment-institutions-accounts__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.type`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.type}
                    onClick={handleModalFilter('type')}
                />
            </div>
            <div className="scd-payment-institutions-accounts__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.technicalSupplier`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.technicalSupplierId}
                    onClick={handleModalFilter('technicalSupplier')}
                />
            </div>
            {isFilterActive && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div
                        className="scd-payment-institutions-accounts__header--buttons-content-error-arrow"
                        onClick={handleResetFilterActive}
                    />
                </Tooltip>
            )}
        </>
    );
};

export default PaymentInstitutionAccountsFilterButtons;
