export enum ChunkStreamType {
    CESSION = 'CESSION',
}
export enum ChunkStrategy {
    GTSEC_SIMPLE_CHUNK = 'GTSEC_SIMPLE_CHUNK',
}

export enum DataChunkType {
    CESSION = 'CESSION',
}

export enum ChunkStreamStatus {
    PENDENT = 'PENDENT',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    PARTIAL = 'PARTIAL',
}

export enum DataChunkStatus {
    PENDENT = 'PENDENT',
    PROCESSING = 'PROCESSING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    PARTIAL = 'PARTIAL',
}

export enum ExportRequestStatus {
    PENDENT = 'PENDENT',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum BatchExportType {
    BATCH_DOCUMENT = 'BATCH_DOCUMENT',
    BATCH_SIGNATURE = 'BATCH_SIGNATURE',
    STREAM_OPERATIONS = 'STREAM_OPERATIONS',
    STREAM_INSTALLMENTS = 'STREAM_INSTALLMENTS',
    CHUNK_OPERATIONS = 'CHUNK_OPERATIONS',
    CHUNK_INSTALLMENTS = 'CHUNK_INSTALLMENTS',
}

export enum ExportResultStatus {
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum ExportRequestType {
    STREAM_OPERATIONS = 'STREAM_OPERATIONS',
    STREAM_INSTALLMENTS = 'STREAM_INSTALLMENTS',
    CHUNK_OPERATIONS = 'CHUNK_OPERATIONS',
    CHUNK_INSTALLMENTS = 'CHUNK_INSTALLMENTS',
    CESSION = 'CESSION',
    CESSION_OPERATION = 'CESSION_OPERATION',
}
