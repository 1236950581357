import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdOriginator from 'entities/originator/originator';
import ScdOriginatorCreateUpdate from 'entities/originator/originator-create-update/originator-create-update';
import ScdOriginatorDetailPage from 'entities/originator/originator-detail/originator-detail';
import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const OriginatorRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdOriginatorCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:originatorId`} component={ScdOriginatorDetailPage} />
        <PrivateRoute exact path={`${match.url}/:originatorId/edit`} component={ScdOriginatorCreateUpdate} />

        <PrivateRoute exact path={`${match.url}`} component={ScdOriginator} />
    </Switch>
);

export default OriginatorRoutes;
