import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdOriginatorList } from 'model/originator';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface OriginatorsRowProps {
    originators: ScdOriginatorList[];
}

const OriginatorsRow = (props: ListChildComponentProps<OriginatorsRowProps>) => {
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { originators } = data;

    const { name, identification, paymentInstitution, id } = originators[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name ?? '-'}</td>
            <td className="page-container--table-cell-title">{identification ?? '-'}</td>
            <td className="page-container--table-cell-title">{paymentInstitution?.name ?? '-'}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default OriginatorsRow;
