import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail.validation.description';

const VALIDATE_MANUAL_DISBURSEMENT_DESCRIPTION: Validations<string | undefined | null> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.maxLength`

    }
}

export const validateManualDisbursementDescription = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_DESCRIPTION)
