import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Issuers
import { listIssuersReducer } from 'reducer/issuer/list-issuers/reducer';
import listIssuersSaga from 'reducer/issuer/list-issuers/sagas';
import { ListIssuersState } from 'reducer/issuer/list-issuers/types';

// Search Issuers
import { searchIssuersReducer } from 'reducer/issuer/search-issuers/reducer';
import searchIssuersSaga from 'reducer/issuer/search-issuers/sagas';
import { SearchIssuersState } from 'reducer/issuer/search-issuers/types';

// Create Issuer
import { createIssuerReducer } from 'reducer/issuer/create-issuer/reducer';
import createIssuerSaga from 'reducer/issuer/create-issuer/sagas';
import { CreateIssuerState } from 'reducer/issuer/create-issuer/types';

// Update Issuer
import { updateIssuerReducer } from 'reducer/issuer/update-issuer/reducer';
import updateIssuerSaga from 'reducer/issuer/update-issuer/sagas';
import { UpdateIssuerState } from 'reducer/issuer/update-issuer/types';

// Detail Issuer
import { detailIssuerReducer } from 'reducer/issuer/detail-issuer/reducer';
import detailIssuerSaga from 'reducer/issuer/detail-issuer/sagas';
import { DetailIssuerState } from 'reducer/issuer/detail-issuer/types';

export interface IssuerState {
    readonly listIssuers: ListIssuersState;
    readonly createIssuer: CreateIssuerState;
    readonly updateIssuer: UpdateIssuerState;
    readonly detailIssuer: DetailIssuerState;
    readonly searchIssuers: SearchIssuersState;
}

export const IssuerReducer: ReducersMapObject<IssuerState, AnyAction> = {
    listIssuers: listIssuersReducer,
    createIssuer: createIssuerReducer,
    updateIssuer: updateIssuerReducer,
    detailIssuer: detailIssuerReducer,
    searchIssuers: searchIssuersReducer,
};

export function* issuerSagas() {
    yield all([
        fork(listIssuersSaga), 
        fork(createIssuerSaga), 
        fork(updateIssuerSaga), 
        fork(detailIssuerSaga), 
        fork(searchIssuersSaga), 
    ]);
}
