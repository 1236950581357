import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyItem } from 'model/company-item';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListCompanyItemsTypes {
    LIST_COMPANY_ITEMS_REQUEST = '@@company-items-list/LIST_COMPANY_ITEMS_REQUEST',
    LIST_COMPANY_ITEMS_SUCCESS = '@@company-items-list/LIST_COMPANY_ITEMS_SUCCESS',
    LIST_COMPANY_ITEMS_ERROR = '@@company-items-list/LIST_COMPANY_ITEMS_ERROR',
    LIST_COMPANY_ITEMS_RESET = '@@company-items-list/LIST_COMPANY_ITEMS_RESET',
}

export interface ListCompanyItemsState extends BaseStoreState {
    readonly companyItems?: Page<ScdCompanyItem>;
}
