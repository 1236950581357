import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ScdBankerHeader, { ScdBankerListType } from 'entities/banker/components/banker-header/banker-header';
import BankersList from 'entities/banker/components/bankers-list/bankers-list';
import { ScdBankerList } from 'model/banker';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listBankersRequest } from 'reducer/banker/list-bankers/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './banker.scss';

const I18N_PREFIX = 'pages.scd.banker';

const ScdBankerPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const history = useHistory();
    const { url } = useRouteMatch();

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScrollLegacy<ScdBankerList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listBankers.bankers,
        statusSelector: (state: IRootState) => state.listBankers.status,
        action: listBankersRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleSort = (property: ScdBankerListType, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.banker.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-banker">
            <section className="scd-banker__container">
                <header className="scd-banker__header">
                    <h2 className="scd-banker__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="scd-banker__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdBankerHeader
                                bankers={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <BankersList bankers={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default ScdBankerPage;
