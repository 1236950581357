import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListMonthlyCommissionsTypes } from 'reducer/monthly-commission/list-monthly-commissions/types';
import {
    listMonthlyCommissionsError,
    listMonthlyCommissionsSuccess,
    listMonthlyCommissionsRequest,
} from 'reducer/monthly-commission/list-monthly-commissions/actions';
import { Page } from 'services/page';
import { ScdMonthlyCommissionData } from 'model/monthly-commission';

function* handleListMonthlyCommissions(action: ReturnType<typeof listMonthlyCommissionsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdMonthlyCommissionData>> = yield call(
            monthlyCommissionAPI.listMonthlyCommissions,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listMonthlyCommissionsError(mapped));
            return;
        }
        yield put(listMonthlyCommissionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listMonthlyCommissionsError(mapped));
    }
}

function* watchListMonthlyCommissions() {
    yield takeEvery(ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_REQUEST, handleListMonthlyCommissions);
}

function* listMonthlyCommissionsSaga() {
    yield all([fork(watchListMonthlyCommissions)]);
}

export default listMonthlyCommissionsSaga;
