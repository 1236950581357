import { GiroWebappError } from 'model/error';
import { ProgramGroupsRequest, ProgramGroupsToGetAll } from 'model/program-groups';
import { ListProgramGroupsTypes } from 'reducer/program-groups/list-program-groups/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listProgramGroupsRequest = (request: ProgramGroupsRequest) => action(ListProgramGroupsTypes.LIST_PROGRAM_GROUPS_REQUEST, request);
export const listProgramGroupsSuccess = (programGroups: Page<ProgramGroupsToGetAll>) => action(ListProgramGroupsTypes.LIST_PROGRAM_GROUPS_SUCCESS, programGroups);
export const listProgramGroupsError = (error: GiroWebappError) => action(ListProgramGroupsTypes.LIST_PROGRAM_GROUPS_ERROR, error);
export const listProgramGroupsResetState = () => action(ListProgramGroupsTypes.LIST_PROGRAM_GROUPS_RESET_STATE);
