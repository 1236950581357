import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './error-toast.scss';

export interface ErrorToastProps {
    open: boolean;
    message: string;
    messageParams?: { [key: string]: string };
    actionLabel?: string;
    onClose: () => void;
    onAction: () => void;
}

export const ErrorToast: React.FunctionComponent<ErrorToastProps> = props => {
    const { t } = useTranslation();
    const { message, messageParams, actionLabel } = props;

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            open={props.open}
            message={t(message, messageParams)}
            onClose={props.onClose}
        >
            <>
                <div className="error-toast__itens">
                    <div className="error-toast__itens--img" />
                    <span className="error-toast__itens--text">{t(message)}</span>
                    <button className="error-toast__itens--button" onClick={props.onAction}>
                        {t(actionLabel ?? 'global.closeError').toUpperCase()}
                    </button>
                    <div className="error-toast__itens--close" onClick={props.onClose} />
                </div>
            </>
        </Snackbar>
    );
};

export default ErrorToast;
