import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';

type BankNumberInputProps = Omit<BaseTextInputProps, 'error' | 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;

const INVALID_BANK_NUMBER_CHARACTERS_REGEX: RegExp = /[^\d-]+/g;

export const BankNumberInput = (props: BankNumberInputProps) => {
    const { type, onChange, ...otherProps } = props;

    const handleChange = (value: string) => {
        const number = value?.toLowerCase().replace(INVALID_BANK_NUMBER_CHARACTERS_REGEX, '');
        if (!onChange || number.length > 6) return;

        onChange(number);
    };

    return (
        <FormControl fullWidth className={clsx('base-input', { dirty: props.value })}>
            <BaseTextInput type={type ?? 'text'} onChange={handleChange} {...otherProps} hasExternalSanitization />
        </FormControl>
    );
};

export default BankNumberInput;
