import { AxiosError, AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { BadRequestError, ForbiddenError, GiroScdError, GiroWebappError, UnauthorizedError, UnknownError } from 'model/error';
import AuthUtil from 'services/api/auth-util';
import LoggingUtils from 'shared/util/logging-utils';

const mapLocalError = (error: any): GiroWebappError => {
    if ((error as AxiosError).response) {
        const response = (error as AxiosError).response;
        const value: GiroScdError = {
            httpStatus: response?.status,
            message: response?.data?.message,
            code: response?.data?.code,
            fieldErrors: response?.data?.fieldErrors,
            detail: response?.data?.error_description,
        };
        return mapHttpError(value);
    }
    if (error instanceof Error && error.stack) {
        LoggingUtils.debugInfo(error);
    }
    return UnknownError;
};

const mapGiroScdError = (response: AxiosResponse): GiroScdError => {
    const value: GiroScdError = {
        httpStatus: response.status,
        message: response.data?.message,
        code: response.data?.code,
        fieldErrors: response.data?.fieldErrors,
        detail: response.data?.error_description,
    };
    return mapHttpError(value);
};

const mapHttpError = (error: GiroScdError): GiroScdError => {
    if (error?.httpStatus === HttpStatus.UNAUTHORIZED) {
        AuthUtil.removeToken();
        return UnauthorizedError;
    }
    if (error?.httpStatus === HttpStatus.FORBIDDEN) {
        return ForbiddenError;
    }
    if (error?.httpStatus === HttpStatus.BAD_REQUEST) {
        if (!!error?.message) {
            return error;
        }
        return BadRequestError;
    }
    return error;
};



export const ErrorUtils = {
    mapLocalError,
    mapGiroScdError: mapGiroScdError,
};

export default ErrorUtils;
