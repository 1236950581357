import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateProgramPolicyTypes, UpdateProgramPolicyState } from 'reducer/program-policy/update-program-policy/types';
import { Reducer } from 'redux';

export const initialState: UpdateProgramPolicyState = {
    status: HttpRequestStatus.NOOP,
    programPolicy: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateProgramPolicyState> = (state = initialState, action): UpdateProgramPolicyState => {
    switch (action.type) {
        case UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, programPolicy: action.payload, error: undefined };
        }
        case UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateProgramPolicyReducer };
