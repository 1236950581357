import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Legacy Create Program
import { legacyCreateProgramReducer } from 'reducer/program/legacy-create-program/reducer';
import legacyCreateProgramSaga from 'reducer/program/legacy-create-program/sagas';
import { LegacyCreateProgramState } from 'reducer/program/legacy-create-program/types';

// Detail Program
import { detailProgramReducer } from 'reducer/program/detail-program/reducer';
import detailProgramSaga from 'reducer/program/detail-program/sagas';
import { DetailProgramState } from 'reducer/program/detail-program/types';

// List Programs
import { listProgramsReducer } from 'reducer/program/list-programs/reducer';
import listProgramsSaga from 'reducer/program/list-programs/sagas';
import { ListProgramsState } from 'reducer/program/list-programs/types';

// Legacy Update Program
import { legacyUpdateProgramReducer } from 'reducer/program/legacy-update-program/reducer';
import legacyUpdateProgramSaga from 'reducer/program/legacy-update-program/sagas';
import { LegacyUpdateProgramState } from 'reducer/program/legacy-update-program/types';

// Search Programs
import { searchProgramsReducer } from 'reducer/program/search-programs/reducer';
import searchProgramsSaga from 'reducer/program/search-programs/sagas';
import { SearchProgramsState } from 'reducer/program/search-programs/types';

// Create Program
import { createProgramReducer } from 'reducer/program/create-program/reducer';
import { CreateProgramState } from 'reducer/program/create-program/types';
import createProgramSaga from 'reducer/program/create-program/sagas';

// Update Program
import { updateProgramReducer } from 'reducer/program/update-program/reducer';
import { UpdateProgramState } from 'reducer/program/update-program/types';
import updateProgramSaga from 'reducer/program/update-program/sagas';


export interface ProgramState {
    readonly legacyCreateProgram: LegacyCreateProgramState;
    readonly detailProgram: DetailProgramState;
    readonly listPrograms: ListProgramsState;
    readonly legacyUpdateProgram: LegacyUpdateProgramState;
    readonly searchPrograms: SearchProgramsState;
    readonly createProgram: CreateProgramState;
    readonly updateProgram: UpdateProgramState;
}

export const ProgramReducer: ReducersMapObject<ProgramState, AnyAction> = {
    legacyCreateProgram: legacyCreateProgramReducer,
    detailProgram: detailProgramReducer,
    listPrograms: listProgramsReducer,
    legacyUpdateProgram: legacyUpdateProgramReducer,
    searchPrograms: searchProgramsReducer,
    createProgram: createProgramReducer,
    updateProgram: updateProgramReducer,
};

export function* programSagas() {
    yield all([
        fork(legacyCreateProgramSaga),
        fork(detailProgramSaga),
        fork(listProgramsSaga),
        fork(legacyUpdateProgramSaga),
        fork(searchProgramsSaga),
        fork(createProgramSaga),
        fork(updateProgramSaga),
    ]);
}
