import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListUserTypes, ListUsersState } from 'reducer/user/list-users/types';

const initialState: ListUsersState = {
    status: HttpRequestStatus.NOOP,
    users: undefined,
    error: undefined,
}

const reducer: Reducer<ListUsersState> = (state = initialState, action): ListUsersState => {
    switch (action.type) {
        case ListUserTypes.LIST_USER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListUserTypes.LIST_USER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, users: action.payload, error: undefined };
        }
        case ListUserTypes.LIST_USER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListUserTypes.LIST_USER_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as listUsersReducer };