import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentFavoredToGetTransaction } from 'model/payment-favored';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';

interface FavoredCardProps {
    paymentFavored: PaymentFavoredToGetTransaction | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const FavoredCard = (props: FavoredCardProps) => {
    const { paymentFavored } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.favored.label`)}>
            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.favored.label`} value={paymentFavored?.name} />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.favored.identification`}
                value={
                    <IdentificationFormatter type={getPersonType(paymentFavored?.identification)} value={paymentFavored?.identification} />
                }
            />
        </ReadOnlyCard>
    );
};

export default FavoredCard;
