import { AxiosResponse } from 'axios';
import { BatchCloseDetails } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchCloseDetailsRequest, batchCloseDetailsError, batchCloseDetailsSuccess } from 'reducer/batch/batch-close-details/actions';
import { BatchCloseDetailsTypes } from 'reducer/batch/batch-close-details/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchCloseDetails(action: ReturnType<typeof batchCloseDetailsRequest>) {
    try {
        const result: AxiosResponse<BatchCloseDetails> = yield call(batchAPI.batchCloseDetails, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchCloseDetailsError(mapped));
            return;
        }
        yield put(batchCloseDetailsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchCloseDetailsError(mapped));
    }
}

function* watchBatchCloseDetails() {
    yield takeEvery(BatchCloseDetailsTypes.BATCH_CLOSE_DETAILS_REQUEST, handleBatchCloseDetails);
}

function* batchCloseDetailsSaga() {
    yield all([fork(watchBatchCloseDetails)]);
}

export default batchCloseDetailsSaga;
