import { GiroWebappError } from 'model/error';
import { ScdProgramResponse } from 'model/program';
import { ManageCompanyGroupToProgramRequest } from 'model/program-groups';
import { RemoveProgramGroupTypes } from 'reducer/program-groups/remove-program-group/types';
import { action } from 'typesafe-actions';

export const removeProgramGroupRequest = (request: ManageCompanyGroupToProgramRequest) => action(RemoveProgramGroupTypes.REMOVE_PROGRAM_GROUP_REQUEST, request);
export const removeProgramGroupSuccess = (programSimple: ScdProgramResponse) => action(RemoveProgramGroupTypes.REMOVE_PROGRAM_GROUP_SUCCESS, programSimple);
export const removeProgramGroupError = (error: GiroWebappError) => action(RemoveProgramGroupTypes.REMOVE_PROGRAM_GROUP_ERROR, error);
export const removeProgramGroupResetState = () => action(RemoveProgramGroupTypes.REMOVE_PROGRAM_GROUP_RESET_STATE);
