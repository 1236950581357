import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchExpirationsReportTypes, BatchExpirationsReportState } from 'reducer/batch/expirations-report/types';

const initialState: BatchExpirationsReportState = {
    status: HttpRequestStatus.NOOP,
    batchExpirationsReport: undefined,
    error: undefined,
};

const reducer: Reducer<BatchExpirationsReportState> = (state = initialState, action): BatchExpirationsReportState => {
    switch (action.type) {
        case BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchExpirationsReport: action.payload, error: undefined };
        }
        case BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchExpirationsReportReducer };
