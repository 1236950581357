import { AxiosResponse } from 'axios';
import { ScdCompanyItemSimple } from 'model/company-item';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    createCompanyItemError,
    createCompanyItemRequest,
    createCompanyItemSuccess,
} from 'reducer/company-item/create-company-item/actions';
import { CreateCompanyItemTypes } from 'reducer/company-item/create-company-item/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyItemAPI from 'services/api/company-item-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateCompanyItem(action: ReturnType<typeof createCompanyItemRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyItemSimple> = yield call(companyItemAPI.createCompanyItem, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createCompanyItemError(mapped));
            return;
        }
        yield put(createCompanyItemSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createCompanyItemError(mapped));
    }
}

function* watchCreateCompanyItem() {
    yield takeEvery(CreateCompanyItemTypes.CREATE_COMPANY_ITEM_REQUEST, handleCreateCompanyItem);
}

function* createCompanyItemSaga() {
    yield all([fork(watchCreateCompanyItem)]);
}

export default createCompanyItemSaga;
