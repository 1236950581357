import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ArchivePaymentTransactionsTypes,
    ArchivePaymentTransactionsState,
} from 'reducer/payment-transaction/archive-payment-transactions/types';

const initialState: ArchivePaymentTransactionsState = {
    status: HttpRequestStatus.NOOP,
    error: undefined,
};

const reducer: Reducer<ArchivePaymentTransactionsState> = (state = initialState, action): ArchivePaymentTransactionsState => {
    switch (action.type) {
        case ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, error: undefined };
        }
        case ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as archivePaymentTransactionsReducer };
