import { GiroWebappError } from 'model/error';
import { ScdProgramPolicy, ScdProgramPolicySimple } from 'model/program-policy';
import { UpdateProgramPolicyTypes } from 'reducer/program-policy/update-program-policy/types';
import { action } from 'typesafe-actions';

export const updateProgramPolicyRequest = (programPolicy: ScdProgramPolicy) =>
    action(UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_REQUEST, programPolicy);
export const updateProgramPolicySuccess = (updatedProgramPolicy: ScdProgramPolicySimple) =>
    action(UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_SUCCESS, updatedProgramPolicy);
export const updateProgramPolicyError = (error: GiroWebappError) => action(UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_ERROR, error);
export const updateProgramPolicyResetState = () => action(UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_RESET_STATE);
