import { ListChildComponentProps } from 'react-window';
import {CessionOperationBco} from "model/bco/batch-output-cession-integration-webcred";

export interface CessionIntegrationWebcredRowProps {
    cessionOperation: CessionOperationBco[];
}

const CessionIntegrationWebcredRow = (props: ListChildComponentProps<CessionIntegrationWebcredRowProps>) => {
    const { index, data, style } = props;

    const { cessionOperation } = data;

    const { id, ccbNumber } = cessionOperation[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{id}</td>
            <td className="page-container--table-cell-title">{ccbNumber}</td>
        </tr>
    );
};

export default CessionIntegrationWebcredRow;
