import { ScdImportedOperationsArchiveManyResponse } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsArchiveManyTypes {

    IMPORTED_OPERATIONS_ARCHIVE_MANY_REQUEST = '@@imported-operations-archive-many/IMPORTED_OPERATIONS_ARCHIVE_MANY_REQUEST',
    IMPORTED_OPERATIONS_ARCHIVE_MANY_SUCCESS = '@@imported-operations-archive-many/IMPORTED_OPERATIONS_ARCHIVE_MANY_SUCCESS',
    IMPORTED_OPERATIONS_ARCHIVE_MANY_ERROR = '@@imported-operations-archive-many/IMPORTED_OPERATIONS_ARCHIVE_MANY_ERROR',
    IMPORTED_OPERATIONS_ARCHIVE_MANY_RESET_STATE = '@@imported-operations-archive-many/IMPORTED_OPERATIONS_ARCHIVE_MANY_RESET_STATE',
}

export interface ImportedOperationsArchiveManyState extends BaseStoreState {
    readonly archivedOperations?: ScdImportedOperationsArchiveManyResponse
}
