import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ArchivePaymentTransactionsTypes {
    ARCHIVE_PAYMENT_TRANSACTIONS_REQUEST = '@@payment-transactions-archive/ARCHIVE_PAYMENT_TRANSACTIONS_REQUEST',
    ARCHIVE_PAYMENT_TRANSACTIONS_SUCCESS = '@@payment-transactions-archive/ARCHIVE_PAYMENT_TRANSACTIONS_SUCCESS',
    ARCHIVE_PAYMENT_TRANSACTIONS_ERROR = '@@payment-transactions-archive/ARCHIVE_PAYMENT_TRANSACTIONS_ERROR',
    ARCHIVE_PAYMENT_TRANSACTIONS_RESET = '@@payment-transactions-archive/ARCHIVE_PAYMENT_TRANSACTIONS_RESET',
}

export interface ArchivePaymentTransactionsState extends BaseStoreState {}
