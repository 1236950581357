import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroup } from 'model/company-group';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchCompanyGroupsTypes {
    SEARCH_COMPANY_GROUPS_REQUEST = '@@company-groups-search/SEARCH_COMPANY_GROUPS_REQUEST',
    SEARCH_COMPANY_GROUPS_SUCCESS = '@@company-groups-search/SEARCH_COMPANY_GROUPS_SUCCESS',
    SEARCH_COMPANY_GROUPS_ERROR = '@@company-groups-search/SEARCH_COMPANY_GROUPS_ERROR',
    SEARCH_COMPANY_GROUPS_RESET = '@@company-groups-search/SEARCH_COMPANY_GROUPS_RESET',
}

export interface SearchCompanyGroupsState extends BaseStoreState {
    readonly companyGroups?: Page<ScdCompanyGroup>;
}
