import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListProgramsTypes, ListProgramsState } from 'reducer/program/list-programs/types';
import { Reducer } from 'redux';

export const initialState: ListProgramsState = {
    status: HttpRequestStatus.NOOP,
    programs: undefined,
    error: undefined,
};

const reducer: Reducer<ListProgramsState> = (state = initialState, action): ListProgramsState => {
    switch (action.type) {
        case ListProgramsTypes.LIST_PROGRAMS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListProgramsTypes.LIST_PROGRAMS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, programs: action.payload, error: undefined };
        }
        case ListProgramsTypes.LIST_PROGRAMS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListProgramsTypes.LIST_PROGRAMS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listProgramsReducer };
