import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import ModalMessage from 'components/modals/modal-message/modal-message';
import PaymentTransactionInSeriesSkeletonSlide from 'entities/payment-transaction/components/skeleton/payment-transaction-in-series-skeleton-slide/payment-transaction-in-series-skeleton-slide';
import PaymentTransactionInSeriesSkeleton from 'entities/payment-transaction/components/skeleton/payment-transaction-in-series-skeleton/payment-transaction-in-series-skeleton';
import DetailPaymentTransactionInSeriesByBatch from 'entities/payment-transaction/payment-transaction-in-series-by-batch/components/detail-payment-transactions-in-series-by-batch/detail-payment-transactions-in-series-by-batch';
import PaymentTransactionInSeriesByBatchModalError from 'entities/payment-transaction/payment-transaction-in-series-by-batch/components/payment-transaction-in-series-by-batch-modal-error/payment-transaction-in-series-by-batch-modal-error';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentValueAccountType } from 'model/enums/payment-value-account-type';
import { PaymentTransactionByBatch, PaymentTransactionCreateByBatch } from 'model/payment-transaction-by-batch';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCreatePaymentTransactionsByBatchState, useListPaymentTransactionByBatchState, useRootDispatch } from 'reducer/hooks';
import {
    createPaymentTransactionsByBatchRequest,
    createPaymentTransactionsByBatchResetState,
} from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/actions';
import {
    listDisbursementTransactionsByBatchRequest,
    listDisbursementTransactionsByBatchResetState,
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/actions';
import { useToast } from 'shared/hooks/use-toast';
import './payment-transaction-in-series-by-batch.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction-in-series';

export interface ScdPaymentTransactionByBatchToCreateList extends PaymentTransactionByBatch {
    description?: string;
    accountId?: number;
    accountIdentifier?: string;
    accountType?: PaymentValueAccountType;
}

type ModalMessageType = 'send' | 'back' | 'cancel';

enum ModalType {
    SEND = 'send',
}

export const getAccountIdentifier = (accountId: string, accountType: PaymentValueAccountType): string => {
    return `${accountType}:${accountId}`;
};

type ScdPaymentTransactionInSeriesProps = RouteComponentProps<{ batchId: string }>;

const ScdPaymentTransactionInSeries = (props: ScdPaymentTransactionInSeriesProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [modalMessage, setModalMessage] = useState<ModalMessageType | undefined>(undefined);
    const [openModalError, setOpenModalError] = useState<boolean>(false);
    const [batchId] = useState(props.match.params.batchId);

    const { status: createPaymentTransactionByBatchStatus, paymentTransactionsByBatchErrors } = useCreatePaymentTransactionsByBatchState();

    const { status: listPaymentTransactionsByBatchStatus, paymentTransactionsByBatch } = useListPaymentTransactionByBatchState();

    const [listPaymentTransactionsByBatchToCreate, setListPaymentTransactionsByBatchListToCreate] = useState<
        ScdPaymentTransactionByBatchToCreateList[]
    >(paymentTransactionsByBatch?.paymentTransactions ?? []);

    const { toastSuccess, toastLoading, closeToast } = useToast();

    const handleCreatePaymentTransactionByBatch = () => {
        const _request: PaymentTransactionCreateByBatch[] = listPaymentTransactionsByBatchToCreate.map(value => {
            return {
                paymentValueId: value.paymentValueId,
                accountId: value.accountId,
                accountType: value.accountType,
                description: value.description,
                type: value.type,
            };
        });
        dispatch(createPaymentTransactionsByBatchRequest({ paymentTransactions: _request }));
    };

    const handleCloseModalError = () => {
        setOpenModalError(false);
        history.push('/payment-transactions');
        dispatch(createPaymentTransactionsByBatchResetState());
    };

    useEffect(() => {
        if (batchId) {
            dispatch(listDisbursementTransactionsByBatchRequest(batchId));
        }
    }, [dispatch, batchId]);

    useEffect(() => {
        return () => {
            dispatch(listDisbursementTransactionsByBatchResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        const updateListPaymentTransactions: PaymentTransactionByBatch[] | undefined = paymentTransactionsByBatch?.paymentTransactions.map(
            (value: PaymentTransactionByBatch) =>
                value.accounts.length === 1
                    ? {
                          ...value,
                          accountId: value.accounts[0].id,
                          accountType: value.accounts[0].accountType,
                          accountIdentifier: getAccountIdentifier(value.accounts[0].id, value.accounts[0].accountType),
                      }
                    : value
        );

        setListPaymentTransactionsByBatchListToCreate(updateListPaymentTransactions ?? []);
    }, [paymentTransactionsByBatch]);

    useEffect(() => {
        if (createPaymentTransactionByBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
            return;
        }

        if (createPaymentTransactionByBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            return;
        }

        if (createPaymentTransactionByBatchStatus === HttpRequestStatus.SUCCESS) {
            setModalMessage(undefined);

            if (paymentTransactionsByBatchErrors?.errors.length === 0) {
                closeToast();
                toastSuccess(
                    t('pages.scd.payment-transaction.new-transaction.toast.create', {
                        count: listPaymentTransactionsByBatchToCreate.length,
                    })
                );
                history.push('/payment-transactions');
                dispatch(createPaymentTransactionsByBatchResetState());
                return;
            }

            closeToast();

            setOpenModalError(true);
        }
    }, [
        toastLoading,
        closeToast,
        toastSuccess,
        dispatch,
        history,
        createPaymentTransactionByBatchStatus,
        paymentTransactionsByBatchErrors,
        listPaymentTransactionsByBatchToCreate.length,
        t,
    ]);

    useEffect(() => {
        const _lastIndex = listPaymentTransactionsByBatchToCreate.length + 1;
        setActiveIndex(_lastIndex);
    }, [batchId, listPaymentTransactionsByBatchToCreate]);

    if (!batchId) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.payment-transaction-in-series-by-batch.error-message')}</div>
                <BackButton label={t('global.back')} onClick={() => history.push('/payment-transactions')} />
            </div>
        );
    }

    const totalAmountPaymenTransactionByBatch: number | undefined =
        listPaymentTransactionsByBatchToCreate.length === 0
            ? 0
            : listPaymentTransactionsByBatchToCreate.map(({ amount }) => amount ?? 0).reduce((acc, value) => value + acc);

    const showSkeleton = listPaymentTransactionsByBatchStatus === HttpRequestStatus.ON_GOING;

    const isSendModal = modalMessage === ModalType.SEND;

    const disabledButton =
        listPaymentTransactionsByBatchToCreate.some(value => !value.accountId) ||
        createPaymentTransactionByBatchStatus === HttpRequestStatus.ON_GOING ||
        listPaymentTransactionsByBatchToCreate.length === 0 ||
        paymentTransactionsByBatch?.paymentTransactions.length === 0 ||
        showSkeleton;

    const modalTitle = {
        send: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`, {
            transactions: listPaymentTransactionsByBatchToCreate.length,
            count: listPaymentTransactionsByBatchToCreate.length,
        }),
        cancel: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`),
        back: t(`${I18N_PREFIX}.modal-message.${modalMessage}.title`),
    } as Record<ModalMessageType, string>;

    const modalOnAction = {
        send: () => {
            handleCreatePaymentTransactionByBatch();
        },
        cancel: () => {
            setModalMessage(undefined);
            history.push('/payment-transactions');
        },
        back: () => {
            setModalMessage(undefined);
            history.push('/payment-transactions');
        },
    } as Record<ModalMessageType, () => void>;

    return (
        <>
            <main className="scd-payment-transaction-in-series-by-batch">
                <ContextRibbon
                    action={() =>
                        listPaymentTransactionsByBatchToCreate.length === 0
                            ? history.push('/payment-transactions')
                            : setModalMessage('back')
                    }
                />
                <section className="scd-payment-transaction-in-series-by-batch__container">
                    <header className="scd-payment-transaction-in-series-by-batch__header">
                        <h2 className="scd-payment-transaction-in-series-by-batch__header--title">{t(`${I18N_PREFIX}.title-legacy`)}</h2>
                    </header>
                    {!showSkeleton && batchId ? (
                        <>
                            {paymentTransactionsByBatch?.paymentTransactions.length === 0 ? (
                                <div className="scd-payment-transaction-in-series-by-batch__error-message">
                                    <span>{t(`${I18N_PREFIX}.by-batch.message.empty-transactions`)} </span>
                                </div>
                            ) : (
                                <div className="scd-payment-transaction-in-series-by-batch__content">
                                    {listPaymentTransactionsByBatchToCreate.length === 0 ? (
                                        <div className="scd-payment-transaction-in-series-by-batch__error-message">
                                            <span>{t(`${I18N_PREFIX}.by-batch.message.no-transactions`)} </span>
                                        </div>
                                    ) : (
                                        listPaymentTransactionsByBatchToCreate?.map((value, key) => {
                                            return (
                                                <DetailPaymentTransactionInSeriesByBatch
                                                    detailPaymentTransactionByBatch={value}
                                                    listPaymentTransactionsByBatch={listPaymentTransactionsByBatchToCreate ?? []}
                                                    setListPaymentTransactionsByBatchList={setListPaymentTransactionsByBatchListToCreate}
                                                    index={key}
                                                    key={value.accountId}
                                                />
                                            );
                                        })
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <PaymentTransactionInSeriesSkeleton />
                    )}

                    <div className="scd-payment-transaction-in-series-by-batch__content-slide">
                        <div className="scd-payment-transaction-in-series-by-batch__content-slide--container">
                            <div className="scd-payment-transaction-in-series-by-batch__content-slide--item">
                                {showSkeleton ? (
                                    <PaymentTransactionInSeriesSkeletonSlide />
                                ) : (
                                    <>
                                        <div className="scd-payment-transaction-in-series-by-batch__content-slide--item__title">
                                            {`${activeIndex - 1} ${t(`${I18N_PREFIX}.slide.transfer`, {
                                                count: activeIndex - 1,
                                            }).toLocaleLowerCase()}`}
                                        </div>
                                        <div className="scd-payment-transaction-in-series-by-batch__content-slide--item__value">
                                            <strong>
                                                <CurrencyFormatter value={totalAmountPaymenTransactionByBatch} prefix />
                                            </strong>

                                            <span>
                                                {t(`${I18N_PREFIX}.slide.batch`)} {paymentTransactionsByBatch?.batchCode}{' '}
                                            </span>
                                            <span> {paymentTransactionsByBatch?.originator ?? '(Sem programa)'} </span>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="scd-payment-transaction-in-series-by-batch__content-slide--buttons">
                                <LinkButton onClick={() => setModalMessage('cancel')} outlinedStyle label={t('entity.action.cancel')} />
                                <span style={{ marginLeft: '12px', width: '145px' }}>
                                    <LinkButton
                                        label={t('entity.action.create')}
                                        onClick={() => setModalMessage('send')}
                                        disabled={disabledButton}
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            {modalMessage && (
                <ModalMessage
                    title={modalTitle[modalMessage]}
                    onClose={() => setModalMessage(undefined)}
                    onAction={modalOnAction[modalMessage]}
                    onCancel={() => setModalMessage(undefined)}
                    disabled={isSendModal && disabledButton}
                >
                    <span>
                        {t(`${I18N_PREFIX}.modal-message.${modalMessage}.message`, {
                            count: listPaymentTransactionsByBatchToCreate.length,
                        })}
                    </span>
                </ModalMessage>
            )}
            {openModalError && (
                <PaymentTransactionInSeriesByBatchModalError
                    title={t(`${I18N_PREFIX}.modal-message.error.title`, { count: paymentTransactionsByBatchErrors?.errors.length })}
                    onClose={handleCloseModalError}
                    onAction={handleCloseModalError}
                    errors={paymentTransactionsByBatchErrors?.errors ?? []}
                    paymentTransactions={listPaymentTransactionsByBatchToCreate ?? []}
                />
            )}
        </>
    );
};

export default ScdPaymentTransactionInSeries;
