import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { ConcludeCessionBcoTypes } from 'reducer-bco/batch-output/conclude-cession/types';
import { CessionOperationRequestRequired, CessionToSimpleDTO } from 'model/bco/batch-output-cession-integration-webcred';

export const concludeCessionBcoRequest = (request: CessionOperationRequestRequired) =>
    action(ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_REQUEST, request);
export const concludeCessionBcoSuccess = (cessionOperationBco: CessionToSimpleDTO) =>
    action(ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_SUCCESS, cessionOperationBco);
export const concludeCessionBcoError = (error: GiroWebappError) => action(ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_ERROR, error);
export const concludeCessionBcoResetState = () => action(ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_RESET);
