import { Bank } from 'model/bank';
import { GiroWebappError } from 'model/error';
import { SearchRequest } from 'model/reducers';
import { SearchBanksTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchBanksRequest = (search: SearchRequest) => action(SearchBanksTypes.SEARCH_BANKS_REQUEST, search);
export const searchBanksSuccess = (bank: Page<Bank>) => action(SearchBanksTypes.SEARCH_BANKS_SUCCESS, bank);
export const searchBanksError = (error: GiroWebappError) => action(SearchBanksTypes.SEARCH_BANKS_ERROR, error);
export const searchBanksResetState = () => action(SearchBanksTypes.SEARCH_BANKS_RESET_STATE);
