import Loading from 'components/loading/loading';
import OrganizationRow, { OrganizationRowProps } from 'entities/organization/components/organization-row/organization-row';
import { OrganizationMoreOptionsType } from 'entities/organization/organization';
import { ScdOrganizationList } from 'model/organization';
import { SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface OrganizationListProps {
    organizations: ScdOrganizationList[];
    setModalOptionType: React.Dispatch<SetStateAction<OrganizationMoreOptionsType | undefined>>;
    setSelectedOrganization: React.Dispatch<SetStateAction<ScdOrganizationList | undefined>>;
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.organization.table';

const OrganizationList = (props: OrganizationListProps) => {
    const { organizations, setModalOptionType, setSelectedOrganization, isLoading } = props;

    const { t } = useTranslation();

    const OrganizationRowObjectData: OrganizationRowProps = {
        organizations,
        setModalOptionType,
        setSelectedOrganization,
    };

    return (
        <>
            {isLoading && !organizations.length && <Loading isTable />}
            {organizations.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={OrganizationRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={organizations.length}
                            className="page-container--table-container"
                        >
                            {OrganizationRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-organization__table-empty-filter">
                            <span className="scd-organization__table-empty-filter-image" />
                            <span className="scd-organization__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default OrganizationList;
