import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchSignatureReExportState, BatchSignatureReExportTypes } from 'reducer/batch/batch-signature-re-export/types';

const initialState: BatchSignatureReExportState = {
    status: HttpRequestStatus.NOOP,
    signatureReExport: undefined,
    error: undefined
}

const reducer: Reducer<BatchSignatureReExportState> = (state = initialState, action): BatchSignatureReExportState => {
    switch (action.type) {
        case BatchSignatureReExportTypes.BATCH_SIGNATURE_RE_EXPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchSignatureReExportTypes.BATCH_SIGNATURE_RE_EXPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, signatureReExport: action.payload, error: undefined };
        }
        case BatchSignatureReExportTypes.BATCH_SIGNATURE_RE_EXPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchSignatureReExportTypes.BATCH_SIGNATURE_RE_EXPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchSignatureReExportReducer }