import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import SelectOneFile from 'features/limelight/components/upload-file/upload-one-file';
import { FileUploaded } from 'features/limelight/send-backings/domain/models';
import { SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface LimelightOptionsItemsProps {
    handleSelectOneFile: (fileSelect: File) => void;
    removeBind: (operationId: number, file: FileUploaded) => void;
    setAnchorEl: (value: SetStateAction<HTMLElement | null>) => void;
    file?: FileUploaded;
    operationId?: number;
    verify: boolean;
}

const I18N_PREFIX = 'pages.scd.provisional-limelight';

const LimelightOptionsItems = (props: LimelightOptionsItemsProps) => {
    const { t } = useTranslation();

    const { handleSelectOneFile, removeBind, setAnchorEl, operationId, file, verify } = props;

    const handleRemove = (operationId?: number, file?: FileUploaded) => {
        if (!operationId || !file) return;
        removeBind(operationId, file);
        setAnchorEl(null);
    };

    return (
        <>
            {verify ? (
                <ModalActionMenuItem
                    titleKey={t(`${I18N_PREFIX}.buttons.remove-operations`)}
                    onClick={() => handleRemove(operationId, file)}
                />
            ) : (
                <SelectOneFile handleChange={handleSelectOneFile} />
            )}
        </>
    );
};

export default LimelightOptionsItems;
