import FilterButton from 'components/buttons/filter-button/filter-button';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TransfeeraStatementFiltersType } from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-filters-modal/transfeera-statement-filters-modal';
import {
    TransfeeraExternalStatementFilterRequest,
    defaultTransfeeraExternalStatementFilters,
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';

interface TransfeeraStatementFiltersButtonsProps {
    filterActive: TransfeeraExternalStatementFilterRequest;
    handleResetFilterActive: () => void;
    setModalFilter: (value: TransfeeraStatementFiltersType) => void;
    setOpenModalFilter: (value: boolean) => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.filters.buttons';

const TransfeeraStatementFiltersButtons = (props: TransfeeraStatementFiltersButtonsProps) => {
    const { filterActive, handleResetFilterActive, setModalFilter, setOpenModalFilter } = props;

    const { t } = useTranslation();

    const handleModalFilter = (value: TransfeeraStatementFiltersType) => () => {
        setModalFilter(value);
        setOpenModalFilter(true);
    };

    const isActiveFilter: boolean = !_.isEqual(filterActive, defaultTransfeeraExternalStatementFilters);

    const isDateFilterActive: boolean =
        !_.isEqual(filterActive.initialDate, defaultTransfeeraExternalStatementFilters.initialDate) ||
        !_.isEqual(filterActive.endDate, defaultTransfeeraExternalStatementFilters.endDate);

    return (
        <div className="transfeera-statement__header-buttons-content">
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('filters')}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isActiveFilter}
                />
            </div>
            <div className="transfeera-transfers__header-buttons-content__filter-button">
                <FilterButton
                    onClick={handleModalFilter('date')}
                    label={t(`${I18N_PREFIX}.date`)}
                    icon={<ArrowDropDownIcon />}
                    isActiveFilter={isDateFilterActive}
                />
            </div>
            {isActiveFilter && (
                <Tooltip title={t('global.remove-filter.title').toString()}>
                    <div className="transfeera-statement__header-buttons-content__error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </div>
    );
};

export default TransfeeraStatementFiltersButtons;
