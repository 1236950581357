import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateIssuerTypes, CreateIssuerState } from 'reducer/issuer/create-issuer/types';
import { Reducer } from 'redux';

export const initialState: CreateIssuerState = {
    status: HttpRequestStatus.NOOP,
    issuer: undefined,
    error: undefined,
};

const reducer: Reducer<CreateIssuerState> = (state = initialState, action): CreateIssuerState => {
    switch (action.type) {
        case CreateIssuerTypes.CREATE_ISSUER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CreateIssuerTypes.CREATE_ISSUER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuer: action.payload, error: undefined };
        }
        case CreateIssuerTypes.CREATE_ISSUER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateIssuerTypes.CREATE_ISSUER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createIssuerReducer };
