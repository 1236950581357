import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBatchData } from 'model/batch';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBatchesTypes {
    LIST_BATCHES_REQUEST = '@@batches-list/LIST_BATCHES_REQUEST',
    LIST_BATCHES_SUCCESS = '@@batches-list/LIST_BATCHES_SUCCESS',
    LIST_BATCHES_ERROR = '@@batches-list/LIST_BATCHES_ERROR',
    LIST_BATCHES_RESET = '@@batches-list/LIST_BATCHES_RESET',
}

export interface ListBatchesState extends BaseStoreState {
    readonly batches?: Page<ScdBatchData>;
}
