import { ScdCompanyGroup, ScdCompanyGroupSimple } from 'model/company-group';
import { GiroWebappError } from 'model/error';
import { CreateCompanyGroupTypes } from 'reducer/company-group/create-company-group/types';
import { action } from 'typesafe-actions';

export const createCompanyGroupRequest = (companyGroup: ScdCompanyGroup) =>
    action(CreateCompanyGroupTypes.CREATE_COMPANY_GROUP_REQUEST, companyGroup);
export const createCompanyGroupSuccess = (createdCompanyGroup: ScdCompanyGroupSimple) =>
    action(CreateCompanyGroupTypes.CREATE_COMPANY_GROUP_SUCCESS, createdCompanyGroup);
export const createCompanyGroupError = (error: GiroWebappError) => action(CreateCompanyGroupTypes.CREATE_COMPANY_GROUP_ERROR, error);
export const createCompanyGroupResetState = () => action(CreateCompanyGroupTypes.CREATE_COMPANY_GROUP_RESET);
