import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import './remove-button.scss';

export interface RemoveButtonProps {
    onClick?: () => void;
}

export const RemoveButton = (props: RemoveButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--remove">
            <Button variant="contained" onClick={props.onClick}>
                <span className="button__style--remove-icon-trash">
                    <DeleteIcon />
                </span>
                <span> {t('entity.action.remove')} </span>
            </Button>
        </div>
    );
};

export default RemoveButton;
