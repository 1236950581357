import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidCnpjInput from 'components/inputs/cnpj-input/valid-cnpj-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import {
    validateIssuerIdentification,
    validateIssuerName,
    validateIssuerStatus,
    validateScdIssuer,
} from 'entities/issuer/validation/issuer-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { IssuerStatus } from 'model/enums/issuer-status';
import { ScdIssuer, defaultScdIssuer } from 'model/issuer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCreateIssuerState, useDetailIssuerState, useRootDispatch, useUpdateIssuerState } from 'reducer/hooks';
import { createIssuerRequest, createIssuerResetState } from 'reducer/issuer/create-issuer/actions';
import { detailIssuerRequest, detailIssuerResetState } from 'reducer/issuer/detail-issuer/actions';
import { updateIssuerRequest, updateIssuerResetState } from 'reducer/issuer/update-issuer/actions';
import './issuer-create-update.scss';

export interface ScdIssuerUpdateProps extends RouteComponentProps<{ issuerId: string }> {}

const I18N_PREFIX = 'pages.scd.issuer';

const ScdIssuerCreateUpdatePage = (props: ScdIssuerUpdateProps) => {
    const { t } = useTranslation();
    const [issuerId] = useState<number | undefined>(props.match?.params?.issuerId ? Number(props.match?.params?.issuerId) : undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateIssuerState();
    const { status: updateStatus } = useUpdateIssuerState();
    const { status: detailStatus, issuer: detailIssuer } = useDetailIssuerState();

    const [scdIssuer, setScdIssuer] = useState<ScdIssuer>(defaultScdIssuer);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        if (!validateScdIssuer(scdIssuer)) return;

        if (issuerId) {
            dispatch(updateIssuerRequest({ issuerId: issuerId, issuer: scdIssuer }));
            return;
        }

        dispatch(createIssuerRequest(scdIssuer));
    };

    const handleChange = (value: Partial<ScdIssuer>) => {
        setScdIssuer(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (issuerId) {
            dispatch(detailIssuerRequest(issuerId));
        }
    }, [issuerId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailIssuer !== undefined) {
            setScdIssuer(detailIssuer);
        }
    }, [detailStatus, detailIssuer]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            history.push('/issuers');
        }
    }, [updateStatus, history]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push('/issuers');
        }
    }, [createStatus, history]);

    useEffect(() => {
        return () => {
            dispatch(createIssuerResetState());
            dispatch(updateIssuerResetState());
            dispatch(detailIssuerResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-issuer-create-update__create">
            <ContextRibbon />
            <div className="scd-issuer-create-update--container">
                <header className="scd-issuer-create-update--header">
                    <h2 className="scd-issuer-create-update--header-title">
                        {!issuerId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && issuerId ? (
                    <Loading />
                ) : (
                    <form className="scd-issuer-create-update--form">
                        <div className="scd-issuer-create-update--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdIssuer?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateIssuerName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-issuer-create-update--form-field">
                            <ValidCnpjInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={scdIssuer?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                onChange={identification => handleChange({ identification })}
                                validate={validateIssuerIdentification}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-issuer-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={scdIssuer?.status ?? ''}
                                validate={_value => validateIssuerStatus(IssuerStatus[_value])}
                                mapperFromString={_value => (_value ? IssuerStatus[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                onChange={(status: string) => handleChange({ status: IssuerStatus[status] })}
                                disabled={!issuerId}
                                externalUpdate
                            >
                                <MenuItem value={IssuerStatus.ACTIVE}>{t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}</MenuItem>
                                <MenuItem value={IssuerStatus.INACTIVE}>{t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-issuer-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={issuerId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/issuers');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdIssuerCreateUpdatePage;
