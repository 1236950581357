import { PaymentInstitutionAccountSimple } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreatePaymentInstitutionAccountTypes {
    CREATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST = '@@payment-institution-account-create/CREATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST',
    CREATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS = '@@payment-institution-account-create/CREATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS',
    CREATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR = '@@payment-institution-account-create/CREATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR',
    CREATE_PAYMENT_INSTITUTION_ACCOUNT_RESET = '@@payment-institution-account-create/CREATE_PAYMENT_INSTITUTION_ACCOUNT_RESET',
}

export interface CreatePaymentInstitutionsAccountState extends BaseStoreState {
    readonly paymentInstitutionAccount?: PaymentInstitutionAccountSimple
}
