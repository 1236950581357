import { Checkbox } from '@material-ui/core';
import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

interface ScdBatchEndorsementDocumentHeaderProps {
    isCheckedBox: boolean;
    selectAllCheckBox: boolean;
    handleSelectAll: () => void;
    isDisabled: boolean;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement.table';

const ScdBatchEndorsementDocumentHeader = (props: ScdBatchEndorsementDocumentHeaderProps) => {
    const { t } = useTranslation();
    const { isCheckedBox, selectAllCheckBox, handleSelectAll, isDisabled } = props;

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.type.label`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.fileName`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.status.label`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.isAlreadyImported.label`).toUpperCase()} />
            <th>{isCheckedBox && <Checkbox disabled={isDisabled} checked={selectAllCheckBox} onChange={handleSelectAll} />}</th>
        </tr>
    );
};

export default ScdBatchEndorsementDocumentHeader;
