import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateProgramState, UpdateProgramTypes } from 'reducer/program/update-program/types';
import { Reducer } from 'redux';

export const initialState: UpdateProgramState = {
    status: HttpRequestStatus.NOOP,
    programSimple: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateProgramState> = (state = initialState, action): UpdateProgramState => {
    switch (action.type) {
        case UpdateProgramTypes.UPDATE_PROGRAM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateProgramTypes.UPDATE_PROGRAM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, programSimple: action.payload, error: undefined };
        }
        case UpdateProgramTypes.UPDATE_PROGRAM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateProgramTypes.UPDATE_PROGRAM_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateProgramReducer };
