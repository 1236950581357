import { IssuerListPolicies } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListIssuerPoliciesTypes {
    LIST_ISSUER_POLICIES_REQUEST = '@@issuer-list-policies/LIST_ISSUER_POLICIES_REQUEST',
    LIST_ISSUER_POLICIES_SUCCESS = '@@issuer-list-policies/LIST_ISSUER_POLICIES_SUCCESS',
    LIST_ISSUER_POLICIES_ERROR = '@@issuer-list-policies/LIST_ISSUER_POLICIES_ERROR',
    LIST_ISSUER_POLICIES_RESET_STATE = '@@issuer-list-policies/LIST_ISSUER_POLICIES_RESET_STATE',
}

export interface ListIssuerPoliciesState extends BaseStoreState {
    readonly issuerPolicies?: Page<IssuerListPolicies>;
}
