import { CompanyBankAccount } from 'model/company-bank-account';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.company.validate.accounts';

const COMPANY_ACCOUNTS_AGENCY_NUMBER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.agencyNumber.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.agencyNumber.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(4),
        errorMessage: `${I18N_PREFIX}.agencyNumber.maxLength`,
    },
};

export const validateCompanyAccountAgencyNumber = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_AGENCY_NUMBER_VALIDATION);

const COMPANY_ACCOUNTS_AGENCY_DIGIT_VALIDATION: Validations<string | undefined | null> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(2),
        errorMessage: `${I18N_PREFIX}.agencyDigit.maxLength`,
    },
};

export const validateCompanyAccountAgencyDigit = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_AGENCY_DIGIT_VALIDATION);

const COMPANY_ACCOUNTS_ACCOUNT_NUMBER_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountNumber.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.accountNumber.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(20),
        errorMessage: `${I18N_PREFIX}.accountNumber.maxLength`,
    },
};

export const validateCompanyAccountNumber = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_ACCOUNT_NUMBER_VALIDATION);

const COMPANY_ACCOUNTS_ACCOUNT_DIGIT_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountDigit.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.accountDigit.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(2),
        errorMessage: `${I18N_PREFIX}.accountDigit.maxLength`,
    },
};

export const validateCompanyAccountDigit = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_ACCOUNT_DIGIT_VALIDATION);

const COMPANY_ACCOUNTS_PIX_KEY_VALIDATION: Validations<string | undefined | null> = {
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(70),
        errorMessage: `${I18N_PREFIX}.pixKey.maxLength`,
    },
};

export const validateCompanyAccountPixKey = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_PIX_KEY_VALIDATION);

const COMPANY_ACCOUNTS_FAVORED_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.favoredIdentification.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.favoredIdentification.required`,
    },
    isValid: {
        predicate: ValidationUtils.STRING.isValidCPForCNPJ,
        errorMessage: `${I18N_PREFIX}.favoredIdentification.valid`,
    },
};

export const validateCompanyAccountFavoredIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_FAVORED_IDENTIFICATION_VALIDATION);

const COMPANY_ACCOUNTS_FAVORED_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.favoredName.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.favoredName.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.favoredName.maxLength`,
    },
};

export const validateCompanyAccountFavoredName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_FAVORED_NAME_VALIDATION);

const COMPANY_ACCOUNTS_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};

export const validateCompanyAccountType = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_TYPE_VALIDATION);

const COMPANY_ACCOUNTS_BANK_ID_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.bankId.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.bankId.required`,
    },
};

export const validateCompanyAccountBankId = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ACCOUNTS_BANK_ID_VALIDATION);

export const validateCompanyAccount = (value: CompanyBankAccount | undefined) => {
    if (!value) {
        return false;
    }

    return (
        validateCompanyAccountAgencyNumber(value.agencyNumber).isValid &&
        validateCompanyAccountAgencyDigit(value.agencyDigit).isValid &&
        validateCompanyAccountNumber(value.accountNumber).isValid &&
        validateCompanyAccountDigit(value.accountDigit).isValid &&
        validateCompanyAccountPixKey(value.pixKey).isValid &&
        validateCompanyAccountFavoredIdentification(value.favoredIdentification).isValid &&
        validateCompanyAccountFavoredName(value.favoredName).isValid &&
        validateCompanyAccountType(value.type).isValid &&
        validateCompanyAccountBankId(value.bankId?.toString()).isValid
    );
};
