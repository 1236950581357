import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import ProgramFilterButtons from 'entities/program/components/program-filter-buttons/program-filter-buttons';
import ScdProgramHeader from 'entities/program/components/program-header/program-header';
import ProgramList from 'entities/program/components/program-list/program-list';
import ProgramModalFilter, { ProgramFilterType } from 'entities/program/components/program-modal-filter/program-modal-filter';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProgramFilterPageable, ProgramFilterRequest, ScdProgramList, defaultProgramFilterRequest } from 'model/program';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useListProgramsState } from 'reducer/hooks';
import { listProgramsRequest } from 'reducer/program/list-programs/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';

import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import useScdProgramFilterChips from 'entities/program/hooks/use-scd-program-filter-chips';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './program.scss';

const I18N_PREFIX = 'pages.scd.program';

export type ProgramSortableProperties = keyof ScdProgramList | 'organization.name';

const ScdProgramPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<ProgramFilterType | undefined>(undefined);
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<ProgramFilterRequest>(defaultProgramFilterRequest);

    const { status: listStatus } = useListProgramsState();

    const filterPageable = useCallback((): ProgramFilterPageable => {
        const pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        const filter: ProgramFilterRequest = filterActive ?? {};

        return {
            filter,
            pageable,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const { isScdProgramChipsActive, scdProgramFilterChips } = useScdProgramFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        handleRefresh,
        isLoading,
        items: programsItems,
        resetInfiniteScrollListing,
    } = useInfiniteScrollLegacy<ScdProgramList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listPrograms.programs,
        statusSelector: (state: IRootState) => state.listPrograms.status,
        action: listProgramsRequest,
        filterPageable,
        setPage,
    });

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.program.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }

    const handleSort = (property: ProgramSortableProperties, order: SortOrder) => {
        resetInfiniteScrollListing();
        setPage(0);

        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultProgramFilterRequest);
    };

    return (
        <main className="scd-program">
            <section className="scd-program__container">
                <header className="scd-program__header">
                    <div className="scd-program__header-container">
                        <h2 className="scd-program__header-container--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton onClick={handleRefresh} />
                        </h2>
                        <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                    </div>
                    <div className="scd-program__header--buttons-content">
                        <ProgramFilterButtons
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setIsOpenModalFilter={setIsOpenModalFilter}
                            setModalFilter={setModalFilter}
                        />
                    </div>
                    <ChipFilterActive filters={scdProgramFilterChips} />
                </header>

                <div className="scd-program__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !programsItems.length,
                            CHIPS_ACTIVE: isScdProgramChipsActive,
                        })}
                    >
                        <thead>
                            <ScdProgramHeader
                                programsItems={programsItems}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                            />
                        </thead>
                        <tbody>
                            <ProgramList programsItems={programsItems} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalFilter && (
                <ProgramModalFilter
                    filterActive={filterActive}
                    isOpenModalFilter={isOpenModalFilter}
                    modalFilter={modalFilter}
                    onClose={() => setIsOpenModalFilter(false)}
                    resetInfiniteScrollListing={resetInfiniteScrollListing}
                    setFilterActive={setFilterActive}
                    setPage={setPage}
                    title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                />
            )}
        </main>
    );
};

export default ScdProgramPage;
