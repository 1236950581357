import { AxiosResponse } from 'axios';
import { ScdCompanyItem, ScdCompanyItemSearch, ScdCompanyItemSimple } from 'model/company-item';
import { SearchRequestCompanyItem } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { Pageable } from 'services/pageable';

const companyItem = () => {
    const createCompanyItem = (companyItem: ScdCompanyItem): Promise<AxiosResponse<ScdCompanyItemSimple>> => {
        return api.post<ScdCompanyItemSimple>(`${BASE_URL_ADMIN}/company-items`, companyItem);
    };

    const updateCompanyItem = (companyItem: ScdCompanyItem): Promise<AxiosResponse<ScdCompanyItemSimple>> => {
        return api.put<ScdCompanyItemSimple>(`${BASE_URL_ADMIN}/company-items`, companyItem);
    };

    const detailCompanyItem = (companyItemId: string): Promise<AxiosResponse<ScdCompanyItem>> => {
        return api.get<ScdCompanyItem>(`${BASE_URL_ADMIN}/company-items/${companyItemId}`);
    };

    const listCompanyItems = (request?: Pageable): Promise<AxiosResponse<Page<ScdCompanyItem>>> => {
        return api.get<Page<ScdCompanyItem>>(`${BASE_URL_ADMIN}/company-items`, { params: { ...request } });
    };

    const searchCompanyItems = (request: SearchRequestCompanyItem): Promise<AxiosResponse<Page<ScdCompanyItemSearch>>> => {
        return api.get<Page<ScdCompanyItemSearch>>(
            `${BASE_URL_ADMIN}/company-items/search?search=${encodeURIComponent(request.search)}&companyGroupId=${request.companyGroupId}`,
            { params: { ...request.pageable } }
        );
    };

    return {
        createCompanyItem,
        updateCompanyItem,
        detailCompanyItem,
        listCompanyItems,
        searchCompanyItems,
    };
};

export default companyItem();
