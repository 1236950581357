import { Bank } from 'model/bank';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum SearchBanksTypes {
    SEARCH_BANKS_REQUEST = '@@search-banks/SEARCH_BANKS_REQUEST',
    SEARCH_BANKS_SUCCESS = '@@search-banks/SEARCH_BANKS_SUCCESS',
    SEARCH_BANKS_ERROR = '@@search-banks/SEARCH_BANKS_ERROR',
    SEARCH_BANKS_RESET_STATE = '@@search-banks/SEARCH_BANKS_RESET_STATE',
}

export interface SearchBanksState extends BaseStoreState {
    readonly banks?: Page<Bank>;
}
