import { ScdOrganization } from 'model/organization';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.organization.validate';

const ORGANIZATION_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateOrganizationName = (value: string | undefined | null) => ValidationUtils.validate(value, ORGANIZATION_NAME_VALIDATION);

const ORGANIZATION_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    pattern: {
        predicate: ValidationUtils.STRING.isValidCNPJ,
        errorMessage: `${I18N_PREFIX}.identification.pattern`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
};
export const validateOrganizationIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORGANIZATION_IDENTIFICATION_VALIDATION);

const ORGANIZATION_STATUS_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.organizationKey.required`,
    },
};
export const validateOrganizationKey = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ORGANIZATION_STATUS_VALIDATION);

export const validateScdOrganization = (value: ScdOrganization | undefined): boolean => {
    if (!value) return false;
    return (
        validateOrganizationName(value.name).isValid &&
        validateOrganizationIdentification(value.identification).isValid &&
        validateOrganizationKey(value.organizationKey).isValid
    );
};
