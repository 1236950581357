import { AxiosResponse } from 'axios';
import { BatchOutputHemera } from 'model/batch-output-hemera';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchOutputHemeraError, batchOutputHemeraRequest, batchOutputHemeraSuccess } from 'reducer/batch/batch-output-hemera/action';
import { OutputHemeraTypes } from 'reducer/batch/batch-output-hemera/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import outputApi from 'services/api/batch-output-api'
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchOutputHemera(action: ReturnType<typeof batchOutputHemeraRequest>) {
    try {
        const result: AxiosResponse<BatchOutputHemera> = yield call(outputApi.outputHemera, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOutputHemeraError(mapped));
            return;
        }
        yield put(batchOutputHemeraSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOutputHemeraError(mapped));
    }
}

function* watchBatchOutputHemera() {
    yield takeEvery(OutputHemeraTypes.BATCH_OUTPUT_HEMERA_REQUEST, handleBatchOutputHemera);
}

function* batchOutputHemeraSaga() {
    yield all([fork(watchBatchOutputHemera)]);
}

export default batchOutputHemeraSaga;