import { ScdBatchOperationsReport } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchOperationsReportTypes {
    BATCH_OPERATIONS_REPORT_REQUEST = '@@batch-operations-report/BATCH_OPERATIONS_REPORT_REQUEST',
    BATCH_OPERATIONS_REPORT_SUCCESS = '@@batch-operations-report/BATCH_OPERATIONS_REPORT_SUCCESS',
    BATCH_OPERATIONS_REPORT_ERROR = '@@batch-operations-report/BATCH_OPERATIONS_REPORT_ERROR',
    BATCH_OPERATIONS_REPORT_RESET = '@@batch-operations-report/BATCH_OPERATIONS_REPORT_RESET',
}

export interface BatchOperationsReportState extends BaseStoreState {
    readonly batchOperationsReport?: ScdBatchOperationsReport;
}
