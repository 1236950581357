import FormatterUtils from 'components/formatter/formatter-utils';

export type PercentageValue = number | undefined | null;

export interface PercentageFormatterProps {
    value: PercentageValue;
    defaultValue?: number;
}

export const PercentageFormatterWithSixDecimals = (props: PercentageFormatterProps) => {
    const { value, defaultValue } = props;

    return <>{FormatterUtils.formatNumberToPercentageWithSixDecimals(value, defaultValue)}</>;
};

export const formatPercentageWithSixDecimals = FormatterUtils.formatNumberToPercentageWithSixDecimals;

export default PercentageFormatterWithSixDecimals;
