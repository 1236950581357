import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import createNumberMask from 'components/inputs/number-mask';
import React from 'react';
import MaskedInput from 'react-text-mask';

const financialPercentageMask = createNumberMask({
    prefix: '',
    suffix: ' %',
    integerLimit: 2,
    decimalLimit: 12,
    includeThousandsSeparator: false,
});

export const FinancialPercentageMask: React.ElementType<InputBaseComponentProps> = props => {
    const { ...others } = props;
    return <MaskedInput {...others} mask={financialPercentageMask} guide={false} />;
};

export default FinancialPercentageMask;
