import { GiroWebappError } from 'model/error';
import { ScdImportedOperationReexport, ScdImportedOperationsReexportAllRequest } from 'model/imported-operation';
import { ImportedOperationsReexportAllTypes } from 'reducer/imported-operation/reexport-all/types';
import { action } from 'typesafe-actions';

export const importedOperationsReexportAllRequest = (request: ScdImportedOperationsReexportAllRequest) =>
    action(ImportedOperationsReexportAllTypes.IMPORTED_OPERATIONS_REEXPORT_ALL_REQUEST, request);
export const importedOperationsReexportAllSuccess = (data: ScdImportedOperationReexport[]) =>
    action(ImportedOperationsReexportAllTypes.IMPORTED_OPERATIONS_REEXPORT_ALL_SUCCESS, data);
export const importedOperationsReexportAllError = (error: GiroWebappError) =>
    action(ImportedOperationsReexportAllTypes.IMPORTED_OPERATIONS_REEXPORT_ALL_ERROR, error);
export const importedOperationsReexportAllResetState = () =>
    action(ImportedOperationsReexportAllTypes.IMPORTED_OPERATIONS_REEXPORT_ALL_RESET_STATE);
