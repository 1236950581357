import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import FormatterUtils from 'components/formatter/formatter-utils';
import DateRangePicker from 'components/inputs/date-input/date-range-picker/date-range-picker';
import SimpleDatePicker from 'components/inputs/date-input/simple-data-picker/simple-date-picker';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import BatchInputSearch from 'entities/ccb-operation/components/batch-input-search/batch-input-search';
import { OperationStatus } from 'model/enums/operation-status';
import { ProductType } from 'model/enums/product-type';
import { DateRange, OperationFilterRequest } from 'model/imported-operation';
import { ScdProgramSimple } from 'model/program';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchBatchesRequest } from 'reducer/batch/search-batches/actions';
import { searchProgramsRequest, searchProgramsResetState } from 'reducer/program/search-programs/actions';
import { valuesOfObject } from 'shared/util/object-utils';
import './operation-modal-filter.scss';

export type OperationModalFilterType =
    | 'filters'
    | 'acceptedDate'
    | 'product'
    | 'reexport'
    | 'createBatch'
    | 'ccb'
    | 'batch'
    | 'errors'
    | 'originator';

export interface OperationModalFilterProps {
    onClose: () => void;
    modalFilter: OperationModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: OperationFilterRequest;
    setFilterActive: (value: OperationFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    createBatch: filter => !filter.scdProgram?.id,
    reexport: filter => !filter.ccbNumber,
    acceptedDate: filter => !filter.acceptedDate,
    batch: filter => !filter.batchId,
    ccb: filter => !filter.ccbNumber,
    product: filter => !filter.type,
    originator: filter => !filter.originator,
    errors: filter => !filter.handleErrorMode,
    filters: filter => valuesOfObject({ ...filter }).filter(it => it !== undefined).length === 0,
} as Record<OperationModalFilterType, (filter: OperationFilterRequest) => boolean>;

export interface CreateBatchFilterRequest {
    programId?: number;
    createdDate?: string;
}

export const OperationModalFilter = (props: OperationModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;
    const [filterRequest, setFilterRequest] = useState<OperationFilterRequest>(filterActive);
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        if (filterActive) {
            setFilterRequest(filterActive);
        }
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);

        if (modalFilter === 'createBatch') {
            const _createBatchParams: CreateBatchFilterRequest = {
                programId: Number(filterRequest.scdProgram?.id),
                createdDate: filterRequest.createdDate,
            };

            history.push(`${url}/create-batch`, _createBatchParams);
            onClose();
            return;
        }

        if (modalFilter === 'reexport') {
            setFilterActive({
                ...filterRequest,
                status: [OperationStatus.EXPORTED],
            });
            onClose();
            return;
        }

        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<OperationFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleChangeStatus = (operationStatus: OperationStatus) => {
        const hasSelectedStatus = filterRequest?.status?.some(status => status === operationStatus);

        if (hasSelectedStatus) {
            const filteredData = filterRequest?.status?.filter(status => status !== operationStatus);
            handleChange({ status: filteredData });
            return;
        }

        handleChange({ status: filterRequest?.status?.concat(operationStatus) });
    };

    const handleClearFilter = {
        createBatch: () => {
            setFilterActive({ ...filterActive, createdDate: undefined, scdProgram: undefined });
            setFilterRequest({ ...filterRequest, createdDate: undefined, scdProgram: undefined });
        },

        product: () => {
            setFilterActive({ ...filterActive, type: undefined });
            setFilterRequest({ ...filterRequest, type: undefined });
        },
        acceptedDate: () => {
            setFilterActive({ ...filterActive, acceptedDate: undefined });
            setFilterRequest({ ...filterRequest, acceptedDate: undefined });
        },
        reexport: () => {
            setFilterActive({ ...filterActive, ccbNumber: undefined, status: [] });
            setFilterRequest({ ...filterRequest, ccbNumber: undefined, status: [] });
        },
        filters: () => {
            setFilterActive({ todayOnly: filterActive.todayOnly, status: [] });
            setFilterRequest({ status: [] });
        },
        originator: () => {
            setFilterActive({ ...filterActive, originator: undefined });
            setFilterRequest({ ...filterRequest, originator: undefined });
        },
        errors: () => {
            setFilterActive({ ...filterActive, handleErrorMode: undefined });
            setFilterRequest({ ...filterRequest, handleErrorMode: undefined });
        },
        ccb: () => {
            setFilterActive({ ...filterActive, ccbNumber: undefined });
            setFilterRequest({ ...filterRequest, ccbNumber: undefined });
        },
    } as Record<OperationModalFilterType, () => void>;

    const handleChangeDate = (newDateRange: DateRange) => {
        setFilterRequest(prevState => ({
            ...prevState,
            acceptedDate: {
                start: FormatterUtils.formatDateToLocalDate(newDateRange.start) ?? undefined,
                end: FormatterUtils.formatDateToLocalDate(newDateRange.end) ?? undefined,
            },
        }));
    };

    const AcceptedDateFilter: () => JSX.Element = () => {
        const acceptedDateStart = filterRequest.acceptedDate?.start;
        const acceptedDateEnd = filterRequest.acceptedDate?.end;

        return (
            <Grid item xs={12}>
                <DateRangePicker
                    dateRange={{
                        start:
                            acceptedDateStart && FormatterUtils.isValidDate(acceptedDateStart)
                                ? FormatterUtils.dateWithoutTimezone(acceptedDateStart)
                                : null,
                        end:
                            acceptedDateEnd && FormatterUtils.isValidDate(acceptedDateEnd)
                                ? FormatterUtils.dateWithoutTimezone(acceptedDateEnd)
                                : null,
                    }}
                    setDateRange={handleChangeDate}
                />
            </Grid>
        );
    };

    const OriginatorFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleSearchInput
                    onChange={originator => handleChange({ originator })}
                    label={t(`${I18N_PREFIX}.inputs.originator`)}
                    placeholder={t(`${I18N_PREFIX}.inputs.originator`)}
                    value={filterRequest?.originator ?? ''}
                />
            </Grid>
        </>
    );

    const ProductFilter: JSX.Element = (
        <div className="operation-modal-filter--content">
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.type === ProductType.CREDIT_CARD,
                })}
                onClick={() => handleChange({ type: ProductType.CREDIT_CARD })}
            >
                {t(`${I18N_PREFIX}.product.options.CREDIT_CARD`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.type === ProductType.DIRECT_CONSUMER_CREDIT,
                })}
                onClick={() => handleChange({ type: ProductType.DIRECT_CONSUMER_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.DIRECT_CONSUMER_CREDIT`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('operation-modal-filter--content-product--label', {
                    active: filterRequest.type === ProductType.PERSONAL_CREDIT,
                })}
                onClick={() => handleChange({ type: ProductType.PERSONAL_CREDIT })}
            >
                {t(`${I18N_PREFIX}.product.options.PERSONAL_CREDIT`).toLocaleUpperCase()}
            </div>
        </div>
    );
    const ProgramFilter: JSX.Element = (
        <Grid item xs={12}>
            <div>
                <ModalSearch<ScdProgramSimple>
                    action={searchProgramsRequest}
                    itemSize={SEARCH_ITEM_SIZE}
                    modalTitle={t(`${I18N_PREFIX}.inputs.programs`)}
                    modalLabel={t(`${I18N_PREFIX}.inputs.programs`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.programs`)}
                    onSelect={scdProgram => handleChange({ scdProgram })}
                    renderItem={scdProgram => scdProgram.name}
                    statusSelector={(state: IRootState) => state.searchPrograms.status}
                    dataSelector={(state: IRootState) => state.searchPrograms.programs}
                    resetState={searchProgramsResetState}
                >
                    {handleOpen => (
                        <>
                            <SimpleSearchInput
                                label={t(`${I18N_PREFIX}.inputs.programs`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.programs`)}
                                value={filterRequest.scdProgram?.name ?? ''}
                                onClick={handleOpen}
                                onFocus={handleOpen}
                                readOnly
                                externalUpdate
                            />
                        </>
                    )}
                </ModalSearch>
            </div>
        </Grid>
    );

    const handleChangeCreatedDate = (date: Date | null) => {
        setFilterRequest(prev => ({
            ...prev,
            createdDate: date ? FormatterUtils.formatDateToLocalDate(date) ?? undefined : undefined,
        }));
    };

    const CreatedDateFilter: JSX.Element = (
        <Grid item xs={12}>
            <SimpleDatePicker
                selectedDate={filterRequest.createdDate ? FormatterUtils.validDateWithoutTimezone(filterRequest.createdDate) : null}
                setSelectedDate={handleChangeCreatedDate}
                label={t(`${I18N_PREFIX}.inputs.createdDate`)}
            />
        </Grid>
    );

    const StatusFilter: JSX.Element = (
        <div className="operation-modal-filter--content">
            <div
                className={clsx('operation-modal-filter--content-status--label', {
                    NEW: filterRequest.status?.some(status => status === OperationStatus.NEW) || modalFilter === 'createBatch',
                })}
                onClick={() => {
                    handleChangeStatus(OperationStatus.NEW);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.NEW`).toLocaleUpperCase()}
            </div>
            {modalFilter !== 'createBatch' && filterActive.todayOnly === false && (
                <>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            RECEIVED: filterRequest.status?.some(status => status === OperationStatus.RECEIVED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.RECEIVED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.RECEIVED`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            PENDING_APPROVAL: filterRequest.status?.some(status => status === OperationStatus.PENDING_APPROVAL),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.PENDING_APPROVAL);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.PENDING_APPROVAL`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            APPROVED: filterRequest.status?.some(status => status === OperationStatus.APPROVED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.APPROVED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.APPROVED`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            PROCESSED: filterRequest.status?.some(status => status === OperationStatus.PROCESSED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.PROCESSED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.PROCESSED`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            EXPORTED: filterRequest.status?.some(status => status === OperationStatus.EXPORTED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.EXPORTED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.EXPORTED`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            ERROR: filterRequest.status?.some(status => status === OperationStatus.ERROR),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.ERROR);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.ERROR`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            ARCHIVED: filterRequest.status?.some(status => status === OperationStatus.ARCHIVED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.ARCHIVED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.ARCHIVED`).toLocaleUpperCase()}
                    </div>
                    <div
                        className={clsx('operation-modal-filter--content-status--label', {
                            DISAPPROVED: filterRequest.status?.some(status => status === OperationStatus.DISAPPROVED),
                        })}
                        onClick={() => {
                            handleChangeStatus(OperationStatus.DISAPPROVED);
                        }}
                    >
                        {t(`${I18N_PREFIX}.status.options.DISAPPROVED`).toLocaleUpperCase()}
                    </div>
                </>
            )}
            <div
                className={clsx('operation-modal-filter--content-status--label', {
                    BATCHLESS: filterRequest.status?.some(status => status === OperationStatus.BATCHLESS) || modalFilter === 'createBatch',
                })}
                onClick={() => {
                    handleChangeStatus(OperationStatus.BATCHLESS);
                }}
            >
                {t(`${I18N_PREFIX}.status.options.BATCHLESS`).toLocaleUpperCase()}
            </div>
        </div>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);

    return (
        openModal && (
            <Modal isOpen>
                <div className="operation-modal-filter">
                    <div className="operation-modal-filter--header">
                        <label className="operation-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            <Grid item xs={6}>
                                                <SimpleSearchInput
                                                    onChange={ccbNumber => handleChange({ ccbNumber })}
                                                    label={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    value={filterRequest?.ccbNumber ?? ''}
                                                />
                                            </Grid>
                                            <Grid item xs={6} style={{ position: 'relative' }}>
                                                <BatchInputSearch
                                                    action={searchBatchesRequest}
                                                    onSelect={batch => handleChange({ batchId: batch.id, batchCode: batch.code })}
                                                    label={t(`${I18N_PREFIX}.inputs.batch`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.batch`)}
                                                    value={filterRequest?.batchCode ? String(filterRequest?.batchCode) : ''}
                                                    itemSize={SEARCH_ITEM_SIZE}
                                                    renderItem={favored => favored?.code}
                                                    statusSelector={(state: IRootState) => state.searchBatches.status}
                                                    dataSelector={(state: IRootState) => state.searchBatches.batches}
                                                />
                                            </Grid>
                                            {AcceptedDateFilter}
                                            {ProductFilter}
                                            {OriginatorFilter}
                                            <Grid item xs={12}>
                                                <SimpleSearchInput
                                                    onChange={identification => handleChange({ identification })}
                                                    label={t(`${I18N_PREFIX}.inputs.loanerIdentification`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.loanerIdentification`)}
                                                    value={filterRequest?.identification ?? ''}
                                                />
                                            </Grid>
                                            {StatusFilter}
                                        </>
                                    ),
                                    acceptedDate: AcceptedDateFilter(),
                                    originator: OriginatorFilter,
                                    product: ProductFilter,
                                    ccb: (
                                        <>
                                            <Grid item xs={12}>
                                                <SimpleSearchInput
                                                    onChange={ccbNumber => handleChange({ ccbNumber })}
                                                    label={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    value={filterRequest?.ccbNumber ?? ''}
                                                />
                                            </Grid>
                                        </>
                                    ),
                                    batch: (
                                        <>
                                            <Grid item xs={12} style={{ position: 'relative', height: '200px' }}>
                                                <BatchInputSearch
                                                    action={searchBatchesRequest}
                                                    onSelect={batch => handleChange({ batchId: batch.id, batchCode: batch.code })}
                                                    label={t(`${I18N_PREFIX}.inputs.batch`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.batch`)}
                                                    value={filterRequest?.batchCode ? String(filterRequest?.batchCode) : ''}
                                                    itemSize={SEARCH_ITEM_SIZE}
                                                    renderItem={favored => favored?.code}
                                                    statusSelector={(state: IRootState) => state.searchBatches.status}
                                                    dataSelector={(state: IRootState) => state.searchBatches.batches}
                                                />
                                            </Grid>
                                        </>
                                    ),
                                    reexport: (
                                        <>
                                            <Grid item xs={12}>
                                                <SimpleSearchInput
                                                    onChange={ccbNumber => handleChange({ ccbNumber })}
                                                    label={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.ccbNumber`)}
                                                    value={filterRequest?.ccbNumber ?? ''}
                                                />
                                            </Grid>
                                            <div
                                                className={clsx('operation-modal-filter--content-status--label', {
                                                    EXPORTED: true,
                                                })}
                                            >
                                                {t(`${I18N_PREFIX}.status.options.EXPORTED`).toLocaleUpperCase()}
                                            </div>
                                        </>
                                    ),
                                    createBatch: (
                                        <>
                                            <Grid item xs={12}>
                                                <div className="operation-modal-filter--content-batchless-input">
                                                    <span className="operation-modal-filter--content-batchless-input__label">
                                                        {t(`${I18N_PREFIX}.batch`)}
                                                    </span>
                                                    <span className="operation-modal-filter--content-batchless-input__value">
                                                        {t(`${I18N_PREFIX}.batchless`)}
                                                    </span>
                                                </div>
                                            </Grid>
                                            {ProgramFilter}
                                            {CreatedDateFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                } as Record<OperationModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="batch-modal-filter--buttons-actions">
                        <ClearButton
                            onClick={handleClearFilter[modalFilter]}
                            disabled={isCleanButtonDisabled && modalFilter !== 'createBatch'}
                        />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default OperationModalFilter;
