import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { OperationFareReports } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';
import {
    fareAmountReportError,
    fareAmountReportRequest,
    fareAmountReportSuccess,
} from './actions';
import { FareAmountReportTypes } from './types';

function* handleFareAmountReport(action: ReturnType<typeof fareAmountReportRequest>) {
    try {
        const result: AxiosResponse<OperationFareReports> = yield call(importedOperationApi.recoverFareAmountReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(fareAmountReportError(mapped));
            return;
        }
        yield put(fareAmountReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(fareAmountReportError(mapped));
    }
}

function* watchFareAmountReport() {
    yield takeLeading(FareAmountReportTypes.FARE_AMOUNT_REPORT_REQUEST, handleFareAmountReport);
}

function* fareAmountReportSaga() {
    yield all([fork(watchFareAmountReport)]);
}

export default fareAmountReportSaga;
