import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdIssuerPage from 'entities/issuer/issuer';
import ScdIssuerCreateUpdatePage from 'entities/issuer/issuer-create-update/issuer-create-update';
import ScdIssuerDetailPage from 'entities/issuer/issuer-detail/issuer-detail';
import ListIssuerPolicies from 'entities/issuer/issuer-policies/issuer-policies';
import IssuerPolicyDetail from 'entities/issuer/issuer-policies/issuer-policies-detail/issuer-policies-detail';
import IssuerPolicyCreateUpdate from 'entities/issuer/issuer-policies/policies-create-update/policies-create-update';
import IssuerPolicyIntegrationCreateUpdate from 'entities/issuer/issuer-policies/issuer-policy-integration/integration-create-update/integration-create-update';

interface MatchParams {
    url: string;
}

const IssuerRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/:issuerId/policies/:issuerPolicyId/integration/:integrationId/edit`} component={IssuerPolicyIntegrationCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:issuerId/policies/:issuerPolicyId/integration/new`} component={IssuerPolicyIntegrationCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:issuerId/policies/new`} component={IssuerPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:issuerId/policies`} component={ListIssuerPolicies} />
        <PrivateRoute exact path={`${match.url}/:issuerId/policies/:issuerPolicyId`} component={IssuerPolicyDetail} />
        <PrivateRoute exact path={`${match.url}/:issuerId/policies/:issuerPolicyId/edit`} component={IssuerPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/new`} component={ScdIssuerCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:issuerId/edit`} component={ScdIssuerCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:issuerId`} component={ScdIssuerDetailPage} />
        <PrivateRoute exact path={`${match.url}`} component={ScdIssuerPage} />
    </Switch>
);

export default IssuerRoutes;
