import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionDocumentSimple } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import { detailMonthlyCommissionRequest } from 'reducer/monthly-commission/detail-monthly-commission/actions';
import {
    monthlyCommissionDeleteDocumentError,
    monthlyCommissionDeleteDocumentRequest,
    monthlyCommissionDeleteDocumentSuccess,
    monthlyCommissionDownloadDocumentError,
    monthlyCommissionDownloadDocumentRequest,
    monthlyCommissionDownloadDocumentSuccess,
    monthlyCommissionUploadDocumentError,
    monthlyCommissionUploadDocumentRequest,
    monthlyCommissionUploadDocumentSuccess,
} from 'reducer/monthly-commission/document/actions';
import { MonthlyCommissionDocumentTypes } from 'reducer/monthly-commission/document/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleMonthlyCommissionUploadDocument(action: ReturnType<typeof monthlyCommissionUploadDocumentRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionDocumentSimple> = yield call(monthlyCommissionAPI.uploadDocument, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionUploadDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionUploadDocumentSuccess(result.data));
        yield put(detailMonthlyCommissionRequest(action.payload.commissionId));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionUploadDocumentError(mapped));
    }
}

function* watchMonthlyCommissionUploadDocument() {
    yield takeLeading(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_REQUEST, handleMonthlyCommissionUploadDocument);
}

function* handleMonthlyCommissionDownloadDocument(action: ReturnType<typeof monthlyCommissionDownloadDocumentRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(monthlyCommissionAPI.downloadDocument, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionDownloadDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionDownloadDocumentSuccess(result.data));

        const _file_name = result.headers['content-disposition']?.split('filename=')[1];
        const _type = result.headers['content-type'];
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _file_name);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionDownloadDocumentError(mapped));
    }
}

function* watchMonthlyCommissionDownloadDocument() {
    yield takeLeading(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_REQUEST, handleMonthlyCommissionDownloadDocument);
}

function* handleMonthlyCommissionDeleteDocument(action: ReturnType<typeof monthlyCommissionDeleteDocumentRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(monthlyCommissionAPI.deleteDocument, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionDeleteDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionDeleteDocumentSuccess());
        yield put(detailMonthlyCommissionRequest(action.payload.commissionId));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionDeleteDocumentError(mapped));
    }
}

function* watchMonthlyCommissionDeleteDocument() {
    yield takeLeading(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_REQUEST, handleMonthlyCommissionDeleteDocument);
}

function* monthlyCommissionDocumentSaga() {
    yield all([
        fork(watchMonthlyCommissionUploadDocument),
        fork(watchMonthlyCommissionDownloadDocument),
        fork(watchMonthlyCommissionDeleteDocument),
    ]);
}

export default monthlyCommissionDocumentSaga;
