import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentTransactionToSimple } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import { createInternalRepaymentPaymentTransactionError, createInternalRepaymentPaymentTransactionRequest, createInternalRepaymentPaymentTransactionSuccess } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/actions';
import { CreateInternalRepaymentPaymentTransactionTypes } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionApi from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateInternalRepaymentPaymentTransaction(action: ReturnType<typeof createInternalRepaymentPaymentTransactionRequest>) {
    try {
        const result: AxiosResponse<PaymentTransactionToSimple> = yield call(
            paymentTransactionApi.createInternalRepaymentPaymentTransaction,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createInternalRepaymentPaymentTransactionError(mapped));
            return;
        }
        yield put(createInternalRepaymentPaymentTransactionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createInternalRepaymentPaymentTransactionError(mapped));
    }
}

function* watchCreateInternalRepaymentPaymentTransaction() {
    yield takeLeading(CreateInternalRepaymentPaymentTransactionTypes.CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_REQUEST, handleCreateInternalRepaymentPaymentTransaction);
}

function* createInternalRepaymentPaymentTransactionSaga() {
    yield all([fork(watchCreateInternalRepaymentPaymentTransaction)]);
}

export default createInternalRepaymentPaymentTransactionSaga;
