import { ScdImportedOperationsData } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsListTypes {
    IMPORTED_OPERATIONS_LIST_REQUEST = '@@imported-operations-list/IMPORTED_OPERATIONS_LIST_REQUEST',
    IMPORTED_OPERATIONS_LIST_SUCCESS = '@@imported-operations-list/IMPORTED_OPERATIONS_LIST_SUCCESS',
    IMPORTED_OPERATIONS_LIST_ERROR = '@@imported-operations-list/IMPORTED_OPERATIONS_LIST_ERROR',
    IMPORTED_OPERATIONS_LIST_RESET_STATE = '@@imported-operations-list/IMPORTED_OPERATIONS_LIST_RESET_STATE',
}

export interface ImportedOperationsListState extends BaseStoreState {
    readonly operations?: Page<ScdImportedOperationsData>;
}
