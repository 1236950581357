import CloseButton from 'components/buttons/close-button/close-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import { BatchExportResult } from 'model/enums/batch-export-result';
import { hasMappedErrorCode } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { batchExportRequest, batchExportResetState } from 'reducer/batch/batch-export/action';
import { useBatchExportDetail, useRootDispatch } from 'reducer/hooks';
import './modal-batch-export.scss';

interface ModalDetailProps {
    batchId?: number;
    exportId?: number;
    openModal: boolean;
    setOpenModal: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch-export-modal';

const ModalBatchExport = (props: ModalDetailProps) => {
    const { batchId, exportId, openModal, setOpenModal } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const modalRef = useRef<HTMLDivElement | null>(null);

    const { status, batchExport } = useBatchExportDetail();

    const handleExportDetail = useCallback(() => {
        if (!batchId || !exportId) return;
        const request = {
            batchId,
            exportId,
        };
        dispatch(batchExportRequest(request));
    }, [dispatch, batchId, exportId]);

    useEffect(() => {
        handleExportDetail();
    }, [handleExportDetail]);

    useEffect(() => {
        return () => {
            dispatch(batchExportResetState());
        };
    }, [dispatch]);

    const toggle = () => setOpenModal(false);

    const loading = status !== HttpRequestStatus.SUCCESS;

    return (
        <Modal isOpen={openModal} toggle={toggle}>
            <div className="modal-detail" ref={modalRef}>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <header className="modal-detail-header">
                            <span className="modal-detail--detail-title">{t(`${I18N_PREFIX}.title`)}</span>
                            <CloseButton onClick={() => setOpenModal(false)} />
                        </header>
                        <div className="modal-detail--detail">
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.batch`)} value={batchExport?.batchId} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.type.label`)}
                                value={t(`${I18N_PREFIX}.type.options.${batchExport?.type}`)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.result.label`)}
                                value={t(`${I18N_PREFIX}.result.options.${batchExport?.result}`)}
                            />
                            {BatchExportResult.FAILURE === batchExport?.result && (
                                <ReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.error-code.label`)}
                                    value={t(
                                        `${
                                            hasMappedErrorCode(batchExport.errorCode)
                                                ? batchExport.errorCode
                                                : t(`${I18N_PREFIX}.error-code.fallback`)
                                        }`
                                    )}
                                />
                            )}
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.platform.title`)} value={batchExport?.platform?.name} />
                        </div>
                        <div className="modal-detail--buttons">
                            <OutlinedButtonLegacy label="entity.action.close" onClick={() => setOpenModal(false)} />
                            <StandardButtonLegacy label="entity.action.update" onClick={handleExportDetail} />
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default ModalBatchExport;
