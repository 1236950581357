import { Grid } from '@material-ui/core';
import BatchCard from 'entities/batch/components/batch-card/batch-card';
import { ProgramToBatchDetail } from 'model/program';
import { useTranslation } from 'react-i18next';

interface ProgramCardProps {
    program?: ProgramToBatchDetail;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const BatchProgramCard = (props: ProgramCardProps) => {
    const { t } = useTranslation();

    const { program } = props;

    return (
        <Grid item hidden={!program}>
            <BatchCard
                title={t(`${I18N_PREFIX}.titles.program`)}
                hasError={false}
                link={`/programs/${program?.id}`}
            >
                <span>{program?.name ?? ''}</span>
            </BatchCard>
        </Grid>
    );
};

export default BatchProgramCard;
