import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdBatchSearch } from 'model/batch';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchBatchesTypes } from 'reducer/batch/search-batches/types';
import { searchBatchesError, searchBatchesSuccess, searchBatchesRequest } from 'reducer/batch/search-batches/actions';
import { Page } from 'services/page';

function* handleSearchBatches(action: ReturnType<typeof searchBatchesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdBatchSearch>> = yield call(batchAPI.searchBatches, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchBatchesError(mapped));
            return;
        }
        yield put(searchBatchesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchBatchesError(mapped));
    }
}

function* watchSearchBatches() {
    yield takeEvery(SearchBatchesTypes.SEARCH_BATCHES_REQUEST, handleSearchBatches);
}

function* searchBatchesSaga() {
    yield all([fork(watchSearchBatches)]);
}

export default searchBatchesSaga;
