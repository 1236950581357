import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import {
    useBatchOutputEndorsementState,
    useBatchSignatureExportState,
    useBatchSignatureImportState,
    useBatchSignatureResumeState,
    useDetailBatchState,
    useRootDispatch,
} from 'reducer/hooks';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ScdBatchEndorsementList from 'entities/batch/batch-endorsement/batch-endorsement-list/batch-endorsement-list';
import ScdBatchEndorsementCounters from 'entities/batch/batch-endorsement/components/batch-endorsement-counters/batch-endorsement-counters';
import ScdBatchEndorsementHeader from 'entities/batch/batch-endorsement/components/batch-endorsement-header/batch-endorsement-header';
import ScdBatchEndorsementSlideButtons from 'entities/batch/batch-endorsement/components/batch-endorsement-slide-buttons/batch-endorsement-slide-buttons';
import { BatchSignatureExportRequest, BatchSignatureRequest } from 'model/batch-signature';
import { BatchStatus } from 'model/enums/batch-status';
import { batchOutputEndorsementRequest, batchOutputEndorsementResetState } from 'reducer/batch/batch-output-endorsement/action';
import { batchSignatureExportRequest, batchSignatureExportResetState } from 'reducer/batch/batch-signature-export/action';
import { batchSignatureImportResetState } from 'reducer/batch/batch-signature-import/action';
import { batchSignatureResumeRequest, batchSignatureResumeResetState } from 'reducer/batch/batch-signature-resume/action';
import { detailBatchRequest, detailBatchResetState } from 'reducer/batch/detail-batch/actions';
import { useToast } from 'shared/hooks/use-toast';
import './batch.endorsement.scss';

type BatchEndorsementProps = RouteComponentProps<{ batchId: string }>;

enum StorageLoadingType {
    IS_LOADING = 'isLoading',
}
type StorageLoading = StorageLoadingType | null;

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsement = (props: BatchEndorsementProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();

    const { toastSuccess, toastLoading, closeToast } = useToast();
    const { status: signaturesExportStatus } = useBatchSignatureExportState();
    const { status: importedStatus } = useBatchSignatureImportState();

    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [signatureIds, setSignatureIds] = useState<number[]>([]);
    const [modalMessage, setModalMessage] = useState<boolean>(false);

    const [selectAllCheckBox, setSelectAllCheckBox] = useState<boolean>(false);

    const { batch, status: batchStatus } = useDetailBatchState();
    const { outputEndorsement, status: endorsementStatus } = useBatchOutputEndorsementState();
    const { signatureResume } = useBatchSignatureResumeState();

    const [hasStorageLoading, setHasStorageLoading] = useState<StorageLoading>(null);

    useEffect(() => {
        if (batch?.code) {
            setHasStorageLoading(localStorage.getItem(batch.code) as StorageLoading);
        }
    }, [batch]);

    useEffect(() => {
        dispatch(detailBatchRequest(batchId));
        dispatch(batchOutputEndorsementRequest(batchId));
    }, [dispatch, batchId]);

    const handleSignatureList = useCallback(() => {
        if (!outputEndorsement?.id) return;
        const request: BatchSignatureRequest = {
            batchId: batchId,
            outputId: outputEndorsement?.id,
        };
        dispatch(batchSignatureResumeRequest(request));
    }, [dispatch, batchId, outputEndorsement?.id]);

    useEffect(() => {
        handleSignatureList();
    }, [handleSignatureList]);

    const handleSwitch = (value: boolean) => {
        setIsChecked(value);
    };

    const handleRedirect = () => {
        dispatch(batchOutputEndorsementResetState());
        history.push(`${url}/imports`);
    };

    const handleExportSignatures = () => {
        if (batch?.code) {
            localStorage.setItem(batch?.code, StorageLoadingType.IS_LOADING);
            setHasStorageLoading(StorageLoadingType.IS_LOADING);
        }

        if (!signatureResume?.batchId || !outputEndorsement?.id) return;
        const request: BatchSignatureExportRequest = {
            batchId: batchId,
            outputId: outputEndorsement?.id,
            signatureIds: signatureIds,
        };
        dispatch(batchSignatureExportRequest(request));
        setSignatureIds([]);
        setModalMessage(false);
        setIsChecked(false);
    };

    const handleModalExport = () => {
        setModalMessage(true);
    };

    useEffect(() => {
        if (signaturesExportStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.signatures.process`));
    }, [signaturesExportStatus, toastSuccess, t]);

    useEffect(() => {
        if (importedStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
        closeToast();
    }, [importedStatus, toastLoading, closeToast]);

    useEffect(() => {
        if (importedStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.imported.success`));
        closeToast();
    }, [importedStatus, toastSuccess, t, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(detailBatchResetState());
            dispatch(batchOutputEndorsementResetState());
            dispatch(batchSignatureResumeResetState());
            dispatch(batchSignatureExportResetState());
            dispatch(batchSignatureImportResetState());
        };
    }, [dispatch]);

    const loading = endorsementStatus === HttpRequestStatus.ON_GOING || batchStatus === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        let intervalId: NodeJS.Timeout | null = null;
        if (hasStorageLoading && signatureResume?.signatures) {
            const hasExport = signatureResume.signatures.every(signature => signature.exports.length > 0);

            if (!hasExport) {
                intervalId = setInterval(handleSignatureList, 30000);
            } else {
                if (batch?.code) localStorage.removeItem(batch?.code);
                setHasStorageLoading(null);
            }
        }
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [hasStorageLoading, signatureResume, handleSignatureList, batch]);

    return (
        <main className="scd-batch-endorsement">
            <ContextRibbon />
            {loading ? (
                <Loading />
            ) : (
                <section className="scd-batch-endorsement__container">
                    {outputEndorsement && (batch?.status === BatchStatus.CLOSED || BatchStatus.CONCLUDED) ? (
                        <>
                            {hasStorageLoading ? (
                                <div className="scd-batch-endorsement__container__loading-box">
                                    <h2 className="scd-batch-endorsement__header-container--title">{t(`${I18N_PREFIX}.loading.title`)}</h2>
                                    <div className="scd-batch-endorsement__header-container--title">
                                        {t(`${I18N_PREFIX}.loading.sub-title`)}
                                    </div>
                                    <Loading />
                                </div>
                            ) : (
                                <>
                                    <ScdBatchEndorsementHeader
                                        batch={batch}
                                        outputEndorsement={outputEndorsement}
                                        handleSwitch={handleSwitch}
                                        setIsChecked={setIsChecked}
                                        isChecked={isChecked}
                                        handleList={handleSignatureList}
                                        listEndorsementOrImports={signatureResume?.signatures}
                                    />

                                    <ScdBatchEndorsementCounters action="sent" batch={batch} signatureResume={signatureResume} />
                                    <ScdBatchEndorsementList
                                        signatureResume={signatureResume}
                                        isChecked={isChecked}
                                        signatureIds={signatureIds}
                                        setSignatureIds={setSignatureIds}
                                        modalMessage={modalMessage}
                                        setModalMessage={setModalMessage}
                                        handleExportSignatures={handleExportSignatures}
                                        selectAllCheckBox={selectAllCheckBox}
                                        setSelectAllCheckBox={setSelectAllCheckBox}
                                    />
                                </>
                            )}

                            <ScdBatchEndorsementSlideButtons
                                handleAction={handleModalExport}
                                signatureIds={signatureIds}
                                handleRedirect={handleRedirect}
                                batchId={batchId}
                                handleSwitch={handleSwitch}
                                setSignatureIds={setSignatureIds}
                                isChecked={isChecked}
                                handleModalExport={handleModalExport}
                            />
                        </>
                    ) : (
                        <ModalMessageLegacy
                            title={t(`${I18N_PREFIX}.not-found.title`)}
                            message={t(`${I18N_PREFIX}.not-found.message`)}
                            onClose={history.goBack}
                            onAction={history.goBack}
                            standardButtonLabel={t(`${I18N_PREFIX}.buttons.modal.back`)}
                        />
                    )}
                </section>
            )}
        </main>
    );
};

export default ScdBatchEndorsement;
