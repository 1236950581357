import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { useTranslation } from 'react-i18next';
import './standard-button.scss';

export interface StandardButtonProps {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
}

export const StandardButton = (props: StandardButtonProps): JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!props.disabled && props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className="button__style--standard">
            <Button variant="contained" onClick={handleClick} disabled={props.disabled}>
                <span> {t(props.label)} </span>
                <span> {props.adornment} </span>
            </Button>
        </div>
    );
};

export default StandardButton;
