import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { CompanyBankAccount } from 'model/company-bank-account';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';

interface CompanyAccountCardDetailProps {
    entity: CompanyBankAccount;
}

const I18N_PREFIX = 'pages.scd.company.card.accounts.input';

const CompanyAccountCardDetail = (props: CompanyAccountCardDetailProps) => {
    const { entity } = props;

    const { t } = useTranslation();

    return (
        <div className="card-edition__container--content">
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.favoredName.label`)} value={entity.favoredName} />
            </div>
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField
                    label={t(`${I18N_PREFIX}.favoredIdentification.label`)}
                    value={
                        <IdentificationFormatter value={entity.favoredIdentification} type={getPersonType(entity.favoredIdentification)} />
                    }
                />
            </div>
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.bankId.label`)} value={entity.bankName} />
            </div>
            <div className="scd-company-create-update--form-card-double-fields">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.agencyNumber.label`)} value={entity.agencyNumber} />
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.agencyDigit.label`)} value={entity.agencyDigit} />
            </div>
            <div className="scd-company-create-update--form-card-double-fields">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.accountNumber.label`)} value={entity.accountNumber} />
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.accountDigit.label`)} value={entity.accountDigit} />
            </div>
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.pixKey.label`)} value={entity.pixKey} />
            </div>
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.type.label`)} value={t(`${I18N_PREFIX}.type.options.${entity.type}`)} />
            </div>
        </div>
    );
};

export default CompanyAccountCardDetail;
