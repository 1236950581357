import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationSimple } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsArchiveOneError,
    importedOperationsArchiveOneRequest,
    importedOperationsArchiveOneSuccess,
} from 'reducer/imported-operation/archive-one/actions';
import { ImportedOperationsArchiveOneTypes } from 'reducer/imported-operation/archive-one/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';


function* handleImportedOperationsArchiveOne(action: ReturnType<typeof importedOperationsArchiveOneRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationSimple> = yield call(importedOperationApi.archiveOne, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsArchiveOneError(mapped));
            return;
        }
        yield put(importedOperationsArchiveOneSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsArchiveOneError(mapped));
    }
}

function* watchImportedOperationArchiveOne() {
    yield takeEvery(ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_REQUEST, handleImportedOperationsArchiveOne);
}

function* importedOperationsArchiveOneSaga() {
    yield all([fork(watchImportedOperationArchiveOne)]);
}

export default importedOperationsArchiveOneSaga;
