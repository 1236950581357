import { ScdBatchDetail } from 'model/batch';
import { BatchOutputSignaturesResume, BatchSignaturePreviewDocumentsImport } from 'model/batch-signature';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type ScdBatchEndorsementCountersTypes = 'sent' | 'export';

interface ScdBatchEndorsementCountersProps {
    action: ScdBatchEndorsementCountersTypes;
    batch?: ScdBatchDetail;
    signatureResume?: BatchOutputSignaturesResume;
    batchSignature?: BatchSignaturePreviewDocumentsImport;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementCounters = (props: ScdBatchEndorsementCountersProps) => {
    const { t } = useTranslation();

    const { batch, signatureResume, batchSignature, action } = props;

    const Counters: Record<ScdBatchEndorsementCountersTypes, ReactNode> = {
        sent: (
            <div className="scd-batch-endorsement__sub-header">
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.operations`)}</h3>
                    <span className="scd-batch-endorsement--display"> {batch?.numberOfOperations ?? '-'} </span>
                </div>
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.numberOfImported`)}</h3>
                    <span className="scd-batch-endorsement--display"> {signatureResume?.numberOfImported} </span>
                </div>
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.numberOfSent`)}</h3>
                    <span className="scd-batch-endorsement--display">{signatureResume?.numberOfSent}</span>
                </div>
            </div>
        ),
        export: (
            <div className="scd-batch-endorsement__sub-header">
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.operations`)}</h3>
                    <span className="scd-batch-endorsement--display"> {batch?.numberOfOperations ?? '-'} </span>
                </div>
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.numberOfDocuments`)}</h3>
                    <span className="scd-batch-endorsement--display"> {batchSignature?.numberOfUploadedDocuments} </span>
                </div>
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.numberOfDocumentsPendingUpload`)}</h3>
                    <span className="scd-batch-endorsement--display">{batchSignature?.numberOfDocumentsPendingUpload}</span>
                </div>
                <div className="scd-batch-endorsement--counters">
                    <h3>{t(`${I18N_PREFIX}.sub-title.options.numberOfImportedDocuments`)}</h3>
                    <span className="scd-batch-endorsement--display"> {batchSignature?.numberOfImportedDocuments} </span>
                </div>
            </div>
        ),
    };

    return <>{Counters[action]}</>;
};

export default ScdBatchEndorsementCounters;
