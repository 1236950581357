import CloseButton from 'components/buttons/close-button/close-button';
import LinkButton from 'components/buttons/link-button/link-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import BatchInputSearchToRelocate from 'entities/batch/components/batch-input-search-to-relocate/batch-input-search-to-relocate';
import { ScdBatchToRelocate, ScdBatchToRelocateCreateNewRequest, ScdBatchToRelocateToExistingRequest } from 'model/batch';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { createBatchToRelocateRequest } from 'reducer/batch/create-batch-to-relocate/actions';
import { listBatchesToRelocateRequest } from 'reducer/batch/list-batches-to-relocate/actions';
import { relocateToExistingBatchRequest } from 'reducer/batch/relocate-operation-to-existing-batch/actions';

import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { useRootDispatch } from 'reducer/hooks';
import { getPersonType } from 'shared/util/person-type-utils';
import './batch-relocate-operation-modal.scss';

type RelocateModalBatchType = 'relocate' | 'createNew';

interface RelocateOperationBatchModalProps {
    isOpenModal: boolean;
    batchId: number;
    operation: ScdImportedOperationBatch | undefined;
    setIsOpenRelocateModal: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch.detail.modal.relocate-operation';

const RelocateOperationBatchModal = (props: RelocateOperationBatchModalProps) => {
    const { isOpenModal, batchId, operation, setIsOpenRelocateModal } = props;

    const [selectedBatch, setSelectedBatch] = useState<ScdBatchToRelocate | undefined>(undefined);

    const [disableButton, setDisableButton] = useState<boolean>(false);

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const onClose = () => {
        setSelectedBatch(undefined);
        setIsOpenRelocateModal(false);
    };

    useEffect(() => {
        if (isOpenModal) return;

        setSelectedBatch(undefined);
    }, [isOpenModal]);

    const handleRelocateModalBatch = {
        relocate: () => {
            const request: ScdBatchToRelocateToExistingRequest = {
                id: batchId,
                operationId: Number(operation?.id),
                batchTargetId: Number(selectedBatch?.id),
            };

            dispatch(relocateToExistingBatchRequest(request));
        },
        createNew: () => {
            const request: ScdBatchToRelocateCreateNewRequest = { id: batchId, operationId: Number(operation?.id) };
            dispatch(createBatchToRelocateRequest(request));
        },
    } as Record<RelocateModalBatchType, () => void>;

    return (
        <>
            {isOpenModal && (
                <Modal isOpen>
                    <div className="relocate-operation-modal-batch">
                        <article className="relocate-operation-modal-batch--container">
                            <div className="relocate-operation-modal-batch--header-close-button">
                                <CloseButton onClick={onClose} />
                            </div>
                            <div className="relocate-operation-modal-batch--content">
                                <div className="relocate-operation-modal-batch--header">
                                    <label className="relocate-operation-modal-batch--header-title"> {t(`${I18N_PREFIX}.title`)} </label>
                                    <label className="relocate-operation-modal-batch--header-subtitle">
                                        <Trans
                                            t={t}
                                            i18nKey={`${I18N_PREFIX}.subtitle`}
                                            values={{ ccbNumber: operation?.ccbNumber }}
                                            components={{
                                                identification: (
                                                    <IdentificationFormatter
                                                        value={operation?.debtorIdentification}
                                                        type={operation?.debtorType ?? getPersonType(operation?.debtorIdentification)}
                                                    />
                                                ),
                                            }}
                                        />
                                    </label>
                                </div>
                                <div className="relocate-operation-modal-batch--body">
                                    <BatchInputSearchToRelocate
                                        label={t(`${I18N_PREFIX}.inputs.batches`)}
                                        itemSize={SEARCH_ITEM_SIZE}
                                        action={listBatchesToRelocateRequest}
                                        onSelect={batch => setSelectedBatch({ ...batch })}
                                        renderItem={batch =>
                                            batch.originatorName ? `${batch.originatorName} ${batch.code}` : `(Sem programa) ${batch?.code}`
                                        }
                                        statusSelector={(state: IRootState) => state.listBatchesToRelocate.status}
                                        dataSelector={(state: IRootState) => state.listBatchesToRelocate.batches}
                                        value={
                                            selectedBatch?.originatorName && selectedBatch?.code
                                                ? `${selectedBatch?.originatorName} ${selectedBatch?.code}`
                                                : ''
                                        }
                                        batchId={batchId}
                                        placeholder={t(`${I18N_PREFIX}.inputs.batches`)}
                                        setDisableButton={setDisableButton}
                                    />
                                </div>
                                <div className="relocate-operation-modal-batch--buttons-actions">
                                    <LinkButton onClick={onClose} outlinedStyle label={t('entity.action.back')} />
                                    <StandardButtonLegacy
                                        label={t(`${I18N_PREFIX}.buttons.relocate`)}
                                        disabled={!disableButton || !selectedBatch}
                                        onClick={handleRelocateModalBatch.relocate}
                                    />
                                </div>
                                {!disableButton && (
                                    <label
                                        className="relocate-operation-modal-batch--content-link"
                                        onClick={handleRelocateModalBatch.createNew}
                                    >
                                        {t(`${I18N_PREFIX}.buttons.new-batch`).toLocaleUpperCase()}
                                    </label>
                                )}
                            </div>
                        </article>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default RelocateOperationBatchModal;
