import { AxiosResponse } from 'axios';
import { RequestLogModelBrs } from 'model/brs/request-log-brs';
import HttpStatus from 'model/enums/http-status';
import {
    requestLogListBrsError,
    requestLogListBrsRequest,
    requestLogListBrsSuccess
} from 'reducer-brs/request-log/request-log-list/actions';
import { RequestLogsListTypesBrs } from 'reducer-brs/request-log/request-log-list/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import requestLogApi from 'services/brs/request-log-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListRequestLogsBrs(action: ReturnType<typeof requestLogListBrsRequest>) {
    try {
        const result: AxiosResponse<Page<RequestLogModelBrs>> = yield call(requestLogApi.listRequestLogs, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(requestLogListBrsError(mapped));
            return;
        }
        yield put(requestLogListBrsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(requestLogListBrsError(mapped));
    }
}

function* watchListRequestLogsBrs() {
    yield takeEvery(RequestLogsListTypesBrs.REQUEST_LOGS_LIST_BRS_REQUEST, handleListRequestLogsBrs);
}

function* listRequestLogsBrsSaga() {
    yield all([fork(watchListRequestLogsBrs)]);
}

export default listRequestLogsBrsSaga;
