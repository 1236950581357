import { GiroWebappError } from 'model/error';
import { ScdProgramPolicy } from 'model/program-policy';
import { ListProgramPoliciesTypes } from 'reducer/program-policy/list-program-policies/types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listProgramPoliciesRequest = (request?: Pageable) => action(ListProgramPoliciesTypes.LIST_PROGRAM_POLICIES_REQUEST, request);
export const listProgramPoliciesSuccess = (programPolicies: Page<ScdProgramPolicy>) =>
    action(ListProgramPoliciesTypes.LIST_PROGRAM_POLICIES_SUCCESS, programPolicies);
export const listProgramPoliciesError = (error: GiroWebappError) => action(ListProgramPoliciesTypes.LIST_PROGRAM_POLICIES_ERROR, error);
export const listProgramPoliciesResetState = () => action(ListProgramPoliciesTypes.LIST_PROGRAM_POLICIES_RESET_STATE);
