import { BatchCloseDetails } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchCloseDetailsTypes {
    BATCH_CLOSE_DETAILS_REQUEST = '@@batch-close-details/BATCH_CLOSE_DETAILS_REQUEST',
    BATCH_CLOSE_DETAILS_SUCCESS = '@@batch-close-details/BATCH_CLOSE_DETAILS_SUCCESS',
    BATCH_CLOSE_DETAILS_ERROR = '@@batch-close-details/BATCH_CLOSE_DETAILS_ERROR',
    BATCH_CLOSE_DETAILS_RESET = '@@batch-close-details/BATCH_CLOSE_DETAILS_RESET',
}

export interface BatchCloseDetailsState extends BaseStoreState {
    readonly batchCloseInformations?: BatchCloseDetails;
}
