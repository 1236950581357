import { clsx } from 'clsx';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { CessionToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import { IntegrationPolicyStatus } from 'model/enums/integration-policy-status';
import { useTranslation } from 'react-i18next';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import './cession-integration-gtsec-header.scss';

interface CessionIntegrationGtsecHeaderProps {
    batchOutputDetails: BatchOutputDetailsBco;
    batchWithProgram: BatchDetailWithProgram;
    cessionToResume?: CessionToResumeBco;
    issuerPolicyStatus?: IntegrationPolicyStatus;
    handleRefresh: () => void;
    isLoadingCessionToResume: boolean;
    isLoadingIssuerPolicy: boolean;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.general-header';

const CessionIntegrationGtsecHeader: React.FC<CessionIntegrationGtsecHeaderProps> = ({
    batchOutputDetails,
    batchWithProgram,
    cessionToResume,
    issuerPolicyStatus,
    handleRefresh,
    isLoadingCessionToResume,
    isLoadingIssuerPolicy,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <h1 className="batch-outputs-detail__container-title">
                {batchOutputDetails.policy.name}
                <RefreshButton onClick={handleRefresh} />
            </h1>
            <BatchDetailHeader>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.batch`)} isTooltip>
                    {batchWithProgram?.code ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.originator`)} isTooltip>
                    {batchWithProgram?.originator?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.operations`)} isTooltip>
                    {isLoadingCessionToResume ? <BalanceLoading /> : cessionToResume?.numberOfOperations ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.installments`)} isTooltip>
                    {isLoadingCessionToResume ? <BalanceLoading /> : cessionToResume?.numberOfInstallments ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent
                    title={t(`${I18N_PREFIX}.output-method.options.${batchOutputDetails.outputMethod ?? '-'}`)}
                    isTooltip
                >
                    {batchOutputDetails?.platform?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.issuer`)} isTooltip>
                    {batchWithProgram?.issuer?.name ?? '-'}
                </BatchDetailHeaderContent>
                <BatchDetailHeaderContent
                    title={t(`${I18N_PREFIX}.titles.issuerPolicy`)}
                    className={clsx('header-content issuer-policy-status', {
                        PENDING: issuerPolicyStatus === IntegrationPolicyStatus.PENDING,
                        CONFIGURED: issuerPolicyStatus === IntegrationPolicyStatus.CONFIGURED,
                        INCONSISTENT: issuerPolicyStatus === IntegrationPolicyStatus.INCONSISTENT,
                    })}
                >
                    {isLoadingIssuerPolicy ? <BalanceLoading /> : t(`${I18N_PREFIX}.issuer-policies-status.options.${issuerPolicyStatus}`)}
                </BatchDetailHeaderContent>
            </BatchDetailHeader>
        </>
    );
};

export default CessionIntegrationGtsecHeader;
