import { GiroWebappError } from 'model/error';
import { ScdOrganization } from 'model/organization';
import { DetailOrganizationTypes } from './types';
import { action } from 'typesafe-actions';

export const detailOrganizationRequest = (organizationId: number) =>
    action(DetailOrganizationTypes.DETAIL_ORGANIZATION_REQUEST, organizationId);
export const detailOrganizationSuccess = (organization: ScdOrganization) =>
    action(DetailOrganizationTypes.DETAIL_ORGANIZATION_SUCCESS, organization);
export const detailOrganizationError = (error: GiroWebappError) => action(DetailOrganizationTypes.DETAIL_ORGANIZATION_ERROR, error);
export const detailOrganizationResetState = () => action(DetailOrganizationTypes.DETAIL_ORGANIZATION_RESET_STATE);
