import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramGet } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum DetailProgramTypes {
    DETAIL_PROGRAM_REQUEST = '@@program-detail/DETAIL_PROGRAM_REQUEST',
    DETAIL_PROGRAM_SUCCESS = '@@program-detail/DETAIL_PROGRAM_SUCCESS',
    DETAIL_PROGRAM_ERROR = '@@program-detail/DETAIL_PROGRAM_ERROR',
    DETAIL_PROGRAM_RESET_STATE = '@@program-detail/DETAIL_PROGRAM_RESET_STATE',
}

export interface DetailProgramState extends BaseStoreState {
    readonly program?: ScdProgramGet;
}
