import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { ScdBatchToRelocateResponse } from 'model/batch';
import { useTranslation } from 'react-i18next';
import { createBatchToRelocateResetState } from 'reducer/batch/create-batch-to-relocate/actions';
import { relocateToExistingBatchResetState } from 'reducer/batch/relocate-operation-to-existing-batch/actions';
import { useRootDispatch } from 'reducer/hooks';
import './batch-relocate-sucess-modal.scss';

interface BatchRelocateSuccessModalProps {
    createdBatchToRelocate: ScdBatchToRelocateResponse | undefined;
    relocatedBatch: ScdBatchToRelocateResponse | undefined;
}

const I18N_PREFIX = 'pages.scd.batch.relocate-operation.modalSuccess';

const BatchRelocateSuccessModal = (props: BatchRelocateSuccessModalProps) => {
    const { createdBatchToRelocate, relocatedBatch } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const onClose = () => {
        dispatch(createBatchToRelocateResetState());
        dispatch(relocateToExistingBatchResetState());
    };

    return (
        <ModalMessageLegacy onClose={onClose} title={t(`${I18N_PREFIX}.title`)} onAction={onClose}>
            <div className="batch-relocate-success-modal--message">
                <div className="batch-relocate-success-modal--message__text">
                    <span>
                        <strong> {t(`${I18N_PREFIX}.ccbNumber`)} </strong>
                        {createdBatchToRelocate ? createdBatchToRelocate?.ccbNumber : relocatedBatch?.ccbNumber}
                    </span>
                    <span>
                        <strong> {t(`${I18N_PREFIX}.from`)} </strong>
                        {createdBatchToRelocate ? createdBatchToRelocate.batchSourceCode : relocatedBatch?.batchSourceCode}
                    </span>
                    <span>
                        <strong> {t(`${I18N_PREFIX}.to`)} </strong>
                        {createdBatchToRelocate ? createdBatchToRelocate.batchTargetCode : relocatedBatch?.batchTargetCode}
                    </span>
                </div>
            </div>
        </ModalMessageLegacy>
    );
};

export default BatchRelocateSuccessModal;
