import { AxiosResponse } from 'axios'
import { OriginatorsByPaymentInstitutionToSearch, PaymentInstitutionAccountConvertExclusive, PaymentInstitutionAccountDetail, PaymentInstitutionAccountSimple, PaymentInstitutionAccountSimpleToUpdate, PaymentInstitutionAccountToCreateUpdate, PaymentInstitutionAccountToGetAll, PaymentInstitutionAccountToWithdraw, PaymentInstitutionToSearch, SimplePaymentInstitutionAccountToWithdraw } from 'model/payment-institution-account'
import { PaymentInstitutionAccountFilterPageable, SearchRequest } from 'model/reducers'
import api from 'services/api/api'
import { BASE_URL_ADMIN, Page } from 'services/page'

const API_URL = `${BASE_URL_ADMIN}/payment-institution-accounts`

const paymentInstitutionsAccounts = () => {
    const listPaymentInstitutionsAccounts = (request: PaymentInstitutionAccountFilterPageable): Promise<AxiosResponse<Page<PaymentInstitutionAccountToGetAll>>> => {
        const { filter, pageable } = request

        const _requestFilters = [
            filter?.name && `name=${filter.name}`,
            filter?.status && `status=${filter.status}`,
            filter?.type && `type=${filter.type}`,
            filter?.technicalSupplierId && `technicalSupplierId=${filter.technicalSupplierId}`
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<Page<PaymentInstitutionAccountToGetAll>>(`${API_URL}?${_requestFilters}`, { params: { ...pageable } })
    }

    const createPaymentInstitutionAccount = (request: PaymentInstitutionAccountToCreateUpdate): Promise<AxiosResponse<PaymentInstitutionAccountSimple>> => {
        return api.post<PaymentInstitutionAccountSimple>(API_URL, request)
    }

    const detailPaymentInstitutionAccount = (id: number): Promise<AxiosResponse<PaymentInstitutionAccountDetail>> => {
        return api.get<PaymentInstitutionAccountDetail>(`${API_URL}/${id}`)
    }

    const updatePaymentInstitutionAccount = (request: PaymentInstitutionAccountToCreateUpdate): Promise<AxiosResponse<PaymentInstitutionAccountSimpleToUpdate>> => {
        return api.patch<PaymentInstitutionAccountSimpleToUpdate>(`${API_URL}/${request.id}`, request)
    }

    const searchPaymentInstitutions = (request: SearchRequest): Promise<AxiosResponse<Page<PaymentInstitutionToSearch>>> => {
        return api.get(`${API_URL}/search?search=${encodeURIComponent(request.search)}`, {
            params: {
                ...request.pageable,
                originatorId: request.originatorId ? encodeURIComponent(request.originatorId) : '',
                technicalSupplierId: request.technicalSupplierId ? encodeURIComponent(request.technicalSupplierId) : ''
            }
        })
    }

    const withdrawPaymentInstitutionsAccountBalance = (request: PaymentInstitutionAccountToWithdraw): Promise<AxiosResponse<SimplePaymentInstitutionAccountToWithdraw>> => {
        const { accountId, requestBody } = request
        return api.post<SimplePaymentInstitutionAccountToWithdraw>(`${API_URL}/${accountId}/balance/withdraw`, requestBody)
    }

    const searchOriginatorsByPaymentInstitutions = (request: SearchRequest): Promise<AxiosResponse<Page<OriginatorsByPaymentInstitutionToSearch>>> => {
        return api.get(`${API_URL}/${request.paymentInstitutionAccountId}/originators/search?search=${encodeURIComponent(request.search)}`, {
            params: {
                ...request.pageable
            }
        })
    }

    const convertPaymentInstitutionAccount = (request: PaymentInstitutionAccountConvertExclusive): Promise<AxiosResponse<PaymentInstitutionAccountSimpleToUpdate>> => {
        return api.patch<PaymentInstitutionAccountSimpleToUpdate>(`${API_URL}/${request.id}/convert-to-exclusive`, request)
    }

    return {
        listPaymentInstitutionsAccounts,
        searchPaymentInstitutions,
        createPaymentInstitutionAccount,
        detailPaymentInstitutionAccount,
        updatePaymentInstitutionAccount,
        withdrawPaymentInstitutionsAccountBalance,
        searchOriginatorsByPaymentInstitutions,
        convertPaymentInstitutionAccount
    }
}
export default paymentInstitutionsAccounts()