import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationMonthlyProcessedReport } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    monthlyProcessedOperationsReportRequest, monthlyProcessedOperationsReportSuccess, monthlyProcessedOperationsReportError
} from './actions';
import { MonthlyProcessedImportedOperationsReportTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleMonthlyProcessedOperationsReport(action: ReturnType<typeof monthlyProcessedOperationsReportRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationMonthlyProcessedReport[]> = yield call(importedOperationApi.generateMonthlyProcessedOperationsReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyProcessedOperationsReportError(mapped));
            return;
        }
        yield put(monthlyProcessedOperationsReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyProcessedOperationsReportError(mapped));
    }
}

function* watchMonthlyProcessedOperationsReport() {
    yield takeLeading(MonthlyProcessedImportedOperationsReportTypes.MONTHLY_PROCESSED_OPERATIONS_REPORT_REQUEST, handleMonthlyProcessedOperationsReport);
}

function* monthlyProcessedOperationsReportSaga() {
    yield all([fork(watchMonthlyProcessedOperationsReport)]);
}

export default monthlyProcessedOperationsReportSaga;
