import { Checkbox } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import SimpleCheckboxInput from 'components/inputs/checkbox-input/simple-checkbox-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { validateUser, validateUserFirstName, validateUserLastName, validateUserLogin } from 'entities/user/validation/user-validation';
import { Authority } from 'model/enums/authority';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UserToCreateOrUpdate, defaultUserToCreate } from 'model/user';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useRootDispatch, useUserCreateState, useUserDetailState, useUserUpdateState } from 'reducer/hooks';
import { userCreateRequest, userCreateResetState } from 'reducer/user/create-user/actions';
import { userUpdateRequest, userUpdateResetState } from 'reducer/user/update-user/actions';
import { userDetailRequest, userDetailResetState } from 'reducer/user/user-detail/actions';
import { useToast } from 'shared/hooks/use-toast';
import './user-create-update.scss';

export interface UserCreateUpdateProps extends RouteComponentProps<{ userId: string }> {}

const I18N_PREFIX = 'pages.scd.users.create-update';

const UserCreateUpdate = (props: UserCreateUpdateProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();
    const { toastSuccess, closeToast, toastLoading } = useToast();

    const [userId] = useState<number | undefined>(Number(props.match?.params?.userId) ?? undefined);

    const [selectAuthorities, setSelectAuthorities] = useState<Authority[]>([]);
    const [userToSave, setUserToSave] = useState<UserToCreateOrUpdate>(defaultUserToCreate);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useUserCreateState();
    const { status: updateStatus } = useUserUpdateState();
    const { status: detailStatus, userDetail } = useUserDetailState();

    const hasSelectedAuthority = useCallback(
        (authority: Authority): boolean => selectAuthorities.some(authorityPresent => authorityPresent === authority),
        [selectAuthorities]
    );

    const handleSelectAuthorities = (authority: Authority) => {
        if (hasSelectedAuthority(authority)) {
            const removeAuthotity = selectAuthorities.filter(authorityRemoved => authorityRemoved !== authority);
            setSelectAuthorities(removeAuthotity);
            handleChange({ authorities: removeAuthotity });
            return;
        }
        setSelectAuthorities(state => [...state, authority]);
        handleChange({ authorities: [...selectAuthorities, authority] });
    };

    const hasCheckedAuthority = useCallback(
        (authority: Authority): boolean => {
            if (!userToSave.authorities) return false;
            return userToSave?.authorities.some(authorityChecked => authorityChecked === authority);
        },
        [userToSave]
    );

    const verifyCheckedBox = useCallback(
        (authority: Authority): boolean => {
            if (hasCheckedAuthority(authority)) return true;
            return hasSelectedAuthority(authority);
        },
        [hasSelectedAuthority, hasCheckedAuthority]
    );

    const handleSelectActivated = () => {
        if (hasCheckedActivated(userToSave.activated)) {
            handleChange({ activated: false });
            return;
        }
        handleChange({ activated: true });
        return;
    };

    const hasCheckedActivated = useCallback((activated?: boolean): boolean => (activated ? true : false), []);

    const verifyActivated = useCallback(
        (user: UserToCreateOrUpdate): boolean => {
            if (hasCheckedActivated(user.activated)) return true;
            return false;
        },
        [hasCheckedActivated]
    );

    const handleChange = (value: Partial<UserToCreateOrUpdate>) => {
        setUserToSave(ps => ({ ...ps, ...value }));
    };

    const handleSave = () => {
        setShowValidation(true);

        const _userCreateOrUpdate: UserToCreateOrUpdate = {
            ...userToSave,
        };

        if (!validateUser(_userCreateOrUpdate)) return;

        if (userId) {
            dispatch(userUpdateRequest(_userCreateOrUpdate));
        } else {
            dispatch(userCreateRequest(_userCreateOrUpdate));
        }
    };

    useEffect(() => {
        if (!userId) return;

        dispatch(userDetailRequest(userId));
    }, [userId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && userDetail !== undefined) {
            setUserToSave({
                ...userDetail,
                login: userDetail.login,
                firstName: userDetail.firstName,
                lastName: userDetail.lastName,
                activated: userDetail.activated,
                authorities: userDetail.authorities,
            });
            setSelectAuthorities(ps => [...ps, ...userDetail.authorities]);
        }
    }, [detailStatus, setUserToSave, userDetail]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [createStatus, toastLoading]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.create`));
        history.push('/users');
    }, [createStatus, toastSuccess, closeToast, t, history]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [createStatus, closeToast]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [updateStatus, toastLoading]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.update`));
        history.push('/users');
    }, [updateStatus, toastSuccess, closeToast, t, history]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [updateStatus, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(userCreateResetState());
            dispatch(userUpdateResetState());
            dispatch(userDetailResetState());
        };
    }, [dispatch]);

    const isLoading = detailStatus === HttpRequestStatus.ON_GOING;

    return (
        <main className="user-create">
            <ContextRibbon />
            <section className="user-create--container">
                <header className="user-create--header">
                    <h2 className="user-create--header-title">
                        {userId ? t(`${I18N_PREFIX}.title.update`) : t(`${I18N_PREFIX}.title.create`)}
                    </h2>
                </header>
                {isLoading && userId ? (
                    <Loading />
                ) : (
                    <form className="user-create--form">
                        <div className="user-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.inputs.login.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.login.placeholder`)}
                                showValidation={showValidation}
                                validate={validateUserLogin}
                                value={userToSave?.login ?? ''}
                                onChange={login => handleChange({ login })}
                                disabled={!!userId}
                                externalUpdate
                            />
                        </div>
                        <div className="user-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.inputs.first-name.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.first-name.placeholder`)}
                                showValidation={showValidation}
                                validate={validateUserFirstName}
                                value={userToSave?.firstName ?? ''}
                                onChange={firstName => handleChange({ firstName })}
                                externalUpdate
                            />
                        </div>
                        <div className="user-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.inputs.last-name.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.last-name.placeholder`)}
                                showValidation={showValidation}
                                validate={validateUserLastName}
                                value={userToSave?.lastName ?? ''}
                                onChange={lastName => handleChange({ lastName })}
                                externalUpdate
                            />
                        </div>
                        {userDetail && (
                            <div className="user-create--form-field">
                                <span className="user-create--form-role-title">
                                    <Checkbox checked={verifyActivated(userToSave)} onChange={handleSelectActivated} color="primary" />
                                    {t(`${I18N_PREFIX}.inputs.activated.${userToSave?.activated}`)}
                                </span>
                            </div>
                        )}
                        <div className="user-create--form-role">
                            <span className="user-create--form-role-title">{t(`${I18N_PREFIX}.title.select-roles`)}</span>
                            <SimpleCheckboxInput
                                label={t(`${I18N_PREFIX}.select-checkbox.user`)}
                                value={verifyCheckedBox(Authority.ROLE_USER)}
                                onChange={() => handleSelectAuthorities(Authority.ROLE_USER)}
                                externalUpdate
                            />
                            <SimpleCheckboxInput
                                label={t(`${I18N_PREFIX}.select-checkbox.admin`)}
                                value={verifyCheckedBox(Authority.ROLE_ADMIN)}
                                onChange={() => handleSelectAuthorities(Authority.ROLE_ADMIN)}
                                externalUpdate
                            />
                            <SimpleCheckboxInput
                                label={t(`${I18N_PREFIX}.select-checkbox.banker`)}
                                value={verifyCheckedBox(Authority.ROLE_BANKER)}
                                onChange={() => handleSelectAuthorities(Authority.ROLE_BANKER)}
                                externalUpdate
                            />
                            <SimpleCheckboxInput
                                label={t(`${I18N_PREFIX}.select-checkbox.banker-approval`)}
                                value={verifyCheckedBox(Authority.ROLE_BANKER_APPROVAL)}
                                onChange={() => handleSelectAuthorities(Authority.ROLE_BANKER_APPROVAL)}
                                externalUpdate
                            />
                        </div>
                        <div className="user-create--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </section>
            {modalCancel && (
                <ModalMessageLegacy
                    title={userId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/users');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default UserCreateUpdate;
