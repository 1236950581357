import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountSimple, PaymentInstitutionAccountToCreateUpdate } from 'model/payment-institution-account';
import { CreatePaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/create-payment-institution-account/types';
import { action } from 'typesafe-actions';

export const createPaymentInstitutionAccountRequest = (request: PaymentInstitutionAccountToCreateUpdate) =>
    action(CreatePaymentInstitutionAccountTypes.CREATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, request);
export const createPaymentInstitutionAccountSuccess = (paymentInstitutionAccount: PaymentInstitutionAccountSimple) =>
    action(CreatePaymentInstitutionAccountTypes.CREATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS, paymentInstitutionAccount);
export const createPaymentInstitutionAccountError = (error: GiroWebappError) =>
    action(CreatePaymentInstitutionAccountTypes.CREATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR, error);
export const createPaymentInstitutionAccountResetState = () =>
    action(CreatePaymentInstitutionAccountTypes.CREATE_PAYMENT_INSTITUTION_ACCOUNT_RESET);
