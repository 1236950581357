import clsx from 'clsx';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import {  useTranslation } from 'react-i18next';
import { useResumePaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import TransfersError from 'shared/external/payment-accounts-strategy/transfers/components/transfers-error/transfers-error';
import { resumePaymentInstitutionAccountRequest } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';

import './repayment-fallback.scss';
import { useCallback } from 'react';

const I18N_PREFIX = 'payment-accounts-strategy';

interface FallbackProps {
    paymentInstitutionAccountId: number;
}

const RepaymentFallback = (props: FallbackProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const { resumedPaymentInstitutionAccount, status } = useResumePaymentInstitutionAccountState();

    const dispatch = useRootDispatch();

    const handleTryAgain = useCallback(() => {
        dispatch(resumePaymentInstitutionAccountRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    const hasError: boolean = status === HttpRequestStatus.ERROR;

    return (
        <main className="repayment-fallback">
            <ContextRibbon />
            {hasError ? (
                <TransfersError handleRetry={handleTryAgain} />
            ) : (
                <section className="repayment-fallback__container">
                    <header className="repayment-fallback__header">
                        <div className="repayment-fallback__header-container">
                            <div className="repayment-fallback__header-container--align">
                                <h2 className="repayment-fallback__header-container--title">{resumedPaymentInstitutionAccount?.name}</h2>
                            </div>
                            <div className="repayment-fallback__header-account-infos">
                                <span className="repayment-fallback__header-account-infos--item">
                                    {t(`${I18N_PREFIX}.type.${resumedPaymentInstitutionAccount?.type}`)}
                                </span>
                                <span className="repayment-fallback__header-account-infos--item">
                                    {resumedPaymentInstitutionAccount?.technicalSupplierName}
                                </span>
                                <span
                                    className={clsx('repayment-fallback__header-account-infos--status', {
                                        ACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.ACTIVE,
                                        INACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.INACTIVE,
                                    })}
                                >
                                    {t(`${I18N_PREFIX}.status.${resumedPaymentInstitutionAccount?.status}`).toUpperCase()}
                                </span>
                            </div>
                        </div>
                    </header>
                </section>
            )}
        </main>
    );
};

export default RepaymentFallback;
