import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidCnpjInput from 'components/inputs/cnpj-input/valid-cnpj-input';
import ValidIdentifierInput from 'components/inputs/identifier-input/valid-identifier-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import IdentificationUtils from 'components/inputs/validation/identification-utils';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import {
    validateOrganizationIdentification,
    validateOrganizationKey,
    validateOrganizationName,
    validateScdOrganization,
} from 'entities/organization/organization-create-update/validation/organization-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdOrganization, defaultScdOrganization } from 'model/organization';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useCreateOrganizationState, useDetailOrganizationState, useRootDispatch, useUpdateOrganizationState } from 'reducer/hooks';
import { createOrganizationRequest, createOrganizationResetState } from 'reducer/organization/create-organization/actions';
import { detailOrganizationRequest, detailOrganizationResetState } from 'reducer/organization/detail-organizations/actions';
import { updateOrganizationRequest, updateOrganizationResetState } from 'reducer/organization/update-organization/actions';
import { useToast } from 'shared/hooks/use-toast';
import './organization-create-update.scss';

export interface ScdOrganizationUpdateProps extends RouteComponentProps<{ organizationId: string }> {}

const I18N_PREFIX = 'pages.scd.organization';

const ScdOrganizationCreateUpdatePage = (props: ScdOrganizationUpdateProps) => {
    const { t } = useTranslation();
    const [organizationId] = useState<number | undefined>(
        props.match?.params?.organizationId ? Number(props.match?.params?.organizationId) : undefined
    );

    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const { toastSuccess } = useToast();

    const { status: createStatus } = useCreateOrganizationState();
    const { status: updateStatus } = useUpdateOrganizationState();
    const { status: detailStatus, organization: detailOrganization } = useDetailOrganizationState();

    const [scdOrganization, setScdOrganization] = useState<ScdOrganization>(defaultScdOrganization);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        if (!validateScdOrganization(scdOrganization)) return;

        if (organizationId) {
            dispatch(updateOrganizationRequest(scdOrganization));
            return;
        }

        dispatch(createOrganizationRequest(scdOrganization));
    };

    const handleChange = (value: Partial<ScdOrganization>) => {
        setScdOrganization(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (organizationId) {
            dispatch(detailOrganizationRequest(organizationId));
        }
    }, [organizationId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailOrganization !== undefined) {
            setScdOrganization({
                ...detailOrganization,
                identification: IdentificationUtils.getNumberFromIdentification(detailOrganization.identification),
            });
        }
    }, [detailStatus, detailOrganization]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.create`));
            history.push('/organizations');
        }
    }, [createStatus, history, t, toastSuccess]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast-success.edit`));
            history.push('/organizations');
        }
    }, [updateStatus, history, t, toastSuccess]);

    useEffect(() => {
        return () => {
            dispatch(createOrganizationResetState());
            dispatch(updateOrganizationResetState());
            dispatch(detailOrganizationResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-organization-create-update__create">
            <ContextRibbon />
            <div className="scd-organization-create-update--container">
                <header className="scd-organization-create-update--header">
                    <h2 className="scd-organization-create-update--header-title">
                        {!organizationId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && organizationId ? (
                    <Loading />
                ) : (
                    <form className="scd-organization-create-update--form">
                        <div className="scd-organization-create-update--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdOrganization?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateOrganizationName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-organization-create-update--form-field">
                            <ValidCnpjInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={scdOrganization?.identification ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                onChange={identification => handleChange({ identification })}
                                validate={validateOrganizationIdentification}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-organization-create-update--form-field">
                            <ValidIdentifierInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.organizationKey.label`)}
                                value={scdOrganization?.organizationKey ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.organizationKey.placeholder`)}
                                onChange={organizationKey => handleChange({ organizationKey })}
                                validate={validateOrganizationKey}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-organization-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={organizationId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/organizations');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdOrganizationCreateUpdatePage;
