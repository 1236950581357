import DashedButton from 'components/buttons/dashed-button/dashed-button';
import { ScdCompanyGroup, defaultScdCompanyGroup } from 'model/company-group';
import { SetStateAction, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import CardEdition, { ICardEdition } from 'components/card/card-edition/card-edition';
import { CompanyGroupCardCreate } from 'entities/legacy-program/components/company-group-card/company-group-card-create/company-group-card-create';
import { CompanyGroupCardDetail } from 'entities/legacy-program/components/company-group-card/company-group-card-detail/company-group-card-detail';

import { useTranslation } from 'react-i18next';
import { validateProgramCompanyGroups } from 'entities/legacy-program/validation/company-groups-validation';
import { LegacyScdProgram } from 'model/program';

export interface CompanyGroupEdition extends ScdCompanyGroup, ICardEdition {
    uniqueKey: string;
}

interface CompanyGroupManagerProps {
    onUpdate: (companyGroupsList: ReadonlyArray<ScdCompanyGroup>) => void;
    editionUpdate?: (value: boolean) => void;
    scdProgram: LegacyScdProgram;
    companyGroupsList: CompanyGroupEdition[];
    setCompanyGroupsList: (value: SetStateAction<CompanyGroupEdition[]>) => void;
    editing: boolean;
    setEditing: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.program.card.companyGroups';

const CompanyGroupManager = (props: CompanyGroupManagerProps) => {
    const { onUpdate, editionUpdate, scdProgram, companyGroupsList, setCompanyGroupsList, editing, setEditing } = props;

    const [alreadyAdd, setAlreadyAdd] = useState<boolean>(false);

    const { t } = useTranslation();

    const updateEditing = (value: boolean) => {
        setEditing(value);
        if (editionUpdate) {
            editionUpdate(value);
        }
    };

    const handleAdd = (_event: any) => {
        setCompanyGroupsList(ps => [...ps, { ...defaultScdCompanyGroup, isNew: true, uniqueKey: uuidV4() }]);
        updateEditing(true);
    };

    const handleAlreadyAdd = (companyGroup: ScdCompanyGroup) => {
        const isAlreadyAdd = companyGroupsList?.some(ps => ps.id === companyGroup.id);
        setAlreadyAdd(isAlreadyAdd);
    };

    const handleSave = (_index: number, _update: CompanyGroupEdition) => {
        const update = companyGroupsList.map((it, index) => (index === _index ? { ..._update, isNew: false } : it));
        setCompanyGroupsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
        updateEditing(false);
    };

    const handleRemove = (_index: number) => {
        const update = companyGroupsList.filter((_it, index) => index !== _index);
        setCompanyGroupsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
    };

    const handleCancel = (_index: number) => {
        if (!companyGroupsList[_index]) return;
        if (companyGroupsList[_index].isNew) {
            setCompanyGroupsList(ps => ps.filter((_it, index) => index !== _index));
        }
        updateEditing(false);
        setAlreadyAdd(false);
    };

    return (
        <div className="scd-program__manager">
            {companyGroupsList &&
                companyGroupsList.length > 0 &&
                companyGroupsList.map((item, index) => (
                    <CardEdition
                        key={item.uniqueKey}
                        entity={item}
                        onSave={value => handleSave(index, value)}
                        validate={validateProgramCompanyGroups}
                        onRemove={() => handleRemove(index)}
                        onCancel={() => handleCancel(index)}
                        editable={true}
                        removable={true}
                        alreadyAdd={alreadyAdd}
                        name={item.name}
                        deleteTitle={t(`${I18N_PREFIX}.modal.delete.title`)}
                        deleteMessage={t(`global.modal.delete.message`)}
                        cancelMessage={t('entity.cancel.info')}
                    >
                        {({ isEditing, onChange, entity, showValidation }) => (
                            <>
                                {isEditing ? (
                                    <CompanyGroupCardCreate
                                        handleAlreadyAdd={handleAlreadyAdd}
                                        alreadyAdd={alreadyAdd}
                                        onChange={onChange}
                                        entity={entity}
                                        showValidation={showValidation}
                                        scdProgram={scdProgram}
                                    />
                                ) : (
                                    <CompanyGroupCardDetail companyGroup={item} />
                                )}
                            </>
                        )}
                    </CardEdition>
                ))}
            {!editing && (
                <div className="scd-program__manager--button">
                    <DashedButton label={`${I18N_PREFIX}.title`} onClick={handleAdd} disabled={!scdProgram.organization} />
                </div>
            )}
        </div>
    );
};

export default CompanyGroupManager;
