import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AccountMenuLegacy from 'shared/navbar/components/account-menu-legacy/account-menu-legacy';
import EntitiesMenuLegacy from 'shared/navbar/components/entities-menu-legacy/entities-menu-legacy';
import './navbar-legacy.scss';

// TODO: AB#546
export const NavbarLegacy = () => {
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    useEffect(() => {
        if (userAccountState.status === HttpRequestStatus.NOOP) {
            dispatch(userAccountRequest());
        }
    }, [dispatch, userAccountState.status]);

    return (
        <div className="page-header-legacy">
            <div>
                <nav className="page-header--menu-legacy">
                    <Link to="/home">
                        <div className="page-header-legacy--logo" />
                    </Link>
                    <div className="page-header--items-legacy">
                        <EntitiesMenuLegacy />
                    </div>
                    <ul className="page-header--menu-legacy-list">
                        <AccountMenuLegacy />
                    </ul>
                </nav>
            </div>
        </div>
    );
};
export default NavbarLegacy;
