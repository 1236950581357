import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { BANK_SVG_URL } from 'config/constants';
import { PaymentTransactionMode } from 'model/enums/payment-transaction-mode';
import { PersonType } from 'model/enums/person-type';
import { ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { Trans, useTranslation } from 'react-i18next';
import invalidBankSvg from 'images/bank-default.svg';
import './read-only-payment-transaction.scss';
import React, { useCallback } from 'react';
import { getPersonType } from 'shared/util/person-type-utils';
import { PaymentTransactionDispatchMode } from 'model/enums/payment-transactions-dispatch-mode';

interface ReadOnlyPaymentTransactionProps {
    paymentTransaction: ScdPaymentTransactionPendingData;
    isAllChecked: boolean;
    selectedTransactions: ScdPaymentTransactionPendingData[] | undefined;
    setSelectedTransactions: (value: ScdPaymentTransactionPendingData[] | undefined) => void;
    currentList: ScdPaymentTransactionPendingData[] | undefined;
}

const I18N_PREFIX = 'pages.scd.complete-payment-transactions';

const ReadOnlyPaymentTransaction = (props: ReadOnlyPaymentTransactionProps) => {
    const { paymentTransaction, isAllChecked, setSelectedTransactions, selectedTransactions, currentList } = props;
    const { t } = useTranslation();

    const {
        bankName,
        bankIdentification,
        mode,
        date,
        amount,
        originatorName,
        pixKey,
        accountNumber,
        accountDigit,
        companyIdentification,
        favoredIdentification,
        favoredName,
        companyName,
        id,
        dispatchMode,
    } = paymentTransaction;

    const getPixKeyOrAccount = (pixKey: string | undefined, accountNumber: string, accountDigit: string) => {
        if (pixKey && pixKey.trim().length)
            return (
                <Tooltip title={pixKey} interactive>
                    <span className="read-only-payment-transaction__field--pixKey">{pixKey}</span>
                </Tooltip>
            );

        const accountInfo: string = accountNumber && accountDigit ? `${accountNumber} - ${accountDigit}` : accountNumber;

        return (
            <>
                <Tooltip title={bankName}>
                    <img
                        src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                        alt={bankName ?? '-'}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                            ((e.target as HTMLImageElement).src = invalidBankSvg)
                        }
                    />
                </Tooltip>
                <Tooltip title={accountInfo} interactive>
                    <span className="read-only-payment-transaction__field--pixKey">{accountInfo}</span>
                </Tooltip>
            </>
        );
    };

    const translateName = (identification: string, name: string): React.ReactNode => {
        if (identification === undefined || identification === null) return name ?? '-';

        if (getPersonType(identification) === PersonType.INDIVIDUAL) {
            return (
                <Trans
                    i18nKey={`${I18N_PREFIX}.translate-name`}
                    t={t}
                    values={{ name: name }}
                    components={{ identification: <IdentificationFormatter value={identification} type={PersonType.INDIVIDUAL} /> }}
                />
            );
        }

        return (
            <Trans
                i18nKey={`${I18N_PREFIX}.translate-name`}
                t={t}
                values={{ name: name }}
                components={{ identification: <IdentificationFormatter value={identification} type={PersonType.CORPORATION} /> }}
            />
        );
    };

    const hasSelectedOperation = useCallback(
        (id: number): boolean | undefined => {
            return selectedTransactions?.some(transaction => transaction.id === id);
        },
        [selectedTransactions]
    );

    const verifyCheckedCheckbox = useCallback(
        (id: number): boolean | undefined => {
            if (!currentList?.includes(paymentTransaction)) return false;
            if (isAllChecked) return isAllChecked;

            return hasSelectedOperation(id);
        },
        [hasSelectedOperation, isAllChecked, currentList, paymentTransaction]
    );

    const handleChange = (id: number) => {
        if (!currentList?.includes(paymentTransaction)) return;

        if (hasSelectedOperation(id)) {
            const filteredData = selectedTransactions?.filter(item => item.id !== id);

            setSelectedTransactions(filteredData);
            return;
        }

        setSelectedTransactions([...(selectedTransactions ?? []), paymentTransaction]);
    };

    return (
        <div className="read-only-payment-transaction">
            <Grid container spacing={2}>
                <Grid item style={{ width: '11%' }}>
                    <div
                        className={clsx('read-only-payment-transaction__field', {
                            MANUAL: mode === PaymentTransactionMode.MANUAL,
                            AUTOMATIC: mode === PaymentTransactionMode.AUTOMATIC,
                            AUTOMATIC_DISPATCH: dispatchMode === PaymentTransactionDispatchMode.AUTOMATIC,
                        })}
                    >
                        <span className="read-only-payment-transaction__field--label">{t(`${I18N_PREFIX}.date`)}</span>
                        <span className="read-only-payment-transaction__field--value">
                            <DateFormatter date={date ?? '00/00/00'} />
                        </span>
                    </div>
                </Grid>
                <Grid item style={{ width: '11.5%' }}>
                    <div className="read-only-payment-transaction__field">
                        <span className="read-only-payment-transaction__field--label"> {t(`${I18N_PREFIX}.amount`)} </span>
                        <Tooltip title={<CurrencyFormatter value={amount} prefix />} interactive>
                            <span className="read-only-payment-transaction__field--value">
                                <CurrencyFormatter value={amount} prefix />
                            </span>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item style={{ width: '11%' }}>
                    <div className="read-only-payment-transaction__field">
                        <span className="read-only-payment-transaction__field--label">{t(`${I18N_PREFIX}.originator`)}</span>
                        <Tooltip title={originatorName ? originatorName : t(`${I18N_PREFIX}.no-originator`).toString()} interactive>
                            <span className="read-only-payment-transaction__field--value">
                                {originatorName ? originatorName : t(`${I18N_PREFIX}.no-originator`)}
                            </span>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item style={{ width: '23%' }}>
                    <div className="read-only-payment-transaction__field">
                        <span className="read-only-payment-transaction__field--label"> {t(`${I18N_PREFIX}.favored`)} </span>
                        <Tooltip title={translateName(favoredIdentification, favoredName) ?? ''} interactive>
                            <span className="read-only-payment-transaction__field--value">
                                {translateName(favoredIdentification, favoredName)}
                            </span>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item style={{ width: '15%' }}>
                    <div className="read-only-payment-transaction__field">
                        <span className="read-only-payment-transaction__field--label"> {t(`${I18N_PREFIX}.account`)} </span>
                        <span className="read-only-payment-transaction__field--pixKey">
                            {getPixKeyOrAccount(pixKey, accountNumber, accountDigit)}
                        </span>
                    </div>
                </Grid>
                <Grid item style={{ width: '23%' }}>
                    <div className="read-only-payment-transaction__field">
                        <span className="read-only-payment-transaction__field--label"> {t(`${I18N_PREFIX}.company`)} </span>
                        <Tooltip
                            title={companyName && companyIdentification && (translateName(companyIdentification, companyName) ?? '')}
                            interactive
                        >
                            <span className="read-only-payment-transaction__field--value">
                                {translateName(companyIdentification, companyName)}
                            </span>
                        </Tooltip>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className="read-only-payment-transaction__checkbox">
                        <Checkbox color="primary" checked={verifyCheckedCheckbox(id)} onChange={() => handleChange(id)} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ReadOnlyPaymentTransaction;
