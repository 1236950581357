import { GiroWebappError } from 'model/error';
import { ScdLegacyPaymentTransactionData } from 'model/payment-transaction';
import { ScdPaymentTransactionToCreate } from 'model/payment-transaction';
import { CreatePaymentTransactionTypes } from 'reducer/payment-transaction/create-payment-transaction/types';
import { action } from 'typesafe-actions';

export const createPaymentTransactionRequest = (request: ScdPaymentTransactionToCreate) =>
    action(CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_REQUEST, request);
export const createPaymentTransactionSuccess = (createdPaymentTransaction: ScdLegacyPaymentTransactionData) =>
    action(CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_SUCCESS, createdPaymentTransaction);
export const createPaymentTransactionError = (error: GiroWebappError) =>
    action(CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSATION_ERROR, error);
export const createPaymentTransactionResetState = () => action(CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_RESET);
