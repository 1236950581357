import { HeaderType } from 'model/enums/header-type';
import Sidebar from 'shared/header/sidebar/sidebar';
import SimpleHeader from 'shared/header/simple-header';

export interface HeaderProps {
    type: HeaderType;
}

export const Header = (props: HeaderProps) => {
    switch (props.type) {
        case HeaderType.ADMIN:
            return <Sidebar />;
        case HeaderType.SIMPLE:
            return <SimpleHeader />;
        case HeaderType.NONE:
        default:
            return <></>;
    }
};

export default Header;
