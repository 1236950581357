import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { BaseStoreState } from 'reducer/base-store-state';

export enum BatchOutputDetailsBcoTypes {
    BATCH_OUTPUT_DETAILS_BCO_REQUEST = '@@batch-output-details-bco/BATCH_OUTPUT_DETAILS_BCO_REQUEST',
    BATCH_OUTPUT_DETAILS_BCO_SUCCESS = '@@batch-output-details-bco/BATCH_OUTPUT_DETAILS_BCO_SUCCESS',
    BATCH_OUTPUT_DETAILS_BCO_ERROR = '@@batch-output-details-bco/BATCH_OUTPUT_DETAILS_BCO_ERROR',
    BATCH_OUTPUT_DETAILS_BCO_RESET = '@@batch-output-details-bco/BATCH_OUTPUT_DETAILS_BCO_RESET',
}

export interface BatchOutputsDetailsBcoState extends BaseStoreState {
    readonly batchOutputDetails?: BatchOutputDetailsBco;
}
