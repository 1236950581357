import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdSearchPaymentImportBatch } from 'model/batch';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchPaymentImportBatchesTypes } from 'reducer/batch/search-payment-import-batches/types';
import {
    searchPaymentImportBatchesError,
    searchPaymentImportBatchesSuccess,
    searchPaymentImportBatchesRequest,
} from 'reducer/batch/search-payment-import-batches/actions';
import { Page } from 'services/page';

function* handleSearchPaymentImportBatches(action: ReturnType<typeof searchPaymentImportBatchesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdSearchPaymentImportBatch>> = yield call(batchAPI.searchPaymentImportBatches, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchPaymentImportBatchesError(mapped));
            return;
        }
        yield put(searchPaymentImportBatchesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchPaymentImportBatchesError(mapped));
    }
}

function* watchPaymentImportBatches() {
    yield takeEvery(SearchPaymentImportBatchesTypes.SEARCH_PAYMENT_IMPORT_BATCHES_REQUEST, handleSearchPaymentImportBatches);
}

function* searchPaymentImportBatchesSaga() {
    yield all([fork(watchPaymentImportBatches)]);
}

export default searchPaymentImportBatchesSaga;
