import { Grid } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { ScdCompanySearch } from 'model/company';
import { ScdOriginatorFilterRequest } from 'model/originator';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchCompaniesRequest, searchCompaniesResetState } from 'reducer/company/search-companies/actions';
import { searchTechnicalSuppliersRequest, searchTechnicalSuppliersResetState } from 'reducer/technical-supplier/search-originators/actions';
import { valuesOfObject } from 'shared/util/object-utils';

import './originator-modal-filter.scss';

interface OriginatorModalFilterProps {
    isOpenModalFilter: boolean;
    modalFilter: OriginatorModalFilterType;
    onClose: () => void;
    title: string;
    filterActive: ScdOriginatorFilterRequest;
    setFilterActive: (value: ScdOriginatorFilterRequest) => void;
    setPage: (value: number) => void;
}

export type OriginatorModalFilterType = 'name' | 'identification' | 'companyId' | 'paymentInstitutionId' | 'filters';

const I18N_PREFIX = 'pages.scd.originator.filter.inputs';

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    filters: filter => valuesOfObject({ ...filter }).length === 0,
    companyId: filter => !filter.companyId,
    identification: filter => !filter.identification,
    name: filter => !filter.name,
    paymentInstitutionId: filter => !filter.paymentInstitutionId,
} as Record<OriginatorModalFilterType, (value: ScdOriginatorFilterRequest) => boolean>;

const OriginatorModalFilter = (props: OriginatorModalFilterProps) => {
    const { title, isOpenModalFilter, modalFilter, onClose, filterActive, setFilterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<ScdOriginatorFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!isOpenModalFilter) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ScdOriginatorFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter = {
        filters: () => {
            setFilterActive({});
            setFilterRequest({});
        },
        companyId: () => {
            setFilterActive({ ...filterActive, companyId: undefined, companyName: undefined });
            setFilterRequest({ ...filterRequest, companyId: undefined, companyName: undefined });
        },
        identification: () => {
            setFilterActive({ ...filterActive, identification: undefined });
            setFilterRequest({ ...filterRequest, identification: undefined });
        },
        name: () => {
            setFilterActive({ ...filterActive, name: undefined });
            setFilterRequest({ ...filterRequest, name: undefined });
        },
        paymentInstitutionId: () => {
            setFilterActive({ ...filterActive, paymentInstitutionId: undefined, paymentInstitutionName: undefined });
            setFilterRequest({ ...filterRequest, paymentInstitutionId: undefined, paymentInstitutionName: undefined });
        },
    } as Record<OriginatorModalFilterType, () => void>;

    const NameFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.name.label`)}
                    placeholder={t(`${I18N_PREFIX}.name.placeholder`)}
                    value={filterRequest?.name}
                    onChange={name => handleChange({ name })}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const IdentificationFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.identification.label`)}
                    placeholder={t(`${I18N_PREFIX}.identification.placeholder`)}
                    value={filterRequest?.identification}
                    onChange={identification => handleChange({ identification })}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const CompanyFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <ModalSearch<ScdCompanySearch>
                    modalTitle={t(`${I18N_PREFIX}.companyId.label`)}
                    modalLabel={t(`${I18N_PREFIX}.companyId.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.companyId.placeholder`)}
                    itemSize={SEARCH_ITEM_SIZE}
                    action={searchCompaniesRequest}
                    onSelect={company => handleChange({ companyId: Number(company.id), companyName: company.name })}
                    renderItem={company => company.name}
                    statusSelector={(state: IRootState) => state.searchCompanies.status}
                    dataSelector={(state: IRootState) => state.searchCompanies.companies}
                    resetState={searchCompaniesResetState}
                >
                    {handleOpen => (
                        <SimpleSearchInput
                            label={t(`${I18N_PREFIX}.companyId.label`)}
                            placeholder={t(`${I18N_PREFIX}.companyId.placeholder`)}
                            value={filterRequest.companyName}
                            onClick={handleOpen}
                            onFocus={handleOpen}
                            readOnly
                            externalUpdate
                        />
                    )}
                </ModalSearch>
            </Grid>
        </>
    );

    const PaymentInstitutionFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <ModalSearch<ScdTechnicalSupplier>
                    modalTitle={t(`${I18N_PREFIX}.paymentInstitutionId.label`)}
                    modalLabel={t(`${I18N_PREFIX}.paymentInstitutionId.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.paymentInstitutionId.placeholder`)}
                    itemSize={SEARCH_ITEM_SIZE}
                    action={searchTechnicalSuppliersRequest}
                    onSelect={technicalSupplier =>
                        handleChange({ paymentInstitutionId: Number(technicalSupplier.id), paymentInstitutionName: technicalSupplier.name })
                    }
                    renderItem={technicalSupplier => technicalSupplier.name}
                    statusSelector={(state: IRootState) => state.searchTechnicalSuppliers.status}
                    dataSelector={(state: IRootState) => state.searchTechnicalSuppliers.technicalSuppliers}
                    resetState={searchTechnicalSuppliersResetState}
                >
                    {handleOpen => (
                        <SimpleSearchInput
                            label={t(`${I18N_PREFIX}.paymentInstitutionId.label`)}
                            placeholder={t(`${I18N_PREFIX}.paymentInstitutionId.placeholder`)}
                            value={filterRequest.paymentInstitutionName}
                            onClick={handleOpen}
                            onFocus={handleOpen}
                            readOnly
                            externalUpdate
                        />
                    )}
                </ModalSearch>
            </Grid>
        </>
    );

    const isConfirmButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        isOpenModalFilter && (
            <Modal isOpen>
                <div className="scd-originator-modal-filter">
                    <div className="scd-originator-modal-filter--header">
                        <label className="scd-originator-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {NameFilter}
                                            {IdentificationFilter}
                                            {CompanyFilter}
                                            {PaymentInstitutionFilter}
                                        </>
                                    ),
                                    name: NameFilter,
                                    identification: IdentificationFilter,
                                    companyId: CompanyFilter,
                                    paymentInstitutionId: PaymentInstitutionFilter,
                                } as Record<OriginatorModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="scd-originator-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default OriginatorModalFilter;
