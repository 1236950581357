import { useState, useEffect, useMemo, ChangeEvent, KeyboardEvent } from 'react';

import FilledInput, { FilledInputProps } from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import uniqueId from 'lodash/uniqueId';
import _ from 'lodash'

import { useTranslation } from 'react-i18next';
import { KeyCode, KeyCodeUtils } from 'shared/util/keypress-utils';

import './base-text-input.scss';

type Base = Pick<FilledInputProps, 'inputComponent' | 'inputProps' | 'onFocus' | 'onBlur' | 'onClick' | 'endAdornment' | 'readOnly' | 'type' | 'disabled'>;

export interface BaseTextInputProps extends Base {
    label: string;
    value: string | undefined;
    error?: boolean;
    onChange?: (value: string) => void;
    shouldShrinkLabel?: (value: string) => boolean | undefined;
    placeholder?: string;
    autoComplete?: string;
    autoFocus?: boolean;
    hasExternalSanitization?: boolean;

    keysToIgnore?: KeyCode[];

    // for situations where the input is externally updated
    // should be the exception
    // avoid using unless necessary to avoid needless rerenders
    externalUpdate?: boolean;
    defaultValue?: string;

}

export const BaseTextInput = (props: BaseTextInputProps) => {
    const {
        label,
        value: propsValue,
        onChange,
        shouldShrinkLabel,
        error,
        externalUpdate,
        keysToIgnore,
        autoFocus,
        placeholder,
        hasExternalSanitization = false,
        defaultValue,
        ...otherProps
    } = props;
    const UID = useMemo(() => uniqueId('text-input-'), []);
    const { t } = useTranslation();
    const [value, setValue] = useState<string>(propsValue ?? '');

    useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === propsValue ? ps : propsValue ?? ''));
        }
    }, [externalUpdate, propsValue]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const _value = event?.target?.value;

        if (_value === value) return;

        if (_.isEmpty(_.trim(_value)) && defaultValue){
            setValue(defaultValue);
            onChange && onChange(defaultValue);
            return;
        }

        if (!hasExternalSanitization) {
            setValue(_value);
        }

        if (onChange) {
            onChange(_value);
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (keysToIgnore && KeyCodeUtils.isAnyKey(event, keysToIgnore)) {
            event.preventDefault();
        }
    };

    const handleShrink = (): boolean | undefined => {
        if (!props.shouldShrinkLabel) return undefined;
        return props.shouldShrinkLabel(value);
    };

    return (
        <>
            <InputLabel htmlFor={UID} shrink={handleShrink()} error={error}>
                {t(label)}
            </InputLabel>
            <FilledInput
                {...otherProps}
                id={UID}
                value={value}
                placeholder={placeholder ? t(placeholder) : undefined}
                onChange={handleChange}
                error={error}
                onKeyDown={handleKeyPress}
                autoFocus={autoFocus}
                autoComplete={props.autoComplete}
                fullWidth
            />
        </>
    );
};

export default BaseTextInput;
