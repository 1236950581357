import { Icon, Tooltip } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './read-only-json-card.scss';

export interface ReadOnlyCardProps {
    name: string;
    text: string;
}

export const ReadOnlyJsonCard = (props: ReadOnlyCardProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);

    const handleTooltipVisibility = () => {
        setOpen(true);

        setTimeout(() => {
            setOpen(false);
        }, 800);
    };

    const formatTextToJson = (): string => {
        try {
            const newString = JSON.parse(props.text);
            return JSON.stringify(newString, null, 2);
        } catch {
            return props.text;
        }
    };

    const formattedText = formatTextToJson();

    return (
        <article className="request-log_json_card__container list">
            <header className="request-log_json_card__container--header">
                <span className="request-log_json_card__container--header-name">{props.name}</span>
                <div
                    className="request-log_json_card__container--header-name"
                    onClick={() => {
                        navigator.clipboard.writeText(formattedText ?? '');
                    }}
                >
                    <Tooltip title={t(`tooltips.copied`) as string} open={open}>
                        <Icon>
                            <span className="request-log_json_card__container--header-icon" onClick={handleTooltipVisibility}></span>
                        </Icon>
                    </Tooltip>
                </div>
            </header>
            <pre>{formattedText}</pre>
        </article>
    );
};
