import BackDrop from 'components/backdrop/backdrop';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ValidCurrencyInput from 'components/inputs/currency-input/valid-currency-input';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import {
    validateRepaymentPaymentTransaction,
    validateRepaymentPaymentTransactionAmount,
    validateRepaymentPaymentTransactionOriginator,
} from 'entities/payment-institutions-accounts/components/payment-institution-account-internal-repayment/validation/internal-repayment-validation';
import ModalSearchOriginatorByPaymentInstitutionAccount from 'entities/payment-institutions-accounts/components/payment-institution-account-search-originator-modal/payment-institution-account-search-originator-modal';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { PaymentTransactionToCreateRepayment } from 'model/payment-transaction';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
    useCreateRepaymentTransactionState,
    useDetailPaymentInstitutionAccountState,
    usePaymentAccountState,
    useResumePaymentInstitutionAccountState,
    useRootDispatch,
} from 'reducer/hooks';
import {
    detailPaymentInstitutionsAccountsRequest,
    detailPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/detail-payment-institution-account/actions';
import { externalBalancePaymentInstitutionsAccountsRequest } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/actions';
import {
    createInternalRepaymentPaymentTransactionRequest,
    createInternalRepaymentPaymentTransactionResetState,
} from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/actions';
import {
    internalRepaymentAccountPaymentTransactionRequest,
    internalRepaymentAccountPaymentTransactionResetState,
} from 'reducer/payment-transaction/internal-repayment-account-payment-transaction/actions';
import { TransfeeraBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/components/transfeera/transfeera-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';
import useGetExternalBalanceStateById from 'shared/external/util/state-util';
import { useToast } from 'shared/hooks/use-toast';

import FormatterUtils from 'components/formatter/formatter-utils';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import RepaymentFallback from 'entities/payment-institutions-accounts/components/payment-institution-account-internal-repayment/fallback/repayment-fallback';
import { resumePaymentInstitutionAccountRequest } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';
import './payment-institution-account-internal-repayment.scss';

interface PaymentInstitutionAccountRepaymentProps extends RouteComponentProps<{ paymentInstitutionAccountId: string }> {}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

const PaymentInstitutionAccountRepayment = (props: PaymentInstitutionAccountRepaymentProps) => {
    const { match } = props;

    const [paymentInstitutionAccountId] = useState<string>(match.params.paymentInstitutionAccountId);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [paymentTransactionToCreateRepayment, setPaymentTransactionToCreateRepayment] = useState<
        PaymentTransactionToCreateRepayment | undefined
    >(undefined);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const { toastSuccess } = useToast();
    const { account } = usePaymentAccountState();
    const { status: createStatus } = useCreateRepaymentTransactionState();
    const { paymentInstitutionsAccount } = useDetailPaymentInstitutionAccountState();
    const { resumedPaymentInstitutionAccount } = useResumePaymentInstitutionAccountState();

    const { balance } = useGetExternalBalanceStateById(Number(paymentInstitutionAccountId));
    const transfeeraBalanceResponse: TransfeeraBalanceResponse | undefined = useMemo(() => {
        return JsonUtils.parseJsonString(balance?.response);
    }, [balance?.response]);
    const showBalance: boolean = !!balance && !!transfeeraBalanceResponse;

    const handleChange = (value: Partial<PaymentTransactionToCreateRepayment>) => {
        setPaymentTransactionToCreateRepayment({ ...paymentTransactionToCreateRepayment, ...value });
    };

    const handleConfirmRepayment = (paymentTransactionToCreateRepayment: PaymentTransactionToCreateRepayment) => {
        setShowValidation(true);
        if (!validateRepaymentPaymentTransaction(paymentTransactionToCreateRepayment)) return;

        setModalConfirm(true);
    };

    const handleSave = () => {
        setShowValidation(true);

        setModalConfirm(false);
        if (!paymentTransactionToCreateRepayment) return;
        if (!validateRepaymentPaymentTransaction(paymentTransactionToCreateRepayment)) return;

        dispatch(createInternalRepaymentPaymentTransactionRequest(paymentTransactionToCreateRepayment));
    };

    useEffect(() => {
        dispatch(resumePaymentInstitutionAccountRequest(Number(paymentInstitutionAccountId)));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        dispatch(externalBalancePaymentInstitutionsAccountsRequest(Number(paymentInstitutionAccountId)));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        dispatch(detailPaymentInstitutionsAccountsRequest(Number(paymentInstitutionAccountId)));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        dispatch(internalRepaymentAccountPaymentTransactionRequest());
    }, [dispatch]);

    useEffect(() => {
        setPaymentTransactionToCreateRepayment(ps => ({
            ...ps,
            paymentInstitutionAccountId: Number(paymentInstitutionAccountId),
        }));
    }, [paymentInstitutionAccountId]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast-success.internal-repayment`));
        history.push('/payment-transactions');
    }, [createStatus, toastSuccess, history, t]);

    useEffect(() => {
        return () => {
            dispatch(detailPaymentInstitutionsAccountsResetState());
            dispatch(createInternalRepaymentPaymentTransactionResetState());
            dispatch(internalRepaymentAccountPaymentTransactionResetState());
        };
    }, [dispatch]);

    const isCreatingRepayment: boolean = createStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isCreatingRepayment && <BackDrop open />}
            <RepaymentFallback paymentInstitutionAccountId={Number(paymentInstitutionAccountId)} />
            <main className="scd-payment-institution-account-internal-repayment">
                <article className="scd-payment-institution-account-internal-repayment--left-column">
                    <div className="scd-payment-institution-account-internal-repayment--detail-container">
                        <div style={{ display: 'flex' }}>
                            <div className="scd-payment-institution-account-internal-repayment--field">
                                <label className="scd-payment-institution-account-internal-repayment--label">
                                    {t(`pages.scd.payment-transaction.detail.inputs.favored.label`)}
                                </label>
                                <span className="scd-payment-institution-account-internal-repayment--text">
                                    {account?.favored.name ?? '-'}
                                </span>
                            </div>
                            <div className="scd-payment-institution-account-internal-repayment--field">
                                <label className="scd-payment-institution-account-internal-repayment--label">
                                    {t(`pages.scd.payment-transaction.detail.inputs.favored.identification`)}
                                </label>
                                <span className="scd-payment-institution-account-internal-repayment--text">
                                    {!!account?.favored.identification ? (
                                        <IdentificationFormatter value={account?.favored.identification} type={PersonType.CORPORATION} />
                                    ) : (
                                        '-'
                                    )}
                                </span>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(`pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.bank`)}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {account?.bankAccount?.bankName ?? '-'}
                                    </span>
                                </div>
                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(
                                            `pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.accountNumberAndDigit.label`
                                        )}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {`${account?.bankAccount?.accountNumber ?? ''} - ${account?.bankAccount?.accountDigit ?? ''}`}
                                    </span>
                                </div>

                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(`pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.agencyNumber`)}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {account?.bankAccount?.agencyNumber ?? '-'}
                                    </span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(`pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.accountType.label`)}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {t(
                                            `pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.accountType.options.${account?.bankAccount?.accountType}`
                                        )}
                                    </span>
                                </div>
                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(`pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.pixKey.label`)}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {account?.pix?.key ?? '-'}
                                    </span>
                                </div>
                                <div className="scd-payment-institution-account-internal-repayment--field">
                                    <label className="scd-payment-institution-account-internal-repayment--label">
                                        {t(`pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.pixKey.type`)}
                                    </label>
                                    <span className="scd-payment-institution-account-internal-repayment--text">
                                        {t(
                                            `pages.scd.payment-transaction.detail.inputs.paymentAccountRecipient.pixKey.options.${account?.pix?.type}`
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>
                </article>
                <section className="scd-payment-institution-account-internal-repayment--content">
                    <article className="scd-payment-institution-account-internal-repayment--rigth-column">
                        <header className="scd-payment-institution-account-internal-repayment--header">
                            <h2>{t(`${I18N_PREFIX}.internal-repayment.title`)}</h2>
                        </header>
                        <form className="scd-payment-institution-account-internal-repayment--form">
                            <div className="scd-payment-institution-account-internal-repayment--form-field">
                                <ReadOnlyTextField
                                    label={`${I18N_PREFIX}.internal-repayment.credits`}
                                    value={showBalance && <CurrencyFormatter value={transfeeraBalanceResponse?.value} prefix />}
                                />
                            </div>
                            <div className="scd-payment-institution-account-internal-repayment--form-field">
                                <ValidCurrencyInput
                                    label={t(`${I18N_PREFIX}.internal-repayment.value`)}
                                    placeholder={t(`${I18N_PREFIX}.internal-repayment.value`)}
                                    value={paymentTransactionToCreateRepayment?.amount ?? undefined}
                                    onChange={amount => handleChange({ amount })}
                                    showValidation={showValidation}
                                    validate={validateRepaymentPaymentTransactionAmount}
                                    externalUpdate
                                />
                            </div>

                            <div className="scd-payment-institution-account-internal-repayment--form-field">
                                <SimpleTextInput
                                    label={t(`${I18N_PREFIX}.internal-repayment.description`)}
                                    placeholder={t(`${I18N_PREFIX}.internal-repayment.description`)}
                                    value={paymentTransactionToCreateRepayment?.description}
                                    onChange={description => handleChange({ description })}
                                    externalUpdate
                                />
                            </div>
                            <div className="scd-payment-institution-account-internal-repayment--form-field">
                                <ModalSearchOriginatorByPaymentInstitutionAccount
                                    onSelect={originator =>
                                        handleChange({
                                            originatorId: originator.id,
                                            originatorName: originator.name,
                                        })
                                    }
                                    paymentInstitutionAccountId={Number(paymentInstitutionAccountId)}
                                >
                                    {handleOpen => (
                                        <ValidSearchInput
                                            label={t(`${I18N_PREFIX}.inputs.originator.label`)}
                                            placeholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)}
                                            value={paymentTransactionToCreateRepayment?.originatorName ?? ''}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            readOnly
                                            externalUpdate
                                            showValidation={showValidation}
                                            validate={validateRepaymentPaymentTransactionOriginator}
                                        />
                                    )}
                                </ModalSearchOriginatorByPaymentInstitutionAccount>
                            </div>
                            <div className="scd-payment-institution-account-internal-repayment--form--buttons">
                                <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                                <StandardButtonLegacy
                                    label={t('entity.action.repayment')}
                                    onClick={() => handleConfirmRepayment(paymentTransactionToCreateRepayment!)}
                                    disabled={paymentInstitutionsAccount?.technicalSupplierName !== 'Transfeera'}
                                />
                            </div>
                        </form>
                        {modalCancel && (
                            <ModalMessageLegacy
                                title={t('entity.cancel.repayment')}
                                message={t('entity.cancel.info')}
                                onCancel={() => setModalCancel(false)}
                                onAction={() => {
                                    setModalCancel(false);
                                    history.push('/payment-institutions-accounts');
                                }}
                                onClose={() => setModalCancel(false)}
                            />
                        )}
                        {modalConfirm && (
                            <ModalMessageLegacy
                                title={t(`${I18N_PREFIX}.modal.repayment.confirm.title`)}
                                message={
                                    <p className="scd-payment-institution-account-internal-repayment--modal-message">
                                        <Trans
                                            t={t}
                                            i18nKey={`${I18N_PREFIX}.modal.repayment.confirm.info`}
                                            values={{
                                                amount: FormatterUtils.formatNumberToCurrency(paymentTransactionToCreateRepayment?.amount),
                                                accountName: resumedPaymentInstitutionAccount?.name,
                                                favoredName: account?.favored.name,
                                            }}
                                        />
                                    </p>
                                }
                                onCancel={() => setModalConfirm(false)}
                                onAction={handleSave}
                                onClose={() => setModalConfirm(false)}
                            />
                        )}
                    </article>
                </section>
            </main>
        </>
    );
};

export default PaymentInstitutionAccountRepayment;
