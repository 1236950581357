import { AxiosResponse } from 'axios';
import { ScdCompanyGroup, ScdCompanyGroupSimple } from 'model/company-group';

import { CompanyGroupFilterPageable, SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

const companyGroup = () => {
    const createCompanyGroup = (companyGroup: ScdCompanyGroup): Promise<AxiosResponse<ScdCompanyGroupSimple>> => {
        return api.post<ScdCompanyGroupSimple>(`${BASE_URL_ADMIN}/company-groups`, companyGroup);
    };

    const updateCompanyGroup = (companyGroup: ScdCompanyGroup): Promise<AxiosResponse<ScdCompanyGroupSimple>> => {
        return api.put<ScdCompanyGroupSimple>(`${BASE_URL_ADMIN}/company-groups`, companyGroup);
    };

    const detailCompanyGroup = (companyGroupId: string): Promise<AxiosResponse<ScdCompanyGroup>> => {
        return api.get<ScdCompanyGroup>(`${BASE_URL_ADMIN}/company-groups/${companyGroupId}`);
    };

    const listCompanyGroups = (request?: CompanyGroupFilterPageable): Promise<AxiosResponse<Page<ScdCompanyGroupSimple>>> => {
        const { pageable, filter } = request ?? {};

        const _requestFilters = [
            filter?.groupName ? `groupName=${filter?.groupName}` : undefined,
            filter?.organizationId ? `organizationId=${filter?.organizationId}` : undefined,
            filter?.companyId ? `companyId=${filter?.companyId}` : undefined,
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<Page<ScdCompanyGroupSimple>>(`${BASE_URL_ADMIN}/company-groups?${_requestFilters}`, { params: { ...pageable } })
    };

    const searchCompanyGroups = (request: SearchRequest): Promise<AxiosResponse<Page<ScdCompanyGroup>>> => {
        return api.get<Page<ScdCompanyGroup>>(`${BASE_URL_ADMIN}/company-groups/search?name=${encodeURIComponent(request.search)}&organizationId=${request.organizationId ? request.organizationId : ''}`, {
            params: { ...request.pageable },
        });
    };

    return {
        createCompanyGroup,
        updateCompanyGroup,
        searchCompanyGroups,
        detailCompanyGroup,
        listCompanyGroups,
    };
};

export default companyGroup();
