import { Checkbox } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import FormatterUtils from 'components/formatter/formatter-utils';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import ScdBatchEndorsementCollapse from 'entities/batch/batch-endorsement/components/batch-endorsement-collapse/batch-endorsement-collapse';
import ScdBatchEndorsementListHeader from 'entities/batch/batch-endorsement/components/batch-endorsement-list-header/batch-endorsement-list-header';
import ScdBatchEndorsementOptionsItems from 'entities/batch/batch-endorsement/components/batch-endorsement-options-items/batch-endorsement-options-items';
import ModalBatchExport from 'features/batch-export/components/modal/modal-batch-export';
import { BatchDocumentDownloadRequest } from 'model/batch-document';
import { BatchOutputSignaturesResume, BatchSignatureForOutputList, BatchSignatureReExportRequest } from 'model/batch-signature';
import { BatchSignatureStatus } from 'model/enums/batch-signature-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batchDocumentDownloadRequest } from 'reducer/batch/batch-document-download/action';
import { batchSignatureReExportRequest, batchSignatureReExportResetState } from 'reducer/batch/batch-signature-re-export/action';
import { useBatchSignatureReExportState, useBatchSignatureResumeState, useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';

interface ScdBatchEndorsementListProps {
    signatureResume?: BatchOutputSignaturesResume;
    isChecked?: boolean;
    signatureIds: number[];
    setSignatureIds: (value: number[]) => void;
    modalMessage?: boolean;
    setModalMessage: (value: boolean) => void;
    handleExportSignatures: () => void;
    selectAllCheckBox: boolean;
    setSelectAllCheckBox: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';
const sizeByScroll = 7;

const ScdBatchEndorsementList = (props: ScdBatchEndorsementListProps) => {
    const {
        signatureResume,
        isChecked,
        signatureIds,
        setSignatureIds,
        modalMessage,
        setModalMessage,
        handleExportSignatures,
        selectAllCheckBox,
        setSelectAllCheckBox,
    } = props;
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterCollapse, setFilterCollapse] = useState<number[]>([]);
    const [batchId, setBatchId] = useState<number>();
    const [outputId, setOutputId] = useState<number>();

    const [modalReExport, setModalReExport] = useState<boolean>(false);

    const [currentSignature, setCurrentSignature] = useState<BatchSignatureForOutputList>();

    const [exportId, setExportId] = useState<number | undefined>(undefined);
    const [openModalDetail, setOpenModalDetail] = useState<boolean>(false);

    const { status: signatureResumeStatus } = useBatchSignatureResumeState();
    const { status: signatureReExportStatus } = useBatchSignatureReExportState();

    const { closeToast, toastLoading, toastSuccess } = useToast();

    const { formatDateTimeCompleteUTC } = FormatterUtils;

    const isDisableSelectAllCheckBox =
        signatureResume?.signatures.length === 0 ||
        signatureResume?.signatures.filter(item => item.status !== BatchSignatureStatus.SENT).length === 0;

    const totalSignatures = signatureResume?.signatures && signatureResume.signatures.length;
    const size = totalSignatures && totalSignatures > sizeByScroll;

    const alreadySent: BatchSignatureForOutputList[] | undefined = signatureResume?.signatures.filter(
        item => item.status !== BatchSignatureStatus.SENT
    );

    const verifyAllSelectedSignatures = useMemo((): boolean => {
        return alreadySent?.length === signatureIds.length;
    }, [signatureIds, alreadySent?.length]);

    useEffect(() => {
        if (isDisableSelectAllCheckBox) {
            setSelectAllCheckBox(false);
            return;
        }
        if (verifyAllSelectedSignatures) {
            setSelectAllCheckBox(true);
            return;
        }

        setSelectAllCheckBox(false);
    }, [verifyAllSelectedSignatures, setSelectAllCheckBox, isDisableSelectAllCheckBox]);

    const isDisableCheckBox = useCallback((status: BatchSignatureStatus): boolean => {
        return status === BatchSignatureStatus.SENT;
    }, []);

    const handleSelectAllSignatures = useCallback(() => {
        if (!signatureResume) return;

        const ids = signatureResume.signatures.filter(item => item.status !== BatchSignatureStatus.SENT).map(item => item.id);
        setSignatureIds(ids);
    }, [setSignatureIds, signatureResume]);

    const handleSelectIdsByReExport = (signature: BatchSignatureForOutputList) => {
        setBatchId(signatureResume?.batchId);
        setOutputId(signatureResume?.batchOutputId);
        setCurrentSignature(signature);
    };

    const handleModalReExport = () => {
        setModalReExport(true);
        setAnchorEl(null);
    };

    const handleReExport = () => {
        if (!batchId || !outputId || !currentSignature?.id) return;

        const request: BatchSignatureReExportRequest = {
            batchId,
            outputId,
            signatureId: currentSignature?.id,
        };
        dispatch(batchSignatureReExportRequest(request));
        setModalReExport(false);
    };

    const handleDownload = useCallback(() => {
        if (!batchId || !currentSignature?.batchDocumentId) return;
        const request: BatchDocumentDownloadRequest = {
            batchId,
            documentId: currentSignature?.batchDocumentId,
        };

        dispatch(batchDocumentDownloadRequest(request));
        setAnchorEl(null);
    }, [dispatch, currentSignature?.batchDocumentId, batchId, setAnchorEl]);

    useEffect(() => {
        handleSelectAllSignatures();
    }, [isChecked, handleSelectAllSignatures]);

    useEffect(() => {
        if (signatureReExportStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
        closeToast();
    }, [signatureReExportStatus, toastLoading, closeToast]);

    useEffect(() => {
        if (signatureReExportStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.signatures.process`));
    }, [signatureReExportStatus, toastSuccess, t, closeToast]);

    useEffect(() => {
        if (signatureReExportStatus === HttpRequestStatus.SUCCESS || signatureReExportStatus === HttpRequestStatus.ERROR) {
            dispatch(batchSignatureReExportResetState());
        }
    }, [dispatch, signatureReExportStatus]);

    const loading = signatureResumeStatus === HttpRequestStatus.ON_GOING;

    const handleSelect = (signature: BatchSignatureForOutputList) => {
        setBatchId(signatureResume?.batchId);
        const exist = filterCollapse.includes(signature.id);
        if (exist) {
            const removed = filterCollapse.filter(item => item !== signature.id);
            setFilterCollapse(removed);
            setCurrentSignature(state => [state].filter(item => item?.id !== signature.id)[0]);
            return;
        }
        setFilterCollapse(prev => [...prev, signature.id]);
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className="scd-batch-endorsement__collapse">
                    <div className="scd-batch-endorsement__collapse--container">
                        <table>
                            <ScdBatchEndorsementListHeader
                                size={size}
                                isChecked={isChecked}
                                selectAllCheckBox={selectAllCheckBox}
                                handleSelectAllSignatures={() => {}}
                                isDisableSelectAllCheckBox={isDisableSelectAllCheckBox}
                            />
                            {signatureResume?.signatures.length ? (
                                <tbody className="scd-batch-endorsement__scroll">
                                    {signatureResume.signatures.map(signature => (
                                        <>
                                            <tr className="scd-batch-endorsement__collapse-content" key={signature.id}>
                                                <td onClick={() => handleSelect(signature)}>
                                                    <ExpandMoreIcon className={filterCollapse.includes(signature.id) ? 'active' : ''} />
                                                </td>
                                                <td>{signature.operation.ccbNumber}</td>
                                                <td>{formatDateTimeCompleteUTC(signature.operation.receivedDate).slice(0, 18)}</td>

                                                <td>
                                                    <span
                                                        className={clsx('scd-batch-endorsement__collapse-content-status', {
                                                            SENT: signature.status === BatchSignatureStatus.SENT,
                                                            PENDING: signature.status === BatchSignatureStatus.PENDING,
                                                            SIGNED: signature.status === BatchSignatureStatus.SIGNED,
                                                            ERROR: signature.status === BatchSignatureStatus.ERROR,
                                                        })}
                                                    >
                                                        {t(`${I18N_PREFIX}.signature.status.options.${signature.status}`)}
                                                    </span>
                                                </td>
                                                <td>
                                                    {isChecked ? (
                                                        <span
                                                            className={clsx(`scd-batch-endorsement__collapse-content-cell`, {
                                                                isCheck: isChecked,
                                                            })}
                                                        >
                                                            <Checkbox
                                                                checked={signature.status !== BatchSignatureStatus.SENT && isChecked}
                                                                onChange={() => {}}
                                                                disabled={isDisableCheckBox(signature.status)}
                                                                color="primary"
                                                            />
                                                        </span>
                                                    ) : (
                                                        <MoreOptionsMenu
                                                            anchorEl={anchorEl}
                                                            setAnchorEl={setAnchorEl}
                                                            onClick={() => handleSelectIdsByReExport(signature)}
                                                        >
                                                            <ScdBatchEndorsementOptionsItems
                                                                handleDownload={handleDownload}
                                                                handleModalReExport={handleModalReExport}
                                                                currentSignature={currentSignature}
                                                            />
                                                        </MoreOptionsMenu>
                                                    )}
                                                </td>
                                            </tr>
                                            <ScdBatchEndorsementCollapse
                                                exports={signature.exports}
                                                filterCollapse={filterCollapse}
                                                signatureId={signature.id}
                                                setExportId={setExportId}
                                                setOpenModalDetail={setOpenModalDetail}
                                            />
                                        </>
                                    ))}
                                </tbody>
                            ) : (
                                <div className="scd-batch-endorsement__collapse__error-message">
                                    <span className="scd-batch-endorsement__collapse__error-message-image" />
                                    <span className="scd-batch-endorsement__collapse__error-message-title">
                                        {t(`${I18N_PREFIX}.endorsement-list.empty`)}
                                    </span>
                                </div>
                            )}
                        </table>
                    </div>
                    {modalMessage && (
                        <ModalMessageLegacy
                            title={t(`${I18N_PREFIX}.modals.export.title`)}
                            message={t(`${I18N_PREFIX}.modals.export.message`)}
                            onCancel={() => setModalMessage(false)}
                            onAction={handleExportSignatures}
                            onClose={() => setModalMessage(false)}
                        />
                    )}
                    {modalReExport && (
                        <ModalMessageLegacy
                            title={t(`${I18N_PREFIX}.modals.re-export.title`)}
                            message={t(`${I18N_PREFIX}.modals.re-export.message`)}
                            onCancel={() => setModalReExport(false)}
                            onAction={handleReExport}
                            onClose={() => setModalReExport(false)}
                        />
                    )}
                    {openModalDetail && (
                        <ModalBatchExport
                            batchId={batchId}
                            exportId={exportId}
                            openModal={openModalDetail}
                            setOpenModal={setOpenModalDetail}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default ScdBatchEndorsementList;
