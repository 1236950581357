import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import bankerAPI from 'services/api/banker-api';
import { detailBankerError, detailBankerRequest, detailBankerSuccess } from './actions';
import { DetailBankerTypes } from './types';
import { ErrorUtils } from 'shared/error/error-utils';
import { ScdBanker } from 'model/banker';

function* handleDetailBanker(action: ReturnType<typeof detailBankerRequest>) {
    try {
        const result: AxiosResponse<ScdBanker> = yield call(bankerAPI.detailBanker, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailBankerError(mapped));
            return;
        }
        yield put(detailBankerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailBankerError(mapped));
    }
}

function* watchDetailBanker() {
    yield takeEvery(DetailBankerTypes.DETAIL_BANKER_REQUEST, handleDetailBanker);
}

function* detailBankerSaga() {
    yield all([fork(watchDetailBanker)]);
}

export default detailBankerSaga;
