import { BatchOutputBridgeProps } from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import BatchOutputDetailHeader from 'entities/batch/batch-outputs-detail/components/batch-output-detail-header/batch-output-detail-header';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.endorsement-creditflow';

const EndorsementLimelightCreditflow = (props: Omit<BatchOutputBridgeProps, 'batchWithProgram'>) => {
    const { t } = useTranslation();

    const { output } = props;

    return (
        <>
            <BatchOutputDetailHeader batchOutputDetails={output} />
            <div className="batch-outputs-detail__component--not-found">
                <span className="batch-outputs-detail__component--not-found-image" />
                <span className="batch-outputs-detail__component--not-found-title">{t(`${I18N_PREFIX}.provisional`)}</span>
            </div>
        </>
    );
};

export default EndorsementLimelightCreditflow;
