import { BankList } from 'model/bank';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBanksTypes {
    LIST_BANKS_REQUEST = '@@banks-list/LIST_BANKS_REQUEST',
    LIST_BANKS_SUCCESS = '@@banks-list/LIST_BANKS_SUCCESS',
    LIST_BANKS_ERROR = '@@banks-list/LIST_BANKS_ERROR',
    LIST_BANKS_RESET_STATE = '@@banks-list/LIST_BANKS_RESET_STATE',
}

export interface ListBanksState extends BaseStoreState {
    readonly banks?: Page<BankList>;
}
