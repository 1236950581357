import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdTax from 'entities/tax/tax';
import ScdTaxCreate from 'entities/tax/tax-create/tax-create';

import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const TaxRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdTaxCreate} />

        <PrivateRoute exact path={`${match.url}`} component={ScdTax} />
    </Switch>
);

export default TaxRoutes;
