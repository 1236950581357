import { ScdImportedOperationSimple } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsArchiveOneTypes {

    IMPORTED_OPERATIONS_ARCHIVE_ONE_REQUEST = '@@imported-operations-archive-one/IMPORTED_OPERATIONS_ARCHIVE_ONE_REQUEST',
    IMPORTED_OPERATIONS_ARCHIVE_ONE_SUCCESS = '@@imported-operations-archive-one/IMPORTED_OPERATIONS_ARCHIVE_ONE_SUCCESS',
    IMPORTED_OPERATIONS_ARCHIVE_ONE_ERROR = '@@imported-operations-archive-one/IMPORTED_OPERATIONS_ARCHIVE_ONE_ERROR',
    IMPORTED_OPERATIONS_ARCHIVE_ONE_RESET_STATE = '@@imported-operations-archive-one/IMPORTED_OPERATIONS_ARCHIVE_ONE_RESET_STATE',
}

export interface ImportedOperationsArchiveOneState extends BaseStoreState {
    readonly archivedOperation?: ScdImportedOperationSimple
}
