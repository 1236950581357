import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdLegacyProgramPage from 'entities/legacy-program/legacy-program';
import ScdLegacyProgramCreateUpdatePage from 'entities/legacy-program/legacy-program-create-update/legacy-program-create-update';
import ScdLegacyProgramDetail from 'entities/legacy-program/legacy-program-detail/legacy-program-detail';

import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const LegacyProgramRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdLegacyProgramCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:programId`} component={ScdLegacyProgramDetail} />
        <PrivateRoute exact path={`${match.url}/:programId/edit`} component={ScdLegacyProgramCreateUpdatePage} />

        <PrivateRoute exact path={`${match.url}`} component={ScdLegacyProgramPage} />
    </Switch>
);

export default LegacyProgramRoutes;
