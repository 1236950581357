import { GiroWebappError } from 'model/error';
import { OperationFareReports, ScdImportedOperationFareAmountRepotyBodyRequest } from 'model/imported-operation';
import { action } from 'typesafe-actions';
import { FareAmountReportTypes } from './types';

export const fareAmountReportRequest = (params: ScdImportedOperationFareAmountRepotyBodyRequest) =>
    action(FareAmountReportTypes.FARE_AMOUNT_REPORT_REQUEST, params);
export const fareAmountReportSuccess = (data: OperationFareReports) =>
    action(FareAmountReportTypes.FARE_AMOUNT_REPORT_SUCCESS, data);
export const fareAmountReportError = (error: GiroWebappError) =>
    action(FareAmountReportTypes.FARE_AMOUNT_REPORT_ERROR, error);
export const fareAmountReportResetState = () => action(FareAmountReportTypes.FARE_AMOUNT_REPORT_RESET_STATE);
