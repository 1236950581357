import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentTransactionManualDisbursementValidation } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import { validateManualDisbursementPaymentTransactionRequest, validateManualDisbursementPaymentTransactionError, validateManualDisbursementPaymentTransactionSuccess } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/actions';
import { ValidateManualDisbursementPaymentTransactionTypes } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/types';
import { all, call, fork, put, takeEvery, } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleValidateManualDisbursementPaymentTransaction(action: ReturnType<typeof validateManualDisbursementPaymentTransactionRequest>) {
    try {
        const result: AxiosResponse<PaymentTransactionManualDisbursementValidation> = yield call(paymentTransactionAPI.manualDisbursementValidationPaymentTransaction, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(validateManualDisbursementPaymentTransactionError(mapped));
            return;
        }
        yield put(validateManualDisbursementPaymentTransactionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(validateManualDisbursementPaymentTransactionError(mapped));
    }
}

function* watchValidateManualDisbursementPaymentTransaction() {
    yield takeEvery(ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST, handleValidateManualDisbursementPaymentTransaction);
}

function* validateManualDisbursementPaymentTransactionSaga() {
    yield all([fork(watchValidateManualDisbursementPaymentTransaction)]);
}

export default validateManualDisbursementPaymentTransactionSaga;
