import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import CessionIntegrationOptionsChunk from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-options-items/CessionIntegrationOptionsChunk';
import { DataChunk } from 'model/bco/batch-output-cession-integration-gtsec';
import { DataChunkStatus, ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { FC, useState } from 'react';

interface MoreOptionsRetryProps {
    setSelectDataChunk: (dataChunk: DataChunk) => void;
    chunk: DataChunk;
    setSelectExportRequestType: (value: ExportRequestType) => void;
    setOpenModalRetry: (value: boolean) => void;
}

const MoreOptionsRetry: FC<MoreOptionsRetryProps> = ({ setSelectDataChunk, chunk, setOpenModalRetry, setSelectExportRequestType }) => {
    const [anchorElSubTable, setAnchorElSubTable] = useState<null | HTMLElement>(null);

    const isDisableChunkOptions = (chunk: DataChunk): boolean => {
        return (
            chunk.status === DataChunkStatus.SUCCESS ||
            chunk.status === DataChunkStatus.PROCESSING ||
            chunk.status === DataChunkStatus.PENDENT
        );
    };

    return (
        <td>
            <MoreOptionsMenu
                anchorEl={anchorElSubTable}
                setAnchorEl={setAnchorElSubTable}
                onClick={() => setSelectDataChunk(chunk)}
                disabled={isDisableChunkOptions(chunk)}
            >
                <CessionIntegrationOptionsChunk
                    setSelectExportRequestType={setSelectExportRequestType}
                    setOpenModal={setOpenModalRetry}
                    setAnchorElSubTable={setAnchorElSubTable}
                    chunk={chunk}
                />
            </MoreOptionsMenu>
        </td>
    );
};

export default MoreOptionsRetry;
