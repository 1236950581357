import FormatterUtils from 'components/formatter/formatter-utils';
import { ScdBatchOperationsReport } from 'model/batch';
import { NormalizedImportedOperationToReport, ScdImportedOperationToReport } from 'model/imported-operation';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { NormalizationUtils } from 'shared/util/normalization-utils';
import { getPersonType } from 'shared/util/person-type-utils';

type ReportData = Omit<ScdImportedOperationToReport, 'id' | 'tacAmount'>;
type ReportHeader = Record<keyof ReportData, string>;

const I18N_PREFIX = 'pages.scd.batch.detail.report.operation.headers';

interface BatchOperationsReportProps {
    batchOperationsReport: ScdBatchOperationsReport;
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
}

const BatchOperationsReport = (props: BatchOperationsReportProps) => {
    const { batchOperationsReport, csvLinkRef } = props;

    const { t } = useTranslation();

    const operationsFilename = useMemo(() => {
        const formattedDate = FormatterUtils.formatDateByYear(batchOperationsReport?.date);
        return `RelatorioDeLote_${batchOperationsReport?.organization?.name ?? 'giro'}_${batchOperationsReport?.code}_${formattedDate}.csv`;
    }, [batchOperationsReport]);

    const OPERATIONS_REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            companyIdentificationNumber: t(`${I18N_PREFIX}.companyIdentificationNumber`),
            ccbNumber: t(`${I18N_PREFIX}.ccbNumber`),
            totalValue: t(`${I18N_PREFIX}.totalValue`),
            retentionValue: t(`${I18N_PREFIX}.retentionValue`),
            fareAmount: t(`${I18N_PREFIX}.fareAmount`),
            iofValue: t(`${I18N_PREFIX}.iofValue`),
            storeTransferValue: t(`${I18N_PREFIX}.storeTransferValue`),
            personName: t(`${I18N_PREFIX}.personName`),
            installmentNumber: t(`${I18N_PREFIX}.installmentNumber`),
        };
    }, [t]);

    const BATCH_OPERATIONS_NORMALIZED_DATA: NormalizedImportedOperationToReport[] = useMemo(() => {
        return batchOperationsReport?.importedOperations.map(it => ({
            ...it,
            companyIdentificationNumber: FormatterUtils.formatIdentification(
                getPersonType(it.companyIdentificationNumber),
                it.companyIdentificationNumber
            ),
            totalValue: NormalizationUtils.normalizeCurrencyValueToLocale(it.totalValue),
            retentionValue: NormalizationUtils.normalizeCurrencyValueToLocale(it.retentionValue),
            fareAmount: NormalizationUtils.normalizeCurrencyValueToLocale(it.fareAmount),
            tacAmount: NormalizationUtils.normalizeCurrencyValueToLocale(it.tacAmount),
            iofValue: NormalizationUtils.normalizeCurrencyValueToLocale(it.iofValue),
            storeTransferValue: NormalizationUtils.normalizeCurrencyValueToLocale(it.storeTransferValue),
        }));
    }, [batchOperationsReport]);

    return (
        <>
            <CSVLink
                className="hidden"
                filename={operationsFilename}
                ref={csvLinkRef}
                data={BATCH_OPERATIONS_NORMALIZED_DATA ?? []}
                headers={Object.keys(OPERATIONS_REPORT_HEADERS).map(key => ({ key, label: OPERATIONS_REPORT_HEADERS[key] }))}
            />
        </>
    );
};

export default BatchOperationsReport;
