import { ScdOrganization } from 'model/organization';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum DetailOrganizationTypes {
    DETAIL_ORGANIZATION_REQUEST = '@@organization-detail/DETAIL_ORGANIZATION_REQUEST',
    DETAIL_ORGANIZATION_SUCCESS = '@@organization-detail/DETAIL_ORGANIZATION_SUCCESS',
    DETAIL_ORGANIZATION_ERROR = '@@organization-detail/DETAIL_ORGANIZATION_ERROR',
    DETAIL_ORGANIZATION_RESET_STATE = '@@organization-detail/DETAIL_ORGANIZATION_RESET_STATE',
}

export interface DetailOrganizationState extends BaseStoreState {
    readonly organization?: ScdOrganization;
}
