import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import _ from 'lodash';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import './loan-operation-card.scss';

interface LoanOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.loan';

const LoanOperationCard = (props: LoanOperationCardProps) => {
    const {
        operation: { loan },
    } = props;

    const { t } = useTranslation();

    return (
        <div className="scd-loan-operation-card">
            <h3 className="scd-loan-operation-card--title"> {t(`${I18N_PREFIX}.title`)} </h3>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.totalValue`)} </span>
                <span className="scd-loan-operation-card--item__total-value">
                    <CurrencyFormatter value={loan?.totalValue} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.financedAmount`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    {!_.isNil(loan?.financedAmount) ? <CurrencyFormatter value={loan?.financedAmount} prefix /> : '-'}
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.storeTransferValue`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    {!_.isNil(loan?.storeTransferValue) ? <CurrencyFormatter value={loan?.storeTransferValue} prefix /> : '-'}
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.installmentValue`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <CurrencyFormatter value={loan?.installmentValue} prefix />
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.taxesAmount`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    {!_.isNil(loan?.taxesAmount) ? <CurrencyFormatter value={loan?.taxesAmount} prefix /> : '-'}
                </span>
            </div>
            <div className="scd-loan-operation-card--item">
                <span className="scd-loan-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.firstDueDate`)} </span>
                <span className="scd-loan-operation-card--item__value">
                    <DateFormatter date={loan?.firstDueDate} />
                </span>
            </div>
        </div>
    );
};

export default LoanOperationCard;
