import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountSimpleToUpdate, PaymentInstitutionAccountToCreateUpdate } from 'model/payment-institution-account';
import { UpdatePaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/update-payment-institution-account/types';
import { action } from 'typesafe-actions';

export const updatePaymentInstitutionAccountRequest = (request: PaymentInstitutionAccountToCreateUpdate) =>
    action(UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, request);
export const updatePaymentInstitutionAccountSuccess = (paymentInstitutionAccount: PaymentInstitutionAccountSimpleToUpdate) =>
    action(UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS, paymentInstitutionAccount);
export const updatePaymentInstitutionAccountError = (error: GiroWebappError) =>
    action(UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR, error);
export const updatePaymentInstitutionAccountResetState = () =>
    action(UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_RESET);
