import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionAccountDetail } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { resumePaymentInstitutionAccountRequest, resumePaymentInstitutionAccountError, resumePaymentInstitutionAccountSuccess } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';
import { ResumePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import externalStrategyApi from 'services/api/external-api';

import { ErrorUtils } from 'shared/error/error-utils';

function* handleResumePaymentInstitutionAccount(action: ReturnType<typeof resumePaymentInstitutionAccountRequest>) {
    try {
        const result: AxiosResponse<PaymentInstitutionAccountDetail> = yield call(
            externalStrategyApi.resumePaymentInstitutionAccount,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(resumePaymentInstitutionAccountError(mapped));
            return;
        }
        yield put(resumePaymentInstitutionAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(resumePaymentInstitutionAccountError(mapped));
    }
}

function* watchResumePaymentInstitutionAccount() {
    yield takeEvery(ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleResumePaymentInstitutionAccount);
}

function* resumePaymentInstitutionAccountSaga() {
    yield all([fork(watchResumePaymentInstitutionAccount)]);
}

export default resumePaymentInstitutionAccountSaga;
