import LinkButton from 'components/buttons/link-button/link-button';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { useTranslation } from 'react-i18next';

interface BatchSendDOcumentsSlideButtonsProps {
    setModalAction: (value: BatchModalActionOptions | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.batch';

const BatchSendDocumentsSlideButtons = (props: BatchSendDOcumentsSlideButtonsProps) => {
    const { setModalAction } = props;
    const { t } = useTranslation();

    const handleModalMessageAction = (action: () => void) => () => {
        action();
    };

    return (
        <div className="batch-send-documents__slide">
            <div className="batch-send-documents__slide-buttons">
                <LinkButton  label={t(`${I18N_PREFIX}.send-ccb-documents.action`)} onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.SEND_CCB_DOCUMENTS))} />
            </div>
        </div>
    );
};

export default BatchSendDocumentsSlideButtons;
