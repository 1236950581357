import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.technical-supplier.table';

interface ScdTechnicalSupplierHeaderProps {
    technicalSuppliers: ScdTechnicalSupplier[];
    handleSort: (property: keyof ScdTechnicalSupplier, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdTechnicalSupplierHeader = (props: ScdTechnicalSupplierHeaderProps) => {
    const { t } = useTranslation();
    const { technicalSuppliers, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: keyof ScdTechnicalSupplier) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: technicalSuppliers && technicalSuppliers.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.internalReference`).toUpperCase()} sortable={mapSortable('internalReference')} />
            <SortableColumn label={t(`${I18N_PREFIX}.type`).toUpperCase()} sortable={mapSortable('type')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn
                label={t(`${I18N_PREFIX}.isDefaultPaymentInstitution`).toUpperCase()}
                sortable={mapSortable('isDefaultPaymentInstitution')}
            />
        </tr>
    );
};

export default ScdTechnicalSupplierHeader;
