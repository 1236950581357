import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ProcessPaymentTransactionsTypes, ProcessPaymentTransactionsState
} from 'reducer/payment-transaction/process-payment-transactions/types';

const initialState: ProcessPaymentTransactionsState = {
    status: HttpRequestStatus.NOOP,
    error: undefined,
};

const reducer: Reducer<ProcessPaymentTransactionsState> = (state = initialState, action): ProcessPaymentTransactionsState => {
    switch (action.type) {
        case ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, processedPaymentTransactions: action.payload, error: undefined };
        }
        case ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as processPaymentTransactionsReducer };
