import React from 'react';
import { EnvironmentEnum, EnvironmentUtils } from 'shared/util/environment-utils';

interface VersionedComponentProps {
    production: JSX.Element;
    staging: JSX.Element;
    development: JSX.Element;
    test?: JSX.Element;
}

const VersionedComponent: React.FC<VersionedComponentProps> = ({ production, staging, development, test }) => {
    const current_environment = EnvironmentUtils.getEnvironment();

    const defineComponent = () => {
        switch (current_environment) {
            case EnvironmentEnum.STAGING:
                return staging;
            case EnvironmentEnum.PRODUCTION:
                return production;

            case EnvironmentEnum.TEST:
                return test ?? development;
            default:
                return development;
        }
    };

    return defineComponent();
};

export default VersionedComponent;
