import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramResponse } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum CreateProgramTypes {
    CREATE_PROGRAM_REQUEST = '@@program-create/CREATE_PROGRAM_REQUEST',
    CREATE_PROGRAM_SUCCESS = '@@program-create/CREATE_PROGRAM_SUCCESS',
    CREATE_PROGRAM_ERROR = '@@program-create/CREATE_PROGRAM_ERROR',
    CREATE_PROGRAM_RESET_STATE = '@@program-create/CREATE_PROGRAM_RESET_STATE',
}

export interface CreateProgramState extends BaseStoreState {
    readonly programSimple?: ScdProgramResponse;
}
