import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramSimple } from 'model/program';
import { searchProgramsError, searchProgramsSuccess, searchProgramsRequest } from 'reducer/program/search-programs/actions';
import { SearchProgramsTypes } from 'reducer/program/search-programs/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { SearchRequest } from 'model/reducers';

function* handleSearchPrograms(action: ReturnType<typeof searchProgramsRequest>) {
    try {
        const _payload: SearchRequest = { ...action.payload, search: `ci(contains(${action.payload.search}))` };
        const result: AxiosResponse<Page<ScdProgramSimple>> = yield call(programApi.searchPrograms, _payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchProgramsError(mapped));
            return;
        }
        yield put(searchProgramsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchProgramsError(mapped));
    }
}

function* watchSearchPrograms() {
    yield takeEvery(SearchProgramsTypes.SEARCH_PROGRAMS_REQUEST, handleSearchPrograms);
}

function* searchProgramsSaga() {
    yield all([fork(watchSearchPrograms)]);
}

export default searchProgramsSaga;
