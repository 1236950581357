import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramSimple } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum LegacyUpdateProgramTypes {
    LEGACY_UPDATE_PROGRAM_REQUEST = '@@legacy-program-update/LEGACY_UPDATE_PROGRAM_REQUEST',
    LEGACY_UPDATE_PROGRAM_SUCCESS = '@@legacy-program-update/LEGACY_UPDATE_PROGRAM_SUCCESS',
    LEGACY_UPDATE_PROGRAM_ERROR = '@@legacy-program-update/LEGACY_UPDATE_PROGRAM_ERROR',
    LEGACY_UPDATE_PROGRAM_RESET_STATE = '@@legacy-program-update/LEGACY_UPDATE_PROGRAM_RESET_STATE',
}

export interface LegacyUpdateProgramState extends BaseStoreState {
    readonly program?: ScdProgramSimple;
}
