import { EMAIL_REGEX, BRAZILIAN_PHONE_REGEX } from 'config/constants';
import { validateManualDisbursementDescription } from 'entities/payment-transaction/payment-transaction-detail/validation/description-validation';
import { validateManualDisbursementPaymentInstitution, validateManualDisbursementPaymentInstitutionAccount } from 'entities/payment-transaction/payment-transaction-detail/validation/payment-institution-validation';
import { PixKeyType } from 'model/enums/pix-key-type';
import { PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail.validation.pix';

interface PixKeyValidation {
    pixKey?: string;
    pixKeyType?: PixKeyType;
}

const validatePixKeyByType = (params: PixKeyValidation | null | undefined): boolean => {
    if (!params?.pixKey || !params.pixKeyType) {
        return false
    }

    const validations: Record<PixKeyType, () => boolean> = {
        CORPORATION: () => ValidationUtils.STRING.isValidCNPJ(params?.pixKey),
        INDIVIDUAL: () => ValidationUtils.STRING.isValidCPF(params?.pixKey),
        EMAIL: () => EMAIL_REGEX.test(params?.pixKey as string),
        PHONE_NUMBER: () => BRAZILIAN_PHONE_REGEX.test(params?.pixKey as string),
        EVP: () => !!ValidationUtils.STRING.maxLength(70)
    }

    return validations[params.pixKeyType]()
}

const MANUAL_DISBURSEMENT_PIX_KEY_VALIDATION: Validations<PixKeyValidation | undefined | null> = {
    required: {
        predicate: it => ValidationUtils.STRING.isNotBlank(it?.pixKey),
        errorMessage: `${I18N_PREFIX}.pixKey.required`
    },
    maxLength: {
        predicate: it => !!it?.pixKey && it?.pixKey.length <= 70,
        errorMessage: `${I18N_PREFIX}.pixKey.maxLength`
    },
    byType: {
        predicate: (it) => validatePixKeyByType(it),
        errorMessage: `${I18N_PREFIX}.pixKey.invalidPixKey`
    }
}

export const validateManualDisbursementPixKey = (value: PixKeyValidation | undefined | null) => ValidationUtils.validate(value, MANUAL_DISBURSEMENT_PIX_KEY_VALIDATION)

const MANUAL_DISBURSEMENT_PIX_KEY_TYPE: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.pixKeyType.required`
    }
}

export const validateManualDisbursementPixKeyType = (value: string | undefined | null) => ValidationUtils.validate(value, MANUAL_DISBURSEMENT_PIX_KEY_TYPE)

export const validateManualDisbursementPix = (value: PaymentTransactionToManualDisbursement | undefined | null) => {
    if (!value) {
        return false
    }

    return (
        validateManualDisbursementPixKey(value?.pix).isValid &&
        validateManualDisbursementPixKeyType(value?.pix?.pixKeyType).isValid &&
        validateManualDisbursementDescription(value.description).isValid &&
        validateManualDisbursementPaymentInstitution(value.paymentInstitutionId?.toString()).isValid &&
        validateManualDisbursementPaymentInstitutionAccount(value.paymentInstitutionAccountId?.toString()).isValid
    )
}
