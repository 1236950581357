import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBankerSimple } from 'model/banker';

export type ApiResponse = Record<string, string>;

export enum UpdateBankerTypes {
    UPDATE_BANKER_REQUEST = '@@banker-update/UPDATE_BANKER_REQUEST',
    UPDATE_BANKER_SUCCESS = '@@banker-update/UPDATE_BANKER_SUCCESS',
    UPDATE_BANKER_ERROR = '@@banker-update/UPDATE_BANKER_ERROR',
    UPDATE_BANKER_RESET_STATE = '@@banker-update/UPDATE_BANKER_RESET_STATE',
}

export interface UpdateBankerState extends BaseStoreState {
    readonly banker?: ScdBankerSimple;
}
