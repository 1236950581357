import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ImportedOperationsArchiveManyTypes,
    ImportedOperationsArchiveManyState,
} from 'reducer/imported-operation/archive-many/types';
import { Reducer } from 'redux';

export const initialState: ImportedOperationsArchiveManyState = {
    status: HttpRequestStatus.NOOP,
    archivedOperations: undefined,
    error: undefined,
};

const reducer: Reducer<ImportedOperationsArchiveManyState> = (
    state = initialState,
    action
): ImportedOperationsArchiveManyState => {
    switch (action.type) {

        case ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, archivedOperations: action.payload, error: undefined };
        }
        case ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as importedOperationsArchiveManyReducer };
