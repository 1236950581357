import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search Bank
import { searchBanksReducer } from 'reducer/bank/search-bank/reducer';
import searchBanksSaga from 'reducer/bank/search-bank/sagas';
import { SearchBanksState } from './search-bank/types';

// List Bank
import { listBanksReducer } from 'reducer/bank/list-banks/reducer';
import listBanksSaga from 'reducer/bank/list-banks/sagas';
import { ListBanksState } from 'reducer/bank/list-banks/types';

export interface BankState {
    readonly searchBanks: SearchBanksState;
    readonly listBanks: ListBanksState;
}

export const BankReducer: ReducersMapObject<BankState, AnyAction> = {
    searchBanks: searchBanksReducer,
    listBanks: listBanksReducer,
};

export function* bankSagas() {
    yield all([fork(searchBanksSaga)]);
    yield all([fork(listBanksSaga)]);
}
