import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ScdOrganizationHeader from 'entities/organization/components/organization-header/organization-header';
import OrganizationList from 'entities/organization/components/organization-list/organization-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdOrganizationList } from 'model/organization';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useChangeTokenOrganizationState, useRootDispatch } from 'reducer/hooks';
import { changeTokenOrganizationRequest, changeTokenOrganizationResetState } from 'reducer/organization/change-token-organization/actions';
import { listOrganizationsRequest } from 'reducer/organization/list-organizations/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './organization.scss';

const I18N_PREFIX = 'pages.scd.organization';

export type OrganizationMoreOptionsType = 'changeToken';

const ScdOrganizationPage = () => {
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalOptionType, setModalOptionType] = useState<OrganizationMoreOptionsType | undefined>(undefined);
    const [selectedOrganization, setSelectedOrganization] = useState<ScdOrganizationList | undefined>(undefined);
    const [successModal, setSuccessModal] = useState<boolean>(false);

    const { t } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();
    const { toastSuccess } = useToast();

    const { organizationDataToken, status: tokenStatus } = useChangeTokenOrganizationState();

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };
    }, [sortedProperty, sortOrder, page]);

    useEffect(() => {
        return () => {
            dispatch(changeTokenOrganizationResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (tokenStatus === HttpRequestStatus.SUCCESS) {
            setSuccessModal(true);
        }
    }, [tokenStatus]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };
    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScrollLegacy<ScdOrganizationList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listOrganizations.organizations,
        statusSelector: (state: IRootState) => state.listOrganizations.status,
        action: listOrganizationsRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleSort = (property: keyof ScdOrganizationList, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleMoreOptionsModalAction = {
        changeToken: () => {
            dispatch(changeTokenOrganizationRequest(Number(selectedOrganization?.id)));
            setModalOptionType(undefined);
        },
    } as Record<OrganizationMoreOptionsType, () => void>;

    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(organizationDataToken?.jwtToken || '');
        toastSuccess(t(`${I18N_PREFIX}.toast-success.refresh`));
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.organization.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-organization">
            <section className="scd-organization__container">
                <header className="scd-organization__header">
                    <h2 className="scd-organization__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="scd-organization__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdOrganizationHeader
                                organizations={items}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                disabledSort={false}
                            />
                        </thead>
                        <tbody>
                            <OrganizationList
                                organizations={items}
                                isLoading={isLoading}
                                setSelectedOrganization={setSelectedOrganization}
                                setModalOptionType={setModalOptionType}
                            />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalOptionType && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.more-options.${modalOptionType}.title`)}
                    message={t(`${I18N_PREFIX}.modal.more-options.${modalOptionType}.message`)}
                    onAction={handleMoreOptionsModalAction[modalOptionType]}
                    onClose={() => setModalOptionType(undefined)}
                    onCancel={() => setModalOptionType(undefined)}
                />
            )}
            {successModal && (
                <ModalMessageLegacy
                    standardButtonLabel={t(`${I18N_PREFIX}.modal.success.copy`)}
                    title={t(`${I18N_PREFIX}.modal.success.title`)}
                    onAction={handleCopyToClipboard}
                    onClose={() => {
                        setSuccessModal(false);
                        dispatch(changeTokenOrganizationResetState());
                        setSelectedOrganization(undefined);
                    }}
                >
                    <p className="scd-organization__modal--message">{organizationDataToken?.jwtToken}</p>
                </ModalMessageLegacy>
            )}
        </main>
    );
};

export default ScdOrganizationPage;
