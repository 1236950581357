import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateIssuerPolicyState, IssuerUpdatePolicyTypes } from 'reducer/issuer-policy/update-policy/types';
import { Reducer } from 'redux';

export const initialState: UpdateIssuerPolicyState = {
    status: HttpRequestStatus.NOOP,
    issuerUpdatePolicy: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateIssuerPolicyState> = (state = initialState, action): UpdateIssuerPolicyState => {
    switch (action.type) {
        case IssuerUpdatePolicyTypes.ISSUER_UPDATE_POLICY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case IssuerUpdatePolicyTypes.ISSUER_UPDATE_POLICY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuerUpdatePolicy: action.payload, error: undefined };
        }
        case IssuerUpdatePolicyTypes.ISSUER_UPDATE_POLICY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case IssuerUpdatePolicyTypes.ISSUER_UPDATE_POLICY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateIssuerPolicyReducer };
