import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ProgramSortableProperties } from 'entities/program/program';
import { ScdProgramList } from 'model/program';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.program.table';

interface ScdProgramHeaderProps {
    programsItems: ScdProgramList[];
    handleSort: (property: ProgramSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdProgramHeader = (props: ScdProgramHeaderProps) => {
    const { t } = useTranslation();
    const { programsItems, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: ProgramSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: programsItems && programsItems.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.productType`).toUpperCase()} sortable={mapSortable('productType')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
            <SortableColumn label={t(`${I18N_PREFIX}.organization`).toUpperCase()} sortable={mapSortable('organization.name')} />
        </tr>
    );
};

export default ScdProgramHeader;
