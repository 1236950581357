import { ScdCompanyToSimple } from 'model/company';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreateCompanyTypes {
    CREATE_COMPANY_REQUEST = '@@company-create/CREATE_COMPANY_REQUEST',
    CREATE_COMPANY_SUCCESS = '@@company-create/CREATE_COMPANY_SUCCESS',
    CREATE_COMPANY_ERROR = '@@company-create/CREATE_COMPANY_ERROR',
    CREATE_COMPANY_RESET = '@@company-create/CREATE_COMPANY_RESET',
}

export interface CreateCompanyState extends BaseStoreState {
    readonly company?: ScdCompanyToSimple;
}
