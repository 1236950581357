import clsx from 'clsx';
import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import { IssuerPoliciesRowProps } from 'entities/issuer/issuer-policies/components/list-policies/list-policies'; 
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

const I18N_PREFIX = 'pages.scd.issuer-policy';

const IssuerPoliciesRow = (props: ListChildComponentProps<IssuerPoliciesRowProps>) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { index, data, style } = props;

    const { policies } = data;

    const { id, name, type, status, issuerIntegrationId } = policies[index]

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.type.options.${type}`)}</td>
            <td className="page-container--table-cell-title">
                <span className={clsx('page-container--table-status', status)}>
                    {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                </span>
            </td>
            <td>
                <MoreOptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
                    <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.detail`} onClick={() => history.push(`${url}/${id}`)} />
                    <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.update`} onClick={() => history.push(`${url}/${id}/edit`)} />
                    <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.integration-update`} onClick={() => history.push(`${url}/${id}/integration/${issuerIntegrationId}/edit`)} show={!!issuerIntegrationId} />
                    <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.integration-create`} onClick={() => history.push(`${url}/${id}/integration/new`)} show={!issuerIntegrationId} />
                </MoreOptionsMenu>
            </td>
        </tr>
    )
}

export default IssuerPoliciesRow;