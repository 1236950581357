import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOriginatorSearch } from 'model/originator';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchOriginatorsTypes {
    SEARCH_ORIGINATORS_REQUEST = '@@originators-list/SEARCH_ORIGINATORS_REQUEST',
    SEARCH_ORIGINATORS_SUCCESS = '@@originators-list/SEARCH_ORIGINATORS_SUCCESS',
    SEARCH_ORIGINATORS_ERROR = '@@originators-list/SEARCH_ORIGINATORS_ERROR',
    SEARCH_ORIGINATORS_RESET = '@@originators-list/SEARCH_ORIGINATORS_RESET',
}

export interface SearchOriginatorsState extends BaseStoreState {
    readonly originators?: Page<ScdOriginatorSearch>;
}
