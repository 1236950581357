import Loading from 'components/loading/loading';
import CompanyRow, { CompanyRowProps } from 'entities/company/components/company-row/company-row';
import { ScdCompany } from 'model/company';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface CompanyListProps {
    company: ScdCompany[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.company';

const CompanyList = (props: CompanyListProps) => {
    const { company, isLoading } = props;

    const { t } = useTranslation();

    const companyRowObjectData: CompanyRowProps = {
        company,
    };

    return (
        <>
            {isLoading && !company.length && <Loading isTable />}
            {company.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={companyRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={company.length}
                            className="page-container--table-container"
                        >
                            {CompanyRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-company__table-empty-filter">
                            <span className="scd-company__table-empty-filter-image" />
                            <span className="scd-company__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CompanyList;
