import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramResponse } from 'model/program';

export type ApiResponse = Record<string, string>;

export enum RemoveProgramGroupTypes {
    REMOVE_PROGRAM_GROUP_REQUEST = '@@program-groups-remove/REMOVE_PROGRAM_GROUP_REQUEST',
    REMOVE_PROGRAM_GROUP_SUCCESS = '@@program-groups-remove/REMOVE_PROGRAM_GROUP_SUCCESS',
    REMOVE_PROGRAM_GROUP_ERROR = '@@program-groups-remove/REMOVE_PROGRAM_GROUP_ERROR',
    REMOVE_PROGRAM_GROUP_RESET_STATE = '@@program-groups-remove/REMOVE_PROGRAM_GROUP_RESET_STATE',
}

export interface RemoveProgramGroupState extends BaseStoreState {
    readonly programSimple?: ScdProgramResponse
}
