import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.request-log.table';


export const RequestLogHeaderBrs = () => {
    const { t } = useTranslation();


    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.requestDate`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.responseStatus`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.agent`).toUpperCase()} />
            <SortableColumn label={t(`${I18N_PREFIX}.route`).toUpperCase()} />
        </tr>
    );
};

export default RequestLogHeaderBrs;
