import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';
import { ProgramGroupsToGetAll } from 'model/program-groups';

export type ApiResponse = Record<string, string>;

export enum ListProgramGroupsTypes {
    LIST_PROGRAM_GROUPS_REQUEST = '@@program-groups-list/LIST_PROGRAM_GROUPS_REQUEST',
    LIST_PROGRAM_GROUPS_SUCCESS = '@@program-groups-list/LIST_PROGRAM_GROUPS_SUCCESS',
    LIST_PROGRAM_GROUPS_ERROR = '@@program-groups-list/LIST_PROGRAM_GROUPS_ERROR',
    LIST_PROGRAM_GROUPS_RESET_STATE = '@@program-groups-list/LIST_PROGRAM_GROUPS_RESET_STATE',
}

export interface ListProgramGroupsState extends BaseStoreState {
    readonly programGroups?: Page<ProgramGroupsToGetAll>;
}
