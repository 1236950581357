import { CloseButton } from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import { ScdPaymentTransactionLegacyByBatch, ScdPaymentTransactionsByBatchLegacyError } from 'model/payment-transaction-by-batch';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './payment-transaction-in-series-by-batch-modal-error.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction-in-series.modal-message.error';

export interface PaymentTransactionInSeriesByBatchModalErrorProps {
    title: string;
    onClose: () => void;
    onAction?: () => void;
    errors: ScdPaymentTransactionsByBatchLegacyError[];
    paymentTransactions: ScdPaymentTransactionLegacyByBatch[];
}

export const PaymentTransactionInSeriesByBatchModalError = (props: PaymentTransactionInSeriesByBatchModalErrorProps) => {
    const { title, onClose, onAction, errors, paymentTransactions } = props;
    const { t } = useTranslation();

    const handleSetFavored = (paymentValueId: number) => {
        const _filteredData: ScdPaymentTransactionLegacyByBatch[] = paymentTransactions.filter(
            paymentTransaction => paymentTransaction.paymentValueId === paymentValueId
        );

        return _filteredData[0];
    };

    return (
        <Modal isOpen>
            <div className="payment-transaction-in-series-by-batch-modal-error">
                <article className="payment-transaction-in-series-by-batch-modal-error--content">
                    <header className="payment-transaction-in-series-by-batch-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="payment-transaction-in-series-by-batch-modal-error--message">
                        <p className="payment-transaction-in-series-by-batch-modal-error--title">{title}</p>
                        <div className="payment-transaction-in-series-by-batch-modal-error--errors">
                            {errors.map(({ paymentValueId, errorMessage }) => (
                                <div className="payment-transaction-in-series-by-batch-modal-error--errors__text" key={paymentValueId}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.favored`)} </strong>
                                        {handleSetFavored(paymentValueId).favoredIdentification}
                                        {` - ${handleSetFavored(paymentValueId).favoredName}`}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.amount`)} </strong>
                                        <CurrencyFormatter value={handleSetFavored(paymentValueId).amount} />
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong> {t(errorMessage)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {onAction && (
                        <div className="payment-transaction-in-series-by-batch-modal-error--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.back'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default PaymentTransactionInSeriesByBatchModalError;
