import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailOriginatorState, useRootDispatch } from 'reducer/hooks';
import { detailOriginatorRequest, detailOriginatorResetState } from 'reducer/originator/detail-originator/actions';
import './originator-detail.scss';

type ScdOriginatorDetailProps = RouteComponentProps<{ originatorId: string }>;

const I18N_PREFIX = 'pages.scd.originator.input';

const ScdOriginatorDetailPage = (props: ScdOriginatorDetailProps) => {
    const { t } = useTranslation();
    const [originatorId] = useState<string>(props.match.params.originatorId);
    const { status, originator } = useDetailOriginatorState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailOriginatorRequest(originatorId));
        return () => {
            dispatch(detailOriginatorResetState());
        };
    }, [dispatch, originatorId]);

    return (
        <main className="scd-originator__detail">
            <ContextRibbon />
            <div className="scd-originator--container">
                <header className="scd-originator--header">
                    <h2 className="scd-originator--header-title">{t('pages.scd.originator.detail.title')}</h2>
                </header>
                <div className="scd-originator__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={originator?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.identification.label`)} value={originator?.identification} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.company.label`)} value={originator?.company?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.organization.label`)} value={originator?.organization?.name} />

                            {originator?.paymentInstitutions && (
                                <div className="scd-program__detail--card">
                                    {originator?.paymentInstitutions?.map((item, index) => (
                                        <ReadOnlyCard
                                            name={t(`${I18N_PREFIX}.originatorPaymentInstitution.name`, { number: `${index + 1}` })}
                                            key={item.id}
                                        >
                                            <>
                                                <ReadOnlyTextField
                                                    key={item.id}
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.name.label`}
                                                    value={item?.name}
                                                />
                                                <ReadOnlyTextField
                                                    key={item.id}
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.status.label`}
                                                    value={t(
                                                        `${I18N_PREFIX}.originatorPaymentInstitution.input.status.options.${item?.status}`
                                                    )}
                                                />
                                                <ReadOnlyTextField
                                                    key={item.id}
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.technicalSupplier.title`}
                                                    value={item?.technicalSupplierName}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.account.label`}
                                                    value={item?.accountName}
                                                />
                                                <ReadOnlyTextField
                                                    label={`${I18N_PREFIX}.originatorPaymentInstitution.input.account.status.label`}
                                                    value={t(
                                                        `${I18N_PREFIX}.originatorPaymentInstitution.input.account.status.options.${item?.accountStatus}`
                                                    )}
                                                />
                                            </>
                                        </ReadOnlyCard>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdOriginatorDetailPage;
