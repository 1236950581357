import { ScdBatchToRelocate } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchSearchToRelocate } from 'model/reducers';
import { ListBatchesToRelocateTypes } from 'reducer/batch/list-batches-to-relocate/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listBatchesToRelocateRequest = (request: BatchSearchToRelocate) => action(ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_REQUEST, request);
export const listBatchesToRelocateSuccess = (batches: Page<ScdBatchToRelocate>) => action(ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_SUCCESS, batches);
export const listBatchesToRelocateError = (error: GiroWebappError) => action(ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_ERROR, error);
export const listBatchesToRelocateResetState = () => action(ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_RESET);
