import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramList } from 'model/program';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ListProgramsTypes {
    LIST_PROGRAMS_REQUEST = '@@programs-list/LIST_PROGRAMS_REQUEST',
    LIST_PROGRAMS_SUCCESS = '@@programs-list/LIST_PROGRAMS_SUCCESS',
    LIST_PROGRAMS_ERROR = '@@programs-list/LIST_PROGRAMS_ERROR',
    LIST_PROGRAMS_RESET_STATE = '@@programs-list/LIST_PROGRAMS_RESET_STATE',
}

export interface ListProgramsState extends BaseStoreState {
    readonly programs?: Page<ScdProgramList>;
}
