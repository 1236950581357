import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import {
    listPaymentTransactionsError,
    listPaymentTransactionsRequest,
    listPaymentTransactionsSuccess,
} from 'reducer/payment-transaction/list-payment-transactions/actions';
import { ListPaymentTransactionsTypes } from 'reducer/payment-transaction/list-payment-transactions/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListPaymentTransactions(action: ReturnType<typeof listPaymentTransactionsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdPaymentTransactionData>> = yield call(
            paymentTransactionAPI.listPaymentTransactions,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listPaymentTransactionsError(mapped));
            return;
        }
        yield put(listPaymentTransactionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listPaymentTransactionsError(mapped));
    }
}

function* watchListPaymentTransactions() {
    yield takeEvery(ListPaymentTransactionsTypes.LIST_PAYMENT_TRANSACTIONS_REQUEST, handleListPaymentTransactions);
}

function* listPaymentTransactionsSaga() {
    yield all([fork(watchListPaymentTransactions)]);
}

export default listPaymentTransactionsSaga;
