import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create company group
import { createCompanyGroupReducer } from 'reducer/company-group/create-company-group/reducer';
import createCompanyGroupSagas from 'reducer/company-group/create-company-group/sagas';
import { CreateCompanyGroupState } from 'reducer/company-group/create-company-group/types';

// Update company group
import { updateCompanyGroupReducer } from 'reducer/company-group/update-company-group/reducer';
import updateCompanyGroupSagas from 'reducer/company-group/update-company-group/sagas';
import { UpdateCompanyGroupState } from 'reducer/company-group/update-company-group/types';

// Detail company group
import { detailCompanyGroupReducer } from 'reducer/company-group/detail-company-group/reducer';
import detailCompanyGroupSagas from 'reducer/company-group/detail-company-group/sagas';
import { DetailCompanyGroupState } from 'reducer/company-group/detail-company-group/types';

// List company groups
import { listCompanyGroupsReducer } from 'reducer/company-group/list-company-groups/reducer';
import listCompanyGroupsSagas from 'reducer/company-group/list-company-groups/sagas';
import { ListCompanyGroupsState } from 'reducer/company-group/list-company-groups/types';

// Search company groups
import { searchCompanyGroupsReducer } from 'reducer/company-group/search-company-groups/reducer';
import searchCompanyGroupsSagas from 'reducer/company-group/search-company-groups/sagas';
import { SearchCompanyGroupsState } from 'reducer/company-group/search-company-groups/types';

export interface CompanyGroupState {
    readonly createCompanyGroup: CreateCompanyGroupState;
    readonly updateCompanyGroup: UpdateCompanyGroupState;
    readonly detailCompanyGroup: DetailCompanyGroupState;
    readonly listCompanyGroups: ListCompanyGroupsState;
    readonly searchCompanyGroups: SearchCompanyGroupsState;
}

export const CompanyGroupReducer: ReducersMapObject<CompanyGroupState, AnyAction> = {
    createCompanyGroup: createCompanyGroupReducer,
    updateCompanyGroup: updateCompanyGroupReducer,
    detailCompanyGroup: detailCompanyGroupReducer,
    listCompanyGroups: listCompanyGroupsReducer,
    searchCompanyGroups: searchCompanyGroupsReducer,
};

export function* companyGroupSagas() {
    yield all([
        fork(createCompanyGroupSagas),
        fork(updateCompanyGroupSagas),
        fork(detailCompanyGroupSagas),
        fork(listCompanyGroupsSagas),
        fork(searchCompanyGroupsSagas),
    ]);
}
