import Button from '@material-ui/core/Button';
import './link-button.scss';

export interface LinkButtonProps {
    label: string;
    onClick: () => void;
    outlinedStyle?: boolean;
    startIcon?: React.ReactNode;
    disabled?: boolean;
}

export const LinkButton = (props: LinkButtonProps) => {
    return (
        <div className={props.outlinedStyle ? 'button__style--link-outlined' : 'button__style--link'}>
            <Button variant="contained" onClick={props.onClick} startIcon={props.startIcon} disabled={props.disabled}>
                <span>{props.label}</span>
            </Button>
        </div>
    );
};

export default LinkButton;
