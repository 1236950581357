import RefreshIcon from '@material-ui/icons/Refresh';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import './refresh-button.scss';
export interface RefreshButtonProps {
    onClick: () => void;
}

export const RefreshButton = (props: RefreshButtonProps): JSX.Element => {
    const { onClick } = props;
    const [isSpinning, setIsSpinning] = useState<boolean>(false);

    const activeSpin: string = isSpinning ? '--spin' : '';

    const debouncedFunction = () => {
        setIsSpinning(true);
        onClick();
        setTimeout(() => setIsSpinning(false), 1000);
    };

    const handleDebounce = debounce(debouncedFunction, 500);

    return (
        <button className="refresh-button" onClick={handleDebounce}>
            <RefreshIcon className={`refresh-button--refresh-icon${activeSpin}`} />
        </button>
    );
};

export default RefreshButton;
