import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanyGroupSimple } from 'model/company-group';
import companyGroupAPI from 'services/api/company-group-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListCompanyGroupsTypes } from 'reducer/company-group/list-company-groups/types';
import {
    listCompanyGroupsError,
    listCompanyGroupsSuccess,
    listCompanyGroupsRequest,
} from 'reducer/company-group/list-company-groups/actions';
import { Page } from 'services/page';

function* handleListCompanyGroups(action: ReturnType<typeof listCompanyGroupsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompanyGroupSimple>> = yield call(companyGroupAPI.listCompanyGroups, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listCompanyGroupsError(mapped));
            return;
        }
        yield put(listCompanyGroupsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listCompanyGroupsError(mapped));
    }
}

function* watchListCompanyGroups() {
    yield takeEvery(ListCompanyGroupsTypes.LIST_COMPANY_GROUPS_REQUEST, handleListCompanyGroups);
}

function* listCompanyGroupsSaga() {
    yield all([fork(watchListCompanyGroups)]);
}

export default listCompanyGroupsSaga;
