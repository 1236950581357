import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateManualDisbursementPaymentInstitution,
    validateManualDisbursementPaymentInstitutionAccount,
} from 'entities/payment-transaction/payment-transaction-detail/validation/payment-institution-validation';
import { PaymentInstitutionToSearch } from 'model/payment-institution-account';
import { PaymentTransactionDetail, PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import {
    searchPaymentInstitutionsAccountsRequest,
    searchPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/actions';
import { searchTechnicalSuppliersRequest, searchTechnicalSuppliersResetState } from 'reducer/technical-supplier/search-originators/actions';

interface PaymentInstitutionFormProps {
    handleChange: (value: Partial<PaymentTransactionToManualDisbursement>) => void;
    paymentTransactionToManualDisbursement: PaymentTransactionToManualDisbursement | undefined;
    showValidation: boolean;
    paymentTransaction: PaymentTransactionDetail | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentInstitutionForm = (props: PaymentInstitutionFormProps) => {
    const { handleChange, paymentTransactionToManualDisbursement, showValidation, paymentTransaction } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}>
            <div className="scd-payment-transaction__detail--form-item">
                <ModalSearch<ScdTechnicalSupplier>
                    action={searchTechnicalSuppliersRequest}
                    requestParameters={{ originatorId: paymentTransaction?.originator?.id }}
                    itemSize={SEARCH_ITEM_SIZE}
                    modalTitle={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}
                    modalLabel={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}
                    onSelect={technicalSupplier =>
                        handleChange({
                            paymentInstitutionId: Number(technicalSupplier.id),
                            paymentInstitutionName: technicalSupplier.name,
                            paymentInstitutionStatus: technicalSupplier.status,
                            paymentInstitutionAccountId: undefined,
                            paymentInstitutionAccountName: undefined,
                            paymentInstitutionAccountStatus: undefined,
                        })
                    }
                    renderItem={technicalSupplier => technicalSupplier.name}
                    statusSelector={(state: IRootState) => state.searchTechnicalSuppliers.status}
                    dataSelector={(state: IRootState) => state.searchTechnicalSuppliers.technicalSuppliers}
                    resetState={searchTechnicalSuppliersResetState}
                >
                    {handleOpen => (
                        <ValidSearchInput
                            label={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}
                            placeholder={t(`${I18N_PREFIX}.inputs.paymentInstitution.label`)}
                            value={t(`${I18N_PREFIX}.inputs.paymentInstitution.value`, {
                                paymentInstitution: paymentTransactionToManualDisbursement?.paymentInstitutionName,
                                paymentInstitutionStatus: t(
                                    `${I18N_PREFIX}.inputs.paymentInstitution.statusOptions.${paymentTransactionToManualDisbursement?.paymentInstitutionStatus}`
                                ),
                            })}
                            onClick={handleOpen}
                            onFocus={handleOpen}
                            readOnly
                            externalUpdate
                            showValidation={showValidation}
                            validate={validateManualDisbursementPaymentInstitution}
                        />
                    )}
                </ModalSearch>
            </div>
            <div className="scd-payment-transaction__detail--form-item">
                <ModalSearch<PaymentInstitutionToSearch>
                    action={searchPaymentInstitutionsAccountsRequest}
                    requestParameters={{
                        technicalSupplierId: paymentTransactionToManualDisbursement?.paymentInstitutionId,
                        originatorId: paymentTransaction?.originator?.id,
                    }}
                    itemSize={SEARCH_ITEM_SIZE}
                    modalTitle={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}
                    modalLabel={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}
                    onSelect={account =>
                        handleChange({
                            paymentInstitutionAccountId: account.id,
                            paymentInstitutionAccountName: account.name,
                            paymentInstitutionAccountStatus: account.status,
                        })
                    }
                    renderItem={account => account.name}
                    statusSelector={(state: IRootState) => state.searchPaymentInstitutionsAccounts.status}
                    dataSelector={(state: IRootState) => state.searchPaymentInstitutionsAccounts.searchPaymentInstitutionsAccounts}
                    resetState={searchPaymentInstitutionsAccountsResetState}
                >
                    {handleOpen => (
                        <>
                            <ValidSearchInput
                                label={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}
                                value={
                                    paymentTransactionToManualDisbursement?.paymentInstitutionAccountId
                                        ? t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.value`, {
                                              paymentInstitutionAccount:
                                                  paymentTransactionToManualDisbursement?.paymentInstitutionAccountName,
                                              paymentInstitutionAccountStatus: t(
                                                  `${I18N_PREFIX}.inputs.paymentInstitutionAccount.status.options.${paymentTransactionToManualDisbursement?.paymentInstitutionAccountStatus}`
                                              ),
                                          })
                                        : ''
                                }
                                onClick={handleOpen}
                                onFocus={handleOpen}
                                disabled={!paymentTransactionToManualDisbursement?.paymentInstitutionId}
                                readOnly
                                externalUpdate
                                showValidation={showValidation}
                                validate={validateManualDisbursementPaymentInstitutionAccount}
                            />
                        </>
                    )}
                </ModalSearch>
            </div>
        </ReadOnlyCard>
    );
};

export default PaymentInstitutionForm;
