import { GiroWebappError } from 'model/error';
import { ProgramFilterPageable, ScdProgramList } from 'model/program';
import { ListProgramsTypes } from 'reducer/program/list-programs/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listProgramsRequest = (request: ProgramFilterPageable) => action(ListProgramsTypes.LIST_PROGRAMS_REQUEST, request);
export const listProgramsSuccess = (programs: Page<ScdProgramList>) => action(ListProgramsTypes.LIST_PROGRAMS_SUCCESS, programs);
export const listProgramsError = (error: GiroWebappError) => action(ListProgramsTypes.LIST_PROGRAMS_ERROR, error);
export const listProgramsResetState = () => action(ListProgramsTypes.LIST_PROGRAMS_RESET_STATE);
