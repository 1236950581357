import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import './error-filter-button.scss';

export interface ErrorFilterButtonProps {
    onClick: () => void;
    label?: string;
    disabled?: boolean;
    icon?: React.ReactFragment;
    isActiveFilter?: boolean;
    errorsAmount?: number | undefined;
}

const ErrorFilterButton = (props: ErrorFilterButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const { disabled, onClick, label, errorsAmount, isActiveFilter } = props;

    return (
        <div className={isActiveFilter ? 'button__style--filter-error-active' : 'button__style--filter-error'}>
            <Button variant="contained" onClick={onClick} disabled={disabled}>
                <span className="button__style--filter-error-label">{label ? label : t('entity.action.filter')}</span>
                {(errorsAmount || errorsAmount === 0) && (
                    <div
                        className={clsx(
                            { 'button__style--filter-error-active-circle': isActiveFilter && errorsAmount !== 0 },
                            { 'button__style--filter-error-circle': !isActiveFilter && errorsAmount !== 0 },
                            { 'button__style--filter-error-empty': !isActiveFilter && errorsAmount === 0 },
                            { 'button__style--filter-error-active-circle': isActiveFilter && errorsAmount === 0 }
                        )}
                    >
                        <span
                            className={isActiveFilter ? 'button__style--filter-error-active-errors' : 'button__style--filter-error-errors'}
                        >
                            {errorsAmount}
                        </span>
                    </div>
                )}
            </Button>
        </div>
    );
};

export default ErrorFilterButton;
