import { AxiosResponse } from 'axios';
import { ScdTaxList, ScdTaxSimple, ScdTaxToCreate } from 'model/tax';
import { api } from 'services/api/api';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';

export const tax = () => {
    const createTax = (request: ScdTaxToCreate): Promise<AxiosResponse<ScdTaxSimple>> => {
        return api.post<ScdTaxSimple>(`api/taxes`, request);
    };

    const listTaxes = (request: Pageable): Promise<AxiosResponse<Page<ScdTaxList>>> => {
        return api.get<Page<ScdTaxList>>(`api/taxes`, { params: { ...request } });
    };

    return {
        createTax,
        listTaxes,
    };
};

export default tax();
