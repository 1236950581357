import { GetProgramInfoState } from './get-program-info/types';
import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create Program Policy
import { createProgramPolicyReducer } from 'reducer/program-policy/create-program-policy/reducer';
import createProgramPolicySaga from 'reducer/program-policy/create-program-policy/sagas';
import { CreateProgramPolicyState } from 'reducer/program-policy/create-program-policy/types';

// Detail Program Policy
import { detailProgramPolicyReducer } from 'reducer/program-policy/detail-program-policy/reducer';
import detailProgramPolicySaga from 'reducer/program-policy/detail-program-policy/sagas';
import { DetailProgramPolicyState } from 'reducer/program-policy/detail-program-policy/types';

// List Program Policies
import { listProgramPoliciesReducer } from 'reducer/program-policy/list-program-policies/reducer';
import listProgramPoliciesSaga from 'reducer/program-policy/list-program-policies/sagas';
import { ListProgramPoliciesState } from 'reducer/program-policy/list-program-policies/types';

// Update Program Policy
import { updateProgramPolicyReducer } from 'reducer/program-policy/update-program-policy/reducer';
import updateProgramPolicySaga from 'reducer/program-policy/update-program-policy/sagas';

// Get Program Info
import { UpdateProgramPolicyState } from 'reducer/program-policy/update-program-policy/types';
import { getProgramInfoReducer } from 'reducer/program-policy/get-program-info/reducer';
import getProgramInfoSaga from 'reducer/program-policy/get-program-info/sagas';

export interface ProgramPolicyState {
    readonly createProgramPolicy: CreateProgramPolicyState;
    readonly detailProgramPolicy: DetailProgramPolicyState;
    readonly listProgramPolicies: ListProgramPoliciesState;
    readonly updateProgramPolicy: UpdateProgramPolicyState;
    readonly getProgramInfo: GetProgramInfoState;
}

export const ProgramPolicyReducer: ReducersMapObject<ProgramPolicyState, AnyAction> = {
    createProgramPolicy: createProgramPolicyReducer,
    detailProgramPolicy: detailProgramPolicyReducer,
    listProgramPolicies: listProgramPoliciesReducer,
    updateProgramPolicy: updateProgramPolicyReducer,
    getProgramInfo: getProgramInfoReducer
};

export function* programPolicySagas() {
    yield all([fork(createProgramPolicySaga), fork(detailProgramPolicySaga), fork(listProgramPoliciesSaga), fork(updateProgramPolicySaga), fork(getProgramInfoSaga)]);
}
