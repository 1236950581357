import CloseButton from 'components/buttons/close-button/close-button';
import LinkButton from 'components/buttons/link-button/link-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import PaymentTransactionInputSearchBatches from 'entities/payment-transaction/components/payment-transaction-input-search-batches/payment-transaction-input-search-batches';
import { ScdSearchPaymentImportBatch } from 'model/batch';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchPaymentImportBatchesRequest } from 'reducer/batch/search-payment-import-batches/actions';
import './payment-transaction-in-series-modal.scss';

export interface PaymentTransactionModalInSeriesProps {
    onClose: () => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.in-series';

const PaymentTransactionModalInSeries = (props: PaymentTransactionModalInSeriesProps) => {
    const { onClose } = props;
    const { url } = useRouteMatch();
    const [selectedBatch, setSelectedBatch] = useState<ScdSearchPaymentImportBatch>();

    const { t } = useTranslation();
    const history = useHistory();

    const handleConfirm = () => {
        if (!selectedBatch) return;
        history.push(`${url}/in-series/disbursement/by-batch/${selectedBatch?.id}`, selectedBatch?.id);
    };

    return (
        <Modal isOpen>
            <div className="payment-transaction-modal-in-series">
                <article className="payment-transaction-modal-in-series--container">
                    <div className="payment-transaction-modal-in-series--header-close-button">
                        <CloseButton onClick={onClose} />
                    </div>
                    <div className="payment-transaction-modal-in-series--content">
                        <div className="payment-transaction-modal-in-series--header">
                            <label className="payment-transaction-modal-in-series--header-title"> {t(`${I18N_PREFIX}.title`)} </label>
                            <label className="payment-transaction-modal-in-series--header-subtitle">{t(`${I18N_PREFIX}.subtitle`)}</label>
                        </div>
                        <div className="payment-transaction-modal-in-series--body">
                            <PaymentTransactionInputSearchBatches
                                action={searchPaymentImportBatchesRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                label={t(`${I18N_PREFIX}.input.batch.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.batch.placeholder`)}
                                onSelect={batch => setSelectedBatch({ ...batch })}
                                renderItem={batch =>
                                    batch.originator ? `${batch.originator} ${batch.code}` : `(Sem programa) ${batch?.code}`
                                }
                                statusSelector={(state: IRootState) => state.searchPaymentImportBatches.status}
                                dataSelector={(state: IRootState) => state.searchPaymentImportBatches.paymentImportBatches}
                                value={
                                    selectedBatch?.originator && selectedBatch?.code
                                        ? `${selectedBatch?.originator} ${selectedBatch?.code}`
                                        : ''
                                }
                            />
                        </div>
                        <div className="payment-transaction-modal-in-series--buttons-actions">
                            <LinkButton onClick={onClose} outlinedStyle label={t('entity.action.back')} />
                            <StandardButtonLegacy label="entity.action.import" onClick={handleConfirm} disabled={!selectedBatch} />
                        </div>
                        <label
                            className="payment-transaction-modal-in-series--content-link"
                            onClick={() => {
                                history.push(`${url}/in-series`);
                                onClose();
                            }}
                        >
                            {t(`${I18N_PREFIX}.manual-registration`).toLocaleUpperCase()}
                        </label>
                    </div>
                </article>
            </div>
        </Modal>
    );
};

export default PaymentTransactionModalInSeries;
