import { ScdCompanyDetails } from './../../../model/company';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import companyAPI from 'services/api/company-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { CompanyDetailsTypes } from './types';
import { companyDetailsRequest, companyDetailsError, companyDetailsSuccess } from './actions';

function* handleCompanyDetails(action: ReturnType<typeof companyDetailsRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyDetails> = yield call(companyAPI.detailCompany, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(companyDetailsError(mapped));
            return;
        }
        yield put(companyDetailsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(companyDetailsError(mapped));
    }
}

function* watchCompanyDetails() {
    yield takeLeading(CompanyDetailsTypes.COMPANY_DETAILS_REQUEST, handleCompanyDetails);
}

function* companyDetailsSaga() {
    yield all([fork(watchCompanyDetails)]);
}

export default companyDetailsSaga;
