import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { LimelightProxySendBackingState, ProvisionalLimelightProxySendBackingTypes } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/types';

const initialState: LimelightProxySendBackingState = {
    status: HttpRequestStatus.NOOP,
    hemeraResponse: undefined,
    error: undefined
}

const reducer: Reducer<LimelightProxySendBackingState> = (state = initialState, action): LimelightProxySendBackingState => {
    switch (action.type) {
        case ProvisionalLimelightProxySendBackingTypes.LIMELIGHT_PROXY_SEND_BACKING_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ProvisionalLimelightProxySendBackingTypes.LIMELIGHT_PROXY_SEND_BACKING_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, hemeraResponse: action.payload, error: undefined };
        }
        case ProvisionalLimelightProxySendBackingTypes.LIMELIGHT_PROXY_SEND_BACKING_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProvisionalLimelightProxySendBackingTypes.LIMELIGHT_PROXY_SEND_BACKING_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as limelightProxySendBackingReducer }