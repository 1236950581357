import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { removeProgramGroupRequest, removeProgramGroupError, removeProgramGroupSuccess } from 'reducer/program-groups/remove-program-group/actions';
import { RemoveProgramGroupTypes } from 'reducer/program-groups/remove-program-group/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import programGroupsApi from 'services/api/program-groups-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { ScdProgramResponse } from 'model/program';

function* handleRemoveProgramGroup(action: ReturnType<typeof removeProgramGroupRequest>) {
    try {
        const result: AxiosResponse<ScdProgramResponse> = yield call(programGroupsApi.removeCompanyGroupFromProgram, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(removeProgramGroupError(mapped));
            return;
        }
        yield put(removeProgramGroupSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(removeProgramGroupError(mapped));
    }
}

function* watchRemoveProgramGroup() {
    yield takeLeading(RemoveProgramGroupTypes.REMOVE_PROGRAM_GROUP_REQUEST, handleRemoveProgramGroup);
}

function* removeProgramGroupSaga() {
    yield all([fork(watchRemoveProgramGroup)]);
}

export default removeProgramGroupSaga;
