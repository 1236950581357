import { AxiosResponse } from 'axios';
import { ChunkStreamSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import {
    chunkStreamRetryBcoError,
    chunkStreamRetryBcoRequest,
    chunkStreamRetryBcoSuccess,
} from 'reducer-bco/batch-output/retry-chunk-stream/action';
import { ChunkStreamRetryBcoTypes } from 'reducer-bco/batch-output/retry-chunk-stream/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleChunkStreamRetryBco(action: ReturnType<typeof chunkStreamRetryBcoRequest>) {
    try {
        const result: AxiosResponse<ChunkStreamSimpleResponse> = yield call(batchOutputBcoApi.chunkStreamRetry, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(chunkStreamRetryBcoError(mapped));
            return;
        }
        yield put(chunkStreamRetryBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(chunkStreamRetryBcoError(mapped));
    }
}

function* watchChunkStreamRetryBcoSaga() {
    yield takeEvery(ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_REQUEST, handleChunkStreamRetryBco);
}

function* chunkStreamRetryBcoSaga() {
    yield all([fork(watchChunkStreamRetryBcoSaga)]);
}

export default chunkStreamRetryBcoSaga;
