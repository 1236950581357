import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchExportState, BatchExportTypes } from 'reducer/batch/batch-export/types';

const initialState: BatchExportState = {
    status: HttpRequestStatus.NOOP,
    batchExport: undefined,
    error: undefined
}

const reducer: Reducer<BatchExportState> = (state = initialState, action): BatchExportState => {
    switch (action.type) {
        case BatchExportTypes.BATCH_EXPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchExportTypes.BATCH_EXPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchExport: action.payload, error: undefined };
        }
        case BatchExportTypes.BATCH_EXPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchExportTypes.BATCH_EXPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchExportReducer }