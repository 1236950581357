import { ScdPaymentTransactionsLegacyByBatch } from 'model/payment-transaction-by-batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ListPaymentTransactionsByBatchLegacyTypes {
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST = '@@payment-transactions-by-batch-list-legacy/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS = '@@payment-transactions-by-batch-list-legacy/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR = '@@payment-transactions-by-batch-list-legacy/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET = '@@payment-transactions-by-batch-list-legacy/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET',
}

export interface ListPaymentTransactionsByBatchLegacyState extends BaseStoreState {
    readonly paymentTransactionsByBatchLegacy?: ScdPaymentTransactionsLegacyByBatch;
}
