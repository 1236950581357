import MenuItem from '@material-ui/core/MenuItem';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateCommissionPolicy,
    validateCompanyGroupPolicy,
    validateDisbursementPolicy,
    validateDisbursmentPaymentPolicy,
    validateMonthlyCommissionPolicy,
} from 'entities/company-group-policy/validation/company-group-policy-validation';
import { validateCompanyGroupName } from 'entities/company-group/validation/company-group-validation';
import { ScdCompanyGroup } from 'model/company-group';
import { ScdCompanyGroupPolicy, defaultScdCompanyGroupPolicy } from 'model/company-group-policy';
import { CompanyGroupCommissionPolicy } from 'model/enums/company-group-commission-policy';
import { CompanyGroupDisbursementPaymentPolicy } from 'model/enums/company-group-disbursement-payment-policy';
import { CompanyGroupMonthlyCommissionPolicy } from 'model/enums/company-group-monthly-commission-policy';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PolicyDisbursementGroupBy } from 'model/enums/policy-disbursement-group';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import {
    createCompanyGroupPolicyRequest,
    createCompanyGroupPolicyResetState,
} from 'reducer/company-group-policy/create-company-group-policy/actions';
import {
    detailCompanyGroupPolicyRequest,
    detailCompanyGroupPolicyResetState,
} from 'reducer/company-group-policy/detail-company-group-policy/actions';
import {
    updateCompanyGroupPolicyRequest,
    updateCompanyGroupPolicyResetState,
} from 'reducer/company-group-policy/update-company-group-policy/actions';
import { searchCompanyGroupsRequest, searchCompanyGroupsResetState } from 'reducer/company-group/search-company-groups/actions';
import {
    useCreateCompanyGroupPolicyState,
    useDetailCompanyGroupPolicyState,
    useRootDispatch,
    useUpdateCompanyGroupPolicyState,
} from 'reducer/hooks';
import './company-group-policy-create-update.scss';

export interface ScdCompanyGroupUpdateProps extends RouteComponentProps<{ companyGroupPolicyId: string }> {}

const I18N_PREFIX = 'pages.scd.company-group-policy';

const ScdCompanyGroupPolicyCreateUpdatePage = (props: ScdCompanyGroupUpdateProps) => {
    const { t } = useTranslation();
    const [companyGroupPolicyId] = useState<string | undefined>(props.match?.params?.companyGroupPolicyId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateCompanyGroupPolicyState();
    const { status: updateStatus } = useUpdateCompanyGroupPolicyState();
    const { status: detailStatus, companyGroupPolicy: detailCompanyGroupPolicy } = useDetailCompanyGroupPolicyState();

    const [scdCompanyGroupPolicy, setScdCompanyGroupPolicy] = useState<ScdCompanyGroupPolicy>(defaultScdCompanyGroupPolicy);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _scdCompanyGroupPolicy: ScdCompanyGroupPolicy = {
            ...scdCompanyGroupPolicy,
        };

        if (!validateCompanyGroupPolicy(_scdCompanyGroupPolicy)) return;

        if (companyGroupPolicyId) {
            dispatch(updateCompanyGroupPolicyRequest(_scdCompanyGroupPolicy));
        } else {
            dispatch(createCompanyGroupPolicyRequest(_scdCompanyGroupPolicy));
        }
    };

    const handleChange = (value: Partial<ScdCompanyGroupPolicy>) => {
        setScdCompanyGroupPolicy(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (companyGroupPolicyId) {
            dispatch(detailCompanyGroupPolicyRequest(companyGroupPolicyId));
        }
    }, [companyGroupPolicyId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailCompanyGroupPolicy !== undefined) {
            setScdCompanyGroupPolicy(detailCompanyGroupPolicy);
        }
    }, [detailStatus, detailCompanyGroupPolicy]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            history.push('/company-group-policies');
        }
    }, [updateStatus, history]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push('/company-group-policies');
        }
    }, [createStatus, history]);

    useEffect(() => {
        return () => {
            dispatch(createCompanyGroupPolicyResetState());
            dispatch(updateCompanyGroupPolicyResetState());
            dispatch(detailCompanyGroupPolicyResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-company-group-policy-create-update__create">
            <ContextRibbon />
            <div className="scd-company-group-policy-create-update--container">
                <header className="scd-company-group-policy-create-update--header">
                    <h2 className="scd-company-group-policy-create-update--header-title">
                        {!companyGroupPolicyId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && companyGroupPolicyId ? (
                    <Loading />
                ) : (
                    <form className="scd-company-group-policy-create-update--form">
                        {!companyGroupPolicyId && (
                            <div className="scd-company-group-policy-create-update--form-field">
                                <ModalSearch<ScdCompanyGroup>
                                    action={searchCompanyGroupsRequest}
                                    itemSize={SEARCH_ITEM_SIZE}
                                    modalTitle={t(`${I18N_PREFIX}.input.companyGroup.modal.title`)}
                                    modalLabel={t(`${I18N_PREFIX}.input.companyGroup.modal.label`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.input.companyGroup.modal.placeholder`)}
                                    onSelect={companyGroup => handleChange({ companyGroup })}
                                    renderItem={companyGroup => companyGroup.name}
                                    statusSelector={(state: IRootState) => state.searchCompanyGroups.status}
                                    dataSelector={(state: IRootState) => state.searchCompanyGroups.companyGroups}
                                    resetState={searchCompanyGroupsResetState}
                                >
                                    {handleOpen => (
                                        <>
                                            <ValidSearchInput
                                                label={`${I18N_PREFIX}.input.companyGroup.label`}
                                                showValidation={showValidation}
                                                placeholder={`${I18N_PREFIX}.input.companyGroup.placeholder`}
                                                validate={validateCompanyGroupName}
                                                value={scdCompanyGroupPolicy.companyGroup?.name ?? ''}
                                                onClick={handleOpen}
                                                onFocus={handleOpen}
                                                readOnly
                                                externalUpdate
                                            />
                                        </>
                                    )}
                                </ModalSearch>
                            </div>
                        )}
                        <div className="scd-company-group-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.disbursementGroupBy.label`)}
                                value={scdCompanyGroupPolicy?.disbursementGroupBy ?? ''}
                                validate={_value => validateDisbursementPolicy(PolicyDisbursementGroupBy[_value])}
                                showValidation={showValidation}
                                mapperFromString={_value => (_value ? PolicyDisbursementGroupBy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.disbursementGroupBy.placeholder`)}
                                onChange={(disbursementGroupBy: string) =>
                                    handleChange({ disbursementGroupBy: PolicyDisbursementGroupBy[disbursementGroupBy] })
                                }
                                externalUpdate
                            >
                                <MenuItem value={PolicyDisbursementGroupBy.LEVEL_ONE}>
                                    {t(`${I18N_PREFIX}.input.disbursementGroupBy.options.LEVEL_ONE`)}
                                </MenuItem>
                                {/* LEVEL_TWO not yet available */}
                                {/* <MenuItem value={PolicyDisbursementGroupBy.LEVEL_TWO}>
                                    {t(`${I18N_PREFIX}.input.disbursementGroupBy.options.LEVEL_TWO`)}
                                </MenuItem> */}
                                <MenuItem value={PolicyDisbursementGroupBy.LEVEL_THREE}>
                                    {t(`${I18N_PREFIX}.input.disbursementGroupBy.options.LEVEL_THREE`)}
                                </MenuItem>
                                <MenuItem value={PolicyDisbursementGroupBy.NO_DISBURSEMENT}>
                                    {t(`${I18N_PREFIX}.input.disbursementGroupBy.options.NO_DISBURSEMENT`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-company-group-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.label`)}
                                value={scdCompanyGroupPolicy?.disbursementPaymentPolicy ?? ''}
                                validate={_value => validateDisbursmentPaymentPolicy(CompanyGroupDisbursementPaymentPolicy[_value])}
                                showValidation={showValidation}
                                mapperFromString={_value => (_value ? CompanyGroupDisbursementPaymentPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.placeholder`)}
                                onChange={(disbursementPaymentPolicy: string) =>
                                    handleChange({
                                        disbursementPaymentPolicy: CompanyGroupDisbursementPaymentPolicy[disbursementPaymentPolicy],
                                    })
                                }
                                externalUpdate
                            >
                                <MenuItem value={CompanyGroupDisbursementPaymentPolicy.LEVEL_ONE}>
                                    {t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.options.LEVEL_ONE`)}
                                </MenuItem>
                                {/* LEVEL_TWO not yet available */}
                                {/* <MenuItem value={CompanyGroupDisbursementPaymentPolicy.LEVEL_TWO}>
                                    {t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.options.LEVEL_TWO`)}
                                </MenuItem> */}
                                <MenuItem value={CompanyGroupDisbursementPaymentPolicy.LEVEL_THREE}>
                                    {t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.options.LEVEL_THREE`)}
                                </MenuItem>
                                <MenuItem value={CompanyGroupDisbursementPaymentPolicy.NO_DISBURSEMENT}>
                                    {t(`${I18N_PREFIX}.input.disbursementPaymentPolicy.options.NO_DISBURSEMENT`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-company-group-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.commissionPolicy.label`)}
                                value={scdCompanyGroupPolicy?.commissionPolicy ?? ''}
                                validate={_value => validateCommissionPolicy(CompanyGroupCommissionPolicy[_value])}
                                showValidation={showValidation}
                                mapperFromString={_value => (_value ? CompanyGroupCommissionPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.commissionPolicy.placeholder`)}
                                onChange={(commissionPolicy: string) =>
                                    handleChange({ commissionPolicy: CompanyGroupCommissionPolicy[commissionPolicy] })
                                }
                                externalUpdate
                            >
                                <MenuItem value={CompanyGroupCommissionPolicy.LEVEL_ONE}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.LEVEL_ONE`)}
                                </MenuItem>
                                {/* LEVEL_TWO not yet available */}
                                {/* <MenuItem value={CompanyGroupCommissionPolicy.LEVEL_TWO}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.LEVEL_TWO`)}
                                </MenuItem> */}
                                <MenuItem value={CompanyGroupCommissionPolicy.LEVEL_THREE}>
                                    {t(`${I18N_PREFIX}.input.disbursementGroupBy.options.LEVEL_THREE`)}
                                </MenuItem>
                                <MenuItem value={CompanyGroupCommissionPolicy.NO_COMMISSION}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.NO_COMMISSION`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>

                        <div className="scd-company-group-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.label`)}
                                value={scdCompanyGroupPolicy?.monthlyCommissionPolicy ?? ''}
                                validate={_value => validateMonthlyCommissionPolicy(CompanyGroupMonthlyCommissionPolicy[_value])}
                                showValidation={showValidation}
                                mapperFromString={_value => (_value ? CompanyGroupMonthlyCommissionPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.placeholder`)}
                                onChange={(monthlyCommissionPolicy: string) =>
                                    handleChange({ monthlyCommissionPolicy: CompanyGroupMonthlyCommissionPolicy[monthlyCommissionPolicy] })
                                }
                                externalUpdate
                            >
                                <MenuItem value={CompanyGroupMonthlyCommissionPolicy.LEVEL_ONE}>
                                    {t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.options.LEVEL_ONE`)}
                                </MenuItem>
                                {/* LEVEL_TWO not yet available */}
                                {/* <MenuItem value={CompanyGroupMonthlyCommissionPolicy.LEVEL_TWO}>
                                    {t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.options.LEVEL_TWO`)}
                                </MenuItem> */}
                                <MenuItem value={CompanyGroupMonthlyCommissionPolicy.LEVEL_THREE}>
                                    {t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.options.LEVEL_THREE`)}
                                </MenuItem>
                                <MenuItem value={CompanyGroupMonthlyCommissionPolicy.NO_COMMISSION}>
                                    {t(`${I18N_PREFIX}.input.monthlyCommissionPolicy.options.NO_COMMISSION`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-company-group-policy-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={companyGroupPolicyId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.goBack();
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdCompanyGroupPolicyCreateUpdatePage;
