import ExternalStrategyDateUtils from 'shared/external/util/external-date-utils';

export enum TransfeeraExternalTransfersStatus {
    CRIADA = 'CRIADA',
    RECEBIDO = 'RECEBIDO',
    TRANSFERIDO = 'TRANSFERIDO',
    FINALIZADO = 'FINALIZADO',
    FALHA = 'FALHA',
    DEVOLVIDO = 'DEVOLVIDO',
    AGENDADO = 'AGENDADO'
}

export interface TransfeeraExternalTransfersData {
    id?: string | null;
    value?: number | null;
    status?: TransfeeraExternalTransfersStatus | string | null;
    created_at?: Date | null;
    transfer_date?: Date | null;
    returned_date?: Date | null;
    payment_date?: Date | null;
    receipt_url?: string | null;
    bank_receipt_url?: string | null;
    DestinationBankAccount?: {
        id?: string | null;
        name?: string | null;
        cpf_cnpj?: string | null;
        bank_id?: string | null;
        agency?: string | null;
        agency_digit?: string | null;
        account?: string | null;
        account_digit?: string | null;
        account_type?: string | null;
        email?: string | null;
        pix_key?: string | null;
        pix_key_type?: string | null;
        status_description?: string | null;
        status?: string | null;
        Bank?: {
            id?: string | null;
            name?: string | null;
            code?: string | null;
            ispb?: string | null;
            image?: string | null;
        }
    }
}

export interface TransfeeraExternalTransfersMetaData {
    pagination: {
        itemsPerPage: number;
        totalItems: number;
    }
}

export interface TransfeeraExternalTransfersGeneralResponse {
    data: ReadonlyArray<TransfeeraExternalTransfersData>;
    metadata: TransfeeraExternalTransfersMetaData;
}

export interface TransfeeraExternalTransfersFilterRequest {
    initialDate: string;
    endDate: string;
    status?: TransfeeraExternalTransfersStatus;
    search?: string;
}

export const defaultTransfeeraExternalTransfersFilter: TransfeeraExternalTransfersFilterRequest = {
    initialDate: ExternalStrategyDateUtils.getFirstDayOfMonth(),
    endDate: ExternalStrategyDateUtils.getTodayDay(),
    status: undefined,
    search: undefined
}

export interface TransfeeraTransfersPageable {
    page: number;
}

export interface TransfeeraExternalTransfersFilterPageable {
    accountId?: number;
    filter?: TransfeeraExternalTransfersFilterRequest;
    pageable?: TransfeeraTransfersPageable;
}

export interface TransfeeraExternalTransfersError {
    data?: object;
    error?: string;
    errorCode?: string;
    field?: string;
    message?: string;
    statusCode?: number;
    path?: string;
}
