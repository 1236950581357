import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List program groups
import { ListProgramGroupsState } from 'reducer/program-groups/list-program-groups/types';
import { listProgramGroupsReducer } from 'reducer/program-groups/list-program-groups/reducer';
import listProgramGroupsSaga from 'reducer/program-groups/list-program-groups/sagas';

// Add program group in program
import { AddProgramGroupState } from 'reducer/program-groups/add-program-group/types';
import { addProgramGroupReducer } from 'reducer/program-groups/add-program-group/reducer';
import addProgramGroupSaga from 'reducer/program-groups/add-program-group/sagas';

// Remove program group from program
import { RemoveProgramGroupState } from 'reducer/program-groups/remove-program-group/types';
import { removeProgramGroupReducer } from 'reducer/program-groups/remove-program-group/reducer';
import removeProgramGroupSaga from 'reducer/program-groups/remove-program-group/sagas';

// Program to resume
import { ProgramToResumeState } from 'reducer/program-groups/resume-program/types';
import { programToResumeReducer } from 'reducer/program-groups/resume-program/reducer';
import programToResumeSaga from 'reducer/program-groups/resume-program/sagas';

export interface ProgramGroupsState {
    readonly listProgramGroups: ListProgramGroupsState;
    readonly addProgramGroup: AddProgramGroupState;
    readonly removeProgramGroup: RemoveProgramGroupState;
    readonly resumeProgram: ProgramToResumeState;
}

export const programGroupsReducer: ReducersMapObject<ProgramGroupsState, AnyAction> = {
    listProgramGroups: listProgramGroupsReducer,
    addProgramGroup: addProgramGroupReducer,
    removeProgramGroup: removeProgramGroupReducer,
    resumeProgram: programToResumeReducer
}

export function* programGroupsSagas() {
    yield all([
        fork(listProgramGroupsSaga),
        fork(addProgramGroupSaga),
        fork(removeProgramGroupSaga),
        fork(programToResumeSaga)
    ])
}