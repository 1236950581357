import { AxiosResponse } from 'axios';
import { ChunkStreamToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import { chunkStreamToResumeBcoError, chunkStreamToResumeBcoRequest, chunkStreamToResumeBcoSuccess } from 'reducer-bco/batch-output/chunk-stream-to-resume/action';
import { ChunkStreamToResumeBcoTypes } from 'reducer-bco/batch-output/chunk-stream-to-resume/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleChunkStreamToResumeBco(action: ReturnType<typeof chunkStreamToResumeBcoRequest>) {
    try {
        const result: AxiosResponse<ChunkStreamToResumeBco> = yield call(batchOutputBcoApi.chunkStreamToResume, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(chunkStreamToResumeBcoError(mapped));
            return;
        }
        yield put(chunkStreamToResumeBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(chunkStreamToResumeBcoError(mapped));
    }
}

function* watchChunkStreamToResumeBcoSaga() {
    yield takeEvery(ChunkStreamToResumeBcoTypes.CHUNK_STREAM_TO_RESUME_BCO_REQUEST, handleChunkStreamToResumeBco);
}

function* chunkStreamToResumeBcoSaga() {
    yield all([fork(watchChunkStreamToResumeBcoSaga)]);
}

export default chunkStreamToResumeBcoSaga;
