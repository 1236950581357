import { AxiosResponse } from 'axios';
import { ScdCompanyItem } from 'model/company-item';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    detailCompanyItemError,
    detailCompanyItemRequest,
    detailCompanyItemSuccess,
} from 'reducer/company-item/detail-company-item/actions';
import { DetailCompanyItemTypes } from 'reducer/company-item/detail-company-item/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyItemAPI from 'services/api/company-item-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailCompanyItem(action: ReturnType<typeof detailCompanyItemRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyItem> = yield call(companyItemAPI.detailCompanyItem, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailCompanyItemError(mapped));
            return;
        }
        yield put(detailCompanyItemSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailCompanyItemError(mapped));
    }
}

function* watchDetailCompanyItem() {
    yield takeEvery(DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_REQUEST, handleDetailCompanyItem);
}

function* detailCompanyItemSaga() {
    yield all([fork(watchDetailCompanyItem)]);
}

export default detailCompanyItemSaga;
