import { ScdCompany } from 'model/company';
import { ScdCompanyGroupSimple } from 'model/company-group';
import { CompanyItemStatus, CompanyItemType } from 'model/enums/company-item';

export interface ScdCompanyItem {
    id?: string;
    commissionTax?: number;
    type?: CompanyItemType;
    status?: CompanyItemStatus;
    company?: ScdCompany;
    companyGroup?: ScdCompanyGroupSimple;
}

export const defaultScdCompanyItem: ScdCompanyItem = {
    id: undefined,
    commissionTax: undefined,
    type: undefined,
    status: undefined,
    company: undefined,
    companyGroup: undefined,
};

export interface ScdCompanyItemSimple {
    id: string;
    commisionTax: number;
    type: CompanyItemType;
    status: CompanyItemStatus;
}

export interface ScdCompanyItemSearch {
    id: string;
    name: string;
    identification: string;
}
