import { ScdBankerList } from 'model/banker';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListBankersTypes {
    LIST_BANKERS_REQUEST = '@@bankers-list/LIST_BANKERS_REQUEST',
    LIST_BANKERS_SUCCESS = '@@bankers-list/LIST_BANKERS_SUCCESS',
    LIST_BANKERS_ERROR = '@@bankers-list/LIST_BANKERS_ERROR',
    LIST_BANKERS_RESET_STATE = '@@bankers-list/LIST_BANKERS_RESET_STATE',
}

export interface ListBankersState extends BaseStoreState {
    readonly bankers?: Page<ScdBankerList>;
}
