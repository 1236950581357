import { ScdCompanyDetails } from 'model/company';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CompanyDetailsTypes {
    COMPANY_DETAILS_REQUEST = '@@company-details/COMPANY_DETAILS_REQUEST',
    COMPANY_DETAILS_SUCCESS = '@@company-details/COMPANY_DETAILS_SUCCESS',
    COMPANY_DETAILS_ERROR = '@@company-details/COMPANY_DETAILS_ERROR',
    COMPANY_DETAILS_RESET = '@@company-details/COMPANY_DETAILS_RESET',
}

export interface CompanyDetailsState extends BaseStoreState {
    readonly companyDetails?: ScdCompanyDetails;
}
