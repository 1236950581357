import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// create payment transactions by batch legacy
import { createPaymentTransactionsByBatchLegacyReducer } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/reducer';
import createPaymentTransactionsByBatchLegacySaga from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/sagas';
import { CreatePaymentTransactionsByBatchLegacyState } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/types';

// List payment transactions by batch legacy
import { listPaymentTransactionsByBatchLegacyReducer } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/reducer';
import listPaymentTransactionsByBatchLegacySaga from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/sagas';
import { ListPaymentTransactionsByBatchLegacyState } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/types';

// create payment transactions by batch
import { createPaymentTransactionsByBatchReducer } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/reducer';
import createPaymentTransactionsByBatchSaga from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/sagas';
import { CreatePaymentTransactionsByBatchState } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/types';

// List payment transactions by batch
import { listPaymentTransactionsByBatchReducer } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/reducer';
import listPaymentTransactionsByBatchSaga from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/sagas';
import { ListPaymentTransactionsByBatchState } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/types';

export interface PaymentTransactionByBatchState {
    readonly createPaymentTransactionsByBatchLegacy: CreatePaymentTransactionsByBatchLegacyState;
    readonly listPaymentTransactionsByBatchLegacy: ListPaymentTransactionsByBatchLegacyState;
    readonly createPaymentTransactionsByBatch: CreatePaymentTransactionsByBatchState;
    readonly listPaymentTransactionsByBatch: ListPaymentTransactionsByBatchState;
}

export const PaymentTransactionByBatchReducer: ReducersMapObject<PaymentTransactionByBatchState, AnyAction> = {
    createPaymentTransactionsByBatchLegacy: createPaymentTransactionsByBatchLegacyReducer,
    listPaymentTransactionsByBatchLegacy: listPaymentTransactionsByBatchLegacyReducer,
    createPaymentTransactionsByBatch: createPaymentTransactionsByBatchReducer,
    listPaymentTransactionsByBatch: listPaymentTransactionsByBatchReducer,
};

export function* paymentTransactionByBatchSagas() {
    yield all([
        fork(createPaymentTransactionsByBatchLegacySaga),
        fork(listPaymentTransactionsByBatchLegacySaga),
        fork(createPaymentTransactionsByBatchSaga),
        fork(listPaymentTransactionsByBatchSaga),
    ]);
}
