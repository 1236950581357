import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { defaultPaymentInstitutionAccountToCreateUpdate, PaymentInstitutionAccountToCreateUpdate } from 'model/payment-institution-account';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    useCreatePaymentInstitutionAccountState,
    useDetailPaymentInstitutionAccountState,
    useRootDispatch,
    useUpdatePaymentInstitutionAccountState,
} from 'reducer/hooks';
import {
    createPaymentInstitutionAccountRequest,
    createPaymentInstitutionAccountResetState,
} from 'reducer/payment-institutions-accounts/create-payment-institution-account/actions';
import { useToast } from 'shared/hooks/use-toast';

import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validatePaymentInstitutionAccount,
    validatePaymentInstitutionAccountExternalReference,
    validatePaymentInstitutionAccountIssuer,
    validatePaymentInstitutionAccountName,
    validatePaymentInstitutionAccountOriginator,
    validatePaymentInstitutionAccountStatus,
    validatePaymentInstitutionAccountTechnicalSupplier,
    validatePaymentInstitutionAccountType,
} from 'entities/payment-institutions-accounts/payment-institution-account-create-update/validation/payment-institution-account-validation';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentInstitutionAccountType } from 'model/enums/payment-institution-account-type';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { IRootState } from 'reducer';
import {
    detailPaymentInstitutionsAccountsRequest,
    detailPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/detail-payment-institution-account/actions';
import {
    updatePaymentInstitutionAccountRequest,
    updatePaymentInstitutionAccountResetState,
} from 'reducer/payment-institutions-accounts/update-payment-institution-account/actions';
import { searchTechnicalSuppliersRequest, searchTechnicalSuppliersResetState } from 'reducer/technical-supplier/search-originators/actions';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { ScdIssuerSearch } from 'model/issuer';
import { ScdOriginatorSearch } from 'model/originator';
import { searchIssuersRequest, searchIssuersResetState } from 'reducer/issuer/search-issuers/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import './payment-institution-account-create-update.scss';

interface PaymentInstitutionAccountCreateUpdateProps extends RouteComponentProps<{ paymentInstitutionAccountId?: string }> {}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

const PaymentInstitutionAccountCreateUpdate = (props: PaymentInstitutionAccountCreateUpdateProps) => {
    const { match } = props;

    const [paymentInstitutionAccountId] = useState<number | undefined>(
        match.params?.paymentInstitutionAccountId ? Number(match.params?.paymentInstitutionAccountId) : undefined
    );
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [scdPaymentInstitutionAccount, setScdPaymentInstitutionAccount] = useState<PaymentInstitutionAccountToCreateUpdate>(
        defaultPaymentInstitutionAccountToCreateUpdate
    );
    const [currentStatus, setCurrentStatus] = useState<PaymentInstitutionAccountStatus | undefined>(undefined);
    const [isOpenModalWarning, setIsOpenModalWarning] = useState<boolean>(false);

    const { t } = useTranslation();
    const { toastSuccess } = useToast();

    const { status: createStatus } = useCreatePaymentInstitutionAccountState();
    const { status: detailStatus, paymentInstitutionsAccount } = useDetailPaymentInstitutionAccountState();
    const { status: updateStatus } = useUpdatePaymentInstitutionAccountState();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleUpdatePaymentInstitutionAccount = useCallback(
        (_scdPaymentInstitutionAccount: PaymentInstitutionAccountToCreateUpdate) => {
            if (currentStatus === PaymentInstitutionAccountStatus.ACTIVE && currentStatus !== scdPaymentInstitutionAccount.status) {
                setIsOpenModalWarning(true);
                return;
            }

            dispatch(updatePaymentInstitutionAccountRequest(_scdPaymentInstitutionAccount));
        },
        [currentStatus, dispatch, scdPaymentInstitutionAccount.status]
    );

    const handleSave = () => {
        setShowValidation(true);

        if (!validatePaymentInstitutionAccount(scdPaymentInstitutionAccount)) return;

        if (paymentInstitutionAccountId) {
            handleUpdatePaymentInstitutionAccount(scdPaymentInstitutionAccount);
            return;
        }

        dispatch(createPaymentInstitutionAccountRequest(scdPaymentInstitutionAccount));
    };

    const handleChange = (value: Partial<PaymentInstitutionAccountToCreateUpdate>) => {
        setScdPaymentInstitutionAccount(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (!paymentInstitutionAccountId) return;

        dispatch(detailPaymentInstitutionsAccountsRequest(paymentInstitutionAccountId));
    }, [paymentInstitutionAccountId, dispatch]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast-success.create`));
        history.push('/payment-institutions-accounts');
    }, [createStatus, toastSuccess, history, t]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast-success.update`));
        history.push('/payment-institutions-accounts');
    }, [toastSuccess, t, history, updateStatus]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && paymentInstitutionsAccount) {
            setScdPaymentInstitutionAccount(paymentInstitutionsAccount);
            setCurrentStatus(paymentInstitutionsAccount.status);
        }
    }, [detailStatus, paymentInstitutionsAccount]);

    useEffect(() => {
        return () => {
            dispatch(createPaymentInstitutionAccountResetState());
            dispatch(detailPaymentInstitutionsAccountsResetState());
            dispatch(updatePaymentInstitutionAccountResetState());
        };
    }, [dispatch]);

    const isLoading: boolean = detailStatus === HttpRequestStatus.ON_GOING;
    const isCreateExclusive: boolean =
        !paymentInstitutionAccountId && scdPaymentInstitutionAccount.type === PaymentInstitutionAccountType.EXCLUSIVE;

    return (
        <main className="scd-payment-institution-account-create-update__create">
            <ContextRibbon />
            <div className="scd-payment-institution-account-create-update--container">
                <header className="scd-payment-institution-account-create-update--header">
                    <h2 className="scd-payment-institution-account-create-update--header-title">
                        {!paymentInstitutionAccountId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.update.title`)}
                    </h2>
                </header>
                {isLoading && paymentInstitutionAccountId ? (
                    <Loading />
                ) : (
                    <form className="scd-payment-institution-account-create-update--form">
                        <div className="scd-payment-institution-account-create-update--form-field">
                            <ValidTextInput
                                label={t(`${I18N_PREFIX}.inputs.name.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.name.placeholder`)}
                                value={scdPaymentInstitutionAccount?.name ?? ''}
                                onChange={name => handleChange({ name })}
                                showValidation={showValidation}
                                validate={validatePaymentInstitutionAccountName}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-payment-institution-account-create-update--form-field">
                            <ValidTextInput
                                label={t(`${I18N_PREFIX}.inputs.externalReference.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.externalReference.placeholder`)}
                                value={scdPaymentInstitutionAccount?.externalReference ?? ''}
                                onChange={externalReference => handleChange({ externalReference })}
                                showValidation={showValidation}
                                validate={externalReference =>
                                    validatePaymentInstitutionAccountExternalReference(externalReference, scdPaymentInstitutionAccount.type)
                                }
                                disabled={!!paymentInstitutionAccountId}
                                externalUpdate
                            />
                        </div>

                        <div className="scd-payment-institution-account-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.inputs.status.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.status.placeholder`)}
                                mapperFromString={_value => (_value ? PaymentInstitutionAccountStatus[_value] : undefined)}
                                value={scdPaymentInstitutionAccount?.status ?? ''}
                                onChange={status => handleChange({ status: PaymentInstitutionAccountStatus[status] })}
                                showValidation={showValidation}
                                validate={validatePaymentInstitutionAccountStatus}
                                externalUpdate
                            >
                                <MenuItem value={PaymentInstitutionAccountStatus.ACTIVE}>
                                    {t(`${I18N_PREFIX}.inputs.status.options.${PaymentInstitutionAccountStatus.ACTIVE}`)}
                                </MenuItem>
                                <MenuItem value={PaymentInstitutionAccountStatus.INACTIVE}>
                                    {t(`${I18N_PREFIX}.inputs.status.options.${PaymentInstitutionAccountStatus.INACTIVE}`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-payment-institution-account-create-update--form-field">
                            <ModalSearch<ScdTechnicalSupplier>
                                action={searchTechnicalSuppliersRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.inputs.technicalSupplier.title`)}
                                modalLabel={t(`${I18N_PREFIX}.inputs.technicalSupplier.label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.inputs.technicalSupplier.placeholder`)}
                                onSelect={technicalSupplier =>
                                    handleChange({
                                        technicalSupplierId: Number(technicalSupplier.id),
                                        technicalSupplierName: technicalSupplier.name,
                                    })
                                }
                                renderItem={technicalSupplier => technicalSupplier.name}
                                statusSelector={(state: IRootState) => state.searchTechnicalSuppliers.status}
                                dataSelector={(state: IRootState) => state.searchTechnicalSuppliers.technicalSuppliers}
                                resetState={searchTechnicalSuppliersResetState}
                            >
                                {handleOpen => (
                                    <>
                                        <ValidSearchInput
                                            label={t(`${I18N_PREFIX}.inputs.technicalSupplier.label`)}
                                            placeholder={t(`${I18N_PREFIX}.inputs.technicalSupplier.placeholder`)}
                                            value={scdPaymentInstitutionAccount.technicalSupplierName ?? ''}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            readOnly
                                            externalUpdate
                                            showValidation={showValidation}
                                            validate={validatePaymentInstitutionAccountTechnicalSupplier}
                                            disabled={!!paymentInstitutionAccountId}
                                        />
                                    </>
                                )}
                            </ModalSearch>
                        </div>
                        <div className="scd-payment-institution-account-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.inputs.type.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.type.placeholder`)}
                                mapperFromString={_value => (_value ? PaymentInstitutionAccountType[_value] : undefined)}
                                value={scdPaymentInstitutionAccount?.type ?? ''}
                                onChange={type =>
                                    handleChange({
                                        type: PaymentInstitutionAccountType[type],
                                        issuerId: undefined,
                                        issuerName: undefined,
                                        originatorId: undefined,
                                        originatorName: undefined,
                                    })
                                }
                                showValidation={showValidation}
                                validate={validatePaymentInstitutionAccountType}
                                disabled={!!paymentInstitutionAccountId}
                                externalUpdate
                            >
                                <MenuItem value={PaymentInstitutionAccountType.GENERAL}>
                                    {t(`${I18N_PREFIX}.inputs.type.options.${PaymentInstitutionAccountType.GENERAL}`)}
                                </MenuItem>
                                <MenuItem value={PaymentInstitutionAccountType.DIGITAL}>
                                    {t(`${I18N_PREFIX}.inputs.type.options.${PaymentInstitutionAccountType.DIGITAL}`)}
                                </MenuItem>
                                <MenuItem value={PaymentInstitutionAccountType.EXCLUSIVE}>
                                    {t(`${I18N_PREFIX}.inputs.type.options.${PaymentInstitutionAccountType.EXCLUSIVE}`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        {(isCreateExclusive || paymentInstitutionAccountId) && (
                            <>
                                <div className="scd-payment-institution-account-create-update--form-field">
                                    <ModalSearch<ScdOriginatorSearch>
                                        action={searchOriginatorsRequest}
                                        itemSize={SEARCH_ITEM_SIZE}
                                        modalTitle={t(`${I18N_PREFIX}.inputs.originator.title`)}
                                        modalLabel={t(`${I18N_PREFIX}.inputs.originator.label`)}
                                        modalPlaceholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)}
                                        onSelect={originator =>
                                            handleChange({
                                                originatorId: Number(originator.id),
                                                originatorName: originator.name,
                                            })
                                        }
                                        renderItem={originator => originator.name}
                                        statusSelector={(state: IRootState) => state.searchOriginators.status}
                                        dataSelector={(state: IRootState) => state.searchOriginators.originators}
                                        resetState={searchOriginatorsResetState}
                                    >
                                        {handleOpen => (
                                            <>
                                                <ValidSearchInput
                                                    label={t(`${I18N_PREFIX}.inputs.originator.label`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)}
                                                    value={scdPaymentInstitutionAccount.originatorName ?? ''}
                                                    onClick={handleOpen}
                                                    onFocus={handleOpen}
                                                    readOnly
                                                    externalUpdate
                                                    showValidation={showValidation}
                                                    validate={() =>
                                                        validatePaymentInstitutionAccountOriginator(
                                                            scdPaymentInstitutionAccount.originatorId,
                                                            scdPaymentInstitutionAccount.type
                                                        )
                                                    }
                                                    disabled={!!paymentInstitutionAccountId}
                                                />
                                            </>
                                        )}
                                    </ModalSearch>
                                </div>
                                <div className="scd-payment-institution-account-create-update--form-field">
                                    <ModalSearch<ScdIssuerSearch>
                                        action={searchIssuersRequest}
                                        itemSize={SEARCH_ITEM_SIZE}
                                        modalTitle={t(`${I18N_PREFIX}.inputs.issuer.title`)}
                                        modalLabel={t(`${I18N_PREFIX}.inputs.issuer.label`)}
                                        modalPlaceholder={t(`${I18N_PREFIX}.inputs.issuer.placeholder`)}
                                        onSelect={issuer =>
                                            handleChange({
                                                issuerId: Number(issuer.id),
                                                issuerName: issuer.name,
                                            })
                                        }
                                        renderItem={issuer => issuer.name}
                                        statusSelector={(state: IRootState) => state.searchIssuers.status}
                                        dataSelector={(state: IRootState) => state.searchIssuers.issuers}
                                        resetState={searchIssuersResetState}
                                    >
                                        {handleOpen => (
                                            <>
                                                <ValidSearchInput
                                                    label={t(`${I18N_PREFIX}.inputs.issuer.label`)}
                                                    placeholder={t(`${I18N_PREFIX}.inputs.issuer.placeholder`)}
                                                    value={scdPaymentInstitutionAccount.issuerName ?? ''}
                                                    onClick={handleOpen}
                                                    onFocus={handleOpen}
                                                    readOnly
                                                    externalUpdate
                                                    showValidation={showValidation}
                                                    validate={() =>
                                                        validatePaymentInstitutionAccountIssuer(
                                                            scdPaymentInstitutionAccount.issuerId,
                                                            scdPaymentInstitutionAccount.type
                                                        )
                                                    }
                                                    disabled={!!paymentInstitutionAccountId}
                                                />
                                            </>
                                        )}
                                    </ModalSearch>
                                </div>
                            </>
                        )}

                        <div className="scd-payment-institution-account-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={paymentInstitutionAccountId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/payment-institutions-accounts');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
            {isOpenModalWarning && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.warning.title`)}
                    message={t(`${I18N_PREFIX}.modal.warning.message`)}
                    onCancel={() => setIsOpenModalWarning(false)}
                    onClose={() => setIsOpenModalWarning(false)}
                    onAction={() => {
                        setIsOpenModalWarning(false);
                        dispatch(updatePaymentInstitutionAccountRequest(scdPaymentInstitutionAccount));
                    }}
                />
            )}
        </main>
    );
};

export default PaymentInstitutionAccountCreateUpdate;
