import { action } from 'typesafe-actions';
import { SearchOrganizationsTypes } from 'reducer/organization/search-organizations/types';
import { ScdOrganization } from 'model/organization';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchOrganizationsRequest = (request: SearchRequest) =>
    action(SearchOrganizationsTypes.SEARCH_ORGANIZATIONS_REQUEST, request);
export const searchOrganizationsSuccess = (organizations: Page<ScdOrganization>) =>
    action(SearchOrganizationsTypes.SEARCH_ORGANIZATIONS_SUCCESS, organizations);
export const searchOrganizationsError = (error: GiroWebappError) => action(SearchOrganizationsTypes.SEARCH_ORGANIZATIONS_ERROR, error);
export const searchOrganizationsResetState = () => action(SearchOrganizationsTypes.SEARCH_ORGANIZATIONS_RESET);
