import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateCompanyGroupTypes, UpdateCompanyGroupState } from 'reducer/company-group/update-company-group/types';

const initialState: UpdateCompanyGroupState = {
    status: HttpRequestStatus.NOOP,
    companyGroup: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateCompanyGroupState> = (state = initialState, action): UpdateCompanyGroupState => {
    switch (action.type) {
        case UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyGroup: action.payload, error: undefined };
        }
        case UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateCompanyGroupReducer };
