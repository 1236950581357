import { ScdCnabSimple } from 'model/cnab';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum GenerateCnabBatchTypes {
    GENERATE_CNAB_BATCH_REQUEST = '@@cnab-batch-generate/GENERATE_CNAB_BATCH_REQUEST',
    GENERATE_CNAB_BATCH_SUCCESS = '@@cnab-batch-generate/GENERATE_CNAB_BATCH_SUCCESS',
    GENERATE_CNAB_BATCH_ERROR = '@@cnab-batch-generate/GENERATE_CNAB_BATCH_ERROR',
    GENERATE_CNAB_BATCH_RESET = '@@cnab-batch-generate/GENERATE_CNAB_BATCH_RESET',
}

export interface GenerateCnabBatchState extends BaseStoreState {
    readonly generateCnabBatch?: ScdCnabSimple;
}
