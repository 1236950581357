export enum TaxType {
    PERSONAL_IOF = 'PERSONAL_IOF',
    PERSONAL_ADDITIONAL_IOF = 'PERSONAL_ADDITIONAL_IOF',
    CORPORATION_IOF = 'CORPORATION_IOF',
    CORPORATION_ADDITIONAL_IOF = 'CORPORATION_ADDITIONAL_IOF',
}

export enum TaxValueType {
    INCOME_TAX = 'INCOME_TAX',
    TAX_OVER_SERVICES = 'TAX_OVER_SERVICES',
    SOCIAL_TAXES = 'SOCIAL_TAXES',
}
