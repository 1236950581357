import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import './document-icon-button.scss';

interface DocumentIconButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    title?: string;
    isActive?: boolean;
}

const DocumentIconButton = (props: DocumentIconButtonProps) => {
    const { disabled, onClick, title, isActive } = props;

    const { t } = useTranslation();

    return (
        <div className="button__style--document-icon">
            <Button variant="contained" onClick={onClick} disabled={disabled}>
                <Tooltip title={t(title ?? 'tooltips.document').toString()} aria-label="visualize">
                    <Icon>
                        <div
                            className={clsx('button__style--document-icon--icon', {
                                ACTIVE: isActive,
                            })}
                        />
                    </Icon>
                </Tooltip>
            </Button>
        </div>
    );
};

export default DocumentIconButton;
