import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { FailedDocumentBind } from 'features/limelight/send-backings/domain/models';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './send-backings-failed-files-modal.scss';

interface SendBackingsFailedFilesModalProps {
    failedFiles: FailedDocumentBind[];
    clearFailedFiles: () => void;
}

const I18N_PREFIX = 'pages.scd.provisional-limelight';

const SendBackingsFailedFilesModal = (props: SendBackingsFailedFilesModalProps) => {
    const { t } = useTranslation();

    const { failedFiles, clearFailedFiles } = props;

    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
        clearFailedFiles();
    };

    useEffect(() => {
        if (failedFiles.length !== 0) {
            setOpenModal(true);
        }
    }, [failedFiles, setOpenModal]);

    const verifyErrorMessage = (errorMessage: string) => {
        for (const key in GiroWebappErrorConstants) {
            if (GiroWebappErrorConstants[key] === errorMessage) {
                return key;
            }
        }
        return 'DEFAULT_FILE_ERROR';
    };

    return (
        <>
            <Modal isOpen={openModal}>
                <div className="send-backings-modal-failed-files">
                    <article className="send-backings-modal-failed-files--content">
                        <header className="send-backings-modal-failed-files--header">
                            <CloseButton onClick={handleCloseModal} />
                        </header>
                        <div className="send-backings-modal-failed-files--message">
                            <p className="send-backings-modal-failed-files--title">{t(`${I18N_PREFIX}.modal-errors.title`)}</p>
                            <div className="send-backings-modal-failed-files--errors">
                                {failedFiles.map(({ file, error }) => (
                                    <div className="send-backings-modal-failed-files--errors__text" key={file.fileName}>
                                        <span>{file.fileName}</span>
                                        <span>
                                            <strong> {t(`${I18N_PREFIX}.modal-errors.${verifyErrorMessage(error)}.message`)} </strong>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {handleCloseModal && (
                            <div className="send-backings-modal-failed-files--actions">
                                {handleCloseModal && (
                                    <StandardButtonLegacy label={t(`${I18N_PREFIX}.modal-errors.button-back`)} onClick={handleCloseModal} />
                                )}
                            </div>
                        )}
                    </article>
                </div>
            </Modal>
        </>
    );
};

export default SendBackingsFailedFilesModal;
