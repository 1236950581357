import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupSimple } from 'model/company-group';

export type ApiResponse = Record<string, any>;

export enum CreateCompanyGroupTypes {
    CREATE_COMPANY_GROUP_REQUEST = '@@company-group-create/CREATE_COMPANY_GROUP_REQUEST',
    CREATE_COMPANY_GROUP_SUCCESS = '@@company-group-create/CREATE_COMPANY_GROUP_SUCCESS',
    CREATE_COMPANY_GROUP_ERROR = '@@company-group-create/CREATE_COMPANY_GROUP_ERROR',
    CREATE_COMPANY_GROUP_RESET = '@@company-group-create/CREATE_COMPANY_GROUP_RESET',
}

export interface CreateCompanyGroupState extends BaseStoreState {
    readonly companyGroup?: ScdCompanyGroupSimple;
}
