import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    TransfeeraExternalTransfersFilterRequest,
    defaultTransfeeraExternalTransfersFilter,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import ExternalStrategyDateUtils from 'shared/external/util/external-date-utils';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseTransfeeraTransfersFilterChipsProps {
    filterActive: TransfeeraExternalTransfersFilterRequest;
    setFilterActive: (value: TransfeeraExternalTransfersFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers';

type TransfeeraTransfersFilterRequestType = keyof TransfeeraExternalTransfersFilterRequest;

const useTransfeeraTransfersFilterChips = (props: UseTransfeeraTransfersFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: TransfeeraExternalTransfersFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<TransfeeraTransfersFilterRequestType, () => void> = useMemo(() => {
        return {
            initialDate: () => clearFilterSupport({ ...filterActive, initialDate: ExternalStrategyDateUtils.getFirstDayOfMonth() }),
            endDate: () => clearFilterSupport({ ...filterActive, endDate: ExternalStrategyDateUtils.getTodayDay() }),
            status: () => clearFilterSupport({ ...filterActive, status: undefined }),
            search: () => clearFilterSupport({ ...filterActive, search: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: TransfeeraTransfersFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filters.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const transfeeraTransfersFilterChips = useMemo(() => {
        const items: Record<TransfeeraTransfersFilterRequestType, ChipFilterItem> = {
            initialDate: {
                isActive:
                    FormatterUtils.isValidDate(filterActive.initialDate) &&
                    !_.isEqual(filterActive.initialDate, defaultTransfeeraExternalTransfersFilter.initialDate),
                onDelete: handleClearFilter['initialDate'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('initialDate')}
                        component={<DateFormatter date={filterActive.initialDate} />}
                    />
                ),
            },
            endDate: {
                isActive:
                    FormatterUtils.isValidDate(filterActive.endDate) &&
                    !_.isEqual(filterActive.endDate, defaultTransfeeraExternalTransfersFilter.endDate),
                onDelete: handleClearFilter['endDate'],
                renderItem: (
                    <ChipRenderItemComponent chipTitle={chipTitle('endDate')} component={<DateFormatter date={filterActive.endDate} />} />
                ),
            },
            status: {
                isActive: !!filterActive.status,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={t(`${I18N_PREFIX}.row.status.${filterActive.status}`)}
                    />
                ),
            },
            search: {
                isActive: !!filterActive.search,
                onDelete: handleClearFilter['search'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('search')} component={filterActive.search} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isTransfeeraTransfersChipsActive: boolean = useMemo(() => {
        return transfeeraTransfersFilterChips.some(it => !!it.isActive);
    }, [transfeeraTransfersFilterChips]);

    return {
        transfeeraTransfersFilterChips,
        isTransfeeraTransfersChipsActive,
    };
};

export default useTransfeeraTransfersFilterChips;
