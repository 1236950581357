import { AxiosResponse } from 'axios';
import { ScdOrganization, ScdOrganizationChangeTokenResponse, ScdOrganizationList, ScdOrganizationSimple } from 'model/organization';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { SearchRequest } from 'model/reducers';
import { Pageable } from 'services/pageable';

const organization = () => {
    const listOrganizations = (request: Pageable): Promise<AxiosResponse<Page<ScdOrganizationList>>> => {
        return api.get<Page<ScdOrganizationList>>(`${BASE_URL_ADMIN}/organizations`, { params: { ...request } });
    };

    const detailOrganization = (organizationId: number): Promise<AxiosResponse<ScdOrganization>> => {
        return api.get<ScdOrganization>(`${BASE_URL_ADMIN}/organizations/${organizationId}`);
    };

    const createOrganization = (request: ScdOrganization): Promise<AxiosResponse<ScdOrganizationSimple>> => {
        return api.post<ScdOrganizationSimple>(`${BASE_URL_ADMIN}/organizations`, request);
    };

    const updateOrganization = (request: ScdOrganization): Promise<AxiosResponse<ScdOrganizationSimple>> => {
        return api.patch<ScdOrganizationSimple>(`${BASE_URL_ADMIN}/organizations/${request.id}`, request);
    };

    const searchOrganizations = (request: SearchRequest): Promise<AxiosResponse<Page<ScdOrganization>>> => {
        return api.get<Page<ScdOrganization>>(`api/organizations/search?name=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    const changeOrganizationToken = (organizationId: number): Promise<AxiosResponse<ScdOrganizationChangeTokenResponse>> => {
        return api.patch<ScdOrganizationChangeTokenResponse>(`${BASE_URL_ADMIN}/organizations/${organizationId}/change-token/`);
    };

    return {
        listOrganizations,
        detailOrganization,
        searchOrganizations,
        createOrganization,
        updateOrganization,
        changeOrganizationToken
    };
};

export default organization();
