import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroup } from 'model/company-group';

export type ApiResponse = Record<string, any>;

export enum DetailCompanyGroupTypes {
    DETAIL_COMPANY_GROUP_REQUEST = '@@company-group-detail/DETAIL_COMPANY_GROUP_REQUEST',
    DETAIL_COMPANY_GROUP_SUCCESS = '@@company-group-detail/DETAIL_COMPANY_GROUP_SUCCESS',
    DETAIL_COMPANY_GROUP_ERROR = '@@company-group-detail/DETAIL_COMPANY_GROUP_ERROR',
    DETAIL_COMPANY_GROUP_RESET = '@@company-group-detail/DETAIL_COMPANY_GROUP_RESET',
}

export interface DetailCompanyGroupState extends BaseStoreState {
    readonly companyGroup?: ScdCompanyGroup;
}
