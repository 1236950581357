import { AxiosResponse } from 'axios';
import { BatchDetailWithProgram } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { detailBatchWithProgramError, detailBatchWithProgramRequest, detailBatchWithProgramSuccess } from 'reducer/batch/batch-details-with-propgram/actions';
import { DetailBatchWithProgramTypes } from 'reducer/batch/batch-details-with-propgram/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailBatchWithProgram(action: ReturnType<typeof detailBatchWithProgramRequest>) {
    try {
        const result: AxiosResponse<BatchDetailWithProgram> = yield call(batchAPI.detailBatchWithProgram, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailBatchWithProgramError(mapped));
            return;
        }
        yield put(detailBatchWithProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailBatchWithProgramError(mapped));
    }
}

function* watchDetailBatchWithProgram() {
    yield takeEvery(DetailBatchWithProgramTypes.DETAIL_BATCH_WITH_PROGRAM_REQUEST, handleDetailBatchWithProgram);
}

function* detailBatchWithProgramSaga() {
    yield all([fork(watchDetailBatchWithProgram)]);
}

export default detailBatchWithProgramSaga;
