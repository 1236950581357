import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export type RedirectItemType = {
    link: string;
    titleKey: string;
    show?: boolean;
};

const RedirectMenuItem = (props: RedirectItemType) => {
    const { link, titleKey, show } = props;

    const { t } = useTranslation();

    if (show === false) {
        return <></>;
    }
   
    return (
        <Link to={link}>
            <MenuItem>{t(titleKey)}</MenuItem>
        </Link>
    );
};

export default RedirectMenuItem;
