import { ScdCompanyItem } from 'model/company-item';
import { GiroWebappError } from 'model/error';
import { DetailCompanyItemTypes } from 'reducer/company-item/detail-company-item/types';
import { action } from 'typesafe-actions';

export const detailCompanyItemRequest = (companyItemId: string) =>
    action(DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_REQUEST, companyItemId);
export const detailCompanyItemSuccess = (companyItem: ScdCompanyItem) =>
    action(DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_SUCCESS, companyItem);
export const detailCompanyItemError = (error: GiroWebappError) => action(DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_ERROR, error);
export const detailCompanyItemResetState = () => action(DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_RESET);
