import WarningOutlinedButton from 'components/buttons/warning-outlined-button/warning-outlined-button';
import { Modal } from 'reactstrap';

import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import './modal-message.scss';
export interface ModalMessageProps {
    title?: string;
    children?: React.ReactNode;
    message?: React.ReactNode;
    onClose: () => void;
    onAction?: () => void;
    onCancel?: () => void;
    outlinedButtonLabel?: string;
    standardButtonLabel?: string;
    disabled?: boolean;
    isWarningModal?: boolean;
    icon?: React.ReactNode;
}

export const ModalMessage = (props: ModalMessageProps) => {
    const { title, onClose, onAction, onCancel, outlinedButtonLabel, standardButtonLabel, children, disabled, isWarningModal } = props;

    const onCancelClick = () => onCancel?.();
    const onActionClick = () => onAction?.();

    const renderDefaultButtons = (disabled?: boolean) => (
        <>
            <OutlinedButton label={outlinedButtonLabel ?? 'entity.action.back'} onClick={onCancelClick} />
            <StandardButton label={standardButtonLabel ?? 'entity.action.confirm'} onClick={onActionClick} disabled={disabled} />
        </>
    );

    const renderWarningButtons = (disabled?: boolean) => (
        <>
            <WarningOutlinedButton label={outlinedButtonLabel ?? 'entity.action.confirm'} onClick={onActionClick} disabled={disabled} />
            <StandardButton label={standardButtonLabel ?? 'entity.action.back'} onClick={onCancelClick} />
        </>
    );

    return (
        <Modal isOpen toggle={onClose}>
            <div className="modal-message__body">
                <header className="modal-message__body__header">
                    <h2 className="modal-message__body__header--title">{title}</h2>
                    <button className="modal-message__body__header--close-modal" onClick={onClose} />
                </header>
                <main className="modal-message--message">{children}</main>
                <footer className="modal-message__body__footer">
                    <div className="modal-message__body__footer--border" />
                    {isWarningModal ? renderWarningButtons(disabled) : renderDefaultButtons(disabled)}
                </footer>
            </div>
        </Modal>
    );
};

export default ModalMessage;
