import { PaymentInstitutionAccountSimpleToUpdate } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ConvertPaymentInstitutionAccountTypes {
    CONVERT_PAYMENT_INSTITUTION_ACCOUNT_REQUEST = '@@payment-institution-account-update/CONVERT_PAYMENT_INSTITUTION_ACCOUNT_REQUEST',
    CONVERT_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS = '@@payment-institution-account-update/CONVERT_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS',
    CONVERT_PAYMENT_INSTITUTION_ACCOUNT_ERROR = '@@payment-institution-account-update/CONVERT_PAYMENT_INSTITUTION_ACCOUNT_ERROR',
    CONVERT_PAYMENT_INSTITUTION_ACCOUNT_RESET = '@@payment-institution-account-update/CONVERT_PAYMENT_INSTITUTION_ACCOUNT_RESET',
}

export interface ConvertPaymentInstitutionAccountState extends BaseStoreState {
    readonly paymentInstitutionAccount?: PaymentInstitutionAccountSimpleToUpdate
}
