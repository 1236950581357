import { Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyCodeUtils } from 'shared/util/keypress-utils';
import './header-operation-info-card.scss';

interface HeaderOperationInfoCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.headerInfo';

const HeaderOperationInfoCard = (props: HeaderOperationInfoCardProps) => {
    const {
        operation: { acceptedDate, releaseDate, expirations, receivedDate },
    } = props;

    const [openInstallmentCard, setOpenInstallmentCard] = useState<boolean>(false);
    const [activeExpirationIndex, setActiveExpirationIndex] = useState<number>(0);
    const [installmenNumberValue, setInstallmenNumberValue] = useState<number | undefined>(undefined);
    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useTranslation();
    const installmentsQuantity = expirations?.length;

    const handleChangeInstallmentNumber = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (!installmentsQuantity) return;

        const _value = event?.target?.value;

        if (Number(_value) <= installmentsQuantity) {
            setInstallmenNumberValue(Number(_value));
        }
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (KeyCodeUtils.isEnterKey(event)) {
            if (!installmenNumberValue) {
                return setInstallmenNumberValue(activeExpirationIndex + 1);
            }
            event.preventDefault();
            setActiveExpirationIndex(installmenNumberValue - 1);

            inputRef?.current?.blur();
        }
    };

    const handleOnBlur = () => {
        if (!installmenNumberValue) {
            return setInstallmenNumberValue(activeExpirationIndex + 1);
        }

        setActiveExpirationIndex(installmenNumberValue - 1);
    };

    useEffect(() => {
        if (installmenNumberValue === 0) {
            setInstallmenNumberValue(undefined);
        }
    }, [installmenNumberValue]);

    useEffect(() => {
        setInstallmenNumberValue(activeExpirationIndex + 1);
    }, [activeExpirationIndex]);

    return (
        <Grid container>
            <Grid item xs={9}>
                <div className="scd-header-operation-info-card">
                    <div className="scd-header-operation-info-card--item">
                        <span className="scd-header-operation-info-card--item__label"> {t(`${I18N_PREFIX}.fields.acceptedDate`)} </span>
                        <span className="scd-header-operation-info-card--item__value">
                            <DateFormatter date={acceptedDate} />
                        </span>
                    </div>
                    <div className="scd-header-operation-info-card--item">
                        <span className="scd-header-operation-info-card--item__label"> {t(`${I18N_PREFIX}.fields.releaseDate`)} </span>
                        <span className="scd-header-operation-info-card--item__value">
                            <DateFormatter date={releaseDate} />
                        </span>
                    </div>
                    <div className="scd-header-operation-info-card--item">
                        <span className="scd-header-operation-info-card--item__label"> {t(`${I18N_PREFIX}.fields.receivedDate`)} </span>
                        <span className="scd-header-operation-info-card--item__value">
                            {receivedDate ? <DateFormatter date={receivedDate} format={DateFormat.DATETIME_UTC_COMPLETE} /> : '-'}
                        </span>
                    </div>
                </div>
            </Grid>
            <Grid item xs={3}>
                <div
                    className={clsx('scd-header-operation-installment-card', { active: openInstallmentCard })}
                    onClick={() => setOpenInstallmentCard(!openInstallmentCard)}
                >
                    <div className="scd-header-operation-installment-card--item">
                        <span className={clsx('scd-header-operation-installment-card--item__label', { active: openInstallmentCard })}>
                            {t(`${I18N_PREFIX}.fields.installmentNumber`)}
                        </span>
                        <span className={clsx('scd-header-operation-installment-card--item__value', { active: openInstallmentCard })}>
                            {installmentsQuantity}
                        </span>
                    </div>
                    {openInstallmentCard ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </div>
                <div className="scd-header-operation-installment-card-ref">
                    {openInstallmentCard && (
                        <div className="scd-header-operation-expiration-card">
                            <div className="scd-header-operation-expiration-card--expiration-number">
                                <span className="scd-header-operation-expiration-card--expiration-number__icon">
                                    {activeExpirationIndex + 1 > 1 && (
                                        <ArrowBackIosIcon onClick={() => setActiveExpirationIndex(activeExpirationIndex - 1)} />
                                    )}
                                </span>
                                <span className="scd-header-operation-expiration-card--expiration-number__value">
                                    <input
                                        ref={inputRef}
                                        value={installmenNumberValue ?? ''}
                                        onChange={handleChangeInstallmentNumber}
                                        onKeyPress={handleKeyPress}
                                        onBlur={handleOnBlur}
                                        type="number"
                                    />
                                </span>
                                <span className="scd-header-operation-expiration-card--expiration-number__icon">
                                    {installmentsQuantity && activeExpirationIndex + 1 < installmentsQuantity && (
                                        <ArrowForwardIosIcon onClick={() => setActiveExpirationIndex(activeExpirationIndex + 1)} />
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.dueDate`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.dueDate ? (
                                        <DateFormatter date={expirations?.[activeExpirationIndex].dueDate} />
                                    ) : (
                                        '00/00/00'
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.outstandingBalance`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.outstandingBalance ? (
                                        <CurrencyFormatter value={expirations?.[activeExpirationIndex].outstandingBalance} prefix />
                                    ) : (
                                        <CurrencyFormatter value={0} prefix />
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.amortizedCapital`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.amortizedCapital ? (
                                        <CurrencyFormatter value={expirations?.[activeExpirationIndex].amortizedCapital} prefix />
                                    ) : (
                                        <CurrencyFormatter value={0} prefix />
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.feeValue`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.feeValue ? (
                                        <CurrencyFormatter value={expirations?.[activeExpirationIndex].feeValue} prefix />
                                    ) : (
                                        <CurrencyFormatter value={0} prefix />
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.fareInstallmentValue`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.fareInstallmentValue ? (
                                        <CurrencyFormatter value={expirations?.[activeExpirationIndex].fareInstallmentValue} prefix />
                                    ) : (
                                        <CurrencyFormatter value={0} prefix />
                                    )}
                                </span>
                            </div>
                            <div className="scd-header-operation-expiration-card--item">
                                <span className={'scd-header-operation-expiration-card--item__label'}>
                                    {t(`${I18N_PREFIX}.fields.iofInstallmentValue`)}
                                </span>
                                <span className={'scd-header-operation-expiration-card--item__value'}>
                                    {expirations?.[activeExpirationIndex]?.iofInstallmentValue ? (
                                        <CurrencyFormatter value={expirations?.[activeExpirationIndex].iofInstallmentValue} prefix />
                                    ) : (
                                        <CurrencyFormatter value={0} prefix />
                                    )}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default HeaderOperationInfoCard;
