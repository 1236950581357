import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ReleaseMonthlyCommissionTypes, ReleaseMonthlyCommissionState } from 'reducer/monthly-commission/release-monthly-commission/types';

const initialState: ReleaseMonthlyCommissionState = {
    status: HttpRequestStatus.NOOP,
    monthlyCommission: undefined,
    error: undefined,
};

const reducer: Reducer<ReleaseMonthlyCommissionState> = (state = initialState, action): ReleaseMonthlyCommissionState => {
    switch (action.type) {
        case ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, monthlyCommission: action.payload, error: undefined };
        }
        case ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as releaseMonthlyCommissionReducer };
