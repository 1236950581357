import { SessionInterestRateResponse } from 'model/session-interest-rates';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum DetailSessionInterestRateTypes {
    DETAIL_SESSION_INTEREST_RATE_REQUEST = '@@session-interest-rate-detail/DETAIL_SESSION_INTEREST_RATE_REQUEST',
    DETAIL_SESSION_INTEREST_RATE_SUCCESS = '@@session-interest-rate-detail/DETAIL_SESSION_INTEREST_RATE_SUCCESS',
    DETAIL_SESSION_INTEREST_RATE_ERROR = '@@session-interest-rate-detail/DETAIL_SESSION_INTEREST_RATE_ERROR',
    DETAIL_SESSION_INTEREST_RATE_RESET_STATE = '@@session-interest-rate-detail/DETAIL_SESSION_INTEREST_RATE_RESET_STATE',
}

export interface DetailSessionInterestRateState extends BaseStoreState {
    readonly detailSessionInterestRate?: SessionInterestRateResponse;
}
