import { ScdIssuerSimple } from 'model/issuer';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CreateIssuerTypes {
    CREATE_ISSUER_REQUEST = '@@issuer-create/CREATE_ISSUER_REQUEST',
    CREATE_ISSUER_SUCCESS = '@@issuer-create/CREATE_ISSUER_SUCCESS',
    CREATE_ISSUER_ERROR = '@@issuer-create/CREATE_ISSUER_ERROR',
    CREATE_ISSUER_RESET_STATE = '@@issuer-create/CREATE_ISSUER_RESET_STATE',
}

export interface CreateIssuerState extends BaseStoreState {
    readonly issuer?: ScdIssuerSimple;
}
