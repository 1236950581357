import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationDocumentTypes {
    IMPORTED_OPERATION_DOCUMENT_REQUEST = '@@imported-operation-document/IMPORTED_OPERATION_DOCUMENT_REQUEST',
    IMPORTED_OPERATION_DOCUMENT_SUCCESS = '@@imported-operation-document/IMPORTED_OPERATION_DOCUMENT_SUCCESS',
    IMPORTED_OPERATION_DOCUMENT_ERROR = '@@imported-operation-document/IMPORTED_OPERATION_DOCUMENT_ERROR',
    IMPORTED_OPERATION_DOCUMENT_RESET_STATE = '@@imported-operation-document/IMPORTED_OPERATION_DOCUMENT_RESET_STATE',
}

export interface ImportedOperationDocumentState extends BaseStoreState {
    readonly document?: Uint8Array;
}
