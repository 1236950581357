import { BaseStoreState } from 'reducer/base-store-state';
import { ScdTechnicalSupplier } from 'model/technical-supplier';

export type ApiResponse = Record<string, string>;

export enum DetailTechnicalSupplierTypes {
    DETAIL_TECHNICAL_SUPPLIER_REQUEST = '@@technical-supplier-detail/DETAIL_TECHNICAL_SUPPLIER_REQUEST',
    DETAIL_TECHNICAL_SUPPLIER_SUCCESS = '@@technical-supplier-detail/DETAIL_TECHNICAL_SUPPLIER_SUCCESS',
    DETAIL_TECHNICAL_SUPPLIER_ERROR = '@@technical-supplier-detail/DETAIL_TECHNICAL_SUPPLIER_ERROR',
    DETAIL_TECHNICAL_SUPPLIER_RESET_STATE = '@@technical-supplier-detail/DETAIL_TECHNICAL_SUPPLIER_RESET_STATE',
}

export interface DetailTechnicalSupplierState extends BaseStoreState {
    readonly technicalSupplier?: ScdTechnicalSupplier;
}
