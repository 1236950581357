import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface LimeligthListOperationsHeaderProps {
    size: boolean;
}

const I18N_PREFIX = 'pages.scd.provisional-limelight';

const LimeligthListOperationsHeader = (props: LimeligthListOperationsHeaderProps) => {
    const { size } = props;
    const { t } = useTranslation();

    return (
        <tr
            className={clsx(`page-container--table-cell`, {
                size: size,
            })}
        >
            <th className="page-container--table-cell-title">{t(`${I18N_PREFIX}.table.options.originator`)}</th>
            <th className="page-container--table-cell-title">{t(`${I18N_PREFIX}.table.options.debtor-identification`)}</th>
            <th className="page-container--table-cell-title">{t(`${I18N_PREFIX}.table.options.ccb-number`)}</th>
            <th className="page-container--table-cell-title">{t(`${I18N_PREFIX}.table.options.file-name`)}</th>
        </tr>
    );
};
export default LimeligthListOperationsHeader;
