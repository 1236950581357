import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import './policy-icon-button.scss';

interface PolicyIconButtomProps {
    onClick?: () => void;
    disabled?: boolean;
    title?: string;
    isActive?: boolean;
}

const PolicyIconButtom = (props: PolicyIconButtomProps) => {
    const { disabled, onClick, title, isActive } = props;

    const { t } = useTranslation();

    const buttonContent = (
        <Button variant="contained" onClick={onClick} disabled={disabled}>
            <Icon>
                <div
                    className={clsx('button__style--policy-icon--icon', {
                        ACTIVE: isActive,
                    })}
                />
            </Icon>
        </Button>
    );

    return (
        <div className="button__style--policy-icon">
            {disabled ? (
                <Tooltip title={t(title ?? 'tooltips.policy').toString()} aria-label="visualize">
                    <span>{buttonContent}</span>
                </Tooltip>
            ) : (
                <Tooltip title={t(title ?? 'tooltips.policy').toString()} aria-label="visualize">
                    {buttonContent}
                </Tooltip>
            )}
        </div>
    );
};

export default PolicyIconButtom;
