import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useBatchDocuemtnExportState, useBatchDocumentProcessState, useBatchOutputHemeraState, useRootDispatch } from 'reducer/hooks';

import { BatchDocumentExportRequest, BatchDocumentProcessRequest } from 'model/batch-output-hemera';
import { batchDocumentExportRequest, batchDocumentExportResetState } from 'reducer/batch/batch-document-export/action';
import { batchDocumentProcessRequest, batchDocumentProcessResetState } from 'reducer/batch/batch-document-process/action';
import { batchOutputHemeraRequest, batchOutputHemeraResetState } from 'reducer/batch/batch-output-hemera/action';
import './batch-output-hemera.scss';

import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import DateFormatter from 'components/formatter/date/date-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { BatchDocumentDownloadRequest } from 'model/batch-document';
import { BatchStatus } from 'model/enums/batch-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { batchDocumentDownloadRequest, batchDocumentDownloadResetState } from 'reducer/batch/batch-document-download/action';
import { useToast } from 'shared/hooks/use-toast';

type BatchOutputProps = RouteComponentProps<{ batchId: string }>;

type ModalDocumentType = 'generated' | 'export';

const I18N_PREFIX = 'pages.scd.batch.batch-output-hemera';

const ScdBatchOutputHemera = (props: BatchOutputProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { goBack } = useHistory();

    const { batchOutput, status } = useBatchOutputHemeraState();
    const { status: statusProcessDocument } = useBatchDocumentProcessState();
    const { status: statusExportedDocument } = useBatchDocuemtnExportState();

    const { toastSuccess, closeToast, toastLoading } = useToast();

    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [modalDocument, setModalDocument] = useState<ModalDocumentType | undefined>(undefined);

    const handleBatchOutputRequest = useCallback(() => {
        if (!batchId) return;

        dispatch(batchOutputHemeraRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        handleBatchOutputRequest();
    }, [handleBatchOutputRequest]);

    const loading = status !== HttpRequestStatus.SUCCESS;

    const outputId = batchOutput?.batchOutput?.id;
    const handleDocumentProcess = useCallback(() => {
        if (!outputId) return;

        const generatedDocuemnt: BatchDocumentProcessRequest = {
            batchId,
            outputId,
        };
        dispatch(batchDocumentProcessRequest(generatedDocuemnt));
        setModalDocument(undefined);
    }, [dispatch, outputId, batchId]);

    const documentId = batchOutput?.batchOutput?.batchDocument?.id;
    const handleDocumentExport = useCallback(() => {
        if (!documentId) return;

        const documentExport: BatchDocumentExportRequest = {
            batchId,
            documentId,
        };
        dispatch(batchDocumentExportRequest(documentExport));
        setModalDocument(undefined);
    }, [dispatch, documentId, batchId]);

    const handleDownload = useCallback(() => {
        const document = batchOutput?.batchOutput?.batchDocument;
        if (!batchId || !document?.id) return;
        const request: BatchDocumentDownloadRequest = {
            batchId,
            documentId: document.id,
        };
        dispatch(batchDocumentDownloadRequest(request));
    }, [dispatch, batchId, batchOutput?.batchOutput?.batchDocument]);

    useEffect(() => {
        if (statusProcessDocument !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [statusProcessDocument, toastLoading]);

    useEffect(() => {
        if (statusProcessDocument !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.generate.success`));
        handleBatchOutputRequest();
    }, [statusProcessDocument, toastSuccess, t, closeToast, handleBatchOutputRequest]);

    useEffect(() => {
        if (statusProcessDocument !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [statusProcessDocument, toastSuccess, t, closeToast]);

    useEffect(() => {
        if (statusExportedDocument !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [statusExportedDocument, toastLoading]);

    useEffect(() => {
        if (statusExportedDocument !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.export.success`));
        handleBatchOutputRequest();
    }, [statusExportedDocument, toastSuccess, t, closeToast, handleBatchOutputRequest]);

    useEffect(() => {
        if (statusExportedDocument !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [statusExportedDocument, toastSuccess, t, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(batchOutputHemeraResetState());
            dispatch(batchDocumentExportResetState());
            dispatch(batchDocumentProcessResetState());
            dispatch(batchDocumentDownloadResetState());
        };
    }, [dispatch]);

    return (
        <main className="scd-output-hemera">
            <ContextRibbon />
            {loading ? (
                <Loading />
            ) : (
                <div className="scd-output-hemera--container">
                    <header className="scd-output-hemera--header">
                        <h2 className="scd-output-hemera--header-title">
                            {batchOutput?.batchOutput?.policyName ? batchOutput?.batchOutput.policyName : t(`${I18N_PREFIX}.title`)}
                        </h2>
                    </header>
                    <div className="scd-output-hemera-table">
                        {batchOutput?.batch.status === BatchStatus.CLOSED || batchOutput?.batch.status === BatchStatus.CONCLUDED ? (
                            <>
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={batchOutput?.batchOutput.policyName} />
                                <ReadOnlyTextField label={t(`${I18N_PREFIX}.batch.code.label`)} value={batchOutput?.batch.code} />
                                <ReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.batch.status.label`)}
                                    value={t(`${I18N_PREFIX}.batch.status.options.${batchOutput?.batch.status}`)}
                                />
                                <ReadOnlyTextField
                                    label={t(`${I18N_PREFIX}.cession.cessionDate`)}
                                    value={<DateFormatter date={batchOutput?.cession?.cessionDate} />}
                                />
                                <div className="scd-output-hemera-container-icon">
                                    <ReadOnlyTextField
                                        label={t(`${I18N_PREFIX}.file.label`)}
                                        value={
                                            batchOutput?.batchOutput?.batchDocument
                                                ? t(`${I18N_PREFIX}.file.document.is-present`)
                                                : t(`${I18N_PREFIX}.file.document.not-present`)
                                        }
                                    />
                                    {batchOutput?.batchOutput?.batchDocument && (
                                        <span className="scd-output-hemera__icon" onClick={handleDownload} />
                                    )}
                                </div>

                                {batchOutput?.batchOutput?.id && batchOutput?.cession?.id ? (
                                    <div className="scd-output-hemera--form-buttons">
                                        <OutlinedButtonLegacy
                                            label={t(`${I18N_PREFIX}.buttom.label.process`)}
                                            onClick={() => setModalDocument('generated')}
                                        />
                                        <StandardButtonLegacy
                                            label={t(`${I18N_PREFIX}.buttom.label.export`)}
                                            onClick={() => setModalDocument('export')}
                                            disabled={!batchOutput?.batchOutput.batchDocument}
                                        />
                                    </div>
                                ) : (
                                    <ModalMessageLegacy
                                        title={
                                            batchOutput?.cession
                                                ? t(`${I18N_PREFIX}.output.not-found.title`)
                                                : t(`${I18N_PREFIX}.cession.not-found.title`)
                                        }
                                        message={
                                            batchOutput?.cession
                                                ? t(`${I18N_PREFIX}.output.not-found.sub-title`)
                                                : t(`${I18N_PREFIX}.cession.not-found.sub-title`)
                                        }
                                        onClose={goBack}
                                        onAction={goBack}
                                        standardButtonLabel={t(`${I18N_PREFIX}.buttom.label.back`)}
                                    />
                                )}
                            </>
                        ) : (
                            <div className="scd-output-hemera--empty">
                                <span className="scd-output-hemera--empty-image" />
                                <span className="scd-output-hemera--empty-title">{t(`${I18N_PREFIX}.not-found.title`)}</span>
                                <span className="scd-batch__table-empty-filter-subtitle">{t(`${I18N_PREFIX}.not-found.sub-title`)}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {modalDocument && (
                <ModalMessageLegacy
                    title={modalDocument === 'generated' ? t(`${I18N_PREFIX}.file.title.generate`) : t(`${I18N_PREFIX}.file.title.export`)}
                    message={
                        modalDocument === 'generated' ? t(`${I18N_PREFIX}.file.message.generate`) : t(`${I18N_PREFIX}.file.message.export`)
                    }
                    onCancel={() => setModalDocument(undefined)}
                    onAction={modalDocument === 'generated' ? handleDocumentProcess : handleDocumentExport}
                    onClose={() => setModalDocument(undefined)}
                />
            )}
        </main>
    );
};

export default ScdBatchOutputHemera;
