import { BaseStoreState } from 'reducer/base-store-state';
import { CessionToSimpleDTO } from 'model/bco/batch-output-cession-integration-webcred';

export enum ConcludeCessionBcoTypes {
    CONCLUDE_CESSION_BCO_REQUEST = '@@conclude-cession-bco/CONCLUDE_CESSION_BCO_REQUEST',
    CONCLUDE_CESSION_BCO_SUCCESS = '@@conclude-cession-bco/CONCLUDE_CESSION_BCO_SUCCESS',
    CONCLUDE_CESSION_BCO_ERROR = '@@conclude-cession-bco/CONCLUDE_CESSION_BCO_ERROR',
    CONCLUDE_CESSION_BCO_RESET = '@@conclude-cession-bco/CONCLUDE_CESSION_BCO_RESET',
}

export interface ConcludeCessionBcoState extends BaseStoreState {
    readonly concludeCession?: CessionToSimpleDTO;
}
