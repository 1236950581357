import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create payment transactions in series
import { createPaymentTransactionsInSeriesReducer } from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/reducer';
import createPaymentTransactionsInSerieSaga from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/sagas';
import { CreatePaymentTransactionsInSeriesState } from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/types';

export interface PaymentTransactionInSeriesState {
    readonly createPaymentTransactionsInSeries: CreatePaymentTransactionsInSeriesState;
}

export const PaymentTransactionInSeriesReducer: ReducersMapObject<PaymentTransactionInSeriesState, AnyAction> = {
    createPaymentTransactionsInSeries: createPaymentTransactionsInSeriesReducer,
};

export function* paymentTransactionInSeriesSagas() {
    yield all([fork(createPaymentTransactionsInSerieSaga)]);
}
