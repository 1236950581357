import { GiroWebappError } from 'model/error';
import { ScdProgramSimple } from 'model/program';
import { SearchRequest } from 'model/reducers';
import { SearchProgramsTypes } from 'reducer/program/search-programs/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchProgramsRequest = (request: SearchRequest) => action(SearchProgramsTypes.SEARCH_PROGRAMS_REQUEST, request);
export const searchProgramsSuccess = (programs: Page<ScdProgramSimple>) => action(SearchProgramsTypes.SEARCH_PROGRAMS_SUCCESS, programs);
export const searchProgramsError = (error: GiroWebappError) => action(SearchProgramsTypes.SEARCH_PROGRAMS_ERROR, error);
export const searchProgramsResetState = () => action(SearchProgramsTypes.SEARCH_PROGRAMS_RESET_STATE);
