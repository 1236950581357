import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramSimple } from 'model/program';
import { legacyCreateProgramRequest, legacyCreateProgramError, legacyCreateProgramSuccess } from 'reducer/program/legacy-create-program/actions';
import { LegacyCreateProgramTypes } from 'reducer/program/legacy-create-program/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleLegacyCreateProgram(action: ReturnType<typeof legacyCreateProgramRequest>) {
    try {
        const result: AxiosResponse<ScdProgramSimple> = yield call(programApi.legacyCreateProgram, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(legacyCreateProgramError(mapped));
            return;
        }
        yield put(legacyCreateProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(legacyCreateProgramError(mapped));
    }
}

function* watchLegacyCreateProgram() {
    yield takeEvery(LegacyCreateProgramTypes.LEGACY_CREATE_PROGRAM_REQUEST, handleLegacyCreateProgram);
}

function* legacyCreateProgramSaga() {
    yield all([fork(watchLegacyCreateProgram)]);
}

export default legacyCreateProgramSaga;
