import { ScdTechnicalSupplier } from './../../../model/technical-supplier';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchTechnicalSuppliersTypes {
    SEARCH_TECHNICAL_SUPPLIERS_REQUEST = '@@technical-suppliers-list/SEARCH_TECHNICAL_SUPPLIERS_REQUEST',
    SEARCH_TECHNICAL_SUPPLIERS_SUCCESS = '@@technical-suppliers-list/SEARCH_TECHNICAL_SUPPLIERS_SUCCESS',
    SEARCH_TECHNICAL_SUPPLIERS_ERROR = '@@technical-suppliers-list/SEARCH_TECHNICAL_SUPPLIERS_ERROR',
    SEARCH_TECHNICAL_SUPPLIERS_RESET_STATE = '@@technical-suppliers-list/SEARCH_TECHNICAL_SUPPLIERS_RESET_STATE',
}

export interface SearchTechnicalSuppliersState extends BaseStoreState {
    readonly technicalSuppliers?: Page<ScdTechnicalSupplier>;
}
