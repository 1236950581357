import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ScdCompanyGroupPolicyHeader from 'entities/company-group-policy/components/company-group-policy-header/company-group-policy-header';
import CompanyGroupPolicyList from 'entities/company-group-policy/components/company-group-policy-list/company-group-policy-list';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listCompanyGroupPoliciesRequest } from 'reducer/company-group-policy/list-company-group-policies/actions';
import { useRootDispatch } from 'reducer/hooks';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './company-group-policy.scss';

const I18N_PREFIX = 'pages.scd.company-group-policy';

const ScdCompanyGroupPolicyPage = () => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const sortPageable = useCallback((): Pageable => {
        return {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };
    }, [sortedProperty, sortOrder, page]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };
    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
    } = useInfiniteScrollLegacy<ScdCompanyGroupPolicy>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listCompanyGroupPolicies.companyGroupPolicies,
        statusSelector: (state: IRootState) => state.listCompanyGroupPolicies.status,
        action: listCompanyGroupPoliciesRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleRefresh = () => {
        dispatch(listCompanyGroupPoliciesRequest(sortPageable()));
    };

    const handleSort = (property: keyof ScdCompanyGroupPolicy, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.company-group-policy.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="scd-company-group-policy">
            <section className="scd-company-group-policy__container">
                <header className="scd-company-group-policy__header">
                    <h2 className="scd-company-group-policy__header--title">
                        {t(`${I18N_PREFIX}.title`)}
                        <RefreshButton onClick={handleRefresh} />
                    </h2>
                    <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                </header>
                <div className="scd-company-group-policy__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdCompanyGroupPolicyHeader
                                companyGroupPolicies={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <CompanyGroupPolicyList companyGroupPolicy={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
        </main>
    );
};

export default ScdCompanyGroupPolicyPage;
