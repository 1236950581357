import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import './upload-file.scss';

const fileTypes = ['PDF'];
const I18N_PREFIX = 'pages.scd.provisional-limelight';

interface SelectMultipleFilesProps {
    handleChange: (file: FileList) => void;
}

const SelectMultipleFiles = (props: SelectMultipleFilesProps) => {
    const { handleChange } = props;
    const { t } = useTranslation();

    return (
        <div className="button__style--action">
            <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                multiple={true}
                maxSize={300}
                children={t(`${I18N_PREFIX}.buttons.imported`).toLocaleUpperCase()}
            />
        </div>
    );
};

export default SelectMultipleFiles;
