import { ScdBatchToRelocateResponse, ScdBatchToRelocateToExistingRequest } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { RelocateToExistingBatchTypes } from 'reducer/batch/relocate-operation-to-existing-batch/types';
import { action } from 'typesafe-actions';

export const relocateToExistingBatchRequest = (request: ScdBatchToRelocateToExistingRequest) =>
    action(RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_REQUEST, request);
export const relocateToExistingBatchSuccess = (batchInfo: ScdBatchToRelocateResponse) =>
    action(RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_SUCCESS, batchInfo);
export const relocateToExistingBatchError = (error: GiroWebappError) =>
    action(RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_ERROR, error);
export const relocateToExistingBatchResetState = () => action(RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_RESET);
