import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DetailBatchTypes, DetailBatchState } from 'reducer/batch/detail-batch/types';

const initialState: DetailBatchState = {
    status: HttpRequestStatus.NOOP,
    batch: undefined,
    error: undefined,
};

const reducer: Reducer<DetailBatchState> = (state = initialState, action): DetailBatchState => {
    switch (action.type) {
        case DetailBatchTypes.DETAIL_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DetailBatchTypes.DETAIL_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batch: action.payload, error: undefined };
        }
        case DetailBatchTypes.DETAIL_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailBatchTypes.DETAIL_BATCH_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailBatchReducer };
