import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdFavoredBankAccount } from 'model/favored-bank-account';
import { markError } from 'reducer/application/error/actions';
import {
    searchFavoredBankAccountsError,
    searchFavoredBankAccountsRequest,
    searchFavoredBankAccountsSuccess,
} from 'reducer/favored-bank-account/search-favored-bank-accounts/actions';
import { SearchFavoredBankAccountsTypes } from 'reducer/favored-bank-account/search-favored-bank-accounts/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import favoredBankAccountAPI from 'services/api/favored-bank-account-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSearchFavoredBankAccounts(action: ReturnType<typeof searchFavoredBankAccountsRequest>) {
    try {
        const result: AxiosResponse<ScdFavoredBankAccount[]> = yield call(favoredBankAccountAPI.searchFavoredBankAccounts, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchFavoredBankAccountsError(mapped));
            return;
        }
        yield put(searchFavoredBankAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchFavoredBankAccountsError(mapped));
    }
}

function* watchSearchFavoredBankAccounts() {
    yield takeEvery(SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_REQUEST, handleSearchFavoredBankAccounts);
}

function* searchFavoredBankAccountsSaga() {
    yield all([fork(watchSearchFavoredBankAccounts)]);
}

export default searchFavoredBankAccountsSaga;
