import Tooltip from '@material-ui/core/Tooltip';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from 'clsx';
import CloseButton from 'components/buttons/close-button/close-button';
import LinkButton from 'components/buttons/link-button/link-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import TaxValueInput from 'entities/monthly-commission/components/inputs/tax-value-input/tax-value-input';
import { MonthlyCommissionDetailModalOptions } from 'entities/monthly-commission/monthly-commission-detail/monthly-commission-detail';
import { ReactComponent as ErrorArrowSvg } from 'images/error-arrow.svg';
import { TaxValueType } from 'model/enums/tax-type';
import { defaultMonthlyCommissionTaxValue, ScdMonthlyCommissionTaxValue } from 'model/monthly-commission';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useRootDispatch } from 'reducer/hooks';
import { monthlyCommissionGenerateReportRequest } from 'reducer/monthly-commission/generate-report/actions';
import './monthly-commission-report-modal.scss';

export interface MonthlyCommissionReportModalProps {
    onClose: () => void;
    openModal: boolean;
    setOpenModal: (value: MonthlyCommissionDetailModalOptions | undefined) => void;
    monthlyCommissionId: number | undefined;
}

enum MonthlyCommissionReportModalActions {
    CONFIRM = 'CONFIRM',
    CANCEL = 'CANCEL',
    COMMENT = 'COMMENT',
    CLEAR_ALL = 'CLEAR_ALL',
}

const I18N_PREFIX = 'pages.scd.monthly-commission-detail.modal';

const defaultTaxOption = Object.values(TaxValueType);

export const MonthlyCommissionReportModal = (props: MonthlyCommissionReportModalProps) => {
    const { onClose, openModal, setOpenModal, monthlyCommissionId } = props;

    const [taxesList, setTaxesList] = useState<ScdMonthlyCommissionTaxValue[]>([defaultMonthlyCommissionTaxValue]);
    const [taxOptions, setTaxOptions] = useState<TaxValueType[]>(defaultTaxOption);

    const [modalAction, setModalAction] = useState<MonthlyCommissionReportModalActions | undefined>(undefined);
    const [comentary, setComentary] = useState<string | undefined>(undefined);

    const { t } = useTranslation();

    const dispatch = useRootDispatch();

    const enableAction = useMemo(() => {
        return !taxesList.some(tax => tax.value === undefined);
    }, [taxesList]);

    if (!openModal) {
        return <></>;
    }

    const handleSelect = (index: number) => (selectedTax: string) => {
        const newValues = [...taxesList];

        newValues[index] = {
            type: TaxValueType[selectedTax],
        };

        setTaxOptions(ps => ps.filter(tax => tax !== TaxValueType[selectedTax]));
        setTaxesList(newValues);
    };

    const handleChangeValue = (index: number) => (value: number | undefined) => {
        const newValues = [...taxesList];

        newValues[index] = {
            ...newValues[index],
            value,
        };

        setTaxesList(newValues);
    };

    const handleResetInputs = () => {
        setTaxesList([defaultMonthlyCommissionTaxValue]);
        setTaxOptions(defaultTaxOption);
        setComentary(undefined);
    };

    const handleAddNewTax = () => {
        setTaxesList(ps => [...ps, defaultMonthlyCommissionTaxValue]);
    };

    const handleRemoveTax = (selectedTax: string) => {
        const _filteredData = taxesList.filter(tax => tax.type !== selectedTax);

        setTaxesList(taxesList.length === 1 ? [defaultMonthlyCommissionTaxValue] : _filteredData);
        setTaxOptions(ps => [...ps, TaxValueType[selectedTax]]);
    };

    const modalTitle = {
        CLEAR_ALL: t(`${I18N_PREFIX}.CLEAR_ALL.title`),
        CANCEL: t(`${I18N_PREFIX}.CANCEL.title`),
        CONFIRM: t(`${I18N_PREFIX}.CONFIRM.title`),
    } as Record<MonthlyCommissionReportModalActions, string>;

    const modalMessage = {
        CONFIRM: t(`${I18N_PREFIX}.CONFIRM.message`),
    } as Record<MonthlyCommissionReportModalActions, string>;

    const modalOnAction = {
        CLEAR_ALL: () => {
            handleResetInputs();
            setModalAction(undefined);
        },
        CANCEL: () => {
            handleResetInputs();
            setModalAction(undefined);
            setOpenModal(undefined);
        },
        CONFIRM: () => {
            if (!monthlyCommissionId) return;
            dispatch(monthlyCommissionGenerateReportRequest({ id: monthlyCommissionId, taxValues: taxesList }));
            setModalAction(undefined);
        },
    } as Record<MonthlyCommissionReportModalActions, () => void>;

    const modalStandardButtonLabel = {
        CLEAR_ALL: t('entity.action.clean'),
    } as Record<MonthlyCommissionReportModalActions, string>;

    return (
        openModal && (
            <Modal isOpen>
                <div className="monthly-commission-report-modal--container">
                    <div className="monthly-commission-report-modal--header">
                        <label className="monthly-commission-report-modal--header-title">{t(`${I18N_PREFIX}.GENERATE_REPORT.title`)}</label>
                        <span className="monthly-commission-report-modal--header-subtitle">
                            {t(`${I18N_PREFIX}.GENERATE_REPORT.subtitle`)}
                        </span>
                        <div className="monthly-commission-report-modal--header-close-icon">
                            <CloseButton onClick={onClose} />
                        </div>
                    </div>
                    <div className="monthly-commission-report-modal--container__inputs">
                        {taxesList.map((value, index) => {
                            return (
                                <TaxValueInput
                                    key={index}
                                    onSelect={handleSelect(index)}
                                    taxOptions={taxOptions}
                                    onChange={handleChangeValue(index)}
                                    onRemove={handleRemoveTax}
                                    tax={value}
                                />
                            );
                        })}
                        {enableAction && taxesList.length < 3 && (
                            <div className="monthly-commission-report-modal--container__tax-add-new-input" onClick={handleAddNewTax}>
                                <AddCircleOutlineIcon />
                            </div>
                        )}
                        {taxesList.some(tax => !!tax.value) && (
                            <div
                                className="monthly-commission-report-modal--container__remove-arrow"
                                onClick={() => setModalAction(MonthlyCommissionReportModalActions.CLEAR_ALL)}
                            >
                                <Tooltip title={String(t('tooltips.cancel'))}>
                                    <ErrorArrowSvg />
                                </Tooltip>
                            </div>
                        )}
                        <Tooltip title={String(t('tooltips.comentary'))}>
                            <div
                                className={clsx('monthly-commission-report-modal--container__comentary', {
                                    active: !!comentary,
                                    disabled: !enableAction,
                                })}
                                onClick={() => enableAction && setModalAction(MonthlyCommissionReportModalActions.COMMENT)}
                            />
                        </Tooltip>
                    </div>
                    <div className="monthly-commission-report-modal--buttons-actions">
                        <LinkButton
                            onClick={() => setModalAction(MonthlyCommissionReportModalActions.CANCEL)}
                            label={t('entity.action.cancel')}
                            outlinedStyle
                        />
                        <StandardButtonLegacy
                            label="entity.action.generate"
                            onClick={() => setModalAction(MonthlyCommissionReportModalActions.CONFIRM)}
                            disabled={!enableAction}
                        />
                    </div>
                </div>
                {modalAction && modalAction !== MonthlyCommissionReportModalActions.COMMENT && (
                    <ModalMessageLegacy
                        onClose={() => setModalAction(undefined)}
                        onCancel={() => setModalAction(undefined)}
                        message={modalMessage[modalAction]}
                        standardButtonLabel={modalStandardButtonLabel[modalAction]}
                        title={modalTitle[modalAction]}
                        onAction={modalOnAction[modalAction]}
                    />
                )}
                {modalAction === MonthlyCommissionReportModalActions.COMMENT && (
                    <ModalTextArea
                        label={t(`${I18N_PREFIX}.COMMENT.label`)}
                        title={t(`${I18N_PREFIX}.COMMENT.title`)}
                        onChange={value => setComentary(value)}
                        defaultValue={comentary}
                        onClose={() => setModalAction(undefined)}
                        openModal
                    />
                )}
            </Modal>
        )
    );
};

export default MonthlyCommissionReportModal;
