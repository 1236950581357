import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountDetail } from 'model/payment-institution-account';
import { DetailPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/types';
import { action } from 'typesafe-actions';

export const detailPaymentInstitutionsAccountsRequest = (id: number) =>
    action(DetailPaymentInstitutionsAccountsTypes.DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, id);
export const detailPaymentInstitutionsAccountsSuccess = (paymentAccount: PaymentInstitutionAccountDetail) =>
    action(DetailPaymentInstitutionsAccountsTypes.DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, paymentAccount);
export const detailPaymentInstitutionsAccountsError = (error: GiroWebappError) =>
    action(DetailPaymentInstitutionsAccountsTypes.DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error);
export const detailPaymentInstitutionsAccountsResetState = () =>
    action(DetailPaymentInstitutionsAccountsTypes.DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
