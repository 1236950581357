
export type Environment = 'development' | 'staging' | 'production' | 'test';

export enum EnvironmentEnum {
    DEVELOPMENT = 'development',
    STAGING = 'staging',
    PRODUCTION = 'production',
    TEST = 'test'
}

const getEnvironment = (): Environment => {
    return process.env.REACT_APP_PROFILE as Environment;
}

const isDevelopmentEnvironment = (): boolean => {
    const current_environment = getEnvironment();
    return current_environment === EnvironmentEnum.DEVELOPMENT;
}

const isStagingEnvironment = (): boolean => {
    const current_environment = getEnvironment();
    return current_environment === EnvironmentEnum.STAGING;
}

const isProductionEnvironment = (): boolean => {
    const current_environment = getEnvironment();
    return current_environment === EnvironmentEnum.PRODUCTION;
}
const isTestEnvironment = (): boolean => {
    const current_environment = getEnvironment();
    return current_environment === EnvironmentEnum.TEST;
}


export const EnvironmentUtils = {
    getEnvironment,
    isDevelopmentEnvironment,
    isStagingEnvironment,
    isProductionEnvironment,
    isTestEnvironment
}

