import { BankAccountType } from 'model/enums/bank-account-type';
import { PaymentTransactionManualDisbursementAvailability } from 'model/enums/manual-disbursement-availability';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentTransactionFailureOrigin } from 'model/enums/payment-transaction-failure-origin';
import { PaymentTransactionFailureType } from 'model/enums/payment-transaction-failure-type';
import { PaymentTransactionMethod } from 'model/enums/payment-transaction-method';
import { PaymentTransactionMode } from 'model/enums/payment-transaction-mode';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import { PaymentTransactionDispatchMode } from 'model/enums/payment-transactions-dispatch-mode';
import { PixKeyType } from 'model/enums/pix-key-type';
import { TechnicalSupplierStatus } from 'model/enums/technical-supplier-status';
import { OriginatorToGetTransaction } from 'model/originator';
import { PaymentFavoredToGetTransaction, PaymentValueToGetTransaction } from 'model/payment-favored';
import { PaymentInstitutionAccountToGetTransaction, PaymentInstitutionToGetTransaction } from 'model/payment-institution-account';
import { PaymentTransactionFailureToGetFailureCategorization } from 'model/transaction-failure-categorization';

export interface ScdLegacyPaymentTransactionData {
    id: string;
    date: Date;
    amount: number;
    favoredName: string;
    favoredIdentification: string;
    companyId?: number;
    personId?: number;
    bankIdentification: string;
    bankName: string;
    agencyNumber: string;
    accountNumber: string;
    accountDigit: string;
    pixKey?: string;
    status: PaymentTransactionStatus;
}

export interface ScdPaymentTransactionData {
    id: number;
    date: Date;
    amount: number;
    favoredName: string;
    favoredIdentification: string;
    status: PaymentTransactionStatus;
    type: PaymentTransactionType;
    mode: PaymentTransactionMode;
    dispatchMode: PaymentTransactionDispatchMode;
    bankIdentification: string;
    bankName: string;
    agencyNumber: string;
    accountNumber: string;
    accountDigit: string;
    pixKey?: string;
    originatorName: string;
}

export interface ScdPaymentTransactionToCreate {
    amount?: number;
    description?: string;
    personId?: number;
    companyId?: number;
    bankAccountId?: number;
}

export const defaulScdPaymentTransactionToCreate: Readonly<ScdPaymentTransactionToCreate> = {};

export interface PaymentTransactionFilterRequest {
    originatorId?: number;
    originatorName?: string;
    mode?: PaymentTransactionMode;
    dispatchMode?: PaymentTransactionDispatchMode;
    status?: PaymentTransactionStatus;
    type?: PaymentTransactionType;
    favored?: string;
    amount?: {
        minValue?: number;
        maxValue?: number;
    };
    date?: {
        start?: string;
        end?: string;
    };
    bankIdentification?: string;
    bankName?: string;
}

export const defaultPaymentTransactionFilterRequest: Readonly<PaymentTransactionFilterRequest> = {};

export interface ScdPaymentTransactionPendingData {
    id: number;
    date: Date;
    amount: number;
    type: PaymentTransactionType;
    mode: PaymentTransactionMode;
    dispatchMode: PaymentTransactionDispatchMode;
    favoredName: string;
    favoredIdentification: string;
    companyId?: number;
    companyName: string;
    companyIdentification: string;
    bankIdentification: string;
    bankName: string;
    agencyNumber: string;
    accountNumber: string;
    accountDigit: string;
    pixKey?: string;
    originatorId: number;
    originatorName: string;
    paymentInstitutionId: number;
    paymentInstitutionName: string;
}

export interface ScdPaymentTransactionPendingList {
    pendingTransactions: ScdPaymentTransactionPendingData[];
}

export interface CompletePaymentTransactionFilterData {
    amount?: {
        minValue?: number;
        maxValue?: number;
    };
    date?: {
        initialDate?: string;
        finalDate?: string;
    };
    favored?: {
        name?: string;
        identification?: string;
        companyId?: number;
    };
    originator?: {
        name?: string;
        id?: string;
    };
    mode?: PaymentTransactionMode;
}

export interface ScdPaymentTransactionsToProcess {
    paymentTransactionIds: number[];
}

interface PaymentTransactionManualDispatchError {
    id: number;
    message: string;
}

export interface PaymentTrasactionToProcessResponse {
    errors: PaymentTransactionManualDispatchError[];
}

export interface ScdPaymentTransactionsToArchive {
    paymentTransactionIds: number[];
}

export interface ScdPaymentTransactionSummarize {
    numberOfPendingTransactions: number;
    numberOfTransactionsPendingManualDispatch: number;
}

export interface PaymentAccountRecipient {
    id: number;
    bankIdentification: string;
    bankName: string;
    agencyNumber: string;
    accountNumber: string;
    accountDigit: string;
    pixKey: string;
    accountType: BankAccountType;
    pixKeyType: PixKeyType;
}

export interface PaymentTransactionDetail {
    id: number;
    amount: number;
    description: string;
    status: PaymentTransactionStatus;
    type: PaymentTransactionType;
    method: PaymentTransactionMethod;
    mode: PaymentTransactionMode;
    dispatchMode: PaymentTransactionDispatchMode;
    date: Date;
    paymentFavored: PaymentFavoredToGetTransaction;
    paymentAccountRecipient: PaymentAccountRecipient;
    originator?: OriginatorToGetTransaction;
    paymentValue?: PaymentValueToGetTransaction;
    paymentInstitution?: PaymentInstitutionToGetTransaction;
    paymentInstitutionAccount?: PaymentInstitutionAccountToGetTransaction;
    paymentTransactionFailure?: PaymentTransactionToGetTransactionFailure;
    transactionFailureCategorization?: PaymentTransactionFailureToGetFailureCategorization;
}

export interface PaymentTransactionToSimple {
    id: number;
}

export interface PaymentTransactionToManualDisbursement {
    paymentTransactionId?: number;
    paymentInstitutionId?: number;
    paymentInstitutionName?: string;
    paymentInstitutionStatus?: TechnicalSupplierStatus;
    paymentInstitutionAccountId?: number;
    paymentInstitutionAccountName?: string;
    paymentInstitutionAccountStatus?: PaymentInstitutionAccountStatus;
    description?: string;
    bankAccount?: {
        bankId?: number;
        bankName?: string;
        bankIdentification?: string;
        agencyNumber?: string;
        accountNumber?: string;
        accountDigit?: string;
        accountType?: BankAccountType;
    };
    pix?: {
        pixKey?: string;
        pixKeyType?: PixKeyType;
    };
}

export interface PaymentTransactionManualDisbursementValidationConstraint {
    code: string;
}

export interface PaymentTransactionManualDisbursementValidation {
    id: number;
    availability: PaymentTransactionManualDisbursementAvailability;
    constraintsCode: ReadonlyArray<PaymentTransactionManualDisbursementValidationConstraint>;
}

export interface PaymentTransactionToCreateRepayment {
    amount?: number;
    description?: string;
    originatorId?: number;
    paymentInstitutionAccountId?: number;
    originatorName?: string;
}

export interface PaymentPixToGetInternalRepayment {
    key: string;
    type: PixKeyType;
}

export interface PaymentFavoredToGetInternalRepayment {
    name: string;
    identification: string;
}

export interface PaymentBankAccountToGetInternalRepayment {
    bankName: string;
    bankIdentification: string;
    agencyNumber: string;
    accountNumber?: string;
    accountDigit?: string;
    accountType: BankAccountType;
}

export interface PaymentAccountToGetInternalRepayment {
    favored: PaymentFavoredToGetTransaction;
    pix?: PaymentPixToGetInternalRepayment;
    bankAccount?: PaymentBankAccountToGetInternalRepayment;
}

export interface PaymentTransactionToGetTransactionFailure {
    id: number;
    type: PaymentTransactionFailureType;
    origin: PaymentTransactionFailureOrigin;
    failureDate: Date;
    institution?: string;
    exceptionClass?: string;
    errorStatus?: string;
    errorCode?: string;
    statusCode?: string;
    errorDescription?: string;
}