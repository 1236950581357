import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdBankerList } from 'model/banker';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.banker.table';

export type ScdBankerListType = keyof ScdBankerList | 'user.firstName';

interface ScdBankerHeaderProps {
    bankers: ScdBankerList[];
    handleSort: (property: ScdBankerListType, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdOBankerHeader = (props: ScdBankerHeaderProps) => {
    const { t } = useTranslation();
    const { bankers, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: ScdBankerListType) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: bankers && bankers.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.user.firstName`).toUpperCase()} sortable={mapSortable('user.firstName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.user.email`).toUpperCase()} sortable={mapSortable('userEmail')} />
            <SortableColumn label={t(`${I18N_PREFIX}.organization.name`).toUpperCase()} sortable={mapSortable('organizationName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.organization.hasApproval`).toUpperCase()} />
        </tr>
    );
};

export default ScdOBankerHeader;
