import Button from '@material-ui/core/Button';
import { InputAdornmentTypeMap } from '@material-ui/core/InputAdornment';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './square-button.scss';
import { ButtonSizeType } from 'model/enums/button-size';

export interface SquareButtonProps {
    label: string;
    onClick?: () => void;
    disabled?: boolean;
    adornment?: OverridableComponent<InputAdornmentTypeMap>;
    size?: ButtonSizeType;
    endIcon?: React.ReactNode;
}

export const SquareButton = (props: SquareButtonProps): JSX.Element => {
    const { t } = useTranslation();

    const handleClick = () => {
        if (!props.disabled && props.onClick) {
            props.onClick();
        }
    };

    return (
        <div className={clsx('button__style--square', props.size?.toLowerCase())}>
            <Button variant="contained" onClick={handleClick} disabled={props.disabled} endIcon={props.endIcon}>
                <span> {t(props.label)} </span>
                <span> {props.adornment} </span>
            </Button>
        </div>
    );
};

export default SquareButton;
