import InputLabel from '@material-ui/core/InputLabel';
import Select, { SelectProps } from '@material-ui/core/Select';
import uniqueId from 'lodash/uniqueId';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './base-select-input.scss';

type Base = Pick<SelectProps, 'children' | 'disabled' | 'readOnly'>;

export interface BaseSelectInputProps<T> extends Base {
    label: string;
    value: T;
    error?: boolean;
    onChange?: (value: T) => void;
    mapperFromString?: (value?: string) => T;
    placeholder?: string | null;
    // for situations where the input is externally updated
    // should be the exception
    // avoid using unless necessary to avoid needless rerenders
    externalUpdate?: boolean;
}

export const BaseSelectInput = <T extends {}>(props: BaseSelectInputProps<T>) => {
    const { label, value: propsValue, onChange, mapperFromString, placeholder, error, externalUpdate, disabled, ...otherProps } = props;
    const UID = React.useMemo(() => uniqueId('select-'), []);

    const { t } = useTranslation();

    const [value, setValue] = React.useState<T>(propsValue);

    const handleChange = (event: any) => {
        const _value = mapperFromString ? mapperFromString(event?.target?.value) : event?.target?.value;
        if (_value === value) return;

        setValue(_value);
        if (onChange) {
            onChange(_value);
        }
    };

    React.useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === propsValue ? ps : propsValue ?? undefined));
        }
    }, [externalUpdate, propsValue]);

    return (
        <>
            <InputLabel htmlFor={UID} error={error}>
                {t(label)}
            </InputLabel>
            <Select
                id={UID}
                variant="filled"
                value={value}
                placeholder={t(placeholder ?? '')}
                onChange={handleChange}
                error={error}
                disabled={disabled}
                {...otherProps}
                fullWidth
            />
        </>
    );
};

export default BaseSelectInput;
