import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListOriginatorsTypes, ListOriginatorsState } from 'reducer/originator/list-originators/types';

const initialState: ListOriginatorsState = {
    status: HttpRequestStatus.NOOP,
    originators: undefined,
    error: undefined,
};

const reducer: Reducer<ListOriginatorsState> = (state = initialState, action): ListOriginatorsState => {
    switch (action.type) {
        case ListOriginatorsTypes.LIST_ORIGINATORS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListOriginatorsTypes.LIST_ORIGINATORS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, originators: action.payload, error: undefined };
        }
        case ListOriginatorsTypes.LIST_ORIGINATORS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListOriginatorsTypes.LIST_ORIGINATORS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listOriginatorsReducer };
