import { format, startOfMonth } from 'date-fns';

const getFirstDayOfMonth = (): string => {
    return format(startOfMonth(new Date()), 'yyyy-MM-dd');
}

const getTodayDay = (): string => {
    return format(new Date(), 'yyyy-MM-dd');
}

const ExternalStrategyDateUtils = {
    getFirstDayOfMonth,
    getTodayDay
}

export default ExternalStrategyDateUtils;