import { ScdOrganizationSimple } from 'model/organization';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum UpdateOrganizationTypes {
    UPDATE_ORGANIZATION_REQUEST = '@@organization-update/UPDATE_ORGANIZATIONR_REQUEST',
    UPDATE_ORGANIZATION_SUCCESS = '@@organization-update/UPDATE_ORGANIZATION_SUCCESS',
    UPDATE_ORGANIZATION_ERROR = '@@organization-update/UPDATE_ORGANIZATION_ERROR',
    UPDATE_ORGANIZATION_RESET_STATE = '@@organization-update/UPDATE_ORGANIZATION_RESET_STATE',
}

export interface UpdateOrganizationState extends BaseStoreState {
    readonly organization?: ScdOrganizationSimple;
}
