import { AxiosResponse } from 'axios';
import {
    PaymentTransactionsByBatchErrorsList,
    PaymentTransactionsCreateByBatch,
    PaymentTransactionsImportByBatch,
    ScdPaymentTransactionsByBatchLegacyErrorsList,
    ScdPaymentTransactionsCreateLegacyByBatch,
    ScdPaymentTransactionsLegacyByBatch,
} from 'model/payment-transaction-by-batch';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN } from 'services/page';

const paymentTransactionByBatch = () => {
    const listPaymentTransactionsByBatchLegacy = (batchId: string): Promise<AxiosResponse<ScdPaymentTransactionsLegacyByBatch>> => {
        return api.get<ScdPaymentTransactionsLegacyByBatch>(`api/payment-transactions/import/disbursement/batch/${batchId}`);
    };

    const listPaymentTransactionsByBatch = (batchId: string): Promise<AxiosResponse<PaymentTransactionsImportByBatch>> => {
        return api.get<PaymentTransactionsImportByBatch>(`${BASE_URL_ADMIN}/payment-transactions/import/disbursements/batches/${batchId}`);
    };

    const createPaymentTransactionsByBatchLegacy = (
        paymentTransactions: ScdPaymentTransactionsCreateLegacyByBatch
    ): Promise<AxiosResponse<ScdPaymentTransactionsByBatchLegacyErrorsList>> => {
        return api.post<ScdPaymentTransactionsByBatchLegacyErrorsList>(`api/payment-transactions/by-batch`, paymentTransactions);
    };

    const createPaymentTransactionsByBatch = (
        paymentTransactions: PaymentTransactionsCreateByBatch
    ): Promise<AxiosResponse<PaymentTransactionsByBatchErrorsList>> => {
        return api.post(`${BASE_URL_ADMIN}/payment-transactions/by-batch`, paymentTransactions);
    };

    return {
        listPaymentTransactionsByBatchLegacy,
        listPaymentTransactionsByBatch,
        createPaymentTransactionsByBatchLegacy,
        createPaymentTransactionsByBatch,
    };
};

export default paymentTransactionByBatch();
