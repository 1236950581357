import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import { BankList } from 'model/bank';
import { ListChildComponentProps } from 'react-window';

export interface BanksRowProps {
    banks: BankList[];
}

const BanksRow = (props: ListChildComponentProps<BanksRowProps>) => {
    const { index, data, style } = props;

    const { banks } = data;

    const { id, name, number, identification } = banks[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{name ?? '-'}</div>
            </td>

            <td className="page-container--table-cell-title">{number ?? '-'}</td>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{identification}</div>
            </td>
            <td className="page-container--table-cell-icon">
                <EditIconButton />
            </td>
        </tr>
    );
};

export default BanksRow;
