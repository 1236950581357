import { action } from 'typesafe-actions';
import { SearchPlatformRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { SearchPlatformsTypes } from 'reducer/platform/search/types';
import { SearchPlatform } from 'model/platform';

export const searchPlatformsRequest = (request: SearchPlatformRequest) => action(SearchPlatformsTypes.SEARCH_PLATFORM_REQUEST, request);
export const searchPlatformsSuccess = (platforms: Page<SearchPlatform>) => action(SearchPlatformsTypes.SEARCH_PLATFORM_SUCCESS, platforms);
export const searchPlatformsError = (error: GiroWebappError) => action(SearchPlatformsTypes.SEARCH_PLATFORM_ERROR, error);
export const searchPlatformsResetState = () => action(SearchPlatformsTypes.SEARCH_PLATFORM_RESET);
