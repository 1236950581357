import clsx from 'clsx';

import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import SwitchTodayOnlyButton from 'components/buttons/switch-today-only-button/switch-today-only-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import BatchFilterButtons from 'entities/batch/components/batch-filter-buttons/batch-filter-buttons';
import ScdBatchHeader from 'entities/batch/components/batch-header/batch-header';
import BatchModalFilter, { BatchModalFilterType } from 'entities/batch/components/batch-modal-filter/batch-modal-filter';
import BatchesList from 'entities/batch/components/batches-list/batches-list';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import useBatchFilterChips from 'entities/batch/hooks/use-batch-filter-chips';
import { BatchFilterRequest, defaultBatchFilterRequest, ScdBatchData } from 'model/batch';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchFilterPageable } from 'model/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { closeBatchResetState } from 'reducer/batch/close-batch/actions';
import { exportBatchResetState } from 'reducer/batch/export-batch/actions';
import { listBatchesRequest } from 'reducer/batch/list-batches/actions';
import { reprocessBatchResetState } from 'reducer/batch/reprocess-batch/actions';
import { sendBatchCcbDocumentsResetState } from 'reducer/batch/send-ccb-documents/actions';
import { sendBatchCnabDocumentResetState } from 'reducer/batch/send-cnab-document/actions';
import {
    useCloseBatchState,
    useExportBatchState,
    useReprocessBatchState,
    useRootDispatch,
    useSendBatchCcbDocumentsState,
    useSendBatchCnabDocumentState,
} from 'reducer/hooks';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './batch.scss';

const I18N_PREFIX = 'pages.scd.batch';

export type BatchDataSortableProperties = keyof ScdBatchData | 'createdDate' | 'yearly_session_interest_rate';

const ScdBatch = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<BatchDataSortableProperties>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [modalFilter, setModalFilter] = useState<BatchModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<BatchFilterRequest>(defaultBatchFilterRequest);
    const [modalAction, setModalAction] = useState<BatchModalActionOptions | undefined>(undefined);

    const { toastLoading, closeToast, toastSuccess } = useToast();

    const { status: closeBatchStatus, closedBatch } = useCloseBatchState();
    const { status: reprocessBatchStatus } = useReprocessBatchState();
    const { status: exportBatchStatus } = useExportBatchState();
    const { status: sendCcbDocumentsStatus } = useSendBatchCcbDocumentsState();
    const { status: sendCnabDocumentStatus } = useSendBatchCnabDocumentState();
    const { isBatchChipsActive, batchFilterChips } = useBatchFilterChips({ filterActive, setFilterActive, setPage });

    const filterPageable = useCallback((): BatchFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const _filter: BatchFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filter,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScroll<ScdBatchData>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listBatches.batches,
        statusSelector: (state: IRootState) => state.listBatches.status,
        action: listBatchesRequest,
        filterPageable,
        setPage,
    });

    useEffect(() => {
        if (closeBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (closeBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(closeBatchResetState());
            setModalAction(undefined);
        }
        if (closeBatchStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(closeBatchResetState());
            handleRefresh();
            setModalAction(undefined);
            toastSuccess(t(`${I18N_PREFIX}.close-batch.toast.success`));
        }
    }, [closeBatchStatus, toastLoading, closeToast, closedBatch, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (reprocessBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (reprocessBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(reprocessBatchResetState());
            setModalAction(undefined);
            handleRefresh();
        }
        if (reprocessBatchStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(reprocessBatchResetState());
            handleRefresh();
            setModalAction(undefined);
        }
    }, [toastLoading, closeToast, handleRefresh, closedBatch, t, reprocessBatchStatus, dispatch]);

    useEffect(() => {
        if (exportBatchStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (exportBatchStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(exportBatchResetState());
        }
        if (exportBatchStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(exportBatchResetState());
            handleRefresh();
            setModalAction(undefined);
            toastSuccess(t(`${I18N_PREFIX}.export-webcred.toast.success`));
        }
    }, [exportBatchStatus, toastLoading, closeToast, handleRefresh, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (sendCcbDocumentsStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
        }
        if (sendCcbDocumentsStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCcbDocumentsResetState());
            toastSuccess(t(`${I18N_PREFIX}.send-ccb-documents.toast.success`));
        }
    }, [sendCcbDocumentsStatus, dispatch, toastLoading, closeToast, toastSuccess, t]);

    useEffect(() => {
        if (sendCnabDocumentStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (sendCnabDocumentStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCnabDocumentResetState());
        }
        if (sendCnabDocumentStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            setModalAction(undefined);
            dispatch(sendBatchCnabDocumentResetState());
            toastSuccess(t(`${I18N_PREFIX}.send-cnab-document.toast.success`));
        }
    }, [sendCnabDocumentStatus, dispatch, toastLoading, closeToast, toastSuccess, t]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    const handleSort = (property: BatchDataSortableProperties, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (sortedProperty !== 'createdDate' && sortOrder === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive({ ...defaultBatchFilterRequest, todayOnly: filterActive.todayOnly });
    };

    const disableActions =
        (HttpRequestStatus.SUCCESS !== closeBatchStatus && HttpRequestStatus.NOOP !== closeBatchStatus) ||
        (HttpRequestStatus.SUCCESS !== reprocessBatchStatus && HttpRequestStatus.NOOP !== reprocessBatchStatus) ||
        (HttpRequestStatus.SUCCESS !== exportBatchStatus && HttpRequestStatus.NOOP !== exportBatchStatus);

    const handleSwitch = (value: boolean) => {
        setFilterActive({ todayOnly: value });
        setPage(0);
    };

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.batch.error-message', { count: 2 })}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-batch">
            <section className="scd-batch__container">
                <header className="scd-batch__header">
                    <div className="scd-batch__header-container">
                        <h2 className="scd-batch__header-container--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                        <RefreshButton onClick={handleRefresh} />
                        <div className="scd-batch__header-container--align-right">
                            <SwitchTodayOnlyButton onSwitch={handleSwitch} isDisabled={disableActions} />
                        </div>
                    </div>
                    <div className="scd-batch__header--buttons-content">
                        <BatchFilterButtons
                            filterActive={filterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                            handleResetFilterActive={handleResetFilterActive}
                            disabled={disableActions}
                        />
                    </div>
                    <ChipFilterActive filters={batchFilterChips} />
                </header>

                <div className="scd-batch__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isBatchChipsActive,
                        })}
                    >
                        <thead>
                            <ScdBatchHeader
                                batches={items}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                disabledSort={disableActions}
                            />
                        </thead>
                        <tbody>
                            <BatchesList
                                batches={items}
                                isLoading={isLoading}
                                filterActive={filterActive}
                                isDisabledMenu={disableActions}
                                handleRefreshList={handleRefresh}
                                modalAction={modalAction}
                                setModalAction={setModalAction}
                            />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
                {modalFilter && !disableActions && (
                    <BatchModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdBatch;
