import { ScdBatchSearch } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchSearchPageable } from 'model/reducers';
import { SearchBatchesTypes } from 'reducer/batch/search-batches/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchBatchesRequest = (request: BatchSearchPageable) => action(SearchBatchesTypes.SEARCH_BATCHES_REQUEST, request);
export const searchBatchesSuccess = (batches: Page<ScdBatchSearch>) => action(SearchBatchesTypes.SEARCH_BATCHES_SUCCESS, batches);
export const searchBatchesError = (error: GiroWebappError) => action(SearchBatchesTypes.SEARCH_BATCHES_ERROR, error);
export const searchBatchesResetState = () => action(SearchBatchesTypes.SEARCH_BATCHES_RESET);
