import { GiroWebappError } from 'model/error';
import {
    ScdMonthlyCommissionDeleteDocumentRequest,
    ScdMonthlyCommissionDocumentSimple,
    ScdMonthlyCommissionDownloadDocumentRequest,
    ScdMonthlyCommissionUploadDocumentRequest,
} from 'model/monthly-commission';
import { MonthlyCommissionDocumentTypes } from 'reducer/monthly-commission/document/types';
import { action } from 'typesafe-actions';

export const monthlyCommissionUploadDocumentRequest = (request: ScdMonthlyCommissionUploadDocumentRequest) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_REQUEST, request);
export const monthlyCommissionUploadDocumentSuccess = (document: ScdMonthlyCommissionDocumentSimple) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_SUCCESS, document);
export const monthlyCommissionUploadDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_ERROR, error);

export const monthlyCommissionDownloadDocumentRequest = (request: ScdMonthlyCommissionDownloadDocumentRequest) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_REQUEST, request);
export const monthlyCommissionDownloadDocumentSuccess = (data: Uint8Array) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_SUCCESS, data);
export const monthlyCommissionDownloadDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_ERROR, error);

export const monthlyCommissionDeleteDocumentRequest = (request: ScdMonthlyCommissionDeleteDocumentRequest) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_REQUEST, request);
export const monthlyCommissionDeleteDocumentSuccess = () =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_SUCCESS);
export const monthlyCommissionDeleteDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_ERROR, error);

export const monthlyCommissionDocumentResetState = () => action(MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOCUMENT_RESET);
