import { ScdBatchClose } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ReprocessBatchTypes {
    REPROCESS_BATCH_REQUEST = '@@reprocess-batch/REPROCESS_BATCH_REQUEST',
    REPROCESS_BATCH_SUCCESS = '@@reprocess-batch/REPROCESS_BATCH_SUCCESS',
    REPROCESS_BATCH_ERROR = '@@reprocess-batch/REPROCESS_BATCH_ERROR',
    REPROCESS_BATCH_RESET = '@@reprocess-batch/REPROCESS_BATCH_RESET',
}

export interface ReprocessBatchState extends BaseStoreState {
    readonly closedBatch?: ScdBatchClose;
}
