import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchIssuersTypes, SearchIssuersState } from 'reducer/issuer/search-issuers/types';
import { Reducer } from 'redux';

export const initialState: SearchIssuersState = {
    status: HttpRequestStatus.NOOP,
    issuers: undefined,
    error: undefined,
};

const reducer: Reducer<SearchIssuersState> = (state = initialState, action): SearchIssuersState => {
    switch (action.type) {
        case SearchIssuersTypes.SEARCH_ISSUERS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case SearchIssuersTypes.SEARCH_ISSUERS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuers: action.payload, error: undefined };
        }
        case SearchIssuersTypes.SEARCH_ISSUERS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchIssuersTypes.SEARCH_ISSUERS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchIssuersReducer };
