import { action } from 'typesafe-actions';
import { SearchOriginatorsTypes } from 'reducer/originator/search-originators/types';
import { ScdOriginatorSearch } from 'model/originator';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchOriginatorsRequest = (request: SearchRequest) => action(SearchOriginatorsTypes.SEARCH_ORIGINATORS_REQUEST, request);
export const searchOriginatorsSuccess = (originators: Page<ScdOriginatorSearch>) =>
    action(SearchOriginatorsTypes.SEARCH_ORIGINATORS_SUCCESS, originators);
export const searchOriginatorsError = (error: GiroWebappError) => action(SearchOriginatorsTypes.SEARCH_ORIGINATORS_ERROR, error);
export const searchOriginatorsResetState = () => action(SearchOriginatorsTypes.SEARCH_ORIGINATORS_RESET);
