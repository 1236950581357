import { AxiosResponse } from 'axios';
import { ScdBatchCreatedByOperations } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    createBatchByOperationsError,
    createBatchByOperationsRequest,
    createBatchByOperationsSuccess,
} from 'reducer/batch/create-batch-by-operations/actions';
import { CreateBatchByOperationsTypes } from 'reducer/batch/create-batch-by-operations/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateBatchByOperations(action: ReturnType<typeof createBatchByOperationsRequest>) {
    try {
        const result: AxiosResponse<ScdBatchCreatedByOperations> = yield call(batchAPI.createBatchByOperations, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createBatchByOperationsError(mapped));
            return;
        }
        yield put(createBatchByOperationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createBatchByOperationsError(mapped));
    }
}

function* watchCreateBatchByOperations() {
    yield takeLeading(CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_REQUEST, handleCreateBatchByOperations);
}

function* createBatchByOperationsSaga() {
    yield all([fork(watchCreateBatchByOperations)]);
}

export default createBatchByOperationsSaga;
