import { IssuerPolicySimple } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum IssuerUpdatePolicyTypes {
    ISSUER_UPDATE_POLICY_REQUEST = '@@issuer-update-policy/ISSUER_UPDATE_POLICY_REQUEST',
    ISSUER_UPDATE_POLICY_SUCCESS = '@@issuer-update-policy/ISSUER_UPDATE_POLICY_SUCCESS',
    ISSUER_UPDATE_POLICY_ERROR = '@@issuer-update-policy/ISSUER_UPDATE_POLICY_ERROR',
    ISSUER_UPDATE_POLICY_RESET_STATE = '@@issuer-update-policy/ISSUER_UPDATE_POLICY_RESET_STATE',
}

export interface UpdateIssuerPolicyState extends BaseStoreState {
    readonly issuerUpdatePolicy?: IssuerPolicySimple;
}