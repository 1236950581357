import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import PercentageFormatter from 'components/formatter/percentage/percentage-formatter';
import { ScdMonthlyCommissionDetailOperationValueDaily, ScdMonthlyCommissionDetailOperationValueMonthly } from 'model/monthly-commission';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap/lib';
import './monthly-commission-operations-value-card.scss';

export const fallbackValue = '-'.repeat(7);

interface MonthlyCommissionOperationsValueCardProps {
    monthlies: ScdMonthlyCommissionDetailOperationValueMonthly[];
    dailies: ScdMonthlyCommissionDetailOperationValueDaily[];
    yearMonth: Date | undefined;
}

const I18N_PREFIX = 'pages.scd.monthly-commission-detail.card.operations-value';

const MonthlyCommissionOperationsValueCard = (props: MonthlyCommissionOperationsValueCardProps) => {
    const { monthlies, dailies, yearMonth } = props;

    const { t } = useTranslation();

    const [openCard, setOpenCard] = useState<boolean>(false);

    return (
        <div className="monthly-commission-operations-value-card">
            <h2 className="monthly-commission-operations-value-card--title">
                {t(`${I18N_PREFIX}.title`)} {yearMonth && <DateFormatter date={yearMonth} format={DateFormat.DATE_TO_MONTH_STRING} />}
            </h2>
            <span className="monthly-commission-operations-value-card--icon" onClick={() => setOpenCard(!openCard)}>
                {!openCard ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </span>
            {openCard &&
                (!!monthlies.length ? (
                    <div className="monthly-commission-operations-value-card--content">
                        <Container>
                            <Row>
                                <Col>
                                    <div style={{ display: 'flex', marginTop: '8px' }}>
                                        <div className="monthly-commission-operations-value-card--content__container">
                                            <div className={'monthly-commission-operations-value-card--content-header'}>
                                                <span className="monthly-commission-operations-value-card--content-header__title">
                                                    {t(`${I18N_PREFIX}.date`)}
                                                </span>
                                            </div>
                                            <div
                                                className={clsx('monthly-commission-operations-value-card--content-header', {
                                                    __border: true,
                                                })}
                                            />
                                        </div>
                                        {!!monthlies.length &&
                                            monthlies.map(({ id, commissionTax, totalValue, productType }) => {
                                                return (
                                                    <div className="monthly-commission-operations-value-card--content__container" key={id}>
                                                        <div
                                                            className={clsx('monthly-commission-operations-value-card--content-header', {
                                                                __border: true,
                                                            })}
                                                        >
                                                            <div className="monthly-commission-operations-value-card--content-header__title">
                                                                <span> {t(`${I18N_PREFIX}.productType.options.${productType}`)} </span>
                                                                <span>
                                                                    {commissionTax ? (
                                                                        <PercentageFormatter value={commissionTax} />
                                                                    ) : (
                                                                        fallbackValue
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={clsx('monthly-commission-operations-value-card--content-header', {
                                                                __border: true,
                                                            })}
                                                        >
                                                            <div className="monthly-commission-operations-value-card--content-header__subtitle">
                                                                <span className="monthly-commission-operations-value-card--content-header__subtitle-label">
                                                                    {t(`${I18N_PREFIX}.totalValue`)}
                                                                </span>
                                                                <span className="monthly-commission-operations-value-card--content-header__subtitle-value">
                                                                    <CurrencyFormatter value={totalValue ?? 0} prefix />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={'monthly-commission-operations-value-card--content-header'}
                                                            style={{ marginTop: '8px' }}
                                                        >
                                                            <span className="monthly-commission-operations-value-card--content-header__subtitle-label">
                                                                {t(`${I18N_PREFIX}.daily`)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {!!dailies.length &&
                                        dailies.map(({ date, values }, dailyKey) => {
                                            return (
                                                <>
                                                    <div style={{ display: 'flex' }}>
                                                        <div
                                                            className="monthly-commission-operations-value-card--content-table"
                                                            key={dailyKey}
                                                        >
                                                            <div
                                                                className="monthly-commission-operations-value-card--content-table"
                                                                style={{ background: dailyKey % 2 === 0 ? 'rgba(106, 207, 241, 0.1)' : '' }}
                                                            >
                                                                <span className="monthly-commission-operations-value-card--content-table-cell">
                                                                    {date ? <DateFormatter date={date} /> : '00/00/0000'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {values?.map((value, valueKey) => {
                                                            return (
                                                                <div
                                                                    className="monthly-commission-operations-value-card--content-table"
                                                                    key={valueKey}
                                                                >
                                                                    <div
                                                                        className="monthly-commission-operations-value-card--content-table"
                                                                        style={{
                                                                            background:
                                                                                dailyKey % 2 === 0 ? 'rgba(106, 207, 241, 0.1)' : '',
                                                                        }}
                                                                    >
                                                                        <span className="monthly-commission-operations-value-card--content-table-cell">
                                                                            {value ? <CurrencyFormatter value={value} prefix /> : '-'}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </>
                                            );
                                        })}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                ) : (
                    <div className="monthly-commission-operations-value-card--no-results">
                        {' '}
                        <span> {t(`${I18N_PREFIX}.no-results`)} </span>{' '}
                    </div>
                ))}
        </div>
    );
};

export default MonthlyCommissionOperationsValueCard;
