import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useDetailCompanyGroupState, useRootDispatch } from 'reducer/hooks';
import { detailCompanyGroupRequest, detailCompanyGroupResetState } from 'reducer/company-group/detail-company-group/actions';
import './company-group-detail.scss';

type ScdCompanyGroupDetailProps = RouteComponentProps<{ companyGroupId: string }>;

const I18N_PREFIX = 'pages.scd.company-group.input';

const ScdCompanyGroupDetailPage = (props: ScdCompanyGroupDetailProps) => {
    const { t } = useTranslation();
    const [companyGroupId] = useState<string>(props.match.params.companyGroupId);
    const { status, companyGroup } = useDetailCompanyGroupState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailCompanyGroupRequest(companyGroupId));
        return () => {
            dispatch(detailCompanyGroupResetState());
        };
    }, [dispatch, companyGroupId]);

    return (
        <main className="scd-company-group__detail">
            <ContextRibbon />
            <div className="scd-company-group--container">
                <header className="scd-company-group--header">
                    <h2 className="scd-company-group--header-title">{t('pages.scd.company-group.detail.title')}</h2>
                </header>
                <div className="scd-company-group__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={companyGroup?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.organization.label`)} value={companyGroup?.organization?.name} />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdCompanyGroupDetailPage;
