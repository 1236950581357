import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import {
    monthlyCommissionRectifyTaxesError,
    monthlyCommissionRectifyTaxesRequest,
    monthlyCommissionRectifyTaxesSuccess,
} from 'reducer/monthly-commission/rectify-taxes/actions';
import { MonthlyCommissionRectifyTaxesTypes } from 'reducer/monthly-commission/rectify-taxes/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleMonthlyCommissionRectifyTaxes(action: ReturnType<typeof monthlyCommissionRectifyTaxesRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionSimple> = yield call(monthlyCommissionAPI.rectifyTaxes, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionRectifyTaxesError(mapped));
            return;
        }
        yield put(monthlyCommissionRectifyTaxesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionRectifyTaxesError(mapped));
    }
}

function* watchMonthlyCommissionRectifyTaxes() {
    yield takeEvery(MonthlyCommissionRectifyTaxesTypes.MONTHLY_COMMISSION_RECTIFY_TAXES_REQUEST, handleMonthlyCommissionRectifyTaxes);
}

function* monthlyCommissionRectifyTaxesSaga() {
    yield all([fork(watchMonthlyCommissionRectifyTaxes)]);
}

export default monthlyCommissionRectifyTaxesSaga;
