import { InputBaseComponentProps } from '@material-ui/core';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import { ValidationResult } from '../../validation/input-validation';
import clsx from 'clsx';
import { MonthMask } from 'components/inputs/date-input/month-input/month-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children' | 'validate' | 'value'>;
type Base = BaseInput & BaseValidation;

export interface ValidMonthInputProps extends Base {
    value: string;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
    validate: (value: string) => ValidationResult;
    placeholder?: string;
    onChange: (value?: string) => void;
}

const ValidMonthInput = (props: ValidMonthInputProps) => {
    const { value: propsValue, onChange, MaskInputComponent, validate, showValidation, placeholder, ...otherProps } = props;

    return (
        <ValidInput
            className={clsx('date-input base-input month__input', { dirty: propsValue })}
            value={propsValue}
            validate={validate}
            showValidation={showValidation}
        >
            {({ error }) => (
                <BaseMaskInput
                    value={propsValue}
                    error={error}
                    onChange={onChange}
                    MaskInputComponent={MaskInputComponent ?? MonthMask}
                    placeholder={placeholder}
                    {...otherProps}
                />
            )}
        </ValidInput>
    );
};

export default ValidMonthInput;
