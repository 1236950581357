import { action } from 'typesafe-actions';
import { SearchCompanyGroupsTypes } from 'reducer/company-group/search-company-groups/types';
import { ScdCompanyGroup } from 'model/company-group';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchCompanyGroupsRequest = (request: SearchRequest) =>
    action(SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_REQUEST, request);

export const searchCompanyGroupsSuccess = (companyGroups: Page<ScdCompanyGroup>) =>
    action(SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_SUCCESS, companyGroups);

export const searchCompanyGroupsError = (error: GiroWebappError) => action(SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_ERROR, error);

export const searchCompanyGroupsResetState = () => action(SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_RESET);
