import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search organizations
import { searchOrganizationsReducer } from 'reducer/organization/search-organizations/reducer';
import searchOrganizationsSagas from 'reducer/organization/search-organizations/sagas';
import { SearchOrganizationsState } from 'reducer/organization/search-organizations/types';

// List Organizations
import { ListOrganizationsState } from 'reducer/organization/list-organizations/types';
import { listOrganizationsReducer } from 'reducer/organization/list-organizations/reducer';
import listOrganizationsSaga from 'reducer/organization/list-organizations/sagas';

// Detail Organization
import { DetailOrganizationState } from 'reducer/organization/detail-organizations/types';
import { detailOrganizationReducer } from 'reducer/organization/detail-organizations/reducer';
import detailOrganizationSaga from 'reducer/organization/detail-organizations/sagas';

// Create Organization
import { CreateOrganizationState } from 'reducer/organization/create-organization/types';
import { createOrganizationReducer } from 'reducer/organization/create-organization/reducer';
import createOrganizationSaga from 'reducer/organization/create-organization/sagas';

// Update Organization
import { UpdateOrganizationState } from 'reducer/organization/update-organization/types';
import { updateOrganizationReducer } from 'reducer/organization/update-organization/reducer';
import updateOrganizationSaga from 'reducer/organization/update-organization/sagas';

// Change Organization Token
import { ChangeTokenOrganizationState } from "reducer/organization/change-token-organization/types"
import { changeTokenOrganizationReducer } from "reducer/organization/change-token-organization/reducer"
import changeTokenOrganizationSaga from "reducer/organization/change-token-organization/sagas"

export interface OrganizationState {
    readonly listOrganizations: ListOrganizationsState;
    readonly detailOrganization: DetailOrganizationState;
    readonly createOrganization: CreateOrganizationState;
    readonly updateOrganization: UpdateOrganizationState;
    readonly searchOrganizations: SearchOrganizationsState;
    readonly changeTokenOrganization: ChangeTokenOrganizationState
}

export const OrganizationReducer: ReducersMapObject<OrganizationState, AnyAction> = {
    listOrganizations: listOrganizationsReducer,
    detailOrganization: detailOrganizationReducer,
    searchOrganizations: searchOrganizationsReducer,
    createOrganization: createOrganizationReducer,
    updateOrganization: updateOrganizationReducer,
    changeTokenOrganization: changeTokenOrganizationReducer
};

export function* organizationSagas() {
    yield all([
        fork(searchOrganizationsSagas),
        fork(listOrganizationsSaga),
        fork(detailOrganizationSaga),
        fork(createOrganizationSaga),
        fork(updateOrganizationSaga),
        fork(changeTokenOrganizationSaga)
    ]);
}
