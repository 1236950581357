import { ScdCompanyGroupSimple } from 'model/company-group';
import { GiroWebappError } from 'model/error';
import { CompanyGroupFilterPageable } from 'model/reducers';
import { ListCompanyGroupsTypes } from 'reducer/company-group/list-company-groups/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listCompanyGroupsRequest = (request?: CompanyGroupFilterPageable) => action(ListCompanyGroupsTypes.LIST_COMPANY_GROUPS_REQUEST, request);
export const listCompanyGroupsSuccess = (companyGroups: Page<ScdCompanyGroupSimple>) =>
    action(ListCompanyGroupsTypes.LIST_COMPANY_GROUPS_SUCCESS, companyGroups);
export const listCompanyGroupsError = (error: GiroWebappError) => action(ListCompanyGroupsTypes.LIST_COMPANY_GROUPS_ERROR, error);
export const listCompanyGroupsResetState = () => action(ListCompanyGroupsTypes.LIST_COMPANY_GROUPS_RESET);
