import { Grid } from '@material-ui/core';
import ZipCodeFormatter from 'components/formatter/zip-code/zip-code-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface AddressOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.address';

const AddressOperationCard = (props: AddressOperationCardProps) => {
    const {
        operation: { address },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.street`)} value={address?.street} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.number`)} value={address?.number} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.complement`)} value={address?.complement} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.neighborhood`)} value={address?.neighborhood} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.city`)} value={address?.city} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.state`)} value={address?.state} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.zipcode`)}
                    value={address?.zipcode ? <ZipCodeFormatter value={address?.zipcode} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.residenceType.label`)}
                    value={t(`${I18N_PREFIX}.fields.residenceType.options.${address?.residenceType}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.timeResidence`)} value={address?.timeResidence} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.rentAmount`)} value={address?.rentAmount ?? 'N/A'} />
            </Grid>
        </BaseOperationCard>
    );
};

export default AddressOperationCard;
