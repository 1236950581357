import { SessionInterestRateRequest } from 'model/session-interest-rates';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.batch.session-interest-rate.validation';

const CALCULATE_SESSION_INTEREST_RATE_DATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.session-date.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.session-date.required`,
    },
};

export const validateCalculateSessionRateSessionDate = (value: string | undefined | null) =>
    ValidationUtils.validate(value, CALCULATE_SESSION_INTEREST_RATE_DATE_VALIDATION);

const CALCULATE_SESSION_INTEREST_RATE_COMMISSION_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.commission-amount.required`,
    },
};

export const validateCalculateSessionRateCommissionAmount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, CALCULATE_SESSION_INTEREST_RATE_COMMISSION_AMOUNT_VALIDATION);

const CALCULATE_SESSION_INTEREST_RATE_BANK_CHARGES_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.bankChargesAmount.required`,
    },
};

export const validateCalculateSessionRateBankChargesAmount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, CALCULATE_SESSION_INTEREST_RATE_BANK_CHARGES_AMOUNT_VALIDATION);


interface EstimatedPercentageValidationType {
    lowEstimatePercentage?: number | undefined,
    highEstimatePercentage?: number | undefined
}

const CALCULATE_SESSION_INTEREST_RATE_TAX_VALIDATION: Validations<EstimatedPercentageValidationType | undefined | null> = {
    required: {
        predicate: it => (!!it?.lowEstimatePercentage && !!it?.highEstimatePercentage) || (!it?.lowEstimatePercentage && !it?.highEstimatePercentage),
        errorMessage: `${I18N_PREFIX}.estimatedPercentage.required`,
    },
};

export const validateCalculateSessionEstimatedPercentage = (value: EstimatedPercentageValidationType | undefined | null) =>
    ValidationUtils.validate(value, CALCULATE_SESSION_INTEREST_RATE_TAX_VALIDATION);


export const validateScdCalculateSessionInterestRate = (value: SessionInterestRateRequest | undefined | null): boolean => {
    if (!value) return false;

    return validateCalculateSessionRateSessionDate(value.sessionDate).isValid &&
        validateCalculateSessionRateBankChargesAmount(value.bankChargesAmount).isValid &&
        validateCalculateSessionRateCommissionAmount(value.commissionAmount).isValid &&
        validateCalculateSessionEstimatedPercentage(value.estimated).isValid;
};
