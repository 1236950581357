import { AxiosResponse } from 'axios';
import { BatchSignatureImportedResponse } from 'model/batch-signature';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchSignatureImportError, batchSignatureImportRequest, batchSignatureImportSuccess } from 'reducer/batch/batch-signature-import/action';
import { BatchSignatureImportTypes } from 'reducer/batch/batch-signature-import/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchSignatureApi from 'services/api/batch-signature-api';
import ErrorUtils from 'shared/error/error-utils';


function* handleBatchSignatureImport(action: ReturnType<typeof batchSignatureImportRequest>) {
    try {
        const result: AxiosResponse<BatchSignatureImportedResponse> = yield call(batchSignatureApi.importDocuments, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchSignatureImportError(mapped));
            return;
        }
        yield put(batchSignatureImportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchSignatureImportError(mapped));
    }
}

function* watchBatchSignatureImport() {
    yield takeLeading(BatchSignatureImportTypes.BATCH_SIGNATURE_IMPORT_REQUEST, handleBatchSignatureImport);
}

function* batchSignatureImportSaga() {
    yield all([fork(watchBatchSignatureImport)]);
}

export default batchSignatureImportSaga;