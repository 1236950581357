import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListRequestLogTypes, ListRequestLogState } from './types';

const initialState: ListRequestLogState = {
    status: HttpRequestStatus.NOOP,
    requestLogs: undefined,
    error: undefined,
};

const reducer: Reducer<ListRequestLogState> = (state = initialState, action): ListRequestLogState => {
    switch (action.type) {
        case ListRequestLogTypes.LIST_REQUEST_LOG_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListRequestLogTypes.LIST_REQUEST_LOG_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, requestLogs: action.payload, error: undefined };
        }
        case ListRequestLogTypes.LIST_REQUEST_LOG_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListRequestLogTypes.LIST_REQUEST_LOG_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listRequestLogsReducer };
