import { InputBaseComponentProps } from '@material-ui/core';
import { MASK_DIGIT_REGEX } from 'components/inputs/constants';
import MaskedInput from 'react-text-mask';

const PHONE_NUMBER_MASK = [
    '+',
    /5/,
    /5/,
    ' ',
    '(',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    ')',
    ' ',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    '-',
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
    MASK_DIGIT_REGEX,
];

export const PhoneNumberMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714

    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={PHONE_NUMBER_MASK} guide={false} />;
};
