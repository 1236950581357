import { ScdBatchToGenerateCessionResponse } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchPreviewCessionTypes {
    BATCH_PREVIEW_CESSION_REQUEST = '@@batch-preview-cession/BATCH_PREVIEW_CESSION_REQUEST',
    BATCH_PREVIEW_CESSION_SUCCESS = '@@batch-preview-cession/BATCH_PREVIEW_CESSION_SUCCESS',
    BATCH_PREVIEW_CESSION_ERROR = '@@batch-preview-cession/BATCH_PREVIEW_CESSION_ERROR',
    BATCH_PREVIEW_CESSION_RESET = '@@batch-preview-cession/BATCH_PREVIEW_CESSION_RESET',
}

export interface BatchPreviewCessionState extends BaseStoreState {
    readonly cessionPreview?: ScdBatchToGenerateCessionResponse;
}
