import { ScdImportedOperationBatch } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsBatchTypes {
    IMPORTED_OPERATIONS_BATCH_REQUEST = '@@imported-operations-batch/IMPORTED_OPERATIONS_BATCH_REQUEST',
    IMPORTED_OPERATIONS_BATCH_SUCCESS = '@@imported-operations-batch/IMPORTED_OPERATIONS_BATCH_SUCCESS',
    IMPORTED_OPERATIONS_BATCH_ERROR = '@@imported-operations-batch/IMPORTED_OPERATIONS_BATCH_ERROR',
    IMPORTED_OPERATIONS_BATCH_RESET_STATE = '@@imported-operations-batch/IMPORTED_OPERATIONS_BATCH_RESET_STATE',
}

export interface ImportedOperationsBatchState extends BaseStoreState {
    readonly batchOperations?: Page<ScdImportedOperationBatch>;
}
