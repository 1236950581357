import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchOperationsState, BatchOperationsTypes } from 'reducer/batch/batch-operations-summarize/types';

const initialState: BatchOperationsState = {
    status: HttpRequestStatus.NOOP,
    batchOperationsSummarize: undefined,
    error: undefined,
};

const reducer: Reducer<BatchOperationsState> = (state = initialState, action): BatchOperationsState => {
    switch (action.type) {
        case BatchOperationsTypes.BATCH_OPERATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchOperationsTypes.BATCH_OPERATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchOperationsSummarize: action.payload, error: undefined };
        }
        case BatchOperationsTypes.BATCH_OPERATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchOperationsTypes.BATCH_OPERATIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchOperationsReducer };
