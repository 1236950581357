import { FC, useCallback, useEffect } from 'react';

import 'entities/batch/batch-detail/batch-detail-footer/batch-detail-footer.scss';
import { useTranslation } from 'react-i18next';
import { useBatchConcludeState, useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import { useFooterComponents } from 'entities/batch/batch-detail/batch-detail-footer/hooks/use-footer-components';
import { detailBatchWithProgramRequest } from 'reducer/batch/batch-details-with-propgram/actions';
import { useParams } from 'react-router-dom';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { batchConcludeResetState } from 'reducer/batch/conclude-batch/actions';
import BatchConcludeModal from 'entities/batch/batch-detail/batch-detail-footer/batch-conclude-modal/batch-conclude-modal';

const I18N_PREFIX = 'pages.scd.batch.detail.footer';

interface StandardFooterProps {}

const BatchDetailFooter: FC<StandardFooterProps> = () => {
    const { t } = useTranslation();
    const { currentFooter, concludeModalOpen, setIsConcludeModalOpen } = useFooterComponents();
    const { batchId } = useParams<{ batchId: string }>();
    const dispatch = useRootDispatch();
    const { status: batchConcludeStatus, batchConclude } = useBatchConcludeState();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();

    const handleBatchWithProgramRequest = useCallback(() => {
        if (!batchId) return;
        dispatch(detailBatchWithProgramRequest(Number(batchId)));
    }, [dispatch, batchId]);

    useEffect(() => {
        switch (batchConcludeStatus) {
            case HttpRequestStatus.ON_GOING:
                toastLoading();
                break;
            case HttpRequestStatus.ERROR:
                closeToast();
                dispatch(batchConcludeResetState());
                toastError(t(`${I18N_PREFIX}.batch-conclude.toast.error`));
                break;
            case HttpRequestStatus.SUCCESS:
                closeToast();
                dispatch(batchConcludeResetState());
                handleBatchWithProgramRequest();
                toastSuccess(t(`${I18N_PREFIX}.batch-conclude.toast.success`));
                break;
            default:
                break;
        }
    }, [batchConcludeStatus, toastLoading, closeToast, toastSuccess, t, dispatch]);

    return (
        <>
            {currentFooter}
            {concludeModalOpen && <BatchConcludeModal setIsModalOpen={setIsConcludeModalOpen} />}
        </>
    );
};

export default BatchDetailFooter;
