import { AxiosResponse } from 'axios';
import { BatchDocumentSimple } from 'model/batch-output-hemera';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchDocumentExportError, batchDocumentExportRequest, batchDocumentExportSuccess } from 'reducer/batch/batch-document-export/action';
import { BatchDocumentExportTypes } from 'reducer/batch/batch-document-export/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import outputApi from 'services/api/batch-output-api'
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchDocumentExport(action: ReturnType<typeof batchDocumentExportRequest>) {
    try {
        const result: AxiosResponse<BatchDocumentSimple> = yield call(outputApi.exportDocument, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchDocumentExportError(mapped));
            return;
        }
        yield put(batchDocumentExportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchDocumentExportError(mapped));
    }
}

function* watchBatchDocumentExport() {
    yield takeLeading(BatchDocumentExportTypes.BATCH_DOCUMENT_EXPORT_REQUEST, handleBatchDocumentExport);
}

function* batchDocumentExportSaga() {
    yield all([fork(watchBatchDocumentExport)]);
}

export default batchDocumentExportSaga;