import { AxiosResponse } from 'axios';
import { Bank, BankFilterPageable, BankList } from 'model/bank';
import { SearchRequest } from 'model/reducers';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { api } from './api';

const BASE_URL = '/api';

export const bank = () => {
    const searchBanks = (request: SearchRequest): Promise<AxiosResponse<Page<Bank>>> => {
        return api.get<Page<Bank>>(`${BASE_URL}/banks/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    const listBanks = (request: BankFilterPageable): Promise<AxiosResponse<Page<BankList>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            Boolean(filter?.name) && `name=${filter?.name}`,
            Boolean(filter?.number) && `number=${filter?.number}`,
            Boolean(filter?.identification) && `identification=${filter?.identification}`,
        ]
            .filter(Boolean)
            .join('&');

        return api.get<Page<BankList>>(`${BASE_URL_ADMIN}/banks?${_requestFilters}`, { params: { ...pageable } });
    };

    return {
        searchBanks,
        listBanks,
    };
};

export default bank();
