import { BatchOutputEndorsementToResume } from 'model/batch-output-endorsement';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchOutputEndorsementTypes {
    BATCH_OUTPUT_ENDORSEMENT_REQUEST = '@@batch-output-endorsement/BATCH_OUTPUT_ENDORSEMENT_REQUEST',
    BATCH_OUTPUT_ENDORSEMENT_SUCCESS = '@@batch-output-endorsement/BATCH_OUTPUT_ENDORSEMENT_SUCCESS',
    BATCH_OUTPUT_ENDORSEMENT_ERROR = '@@batch-output-endorsement/BATCH_OUTPUT_ENDORSEMENT_ERROR',
    BATCH_OUTPUT_ENDORSEMENT_RESET = '@@batch-output-endorsement/BATCH_OUTPUT_ENDORSEMENT_RESET',
}

export interface BatchOutputEndorsementState extends BaseStoreState {
    readonly outputEndorsement?: BatchOutputEndorsementToResume;
}