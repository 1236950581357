import { AxiosResponse } from 'axios';
import { ScdIssuerList, ScdIssuer, ScdIssuerSimple, ScdIssuerToUpdateRequest, ScdIssuerSearch } from 'model/issuer';
import { SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';

export const issuer = () => {
    const listIssuers = (request: Pageable): Promise<AxiosResponse<Page<ScdIssuerList>>> => {
        return api.get<Page<ScdIssuerList>>(`api/issuers`, { params: { ...request } });
    };

    const searchIssuers = (request: SearchRequest): Promise<AxiosResponse<Page<ScdIssuerSearch>>> => {
        const DEFAULT_SORT = 'name,asc';

        return api.get<Page<ScdIssuerSearch>>(`api/issuers/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable, sort: DEFAULT_SORT },
        });
    };

    const createIssuer = (request: ScdIssuer): Promise<AxiosResponse<ScdIssuerSimple>> => {
        return api.post<ScdIssuerSimple>(`api/issuers`, request);
    };

    const updateIssuer = (request: ScdIssuerToUpdateRequest): Promise<AxiosResponse<ScdIssuerSimple>> => {
        return api.put<ScdIssuerSimple>(`api/issuers/${request.issuerId}`, request.issuer);
    };

    const detailIssuer = (issuerId: number): Promise<AxiosResponse<ScdIssuer>> => {
        return api.get<ScdIssuer>(`api/issuers/${issuerId}`);
    };

    return {
        listIssuers,
        createIssuer,
        updateIssuer,
        detailIssuer,
        searchIssuers,
    };
};

export default issuer();
