import { AxiosResponse } from 'axios';
import { ScdProgramInfo, ScdProgramPolicy, ScdProgramPolicySimple } from 'model/program-policy';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { Pageable } from 'services/pageable';

export const programPolicy = () => {
    const createProgramPolicy = (programPolicy: ScdProgramPolicy): Promise<AxiosResponse<ScdProgramPolicySimple>> => {
        return api.post<ScdProgramPolicySimple>(`${BASE_URL_ADMIN}/scd-program-policies`, programPolicy);
    };

    const updateProgramPolicy = (programPolicy: ScdProgramPolicy): Promise<AxiosResponse<ScdProgramPolicySimple>> => {
        return api.put<ScdProgramPolicySimple>(`${BASE_URL_ADMIN}/scd-program-policies`, programPolicy);
    };

    const detailProgramPolicy = (programPolicyId: string): Promise<AxiosResponse<ScdProgramPolicy>> => {
        return api.get<ScdProgramPolicy>(`${BASE_URL_ADMIN}/scd-program-policies/${programPolicyId}`);
    };

    const listProgramPolicies = (request?: Pageable): Promise<AxiosResponse<Page<ScdProgramPolicy>>> => {
        return api.get<Page<ScdProgramPolicy>>(`${BASE_URL_ADMIN}/scd-program-policies`, { params: { ...request } });
    };

    const getProgramInfo = (programId: number): Promise<AxiosResponse<ScdProgramInfo>> => {
        return api.get<ScdProgramInfo>(`${BASE_URL_ADMIN}/scd-program-policies/programs/${programId}`);
    };

    return {
        createProgramPolicy,
        detailProgramPolicy,
        updateProgramPolicy,
        listProgramPolicies,
        getProgramInfo,
    };
};

export default programPolicy();
