import { action } from 'typesafe-actions';
import { ListOriginatorsTypes } from 'reducer/originator/list-originators/types';
import { ScdOriginatorList } from 'model/originator';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { OriginatorFilterPageable } from 'model/reducers';

export const listOriginatorsRequest = (request: OriginatorFilterPageable) => action(ListOriginatorsTypes.LIST_ORIGINATORS_REQUEST, request);
export const listOriginatorsSuccess = (originators: Page<ScdOriginatorList>) =>
    action(ListOriginatorsTypes.LIST_ORIGINATORS_SUCCESS, originators);
export const listOriginatorsError = (error: GiroWebappError) => action(ListOriginatorsTypes.LIST_ORIGINATORS_ERROR, error);
export const listOriginatorsResetState = () => action(ListOriginatorsTypes.LIST_ORIGINATORS_RESET);
