import { AxiosResponse } from 'axios';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import HttpStatus from 'model/enums/http-status';
import {
    batchOutputDetailsBcoError,
    batchOutputDetailsBcoRequest,
    batchOutputDetailsBcoSuccess,
} from 'reducer-bco/batch-output/details-batch-output/action';
import { BatchOutputDetailsBcoTypes } from 'reducer-bco/batch-output/details-batch-output/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchOutputDetailsBco(action: ReturnType<typeof batchOutputDetailsBcoRequest>) {
    try {
        const result: AxiosResponse<BatchOutputDetailsBco> = yield call(batchOutputBcoApi.detailsOutput, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOutputDetailsBcoError(mapped));
            return;
        }
        yield put(batchOutputDetailsBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOutputDetailsBcoError(mapped));
    }
}

function* watchBatchOutputDetailsBco() {
    yield takeEvery(BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_REQUEST, handleBatchOutputDetailsBco);
}

function* batchOutputDetailsBcoSaga() {
    yield all([fork(watchBatchOutputDetailsBco)]);
}

export default batchOutputDetailsBcoSaga;
