import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './simple-menu-item.scss';
import { FC, MouseEvent } from 'react';
import { LinkAction } from 'model/sidebar';

export interface SimpleMenuItemProps {
    link: string;
    title: string;
    iconSource?: string;
    isExternalLink?: boolean;
    action?: LinkAction;
}

const SimpleMenuItem: FC<SimpleMenuItemProps> = ({ link, isExternalLink, title, iconSource, action }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const local = useLocation().pathname;

    const handleRedirect = (event?: MouseEvent<HTMLAnchorElement>) => {
        if (event?.ctrlKey || event?.altKey) return;
        if (action) action();

        return history.push(link);
    };

    const commonContent = (
        <>
            <img src={iconSource} alt={''} />
            <p className="simple-menu-item--link--text">{t(title)}</p>
        </>
    );

    return (
        <li className="simple-menu-item">
            {isExternalLink ? (
                <a className="simple-menu-item--link" href={link}>
                    {commonContent}
                </a>
            ) : (
                <Link to={link} className={clsx('simple-menu-item--link', { active: link === local })} onClick={handleRedirect}>
                    {commonContent}
                </Link>
            )}
        </li>
    );
};

export default SimpleMenuItem;
