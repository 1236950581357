import { HttpRequestStatus } from 'model/enums/http-request-status';

import { Reducer } from 'redux';
import {
    ExportRequestSummaryResultsBcoState,
    ExportRequestSummaryResultsBcoTypes,
} from 'reducer-bco/batch-output/export-request-summary-results/types';

const initialState: ExportRequestSummaryResultsBcoState = {
    status: HttpRequestStatus.NOOP,
    exportRequestSummaryResults: undefined,
    error: undefined,
};

const reducer: Reducer<ExportRequestSummaryResultsBcoState> = (state = initialState, action): ExportRequestSummaryResultsBcoState => {
    switch (action.type) {
        case ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_SUCCESS: {
            return {
                ...state,
                status: HttpRequestStatus.SUCCESS,
                exportRequestSummaryResults: action.payload,
                error: undefined,
            };
        }
        case ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as ExportRequestSummaryResultsBcoReducer };
