import { ScdImportedOperationReexport } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsReexportAllTypes {
    IMPORTED_OPERATIONS_REEXPORT_ALL_REQUEST = '@@imported-operations-reexport-all/IMPORTED_OPERATIONS_REEXPORT_ALL_REQUEST',
    IMPORTED_OPERATIONS_REEXPORT_ALL_SUCCESS = '@@imported-operations-reexport-all/IMPORTED_OPERATIONS_REEXPORT_ALL_SUCCESS',
    IMPORTED_OPERATIONS_REEXPORT_ALL_ERROR = '@@imported-operations-reexport-all/IMPORTED_OPERATIONS_REEXPORT_ALL_ERROR',
    IMPORTED_OPERATIONS_REEXPORT_ALL_RESET_STATE = '@@imported-operations-reexport-all/IMPORTED_OPERATIONS_REEXPORT_ALL_RESET_STATE',
}

export interface ImportedOperationsReexportAllState extends BaseStoreState {
    readonly reexportedOperations?: ScdImportedOperationReexport[];
}
