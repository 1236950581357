import { PaymentTransactionsByBatchErrorsList } from 'model/payment-transaction-by-batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreatePaymentTransactionsByBatchTypes {
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST = '@@payment-transactions-by-batch-create/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS = '@@payment-transactions-by-batch-create/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR = '@@payment-transactions-by-batch-create/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR',
    CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_RESET = '@@payment-transactions-by-batch-create/CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_RESET',
}

export interface CreatePaymentTransactionsByBatchState extends BaseStoreState {
    readonly paymentTransactionsByBatchErrors?: PaymentTransactionsByBatchErrorsList;
}
