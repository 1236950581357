import StandardButton from 'components/buttons/standard-button/standard-button';
import { ChunkStreamStatus, DataChunkStatus, ExportRequestStatus } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChunkStreamToResumeBco } from 'reducer-bco/hooks-bco';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.footer.components';

enum GTSecIntegrationSteps {
    UNDEFINED = 'UNDEFINED',
    PENDING_PARTITIONING = 'PENDING_PARTITIONING',
    SEND_CHUNKS = 'SEND_CHUNKS',
    SEND_CHUNKS_PROCESSING = 'SEND_CHUNKS_PROCESSING',
    SEND_CHUNKS_FAILURE = 'SEND_CHUNKS_FAILURE',
    FINALIZE_CESSION = 'FINALIZE_CESSION',
    FINALIZE_CESSION_FAILURE = 'FINALIZE_CESSION_FAILURE',
    CESSION_SUCCESSFUL = 'CESSION_SUCCESSFUL',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();
    const { chunkStreamToResume } = useChunkStreamToResumeBco();

    const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState<boolean>(false);
    const [isSendModalOpen, setIsSendModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<GTSecIntegrationSteps>(GTSecIntegrationSteps.UNDEFINED);

    const stream = chunkStreamToResume?.stream;
    const finalizeModalOpen = isFinalizeModalOpen && stream;
    const sendModalOpen = isSendModalOpen && stream;

    const isSuccessStream = stream?.status === ChunkStreamStatus.SUCCESS;
    const hasStreamError = stream?.status === ChunkStreamStatus.PARTIAL || stream?.status === ChunkStreamStatus.ERROR;
    const allChunksProcessed = stream?.chunks?.every(dataChunk => dataChunk.status === DataChunkStatus.SUCCESS);
    const hasAnyChunkFailed = stream?.chunks?.some(dataChunk =>
        dataChunk.exports.some(exportRequest => exportRequest.status === ExportRequestStatus.ERROR)
    );
    const hasAnyChunkProcessing = stream?.chunks?.some(dataChunk => dataChunk.status === DataChunkStatus.PROCESSING);
    const hasPartitionToSendChunk = chunkStreamToResume?.hasPartition;

    const determineFooterType = () => {
        if (isSuccessStream) return GTSecIntegrationSteps.CESSION_SUCCESSFUL;
        if (hasStreamError) return GTSecIntegrationSteps.FINALIZE_CESSION_FAILURE;
        if (allChunksProcessed) return GTSecIntegrationSteps.FINALIZE_CESSION;
        if (hasAnyChunkFailed) return GTSecIntegrationSteps.SEND_CHUNKS_FAILURE;
        if (hasAnyChunkProcessing) return GTSecIntegrationSteps.SEND_CHUNKS_PROCESSING;
        if (hasPartitionToSendChunk) return GTSecIntegrationSteps.SEND_CHUNKS;
        return GTSecIntegrationSteps.PENDING_PARTITIONING;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [isSuccessStream, hasStreamError, allChunksProcessed, hasAnyChunkFailed, hasAnyChunkProcessing, hasPartitionToSendChunk]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="cession-integration-footer">
            <div className="cession-integration-footer__button-box">{children}</div>
        </div>
    );

    const SendChunksFailureContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.error-message`)}</div>
    );

    const EmptyFooter: ReactNode = <></>;

    const CessionSuccessfulContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.success-message`)}</div>
    );
    const FinalizeCessionFailureContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.stream-partial-message`)}</div>
    );

    const FinalizeCessionContent: ReactNode = generalFooterContent(
        <>
            <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.finalize-message`)}</div>
            <StandardButton
                label={t(`${I18N_PREFIX}.finalize`)}
                onClick={() => setIsFinalizeModalOpen(true)}
                disabled={!allChunksProcessed}
            />
        </>
    );

    const SendChunksProcessingContent: ReactNode = generalFooterContent(
        <div className="cession-integration-footer__message-box">{t(`${I18N_PREFIX}.loading`)}</div>
    );

    const SendChunksContent: ReactNode = generalFooterContent(
        <StandardButton label={t(`${I18N_PREFIX}.send`)} onClick={() => setIsSendModalOpen(true)} disabled={hasAnyChunkProcessing} />
    );

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        PENDING_PARTITIONING: EmptyFooter,
        SEND_CHUNKS: SendChunksContent,
        SEND_CHUNKS_PROCESSING: SendChunksProcessingContent,
        SEND_CHUNKS_FAILURE: SendChunksFailureContent,
        FINALIZE_CESSION: FinalizeCessionContent,
        FINALIZE_CESSION_FAILURE: FinalizeCessionFailureContent,
        CESSION_SUCCESSFUL: CessionSuccessfulContent,
    } as Record<GTSecIntegrationSteps, ReactNode>;
    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, sendModalOpen, setIsSendModalOpen, finalizeModalOpen, setIsFinalizeModalOpen };
};
