import { AxiosResponse } from 'axios';
import { ChunkStreamPartitioningResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import { chunkStreamPartitioningBcoError, chunkStreamPartitioningBcoRequest, chunkStreamPartitioningBcoSuccess } from 'reducer-bco/batch-output/chunk-stream-partitioning/action';
import { ChunkStreamPartitioningBcoTypes } from 'reducer-bco/batch-output/chunk-stream-partitioning/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleChunkStreamPartitioningBco(action: ReturnType<typeof chunkStreamPartitioningBcoRequest>) {
    try {
        const result: AxiosResponse<ChunkStreamPartitioningResponse> = yield call(batchOutputBcoApi.chunkStreamPartitioning, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(chunkStreamPartitioningBcoError(mapped));
            return;
        }
        yield put(chunkStreamPartitioningBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(chunkStreamPartitioningBcoError(mapped));
    }
}

function* watchChunkStreamPartitioningBcoSaga() {
    yield takeEvery(ChunkStreamPartitioningBcoTypes.CHUNK_STREAM_PARTITIONING_BCO_REQUEST, handleChunkStreamPartitioningBco);
}

function* chunkStreamPartitioningBcoSaga() {
    yield all([fork(watchChunkStreamPartitioningBcoSaga)]);
}

export default chunkStreamPartitioningBcoSaga;
