import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBatchSearch } from 'model/batch';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchBatchesTypes {
    SEARCH_BATCHES_REQUEST = '@@batches-search/SEARCH_BATCHES_REQUEST',
    SEARCH_BATCHES_SUCCESS = '@@batches-search/SEARCH_BATCHES_SUCCESS',
    SEARCH_BATCHES_ERROR = '@@batches-search/SEARCH_BATCHES_ERROR',
    SEARCH_BATCHES_RESET = '@@batches-search/SEARCH_BATCHES_RESET',
}

export interface SearchBatchesState extends BaseStoreState {
    readonly batches?: Page<ScdBatchSearch>;
}
