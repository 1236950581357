import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import CompanyAccountsManager from 'entities/company/components/company-accounts-card/company-accounts-manager';
import CompanyOrganizationsManager from 'entities/company/components/company-organizations-card/company-organizations-manager';
import {
    validateCompanyIdentification,
    validateCompanyName,
    validateCompanyType,
    validateScdCompany,
} from 'entities/company/validations/company-validation';
import { ScdCompanyCreateUpdateRequest, defaultScdCompany } from 'model/company';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { companyDetailsRequest, companyDetailsResetState } from 'reducer/company/company-detail/actions';
import { createCompanyRequest, createCompanyResetState } from 'reducer/company/create-company/actions';
import { updateCompanyRequest, updateCompanyResetState } from 'reducer/company/update-company/actions';
import { useCreateCompanyState, useDetailCompanyState, useRootDispatch, useUpdateCompanyState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './company-create-update.scss';

export interface ScdCompanyUpdateProps extends RouteComponentProps<{ companyId: string }> {}

enum ScdCompanyEntity {
    BankAccounts = 'BANK_ACCOUNTS',
    Organizations = 'ORGANIZATIONS',
}

const I18N_PREFIX = 'pages.scd.company';

const ScdCompanyCreateUpdate = (props: ScdCompanyUpdateProps) => {
    const { t } = useTranslation();
    const { toastLoading, toastSuccess, closeToast } = useToast();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const [companyId] = useState<number | undefined>(props?.match?.params?.companyId ? Number(props?.match?.params?.companyId) : undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [scdCompany, setScdCompany] = useState<ScdCompanyCreateUpdateRequest>(defaultScdCompany);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [editionRecord, setEditionRecord] = useState<Record<ScdCompanyEntity, boolean>>({
        BANK_ACCOUNTS: false,
        ORGANIZATIONS: false,
    });

    const { status: createCompanyStatus } = useCreateCompanyState();
    const { status: detailCompanyStatus, companyDetails } = useDetailCompanyState();
    const { status: updateCompanyStatus } = useUpdateCompanyState();

    const handleSave = () => {
        setShowValidation(true);

        const _scdCompany: ScdCompanyCreateUpdateRequest = {
            ...scdCompany,
        };

        if (!validateScdCompany(_scdCompany)) return;

        if (companyId) {
            return dispatch(updateCompanyRequest(_scdCompany));
        }

        return dispatch(createCompanyRequest(_scdCompany));
    };

    const handleChange = (value: Partial<ScdCompanyCreateUpdateRequest>) => {
        setScdCompany(prev => ({ ...prev, ...value }));
    };

    useEffect(() => {
        if (!companyId) return;

        dispatch(companyDetailsRequest(companyId));
    }, [companyId, dispatch]);

    useEffect(() => {
        if (detailCompanyStatus === HttpRequestStatus.SUCCESS && companyDetails !== undefined) {
            setScdCompany(companyDetails);
        }
    }, [detailCompanyStatus, companyDetails]);

    useEffect(() => {
        if (createCompanyStatus !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [createCompanyStatus, toastLoading]);

    useEffect(() => {
        if (createCompanyStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.createSuccess`));
        history.push('/companies');
    }, [createCompanyStatus, closeToast, toastSuccess, history, t]);

    useEffect(() => {
        if (createCompanyStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [createCompanyStatus, closeToast]);

    useEffect(() => {
        if (updateCompanyStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [updateCompanyStatus, toastLoading]);

    useEffect(() => {
        if (updateCompanyStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.updateSuccess`));
        history.push('/companies');
    }, [updateCompanyStatus, closeToast, toastSuccess, history, t]);

    useEffect(() => {
        if (updateCompanyStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [updateCompanyStatus, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(createCompanyResetState());
            dispatch(companyDetailsResetState());
            dispatch(updateCompanyResetState());
        };
    }, [dispatch]);

    const isLoading: boolean = detailCompanyStatus === HttpRequestStatus.ON_GOING;

    return (
        <main className="scd-company-create-update__create">
            <ContextRibbon />
            <div className="scd-company-create-update--container">
                <header className="scd-company-create-update--header">
                    <h2 className="scd-company-create-update--header-title">
                        {companyId ? t(`${I18N_PREFIX}.update.title`) : t(`${I18N_PREFIX}.create.title`)}
                    </h2>
                </header>
                {isLoading && companyId ? (
                    <Loading />
                ) : (
                    <form className="scd-company-create-update--form">
                        <div className="scd-company-create-update--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                value={scdCompany?.name ?? ''}
                                onChange={name => handleChange({ name })}
                                validate={validateCompanyName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-company-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                                value={scdCompany?.type ?? ''}
                                validate={validateCompanyType}
                                mapperFromString={_value => (_value ? PersonType[_value] : undefined)}
                                showValidation={showValidation}
                                onChange={(type: string) => handleChange({ type: PersonType[type], identification: undefined })}
                                externalUpdate
                            >
                                <MenuItem value={PersonType.INDIVIDUAL}>{t(`${I18N_PREFIX}.input.type.options.INDIVIDUAL`)}</MenuItem>
                                <MenuItem value={PersonType.CORPORATION}>{t(`${I18N_PREFIX}.input.type.options.CORPORATION`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-company-create-update--form-field">
                            <ValidIdentificationInput
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.identification.placeholder`)}
                                value={scdCompany?.identification ?? ''}
                                onChange={identification => handleChange({ identification })}
                                validate={identification => validateCompanyIdentification(identification, scdCompany.type)}
                                showValidation={showValidation}
                                disabled={!scdCompany.type}
                                personType={scdCompany.type}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-company-create-update--form-field">
                            <SimpleTextInput
                                label={t(`${I18N_PREFIX}.input.tradeName.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.tradeName.placeholder`)}
                                value={scdCompany?.tradeName ?? ''}
                                onChange={tradeName => handleChange({ tradeName })}
                                externalUpdate
                            />
                        </div>

                        <div className="scd-company-create-update--form-card">
                            <CompanyAccountsManager
                                companyAccounts={companyId ? companyDetails?.accounts : scdCompany.accounts}
                                onUpdate={bankAccounts => handleChange({ accounts: bankAccounts })}
                                editionUpdate={_isEditing => setEditionRecord(prev => ({ ...prev, BANK_ACCOUNTS: _isEditing }))}
                            />
                        </div>

                        <div className="scd-company-create-update--form-card">
                            <CompanyOrganizationsManager
                                companyOrganizations={companyId ? companyDetails?.organizations : scdCompany.organizations}
                                onUpdate={organizations => handleChange({ organizations })}
                                editionUpdate={_isEditing => setEditionRecord(prev => ({ ...prev, ORGANIZATIONS: _isEditing }))}
                            />
                        </div>
                        {showValidation && (!scdCompany?.organizations || !scdCompany.organizations.length) && (
                            <span className="scd-company-create-update--form-card--error-message">
                                {t(`${I18N_PREFIX}.validate.organizations.required`)}
                            </span>
                        )}
                        <div className="scd-company-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy
                                label={t('entity.action.save')}
                                onClick={handleSave}
                                disabled={editionRecord.BANK_ACCOUNTS}
                            />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={companyId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/companies');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdCompanyCreateUpdate;
