import { ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { GiroWebappError } from 'model/error';
import { DefaultTechnicalSupplierTypes } from './types';
import { action } from 'typesafe-actions';

export const defaultTechnicalSupplierRequest = (technicalSupplierId: number) =>
    action(DefaultTechnicalSupplierTypes.DEFAULT_TECHNICAL_SUPPLIER_REQUEST, technicalSupplierId);
export const defaultTechnicalSupplierSuccess = (technicalSupplier: ScdTechnicalSupplierSimple) =>
    action(DefaultTechnicalSupplierTypes.DEFAULT_TECHNICAL_SUPPLIER_SUCCESS, technicalSupplier);
export const defaultTechnicalSupplierError = (error: GiroWebappError) =>
    action(DefaultTechnicalSupplierTypes.DEFAULT_TECHNICAL_SUPPLIER_ERROR, error);
export const defaultTechnicalSupplierResetState = () => action(DefaultTechnicalSupplierTypes.DEFAULT_TECHNICAL_SUPPLIER_RESET_STATE);
