import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { externalStatementPaymentInstitutionsAccountsRequest, externalStatementPaymentInstitutionsAccountsError, externalStatementPaymentInstitutionsAccountsSuccess } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/actions';
import { ExternalStatementPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import externalStrategyApi from 'services/api/external-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';


function* handleExternalStatementPaymentInstitutionsAccounts(action: ReturnType<typeof externalStatementPaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<ExternalStatementResponse> = yield call(
            externalStrategyApi.externalStatementPaymentInstitutionAccount,
            action.payload
        );

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(externalStatementPaymentInstitutionsAccountsError(mapped));
            yield put(markError(mapped))
            return;
        }
        yield put(externalStatementPaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped))
        yield put(externalStatementPaymentInstitutionsAccountsError(mapped));
    }
}

function* watchExternalStatementPaymentInstitutionsAccounts() {
    yield takeEvery(ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleExternalStatementPaymentInstitutionsAccounts);
}

function* externalStatementPaymentInstitutionsAccountsSaga() {
    yield all([fork(watchExternalStatementPaymentInstitutionsAccounts)]);
}

export default externalStatementPaymentInstitutionsAccountsSaga;
