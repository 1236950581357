import { ScdCommissionReport } from 'model/commission-report';
import { ScdDisbursementToReport } from 'model/disbursement-report';
import { BatchExportResult } from 'model/enums/batch-export-result';
import { BatchStatus } from 'model/enums/batch-status';
import { CnabTemplate } from 'model/enums/cnab-template';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramPaymentFavoredPolicy } from 'model/enums/program-payment-favored-policy';
import { ScdProgramPaymentModePolicy } from 'model/enums/program-payment-mode-policy-type';
import { ScdImportedExpirationToReport } from 'model/imported-expiration';
import { ScdImportedOperationToReport } from 'model/imported-operation';
import { ScdIssuer } from 'model/issuer';
import { ScdOrganizationSimple } from 'model/organization';
import { ScdOriginatorSimple } from 'model/originator';
import { ProgramToBatchDetail } from 'model/program';
import { ProgramOutputPoliciesToBatchDetail } from 'model/program-output-policy';

export interface ScdBatchSimple {
    id: number;
}
export interface ScdBatchData {
    id: number;
    date: Date;
    product: ProductType;
    code: string;
    nominalValue?: number;
    originatorName: string;
    originatorOrganization: string;
    numberOfOperations: number;
    status: BatchStatus;
    cnabTemplate: CnabTemplate;
    hasCnab: boolean;
    hasSessionInterestRate?: boolean;
    yearlySessionInterestRatePercentage?: number;
}
export interface ScdBatchDetail {
    id: number;
    status: BatchStatus;
    product: ProductType;
    code: string;
    date: Date;
    nominalValue?: number;
    originator: string;
    originatorName: string;
    originatorOrganization: string;
    numberOfOperations: number;
    hasCnab: boolean;
    cnabTemplate: CnabTemplate;
    paymentModePolicy: ScdProgramPaymentModePolicy;
    paymentFavoredPolicy: ScdProgramPaymentFavoredPolicy;
    numberOfPayments: number;
}

export interface BatchFilterRequest {
    product?: ProductType;
    originator?: {
        id?: string;
        name?: string;
        identification?: string;
    };
    status?: BatchStatus;
    todayOnly?: boolean;
}

export const defaultBatchFilterRequest: BatchFilterRequest = {
    todayOnly: true,
};

export interface ScdSearchPaymentImportBatch {
    id: number;
    code: string;
    originator: string;
}

// Lote
export interface ScdBatchOperationsReport {
    id?: number;
    code?: string;
    product?: ProductType;
    date?: Date;
    organization?: ScdOrganizationSimple;
    importedOperations: ScdImportedOperationToReport[];
    fareAmount?: number;
}

// Fatura
export interface ScdBatchExpirationsReport {
    id?: number;
    code?: string;
    product?: ProductType;
    date?: Date;
    organization?: ScdOrganizationSimple;
    importedExpirations: ScdImportedExpirationToReport[];
}

// Desembolso
export interface ScdBatchDisbursementReport {
    id?: number;
    code?: string;
    product?: ProductType;
    date?: Date;
    organization?: ScdOrganizationSimple;
    disbursements?: ScdDisbursementToReport[];
}

// Comissão diária
export interface ScdBatchCommissionReport {
    id?: number;
    code?: string;
    product?: ProductType;
    date?: Date;
    organization?: ScdOrganizationSimple;
    commissions?: ScdCommissionReport[];
}

export interface ScdBatchClose {
    id: number;
    code: string;
    status: BatchStatus;
}

export interface ScdBatchDeleteOperation {
    batchId: number;
    operationId: number;
}

export interface ScdBatchExport {
    id: number;
    code: string;
    status: BatchStatus;
}

export interface ScdBatchToCreateByOperations {
    importedOperationsIds: number[];
    programId: number;
}

export interface ScdBatchCreatedByOperations {
    id: number;
    code: string;
    status: BatchStatus;
    product: ProductType;
    originator: string;
}

export interface ScdBatchSearch {
    id: number;
    code: string;
}

export interface ScdBatchToRelocate {
    id: number;
    code: string;
    originatorName: string;
}

export interface ScdBatchToRelocateCreateNewRequest {
    id: number;
    operationId: number;
}

export interface ScdBatchToRelocateToExistingRequest {
    id: number;
    operationId: number;
    batchTargetId: number;
}

export interface ScdBatchToRelocateResponse {
    batchSourceId: number;
    batchTargetId: number;
    batchSourceCode: string;
    batchTargetCode: string;
    operationId: string;
    ccbNumber: string;
}

export interface ScdBatchToArchiveRequest {
    id: number;
    operationId: number;
}

export interface ScdBatchToArchiveResponse {
    batchId: number;
    operationId: number;
    ccbNumber: string;
}

export interface ScdBatchToBatchOutputHemera {
    id: number;
    code: string;
    status: BatchStatus;
}
export interface ScdBatchToGenerateCessionRequest {
    batchId?: number;
    cessionDate?: Date;
    paymentAmount?: number;
    cessionTaxPercentage?: number;
}

export interface ScdBatchToGenerateCessionResponse {
    id: number;
    cessionDate: Date;
    shippingNumber: string;
    numberOfInstallments: number;
    cessionTaxPercentage: number;
    cededAmount: number;
    cessionPresentAmount: number;
}

export interface BatchExportForSignatureOutput {
    id: number;
    result: BatchExportResult;
    exportDate: Date;
}

export const defaultCessionRequest: ScdBatchToGenerateCessionRequest = {
    batchId: undefined,
    cessionDate: undefined,
    paymentAmount: undefined,
    cessionTaxPercentage: undefined,
};

export interface BatchCloseDetails {
    batchId: number;
    countOperations: number;
    countOperationsWithPaymentError: number;
}

export interface BatchDetailWithProgram {
    id?: number;
    status?: BatchStatus;
    code?: string;
    date?: Date;
    product?: ProductType;
    program?: ProgramToBatchDetail;
    originator?: ScdOriginatorSimple;
    issuer?: ScdIssuer;
    outputPolicies?: ProgramOutputPoliciesToBatchDetail[];
}

export interface BatchOperations {
    countOperations: number;
    loanAmountOperations: number;
}

export interface BatchListOutputs {
    batchId: number;
    outputs: BatchOutput[];
}

export interface BatchOutput {
    id?: number;
    name?: string;
}
