import { GiroWebappError } from 'model/error';
import { ScdFavoredBankAccount, ScdFavoredBankAccountRequest } from 'model/favored-bank-account';
import { SearchFavoredBankAccountsTypes } from 'reducer/favored-bank-account/search-favored-bank-accounts/types';
import { action } from 'typesafe-actions';

export const searchFavoredBankAccountsRequest = (request: ScdFavoredBankAccountRequest) =>
    action(SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_REQUEST, request);
export const searchFavoredBankAccountsSuccess = (favoredBankAccounts: ScdFavoredBankAccount[]) =>
    action(SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_SUCCESS, favoredBankAccounts);
export const searchFavoredBankAccountsError = (error: GiroWebappError) =>
    action(SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_ERROR, error);
export const searchFavoredBankAccountsResetState = () => action(SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_RESET);
