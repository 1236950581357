import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { updateTechnicalSupplierError, updateTechnicalSupplierSuccess, updateTechnicalSupplierRequest } from './actions';
import { UpdateTechnicalSupplierTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import technicalSupplierApi from 'services/api/technical-supplier-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateTechnicalSupplier(action: ReturnType<typeof updateTechnicalSupplierRequest>) {
    try {
        const result: AxiosResponse<ScdTechnicalSupplierSimple> = yield call(technicalSupplierApi.updateTechnicalSupplier, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateTechnicalSupplierError(mapped));
            return;
        }
        yield put(updateTechnicalSupplierSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateTechnicalSupplierError(mapped));
    }
}

function* watchUpdateTechnicalSupplier() {
    yield takeLeading(UpdateTechnicalSupplierTypes.UPDATE_TECHNICAL_SUPPLIER_REQUEST, handleUpdateTechnicalSupplier);
}

function* updateTechnicalSupplierSaga() {
    yield all([fork(watchUpdateTechnicalSupplier)]);
}

export default updateTechnicalSupplierSaga;
