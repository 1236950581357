import { ScdPaymentTransactionByBatchToCreateList } from 'entities/payment-transaction/payment-transaction-in-series-by-batch/payment-transaction-in-series-by-batch';
import { PaymentValueType } from 'model/enums/payment-transaction-in-series-type';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.validate';

const PAYMENT_TRANSACTIONS_ACCOUNT_VALIDATION: Validations<ScdPaymentTransactionByBatchToCreateList | undefined | null> = {
    notFound: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.isNotEmpty(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.notFound`,
    },
    maxLength: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.maxSize(1)(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.maxLength`,
    },
};

const PAYMENT_TRANSACTIONS_ACCOUNT_TO_DEBTOR_VALIDATION: Validations<ScdPaymentTransactionByBatchToCreateList | undefined | null> = {
    notFoundToDebtor: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.isNotEmpty(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.notFoundToDebtor`,
    },
    maxLength: {
        predicate: it => {
            const isSelectedAccount = !!it?.accountId;
            return isSelectedAccount || ValidationUtils.ARRAYS.maxSize(1)(it?.accounts);
        },
        errorMessage: `${I18N_PREFIX}.account.maxLength`,
    },
};
export const validatePaymentTransactionsAccount = (value: ScdPaymentTransactionByBatchToCreateList | undefined | null) =>
    ValidationUtils.validate(
        value,
        value?.type === PaymentValueType.DISBURSEMENT_TO_DEBTOR
            ? PAYMENT_TRANSACTIONS_ACCOUNT_TO_DEBTOR_VALIDATION
            : PAYMENT_TRANSACTIONS_ACCOUNT_VALIDATION
    );
