import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateBankerTypes, UpdateBankerState } from './types';
import { Reducer } from 'redux';

export const initialState: UpdateBankerState = {
    status: HttpRequestStatus.NOOP,
    banker: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateBankerState> = (state = initialState, action): UpdateBankerState => {
    switch (action.type) {
        case UpdateBankerTypes.UPDATE_BANKER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateBankerTypes.UPDATE_BANKER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, banker: action.payload, error: undefined };
        }
        case UpdateBankerTypes.UPDATE_BANKER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateBankerTypes.UPDATE_BANKER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateBankerReducer };
