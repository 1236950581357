import { GiroWebappError } from 'model/error';
import { IssuerListPolicies } from 'model/issuer-policy';
import { IssuerPoliciesPageable } from 'model/reducers';
import { ListIssuerPoliciesTypes } from 'reducer/issuer-policy/list-issuer-policies/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listIssuerPoliciesRequest = (request: IssuerPoliciesPageable) => action(ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_REQUEST, request);
export const listIssuerPoliciesSuccess = (issuerPolicies: Page<IssuerListPolicies>) => action(ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_SUCCESS, issuerPolicies);
export const listIssuerPoliciesError = (error: GiroWebappError) => action(ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_ERROR, error);
export const listIssuerPoliciesResetState = () => action(ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_RESET_STATE);
