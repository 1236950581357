import { Icon, Tooltip } from '@material-ui/core';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks/use-toast';
import './copy-icon.scss';

type NumberFormatter = (value: number) => string | undefined;

interface NumberCopy {
    value: number;
    formatter: NumberFormatter;
}

interface StringCopy {
    value: string;
}

export type CopyIconProps = NumberCopy | StringCopy;

export const CopyIcon = (props: CopyIconProps) => {
    const { value } = props;

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const { toastError } = useToast();

    const handleTooltipVisibility = () => {
        setOpen(true);
        setTimeout(() => setOpen(false), 800);
    };

    const processValueToClipboard = (value: string): void => {
        navigator.clipboard
            .writeText(value)
            .then(() => handleTooltipVisibility())
            .catch(() => toastError(t('tooltips.copyFailed')));
    };

    const handleStringCopy = (value: string): void => {
        processValueToClipboard(value);
    };

    const handleNumberCopy = (value: number, formatter: NumberFormatter): void => {
        const formattedValue = formatter(value);

        if (!formattedValue) {
            toastError(t(`tooltips.copyFailed`));
            return;
        }

        processValueToClipboard(formattedValue);
    };

    const copyToClipboard = (): void => {
        if (typeof value === 'string') {
            handleStringCopy(value);
        } else {
            handleNumberCopy(value, (props as NumberCopy).formatter);
        }
    };

    return (
        <Tooltip title={t(`tooltips.copied`)} open={open}>
            <Icon onClick={copyToClipboard} className="copy-icon--icon" />
        </Tooltip>
    );
};
