import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ExternalStatementPaymentInstitutionsAccountsState, ExternalStatementPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/types';

const initialState: ExternalStatementPaymentInstitutionsAccountsState = {
    status: HttpRequestStatus.NOOP,
    externalStatement: undefined,
    error: undefined
};

const reducer: Reducer<ExternalStatementPaymentInstitutionsAccountsState> = (state = initialState, action): ExternalStatementPaymentInstitutionsAccountsState => {
    switch (action.type) {
        case ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, externalStatement: action.payload, error: undefined };
        }
        case ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as externalStatementPaymentInstitutionsAccountsReducer };
