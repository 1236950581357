import clsx from 'clsx';
import BackDrop from 'components/backdrop/backdrop';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ScdBatchEndorsementCounters from 'entities/batch/batch-endorsement/components/batch-endorsement-counters/batch-endorsement-counters';
import ScdBatchEndorsementDocumentList from 'entities/batch/batch-endorsement/components/batch-endorsement-document-list/batch-endorsement-document-list';
import ScdBatchEndorsementDocumentHeader from 'entities/batch/batch-endorsement/components/batch-endorsement-documents-header/batch-endorsement-documents-header';
import ScdBatchEndorsementHeader from 'entities/batch/batch-endorsement/components/batch-endorsement-header/batch-endorsement-header';
import ScdBatchEndorsementSlideButtons from 'entities/batch/batch-endorsement/components/batch-endorsement-slide-buttons/batch-endorsement-slide-buttons';
import {
    BatchSignatureDocumentsList,
    BatchSignatureImportIds,
    BatchSignatureImportRequest,
    BatchSignatureRequest,
} from 'model/batch-signature';
import { FileStatus } from 'model/enums/file-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { batchOutputEndorsementRequest, batchOutputEndorsementResetState } from 'reducer/batch/batch-output-endorsement/action';
import { batchSignatureImportRequest, batchSignatureImportResetState } from 'reducer/batch/batch-signature-import/action';
import { batchSignatureRequest, batchSignatureResetState } from 'reducer/batch/batch-signature/action';
import { detailBatchRequest, detailBatchResetState } from 'reducer/batch/detail-batch/actions';
import {
    useBatchOutputEndorsementState,
    useBatchSignatureImportState,
    useBatchSignatureState,
    useDetailBatchState,
    useRootDispatch,
} from 'reducer/hooks';

type BatchEndorsementImportsProps = RouteComponentProps<{ batchId: string }>;

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';
const sizeByScroll = 7;

const ScdBatchEndorsementImports = (props: BatchEndorsementImportsProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const [isOpenModalImport, setIsOpenModalImport] = useState<boolean>(false);
    const [documentsFiltered, setDocumentsFiltered] = useState<BatchSignatureDocumentsList[]>([]);
    const [isCheckedBox, setIsCheckedBox] = useState<boolean>(false);
    const [selectAllCheckBox, setSelectAllCheckBox] = useState<boolean>(false);

    const { batch } = useDetailBatchState();
    const { outputEndorsement, status: outputEndorsementStatus } = useBatchOutputEndorsementState();
    const { batchSignature, status: statusSignature } = useBatchSignatureState();
    const { status: importedStatus } = useBatchSignatureImportState();

    const size = batchSignature?.documents && batchSignature?.documents.length > sizeByScroll;

    const isAlreadyImported =
        batchSignature?.documents.filter(item => item.isAlreadyImported && item.fileStatus === FileStatus.UPLOADED).length ===
        batchSignature?.documents.length;

    const isNotUploaded =
        batchSignature?.documents.filter(item => !item.isAlreadyImported && item.fileStatus !== FileStatus.UPLOADED).length ===
        batchSignature?.documents.length;

    const isDisableCheckAll = isAlreadyImported || isNotUploaded;

    const notImportedListAndUploadedItems: BatchSignatureDocumentsList[] | undefined = batchSignature?.documents.filter(
        item => !item.isAlreadyImported && item.fileStatus === FileStatus.UPLOADED
    );

    const verifyAllSelectedOperations = useMemo((): boolean => {
        return notImportedListAndUploadedItems?.length === documentsFiltered.length;
    }, [documentsFiltered, notImportedListAndUploadedItems]);

    useEffect(() => {
        if (documentsFiltered.length === 0) {
            setSelectAllCheckBox(false);
            return;
        }
        if (verifyAllSelectedOperations) {
            setSelectAllCheckBox(true);
            return;
        }

        setSelectAllCheckBox(false);
    }, [verifyAllSelectedOperations, documentsFiltered]);

    useEffect(() => {
        dispatch(detailBatchRequest(batchId));
        dispatch(batchOutputEndorsementRequest(batchId));
    }, [dispatch, batchId]);

    const handleSignature = useCallback(() => {
        if (!batchId || !outputEndorsement?.id) return;
        const request: BatchSignatureRequest = {
            batchId: batchId,
            outputId: outputEndorsement?.id,
        };
        dispatch(batchSignatureRequest(request));
    }, [dispatch, batchId, outputEndorsement?.id]);

    useEffect(() => {
        handleSignature();
    }, [handleSignature]);

    const hasSelectedImport = useCallback(
        (document: BatchSignatureDocumentsList): boolean =>
            documentsFiltered.some(item => item.operationDocumentId === document.operationDocumentId),
        [documentsFiltered]
    );

    const handleSelectAll = useCallback(() => {
        if (!batchSignature?.documents) return;

        if (selectAllCheckBox) {
            setSelectAllCheckBox(false);
            setDocumentsFiltered([]);
            return;
        }

        setDocumentsFiltered(batchSignature?.documents.filter(item => !item.isAlreadyImported && item.fileStatus === FileStatus.UPLOADED));
        setSelectAllCheckBox(!selectAllCheckBox);
    }, [batchSignature?.documents, setSelectAllCheckBox, selectAllCheckBox]);

    const handleChangeImport = (document: BatchSignatureDocumentsList) => {
        if (hasSelectedImport(document)) {
            const removeDocument: BatchSignatureDocumentsList[] = documentsFiltered.filter(
                item => item.operationDocumentId !== document.operationDocumentId
            );

            setDocumentsFiltered(removeDocument);
            return;
        }
        setDocumentsFiltered([...documentsFiltered, document]);
    };

    const handleSelectedImports = useCallback(() => {
        if (!outputEndorsement?.id || !batchSignature?.documents) return;

        const documents: BatchSignatureImportIds[] = documentsFiltered.map(document => ({
            documentId: document.operationDocumentId,
            operationId: document.operationId,
        }));

        const request: BatchSignatureImportRequest = {
            batchId: batchId,
            outputId: outputEndorsement?.id,
            documents,
        };

        dispatch(batchSignatureImportRequest(request));
        setSelectAllCheckBox(false);
        setIsOpenModalImport(false);
        setDocumentsFiltered([]);
        setIsCheckedBox(false);
        history.goBack();
    }, [dispatch, batchId, outputEndorsement?.id, batchSignature?.documents, documentsFiltered, history]);

    const handleSwitch = (value: boolean) => {
        setIsCheckedBox(value);
        setDocumentsFiltered([]);
    };

    useEffect(() => {
        return () => {
            dispatch(detailBatchResetState());
            dispatch(batchOutputEndorsementResetState());
            dispatch(batchSignatureResetState());
            dispatch(batchSignatureImportResetState());
        };
    }, [dispatch]);

    const loading = statusSignature !== HttpRequestStatus.SUCCESS;
    const loadingComponent = outputEndorsementStatus !== HttpRequestStatus.SUCCESS;
    const isBackdropOpen: boolean = importedStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {<BackDrop open={isBackdropOpen} />}
            <main className="scd-batch-endorsement">
                <ContextRibbon />
                <section className="scd-batch-endorsement__container">
                    {loadingComponent ? (
                        <Loading />
                    ) : (
                        <>
                            <ScdBatchEndorsementHeader
                                batch={batch}
                                outputEndorsement={outputEndorsement}
                                isChecked={isCheckedBox}
                                handleSwitch={handleSwitch}
                                handleList={handleSignature}
                                listEndorsementOrImports={batchSignature?.documents}
                            />

                            <ScdBatchEndorsementCounters action="export" batch={batch} batchSignature={batchSignature} />

                            {loading ? (
                                <Loading />
                            ) : batchSignature?.documents ? (
                                <div className="scd-batch-endorsement__table">
                                    <table className="page-container--table">
                                        <thead
                                            className={clsx(`scd-batch-endorsement-header`, {
                                                size: size,
                                            })}
                                        >
                                            <ScdBatchEndorsementDocumentHeader
                                                isCheckedBox={isCheckedBox}
                                                selectAllCheckBox={selectAllCheckBox}
                                                handleSelectAll={handleSelectAll}
                                                isDisabled={isDisableCheckAll}
                                            />
                                        </thead>
                                        <tbody>
                                            <ScdBatchEndorsementDocumentList
                                                documents={batchSignature?.documents}
                                                isCheckedBox={isCheckedBox}
                                                handleChangeImport={handleChangeImport}
                                                selectAllCheckBox={selectAllCheckBox}
                                                hasSelectedImport={hasSelectedImport}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <div className="scd-batch-endorsement__error-message">
                                    <span>{t(`${I18N_PREFIX}.table.empty`)}</span>
                                </div>
                            )}
                            <ScdBatchEndorsementSlideButtons
                                action="import"
                                handleAction={() => setIsOpenModalImport(true)}
                                isDisableExport={!documentsFiltered.length}
                                documentsFiltered={documentsFiltered}
                                isChecked={isCheckedBox}
                                handleSwitch={handleSwitch}
                            />
                        </>
                    )}
                </section>
                {isOpenModalImport && (
                    <ModalMessageLegacy
                        title={t(`${I18N_PREFIX}.modals.import.title`)}
                        onClose={() => setIsOpenModalImport(false)}
                        onCancel={() => setIsOpenModalImport(false)}
                        onAction={handleSelectedImports}
                    />
                )}
            </main>
        </>
    );
};

export default ScdBatchEndorsementImports;
