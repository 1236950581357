import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramPolicy } from 'model/program-policy';
import {
    listProgramPoliciesError,
    listProgramPoliciesSuccess,
    listProgramPoliciesRequest,
} from 'reducer/program-policy/list-program-policies/actions';
import { ListProgramPoliciesTypes } from 'reducer/program-policy/list-program-policies/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programPolicyApi from 'services/api/program-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';

function* handleListProgramPolicies(action: ReturnType<typeof listProgramPoliciesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdProgramPolicy>> = yield call(programPolicyApi.listProgramPolicies, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listProgramPoliciesError(mapped));
            return;
        }
        yield put(listProgramPoliciesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listProgramPoliciesError(mapped));
    }
}

function* watchListProgramPolicies() {
    yield takeEvery(ListProgramPoliciesTypes.LIST_PROGRAM_POLICIES_REQUEST, handleListProgramPolicies);
}

function* listProgramPoliciesSaga() {
    yield all([fork(watchListProgramPolicies)]);
}

export default listProgramPoliciesSaga;
