import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';

import './description-icon-button.scss';

export enum DescriptionIconButtonType {
    ALTERNATIVE = 'ALTERNATIVE',
    DEFAULT = 'DEFAULT'
}
interface DescriptionIconButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    title?: string;
    iconType?: DescriptionIconButtonType;
}

const DescriptionIconButton = (props: DescriptionIconButtonProps) => {
    const { disabled, onClick, title, iconType } = props;

    const { t } = useTranslation();

    return (
        <button className="button__style--description-icon" disabled={disabled} onClick={onClick}>
                <Tooltip title={t(title ?? 'tooltips.description').toString()} aria-label="visualize">
                    <div className={clsx('button__style--description-icon--icon', {
                        ALTERNATIVE: iconType === DescriptionIconButtonType.ALTERNATIVE,
                        DEFAULT: iconType === DescriptionIconButtonType.DEFAULT
                    })} />
                </Tooltip>
        </button>
    );
};

export default DescriptionIconButton;
