import { ScdCompanyItem, ScdCompanyItemSimple } from 'model/company-item';
import { GiroWebappError } from 'model/error';
import { UpdateCompanyItemTypes } from 'reducer/company-item/update-company-item/types';
import { action } from 'typesafe-actions';

export const updateCompanyItemRequest = (companyItem: ScdCompanyItem) =>
    action(UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_REQUEST, companyItem);
export const updateCompanyItemSuccess = (updatedCompanyItem: ScdCompanyItemSimple) =>
    action(UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_SUCCESS, updatedCompanyItem);
export const updateCompanyItemError = (error: GiroWebappError) => action(UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_ERROR, error);
export const updateCompanyItemResetState = () => action(UpdateCompanyItemTypes.UPDATE_COMPANY_ITEM_RESET);
