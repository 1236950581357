import { UserLogin } from 'model/user';
import { BaseStoreState } from 'reducer/base-store-state';

export enum UserByResetKeyActionTypes {
    USER_BY_RESET_KEY_REQUEST = '@@userByResetKey/USER_BY_RESET_KEY_REQUEST',
    USER_BY_RESET_KEY_SUCCESS = '@@userByResetKey/USER_BY_RESET_KEY_SUCCESS',
    USER_BY_RESET_KEY_ERROR = '@@userByResetKey/USER_BY_RESET_KEY_ERROR',
    USER_BY_RESET_KEY_RESET_STATE = '@@userByResetKey/USER_BY_RESET_KEY_RESET_STATE',
}

export interface UserByResetKeyState extends BaseStoreState {
    readonly user?: UserLogin;
}
