import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { MonthlyCommissionDocumentFileInput } from 'entities/monthly-commission/monthly-commission-detail/components/inputs/monthly-commission-document-file-input/monthly-commission-document-file-input';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionDocumentType } from 'model/enums/monthly-commission-document';
import { MonthlyCommissionStatus } from 'model/enums/monthly-commission-status';
import { PersonType } from 'model/enums/person-type';
import {
    ScdMonthlyCommissionDeleteDocumentRequest,
    ScdMonthlyCommissionDetail,
    ScdMonthlyCommissionDownloadDocumentRequest,
    ScdMonthlyCommissionUploadDocumentRequest,
} from 'model/monthly-commission';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMonthlyCommissionDocumentState, useRootDispatch } from 'reducer/hooks';
import {
    monthlyCommissionDeleteDocumentRequest,
    monthlyCommissionDownloadDocumentRequest,
    monthlyCommissionUploadDocumentRequest,
} from 'reducer/monthly-commission/document/actions';
import './monthly-commission-originator-card.scss';

const I18N_PREFIX = 'pages.scd.monthly-commission-detail.card.originator';

const fallbackValue = '-'.repeat(7);

interface MonthlyCommissionOriginatorCardProps {
    monthlyCommission: ScdMonthlyCommissionDetail | undefined;
}

export const MonthlyCommissionOriginatorCard = (props: MonthlyCommissionOriginatorCardProps) => {
    const { monthlyCommission } = props;
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { status: documentStatus } = useMonthlyCommissionDocumentState();

    const isDisabledFileInput: boolean = useMemo(() => {
        return (
            monthlyCommission?.status === MonthlyCommissionStatus.NEW ||
            monthlyCommission?.status === MonthlyCommissionStatus.TRANSFERRING ||
            documentStatus === HttpRequestStatus.ON_GOING
        );
    }, [documentStatus, monthlyCommission]);

    const handleUploadDocument = (request: ScdMonthlyCommissionUploadDocumentRequest) => {
        dispatch(monthlyCommissionUploadDocumentRequest(request));
    };

    const handleDownloadDocument = (request: ScdMonthlyCommissionDownloadDocumentRequest) => {
        dispatch(monthlyCommissionDownloadDocumentRequest(request));
    };

    const handleDeleteDocument = (request: ScdMonthlyCommissionDeleteDocumentRequest) => {
        dispatch(monthlyCommissionDeleteDocumentRequest(request));
    };

    return (
        <div className="monthly-commission-originator-card">
            <h2 className="monthly-commission-originator-card--title">{t(`${I18N_PREFIX}.title`)}</h2>
            <div className="monthly-commission-originator-card--item">
                <span className="monthly-commission-originator-card--item__label"> {t(`${I18N_PREFIX}.identification`)} </span>
                <span className="monthly-commission-originator-card--item__value">
                    {monthlyCommission?.companyIdentification ? (
                        <IdentificationFormatter value={monthlyCommission.companyIdentification} type={PersonType.CORPORATION} />
                    ) : (
                        fallbackValue
                    )}
                </span>
            </div>
            <div className="monthly-commission-originator-card--item">
                <span className="monthly-commission-originator-card--item__label"> {t(`${I18N_PREFIX}.company`)} </span>
                <span className="monthly-commission-originator-card--item__value">{monthlyCommission?.companyName ?? fallbackValue}</span>
            </div>
            <div style={{ display: 'flex' }}>
                <div className="monthly-commission-originator-card--item">
                    <span className="monthly-commission-originator-card--item__label"> {t(`${I18N_PREFIX}.invoice`)} </span>
                    <span className="monthly-commission-originator-card--item__value">
                        <MonthlyCommissionDocumentFileInput
                            label={t(`${I18N_PREFIX}.upload-file`)}
                            documentFile={monthlyCommission?.companyInvoice}
                            documentType={MonthlyCommissionDocumentType.COMPANY_INVOICE}
                            monthlyCommissionId={monthlyCommission?.id}
                            monthlyCommissionStatus={monthlyCommission?.status}
                            onUpload={handleUploadDocument}
                            onDownload={handleDownloadDocument}
                            onDelete={handleDeleteDocument}
                            disabled={isDisabledFileInput}
                        />
                    </span>
                </div>
                <div className="monthly-commission-originator-card--item">
                    <span className="monthly-commission-originator-card--item__label"> {t(`${I18N_PREFIX}.receipt`)} </span>
                    <span className="monthly-commission-originator-card--item__value">
                        <MonthlyCommissionDocumentFileInput
                            label={t(`${I18N_PREFIX}.upload-file`)}
                            documentFile={monthlyCommission?.companyReceipt}
                            documentType={MonthlyCommissionDocumentType.COMPANY_RECEIPT}
                            monthlyCommissionId={monthlyCommission?.id}
                            monthlyCommissionStatus={monthlyCommission?.status}
                            onUpload={handleUploadDocument}
                            onDownload={handleDownloadDocument}
                            onDelete={handleDeleteDocument}
                            disabled={isDisabledFileInput}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default MonthlyCommissionOriginatorCard;
