import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { listIssuersError, listIssuersSuccess, listIssuersRequest } from 'reducer/issuer/list-issuers/actions';
import { ListIssuersTypes } from 'reducer/issuer/list-issuers/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import issuerApi from 'services/api/issuer-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { ScdIssuerList } from 'model/issuer';

function* handleListIssuers(action: ReturnType<typeof listIssuersRequest>) {
    try {
        const result: AxiosResponse<Page<ScdIssuerList>> = yield call(issuerApi.listIssuers, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listIssuersError(mapped));
            return;
        }
        yield put(listIssuersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listIssuersError(mapped));
    }
}

function* watchListIssuers() {
    yield takeEvery(ListIssuersTypes.LIST_ISSUERS_REQUEST, handleListIssuers);
}

function* listIssuersSaga() {
    yield all([fork(watchListIssuers)]);
}

export default listIssuersSaga;
