import { Box, Grid } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { ScdCompany } from 'model/company';
import { CompanyGroupFilterRequest } from 'model/company-group';
import { ScdOrganization } from 'model/organization';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchCompaniesRequest } from 'reducer/company/search-companies/actions';
import { searchOrganizationsRequest } from 'reducer/organization/search-organizations/actions';
import { valuesOfObject } from 'shared/util/object-utils';
import './company-group-modal-filter.scss';

export type CompanyGroupModalFilterType = 'filters' | 'groupName' | 'organizationId' | 'companyId';

interface CompanyGroupModalFilterProps {
    onClose: () => void;
    modalFilter: CompanyGroupModalFilterType;
    isOpenModalFilter: boolean;
    title: string;
    filterActive: CompanyGroupFilterRequest;
    setFilterActive: (value: CompanyGroupFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'pages.scd.company-group.filter.inputs';

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD: Record<CompanyGroupModalFilterType, (filter: CompanyGroupFilterRequest) => boolean> = {
    filters: filter => valuesOfObject({ ...filter }).filter(it => !isNil(it)).length === 0,
    groupName: filter => filter.groupName === undefined,
    organizationId: filter => filter.organizationId === undefined,
    companyId: filter => filter.companyId === undefined,
};

const CompanyGroupModalFilter = (props: CompanyGroupModalFilterProps) => {
    const { t } = useTranslation();

    const { filterActive, modalFilter, onClose, isOpenModalFilter, setFilterActive, setPage, title, resetInfiniteScrollListing } = props;

    const [filterRequest, setFilterRequest] = useState<CompanyGroupFilterRequest>(filterActive);

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    const checkResetList = useCallback(() => {
        if (isEqual(filterActive, filterRequest)) return;

        resetInfiniteScrollListing();
    }, [filterActive, filterRequest, resetInfiniteScrollListing]);

    if (!isOpenModalFilter) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        checkResetList();
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<CompanyGroupFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter: Record<CompanyGroupModalFilterType, () => void> = {
        groupName: () => {
            setFilterActive({ ...filterActive, groupName: undefined });
            setFilterRequest({ ...filterRequest, groupName: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        organizationId: () => {
            setFilterActive({ ...filterActive, organizationId: undefined, organizationName: undefined });
            setFilterRequest({ ...filterRequest, organizationId: undefined, organizationName: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        companyId: () => {
            setFilterActive({ ...filterActive, companyId: undefined, companyName: undefined });
            setFilterRequest({ ...filterRequest, companyId: undefined, companyName: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
            setPage(0);
            resetInfiniteScrollListing();
        },
    };

    const GroupNameFilter: JSX.Element = (
        <Box className="company-group-modal-filter--container-input">
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.groupName.label`)}
                    placeholder={t(`${I18N_PREFIX}.groupName.placeholder`)}
                    onChange={groupName => handleChange({ groupName: groupName })}
                    value={filterRequest.groupName}
                    externalUpdate
                />
            </Grid>
        </Box>
    );

    const OrganizationIdFilter: JSX.Element = (
        <Box className="company-group-modal-filter--container-input">
            <Grid item xs={12}>
                <ListSearchInput<ScdOrganization>
                    action={searchOrganizationsRequest}
                    itemSize={SEARCH_ITEM_SIZE_LARGE}
                    onSelect={organization => {
                        handleChange({ organizationId: organization.id, organizationName: organization.name });
                    }}
                    renderItem={organization => `${organization.id} ${organization?.name}`}
                    statusSelector={(state: IRootState) => state.searchOrganizations.status}
                    dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                    label={t(`${I18N_PREFIX}.organization.label`)}
                    placeholder={t(`${I18N_PREFIX}.organization.placeholder`)}
                    value={filterRequest?.organizationId ? `${filterRequest?.organizationId} - ${filterRequest.organizationName}` : ''}
                />
            </Grid>
        </Box>
    );

    const CompanyIdFilter: JSX.Element = (
        <Box className="company-group-modal-filter--container-input">
            <Grid item xs={12}>
                <ListSearchInput<ScdCompany>
                    action={searchCompaniesRequest}
                    itemSize={SEARCH_ITEM_SIZE_LARGE}
                    onSelect={company => {
                        handleChange({ companyId: company.id, companyName: company.name });
                    }}
                    renderItem={company => `${company.id} ${company?.name}`}
                    statusSelector={(state: IRootState) => state.searchCompanies.status}
                    dataSelector={(state: IRootState) => state.searchCompanies.companies}
                    label={t(`${I18N_PREFIX}.company.label`)}
                    placeholder={t(`${I18N_PREFIX}.company.placeholder`)}
                    value={filterRequest?.companyId ? `${filterRequest?.companyId} - ${filterRequest.companyName}` : ''}
                />
            </Grid>
        </Box>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        isOpenModalFilter && (
            <Modal isOpen>
                <div className="company-group-modal-filter">
                    <div className="company-group-modal-filter--header">
                        <label className="company-group-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {GroupNameFilter}
                                            {OrganizationIdFilter}
                                            {CompanyIdFilter}
                                        </>
                                    ),
                                    groupName: GroupNameFilter,
                                    organizationId: OrganizationIdFilter,
                                    companyId: CompanyIdFilter,
                                } as Record<CompanyGroupModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="company-group-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default CompanyGroupModalFilter;
