import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateInternalRepaymentPaymentTransactionState, CreateInternalRepaymentPaymentTransactionTypes } from 'reducer/payment-transaction/create-internal-repayment-payment-transaction/types';

const initialState: CreateInternalRepaymentPaymentTransactionState = {
    status: HttpRequestStatus.NOOP,
    paymentTransaction: undefined,
    error: undefined,
};

const reducer: Reducer<CreateInternalRepaymentPaymentTransactionState> = (state = initialState, action): CreateInternalRepaymentPaymentTransactionState => {
    switch (action.type) {
        case CreateInternalRepaymentPaymentTransactionTypes.CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreateInternalRepaymentPaymentTransactionTypes.CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransaction: action.payload, error: undefined };
        }
        case CreateInternalRepaymentPaymentTransactionTypes.CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateInternalRepaymentPaymentTransactionTypes.CREATE_INTERNAL_REPAYMENT_PAYMENT_TRANSACTION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createInternalRepaymentPaymentTransactionReducer };
