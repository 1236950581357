import clsx from 'clsx';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';

type BaseInput = Omit<BaseTextInputProps, 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;

export type ValidTextNumberInputProps = BaseInput & BaseValidation;

const INVALID_IDENTIFIER_CARATERS_REGEX = new RegExp(/[^0-9_.-]/g);

export const ValidTextNumberInput = (props: ValidTextNumberInputProps) => {
    const { validate, showValidation, type, onChange, ...otherProps } = props;

    const handleChange = (value: string) => {
        const _value = value?.toLowerCase().replace(INVALID_IDENTIFIER_CARATERS_REGEX, '');

        if (!onChange) return;

        onChange(_value);
    };

    return (
        <ValidInput
            value={props.value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('base-input', { dirty: props.value })}
        >
            {({ error }) => (
                <BaseTextInput {...otherProps} onChange={handleChange} error={error} type={type ?? 'text'} hasExternalSanitization />
            )}
        </ValidInput>
    );
};

export default ValidTextNumberInput;
