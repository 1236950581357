import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupSimple } from 'model/company-group';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListCompanyGroupsTypes {
    LIST_COMPANY_GROUPS_REQUEST = '@@company-groups-list/LIST_COMPANY_GROUPS_REQUEST',
    LIST_COMPANY_GROUPS_SUCCESS = '@@company-groups-list/LIST_COMPANY_GROUPS_SUCCESS',
    LIST_COMPANY_GROUPS_ERROR = '@@company-groups-list/LIST_COMPANY_GROUPS_ERROR',
    LIST_COMPANY_GROUPS_RESET = '@@company-groups-list/LIST_COMPANY_GROUPS_RESET',
}

export interface ListCompanyGroupsState extends BaseStoreState {
    readonly companyGroups?: Page<ScdCompanyGroupSimple>;
}
