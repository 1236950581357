import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdOriginatorSearch } from 'model/originator';
import originatorAPI from 'services/api/originator-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchOriginatorsTypes } from 'reducer/originator/search-originators/types';
import { searchOriginatorsError, searchOriginatorsSuccess, searchOriginatorsRequest } from 'reducer/originator/search-originators/actions';
import { Page } from 'services/page';

function* handleSearchOriginators(action: ReturnType<typeof searchOriginatorsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdOriginatorSearch>> = yield call(originatorAPI.searchOriginators, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchOriginatorsError(mapped));
            return;
        }
        yield put(searchOriginatorsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchOriginatorsError(mapped));
    }
}

function* watchSearchOriginators() {
    yield takeEvery(SearchOriginatorsTypes.SEARCH_ORIGINATORS_REQUEST, handleSearchOriginators);
}

function* searchOriginatorsSaga() {
    yield all([fork(watchSearchOriginators)]);
}

export default searchOriginatorsSaga;
