import clsx from 'clsx';
import { Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

interface ScdBatchEndorsementListHeaderProps {
    size?: boolean | number;
    isChecked?: boolean;
    selectAllCheckBox: boolean;
    isDisableSelectAllCheckBox: boolean;
    handleSelectAllSignatures: () => void;
}

const ScdBatchEndorsementListHeader = (props: ScdBatchEndorsementListHeaderProps) => {
    const { size, isChecked, selectAllCheckBox, handleSelectAllSignatures, isDisableSelectAllCheckBox } = props;
    const { t } = useTranslation();

    return (
        <thead>
            <tr
                className={clsx(`scd-batch-endorsement__collapse-header--fixed`, {
                    size: size,
                })}
            >
                <th></th>
                <th>{t(`${I18N_PREFIX}.endorsement-list.table.operation`)}</th>
                <th>{t(`${I18N_PREFIX}.endorsement-list.table.receivedDate`)}</th>
                <th>{t(`${I18N_PREFIX}.endorsement-list.table.status`)}</th>
                <th>
                    <span>
                        {isChecked && (
                            <Checkbox
                                checked={selectAllCheckBox}
                                onChange={handleSelectAllSignatures}
                                disabled={isDisableSelectAllCheckBox}
                            />
                        )}
                    </span>
                </th>
            </tr>
        </thead>
    );
};

export default ScdBatchEndorsementListHeader;
