import { ScdMonthlyCommissionDetail } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailMonthlyCommissionTypes {
    DETAIL_MONTHLY_COMMISSION_REQUEST = '@@monthly-commission-detail/DETAIL_MONTHLY_COMMISSION_REQUEST',
    DETAIL_MONTHLY_COMMISSION_SUCCESS = '@@monthly-commission-detail/DETAIL_MONTHLY_COMMISSION_SUCCESS',
    DETAIL_MONTHLY_COMMISSION_ERROR = '@@monthly-commission-detail/DETAIL_MONTHLY_COMMISSION_ERROR',
    DETAIL_MONTHLY_COMMISSION_RESET = '@@monthly-commission-detail/DETAIL_MONTHLY_COMMISSION_RESET',
}

export interface DetailMonthlyCommissionState extends BaseStoreState {
    readonly monthlyCommission?: ScdMonthlyCommissionDetail;
}
