import { ExternalBalanceStateModel } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';

export type ApiResponse = Record<string, any>;

export enum ExternalBalancePaymentInstitutionsAccountsTypes {
    EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-balance/EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-balance/EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-balance/EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-balance/EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
    EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET_BY_ID = '@@payment-institutions-accounts-balance/EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET_BY_ID',
}

export interface ExternalBalancePaymentInstitutionsAccountsState {
    readonly externalBalances: {
        [id: number]: ExternalBalanceStateModel
    };
}
