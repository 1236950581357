import StandardButton from 'components/buttons/standard-button/standard-button';
import SendPartitionModal from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/without-partition/send-partition-modal/send-partition-modal';
import { BatchOutputBcoRequest } from 'model/bco/batch-output';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { chunkStreamPartitioningBcoResetState } from 'reducer-bco/batch-output/chunk-stream-partitioning/action';
import { chunkStreamToResumeBcoRequest } from 'reducer-bco/batch-output/chunk-stream-to-resume/action';
import { useChunkStreamPartitioningBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './without-partition.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.without-partition';

interface WithoutPartitionProps {}

const WithoutPartition: React.FC<WithoutPartitionProps> = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();
    const { status: chunkStreamPartitioningStatus, chunkStreamPartitioningBco } = useChunkStreamPartitioningBco();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const dispatch = useRootDispatch();

    const handleChunkStreamToResume = () => {
        const request: BatchOutputBcoRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(chunkStreamToResumeBcoRequest(request));
    };

    useEffect(() => {
        if (chunkStreamPartitioningStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (chunkStreamPartitioningStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(chunkStreamPartitioningBcoResetState());
            toastError(t(`${I18N_PREFIX}.toast.error`));
        }
        if (chunkStreamPartitioningStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(chunkStreamPartitioningBcoResetState());
            handleChunkStreamToResume();
            toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        }
    }, [chunkStreamPartitioningStatus, toastLoading, closeToast, chunkStreamPartitioningBco, toastSuccess, t, dispatch]);

    return (
        <div className="without-partition">
            <div className="without-partition__title">{t(`${I18N_PREFIX}.missing-partition`)}</div>
            <StandardButton label={t(`${I18N_PREFIX}.execute-partition`)} onClick={() => setIsModalOpen(true)} />
            {isModalOpen && <SendPartitionModal setIsModalOpen={setIsModalOpen} />}
        </div>
    );
};

export default WithoutPartition;
