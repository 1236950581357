import alternativeLogoWhite from 'images/alternative-logo-white.svg';
import { useHistory } from 'react-router-dom';
import OpenedMenuItem from 'shared/header/sidebar/componentes/opened-menu-item/opened-menu-item';
import { accountData } from 'shared/header/sidebar/data/account';
import { EntitiesData } from 'shared/header/sidebar/data/entities';
import './opened-drawer.scss';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import openLeft from 'images/open-left.svg';

interface OpenedDrawerProps {
    openedDrawer?: boolean;
    setSidebarIsOpen: (value: boolean) => void;
}

export const OpenedDrawer: FC<OpenedDrawerProps> = ({ openedDrawer, setSidebarIsOpen }) => {
    const history = useHistory();
    const { t } = useTranslation();


    return openedDrawer ? (
        <div className="opened-drawer">
            <div className="opened-drawer--header">
                <div className="opened-drawer--header--logo_box">
                    <button className="opened-drawer--header--close-button" onClick={() => setSidebarIsOpen(false)}>
                        <img src={openLeft} alt="close" />
                    </button>
                    <button className="opened-drawer--header--logo_box--logo" onClick={() => history.push('/home')}>
                        <img src={alternativeLogoWhite} alt="logo" />
                    </button>
                </div>
            </div>
            <div className="opened-drawer--items_box">
                <OpenedMenuItem menuList={EntitiesData} />
            </div>
            <div className="opened-drawer--footer">
                <button className="opened-drawer--footer--button" onClick={accountData().action}>
                    <img src={accountData().iconSource} alt={accountData().iconSource} />
                    <span> {t(accountData().title)}</span>
                </button>
            </div>
        </div>
    ) : null;
};
export default OpenedDrawer;
