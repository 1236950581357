import { Box, Grid, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import isNil from 'lodash/isNil';
import { Authority } from 'model/enums/authority';
import { UserActivated } from 'model/enums/user-activated';
import { UserFilterRequest } from 'model/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './user-modal-filter.scss';

export type UserModalFilterType = 'filters' | 'login' | 'name' | 'activated' | 'authority';

interface UserModalFilterProps {
    onClose: () => void;
    modalFilter: UserModalFilterType;
    isOpenModalFilter: boolean;
    title: string;
    filterActive: UserFilterRequest;
    setFilterActive: (value: UserFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'pages.scd.users.filter.inputs';

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD: Record<UserModalFilterType, (filter: UserFilterRequest) => boolean> = {
    login: filter => filter.login === undefined,
    name: filter => filter.firstName === undefined,
    activated: filter => filter.activated === undefined,
    authority: filter => filter.authority === undefined,
    filters: filter => valuesOfObject({ ...filter }).filter(it => !isNil(it)).length === 0,
};

const UserModalFilter = (props: UserModalFilterProps) => {
    const { t } = useTranslation();

    const { filterActive, modalFilter, onClose, isOpenModalFilter, setFilterActive, setPage, title, resetInfiniteScrollListing } = props;

    const [filterRequest, setFilterRequest] = useState<UserFilterRequest>(filterActive);

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!isOpenModalFilter) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<UserFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter: Record<UserModalFilterType, () => void> = {
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
            setPage(0);
            resetInfiniteScrollListing();
        },
        login: () => {
            setFilterRequest({ ...filterRequest, login: undefined });
            setFilterActive({ ...filterActive, login: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        name: () => {
            setFilterRequest({ ...filterRequest, firstName: undefined });
            setFilterActive({ ...filterActive, firstName: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        activated: () => {
            setFilterRequest({ ...filterRequest, activated: undefined });
            setFilterActive({ ...filterActive, activated: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
        authority: () => {
            setFilterRequest({ ...filterRequest, authority: undefined });
            setFilterActive({ ...filterActive, authority: undefined });
            setPage(0);
            resetInfiniteScrollListing();
        },
    };

    const LoginFilter: JSX.Element = (
        <Box className="user-modal-filter--container-input">
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.login.label`)}
                    placeholder={t(`${I18N_PREFIX}.login.placeholder`)}
                    onChange={login => handleChange({ login: login })}
                    value={filterRequest.login}
                    externalUpdate
                />
            </Grid>
        </Box>
    );

    const FirstNameFilter: JSX.Element = (
        <Box className="user-modal-filter--container-input">
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.name.label`)}
                    placeholder={t(`${I18N_PREFIX}.name.placeholder`)}
                    onChange={firstName => handleChange({ firstName: firstName })}
                    value={filterRequest.firstName}
                    externalUpdate
                />
            </Grid>
        </Box>
    );

    const ActivatedFilter: JSX.Element = (
        <Box className="user-modal-filter--container-input">
            <div
                className={clsx('user-modal-filter--content-status--label', {
                    ACTIVE: filterRequest.activated === true ?? UserActivated.ACTIVE,
                })}
                onClick={() => handleChange({ activated: true })}
            >
                {t(`${I18N_PREFIX}.activated.options.ACTIVE`)}
            </div>
            <div
                className={clsx('user-modal-filter--content-status--label', {
                    INACTIVE: filterRequest.activated === false ?? UserActivated.INACTIVE,
                })}
                onClick={() => handleChange({ activated: false })}
            >
                {t(`${I18N_PREFIX}.activated.options.INACTIVE`)}
            </div>
        </Box>
    );

    const AuthorityFilter: JSX.Element = (
        <Box className="user-modal-filter--container-input">
            <Grid item xs={12}>
                <SimpleSelectInput
                    label={t(`${I18N_PREFIX}.authority.label`)}
                    value={filterRequest?.authority ?? ''}
                    mapperFromString={authority => (authority ? Authority[authority] : undefined)}
                    placeholder={t(`${I18N_PREFIX}.authority.placeholder`)}
                    onChange={authority => handleChange({ authority: Authority[authority] })}
                    externalUpdate
                >
                    <MenuItem value={Authority.ROLE_USER}>{t(`${I18N_PREFIX}.authority.options.${Authority.ROLE_USER}`)}</MenuItem>
                    <MenuItem value={Authority.ROLE_ADMIN}>{t(`${I18N_PREFIX}.authority.options.${Authority.ROLE_ADMIN}`)}</MenuItem>
                    <MenuItem value={Authority.ROLE_BANKER}>{t(`${I18N_PREFIX}.authority.options.${Authority.ROLE_BANKER}`)}</MenuItem>
                    <MenuItem value={Authority.ROLE_BANKER_APPROVAL}>
                        {t(`${I18N_PREFIX}.authority.options.${Authority.ROLE_BANKER_APPROVAL}`)}
                    </MenuItem>
                </SimpleSelectInput>
            </Grid>
        </Box>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        isOpenModalFilter && (
            <Modal isOpen>
                <div className="user-modal-filter">
                    <div className="user-modal-filter--header">
                        <label className="user-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {LoginFilter}
                                            {FirstNameFilter}
                                            {AuthorityFilter}
                                            {ActivatedFilter}
                                        </>
                                    ),
                                    login: LoginFilter,
                                    name: FirstNameFilter,
                                    authority: AuthorityFilter,
                                    activated: ActivatedFilter,
                                } as Record<UserModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="user-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default UserModalFilter;
