import { ScdBatchCommissionReport } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchCommissionReportTypes {
    BATCH_COMMISSION_REPORT_REQUEST = '@@batch-commission-report/BATCH_COMMISSION_REPORT_REQUEST',
    BATCH_COMMISSION_REPORT_SUCCESS = '@@batch-commission-report/BATCH_COMMISSION_REPORT_SUCCESS',
    BATCH_COMMISSION_REPORT_ERROR = '@@batch-commission-report/BATCH_COMMISSION_REPORT_ERROR',
    BATCH_COMMISSION_REPORT_RESET = '@@batch-commission-report/BATCH_COMMISSION_REPORT_RESET',
}

export interface BatchCommissionReportState extends BaseStoreState {
    readonly batchCommissionReport?: ScdBatchCommissionReport;
}
