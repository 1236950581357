import Loading from 'components/loading/loading';
import BankersRow, { BankersRowProps } from 'entities/banker/components/bankers-row/bankers-row';
import { ScdBankerList } from 'model/banker';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface BankersListProps {
    bankers: ScdBankerList[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.banker.table';

const BankersList = (props: BankersListProps) => {
    const { bankers, isLoading } = props;

    const { t } = useTranslation();

    const bankersRowObjectData: BankersRowProps = {
        bankers,
    };

    return (
        <>
            {isLoading && !bankers.length && <Loading isTable />}
            {bankers.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={bankersRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={bankers.length}
                            className="page-container--table-container"
                        >
                            {BankersRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-banker__table-empty-filter">
                            <span className="scd-banker__table-empty-filter-image" />
                            <span className="scd-banker__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BankersList;
