import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';
import {
    concludeCessionBcoError,
    concludeCessionBcoRequest,
    concludeCessionBcoSuccess,
} from 'reducer-bco/batch-output/conclude-cession/action';
import { CessionToSimpleDTO } from 'model/bco/batch-output-cession-integration-webcred';
import { ConcludeCessionBcoTypes } from 'reducer-bco/batch-output/conclude-cession/types';

function* handleConcludeCessionBco(action: ReturnType<typeof concludeCessionBcoRequest>) {
    try {
        const result: AxiosResponse<CessionToSimpleDTO> = yield call(batchOutputBcoApi.concludeCession, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(concludeCessionBcoError(mapped));
            return;
        }
        yield put(concludeCessionBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(concludeCessionBcoError(mapped));
    }
}

function* watchConcludeCessionBcoSaga() {
    yield takeEvery(ConcludeCessionBcoTypes.CONCLUDE_CESSION_BCO_REQUEST, handleConcludeCessionBco);
}

function* concludeCessionBcoSaga() {
    yield all([fork(watchConcludeCessionBcoSaga)]);
}

export default concludeCessionBcoSaga;
