import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ValidCurrencyInput from 'components/inputs/currency-input/valid-currency-input';
import _ from 'lodash';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentInstitutionAccountToWithdraw } from 'model/payment-institution-account';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch, useWithdrawExternalBalanceState } from 'reducer/hooks';
import {
    withdrawExternalBalanceRequest,
    withdrawExternalBalanceResetState,
} from 'reducer/payment-institutions-accounts/withdraw-external-balance/actions';
import {
    validateWithdrawBalance,
    validateWithdrawBalanceValue,
} from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/validation/withdraw-balance.validate';
import { TransfeeraBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/components/transfeera/transfeera-balance.model';
import { ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';
import { useToast } from 'shared/hooks/use-toast';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import './withdraw-balance.scss';

interface WithdrawBalanceProps {
    balance: ExternalBalanceResponse | undefined | null;
    accountId: number;
    onSuccess: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.withdraw-credits';

const WithdrawBalance = (props: WithdrawBalanceProps) => {
    const { balance, accountId, onSuccess } = props;

    const [valueToBeWithdrawed, setValueToBeWithdrawed] = useState<number | undefined>(undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [isOpenModalMessage, setIsOpenModalMessage] = useState<boolean>(false);

    const { t } = useTranslation();

    const { status: withdrawStatus } = useWithdrawExternalBalanceState();

    const { toastSuccess, toastError } = useToast();

    const dispatch = useRootDispatch();

    const parsedSuccessResponse = useMemo((): TransfeeraBalanceResponse | undefined => {
        return JsonUtils.parseJsonString(balance?.response);
    }, [balance?.response]);

    const isLoading: boolean = useMemo(() => {
        return withdrawStatus === HttpRequestStatus.ON_GOING;
    }, [withdrawStatus]);

    const handleWithdrawBalance = useCallback(() => {
        if (!validateWithdrawBalance({ value: valueToBeWithdrawed, maxValue: parsedSuccessResponse?.value })) return;

        if (_.isNil(valueToBeWithdrawed)) return;

        const withdrawBalanceRequest: PaymentInstitutionAccountToWithdraw = {
            accountId,
            requestBody: {
                value: valueToBeWithdrawed,
            },
        };

        dispatch(withdrawExternalBalanceRequest(withdrawBalanceRequest));
    }, [parsedSuccessResponse, valueToBeWithdrawed, dispatch, accountId]);

    const handleOpenModalMessage = useCallback(() => {
        setShowValidation(true);

        if (!validateWithdrawBalance({ value: valueToBeWithdrawed, maxValue: parsedSuccessResponse?.value })) return;

        setIsOpenModalMessage(true);
    }, [valueToBeWithdrawed, parsedSuccessResponse]);

    useEffect(() => {
        return () => {
            dispatch(withdrawExternalBalanceResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (withdrawStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        setIsOpenModalMessage(false);
        onSuccess();
        dispatch(withdrawExternalBalanceResetState());
    }, [withdrawStatus, toastSuccess, t, onSuccess, dispatch]);

    useEffect(() => {
        if (withdrawStatus !== HttpRequestStatus.ERROR) return;

        toastError(t(`${I18N_PREFIX}.toast.failure`));
        setIsOpenModalMessage(false);
        dispatch(withdrawExternalBalanceResetState());
    }, [withdrawStatus, toastError, t, dispatch]);

    if (!balance?.isSuccessful) {
        return <></>;
    }

    return (
        <>
            <Grid item xs={12}>
                <Accordion variant="outlined">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <label>{t(`${I18N_PREFIX}.title`)}</label>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="withdraw-transfeera-balance">
                            <ValidCurrencyInput
                                label={t(`${I18N_PREFIX}.inputs.value.label`)}
                                placeholder={t(`${I18N_PREFIX}.inputs.value.placeholder`)}
                                value={valueToBeWithdrawed}
                                showValidation={showValidation}
                                validate={value =>
                                    validateWithdrawBalanceValue({ value: Number(value), maxValue: parsedSuccessResponse?.value })
                                }
                                onChange={value => setValueToBeWithdrawed(Number(value))}
                                externalUpdate
                            />
                            <StandardButtonLegacy
                                label={t(`${I18N_PREFIX}.inputs.withdraw-button.label`)}
                                onClick={handleOpenModalMessage}
                                disabled={!valueToBeWithdrawed}
                            />
                        </div>
                    </AccordionDetails>
                </Accordion>
                {isOpenModalMessage && (
                    <ModalMessageLegacy
                        title={t(`${I18N_PREFIX}.modal.title`)}
                        message={t(`${I18N_PREFIX}.modal.message`)}
                        onClose={() => setIsOpenModalMessage(false)}
                        onCancel={() => setIsOpenModalMessage(false)}
                        onAction={handleWithdrawBalance}
                        disabled={isLoading}
                    />
                )}
            </Grid>
        </>
    );
};

export default WithdrawBalance;
