import IdentificationUtils from 'components/inputs/validation/identification-utils';
import { isObjectDefined, valuesOfObject } from 'shared/util/object-utils';

export type Predicate<T> = (value: T | null | undefined) => boolean;

export interface Validation<T> {
    predicate: Predicate<T>;
    errorMessage?: string;
}

export interface Validations<T> {
    [key: string]: Validation<T>;
}

export interface ValidResult {
    isValid: true;
    errorMessage?: undefined;
}

export interface InvalidReulst {
    isValid: false;
    errorMessage: string;
}

export type ValidationResult = ValidResult | InvalidReulst;

const validatePredicate = <T>(value: T | null | undefined, validation: Validation<T>): ValidationResult => {
    return validation.predicate(value) ? { isValid: true } : { isValid: false, errorMessage: validation.errorMessage ?? '' };
};

const validate = <T>(value: T | null | undefined, validation: Validations<T>): ValidationResult => {
    return (
        valuesOfObject(validation)
            .filter(isObjectDefined)
            .map(it => validatePredicate(value, it))
            .find(it => !it.isValid) ?? { isValid: true }
    );
};

// Object
type ObjectPredicate = Predicate<any | null | undefined>;
const isDefined: ObjectPredicate = it => it !== null && it !== undefined;

// Enumeration
interface EnumerationType {
    [id: number]: string;
}
type EnumerationPredicate = Predicate<EnumerationType | null | undefined>;
const isDefinedEnum: EnumerationPredicate = it => it !== null && it !== undefined;

// String
type StringPredicate = Predicate<string | null | undefined>;

const isNotBlank: StringPredicate = it => it !== null && it !== undefined && it.trim() !== '';
const minLength =
    (targetLength: number): StringPredicate =>
        it =>
            it === null || it === undefined || it.length >= targetLength;
const maxLength =
    (targetLenght: number): StringPredicate =>
        it =>
            it === null || it === undefined || it.length <= targetLenght;
const pattern =
    (pattern: RegExp): StringPredicate =>
        it =>
            it === null || it === undefined || pattern.test(it);
const isValidCNPJ: StringPredicate = it => it === null || it === undefined || IdentificationUtils.isValidCNPJ(it);
const isValidCPF: StringPredicate = it => it === null || it === undefined || IdentificationUtils.isValidCPF(it);
const isValidCPForCNPJ: StringPredicate = it => !!it && (IdentificationUtils.isValidCNPJ(it) || IdentificationUtils.isValidCPF(it));

// Number
type NumberPredicate = Predicate<number | null | undefined>;
const isDefinedNumber: NumberPredicate = it => it !== null && it !== undefined;
const minNumberValue =
    (targetSize: number): NumberPredicate =>
        it =>
            it === null || it === undefined || it >= targetSize;
const maxNumberValue =
    (targetSize: number): NumberPredicate =>
        it =>
            it === null || it === undefined || it <= targetSize;

// Arrays
type ArraysPredicate = Predicate<Array<unknown>>;

const isNotEmptyArray: ArraysPredicate = it =>
    it !== null && it !== undefined && it.length !== null && it.length !== undefined && it.length > 0;

const arrayMaxSize =
    (targetSize: number): ArraysPredicate =>
        it =>
            it === null || it === undefined || it.length === null || it.length === undefined || it.length <= targetSize;

export const ValidationUtils = {
    validate,
    OBJECT: {
        isDefined,
    },
    ENUMERATION: {
        isDefinedEnum,
    },
    STRING: {
        isNotBlank,
        minLength,
        maxLength,
        pattern,
        isValidCNPJ,
        isValidCPF,
        isValidCPForCNPJ,
    },
    NUMBER: {
        isNotEmpty: isDefinedNumber,
        minSize: minNumberValue,
        maxSize: maxNumberValue,
    },
    ARRAYS: {
        isNotEmpty: isNotEmptyArray,
        maxSize: arrayMaxSize,
    },
};
