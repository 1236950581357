import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { useRouteMatch } from 'react-router-dom';

interface PaymentTransactionMoreOptionsMenuItemsProps {
    selectedPaymentTransactionId: number | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.more-options';

const PaymentTransactionMoreOptionsMenuItems = (props: PaymentTransactionMoreOptionsMenuItemsProps) => {
    const { selectedPaymentTransactionId } = props;

    const { url } = useRouteMatch();

    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.details`} link={`${url}/${selectedPaymentTransactionId}`} />
        </>
    );
};

export default PaymentTransactionMoreOptionsMenuItems;
