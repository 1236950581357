import ModalMessage from 'components/modals/modal-message/modal-message';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ChunkCessionFinalizeRequest } from 'model/bco/batch-output-cession-integration-gtsec';
import { chunkCessionFinalizeBcoRequest, chunkCessionFinalizeBcoResetState } from 'reducer-bco/batch-output/chunk-cession-finalize/action';
import { useCessionToResumeBco, useChunkCessionFinalizeBco, useChunkStreamToResumeBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';

import './chunk-cession-finalize-modal.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.footer.modal.finalize';

interface ChunkCessionFinalizeModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const ChunkCessionFinalizeModal: FC<ChunkCessionFinalizeModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useChunkCessionFinalizeBco();
    const { chunkStreamToResume } = useChunkStreamToResumeBco();
    const { cessionToResume } = useCessionToResumeBco();

    const stream = chunkStreamToResume?.stream;

    const handleChunkCessionFinalize = () => {
        if (!stream) return;
        const request: ChunkCessionFinalizeRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
            streamId: stream?.id,
        };
        dispatch(chunkCessionFinalizeBcoRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(chunkCessionFinalizeBcoResetState());
        };
    }, [dispatch]);

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleChunkCessionFinalize}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.finalize`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="chunk-cession-finalize-modal__content-title">{t(`${I18N_PREFIX}.message.finalize-title`)}</div>
            <div className="chunk-cession-finalize-modal__content-subtitle">{t(`${I18N_PREFIX}.message.closed-data`)}</div>
            <div className="chunk-cession-finalize-modal__text-box">
                <div className="chunk-cession-finalize-modal__text-data">
                    {t(`${I18N_PREFIX}.message.contracts`, { count: cessionToResume?.numberOfOperations })}
                </div>
                <div className="chunk-cession-finalize-modal__text-data">
                    {t(`${I18N_PREFIX}.message.receivables`, { count: cessionToResume?.numberOfInstallments })}
                </div>
                <div className="chunk-cession-finalize-modal__text-data">
                    {t(`${I18N_PREFIX}.message.original-value`, { value: cessionToResume?.cededAmount })}
                </div>
                <div className="chunk-cession-finalize-modal__text-data">
                    {t(`${I18N_PREFIX}.message.net-value`, { value: cessionToResume?.cessionPresentAmount })}
                </div>
            </div>
        </ModalMessage>
    );
};

export default ChunkCessionFinalizeModal;
