import { GiroWebappError } from 'model/error';
import { ScdRequestLogData, ScdRequestLogFilterPageable } from 'model/request-log';
import { ListRequestLogTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listRequestLogRequest = (request: ScdRequestLogFilterPageable) =>
    action(ListRequestLogTypes.LIST_REQUEST_LOG_REQUEST, request);
export const listRequestLogSuccess = (requestLogs: Page<ScdRequestLogData>) =>
    action(ListRequestLogTypes.LIST_REQUEST_LOG_SUCCESS, requestLogs);
export const listRequestLogError = (error: GiroWebappError) => action(ListRequestLogTypes.LIST_REQUEST_LOG_ERROR, error);
export const listRequestLogResetState = () => action(ListRequestLogTypes.LIST_REQUEST_LOG_RESET_STATE);
