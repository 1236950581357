import { GiroWebappError } from 'model/error';
import { ExternalStatementPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-statement-payment-institution-account/types';
import { ExternalStatementRequest, ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';
import { action } from 'typesafe-actions';

export const externalStatementPaymentInstitutionsAccountsRequest = (request: ExternalStatementRequest) =>
    action(ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, request)

export const externalStatementPaymentInstitutionsAccountsSuccess = (statement: ExternalStatementResponse) =>
    action(ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, statement);

export const externalStatementPaymentInstitutionsAccountsError = (error: GiroWebappError) =>
    action(ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error)

export const externalStatementPaymentInstitutionsAccountsResetState = () =>
    action(ExternalStatementPaymentInstitutionsAccountsTypes.EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
