import { AxiosResponse } from 'axios';
import { RequestLogFilterPageableBrs, RequestLogModelBrs } from 'model/brs/request-log-brs';
import { api } from 'services/api/api';
import { BASE_URL_BRS, Page } from 'services/page';

export const requestLogs = () => {
    const listRequestLogs = (request: RequestLogFilterPageableBrs): Promise<AxiosResponse<Page<RequestLogModelBrs>>> => {
        const { pageable } = request;


        return api.get<Page<RequestLogModelBrs>>(`${BASE_URL_BRS}/request-logs`, { params: { ...pageable } });
    };

    return {
        listRequestLogs,
    };
};

export default requestLogs();
