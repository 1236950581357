import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdIssuer } from 'model/issuer';
import { markError } from 'reducer/application/error/actions';
import { detailIssuerError, detailIssuerRequest, detailIssuerSuccess } from 'reducer/issuer/detail-issuer/actions';
import { DetailIssuerTypes } from 'reducer/issuer/detail-issuer/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import issuerApi from 'services/api/issuer-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailIssuer(action: ReturnType<typeof detailIssuerRequest>) {
    try {
        const result: AxiosResponse<ScdIssuer> = yield call(issuerApi.detailIssuer, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailIssuerError(mapped));
            return;
        }
        yield put(detailIssuerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailIssuerError(mapped));
    }
}

function* watchDetailIssuer() {
    yield takeEvery(DetailIssuerTypes.DETAIL_ISSUER_REQUEST, handleDetailIssuer);
}

function* detailIssuerSaga() {
    yield all([fork(watchDetailIssuer)]);
}

export default detailIssuerSaga;
