import clsx from 'clsx';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './submenu-menu-item.scss';
import { LinkAction } from 'model/sidebar';

export interface SubmenuMenuItemProps {
    title: string;
    isExternalLink?: boolean;
    link: string;
    action?: LinkAction;
}

const SubmenuMenuItem: FC<SubmenuMenuItemProps> = ({ title, action, isExternalLink, link }) => {
    const { t } = useTranslation();
    const local = useLocation().pathname;
    const history = useHistory();

    const handleRedirect = (link: string, event?: MouseEvent<HTMLAnchorElement>, submenuAction?: LinkAction) => {
        if (event?.ctrlKey || event?.altKey) return;
        if (submenuAction) submenuAction();

        if (action) action();

        return history.push(link);
    };

    return (
        <li
            className={clsx('submenu-menu-item--item', {
                active: link === local,
            })}
        >
            {isExternalLink ? (
                <a className="submenu-menu-item--item--link" href={link}>
                    <p className="submenu-menu-item--item--link--text">{t(title)}</p>
                </a>
            ) : (
                <Link
                    to={link}
                    className={clsx('submenu-menu-item--item--link', {
                        active: link === local,
                    })}
                    onClick={(e: MouseEvent<HTMLAnchorElement>) => handleRedirect(link, e, action)}
                >
                    <p className="submenu-menu-item--item--link--text">{t(title)}</p>
                </Link>
            )}
        </li>
    );
};

export default SubmenuMenuItem;
