import { ScdCompanyGroup, ScdCompanyGroupSimple } from 'model/company-group';
import { GiroWebappError } from 'model/error';
import { UpdateCompanyGroupTypes } from 'reducer/company-group/update-company-group/types';
import { action } from 'typesafe-actions';

export const updateCompanyGroupRequest = (companyGroup: ScdCompanyGroup) =>
    action(UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_REQUEST, companyGroup);
export const updateCompanyGroupSuccess = (updatedCompanyGroup: ScdCompanyGroupSimple) =>
    action(UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_SUCCESS, updatedCompanyGroup);
export const updateCompanyGroupError = (error: GiroWebappError) => action(UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_ERROR, error);
export const updateCompanyGroupResetState = () => action(UpdateCompanyGroupTypes.UPDATE_COMPANY_GROUP_RESET);
