export interface ScdBankerList {
    id: number;
    organizationName: string;
    userName: string;
    userEmail: string;
    hasApprovalAuthority: boolean
}

export interface ScdBanker {
    id?: number;
    organizationId?: number;
    organizationName?: string;
    userName?: string;
    userEmail?: string;
    hasApprovalAuthority: boolean;
}

export const defaultScdBanker: ScdBanker = { hasApprovalAuthority: false };

export interface ScdBankerSimple {
    id?: number;
}
