import { Grid } from '@material-ui/core';
import CloseButton from 'components/buttons/close-button/close-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { ScdCompanyGroup } from 'model/company-group';
import {} from 'model/enums/http-request-status';
import { ManageCompanyGroupToProgramRequest } from 'model/program-groups';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchCompanyGroupsRequest, searchCompanyGroupsResetState } from 'reducer/company-group/search-company-groups/actions';
import { useRootDispatch } from 'reducer/hooks';
import { addProgramGroupRequest, addProgramGroupResetState } from 'reducer/program-groups/add-program-group/actions';

import './program-groups-add-modal.scss';

interface ProgramGroupsAddModalProps {
    onClose: () => void;
    programId: string;
    organizationId: string | undefined;
}

const I18N_PREFIX = 'pages.scd.program-groups.add-modal';

const ProgramGroupsAddModal = (props: ProgramGroupsAddModalProps) => {
    const { onClose, programId, organizationId } = props;

    const [companyGroup, setCompanyGroup] = useState<ScdCompanyGroup | undefined>(undefined);

    const { t } = useTranslation();

    const dispatch = useRootDispatch();

    const addProgramGroup = () => {
        if (!companyGroup?.id) return;

        const programGroupRequest: ManageCompanyGroupToProgramRequest = {
            programId,
            companyGroupId: companyGroup.id,
        };

        dispatch(addProgramGroupRequest(programGroupRequest));
    };

    useEffect(() => {
        return () => {
            dispatch(addProgramGroupResetState());
        };
    }, [dispatch]);

    const CompanyGroupsSearch: JSX.Element = (
        <>
            <Grid item xs={12}>
                <ModalSearch<ScdCompanyGroup>
                    modalTitle={t(`${I18N_PREFIX}.inputs.companyGroups.label`)}
                    modalLabel={t(`${I18N_PREFIX}.inputs.companyGroups.label`)}
                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.companyGroups.placeholder`)}
                    itemSize={SEARCH_ITEM_SIZE}
                    action={searchCompanyGroupsRequest}
                    resetState={searchCompanyGroupsResetState}
                    renderItem={companyGroup => companyGroup.name}
                    statusSelector={(state: IRootState) => state.searchCompanyGroups.status}
                    dataSelector={(state: IRootState) => state.searchCompanyGroups.companyGroups}
                    onSelect={companyGroup => setCompanyGroup(companyGroup)}
                    requestParameters={{ organizationId }}
                >
                    {handleOpen => (
                        <SimpleSearchInput
                            label={t(`${I18N_PREFIX}.inputs.companyGroups.label`)}
                            placeholder={t(`${I18N_PREFIX}.inputs.companyGroups.placeholder`)}
                            value={companyGroup?.name}
                            onClick={handleOpen}
                            onFocus={handleOpen}
                            disabled={!organizationId}
                            readOnly
                            externalUpdate
                        />
                    )}
                </ModalSearch>
            </Grid>
        </>
    );

    return (
        <Modal isOpen>
            <div className="scd-program-groups-add">
                <div className="scd-program-groups-add--header">
                    <label className="scd-program-groups-add--header-title">{t(`${I18N_PREFIX}.title`)}</label>
                    <CloseButton onClick={onClose} />
                </div>
                <Grid container spacing={2}>
                    {CompanyGroupsSearch}
                </Grid>
                <div className="scd-program-groups-add--buttons-actions">
                    <OutlinedButtonLegacy label={t('entity.action.cancel')} onClick={onClose} />
                    <StandardButtonLegacy label={t('entity.action.confirm')} onClick={addProgramGroup} />
                </div>
            </div>
        </Modal>
    );
};

export default ProgramGroupsAddModal;
