import { BatchOperations } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchOperationsTypes {
    BATCH_OPERATIONS_REQUEST = '@@batch-operations/BATCH_OPERATIONS_REQUEST',
    BATCH_OPERATIONS_SUCCESS = '@@batch-operations/BATCH_OPERATIONS_SUCCESS',
    BATCH_OPERATIONS_ERROR = '@@batch-operations/BATCH_OPERATIONS_ERROR',
    BATCH_OPERATIONS_RESET = '@@batch-operations/BATCH_OPERATIONS_RESET',
}

export interface BatchOperationsState extends BaseStoreState {
    readonly batchOperationsSummarize?: BatchOperations;
}
