import { PaymentValueType } from 'model/enums/payment-transaction-in-series-type';
import { v4 as uuidV4 } from 'uuid';

export interface ScdPaymentTransactionInSeriesToCreate {
    uniqueIdentifier?: string;
    amount?: number;
    description?: string;
    personId?: number;
    companyId?: number;
    bankAccountId?: number;
    type?: PaymentValueType;
}

export const defaultPaymentTransactionInSeriesCreate: ScdPaymentTransactionInSeriesToCreate = {
    uniqueIdentifier: uuidV4(),
};

export interface ScdPaymentTransactionsInSeriesToCreateList {
    paymentTransactions: ScdPaymentTransactionInSeriesToCreate[];
}
export interface ScdPaymentTransactionsInSeriesError {
    uniqueIdentifier: string;
    errorMessage: string;
}
export interface ScdPaymentTransactionsInSeriesErrorsList {
    errors: ScdPaymentTransactionsInSeriesError[];
}
