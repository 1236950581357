import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { limelightAuthorizationSendBackingState, ProvisionalLimelightSendBackingTypes } from 'reducer/provisional-limelight/provisional-limelight-send/types';

const initialState: limelightAuthorizationSendBackingState = {
    status: HttpRequestStatus.NOOP,
    authorizationSendBacking: undefined,
    error: undefined
}

const reducer: Reducer<limelightAuthorizationSendBackingState> = (state = initialState, action): limelightAuthorizationSendBackingState => {
    switch (action.type) {
        case ProvisionalLimelightSendBackingTypes.LIMELIGHT_AUTHORIZATION_SEND_BACKING_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ProvisionalLimelightSendBackingTypes.LIMELIGHT_AUTHORIZATION_SEND_BACKING_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, authorizationSendBacking: action.payload, error: undefined };
        }
        case ProvisionalLimelightSendBackingTypes.LIMELIGHT_AUTHORIZATION_SEND_BACKING_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProvisionalLimelightSendBackingTypes.LIMELIGHT_AUTHORIZATION_SEND_BACKING_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as limelightAuthorizationSendBackingReducer }