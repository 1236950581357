import { ErrorConstants, ErrorType } from 'model/enums/error-constants';
import { ErrorHandlingCustomization } from 'model/error';

export type ApiResponse = Record<string, string>;

export enum ApplicationErrorActionTypes {
    MARK_ERROR = '@@applicationError/MARK_ERROR',
    RESOLVE_ERROR = '@@applicationError/RESOLVE_ERROR',
    CUSTOMIZE_HANDLING = '@@applicationError/CUSTOMIZE_HANDLING',
    RESET = '@@applicationError/RESET',
}

export interface ApplicationErrorState {
    readonly errors: string[];
    readonly customs: Partial<Record<ErrorConstants, ErrorHandlingCustomization>>;
    readonly defaultType: ErrorType;
}
