import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';

import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ErrorUtils from 'shared/error/error-utils';
import {
    exportRequestSummaryResultsBcoError,
    exportRequestSummaryResultsBcoRequest,
    exportRequestSummaryResultsBcoSuccess,
} from 'reducer-bco/batch-output/export-request-summary-results/action';
import { ExportRequestSummaryResultsResponse } from 'model/bco/batch-output';
import batchOutputApi from 'services/bco/batch-outputs-bco';
import { ExportRequestSummaryResultsBcoTypes } from 'reducer-bco/batch-output/export-request-summary-results/types';

function* handleExportRequestSummaryResultsBco(action: ReturnType<typeof exportRequestSummaryResultsBcoRequest>) {
    try {
        const result: AxiosResponse<ExportRequestSummaryResultsResponse> = yield call(
            batchOutputApi.exportRequestSummaryResults,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(exportRequestSummaryResultsBcoError(mapped));
            return;
        }
        yield put(exportRequestSummaryResultsBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(exportRequestSummaryResultsBcoError(mapped));
    }
}

function* watchExportRequestSummaryResultsBco() {
    yield takeEvery(ExportRequestSummaryResultsBcoTypes.EXPORT_REQUEST_SUMMARY_RESULTS_BCO_REQUEST, handleExportRequestSummaryResultsBco);
}

function* ExportRequestSummaryResultsBcoSaga() {
    yield all([fork(watchExportRequestSummaryResultsBco)]);
}

export default ExportRequestSummaryResultsBcoSaga;
