import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ListPaymentTransactionsByBatchLegacyTypes,
    ListPaymentTransactionsByBatchLegacyState,
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/types';

const initialState: ListPaymentTransactionsByBatchLegacyState = {
    status: HttpRequestStatus.NOOP,
    paymentTransactionsByBatchLegacy: undefined,
    error: undefined,
};

const reducer: Reducer<ListPaymentTransactionsByBatchLegacyState> = (state = initialState, action): ListPaymentTransactionsByBatchLegacyState => {
    switch (action.type) {
        case ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransactionsByBatchLegacy: action.payload, error: undefined };
        }
        case ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listPaymentTransactionsByBatchLegacyReducer };
