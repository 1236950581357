import { ScdIssuerSimple } from 'model/issuer';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum UpdateIssuerTypes {
    UPDATE_ISSUER_REQUEST = '@@issuer-update/UPDATE_ISSUER_REQUEST',
    UPDATE_ISSUER_SUCCESS = '@@issuer-update/UPDATE_ISSUER_SUCCESS',
    UPDATE_ISSUER_ERROR = '@@issuer-update/UPDATE_ISSUER_ERROR',
    UPDATE_ISSUER_RESET_STATE = '@@issuer-update/UPDATE_ISSUER_RESET_STATE',
}

export interface UpdateIssuerState extends BaseStoreState {
    readonly issuer?: ScdIssuerSimple;
}
