import { ScdCompanyItemSearch } from 'model/company-item';
import { GiroWebappError } from 'model/error';
import { SearchRequestCompanyItem } from 'model/reducers';
import { SearchCompanyItemsTypes } from 'reducer/company-item/search-company-items/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchCompanyItemsRequest = (request: SearchRequestCompanyItem) =>
    action(SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_REQUEST, request);
export const searchCompanyItemsSuccess = (companyItems: Page<ScdCompanyItemSearch>) =>
    action(SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_SUCCESS, companyItems);
export const searchCompanyItemsError = (error: GiroWebappError) => action(SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_ERROR, error);
export const searchCompanyItemsResetState = () => action(SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_RESET);
