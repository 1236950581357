import React from 'react';
import { useLocation } from 'react-router-dom';

interface VersionSwitchComponentProps {
    legacyComponent: JSX.Element;
    newComponent: JSX.Element;
    queryParamForLegacy: string;
}

const VersionSwitchComponent: React.FC<VersionSwitchComponentProps> = ({ legacyComponent, newComponent, queryParamForLegacy }) => {
    const location = useLocation();
    const isLegacyVersion = location.search.includes(queryParamForLegacy);

    if (isLegacyVersion) {
        return legacyComponent;
    }

    return newComponent;
};

export default VersionSwitchComponent;
