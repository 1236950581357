import { PaymentTransactionManualDisbursementValidation } from 'model/payment-transaction';
import { Trans, useTranslation } from 'react-i18next';

interface FormErrorByValidationProps {
    manualDisbursementValidation: PaymentTransactionManualDisbursementValidation | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const FormErrorByValidation = (props: FormErrorByValidationProps) => {
    const { manualDisbursementValidation } = props;

    const { t } = useTranslation();

    if (!manualDisbursementValidation) {
        return <></>;
    }

    const { constraintsCode, availability } = manualDisbursementValidation;

    return (
        <div className="scd-payment-transaction__detail--form-errors">
            <label className="scd-payment-transaction__detail--form-errors__label">
                {<Trans t={t} i18nKey={`${I18N_PREFIX}.unavailable-disbursement.${availability}`} />}
            </label>
            <ul>
                {constraintsCode.map(it => (
                    <li key={it.code} className="scd-payment-transaction__detail--form-errors__item">
                        {t(it.code) ?? '-'}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FormErrorByValidation;
