import BackDrop from 'components/backdrop/backdrop';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import PercentageFormatterWithSixDecimals from 'components/formatter/percentage/percentage-formatter-six-decimals';
import ValidCurrencyInput from 'components/inputs/currency-input/valid-currency-input';
import ValidLocalDateInput from 'components/inputs/date-input/valid-local-date-input';
import Loading from 'components/loading/loading';
import ValidEstimatedPercentInput from 'entities/batch/batch-session-interest-rate-calculate-recalculate/components/inputs/valid-estimated-percent-input';
import {
    validateCalculateSessionEstimatedPercentage,
    validateCalculateSessionRateBankChargesAmount,
    validateCalculateSessionRateCommissionAmount,
    validateCalculateSessionRateSessionDate,
    validateScdCalculateSessionInterestRate,
} from 'entities/batch/batch-session-interest-rate-calculate-recalculate/validation/batch-session-interest-rate-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SessionInterestRateCalculationResult } from 'model/enums/session-interest-rate-result';
import { defaultSessionInterestRateRequest, SessionInterestRateRequest, SessionInterestRateResponse } from 'model/session-interest-rates';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useDetailSessionInterestRateState, useRecalculateSessionInterestRateState, useRootDispatch } from 'reducer/hooks';
import {
    detailSessionInterestRateRequest,
    detailSessionInterestRateResetState,
} from 'reducer/session-interest-rate/detail-session-interest-rate/actions';
import {
    recalculateSessionInterestRateRequest,
    recalculateSessionInterestRateResetState,
} from 'reducer/session-interest-rate/recalculate-session-interest-rate/actions';
import { useToast } from 'shared/hooks/use-toast';

import { CopyIcon } from 'components/copy/copy-icon';
import FormatterUtils from 'components/formatter/formatter-utils';
import PercentageFormatterWithTwelveDecimals from 'components/formatter/percentage/percentage-formatter-twelve-decimals';
import './batch-session-interest-rate-recalculate.scss';

type ScdBatchSessionInterestCalculateProps = RouteComponentProps<{ batchId: string }>;

const I18N_PREFIX = 'pages.scd.batch.session-interest-rate';

const ScdBatchSessionInterestRateRecalculatePage = (props: ScdBatchSessionInterestCalculateProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { toastSuccess, toastError } = useToast();
    const history = useHistory();

    const [batchId] = useState<number | undefined>(props.match.params.batchId ? Number(props.match.params.batchId) : undefined);
    const [sessionInterestRate, setSessionInterestRate] = useState<SessionInterestRateRequest | undefined>(undefined);
    const [detailSessionInterestRateToResume, setDetailSessionInterestRateToResume] = useState<SessionInterestRateResponse | undefined>(
        undefined
    );

    const [showValidation, setShowValidation] = useState<boolean>(false);

    const { sessionInterestRateRecalculate, status: recalculateStatus } = useRecalculateSessionInterestRateState();
    const { detailSessionInterestRate, status: detailStatus } = useDetailSessionInterestRateState();

    const isSendingRequest = recalculateStatus === HttpRequestStatus.ON_GOING;
    const isLoadingDetails = detailStatus !== HttpRequestStatus.SUCCESS || !sessionInterestRate;

    useEffect(() => {
        if (!batchId) return;

        dispatch(detailSessionInterestRateRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        if (!batchId) return;

        setSessionInterestRate(ps => ({ ...ps, ...defaultSessionInterestRateRequest, batchId }));
    }, [batchId]);

    useEffect(() => {
        return () => {
            dispatch(detailSessionInterestRateResetState());
            dispatch(recalculateSessionInterestRateResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        return () => {
            setSessionInterestRate(undefined);
        };
    }, []);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailSessionInterestRate?.sessionDate) {
            setDetailSessionInterestRateToResume(detailSessionInterestRate);
            setSessionInterestRate(ps => ({
                ...ps,
                sessionDate: detailSessionInterestRate.sessionDate?.toLocaleString(),
                bankChargesAmount: detailSessionInterestRate?.bankChargesAmount,
                commissionAmount: detailSessionInterestRate?.commissionAmount,
                estimated: {
                    lowEstimatePercentage: detailSessionInterestRate?.lowEstimateInterestRatePercentage,
                    highEstimatePercentage: detailSessionInterestRate?.highEstimateInterestRatePercentage,
                },
            }));
        }
    }, [dispatch, detailStatus, detailSessionInterestRate]);

    useEffect(() => {
        if (
            recalculateStatus === HttpRequestStatus.SUCCESS &&
            sessionInterestRateRecalculate?.result === SessionInterestRateCalculationResult.SUCCESS
        ) {
            setDetailSessionInterestRateToResume({ ...sessionInterestRateRecalculate });
            toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        }
    }, [dispatch, recalculateStatus, sessionInterestRateRecalculate?.result, sessionInterestRateRecalculate, t, toastSuccess]);

    useEffect(() => {
        if (
            recalculateStatus === HttpRequestStatus.SUCCESS &&
            sessionInterestRateRecalculate?.result === SessionInterestRateCalculationResult.FAILURE
        ) {
            setDetailSessionInterestRateToResume({ ...sessionInterestRateRecalculate });
            toastError(t(`${I18N_PREFIX}.toast.error`));
        }
    }, [dispatch, recalculateStatus, sessionInterestRateRecalculate?.result, sessionInterestRateRecalculate, t, toastError]);

    useEffect(() => {
        if (!sessionInterestRate?.estimated?.lowEstimatePercentage && !sessionInterestRate?.estimated?.highEstimatePercentage) {
            setSessionInterestRate(ps => ({ ...ps, estimated: undefined }));
        }
    }, [batchId, sessionInterestRate?.estimated?.lowEstimatePercentage, sessionInterestRate?.estimated?.highEstimatePercentage]);

    const handleChange = (value: Partial<SessionInterestRateRequest>) => {
        setSessionInterestRate(ps => ({ ...ps, ...value }));
    };

    const handleRecalculateSession = () => {
        setShowValidation(true);

        const _scdSessionInterestRate: SessionInterestRateRequest = {
            ...sessionInterestRate,
        };

        if (!validateScdCalculateSessionInterestRate(_scdSessionInterestRate)) return;

        dispatch(recalculateSessionInterestRateRequest(_scdSessionInterestRate));
    };

    const isNotFailure = detailSessionInterestRateToResume?.result !== SessionInterestRateCalculationResult.FAILURE;

    return (
        <>
            {isSendingRequest && <BackDrop open />}
            <main className="batch-session-interest-recalculate">
                <ContextRibbon action={() => history.push('/batches')} />
                {isLoadingDetails ? (
                    <Loading />
                ) : (
                    <>
                        <article className="batch-session-interest-recalculate--left-column">
                            <div className="batch-session-interest-recalculate--detail-container">
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.sessionDate`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.sessionDate ? (
                                                    <DateFormatter date={detailSessionInterestRateToResume?.sessionDate} />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.bankChargesAmount`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.bankChargesAmount ||
                                                detailSessionInterestRateToResume?.bankChargesAmount === 0 ? (
                                                    <CurrencyFormatter
                                                        value={detailSessionInterestRateToResume?.bankChargesAmount}
                                                        prefix
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>

                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.commissionAmount`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.commissionAmount ||
                                                detailSessionInterestRateToResume?.commissionAmount === 0 ? (
                                                    <CurrencyFormatter value={detailSessionInterestRateToResume?.commissionAmount} prefix />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.disbursementAmount`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.disbursementAmount ||
                                                detailSessionInterestRateToResume?.disbursementAmount === 0 ? (
                                                    <CurrencyFormatter
                                                        value={detailSessionInterestRateToResume?.disbursementAmount}
                                                        prefix
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.grossAmount`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.grossAmount ||
                                                detailSessionInterestRateToResume?.grossAmount === 0 ? (
                                                    <CurrencyFormatter value={detailSessionInterestRateToResume?.grossAmount} prefix />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>

                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.iofAmount`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.iofAmount ||
                                                detailSessionInterestRateToResume?.iofAmount === 0 ? (
                                                    <CurrencyFormatter value={detailSessionInterestRateToResume?.iofAmount} prefix />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div className="batch-session-interest-recalculate--field">
                                        <label className="batch-session-interest-recalculate--label">
                                            {t(`${I18N_PREFIX}.detail.exchangePremiumPercentage`)}
                                        </label>
                                        <span className="batch-session-interest-recalculate--text">
                                            {detailSessionInterestRateToResume?.exchangePremiumPercentage ||
                                            detailSessionInterestRateToResume?.exchangePremiumPercentage === 0 ? (
                                                <PercentageFormatterWithSixDecimals
                                                    value={detailSessionInterestRateToResume?.exchangePremiumPercentage}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-recalculate--field">
                                        <label className="batch-session-interest-recalculate--label">
                                            {t(`${I18N_PREFIX}.detail.exchangePremiumAmount`)}
                                        </label>
                                        <span className="batch-session-interest-recalculate--text">
                                            {detailSessionInterestRateToResume?.exchangePremiumAmount ||
                                            detailSessionInterestRateToResume?.exchangePremiumAmount === 0 ? (
                                                <CurrencyFormatter value={detailSessionInterestRateToResume.exchangePremiumAmount} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.baseEstimateInterestRatePercentage`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.baseEstimateInterestRatePercentage ||
                                                detailSessionInterestRateToResume?.baseEstimateInterestRatePercentage === 0 ? (
                                                    <PercentageFormatterWithSixDecimals
                                                        value={detailSessionInterestRateToResume?.baseEstimateInterestRatePercentage}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.lowEstimateInterestRatePercentage`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.lowEstimateInterestRatePercentage ||
                                                detailSessionInterestRateToResume?.lowEstimateInterestRatePercentage === 0 ? (
                                                    <PercentageFormatterWithSixDecimals
                                                        value={detailSessionInterestRateToResume?.lowEstimateInterestRatePercentage}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.highEstimateInterestRatePercentage`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.highEstimateInterestRatePercentage ||
                                                detailSessionInterestRateToResume?.highEstimateInterestRatePercentage === 0 ? (
                                                    <PercentageFormatterWithSixDecimals
                                                        value={detailSessionInterestRateToResume?.highEstimateInterestRatePercentage}
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.monthlyInterestRatePercentage`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.monthlyInterestRatePercentage ||
                                                detailSessionInterestRateToResume?.monthlyInterestRatePercentage === 0 ? (
                                                    <>
                                                        <PercentageFormatterWithTwelveDecimals
                                                            value={detailSessionInterestRateToResume?.monthlyInterestRatePercentage}
                                                        />
                                                        {isNotFailure && (
                                                            <CopyIcon
                                                                value={detailSessionInterestRateToResume.monthlyInterestRatePercentage}
                                                                formatter={
                                                                    FormatterUtils.formatNumberToPercentageWithTwelveDecimalsWithoutSymbols
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.yearlyInterestRatePercentage`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {detailSessionInterestRateToResume?.yearlyInterestRatePercentage ? (
                                                    <>
                                                        <PercentageFormatterWithTwelveDecimals
                                                            value={detailSessionInterestRateToResume?.yearlyInterestRatePercentage}
                                                        />
                                                        {isNotFailure && (
                                                            <CopyIcon
                                                                value={detailSessionInterestRateToResume.yearlyInterestRatePercentage}
                                                                formatter={
                                                                    FormatterUtils.formatNumberToPercentageWithTwelveDecimalsWithoutSymbols
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </span>
                                        </div>
                                        <div className="batch-session-interest-recalculate--field">
                                            <label className="batch-session-interest-recalculate--label">
                                                {t(`${I18N_PREFIX}.detail.result.label`)}
                                            </label>
                                            <span className="batch-session-interest-recalculate--text">
                                                {t(`${I18N_PREFIX}.detail.result.${detailSessionInterestRateToResume?.result}`)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div style={{ display: 'flex' }}>
                                    <div className="batch-session-interest-recalculate--field">
                                        <label className="batch-session-interest-recalculate--label">
                                            {t(`${I18N_PREFIX}.detail.sessionAmount`)}
                                        </label>
                                        <span className="batch-session-interest-recalculate--value">
                                            {detailSessionInterestRateToResume?.sessionAmount ||
                                            detailSessionInterestRateToResume?.sessionAmount === 0 ? (
                                                <>
                                                    <CurrencyFormatter value={detailSessionInterestRateToResume.sessionAmount} prefix />
                                                    {isNotFailure && (
                                                        <CopyIcon
                                                            value={detailSessionInterestRateToResume.sessionAmount}
                                                            formatter={FormatterUtils.formatNumberToCurrency}
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                    <div className="batch-session-interest-recalculate--field">
                                        <label className="batch-session-interest-recalculate--label">
                                            {t(`${I18N_PREFIX}.detail.sessionPresentValue`)}
                                        </label>
                                        <span className="batch-session-interest-recalculate--value">
                                            {detailSessionInterestRateToResume?.sessionPresentValue ||
                                            detailSessionInterestRateToResume?.sessionPresentValue === 0 ? (
                                                <CurrencyFormatter value={detailSessionInterestRateToResume.sessionPresentValue} prefix />
                                            ) : (
                                                '-'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <section className="batch-session-interest-recalculate--content">
                            <article className="batch-session-interest-recalculate--rigth-column">
                                <header className="batch-session-interest-recalculate--header">
                                    <h2 id="giroInvestmentVehicleManagementApiApp.share.home.purchase">
                                        {t(`${I18N_PREFIX}.calculate.title`)}
                                    </h2>
                                </header>
                                <form className="batch-session-interest-recalculate--form">
                                    <div className="batch-session-interest-recalculate--form-field">
                                        <ValidLocalDateInput
                                            value={sessionInterestRate.sessionDate}
                                            label={t(`${I18N_PREFIX}.input.sessionDate.label`)}
                                            placeholder={t(`${I18N_PREFIX}.input.sessionDate.placeholder`)}
                                            validate={validateCalculateSessionRateSessionDate}
                                            onChange={sessionDate => handleChange({ sessionDate })}
                                            showValidation={showValidation}
                                            externalUpdate
                                        />
                                    </div>
                                    <div className="batch-session-interest-recalculate--form-field">
                                        <ValidCurrencyInput
                                            value={sessionInterestRate.commissionAmount}
                                            label={t(`${I18N_PREFIX}.input.commissionAmount.label`)}
                                            placeholder={t(`${I18N_PREFIX}.input.commissionAmount.placeholder`)}
                                            onChange={commissionAmount => handleChange({ commissionAmount })}
                                            showValidation={showValidation}
                                            validate={validateCalculateSessionRateCommissionAmount}
                                            zeroValue
                                            externalUpdate
                                        />
                                    </div>
                                    <div className="batch-session-interest-recalculate--form-field">
                                        <ValidCurrencyInput
                                            value={sessionInterestRate?.bankChargesAmount}
                                            label={t(`${I18N_PREFIX}.input.bankChargesAmount.label`)}
                                            placeholder={t(`${I18N_PREFIX}.input.bankChargesAmount.placeholder`)}
                                            onChange={bankChargesAmount => handleChange({ bankChargesAmount })}
                                            showValidation={showValidation}
                                            validate={validateCalculateSessionRateBankChargesAmount}
                                            zeroValue
                                            externalUpdate
                                        />
                                    </div>
                                    <div className="batch-session-interest-recalculate--form-field">
                                        <ValidEstimatedPercentInput
                                            value={sessionInterestRate?.estimated?.lowEstimatePercentage}
                                            label={t(`${I18N_PREFIX}.input.lowEstimatePercentage.label`)}
                                            placeholder={t(`${I18N_PREFIX}.input.lowEstimatePercentage.placeholder`)}
                                            onChange={lowEstimatePercentage =>
                                                handleChange({
                                                    estimated: {
                                                        ...sessionInterestRate?.estimated,
                                                        lowEstimatePercentage,
                                                    },
                                                })
                                            }
                                            showValidation={showValidation}
                                            validate={() =>
                                                validateCalculateSessionEstimatedPercentage({
                                                    lowEstimatePercentage: sessionInterestRate?.estimated?.lowEstimatePercentage,
                                                    highEstimatePercentage: sessionInterestRate?.estimated?.highEstimatePercentage,
                                                })
                                            }
                                            externalUpdate
                                        />
                                    </div>
                                    <div className="batch-session-interest-recalculate--form-field">
                                        <ValidEstimatedPercentInput
                                            value={sessionInterestRate?.estimated?.highEstimatePercentage}
                                            label={t(`${I18N_PREFIX}.input.highEstimatePercentage.label`)}
                                            placeholder={t(`${I18N_PREFIX}.input.highEstimatePercentage.placeholder`)}
                                            onChange={highEstimatePercentage =>
                                                handleChange({
                                                    estimated: {
                                                        ...sessionInterestRate?.estimated,
                                                        highEstimatePercentage,
                                                    },
                                                })
                                            }
                                            showValidation={showValidation}
                                            validate={() =>
                                                validateCalculateSessionEstimatedPercentage({
                                                    lowEstimatePercentage: sessionInterestRate?.estimated?.lowEstimatePercentage,
                                                    highEstimatePercentage: sessionInterestRate?.estimated?.highEstimatePercentage,
                                                })
                                            }
                                            externalUpdate
                                        />
                                    </div>
                                    <div className="batch-session-interest-recalculate--form--buttons">
                                        <div className="batch-session-interest-recalculate--form--buttons-button">
                                            <Link to="/batches">
                                                <OutlinedButtonLegacy label="entity.action.cancel" />
                                            </Link>
                                        </div>
                                        <div className="batch-session-interest-recalculate--form--buttons-button">
                                            <StandardButtonLegacy
                                                label={'entity.action.recalculate'}
                                                onClick={handleRecalculateSession}
                                                disabled={!validateScdCalculateSessionInterestRate(sessionInterestRate)}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </article>
                        </section>
                    </>
                )}
            </main>
        </>
    );
};

export default ScdBatchSessionInterestRateRecalculatePage;
