import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionAccountSimple } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { createPaymentInstitutionAccountRequest, createPaymentInstitutionAccountError, createPaymentInstitutionAccountSuccess } from 'reducer/payment-institutions-accounts/create-payment-institution-account/actions';
import { CreatePaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/create-payment-institution-account/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreatePaymentInstitutionAccount(action: ReturnType<typeof createPaymentInstitutionAccountRequest>) {
    try {
        const result: AxiosResponse<PaymentInstitutionAccountSimple> = yield call(
            paymentInstitutionsAccountsApi.createPaymentInstitutionAccount,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createPaymentInstitutionAccountError(mapped));
            return;
        }
        yield put(createPaymentInstitutionAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createPaymentInstitutionAccountError(mapped));
    }
}

function* watchCreatePaymentInstitutionAccount() {
    yield takeLeading(CreatePaymentInstitutionAccountTypes.CREATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, handleCreatePaymentInstitutionAccount);
}

function* createPaymentInstitutionAccountSaga() {
    yield all([fork(watchCreatePaymentInstitutionAccount)]);
}

export default createPaymentInstitutionAccountSaga;
