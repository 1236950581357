import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchSignatureResumeState, BatchSignatureResumeTypes } from 'reducer/batch/batch-signature-resume/types';

const initialState: BatchSignatureResumeState = {
    status: HttpRequestStatus.NOOP,
    signatureResume: undefined,
    error: undefined
}

const reducer: Reducer<BatchSignatureResumeState> = (state = initialState, action): BatchSignatureResumeState => {
    switch (action.type) {
        case BatchSignatureResumeTypes.BATCH_SIGNATURE_RESUME_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchSignatureResumeTypes.BATCH_SIGNATURE_RESUME_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, signatureResume: action.payload, error: undefined };
        }
        case BatchSignatureResumeTypes.BATCH_SIGNATURE_RESUME_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchSignatureResumeTypes.BATCH_SIGNATURE_RESUME_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchSignatureResumeReducer }