import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import issuerPolicyApi from 'services/api/issuer-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { IssuerListPolicies } from 'model/issuer-policy';
import { listIssuerPoliciesError, listIssuerPoliciesRequest, listIssuerPoliciesSuccess } from 'reducer/issuer-policy/list-issuer-policies/actions';
import { ListIssuerPoliciesTypes } from 'reducer/issuer-policy/list-issuer-policies/types';

function* handleListIssuerPolicies(action: ReturnType<typeof listIssuerPoliciesRequest>) {
    try {
        const result: AxiosResponse<Page<IssuerListPolicies>> = yield call(issuerPolicyApi.listPolicies, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listIssuerPoliciesError(mapped));
            return;
        }
        yield put(listIssuerPoliciesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listIssuerPoliciesError(mapped));
    }
}

function* watchListIssuerPolicies() {
    yield takeEvery(ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_REQUEST, handleListIssuerPolicies);
}

function* listIssuerPoliciesSaga() {
    yield all([fork(watchListIssuerPolicies)]);
}

export default listIssuerPoliciesSaga;
