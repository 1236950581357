import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListPendingPaymentTransactionsTypes, ListPendingPaymentTransactionsState } from 'reducer/payment-transaction/list-pending-payment-transactions/types';

const initialState: ListPendingPaymentTransactionsState = {
    status: HttpRequestStatus.NOOP,
    pendingTransactions: undefined,
    error: undefined,
};

const reducer: Reducer<ListPendingPaymentTransactionsState> = (state = initialState, action): ListPendingPaymentTransactionsState => {
    switch (action.type) {
        case ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, pendingTransactions: action.payload, error: undefined };
        }
        case ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listPendingPaymentTransactionsReducer };
