import { ScdPaymentTransactionsInSeriesErrorsList } from 'model/payment-transaction-in-series';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreatePaymentTransactionsInSeriesTypes {
    CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_REQUEST = '@@payment-transactions-in-series-create/CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_REQUEST',
    CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_SUCCESS = '@@payment-transactions-in-series-create/CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_SUCCESS',
    CREATE_PAYMENT_TRANSATIONS_IN_SERIES_ERROR = '@@payment-transactions-in-series-create/CREATE_PAYMENT_TRANSATIONS_IN_SERIES_ERROR',
    CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_RESET = '@@payment-transactions-in-series-create/CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_RESET',
}

export interface CreatePaymentTransactionsInSeriesState extends BaseStoreState {
    readonly paymentTransactionsInSeriesErrors?: ScdPaymentTransactionsInSeriesErrorsList;
}
