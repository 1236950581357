import { Checkbox } from '@material-ui/core';
import clsx from 'clsx';
import Loading from 'components/loading/loading';
import ModalMessage from 'components/modals/modal-message/modal-message';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { batchCloseDetailsRequest, batchCloseDetailsResetState } from 'reducer/batch/batch-close-details/actions';
import { closeBatchRequest, closeBatchResetState } from 'reducer/batch/close-batch/actions';
import { useBatchCloseDetails, useRootDispatch } from 'reducer/hooks';
import './modal-close-details.scss';

interface BatchModalCloseDetailsProps {
    batchId: number;
    setModalCloseDetails: (value: boolean) => void;
    modalCloseDetails?: boolean;
    batchCode?: string;
}

const I18N_PREFIX = 'pages.scd.batch.batch-modal-close-details';

// TODO AB#5837 retire obsolete Batch Close flow

const BatchModalCloseDetails = (props: BatchModalCloseDetailsProps) => {
    const { batchId, modalCloseDetails, setModalCloseDetails, batchCode } = props;
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { batchCloseInformations } = useBatchCloseDetails();

    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [totalOperations, setTotalOperations] = useState<number | undefined>(batchCloseInformations?.countOperations);
    const [totalPaymentsErrors, setTotalPaymentsErrors] = useState<number | undefined>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCloseModal = () => {
        setModalCloseDetails(false);
        setIsChecked(false);
    };

    const handleCloseBatch = () => {
        if (!batchId) return;
        dispatch(closeBatchRequest(batchId));
        setModalCloseDetails(false);
    };

    useEffect(() => {
        setIsLoading(true);
        if (!batchId || !modalCloseDetails) return;
        dispatch(batchCloseDetailsRequest(batchId));
    }, [dispatch, batchId, modalCloseDetails]);

    useEffect(() => {
        if (!batchCloseInformations) return;
        setTotalOperations(batchCloseInformations.countOperations);
        setTotalPaymentsErrors(batchCloseInformations.countOperationsWithPaymentError);
        setIsLoading(false);
    }, [batchCloseInformations]);

    useEffect(() => {
        return () => {
            dispatch(closeBatchResetState());
            dispatch(batchCloseDetailsResetState());
        };
    }, [dispatch]);

    const isDisable = !isChecked && !!totalPaymentsErrors;

    const renderPaymenrErrorsQuantities = () => (
        <div className="modal-close-details--batch-errors">
            <div className="modal-close-details--batch-errors--outlined-icon" />
            <div className="modal-close-details--batch-errors--text">
                <Trans i18nKey={`${I18N_PREFIX}.payment-errors.total`}>
                    {t(`${I18N_PREFIX}.payment-errors.total`, {
                        payments: totalPaymentsErrors ?? 0,
                        count: totalPaymentsErrors ?? 0
                    })}
                </Trans>
            </div>
        </div>
    );

    const renderEmptyPaymentErrors = () => (
        <div className="modal-close-details--batch-errors-empty">{t(`${I18N_PREFIX}.payment-errors.total_zero`)}</div>
    );

    const hasPaymentErrors = !!totalPaymentsErrors && totalPaymentsErrors > 0;

    return (
        <>
            {modalCloseDetails && (
                <>
                    {!isLoading ? (
                        <ModalMessage
                            onClose={handleCloseModal}
                            onCancel={handleCloseModal}
                            standardButtonLabel={hasPaymentErrors ? 'entity.action.back' : 'entity.action.confirm'}
                            outlinedButtonLabel={hasPaymentErrors ? 'entity.action.confirm' : 'entity.action.back'}
                            title={t(`${I18N_PREFIX}.title`)}
                            onAction={handleCloseBatch}
                            disabled={isDisable}
                            isWarningModal={hasPaymentErrors}
                        >
                            <div className="modal-close-details--batch-code">
                                <div
                                    className="modal-close-details--batch-code--title">{t(`${I18N_PREFIX}.batch-code`)}</div>
                                <div className="modal-close-details--batch-code--number">{batchCode}</div>
                            </div>

                            <div className="modal-close-details--batch-operations">
                                <div className="modal-close-details--batch-operations--title">
                                    {t(`${I18N_PREFIX}.operations.total-title`)}
                                </div>
                                <div className="modal-close-details--batch-operations--number">
                                    {t(`${I18N_PREFIX}.operations.total`, {
                                        operations: totalOperations ?? 0,
                                        count: totalOperations ?? 0
                                    })}
                                </div>
                            </div>

                            {hasPaymentErrors ? renderPaymenrErrorsQuantities() : renderEmptyPaymentErrors()}
                            <div
                                className={clsx('modal-close-details--batch-warning-message', { HAS_ERROR: hasPaymentErrors })}>
                                {t(`${I18N_PREFIX}.warning-message`)}
                            </div>
                            {!!hasPaymentErrors && (
                                <div className="modal-close-details__checkbox">
                                    <Checkbox color="primary" onChange={() => setIsChecked(state => !state)} />{' '}
                                    {t(`${I18N_PREFIX}.checkbox`)}
                                </div>
                            )}
                        </ModalMessage>
                    ) : (
                        <Loading />
                    )}
                </>
            )}
        </>
    );
};

export default BatchModalCloseDetails;
