import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import { TechnicalSupplierMoreOptionsType } from 'entities/technical-supplier/technical-supplier';
import { TechnicalSupplierStatus } from 'model/enums/technical-supplier-status';
import { TechnicalSupplierType } from 'model/enums/technical-supplier-type';
import { ScdTechnicalSupplier } from 'model/technical-supplier';

interface TechnicalSupplierMoreOptionsMenuItemsProps {
    selectedTechnicalSupplier: ScdTechnicalSupplier | undefined;
    setModalOptionType: (value: TechnicalSupplierMoreOptionsType | undefined) => void;
    handleCloseMenu: () => void;
}

const I18N_PREFIX = 'pages.scd.technical-supplier.more-options';

const TechnicalSupplierMoreOptionsMenuItems = (props: TechnicalSupplierMoreOptionsMenuItemsProps) => {
    const { selectedTechnicalSupplier, handleCloseMenu, setModalOptionType } = props;

    const handleModalMessageAction = (action: () => void) => () => {
        handleCloseMenu();
        action();
    };

    return (
        <>
            {selectedTechnicalSupplier?.status &&
                (
                    {
                        ACTIVE: (
                            <>
                                <ModalActionMenuItem
                                    show={
                                        !selectedTechnicalSupplier?.isDefaultPaymentInstitution &&
                                        selectedTechnicalSupplier?.type === TechnicalSupplierType.PAYMENT_INSTITUTION
                                    }
                                    titleKey={`${I18N_PREFIX}.defaultPaymentInstitution.title`}
                                    onClick={handleModalMessageAction(() => setModalOptionType('defaultPaymentInstitution'))}
                                />
                            </>
                        ),
                    } as Record<TechnicalSupplierStatus, React.ReactNode>
                )[selectedTechnicalSupplier?.status]}
        </>
    );
};

export default TechnicalSupplierMoreOptionsMenuItems;
