import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { GiroWebappError } from 'model/error';
import { ListCompanyGroupPoliciesTypes } from 'reducer/company-group-policy/list-company-group-policies/types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listCompanyGroupPoliciesRequest = (request?: Pageable) =>
    action(ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_REQUEST, request);

export const listCompanyGroupPoliciesSuccess = (companyGroupPolicies: Page<ScdCompanyGroupPolicy>) =>
    action(ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_SUCCESS, companyGroupPolicies);

export const listCompanyGroupPoliciesError = (error: GiroWebappError) =>
    action(ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_ERROR, error);

export const listCompanyGroupPoliciesResetState = () => action(ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_RESET);
