import { ComponentType } from 'react';
import { RouteProps } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import { Environment, EnvironmentUtils } from 'shared/util/environment-utils';

type AnyComponent = ComponentType<any>;

interface IVersionedRouteProps extends Omit<RouteProps, 'component'> {
    production: AnyComponent;
    staging: AnyComponent;
    development: AnyComponent;
    test?: AnyComponent;
}

const current_environment = EnvironmentUtils.getEnvironment();

export const VersionedPrivateRoute = (props: IVersionedRouteProps) => {
    const { production, staging, development, test, ...routerProps } = props;

    const environtment_record: Record<Environment, AnyComponent> = {
        production: production,
        staging: staging,
        development: development,
        test: test ?? development,
    };

    return <PrivateRoute {...routerProps} component={environtment_record[current_environment]} />;
};

export default VersionedPrivateRoute;
