import { AxiosResponse } from 'axios'
import { ProgramOutputPoliciesToGetAll } from 'model/program-output-policy'
import api from 'services/api/api'
import { BASE_URL_ADMIN } from 'services/page'


export const programOutputPolicyApi = () => {
    const listProgramOutputPolicy = (programId: string): Promise<AxiosResponse<ProgramOutputPoliciesToGetAll[]>> => {
        return api.get<ProgramOutputPoliciesToGetAll[]>(`${BASE_URL_ADMIN}/programs/${programId}/output-policies`)
    }

    return {
        listProgramOutputPolicy,
    }
}

export default programOutputPolicyApi();

