import { AxiosResponse } from 'axios';
import { ScdBatchSimple } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { sendBatchCnabDocumentError, sendBatchCnabDocumentRequest, sendBatchCnabDocumentSuccess } from 'reducer/batch/send-cnab-document/actions';
import { SendBatchCnabDocumentTypes } from 'reducer/batch/send-cnab-document/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSendBatchCnabDocument(action: ReturnType<typeof sendBatchCnabDocumentRequest>) {
    try {
        const result: AxiosResponse<ScdBatchSimple> = yield call(batchAPI.sendBatchCnabDocument, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(sendBatchCnabDocumentError(mapped));
            return;
        }
        yield put(sendBatchCnabDocumentSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(sendBatchCnabDocumentError(mapped));
    }
}

function* watchSendBatchCnabDocument() {
    yield takeLeading(SendBatchCnabDocumentTypes.SEND_BATCH_CNAB_DOCUMENT_REQUEST, handleSendBatchCnabDocument);
}

function* sendBatchCnabDocumentSaga() {
    yield all([fork(watchSendBatchCnabDocument)]);
}

export default sendBatchCnabDocumentSaga;
