import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionsToArchive } from 'model/payment-transaction';
import { ArchivePaymentTransactionsTypes } from 'reducer/payment-transaction/archive-payment-transactions/types';
import { action } from 'typesafe-actions';

export const archivePaymentTransactionsRequest = (request: ScdPaymentTransactionsToArchive) =>
    action(ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_REQUEST, request);
export const archivePaymentTransactionsSuccess = () => action(ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_SUCCESS);
export const archivePaymentTransactionsError = (error: GiroWebappError) =>
    action(ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_ERROR, error);
export const archivePaymentTransactionsResetState = () => action(ArchivePaymentTransactionsTypes.ARCHIVE_PAYMENT_TRANSACTIONS_RESET);
