import { AxiosResponse } from 'axios';
import { BankList } from 'model/bank';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import bankAPI from 'services/api/bank-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';
import { listBanksError, listBanksRequest, listBanksSuccess } from './actions';
import { ListBanksTypes } from './types';

function* handleListBanks(action: ReturnType<typeof listBanksRequest>) {
    try {
        const result: AxiosResponse<Page<BankList>> = yield call(bankAPI.listBanks, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listBanksError(mapped));
            return;
        }
        yield put(listBanksSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listBanksError(mapped));
    }
}

function* watchListBanks() {
    yield takeEvery(ListBanksTypes.LIST_BANKS_REQUEST, handleListBanks);
}

function* listBanksSaga() {
    yield all([fork(watchListBanks)]);
}

export default listBanksSaga;
