import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdLegacyPaymentTransactionData } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import {
    createPaymentTransactionError,
    createPaymentTransactionRequest,
    createPaymentTransactionSuccess,
} from 'reducer/payment-transaction/create-payment-transaction/actions';
import { CreatePaymentTransactionTypes } from 'reducer/payment-transaction/create-payment-transaction/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreatePaymentTransaction(action: ReturnType<typeof createPaymentTransactionRequest>) {
    try {
        const result: AxiosResponse<ScdLegacyPaymentTransactionData> = yield call(paymentTransactionAPI.createPaymentTransaction, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createPaymentTransactionError(mapped));
            return;
        }
        yield put(createPaymentTransactionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createPaymentTransactionError(mapped));
    }
}

function* watchCreatePaymentTransaction() {
    yield takeLeading(CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_REQUEST, handleCreatePaymentTransaction);
}

function* createPaymentTransactionSaga() {
    yield all([fork(watchCreatePaymentTransaction)]);
}

export default createPaymentTransactionSaga;
