import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ImportedOperationsToCreateBatchTypes,
    ImportedOperationsToCreateBatchState,
} from 'reducer/imported-operation/to-create-batch/types';
import { Reducer } from 'redux';

export const initialState: ImportedOperationsToCreateBatchState = {
    status: HttpRequestStatus.NOOP,
    operationsToCreateBatch: undefined,
    error: undefined,
};

const reducer: Reducer<ImportedOperationsToCreateBatchState> = (state = initialState, action): ImportedOperationsToCreateBatchState => {
    switch (action.type) {
        case ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, operationsToCreateBatch: action.payload, error: undefined };
        }
        case ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as importedOperationsToCreateBatchReducer };
