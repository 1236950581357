import { UserDetailData } from 'model/user';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum UserDetailTypes {
    USER_DETAIL_REQUEST = '@@user-detail/USER_DETAIL_REQUEST',
    USER_DETAIL_SUCCESS = '@@user-detail/USER_DETAIL_SUCCESS',
    USER_DETAIL_ERROR = '@@user-detail/USER_DETAIL_ERROR',
    USER_DETAIL_RESET = '@@user-detail/USER_DETAIL_RESET',
}

export interface UserDetailState extends BaseStoreState {
    readonly userDetail?: UserDetailData
}