import { useExternalBalancePaymentInstitutionAccountState } from 'reducer/hooks';

const useGetExternalBalanceStateById = (accountId: number) => {
    const { externalBalances } = useExternalBalancePaymentInstitutionAccountState();

    const { status, balance, error } = externalBalances?.[accountId] ?? {};

    return {
        status,
        balance,
        error,
    };
};

export default useGetExternalBalanceStateById;
