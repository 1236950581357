import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { listTechnicalSuppliersError, listTechnicalSuppliersSuccess, listTechnicalSuppliersRequest } from './actions';
import { ListTechnicalSuppliersTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import technicalSupplierApi from 'services/api/technical-supplier-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';

function* handleListTechnicalSuppliers(action: ReturnType<typeof listTechnicalSuppliersRequest>) {
    try {
        const result: AxiosResponse<Page<ScdTechnicalSupplier>> = yield call(technicalSupplierApi.listTechnicalSuppliers, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listTechnicalSuppliersError(mapped));
            return;
        }
        yield put(listTechnicalSuppliersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listTechnicalSuppliersError(mapped));
    }
}

function* watchListTechnicalSuppliers() {
    yield takeEvery(ListTechnicalSuppliersTypes.LIST_TECHNICAL_SUPPLIERS_REQUEST, handleListTechnicalSuppliers);
}

function* listTechnicalSuppliersSaga() {
    yield all([fork(watchListTechnicalSuppliers)]);
}

export default listTechnicalSuppliersSaga;
