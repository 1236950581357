import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DetailSessionInterestRateTypes, DetailSessionInterestRateState } from './types';
import { Reducer } from 'redux';

export const initialState: DetailSessionInterestRateState = {
    status: HttpRequestStatus.NOOP,
    detailSessionInterestRate: undefined,
    error: undefined,
};

const reducer: Reducer<DetailSessionInterestRateState> = (state = initialState, action): DetailSessionInterestRateState => {
    switch (action.type) {
        case DetailSessionInterestRateTypes.DETAIL_SESSION_INTEREST_RATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case DetailSessionInterestRateTypes.DETAIL_SESSION_INTEREST_RATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, detailSessionInterestRate: action.payload, error: undefined };
        }
        case DetailSessionInterestRateTypes.DETAIL_SESSION_INTEREST_RATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailSessionInterestRateTypes.DETAIL_SESSION_INTEREST_RATE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailSessionInterestRateReducer };
