import ContextRibbon from 'components/context-ribbon/context-ribbon';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDetailIssuerState, useRootDispatch } from 'reducer/hooks';
import { detailIssuerRequest, detailIssuerResetState } from 'reducer/issuer/detail-issuer/actions';
import './issuer-detail.scss';

type ScdIssuerDetailProps = RouteComponentProps<{ issuerId: string }>;

const I18N_PREFIX = 'pages.scd.issuer.input';

const ScdIssuerDetailPage = (props: ScdIssuerDetailProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [issuerId] = useState<number | undefined>(props.match.params.issuerId ? Number(props.match.params.issuerId) : undefined);

    const { status, issuer } = useDetailIssuerState();

    useEffect(() => {
        if (!issuerId) return;

        dispatch(detailIssuerRequest(issuerId));
        return () => {
            dispatch(detailIssuerResetState());
        };
    }, [dispatch, issuerId]);

    const isLoading = status !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-issuer__detail">
            <ContextRibbon action={() => history.push('/issuers')} />
            <div className="scd-issuer--container">
                <header className="scd-issuer--header">
                    <h2 className="scd-issuer--header-title">{t('pages.scd.issuer.detail.title')}</h2>
                </header>
                <div className="scd-issuer__detail--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={issuer?.name ?? '-'} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.identification.label`)}
                                value={
                                    issuer?.identification ? (
                                        <IdentificationFormatter value={issuer.identification} type={PersonType.CORPORATION} />
                                    ) : (
                                        '-'
                                    )
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.status.label`)}
                                value={issuer?.status ? t(`${I18N_PREFIX}.status.options.${issuer?.status}`) : '-'}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdIssuerDetailPage;
