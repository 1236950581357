import { ScdRequestLogDetail } from 'model/request-log';
import { GiroWebappError } from 'model/error';
import { DetailRequestLogTypes } from './types';
import { action } from 'typesafe-actions';

export const detailRequestLogRequest = (requestLogId: number) => action(DetailRequestLogTypes.DETAIL_REQUEST_LOG_REQUEST, requestLogId);
export const detailRequestLogSuccess = (requestLog: ScdRequestLogDetail) =>
    action(DetailRequestLogTypes.DETAIL_REQUEST_LOG_SUCCESS, requestLog);
export const detailRequestLogError = (error: GiroWebappError) => action(DetailRequestLogTypes.DETAIL_REQUEST_LOG_ERROR, error);
export const detailRequestLogResetState = () => action(DetailRequestLogTypes.DETAIL_REQUEST_LOG_RESET_STATE);
