import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdIssuerSearch } from 'model/issuer';
import { markError } from 'reducer/application/error/actions';
import { searchIssuersError, searchIssuersRequest, searchIssuersSuccess } from 'reducer/issuer/search-issuers/actions';
import { SearchIssuersTypes } from 'reducer/issuer/search-issuers/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import issuerApi from 'services/api/issuer-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSearchIssuers(action: ReturnType<typeof searchIssuersRequest>) {
    try {
        const result: AxiosResponse<Page<ScdIssuerSearch>> = yield call(issuerApi.searchIssuers, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchIssuersError(mapped));
            return;
        }
        yield put(searchIssuersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchIssuersError(mapped));
    }
}

function* watchSearchIssuers() {
    yield takeEvery(SearchIssuersTypes.SEARCH_ISSUERS_REQUEST, handleSearchIssuers);
}

function* searchIssuersSaga() {
    yield all([fork(watchSearchIssuers)]);
}

export default searchIssuersSaga;
