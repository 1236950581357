import BalanceFallback from 'shared/external/payment-accounts-strategy/balance/components/fallback/fallback';
import { ExternalBalanceProps } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';

// TODO: Add redux/context strategy
// TODO: Add "request details" component
const CelCoinBalance = (props: ExternalBalanceProps) => {
    return <BalanceFallback {...props} />;
};

export default CelCoinBalance;
