import { SessionInterestRateResponse } from './../../../model/session-interest-rates';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum RecalculateSessionInterestRateTypes {
    RECALCULATE_SESSION_INTEREST_RATE_REQUEST = '@@session-interest-rate-recalculate/RECALCULATE_SESSION_INTEREST_RATE_REQUEST',
    RECALCULATE_SESSION_INTEREST_RATE_SUCCESS = '@@session-interest-rate-recalculate/RECALCULATE_SESSION_INTEREST_RATE_SUCCESS',
    RECALCULATE_SESSION_INTEREST_RATE_ERROR = '@@session-interest-rate-recalculate/RECALCULATE_SESSION_INTEREST_RATE_ERROR',
    RECALCULATE_SESSION_INTEREST_RATE_RESET_STATE = '@@session-interest-rate-recalculate/RECALCULATE_SESSION_INTEREST_RATE_RESET_STATE',
}

export interface RecalculateSessionInterestRateState extends BaseStoreState {
    readonly sessionInterestRateRecalculate?: SessionInterestRateResponse;
}
