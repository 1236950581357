import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import createNumberMask from 'components/inputs/number-mask';
import React from 'react';
import MaskedInput from 'react-text-mask';

const sessionTaxMask = createNumberMask({
    decimalLimit: 15,
    prefix: '',
    suffix: '',
    integerLimit: 3,
    includeThousandsSeparator: false,
    allowNegative: true,
});

export const SessionTaxMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={sessionTaxMask} guide={false} keepCharPositions={false} />;
};

export default SessionTaxMask;
