import { AxiosResponse } from 'axios';
import {
    ScdMonthlyCommissionData,
    ScdMonthlyCommissionDeleteDocumentRequest,
    ScdMonthlyCommissionDeleteTaxDocumentRequest,
    ScdMonthlyCommissionDetail,
    ScdMonthlyCommissionDocumentSimple,
    ScdMonthlyCommissionDownloadDocumentRequest,
    ScdMonthlyCommissionDownloadTaxDocumentRequest,
    ScdMonthlyCommissionGenerateReport,
    ScdMonthlyCommissionRectifyTaxRequest,
    ScdMonthlyCommissionSimple,
    ScdMonthlyCommissionUploadDocumentRequest,
    ScdMonthlyCommissionUploadTaxDocumentRequest,
} from 'model/monthly-commission';
import { MonthlyCommissionFilterPageable } from 'model/reducers';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const monthlyCommission = () => {
    const listMonthlyCommissions = (request: MonthlyCommissionFilterPageable): Promise<AxiosResponse<Page<ScdMonthlyCommissionData>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.grossValue?.minValue && filter?.grossValue?.maxValue
                ? `grossValue=gte(${filter?.grossValue?.minValue})&grossValue=and(lte(${filter?.grossValue?.maxValue}))`
                : undefined,
            filter?.grossValue?.minValue && !filter?.grossValue?.maxValue ? `grossValue=gte(${filter?.grossValue?.minValue})` : undefined,
            filter?.grossValue?.maxValue && !filter?.grossValue?.minValue ? `grossValue=lte(${filter?.grossValue?.maxValue})` : undefined,

            filter?.yearMonth?.initialDate && filter?.yearMonth?.finalDate
                ? `yearMonth=gte(${filter?.yearMonth?.initialDate})&yearMonth=and(lte(${filter?.yearMonth?.finalDate}))`
                : undefined,
            filter?.yearMonth?.initialDate && !filter?.yearMonth?.finalDate
                ? `yearMonth=gte(${filter?.yearMonth?.initialDate})`
                : undefined,
            filter?.yearMonth?.finalDate && !filter?.yearMonth?.initialDate ? `yearMonth=lte(${filter?.yearMonth?.finalDate})` : undefined,

            filter?.status && `status=contains(${filter?.status})`,

            filter?.originator?.companyId && `company.id=eq(${filter.originator.companyId})`,

            filter?.organization?.originatorId && `originator.id=eq(${filter.organization.originatorId})`,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdMonthlyCommissionData>>(`api/monthly-commissions?${_requestFilters}`, { params: { ...pageable } });
    };

    const detailMonthlyCommission = (commissionId: number): Promise<AxiosResponse<ScdMonthlyCommissionDetail>> => {
        return api.get<ScdMonthlyCommissionDetail>(`api/monthly-commissions/${commissionId}`);
    };

    const generateReport = (request: ScdMonthlyCommissionGenerateReport): Promise<AxiosResponse<ScdMonthlyCommissionSimple>> => {
        return api.post<ScdMonthlyCommissionSimple>(`api/monthly-commissions/${request.id}/generate`, request);
    };

    const uploadDocument = (
        request: ScdMonthlyCommissionUploadDocumentRequest
    ): Promise<AxiosResponse<ScdMonthlyCommissionDocumentSimple>> => {
        return api.post<ScdMonthlyCommissionDocumentSimple>(
            `api/monthly-commissions/${request.commissionId}/documents/${request.type}`,
            request.document
        );
    };

    const downloadDocument = (request: ScdMonthlyCommissionDownloadDocumentRequest): Promise<AxiosResponse<Uint8Array>> => {
        return api.get<Uint8Array>(
            `api/monthly-commissions/${request.commissionId}/documents/${request.type}/${request.documentId}/download`,
            { responseType: 'arraybuffer' }
        );
    };

    const deleteDocument = (request: ScdMonthlyCommissionDeleteDocumentRequest): Promise<AxiosResponse<void>> => {
        return api.delete<void>(`api/monthly-commissions/${request.commissionId}/documents/${request.type}/${request.documentId}`);
    };

    const uploadTaxDocument = (
        request: ScdMonthlyCommissionUploadTaxDocumentRequest
    ): Promise<AxiosResponse<ScdMonthlyCommissionDocumentSimple>> => {
        return api.post<ScdMonthlyCommissionDocumentSimple>(
            `api/monthly-commissions/${request.commissionId}/tax-values/${request.taxValueId}/documents/${request.type}`,
            request.document
        );
    };

    const downloadTaxDocument = (request: ScdMonthlyCommissionDownloadTaxDocumentRequest): Promise<AxiosResponse<Uint8Array>> => {
        return api.get<Uint8Array>(
            `api/monthly-commissions/${request.commissionId}/tax-values/${request.taxValueId}/documents/${request.type}/${request.documentId}/download`,
            { responseType: 'arraybuffer' }
        );
    };

    const deleteTaxDocument = (request: ScdMonthlyCommissionDeleteTaxDocumentRequest): Promise<AxiosResponse<void>> => {
        return api.delete<void>(
            `api/monthly-commissions/${request.commissionId}/tax-values/${request.taxValueId}/documents/${request.type}/${request.documentId}`
        );
    };

    const rectifyTaxes = (request: ScdMonthlyCommissionRectifyTaxRequest): Promise<AxiosResponse<ScdMonthlyCommissionSimple>> => {
        return api.patch<ScdMonthlyCommissionSimple>(`api/monthly-commissions/${request.id}/tax-values/rectify`, request);
    };

    const releaseMonthlyCommission = (commissionId: number): Promise<AxiosResponse<ScdMonthlyCommissionSimple>> => {
        return api.patch<ScdMonthlyCommissionSimple>(`api/monthly-commissions/${commissionId}/release`);
    };

    return {
        listMonthlyCommissions,
        detailMonthlyCommission,
        generateReport,
        uploadDocument,
        downloadDocument,
        deleteDocument,
        uploadTaxDocument,
        downloadTaxDocument,
        deleteTaxDocument,
        rectifyTaxes,
        releaseMonthlyCommission,
    };
};

export default monthlyCommission();
