import { IconButton } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleLocalDateInput from 'components/inputs/date-input/simple-local-date-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { defaultScdDisbursementReport, ScdDisbursementReport } from 'model/disbursement-report';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdImportedOperationDisbursementReportData } from 'model/imported-operation';
import React, { useEffect, useMemo, useState } from 'react';
import { CSVDownload } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useImportedOperationDisbursementReportState, useRootDispatch } from 'reducer/hooks';
import { importedOperationDisbursementReportRequest } from 'reducer/imported-operation/disbursement-report/actions';
import './disbursement-report.scss';

const I18N_PREFIX = 'pages.scd.disbursement-report';

type ReportHeader = Record<keyof ScdImportedOperationDisbursementReportData, string>;

const ScdCompanyGroupPolicyPage = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const [disbursementReport, setDisbursementReport] = useState<ScdDisbursementReport>(defaultScdDisbursementReport);
    const [identification, setIdentification] = useState<string | undefined>(undefined);
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

    const { status, disbursementReport: disbursementReportData } = useImportedOperationDisbursementReportState();

    const handleSave = () => {
        dispatch(importedOperationDisbursementReportRequest(disbursementReport));
    };

    const handleChange = (value: Partial<ScdDisbursementReport>) => {
        setDisbursementReport(ps => ({ ...ps, ...value }));
    };

    const handleAddIdentification = () => {
        if (!identification) return;

        disbursementReport.identifications?.push(identification);
        setIdentification(undefined);
    };

    const handleDeleteIdentification = (deletedIndex: number) => {
        const filteredData = disbursementReport.identifications?.filter((_value, index) => index !== deletedIndex);
        handleChange({ identifications: filteredData });
    };

    const handleResetInputs = () => {
        setDisbursementReport({ createDatePeriod: undefined, identifications: [], originator: undefined });
        setIdentification(undefined);
    };

    const REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            identification: t(`${I18N_PREFIX}.report.header.operations.identification`),
            value: t(`${I18N_PREFIX}.report.header.operations.value`),
            retention: t(`${I18N_PREFIX}.report.header.operations.retention`),
            iof: t(`${I18N_PREFIX}.report.header.operations.iof`),
            createdDate: t(`${I18N_PREFIX}.report.header.operations.createdDate`),
        };
    }, [t]);

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && disbursementReportData && !disbursementReportData?.operations.length) {
            setOpenSnackbar(true);
        }
    }, [setOpenSnackbar, status, disbursementReportData]);

    return (
        <>
            <main className="scd-disbursement-report">
                <section className="scd-disbursement-report__container">
                    <header className="scd-disbursement-report--header">
                        <h2 className="scd-disbursement-report--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                    </header>
                    <form className="scd-disbursement-report--form">
                        <div className="scd-disbursement-report--form-field">
                            <SimpleLocalDateInput
                                label={`${I18N_PREFIX}.input.createDatePeriod.start.label`}
                                placeholder={`${I18N_PREFIX}.input.createDatePeriod.start.placeholder`}
                                value={disbursementReport.createDatePeriod?.start!}
                                onChange={startDatePeriod =>
                                    handleChange({
                                        createDatePeriod: { ...disbursementReport.createDatePeriod, start: startDatePeriod },
                                    })
                                }
                                externalUpdate
                            />
                        </div>
                        <div className="scd-disbursement-report--form-field">
                            <SimpleLocalDateInput
                                label={`${I18N_PREFIX}.input.createDatePeriod.end.label`}
                                placeholder={`${I18N_PREFIX}.input.createDatePeriod.end.placeholder`}
                                value={disbursementReport.createDatePeriod?.end!}
                                onChange={endDatePeriod =>
                                    handleChange({ createDatePeriod: { ...disbursementReport.createDatePeriod, end: endDatePeriod } })
                                }
                                externalUpdate
                            />
                        </div>
                        <div className="scd-disbursement-report--form-field">
                            <SimpleTextInput
                                label={`${I18N_PREFIX}.input.originator.label`}
                                placeholder={`${I18N_PREFIX}.input.originator.placeholder`}
                                value={disbursementReport.originator ?? ''}
                                onChange={originator => handleChange({ originator })}
                                externalUpdate
                            />
                        </div>
                        <div className="scd-disbursement-report--form-field">
                            <SimpleTextInput
                                label={`${I18N_PREFIX}.input.identification.label`}
                                placeholder={`${I18N_PREFIX}.input.identification.placeholder`}
                                value={identification ?? ''}
                                onChange={setIdentification}
                                externalUpdate
                            />
                            <Tooltip title={t('tooltips.add') ?? ''} aria-label="add">
                                <div className="scd-disbursement-report--form-field__icon" onClick={handleAddIdentification}>
                                    <AddIcon />
                                </div>
                            </Tooltip>
                        </div>

                        {disbursementReport?.identifications &&
                            disbursementReport.identifications?.length > 0 &&
                            disbursementReport.identifications?.map((value, index) => {
                                return (
                                    <Chip
                                        key={index}
                                        variant="outlined"
                                        color="primary"
                                        label={value}
                                        onDelete={() => handleDeleteIdentification(index)}
                                    />
                                );
                            })}
                        <div className="scd-originator--form-buttons">
                            <OutlinedButtonLegacy label={`${I18N_PREFIX}.buttons.clean`} onClick={handleResetInputs} />
                            <StandardButtonLegacy label={`${I18N_PREFIX}.buttons.generate-csv`} onClick={handleSave} />
                        </div>
                    </form>
                </section>
                <Snackbar
                    message={t(`${I18N_PREFIX}.error-message`)}
                    open={openSnackbar}
                    autoHideDuration={5000}
                    onClose={() => setOpenSnackbar(false)}
                    action={action}
                />
            </main>
            {status === HttpRequestStatus.SUCCESS && !!disbursementReportData?.operations.length && (
                <CSVDownload
                    data={disbursementReportData?.operations ?? []}
                    headers={Object.keys(REPORT_HEADERS).map(key => ({ key, label: REPORT_HEADERS[key] }))}
                />
            )}
        </>
    );
};

export default ScdCompanyGroupPolicyPage;
