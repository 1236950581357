import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';
import ScdTechnicalSupplierPage from 'entities/technical-supplier/technical-supplier';
import ScdTechnicalSupplierCreateUpdatePage from 'entities/technical-supplier/technical-supplier-create-update/technical-supplier-create-update';
import ScdTechnicalSupplierDetailPage from 'entities/technical-supplier/technical-supplier-detail/technical-supplier-detail';

interface MatchParams {
    url: string;
}

const TechnicalSupplierRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdTechnicalSupplierCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:technicalSupplierId`} component={ScdTechnicalSupplierDetailPage} />
        <PrivateRoute exact path={`${match.url}/:technicalSupplierId/edit`} component={ScdTechnicalSupplierCreateUpdatePage} />

        <PrivateRoute exact path={`${match.url}`} component={ScdTechnicalSupplierPage} />
    </Switch>
);

export default TechnicalSupplierRoutes;
