import { PopperProps, Tooltip } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { BatchCardType } from 'entities/batch/components/batch-card/batch-card';
import ErrorCard from 'entities/batch/components/batch-card/cards/error/error-card';
import LoadingCard from 'entities/batch/components/batch-card/cards/loading/loading-card';
import { Link } from 'react-router-dom';
import useBatchResetState from 'shared/provider/useBathResetState';
import './default-card.scss';

export interface DefaultCardProps {
    type?: BatchCardType.DEFAULT;
    title: string;
    link: string;
    isLoading?: boolean;
    hasError?: boolean;
    handleRefresh?: () => void;
    children?: React.ReactNode;
    options?: React.ReactNode;
}

const DefaultCard = (props: DefaultCardProps) => {
    const { title, link, isLoading, hasError, handleRefresh, children, options } = props;

    const resetState = useBatchResetState();

    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    const handleClick = (event: React.MouseEvent) => {
        if (event.ctrlKey) return;
        resetState();
    };

    if (isLoading) {
        return <LoadingCard />;
    }

    if (hasError) {
        return <ErrorCard type={BatchCardType.ERROR} title={title} handleRefresh={handleRefresh} />;
    }

    return (
        <div className="default-card">
            <div className="default-card__title">
                <Tooltip title={title} PopperProps={popperProps}>
                    <span className="default-card__title--tooltip">{title}</span>
                </Tooltip>
                <Link to={link} onClick={(event: React.MouseEvent) => handleClick(event)}>
                    <div className="default-card__title--icon">
                        <ChevronRightIcon />
                    </div>
                </Link>
            </div>
            <div className="default-card__display">
                <div className="default-card__info">{children}</div>
                <div>{options}</div>
            </div>
        </div>
    );
};

export default DefaultCard;
