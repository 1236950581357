import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import './request-log.scss';
import { IRootState } from 'reducer';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import RequestLogDetailsModal
    from 'entities/request-log/components/request-log-details-modal/request-log-details-modal';
import RequestLogHeaderBrs from 'entities/request-log/request-log-header-brs/request-log-header-brs';
import RequestLogsListBrs from 'entities/request-log/request-log-list-brs/request-log-list-brs';
import { requestLogListBrsRequest } from 'reducer-brs/request-log/request-log-list/actions';
import { RequestLogFilterPageableBrs, RequestLogModelBrs } from 'model/brs/request-log-brs';


const I18N_PREFIX = 'pages.scd.request-log';

export const RequestLogBrs = () => {
    const { t } = useTranslation();

    const [page, setPage] = useState<number>(0);
    const [requestLogData, setRequestLogData] = useState<RequestLogModelBrs | undefined>(undefined);

    const filterPageable = useCallback((): RequestLogFilterPageableBrs => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `requestDate,DESC`
        };


        return {
            pageable: _pageable
        };
    }, [page]);


    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const { InfiniteScrollButton, items, isLoading, handleRefresh, status } =
        useInfiniteScroll<RequestLogModelBrs>({
            handlePageChange,
            currentPage: page,
            dataSelector: (state: IRootState) => state.listRequestLogsBrs.requestLogList,
            statusSelector: (state: IRootState) => state.listRequestLogsBrs.status,
            action: requestLogListBrsRequest,
            filterPageable,
            setPage
        });


    const hasError = status === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.request-log.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }

    return (
        <main className="scd-request-log">
            <section className="scd-request-log__container">
                <header className="scd-request-log__header">
                    <div className="scd-request-log__header-container">
                        <div style={{ display: 'flex' }}>
                            <h2 className="scd-request-log__header-container--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                            <RefreshButton onClick={handleRefresh} />
                        </div>
                    </div>
                </header>
                <div className="scd-request-log__table">
                    <table className="page-container--table">
                        <thead>
                        <RequestLogHeaderBrs />
                        </thead>
                        <tbody>
                        <RequestLogsListBrs isLoading={isLoading} logs={items} setRequestLogData={setRequestLogData} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {requestLogData &&
                <RequestLogDetailsModal requestLog={requestLogData} onClose={() => setRequestLogData(undefined)} />}
        </main>
    );
};

export default RequestLogBrs;
