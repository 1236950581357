import { PixKeyType } from 'model/enums/pix-key-type';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';

export const pixKeyFormatter = (type: PixKeyType | undefined, value: string | undefined) => {
    switch (type) {
        case PixKeyType.EVP:
        case PixKeyType.EMAIL:
        case PixKeyType.PHONE:
            return value;
        case PixKeyType.CPF:
            return <IdentificationFormatter value={value} type={PersonType.INDIVIDUAL} />;
        case PixKeyType.CNPJ:
            return <IdentificationFormatter value={value} type={PersonType.CORPORATION} />;
        default:
            return '-';
    }
};
