import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountConvertExclusive, PaymentInstitutionAccountSimpleToUpdate } from 'model/payment-institution-account';
import { ConvertPaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/convert-payment-institution-account/types';
import { action } from 'typesafe-actions';

export const convertPaymentInstitutionAccountRequest = (request: PaymentInstitutionAccountConvertExclusive) =>
    action(ConvertPaymentInstitutionAccountTypes.CONVERT_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, request);
export const convertPaymentInstitutionAccountSuccess = (paymentInstitutionAccount: PaymentInstitutionAccountSimpleToUpdate) =>
    action(ConvertPaymentInstitutionAccountTypes.CONVERT_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS, paymentInstitutionAccount);
export const convertPaymentInstitutionAccountError = (error: GiroWebappError) =>
    action(ConvertPaymentInstitutionAccountTypes.CONVERT_PAYMENT_INSTITUTION_ACCOUNT_ERROR, error);
export const convertPaymentInstitutionAccountResetState = () =>
    action(ConvertPaymentInstitutionAccountTypes.CONVERT_PAYMENT_INSTITUTION_ACCOUNT_RESET);
