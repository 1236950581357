import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum MonthlyCommissionGenerateReportTypes {
    MONTHLY_COMMISSION_GENERATE_REPORT_REQUEST = '@@monthly-commission-generate-report/MONTHLY_COMMISSION_GENERATE_REPORT_REQUEST',
    MONTHLY_COMMISSION_GENERATE_REPORT_SUCCESS = '@@monthly-commission-generate-report/MONTHLY_COMMISSION_GENERATE_REPORT_SUCCESS',
    MONTHLY_COMMISSION_GENERATE_REPORT_ERROR = '@@monthly-commission-generate-report/MONTHLY_COMMISSION_GENERATE_REPORT_ERROR',
    MONTHLY_COMMISSION_GENERATE_REPORT_RESET = '@@monthly-commission-generate-report/MONTHLY_COMMISSION_GENERATE_REPORT_RESET',
}

export interface MonthlyCommissionGenerateReportState extends BaseStoreState {
    readonly monthlyCommission?: ScdMonthlyCommissionSimple;
}
