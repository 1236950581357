import { action } from 'typesafe-actions';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { OriginatorsByPaymentInstitutionToSearch } from 'model/payment-institution-account';
import { SearchOriginatorsByPaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/types';

export const searchOriginatorsByPaymentInstitutionAccountRequest = (request: SearchRequest) => action(SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, request);
export const searchOriginatorsByPaymentInstitutionAccountSuccess = (originatorsByPaymentInstitutionAccount: Page<OriginatorsByPaymentInstitutionToSearch>) => action(SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS, originatorsByPaymentInstitutionAccount);
export const searchOriginatorsByPaymentInstitutionAccountError = (error: GiroWebappError) => action(SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_ERROR, error);
export const searchOriginatorsByPaymentInstitutionAccountResetState = () => action(SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_RESET);
