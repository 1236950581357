import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useTranslation } from 'react-i18next';
import { useUserAccountState } from 'reducer/hooks';
import './home.scss';

const Home = () => {
    const { t } = useTranslation();
    const { account, status } = useUserAccountState();

    const isLoading = status !== HttpRequestStatus.SUCCESS;

    return isLoading ? (
        <div style={{ position: 'relative' }}>
            <Loading />
        </div>
    ) : (
        <main className="home">
            <article className="home__container">
                <header className="home__header">
                    <h1 className="home__header--title">{t('pages.home.title', { account_name: account?.firstName })}</h1>
                </header>
            </article>
        </main>
    );
};

export default Home;
