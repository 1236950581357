
import {CessionToSimpleDTO} from "model/bco/batch-output-cession-integration-webcred";
import {BaseStoreState} from "reducer/base-store-state";

export enum ProcessCessionOperationBcoTypes {
    PROCESS_CESSION_OPERATION_BCO_REQUEST = '@@process-cession-operation-bco/PROCESS_CESSION_OPERATION_BCO_REQUEST',
    PROCESS_CESSION_OPERATION_BCO_SUCCESS = '@@process-cession-operation-bco/PROCESS_CESSION_OPERATION_BCO_SUCCESS',
    PROCESS_CESSION_OPERATION_BCO_ERROR = '@@process-cession-operation-bco/PROCESS_CESSION_OPERATION_BCO_ERROR',
    PROCESS_CESSION_OPERATION_BCO_RESET = '@@process-cession-operation-bco/PROCESS_CESSION_OPERATION_BCO_RESET',
}

export interface ProcessCessionOperationBcoState extends BaseStoreState {
    readonly processCessionOperation?: CessionToSimpleDTO;
}
