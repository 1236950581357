import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ChunkStreamRetryBcoState, ChunkStreamRetryBcoTypes } from 'reducer-bco/batch-output/retry-chunk-stream/types';
import { Reducer } from 'redux';

const initialState: ChunkStreamRetryBcoState = {
    status: HttpRequestStatus.NOOP,
    chunkStreamRetryBco: undefined,
    error: undefined,
};

const reducer: Reducer<ChunkStreamRetryBcoState> = (state = initialState, action): ChunkStreamRetryBcoState => {
    switch (action.type) {
        case ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, chunkStreamRetryBco: action.payload, error: undefined };
        }
        case ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as chunkStreamRetryBcoReducer };
