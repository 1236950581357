import { AxiosResponse } from 'axios';
import { ScdBatchCommissionReport } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    batchCommissionReportError,
    batchCommissionReportRequest,
    batchCommissionReportSuccess,
} from 'reducer/batch/commission-report/actions';
import { BatchCommissionReportTypes } from 'reducer/batch/commission-report/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchCommissionReport(action: ReturnType<typeof batchCommissionReportRequest>) {
    try {
        const result: AxiosResponse<ScdBatchCommissionReport> = yield call(batchAPI.getCommissionReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchCommissionReportError(mapped));
            return;
        }
        yield put(batchCommissionReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchCommissionReportError(mapped));
    }
}

function* watchBatchCommissionReport() {
    yield takeLeading(BatchCommissionReportTypes.BATCH_COMMISSION_REPORT_REQUEST, handleBatchCommissionReport);
}

function* batchCommissionReportSaga() {
    yield all([fork(watchBatchCommissionReport)]);
}

export default batchCommissionReportSaga;
