import Loading from 'components/loading/loading';
import ProgramRow, { ProgramRowProps } from 'entities/program/components/program-row/program-row';
import { ScdProgramList } from 'model/program';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface ProgramListProps {
    programsItems: ScdProgramList[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.program';

const ProgramList = (props: ProgramListProps) => {
    const { programsItems, isLoading } = props;

    const { t } = useTranslation();

    const programRowObjectData: ProgramRowProps = {
        programsItems,
    };

    return (
        <>
            {isLoading && !programsItems.length && <Loading isTable />}
            {programsItems.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT}>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={programRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={programsItems.length}
                            className="page-container--table-container"
                        >
                            {ProgramRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-program-groups__table-not-found">
                            <span className="scd-program-groups__table-not-found-image" />
                            <span className="scd-program-groups__table-not-found-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ProgramList;
