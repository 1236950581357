import Loading from 'components/loading/loading';
import PaymentTransactionRow from 'entities/payment-transaction/components/payment-transaction-row/payment-transaction-row';
import { PaymentTransactionFilterRequest, ScdPaymentTransactionData } from 'model/payment-transaction';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { LIST_HEIGHT, LIST_ITEMS_SIZE } from 'shared/infinite-components/constants';
import { valuesOfObject } from 'shared/util/object-utils';

interface ListPaymentTransactionsProps {
    filterActive: PaymentTransactionFilterRequest;
    isNewTransaction: boolean;
    paymentTransactions: ScdPaymentTransactionData[];
    isLoading: boolean;
    selectedPaymentTransactionId: number | undefined;
    setSelectedPaymentTransactionId: (value: number | undefined) => void;
}

export interface PaymentTransactionsRowProps {
    paymentTransactions: ScdPaymentTransactionData[];
    isNewTransaction: boolean;
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: HTMLElement | null) => void;
    selectedPaymentTransactionId: number | undefined;
    setSelectedPaymentTransactionId: (value: number | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction';

const ListPaymentTransactions = (props: ListPaymentTransactionsProps) => {
    const {
        filterActive,
        isNewTransaction,
        paymentTransactions,
        isLoading,
        selectedPaymentTransactionId,
        setSelectedPaymentTransactionId,
    } = props;
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const paymentTransactionsRowObjectData: PaymentTransactionsRowProps = {
        paymentTransactions,
        isNewTransaction,
        anchorEl,
        setAnchorEl,
        selectedPaymentTransactionId,
        setSelectedPaymentTransactionId,
    };

    return (
        <>
            {isLoading && !paymentTransactions.length && <Loading isTable />}
            {paymentTransactions?.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={paymentTransactionsRowObjectData}
                            height={height}
                            width={width}
                            itemSize={LIST_ITEMS_SIZE}
                            itemCount={paymentTransactions.length}
                            style={{ top: '13px' }}
                        >
                            {PaymentTransactionRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-payment-transaction__table-empty-filter">
                            <span className="scd-payment-transaction__table-empty-filter-image" />
                            <span className="scd-payment-transaction__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                            <span className="scd-payment-transaction__table-empty-filter-subtitle">
                                {valuesOfObject({ ...filterActive }).length !== 0 && t(`${I18N_PREFIX}.invalid-filter`)}
                            </span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ListPaymentTransactions;
