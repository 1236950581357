import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import { BatchOutputDetailsBco } from 'model/bco/batch-output';
import { useTranslation } from 'react-i18next';

interface BatchOutputDetailHeaderProps {
    batchOutputDetails: BatchOutputDetailsBco;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.general-header';

const BatchOutputDetailHeader = (props: BatchOutputDetailHeaderProps) => {
    const { t } = useTranslation();

    const { batchOutputDetails } = props;

    return (
        <BatchDetailHeader>
            <h1 className="batch-outputs-detail__container-title">{batchOutputDetails.policy.name}</h1>
            <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.titles.policy`)} isTooltip>
                {batchOutputDetails?.policy?.name ?? '-'}
            </BatchDetailHeaderContent>
        </BatchDetailHeader>
    );
};

export default BatchOutputDetailHeader;
