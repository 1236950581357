import BackButton from 'components/buttons/back-button/back-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import { OperationsModalMessageActions } from 'entities/ccb-operation/ccb-operation';
import ScdCreateBatchOperationsHeader from 'entities/ccb-operation/ccb-operation-create-batch/components/create-batch-operations-header/create-batch-operations-header';
import CreateBatchOperationsList from 'entities/ccb-operation/ccb-operation-create-batch/components/create-batch-operations-list/create-batch-operations-list';
import CreateBatchOperationsSlide from 'entities/ccb-operation/ccb-operation-create-batch/components/create-batch-operations-slide/create-batch-operations-slide';
import { ImportedOperationsDataSortableProperties } from 'entities/ccb-operation/components/operation-header/operation-header';
import { CreateBatchFilterRequest } from 'entities/ccb-operation/components/operation-modal-filter/operation-modal-filter';
import ErrorSvg from 'images/error.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdImportedOperationToCreateBatchRequest } from 'model/imported-operation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { createBatchByOperationsRequest, createBatchByOperationsResetState } from 'reducer/batch/create-batch-by-operations/actions';
import { useCreateBatchByOperationsState, useImportedOperationToCreateBatchState, useRootDispatch } from 'reducer/hooks';
import {
    importedOperationsToCreateBatchRequest,
    importedOperationsToCreateBatchResetState,
} from 'reducer/imported-operation/to-create-batch/actions';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import './ccb-operation-create-batch.scss';

const I18N_PREFIX = 'pages.scd.ccb-operation';

export const CcbOperationCreateBatch = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const [sortedProperty, setSortedProperty] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [isSelectedAllCheckbox, setIsSelectedAllCheckbox] = useState<boolean>(false);
    const [selectedOperations, setSelectedOperations] = useState<Set<number>>(new Set([]));
    const [modalMessage, setModalMessage] = useState<OperationsModalMessageActions | undefined>(undefined);
    const [operationsAmount, setOperationsAmount] = useState<number>(0);

    const { toastSuccess } = useToast();

    const history = useHistory();

    const location = useLocation<CreateBatchFilterRequest>();

    const { status: createBatchStatus } = useCreateBatchByOperationsState();
    const { status: listStatus, operationsToCreateBatch } = useImportedOperationToCreateBatchState();

    const verifyAllSelectedOperations = useMemo((): boolean => {
        return new Set(operationsToCreateBatch?.operations).size === selectedOperations.size;
    }, [operationsToCreateBatch, selectedOperations]);

    const handleListOperationsToCreateBatch = useCallback(() => {
        if (location.state?.programId) {
            const { programId, createdDate = undefined } = location.state;

            const _request: ScdImportedOperationToCreateBatchRequest = {
                filter: {
                    programId,
                    createdDate: createdDate ?? undefined,
                },
                sort: sortedProperty && sortOrder ? `${sortedProperty},createdDate,${sortOrder}` : '',
            };

            dispatch(importedOperationsToCreateBatchRequest(_request));
        }
    }, [location.state, sortedProperty, sortOrder, dispatch]);

    useEffect(() => {
        handleListOperationsToCreateBatch();
        return () => {
            dispatch(importedOperationsToCreateBatchResetState());
        };
    }, [dispatch, handleListOperationsToCreateBatch]);

    useEffect(() => {
        if (createBatchStatus === HttpRequestStatus.SUCCESS) {
            dispatch(createBatchByOperationsResetState());
            toastSuccess(t(`${I18N_PREFIX}.toast.${modalMessage}.success`));
            setSelectedOperations(new Set([]));
            setModalMessage(undefined);
            history.goBack();
        }
        if (createBatchStatus === HttpRequestStatus.ERROR) {
            setModalMessage(OperationsModalMessageActions.CREATE_BATCH_ERROR);
            dispatch(createBatchByOperationsResetState());
        }
    }, [createBatchStatus, dispatch, toastSuccess, t, modalMessage, history]);

    useEffect(() => {
        if (!operationsToCreateBatch?.operations.length) {
            setIsSelectedAllCheckbox(false);
            return;
        }

        if (verifyAllSelectedOperations) {
            setIsSelectedAllCheckbox(true);
            return;
        }

        setIsSelectedAllCheckbox(false);
    }, [selectedOperations, operationsToCreateBatch, verifyAllSelectedOperations]);

    const handleOperationsAmount = useCallback(() => {
        const amountValue: number =
            operationsToCreateBatch?.operations
                .filter(it => Array.from(selectedOperations).includes(it.id))
                .reduce((acc, cur) => cur.totalValue + acc, 0) ?? 0;

        setOperationsAmount(amountValue);
    }, [operationsToCreateBatch, setOperationsAmount, selectedOperations]);

    useEffect(() => {
        handleOperationsAmount();
    }, [handleOperationsAmount]);

    const hasError = listStatus === HttpRequestStatus.ERROR || !location.state?.programId;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.ccb-operation.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListOperationsToCreateBatch} />
            </div>
        );
    }

    const handleSort = (property: ImportedOperationsDataSortableProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty(undefined);
            setSortOrder(undefined);
        }
    };

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const modalMessageOnAction = {
        CANCEL: () => {
            setModalMessage(undefined);
            setSelectedOperations(new Set([]));
            history.goBack();
        },
        CREATE_BATCH: () => {
            if (location.state?.programId) {
                dispatch(
                    createBatchByOperationsRequest({
                        importedOperationsIds: Array.from(selectedOperations),
                        programId: location.state?.programId,
                    })
                );
            }
        },
    } as Record<OperationsModalMessageActions, () => void>;

    return (
        <main className="scd-ccb-operation-create-batch">
            <ContextRibbon />
            <section className="scd-ccb-operation-create-batch__container">
                <header className="scd-ccb-operation-create-batch__header">
                    <div className="scd-ccb-operation-create-batch__header-container">
                        <h2 className="scd-ccb-operation-create-batch__header-container--title">{t(`${I18N_PREFIX}.createBatch`)}</h2>
                    </div>
                </header>
                <div className="scd-ccb-operation-create-batch__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdCreateBatchOperationsHeader
                                operations={operationsToCreateBatch?.operations ?? []}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                                isSelectedAllCheckbox={isSelectedAllCheckbox}
                                setIsSelectedAllCheckbox={setIsSelectedAllCheckbox}
                                setSelectedOperations={setSelectedOperations}
                                disabledCheckboxAll={operationsToCreateBatch?.operations.length === 0}
                            />
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <Loading isTable />
                            ) : (
                                <CreateBatchOperationsList
                                    isSelectedAllCheckbox={isSelectedAllCheckbox}
                                    selectedOperations={selectedOperations}
                                    setSelectedOperations={setSelectedOperations}
                                />
                            )}
                        </tbody>
                    </table>
                </div>
                <CreateBatchOperationsSlide
                    selectedOperationsAmount={selectedOperations?.size ?? 0}
                    setModalMessage={setModalMessage}
                    totalAmountOperations={operationsAmount}
                />
                {modalMessage && (
                    <ModalMessageLegacy
                        icon={
                            modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR ? (
                                <img src={ErrorSvg} alt="Error" style={{ height: '100px' }} />
                            ) : undefined
                        }
                        onClose={() => setModalMessage(undefined)}
                        onCancel={() => setModalMessage(undefined)}
                        onAction={modalMessageOnAction[modalMessage]}
                        title={t(`${I18N_PREFIX}.modal.${modalMessage}.title`)}
                        message={t(`${I18N_PREFIX}.modal.${modalMessage}.message`, { count: selectedOperations.size })}
                        outlinedButtonLabel={
                            modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR
                                ? t('entity.action.try-again')
                                : t('entity.action.cancel')
                        }
                        outlinedButtonSize={modalMessage === OperationsModalMessageActions.CREATE_BATCH_ERROR ? 'large' : undefined}
                        disabled={createBatchStatus === HttpRequestStatus.ON_GOING}
                    />
                )}
            </section>
        </main>
    );
};

export default CcbOperationCreateBatch;
