import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ValidateManualDisbursementPaymentTransactionState, ValidateManualDisbursementPaymentTransactionTypes } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/types';

const initialState: ValidateManualDisbursementPaymentTransactionState = {
    status: HttpRequestStatus.NOOP,
    manualDisbursementValidation: undefined,
    error: undefined,
};

const reducer: Reducer<ValidateManualDisbursementPaymentTransactionState> = (state = initialState, action): ValidateManualDisbursementPaymentTransactionState => {
    switch (action.type) {
        case ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, manualDisbursementValidation: action.payload, error: undefined };
        }
        case ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as validateManualDisbursementPaymentTransactionReducer };
