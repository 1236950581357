import { ChunkStreamToResumeBco, ChunkStreamToResumeRequest } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { ChunkStreamToResumeBcoTypes } from 'reducer-bco/batch-output/chunk-stream-to-resume/types';
import { action } from 'typesafe-actions';

export const chunkStreamToResumeBcoRequest = (request: ChunkStreamToResumeRequest) =>
    action(ChunkStreamToResumeBcoTypes.CHUNK_STREAM_TO_RESUME_BCO_REQUEST, request);
export const chunkStreamToResumeBcoSuccess = (chunkStreamToResumeBco: ChunkStreamToResumeBco) =>
    action(ChunkStreamToResumeBcoTypes.CHUNK_STREAM_TO_RESUME_BCO_SUCCESS, chunkStreamToResumeBco);
export const chunkStreamToResumeBcoError = (error: GiroWebappError) =>
    action(ChunkStreamToResumeBcoTypes.CHUNK_STREAM_TO_RESUME_BCO_ERROR, error);
export const chunkStreamToResumeBcoResetState = () => action(ChunkStreamToResumeBcoTypes.CHUNK_STREAM_TO_RESUME_BCO_RESET);
