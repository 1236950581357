import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdOriginatorListType } from 'entities/originator/originator';
import { ScdOriginatorList } from 'model/originator';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.originator.table';

interface ScdOriginatorHeaderProps {
    originators: ScdOriginatorList[];
    handleSort: (property: ScdOriginatorListType, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdOriginatorHeader = (props: ScdOriginatorHeaderProps) => {
    const { t } = useTranslation();
    const { originators, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: ScdOriginatorListType) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: originators && originators.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.paymentInstitution.name`).toUpperCase()} />
        </tr>
    );
};

export default ScdOriginatorHeader;
