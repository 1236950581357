import { Grid } from '@material-ui/core';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { PersonType } from 'model/enums/person-type';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface SupplementaryDataOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.supplementaryData';

const SupplementaryDataOperationCard = (props: SupplementaryDataOperationCardProps) => {
    const {
        operation: { supplementaryData },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.gender.label`)}
                    value={t(`${I18N_PREFIX}.fields.gender.options.${supplementaryData?.gender}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.education.label`)}
                    value={t(`${I18N_PREFIX}.fields.education.options.${supplementaryData?.education}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.nationality`)} value={supplementaryData?.nationality} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.martialStatus.label`)}
                    value={t(`${I18N_PREFIX}.fields.martialStatus.options.${supplementaryData?.martialStatus}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.spouseName`)} value={supplementaryData?.spouseName} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.spouseIdentification`)}
                    value={
                        supplementaryData?.spouseIdentification ? (
                            <IdentificationFormatter value={supplementaryData?.spouseIdentification} type={PersonType.INDIVIDUAL} />
                        ) : (
                            '-'
                        )
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.spouseDateBirth`)}
                    value={supplementaryData?.spouseDateBirth ? <DateFormatter date={supplementaryData?.spouseDateBirth} /> : '-'}
                />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.dependents.label`)}
                    value={
                        supplementaryData?.dependentsNumber && supplementaryData?.dependentsNumber > 0
                            ? t(`${I18N_PREFIX}.fields.dependents.options.yes`)
                            : t(`${I18N_PREFIX}.fields.dependents.options.no`)
                    }
                />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.dependentsNumber`)}
                    value={supplementaryData?.dependentsNumber}
                />
            </Grid>
        </BaseOperationCard>
    );
};

export default SupplementaryDataOperationCard;
