import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionDocumentSimple } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import { detailMonthlyCommissionRequest } from 'reducer/monthly-commission/detail-monthly-commission/actions';
import {
    monthlyCommissionDeleteTaxDocumentError,
    monthlyCommissionDeleteTaxDocumentRequest,
    monthlyCommissionDeleteTaxDocumentSuccess,
    monthlyCommissionDownloadTaxDocumentError,
    monthlyCommissionDownloadTaxDocumentRequest,
    monthlyCommissionDownloadTaxDocumentSuccess,
    monthlyCommissionUploadTaxDocumentError,
    monthlyCommissionUploadTaxDocumentRequest,
    monthlyCommissionUploadTaxDocumentSuccess,
} from 'reducer/monthly-commission/tax-document/actions';
import { MonthlyCommissionTaxDocumentTypes } from 'reducer/monthly-commission/tax-document/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleMonthlyCommissionUploadTaxDocument(action: ReturnType<typeof monthlyCommissionUploadTaxDocumentRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionDocumentSimple> = yield call(
            monthlyCommissionAPI.uploadTaxDocument,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionUploadTaxDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionUploadTaxDocumentSuccess(result.data));
        yield put(detailMonthlyCommissionRequest(action.payload.commissionId));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionUploadTaxDocumentError(mapped));
    }
}

function* watchMonthlyCommissionUploadTaxDocument() {
    yield takeLeading(
        MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_REQUEST,
        handleMonthlyCommissionUploadTaxDocument
    );
}

function* handleMonthlyCommissionDownloadTaxDocument(action: ReturnType<typeof monthlyCommissionDownloadTaxDocumentRequest>) {
    try {
        const result: AxiosResponse<Uint8Array> = yield call(monthlyCommissionAPI.downloadTaxDocument, action.payload);

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionDownloadTaxDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionDownloadTaxDocumentSuccess(result.data));

        const _file_name = result.headers['content-disposition']?.split('filename=')[1];
        const _type = result.headers['content-type'];
        const _blob = new Blob([result.data], { type: _type });
        const _url = window.URL.createObjectURL(_blob);
        const _link: HTMLAnchorElement = document.createElement('a');
        _link.href = _url;
        _link.setAttribute('download', _file_name);
        document.body.appendChild(_link);
        _link.click();
        _link.remove();
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionDownloadTaxDocumentError(mapped));
    }
}

function* watchMonthlyCommissionDownloadTaxDocument() {
    yield takeLeading(
        MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_REQUEST,
        handleMonthlyCommissionDownloadTaxDocument
    );
}

function* handleMonthlyCommissionDeleteTaxDocument(action: ReturnType<typeof monthlyCommissionDeleteTaxDocumentRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(monthlyCommissionAPI.deleteTaxDocument, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(monthlyCommissionDeleteTaxDocumentError(mapped));
            return;
        }
        yield put(monthlyCommissionDeleteTaxDocumentSuccess());
        yield put(detailMonthlyCommissionRequest(action.payload.commissionId));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(monthlyCommissionDeleteTaxDocumentError(mapped));
    }
}

function* watchMonthlyCommissionDeleteTaxDocument() {
    yield takeLeading(
        MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_REQUEST,
        handleMonthlyCommissionDeleteTaxDocument
    );
}

function* monthlyCommissionTaxDocumentSaga() {
    yield all([
        fork(watchMonthlyCommissionUploadTaxDocument),
        fork(watchMonthlyCommissionDownloadTaxDocument),
        fork(watchMonthlyCommissionDeleteTaxDocument),
    ]);
}

export default monthlyCommissionTaxDocumentSaga;
