import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { BatchModalFilterType } from 'entities/batch/components/batch-modal-filter/batch-modal-filter';
import { BatchFilterRequest } from 'model/batch';
import React from 'react';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch.filter.buttons';

interface BatchFilterButtonsProps {
    setModalFilter: (value: BatchModalFilterType) => void;
    setOpenModalFilter: (value: boolean) => void;
    filterActive: BatchFilterRequest;
    handleResetFilterActive: () => void;
    disabled: boolean;
}

const BatchFilterButtons = (props: BatchFilterButtonsProps) => {
    const { setModalFilter, setOpenModalFilter, filterActive, handleResetFilterActive, disabled } = props;
    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const showRemoveAllIcon = filterActive.originator || filterActive.product || filterActive.status;

    return (
        <>
            <div className="scd-batch__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('product');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.product`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.product ? true : false}
                    disabled={disabled}
                />
            </div>
            <div className="scd-batch__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('originator');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.originator`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.originator ? true : false}
                    disabled={disabled}
                />
            </div>
            <div className="scd-batch__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('status');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.status ? true : false}
                    disabled={disabled}
                />
            </div>
            {showRemoveAllIcon && !disabled ? (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-batch__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    );
};

export default BatchFilterButtons;
