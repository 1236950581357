import { ScdMonthlyCommissionDocumentSimple } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum MonthlyCommissionTaxDocumentTypes {
    MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_REQUEST = '@@monthly-commission-upload-tax-value-document/MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_SUCCESS = '@@monthly-commission-upload-tax-value-document/MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_ERROR = '@@monthly-commission-upload-tax-value-document/MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_REQUEST = '@@monthly-commission-download-tax-value-document/MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_SUCCESS = '@@monthly-commission-download-tax-value-document/MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_ERROR = '@@monthly-commission-download-tax-value-document/MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_REQUEST = '@@monthly-commission-delete-tax-value-document/MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_SUCCESS = '@@monthly-commission-delete-tax-value-document/MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_ERROR = '@@monthly-commission-delete-tax-value-document/MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_TAX_DOCUMENT_RESET = '@@monthly-commission-tax-value-document/MONTHLY_COMMISSION_TAX_DOCUMENT_RESET',
}

export interface MonthlyCommissionTaxDocumentState extends BaseStoreState {
    readonly uploadTaxDocument?: ScdMonthlyCommissionDocumentSimple;
    readonly downloadTaxDocument?: Uint8Array;
}
