import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreatePaymentTransactionTypes, CreatePaymentTransactionState } from 'reducer/payment-transaction/create-payment-transaction/types';

const initialState: CreatePaymentTransactionState = {
    status: HttpRequestStatus.NOOP,
    paymentTransaction: undefined,
    error: undefined,
};

const reducer: Reducer<CreatePaymentTransactionState> = (state = initialState, action): CreatePaymentTransactionState => {
    switch (action.type) {
        case CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransaction: action.payload, error: undefined };
        }
        case CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSATION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreatePaymentTransactionTypes.CREATE_PAYMENT_TRANSACTION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createPaymentTransactionReducer };
