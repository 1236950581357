import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdProgramPolicy } from 'model/program-policy';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
const I18N_PREFIX = 'pages.scd.program-policy';

export interface ProgramPolicyRowProps {
    programPolicy: ScdProgramPolicy[];
}

const ProgramPolicyRow = (props: ListChildComponentProps<ProgramPolicyRowProps>) => {
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const { index, data, style } = props;

    const { programPolicy } = data;

    const { id, scdProgram, policyType } = programPolicy[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{scdProgram?.name}</td>
            <td className="page-container--table-cell-title">
                {policyType ? t(`${I18N_PREFIX}.input.policyType.options.${policyType}`) : '-'}
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default ProgramPolicyRow;
