import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DetailCompanyItemTypes, DetailCompanyItemState } from 'reducer/company-item/detail-company-item/types';

const initialState: DetailCompanyItemState = {
    status: HttpRequestStatus.NOOP,
    companyItem: undefined,
    error: undefined,
};

const reducer: Reducer<DetailCompanyItemState> = (state = initialState, action): DetailCompanyItemState => {
    switch (action.type) {
        case DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyItem: action.payload, error: undefined };
        }
        case DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailCompanyItemTypes.DETAIL_COMPANY_ITEM_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailCompanyItemReducer };
