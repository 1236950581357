import { Grid } from '@material-ui/core';
import BatchCard, { BatchCardType } from 'entities/batch/components/batch-card/batch-card';

interface BatchErrorCardProps {
    title: string;
    handleRefresh: () => void;
}

const BatchErrorCard = ({ title, handleRefresh }: BatchErrorCardProps) => {
    return (
        <Grid item>
            <BatchCard type={BatchCardType.ERROR} title={title} handleRefresh={handleRefresh} />
        </Grid>
    );
};

export default BatchErrorCard;
