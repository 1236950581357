import { IssuerPolicyIntegrationSimple } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CreateIntegrationIssuerPolicyTypes {
    ISSUER_POLICY_INTEGRATION_CREATE_REQUEST = '@@issuer-policy-integration-create/ISSUER_POLICY_INTEGRATION_CREATE_REQUEST',
    ISSUER_POLICY_INTEGRATION_CREATE_SUCCESS = '@@issuer-policy-integration-create/ISSUER_POLICY_INTEGRATION_CREATE_SUCCESS',
    ISSUER_POLICY_INTEGRATION_CREATE_ERROR = '@@issuer-policy-integration-create/ISSUER_POLICY_INTEGRATION_CREATE_ERROR',
    ISSUER_POLICY_INTEGRATION_CREATE_RESET_STATE = '@@issuer-policy-integration-create/ISSUER_POLICY_INTEGRATION_CREATE_RESET_STATE',
}

export interface CreateIntegrationIssuerPolicyState extends BaseStoreState {
    readonly policyIntegrationCreate?: IssuerPolicyIntegrationSimple;
}