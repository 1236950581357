import Loading from 'components/loading/loading';
import UserRow, { UsersRowProps } from 'entities/user/components/users-row/users-row';

import { UsersToGetAll } from 'model/user';
import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface UserListProps {
    users: UsersToGetAll[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.users.table';

const UsersList = (props: UserListProps) => {
    const { users, isLoading } = props;

    const { t } = useTranslation();

    const usersRowObjectData: UsersRowProps = {
        users,
    };

    return (
        <>
            {isLoading && !users.length && <Loading isTable />}
            {users.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={usersRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={users.length}
                            className="page-container--table-container"
                        >
                            {UserRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-users__table-empty-filter">
                            <span className="scd-users__table-empty-filter-image" />
                            <span className="scd-users__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default UsersList;
