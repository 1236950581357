import { PaymentInstitutionAccountToGetAll } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListPaymentInstitutionsAccountsTypes {
    LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-list/LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-list/LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-list/LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-list/LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface ListPaymentInstitutionsAccountsState extends BaseStoreState {
    readonly paymentInstitutionsAccounts?: Page<PaymentInstitutionAccountToGetAll>;
}
