export const BASE_URL_ADMIN = 'api/admin';
export const BASE_URL_BRS = 'api/gateway/brs/admin';
export const BASE_URL_BCO = 'api/gateway/bco/admin';

export interface Page<T> {
    content?: T[];
    fist: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    size: number;
    totalElements: number;
    totalPages: number;

    sort: {
        sorted: boolean;
        unsorted: boolean;
    };

    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
        };
        offset: number;
        pageSize: number;
        pageNumber: number;
        paged: boolean;
        unpaged: boolean;
    };
}
