import { Checkbox } from '@material-ui/core';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import CompletePaymentTransactionFilterButtons from 'entities/payment-transaction/complete-payment-transaction/components/complete-payment-transactions-filter-buttons/complete-payment-transactions-filter-buttons';
import CompletePaymentTransactionModalFilter, {
    CompletePaymentTransactionFilterType,
} from 'entities/payment-transaction/complete-payment-transaction/components/complete-payment-transactions-modal-filter/complete-payment-transactions-modal-filter';
import CompletePaymentTransactionsSlide from 'entities/payment-transaction/complete-payment-transaction/components/complete-payment-transactions-slide/complete-payment-transactions-slide';
import usePendingFilter from 'entities/payment-transaction/complete-payment-transaction/components/filter-hook/filter-hook';
import ProcessPaymentTransactionsModalError from 'entities/payment-transaction/complete-payment-transaction/components/process-payment-transactions-modal-error/process-payment-transactions-modal-error';
import ReadOnlyPaymentTransaction from 'entities/payment-transaction/complete-payment-transaction/components/read-only-payment-transaction/read-only-payment-transaction';
import useCompletePaymentFilterChips from 'entities/payment-transaction/complete-payment-transaction/hooks/use-complete-payment-filter-chips';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CompletePaymentTransactionFilterData, ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
    useArchivePaymentTransactionsState,
    useListPendingPaymentTransactionsState,
    useProcessPaymentTransactionsState,
    useRootDispatch,
} from 'reducer/hooks';
import {
    archivePaymentTransactionsRequest,
    archivePaymentTransactionsResetState,
} from 'reducer/payment-transaction/archive-payment-transactions/actions';
import {
    listPendingPaymentTransactionsRequest,
    listPendingPaymentTransactionsResetState,
} from 'reducer/payment-transaction/list-pending-payment-transactions/actions';
import {
    processPaymentTransactionsRequest,
    processPaymentTransactionsResetState,
} from 'reducer/payment-transaction/process-payment-transactions/actions';
import { useToast } from 'shared/hooks/use-toast';
import { valuesOfObject } from 'shared/util/object-utils';
import './complete-payment-transaction.scss';

const I18N_PREFIX = 'pages.scd.complete-payment-transactions';

export enum CompletePaymentTransactionsModalAction {
    ARCHIVE = 'ARCHIVE',
    PROCESS = 'PROCESS',
}

const CompletePaymentTransaction = () => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const defaultFilterActivePendingTransactions: Readonly<CompletePaymentTransactionFilterData> = {};

    const [openModal, setOpenModal] = useState<CompletePaymentTransactionsModalAction | undefined>(undefined);
    const [selectedTransactions, setSelectedTransactions] = useState<ScdPaymentTransactionPendingData[] | undefined>([]);
    const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(false);
    const [modalFilter, setModalFilter] = useState<CompletePaymentTransactionFilterType | undefined>(undefined);
    const [filterActive, setFilterActive] = useState<CompletePaymentTransactionFilterData>(defaultFilterActivePendingTransactions);
    const [isProcessErrorModalOpen, setIsProcessErrorModalOpen] = useState<boolean>(false);

    const { toastLoading, toastSuccess, closeToast } = useToast();

    const { pendingTransactions, status: pendingPaymentTransactionsStatus } = useListPendingPaymentTransactionsState();

    const [filteredData, setFilteredData] = useState<ScdPaymentTransactionPendingData[]>(pendingTransactions ?? []);
    const { completePaymentFilterChips } = useCompletePaymentFilterChips({
        filterActive,
        setFilterActive,
    });

    const { status: archiveStatus } = useArchivePaymentTransactionsState();
    const { status: processStatus, processedPaymentTransactions } = useProcessPaymentTransactionsState();
    const { filterItems } = usePendingFilter();

    const isFilterActive = valuesOfObject({ ...filterActive }).length !== 0;
    const isAllFilterActiveUndefined = valuesOfObject({ ...filterActive }).every(item => !item);

    const handleListPendingOperations = useCallback(() => {
        dispatch(listPendingPaymentTransactionsRequest());
    }, [dispatch]);

    const handleCloseProcessErrorModal = useCallback(() => {
        setIsProcessErrorModalOpen(false);
        dispatch(processPaymentTransactionsResetState());
        handleListPendingOperations();
    }, [dispatch, handleListPendingOperations]);

    useEffect(() => {
        handleListPendingOperations();
    }, [handleListPendingOperations]);

    useEffect(() => {
        return () => {
            dispatch(listPendingPaymentTransactionsResetState());
            dispatch(processPaymentTransactionsResetState());
            dispatch(archivePaymentTransactionsResetState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (pendingTransactions?.length !== 0) return;
        if (pendingTransactions === undefined) return;
        if (processedPaymentTransactions?.errors.length) return;

        history.push('/payment-transactions');
    }, [pendingTransactions, history, processedPaymentTransactions]);

    useEffect(() => {
        if (archiveStatus !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [archiveStatus, toastLoading]);

    useEffect(() => {
        if (archiveStatus !== HttpRequestStatus.ERROR) return;
        closeToast();
        setOpenModal(undefined);
    }, [archiveStatus, closeToast]);

    useEffect(() => {
        if (archiveStatus !== HttpRequestStatus.SUCCESS) return;
        closeToast();
        setOpenModal(undefined);
        toastSuccess(t(`${I18N_PREFIX}.toast.archive.success`));
        handleListPendingOperations();
    }, [archiveStatus, closeToast, t, dispatch, history, toastSuccess, handleListPendingOperations]);

    useEffect(() => {
        if (processStatus !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [processStatus, toastLoading]);

    useEffect(() => {
        if (processStatus !== HttpRequestStatus.ERROR) return;
        closeToast();
        setOpenModal(undefined);
    }, [processStatus, closeToast]);

    const onSuccessWithErrors = useCallback(() => {
        setOpenModal(undefined);
        setSelectedTransactions([]);
        setIsAllChecked(false);
        setIsProcessErrorModalOpen(true);
    }, []);

    useEffect(() => {
        if (processStatus !== HttpRequestStatus.SUCCESS) return;

        if (processedPaymentTransactions?.errors.length) {
            closeToast();
            onSuccessWithErrors();
            return;
        }

        closeToast();
        setOpenModal(undefined);
        toastSuccess(t(`${I18N_PREFIX}.toast.process.success`));
        handleListPendingOperations();
        dispatch(processPaymentTransactionsResetState());
    }, [
        processStatus,
        closeToast,
        t,
        dispatch,
        history,
        toastSuccess,
        handleListPendingOperations,
        onSuccessWithErrors,
        processedPaymentTransactions,
    ]);

    const handleCheckAll = useCallback(() => {
        if (!isAllChecked && !filteredData.length) {
            setSelectedTransactions(pendingTransactions);
            return setIsAllChecked(true);
        }

        if (!isAllChecked && filteredData.length) {
            setSelectedTransactions(filteredData);
            return setIsAllChecked(true);
        }

        setSelectedTransactions([]);
        return setIsAllChecked(false);
    }, [filteredData, pendingTransactions, isAllChecked]);

    const currentList = useMemo(() => {
        if (isFilterActive) return filteredData;

        return pendingTransactions;
    }, [filteredData, pendingTransactions, isFilterActive]);

    const checkIsSelected = useMemo(() => {
        const filtered = selectedTransactions?.filter(elem => {
            if (!currentList?.some(item => item.id === elem.id)) return false;
            return true;
        });

        if (filtered?.length) {
            return true;
        }

        return false;
    }, [currentList, selectedTransactions]);

    const validateAllCheckbox = useMemo((): boolean => {
        if (isAllChecked && currentList?.length === selectedTransactions?.length) {
            return true;
        }

        if (isAllChecked && currentList?.length !== selectedTransactions?.length) {
            return false;
        }

        if (!isAllChecked && currentList?.length === selectedTransactions?.length && isFilterActive) {
            return false;
        }

        if (!isAllChecked && currentList?.length === selectedTransactions?.length) {
            return true;
        }

        return false;
    }, [currentList, isAllChecked, isFilterActive, selectedTransactions]);

    useEffect(() => {
        setIsAllChecked(validateAllCheckbox);
    }, [validateAllCheckbox]);

    const filterOnCurrentList = useCallback(() => {
        const filtered = selectedTransactions?.filter(elem => {
            if (!currentList?.some(item => item.id === elem.id)) return false;
            return true;
        });

        setSelectedTransactions(filtered);
    }, [selectedTransactions, currentList]);

    useEffect(() => {
        if (selectedTransactions?.length === 0) return;

        if (!selectedTransactions || !currentList) return;

        if (selectedTransactions?.length <= currentList?.length && checkIsSelected) return;

        filterOnCurrentList();
    }, [checkIsSelected, currentList, selectedTransactions, filterOnCurrentList, filteredData, isFilterActive]);

    const idsArray = useMemo(() => {
        return selectedTransactions?.map(transaction => transaction.id) ?? [];
    }, [selectedTransactions]);

    const handlePaymentTransactionsRequest = {
        ARCHIVE: () => {
            dispatch(archivePaymentTransactionsRequest({ paymentTransactionIds: idsArray }));
        },
        PROCESS: () => {
            dispatch(processPaymentTransactionsRequest({ paymentTransactionIds: idsArray }));
        },
    } as Record<CompletePaymentTransactionsModalAction, () => void>;

    const handleResetFilterActive = () => {
        setFilterActive(defaultFilterActivePendingTransactions);
    };

    useEffect(() => {
        if (!isAllFilterActiveUndefined) return;

        setFilterActive({});
        setFilteredData(pendingTransactions ?? []);
    }, [isAllFilterActiveUndefined, pendingTransactions]);

    useEffect(() => {
        if (isAllFilterActiveUndefined) return;

        const filtered = filterItems(pendingTransactions ?? [], filterActive);
        setFilteredData(filtered);
    }, [filterItems, filterActive, isAllFilterActiveUndefined, pendingTransactions]);

    const disabledActionButton = processStatus === HttpRequestStatus.ON_GOING || archiveStatus === HttpRequestStatus.ON_GOING;

    const showSlide: boolean = !!selectedTransactions?.length;

    const isLoading = pendingPaymentTransactionsStatus !== HttpRequestStatus.SUCCESS;

    const disabledCheckbox = (isFilterActive && !filteredData.length) || !pendingTransactions?.length;

    return (
        <main className="scd-complete-payment-transactions">
            <ContextRibbon />
            <section className="scd-complete-payment-transactions__container">
                {isLoading ? (
                    <Loading />
                ) : (
                    <>
                        <header className="scd-complete-payment-transactions__header">
                            <h2 className="scd-complete-payment-transactions__header--title">{t(`${I18N_PREFIX}.title`)}</h2>
                            <div className="scd-complete-payment-transactions__header--buttons-content">
                                <CompletePaymentTransactionFilterButtons
                                    filterActive={filterActive}
                                    handleResetFilterActive={handleResetFilterActive}
                                    setModalFilter={setModalFilter}
                                    setOpenModalFilter={setOpenModalFilter}
                                />
                            </div>
                        </header>
                        <ChipFilterActive filters={completePaymentFilterChips} />
                        <div className="scd-complete-payment-transactions__content">
                            <div className="scd-complete-payment-transactions__content--infos">
                                <span>{t(`${I18N_PREFIX}.subtitle`)}</span>
                                <div>
                                    <Checkbox
                                        color="secondary"
                                        value={isAllChecked}
                                        onChange={handleCheckAll}
                                        checked={isAllChecked}
                                        disabled={disabledCheckbox}
                                    />
                                </div>
                            </div>
                            {!filteredData.length &&
                                !isFilterActive &&
                                pendingTransactions?.map(value => {
                                    return (
                                        <ReadOnlyPaymentTransaction
                                            key={value.id}
                                            paymentTransaction={value}
                                            isAllChecked={isAllChecked}
                                            setSelectedTransactions={setSelectedTransactions}
                                            selectedTransactions={selectedTransactions}
                                            currentList={currentList}
                                        />
                                    );
                                })}
                            {isFilterActive && !filteredData.length ? (
                                <div className="scd-complete-payment-transactions__table-empty-filter">
                                    <span className="scd-complete-payment-transactions__table-empty-filter-image" />
                                    <span className="scd-complete-payment-transactions__table-empty-filter-title">
                                        {t(`${I18N_PREFIX}.not-found`)}
                                    </span>
                                    <span className="scd-complete-payment-transactions__table-empty-filter-subtitle">
                                        {valuesOfObject({ ...filterActive }).length !== 0 && t(`${I18N_PREFIX}.invalid-filter`)}
                                    </span>
                                </div>
                            ) : (
                                filteredData.map(value => {
                                    return (
                                        <ReadOnlyPaymentTransaction
                                            key={value.id}
                                            paymentTransaction={value}
                                            isAllChecked={isAllChecked}
                                            setSelectedTransactions={setSelectedTransactions}
                                            selectedTransactions={selectedTransactions}
                                            currentList={currentList}
                                        />
                                    );
                                })
                            )}
                        </div>
                        {showSlide && (
                            <CompletePaymentTransactionsSlide selectedTransactions={selectedTransactions} setOpenModal={setOpenModal} />
                        )}
                    </>
                )}
            </section>

            {openModal && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.${openModal}.title`, {
                        transactions: selectedTransactions?.length ?? 0,
                        count: selectedTransactions?.length ?? 0,
                    })}
                    message={
                        openModal === CompletePaymentTransactionsModalAction.PROCESS &&
                        (t(`${I18N_PREFIX}.modal.${openModal}.message`, {
                            count: selectedTransactions?.length,
                        }) ??
                            '-')
                    }
                    onAction={handlePaymentTransactionsRequest[openModal]}
                    onCancel={() => setOpenModal(undefined)}
                    onClose={() => setOpenModal(undefined)}
                    disabled={disabledActionButton}
                />
            )}
            {modalFilter && pendingTransactions && (
                <CompletePaymentTransactionModalFilter
                    title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                    modalFilter={modalFilter}
                    openModalFilter={openModalFilter}
                    onClose={() => setOpenModalFilter(false)}
                    setFilterActive={setFilterActive}
                    filterActive={filterActive}
                    paymentTransactions={pendingTransactions}
                />
            )}
            {isProcessErrorModalOpen && (
                <ProcessPaymentTransactionsModalError
                    title={t(`${I18N_PREFIX}.modal.PROCESS_ERROR.title`)}
                    onClose={handleCloseProcessErrorModal}
                    pendingTransactions={pendingTransactions}
                    processedPaymentTransactions={processedPaymentTransactions}
                    onAction={handleCloseProcessErrorModal}
                />
            )}
        </main>
    );
};

export default CompletePaymentTransaction;
