import { OperationFareReports } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum FareAmountReportTypes {
    FARE_AMOUNT_REPORT_REQUEST = '@@imported-operations-monthly-processed-report/FARE_AMOUNT_REPORT_REQUEST',
    FARE_AMOUNT_REPORT_SUCCESS = '@@imported-operations-monthly-processed-report/FARE_AMOUNT_REPORT_SUCCESS',
    FARE_AMOUNT_REPORT_ERROR = '@@imported-operations-monthly-processed-report/FARE_AMOUNT_REPORT_ERROR',
    FARE_AMOUNT_REPORT_RESET_STATE = '@@imported-operations-monthly-processed-report/FARE_AMOUNT_REPORT_RESET_STATE',
}

export interface FareAmountReportState extends BaseStoreState {
    readonly fareAmountReportData?: OperationFareReports;
}
