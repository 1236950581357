import { CompanyBankAccount } from 'model/company-bank-account';
import { PersonType } from 'model/enums/person-type';
import { Pageable } from 'services/pageable';

export interface ScdCompany {
    id?: string;
    name?: string;
    tradeName?: string;
    identification?: string;
    type?: PersonType;
}

export interface ScdCompanySearch {
    id?: string;
    name?: string;
    identification?: string;
}

export interface ScdCompanyFilterRequest {
    name?: string;
    identification?: string;
}

export const defaultCompanyFilterRequest: Readonly<ScdCompanyFilterRequest> = {};

export interface ScdCompanyFilterPageable {
    pageable?: Pageable;
    filter?: ScdCompanyFilterRequest;
}

export interface OrganizationToCreateOrUpdateCompany {
    id?: number;
}

export interface ScdCompanyCreateUpdateRequest {
    id?: number;
    name?: string;
    identification?: string;
    tradeName?: string;
    type?: PersonType;
    accounts?: CompanyBankAccount[];
    organizations?: ScdCompany[];
}

export const defaultScdCompany: ScdCompanyCreateUpdateRequest = {
    id: undefined,
    name: undefined,
    identification: undefined,
    tradeName: undefined,
    type: undefined,
    accounts: [],
    organizations: [],
};

export interface ScdCompanyToSimple {
    id: number;
    name: string;
    identification: string;
    type: PersonType;
}

export interface ScdCompanyDetails {
    id?: number;
    name?: string;
    identification?: string;
    type?: PersonType;
    tradeName?: string;
    organizations?: ScdCompany[];
    accounts?: CompanyBankAccount[];
}
