import DefaultCard, { DefaultCardProps } from 'entities/batch/components/batch-card/cards/default/default-card';
import ErrorCard, { ErrorCardProps } from 'entities/batch/components/batch-card/cards/error/error-card';
import InternalErrorCard, { InternalErrorCardProps } from 'entities/batch/components/batch-card/cards/internal-error/internal-error-card';
import LoadingCard, { LoadingCardProps } from 'entities/batch/components/batch-card/cards/loading/loading-card';
import './batch-card.scss';

export enum BatchCardType {
    STATIC = 'STATIC',
    ERROR = 'ERROR',
    INTERNAL_ERROR = 'INTERNAL_ERROR',
    LOADING = 'LOADING',
    DEFAULT = 'DEFAULT',
}

type BatchCardProps = ErrorCardProps | LoadingCardProps | InternalErrorCardProps | DefaultCardProps;

const BatchCard = (props: BatchCardProps) => {
    const cardType = props.type ?? BatchCardType.DEFAULT;

    const card = {
        ERROR: <ErrorCard {...(props as ErrorCardProps)} />,
        INTERNAL_ERROR: <InternalErrorCard {...(props as InternalErrorCardProps)} />,
        LOADING: <LoadingCard />,
        DEFAULT: <DefaultCard {...(props as DefaultCardProps)} />,
    } as Record<BatchCardType, React.ReactElement>;

    return <div className="batch-card">{card[cardType]}</div>;
};

export default BatchCard;
