import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { Page } from 'services/page';
import { searchPlatformsError, searchPlatformsRequest, searchPlatformsSuccess } from 'reducer/platform/search/actions';
import { SearchPlatformsTypes } from 'reducer/platform/search/types';
import { SearchPlatform } from 'model/platform';
import platformApi from 'services/api/platform-api'

function* handleSearchPlatforms(action: ReturnType<typeof searchPlatformsRequest>) {
    try {
        const result: AxiosResponse<Page<SearchPlatform>> = yield call(platformApi.searchPlatforms, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchPlatformsError(mapped));
            return;
        }
        yield put(searchPlatformsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchPlatformsError(mapped));
    }
}

function* watchSearchPlatforms() {
    yield takeEvery(SearchPlatformsTypes.SEARCH_PLATFORM_REQUEST, handleSearchPlatforms);
}

function* searchPlatformsSagas() {
    yield all([fork(watchSearchPlatforms)]);
}

export default searchPlatformsSagas;
