import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ReprocessBatchTypes, ReprocessBatchState } from 'reducer/batch/reprocess-batch/types';

const initialState: ReprocessBatchState = {
    status: HttpRequestStatus.NOOP,
    closedBatch: undefined,
    error: undefined,
};

const reducer: Reducer<ReprocessBatchState> = (state = initialState, action): ReprocessBatchState => {
    switch (action.type) {
        case ReprocessBatchTypes.REPROCESS_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ReprocessBatchTypes.REPROCESS_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, closedBatch: action.payload, error: undefined };
        }
        case ReprocessBatchTypes.REPROCESS_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ReprocessBatchTypes.REPROCESS_BATCH_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as reprocessBatchReducer };
