import FormatterUtils from 'components/formatter/formatter-utils';

export type PercentageValue = number | undefined | null;

export interface PercentageFormatterProps {
    value: PercentageValue;
    defaultValue?: number;
}

export const PercentageFormatterWithTwelveDecimals = (props: PercentageFormatterProps) => {
    const { value, defaultValue } = props;

    return <>{FormatterUtils.formatNumberToPercentageWithTwelveDecimals(value, defaultValue)}</>;
};

export const formatPercentageWithTwelveDecimals = FormatterUtils.formatNumberToPercentageWithTwelveDecimalsWithoutSymbols;

export default PercentageFormatterWithTwelveDecimals;
