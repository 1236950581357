import { AxiosResponse } from 'axios';
import { Token } from 'model/authentication';
import HttpStatus from 'model/enums/http-status';
import { UserAccount } from 'model/user';
import { userAccountError, userAccountSuccess } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { loginError, loginRequest, loginSuccess } from 'reducer/authentication/actions';
import { AuthenticationActionTypes } from 'reducer/authentication/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import accountApi from 'services/api/account-api';
import AuthUtil from 'services/api/auth-util';
import authenticationApi from 'services/api/authentication-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleLogin(action: ReturnType<typeof loginRequest>) {
    try {
        const result: AxiosResponse<Token> = yield call(authenticationApi.login, action.payload);
        if (result.status !== 200 || !result.data) {
            AuthUtil.removeToken();
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(loginError(mapped));
            return;
        }

        const token: Token = result.data;
        AuthUtil.setToken(token);

        yield recoverUserAccount();

        yield put(loginSuccess(result.data));
    } catch (error) {
        AuthUtil.removeToken();
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(loginError(mapped));
    }
}

function* watchLoginRequest() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, handleLogin);
}

function* recoverUserAccount() {
    const result: AxiosResponse<UserAccount> = yield call(accountApi.recoverAccount);
    if (result.status !== HttpStatus.OK) {
        const mapped = ErrorUtils.mapGiroScdError(result);
        yield put(markError(mapped));
        yield put(userAccountError(mapped));
        return;
    }
    yield put(userAccountSuccess(result.data));
}

function* authenticationSaga() {
    yield all([fork(watchLoginRequest)]);
}

export default authenticationSaga;
