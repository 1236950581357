import { ScdImportedOperationSimple } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum RetryDefinePaymentImportedOperationTypes {
    RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_REQUEST = '@@retry-define-payment-imported-operation/RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_REQUEST',
    RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_SUCCESS = '@@retry-define-payment-imported-operation/RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_SUCCESS',
    RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_ERROR = '@@retry-define-payment-imported-operation/RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_ERROR',
    RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_RESET_STATE = '@@retry-define-payment-imported-operation/RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_RESET_STATE',
}

export interface RetryDefinePaymentImportedOperationState extends BaseStoreState {
    readonly importedOperationSimple?: ScdImportedOperationSimple;
}
