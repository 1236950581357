import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import AccountsDetail from 'entities/company/company-detail/components/accounts-detail';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { companyDetailsRequest, companyDetailsResetState } from 'reducer/company/company-detail/actions';
import { useDetailCompanyState, useRootDispatch } from 'reducer/hooks';
import { getPersonType } from 'shared/util/person-type-utils';

import './company-detail.scss';

interface ScdCompanyDetailProps extends RouteComponentProps<{ companyId: string }> {}

const I18N_PREFIX = 'pages.scd.company';

const CompanyDetail = (props: ScdCompanyDetailProps) => {
    const dispatch = useRootDispatch();

    const { t } = useTranslation();

    const [companyId] = useState<string>(props.match.params.companyId);

    const { status, companyDetails } = useDetailCompanyState();

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        dispatch(companyDetailsRequest(Number(companyId)));
    }, [dispatch, companyId]);

    useEffect(() => {
        return () => {
            dispatch(companyDetailsResetState());
        };
    }, [dispatch]);

    return (
        <main className="scd-company__detail">
            <ContextRibbon />
            <div className="scd-company__detail--container">
                <header className="scd-company__detail--header">
                    <h2 className="scd-company__detail--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="scd-company__detail--form">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.name.label`)} value={companyDetails?.name} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.identification.label`)}
                                value={
                                    <IdentificationFormatter
                                        type={getPersonType(companyDetails?.identification)}
                                        value={companyDetails?.identification}
                                    />
                                }
                            />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.tradeName.label`)} value={companyDetails?.tradeName} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                value={t(`${I18N_PREFIX}.input.type.options.${companyDetails?.type}`)}
                            />
                            {companyDetails?.accounts &&
                                companyDetails?.accounts?.map((account, index) => (
                                    <ReadOnlyCard
                                        name={t(`${I18N_PREFIX}.card.accounts.name`, {
                                            number: index + 1,
                                        })}
                                        key={account.id}
                                    >
                                        <AccountsDetail account={account} />
                                    </ReadOnlyCard>
                                ))}
                            {companyDetails?.organizations &&
                                companyDetails?.organizations?.map((organization, index) => (
                                    <ReadOnlyCard
                                        name={t(`${I18N_PREFIX}.card.organizations.name`, { number: index + 1 })}
                                        key={organization.id}
                                    >
                                        <ReadOnlyTextField
                                            label={t(`${I18N_PREFIX}.card.organizations.input.organizationId.label`)}
                                            value={organization.name}
                                        />
                                    </ReadOnlyCard>
                                ))}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default CompanyDetail;
