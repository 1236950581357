export const SEARCH_PAGE_SIZE_DEFAULT = 10;
export const SEARCH_ITEM_SIZE = 60;
export const SEARCH_ITEM_SIZE_LARGE = 70;

export const MODAL_SEARCH_HEIGHT = 200;
export const MODAL_SEARCH_WIDTH = 335;

export const LIST_WINDOW_SIZE = 140

export const LIST_MODAL_WINDOW_SIZE = 160

export const LIST_PAGE_SIZE = 10;
