import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import SessionTaxMask from 'entities/batch/components/inputs/masks/session-tax-mask';
import React from 'react';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<number>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface NumberInput extends Base {
    value: number;
    onChange: (value?: number) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

const ValidSessionTaxInput = (props: NumberInput) => {
    const { validate, showValidation, value, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = _value.trim().replace(',', '.');

        const _normalizedNumber = Number(normalized);

        if (!_value) {
            onChange(undefined);
            return;
        }

        if (onChange && !isNaN(_normalizedNumber)) {
            onChange(_normalizedNumber);
        }
    };

    return (
        <ValidInput
            value={value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('base-input', { dirty: props.value })}
        >
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    error={error}
                    value={value ? InputUtils.numberToSimpleString(value) : undefined}
                    onChange={handleOnChange}
                    MaskInputComponent={SessionTaxMask}
                />
            )}
        </ValidInput>
    );
};

export default ValidSessionTaxInput;
