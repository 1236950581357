import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';
import { OriginatorsByPaymentInstitutionToSearch } from 'model/payment-institution-account';

export type ApiResponse = Record<string, any>;

export enum SearchOriginatorsByPaymentInstitutionAccountTypes {
    SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_REQUEST = '@@originators/SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_REQUEST',
    SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS = '@@originators/SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS',
    SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_ERROR = '@@originators/SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_ERROR',
    SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_RESET = '@@originators/SEARCH_ORIGINATORS_PAYMENT_INSTITUTION_ACCOUNT_RESET',
}

export interface SearchOriginatorsByPaymentInstitutionAccountState extends BaseStoreState {
    readonly originatorsByPaymentInstitutionAccount?: Page<OriginatorsByPaymentInstitutionToSearch>;
}
