import { PaymentInstitutionToSearch } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchPaymentInstitutionsAccountsTypes {
    SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-search/SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-search/SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-search/SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-search/SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface SearchPaymentInstitutionsAccountsState extends BaseStoreState {
    readonly searchPaymentInstitutionsAccounts?: Page<PaymentInstitutionToSearch>;
}
