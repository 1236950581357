import { ScdSessionInfo } from 'model/session';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.batch.detail.modal.generate-cnab.validate';

const GENERATE_CNAB_SESSION_DATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.session-date.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.session-date.required`,
    },
};
export const validateGenerateCnabSessionDate = (value: string | undefined | null) =>
    ValidationUtils.validate(value, GENERATE_CNAB_SESSION_DATE_VALIDATION);

const GENERATE_CNAB_SESSION_TAX_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.session-tax.required`,
    },
};
export const validateGenerateCnabSessionTax = (value: number | undefined | null) =>
    ValidationUtils.validate(value, GENERATE_CNAB_SESSION_TAX_VALIDATION);

export const validateScdGenerateCnabSession = (value: ScdSessionInfo | undefined | null): boolean => {
    if (!value) return false;
    return validateGenerateCnabSessionDate(value.date).isValid && validateGenerateCnabSessionTax(value.tax).isValid;
};
