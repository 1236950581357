import { useTranslation } from 'react-i18next';

import { useEffect, useRef, useState } from 'react';

import OutlinedButton from 'components/buttons/outlined-button/outlined-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import DateRangePicker from 'components/inputs/date-input/date-range-picker/date-range-picker';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import FareAmountReportCSV from 'entities/fare-amount-report/fare-amount-report-csv/fare-amount-report-csv';
import {
    validateFareAmountReportDate,
    validateFareAmountReportOriginator,
} from 'entities/fare-amount-report/validation/fare-amount-report.validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DateRange, ScdImportedOperationFareAmountRepotyBodyRequest } from 'model/imported-operation';
import { ScdOriginatorSearch } from 'model/originator';
import { CSVLink } from 'react-csv';
import { useDispatch } from 'react-redux';
import { IRootState } from 'reducer';
import { useRecoverFareAmountReportState } from 'reducer/hooks';
import { fareAmountReportRequest, fareAmountReportResetState } from 'reducer/imported-operation/fare-amount-report/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import { useToast } from 'shared/hooks/use-toast';
import './fare-amount-report.scss';

const I18N_PREFIX = 'pages.scd.fare-amount-report';

interface OriginatorInputSelect {
    id?: string;
    name?: string;
    identification?: string;
}

const FareAmountReport = () => {
    const { t } = useTranslation();

    const [batchPeriod, setBatchPeriod] = useState<DateRange>({
        start: undefined,
        end: undefined,
    });

    const dispatch = useDispatch();
    const [originator, setOriginator] = useState<OriginatorInputSelect>();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();
    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const { status, fareAmountReportData } = useRecoverFareAmountReportState();

    const handleResetInputs = () => {
        setBatchPeriod({
            start: undefined,
            end: undefined,
        });
        setOriginator(undefined);
    };

    const [batchPeriodError, setBatchPeriodError] = useState<boolean>(false);
    const [originatorIdError, setOriginatorIdError] = useState<boolean>(false);

    const isValidStartDate = validateFareAmountReportDate(batchPeriod.start?.toString());
    const isValidEndDate = validateFareAmountReportDate(batchPeriod.end?.toString());
    const isValidOriginator = validateFareAmountReportOriginator(originator?.id?.toString());
    const hasOperations = fareAmountReportData?.operations && fareAmountReportData?.operations?.length > 0;

    const handleGenerateReport = () => {
        if (!isValidStartDate.isValid) return setBatchPeriodError(!isValidStartDate.isValid);
        if (!isValidEndDate.isValid) return setBatchPeriodError(!isValidEndDate.isValid);

        setBatchPeriodError(false);

        if (!isValidOriginator.isValid) return setOriginatorIdError(!isValidOriginator.isValid);

        setOriginatorIdError(false);

        const params: ScdImportedOperationFareAmountRepotyBodyRequest = {
            originatorId: Number(originator?.id),
            batchPeriod: {
                start: batchPeriod.start,
                end: batchPeriod.end,
            },
        };

        dispatch(fareAmountReportRequest(params));
    };

    useEffect(() => {
        if (status !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [status, toastLoading]);

    useEffect(() => {
        if (status !== HttpRequestStatus.ERROR) return;

        closeToast();
        toastError(t(`${I18N_PREFIX}.toast.error`));
        dispatch(fareAmountReportResetState());
    }, [status, closeToast, toastError, t, dispatch]);

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS && !hasOperations) {
            toastError(t(`${I18N_PREFIX}.toast.empty`));
            dispatch(fareAmountReportResetState());
            closeToast();
        }
    }, [status, hasOperations, closeToast, toastError, t, dispatch]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS || !hasOperations) return;

        csvLinkRef.current?.link.click();
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.success`));
        dispatch(fareAmountReportResetState());
    }, [status, hasOperations, closeToast, toastSuccess, t, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(fareAmountReportResetState());
        };
    }, [dispatch]);

    return (
        <main className="fare-amount-report">
            <section className="fare-amount-report__container">
                <header className="fare-amount-report--header">
                    <h2 className="fare-amount-report--header-title">{t(`${I18N_PREFIX}.title`)}</h2>
                </header>
                <form className="fare-amount-report--form">
                    <DateRangePicker dateRange={batchPeriod} setDateRange={setBatchPeriod} />
                    {batchPeriodError && (
                        <div className="fare-amount-report--form-field--error">{t(`${I18N_PREFIX}.validate.required`)}</div>
                    )}

                    <div className="fare-amount-report--form-field">
                        <ModalSearch<ScdOriginatorSearch>
                            action={searchOriginatorsRequest}
                            itemSize={SEARCH_ITEM_SIZE}
                            modalTitle={t(`${I18N_PREFIX}.originator.label`)}
                            modalLabel={t(`${I18N_PREFIX}.originator.label`)}
                            modalPlaceholder={t(`${I18N_PREFIX}.originator.placeholder`)}
                            onSelect={originator => setOriginator({ id: originator.id, name: originator.name })}
                            renderItem={originator => originator?.name}
                            statusSelector={(state: IRootState) => state.searchOriginators.status}
                            dataSelector={(state: IRootState) => state.searchOriginators.originators}
                            resetState={searchOriginatorsResetState}
                        >
                            {handleOpen => (
                                <SimpleSearchInput
                                    label={`${I18N_PREFIX}.originator.label`}
                                    placeholder={`${I18N_PREFIX}.originator.placeholder`}
                                    value={originator?.name}
                                    onClick={handleOpen}
                                    onFocus={handleOpen}
                                    readOnly
                                    externalUpdate
                                />
                            )}
                        </ModalSearch>
                    </div>
                    {originatorIdError && (
                        <div className="fare-amount-report--form-field--error">{t(`${I18N_PREFIX}.validate.required`)}</div>
                    )}
                    <div className="fare-amount-report--form-buttons">
                        <OutlinedButton label={t(`${I18N_PREFIX}.buttons.clean`)} onClick={handleResetInputs} />
                        <StandardButton label={t(`${I18N_PREFIX}.buttons.generate-csv`)} onClick={handleGenerateReport} />
                    </div>
                </form>
            </section>
            {fareAmountReportData && (
                <FareAmountReportCSV
                    fareAmountReportData={fareAmountReportData.operations}
                    batchPeriod={batchPeriod}
                    csvLinkRef={csvLinkRef}
                />
            )}
        </main>
    );
};

export default FareAmountReport;
