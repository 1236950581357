import { ScdBatchToCreateByOperations, ScdBatchCreatedByOperations } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { CreateBatchByOperationsTypes } from 'reducer/batch/create-batch-by-operations/types';
import { action } from 'typesafe-actions';

export const createBatchByOperationsRequest = (request: ScdBatchToCreateByOperations) =>
    action(CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_REQUEST, request);
export const createBatchByOperationsSuccess = (batch: ScdBatchCreatedByOperations) =>
    action(CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_SUCCESS, batch);
export const createBatchByOperationsError = (error: GiroWebappError) =>
    action(CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_ERROR, error);
export const createBatchByOperationsResetState = () => action(CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_RESET);
