import { GiroWebappError } from 'model/error';
import { ScdProgramPolicy } from 'model/program-policy';
import { DetailProgramPolicyTypes } from 'reducer/program-policy/detail-program-policy/types';
import { action } from 'typesafe-actions';

export const detailProgramPolicyRequest = (programPolicyId: string) =>
    action(DetailProgramPolicyTypes.DETAIL_PROGRAM_POLICY_REQUEST, programPolicyId);
export const detailProgramPolicySuccess = (programPolicy: ScdProgramPolicy) =>
    action(DetailProgramPolicyTypes.DETAIL_PROGRAM_POLICY_SUCCESS, programPolicy);
export const detailProgramPolicyError = (error: GiroWebappError) => action(DetailProgramPolicyTypes.DETAIL_PROGRAM_POLICY_ERROR, error);
export const detailProgramPolicyResetState = () => action(DetailProgramPolicyTypes.DETAIL_PROGRAM_POLICY_RESET_STATE);
