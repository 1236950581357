import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ListCompanyGroupPoliciesTypes,
    ListCompanyGroupPoliciesState,
} from 'reducer/company-group-policy/list-company-group-policies/types';

const initialState: ListCompanyGroupPoliciesState = {
    status: HttpRequestStatus.NOOP,
    companyGroupPolicies: undefined,
    error: undefined,
};

const reducer: Reducer<ListCompanyGroupPoliciesState> = (state = initialState, action): ListCompanyGroupPoliciesState => {
    switch (action.type) {
        case ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyGroupPolicies: action.payload, error: undefined };
        }
        case ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListCompanyGroupPoliciesTypes.LIST_COMPANY_GROUP_POLICIES_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listCompanyGroupPoliciesReducer };
