import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdIssuerList } from 'model/issuer';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.issuer.table';

interface ScdIssuerHeaderProps {
    issuers: ScdIssuerList[];
    handleSort: (property: keyof ScdIssuerList, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort: boolean;
}

export const ScdIssuerHeader = (props: ScdIssuerHeaderProps) => {
    const { t } = useTranslation();
    const { issuers, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof ScdIssuerList) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (issuers && issuers.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
};

export default ScdIssuerHeader;
