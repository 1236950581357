import { Grid } from '@material-ui/core';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { PersonType } from 'model/enums/person-type';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { pixKeyFormatter } from 'shared/util/pixKey-utils';

interface AccountOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.account';

const AccountOperationCard = (props: AccountOperationCardProps) => {
    const {
        operation: { account },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.bank.label`)}
                    value={account?.bankNumber && `${account?.bankNumber} - ${account?.bankName ?? t(`${I18N_PREFIX}.fields.bank.noBank`)}`}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.type.label`)}
                    value={t(`${I18N_PREFIX}.fields.type.options.${account?.type}`)}
                />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.agency`)} value={account?.agencyNumber} />
            </Grid>
            <Grid item xs={3}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.digit`)} value={account?.agencyDigit} />
            </Grid>
            <Grid item xs={4}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.account`)} value={account?.accountNumber} />
            </Grid>
            <Grid item xs={1}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.digit`)} value={account?.accountDigit} />
            </Grid>
            <Grid item xs={1}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.operation`)} value={account?.operation} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.jointAccount.label`)}
                    value={t(`${I18N_PREFIX}.fields.jointAccount.options.${account?.jointAccount}`)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.favoredIdentification`)}
                    value={
                        account?.favoredIdentification ? (
                            <IdentificationFormatter value={account?.favoredIdentification} type={PersonType.INDIVIDUAL} />
                        ) : (
                            '-'
                        )
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.pixKey`)}
                    value={pixKeyFormatter(account?.pixType, account?.pixKey)}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.pixType.label`)}
                    value={t(`${I18N_PREFIX}.fields.pixType.options.${account?.pixType}`)}
                />
            </Grid>
        </BaseOperationCard>
    );
};

export default AccountOperationCard;
