import { BankAccountType } from 'model/enums/bank-account-type';

export interface CompanyBankAccount {
    id?: number;
    agencyNumber?: string;
    agencyDigit?: string;
    accountNumber?: string;
    accountDigit?: string;
    pixKey?: string;
    favoredIdentification?: string;
    favoredName?: string;
    type?: BankAccountType;
    bankId?: number;
    bankName?: string;
}

export const defaultCompanyBankAccount = {
    agencyNumber: undefined,
    agencyDigit: undefined,
    accountNumber: undefined,
    accountDigit: undefined,
    pixKey: undefined,
    favoredIdentification: undefined,
    favoredName: undefined,
    type: undefined,
    bankId: undefined,
};
