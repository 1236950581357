import { PaymentInstitutionReference } from 'model/enums/payment-institution-reference';
import { ReactNode, useCallback, useEffect } from 'react';
import { useRootDispatch } from 'reducer/hooks';
import CelCoinBalance from 'shared/external/payment-accounts-strategy/balance/components/celcoin/celcoin-balance';
import BalanceFallback from 'shared/external/payment-accounts-strategy/balance/components/fallback/fallback';
import TransfeeraBalance from 'shared/external/payment-accounts-strategy/balance/components/transfeera/show-balance/transfeera-balance';
import { ExternalBalanceProps } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { externalBalancePaymentInstitutionsAccountsRequest } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/actions';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import useGetExternalBalanceStateById from 'shared/external/util/state-util';

const BalanceBridge = (props: ExternalBalanceProps) => {
    const { technicalSupplierReference, paymentInstitutionAccountId } = props;

    const { balance, status, error } = useGetExternalBalanceStateById(paymentInstitutionAccountId);

    const dispatch = useRootDispatch();

    const handleExternalBalanceRequest = useCallback(() => {
        dispatch(externalBalancePaymentInstitutionsAccountsRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    const shouldBlockRequestByTechnicalSupplier: boolean = technicalSupplierReference !== PaymentInstitutionReference.TRANSFEERA;

    const shouldBlockRequestByStatus: boolean = !!status && status !== HttpRequestStatus.NOOP;

    useEffect(() => {
        if (technicalSupplierReference === undefined) return;
        if (shouldBlockRequestByTechnicalSupplier) return;
        if (shouldBlockRequestByStatus) return;

        handleExternalBalanceRequest();
    }, [handleExternalBalanceRequest, technicalSupplierReference, shouldBlockRequestByTechnicalSupplier, shouldBlockRequestByStatus]);

    const isRequestCompleted = (status === HttpRequestStatus.SUCCESS || status === HttpRequestStatus.ERROR) && !error;

    const bridgeComponent: Record<PaymentInstitutionReference, ReactNode> = {
        transfeera: <TransfeeraBalance {...props} />,
        celcoin: <CelCoinBalance {...props} />,
    };

    if (technicalSupplierReference === undefined) {
        return <BalanceFallback {...props} />;
    }

    if (isRequestCompleted && paymentInstitutionAccountId !== balance?.accountId) {
        return <BalanceFallback {...props} />;
    }

    if (isRequestCompleted && technicalSupplierReference !== balance?.technicalSupplierReference) {
        return <BalanceFallback {...props} />;
    }

    return <>{bridgeComponent[technicalSupplierReference] ?? <BalanceFallback {...props} />}</>;
};

export default BalanceBridge;
