import { LocalDatePeriod } from 'model/local-date-period';

export interface ScdDisbursementReport {
    originator?: string;
    createDatePeriod?: LocalDatePeriod;
    identifications?: string[];
}

export const defaultScdDisbursementReport: Readonly<ScdDisbursementReport> = {
    originator: undefined,
    createDatePeriod: undefined,
    identifications: [],
};

export interface ScdDisbursementToReport {
    name: string;
    identification: string;
    value: number;
}
