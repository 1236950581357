import { GiroWebappError } from 'model/error';
import { BatchOutputsListBco } from 'model/bco/batch-output';
import { BatchOutputsListBcoTypes } from 'reducer-bco/batch-output/list-batch-output/types';
import { action } from 'typesafe-actions';

export const batchOutputsListBcoRequest = (batchId: number) => action(BatchOutputsListBcoTypes.BATCH_OUTPUTS_LIST_BCO_REQUEST, batchId);
export const batchOutputsListBcoSuccess = (batchOutputsList: BatchOutputsListBco) =>
    action(BatchOutputsListBcoTypes.BATCH_OUTPUTS_LIST_BCO_SUCCESS, batchOutputsList);
export const batchOutputsListBcoError = (error: GiroWebappError) => action(BatchOutputsListBcoTypes.BATCH_OUTPUTS_LIST_BCO_ERROR, error);
export const batchOutputsListBcoResetState = () => action(BatchOutputsListBcoTypes.BATCH_OUTPUTS_LIST_BCO_RESET);
