import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IssuerPolicySimple } from 'model/issuer-policy';
import { markError } from 'reducer/application/error/actions';
import { createIssuerPolicyError, createIssuerPolicyRequest, createIssuerPolicySuccess } from 'reducer/issuer-policy/create-policy/actions';
import { CreateIssuerPolicyTypes } from 'reducer/issuer-policy/create-policy/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import issuerPolicyApi from 'services/api/issuer-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleIssuerCreatePolicy(action: ReturnType<typeof createIssuerPolicyRequest>) {
    try {
        const result: AxiosResponse<IssuerPolicySimple> = yield call(issuerPolicyApi.createPolicy, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createIssuerPolicyError(mapped));
            return;
        }
        yield put(createIssuerPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createIssuerPolicyError(mapped));
    }
}

function* watchIssuerCreatePolicy() {
    yield takeLeading(CreateIssuerPolicyTypes.ISSUER_CREATE_POLICY_REQUEST, handleIssuerCreatePolicy);
}

function* createIssuerPolicySaga() {
    yield all([fork(watchIssuerCreatePolicy)]);
}

export default createIssuerPolicySaga;
