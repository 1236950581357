import { validateManualDisbursementDescription } from 'entities/payment-transaction/payment-transaction-detail/validation/description-validation';
import { validateManualDisbursementPaymentInstitution, validateManualDisbursementPaymentInstitutionAccount } from 'entities/payment-transaction/payment-transaction-detail/validation/payment-institution-validation';
import { PaymentTransactionToManualDisbursement } from 'model/payment-transaction';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail.validation.bankAccount';

const VALIDATE_MANUAL_DISBURSEMENT_BANK_ID: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.bankId.required`
    }
}

export const validateManualDisbursementBankId = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_BANK_ID)

const VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_NUMBER: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountNumber.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(20),
        errorMessage: `${I18N_PREFIX}.accountNumber.maxLength`
    }
}

export const validateManualDisbursementAccountNumber = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_NUMBER)

const VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_DIGIT: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountDigit.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(2),
        errorMessage: `${I18N_PREFIX}.accountDigit.maxLength`
    }
}

export const validateManualDisbursementAccountDigit = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_DIGIT)

const VALIDATE_MANUAL_DISBURSEMENT_AGENCY_NUMBER: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.agencyNumber.required`
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(4),
        errorMessage: `${I18N_PREFIX}.agencyNumber.maxLength`
    }
}

export const validateManualDisbursementAgencyNumber = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_AGENCY_NUMBER)

const VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_TYPE: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountType.required`
    }
}

export const validateManualDisbursementAccountType = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_ACCOUNT_TYPE)

export const validateManualDIsbursementBankAccount = (value: PaymentTransactionToManualDisbursement | undefined | null) => {
    if (!value) {
        return false
    }

    return (
        validateManualDisbursementBankId(value.bankAccount?.bankId?.toString()).isValid &&
        validateManualDisbursementAccountNumber(value.bankAccount?.accountNumber).isValid &&
        validateManualDisbursementAccountDigit(value.bankAccount?.accountDigit).isValid &&
        validateManualDisbursementAgencyNumber(value.bankAccount?.agencyNumber).isValid &&
        validateManualDisbursementAccountType(value.bankAccount?.accountType).isValid &&
        validateManualDisbursementDescription(value.description).isValid &&
        validateManualDisbursementPaymentInstitution(value.paymentInstitutionId?.toString()).isValid &&
        validateManualDisbursementPaymentInstitutionAccount(value.paymentInstitutionAccountId?.toString()).isValid
    )
}
