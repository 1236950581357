import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { RelocateToExistingBatchTypes, RelocateToExistingBatchState } from 'reducer/batch/relocate-operation-to-existing-batch/types';

const initialState: RelocateToExistingBatchState = {
    status: HttpRequestStatus.NOOP,
    relocatedBatch: undefined,
    error: undefined,
};

const reducer: Reducer<RelocateToExistingBatchState> = (state = initialState, action): RelocateToExistingBatchState => {
    switch (action.type) {
        case RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, relocatedBatch: action.payload, error: undefined };
        }
        case RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case RelocateToExistingBatchTypes.RELOCATE_TO_EXISTING_BATCH_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as relocateToExistingBatchReducer };
