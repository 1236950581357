import Loading from 'components/loading/loading';
import H from 'history';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { userAccountRequest } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/auth-util';
import './auto-login.scss';

const useHandleExceptions = (history: H.History) => {
    const dispatch = useRootDispatch();
    const accountState = useUserAccountState();

    useEffect(() => {
        if (accountState.status === HttpRequestStatus.ERROR) {
            AuthUtil.removeToken();
            dispatch(loginResetState());
            history.replace('/');
        }
    }, [dispatch, history, accountState]);
};

export const AutoLogin = () => {
    const history: H.History = useHistory();
    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(userAccountRequest());
    }, [dispatch]);

    useHandleExceptions(history);

    return (
        <div className="auto-login__loading">
            <Loading />
        </div>
    );
};

export default AutoLogin;
