import { DateUtils } from 'components/inputs/input-utils';
import { CompletePaymentTransactionFilterData, ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { useCallback } from 'react';

const usePendingFilter = () => {
    const { parseDateFromISO } = DateUtils;

    const getDates = useCallback(
        (date: string | undefined | Date): number | boolean => {
            if (!date) return false;

            if (typeof date === 'string') {
                const strDate = parseDateFromISO(date ?? '');
                const toDate = new Date(strDate);
                return toDate.getTime();
            }

            return new Date(date).getTime();
        },
        [parseDateFromISO]
    );

    const filterItems = useCallback(
        (pendingTransactions: ScdPaymentTransactionPendingData[], filterActive: CompletePaymentTransactionFilterData) => {
            const { date, amount, originator, favored, mode } = filterActive;

            const minDateFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!date?.initialDate) {
                    return true;
                }

                return getDates(date.initialDate) <= getDates(item.date);
            };

            const maxDateFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!date?.finalDate) {
                    return true;
                }

                return getDates(date.finalDate) >= getDates(item.date);
            };

            const minValueFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!amount?.minValue) {
                    return true;
                }

                return item.amount >= amount.minValue;
            };

            const maxValueFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!amount?.maxValue) {
                    return true;
                }

                return item.amount <= amount.maxValue;
            };

            const favoredFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!favored) {
                    return true;
                }

                return item.favoredIdentification === favored.identification;
            };

            const originatorFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!originator) {
                    return true;
                }

                return Number(originator.id) === item.originatorId;
            };

            const modeFilter = (item: ScdPaymentTransactionPendingData) => {
                if (!mode) {
                    return true;
                }

                return mode === item.mode;
            };

            const filtered = pendingTransactions
                ?.filter(minDateFilter)
                .filter(maxDateFilter)
                .filter(minValueFilter)
                .filter(maxValueFilter)
                .filter(favoredFilter)
                .filter(originatorFilter)
                .filter(modeFilter);

            return filtered;
        },
        [getDates]
    );

    return { filterItems };
};

export default usePendingFilter;
