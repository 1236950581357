import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentTransactionsInSeriesErrorsList } from 'model/payment-transaction-in-series';
import { markError } from 'reducer/application/error/actions';
import {
    createPaymentTransactionsInSeriesError,
    createPaymentTransactionsInSeriesRequest,
    createPaymentTransactionsInSeriesSuccess,
} from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/actions';
import { CreatePaymentTransactionsInSeriesTypes } from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionInSeriesAPI from 'services/api/payment-transaction-in-series-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreatePaymentTransactionsInSeries(action: ReturnType<typeof createPaymentTransactionsInSeriesRequest>) {
    try {
        const result: AxiosResponse<ScdPaymentTransactionsInSeriesErrorsList> = yield call(
            paymentTransactionInSeriesAPI.createPaymentTransactionsInSeriesLegacy,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createPaymentTransactionsInSeriesError(mapped));
            return;
        }
        yield put(createPaymentTransactionsInSeriesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createPaymentTransactionsInSeriesError(mapped));
    }
}

function* watchCreatePaymentTransactionsInSeries() {
    yield takeLeading(
        CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_REQUEST,
        handleCreatePaymentTransactionsInSeries
    );
}

function* createPaymentTransactionsInSeriesSagas() {
    yield all([fork(watchCreatePaymentTransactionsInSeries)]);
}

export default createPaymentTransactionsInSeriesSagas;
