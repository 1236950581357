import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchCompanyGroupsTypes, SearchCompanyGroupsState } from 'reducer/company-group/search-company-groups/types';

const initialState: SearchCompanyGroupsState = {
    status: HttpRequestStatus.NOOP,
    companyGroups: undefined,
    error: undefined,
};

const reducer: Reducer<SearchCompanyGroupsState> = (state = initialState, action): SearchCompanyGroupsState => {
    switch (action.type) {
        case SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyGroups: action.payload, error: undefined };
        }
        case SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchCompanyGroupsReducer };
