import { IssuerStatus } from 'model/enums/issuer-status';
import { ScdIssuer } from 'model/issuer';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.issuer.validate';

const ISSUER_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateIssuerName = (value: string | undefined | null) => ValidationUtils.validate(value, ISSUER_NAME_VALIDATION);

const ISSUER_IDENTIFICATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
    pattern: {
        predicate: ValidationUtils.STRING.isValidCNPJ,
        errorMessage: `${I18N_PREFIX}.identification.pattern`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.identification.required`,
    },
};
export const validateIssuerIdentification = (value: string | undefined | null) =>
    ValidationUtils.validate(value, ISSUER_IDENTIFICATION_VALIDATION);

const ISSUER_STATUS_VALIDATION: Validations<IssuerStatus | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
};
export const validateIssuerStatus = (value: IssuerStatus | undefined | null) => ValidationUtils.validate(value, ISSUER_STATUS_VALIDATION);

export const validateScdIssuer = (value: ScdIssuer | undefined): boolean => {
    if (!value) return false;
    return (
        validateIssuerName(value.name).isValid &&
        validateIssuerIdentification(value.identification).isValid &&
        validateIssuerStatus(value.status).isValid
    );
};
