import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ActivePoliciesSummaryState, ActivePoliciesSummaryTypes } from 'reducer/issuer-policy/active-policies-summary/types';
import { Reducer } from 'redux';

export const initialState: ActivePoliciesSummaryState = {
    status: HttpRequestStatus.NOOP,
    issuerPolicy: undefined,
    error: undefined,
};

const reducer: Reducer<ActivePoliciesSummaryState> = (state = initialState, action): ActivePoliciesSummaryState => {
    switch (action.type) {
        case ActivePoliciesSummaryTypes.ACTIVE_POLICIES_SUMMARY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ActivePoliciesSummaryTypes.ACTIVE_POLICIES_SUMMARY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuerPolicy: action.payload, error: undefined };
        }
        case ActivePoliciesSummaryTypes.ACTIVE_POLICIES_SUMMARY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ActivePoliciesSummaryTypes.ACTIVE_POLICIES_SUMMARY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as activePoliciesSummaryReducer };
