import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOriginatorSimple } from 'model/originator';

export type ApiResponse = Record<string, string>;

export enum CreateOriginatorTypes {
    CREATE_ORIGINATOR_REQUEST = '@@originator-create/CREATE_ORIGINATOR_REQUEST',
    CREATE_ORIGINATOR_SUCCESS = '@@originator-create/CREATE_ORIGINATOR_SUCCESS',
    CREATE_ORIGINATOR_ERROR = '@@originator-create/CREATE_ORIGINATOR_ERROR',
    CREATE_ORIGINATOR_RESET_STATE = '@@originator-create/CREATE_ORIGINATOR_RESET_STATE',
}

export interface CreateOriginatorState extends BaseStoreState {
    readonly originator?: ScdOriginatorSimple;
}
