import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListProgramOutputPoliciesState, ListProgramOutputPoliciesTypes } from 'reducer/program-output-policies/list-program-output-policies/types';
import { Reducer } from 'redux';

export const initialState: ListProgramOutputPoliciesState = {
    status: HttpRequestStatus.NOOP,
    programOutputPolicies: undefined,
    error: undefined,
};

const reducer: Reducer<ListProgramOutputPoliciesState> = (state = initialState, action): ListProgramOutputPoliciesState => {
    switch (action.type) {
        case ListProgramOutputPoliciesTypes.LIST_PROGRAM_OUTPUT_POLICIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListProgramOutputPoliciesTypes.LIST_PROGRAM_OUTPUT_POLICIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, programOutputPolicies: action.payload, error: undefined }
        }
        case ListProgramOutputPoliciesTypes.LIST_PROGRAM_OUTPUT_POLICIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListProgramOutputPoliciesTypes.LIST_PROGRAM_OUTPUT_POLICIES_RESET_STATE: {
            return initialState
        }
        default: {
            return state;
        }
    }
};

export { reducer as listProgramOutputPoliciesReducer };