import clsx from 'clsx';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import RemoveIconButton from 'components/buttons/icon-buttons/remove/remove-icon-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './card-edition.scss';

export interface ICardEdition {
    isNew?: boolean;
}

export interface CardEditionParams<T extends ICardEdition> {
    entity: T;
    isEditing: boolean;
    showValidation: boolean;
    onChange: (update: Partial<T>) => void;
}

export interface CardEditionProps<T extends ICardEdition> {
    entity: T;
    children: (options: CardEditionParams<T>) => React.ReactNode;
    editable?: boolean;
    removable?: boolean;
    onEdit?: () => void;
    onRemove?: () => void;
    onCancel?: () => void;
    onSave?: (entity: T) => void;
    validate?: (entity: T) => boolean;
    name?: string;
    readOnly?: boolean;
    deleteMessage?: string;
    deleteTitle?: string;
    cancelMessage?: string;
    alreadyAdd?: boolean;
}

export const CardEdition = <T extends ICardEdition>(props: CardEditionProps<T>) => {
    const [entity, setEntity] = React.useState<T>(props.entity);
    const [isEditing, setIsEditing] = React.useState<boolean>(!!props.entity?.isNew);
    const [showValidation, setShowValidation] = React.useState<boolean>(false);
    const [modalCancel, setModalCancel] = React.useState<boolean>(false);
    const [modalRemove, setModalRemove] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const handleEdit = () => {
        setIsEditing(true);
        if (props.onEdit) {
            props.onEdit();
        }
    };

    const handleRemove = () => {
        if (props.onRemove) {
            props.onRemove();
            setModalRemove(false);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEntity(props.entity);
        if (props.onCancel) {
            props.onCancel();
            setModalCancel(false);
        }
    };

    const handleSave = () => {
        setShowValidation(true);
        if (props.validate && !props.validate(entity)) return;

        setIsEditing(false);
        if (props.onSave) {
            props.onSave(entity);
        }
    };

    const handleChange = React.useMemo(() => {
        return (update: Partial<T>) => {
            setEntity(ps => ({ ...ps, ...update }));
        };
    }, [setEntity]);

    const params: CardEditionParams<T> = {
        entity,
        isEditing,
        showValidation,
        onChange: handleChange,
    };

    return (
        <article className={clsx('card-edition__container', { edit: isEditing }, { list: !isEditing })}>
            {!isEditing && !props.readOnly && (
                <>
                    <header className="card-edition__container--header">
                        <span className="card-edition__container--header-name">{props.name}</span>
                        <div className="card-edition__container--buttons">
                            {props.editable && (
                                <div className="card-edition__container--buttons-button">
                                    <EditIconButton onClick={handleEdit} />
                                </div>
                            )}
                            {props.removable && (
                                <div className="card-edition__container--buttons-button">
                                    <RemoveIconButton onClick={() => setModalRemove(true)} />
                                </div>
                            )}
                        </div>
                    </header>
                    <div>{props.children(params)}</div>
                </>
            )}
            {isEditing && !props.readOnly && (
                <>
                    <div>{props.children(params)}</div>
                    <footer className="card-edition__container--footer">
                        <div className="card-edition__container--buttons">
                            <div className="card-edition__container--buttons-button">
                                <OutlinedButtonLegacy label="entity.action.cancel" onClick={() => setModalCancel(true)} size="small" />
                            </div>
                            <div className="card-edition__container--buttons-button">
                                <StandardButtonLegacy
                                    label="entity.action.save"
                                    onClick={handleSave}
                                    size="small"
                                    disabled={props.alreadyAdd}
                                />
                            </div>
                        </div>
                    </footer>
                </>
            )}
            {props.readOnly && (
                <>
                    <header className="card-edition__container--header">
                        <span className="card-edition__container--header-name">{props.name}</span>
                    </header>
                    <div>{props.children(params)}</div>
                </>
            )}
            {modalCancel && (
                <ModalMessageLegacy
                    title={t('entity.cancel.create')}
                    message={props.cancelMessage ?? ''}
                    onCancel={() => setModalCancel(false)}
                    onAction={handleCancel}
                    onClose={() => setModalCancel(false)}
                />
            )}
            {modalRemove && (
                <ModalMessageLegacy
                    title={props.deleteTitle ?? ''}
                    message={props.deleteMessage ?? ''}
                    onCancel={() => setModalRemove(false)}
                    onAction={handleRemove}
                    onClose={() => setModalRemove(false)}
                />
            )}
        </article>
    );
};

export default CardEdition;
