import { GiroWebappError } from 'model/error';
import { UsersFilterPageable } from 'model/reducers';
import { UsersToGetAll } from 'model/user';
import { ListUserTypes } from 'reducer/user/list-users/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listUsersRequest = (request: UsersFilterPageable) => action(ListUserTypes.LIST_USER_REQUEST, request);
export const listUsersSuccess = (pageUsers: Page<UsersToGetAll>) => action(ListUserTypes.LIST_USER_SUCCESS, pageUsers);
export const listUsersError = (error: GiroWebappError) => action(ListUserTypes.LIST_USER_ERROR, error);
export const listUsersResetState = () => action(ListUserTypes.LIST_USER_RESET);