import { BaseStoreState } from 'reducer/base-store-state';
import { ScdFavoredBankAccount } from 'model/favored-bank-account';

export type ApiResponse = Record<string, any>;

export enum SearchFavoredBankAccountsTypes {
    SEARCH_FAVORED_BANK_ACCOUNTS_REQUEST = '@@favored-bank-accounts-search/SEARCH_FAVORED_BANK_ACCOUNTS_REQUEST',
    SEARCH_FAVORED_BANK_ACCOUNTS_SUCCESS = '@@favored-bank-accounts-search/SEARCH_FAVORED_BANK_ACCOUNTS_SUCCESS',
    SEARCH_FAVORED_BANK_ACCOUNTS_ERROR = '@@favored-bank-accounts-search/SEARCH_FAVORED_BANK_ACCOUNTS_ERROR',
    SEARCH_FAVORED_BANK_ACCOUNTS_RESET = '@@favored-bank-accounts-search/SEARCH_FAVORED_BANK_ACCOUNTS_RESET',
}

export interface SearchFavoredBankAccountsState extends BaseStoreState {
    readonly favoredBankAccounts?: ScdFavoredBankAccount[];
}
