import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ImportedOperationDisbursementReportTypes,
    ImportedOperationDisbursementReportState,
} from 'reducer/imported-operation/disbursement-report/types';
import { Reducer } from 'redux';

export const initialState: ImportedOperationDisbursementReportState = {
    status: HttpRequestStatus.NOOP,
    disbursementReport: undefined,
    error: undefined,
};

const reducer: Reducer<ImportedOperationDisbursementReportState> = (
    state = initialState,
    action
): ImportedOperationDisbursementReportState => {
    switch (action.type) {
        case ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, disbursementReport: action.payload, error: undefined };
        }
        case ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as importedOperationDisbursementReportReducer };
