import { Grid } from '@material-ui/core';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleIdentificationInput from 'components/inputs/identification-input/simple-identification-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { ScdCompanyFilterRequest } from 'model/company';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './company-modal-filter.scss';

export type CompanyModalFilterType = 'filters' | 'name' | 'identification';

export interface CompanyModalFilterProps {
    onClose: () => void;
    modalFilter: CompanyModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: ScdCompanyFilterRequest;
    setFilterActive: (value: ScdCompanyFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.company.filter.inputs';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    name: filter => !filter.name,
    identification: filter => !filter.identification,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
} as Record<CompanyModalFilterType, (filter: ScdCompanyFilterRequest) => boolean>;

export const CompanyModalFilter = (props: CompanyModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<ScdCompanyFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ScdCompanyFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        name: () => {
            setFilterRequest({ ...filterRequest, name: undefined });
            setFilterActive({ ...filterActive, name: undefined });
        },
        identification: () => {
            setFilterRequest({ ...filterRequest, identification: undefined });
            setFilterActive({ ...filterActive, identification: undefined });
        },
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
        },
    } as Record<CompanyModalFilterType, () => void>;

    const NameFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleTextInput
                    label={t(`${I18N_PREFIX}.name`)}
                    placeholder={t(`${I18N_PREFIX}.name`)}
                    onChange={name => handleChange({ name: name })}
                    value={filterRequest?.name}
                    externalUpdate
                />
            </Grid>
        </>
    );
    const IdentificationFilter: JSX.Element = (
        <>
            <Grid item xs={12}>
                <SimpleIdentificationInput
                    label={t(`${I18N_PREFIX}.identification`)}
                    placeholder={t(`${I18N_PREFIX}.identification`)}
                    onChange={identification => handleChange({ identification: identification })}
                    value={filterRequest?.identification}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="company-modal-filter">
                    <div className="company-modal-filter--header">
                        <label className="company-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {NameFilter}
                                            {IdentificationFilter}
                                        </>
                                    ),
                                    name: NameFilter,
                                    identification: IdentificationFilter,
                                } as Record<CompanyModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="company-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default CompanyModalFilter;
