import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateBatchByOperationsTypes, CreateBatchByOperationsState } from 'reducer/batch/create-batch-by-operations/types';

const initialState: CreateBatchByOperationsState = {
    status: HttpRequestStatus.NOOP,
    createdBatch: undefined,
    error: undefined,
};

const reducer: Reducer<CreateBatchByOperationsState> = (state = initialState, action): CreateBatchByOperationsState => {
    switch (action.type) {
        case CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, createdBatch: action.payload, error: undefined };
        }
        case CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateBatchByOperationsTypes.CREATE_BATCH_BY_OPERATIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createBatchByOperationsReducer };
