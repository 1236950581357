import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';

export type ApiResponse = Record<string, any>;

export enum DetailCompanyGroupPolicyTypes {
    DETAIL_COMPANY_GROUP_POLICY_REQUEST = '@@company-group-policy-detail/DETAIL_COMPANY_GROUP_POLICY_REQUEST',
    DETAIL_COMPANY_GROUP_POLICY_SUCCESS = '@@company-group-policy-detail/DETAIL_COMPANY_GROUP_POLICY_SUCCESS',
    DETAIL_COMPANY_GROUP_POLICY_ERROR = '@@company-group-policy-detail/DETAIL_COMPANY_GROUP_POLICY_ERROR',
    DETAIL_COMPANY_GROUP_POLICY_RESET = '@@company-group-policy-detail/DETAIL_COMPANY_GROUP_POLICY_RESET',
}

export interface DetailCompanyGroupPolicyState extends BaseStoreState {
    readonly companyGroupPolicy?: ScdCompanyGroupPolicy;
}
