import { GiroWebappError } from 'model/error';
import { PaymentTrasactionToProcessResponse, ScdPaymentTransactionsToProcess } from 'model/payment-transaction';
import { ProcessPaymentTransactionsTypes } from 'reducer/payment-transaction/process-payment-transactions/types';
import { action } from 'typesafe-actions';

export const processPaymentTransactionsRequest = (request: ScdPaymentTransactionsToProcess) =>
    action(ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_REQUEST, request);
export const processPaymentTransactionsSuccess = (processedPaymentTransactions: PaymentTrasactionToProcessResponse) => action(ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_SUCCESS, processedPaymentTransactions);
export const processPaymentTransactionsError = (error: GiroWebappError) =>
    action(ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_ERROR, error);
export const processPaymentTransactionsResetState = () => action(ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_RESET);
