import { ChunkStreamPartitioningResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ChunkStreamPartitioningBcoTypes {
    CHUNK_STREAM_PARTITIONING_BCO_REQUEST = '@@chunk-stream-partitioning-bco/CHUNK_STREAM_PARTITIONING_BCO_REQUEST',
    CHUNK_STREAM_PARTITIONING_BCO_SUCCESS = '@@chunk-stream-partitioning-bco/CHUNK_STREAM_PARTITIONING_BCO_SUCCESS',
    CHUNK_STREAM_PARTITIONING_BCO_ERROR = '@@chunk-stream-partitioning-bco/CHUNK_STREAM_PARTITIONING_BCO_ERROR',
    CHUNK_STREAM_PARTITIONING_BCO_RESET = '@@chunk-stream-partitioning-bco/CHUNK_STREAM_PARTITIONING_BCO_RESET',
}

export interface ChunkStreamPartitioningBcoState extends BaseStoreState {
    readonly chunkStreamPartitioningBco?: ChunkStreamPartitioningResponse;
}
