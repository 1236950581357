import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search company groups
import { searchCompaniesReducer } from 'reducer/company/search-companies/reducer';
import searchCompaniesSagas from 'reducer/company/search-companies/sagas';
import { SearchCompaniesState } from 'reducer/company/search-companies/types';

// List Companies
import { ListCompaniesState } from 'reducer/company/list-companies/types';
import { listCompaniesReducer } from 'reducer/company/list-companies/reducer';
import listCompaniesSaga from 'reducer/company/list-companies/sagas';

// Create company
import { CreateCompanyState } from 'reducer/company/create-company/types';
import { createCompanyReducer } from 'reducer/company/create-company/reducer';
import createCompanySaga from 'reducer/company/create-company/sagas';

// Company details
import { CompanyDetailsState } from 'reducer/company/company-detail/types';
import { companyDetailsReducer } from 'reducer/company/company-detail/reducer';
import companyDetailsSaga from 'reducer/company/company-detail/sagas';

// Update company
import { UpdateCompanyState } from 'reducer/company/update-company/types';
import { updateCompanyReducer } from 'reducer/company/update-company/reducer';
import updateCompanySaga from 'reducer/company/update-company/sagas';
export interface CompanyState {
    readonly searchCompanies: SearchCompaniesState;
    readonly listCompanies: ListCompaniesState;
    readonly createCompany: CreateCompanyState;
    readonly companyDetails: CompanyDetailsState;
    readonly updateCompany: UpdateCompanyState;
}

export const CompanyReducer: ReducersMapObject<CompanyState, AnyAction> = {
    searchCompanies: searchCompaniesReducer,
    listCompanies: listCompaniesReducer,
    createCompany: createCompanyReducer,
    companyDetails: companyDetailsReducer,
    updateCompany: updateCompanyReducer,
};

export function* companySagas() {
    yield all([
        fork(searchCompaniesSagas),
        fork(listCompaniesSaga),
        fork(createCompanySaga),
        fork(companyDetailsSaga),
        fork(updateCompanySaga),
    ]);
}
