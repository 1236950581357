
import { AuthorizationScheme } from 'model/enums/authorization-scheme';
import { IssuerPolicyIntegrationCreate } from 'model/issuer-policy';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.issuer-policy.validate';

const ISSUER_POLICY_INTEGRATION_SCHEME: Validations<AuthorizationScheme | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateIssuerPolicyIntegrationScheme = (value: AuthorizationScheme | undefined | null) =>
    ValidationUtils.validate(value, ISSUER_POLICY_INTEGRATION_SCHEME);

export const validateIssuerPolicyIntegration = (value: IssuerPolicyIntegrationCreate | undefined): boolean => {
    if (!value) return true;
    return (
        validateIssuerPolicyIntegrationScheme(value.authorizationScheme).isValid
    )
}