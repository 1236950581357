import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import PolicyIconButtom from 'components/buttons/icon-buttons/policy/policy-icon-button';
import SuitCaseIconButton from 'components/buttons/icon-buttons/suitcase/suitcase-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdCompanyGroupSimple } from 'model/company-group';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface CompanyGroupRowProps {
    companyGroup: ScdCompanyGroupSimple[];
}

const I18N_PREFIX = 'pages.scd.company-group.table';

const CompanyGroupRow = (props: ListChildComponentProps<CompanyGroupRowProps>) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { companyGroup } = data;

    const { id, name, policyId } = companyGroup[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/company-items`}>
                    <SuitCaseIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={policyId ? `/company-group-policies/${policyId}` : '#'}>
                    <PolicyIconButtom
                        title={policyId ? t(`${I18N_PREFIX}.tooltip.policy`) : t(`${I18N_PREFIX}.tooltip.policy-not-present`)}
                        disabled={!policyId}
                    />
                </Link>
            </td>
        </tr>
    );
};

export default CompanyGroupRow;
