import { MODAL_SEARCH_HEIGHT, MODAL_SEARCH_WIDTH, SEARCH_PAGE_SIZE_DEFAULT } from 'components/modals/constants';
import { debounce } from 'lodash';
import { ScdBatchToRelocate } from 'model/batch';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchSearchToRelocate } from 'model/reducers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { listBatchesToRelocateResetState } from 'reducer/batch/list-batches-to-relocate/actions';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { v4 as uuidV4 } from 'uuid';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import './batch-input-search-to-relocate.scss';

export interface BatchInputSearchToRelocateProps {
    label: string;
    placeholder: string;
    itemSize: number;
    action: (search: BatchSearchToRelocate) => AnyAction;
    onSelect?: (value: ScdBatchToRelocate) => void;
    renderItem: (item: ScdBatchToRelocate) => React.ReactNode;
    dataSelector: (state: IRootState) => Page<ScdBatchToRelocate> | undefined;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    requestParameters?: object;
    readOnly?: boolean;
    value: string;
    batchId: number;
    setDisableButton: (value: boolean) => void;
}

const BatchInputSearchToRelocate = (props: BatchInputSearchToRelocateProps) => {
    const {
        label,
        placeholder,
        itemSize,
        action,
        statusSelector,
        requestParameters,
        readOnly,
        value,
        dataSelector,
        renderItem,
        onSelect,
        batchId,
        setDisableButton,
    } = props;

    const dispatch = useRootDispatch();
    const [valueSearch, setValueSearch] = useState<string>(value ?? '');

    const data = useSelector<IRootState, Page<ScdBatchToRelocate> | undefined>(dataSelector);
    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);

    const [filteredData, setFilteredData] = useState(data?.content ?? []);

    const isLoading: boolean = status !== HttpRequestStatus.ERROR && status !== HttpRequestStatus.SUCCESS;
    const isItemLoaded = ({ index }: any) => !!filteredData[index];
    const dataCount: number = filteredData?.length ?? 0;

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const searchPageable = useCallback(
        (search: string) => {
            const _pageable: Pageable = { page: 0, size: SEARCH_PAGE_SIZE_DEFAULT };
            const request = { batchId, search: search, pageable: _pageable, ...requestParameters };
            return request;
        },
        [requestParameters, batchId]
    );

    const requestNextPage = () => {
        const _pageable: Pageable = { page: (data?.number ?? 0) + 1, size: SEARCH_PAGE_SIZE_DEFAULT };

        const request = { batchId, search: valueSearch, pageable: _pageable, ...requestParameters };
        if (data && !data.last) {
            dispatch(action(request));
        }
    };

    const loadMoreItems = (_startIndex: number, _stopIndex: number): any => {
        if (!isLoading) {
            requestNextPage();
        }
    };

    const debounceSearch = useRef(
        debounce(value => {
            dispatch(action(searchPageable(value)));
        }, 500)
    );

    const handleOnChange = (searchInput: string) => {
        setValueSearch(searchInput);

        if (!searchInput.length) return;
        debounceSearch.current(searchInput);
    };

    const handleSelect = (selectValue: ScdBatchToRelocate) => {
        if (!onSelect) return;
        onSelect(selectValue);
        setValueSearch(
            selectValue?.originatorName ? `${selectValue?.originatorName} ${selectValue?.code}` : `(Sem programa) ${selectValue?.code}`
        );
    };

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            const newData = data?.content ?? [];
            const newPage = data?.number === 0;
            setFilteredData(oldData => [...(!newPage && oldData ? oldData : []), ...newData]);
        }
    }, [status, data]);

    useEffect(() => {
        if (valueSearch.length === 0) {
            dispatch(action(searchPageable('')));
        }
    }, [dispatch, action, valueSearch, searchPageable]);

    useEffect(() => {
        setValueSearch(value);
    }, [value]);

    useEffect(() => {
        if (filteredData.length || status === HttpRequestStatus.ON_GOING) {
            return setDisableButton(true);
        }

        return setDisableButton(false);
    }, [filteredData, setDisableButton, status]);

    useEffect(() => {
        return () => {
            dispatch(listBatchesToRelocateResetState());
        };
    }, [dispatch]);

    return (
        <>
            <div className="select-option-search-batch-to-relocate-input-container">
                <SimpleSearchInput
                    label={label}
                    value={valueSearch}
                    placeholder={placeholder}
                    onChange={handleOnChange}
                    readOnly={readOnly}
                    disabled
                    externalUpdate
                />
                <AutoSizer defaultHeight={MODAL_SEARCH_HEIGHT} defaultWidth={MODAL_SEARCH_WIDTH}>
                    {({ height, width }) => {
                        return (
                            <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={dataCount}>
                                {({ onItemsRendered, ref }) => (
                                    <FixedSizeList
                                        onItemsRendered={onItemsRendered}
                                        className="select-option-search-batch-to-relocate-div-style"
                                        ref={ref}
                                        height={height}
                                        itemKey={itemKey}
                                        itemCount={dataCount}
                                        itemSize={itemSize}
                                        width={width}
                                        itemData={filteredData}
                                    >
                                        {({ index, style }) =>
                                            filteredData[index] !== undefined && filteredData.length !== 0 ? (
                                                <div
                                                    key={uuidV4()}
                                                    className="select-option-search-batch-to-relocate-item-container"
                                                    style={{
                                                        ...style,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={() => {
                                                        handleSelect(filteredData[index]);
                                                    }}
                                                >
                                                    {renderItem(filteredData[index] ?? '')}
                                                </div>
                                            ) : (
                                                <> </>
                                            )
                                        }
                                    </FixedSizeList>
                                )}
                            </InfiniteLoader>
                        );
                    }}
                </AutoSizer>
            </div>
        </>
    );
};

export default BatchInputSearchToRelocate;
