import { ScdImportedOperationDetail } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationDetailTypes {
    IMPORTED_OPERATION_DETAIL_REQUEST = '@@imported-operations-detail/IMPORTED_OPERATION_DETAIL_REQUEST',
    IMPORTED_OPERATION_DETAIL_SUCCESS = '@@imported-operations-detail/IMPORTED_OPERATION_DETAIL_SUCCESS',
    IMPORTED_OPERATION_DETAIL_ERROR = '@@imported-operations-detail/IMPORTED_OPERATION_DETAIL_ERROR',
    IMPORTED_OPERATION_DETAIL_RESET_STATE = '@@imported-operations-detail/IMPORTED_OPERATION_DETAIL_RESET_STATE',
}

export interface ImportedOperationDetailState extends BaseStoreState {
    readonly operation?: ScdImportedOperationDetail;
}
