import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import './outlined-button.scss';

export interface OutlinedButtonProps {
    onClick?: () => void;
    label: string;
    disabled?: boolean;
}

export const OutlinedButton = (props: OutlinedButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--outlined">
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled}>
                <span>{t(props.label)} </span>
            </Button>
        </div>
    );
};

export default OutlinedButton;
