import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { WithdrawExternalBalanceState, WithdrawExternalBalanceTypes } from 'reducer/payment-institutions-accounts/withdraw-external-balance/types';

const initialState: WithdrawExternalBalanceState = {
    status: HttpRequestStatus.NOOP,
    error: undefined,
    paymentInstitutionAccount: undefined
};

const reducer: Reducer<WithdrawExternalBalanceState> = (state = initialState, action): WithdrawExternalBalanceState => {
    switch (action.type) {
        case WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentInstitutionAccount: action.payload, error: undefined };
        }
        case WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as withdrawExternalBalanceReducer };
