import { AxiosResponse } from 'axios';
import { BASE_URL_ADMIN } from 'services/page'
import { api } from 'services/api/api';
import { BatchOutputSignaturesResume, BatchSignatureExportRequest, BatchSignatureExportResponse, BatchSignatureImportRequest, BatchSignatureImportedResponse, BatchSignaturePreviewDocumentsImport, BatchSignatureReExportRequest, BatchSignatureReExportResponse, BatchSignatureRequest } from 'model/batch-signature';

const batchSignatureApi = () => {

    const previewDocumentsImport = (request: BatchSignatureRequest): Promise<AxiosResponse<BatchSignaturePreviewDocumentsImport>> => {
        return api.get<BatchSignaturePreviewDocumentsImport>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/signatures/import/preview`);
    }

    const importDocuments = (request: BatchSignatureImportRequest): Promise<AxiosResponse<BatchSignatureImportedResponse>> => {
        return api.post<BatchSignatureImportedResponse>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/signatures/import`, request);
    }

    const resumeAllByBatchOutput = (request: BatchSignatureRequest): Promise<AxiosResponse<BatchOutputSignaturesResume>> => {
        return api.get<BatchOutputSignaturesResume>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/signatures/resume`);
    }

    const exportSignatures = (request: BatchSignatureExportRequest): Promise<AxiosResponse<BatchSignatureExportResponse>> => {
        return api.post<BatchSignatureExportResponse>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/signatures/export`, request);
    }

    const reExportSignatures = (request: BatchSignatureReExportRequest): Promise<AxiosResponse<BatchSignatureReExportResponse>> => {
        return api.post<BatchSignatureReExportResponse>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/signatures/${request.signatureId}/re-export`)
    };

    return {
        previewDocumentsImport,
        importDocuments,
        resumeAllByBatchOutput,
        exportSignatures,
        reExportSignatures
    }
}

export default batchSignatureApi();