import { GiroWebappError } from 'model/error';
import { ImportedOperationsToBatchCreation, ScdImportedOperationToCreateBatchRequest } from 'model/imported-operation';
import { ImportedOperationsToCreateBatchTypes } from 'reducer/imported-operation/to-create-batch/types';
import { action } from 'typesafe-actions';

export const importedOperationsToCreateBatchRequest = (request: ScdImportedOperationToCreateBatchRequest) =>
    action(ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_REQUEST, request);
export const importedOperationsToCreateBatchSuccess = (operations: ImportedOperationsToBatchCreation) =>
    action(ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_SUCCESS, operations);
export const importedOperationsToCreateBatchError = (error: GiroWebappError) =>
    action(ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_ERROR, error);
export const importedOperationsToCreateBatchResetState = () =>
    action(ImportedOperationsToCreateBatchTypes.IMPORTED_OPERATIONS_TO_CREATE_BATCH_RESET_STATE);
