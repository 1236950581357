import { YearMonthInterface } from 'model/imported-operation';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.monthly-processed-operations-report.validate'

const MONTHLY_PROCESSED_OPERATIONS_REPORT_MONTH_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.month.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(2),
        errorMessage: `${I18N_PREFIX}.month.minLength`
    }
}

export const validateMonthlyProcessedOperationReportMonth = (value: string | undefined | null) => ValidationUtils.validate(value, MONTHLY_PROCESSED_OPERATIONS_REPORT_MONTH_VALIDATION)

const MONTHLY_PROCESSED_OPERATIONS_REPORT_YEAR_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.year.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(4),
        errorMessage: `${I18N_PREFIX}.year.minLength`
    }
}

export const validateMonthlyProcessedOperationReportYear = (value: string | undefined | null) => ValidationUtils.validate(value, MONTHLY_PROCESSED_OPERATIONS_REPORT_YEAR_VALIDATION)

export const validateMonthlyProcessedOperationReport = (value: YearMonthInterface | undefined) => {
    if (!value) {
        return true
    }

    return validateMonthlyProcessedOperationReportMonth(value?.month).isValid && validateMonthlyProcessedOperationReportYear(value?.year).isValid
}