import { AxiosResponse } from 'axios';
import { ScdCnabSimple } from 'model/cnab';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { generateCnabBatchError, generateCnabBatchRequest, generateCnabBatchSuccess } from 'reducer/cnab/generate-cnab-batch/actions';
import { GenerateCnabBatchTypes } from 'reducer/cnab/generate-cnab-batch/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import cnabAPI from 'services/api/cnab-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleGenerateCnabBatch(action: ReturnType<typeof generateCnabBatchRequest>) {
    try {
        const result: AxiosResponse<ScdCnabSimple> = yield call(cnabAPI.generateCnaBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(generateCnabBatchError(mapped));
            return;
        }
        yield put(generateCnabBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(generateCnabBatchError(mapped));
    }
}

function* watchGenerateCnabBatch() {
    yield takeLeading(GenerateCnabBatchTypes.GENERATE_CNAB_BATCH_REQUEST, handleGenerateCnabBatch);
}

function* generateCnabBatchSaga() {
    yield all([fork(watchGenerateCnabBatch)]);
}

export default generateCnabBatchSaga;
