import { Tooltip } from '@material-ui/core';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { Trans, useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';
import {
    TransfeeraExternalTransfersData,
    TransfeeraExternalTransfersStatus,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { getPersonType } from 'shared/util/person-type-utils';
import invalidBankSvg from 'images/bank-default.svg';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import BankIconRedirect from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-row/components/bank-icon-redirect';
import DocumentRedirectIcon from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-row/components/document-icon-redirect';
import { useMemo } from 'react';
import DescriptionIconButton from 'components/buttons/icon-buttons/description/description-icon-button';

export interface TransfeeraTransfersRowProps {
    transfeeraExternalTransferences: ReadonlyArray<TransfeeraExternalTransfersData>;
    setExternalTransference: (value: TransfeeraExternalTransfersData) => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.row';

const TransfeeraTransfersRow = (props: ListChildComponentProps<TransfeeraTransfersRowProps>) => {
    const { data, index, style } = props;

    const { transfeeraExternalTransferences, setExternalTransference } = data;

    const { DestinationBankAccount, created_at, id, status, value, bank_receipt_url, receipt_url } = transfeeraExternalTransferences[index];

    const { Bank, account, account_digit, agency, cpf_cnpj, pix_key, name } = DestinationBankAccount ?? {};

    const { t } = useTranslation();

    const TranslateFavored = useMemo((): JSX.Element => {
        if (cpf_cnpj === undefined || cpf_cnpj === null) return <div>{name ?? '-'}</div>;

        return (
            <>
                <div>{<IdentificationFormatter type={getPersonType(cpf_cnpj)} value={cpf_cnpj} />}</div>
                <div>{name}</div>
            </>
        );
    }, [cpf_cnpj, name]);

    const PixOrAccount = useMemo((): JSX.Element => {
        if (!pix_key || !pix_key.trim().length) {
            return <>{account_digit ? `${account}-${account_digit}` : account}</>;
        }

        return <>{pix_key}</>;
    }, [pix_key, account, account_digit]);

    const FavoredTooltip = useMemo(() => {
        if (cpf_cnpj === undefined || cpf_cnpj === null) return <>{name ?? '-'}</>;

        return (
            <Trans
                i18nKey={`${I18N_PREFIX}.row.favored-component`}
                t={t}
                values={{ name: name }}
                components={{ identification: <IdentificationFormatter value={cpf_cnpj} type={getPersonType(cpf_cnpj)} /> }}
            />
        );
    }, [t, cpf_cnpj, name]);

    return (
        <tr className="page-container--table-cell" style={style} key={id}>
            <td className="page-container--table-cell-title">{created_at ? <DateFormatter date={created_at} /> : '00/00/00'}</td>
            <td className="page-container--table-cell-title">
                <div>
                    <Tooltip title={FavoredTooltip ?? ''} interactive>
                        <div className="transfeera-transfers__table-fields--favored-value">{TranslateFavored}</div>
                    </Tooltip>
                </div>
            </td>
            <td className="page-container--table-cell-title">
                {pix_key ? (
                    <div className="transfeera-transfers__table-fields--agency-value">N/A</div>
                ) : (
                    <div className="transfeera-transfers__table-fields--agency-value">
                        {Bank?.image && (
                            <img
                                src={Bank?.image ?? '-'}
                                alt={Bank?.name ?? '-'}
                                className="transfeera-transfers__table-fields--agency-value__image"
                            />
                        )}
                        {!Bank?.image && <img src={invalidBankSvg} alt={Bank?.name ?? '-'} />}
                        {agency}
                    </div>
                )}
            </td>
            <td className="page-container--table-cell-title">
                <Tooltip title={PixOrAccount ?? '-'} interactive>
                    <div className="transfeera-transfers__table-fields--account-value">{PixOrAccount}</div>
                </Tooltip>
            </td>
            <td className="page-container--table-cell-title">
                <div className="page-container--table-status">
                    <span
                        className={clsx('page-container--table-status', {
                            CRIADA: status === TransfeeraExternalTransfersStatus.CRIADA,
                            RECEBIDO: status === TransfeeraExternalTransfersStatus.RECEBIDO,
                            TRANSFERIDO:
                                status === TransfeeraExternalTransfersStatus.TRANSFERIDO ||
                                status === TransfeeraExternalTransfersStatus.FINALIZADO,
                            FALHA: status === TransfeeraExternalTransfersStatus.FALHA,
                            DEVOLVIDO: status === TransfeeraExternalTransfersStatus.DEVOLVIDO,
                        })}
                    >
                        {t(`${I18N_PREFIX}.status.${status ?? 'other'}`).toUpperCase()}
                    </span>
                </div>
            </td>
            <td className="page-container--table-cell-title">
                <Tooltip title={<CurrencyFormatter value={value} prefix />} interactive>
                    <div className="transfeera-transfers__table-fields--amount-value">
                        <CurrencyFormatter value={value} prefix />
                    </div>
                </Tooltip>
            </td>
            <td className="page-container--table-cell-title">
                <DescriptionIconButton
                    onClick={() => setExternalTransference(transfeeraExternalTransferences[index])}
                    title={`${I18N_PREFIX}.tooltips.transfer-details`}
                />
            </td>
            <td className="page-container--table-cell-title">
                <BankIconRedirect url={bank_receipt_url} />
            </td>
            <td className="page-container--table-cell-title">
                <DocumentRedirectIcon url={receipt_url} />
            </td>
        </tr>
    );
};

export default TransfeeraTransfersRow;
