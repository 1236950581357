import * as H from 'history';
import { ErrorConstants, ErrorType } from 'model/enums/error-constants';
import { ErrorCustominization, ErrorHandlingCustomization, GiroWebappError, UnknownError } from 'model/error';
import { ApplicationErrorActionTypes, ApplicationErrorState } from 'reducer/application/error/types';
import { Reducer } from 'redux';
import authUtil from 'services/api/auth-util';

export const initialState: ApplicationErrorState = {
    errors: [],
    customs: {
        'error.http.unauthorized': {
            type: ErrorType.BLOCKING,
            action: {
                label: 'global.unauthorized-login',
                handler: (history: H.History, _dispatch: any) => {
                    authUtil.removeToken();
                    history.push('/');
                },
            },
        },
    } as Partial<Record<ErrorConstants, ErrorHandlingCustomization>>,
    defaultType: ErrorType.NON_BLOCKING,
};

const reducer: Reducer<ApplicationErrorState> = (state = initialState, action): ApplicationErrorState => {
    switch (action.type) {
        case ApplicationErrorActionTypes.MARK_ERROR: {
            const error: GiroWebappError = action.payload;
            const _description: string = error.message ?? UnknownError.message;
            if (state.errors.find(it => it === _description)) {
                return state;
            }
            return { ...state, errors: [...state.errors, _description] };
        }
        case ApplicationErrorActionTypes.RESOLVE_ERROR: {
            return { ...state, errors: state.errors.filter(it => it !== action.payload) };
        }
        case ApplicationErrorActionTypes.CUSTOMIZE_HANDLING: {
            const _custom: ErrorCustominization = action.payload;
            const newRecords: Partial<Record<ErrorConstants, ErrorHandlingCustomization>> = { ...state.customs, ..._custom.record };
            return { ...state, customs: newRecords, defaultType: _custom.defaultType ?? ErrorType.NON_BLOCKING };
        }
        case ApplicationErrorActionTypes.RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as applicationErrorReducer };
