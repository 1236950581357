import BackButton from 'components/buttons/back-button/back-button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import './transfers-error.scss';

interface TransfersErrorProps {
    handleRetry: () => void;
    errorComponent?: ReactNode;
}

const I18N_PREFIX = 'payment-accounts-strategy';

const TransfersError = (props: TransfersErrorProps) => {
    const { handleRetry, errorComponent: ErrorComponent } = props;

    const { t } = useTranslation();

    return (
        <div className="transfers-error">
            {ErrorComponent}
            <div className="transfers-error--container">
                <div className="transfers-error--message">{t(`${I18N_PREFIX}.error-message`)}</div>
                <BackButton label={t('global.try-again')} onClick={handleRetry} />
            </div>
        </div>
    );
};

export default TransfersError;
