import Loading from 'components/loading/loading';
import OriginatorsRow, { OriginatorsRowProps } from 'entities/originator/components/originators-row/originators-row';
import { ScdOriginatorList } from 'model/originator';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface OriginatorsListProps {
    originators: ScdOriginatorList[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.company-group.table';

const OriginatorsList = (props: OriginatorsListProps) => {
    const { originators, isLoading } = props;

    const { t } = useTranslation();

    const originatorsRowObjectData: OriginatorsRowProps = {
        originators,
    };

    return (
        <>
            {isLoading && !originators.length && <Loading isTable />}
            {originators.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={originatorsRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={originators.length}
                            className="page-container--table-container"
                        >
                            {OriginatorsRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-company-group__table-empty-filter">
                            <span className="scd-company-group__table-empty-filter-image" />
                            <span className="scd-company-group__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default OriginatorsList;
