import DashedButton from 'components/buttons/dashed-button/dashed-button';
import CardEdition, { ICardEdition } from 'components/card/card-edition/card-edition';
import CompanyOrganizationsCardCreate from 'entities/company/components/company-organizations-card/company-organizations-card-create/company-organizations-card-create';
import CompanyOrganizationCardDetail from 'entities/company/components/company-organizations-card/company-organizations-card-detail/company-organizations-card-detail';
import { validateCompanyOrganization } from 'entities/company/validations/company-organizations-validation';
import { defaultOrganizationToCreate, ScdOrganization } from 'model/organization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidV4 } from 'uuid';

interface CompanyOrganizationsManagerProps {
    companyOrganizations: ScdOrganization[] | undefined;
    onUpdate: (companyOrganizationsList: ScdOrganization[]) => void;
    editionUpdate?: (value: boolean) => void;
}

interface CompanyOrganizationEdition extends ScdOrganization, ICardEdition {
    uniqueKey?: string;
}

const I18N_PREFIX = 'pages.scd.company.card.organizations';

const CompanyOrganizationsManager = (props: CompanyOrganizationsManagerProps) => {
    const { companyOrganizations, onUpdate, editionUpdate } = props;

    const { t } = useTranslation();

    const [editing, setEditing] = useState<boolean>(false);

    const [companyOrganizationsList, setCompanyOrganizationsList] = useState<CompanyOrganizationEdition[]>(
        companyOrganizations?.map(it => ({
            ...it,
            isNew: false,
            uniqueKey: uuidV4(),
        })) ?? []
    );

    const [alreadyAdd, setAlreadyAdd] = useState<boolean>(false);

    const updateEditing = (value: boolean) => {
        setEditing(value);

        if (editionUpdate) {
            editionUpdate(value);
        }
    };

    const handleAdd = (_event: any) => {
        setCompanyOrganizationsList(ps => [...ps, { ...defaultOrganizationToCreate, isNew: true, uniqueKey: uuidV4() }]);
    };

    const handleAlreadyAdd = (companyOrganization: ScdOrganization) => {
        const isAlreadyAdd = companyOrganizationsList.some(it => it.id === companyOrganization.id);

        setAlreadyAdd(isAlreadyAdd);
    };

    const handleSave = (_index: number, _update: CompanyOrganizationEdition) => {
        const update = companyOrganizationsList.map((item, index) => (index === _index ? { ..._update, isNew: false } : item));

        setCompanyOrganizationsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
        updateEditing(false);
    };

    const handleRemove = (_index: number) => {
        const update = companyOrganizationsList.filter((_it, index) => index !== _index);

        setCompanyOrganizationsList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
    };

    const handleCancel = (_index: number) => {
        if (!companyOrganizationsList[_index]) return;

        if (companyOrganizationsList[_index].isNew) {
            setCompanyOrganizationsList(prev => prev.filter((_it, index) => index !== _index));
        }
        updateEditing(false);
        setAlreadyAdd(false);
    };

    return (
        <div className="scd-company-create-update__manager">
            {companyOrganizationsList &&
                companyOrganizationsList.length > 0 &&
                companyOrganizationsList.map((item, index) => (
                    <CardEdition
                        key={item.uniqueKey}
                        entity={item}
                        onSave={value => handleSave(index, value)}
                        validate={validateCompanyOrganization}
                        onRemove={() => handleRemove(index)}
                        onCancel={() => handleCancel(index)}
                        editable={true}
                        removable={true}
                        alreadyAdd={alreadyAdd}
                        name={t(`${I18N_PREFIX}.name`, { number: `${index + 1}` })}
                        deleteTitle={t(`${I18N_PREFIX}.modal.delete.title`)}
                        deleteMessage={t(`global.modal.delete.message`)}
                        cancelMessage={t('entity.cancel.info')}
                    >
                        {({ isEditing, onChange, entity, showValidation }) => (
                            <>
                                {isEditing ? (
                                    <CompanyOrganizationsCardCreate
                                        entity={entity}
                                        onChange={onChange}
                                        showValidation={showValidation}
                                        alreadyAdd={alreadyAdd}
                                        handleAlreadyAdd={handleAlreadyAdd}
                                        index={index}
                                    />
                                ) : (
                                    <CompanyOrganizationCardDetail entity={entity} />
                                )}
                            </>
                        )}
                    </CardEdition>
                ))}

            {!editing && (
                <div className="scd-company-create-update__manager--button">
                    <DashedButton label={t(`${I18N_PREFIX}.title`)} onClick={handleAdd} />
                </div>
            )}
        </div>
    );
};

export default CompanyOrganizationsManager;
