import Loading from 'components/loading/loading';
import BanksRow, { BanksRowProps } from 'entities/bank/components/bank-row/bank-row';
import { BankList } from 'model/bank';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface BanksListProps {
    banks: BankList[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.bank';

const BanksList = (props: BanksListProps) => {
    const { banks, isLoading } = props;

    const { t } = useTranslation();

    const banksRowObjectData: BanksRowProps = {
        banks,
    };

    return (
        <>
            {isLoading && !banks.length && <Loading isTable />}
            {banks.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={banksRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={banks.length}
                            className="page-container--table-container"
                        >
                            {BanksRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-bank__table-empty-filter">
                            <span className="scd-bank__table-empty-filter-image" />
                            <span className="scd-bank__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default BanksList;
