import { PaymentTrasactionToProcessResponse } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ProcessPaymentTransactionsTypes {
    PROCESS_PAYMENT_TRANSACTIONS_REQUEST = '@@payment-transactions-process/PROCESS_PAYMENT_TRANSACTIONS_REQUEST',
    PROCESS_PAYMENT_TRANSACTIONS_SUCCESS = '@@payment-transactions-process/PROCESS_PAYMENT_TRANSACTIONS_SUCCESS',
    PROCESS_PAYMENT_TRANSACTIONS_ERROR = '@@payment-transactions-process/PROCESS_PAYMENT_TRANSACTIONS_ERROR',
    PROCESS_PAYMENT_TRANSACTIONS_RESET = '@@payment-transactions-process/PROCESS_PAYMENT_TRANSACTIONS_RESET',
}

export interface ProcessPaymentTransactionsState extends BaseStoreState {
    processedPaymentTransactions?: PaymentTrasactionToProcessResponse
}
