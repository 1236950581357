import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    CreatePaymentTransactionsInSeriesTypes,
    CreatePaymentTransactionsInSeriesState,
} from 'reducer/payment-transaction-in-series/create-payment-transactions-in-series/types';

const initialState: CreatePaymentTransactionsInSeriesState = {
    status: HttpRequestStatus.NOOP,
    paymentTransactionsInSeriesErrors: undefined,
    error: undefined,
};

const reducer: Reducer<CreatePaymentTransactionsInSeriesState> = (state = initialState, action): CreatePaymentTransactionsInSeriesState => {
    switch (action.type) {
        case CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransactionsInSeriesErrors: action.payload, error: undefined };
        }
        case CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSATIONS_IN_SERIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreatePaymentTransactionsInSeriesTypes.CREATE_PAYMENT_TRANSACTIONS_IN_SERIES_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createPaymentTransactionsInSeriesReducer };
