import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyItemSimple } from 'model/company-item';

export type ApiResponse = Record<string, any>;

export enum UpdateCompanyItemTypes {
    UPDATE_COMPANY_ITEM_REQUEST = '@@company-item-update/UPDATE_COMPANY_ITEM_REQUEST',
    UPDATE_COMPANY_ITEM_SUCCESS = '@@company-item-update/UPDATE_COMPANY_ITEM_SUCCESS',
    UPDATE_COMPANY_ITEM_ERROR = '@@company-item-update/UPDATE_COMPANY_ITEM_ERROR',
    UPDATE_COMPANY_ITEM_RESET = '@@company-item-update/UPDATE_COMPANY_ITEM_RESET',
}

export interface UpdateCompanyItemState extends BaseStoreState {
    readonly companyItem?: ScdCompanyItemSimple;
}
