import IssuerPoliciesRow from 'entities/issuer/issuer-policies/components/issuer-policies-row/issuer-policies-row';
import { IssuerListPolicies } from 'model/issuer-policy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface ListPoliciesProps {
    policies: IssuerListPolicies[];
    isLoading: boolean;
}

export interface IssuerPoliciesRowProps {
    policies: IssuerListPolicies[];
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: HTMLElement | null) => void;
}

const I18N_PREFIX = 'pages.scd.issuer-policy';

const ListPolicies = (props: ListPoliciesProps) => {
    const { policies, isLoading } = props;

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const issuerPoliciesObjectData: IssuerPoliciesRowProps = {
        policies,
        anchorEl,
        setAnchorEl,
    };

    return (
        <>
            {policies.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={issuerPoliciesObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={policies.length}
                            className="page-container--table-container"
                        >
                            {IssuerPoliciesRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="issuer-policies__table-empty-filter">
                            <span className="issuer-policies__table-empty-filter-image" />
                            <span className="issuer-policies__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ListPolicies;
