import { MenuItem, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import { BANK_SVG_URL } from 'config/constants';
import { ScdPaymentTransactionInSeriesCreate } from 'entities/payment-transaction/payment-transaction-in-series/payment-transaction-in-series';
import { validateScdNewPaymentTransaction } from 'entities/payment-transaction/validation/new-payment-transaction-validation';
import invalidBankSvg from 'images/bank-default.svg';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IRootState } from 'reducer';
import { searchPaymentFavoredRequest } from 'reducer/payment-favored/search-payment-favored/actions';

const I18N_PREFIX = 'pages.scd.payment-transaction.new-transaction';

interface DetailPaymentTransactionInSeriesProps {
    detailPaymentTransaction: ScdPaymentTransactionInSeriesCreate;
    newPaymentTransactionsList: ScdPaymentTransactionInSeriesCreate[];
    setNewPaymentTransactionsList: (value: ScdPaymentTransactionInSeriesCreate[]) => void;
    index: number;
}

const DetailPaymentTransactionInSeries = (props: DetailPaymentTransactionInSeriesProps) => {
    const { detailPaymentTransaction, newPaymentTransactionsList, setNewPaymentTransactionsList, index } = props;
    const { bankAccountSelected } = detailPaymentTransaction;

    const [modalDescription, setModalDescription] = useState<boolean>(false);
    const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

    const { t } = useTranslation();

    const handleDelete = (uniqueIdentifier: string) => {
        const filteredData = newPaymentTransactionsList.filter(value => value.uniqueIdentifier !== uniqueIdentifier);
        setNewPaymentTransactionsList(filteredData);
        toast.success(t(`${I18N_PREFIX}.toast.delete`), { icon: false });
    };

    return (
        <>
            <div
                className="scd-payment-transaction-in-series__content-new-transaction"
                onMouseEnter={() => setShowDeleteIcon(true)}
                onMouseLeave={() => setShowDeleteIcon(false)}
            >
                <span style={{ width: '30px' }}>{String(index + 1)}</span>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-value">
                    <SimpleCurrencyInput
                        label={t(`${I18N_PREFIX}.amount`)}
                        placeholder={t(`${I18N_PREFIX}.amount`)}
                        value={detailPaymentTransaction?.amount}
                        externalUpdate
                        autoFocus
                        disabled={true}
                        readOnly
                    />
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-favored">
                    <div className="scd-payment-transaction-in-series__content-new-transaction__field-favored-content">
                        <ListSearchInput<ScdPaymentFavoredSearch>
                            action={searchPaymentFavoredRequest}
                            itemSize={SEARCH_ITEM_SIZE_LARGE}
                            renderItem={favored => `${favored?.identification} ${favored?.name}`}
                            statusSelector={(state: IRootState) => state.searchPaymentFavored.status}
                            dataSelector={(state: IRootState) => state.searchPaymentFavored.paymentFavored}
                            label={t(`${I18N_PREFIX}.favored`)}
                            placeholder={t(`${I18N_PREFIX}.favored`)}
                            disabled={true}
                            value={
                                detailPaymentTransaction?.favoredIdentification && detailPaymentTransaction?.favoredName
                                    ? `${detailPaymentTransaction?.favoredIdentification} ${detailPaymentTransaction?.favoredName}`
                                    : ''
                            }
                        />
                    </div>
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__field-account">
                    <SimpleSelectInput
                        label={t(`${I18N_PREFIX}.account`)}
                        value={'defaultValue'}
                        placeholder={t(`${I18N_PREFIX}.account`)}
                        disabled={true}
                        externalUpdate
                        readOnly
                    >
                        <MenuItem value="defaultValue" disabled>
                            {bankAccountSelected?.pixKey ? (
                                <>
                                    <span> {bankAccountSelected?.pixKey} </span>
                                </>
                            ) : (
                                <>
                                    {bankAccountSelected?.bankIdentification && (
                                        <span style={{ marginRight: '4px' }}>
                                            <Tooltip title={bankAccountSelected?.bankName}>
                                                <img
                                                    src={`${BANK_SVG_URL}/${bankAccountSelected?.bankIdentification}.svg`}
                                                    alt={bankAccountSelected?.bankName ?? ''}
                                                    width={20}
                                                    height={20}
                                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                        ((e.target as HTMLImageElement).src = invalidBankSvg)
                                                    }
                                                />
                                            </Tooltip>
                                        </span>
                                    )}
                                    <span style={{ marginRight: '2px' }}>
                                        {bankAccountSelected?.agencyDigit
                                            ? `${bankAccountSelected?.agencyNumber}-${bankAccountSelected?.agencyDigit}`
                                            : bankAccountSelected?.agencyNumber}
                                    </span>
                                    <span style={{ marginRight: '2px' }}>
                                        {bankAccountSelected?.accountDigit
                                            ? `${bankAccountSelected?.accountNumber}-${bankAccountSelected.accountDigit}`
                                            : bankAccountSelected?.accountNumber}
                                    </span>
                                </>
                            )}
                        </MenuItem>
                    </SimpleSelectInput>
                </div>
                <div className="scd-payment-transaction-in-series__content-new-transaction__icons ">
                    <Tooltip title={String(t('tooltips.description'))}>
                        <div
                            className={clsx(
                                'scd-payment-transaction-in-series__content-new-transaction__icons-description scd-payment-transaction-in-series__content-new-transaction__icons-icon',
                                {
                                    active: detailPaymentTransaction?.description,
                                    disabled: !validateScdNewPaymentTransaction(detailPaymentTransaction),
                                }
                            )}
                            onClick={() =>
                                validateScdNewPaymentTransaction(detailPaymentTransaction) ? setModalDescription(true) : undefined
                            }
                        />
                    </Tooltip>
                    {showDeleteIcon ? (
                        <div
                            className="scd-payment-transaction-in-series__content-new-transaction__icons-delete"
                            onClick={() => handleDelete(detailPaymentTransaction?.uniqueIdentifier ?? '')}
                        />
                    ) : (
                        <div className="scd-payment-transaction-in-series__content-new-transaction__icons-blank" />
                    )}
                </div>
                <ModalTextArea
                    title={t(`${I18N_PREFIX}.description.title`)}
                    label={t(`${I18N_PREFIX}.description.label`)}
                    openModal={modalDescription}
                    defaultValue={detailPaymentTransaction?.description ?? ''}
                    onClose={() => setModalDescription(false)}
                    isActive={false}
                />
            </div>
        </>
    );
};

export default DetailPaymentTransactionInSeries;
