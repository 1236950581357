import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdMonthlyCommissionSimple } from 'model/monthly-commission';
import { markError } from 'reducer/application/error/actions';
import {
    releaseMonthlyCommissionError,
    releaseMonthlyCommissionRequest,
    releaseMonthlyCommissionSuccess,
} from 'reducer/monthly-commission/release-monthly-commission/actions';
import { ReleaseMonthlyCommissionTypes } from 'reducer/monthly-commission/release-monthly-commission/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import monthlyCommissionAPI from 'services/api/monthly-commission-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleReleaseMonthlyCommission(action: ReturnType<typeof releaseMonthlyCommissionRequest>) {
    try {
        const result: AxiosResponse<ScdMonthlyCommissionSimple> = yield call(monthlyCommissionAPI.releaseMonthlyCommission, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(releaseMonthlyCommissionError(mapped));
            return;
        }
        yield put(releaseMonthlyCommissionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(releaseMonthlyCommissionError(mapped));
    }
}

function* watchReleaseMonthlyCommission() {
    yield takeLeading(ReleaseMonthlyCommissionTypes.RELEASE_MONTHLY_COMMISSION_REQUEST, handleReleaseMonthlyCommission);
}

function* releaseMonthlyCommissionSaga() {
    yield all([fork(watchReleaseMonthlyCommission)]);
}

export default releaseMonthlyCommissionSaga;
