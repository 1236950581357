import FormatterUtils from 'components/formatter/formatter-utils';
import { ScdBatchExpirationsReport } from 'model/batch';
import { ScdImportedExpirationNormalizedDataToReport, ScdImportedExpirationToReport } from 'model/imported-expiration';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { NormalizationUtils } from 'shared/util/normalization-utils';
import { getPersonType } from 'shared/util/person-type-utils';

type ReportData = Omit<ScdImportedExpirationToReport, 'id'>;
type ReportHeader = Record<keyof ReportData, string>;

const I18N_PREFIX = 'pages.scd.batch.detail.report.expiration.headers';

interface BatchExpirationsReportProps {
    batchExpirationsReport: ScdBatchExpirationsReport;
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
}

const BatchExpirationsReport = (props: BatchExpirationsReportProps) => {
    const { batchExpirationsReport, csvLinkRef } = props;
    const { t } = useTranslation();

    const expirationsFilename = useMemo(() => {
        const formattedDate = FormatterUtils.formatDateByYear(batchExpirationsReport?.date);
        return `RelatorioDeFaturas_${batchExpirationsReport?.organization?.name ?? 'giro'}_${
            batchExpirationsReport?.code
        }_${formattedDate}.csv`;
    }, [batchExpirationsReport]);

    const EXPIRATIONS_REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            ccbNumber: t(`${I18N_PREFIX}.ccbNumber`),
            personName: t(`${I18N_PREFIX}.personName`),
            identification: t(`${I18N_PREFIX}.identification`),
            installmentValue: t(`${I18N_PREFIX}.installmentValue`),
            expirationNumber: t(`${I18N_PREFIX}.expirationNumber`),
            dueDate: t(`${I18N_PREFIX}.dueDate`),
        };
    }, [t]);

    const BATCH_EXPIRATIONS_NORMALIZED_DATA: ScdImportedExpirationNormalizedDataToReport[] = useMemo(() => {
        return batchExpirationsReport.importedExpirations.map(it => ({
            ...it,
            identification: FormatterUtils.formatIdentification(getPersonType(it.identification), it.identification),
            installmentValue: NormalizationUtils.normalizeCurrencyValueToLocale(it.installmentValue),
        }));
    }, [batchExpirationsReport]);

    return (
        <>
            <CSVLink
                className="hidden"
                filename={expirationsFilename}
                ref={csvLinkRef}
                data={BATCH_EXPIRATIONS_NORMALIZED_DATA ?? []}
                headers={Object.keys(EXPIRATIONS_REPORT_HEADERS).map(key => ({ key, label: EXPIRATIONS_REPORT_HEADERS[key] }))}
            />
        </>
    );
};

export default BatchExpirationsReport;
