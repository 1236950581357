import { AxiosResponse } from 'axios';
import { LegacyScdProgram, ScdProgramGet, ScdProgramList, ScdProgramSimple, ScdProgramToCreateUpdate, ScdProgramResponse, ProgramFilterPageable } from 'model/program';
import { SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';

export const program = () => {
    const createProgram = (program: ScdProgramToCreateUpdate): Promise<AxiosResponse<ScdProgramResponse>> => {
        return api.post<ScdProgramResponse>(`${BASE_URL_ADMIN}/scd-programs`, program)
    }

    const legacyCreateProgram = (program: LegacyScdProgram): Promise<AxiosResponse<ScdProgramSimple>> => {
        return api.post<ScdProgramSimple>(`${BASE_URL_ADMIN}/legacy/scd-programs`, program);
    };

    const updateProgram = (program: ScdProgramToCreateUpdate): Promise<AxiosResponse<ScdProgramResponse>> => {
        return api.put<ScdProgramResponse>(`${BASE_URL_ADMIN}/scd-programs/${program.id}`, program)
    }

    const legacyUpdateProgram = (program: LegacyScdProgram): Promise<AxiosResponse<ScdProgramSimple>> => {
        return api.put<ScdProgramSimple>(`${BASE_URL_ADMIN}/legacy/scd-programs`, program);
    };

    const detailProgram = (programId: string): Promise<AxiosResponse<ScdProgramGet>> => {
        return api.get<ScdProgramGet>(`${BASE_URL_ADMIN}/scd-programs/${programId}`);
    };

    const listPrograms = (request: ProgramFilterPageable): Promise<AxiosResponse<Page<ScdProgramList>>> => {
        const { filter, pageable } = request

        const _requestFilters = [
            filter?.status ? `status=${filter.status}` : undefined,
            filter?.productType ? `productType=${filter.productType}` : undefined,
            filter?.identifier ? `identifier=${filter.identifier}` : undefined,
            filter?.originatorId ? `originatorId=${filter.originatorId}` : undefined,
            filter?.companyGroupId ? `companyGroupId=${filter.companyGroupId}` : undefined
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<Page<ScdProgramList>>(`${BASE_URL_ADMIN}/scd-programs?${_requestFilters}`, { params: { ...pageable } });
    };

    const searchPrograms = (request: SearchRequest): Promise<AxiosResponse<Page<ScdProgramSimple>>> => {
        return api.get<Page<ScdProgramSimple>>(`${BASE_URL_ADMIN}/scd-programs/search?name=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        legacyCreateProgram,
        detailProgram,
        legacyUpdateProgram,
        listPrograms,
        searchPrograms,
        createProgram,
        updateProgram
    };
};

export default program();
