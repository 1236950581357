import { ScdCompanyToSimple } from 'model/company';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum UpdateCompanyTypes {
    UPDATE_COMPANY_REQUEST = '@@company-update/UPDATE_COMPANY_REQUEST',
    UPDATE_COMPANY_SUCCESS = '@@company-update/UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_ERROR = '@@company-update/UPDATE_COMPANY_ERROR',
    UPDATE_COMPANY_RESET = '@@company-update/UPDATE_COMPANY_RESET',
}

export interface UpdateCompanyState extends BaseStoreState {
    readonly updatedCompany?: ScdCompanyToSimple;
}
