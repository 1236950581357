import { BatchCardType } from 'entities/batch/components/batch-card/batch-card';
import { useTranslation } from 'react-i18next';
import BalanceLoading from 'shared/external/payment-accounts-strategy/balance/components/balance-loading/balance-loading';
import './loading-card.scss';

export interface LoadingCardProps {
    type: BatchCardType.LOADING;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const LoadingCard = () => {
    const { t } = useTranslation();

    return (
        <div className="loading-card">
            <div className="loading-card__title">
                <span className="loading-card__title--tooltip">{t(`${I18N_PREFIX}.loading`)}</span>
            </div>
            <div className="loading-card__display">
                <div className="loading-card__display--box-loading">
                    <BalanceLoading />
                </div>
            </div>
        </div>
    );
};

export default LoadingCard;
