import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import { OperationFilterRequest } from 'model/imported-operation';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseCcbOperationFilterChipsProps {
    filterActive: OperationFilterRequest;
    setFilterActive: (value: OperationFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

type OperationFilterRequestType = keyof Omit<OperationFilterRequest, 'todayOnly' | 'batchId' | 'handleErrorMode' | 'createdDate'>;

const useCcbOperationFilterChips = (props: UseCcbOperationFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: OperationFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<OperationFilterRequestType, () => void> = useMemo(() => {
        return {
            scdProgram: () => clearFilterSupport({ ...filterActive, scdProgram: undefined }),
            ccbNumber: () => clearFilterSupport({ ...filterActive, ccbNumber: undefined }),
            batchCode: () => clearFilterSupport({ ...filterActive, batchCode: undefined, batchId: undefined }),
            acceptedDate: () => clearFilterSupport({ ...filterActive, acceptedDate: undefined }),
            type: () => clearFilterSupport({ ...filterActive, type: undefined }),
            status: () => clearFilterSupport({ ...filterActive, status: [] }),
            originator: () => clearFilterSupport({ ...filterActive, originator: undefined }),
            identification: () => clearFilterSupport({ ...filterActive, identification: undefined }),
            withBatchOnly: () => clearFilterSupport({ ...filterActive, withBatchOnly: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: OperationFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filter.buttons.${title}`);

            return `${titleTranslation}: `;
        },
        [t]
    );
    const ccbOperationFilterChips = useMemo(() => {
        const items: Record<OperationFilterRequestType, ChipFilterItem> = {
            scdProgram: {
                isActive: !!filterActive.scdProgram,
                onDelete: handleClearFilter['scdProgram'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('scdProgram')} component={filterActive.scdProgram} />,
            },
            ccbNumber: {
                isActive: !!filterActive.ccbNumber,
                onDelete: handleClearFilter['ccbNumber'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('ccb')} component={filterActive.ccbNumber} />,
            },
            batchCode: {
                isActive: !!filterActive.batchCode,
                onDelete: handleClearFilter['batchCode'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('batch')} component={filterActive.batchCode} />,
            },
            acceptedDate: {
                isActive: !!filterActive.acceptedDate,
                onDelete: handleClearFilter['acceptedDate'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('acceptedDate')}
                        component={
                            <>
                                {FormatterUtils.isValidDate(filterActive.acceptedDate?.start) && (
                                    <>
                                        <DateFormatter date={filterActive.acceptedDate?.start} />
                                        {' - '}
                                    </>
                                )}
                                {FormatterUtils.isValidDate(filterActive.acceptedDate?.end) && (
                                    <DateFormatter date={filterActive.acceptedDate?.start ?? ''} />
                                )}
                            </>
                        }
                    />
                ),
            },
            type: {
                isActive: !!filterActive.type,
                onDelete: handleClearFilter['type'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('product')}
                        component={t(`${I18N_PREFIX}.product.options.${filterActive.type}`)}
                    />
                ),
            },
            status: {
                isActive: !!filterActive?.status && filterActive?.status?.length > 0,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={filterActive.status?.map(status => `${t(`${I18N_PREFIX}.status.options.${status}`)}`).join(', ')}
                    />
                ),
            },
            originator: {
                isActive: !!filterActive.originator,
                onDelete: handleClearFilter['originator'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('originator')} component={filterActive.originator} />,
            },
            identification: {
                isActive: !!filterActive.identification,
                onDelete: handleClearFilter['identification'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('loaner')} component={filterActive.identification} />,
            },

            withBatchOnly: {
                isActive: !!filterActive.withBatchOnly,
                onDelete: handleClearFilter['withBatchOnly'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('withBatchOnly')} component={filterActive.withBatchOnly} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isCcbOperationChipsActive: boolean = useMemo(() => {
        return ccbOperationFilterChips.some(it => !!it.isActive);
    }, [ccbOperationFilterChips]);

    return {
        ccbOperationFilterChips,
        isCcbOperationChipsActive,
    };
};

export default useCcbOperationFilterChips;
