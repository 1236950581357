import { PopperProps, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import { BANK_SVG_URL } from 'config/constants';
import { PaymentTransactionsRowProps } from 'entities/payment-transaction/components/list-payment-transactions/list-payment-transactions';
import PaymentTransactionMoreOptionsMenuItems from 'entities/payment-transaction/components/payment-transaction-more-options-menu-items/payment-transaction-more-options-menu-items';
import invalidBankSvg from 'images/bank-default.svg';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import { Trans, useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';
import { ROW_DIFFERENCE } from 'shared/infinite-components/constants';
import { getPersonType } from 'shared/util/person-type-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction';

const PaymentTransactionRow = (props: ListChildComponentProps<PaymentTransactionsRowProps>) => {
    const { index, data, style } = props;

    const { isNewTransaction, paymentTransactions, anchorEl, setAnchorEl, selectedPaymentTransactionId, setSelectedPaymentTransactionId } =
        data;

    const { t } = useTranslation();

    const {
        id,
        date,
        favoredName,
        favoredIdentification,
        amount,
        accountNumber,
        accountDigit,
        status,
        pixKey,
        bankName,
        bankIdentification,
        agencyNumber,
        type,
        originatorName,
    } = paymentTransactions[index];

    const translateFavored = (identification: string, name: string): React.ReactNode => {
        if (identification === undefined || identification === null) return name ?? '-';

        return (
            <>
                <div>{<IdentificationFormatter type={getPersonType(identification)} value={identification} />}</div>
                <div>{name}</div>
            </>
        );
    };

    const translateFavoredTooltip = (identification: string, name: string): React.ReactNode => {
        if (identification === undefined || identification === null) return name ?? '-';

        return (
            <Trans
                i18nKey={`${I18N_PREFIX}.favored-component`}
                t={t}
                values={{ name: name }}
                components={{ identification: <IdentificationFormatter value={identification} type={getPersonType(identification)} /> }}
            />
        );
    };

    const getPixOrAccount = (pixKey?: string, accountNumber?: string, accountDigit?: string): string | undefined => {
        if (!pixKey || !pixKey.trim().length) {
            return accountDigit ? `${accountNumber}-${accountDigit}` : accountNumber;
        }

        return pixKey;
    };

    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    // DEPRECATED: MONTHLY_COMMISSION will be removed in the future
    const typeList: PaymentTransactionType[] = Object.values(PaymentTransactionType).filter(
        type => type !== PaymentTransactionType.MONTHLY_COMMISSION
    );

    return (
        <tr
            className={clsx('page-container--table-infinite-scroll-cell', {
                newActive: isNewTransaction,
                MANUAL: type === PaymentTransactionType.MANUAL,
                DISBURSEMENT_BY_BATCH: type === PaymentTransactionType.DISBURSEMENT_BY_BATCH,
                DISBURSEMENT_TO_DEBTOR: type === PaymentTransactionType.DISBURSEMENT_TO_DEBTOR,
                MANUAL_DISBURSEMENT: type === PaymentTransactionType.MANUAL_DISBURSEMENT,
                AMENDED_DISBURSEMENT: type === PaymentTransactionType.AMENDED_DISBURSEMENT,
                INTERNAL_REPAYMENT: type === PaymentTransactionType.INTERNAL_REPAYMENT,
                FALLBACK: !typeList.includes(type),
            })}
            key={id}
            style={{ ...style, height: Number(style.height) - ROW_DIFFERENCE }}
        >
            <td className="page-container--table-infinite-scroll-cell-title">
                <div> {date ? <DateFormatter date={date} /> : '00/00/00'} </div>
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                <Tooltip title={<CurrencyFormatter value={amount} prefix />} interactive>
                    <div className="scd-payment-transaction__table-fields-table-column__amount-value">
                        <CurrencyFormatter value={amount} prefix />
                    </div>
                </Tooltip>
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                <div className="scd-payment-transaction__table-fields">
                    <Tooltip title={originatorName ?? t(`${I18N_PREFIX}.no-originator`)} interactive>
                        <div className="scd-payment-transaction__table-fields-table-column__originator-value">
                            {originatorName ?? t(`${I18N_PREFIX}.no-originator`)}
                        </div>
                    </Tooltip>
                </div>
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                <div className="scd-payment-transaction__table-fields">
                    <Tooltip
                        title={translateFavoredTooltip(favoredIdentification, favoredName) ?? ''}
                        interactive
                        PopperProps={popperProps}
                    >
                        <div className="scd-payment-transaction__table-fields-table-column__favored-value">
                            {translateFavored(favoredIdentification, favoredName)}
                        </div>
                    </Tooltip>
                </div>
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                {pixKey ? (
                    <div className="scd-payment-transaction__table-fields-table-column__agency-value">N/A</div>
                ) : (
                    <div className="scd-payment-transaction__table-fields-table-column__agency-value">
                        {bankIdentification && (
                            <Tooltip title={bankName}>
                                <img
                                    src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                                    alt={bankName ?? ''}
                                    onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                        ((e.target as HTMLImageElement).src = invalidBankSvg)
                                    }
                                />
                            </Tooltip>
                        )}
                        {!bankIdentification && (
                            <span style={{ marginRight: '8px' }}>
                                <Tooltip title={bankName ?? t('tooltips.bankInvalid')}>
                                    <img src={invalidBankSvg} alt={bankName ?? ''} />
                                </Tooltip>
                            </span>
                        )}
                        {agencyNumber}
                    </div>
                )}
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                <Tooltip
                    title={pixKey ?? (accountDigit ? `${accountNumber}-${accountDigit}` : accountNumber)}
                    interactive
                    PopperProps={popperProps}
                >
                    <div className="scd-payment-transaction__table-fields-table-column__account-value">
                        {getPixOrAccount(pixKey, accountNumber, accountDigit)}
                    </div>
                </Tooltip>
            </td>
            <td className="page-container--table-infinite-scroll-cell-title">
                <div className="page-container--table-status">
                    <span
                        className={clsx('page-container--table-status', {
                            CONFIRMED: status === PaymentTransactionStatus.CONFIRMED,
                            ERROR: status === PaymentTransactionStatus.ERROR,
                            WAITING: status === PaymentTransactionStatus.WAITING,
                            ARCHIVED: status === PaymentTransactionStatus.ARCHIVED,
                            VALIDATING: status === PaymentTransactionStatus.VALIDATING,
                            INCONSISTENT: status === PaymentTransactionStatus.INCONSISTENT,
                            PENDING: status === PaymentTransactionStatus.PENDING,
                            UNRESOLVED: status === PaymentTransactionStatus.UNRESOLVED,
                        })}
                    >
                        {t(`${I18N_PREFIX}.filter.input.status.options.${status}`).toUpperCase()}
                    </span>
                </div>
            </td>
            <td className="page-container--table-infinite-scroll-cell-icon">
                <MoreOptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} onClick={() => setSelectedPaymentTransactionId(id)}>
                    <PaymentTransactionMoreOptionsMenuItems selectedPaymentTransactionId={selectedPaymentTransactionId} />
                </MoreOptionsMenu>
            </td>
        </tr>
    );
};

export default PaymentTransactionRow;
