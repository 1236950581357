import { ScdOrganizationSimple } from 'model/organization';
import { ScdOrganization } from 'model/organization';
import { GiroWebappError } from 'model/error';
import { CreateOrganizationTypes } from './types';
import { action } from 'typesafe-actions';

export const createOrganizationRequest = (request: ScdOrganization) => action(CreateOrganizationTypes.CREATE_ORGANIZATION_REQUEST, request);
export const createOrganizationSuccess = (organizationSimple: ScdOrganizationSimple) =>
    action(CreateOrganizationTypes.CREATE_ORGANIZATION_SUCCESS, organizationSimple);
export const createOrganizationError = (error: GiroWebappError) => action(CreateOrganizationTypes.CREATE_ORGANIZATION_ERROR, error);
export const createOrganizationResetState = () => action(CreateOrganizationTypes.CREATE_ORGANIZATION_RESET_STATE);
