import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdTaxList } from 'model/tax';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.tax.table';

interface ScdTaxHeaderProps {
    taxes: ScdTaxList[];
    handleSort: (property: keyof ScdTaxList, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export const ScdTaxHeader = (props: ScdTaxHeaderProps) => {
    const { t } = useTranslation();
    const { taxes, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof ScdTaxList) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (taxes && taxes.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.date`).toUpperCase()} sortable={mapSortable('date')} />
            <SortableColumn label={t(`${I18N_PREFIX}.value`).toUpperCase()} sortable={mapSortable('value')} />
            <SortableColumn label={t(`${I18N_PREFIX}.type`).toUpperCase()} sortable={mapSortable('type')} />
        </tr>
    );
};

export default ScdTaxHeader;
