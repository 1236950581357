import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdBatchData } from 'model/batch';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListBatchesTypes } from 'reducer/batch/list-batches/types';
import { listBatchesError, listBatchesSuccess, listBatchesRequest } from 'reducer/batch/list-batches/actions';
import { Page } from 'services/page';

function* handleListBatches(action: ReturnType<typeof listBatchesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdBatchData>> = yield call(batchAPI.listBatches, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listBatchesError(mapped));
            return;
        }
        yield put(listBatchesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listBatchesError(mapped));
    }
}

function* watchListBatches() {
    yield takeLatest(ListBatchesTypes.LIST_BATCHES_REQUEST, handleListBatches);
}

function* listBatchesSaga() {
    yield all([fork(watchListBatches)]);
}

export default listBatchesSaga;
