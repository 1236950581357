import ProvisionalHemeraBackings from 'features/limelight/provisional-hemera-backings';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';


interface MatchParams {
    url: string;
}

const ProvisionalLimelightRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/:batchId/backings`} component={ProvisionalHemeraBackings} />
    </Switch> 
)

export default ProvisionalLimelightRoutes;