import { ScdCompanyDetails } from 'model/company';
import { GiroWebappError } from 'model/error';
import { CompanyDetailsTypes } from './types';
import { action } from 'typesafe-actions';

export const companyDetailsRequest = (companyId: number) => action(CompanyDetailsTypes.COMPANY_DETAILS_REQUEST, companyId);
export const companyDetailsSuccess = (companyDetails: ScdCompanyDetails) =>
    action(CompanyDetailsTypes.COMPANY_DETAILS_SUCCESS, companyDetails);
export const companyDetailsError = (error: GiroWebappError) => action(CompanyDetailsTypes.COMPANY_DETAILS_ERROR, error);
export const companyDetailsResetState = () => action(CompanyDetailsTypes.COMPANY_DETAILS_RESET);
