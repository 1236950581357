import { ScdImportedOperationReexport } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ImportedOperationsReexportTypes {
    IMPORTED_OPERATIONS_REEXPORT_REQUEST = '@@imported-operations-reexport/IMPORTED_OPERATIONS_REEXPORT_REQUEST',
    IMPORTED_OPERATIONS_REEXPORT_SUCCESS = '@@imported-operations-reexport/IMPORTED_OPERATIONS_REEXPORT_SUCCESS',
    IMPORTED_OPERATIONS_REEXPORT_ERROR = '@@imported-operations-reexport/IMPORTED_OPERATIONS_REEXPORT_ERROR',
    IMPORTED_OPERATIONS_REEXPORT_RESET_STATE = '@@imported-operations-reexport/IMPORTED_OPERATIONS_REEXPORT_RESET_STATE',
}

export interface ImportedOperationsReexportState extends BaseStoreState {
    readonly reexportedOperation?: ScdImportedOperationReexport;
}
