import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdOrganizationList } from 'model/organization';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.organization.table';

interface ScdOrganizationHeaderProps {
    organizations: ScdOrganizationList[];
    handleSort: (property: keyof ScdOrganizationList, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort: boolean;
}

export const ScdOrganizationHeader = (props: ScdOrganizationHeaderProps) => {
    const { t } = useTranslation();
    const { organizations, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof ScdOrganizationList) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (organizations && organizations.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.organizationKey`).toUpperCase()} sortable={mapSortable('organizationKey')} />
        </tr>
    );
};

export default ScdOrganizationHeader;
