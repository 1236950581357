import { GiroWebappError } from 'model/error';
import { PaymentTransactionsImportByBatch } from 'model/payment-transaction-by-batch';
import { ListPaymentTransactionsByBatchTypes } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch/types';
import { action } from 'typesafe-actions';

export const listDisbursementTransactionsByBatchRequest = (batchId: string) =>
    action(ListPaymentTransactionsByBatchTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST, batchId);
export const listDisbursementTransactionsByBatchSuccess = (disbursementTransactions: PaymentTransactionsImportByBatch) =>
    action(ListPaymentTransactionsByBatchTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS, disbursementTransactions);
export const listDisbursementTransactionsByBatchError = (error: GiroWebappError) =>
    action(ListPaymentTransactionsByBatchTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR, error);
export const listDisbursementTransactionsByBatchResetState = () =>
    action(ListPaymentTransactionsByBatchTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_RESET);
