import { AxiosResponse } from 'axios';
import { ScdBatchSimple } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { sendBatchCcbDocumentsError, sendBatchCcbDocumentsRequest, sendBatchCcbDocumentsSuccess } from 'reducer/batch/send-ccb-documents/actions';
import { SendBatchCcbDocumentsTypes } from 'reducer/batch/send-ccb-documents/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSendBatchCcbDocuments(action: ReturnType<typeof sendBatchCcbDocumentsRequest>) {
    try {
        const result: AxiosResponse<ScdBatchSimple> = yield call(batchAPI.sendBatchCcbDocuments, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(sendBatchCcbDocumentsError(mapped));
            return;
        }
        yield put(sendBatchCcbDocumentsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(sendBatchCcbDocumentsError(mapped));
    }
}

function* watchSendBatchCcbDocuments() {
    yield takeLeading(SendBatchCcbDocumentsTypes.SEND_BATCH_CCB_DOCUMENTS_REQUEST, handleSendBatchCcbDocuments);
}

function* sendBatchCcbDocumentsSaga() {
    yield all([fork(watchSendBatchCcbDocuments)]);
}

export default sendBatchCcbDocumentsSaga;
