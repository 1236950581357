import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import IdentificationMask, { CNPJPersonTypeMask, CPFPersonTypeMask } from 'components/inputs/identification-input/identification-mask';
import { PersonType } from 'model/enums/person-type';
import { InputBaseComponentProps } from '@material-ui/core';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface ValidIdentificationInputProps extends Base {
    onChange?: (value: string) => void;
    personType?: PersonType;
}

export const ValidIdentificationInput = (props: ValidIdentificationInputProps) => {
    const { validate, showValidation, value, onChange, personType, ...otherProps } = props;

    const handleChange = (_value: string) => {
        const normalized = InputUtils.removeNonDigits(_value);

        if (onChange) {
            onChange(normalized);
        }
    };

    const maskToBeApplied: React.ElementType<InputBaseComponentProps> = useMemo(() => {
        if (!personType) {
            return IdentificationMask;
        }

        if (personType === PersonType.INDIVIDUAL) {
            return CPFPersonTypeMask;
        }

        return CNPJPersonTypeMask;
    }, [personType]);

    return (
        <ValidInput value={value} validate={validate} showValidation={showValidation} className={clsx('base-input', { dirty: value })}>
            {({ error }) => (
                <BaseMaskInput {...otherProps} error={error} value={value} onChange={handleChange} MaskInputComponent={maskToBeApplied} />
            )}
        </ValidInput>
    );
};

export default ValidIdentificationInput;
