import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { ProgramFilterRequest } from 'model/program';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseScdProgramFilterChipsProps {
    filterActive: ProgramFilterRequest;
    setFilterActive: (value: ProgramFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.program.filter';

type ScdProgramFilterRequestType = keyof Omit<ProgramFilterRequest, 'originatorId' | 'companyGroupId'>;

const useScdProgramFilterChips = (props: UseScdProgramFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: ProgramFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<ScdProgramFilterRequestType, () => void> = useMemo(() => {
        return {
            identifier: () => clearFilterSupport({ ...filterActive, identifier: undefined }),
            status: () => clearFilterSupport({ ...filterActive, status: undefined }),
            productType: () => clearFilterSupport({ ...filterActive, productType: undefined }),
            originatorName: () => clearFilterSupport({ ...filterActive, originatorId: undefined, originatorName: undefined }),
            companyGroupName: () => clearFilterSupport({ ...filterActive, companyGroupId: undefined, companyGroupName: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: ScdProgramFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const scdProgramFilterChips = useMemo(() => {
        const items: Record<ScdProgramFilterRequestType, ChipFilterItem> = {
            identifier: {
                isActive: !!filterActive.identifier,
                onDelete: handleClearFilter['identifier'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('identifier')} component={filterActive.identifier} />,
            },
            productType: {
                isActive: !!filterActive.productType,
                onDelete: handleClearFilter['productType'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('productType')}
                        component={t(`${I18N_PREFIX}.inputs.productType.options.${filterActive.productType}`)}
                    />
                ),
            },
            status: {
                isActive: !!filterActive.status,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={t(`${I18N_PREFIX}.inputs.status.options.${filterActive.status}`)}
                    />
                ),
            },
            companyGroupName: {
                isActive: !!filterActive.companyGroupName,
                onDelete: handleClearFilter['companyGroupName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('companyGroupId')} component={filterActive.companyGroupName} />,
            },
            originatorName: {
                isActive: !!filterActive.originatorName,
                onDelete: handleClearFilter['originatorName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('originatorId')} component={filterActive.originatorName} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isScdProgramChipsActive: boolean = useMemo(() => {
        return scdProgramFilterChips.some(it => !!it.isActive);
    }, [scdProgramFilterChips]);

    return {
        scdProgramFilterChips,
        isScdProgramChipsActive,
    };
};

export default useScdProgramFilterChips;
