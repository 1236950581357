import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    SearchFavoredBankAccountsTypes,
    SearchFavoredBankAccountsState,
} from 'reducer/favored-bank-account/search-favored-bank-accounts/types';

const initialState: SearchFavoredBankAccountsState = {
    status: HttpRequestStatus.NOOP,
    favoredBankAccounts: undefined,
    error: undefined,
};

const reducer: Reducer<SearchFavoredBankAccountsState> = (state = initialState, action): SearchFavoredBankAccountsState => {
    switch (action.type) {
        case SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, favoredBankAccounts: action.payload, error: undefined };
        }
        case SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchFavoredBankAccountsTypes.SEARCH_FAVORED_BANK_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchFavoredBankAccountsReducer };
