import LinkButton from 'components/buttons/link-button/link-button';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import { CompletePaymentTransactionsModalAction } from 'entities/payment-transaction/complete-payment-transaction/complete-payment-transaction';
import { ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { useTranslation } from 'react-i18next';

interface CompletePaymentTransactionsSlideProps {
    selectedTransactions: ScdPaymentTransactionPendingData[] | undefined;
    setOpenModal: (value: CompletePaymentTransactionsModalAction | undefined) => void;
}

const I18N_PREFIX = 'pages.scd.complete-payment-transactions';

const CompletePaymentTransactionsSlide = (props: CompletePaymentTransactionsSlideProps) => {
    const { selectedTransactions, setOpenModal } = props;

    const { t } = useTranslation();

    const disabledSlideButon: boolean = !selectedTransactions?.length;

    return (
        <div className="scd-complete-payment-transactions__content-slide">
            <div className="scd-complete-payment-transactions__content-slide--container">
                <div className="scd-complete-payment-transactions__content-slide--item">
                    <div className="scd-complete-payment-transactions__content-slide--item__title">
                        {`${selectedTransactions?.length ?? 0} ${t(`${I18N_PREFIX}.slide.transfer`, {
                            count: selectedTransactions?.length ?? 0,
                        }).toLocaleLowerCase()}`}
                    </div>
                    <div className="scd-complete-payment-transactions__content-slide--item__value">
                        <strong>
                            <CurrencyFormatter value={selectedTransactions?.reduce((acc, act) => acc + act.amount, 0) ?? 0} prefix />
                        </strong>
                    </div>
                </div>
                <div className="scd-payment-transaction-in-series__content-slide--buttons">
                    <LinkButton
                        onClick={() => setOpenModal(CompletePaymentTransactionsModalAction.ARCHIVE)}
                        outlinedStyle
                        label={t('entity.action.archive')}
                        disabled={disabledSlideButon}
                    />
                    <span style={{ marginLeft: '20px', width: '145px' }}>
                        <LinkButton
                            label={t('entity.action.send')}
                            onClick={() => setOpenModal(CompletePaymentTransactionsModalAction.PROCESS)}
                            disabled={disabledSlideButon}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CompletePaymentTransactionsSlide;
