import { ScdPaymentTransactionToCreate } from 'model/payment-transaction';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const NEW_TRANSACTION_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
    },
    minLength: {
        predicate: ValidationUtils.NUMBER.minSize(0.01),
    },
};
export const validateNewTransactionAmount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, NEW_TRANSACTION_AMOUNT_VALIDATION);

const NEW_TRANSACTION_FAVORED_ID_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
    },
};
export const validateNewTransactionFavoredId = (value: number | undefined | null) =>
    ValidationUtils.validate(value, NEW_TRANSACTION_FAVORED_ID_VALIDATION);

const NEW_TRANSACTION_ACCOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
    },
};
export const validateNewTransactionAccount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, NEW_TRANSACTION_ACCOUNT_VALIDATION);

export const validateScdNewPaymentTransaction = (value: ScdPaymentTransactionToCreate | undefined): boolean => {
    if (!value) return true;
    return (
        validateNewTransactionAmount(value.amount).isValid &&
        validateNewTransactionFavoredId(value.companyId).isValid &&
        validateNewTransactionAccount(value.bankAccountId).isValid
    );
};
