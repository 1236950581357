import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { UserDataSortableProperties } from 'entities/user/users';
import { UsersToGetAll } from 'model/user';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

interface UsersListHeaderProps {
    users: UsersToGetAll[];
    handleSort: (property: UserDataSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

const I18N_PREFIX = 'pages.scd.users';

const UsersListHeader = (props: UsersListHeaderProps) => {
    const { t } = useTranslation();
    const { users, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: UserDataSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (users && users.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.table.login`)} sortable={mapSortable('login')} />
            <SortableColumn label={t(`${I18N_PREFIX}.table.name`)} sortable={mapSortable('firstName')} />
            <SortableColumn label={t(`${I18N_PREFIX}.table.status.label`)} sortable={mapSortable('activated')} />
        </tr>
    );
};

export default UsersListHeader;
