import clsx from 'clsx';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { PaymentInstitutionAccountToResume } from 'model/payment-institution-account';
import { useTranslation } from 'react-i18next';

import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import './statement-header.scss';

interface StatementHeaderProps {
    resumedPaymentInstitutionAccount: PaymentInstitutionAccountToResume | undefined;
    handleTryAgain: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy';

const StatementHeader = (props: StatementHeaderProps) => {
    const { resumedPaymentInstitutionAccount, handleTryAgain } = props;

    const { t } = useTranslation();

    return (
        <header className="statement-header">
            <div className="statement-header-container">
                <div className="statement-header-container--align">
                    <h2 className="statement-header-container--title">
                        {t(`${I18N_PREFIX}.statement-header-title`, {
                            accountName: resumedPaymentInstitutionAccount?.name,
                        })}
                    </h2>
                    <RefreshButton onClick={handleTryAgain} />
                </div>
                <div className="statement-header-account-infos">
                    <span className="statement-header-account-infos--item">
                        {t(`${I18N_PREFIX}.type.${resumedPaymentInstitutionAccount?.type}`)}
                    </span>
                    <span className="statement-header-account-infos--item">{resumedPaymentInstitutionAccount?.technicalSupplierName}</span>
                    <span
                        className={clsx('statement-header-account-infos--status', {
                            ACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.ACTIVE,
                            INACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.INACTIVE,
                        })}
                    >
                        {t(`${I18N_PREFIX}.status.${resumedPaymentInstitutionAccount?.status}`).toUpperCase()}
                    </span>
                </div>
            </div>
        </header>
    );
};

export default StatementHeader;
