import { ScdBatchClose } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CloseBatchTypes {
    CLOSE_BATCH_REQUEST = '@@close-batch/CLOSE_BATCH_REQUEST',
    CLOSE_BATCH_SUCCESS = '@@close-batch/CLOSE_BATCH_SUCCESS',
    CLOSE_BATCH_ERROR = '@@close-batch/CLOSE_BATCH_ERROR',
    CLOSE_BATCH_RESET = '@@close-batch/CLOSE_BATCH_RESET',
}

export interface CloseBatchState extends BaseStoreState {
    readonly closedBatch?: ScdBatchClose;
}
