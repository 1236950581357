import { Grid } from '@material-ui/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GenericTransfeeraErrorResponse } from 'shared/external/payment-accounts-strategy/balance/components/transfeera/transfeera-balance.model';
import { ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';

interface ExternalErrorResponseProps {
    balance: ExternalBalanceResponse | undefined | null;
}
const I18N_PREFIX = 'payment-accounts-strategy.transfeera.balance-details';

const ExternalErrorResponse = (props: ExternalErrorResponseProps): JSX.Element => {
    const { balance } = props;

    const { t } = useTranslation();

    const parsedExternalErrorResponse = useMemo((): GenericTransfeeraErrorResponse | undefined => {
        return JsonUtils.parseJsonString(balance?.responseError);
    }, [balance?.responseError]);

    if (!balance || balance?.isSuccessful) {
        return <></>;
    }

    return (
        <>
            <Grid item xs={12}>
                <label>{t(`${I18N_PREFIX}.externalError`)}</label>
                <span>{t(`${I18N_PREFIX}.checkRawResponse`)}</span>
            </Grid>
            <Grid item xs={12}>
                <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                <span>{parsedExternalErrorResponse?.message ? parsedExternalErrorResponse?.message : '-'}</span>
            </Grid>
        </>
    );
};

export default ExternalErrorResponse;
