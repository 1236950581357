import ChunkCessionFinalizeModal from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-footer/chunk-cession-finalize-modal/chunk-cession-finalize-modal';
import { useFooterComponents } from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-footer/hooks/use-footer-components';
import SendStreamModal from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-footer/send-stream-modal/send-stream-modal';
import { BatchOutputBcoRequest } from 'model/bco/batch-output';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { chunkCessionFinalizeBcoResetState } from 'reducer-bco/batch-output/chunk-cession-finalize/action';
import { chunkStreamProcessBcoResetState } from 'reducer-bco/batch-output/chunk-stream-process/action';
import { chunkStreamToResumeBcoRequest } from 'reducer-bco/batch-output/chunk-stream-to-resume/action';
import { useChunkCessionFinalizeBco, useChunkStreamProcessBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './cession-integration-footer.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.footer';

interface CessionIntegrationFooterProps {}

const CessionIntegrationFooter: React.FC<CessionIntegrationFooterProps> = () => {
    const dispatch = useRootDispatch();
    const { t } = useTranslation();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();
    const { status: chunkStreamProcessStatus, chunkStreamProcessBco } = useChunkStreamProcessBco();
    const { status: chunkCessionFinalizeStatus, chunkCessionFinalizeBco } = useChunkCessionFinalizeBco();
    const { toastLoading, closeToast, toastSuccess, toastError } = useToast();
    const { sendModalOpen, finalizeModalOpen, setIsFinalizeModalOpen, setIsSendModalOpen, currentFooter } = useFooterComponents();

    const handleChunkStreamToResume = () => {
        const request: BatchOutputBcoRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(chunkStreamToResumeBcoRequest(request));
    };

    useEffect(() => {
        if (chunkStreamProcessStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (chunkStreamProcessStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(chunkStreamProcessBcoResetState());
            toastError(t(`${I18N_PREFIX}.send-chunks.toast.error`));
        }
        if (chunkStreamProcessStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(chunkStreamProcessBcoResetState());
            handleChunkStreamToResume();
            toastSuccess(t(`${I18N_PREFIX}.send-chunks.toast.success`));
        }
    }, [chunkStreamProcessStatus, toastLoading, closeToast, chunkStreamProcessBco, toastSuccess, t, dispatch]);

    useEffect(() => {
        if (chunkCessionFinalizeStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (chunkCessionFinalizeStatus === HttpRequestStatus.ERROR) {
            closeToast();
            dispatch(chunkCessionFinalizeBcoResetState());
            toastError(t(`${I18N_PREFIX}.finalize-cession.toast.error`));
        }
        if (chunkCessionFinalizeStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(chunkCessionFinalizeBcoResetState());
            handleChunkStreamToResume();
            toastSuccess(t(`${I18N_PREFIX}.finalize-cession.toast.success`));
        }
    }, [chunkCessionFinalizeStatus, toastLoading, closeToast, chunkCessionFinalizeBco, toastSuccess, t, dispatch]);

    return (
        <>
            {currentFooter}
            {sendModalOpen && <SendStreamModal setIsModalOpen={setIsSendModalOpen} />}
            {finalizeModalOpen && <ChunkCessionFinalizeModal setIsModalOpen={setIsFinalizeModalOpen} />}
        </>
    );
};

export default CessionIntegrationFooter;
