import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { IssuerPolicyIntegrationSimple } from 'model/issuer-policy';
import { markError } from 'reducer/application/error/actions';
import { updateIssuerPolicyIntegrationError, updateIssuerPolicyIntegrationRequest, updateIssuerPolicyIntegrationSuccess } from 'reducer/issuer-policy/update-integration/actions';
import { UpdateIntegrationIssuerPolicyTypes } from 'reducer/issuer-policy/update-integration/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import issuerPolicyApi from 'services/api/issuer-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateIssuerPolicyIntegration(action: ReturnType<typeof updateIssuerPolicyIntegrationRequest>) {
    try {
        const result: AxiosResponse<IssuerPolicyIntegrationSimple> = yield call(issuerPolicyApi.updateIntegration, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateIssuerPolicyIntegrationError(mapped));
            return;
        }
        yield put(updateIssuerPolicyIntegrationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateIssuerPolicyIntegrationError(mapped));
    }
}

function* watchUpdateIssuerPolicyIntegration() {
    yield takeLeading(UpdateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_UPDATE_REQUEST, handleUpdateIssuerPolicyIntegration);
}

function* updateIssuerPolicyIntegrationSaga() {
    yield all([fork(watchUpdateIssuerPolicyIntegration)]);
}

export default updateIssuerPolicyIntegrationSaga;
