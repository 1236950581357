import { ScdCompanyItem } from 'model/company-item';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.company-item.validate';

const COMPANY_ITEM_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateCompanyItemType = (value: string | undefined | null) => ValidationUtils.validate(value, COMPANY_ITEM_TYPE_VALIDATION);

const COMPANY_ITEM_STATUS_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
};
export const validateCompanyItemStatus = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ITEM_STATUS_VALIDATION);

const COMPANY_ITEM_COMPANY_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.company.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.company.required`,
    },
};
export const validateCompanyItemCompany = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_ITEM_COMPANY_VALIDATION);

export const validateScdCompanyItem = (value: ScdCompanyItem | undefined): boolean => {
    if (!value) return true;
    return (
        validateCompanyItemType(value.type).isValid &&
        validateCompanyItemStatus(value.status).isValid &&
        validateCompanyItemCompany(value.company?.name).isValid
    );
};
