import { PopperProps, Tooltip } from '@material-ui/core';
import FormatterUtils from 'components/formatter/formatter-utils';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import LimelightOptionsItems from 'features/limelight/components/limelight-options-items/limelight-options-items';
import { FileUploaded, OperationWithFileToList } from 'features/limelight/send-backings/domain/models';
import { useState } from 'react';

interface LimelightOperationListProps {
    operations: OperationWithFileToList[];
    removeBind: (operationId: number, file: FileUploaded) => void;
    overrideBind: (operationId: number, file: FileUploaded) => void;
}

const LimelightOperationList = (props: LimelightOperationListProps) => {
    const { operations, removeBind, overrideBind } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [operationId, setOperationId] = useState<number | undefined>(undefined);
    const [fileSelect, setFileSelect] = useState<FileUploaded | undefined>(undefined);

    const handleSelects = (operationId: number, file?: FileUploaded) => {
        setOperationId(operationId);
        setFileSelect(file);
    };

    const handleSelectOneFile = (fileSelect: File) => {
        if (!operationId) return;

        const file: FileUploaded = { fileName: fileSelect?.name, fileData: fileSelect };

        overrideBind(operationId, file);
        setAnchorEl(null);
    };

    const popperProps: Partial<PopperProps> = {
        style: {
            marginTop: -15,
        },
    };

    const verify = operations.some(item => item.file && item.operation.id === operationId);

    return (
        <>
            {operations.map(({ operation, file }) => (
                <tr className="page-container--table-cell" key={operation.id}>
                    <td className="page-container--table-cell-title">{operation.originator}</td>
                    <td className="page-container--table-cell-title">
                        {FormatterUtils.formatIdentification(undefined, operation.debtorIdentification)}
                    </td>
                    <td className="page-container--table-cell-title">{operation.ccbNumber}</td>
                    <td className="page-container--table-cell-title">
                        <Tooltip title={file?.fileName ?? '-'} PopperProps={popperProps}>
                            <div>{file?.fileName ?? '-'}</div>
                        </Tooltip>
                    </td>
                    <td>
                        <MoreOptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} onClick={() => handleSelects(operation.id, file)}>
                            <LimelightOptionsItems
                                handleSelectOneFile={handleSelectOneFile}
                                removeBind={removeBind}
                                setAnchorEl={setAnchorEl}
                                file={fileSelect}
                                operationId={operationId}
                                verify={verify}
                            />
                        </MoreOptionsMenu>
                    </td>
                </tr>
            ))}
        </>
    );
};

export default LimelightOperationList;
