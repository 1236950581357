import { ScdBatchExpirationsReport } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchExpirationsReportTypes } from 'reducer/batch/expirations-report/types';
import { action } from 'typesafe-actions';

export const batchExpirationsReportRequest = (batchId: number) =>
    action(BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_REQUEST, batchId);
export const batchExpirationsReportSuccess = (batchExpirationsReport: ScdBatchExpirationsReport) =>
    action(BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_SUCCESS, batchExpirationsReport);
export const batchExpirationsReportError = (error: GiroWebappError) =>
    action(BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_ERROR, error);
export const batchExpirationsReportResetState = () => action(BatchExpirationsReportTypes.BATCH_EXPIRATIONS_REPORT_RESET);
