import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { ScdCompanyFilterRequest } from 'model/company';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface ScdCompanyFilterChipsProps {
    filterActive: ScdCompanyFilterRequest;
    setFilterActive: (value: ScdCompanyFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.company.filter.buttons';

type ScdCompanyFilterRequestType = keyof ScdCompanyFilterRequest;

const useScdCompanyFilterChips = (props: ScdCompanyFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: ScdCompanyFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<ScdCompanyFilterRequestType, () => void> = useMemo(() => {
        return {
            name: () => clearFilterSupport({ ...filterActive, name: undefined }),
            identification: () => clearFilterSupport({ ...filterActive, identification: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: ScdCompanyFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );
    const scdCompanyFilterChips = useMemo(() => {
        const items: Record<ScdCompanyFilterRequestType, ChipFilterItem> = {
            name: {
                isActive: !!filterActive.name,
                onDelete: handleClearFilter['name'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('name')} component={filterActive.name} />,
            },
            identification: {
                isActive: !!filterActive.identification,
                onDelete: handleClearFilter['identification'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('identification')} component={filterActive.identification} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle]);

    const isScdCompanyChipsActive: boolean = useMemo(() => {
        return scdCompanyFilterChips.some(it => !!it.isActive);
    }, [scdCompanyFilterChips]);

    return {
        scdCompanyFilterChips,
        isScdCompanyChipsActive,
    };
};

export default useScdCompanyFilterChips;
