import { Tooltip } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import TaxValueInput from 'entities/monthly-commission/components/inputs/tax-value-input/tax-value-input';
import { ReactComponent as ErrorArrowSvg } from 'images/error-arrow.svg';
import _ from 'lodash';
import { MonthlyCommissionStatus } from 'model/enums/monthly-commission-status';
import { TaxValueType } from 'model/enums/tax-type';
import {
    defaultMonthlyCommissionTaxValue,
    ScdMonthlyCommissionDetailTaxValue,
    ScdMonthlyCommissionTaxValue,
} from 'model/monthly-commission';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRootDispatch } from 'reducer/hooks';
import { monthlyCommissionGenerateReportRequest } from 'reducer/monthly-commission/generate-report/actions';
import { monthlyCommissionRectifyTaxesRequest } from 'reducer/monthly-commission/rectify-taxes/actions';
import './monthly-commission-generate-and-rectify-tax-report.scss';

const I18N_PREFIX = 'pages.scd.monthly-commission';

enum MonthlyCommissionGenerateAndRectifyTaxReportModalActions {
    CLEAR_ALL = 'CLEAR_ALL',
    COMMENT = 'COMMENT',
    CANCEL = 'CANCEL',
    CONFIRM = 'CONFIRM',
    RECTIFY = 'RECTIFY',
}

interface MonthlyCommissionGenerateAndRectifyTaxReportProps {
    setSelectedCommissionId: (value: number | undefined) => void;
    monthlyCommissionId: number | undefined;
    monthlyCommissionStatus: MonthlyCommissionStatus | undefined;
    monthlyCommissionTaxesValues: ScdMonthlyCommissionDetailTaxValue[];
}

const defaultTaxOptions = Object.values(TaxValueType);

const filterTaxOptions = (taxValues: ScdMonthlyCommissionDetailTaxValue[]): TaxValueType[] => {
    const mappedTaxes = taxValues.map(value => value.type);
    return Object.values(TaxValueType).filter(value => !mappedTaxes.includes(value));
};

const MonthlyCommissionGenerateAndRectifyTaxReport = (props: MonthlyCommissionGenerateAndRectifyTaxReportProps) => {
    const { setSelectedCommissionId, monthlyCommissionId, monthlyCommissionStatus, monthlyCommissionTaxesValues } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const isNewMonthlyCommission: boolean = useMemo(() => {
        return monthlyCommissionStatus === MonthlyCommissionStatus.NEW;
    }, [monthlyCommissionStatus]);

    const [modalAction, setModalAction] = useState<MonthlyCommissionGenerateAndRectifyTaxReportModalActions | undefined>(undefined);
    const [taxesList, setTaxesList] = useState<ScdMonthlyCommissionTaxValue[]>(
        monthlyCommissionTaxesValues.length > 0 ? monthlyCommissionTaxesValues : [defaultMonthlyCommissionTaxValue]
    );
    const [taxOptions, setTaxOptions] = useState<TaxValueType[]>(
        isNewMonthlyCommission ? defaultTaxOptions : filterTaxOptions(monthlyCommissionTaxesValues)
    );
    const [comentary, setComentary] = useState<string | undefined>(undefined);

    const isDisabledAction: boolean = useMemo(() => {
        return taxesList.some(tax => tax.value === undefined);
    }, [taxesList]);

    const handleSelect = (index: number) => (selectedTax: string) => {
        const newValues = [...taxesList];

        newValues[index] = {
            type: TaxValueType[selectedTax],
        };

        setTaxOptions(ps => ps.filter(tax => tax !== TaxValueType[selectedTax]));
        setTaxesList(newValues);
    };

    const handleChangeValue = (index: number) => (value: number | undefined) => {
        const newValues = [...taxesList];

        newValues[index] = {
            ...newValues[index],
            value,
        };

        setTaxesList(newValues);
    };

    const handleAddNewTax = () => {
        setTaxesList(ps => [...ps, defaultMonthlyCommissionTaxValue]);
    };

    const handleResetInputs = () => {
        setTaxesList([defaultMonthlyCommissionTaxValue]);
        setTaxOptions(defaultTaxOptions);
        setComentary(undefined);
    };

    const handleRemoveTax = (selectedTax: string) => {
        const _filteredData = taxesList.filter(tax => tax.type !== selectedTax);

        setTaxesList(taxesList.length === 1 ? [defaultMonthlyCommissionTaxValue] : _filteredData);
        setTaxOptions(ps => [...ps, TaxValueType[selectedTax]]);
    };

    const modalTitle = {
        CLEAR_ALL: t(`${I18N_PREFIX}.modal.CLEAR_ALL.title`),
        CANCEL: isNewMonthlyCommission ? t(`${I18N_PREFIX}.modal.CANCEL.creation`) : t(`${I18N_PREFIX}.modal.CANCEL.correction`),
        CONFIRM: t(`${I18N_PREFIX}.modal.CONFIRM.title`),
        RECTIFY: t(`${I18N_PREFIX}.modal.RECTIFY.title`),
    } as Record<MonthlyCommissionGenerateAndRectifyTaxReportModalActions, string>;

    const modalMessage = {
        CONFIRM: t(`${I18N_PREFIX}.modal.CONFIRM.message`),
        RECTIFY: t(`${I18N_PREFIX}.modal.RECTIFY.message`),
    } as Record<MonthlyCommissionGenerateAndRectifyTaxReportModalActions, string>;

    const modalOnAction = {
        CLEAR_ALL: () => {
            handleResetInputs();
            setModalAction(undefined);
        },
        CANCEL: () => {
            handleResetInputs();
            setModalAction(undefined);
            setSelectedCommissionId(undefined);
        },
        RECTIFY: () => {
            if (!monthlyCommissionId) return;
            dispatch(
                monthlyCommissionRectifyTaxesRequest({
                    id: monthlyCommissionId,
                    taxValues: taxesList,
                })
            );
            setModalAction(undefined);
        },
        CONFIRM: () => {
            if (!monthlyCommissionId) return;
            dispatch(monthlyCommissionGenerateReportRequest({ id: monthlyCommissionId, taxValues: taxesList }));
            setModalAction(undefined);
        },
    } as Record<MonthlyCommissionGenerateAndRectifyTaxReportModalActions, () => void>;

    const modalStandardButtonLabel = {
        CLEAR_ALL: t('entity.action.clean'),
        CANCEL: t('entity.action.cancel'),
        CONFIRM: t('entity.action.generate'),
        RECTIFY: t('entity.action.correct'),
    } as Record<MonthlyCommissionGenerateAndRectifyTaxReportModalActions, string>;

    const modalOutlinedButtonLabel = {
        CANCEL: t('entity.action.back'),
    } as Record<MonthlyCommissionGenerateAndRectifyTaxReportModalActions, string>;

    return (
        <>
            <div className="monthly-commission-generate-and-rectify-tax-report">
                <span className="monthly-commission-generate-and-rectify-tax-report--title">
                    {isNewMonthlyCommission
                        ? t(`${I18N_PREFIX}.report.generate`).toUpperCase()
                        : t(`${I18N_PREFIX}.report.rectify-taxes`).toUpperCase()}
                </span>
                <div className="monthly-commission-generate-and-rectify-tax-report--inputs">
                    {taxesList.map((value, index) => {
                        return (
                            <TaxValueInput
                                key={index}
                                onSelect={handleSelect(index)}
                                taxOptions={taxOptions}
                                onChange={handleChangeValue(index)}
                                onRemove={handleRemoveTax}
                                tax={value}
                            />
                        );
                    })}
                    {!isDisabledAction && taxesList.length < 3 && (
                        <div className="monthly-commission-generate-and-rectify-tax-report__tax-add-new-input" onClick={handleAddNewTax}>
                            <AddCircleOutlineIcon />
                        </div>
                    )}
                </div>
                <div className="monthly-commission-generate-and-rectify-tax-report--icons">
                    <div
                        className="monthly-commission-generate-and-rectify-tax-report--icons-remove-arrow"
                        onClick={() => setModalAction(MonthlyCommissionGenerateAndRectifyTaxReportModalActions.CLEAR_ALL)}
                    >
                        {taxesList.some(tax => !!tax.value) && (
                            <Tooltip title={String(t(`${I18N_PREFIX}.report.tooltips.remove-tax`))}>
                                <ErrorArrowSvg />
                            </Tooltip>
                        )}
                    </div>

                    <div className="monthly-commission-generate-and-rectify-tax-report--icons__content">
                        <div
                            className="monthly-commission-generate-and-rectify-tax-report--icons__content-icon"
                            onClick={() =>
                                !isDisabledAction && setModalAction(MonthlyCommissionGenerateAndRectifyTaxReportModalActions.COMMENT)
                            }
                        >
                            <Tooltip title={String(t('tooltips.comentary'))}>
                                <div
                                    className={clsx('monthly-commission-generate-and-rectify-tax-report--icons-comentary', {
                                        active: !!comentary,
                                        disabled: isDisabledAction,
                                    })}
                                />
                            </Tooltip>
                        </div>
                        <div
                            className="monthly-commission-generate-and-rectify-tax-report--icons__content-icon"
                            onClick={() =>
                                !isDisabledAction &&
                                !_.isEqual(taxesList, monthlyCommissionTaxesValues) &&
                                setModalAction(
                                    isNewMonthlyCommission
                                        ? MonthlyCommissionGenerateAndRectifyTaxReportModalActions.CONFIRM
                                        : MonthlyCommissionGenerateAndRectifyTaxReportModalActions.RECTIFY
                                )
                            }
                        >
                            <Tooltip title={String(t('tooltips.confirm'))}>
                                <span
                                    className={clsx('monthly-commission-generate-and-rectify-tax-report--icons-check', {
                                        disabled: isDisabledAction || _.isEqual(taxesList, monthlyCommissionTaxesValues),
                                        active: !isDisabledAction && !_.isEqual(taxesList, monthlyCommissionTaxesValues),
                                    })}
                                >
                                    <CheckIcon fontSize="small" />
                                </span>
                            </Tooltip>
                        </div>
                        <div
                            className="monthly-commission-generate-and-rectify-tax-report--icons__content-icon"
                            onClick={() => setModalAction(MonthlyCommissionGenerateAndRectifyTaxReportModalActions.CANCEL)}
                        >
                            <Tooltip title={String(t('tooltips.cancel'))}>
                                <ClearIcon />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
            {modalAction && modalAction !== MonthlyCommissionGenerateAndRectifyTaxReportModalActions.COMMENT && (
                <ModalMessageLegacy
                    onClose={() => setModalAction(undefined)}
                    onCancel={() => setModalAction(undefined)}
                    message={modalMessage[modalAction]}
                    standardButtonLabel={modalStandardButtonLabel[modalAction]}
                    outlinedButtonLabel={modalOutlinedButtonLabel[modalAction]}
                    title={modalTitle[modalAction]}
                    onAction={modalOnAction[modalAction]}
                />
            )}
            {modalAction === MonthlyCommissionGenerateAndRectifyTaxReportModalActions.COMMENT && (
                <ModalTextArea
                    label={t(`${I18N_PREFIX}.modal.COMMENT.label`)}
                    title={t(`${I18N_PREFIX}.modal.COMMENT.title`)}
                    onChange={value => setComentary(value)}
                    defaultValue={comentary}
                    onClose={() => setModalAction(undefined)}
                    openModal
                />
            )}
        </>
    );
};

export default MonthlyCommissionGenerateAndRectifyTaxReport;
