import { ScdBatchSimple } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum SendBatchCcbDocumentsTypes {
    SEND_BATCH_CCB_DOCUMENTS_REQUEST = '@@batch-send-ccb-documents/SEND_BATCH_CCB_DOCUMENTS_REQUEST',
    SEND_BATCH_CCB_DOCUMENTS_SUCCESS = '@@batch-send-ccb-documents/SEND_BATCH_CCB_DOCUMENTS_SUCCESS',
    SEND_BATCH_CCB_DOCUMENTS_ERROR = '@@batch-send-ccb-documents/SEND_BATCH_CCB_DOCUMENTS_ERROR',
    SEND_BATCH_CCB_DOCUMENTS_RESET = '@@batch-send-ccb-documents/SEND_BATCH_CCB_DOCUMENTS_RESET',
}

export interface SendBatchCcbDocumentsState extends BaseStoreState {
    readonly batch?: ScdBatchSimple;
}
