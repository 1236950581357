import Loading from 'components/loading/loading';
import CompanyItemRow, { CompanyItemRowProps } from 'entities/company-group/company-item/components/company-item-row/company-item-row';
import { ScdCompanyItemSearch } from 'model/company-item';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface CompanyItemListProps {
    companyItems: ScdCompanyItemSearch[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.company-item.table';

const CompanyItemList = (props: CompanyItemListProps) => {
    const { companyItems, isLoading } = props;

    const { t } = useTranslation();

    const companyItemRowObjectData: CompanyItemRowProps = {
        companyItems,
    };

    return (
        <>
            {isLoading && !companyItems.length && <Loading isTable />}
            {companyItems.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={companyItemRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={companyItems.length}
                            className="page-container--table-container"
                        >
                            {CompanyItemRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-company-group__table-empty-filter">
                            <span className="scd-company-item__table-empty-filter-image" />
                            <span className="scd-company-item__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CompanyItemList;
