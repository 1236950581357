import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateIssuerPolicy,
    validateIssuerPolicyName,
    validateIssuerPolicyPlatform,
    validateIssuerPolicyStatus,
    validateIssuerPolicyType,
} from 'entities/issuer/issuer-policies/validation/issuer-policies-validation';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { IssuerPolicyStatus } from 'model/enums/issuer-policy-status';
import { IssuerPolicyType } from 'model/enums/issuer-policy-type';
import {
    IssuerPolicyCreateRequest,
    IssuerPolicyCreateUpdate,
    IssuerPolicyDetailRequest,
    IssuerPolicyUpdateRequest,
    defaultIssuerPolicy,
} from 'model/issuer-policy';
import { SearchPlatform } from 'model/platform';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useCreateIssuerPolicyState, useDetailIssuerPolicyState, useRootDispatch, useUpdateIssuerPolicyState } from 'reducer/hooks';
import { createIssuerPolicyRequest, createIssuerPolicyResetState } from 'reducer/issuer-policy/create-policy/actions';
import { detailIssuerPolicyRequest, detailIssuerPolicyResetState } from 'reducer/issuer-policy/detail-policy/actions';
import { updateIssuerPolicyRequest, updateIssuerPolicyResetState } from 'reducer/issuer-policy/update-policy/actions';
import { searchPlatformsRequest, searchPlatformsResetState } from 'reducer/platform/search/actions';
import { useToast } from 'shared/hooks/use-toast';
import './policies-create-update.scss';

interface IssuerPolicyCreateUpdateProps extends RouteComponentProps<{ issuerPolicyId: string; issuerId: string }> {}

const I18N_PREFIX = 'pages.scd.issuer-policy';

const IssuerPolicyCreateUpdatePage = (props: IssuerPolicyCreateUpdateProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const [issuerPolicyId] = useState<number | undefined>(Number(props.match?.params?.issuerPolicyId) ?? undefined);
    const [issuerId] = useState<number | undefined>(Number(props.match?.params?.issuerId) ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [issuerPolicy, setIssuerPolicy] = useState<IssuerPolicyCreateUpdate>(defaultIssuerPolicy);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: statusCreatePolicy, issuerCreatePolicy } = useCreateIssuerPolicyState();
    const { status: statsUpdatePolicy, issuerUpdatePolicy } = useUpdateIssuerPolicyState();
    const { status: statusDetailPolicy, issuerPolicyDetail } = useDetailIssuerPolicyState();

    const { toastLoading, closeToast, toastSuccess } = useToast();

    const handleSave = () => {
        setShowValidation(true);
        const _issuerPolicy: IssuerPolicyCreateUpdate = {
            ...issuerPolicy,
        };

        if (!issuerId) return;
        if (!validateIssuerPolicy(_issuerPolicy)) return;

        const request: IssuerPolicyCreateRequest = {
            issuerId,
            policy: _issuerPolicy,
        };

        if (!issuerPolicyId) {
            dispatch(createIssuerPolicyRequest(request));
        } else {
            const requestUpdate: IssuerPolicyUpdateRequest = {
                issuerId,
                id: issuerPolicyId,
                policy: _issuerPolicy,
            };
            dispatch(updateIssuerPolicyRequest(requestUpdate));
        }
    };
    const handleChange = (value: Partial<IssuerPolicyCreateUpdate>) => {
        setIssuerPolicy(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (statusDetailPolicy !== HttpRequestStatus.SUCCESS && !issuerPolicyDetail) return;
        setIssuerPolicy({
            ...issuerPolicyDetail,
            platformId: issuerPolicyDetail?.platform?.id,
            platformName: issuerPolicyDetail?.platform?.name,
        });
    }, [statusDetailPolicy, issuerPolicyDetail]);

    useEffect(() => {
        if (!issuerId || !issuerPolicyId) return;
        const request: IssuerPolicyDetailRequest = {
            issuerId,
            id: issuerPolicyId,
        };
        dispatch(detailIssuerPolicyRequest(request));
    }, [dispatch, issuerId, issuerPolicyId]);

    useEffect(() => {
        if (statusCreatePolicy !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [statusCreatePolicy, toastLoading]);

    useEffect(() => {
        if (statusCreatePolicy !== HttpRequestStatus.SUCCESS && !issuerCreatePolicy) return;
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.create.success`));
        history.goBack();
    }, [statusCreatePolicy, issuerCreatePolicy, toastSuccess, closeToast, history, t]);

    useEffect(() => {
        if (statusCreatePolicy !== HttpRequestStatus.ERROR) return;
        closeToast();
    }, [statusCreatePolicy, closeToast]);

    useEffect(() => {
        if (statsUpdatePolicy !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [statsUpdatePolicy, toastLoading]);

    useEffect(() => {
        if (statsUpdatePolicy !== HttpRequestStatus.SUCCESS && !issuerUpdatePolicy) return;
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.update.success`));
        history.goBack();
    }, [statsUpdatePolicy, issuerUpdatePolicy, toastSuccess, closeToast, history, t]);

    useEffect(() => {
        if (statsUpdatePolicy !== HttpRequestStatus.ERROR) return;
        closeToast();
    }, [statsUpdatePolicy, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(createIssuerPolicyResetState());
            dispatch(updateIssuerPolicyResetState());
            dispatch(detailIssuerPolicyResetState());
        };
    }, [dispatch]);

    const loading = statusDetailPolicy === HttpRequestStatus.ON_GOING;

    return (
        <main className="issuer-policy__create">
            <ContextRibbon />
            <div className="issuer-policy--container">
                <header className="issuer-policy--header">
                    <h2 className="issuer-policy--header-title">
                        {!issuerPolicyId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && issuerId ? (
                    <Loading />
                ) : (
                    <form className="issuer-policy--form">
                        <div className="issuer-policy--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={issuerPolicy?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateIssuerPolicyName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        <div className="issuer-policy--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={issuerPolicy?.status ?? ''}
                                validate={_value => validateIssuerPolicyStatus(IssuerPolicyStatus[_value])}
                                mapperFromString={_value => (_value ? IssuerPolicyStatus[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.status.placeholder`)}
                                onChange={(status: string) => handleChange({ status: IssuerPolicyStatus[status] })}
                                disabled={!issuerId}
                                externalUpdate
                            >
                                <MenuItem value={IssuerPolicyStatus.ACTIVE}>{t(`${I18N_PREFIX}.input.status.options.ACTIVE`)}</MenuItem>
                                <MenuItem value={IssuerPolicyStatus.INACTIVE}>{t(`${I18N_PREFIX}.input.status.options.INACTIVE`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="issuer-policy--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                value={issuerPolicy?.type ?? ''}
                                validate={_value => validateIssuerPolicyType(IssuerPolicyType[_value])}
                                mapperFromString={_value => (_value ? IssuerPolicyType[_value] : undefined)}
                                showValidation={showValidation}
                                placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                                onChange={(type: string) => handleChange({ type: IssuerPolicyType[type] })}
                                externalUpdate
                                disabled={!!issuerPolicyId}
                            >
                                <MenuItem value={IssuerPolicyType.INTEGRATION}>
                                    {t(`${I18N_PREFIX}.input.type.options.INTEGRATION`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="issuer-policy--form-field">
                            <ModalSearch<SearchPlatform>
                                action={searchPlatformsRequest}
                                itemSize={SEARCH_ITEM_SIZE}
                                modalTitle={t(`${I18N_PREFIX}.input.platform.modal-title`)}
                                modalLabel={t(`${I18N_PREFIX}.input.platform.modal-label`)}
                                modalPlaceholder={t(`${I18N_PREFIX}.input.platform.modal-placeholder`)}
                                onSelect={platform => handleChange({ platformId: platform.id, platformName: platform.name })}
                                renderItem={platform => platform?.name}
                                statusSelector={(state: IRootState) => state.searchPlatform.status}
                                dataSelector={(state: IRootState) => state.searchPlatform.platforms}
                                requestParameters={{ platformId: issuerPolicy.platformId }}
                                resetState={searchPlatformsResetState}
                            >
                                {handleOpen => (
                                    <ValidSearchInput
                                        label={`${I18N_PREFIX}.input.platform.label`}
                                        showValidation={showValidation}
                                        placeholder={`${I18N_PREFIX}.input.platform.placeholder`}
                                        value={issuerPolicy.platformName ?? ''}
                                        onClick={handleOpen}
                                        onFocus={handleOpen}
                                        readOnly
                                        externalUpdate
                                        validate={() => validateIssuerPolicyPlatform(issuerPolicy.platformId, issuerPolicyId)}
                                        disabled={!!issuerPolicyId}
                                    />
                                )}
                            </ModalSearch>
                        </div>
                        <div className="issuer-policy--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={issuerPolicyId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.goBack();
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default IssuerPolicyCreateUpdatePage;
