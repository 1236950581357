import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.fallback';

const BatchOutputDetailFallback = () => {
    const { t } = useTranslation();
    return (
        <div className="batch-outputs-detail__component--not-found">
            <span className="batch-outputs-detail__component--not-found-image" />
            <span className="batch-outputs-detail__component--not-found-title">{t(`${I18N_PREFIX}.provisional`)}</span>
        </div>
    );
};

export default BatchOutputDetailFallback;
