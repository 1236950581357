import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import OrganizationMoreOptionsMenuItems from 'entities/organization/components/organization-more-options-menu-items/organization-more-options-menu-items';
import { OrganizationMoreOptionsType } from 'entities/organization/organization';
import { ScdOrganizationList } from 'model/organization';
import { SetStateAction, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { getPersonType } from 'shared/util/person-type-utils';

export interface OrganizationRowProps {
    organizations: ScdOrganizationList[];
    setModalOptionType: React.Dispatch<SetStateAction<OrganizationMoreOptionsType | undefined>>;
    setSelectedOrganization: React.Dispatch<SetStateAction<ScdOrganizationList | undefined>>;
}

const OrganizationRow = (props: ListChildComponentProps<OrganizationRowProps>) => {
    const { index, data, style } = props;
    const { organizations, setModalOptionType, setSelectedOrganization } = data;
    const { id, name, identification, organizationKey } = organizations[index];
    const { url } = useRouteMatch();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-title">
                {<IdentificationFormatter value={identification} type={getPersonType(identification)} />}
            </td>
            <td className="page-container--table-cell-title">{organizationKey}</td>

            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/edit/${id}`}>
                    <EditIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <MoreOptionsMenu
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    onClick={() => setSelectedOrganization(organizations[index])}
                >
                    <OrganizationMoreOptionsMenuItems handleCloseMenu={handleCloseMenu} setModalOptionType={setModalOptionType} />
                </MoreOptionsMenu>
            </td>
        </tr>
    );
};

export default OrganizationRow;
