import { IconButton, Menu, PaperProps, PopoverOrigin } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ReactNode } from 'react';

import './options-menu.scss';

interface OptionsMenuProps {
    onClick?: () => void;
    disabled?: boolean;
    children: ReactNode;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    menuPaperStyle?: PaperProps;
    anchorEl: HTMLElement | null;
    setAnchorEl: (value: HTMLElement | null) => void;
}

const MoreOptionsMenu = (props: OptionsMenuProps) => {
    const {
        disabled,
        onClick,
        children,
        anchorOrigin: propsAnchorOrigin,
        menuPaperStyle: propsMenuPaperStyle,
        transformOrigin: propsTransformOrigin,
        anchorEl,
        setAnchorEl,
    } = props;

    const transformOrigin: PopoverOrigin = {
        vertical: -40,
        horizontal: 'left',
    };

    const anchorOrigin: PopoverOrigin = {
        vertical: 'top',
        horizontal: 'center',
    };

    const menuPaperStyle: PaperProps = {
        style: {
            width: 'auto',
        },
    };

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (disabled) return;

        setAnchorEl(event.currentTarget);
        onClick?.();
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const openMenu: boolean = !!anchorEl;

    return (
        <>
            <IconButton onClick={e => handleClickMenu(e)} disabled={disabled}>
                <MoreVertIcon style={{ color: disabled ? 'E9E9E9' : '#BAC0C2' }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={propsMenuPaperStyle ?? menuPaperStyle}
                anchorOrigin={propsAnchorOrigin ?? anchorOrigin}
                transformOrigin={propsTransformOrigin ?? transformOrigin}
                className="options-menu"
            >
                {children}
            </Menu>
        </>
    );
};

export default MoreOptionsMenu;
