import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import AccountOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/account-operation-card/account-operation-card';
import AddressOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/address-operation-card/address-operation-card';
import ContactOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/contact-operation-card/contact-operation-card';
import DocumentOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/document-operation-card/document-operation-card';
import HeaderOperationInfoCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/header-operation-info-card/header-operation-info-card';
import LoanOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/loan-operation-card/loan-operation-card';
import OperationApprovalCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/operation-approval-card/operation-approval-card';
import PersonalInfoOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/personal-info-operation-card/personal-info-operation-card';
import SupplementaryDataOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/supplementary-data-operation-card/supplementary-data-operation-card';
import TaxOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/tax-operation-card.tsx/tax-operation-card';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { OperationStatus } from 'model/enums/operation-status';
import { defaultScdImportedOperationDetail } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useImportedOperationDetailState, useRootDispatch } from 'reducer/hooks';
import { importedOperationDetailRequest } from 'reducer/imported-operation/detail/actions';
import { importedOperationsListResetState } from 'reducer/imported-operation/list-operations/actions';
import './ccb-operation-detail.scss';
import HeaderOperationInfoTop from 'entities/ccb-operation/ccb-operation-detail/components/cards/header-operation-info-top/header-operation-info-top';

type ScdCcbOperationDetailProps = RouteComponentProps<{ operationId: string }>;

const I18N_PREFIX = 'pages.scd.ccb-operation-detail';

const ScdCcbOperationDetail = (props: ScdCcbOperationDetailProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const [operationId] = useState<number>(Number(props.match.params.operationId));

    const { operation, status: operationDetailStatus } = useImportedOperationDetailState();

    const handleOperationDetailRequest = useCallback(() => {
        dispatch(importedOperationDetailRequest(operationId));
    }, [dispatch, operationId]);

    useEffect(() => {
        handleOperationDetailRequest();
    }, [handleOperationDetailRequest]);

    useEffect(() => {
        return () => {
            dispatch(importedOperationsListResetState());
        };
    }, [dispatch]);

    const hasError = operationDetailStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.ccb-operation-detail.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleOperationDetailRequest} />
            </div>
        );
    }

    const isLoading = operationDetailStatus !== HttpRequestStatus.SUCCESS;

    const operationDetail = operation ?? defaultScdImportedOperationDetail;

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <main className="scd-ccb-operation-detail">
                    <ContextRibbon />
                    <section className="scd-ccb-operation-detail__container">
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <div className="scd-ccb-operation-detail__header">
                                    <h2 className="scd-ccb-operation-detail__header--title">{t(`${I18N_PREFIX}.title`)} </h2>
                                    <div>
                                        <span
                                            className={clsx('scd-ccb-operation-detail__content--status', {
                                                ERROR: operation?.status === OperationStatus.ERROR,
                                                EXPORTED: operation?.status === OperationStatus.EXPORTED,
                                                NEW: operation?.status === OperationStatus.NEW,
                                                PROCESSED: operation?.status === OperationStatus.PROCESSED,
                                                BATCHLESS: operation?.status === OperationStatus.BATCHLESS,
                                                ARCHIVED: operation?.status === OperationStatus.ARCHIVED,
                                                DISAPPROVED: operation?.status === OperationStatus.DISAPPROVED,
                                                PENDING_APPROVAL: operation?.status === OperationStatus.PENDING_APPROVAL,
                                            })}
                                        >
                                            {t(`pages.scd.ccb-operation.status.options.${operation?.status}`).toUpperCase()}
                                        </span>
                                        <div className="scd-ccb-operation-detail__content--info">
                                            <span> {operation?.ccbNumber} </span>
                                            <span> {t(`pages.scd.ccb-operation.product.options.${operation?.product}`)} </span>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={9} spacing={2}>
                                <div className="scd-ccb-operation-detail__header--cards">
                                    <HeaderOperationInfoTop operation={operationDetail} />
                                    <HeaderOperationInfoCard operation={operationDetail} />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="scd-ccb-operation-detail__content">
                                    <LoanOperationCard operation={operationDetail} />
                                    <TaxOperationCard operation={operationDetail} />
                                </div>
                            </Grid>
                            <Grid item xs={9}>
                                <PersonalInfoOperationCard operation={operationDetail} />
                                <OperationApprovalCard operation={operationDetail} />
                                <ContactOperationCard operation={operationDetail} />
                                <AccountOperationCard operation={operationDetail} />
                                <AddressOperationCard operation={operationDetail} />
                                <DocumentOperationCard operation={operationDetail} />
                                <SupplementaryDataOperationCard operation={operationDetail} />
                            </Grid>
                        </Grid>
                    </section>
                </main>
            )}
        </>
    );
};

export default ScdCcbOperationDetail;
