import { HemeraResumeIntegration } from 'features/limelight/send-backings/domain/models';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ProvisionalLimelightTypes {
    LIMELIGHT_LIST_OPERATIONS_REQUEST = '@@limelight-list-operations/LIMELIGHT_LIST_OPERATIONS_REQUEST',
    LIMELIGHT_LIST_OPERATIONS_SUCCESS = '@@limelight-list-operations/LIMELIGHT_LIST_OPERATIONS_SUCCESS',
    LIMELIGHT_LIST_OPERATIONS_ERROR = '@@limelight-list-operations/LIMELIGHT_LIST_OPERATIONS_ERROR',
    LIMELIGHT_LIST_OPERATIONS_RESET = '@@limelight-list-operations/LIMELIGHT_LIST_OPERATIONS_RESET'
}

export interface ProvisionalLimelightListState extends BaseStoreState {
    readonly limelightListOperations?: HemeraResumeIntegration
}