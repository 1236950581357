import { Grid } from '@material-ui/core';
import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import BatchCard from 'entities/batch/components/batch-card/batch-card';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { batchOperationsRequest, batchOperationsResetState } from 'reducer/batch/batch-operations-summarize/actions';
import { useBatchOperationsSummarize, useRootDispatch } from 'reducer/hooks';

interface OperationCardProps {
    batchId: number;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const BatchOperationCard = (props: OperationCardProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { batchId } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { batchOperationsSummarize, status } = useBatchOperationsSummarize();

    const handleBatchOperationsRequest = useCallback(() => {
        dispatch(batchOperationsRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        handleBatchOperationsRequest();
    }, [handleBatchOperationsRequest]);

    const handleUpdate = () => {
        setAnchorEl(null);
        handleBatchOperationsRequest();
    };

    useEffect(() => {
        return () => {
            dispatch(batchOperationsResetState());
        };
    }, [dispatch]);

    const isLoadingOperations: boolean = status !== HttpRequestStatus.SUCCESS;
    const hasError: boolean = status === HttpRequestStatus.ERROR;

    const onClick = () => {};

    return (
        <Grid item>
            <BatchCard
                title={t(`${I18N_PREFIX}.titles.operation`)}
                isLoading={isLoadingOperations}
                hasError={hasError}
                link={`/batches/${batchId}/operations`}
                handleRefresh={handleUpdate}
                options={
                    <MoreOptionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} onClick={onClick}>
                        <ModalActionMenuItem titleKey={t(`${I18N_PREFIX}.buttons.update`)} onClick={handleUpdate} />
                    </MoreOptionsMenu>
                }
            >
                <span>
                    {t(`${I18N_PREFIX}.content-info.operations-amount`)}
                    <span className="batch-list-card--detail-card">{batchOperationsSummarize?.countOperations}</span>
                </span>
            </BatchCard>
        </Grid>
    );
};

export default BatchOperationCard;
