import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateIssuerTypes, UpdateIssuerState } from 'reducer/issuer/update-issuer/types';
import { Reducer } from 'redux';

export const initialState: UpdateIssuerState = {
    status: HttpRequestStatus.NOOP,
    issuer: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateIssuerState> = (state = initialState, action): UpdateIssuerState => {
    switch (action.type) {
        case UpdateIssuerTypes.UPDATE_ISSUER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateIssuerTypes.UPDATE_ISSUER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuer: action.payload, error: undefined };
        }
        case UpdateIssuerTypes.UPDATE_ISSUER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateIssuerTypes.UPDATE_ISSUER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateIssuerReducer };
