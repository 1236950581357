import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentTransactionSummarize } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import {
    summarizePaymentTransactionsError, summarizePaymentTransactionsSuccess
} from 'reducer/payment-transaction/summarize-payment-transactions/actions';
import { SummarizePaymentTransactionsTypes } from 'reducer/payment-transaction/summarize-payment-transactions/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleSummarizePaymentTransactions() {
    try {
        const result: AxiosResponse<ScdPaymentTransactionSummarize> = yield call(
            paymentTransactionAPI.summarizePaymentTransactions

        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(summarizePaymentTransactionsError(mapped));
            return;
        }
        yield put(summarizePaymentTransactionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(summarizePaymentTransactionsError(mapped));
    }
}

function* watchSummarizePaymentTransactions() {
    yield takeEvery(SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_REQUEST, handleSummarizePaymentTransactions);
}

function* summarizePaymentTransactionsSaga() {
    yield all([fork(watchSummarizePaymentTransactions)]);
}

export default summarizePaymentTransactionsSaga;
