import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentInstitutionAccountToGetTransaction } from 'model/payment-institution-account';
import { useTranslation } from 'react-i18next';

interface PaymentInstitutionAccountCardProps {
    paymentInstitutionAccount: PaymentInstitutionAccountToGetTransaction | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentInstitutionAccountCard = (props: PaymentInstitutionAccountCardProps) => {
    const { paymentInstitutionAccount } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`)}>
            <ReadOnlyTextField label={`${I18N_PREFIX}.inputs.paymentInstitutionAccount.label`} value={paymentInstitutionAccount?.name} />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentInstitutionAccount.externalReference`}
                value={paymentInstitutionAccount?.externalReference}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentInstitutionAccount.status.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.status.options.${paymentInstitutionAccount?.status}`)}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentInstitutionAccount.type.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentInstitutionAccount.type.options.${paymentInstitutionAccount?.type}`)}
            />
        </ReadOnlyCard>
    );
};

export default PaymentInstitutionAccountCard;
