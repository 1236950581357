import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramPolicySimple } from 'model/program-policy';

export type ApiResponse = Record<string, string>;

export enum CreateProgramPolicyTypes {
    CREATE_PROGRAM_POLICY_REQUEST = '@@program-policy-create/CREATE_PROGRAM_POLICY_REQUEST',
    CREATE_PROGRAM_POLICY_SUCCESS = '@@program-policy-create/CREATE_PROGRAM_POLICY_SUCCESS',
    CREATE_PROGRAM_POLICY_ERROR = '@@program-policy-create/CREATE_PROGRAM_POLICY_ERROR',
    CREATE_PROGRAM_POLICY_RESET_STATE = '@@program-policy-create/CREATE_PROGRAM_POLICY_RESET_STATE',
}

export interface CreateProgramPolicyState extends BaseStoreState {
    readonly programPolicy?: ScdProgramPolicySimple;
}
