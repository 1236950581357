import { AxiosResponse } from 'axios'
import { LimelightListRequest, HemeraResumeIntegration, HemeraAuthorizationForSendBackingRequest, HemeraAuthorizationForSendBackingResponse, HemeraProvisionalProxyResponse, HemeraProvisionalProxyRequest } from 'features/limelight/send-backings/domain/models'
import api from 'services/api/api'
import { BASE_URL_ADMIN } from 'services/page'

const provisionalLimelightApi = () => {

    const listOperations = (request: LimelightListRequest): Promise<AxiosResponse<HemeraResumeIntegration>> => {
        return api.get<HemeraResumeIntegration>(`${BASE_URL_ADMIN}/provisional/hemera/${request.batchId}/outputs/${request.outputId}`)
    }

    const getAuthorizationForSendBackings = (request: HemeraAuthorizationForSendBackingRequest): Promise<AxiosResponse<HemeraAuthorizationForSendBackingResponse>> => {
        return api.get<HemeraAuthorizationForSendBackingResponse>(`${BASE_URL_ADMIN}/provisional/hemera/${request.batchId}/outputs/${request.outputId}/integrations`)
    }

    const sendProxyHemeraBackings = (request: HemeraProvisionalProxyRequest): Promise<AxiosResponse<HemeraProvisionalProxyResponse>> => {
        return api.post<HemeraProvisionalProxyResponse>(`${BASE_URL_ADMIN}/provisional/hemera/${request.batchId}/outputs/${request.outputId}/proxy`, request.request, {timeout:0})
    }

    return {
        listOperations,
        getAuthorizationForSendBackings,
        sendProxyHemeraBackings
    }
}

export default provisionalLimelightApi();