import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';
import InputUtils from 'components/inputs/input-utils';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import clsx from 'clsx';

type BaseInput = Omit<BaseTextInputProps, 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;
type BaseValidation = Omit<ValidInputProps<string>, 'children'>;
type Base = BaseInput & BaseValidation;

interface ValidNormalizedInputProps extends Base {
    onChange?: (value: string) => void;
}

const ValidNormalizedInput = (props: ValidNormalizedInputProps) => {
    const { validate, showValidation, value, onChange, type, ...otherProps } = props;

    const handleChange = (_value: string) => {
        const normalized = InputUtils.normalizeBlankSpaces(_value);

        if (onChange) {
            onChange(normalized);
        }
    };

    return (
        <ValidInput value={value} validate={validate} showValidation={showValidation} className={clsx('base-input', { dirty: value })}>
            {({ error }) => <BaseTextInput {...otherProps} value={value} onChange={handleChange} error={error} type={type ?? 'text'} />}
        </ValidInput>
    );
};

export default ValidNormalizedInput;
