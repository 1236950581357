import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Request Log List
import { RequestLogListBrsState } from 'reducer-brs/request-log/request-log-list/types';
import listRequestLogsBrsSaga from 'reducer-brs/request-log/request-log-list/sagas';
import { listRequestLogBrsReducer } from 'reducer-brs/request-log/request-log-list/reducer';

export interface RequestLogBrsState {
    readonly listRequestLogsBrs: RequestLogListBrsState;
}

export const RequestLogBrsReducer: ReducersMapObject<RequestLogBrsState, AnyAction> = {
    listRequestLogsBrs: listRequestLogBrsReducer,
};

export function* requestLogBrsSagas() {
    yield all([fork(listRequestLogsBrsSaga)]);
}
