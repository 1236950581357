import { PaymentTransactionsImportByBatch } from 'model/payment-transaction-by-batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ListPaymentTransactionsByBatchTypes {
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST = '@@payment-transactions-by-batch-list/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS = '@@payment-transactions-by-batch-list/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR = '@@payment-transactions-by-batch-list/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR',
    LIST_PAYMENT_TRANSACTIONS_BY_BATCH_RESET = '@@payment-transactions-by-batch-list/LIST_PAYMENT_TRANSACTIONS_BY_BATCH_RESET',
}

export interface ListPaymentTransactionsByBatchState extends BaseStoreState {
    readonly paymentTransactionsByBatch?: PaymentTransactionsImportByBatch;
}
