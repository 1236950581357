import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    UpdateCompanyGroupPolicyTypes,
    UpdateCompanyGroupPolicyState,
} from 'reducer/company-group-policy/update-company-group-policy/types';

const initialState: UpdateCompanyGroupPolicyState = {
    status: HttpRequestStatus.NOOP,
    companyGroupPolicy: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateCompanyGroupPolicyState> = (state = initialState, action): UpdateCompanyGroupPolicyState => {
    switch (action.type) {
        case UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyGroupPolicy: action.payload, error: undefined };
        }
        case UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateCompanyGroupPolicyReducer };
