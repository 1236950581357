import { useTranslation } from 'react-i18next';
import {
    useBatchDisbursementReportState,
    useBatchExpirationsReportState,
    useBatchOperationsReportState,
    useRootDispatch,
} from 'reducer/hooks';
import { useEffect, useRef } from 'react';
import { useToast } from 'shared/hooks/use-toast';
import { CSVLink } from 'react-csv';
import { batchOperationsReportRequest, batchOperationsReportResetState } from 'reducer/batch/operations-report/actions';
import { batchExpirationsReportRequest, batchExpirationsReportResetState } from 'reducer/batch/expirations-report/actions';
import { batchDisbursementReportRequest, batchDisbursementReportResetState } from 'reducer/batch/disbursement-report/actions';
import BatchOperationsReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-operations-report/batch-operations-report';
import BatchExpirationsReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-expirations-report/batch-expirations-report';
import BatchDisbursementReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-disbursement-report/batch-disbursment-report';
import './list-reports.scss';
import ListReportsItem from 'entities/batch/batch-reports/components/list-reports-item/list-reports-item';

type BatchReportType = 'disbursement' | 'expiration' | 'operation';

interface ListReportsProps {
    batchId?: number;
}

const I18N_PREFIX = 'pages.scd.batch';

const ListReports = (props: ListReportsProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { toastInfo } = useToast();

    const { batchId } = props;

    const operationCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const expirationCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const disbursementCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const { batchOperationsReport } = useBatchOperationsReportState();
    const { batchExpirationsReport } = useBatchExpirationsReportState();
    const { batchDisbursementReport } = useBatchDisbursementReportState();

    useEffect(() => {
        if (!batchId) return;
        dispatch(batchOperationsReportRequest(batchId));
        dispatch(batchExpirationsReportRequest(batchId));
        dispatch(batchDisbursementReportRequest(batchId));
    }, [dispatch, batchId]);

    const handleGenerateReport = {
        operation: () => {
            if (batchOperationsReport?.importedOperations.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.detail.report.operation.empty`));
                return;
            }
            operationCsvLinkRef.current?.link.click();
        },
        expiration: () => {
            if (batchExpirationsReport?.importedExpirations.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.detail.report.expiration.empty`));
                return;
            }
            expirationCsvLinkRef.current?.link.click();
        },
        disbursement: () => {
            if (batchDisbursementReport?.disbursements?.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.detail.report.disbursement.empty`));
                return;
            }
            disbursementCsvLinkRef.current?.link.click();
        },
    } as Record<BatchReportType, () => void>;

    useEffect(() => {
        return () => {
            dispatch(batchOperationsReportResetState());
            dispatch(batchExpirationsReportResetState());
            dispatch(batchDisbursementReportResetState());
        };
    }, [dispatch]);

    return (
        <div className="batch-reports-list">
            <div className="batch-reports-list__container">
                <ListReportsItem title={t(`${I18N_PREFIX}.reports.options.operation`)} action={handleGenerateReport['operation']} />
                <ListReportsItem title={t(`${I18N_PREFIX}.reports.options.expiration`)} action={handleGenerateReport['expiration']} />
                <ListReportsItem title={t(`${I18N_PREFIX}.reports.options.disbursement`)} action={handleGenerateReport['disbursement']} />
                {batchOperationsReport && (
                    <BatchOperationsReport csvLinkRef={operationCsvLinkRef} batchOperationsReport={batchOperationsReport} />
                )}
                {batchExpirationsReport && (
                    <BatchExpirationsReport csvLinkRef={expirationCsvLinkRef} batchExpirationsReport={batchExpirationsReport} />
                )}
                {batchDisbursementReport && (
                    <BatchDisbursementReport csvLinkRef={disbursementCsvLinkRef} batchDisbursementReport={batchDisbursementReport} />
                )}
            </div>
        </div>
    );
};

export default ListReports;
