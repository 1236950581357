import { GiroWebappError } from 'model/error';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { ListTechnicalSuppliersTypes } from './types';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { action } from 'typesafe-actions';

export const listTechnicalSuppliersRequest = (request?: Pageable) =>
    action(ListTechnicalSuppliersTypes.LIST_TECHNICAL_SUPPLIERS_REQUEST, request);
export const listTechnicalSuppliersSuccess = (technicalSuppliers: Page<ScdTechnicalSupplier>) =>
    action(ListTechnicalSuppliersTypes.LIST_TECHNICAL_SUPPLIERS_SUCCESS, technicalSuppliers);
export const listTechnicalSuppliersError = (error: GiroWebappError) =>
    action(ListTechnicalSuppliersTypes.LIST_TECHNICAL_SUPPLIERS_ERROR, error);
export const listTechnicalSuppliersResetState = () => action(ListTechnicalSuppliersTypes.LIST_TECHNICAL_SUPPLIERS_RESET_STATE);
