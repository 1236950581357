import { CloseButton } from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import { ScdPaymentTransactionInSeriesCreate } from 'entities/payment-transaction/payment-transaction-in-series/payment-transaction-in-series';
import { ScdPaymentTransactionsInSeriesError } from 'model/payment-transaction-in-series';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import './payment-transaction-in-series-modal-error.scss';

const I18N_PREFIX = 'pages.scd.payment-transaction-in-series.modal-message.error';

export interface PaymentTransactionInSeriesModalErrorProps {
    title: string;
    onClose: () => void;
    onAction?: () => void;
    errors: ScdPaymentTransactionsInSeriesError[];
    paymentTransactions: ScdPaymentTransactionInSeriesCreate[];
}

export const PaymentTransactionInSeriesModalError = (props: PaymentTransactionInSeriesModalErrorProps) => {
    const { title, onClose, onAction, errors, paymentTransactions } = props;
    const { t } = useTranslation();

    const handleSetFavored = (uniqueIdentifier: string) => {
        const _filteredData: ScdPaymentTransactionInSeriesCreate[] = paymentTransactions.filter(
            paymentTransaction => paymentTransaction.uniqueIdentifier === uniqueIdentifier
        );

        return _filteredData[0];
    };

    return (
        <Modal isOpen>
            <div className="payment-transaction-in-series-modal-error">
                <article className="payment-transaction-in-series-modal-error--content">
                    <header className="payment-transaction-in-series-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="payment-transaction-in-series-modal-error--message">
                        <p className="payment-transaction-in-series-modal-error--title">{title}</p>
                        <div className="payment-transaction-in-series-modal-error--errors">
                            {errors.map(({ uniqueIdentifier, errorMessage }) => (
                                <div className="payment-transaction-in-series-modal-error--errors__text" key={uniqueIdentifier}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.favored`)} </strong>
                                        {handleSetFavored(uniqueIdentifier).favoredIdentification}
                                        {` - ${handleSetFavored(uniqueIdentifier).favoredName}`}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.amount`)} </strong>
                                        <CurrencyFormatter value={handleSetFavored(uniqueIdentifier).amount} />
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong> {t(errorMessage)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {onAction && (
                        <div className="payment-transaction-in-series-modal-error--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.back'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default PaymentTransactionInSeriesModalError;
