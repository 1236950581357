import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { CompanyBankAccount } from 'model/company-bank-account';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';

interface AccountsDetailProps {
    account: CompanyBankAccount;
}

const I18N_PREFIX = 'pages.scd.company.card.accounts';

const AccountsDetail = (props: AccountsDetailProps) => {
    const { account } = props;

    const { t } = useTranslation();
    return (
        <>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.favoredName.label`)} value={account.favoredName} />
            <ReadOnlyTextField
                label={t(`${I18N_PREFIX}.input.favoredIdentification.label`)}
                value={
                    <IdentificationFormatter type={getPersonType(account.favoredIdentification)} value={account.favoredIdentification} />
                }
            />
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.bankId.label`)} value={account.bankName} />
            <div className="scd-company-create-update--form-card-double-fields">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.agencyNumber.label`)} value={account.agencyNumber} />
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.agencyDigit.label`)} value={account.agencyDigit} />
            </div>
            <div className="scd-company-create-update--form-card-double-fields">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.accountNumber.label`)} value={account.accountNumber} />
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.accountDigit.label`)} value={account.accountDigit} />
            </div>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.input.pixKey.label`)} value={account.pixKey} />
            <ReadOnlyTextField
                label={t(`${I18N_PREFIX}.input.type.label`)}
                value={t(`${I18N_PREFIX}.input.type.options.${account.type}`)}
            />
        </>
    );
};

export default AccountsDetail;
