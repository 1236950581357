import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdBankerList } from 'model/banker';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface BankersRowProps {
    bankers: ScdBankerList[];
}

const I18N_PREFIX = 'pages.scd.banker';

const BankersRow = (props: ListChildComponentProps<BankersRowProps>) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { bankers } = data;

    const { id, userName, userEmail, organizationName, hasApprovalAuthority } = bankers[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{userName ?? '-'}</div>
            </td>

            <td className="page-container--table-cell-title">{userEmail ?? '-'}</td>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{organizationName}</div>
            </td>
            <td className="page-container--table-cell-title">
                <div className="hide-overflow">{t(`${I18N_PREFIX}.detail.${hasApprovalAuthority}`)}</div>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/edit/${id}`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default BankersRow;
