import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramInfo } from 'model/program-policy';

export type ApiResponse = Record<string, string>;

export enum GetProgramInfoTypes {
    GET_PROGRAM_INFO_REQUEST = '@@program-info/GET_PROGRAM_INFO_REQUEST',
    GET_PROGRAM_INFO_SUCCESS = '@@program-info/GET_PROGRAM_INFO_SUCCESS',
    GET_PROGRAM_INFO_ERROR = '@@program-info/GET_PROGRAM_INFO_ERROR',
    GET_PROGRAM_INFO_RESET_STATE = '@@program-info/GET_PROGRAM_INFO_RESET_STATE',
}

export interface GetProgramInfoState extends BaseStoreState {
    readonly programInfo?: ScdProgramInfo;
}
