import { GiroWebappError } from 'model/error';
import { PaymentTransactionsByBatchErrorsList, PaymentTransactionsCreateByBatch } from 'model/payment-transaction-by-batch';
import { CreatePaymentTransactionsByBatchTypes } from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch/types';
import { action } from 'typesafe-actions';

export const createPaymentTransactionsByBatchRequest = (request: PaymentTransactionsCreateByBatch) =>
    action(CreatePaymentTransactionsByBatchTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_REQUEST, request);
export const createPaymentTransactionsByBatchSuccess = (paymentTransactionByBatchErrors: PaymentTransactionsByBatchErrorsList) =>
    action(CreatePaymentTransactionsByBatchTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_SUCCESS, paymentTransactionByBatchErrors);
export const createPaymentTransactionsByBatchError = (error: GiroWebappError) =>
    action(CreatePaymentTransactionsByBatchTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_ERROR, error);
export const createPaymentTransactionsByBatchResetState = () =>
    action(CreatePaymentTransactionsByBatchTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_RESET);
