import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Bankers
import { listBankersReducer } from 'reducer/banker/list-bankers/reducer';
import listBankersSaga from 'reducer/banker/list-bankers/sagas';
import { ListBankersState } from 'reducer/banker/list-bankers/types';

// Detail Banker
import { DetailBankerState } from 'reducer/banker/detail-banker/types';
import { detailBankerReducer } from 'reducer/banker/detail-banker/reducer';
import detailBankerSaga from 'reducer/banker/detail-banker/sagas';

// Create Banker
import { CreateBankerState } from './create-banker/types';
import { createBankerReducer } from 'reducer/banker/create-banker/reducer';
import createBankerSaga from 'reducer/banker/create-banker/sagas';

// Update Banker
import { UpdateBankerState } from 'reducer/banker/update-banker/types';
import { updateBankerReducer } from 'reducer/banker/update-banker/reducer';
import updateBankerSaga from 'reducer/banker/update-banker/sagas';
export interface BankerState {
    readonly listBankers: ListBankersState;
    readonly detailBanker: DetailBankerState;
    readonly createBanker: CreateBankerState;
    readonly updateBanker: UpdateBankerState;
}

export const BankerReducer: ReducersMapObject<BankerState, AnyAction> = {
    listBankers: listBankersReducer,
    detailBanker: detailBankerReducer,
    createBanker: createBankerReducer,
    updateBanker: updateBankerReducer,
};

export function* bankerSagas() {
    yield all([fork(listBankersSaga), fork(detailBankerSaga), fork(createBankerSaga), fork(updateBankerSaga)]);
}
