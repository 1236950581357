import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyItem } from 'model/company-item';

export type ApiResponse = Record<string, any>;

export enum DetailCompanyItemTypes {
    DETAIL_COMPANY_ITEM_REQUEST = '@@company-item-detail/DETAIL_COMPANY_ITEM_REQUEST',
    DETAIL_COMPANY_ITEM_SUCCESS = '@@company-item-detail/DETAIL_COMPANY_ITEM_SUCCESS',
    DETAIL_COMPANY_ITEM_ERROR = '@@company-item-detail/DETAIL_COMPANY_ITEM_ERROR',
    DETAIL_COMPANY_ITEM_RESET = '@@company-item-detail/DETAIL_COMPANY_ITEM_RESET',
}

export interface DetailCompanyItemState extends BaseStoreState {
    readonly companyItem?: ScdCompanyItem;
}
