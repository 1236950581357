import { MenuItem } from '@material-ui/core';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import {
    validateIssuerPolicyIntegration,
    validateIssuerPolicyIntegrationScheme,
} from 'entities/issuer/issuer-policies/issuer-policy-integration/valitation/issuer-policy-integration-validation';
import { AuthorizationScheme } from 'model/enums/authorization-scheme';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { IssuerPolicyIntegrationCreate, IssuerPolicyIntegrationRequest, defaultIntegrationPolicy } from 'model/issuer-policy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
    useCreateIssuerPolicyIntegration,
    useDetailIssuerPolicyState,
    useRootDispatch,
    useUpdateIssuerPolicyIntegration,
} from 'reducer/hooks';
import {
    createIssuerPolicyIntegrationRequest,
    createIssuerPolicyIntegrationResetState,
} from 'reducer/issuer-policy/create-integration/actions';
import { detailIssuerPolicyRequest, detailIssuerPolicyResetState } from 'reducer/issuer-policy/detail-policy/actions';
import {
    updateIssuerPolicyIntegrationRequest,
    updateIssuerPolicyIntegrationResetState,
} from 'reducer/issuer-policy/update-integration/actions';
import { useToast } from 'shared/hooks/use-toast';

interface IssuerPolicyIntegrationCreateUpdateProps extends RouteComponentProps<{ issuerPolicyId: string; issuerId: string }> {}

const I18N_PREFIX = 'pages.scd.issuer-policy.integration';

const IssuerPolicyIntegrationCreateUpdate = (props: IssuerPolicyIntegrationCreateUpdateProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    const [issuerId] = useState<number | undefined>(Number(props.match?.params?.issuerId) ?? undefined);
    const [issuerPolicyId] = useState<number | undefined>(Number(props.match?.params?.issuerPolicyId) ?? undefined);
    const [integrationPolicy, setIntegrationPolicy] = useState<IssuerPolicyIntegrationCreate>(defaultIntegrationPolicy);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: statusDetailPolicy, issuerPolicyDetail } = useDetailIssuerPolicyState();
    const { status: statusIntegrationCreate, policyIntegrationCreate } = useCreateIssuerPolicyIntegration();
    const { status: statusIntegrationUpdate, policyIntegrationUpdate } = useUpdateIssuerPolicyIntegration();

    const { toastLoading, closeToast, toastSuccess } = useToast();

    const handleSave = () => {
        setShowValidation(true);
        const _issuerPolicyIntegration: IssuerPolicyIntegrationCreate = {
            ...integrationPolicy,
        };
        if (!issuerPolicyId) return;
        if (!validateIssuerPolicyIntegration(_issuerPolicyIntegration)) return;

        if (!policyIntegrationUpdate?.id) {
            const request: IssuerPolicyIntegrationRequest = {
                policyId: issuerPolicyId,
                authorizationToken: integrationPolicy.authorizationToken,
                authorizationScheme: integrationPolicy.authorizationScheme,
            };
            dispatch(createIssuerPolicyIntegrationRequest(request));
        } else {
            const request: IssuerPolicyIntegrationRequest = {
                id: policyIntegrationUpdate.id,
                policyId: issuerPolicyId,
                authorizationToken: integrationPolicy.authorizationToken,
                authorizationScheme: integrationPolicy.authorizationScheme,
            };
            dispatch(updateIssuerPolicyIntegrationRequest(request));
        }
    };

    const handleChange = (value: Partial<IssuerPolicyIntegrationCreate>) => {
        setIntegrationPolicy(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (statusDetailPolicy !== HttpRequestStatus.SUCCESS && !issuerPolicyDetail) return;
        setIntegrationPolicy({
            authorizationScheme: issuerPolicyDetail?.issuerIntegration?.authorizationScheme,
            authorizationToken: issuerPolicyDetail?.issuerIntegration?.authorizationToken,
        });
    }, [statusDetailPolicy, issuerPolicyDetail]);

    useEffect(() => {
        if (!issuerId || !issuerPolicyId) return;
        const request = {
            id: issuerPolicyId,
            issuerId,
        };
        dispatch(detailIssuerPolicyRequest(request));
    }, [dispatch, issuerId, issuerPolicyId]);

    useEffect(() => {
        if (statusIntegrationCreate !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [statusIntegrationCreate, toastLoading]);

    useEffect(() => {
        if (statusIntegrationCreate !== HttpRequestStatus.SUCCESS && !policyIntegrationCreate) return;
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.config.success`));
        history.goBack();
    }, [statusIntegrationCreate, policyIntegrationCreate, closeToast, toastSuccess, t, history]);

    useEffect(() => {
        if (statusIntegrationUpdate !== HttpRequestStatus.ON_GOING) return;
        toastLoading();
    }, [statusIntegrationUpdate, toastLoading]);

    useEffect(() => {
        if (statusIntegrationUpdate !== HttpRequestStatus.SUCCESS && !policyIntegrationUpdate) return;
        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.update.success`));
        history.goBack();
    }, [statusIntegrationUpdate, policyIntegrationUpdate, closeToast, toastSuccess, t, history]);

    useEffect(() => {
        return () => {
            dispatch(detailIssuerPolicyResetState());
            dispatch(createIssuerPolicyIntegrationResetState());
            dispatch(updateIssuerPolicyIntegrationResetState());
        };
    }, [dispatch]);

    const loading = statusDetailPolicy === HttpRequestStatus.ON_GOING;

    return (
        <main className="issuer-policy__create">
            <ContextRibbon />
            <div className="issuer-policy--container">
                {loading && issuerPolicyId ? (
                    <Loading />
                ) : (
                    <>
                        <header className="issuer-policy--header">
                            <h2 className="issuer-policy--header-title">
                                {issuerPolicyDetail?.issuerIntegration?.id
                                    ? t(`${I18N_PREFIX}.title.edit`)
                                    : t(`${I18N_PREFIX}.title.create`)}
                            </h2>
                        </header>
                        <form className="issuer-policy--form">
                            <div className="issuer-policy--form-field">
                                <SimpleTextInput
                                    type="text"
                                    label={t(`${I18N_PREFIX}.input.authorizationToken.label`)}
                                    value={integrationPolicy?.authorizationToken ?? ''}
                                    placeholder={t(`${I18N_PREFIX}.input.authorizationToken.placeholder`)}
                                    onChange={authorizationToken => handleChange({ authorizationToken })}
                                    externalUpdate
                                />
                            </div>
                            <div className="issuer-policy--form-field">
                                <ValidSelectInput
                                    label={t(`${I18N_PREFIX}.input.authorizationScheme.label`)}
                                    value={integrationPolicy.authorizationScheme ?? ''}
                                    validate={_value => validateIssuerPolicyIntegrationScheme(AuthorizationScheme[_value])}
                                    mapperFromString={_value => (_value ? AuthorizationScheme[_value] : undefined)}
                                    showValidation={showValidation}
                                    placeholder={t(`${I18N_PREFIX}.input.authorizationScheme.placeholder`)}
                                    onChange={(authorizationScheme: string) =>
                                        handleChange({ authorizationScheme: AuthorizationScheme[authorizationScheme] })
                                    }
                                    disabled={!issuerId}
                                    externalUpdate
                                >
                                    <MenuItem value={AuthorizationScheme.API_KEY}>
                                        {t(`${I18N_PREFIX}.input.authorizationScheme.options.API_KEY`)}
                                    </MenuItem>
                                </ValidSelectInput>
                            </div>
                            <div className="issuer-policy--form-buttons">
                                <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                                <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                            </div>
                        </form>
                    </>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={issuerPolicyDetail?.issuerIntegration?.id ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.goBack();
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default IssuerPolicyIntegrationCreateUpdate;
