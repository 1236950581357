import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import BatchDetailMoreOptionsMenuItems from 'entities/batch/batch-detail-operations/components/batch-detail-more-options-menu-items/batch-detail-more-options-menu-items';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { BatchStatus } from 'model/enums/batch-status';
import { OperationStatus } from 'model/enums/operation-status';
import { ScdProgramPaymentFavoredPolicy } from 'model/enums/program-payment-favored-policy';
import { ScdProgramPaymentModePolicy } from 'model/enums/program-payment-mode-policy-type';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';
interface BatchOperationsListProps {
    operations: ScdImportedOperationBatch[];
    batchStatus: BatchStatus;
    setModalAction: (value: BatchModalActionOptions) => void;
    setOperation: (value: ScdImportedOperationBatch | undefined) => void;
    paymentModePolicy: ScdProgramPaymentModePolicy;
    paymentFavoredPolicy: ScdProgramPaymentFavoredPolicy;
    setIsOpenRelocateModal: (value: boolean) => void;
    operation: ScdImportedOperationBatch | undefined;
}

const I18N_PREFIX = 'pages.scd.batch';

const BatchOperationsList = (props: BatchOperationsListProps) => {
    const {
        operations,
        batchStatus,
        setOperation,
        setModalAction,
        paymentModePolicy,
        paymentFavoredPolicy,
        setIsOpenRelocateModal,
        operation,
    } = props;

    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [operationStatus, setOperationStatus] = useState<OperationStatus | undefined>(undefined);

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleDeleteOperationModal = () => {
        handleCloseMenu();

        if (paymentModePolicy === ScdProgramPaymentModePolicy.AUTOMATIC) {
            return setModalAction(BatchModalActionOptions.AUTOMATIC_PAYMENT);
        }

        return setModalAction(BatchModalActionOptions.DELETE_OPERATION);
    };

    const isAutomaticPaymentDirectFavoredOperation: boolean =
        paymentModePolicy === ScdProgramPaymentModePolicy.AUTOMATIC && paymentFavoredPolicy === ScdProgramPaymentFavoredPolicy.DIRECT;

    return (
        <>
            {operations?.length !== 0 &&
                operations?.map(currentOperation => (
                    <tr className="page-container--table-cell" key={currentOperation.id}>
                        <td className="page-container--table-cell-title">
                            {currentOperation.date ? <DateFormatter date={currentOperation.date} /> : '00/00/00'}
                        </td>
                        <td className="page-container--table-cell-title">
                            {currentOperation.value ? (
                                <>
                                    <CurrencyFormatter value={currentOperation.value} prefix />
                                </>
                            ) : (
                                <>{t(`${I18N_PREFIX}.no-value`)}</>
                            )}
                        </td>
                        <td className="page-container--table-cell-title">
                            <IdentificationFormatter
                                value={currentOperation.loanerIdentification}
                                type={currentOperation.assignorType ?? getPersonType(currentOperation.loanerIdentification)}
                            />
                        </td>
                        <td className="page-container--table-cell-title">
                            <IdentificationFormatter
                                value={currentOperation.debtorIdentification}
                                type={currentOperation.debtorType ?? getPersonType(currentOperation.debtorIdentification)}
                            />
                        </td>
                        <td className="page-container--table-cell-title"> {currentOperation.ccbNumber} </td>
                        <td className="page-container--table-cell-icon">
                            <MoreOptionsMenu
                                anchorEl={anchorEl}
                                setAnchorEl={setAnchorEl}
                                onClick={() => {
                                    setOperationStatus(currentOperation.status);
                                    setOperation(currentOperation);
                                }}
                            >
                                <BatchDetailMoreOptionsMenuItems
                                    batchStatus={batchStatus}
                                    handleCloseMenu={handleCloseMenu}
                                    handleDeleteOperationModal={handleDeleteOperationModal}
                                    isAutomaticPaymentDirectFavoredOperation={isAutomaticPaymentDirectFavoredOperation}
                                    operation={operation}
                                    operationStatus={operationStatus}
                                    setIsOpenRelocateModal={setIsOpenRelocateModal}
                                    setModalAction={setModalAction}
                                />
                            </MoreOptionsMenu>
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default BatchOperationsList;
