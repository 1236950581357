import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdCompanyItemSearch } from 'model/company-item';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

export interface CompanyItemRowProps {
    companyItems: ScdCompanyItemSearch[];
}

const CompanyItemRow = (props: ListChildComponentProps<CompanyItemRowProps>) => {
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { companyItems } = data;

    const { id, name, identification } = companyItems[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                <span>{name}</span>
            </td>
            <td className="page-container--table-cell-title">
                <span>{identification}</span>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default CompanyItemRow;
