import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdOrganization } from 'model/organization';
import organizationAPI from 'services/api/organization-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchOrganizationsTypes } from 'reducer/organization/search-organizations/types';
import {
    searchOrganizationsError,
    searchOrganizationsSuccess,
    searchOrganizationsRequest,
} from 'reducer/organization/search-organizations/actions';
import { Page } from 'services/page';
import { SearchRequest } from 'model/reducers';

function* handleSearchOrganizations(action: ReturnType<typeof searchOrganizationsRequest>) {
    try {
        const _payload: SearchRequest = { ...action.payload, search: `ci(contains(${action.payload.search}))` };
        const result: AxiosResponse<Page<ScdOrganization>> = yield call(organizationAPI.searchOrganizations, _payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchOrganizationsError(mapped));
            return;
        }
        yield put(searchOrganizationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchOrganizationsError(mapped));
    }
}

function* watchSearchOrganizations() {
    yield takeEvery(SearchOrganizationsTypes.SEARCH_ORGANIZATIONS_REQUEST, handleSearchOrganizations);
}

function* searchOrganizationsSaga() {
    yield all([fork(watchSearchOrganizations)]);
}

export default searchOrganizationsSaga;
