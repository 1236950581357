import { AxiosResponse } from 'axios';
import { ScdCompanyGroupSimple } from 'model/company-group';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    createCompanyGroupError,
    createCompanyGroupRequest,
    createCompanyGroupSuccess,
} from 'reducer/company-group/create-company-group/actions';
import { CreateCompanyGroupTypes } from 'reducer/company-group/create-company-group/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyGroupAPI from 'services/api/company-group-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateCompanyGroup(action: ReturnType<typeof createCompanyGroupRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyGroupSimple> = yield call(companyGroupAPI.createCompanyGroup, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createCompanyGroupError(mapped));
            return;
        }
        yield put(createCompanyGroupSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createCompanyGroupError(mapped));
    }
}

function* watchCreateCompanyGroup() {
    yield takeEvery(CreateCompanyGroupTypes.CREATE_COMPANY_GROUP_REQUEST, handleCreateCompanyGroup);
}

function* createCompanyGroupSaga() {
    yield all([fork(watchCreateCompanyGroup)]);
}

export default createCompanyGroupSaga;
