import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdOrganization } from 'model/organization';
import { markError } from 'reducer/application/error/actions';
import { detailOrganizationError, detailOrganizationRequest, detailOrganizationSuccess } from './actions';
import { DetailOrganizationTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import organizationApi from 'services/api/organization-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailOrganization(action: ReturnType<typeof detailOrganizationRequest>) {
    try {
        const result: AxiosResponse<ScdOrganization> = yield call(organizationApi.detailOrganization, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailOrganizationError(mapped));
            return;
        }
        yield put(detailOrganizationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailOrganizationError(mapped));
    }
}

function* watchDetailOrganization() {
    yield takeEvery(DetailOrganizationTypes.DETAIL_ORGANIZATION_REQUEST, handleDetailOrganization);
}

function* detailOrganizationSaga() {
    yield all([fork(watchDetailOrganization)]);
}

export default detailOrganizationSaga;
