import { ScdCompany } from './../../../model/company';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import companyAPI from 'services/api/company-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListCompaniesTypes } from './types';
import { listCompaniesError, listCompaniesSuccess, listCompaniesRequest } from './actions';
import { Page } from 'services/page';

function* handleListCompanies(action: ReturnType<typeof listCompaniesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompany>> = yield call(companyAPI.listCompanies, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listCompaniesError(mapped));
            return;
        }
        yield put(listCompaniesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listCompaniesError(mapped));
    }
}

function* watchListCompanies() {
    yield takeEvery(ListCompaniesTypes.LIST_COMPANIES_REQUEST, handleListCompanies);
}

function* listCompaniesSaga() {
    yield all([fork(watchListCompanies)]);
}

export default listCompaniesSaga;
