import { ScdCompanyGroup } from 'model/company-group';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramStatus } from 'model/enums/program-status';
import { ScdIssuer } from 'model/issuer';
import { ScdOrganization } from 'model/organization';
import { ScdOriginatorSimple } from 'model/originator';
import { ScdOriginatorSearch, ScdOriginatorCompany } from 'model/originator';
import { ProgramPolicyToBatchDetail } from 'model/program-policy';
import { Pageable } from 'services/pageable';

export interface LegacyScdProgram {
    id?: string;
    name?: string;
    goodwill?: number;
    identifier?: string;
    productType?: ProductType;
    status?: ScdProgramStatus;
    organization?: ScdOrganization;
    originator?: ScdOriginatorSearch;
    issuer?: ScdIssuer;
    companyGroups: ReadonlyArray<ScdCompanyGroup>;
}

export const defaultLegacyScdProgram: LegacyScdProgram = {
    id: undefined,
    name: undefined,
    goodwill: undefined,
    identifier: undefined,
    status: undefined,
    productType: undefined,
    organization: undefined,
    originator: undefined,
    companyGroups: [],
};

export interface ScdProgramToCreateUpdate {
    id?: string;
    name?: string;
    goodwill?: number;
    identifier?: string;
    productType?: ProductType;
    status?: ScdProgramStatus;
    organizationId?: string;
    organizationName?: string;
    originatorId?: string;
    originatorName?: string;
    issuerId?: number;
    issuerName?: string;
    issuerIdentification?: string;
}

export const defaultScdProgram: ScdProgramToCreateUpdate = {
    name: undefined,
    goodwill: undefined,
    identifier: undefined,
    productType: undefined,
    status: undefined,
    organizationId: undefined,
    organizationName: undefined,
    originatorId: undefined,
    originatorName: undefined,
    issuerId: undefined,
    issuerName: undefined,
    issuerIdentification: undefined,
};

export interface ScdProgramGet {
    id?: string;
    name?: string;
    goodwill?: number;
    identifier?: string;
    status?: ScdProgramStatus;
    productType?: ProductType;
    organization?: ScdOrganization;
    originator?: ScdOriginatorCompany;
    issuer?: ScdIssuer;
    companyGroups: ReadonlyArray<ScdCompanyGroup>;
}

export interface ScdProgramSimple {
    id: number;
    name?: string;
}

export interface ScdProgramResponse {
    id: number;
}

export interface ScdProgramList {
    id: string;
    name: string;
    identifier: string;
    goodwill: number;
    status?: ScdProgramStatus;
    productType: ProductType;
    policyId?: number;
    organization: ScdOrganization;
    originator: ScdOriginatorSimple;
}

export interface ProgramFilterRequest {
    status?: ScdProgramStatus;
    productType?: ProductType;
    identifier?: string;
    originatorId?: number;
    originatorName?: string;
    companyGroupId?: number;
    companyGroupName?: string;
}

export const defaultProgramFilterRequest: ProgramFilterRequest = {};

export interface ProgramFilterPageable {
    pageable?: Pageable;
    filter?: ProgramFilterRequest;
}

export interface ProgramToBatchDetail {
    id?: string;
    name?: string;
    identifier?: string;
    goodwill?: number;
    status?: ScdProgramStatus;
    policy?: ProgramPolicyToBatchDetail;
}
