import { action } from 'typesafe-actions';
import { SearchCompanyTypes } from 'reducer/company/search-companies/types';
import { ScdCompanySearch } from 'model/company';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchCompaniesRequest = (request: SearchRequest) => action(SearchCompanyTypes.SEARCH_COMPANIES_REQUEST, request);
export const searchCompaniesSuccess = (companies: Page<ScdCompanySearch>) => action(SearchCompanyTypes.SEARCH_COMPANIES_SUCCESS, companies);
export const searchCompaniesError = (error: GiroWebappError) => action(SearchCompanyTypes.SEARCH_COMPANIES_ERROR, error);
export const searchCompaniesResetState = () => action(SearchCompanyTypes.SEARCH_COMPANIES_RESET);
