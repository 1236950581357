import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOrganization } from 'model/organization';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchOrganizationsTypes {
    SEARCH_ORGANIZATIONS_REQUEST = '@@organizations-search/SEARCH_ORGANIZATIONS_REQUEST',
    SEARCH_ORGANIZATIONS_SUCCESS = '@@organizations-search/SEARCH_ORGANIZATIONS_SUCCESS',
    SEARCH_ORGANIZATIONS_ERROR = '@@organizations-search/SEARCH_ORGANIZATIONS_ERROR',
    SEARCH_ORGANIZATIONS_RESET = '@@organizations-search/SEARCH_ORGANIZATIONS_RESET',
}

export interface SearchOrganizationsState extends BaseStoreState {
    readonly organizations?: Page<ScdOrganization>;
}
