import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchOutputDetailsBcoTypes, BatchOutputsDetailsBcoState } from 'reducer-bco/batch-output/details-batch-output/types';

const initialState: BatchOutputsDetailsBcoState = {
    status: HttpRequestStatus.NOOP,
    batchOutputDetails: undefined,
    error: undefined,
};

const reducer: Reducer<BatchOutputsDetailsBcoState> = (state = initialState, action): BatchOutputsDetailsBcoState => {
    switch (action.type) {
        case BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchOutputDetails: action.payload, error: undefined };
        }
        case BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchOutputDetailsBcoTypes.BATCH_OUTPUT_DETAILS_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchOutputDetailsBcoReducer };
