import { EMAIL_REGEX } from 'config/constants';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_EMAIL_PREFIX = 'pages.login.email.validation';

const EMAIL_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_EMAIL_PREFIX}.required`,
    },
    format: {
        predicate: ValidationUtils.STRING.pattern(EMAIL_REGEX),
        errorMessage: `${I18N_EMAIL_PREFIX}.format`,
    },
    max_length: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_EMAIL_PREFIX}.maxLength`,
    },
};
export const validateEmail = (value: string | undefined | null) => ValidationUtils.validate(value, EMAIL_VALIDATION);

const I18N_PASSWORD_PREFIX = 'pages.login.password.validation';

const PASSWORD_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PASSWORD_PREFIX}.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(4),
        errorMessage: `${I18N_PASSWORD_PREFIX}.minLength`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(100),
        errorMessage: `${I18N_PASSWORD_PREFIX}.maxLength`,
    },
};
export const validatePassword = (value: string | undefined | null) => ValidationUtils.validate(value, PASSWORD_VALIDATION);

export interface PasswordMatch {
    password: string;
    confirm: string;
}

const I18N_PASSWORD_RESET_PREFIX = 'pages.password-reset';

const PASSWORD_MATCH_VALIDATION: Validations<PasswordMatch | undefined | null> = {
    required: {
        predicate: it => ValidationUtils.STRING.isNotBlank(it?.confirm),
        errorMessage: `${I18N_PASSWORD_RESET_PREFIX}.confirm-password.validation.required`,
    },
    minLength: {
        predicate: it => ValidationUtils.STRING.minLength(4)(it?.confirm),
        errorMessage: `${I18N_PASSWORD_RESET_PREFIX}.confirm-password.validation.minLength`,
    },
    maxLength: {
        predicate: it => ValidationUtils.STRING.maxLength(100)(it?.confirm),
        errorMessage: `${I18N_PASSWORD_RESET_PREFIX}.confirm-password.validation.maxLength`,
    },
    match: {
        predicate: it => it?.password === it?.confirm,
        errorMessage: `${I18N_PASSWORD_RESET_PREFIX}.confirm-password.validation.match`,
    },
};
export const validatePasswordMatch = ({ password, confirm }: PasswordMatch) =>
    ValidationUtils.validate({ password, confirm }, PASSWORD_MATCH_VALIDATION);

export const validatePasswordReset = (value: PasswordMatch): boolean => {
    if (!value) return false;
    return validatePassword(value.password).isValid && validatePasswordMatch(value).isValid;
};
