import { Grid } from '@material-ui/core';
import BatchCard, { BatchCardType } from 'entities/batch/components/batch-card/batch-card';

const BatchLoadingCard = () => {
    return (
        <Grid item>
            <BatchCard type={BatchCardType.LOADING} />
        </Grid>
    );
};

export default BatchLoadingCard;
