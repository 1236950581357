import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationsArchiveManyResponse } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsArchiveManyError,
    importedOperationsArchiveManyRequest,
    importedOperationsArchiveManySuccess,
} from 'reducer/imported-operation/archive-many/actions';
import { ImportedOperationsArchiveManyTypes } from 'reducer/imported-operation/archive-many/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';


function* handleImportedOperationsArchiveMany(action: ReturnType<typeof importedOperationsArchiveManyRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationsArchiveManyResponse> = yield call(importedOperationApi.archiveMany, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsArchiveManyError(mapped));
            return;
        }
        yield put(importedOperationsArchiveManySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsArchiveManyError(mapped));
    }
}

function* watchImportedOperationArchiveMany() {
    yield takeEvery(ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_REQUEST, handleImportedOperationsArchiveMany);
}

function* importedOperationsArchiveManySaga() {
    yield all([fork(watchImportedOperationArchiveMany)]);
}

export default importedOperationsArchiveManySaga;
