import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { CompanyGroupFilterRequest } from 'model/company-group';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseCompanyGroupFilterChipsProps {
    filterActive: CompanyGroupFilterRequest;
    setFilterActive: (value: CompanyGroupFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.company-group.filter.inputs';

type CompanyGroupFilterRequestType = keyof Omit<CompanyGroupFilterRequest, 'organizationId' | 'companyId'>;

const useCompanyGroupFilterChips = (props: UseCompanyGroupFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: CompanyGroupFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<CompanyGroupFilterRequestType, () => void> = useMemo(() => {
        return {
            groupName: () => clearFilterSupport({ ...filterActive, groupName: undefined }),
            organizationName: () => {
                clearFilterSupport({ ...filterActive, organizationId: undefined, organizationName: undefined });
            },
            companyName: () => {
                clearFilterSupport({ ...filterActive, companyId: undefined, companyName: undefined });
            },
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: CompanyGroupFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.${title}.label`);
            return `${titleTranslation}: `;
        },
        [t]
    );
    const companyGroupFilterChips = useMemo(() => {
        const items: Record<CompanyGroupFilterRequestType, ChipFilterItem> = {
            groupName: {
                isActive: !!filterActive.groupName,
                onDelete: handleClearFilter['groupName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('groupName')} component={filterActive.groupName} />,
            },
            organizationName: {
                isActive: !!filterActive.organizationName,
                onDelete: handleClearFilter['organizationName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('organization')} component={filterActive.organizationName} />,
            },
            companyName: {
                isActive: !!filterActive.companyName,
                onDelete: handleClearFilter['companyName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('company')} component={filterActive.companyName} />,
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isCompanyGroupChipsActive: boolean = useMemo(() => {
        return companyGroupFilterChips.some(it => !!it.isActive);
    }, [companyGroupFilterChips]);

    return {
        companyGroupFilterChips,
        isCompanyGroupChipsActive,
    };
};

export default useCompanyGroupFilterChips;
