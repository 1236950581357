import { ProgramFilterType } from 'entities/program/components/program-modal-filter/program-modal-filter';
import { ProgramFilterRequest } from 'model/program';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { valuesOfObject } from 'shared/util/object-utils';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterButton from 'components/buttons/filter-button/filter-button';

interface ProgramFilterButtonsProps {
    filterActive: ProgramFilterRequest;
    setIsOpenModalFilter: (value: boolean) => void;
    setModalFilter: (value: ProgramFilterType | undefined) => void;
    handleResetFilterActive: () => void;
}

const I18N_PREFIX = 'pages.scd.program.filter.buttons';

const ProgramFilterButtons = (props: ProgramFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setIsOpenModalFilter, setModalFilter } = props;

    const { t } = useTranslation();

    const ArrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );

    const isFilterActive: boolean = valuesOfObject({ ...filterActive }).some(it => !!it);

    const handleModalFilter = (value: ProgramFilterType) => () => {
        setIsOpenModalFilter(true);
        setModalFilter(value);
    };

    return (
        <>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFilterActive}
                    onClick={handleModalFilter('filters')}
                />
            </div>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.identifier`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.identifier}
                    onClick={handleModalFilter('identifier')}
                />
            </div>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.status`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.status}
                    onClick={handleModalFilter('status')}
                />
            </div>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.productType`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.productType}
                    onClick={handleModalFilter('productType')}
                />
            </div>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.originatorId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.originatorId}
                    onClick={handleModalFilter('originatorId')}
                />
            </div>
            <div className="scd-program__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.companyGroupId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.companyGroupId}
                    onClick={handleModalFilter('companyGroupId')}
                />
            </div>
            {isFilterActive && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-program__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default ProgramFilterButtons;
