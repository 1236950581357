import { GiroWebappError } from 'model/error';
import { PaymentInstitutionToSearch } from 'model/payment-institution-account';
import { SearchRequest } from 'model/reducers';
import { SearchPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/search-payment-institutions-accounts/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const searchPaymentInstitutionsAccountsRequest = (request: SearchRequest) =>
    action(SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, request);
export const searchPaymentInstitutionsAccountsSuccess = (paymentAccounts: Page<PaymentInstitutionToSearch>) =>
    action(SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, paymentAccounts);
export const searchPaymentInstitutionsAccountsError = (error: GiroWebappError) =>
    action(SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error);
export const searchPaymentInstitutionsAccountsResetState = () =>
    action(SearchPaymentInstitutionsAccountsTypes.SEARCH_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
