import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdCompanyGroup from 'entities/company-group/company-group';
import ScdCompanyGroupCreateUpdate from 'entities/company-group/company-group-create-update/company-group-create-update';
import ScdCompanyGroupDetail from 'entities/company-group/company-group-detail/company-group-detail';

import ScdCompanyItem from 'entities/company-group/company-item/company-item';
import ScdCompanyItemCreateUpdate from 'entities/company-group/company-item/company-item-create-update/company-item-create-update';
import ScdCompanyItemDetail from 'entities/company-group/company-item/company-item-detail/company-item-detail';

interface MatchParams {
    url: string;
}

const CompanyGroupRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdCompanyGroupCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyGroupId`} component={ScdCompanyGroupDetail} />
        <PrivateRoute exact path={`${match.url}/:companyGroupId/edit`} component={ScdCompanyGroupCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyGroupId/company-items`} component={ScdCompanyItem} />
        <PrivateRoute exact path={`${match.url}/:companyGroupId/company-items/new`} component={ScdCompanyItemCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyGroupId/company-items/:companyItemId`} component={ScdCompanyItemDetail} />
        <PrivateRoute
            exact
            path={`${match.url}/:companyGroupId/company-items/:companyItemId/edit`}
            component={ScdCompanyItemCreateUpdate}
        />

        <PrivateRoute exact path={`${match.url}`} component={ScdCompanyGroup} />
    </Switch>
);

export default CompanyGroupRoutes;
