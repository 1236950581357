import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionDocumentState, MonthlyCommissionDocumentTypes } from 'reducer/monthly-commission/document/types';

const initialState: MonthlyCommissionDocumentState = {
    status: HttpRequestStatus.NOOP,
    uploadDocument: undefined,
    downloadDocument: undefined,
    error: undefined,
};

const reducer: Reducer<MonthlyCommissionDocumentState> = (state = initialState, action): MonthlyCommissionDocumentState => {
    switch (action.type) {
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, uploadDocument: action.payload, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_UPLOAD_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, downloadDocument: action.payload, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, error: undefined };
        }
        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DELETE_DOCUMENT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case MonthlyCommissionDocumentTypes.MONTHLY_COMMISSION_DOCUMENT_RESET: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};

export { reducer as monthlyCommissionDocumentReducer };
