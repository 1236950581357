import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import Loading from 'components/loading/loading';
import RequestLogFilterButtons from 'entities/request-log/components/request-log-filter-buttons/request-log-filter-buttons';
import RequestLogModalFilter, {
    RequestLogModalFilterType,
} from 'entities/request-log/components/request-log-modal-filter/request-log-modal-filter';
import ScdRequestLogList from 'entities/request-log/request-log-list/request-log-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    defaultRequestLogFilterRequest,
    ScdRequestLogData,
    ScdRequestLogFilterPageable,
    ScdRequestLogFilterRequest,
} from 'model/request-log';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useListRequestLogState, useRootDispatch } from 'reducer/hooks';
import { listRequestLogRequest, listRequestLogResetState } from 'reducer/request-log/list-request-log/actions';
import { LIST_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';
import './request-log.scss';
import ScdRequestLogHeader from 'entities/request-log/request-log-header/request-log-header';

export interface RequestLogLocation {
    filterActive: ScdRequestLogFilterRequest;
}

const I18N_PREFIX = 'pages.scd.request-log';

export const ScdRequestLog = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);

    const dispatch = useRootDispatch();

    const [sortedProperty, setSortedProperty] = useState<string>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const [filterActive, setFilterActive] = useState<ScdRequestLogFilterRequest>(defaultRequestLogFilterRequest);
    const [modalFilter, setModalFilter] = useState<RequestLogModalFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);

    const { requestLogs, status: listStatus } = useListRequestLogState();
    const location = useLocation<RequestLogLocation>();

    const filterPageable = useCallback((): ScdRequestLogFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: LIST_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const _filters: ScdRequestLogFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    useEffect(() => {
        if (location?.state?.filterActive) {
            setFilterActive(location.state.filterActive);
        }
    }, [location?.state?.filterActive]);

    const handleListRequestLog = useCallback(() => {
        dispatch(listRequestLogRequest(filterPageable()));
    }, [dispatch, filterPageable]);

    useEffect(() => {
        handleListRequestLog();
    }, [handleListRequestLog]);

    useEffect(() => {
        return () => {
            dispatch(listRequestLogResetState());
        };
    }, [dispatch]);

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.request-log.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleListRequestLog} />
            </div>
        );
    }

    const isLoading = listStatus !== HttpRequestStatus.SUCCESS;

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const handleSort = (property: keyof ScdRequestLogData, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc' && property !== 'createdDate') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive({ ...defaultRequestLogFilterRequest });
    };

    return (
        <main className="scd-request-log">
            <section className="scd-request-log__container">
                <header className="scd-request-log__header">
                    <div className="scd-request-log__header-container">
                        <div style={{ display: 'flex' }}>
                            <h2 className="scd-request-log__header-container--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                            <RefreshButton onClick={handleListRequestLog} />
                        </div>
                    </div>
                    <div className="scd-batch__header--buttons-content">
                        <RequestLogFilterButtons
                            filterActive={filterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                            handleResetFilterActive={handleResetFilterActive}
                        />
                    </div>
                </header>
                <div className="scd-request-log__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdRequestLogHeader
                                requestLogs={requestLogs?.content ?? []}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                            />
                        </thead>
                        <tbody>{isLoading ? <Loading isTable /> : <ScdRequestLogList filterActive={filterActive} />}</tbody>
                    </table>
                </div>
                {!isLoading && <Pagination page={page} totalPages={requestLogs?.totalPages ?? 0} onChange={handlePageChange} />}
                {modalFilter && (
                    <RequestLogModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdRequestLog;
