
export const BRAZILIAN_LOCALE = 'pt-BR';

const brazilianCurrencyPrefix = (value: string): string => {
    return `R$ ${value}`;
};

const normalizeToBrazilianCurrencyAndParseFloat = (value: string): number => {
    if (!value) return NaN;
    const normalized = value.replaceAll('.', '').replace(',', '.');

    return parseFloat(normalized);
};


export const LocalizationUtils = {
    brazilianCurrencyPrefix,
    normalizeToBrazilianCurrencyAndParseFloat,
};