import ModalMessage from 'components/modals/modal-message/modal-message';
import { ChunkStreamPartitioningRequest } from 'model/bco/batch-output-cession-integration-gtsec';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    chunkStreamPartitioningBcoRequest,
    chunkStreamPartitioningBcoResetState,
} from 'reducer-bco/batch-output/chunk-stream-partitioning/action';
import { useCessionToResumeBco, useChunkStreamPartitioningBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import './send-partition-modal.scss';

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.without-partition.modal.partitioning';

interface SendPartitionModalProps {
    setIsModalOpen: (value: boolean) => void;
}

const SendPartitionModal: React.FC<SendPartitionModalProps> = ({ setIsModalOpen }) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { status } = useChunkStreamPartitioningBco();
    const { cessionToResume } = useCessionToResumeBco();
    const handleSendPartition = () => {
        const request: ChunkStreamPartitioningRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(chunkStreamPartitioningBcoRequest(request));
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        return () => {
            dispatch(chunkStreamPartitioningBcoResetState());
        };
    }, [dispatch]);

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={handleCloseModal}
            onAction={handleSendPartition}
            onCancel={handleCloseModal}
            standardButtonLabel={`${I18N_PREFIX}.button.execute-partition`}
            outlinedButtonLabel={'entity.action.back'}
            disabled={status === HttpRequestStatus.ON_GOING}
        >
            <div className="send-partition-modal__text">{t(`${I18N_PREFIX}.message`, { count: cessionToResume?.numberOfOperations })}</div>
        </ModalMessage>
    );
};

export default SendPartitionModal;
