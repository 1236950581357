import { EMAIL_REGEX } from 'config/constants';
import { UserToCreateOrUpdate } from 'model/user';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.users.create-update.validate';

const USER_LOGIN_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.login.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.login.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(50),
        errorMessage: `${I18N_PREFIX}.login.maxLength`,
    },
    validLogin: {
        predicate: ValidationUtils.STRING.pattern(EMAIL_REGEX),
        errorMessage: `${I18N_PREFIX}.login.invalid`,
    },
};

export const validateUserLogin = (value: string | undefined | null) => ValidationUtils.validate(value, USER_LOGIN_VALIDATION);

const USER_FIRST_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.first-name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(3),
        errorMessage: `${I18N_PREFIX}.first-name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(50),
        errorMessage: `${I18N_PREFIX}.first-name.maxLength`,
    },
};

export const validateUserFirstName = (value: string | undefined | null) => ValidationUtils.validate(value, USER_FIRST_NAME_VALIDATION);

const USER_LAST_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.last-name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(3),
        errorMessage: `${I18N_PREFIX}.last-name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(50),
        errorMessage: `${I18N_PREFIX}.last-name.maxLength`,
    },
};
export const validateUserLastName = (value: string | undefined | null) => ValidationUtils.validate(value, USER_LAST_NAME_VALIDATION);

export const validateUser = (value: UserToCreateOrUpdate) => {
    if (!value) {
        return false;
    }

    return (
        validateUserLogin(value.login).isValid &&
        validateUserFirstName(value.firstName).isValid &&
        validateUserLastName(value.lastName).isValid
    );
};
