import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { ScdCompanyGroup } from 'model/company-group';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramStatus } from 'model/enums/program-status';
import { ScdOriginatorSearch } from 'model/originator';
import { ProgramFilterRequest } from 'model/program';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchCompanyGroupsRequest, searchCompanyGroupsResetState } from 'reducer/company-group/search-company-groups/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import { valuesOfObject } from 'shared/util/object-utils';

import isEqual from 'lodash/isEqual';
import './program-modal-filter.scss';

export type ProgramFilterType = 'filters' | 'status' | 'productType' | 'identifier' | 'originatorId' | 'companyGroupId';

interface ProgramModalFilterProps {
    onClose: () => void;
    modalFilter: ProgramFilterType;
    isOpenModalFilter: boolean;
    title: string;
    filterActive: ProgramFilterRequest;
    setFilterActive: (value: ProgramFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'pages.scd.program.filter.inputs';

const DISABLED_MODAL_BUTTON_FUNCTION_RECORD: Record<ProgramFilterType, (filter: ProgramFilterRequest) => boolean> = {
    companyGroupId: filter => !filter.companyGroupId,
    originatorId: filter => !filter.originatorId,
    identifier: filter => !filter.identifier,
    productType: filter => !filter.productType,
    status: filter => !filter.status,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
};

const ProgramModalFilter = (props: ProgramModalFilterProps) => {
    const { filterActive, modalFilter, onClose, isOpenModalFilter, setFilterActive, setPage, title, resetInfiniteScrollListing } = props;

    const [filterRequest, setFilterRequest] = useState<ProgramFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    const checkResetList = useCallback(() => {
        if (isEqual(filterActive, filterRequest)) return;

        resetInfiniteScrollListing();
    }, [filterActive, filterRequest, resetInfiniteScrollListing]);

    if (!isOpenModalFilter) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        checkResetList();
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<ProgramFilterRequest>) => {
        setFilterRequest(ps => ({ ...ps, ...value }));
    };

    const handleClearFilter: Record<ProgramFilterType, () => void> = {
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
            resetInfiniteScrollListing();
        },
        companyGroupId: () => {
            setFilterRequest({ ...filterRequest, companyGroupId: undefined, companyGroupName: undefined });
            setFilterActive({ ...filterActive, companyGroupId: undefined, companyGroupName: undefined });
            resetInfiniteScrollListing();
        },
        identifier: () => {
            setFilterRequest({ ...filterRequest, identifier: undefined });
            setFilterActive({ ...filterActive, identifier: undefined });
            resetInfiniteScrollListing();
        },
        originatorId: () => {
            setFilterRequest({ ...filterRequest, originatorId: undefined, originatorName: undefined });
            setFilterActive({ ...filterActive, originatorId: undefined, originatorName: undefined });
            resetInfiniteScrollListing();
        },
        productType: () => {
            setFilterRequest({ ...filterRequest, productType: undefined });
            setFilterActive({ ...filterActive, productType: undefined });
            resetInfiniteScrollListing();
        },
        status: () => {
            setFilterRequest({ ...filterRequest, status: undefined });
            setFilterActive({ ...filterActive, status: undefined });
            resetInfiniteScrollListing();
        },
    };

    const ProductTypeFilter: JSX.Element = (
        <Grid item xs={12}>
            <div className="program-modal-filter--content">
                <div
                    className={clsx('program-modal-filter--content-product--label', {
                        active: filterRequest.productType === ProductType.CREDIT_CARD,
                    })}
                    onClick={() => handleChange({ productType: ProductType.CREDIT_CARD })}
                >
                    {t(`${I18N_PREFIX}.productType.options.CREDIT_CARD`).toLocaleUpperCase()}
                </div>
                <div
                    className={clsx('program-modal-filter--content-product--label', {
                        active: filterRequest.productType === ProductType.DIRECT_CONSUMER_CREDIT,
                    })}
                    onClick={() => handleChange({ productType: ProductType.DIRECT_CONSUMER_CREDIT })}
                >
                    {t(`${I18N_PREFIX}.productType.options.DIRECT_CONSUMER_CREDIT`).toLocaleUpperCase()}
                </div>
                <div
                    className={clsx('program-modal-filter--content-product--label', {
                        active: filterRequest.productType === ProductType.PERSONAL_CREDIT,
                    })}
                    onClick={() => handleChange({ productType: ProductType.PERSONAL_CREDIT })}
                >
                    {t(`${I18N_PREFIX}.productType.options.PERSONAL_CREDIT`).toLocaleUpperCase()}
                </div>
            </div>
        </Grid>
    );

    const StatusFilter: JSX.Element = (
        <Grid item xs={12}>
            <div className="program-modal-filter--content">
                <div
                    className={clsx('program-modal-filter--content-status--label', {
                        ACTIVE: filterRequest.status === ScdProgramStatus.ACTIVE,
                    })}
                    onClick={() => handleChange({ status: ScdProgramStatus.ACTIVE })}
                >
                    {t(`${I18N_PREFIX}.status.options.ACTIVE`).toLocaleUpperCase()}
                </div>
                <div
                    className={clsx('program-modal-filter--content-status--label', {
                        INACTIVE: filterRequest.status === ScdProgramStatus.INACTIVE,
                    })}
                    onClick={() => handleChange({ status: ScdProgramStatus.INACTIVE })}
                >
                    {t(`${I18N_PREFIX}.status.options.INACTIVE`).toLocaleUpperCase()}
                </div>
            </div>
        </Grid>
    );

    const CompanyGroupIdFilter: JSX.Element = (
        <Grid item xs={12}>
            <ModalSearch<ScdCompanyGroup>
                modalTitle={t(`${I18N_PREFIX}.companyGroupId.label`)}
                modalLabel={t(`${I18N_PREFIX}.companyGroupId.label`)}
                modalPlaceholder={t(`${I18N_PREFIX}.companyGroupId.placeholder`)}
                itemSize={SEARCH_ITEM_SIZE}
                action={searchCompanyGroupsRequest}
                resetState={searchCompanyGroupsResetState}
                renderItem={companyGroup => companyGroup.name}
                statusSelector={(state: IRootState) => state.searchCompanyGroups.status}
                dataSelector={(state: IRootState) => state.searchCompanyGroups.companyGroups}
                onSelect={companyGroup => handleChange({ companyGroupId: Number(companyGroup.id), companyGroupName: companyGroup.name })}
            >
                {handleOpen => (
                    <SimpleSearchInput
                        label={t(`${I18N_PREFIX}.companyGroupId.label`)}
                        placeholder={t(`${I18N_PREFIX}.companyGroupId.placeholder`)}
                        value={filterRequest?.companyGroupName}
                        onClick={handleOpen}
                        onFocus={handleOpen}
                        readOnly
                        externalUpdate
                    />
                )}
            </ModalSearch>
        </Grid>
    );

    const IdentifierFilter: JSX.Element = (
        <Grid item xs={12}>
            <SimpleTextInput
                label={t(`${I18N_PREFIX}.identifier.label`)}
                placeholder={t(`${I18N_PREFIX}.identifier.placeholder`)}
                value={filterRequest.identifier}
                onChange={identifier => handleChange({ identifier })}
                externalUpdate
            />
        </Grid>
    );

    const OriginatorIdFilter: JSX.Element = (
        <Grid item xs={12}>
            <ModalSearch<ScdOriginatorSearch>
                action={searchOriginatorsRequest}
                itemSize={SEARCH_ITEM_SIZE}
                modalTitle={t(`${I18N_PREFIX}.originatorId.label`)}
                modalLabel={t(`${I18N_PREFIX}.originatorId.label`)}
                modalPlaceholder={t(`${I18N_PREFIX}.originatorId.placeholder`)}
                onSelect={originator => handleChange({ originatorId: Number(originator.id), originatorName: originator.name })}
                renderItem={originator => originator?.name}
                statusSelector={(state: IRootState) => state.searchOriginators.status}
                dataSelector={(state: IRootState) => state.searchOriginators.originators}
                resetState={searchOriginatorsResetState}
            >
                {handleOpen => (
                    <SimpleSearchInput
                        label={`${I18N_PREFIX}.originatorId.label`}
                        placeholder={`${I18N_PREFIX}.originatorId.placeholder`}
                        value={filterRequest.originatorName}
                        onClick={handleOpen}
                        onFocus={handleOpen}
                        readOnly
                        externalUpdate
                    />
                )}
            </ModalSearch>
        </Grid>
    );

    const isConfirmButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled: boolean = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        isOpenModalFilter && (
            <Modal isOpen>
                <div className="program-modal-filter">
                    <div className="program-modal-filter--header">
                        <label className="program-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {IdentifierFilter}
                                            {OriginatorIdFilter}
                                            {CompanyGroupIdFilter}
                                            {ProductTypeFilter}
                                            {StatusFilter}
                                        </>
                                    ),
                                    identifier: IdentifierFilter,
                                    originatorId: OriginatorIdFilter,
                                    companyGroupId: CompanyGroupIdFilter,
                                    productType: ProductTypeFilter,
                                    status: StatusFilter,
                                } as Record<ProgramFilterType, ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="program-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};
export default ProgramModalFilter;
