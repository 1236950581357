import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdIssuerSearch } from 'model/issuer';
import { ScdOriginatorSearch } from 'model/originator';
import {
    PaymentInstitutionAccountConvertExclusive,
    PaymentInstitutionAccountConvertExclusiveRequest,
    defaultPaymentInstitutionAccountConvert,
} from 'model/payment-institution-account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { IRootState } from 'reducer';
import { useConvertPaymentInstitutionAccountState, useDetailPaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import { searchIssuersRequest, searchIssuersResetState } from 'reducer/issuer/search-issuers/actions';
import { searchOriginatorsRequest, searchOriginatorsResetState } from 'reducer/originator/search-originators/actions';
import {
    detailPaymentInstitutionsAccountsRequest,
    detailPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/detail-payment-institution-account/actions';

import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import {
    validatePaymentInstitutionAccountConvert,
    validatePaymentInstitutionAccountIssuer,
    validatePaymentInstitutionAccountOriginator,
} from 'entities/payment-institutions-accounts/payment-institution-account-convert/validation/payment-institution-account-convert-validation';
import { useHistory } from 'react-router-dom';
import {
    convertPaymentInstitutionAccountRequest,
    convertPaymentInstitutionAccountResetState,
} from 'reducer/payment-institutions-accounts/convert-payment-institution-account/actions';
import { useToast } from 'shared/hooks/use-toast';
import './payment-institution-account-convert.scss';

interface PaymentInstitutionAccountConvertProps extends RouteComponentProps<{ paymentInstitutionAccountId: string }> {}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

const PaymentInstitutionAccountConvert = (props: PaymentInstitutionAccountConvertProps) => {
    const { match } = props;

    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);

    const [paymentInstitutionAccountId] = useState<number | undefined>(
        match.params?.paymentInstitutionAccountId ? Number(match.params?.paymentInstitutionAccountId) : undefined
    );
    const [convertAccount, setConvertAccount] = useState<PaymentInstitutionAccountConvertExclusive>(
        defaultPaymentInstitutionAccountConvert
    );

    const dispatch = useRootDispatch();
    const history = useHistory();

    const { t } = useTranslation();
    const { toastSuccess } = useToast();

    const { status: detailStatus, paymentInstitutionsAccount } = useDetailPaymentInstitutionAccountState();
    const { status: convertStatus } = useConvertPaymentInstitutionAccountState();

    const handleConfirmConvert = (convertAccount: PaymentInstitutionAccountConvertExclusive) => {
        setShowValidation(true);

        if (!paymentInstitutionAccountId) return;
        if (!validatePaymentInstitutionAccountConvert(convertAccount)) return;

        setModalConfirm(true);
    };

    const handleSave = () => {
        setShowValidation(true);

        if (!validatePaymentInstitutionAccountConvert(convertAccount)) return;

        const _request: PaymentInstitutionAccountConvertExclusiveRequest = {
            id: paymentInstitutionAccountId,
            originatorId: convertAccount.originatorId,
            issuerId: convertAccount.issuerId,
        };

        dispatch(convertPaymentInstitutionAccountRequest(_request));
    };

    const handleChange = (value: Partial<PaymentInstitutionAccountConvertExclusive>) => {
        setConvertAccount(ps => ({ ...ps, ...value }));
    };

    const isLoading: boolean = detailStatus === HttpRequestStatus.ON_GOING;

    useEffect(() => {
        dispatch(detailPaymentInstitutionsAccountsRequest(Number(paymentInstitutionAccountId)));
    }, [dispatch, paymentInstitutionAccountId]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && paymentInstitutionsAccount) {
            setConvertAccount(paymentInstitutionsAccount);
        }
    }, [detailStatus, paymentInstitutionsAccount]);

    useEffect(() => {
        if (convertStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast-success.convert`));
        history.push('/payment-institutions-accounts');
    }, [toastSuccess, t, history, convertStatus]);

    useEffect(() => {
        return () => {
            dispatch(detailPaymentInstitutionsAccountsResetState());
            dispatch(convertPaymentInstitutionAccountResetState());
        };
    }, [dispatch]);

    return (
        <main className="scd-payment-institution-account-convert">
            <ContextRibbon />
            <div className="scd-payment-institution-account-convert--container">
                <header className="scd-payment-institution-account-convert--header">
                    <h2 className="scd-payment-institution-account-convert--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="scd-payment-institution-account-convert--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <form className="scd-payment-institution-account-convert--form">
                            <div className="scd-payment-institution-account-create-update--form-field">
                                <SimpleTextInput
                                    label={t(`${I18N_PREFIX}.inputs.name.label`)}
                                    placeholder={t(`${I18N_PREFIX}.inputs.name.placeholder`)}
                                    value={convertAccount?.name ?? ''}
                                    onChange={name => handleChange({ name })}
                                    disabled={!!paymentInstitutionAccountId}
                                    externalUpdate
                                />
                            </div>
                            <div className="scd-payment-institution-account-create-update--form-field">
                                <SimpleTextInput
                                    label={t(`${I18N_PREFIX}.inputs.type.label`)}
                                    placeholder={t(`${I18N_PREFIX}.inputs.type.placeholder`)}
                                    value={t(`${I18N_PREFIX}.inputs.type.options.${convertAccount?.type}`)}
                                    disabled={!!paymentInstitutionAccountId}
                                    externalUpdate
                                />
                            </div>
                            <div className="scd-payment-institution-account-create-update--form-field">
                                <SimpleTextInput
                                    label={t(`${I18N_PREFIX}.inputs.technicalSupplier.label`)}
                                    placeholder={t(`${I18N_PREFIX}.inputs.technicalSupplier.placeholder`)}
                                    value={convertAccount?.technicalSupplierName ?? ''}
                                    disabled={!!paymentInstitutionAccountId}
                                    externalUpdate
                                />
                            </div>
                            <div className="scd-payment-institution-account-convert--form-field">
                                <ModalSearch<ScdOriginatorSearch>
                                    action={searchOriginatorsRequest}
                                    itemSize={SEARCH_ITEM_SIZE}
                                    modalTitle={t(`${I18N_PREFIX}.inputs.originator.title`)}
                                    modalLabel={t(`${I18N_PREFIX}.inputs.originator.label`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)}
                                    onSelect={originator =>
                                        handleChange({
                                            originatorId: Number(originator.id),
                                            originatorName: originator.name,
                                        })
                                    }
                                    renderItem={originator => originator.name}
                                    statusSelector={(state: IRootState) => state.searchOriginators.status}
                                    dataSelector={(state: IRootState) => state.searchOriginators.originators}
                                    resetState={searchOriginatorsResetState}
                                >
                                    {handleOpen => (
                                        <ValidSearchInput
                                            label={t(`${I18N_PREFIX}.inputs.originator.label`)}
                                            placeholder={t(`${I18N_PREFIX}.inputs.originator.placeholder`)}
                                            value={convertAccount.originatorName ?? ''}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            readOnly
                                            externalUpdate
                                            showValidation={showValidation}
                                            validate={() => validatePaymentInstitutionAccountOriginator(convertAccount.originatorId)}
                                        />
                                    )}
                                </ModalSearch>
                            </div>
                            <div className="scd-payment-institution-account-convert--form-field">
                                <ModalSearch<ScdIssuerSearch>
                                    action={searchIssuersRequest}
                                    itemSize={SEARCH_ITEM_SIZE}
                                    modalTitle={t(`${I18N_PREFIX}.inputs.issuer.title`)}
                                    modalLabel={t(`${I18N_PREFIX}.inputs.issuer.label`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.inputs.issuer.placeholder`)}
                                    onSelect={issuer =>
                                        handleChange({
                                            issuerId: Number(issuer.id),
                                            issuerName: issuer.name,
                                        })
                                    }
                                    renderItem={issuer => issuer.name}
                                    statusSelector={(state: IRootState) => state.searchIssuers.status}
                                    dataSelector={(state: IRootState) => state.searchIssuers.issuers}
                                    resetState={searchIssuersResetState}
                                >
                                    {handleOpen => (
                                        <ValidSearchInput
                                            label={t(`${I18N_PREFIX}.inputs.issuer.label`)}
                                            placeholder={t(`${I18N_PREFIX}.inputs.issuer.placeholder`)}
                                            value={convertAccount.issuerName ?? ''}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            readOnly
                                            externalUpdate
                                            showValidation={showValidation}
                                            validate={() => validatePaymentInstitutionAccountIssuer(convertAccount.issuerId)}
                                        />
                                    )}
                                </ModalSearch>
                            </div>
                            <div className="scd-payment-institution-account-convert--form-buttons">
                                <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                                <StandardButtonLegacy
                                    label={t('entity.action.save')}
                                    onClick={() => handleConfirmConvert(convertAccount)}
                                />
                            </div>
                        </form>
                    )}
                    <div>
                        {modalCancel && (
                            <ModalMessageLegacy
                                title={t('entity.cancel.convert')}
                                message={t('entity.cancel.info')}
                                onCancel={() => setModalCancel(false)}
                                onAction={() => {
                                    setModalCancel(false);
                                    history.push('/payment-institutions-accounts');
                                }}
                                onClose={() => setModalCancel(false)}
                            />
                        )}
                        {modalConfirm && (
                            <ModalMessageLegacy
                                title={t(`${I18N_PREFIX}.modal.convert.confirm.title`)}
                                message={t(`${I18N_PREFIX}.modal.convert.confirm.info`)}
                                onCancel={() => setModalConfirm(false)}
                                onAction={handleSave}
                                onClose={() => setModalConfirm(false)}
                            />
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PaymentInstitutionAccountConvert;
