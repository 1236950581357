
import { formatIdentification } from 'components/formatter/identification/identification-formatter';
import { PersonType } from 'model/enums/person-type';


export interface HemeraBacking {
    participantControl: string;
    fileName: string;
}

export class HemeraBackingsXmlBuilder {

    private default_xml_header: string = `<?xml version="1.0" encoding="UTF-8"?>`;

    private issuerIdentification: string = "";
    private zipBase64: string = "";
    private backings: HemeraBacking[] = [];

    private isDebug: boolean = false;


    private composeWrapper(content?: string): string {
        if (!content) return "";
        if (this.isDebug) return `\n<DocLastros>${content}</DocLastros>`;
        return `<DocLastros>${content}</DocLastros>`;
    }

    withIssuer(issuerIdentification: string): this {
        this.issuerIdentification = formatIdentification(PersonType.CORPORATION, issuerIdentification) ?? issuerIdentification;
        return this;
    }

    private composeIssuer(): string {
        if (!this.issuerIdentification) return "";
        if (this.isDebug) return `\n\t<Fundo>${this.issuerIdentification}</Fundo>\n`;

        return `<Fundo>${this.issuerIdentification}</Fundo>`;
    }

    withZipBase64(zipBase64: string): this {
        this.zipBase64 = zipBase64;
        return this;
    }

    private composeZipBase64(): string {
        if (!this.zipBase64) return "";
        if (this.isDebug) return `\n\t<zipBase64>...omitted base64...</zipBase64>\n`;
        return `<zipBase64>${this.zipBase64}</zipBase64>`;
    }

    withBackings(backings: HemeraBacking[]): this {
        this.backings = backings;
        return this;
    }

    private composeParticipantControl(backing: HemeraBacking): string {
        if (!backing.participantControl) return "";
        if (this.isDebug) return `\n\t\t\t<CONTROLE_PARTICIPANTE>${backing.participantControl}</CONTROLE_PARTICIPANTE>`;
        return `<CONTROLE_PARTICIPANTE>${backing.participantControl}</CONTROLE_PARTICIPANTE>`;
    }

    private composeFileName(backing: HemeraBacking): string {
        if (!backing.fileName) return "";
        if (this.isDebug) return `\n\t\t\t<identificadorArquivo>${backing.fileName}</identificadorArquivo>`;
        return `<identificadorArquivo>${backing.fileName}</identificadorArquivo>`;
    }

    private composeBacking(backing: HemeraBacking): string {
        if (!backing) return "";
        if (this.isDebug) return `\n\t\t<Lastro>${this.composeParticipantControl(backing)}${this.composeFileName(backing)}\n\t\t</Lastro>`;
        return `<Lastro>${this.composeParticipantControl(backing)}${this.composeFileName(backing)}</Lastro>`;
    }

    private composeBackings(): string {
        if (!this.backings.length) return "";
        if (this.isDebug) return `\n\t<Lastros>${this.backings.map(it => this.composeBacking(it)).join("")}\n\t</Lastros>\n`;
        return `<Lastros>${this.backings.map(it => this.composeBacking(it)).join("")}</Lastros>`;

    }



    build(): string {
        this.isDebug = false;
        const issuer = this.composeIssuer();
        const backings = this.composeBackings();
        const zipBase64 = this.composeZipBase64();

        const content = `${issuer}${backings}${zipBase64}`;
        const wrapper = this.composeWrapper(content);

        return `${this.default_xml_header}${wrapper}`;
    }

    getDebugInfo(): string {
        this.isDebug = true;
        const issuer = this.composeIssuer();
        const backings = this.composeBackings();
        const zipBase64 = this.composeZipBase64();

        const content = `${issuer}${backings}${zipBase64}`;
        const wrapper = this.composeWrapper(content);

        return `${this.default_xml_header}${wrapper}`;
    }

}



