import { Grid } from '@material-ui/core';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { PersonType } from 'model/enums/person-type';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getPersonType } from 'shared/util/person-type-utils';

interface PersonalInformationOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.personalInfo';

const PersonalInfoOperationCard = (props: PersonalInformationOperationCardProps) => {
    const {
        operation: { personalInformation },
    } = props;

    const { t } = useTranslation();

    const personTypeToFormat: PersonType = useMemo(() => {
        return personalInformation?.debtorType ?? getPersonType(personalInformation?.identification);
    }, [personalInformation]);

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)}>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.person`)} value={personalInformation?.personName} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.identification.${personTypeToFormat}`)}
                    value={
                        personalInformation?.identification && (
                            <IdentificationFormatter value={personalInformation?.identification} type={personTypeToFormat} />
                        )
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.company`)} value={personalInformation?.companyName} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.stateRegistration`)}
                    value={personalInformation?.stateRegistration}
                />
            </Grid>
        </BaseOperationCard>
    );
};

export default PersonalInfoOperationCard;
