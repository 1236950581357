import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { GiroScdErrorConstants } from 'model/enums/error-constants';
import { ScdImportedOperationsArchiveManyResponse, ScdImportedOperationsData } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './archive-many-modal.scss';

const I18N_PREFIX = 'pages.scd.ccb-operation.modal.ARCHIVE_MANY_ERROR';

interface ArchiveManyOperationsModalErrorProps {
    title: string;
    onClose: () => void;
    onAction?: () => void;
    archivedOperations: ScdImportedOperationsArchiveManyResponse | undefined;
    operations: ScdImportedOperationsData[] | undefined;
}

const ArchiveManyOperationsModalError = (props: ArchiveManyOperationsModalErrorProps) => {
    const { title, onClose, onAction, archivedOperations, operations } = props;
    const { t } = useTranslation();

    const verifyErrorMessage = (message: string | undefined) => {
        const errors: string[] = valuesOfObject(GiroScdErrorConstants);

        return errors.some(value => value === message);
    };

    const getCcb = (id: number): number | string | undefined => {
        const selectedOperation = operations?.find(operation => operation.id === id);

        if (!selectedOperation?.ccbNumber) return '-';

        return selectedOperation?.ccbNumber;
    };

    return (
        <Modal isOpen>
            <div className="archive-many-modal-error">
                <article className="archive-many-modal-error--content">
                    <header className="archive-many-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="archive-many-modal-error--message">
                        <p className="archive-many-modal-error--title">{t(title)}</p>
                        <div className="archive-many-modal-error--errors">
                            {archivedOperations?.errors?.map(({ id, message }) => (
                                <div className="archive-many-modal-error--errors__text" key={id}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.ccbNumber`)} </strong>
                                        {getCcb(id)}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong>
                                        {message && verifyErrorMessage(message)
                                            ? t(message)
                                            : !!message
                                            ? message
                                            : t(`${I18N_PREFIX}.default-error-message`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {onAction && (
                        <div className="archive-many-modal-error--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.back'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ArchiveManyOperationsModalError;
