import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import technicalSupplierApi from 'services/api/technical-supplier-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchTechnicalSuppliersTypes } from './types';
import { searchTechnicalSuppliersError, searchTechnicalSuppliersSuccess, searchTechnicalSuppliersRequest } from './actions';
import { Page } from 'services/page';

function* handleSearchTechnicalSuppliers(action: ReturnType<typeof searchTechnicalSuppliersRequest>) {
    try {
        const result: AxiosResponse<Page<ScdTechnicalSupplier>> = yield call(technicalSupplierApi.searchTechnicalSuppliers, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchTechnicalSuppliersError(mapped));
            return;
        }
        yield put(searchTechnicalSuppliersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchTechnicalSuppliersError(mapped));
    }
}

function* watchSearchTechnicalSuppliers() {
    yield takeEvery(SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_REQUEST, handleSearchTechnicalSuppliers);
}

function* searchTechnicalSuppliersSaga() {
    yield all([fork(watchSearchTechnicalSuppliers)]);
}

export default searchTechnicalSuppliersSaga;
