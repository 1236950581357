import { AxiosResponse } from 'axios';
import { ScdBatchClose } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { reprocessBatchError, reprocessBatchRequest, reprocessBatchSuccess } from 'reducer/batch/reprocess-batch/actions';
import { ReprocessBatchTypes } from 'reducer/batch/reprocess-batch/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleReprocessBatch(action: ReturnType<typeof reprocessBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchClose> = yield call(batchAPI.reprocessBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(reprocessBatchError(mapped));
            return;
        }
        yield put(reprocessBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(reprocessBatchError(mapped));
    }
}

function* watchReprocessBatch() {
    yield takeLeading(ReprocessBatchTypes.REPROCESS_BATCH_REQUEST, handleReprocessBatch);
}

function* reprocessBatchSaga() {
    yield all([fork(watchReprocessBatch)]);
}

export default reprocessBatchSaga;
