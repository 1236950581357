import { GiroWebappError } from 'model/error';
import { action } from 'typesafe-actions';
import { ProcessCessionOperationBcoTypes } from 'reducer-bco/batch-output/process-cession-operation/types';
import { CessionOperationRequestRequired, CessionToSimpleDTO } from 'model/bco/batch-output-cession-integration-webcred';

export const processCessionOperationBcoRequest = (request: CessionOperationRequestRequired) =>
    action(ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_REQUEST, request);
export const processCessionOperationBcoSuccess = (cessionToSimpleDTO: CessionToSimpleDTO) =>
    action(ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_SUCCESS, cessionToSimpleDTO);
export const processCessionOperationBcoError = (error: GiroWebappError) =>
    action(ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_ERROR, error);
export const processCessionOperationBcoResetState = () => 
    action(ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_RESET);
