import { ScdIssuer } from 'model/issuer';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum DetailIssuerTypes {
    DETAIL_ISSUER_REQUEST = '@@issuer-detail/DETAIL_ISSUER_REQUEST',
    DETAIL_ISSUER_SUCCESS = '@@issuer-detail/DETAIL_ISSUER_SUCCESS',
    DETAIL_ISSUER_ERROR = '@@issuer-detail/DETAIL_ISSUER_ERROR',
    DETAIL_ISSUER_RESET_STATE = '@@issuer-detail/DETAIL_ISSUER_RESET_STATE',
}

export interface DetailIssuerState extends BaseStoreState {
    readonly issuer?: ScdIssuer;
}
