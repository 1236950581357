import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdCompanyGroup } from 'model/company-group';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.company-group.table';

interface ScdCompanyGroupHeaderProps {
    companyGroups: ScdCompanyGroup[];
    handleSort: (property: keyof ScdCompanyGroup, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

export const ScdCompanyGroupHeader = (props: ScdCompanyGroupHeaderProps) => {
    const { t } = useTranslation();
    const { companyGroups, handleSort, sortedProperty, sortOrder } = props;

    const mapSortable = (_property: keyof ScdCompanyGroup) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: companyGroups && companyGroups.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
        </tr>
    );
};

export default ScdCompanyGroupHeader;
