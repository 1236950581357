import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import PercentMask from 'components/inputs/percentage-input/percent-mask';
import FormControl from '@material-ui/core/FormControl';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;

export interface SimplePercentInputProps extends Base {
    value: number;
    onChange?: (value: number) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

const SimplePercentInput = (props: SimplePercentInputProps) => {
    const { value, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = InputUtils.percentStringToNumber(_value);
        if (onChange && !isNaN(normalized)) {
            onChange(normalized);
        }
    };

    return (
        <FormControl className={clsx('base-input', { dirty: value })} fullWidth>
            <BaseMaskInput
                {...otherProps}
                value={InputUtils.formatNumberToPercentage(value)}
                onChange={handleOnChange}
                MaskInputComponent={MaskInputComponent ?? PercentMask}
            />
        </FormControl>
    );
};

export default SimplePercentInput;
