import _ from 'lodash'

const normalizeCurrencyValueToLocale = (value: number | undefined): string | undefined => {
    if (_.isNil(value)) return ''

    return String(value).replace('.', ',')
}

export const NormalizationUtils = {
    normalizeCurrencyValueToLocale
}