import { Checkbox } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import clsx from 'clsx';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import BatchCommissionReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-commision-report/batch-commission-report';
import BatchDisbursementReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-disbursement-report/batch-disbursment-report';
import BatchExpirationsReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-expirations-report/batch-expirations-report';
import BatchOperationsReport from 'entities/batch/components/batch-report-modal/batch-reports/batch-operations-report/batch-operations-report';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { batchCommissionReportRequest, batchCommissionReportResetState } from 'reducer/batch/commission-report/actions';
import { batchDisbursementReportRequest, batchDisbursementReportResetState } from 'reducer/batch/disbursement-report/actions';
import { batchExpirationsReportRequest, batchExpirationsReportResetState } from 'reducer/batch/expirations-report/actions';
import { batchOperationsReportRequest, batchOperationsReportResetState } from 'reducer/batch/operations-report/actions';
import { cnabBatchReportRequest, cnabBatchReportResetState } from 'reducer/cnab/cnab-batch-report/actions';
import {
    useBatchCommissionReportState,
    useBatchDisbursementReportState,
    useBatchExpirationsReportState,
    useBatchOperationsReportState,
    useCnabBatchReportState,
    useRootDispatch,
} from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import FileUtils from 'shared/util/file-utils';
import './batch-report-modal.scss';

interface BatchReportModalCheckbox {
    operation: boolean;
    expiration: boolean;
    disbursement: boolean;
    cnab: boolean;
    commission: boolean;
}

const defaultBatchReportModalCheckbox: BatchReportModalCheckbox = {
    operation: true,
    expiration: true,
    disbursement: true,
    cnab: true,
    commission: true,
};

interface BatchReportModalProps {
    batchId: number | undefined;
    isOpenModal: boolean;
    setIsOpenModal: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch.detail.report';

type BatchReportType = 'disbursement' | 'expiration' | 'operation' | 'cnab' | 'commission';

const BatchReportModal = (props: BatchReportModalProps) => {
    const { batchId, isOpenModal, setIsOpenModal } = props;

    const dispatch = useRootDispatch();

    const operationCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const expirationCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const disbursementCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const commissionCsvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const { toastLoading, closeToast, toastInfo } = useToast();

    const [checkbox, setCheckbox] = useState<BatchReportModalCheckbox>(defaultBatchReportModalCheckbox);

    const { t } = useTranslation();

    const { batchOperationsReport, status: batchOperationsReportStatus } = useBatchOperationsReportState();
    const { batchExpirationsReport, status: batchExpirationsReportStatus } = useBatchExpirationsReportState();
    const { batchDisbursementReport, status: batchDisbursementReportStatus } = useBatchDisbursementReportState();
    const { cnabBatchReport, status: batchCnabStatus } = useCnabBatchReportState();
    const { batchCommissionReport, status: batchCommissionStatus } = useBatchCommissionReportState();

    const showToastLoading =
        batchOperationsReportStatus === HttpRequestStatus.ON_GOING ||
        batchExpirationsReportStatus === HttpRequestStatus.ON_GOING ||
        batchDisbursementReportStatus === HttpRequestStatus.ON_GOING ||
        batchCnabStatus === HttpRequestStatus.ON_GOING ||
        batchCommissionStatus === HttpRequestStatus.ON_GOING;

    const hasSuccessRequest =
        batchOperationsReportStatus === HttpRequestStatus.SUCCESS ||
        batchExpirationsReportStatus === HttpRequestStatus.SUCCESS ||
        batchDisbursementReportStatus === HttpRequestStatus.SUCCESS ||
        batchCnabStatus === HttpRequestStatus.SUCCESS ||
        batchCommissionStatus === HttpRequestStatus.SUCCESS;

    useEffect(() => {
        if (showToastLoading) {
            toastLoading();
            return;
        }

        closeToast();
    }, [showToastLoading, toastLoading, closeToast, t]);

    const handleRequestReport = () => {
        if (!batchId) return;

        if (checkbox.operation) {
            dispatch(batchOperationsReportRequest(batchId));
        }

        if (checkbox.expiration) {
            dispatch(batchExpirationsReportRequest(batchId));
        }

        if (checkbox.disbursement) {
            dispatch(batchDisbursementReportRequest(batchId));
        }

        if (checkbox.cnab) {
            dispatch(cnabBatchReportRequest(batchId));
        }

        if (checkbox.commission) {
            dispatch(batchCommissionReportRequest(batchId));
        }
    };

    const handleGenerateReport = {
        operation: () => {
            if (batchOperationsReport?.importedOperations.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.operation.empty`));
                return;
            }
            operationCsvLinkRef.current?.link.click();
        },
        expiration: () => {
            if (batchExpirationsReport?.importedExpirations.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.expiration.empty`));
                return;
            }
            expirationCsvLinkRef.current?.link.click();
        },
        disbursement: () => {
            if (batchDisbursementReport?.disbursements?.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.disbursement.empty`));
                return;
            }
            disbursementCsvLinkRef.current?.link.click();
        },
        cnab: () => {
            if (!cnabBatchReport?.fileId || !cnabBatchReport?.file || !cnabBatchReport.fileName) return;
            FileUtils.downloadObject(cnabBatchReport.fileId, cnabBatchReport.file, cnabBatchReport.fileName);
        },
        commission: () => {
            if (batchCommissionReport?.commissions?.length === 0) {
                toastInfo(t(`${I18N_PREFIX}.commission.empty`));
                return;
            }
            commissionCsvLinkRef.current?.link.click();
        },
    } as Record<BatchReportType, () => void>;

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setCheckbox(defaultBatchReportModalCheckbox);

        dispatch(batchOperationsReportResetState());
        dispatch(batchExpirationsReportResetState());
        dispatch(batchDisbursementReportResetState());
        dispatch(cnabBatchReportResetState());
        dispatch(batchCommissionReportResetState());
    };

    return (
        <div className="batch-report-modal">
            {isOpenModal && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.title`)}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal}
                    onAction={handleRequestReport}
                    standardButtonLabel={t('entity.action.save')}
                    disabled={hasSuccessRequest}
                >
                    <div className="batch-report-modal--content">
                        <div className="batch-report-modal--content-item">
                            <div>
                                <InsertDriveFileIcon
                                    className={clsx('batch-report-modal--content-item__icon', { active: checkbox.operation })}
                                />
                                <span> {t(`${I18N_PREFIX}.operation.title`)} </span>
                            </div>
                            {
                                (
                                    {
                                        NOOP: (
                                            <Checkbox
                                                checked={checkbox.operation}
                                                disabled={hasSuccessRequest}
                                                onChange={() => setCheckbox({ ...checkbox, operation: !checkbox.operation })}
                                            />
                                        ),
                                        SUCCESS: (
                                            <div
                                                className="batch-report-modal--link__icon"
                                                style={{ margin: '12px' }}
                                                onClick={handleGenerateReport['operation']}
                                            />
                                        ),
                                        ERROR: <ErrorIcon style={{ color: '#ff7978', margin: '7px 8px' }} />,
                                    } as Record<HttpRequestStatus, React.ReactNode>
                                )[batchOperationsReportStatus]
                            }
                        </div>
                        <div className="batch-report-modal--content-item">
                            <div>
                                <InsertDriveFileIcon
                                    className={clsx('batch-report-modal--content-item__icon', { active: checkbox.expiration })}
                                />
                                <span> {t(`${I18N_PREFIX}.expiration.title`)} </span>
                            </div>
                            {
                                (
                                    {
                                        NOOP: (
                                            <Checkbox
                                                checked={checkbox.expiration}
                                                disabled={hasSuccessRequest}
                                                onChange={() => setCheckbox({ ...checkbox, expiration: !checkbox.expiration })}
                                            />
                                        ),
                                        SUCCESS: (
                                            <div
                                                className="batch-report-modal--link__icon"
                                                style={{ margin: '12px' }}
                                                onClick={handleGenerateReport['expiration']}
                                            />
                                        ),
                                        ERROR: <ErrorIcon style={{ color: '#ff7978', margin: '7px 8px' }} />,
                                    } as Record<HttpRequestStatus, React.ReactNode>
                                )[batchExpirationsReportStatus]
                            }
                        </div>
                        <div className="batch-report-modal--content-item">
                            <div>
                                <InsertDriveFileIcon
                                    className={clsx('batch-report-modal--content-item__icon', { active: checkbox.disbursement })}
                                />
                                <span> {t(`${I18N_PREFIX}.disbursement.title`)} </span>
                            </div>
                            {
                                (
                                    {
                                        NOOP: (
                                            <Checkbox
                                                checked={checkbox.disbursement}
                                                disabled={hasSuccessRequest}
                                                onChange={() => setCheckbox({ ...checkbox, disbursement: !checkbox.disbursement })}
                                            />
                                        ),
                                        SUCCESS: (
                                            <div
                                                className="batch-report-modal--link__icon"
                                                style={{ margin: '12px' }}
                                                onClick={handleGenerateReport['disbursement']}
                                            />
                                        ),
                                        ERROR: <ErrorIcon style={{ color: '#ff7978', margin: '7px 8px' }} />,
                                    } as Record<HttpRequestStatus, React.ReactNode>
                                )[batchDisbursementReportStatus]
                            }
                        </div>
                        <div className="batch-report-modal--content-item">
                            <div>
                                <InsertDriveFileIcon
                                    className={clsx('batch-report-modal--content-item__icon', { active: checkbox.cnab })}
                                />
                                <span> {t(`${I18N_PREFIX}.cnab.title`)} </span>
                            </div>
                            {
                                (
                                    {
                                        NOOP: (
                                            <Checkbox
                                                disabled={hasSuccessRequest}
                                                checked={checkbox.cnab}
                                                onChange={() => setCheckbox({ ...checkbox, cnab: !checkbox.cnab })}
                                            />
                                        ),
                                        SUCCESS: (
                                            <div
                                                className="batch-report-modal--link__icon"
                                                style={{ margin: '12px' }}
                                                onClick={handleGenerateReport['cnab']}
                                            />
                                        ),
                                        ERROR: <ErrorIcon style={{ color: '#ff7978', margin: '7px 8px' }} />,
                                    } as Record<HttpRequestStatus, React.ReactNode>
                                )[batchCnabStatus]
                            }
                        </div>
                        <div className="batch-report-modal--content-item">
                            <div>
                                <InsertDriveFileIcon
                                    className={clsx('batch-report-modal--content-item__icon', { active: checkbox.commission })}
                                />
                                <span> {t(`${I18N_PREFIX}.commission.title`)} </span>
                            </div>
                            {
                                (
                                    {
                                        NOOP: (
                                            <Checkbox
                                                disabled={hasSuccessRequest}
                                                checked={checkbox.commission}
                                                onChange={() => setCheckbox({ ...checkbox, commission: !checkbox.commission })}
                                            />
                                        ),
                                        SUCCESS: (
                                            <div
                                                className="batch-report-modal--link__icon"
                                                style={{ margin: '12px' }}
                                                onClick={handleGenerateReport['commission']}
                                            />
                                        ),
                                        ERROR: <ErrorIcon style={{ color: '#ff7978', margin: '7px 8px' }} />,
                                    } as Record<HttpRequestStatus, React.ReactNode>
                                )[batchCommissionStatus]
                            }
                        </div>
                    </div>
                    {batchOperationsReport && (
                        <BatchOperationsReport csvLinkRef={operationCsvLinkRef} batchOperationsReport={batchOperationsReport} />
                    )}
                    {batchExpirationsReport && (
                        <BatchExpirationsReport csvLinkRef={expirationCsvLinkRef} batchExpirationsReport={batchExpirationsReport} />
                    )}
                    {batchDisbursementReport && (
                        <BatchDisbursementReport csvLinkRef={disbursementCsvLinkRef} batchDisbursementReport={batchDisbursementReport} />
                    )}
                    {batchCommissionReport && (
                        <BatchCommissionReport csvLinkRef={commissionCsvLinkRef} batchCommissionReport={batchCommissionReport} />
                    )}
                </ModalMessageLegacy>
            )}
        </div>
    );
};

export default BatchReportModal;
