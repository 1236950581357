import { PaymentInstitutionAccountDetail } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailPaymentInstitutionsAccountsTypes {
    DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-detail/DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-detail/DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-detail/DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-detail/DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface DetailPaymentInstitutionsAccountsState extends BaseStoreState {
    readonly paymentInstitutionsAccount?: PaymentInstitutionAccountDetail;
}
