import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Search platform
import { searchPlatformsReducer } from 'reducer/platform/search/reducer';
import searchPlatformsSagas from 'reducer/platform/search/sagas';
import { SearchPlatformsState } from 'reducer/platform/search/types';

export interface PlatformState {
    readonly searchPlatform: SearchPlatformsState;
}

export const PlatformReducer: ReducersMapObject<PlatformState, AnyAction> = {
    searchPlatform: searchPlatformsReducer,
}

export function* platformSagas() {
    yield all([fork(searchPlatformsSagas)])
}