import { ChunkCessionFinalizeResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ChunkCessionFinalizeBcoTypes {
    CHUNK_CESSION_FINALIZE_BCO_REQUEST = '@@chunk-cession-finalize-bco/CHUNK_CESSION_FINALIZE_BCO_REQUEST',
    CHUNK_CESSION_FINALIZE_BCO_SUCCESS = '@@chunk-cession-finalize-bco/CHUNK_CESSION_FINALIZE_BCO_SUCCESS',
    CHUNK_CESSION_FINALIZE_BCO_ERROR = '@@chunk-cession-finalize-bco/CHUNK_CESSION_FINALIZE_BCO_ERROR',
    CHUNK_CESSION_FINALIZE_BCO_RESET = '@@chunk-cession-finalize-bco/CHUNK_CESSION_FINALIZE_BCO_RESET',
}

export interface ChunkCessionFinalizeBcoState extends BaseStoreState {
    readonly chunkCessionFinalizeBco?: ChunkCessionFinalizeResponse;
}
