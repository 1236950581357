import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { SessionInterestRateResponse } from 'model/session-interest-rates';
import { markError } from 'reducer/application/error/actions';
import { detailSessionInterestRateError, detailSessionInterestRateRequest, detailSessionInterestRateSuccess } from './actions';
import { DetailSessionInterestRateTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import sessionInterestRateApi from 'services/api/session-interest-rates-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailSessionInterestRate(action: ReturnType<typeof detailSessionInterestRateRequest>) {
    try {
        const result: AxiosResponse<SessionInterestRateResponse> = yield call(sessionInterestRateApi.detailSessionInterestRates, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailSessionInterestRateError(mapped));
            return;
        }
        yield put(detailSessionInterestRateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailSessionInterestRateError(mapped));
    }
}

function* watchDetailSessionInterestRate() {
    yield takeEvery(DetailSessionInterestRateTypes.DETAIL_SESSION_INTEREST_RATE_REQUEST, handleDetailSessionInterestRate);
}

function* detailSessionInterestRateSaga() {
    yield all([fork(watchDetailSessionInterestRate)]);
}

export default detailSessionInterestRateSaga;
