import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { ScdBatchData } from 'model/batch';
import { BatchStatus } from 'model/enums/batch-status';
import { useRouteMatch } from 'react-router-dom';

interface BatchMoreOptionsMenuItemsProps {
    selectedBatch: ScdBatchData;
    handleCloseMenu: () => void;
    setModalAction: (value: BatchModalActionOptions | undefined) => void;
    setIsOpenReportModal: (value: boolean) => void;
    handleReprocessBatch: (batchId: number | undefined) => void;
    handleOpenGenerateCnabModal: () => void;
    setModalCloseDetails: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch';

const BatchMoreOptionsMenuItems = (props: BatchMoreOptionsMenuItemsProps) => {
    const {
        selectedBatch,
        handleCloseMenu,
        setModalAction,
        setIsOpenReportModal,
        handleOpenGenerateCnabModal,
        handleReprocessBatch,
        setModalCloseDetails,
    } = props;

    const { url } = useRouteMatch();

    const handleModalMessageAction = (action: () => void) => () => {
        handleCloseMenu();
        action();
    };

    const handleModalCloseBatch = () => {
        handleCloseMenu();
        setModalCloseDetails(true);
    };

    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.menu.show-detail`} link={`${url}/${selectedBatch?.id}`} />
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.menu.show-detail-beta`} link={`${url}/${selectedBatch?.id}/details`} />
            {selectedBatch?.status &&
                (
                    {
                        OPEN: (
                            <>
                                <ModalActionMenuItem titleKey={`${I18N_PREFIX}.menu.close-batch`} onClick={handleModalCloseBatch} />
                            </>
                        ),
                        ERROR: (
                            <>
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.reprocess-batch`}
                                    onClick={handleModalMessageAction(() => handleReprocessBatch(selectedBatch?.id))}
                                />
                            </>
                        ),
                        CLOSED: (
                            <>
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.export-webcred`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.EXPORT_WEBCRED))}
                                />
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.report`}
                                    onClick={handleModalMessageAction(() => setIsOpenReportModal(true))}
                                />
                                <RedirectMenuItem
                                    show={!selectedBatch?.hasSessionInterestRate}
                                    titleKey={`${I18N_PREFIX}.menu.calculate-session-interest-rate`}
                                    link={`${url}/${selectedBatch?.id}/session-interest-rate/calculate`}
                                />
                                <RedirectMenuItem
                                    show={selectedBatch?.hasSessionInterestRate}
                                    titleKey={`${I18N_PREFIX}.menu.recalculate-session-interest-rate`}
                                    link={`${url}/${selectedBatch?.id}/session-interest-rate/recalculate`}
                                />

                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.hemera-document`}
                                    link={`${url}/${selectedBatch?.id}/hemera`}
                                />
                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.generate-cession`}
                                    link={`${url}/${selectedBatch?.id}/generate-cession`}
                                />
                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.endorsement`}
                                    link={`${url}/${selectedBatch?.id}/endorsement`}
                                />
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.send-ccb-documents`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.SEND_CCB_DOCUMENTS))}
                                />
                                <ModalActionMenuItem
                                    show={selectedBatch?.hasCnab}
                                    titleKey={`${I18N_PREFIX}.menu.send-cnab-document`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.SEND_CNAB_DOCUMENT))}
                                />
                                <ModalActionMenuItem
                                    show={!selectedBatch?.hasCnab}
                                    titleKey={`${I18N_PREFIX}.menu.generate-cnab`}
                                    onClick={handleModalMessageAction(() => handleOpenGenerateCnabModal())}
                                />
                            </>
                        ),
                        CONCLUDED: (
                            <>
                                <RedirectMenuItem
                                    show={!selectedBatch?.hasSessionInterestRate}
                                    titleKey={`${I18N_PREFIX}.menu.calculate-session-interest-rate`}
                                    link={`${url}/${selectedBatch?.id}/session-interest-rate/calculate`}
                                />
                                <RedirectMenuItem
                                    show={selectedBatch?.hasSessionInterestRate}
                                    titleKey={`${I18N_PREFIX}.menu.recalculate-session-interest-rate`}
                                    link={`${url}/${selectedBatch?.id}/session-interest-rate/recalculate`}
                                />
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.export-webcred`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.EXPORT_WEBCRED))}
                                />
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.report`}
                                    onClick={handleModalMessageAction(() => setIsOpenReportModal(true))}
                                />
                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.hemera-document`}
                                    link={`${url}/${selectedBatch?.id}/hemera`}
                                />
                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.generate-cession`}
                                    link={`${url}/${selectedBatch?.id}/generate-cession`}
                                />
                                <RedirectMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.endorsement`}
                                    link={`${url}/${selectedBatch?.id}/endorsement`}
                                />
                                <ModalActionMenuItem
                                    titleKey={`${I18N_PREFIX}.menu.send-ccb-documents`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.SEND_CCB_DOCUMENTS))}
                                />
                                <ModalActionMenuItem
                                    show={selectedBatch?.hasCnab}
                                    titleKey={`${I18N_PREFIX}.menu.send-cnab-document`}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.SEND_CNAB_DOCUMENT))}
                                />
                                <ModalActionMenuItem
                                    show={!selectedBatch?.hasCnab}
                                    titleKey={`${I18N_PREFIX}.menu.generate-cnab`}
                                    onClick={handleModalMessageAction(() => handleOpenGenerateCnabModal())}
                                />
                            </>
                        ),
                    } as Record<BatchStatus, React.ReactNode>
                )[selectedBatch?.status]}
        </>
    );
};

export default BatchMoreOptionsMenuItems;
