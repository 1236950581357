import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail.validation';

const VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_INSTITUTION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.paymentInstitution.required`
    }
}

export const validateManualDisbursementPaymentInstitution = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_INSTITUTION)

const VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_INSTITUTION_ACCOUNT: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.paymentInstitutionAccount.required`
    }
}

export const validateManualDisbursementPaymentInstitutionAccount = (value: string | undefined | null) => ValidationUtils.validate(value, VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_INSTITUTION_ACCOUNT)
