import { Token } from 'model/authentication';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum AuthenticationActionTypes {
    LOGIN_REQUEST = '@@authentication/LOGIN_REQUEST',
    LOGIN_SUCCESS = '@@authentication/LOGIN_SUCCESS',
    LOGIN_ERROR = '@@authentication/LOGIN_ERROR',
    LOGIN_RESET_STATE = '@@authentication/LOGIN_RESET_STATE',
}

export interface AuthenticationState extends BaseStoreState {
    readonly token?: Token;
}
