/*
   Utility method to clean json with escaped characters from mongoDB to make a json text parseable in javascript
 */
const tryToCleanMongoDBJson = (potentialJson?: string): string => {
    if (!potentialJson) return '';

    return potentialJson
        .replace(/\\n/g, '') // Removes escaped line breaks
        .replace(/\\"/g, '"') // Converts escaped quotes to regular quotes
        .replace(/\\t/g, '') // Removes escaped tabs
        .replace(/^"|"$/g, ''); // Removes the outer quotes that delimit the JSON string
};


const RequestLogUtils = {
    tryToCleanMongoDBJson
};

export default RequestLogUtils;