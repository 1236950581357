import { PaymentTransactionToCreateRepayment } from 'model/payment-transaction';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts.internal-repayment.validate';

const REPAYMENT_PAYMENT_TRANSACTION_ORIGINATOR_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.originator.required`
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.originator.required`
    }
}

export const validateRepaymentPaymentTransactionOriginator = (value: string | undefined | null) => ValidationUtils.validate(value, REPAYMENT_PAYMENT_TRANSACTION_ORIGINATOR_VALIDATION)

const REPAYMENT_PAYMENT_TRANSACTION_AMOUNT_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.amount.required`,
    },
};

export const validateRepaymentPaymentTransactionAmount = (value: number | undefined | null) =>
    ValidationUtils.validate(value, REPAYMENT_PAYMENT_TRANSACTION_AMOUNT_VALIDATION);


export const validateRepaymentPaymentTransaction = (value: PaymentTransactionToCreateRepayment) => {
    if (!value) {
        return false
    }

    return validateRepaymentPaymentTransactionAmount(value.amount).isValid &&
        validateRepaymentPaymentTransactionOriginator(value.originatorName).isValid;
    
}