import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ManualDisbursementPaymentTransactionState, ManualDisbursementPaymentTransactionTypes } from 'reducer/payment-transaction/manual-disbursement-payment-transaction/types';

const initialState: ManualDisbursementPaymentTransactionState = {
    status: HttpRequestStatus.NOOP,
    paymentTransactionSimple: undefined,
    error: undefined,
};

const reducer: Reducer<ManualDisbursementPaymentTransactionState> = (state = initialState, action): ManualDisbursementPaymentTransactionState => {
    switch (action.type) {
        case ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentTransactionSimple: action.payload, error: undefined };
        }
        case ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as manualDisbursementPaymentTransactionReducer };
