import ContextRibbon from 'components/context-ribbon/context-ribbon';
import Loading from 'components/loading/loading';
import { BatchStatus } from 'model/enums/batch-status';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { batchOutputEndorsementRequest, batchOutputEndorsementResetState } from 'reducer/batch/batch-output-endorsement/action';
import { detailBatchRequest, detailBatchResetState } from 'reducer/batch/detail-batch/actions';
import {
    useBatchOutputEndorsementState,
    useDetailBatchState,
    useProvisionalLimelightAuthorizationSendBrackings,
    useRootDispatch,
} from 'reducer/hooks';

import ActionButton from 'components/buttons/action-button/action-button';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import LimelightHeader from 'features/limelight/components/limelight-header/limelight-header';
import LimeligthListOperationsHeader from 'features/limelight/components/limelight-list-operations-header/limelight-list-operations.-header';
import LimelightOperationList from 'features/limelight/components/limelight-list-operations/limelight-list-operations';
import LimelightModal, { ProcessSteps } from 'features/limelight/components/limelight-modal/limelight-modal';
import SelectMultipleFiles from 'features/limelight/components/upload-file/upload-file';
import SendBackingsFailedFilesModal from 'features/limelight/send-backings/components/send-backings-failed-files-modal/send-backings-failed-files-modal';
import {
    FileUploaded,
    HemeraAuthorizationForSendBackingRequest,
    LimelightListRequest,
} from 'features/limelight/send-backings/domain/models';
import { useBindDocumentsToOperations } from 'features/limelight/send-backings/hooks/use-bind-documents-to-operations';
import { useProcessBackings } from 'features/limelight/send-backings/hooks/use-process-backings';
import { limelightListOperationsRequest } from 'reducer/provisional-limelight/provisional-limelight-list/action';
import { limelightAuthorizationSendBackingRequest } from 'reducer/provisional-limelight/provisional-limelight-send/action';
import './provisional-hemera-backings.scss';

type ProvisionalBatchEndorsementToHemeraProps = RouteComponentProps<{ batchId: string }>;

const I18N_PREFIX = 'pages.scd.provisional-limelight';
const sizeByScroll = 7;

const ProvisionalHemeraBackings = (props: ProvisionalBatchEndorsementToHemeraProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();
    const [steps, setSteps] = useState<ProcessSteps>('loadingAuthorization');
    const [batchId] = useState<number>(Number(props.match.params.batchId));
    const { operations, inferDocuments, failedFiles, clearFailedFiles, removeBind, overrideBind } = useBindDocumentsToOperations({});
    const { status } = useProvisionalLimelightAuthorizationSendBrackings();

    const processHook = useProcessBackings({ operations });
    const [modalSend, setModalSend] = useState<boolean>(false);

    const handleLimelightModal = () => {
        if (!batch?.id || !outputEndorsement) return;

        const request: HemeraAuthorizationForSendBackingRequest = {
            batchId: batch?.id,
            outputId: outputEndorsement?.id,
        };
        if (status === HttpRequestStatus.NOOP) {
            dispatch(limelightAuthorizationSendBackingRequest(request));
        }
        setModalSend(true);
    };

    const handleChangeFile = (file: FileList) => {
        const files: FileUploaded[] = Object.values(file).map((document: File) => ({ fileName: document.name, fileData: document }));
        inferDocuments(files);
    };

    const { batch, status: batchStatus } = useDetailBatchState();
    const { outputEndorsement, status: endorsementStatus } = useBatchOutputEndorsementState();

    useEffect(() => {
        dispatch(detailBatchRequest(batchId));
        dispatch(batchOutputEndorsementRequest(batchId));
    }, [dispatch, batchId]);

    useEffect(() => {
        if (!batch?.id || !outputEndorsement) return;

        const request: LimelightListRequest = {
            batchId: batch?.id,
            outputId: outputEndorsement?.id,
        };
        dispatch(limelightListOperationsRequest(request));
    }, [dispatch, outputEndorsement, batch]);

    useEffect(() => {
        return () => {
            dispatch(detailBatchResetState());
            dispatch(batchOutputEndorsementResetState());
        };
    }, [dispatch]);

    const resetSetpsOnOperationsChange = useCallback(() => {
        setSteps(state => {
            if (state !== 'loadingAuthorization') {
                return 'loadingAuthorization';
            }
            return state;
        });
    }, [setSteps]);

    useEffect(() => {
        resetSetpsOnOperationsChange();
    }, [operations, resetSetpsOnOperationsChange]);

    const loading = endorsementStatus === HttpRequestStatus.ON_GOING || batchStatus === HttpRequestStatus.ON_GOING;

    const size: boolean = operations.length > sizeByScroll;

    return (
        <main className="provisional-limelight">
            <ContextRibbon />
            {loading ? (
                <Loading />
            ) : (
                <>
                    <section className="provisional-limelight__container">
                        {outputEndorsement && (batch?.status === BatchStatus.CLOSED || BatchStatus.CONCLUDED) ? (
                            <>
                                <LimelightHeader batch={batch} outputEndorsement={outputEndorsement} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <SelectMultipleFiles handleChange={handleChangeFile} />
                                    <ActionButton
                                        onClick={handleLimelightModal}
                                        label={t(`${I18N_PREFIX}.buttons.process`)}
                                        disabled={operations.every(it => !it.isBinded)}
                                    />
                                </div>
                                <div className="provisional-limelight__table">
                                    <table className="page-container--table">
                                        <thead>
                                            <LimeligthListOperationsHeader size={size} />
                                        </thead>
                                        <tbody>
                                            <LimelightOperationList
                                                operations={operations}
                                                removeBind={removeBind}
                                                overrideBind={overrideBind}
                                            />
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (
                            <ModalMessageLegacy
                                title={t(`${I18N_PREFIX}.not-found.title`)}
                                message={t(`${I18N_PREFIX}.not-found.message`)}
                                onClose={history.goBack}
                                onAction={history.goBack}
                                standardButtonLabel={t(`${I18N_PREFIX}.buttons.back`)}
                            />
                        )}
                        {modalSend && (
                            <LimelightModal
                                title={t(`${I18N_PREFIX}.not-found.title`)}
                                onClose={() => setModalSend(false)}
                                onCancel={() => setModalSend(false)}
                                steps={steps}
                                setSteps={setSteps}
                                processHook={processHook}
                            />
                        )}
                        <SendBackingsFailedFilesModal failedFiles={failedFiles} clearFailedFiles={clearFailedFiles} />
                    </section>
                </>
            )}
        </main>
    );
};

export default ProvisionalHemeraBackings;
