import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionData } from 'model/payment-transaction';
import { PaymentTransactionFilterPageable } from 'model/reducers';
import { ListPaymentTransactionsTypes } from 'reducer/payment-transaction/list-payment-transactions/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listPaymentTransactionsRequest = (request: PaymentTransactionFilterPageable) =>
    action(ListPaymentTransactionsTypes.LIST_PAYMENT_TRANSACTIONS_REQUEST, request);
export const listPaymentTransactionsSuccess = (paymentTransactions: Page<ScdPaymentTransactionData>) =>
    action(ListPaymentTransactionsTypes.LIST_PAYMENT_TRANSACTIONS_SUCCESS, paymentTransactions);
export const listPaymentTransactionsError = (error: GiroWebappError) =>
    action(ListPaymentTransactionsTypes.LIST_PAYMENT_TRANSACTIONS_ERROR, error);
export const listPaymentTransactionsResetState = () => action(ListPaymentTransactionsTypes.LIST_PAYMENT_TRANSACTIONS_RESET);
