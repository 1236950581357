import { ScdCompanyGroup } from 'model/company-group';
import { GiroWebappError } from 'model/error';
import { DetailCompanyGroupTypes } from 'reducer/company-group/detail-company-group/types';
import { action } from 'typesafe-actions';

export const detailCompanyGroupRequest = (companyGroupId: string) =>
    action(DetailCompanyGroupTypes.DETAIL_COMPANY_GROUP_REQUEST, companyGroupId);
export const detailCompanyGroupSuccess = (companyGroup: ScdCompanyGroup) =>
    action(DetailCompanyGroupTypes.DETAIL_COMPANY_GROUP_SUCCESS, companyGroup);
export const detailCompanyGroupError = (error: GiroWebappError) => action(DetailCompanyGroupTypes.DETAIL_COMPANY_GROUP_ERROR, error);
export const detailCompanyGroupResetState = () => action(DetailCompanyGroupTypes.DETAIL_COMPANY_GROUP_RESET);
