import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { action } from 'typesafe-actions';
import { SearchTechnicalSuppliersTypes } from './types';
import { SearchRequest } from 'model/reducers';
import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchTechnicalSuppliersRequest = (request: SearchRequest) =>
    action(SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_REQUEST, request);
export const searchTechnicalSuppliersSuccess = (technicalSuppliers: Page<ScdTechnicalSupplier>) =>
    action(SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_SUCCESS, technicalSuppliers);
export const searchTechnicalSuppliersError = (error: GiroWebappError) =>
    action(SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_ERROR, error);
export const searchTechnicalSuppliersResetState = () => action(SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_RESET_STATE);
