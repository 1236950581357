import clsx from 'clsx';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import _ from 'lodash';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import {
    useExternalTransfersPaymentInstitutionAccountState,
    useResumePaymentInstitutionAccountState,
    useRootDispatch,
} from 'reducer/hooks';
import {
    externalTransfersPaymentInstitutionsAccountsRequest,
    externalTransfersPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/actions';
import TransfeeraErrorResponse from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/errors/transfeera-error-response';
import TransfeeraTransfersDetailsModal from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-details-modal/transfeera-transfers-details-modal';
import TransfeeraTransfersFiltersButtons from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-filters-buttons/transfeera-transfers-filters-buttons';
import TransfeeraTransfersFiltersModal, {
    TransfeeraExternalTransfersFilterType,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-filters-modal/transfeera-transfers-filters-modal';
import TransfeeraTransfersHeader from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-header/transfeera-transfers-header';
import TransfeeraTransfersList from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-list/transfeera-transfers-list';
import {
    TransfeeraExternalTransfersData,
    TransfeeraExternalTransfersFilterPageable,
    TransfeeraExternalTransfersFilterRequest,
    TransfeeraTransfersPageable,
    defaultTransfeeraExternalTransfersFilter,
} from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import TransfersError from 'shared/external/payment-accounts-strategy/transfers/components/transfers-error/transfers-error';
import { ExternalTransfersProps } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';

import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import useTransfeeraInfiniteScroll from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/components/transfeera-transfers-infinite-scroll/transfeera-transfers-infinite-scroll';
import useTransfeeraTransfersFilterChips from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/hooks/use-transfeera-transfers-filter-chips';
import './transfeera-transfers.scss';

const I18N_PREFIX = 'payment-accounts-strategy';

const TransfeeraTransfers = (props: ExternalTransfersProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const [filterActive, setFilterActive] = useState<TransfeeraExternalTransfersFilterRequest>(defaultTransfeeraExternalTransfersFilter);
    const [modalFilter, setModalFilter] = useState<TransfeeraExternalTransfersFilterType | undefined>(undefined);
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);
    const [page, setPage] = useState<number>(0);
    const [externalTransference, setExternalTransference] = useState<TransfeeraExternalTransfersData | undefined>(undefined);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const { isTransfeeraTransfersChipsActive, transfeeraTransfersFilterChips } = useTransfeeraTransfersFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const { resumedPaymentInstitutionAccount } = useResumePaymentInstitutionAccountState();
    const { externalTransfers, error } = useExternalTransfersPaymentInstitutionAccountState();

    const dispatch = useRootDispatch();

    const filterPageable = useCallback((): TransfeeraExternalTransfersFilterPageable => {
        const pageable: TransfeeraTransfersPageable = { page };
        const filter: TransfeeraExternalTransfersFilterRequest = filterActive;

        return {
            filter,
            pageable,
            accountId: paymentInstitutionAccountId,
        };
    }, [filterActive, page, paymentInstitutionAccountId]);

    const handleExternalTransfersRequest = useCallback(() => {
        if (!paymentInstitutionAccountId) return;
        dispatch(externalTransfersPaymentInstitutionsAccountsRequest(filterPageable()));
    }, [dispatch, filterPageable, paymentInstitutionAccountId]);

    const {
        InfiniteScrollButton,
        items: externalTransfersItems,
        resetInfiniteScrollListing,
        handleRefresh,
        isLoading,
    } = useTransfeeraInfiniteScroll({
        handlePageChange,
        statusSelector: (state: IRootState) => state.getExternalTransfers.status,
        dataSelector: (state: IRootState) => state.getExternalTransfers.externalTransfers,
        handleAction: handleExternalTransfersRequest,
        setPage,
        currentPage: page,
    });

    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultTransfeeraExternalTransfersFilter);
    };

    useEffect(() => {
        if (_.isEqual(filterActive, defaultTransfeeraExternalTransfersFilter)) return;

        resetInfiniteScrollListing();
    }, [resetInfiniteScrollListing, filterActive]);

    useEffect(() => {
        return () => {
            dispatch(externalTransfersPaymentInstitutionsAccountsResetState());
        };
    }, [dispatch]);

    const hasError: boolean = (!!error || !externalTransfers?.isSuccessful) && !isLoading;

    return (
        <main className="transfeera-transfers">
            <ContextRibbon />
            <section className="transfeera-transfers__container">
                <header className="transfeera-transfers__header">
                    <div className="transfeera-transfers__header-container">
                        <div className="transfeera-transfers__header-container--align">
                            <h2 className="transfeera-transfers__header-container--title">{resumedPaymentInstitutionAccount?.name}</h2>
                            <RefreshButton onClick={handleRefresh} />
                        </div>
                        <div className="transfeera-transfers__header-account-infos">
                            <span className="transfeera-transfers__header-account-infos--item">
                                {t(`${I18N_PREFIX}.type.${resumedPaymentInstitutionAccount?.type}`)}
                            </span>
                            <span className="transfeera-transfers__header-account-infos--item">
                                {resumedPaymentInstitutionAccount?.technicalSupplierName}
                            </span>
                            <span
                                className={clsx('transfeera-transfers__header-account-infos--status', {
                                    ACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.ACTIVE,
                                    INACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.INACTIVE,
                                })}
                            >
                                {t(`${I18N_PREFIX}.status.${resumedPaymentInstitutionAccount?.status}`).toUpperCase()}
                            </span>
                        </div>
                    </div>

                    <div className="transfeera-transfers__header-buttons-content">
                        <TransfeeraTransfersFiltersButtons
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                        />
                    </div>
                </header>
                <ChipFilterActive filters={transfeeraTransfersFilterChips} />

                {hasError ? (
                    <TransfersError
                        handleRetry={handleRefresh}
                        errorComponent={<TransfeeraErrorResponse error={error} externalTransfers={externalTransfers} />}
                    />
                ) : (
                    <div className="transfeera-transfers__table">
                        <table
                            className={clsx('page-container--table', {
                                EMPTY_COLLECTION: !externalTransfersItems.length,
                                CHIPS_ACTIVE: isTransfeeraTransfersChipsActive,
                            })}
                        >
                            <thead>
                                <TransfeeraTransfersHeader />
                            </thead>
                            <tbody>
                                <TransfeeraTransfersList
                                    isLoading={isLoading}
                                    externalTransfersItems={externalTransfersItems}
                                    setExternalTransference={setExternalTransference}
                                />
                            </tbody>
                        </table>
                        <InfiniteScrollButton />
                    </div>
                )}
                {modalFilter && (
                    <TransfeeraTransfersFiltersModal
                        filterActive={filterActive}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        openModal={openModalFilter}
                        setFilterActive={setFilterActive}
                        setPage={setPage}
                        title={t(`${I18N_PREFIX}.transfeera.external-transfers.filters.${modalFilter}`)}
                        resetInfiniteScrollListing={resetInfiniteScrollListing}
                    />
                )}
                {externalTransference && (
                    <TransfeeraTransfersDetailsModal
                        externalTransference={externalTransference}
                        onClose={() => setExternalTransference(undefined)}
                    />
                )}
            </section>
        </main>
    );
};

export default TransfeeraTransfers;
