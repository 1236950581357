import { ScdBankerList } from 'model/banker';
import { GiroWebappError } from 'model/error';
import { ListBankersTypes } from './types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { Pageable } from 'services/pageable';

export const listBankersRequest = (request: Pageable) => action(ListBankersTypes.LIST_BANKERS_REQUEST, request);
export const listBankersSuccess = (bankers: Page<ScdBankerList>) => action(ListBankersTypes.LIST_BANKERS_SUCCESS, bankers);
export const listBankersError = (error: GiroWebappError) => action(ListBankersTypes.LIST_BANKERS_ERROR, error);
export const listBankersResetState = () => action(ListBankersTypes.LIST_BANKERS_RESET_STATE);
