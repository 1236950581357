import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';
import {
    processCessionOperationBcoError,
    processCessionOperationBcoRequest,
    processCessionOperationBcoSuccess,
} from 'reducer-bco/batch-output/process-cession-operation/action';
import { CessionToSimpleDTO } from 'model/bco/batch-output-cession-integration-webcred';
import { ProcessCessionOperationBcoTypes } from 'reducer-bco/batch-output/process-cession-operation/types';

function* handleProcessCessionOperationBco(action: ReturnType<typeof processCessionOperationBcoRequest>) {
    try {
        const result: AxiosResponse<CessionToSimpleDTO> = yield call(batchOutputBcoApi.processCessionOperation, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(processCessionOperationBcoError(mapped));
            return;
        }
        yield put(processCessionOperationBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(processCessionOperationBcoError(mapped));
    }
}

function* watchProcessCessionOperationBcoSaga() {
    yield takeEvery(ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_REQUEST, handleProcessCessionOperationBco);
}

function* ProcessCessionOperationBcoSaga() {
    yield all([fork(watchProcessCessionOperationBcoSaga)]);
}

export default ProcessCessionOperationBcoSaga;
