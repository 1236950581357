import { ScdCompany } from 'model/company';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum ListCompaniesTypes {
    LIST_COMPANIES_REQUEST = '@@companies-list/LIST_COMPANIES_REQUEST',
    LIST_COMPANIES_SUCCESS = '@@companies-list/LIST_COMPANIES_SUCCESS',
    LIST_COMPANIES_ERROR = '@@companies-list/LIST_COMPANIES_ERROR',
    LIST_COMPANIES_RESET_STATE = '@@companies-list/LIST_COMPANIES_RESET_STATE',
}

export interface ListCompaniesState extends BaseStoreState {
    readonly companies?: Page<ScdCompany>;
}
