import { GiroWebappError } from 'model/error';
import { ScdOriginator, ScdOriginatorSimple } from 'model/originator';
import { CreateOriginatorTypes } from 'reducer/originator/create-originator/types';
import { action } from 'typesafe-actions';

export const createOriginatorRequest = (originator: ScdOriginator) => action(CreateOriginatorTypes.CREATE_ORIGINATOR_REQUEST, originator);
export const createOriginatorSuccess = (createdOriginator: ScdOriginatorSimple) =>
    action(CreateOriginatorTypes.CREATE_ORIGINATOR_SUCCESS, createdOriginator);
export const createOriginatorError = (error: GiroWebappError) => action(CreateOriginatorTypes.CREATE_ORIGINATOR_ERROR, error);
export const createOriginatorResetState = () => action(CreateOriginatorTypes.CREATE_ORIGINATOR_RESET_STATE);
