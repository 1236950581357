import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import _ from 'lodash';
import { ChangeEvent } from 'react';

type Base = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'value' | 'onChange'>;

export interface TextAreaInputProps extends Base {
    value?: string;
    placeholder?: string;
    onChange?: (value?: string) => void;
}

type BaseValidation = Omit<ValidInputProps<string>, 'children'>;

export type ValidTextAreaInputProps = TextAreaInputProps & BaseValidation;

const ValidTextArea = (props: ValidTextAreaInputProps) => {
    const { value: propsValue, label, placeholder, onChange, showValidation, validate, disabled } = props;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!onChange) return;

        const _value = event?.target?.value;

        if (_.isEmpty(_.trim(_value)) && onChange) {
            onChange(undefined);
            return;
        }

        onChange(_value);
    };

    return (
        <ValidInput
            showValidation={showValidation}
            value={propsValue}
            validate={validate}
            className={clsx('base-input', { dirty: propsValue })}
        >
            {({ error }) => (
                <TextField
                    label={label}
                    placeholder={placeholder}
                    multiline
                    rows={5}
                    variant="filled"
                    value={propsValue}
                    onChange={handleChange}
                    error={error}
                    disabled={disabled}
                />
            )}
        </ValidInput>
    );
};

export default ValidTextArea;
