import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateIssuerPolicyState, CreateIssuerPolicyTypes } from 'reducer/issuer-policy/create-policy/types';
import { Reducer } from 'redux';

export const initialState: CreateIssuerPolicyState = {
    status: HttpRequestStatus.NOOP,
    issuerCreatePolicy: undefined,
    error: undefined,
};

const reducer: Reducer<CreateIssuerPolicyState> = (state = initialState, action): CreateIssuerPolicyState => {
    switch (action.type) {
        case CreateIssuerPolicyTypes.ISSUER_CREATE_POLICY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case CreateIssuerPolicyTypes.ISSUER_CREATE_POLICY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuerCreatePolicy: action.payload, error: undefined };
        }
        case CreateIssuerPolicyTypes.ISSUER_CREATE_POLICY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateIssuerPolicyTypes.ISSUER_CREATE_POLICY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createIssuerPolicyReducer };
