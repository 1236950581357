import { BaseStoreState } from 'reducer/base-store-state';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchPaymentFavoredTypes {
    SEARCH_PAYMENT_FAVORED_REQUEST = '@@payment-favored-search/SEARCH_PAYMENT_FAVORED_REQUEST',
    SEARCH_PAYMENT_FAVORED_SUCCESS = '@@payment-favored-search/SEARCH_PAYMENT_FAVORED_SUCCESS',
    SEARCH_PAYMENT_FAVORED_ERROR = '@@payment-favored-search/SEARCH_PAYMENT_FAVORED_ERROR',
    SEARCH_PAYMENT_FAVORED_RESET = '@@payment-favored-search/SEARCH_PAYMENT_FAVORED_RESET',
}

export interface SearchPaymentFavoredState extends BaseStoreState {
    readonly paymentFavored?: Page<ScdPaymentFavoredSearch>;
}
