import { ChunkStreamRetryRequest, ChunkStreamSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { ChunkStreamRetryBcoTypes } from 'reducer-bco/batch-output/retry-chunk-stream/types';
import { action } from 'typesafe-actions';

export const chunkStreamRetryBcoRequest = (request: ChunkStreamRetryRequest) =>
    action(ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_REQUEST, request);
export const chunkStreamRetryBcoSuccess = (chunkStreamRetryBco: ChunkStreamSimpleResponse) =>
    action(ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_SUCCESS, chunkStreamRetryBco);
export const chunkStreamRetryBcoError = (error: GiroWebappError) => action(ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_ERROR, error);
export const chunkStreamRetryBcoResetState = () => action(ChunkStreamRetryBcoTypes.CHUNK_STREAM_RETRY_BCO_RESET);
