import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationsData } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsListError,
    importedOperationsListRequest,
    importedOperationsListSuccess,
} from 'reducer/imported-operation/list-operations/actions';
import { ImportedOperationsListTypes } from 'reducer/imported-operation/list-operations/types';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsList(action: ReturnType<typeof importedOperationsListRequest>) {
    try {
        const result: AxiosResponse<Page<ScdImportedOperationsData>> = yield call(importedOperationApi.listOperations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsListError(mapped));
            return;
        }
        yield put(importedOperationsListSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsListError(mapped));
    }
}

function* watchImportedOperationsList() {
    yield takeLatest(ImportedOperationsListTypes.IMPORTED_OPERATIONS_LIST_REQUEST, handleImportedOperationsList);
}

function* importedOperationsListSaga() {
    yield all([fork(watchImportedOperationsList)]);
}

export default importedOperationsListSaga;
