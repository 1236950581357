import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.fare-amount-report.validate'

const FARE_AMOUNT_REPORT_DATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.required`
    },

}

export const validateFareAmountReportDate = (value: string | undefined | null) => ValidationUtils.validate(value, FARE_AMOUNT_REPORT_DATE_VALIDATION)

const FARE_AMOUNT_REPORT_ORIGINATOR_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.required`
    },
}

export const validateFareAmountReportOriginator = (value: string | undefined | null) => ValidationUtils.validate(value, FARE_AMOUNT_REPORT_ORIGINATOR_VALIDATION)