export enum CompanyItemType {
    LEVEL_ONE = 'LEVEL_ONE',
    LEVEL_TWO = 'LEVEL_TWO',
    LEVEL_THREE = 'LEVEL_THREE',
}

export enum CompanyItemStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}
