import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionAccountDetail } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { detailPaymentInstitutionsAccountsRequest, detailPaymentInstitutionsAccountsError, detailPaymentInstitutionsAccountsSuccess } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/actions';
import { DetailPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/detail-payment-institution-account/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';

import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailPaymentInstitutionsAccounts(action: ReturnType<typeof detailPaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<PaymentInstitutionAccountDetail> = yield call(
            paymentInstitutionsAccountsApi.detailPaymentInstitutionAccount,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailPaymentInstitutionsAccountsError(mapped));
            return;
        }
        yield put(detailPaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailPaymentInstitutionsAccountsError(mapped));
    }
}

function* watchDetailPaymentInstitutionsAccounts() {
    yield takeEvery(DetailPaymentInstitutionsAccountsTypes.DETAIL_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleDetailPaymentInstitutionsAccounts);
}

function* detailPaymentInstitutionsAccountsSaga() {
    yield all([fork(watchDetailPaymentInstitutionsAccounts)]);
}

export default detailPaymentInstitutionsAccountsSaga;
