import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationReexport } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsReexportAllError,
    importedOperationsReexportAllRequest,
    importedOperationsReexportAllSuccess,
} from 'reducer/imported-operation/reexport-all/actions';
import { ImportedOperationsReexportAllTypes } from 'reducer/imported-operation/reexport-all/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsReexportAll(action: ReturnType<typeof importedOperationsReexportAllRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationReexport[]> = yield call(importedOperationApi.reexportAll, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsReexportAllError(mapped));
            return;
        }
        yield put(importedOperationsReexportAllSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsReexportAllError(mapped));
    }
}

function* watchImportedOperationsReexportAll() {
    yield takeLeading(ImportedOperationsReexportAllTypes.IMPORTED_OPERATIONS_REEXPORT_ALL_REQUEST, handleImportedOperationsReexportAll);
}

function* importedOperationsReexportAllSaga() {
    yield all([fork(watchImportedOperationsReexportAll)]);
}

export default importedOperationsReexportAllSaga;
