import { AxiosResponse } from 'axios';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { api } from 'services/api/api';
import { SearchPlatform } from 'model/platform';
import { SearchPlatformRequest } from 'model/reducers';


export const platform = () => {
    const searchPlatforms = (request: SearchPlatformRequest): Promise<AxiosResponse<Page<SearchPlatform>>> => {
        const DEFAULT_SORT = 'name,asc';

        return api.get<Page<SearchPlatform>>(`${BASE_URL_ADMIN}/platforms/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable, sort: DEFAULT_SORT },
        })
    }

    return {
        searchPlatforms,
    }
}

export default platform();