import { Grid } from '@material-ui/core';
import DateFormatter from 'components/formatter/date/date-formatter';
import RgFormatter from 'components/formatter/rg/rg-formatter';
import BaseOperationCard from 'entities/ccb-operation/ccb-operation-detail/components/cards/base-operation-card/base-operation-card';
import OperationReadOnlyTextField from 'entities/ccb-operation/ccb-operation-detail/components/operation-read-only-text-field/operation-read-only-text-field';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';

interface DocumentOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.document';

const DocumentOperationCard = (props: DocumentOperationCardProps) => {
    const {
        operation: { document },
    } = props;

    const { t } = useTranslation();

    return (
        <BaseOperationCard title={t(`${I18N_PREFIX}.title`)} isOpenable>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.rgIdentification`)}
                    value={document?.rgIdentification ? <RgFormatter value={document?.rgIdentification} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.dateBirth`)}
                    value={<DateFormatter date={document?.dateBirth} />}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.agencyIssuing`)} value={document?.agencyIssuing} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.agencyIssuingAcronym`)}
                    value={document?.agencyIssuingAcronym}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField
                    label={t(`${I18N_PREFIX}.fields.issuingDate`)}
                    value={document?.issuingDate ? <DateFormatter date={document?.issuingDate} /> : '-'}
                />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.placeBirth`)} value={document?.placeBirth} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.motherName`)} value={document?.motherName} />
            </Grid>
            <Grid item xs={6}>
                <OperationReadOnlyTextField label={t(`${I18N_PREFIX}.fields.fatherName`)} value={document?.fatherName} />
            </Grid>
        </BaseOperationCard>
    );
};

export default DocumentOperationCard;
