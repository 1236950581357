import { ScdCnabDownload } from 'model/cnab';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CnabBatchReportTypes {
    CNAB_BATCH_REPORT_REQUEST = '@@cnab-batch-report/CNAB_BATCH_REPORT_REQUEST',
    CNAB_BATCH_REPORT_SUCCESS = '@@cnab-batch-report/CNAB_BATCH_REPORT_SUCCESS',
    CNAB_BATCH_REPORT_ERROR = '@@cnab-batch-report/CNAB_BATCH_REPORT_ERROR',
    CNAB_BATCH_REPORT_RESET = '@@cnab-batch-report/CNAB_BATCH_REPORT_RESET',
}

export interface CnabBatchReportState extends BaseStoreState {
    readonly cnabBatchReport?: ScdCnabDownload;
}
