import { ScdImportedOperationSimple } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum RetryProcessAutomaticPaymentImportedOperationTypes {
    RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_REQUEST = '@@retry-process-automatic-payment-imported-operation/RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_REQUEST',
    RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_SUCCESS = '@@retry-process-automatic-payment-imported-operation/RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_SUCCESS',
    RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_ERROR = '@@retry-process-automatic-payment-imported-operation/RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_ERROR',
    RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_RESET = '@@retry-process-automatic-payment-imported-operation/RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_RESET',
}

export interface RetryProcessAutomaticPaymentImportedOperationState extends BaseStoreState {
    readonly importedOperationSimple?: ScdImportedOperationSimple;
}
