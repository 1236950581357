import { AuthorizationScheme } from 'model/enums/authorization-scheme';
import { IssuerPolicyStatus } from 'model/enums/issuer-policy-status';
import { IssuerPolicyType } from 'model/enums/issuer-policy-type';
import { Platform } from 'model/platform';


export interface IssuerListPolicies {
    id: number;
    name: string;
    status: IssuerPolicyStatus;
    type: IssuerPolicyType;
    issuerIntegrationId: number;
}

export interface IssuerPolicyCreateUpdate {
    id?: number;
    issuerPolicyId?: number;
    name?: string;
    status?: IssuerPolicyStatus;
    type?: IssuerPolicyType;
    platformId?: number;
    platformName?: string;
}

export const defaultIssuerPolicy: IssuerPolicyCreateUpdate = {
    id: undefined,
    issuerPolicyId: undefined,
    name: undefined,
    status: undefined,
    type: undefined,
    platformId: undefined,
    platformName: undefined,
}

export interface IssuerPolicySimple {
    id: number;
}

export interface IssuerPolicyCreateRequest {
    issuerId: number;
    policy: IssuerPolicyCreateUpdate
}

export interface IssuerPolicyDetailRequest {
    issuerId: number;
    id: number;
}

export interface IssuerPolicyDetail {
    id?: number
    name?: string;
    status?: IssuerPolicyStatus
    type?: IssuerPolicyType;
    issuerIntegration?: IssuerPolicyIntegration;
    platform?: Platform;
}

export interface IssuerPolicyIntegration {
    id?: number;
    authorizationToken?: string;
    authorizationScheme?: AuthorizationScheme;
}

export interface IssuerPolicyUpdateRequest {
    issuerId: number;
    id: number;
    policy: IssuerPolicyCreateUpdate;
}

export interface IssuerPolicyUpdate {
    id: number;
    name: string;
    status: IssuerPolicyStatus;
}

export interface IssuerPolicyIntegrationCreate {
    authorizationToken?: string;
    authorizationScheme?: AuthorizationScheme;
}

export const defaultIntegrationPolicy: IssuerPolicyIntegrationCreate = {
    authorizationScheme: undefined,
    authorizationToken: undefined,
}

export interface IssuerPolicyIntegrationSimple {
    id: number;
}

export interface IssuerPolicyIntegrationRequest {
    id?: number;
    policyId: number;
    authorizationToken?: string;
    authorizationScheme?: AuthorizationScheme;
}

export interface IssuerPolicyToRecoverFilter {
    type?: IssuerPolicyType;
    platformId?: number;
}

export interface IssuerPoliciesList {
    issuerId: number;
    issuerPolicies: IssuerPoliciesToResumeAllActive[];
}

export interface IssuerPoliciesToResumeAllActive {
    id: number;
    name: string;
    type: IssuerPolicyType;
    platformName?: string;
    isIntegrationConfigured: boolean;
}

export interface IssuerPolicyResumeAllActivesRequest {
    issuerId: number;
    filter: IssuerPolicyToRecoverFilter;
}