import { ScdBatchDetail } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailBatchTypes {
    DETAIL_BATCH_REQUEST = '@@batch-detail/DETAIL_BATCH_REQUEST',
    DETAIL_BATCH_SUCCESS = '@@batch-detail/DETAIL_BATCH_SUCCESS',
    DETAIL_BATCH_ERROR = '@@batch-detail/DETAIL_BATCH_ERROR',
    DETAIL_BATCH_RESET = '@@batch-detail/DETAIL_BATCH_RESET',
}

export interface DetailBatchState extends BaseStoreState {
    readonly batch?: ScdBatchDetail;
}
