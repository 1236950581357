import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramGet } from 'model/program';
import { detailProgramRequest, detailProgramError, detailProgramSuccess } from 'reducer/program/detail-program/actions';
import { DetailProgramTypes } from 'reducer/program/detail-program/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailProgram(action: ReturnType<typeof detailProgramRequest>) {
    try {
        const result: AxiosResponse<ScdProgramGet> = yield call(programApi.detailProgram, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailProgramError(mapped));
            return;
        }
        yield put(detailProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailProgramError(mapped));
    }
}

function* watchDetailProgram() {
    yield takeEvery(DetailProgramTypes.DETAIL_PROGRAM_REQUEST, handleDetailProgram);
}

function* detailProgramSaga() {
    yield all([fork(watchDetailProgram)]);
}

export default detailProgramSaga;
