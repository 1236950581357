import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchDocumentDownloadTypes {
    BATCH_DOCUMENT_DOWNLOAD_REQUEST = '@@batch-document-download/BATCH_DOCUMENT_DOWNLOAD_REQUEST',
    BATCH_DOCUMENT_DOWNLOAD_SUCCESS = '@@batch-document-download/BATCH_DOCUMENT_DOWNLOAD_SUCCESS',
    BATCH_DOCUMENT_DOWNLOAD_ERROR = '@@batch-document-download/BATCH_DOCUMENT_DOWNLOAD_ERROR',
    BATCH_DOCUMENT_DOWNLOAD_RESET = '@@batch-document-download/BATCH_DOCUMENT_DOWNLOAD_RESET',
}

export interface BatchDocumentDownloadState extends BaseStoreState {
    readonly batchDocumentDownload?: Uint8Array;
}