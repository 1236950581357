import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { IssuerListPolicies } from 'model/issuer-policy';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';


interface PoliciesHeaderProps {
    policies: IssuerListPolicies[];
    handleSort: (property: keyof IssuerListPolicies, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort: boolean;
}

const I18N_PREFIX = 'pages.scd.issuer-policy.table';

const PoliciesHeader = (props: PoliciesHeaderProps) => {
    const { t } = useTranslation();
    const { policies, handleSort, sortedProperty, sortOrder, disabledSort } = props;


    const mapSortable = (_property: keyof IssuerListPolicies) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (policies && policies.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.type`).toUpperCase()} sortable={mapSortable('type')} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`).toUpperCase()} sortable={mapSortable('status')} />
        </tr>
    );
}

export default PoliciesHeader;