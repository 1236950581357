import {
    CURRENCY_FORMATTER,
    CURRENCY_FORMATTER_PREFIXED,
    PERCENTAGE_FORMATTER,
    PERCENTAGE_FORMATTER_WITH_SIX_DECIMALS,
    PERCENTAGE_FORMATTER_WITH_TWELVE_DECIMALS,
} from 'components/formatter/formatter-constants';
import {
    CORPORATION_IDENTIFICATION_LENGTH,
    CORPORATION_IDENTIFICATION_MASK,
    CORPORATION_IDENTIFICATION_MASK_REGEX,
    DATE_FNS_LOCAL_DATE_FORMAT,
    DEFAULT_PHONE_LENGTH,
    DEFAULT_PHONE_MASK,
    DEFAULT_PHONE_REGEX,
    EXTENDED_PHONE_LENGTH,
    EXTENDED_PHONE_MASK,
    EXTENDED_PHONE_REGEX,
    INDIVIDUAL_IDENTIFICATION_LENGTH,
    INDIVIDUAL_IDENTIFICATION_MASK,
    INDIVIDUAL_IDENTIFICATION_MASK_REGEX,
} from 'config/constants';
import { format, isValid, parseISO } from 'date-fns';
import { PersonType } from 'model/enums/person-type';
import { YearMonthInterface } from 'model/imported-operation';

const EMPTY_STRING = '';
const NON_DIGITS_SEARCH_REGEX = /\D/g;

const RG_TO_FORMAT = /(^\d{1,2}).?(\d{3}).?(\d{3})-?(\d{1}|X|x$)/g;

const isNotDefined = (value: any) => value === null || value === undefined;

const formatNumberToPercentage = (value: number | undefined | null, defaultValue?: number): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;

    if (percentageValue) {
        return PERCENTAGE_FORMATTER.format(percentageValue / 100);
    }
    return;
};

const formatNumberToPercentageWithSixDecimals = (value: number | undefined | null, defaultValue?: number): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;

    if (percentageValue) {
        return PERCENTAGE_FORMATTER_WITH_SIX_DECIMALS.format(percentageValue / 100);
    }
    return;
};

const formatNumberToCurrency = (value: number | undefined | null, defaultValue?: number, prefix?: boolean): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    if (value !== undefined && value !== null) {
        const currencyValue: number = value ?? defaultValue;
        return prefix ? CURRENCY_FORMATTER_PREFIXED.format(currencyValue) : CURRENCY_FORMATTER.format(currencyValue);
    }
};

const formatIdentification = (type?: PersonType, value: string = ''): string | undefined => {
    if (isNotDefined(value)) return EMPTY_STRING;

    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);
    if (type === PersonType.INDIVIDUAL && digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH)
        return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (type === PersonType.CORPORATION && digits.length === CORPORATION_IDENTIFICATION_LENGTH)
        return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    if (digits.length === INDIVIDUAL_IDENTIFICATION_LENGTH)
        return digits.replace(INDIVIDUAL_IDENTIFICATION_MASK_REGEX, INDIVIDUAL_IDENTIFICATION_MASK);
    if (digits.length === CORPORATION_IDENTIFICATION_LENGTH)
        return digits.replace(CORPORATION_IDENTIFICATION_MASK_REGEX, CORPORATION_IDENTIFICATION_MASK);
    return value;
};

const formatStringToZipCode = (value: string | undefined | null) => {
    if (!value) return;
    if (isNotDefined(value) || value.length < 5) return value;
    return `${value.slice(0, 2)}.${value.slice(2, 5)}-${value.slice(5)}`;
};

const formatPhone = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;

    const digits = value.replace(NON_DIGITS_SEARCH_REGEX, EMPTY_STRING);

    if (digits.length === DEFAULT_PHONE_LENGTH) return digits.replace(DEFAULT_PHONE_REGEX, DEFAULT_PHONE_MASK);
    if (digits.length === EXTENDED_PHONE_LENGTH) return digits.replace(EXTENDED_PHONE_REGEX, EXTENDED_PHONE_MASK);

    return value;
};

const formatRg = (value?: string): string => {
    if (!value) return EMPTY_STRING;
    if (isNotDefined(value)) return EMPTY_STRING;

    return value?.replace(RG_TO_FORMAT, '$1.$2.$3-$4');
};

const formatDate = (date?: Date | string): string => {
    if (!date) return '';

    const parseDate = parseISO(date.toString());

    return format(parseDate, DATE_FNS_LOCAL_DATE_FORMAT);
};

const formatNewDate = (date: Date): string => {
    if (!date) return '';

    return format(date, DATE_FNS_LOCAL_DATE_FORMAT);
};

const formatDateByYear = (date?: Date): string => {
    if (!date) return '';
    const parseDate = parseISO(date.toString());

    return format(parseDate, 'yyyy/MM/dd');
};

const formatDateTimeUTC = (utcDate?: Date | string): string => {
    if (!utcDate) return '';

    return new Date(utcDate).toLocaleString();
};

const formatDateTimeCompleteUTC = (utcDate?: Date | string): string => {
    if (!utcDate) return '';

    const parseUtcDate = parseISO(utcDate.toString());

    return format(parseUtcDate, 'dd/MM/yyyy - HH:mm:ss');
};

const formatYearMonthDate = (date?: Date | string): string => {
    if (!date) return '';
    const parseDate = parseISO(date.toString());

    return format(parseDate, 'MM/yyyy');
};

const formatDateToMonthString = (date?: Date | string): string => {
    if (!date) return '';

    const parseDate = parseISO(date.toString());

    return format(parseDate, 'MMMM');
};

const formatYearMonthInterfaceToString = (yearMonth?: YearMonthInterface): string => {
    if (!yearMonth) return '';

    return `${yearMonth.year}-${yearMonth.month}`;
};

const formatDateToLocalDate = (date?: Date | null): string | null => {
    if (!date) return null;

    const formattedDate = format(parseISO(date.toISOString()), 'yyyy-MM-dd');

    return formattedDate;
};

const isValidDate = (dateString: string | undefined): boolean => {
    if (!dateString) return false;
    const parsedDate = parseISO(dateString);
    return isValid(parsedDate);
};

export const dateWithoutTimezone = (dateString: string | Date | null) => {
    return new Date(`${dateString}T00:00:00.000`);
};

export const validDateWithoutTimezone = (dateString: string | Date | null) => {
    const withoutTimezone = new Date(`${dateString}T00:00:00.000`);

    if (isNaN(withoutTimezone.getTime())) return null;

    return withoutTimezone;
};

const formatNumberToPercentageWithSixDecimalsWithoutSymbols = (
    value: number | undefined | null,
    defaultValue?: number
): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;

    if (percentageValue) {
        return PERCENTAGE_FORMATTER_WITH_SIX_DECIMALS.format(percentageValue / 100).replace('%', '');
    }
};

const formatNumberToPercentageWithTwelveDecimals = (value: number | undefined | null, defaultValue?: number): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;

    if (percentageValue) {
        return PERCENTAGE_FORMATTER_WITH_TWELVE_DECIMALS.format(percentageValue / 100);
    }
};

const formatNumberToPercentageWithTwelveDecimalsWithoutSymbols = (
    value: number | undefined | null,
    defaultValue?: number
): string | undefined => {
    if (isNotDefined(value) && isNotDefined(defaultValue)) return undefined;
    const percentageValue = value ?? defaultValue;

    if (percentageValue) {
        return PERCENTAGE_FORMATTER_WITH_TWELVE_DECIMALS.format(percentageValue / 100).replace('%', '');
    }
};

const FormatterUtils = {
    formatNumberToPercentage,
    formatNumberToCurrency,
    formatIdentification,
    formatDate,
    formatNewDate,
    formatRg,
    formatPhone,
    formatStringToZipCode,
    formatYearMonthDate,
    formatDateTimeUTC,
    formatDateTimeCompleteUTC,
    formatDateToMonthString,
    formatNumberToPercentageWithSixDecimals,
    formatYearMonthInterfaceToString,
    formatDateByYear,
    formatDateToLocalDate,
    formatNumberToPercentageWithSixDecimalsWithoutSymbols,
    formatNumberToPercentageWithTwelveDecimals,
    formatNumberToPercentageWithTwelveDecimalsWithoutSymbols,
    dateWithoutTimezone,
    validDateWithoutTimezone,
    isValidDate,
};

export default FormatterUtils;
