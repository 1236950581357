import { AxiosResponse } from 'axios';
import { BatchDocumentSimple } from 'model/batch-output-hemera';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchDocumentProcessError, batchDocumentProcessRequest, batchDocumentProcessSuccess } from 'reducer/batch/batch-document-process/action';
import { BatchDocumentProcessTypes } from 'reducer/batch/batch-document-process/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import outputApi from 'services/api/batch-output-api'
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchDocument(action: ReturnType<typeof batchDocumentProcessRequest>) {
    try {
        const result: AxiosResponse<BatchDocumentSimple> = yield call(outputApi.processDocument, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchDocumentProcessError(mapped));
            return;
        }
        yield put(batchDocumentProcessSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchDocumentProcessError(mapped));
    }
}

function* watchBatchDocument() {
    yield takeLeading(BatchDocumentProcessTypes.BATCH_DOCUMENT_PROCESS_REQUEST, handleBatchDocument);
}

function* batchDocumentSaga() {
    yield all([fork(watchBatchDocument)]);
}

export default batchDocumentSaga;