import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdIssuerSimple } from 'model/issuer';
import { markError } from 'reducer/application/error/actions';
import { updateIssuerError, updateIssuerRequest, updateIssuerSuccess } from 'reducer/issuer/update-issuer/actions';
import { UpdateIssuerTypes } from 'reducer/issuer/update-issuer/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import issuerApi from 'services/api/issuer-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateIssuer(action: ReturnType<typeof updateIssuerRequest>) {
    try {
        const result: AxiosResponse<ScdIssuerSimple> = yield call(issuerApi.updateIssuer, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateIssuerError(mapped));
            return;
        }
        yield put(updateIssuerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateIssuerError(mapped));
    }
}

function* watchUpdateIssuer() {
    yield takeLeading(UpdateIssuerTypes.UPDATE_ISSUER_REQUEST, handleUpdateIssuer);
}

function* updateIssuerSaga() {
    yield all([fork(watchUpdateIssuer)]);
}

export default updateIssuerSaga;
