import { ScdOrganizationSimple } from 'model/organization';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum CreateOrganizationTypes {
    CREATE_ORGANIZATION_REQUEST = '@@organization-create/CREATE_ORGANIZATION_REQUEST',
    CREATE_ORGANIZATION_SUCCESS = '@@organization-create/CREATE_ORGANIZATION_SUCCESS',
    CREATE_ORGANIZATION_ERROR = '@@organization-create/CREATE_ORGANIZATION_ERROR',
    CREATE_ORGANIZATION_RESET_STATE = '@@organization-create/CREATE_ORGANIZATION_RESET_STATE',
}

export interface CreateOrganizationState extends BaseStoreState {
    readonly organizationToken?: ScdOrganizationSimple;
}
