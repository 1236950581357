import { ScdTaxToCreate } from 'model/tax';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.tax.validate';

const TAX_VALUE_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.value.required`,
    },
    minSize: {
        predicate: ValidationUtils.NUMBER.minSize(0),
        errorMessage: `${I18N_PREFIX}.value.required`,
    },
};
export const validateTaxValue = (value: number | undefined | null) => ValidationUtils.validate(value, TAX_VALUE_VALIDATION);

const TAX_TYPE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateTaxType = (value: string | undefined | null) => ValidationUtils.validate(value, TAX_TYPE_VALIDATION);

const TAX_DATE_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.date.required`,
    },
};
export const validateTaxDate = (value: string | undefined) => ValidationUtils.validate(value, TAX_DATE_VALIDATION);

export const validateScdTax = (value: ScdTaxToCreate | undefined): boolean => {
    if (!value) return true;
    return validateTaxValue(value.value).isValid && validateTaxType(value.type).isValid && validateTaxDate(value.date).isValid;
};
