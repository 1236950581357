import { Box, Grid } from '@material-ui/core';
import VersionedComponent from 'components/versioned-component';
import BatchClosingCard from 'entities/batch/batch-list-card/prepare-cards/batch-closing-card';
import BatchErrorCard from 'entities/batch/batch-list-card/prepare-cards/batch-error-card';
import BatchInternalErrorCard from 'entities/batch/batch-list-card/prepare-cards/batch-internal-error-card';
import BatchLoadingCard from 'entities/batch/batch-list-card/prepare-cards/batch-loading-card';
import BatchOperationCard from 'entities/batch/batch-list-card/prepare-cards/batch-operation-card';
import BatchOutputsCard from 'entities/batch/batch-list-card/prepare-cards/batch-outputs-card';
import BatchProgramCard from 'entities/batch/batch-list-card/prepare-cards/batch-program-card';
import BatchReportCard from 'entities/batch/batch-list-card/prepare-cards/batch-reports-card';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { batchOutputsListBcoRequest, batchOutputsListBcoResetState } from 'reducer-bco/batch-output/list-batch-output/action';
import { useBatchOutputsListBco } from 'reducer-bco/hooks-bco';
import { useBatchDetailWithProgramState, useRootDispatch } from 'reducer/hooks';
import './batch-list-card.scss';

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const BatchListCard = () => {
    const dispatch = useRootDispatch();
    const { t } = useTranslation();

    const { batchWithProgram } = useBatchDetailWithProgramState();
    const { status: batchOutputsListStatus, batchOutputsList } = useBatchOutputsListBco();

    const handleOutputsListRequest = useCallback(() => {
        if (!batchWithProgram?.id) return;
        dispatch(batchOutputsListBcoRequest(batchWithProgram?.id));
    }, [dispatch, batchWithProgram?.id, batchWithProgram?.status]);

    useEffect(() => {
        handleOutputsListRequest();
    }, [handleOutputsListRequest]);

    useEffect(() => {
        return () => {
            dispatch(batchOutputsListBcoResetState());
        };
    }, [dispatch]);

    const isLoading: boolean = batchOutputsListStatus === HttpRequestStatus.ON_GOING;
    const hasError: boolean = batchOutputsListStatus === HttpRequestStatus.ERROR;
    const hasOutputs: boolean = batchOutputsList?.outputs?.length === 0;

    const handleCardListOutputs = () => {
        if (!batchWithProgram) return;
        if (hasOutputs)
            return <BatchInternalErrorCard message={t(`${I18N_PREFIX}.titles.without-output`)} title={t(`${I18N_PREFIX}.titles.ops`)} />;
        if (hasError) return <BatchErrorCard title={t(`${I18N_PREFIX}.titles.error-output`)} handleRefresh={handleOutputsListRequest} />;

        return isLoading ? (
            <BatchLoadingCard />
        ) : (
            batchOutputsList?.outputs?.map(output => (
                <BatchOutputsCard
                    batch={batchWithProgram}
                    output={output}
                    status={batchOutputsListStatus}
                    handleRefresh={handleOutputsListRequest}
                    key={output.id}
                />
            ))
        );
    };

    if (!batchWithProgram?.id) {
        return <></>;
    }

    return (
        <main className="batch-list-card">
            <Box sx={{ flexGrow: 1 }} className="batch-list-card__container">
                <Grid container spacing={1} style={{ justifyContent: 'flex-start', overflowY: 'auto', maxHeight: 'calc(100vh - 450px)' }}>
                    <BatchOperationCard batchId={batchWithProgram.id} />
                    <BatchProgramCard program={batchWithProgram?.program} />
                    <BatchReportCard batch={batchWithProgram} />
                    <VersionedComponent
                        production={<div style={{ display: 'none' }} />}
                        staging={<div style={{ display: 'none' }} />}
                        development={<BatchClosingCard batch={batchWithProgram} />}
                    />
                    {handleCardListOutputs()}
                </Grid>
            </Box>
        </main>
    );
};

export default BatchListCard;
