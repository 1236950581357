import { action } from 'typesafe-actions';
import { SearchPaymentFavoredTypes } from 'reducer/payment-favored/search-payment-favored/types';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchPaymentFavoredRequest = (request: SearchRequest) =>
    action(SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_REQUEST, request);
export const searchPaymentFavoredSuccess = (paymentFavored: Page<ScdPaymentFavoredSearch>) =>
    action(SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_SUCCESS, paymentFavored);
export const searchPaymentFavoredError = (error: GiroWebappError) => action(SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_ERROR, error);
export const searchPaymentFavoredResetState = () => action(SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_RESET);
