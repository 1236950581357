import { ReactNode, useEffect, useState } from 'react';
import { useBatchDetailWithProgramState } from 'reducer/hooks';
import { useTranslation } from 'react-i18next';
import StandardButton from 'components/buttons/standard-button/standard-button';

const I18N_PREFIX = 'pages.scd.batch.detail.footer';

enum BatchDetailSteps {
    UNDEFINED = 'UNDEFINED',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CONCLUDED = 'CONCLUDED',
}

export const useFooterComponents = () => {
    const { t } = useTranslation();
    const { batchWithProgram } = useBatchDetailWithProgramState();

    const [isConcludeModalOpen, setIsConcludeModalOpen] = useState<boolean>(false);
    const [footerType, setFooterType] = useState<BatchDetailSteps>(BatchDetailSteps.UNDEFINED);

    const batchStatus = batchWithProgram?.status;
    const concludeModalOpen = isConcludeModalOpen && batchStatus;

    const isOpenBatch = batchStatus === 'OPEN';
    const isClosedBatch = batchStatus === 'CLOSED';
    const isConcludedBatch = batchStatus === 'CONCLUDED';

    const determineFooterType = () => {
        if (isOpenBatch) return BatchDetailSteps.OPEN;
        if (isClosedBatch) return BatchDetailSteps.CLOSED;
        return BatchDetailSteps.UNDEFINED;
    };

    useEffect(() => {
        setFooterType(determineFooterType());
    }, [isOpenBatch, isClosedBatch, isConcludedBatch, ]);

    const generalFooterContent = (children: ReactNode): ReactNode => (
        <div className="batch-detail-footer">
            <div className="batch-detail-footer__button-box">{children}</div>
        </div>
    );

    const BatchConcludeContent: ReactNode = generalFooterContent(
        <StandardButton label={t(`${I18N_PREFIX}.batch-conclude.conclude`)} onClick={() => setIsConcludeModalOpen(true)} disabled={!isClosedBatch} />
    );


    const EmptyFooter: ReactNode = <></>;

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
        OPEN: EmptyFooter,
        CLOSED: BatchConcludeContent,
        CONCLUDED: EmptyFooter,
    } as Record<BatchDetailSteps, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter, setIsConcludeModalOpen, concludeModalOpen };
};
