import { LoopCircleLoading } from 'react-loadingg';
import './loading.scss';

interface LoadingProps {
    isTable?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ isTable }) => {
    return isTable ? (
        <tr className="loading--row">
            <td className="loading--cell">
                <LoopCircleLoading color="#3f41d1" />
            </td>
        </tr>
    ) : (
        <div className="loading--div">
            <LoopCircleLoading color="#3f41d1" />
        </div>
    );
};

export default Loading;
