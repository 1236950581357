import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchDocumentExportState, BatchDocumentExportTypes } from 'reducer/batch/batch-document-export/types';

const initialState: BatchDocumentExportState = {
    status: HttpRequestStatus.NOOP,
    document: undefined,
    error: undefined
}

const reducer: Reducer<BatchDocumentExportState> = (state = initialState, action): BatchDocumentExportState => {
    switch (action.type) {
        case BatchDocumentExportTypes.BATCH_DOCUMENT_EXPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchDocumentExportTypes.BATCH_DOCUMENT_EXPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, document: action.payload, error: undefined };
        }
        case BatchDocumentExportTypes.BATCH_DOCUMENT_EXPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchDocumentExportTypes.BATCH_DOCUMENT_EXPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchDocumentExportReducer }