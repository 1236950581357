import PublishIcon from '@material-ui/icons/Publish';
import SendIcon from '@material-ui/icons/Send';
import LinkButton from 'components/buttons/link-button/link-button';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import { BatchSignatureDocumentsList } from 'model/batch-signature';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';

type BatchEndorsementButtomTypes = 'import' | 'export';

interface ScdBatchEndorsementSlideButtonsProps {
    action?: BatchEndorsementButtomTypes;
    handleAction: () => void;
    signatureIds?: number[];
    handleRedirect?: () => void;
    isDisableExport?: boolean;
    batchId?: number;
    handleSwitch?: (value: boolean) => void;
    setSignatureIds?: (value: number[]) => void;
    isChecked?: boolean;
    handleModalExport?: () => void;
    documentsFiltered?: BatchSignatureDocumentsList[];
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementSlideButtons = (props: ScdBatchEndorsementSlideButtonsProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { url } = useRouteMatch();

    const {
        action,
        handleAction,
        signatureIds,
        handleRedirect,
        isDisableExport,
        batchId,
        handleSwitch,
        setSignatureIds,
        isChecked,
        handleModalExport,
        documentsFiltered,
    } = props;

    const isDisable = !signatureIds?.length;

    const Buttons: Record<BatchEndorsementButtomTypes, ReactNode> = {
        import: (
            <>
                {isChecked && (
                    <div className="scd-batch-endorsement__content-slide-buttons--import-left-box">
                        <div
                            className="scd-batch-endorsement__content-slide-buttons--redirect-buttons"
                            onClick={() => {
                                handleSwitch?.(false);
                                setSignatureIds?.([]);
                            }}
                        >
                            <span className="scd-batch-endorsement__content-slide-buttons--redirect-buttons__value">
                                {t(`${I18N_PREFIX}.buttons.select.action.close-selected`).toLocaleUpperCase()}
                            </span>
                        </div>
                        <span>{documentsFiltered?.length || 0} selecionados</span>
                    </div>
                )}
                <div className="scd-batch-endorsement__content-slide-buttons--import-right-box">
                    <div className="scd-batch-endorsement__content-slide-buttons--redirect-buttons" onClick={() => history.goBack()}>
                        <span className="scd-batch-endorsement__content-slide-buttons--redirect-buttons__value">
                            {t('entity.action.back')}
                        </span>
                    </div>
                    <OutlinedButtonLegacy
                        startIcon={<PublishIcon />}
                        label={t(`${I18N_PREFIX}.buttons.slide.import`)}
                        onClick={handleAction}
                        disabled={isDisableExport}
                    />
                </div>
            </>
        ),
        export: (
            <>
                <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => history.goBack()} />
                <LinkButton label={t(`${I18N_PREFIX}.buttons.slide.export`)} onClick={handleAction} />
            </>
        ),
    };

    const DefaultButtom: ReactNode = (
        <>
            {isChecked && (
                <div className="scd-batch-endorsement__content-slide-buttons--left-box">
                    <div
                        className="scd-batch-endorsement__content-slide-buttons--redirect-buttons"
                        onClick={() => {
                            handleSwitch?.(false);
                            setSignatureIds?.([]);
                        }}
                    >
                        <span className="scd-batch-endorsement__content-slide-buttons--redirect-buttons__value">
                            {t(`${I18N_PREFIX}.buttons.select.action.close-selected`).toLocaleUpperCase()}
                        </span>
                    </div>
                    <span>{signatureIds?.length || 0} selecionados</span>
                </div>
            )}
            <div
                className={
                    !isChecked
                        ? 'scd-batch-endorsement__content-slide-buttons--redirect-box-no-select'
                        : 'scd-batch-endorsement__content-slide-buttons--redirect-box'
                }
            >
                {!isChecked && (
                    <>
                        <div
                            className="scd-batch-endorsement__content-slide-buttons--redirect-buttons"
                            onClick={() => history.push(`/provisional/limelight/${batchId}/backings`)}
                        >
                            <span className="scd-batch-endorsement__content-slide-buttons--redirect-buttons__value">
                                {t(`${I18N_PREFIX}.buttons.slide.hemera`)}
                            </span>
                        </div>
                        <span className="scd-batch-endorsement__content-slide-buttons-style">
                            <LinkButton
                                label={t(`${I18N_PREFIX}.buttons.slide-select.import`)}
                                onClick={handleRedirect ? handleRedirect : () => history.push(`${url}/imports`)}
                                outlinedStyle
                            />
                        </span>
                    </>
                )}

                <span className="scd-batch-endorsement__content-slide-buttons-style">
                    <LinkButton
                        startIcon={<SendIcon />}
                        label={t(`${I18N_PREFIX}.buttons.slide-select.export`)}
                        onClick={handleModalExport ? handleModalExport : () => {}}
                        disabled={isDisable}
                    />
                </span>
            </div>
        </>
    );

    return (
        <div className="scd-batch-endorsement__content-slide">
            <div className="scd-batch-endorsement__content-slide-buttons">{action ? Buttons[action] : DefaultButtom}</div>
        </div>
    );
};

export default ScdBatchEndorsementSlideButtons;
