import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { validateCompanyOrganizationId } from 'entities/company/validations/company-organizations-validation';
import { ScdOrganization } from 'model/organization';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchOrganizationsRequest, searchOrganizationsResetState } from 'reducer/organization/search-organizations/actions';

interface CompanyOrganizationsCardCreateProps {
    entity: ScdOrganization;
    onChange: (companyOrganization: Partial<ScdOrganization>) => void;
    showValidation: boolean;
    alreadyAdd?: boolean;
    handleAlreadyAdd: (companyOrganization: ScdOrganization) => void;
    index: number;
}

const I18N_PREFIX = 'pages.scd.company.card.organizations';

const CompanyOrganizationsCardCreate = (props: CompanyOrganizationsCardCreateProps) => {
    const { entity, handleAlreadyAdd, onChange, showValidation, alreadyAdd, index } = props;

    const { t } = useTranslation();

    return (
        <article className="scd-company-create-update__card-manager">
            <header className="card-edition__container--header">
                <span className="card-edition__container--header-name">{t(`${I18N_PREFIX}.name`, { number: index + 1 })}</span>
            </header>
            <div className="card-edition__container--content">
                <div className="scd-company-create-update--form-card-field">
                    <ModalSearch<ScdOrganization>
                        action={searchOrganizationsRequest}
                        itemSize={SEARCH_ITEM_SIZE}
                        modalTitle={t(`${I18N_PREFIX}.input.organizationId.title`)}
                        modalLabel={t(`${I18N_PREFIX}.input.organizationId.label`)}
                        modalPlaceholder={t(`${I18N_PREFIX}.input.organizationId.placeholder`)}
                        onSelect={organization => {
                            handleAlreadyAdd && handleAlreadyAdd(organization);
                            onChange({ ...organization });
                        }}
                        renderItem={organization => organization.name}
                        statusSelector={(state: IRootState) => state.searchOrganizations.status}
                        dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                        resetState={searchOrganizationsResetState}
                    >
                        {handleOpen => (
                            <ValidSearchInput
                                label={`${I18N_PREFIX}.input.organizationId.label`}
                                showValidation={showValidation}
                                placeholder={`${I18N_PREFIX}.input.organizationId.placeholder`}
                                validate={validateCompanyOrganizationId}
                                value={entity.name ?? ''}
                                onClick={handleOpen}
                                onFocus={handleOpen}
                                readOnly
                                externalUpdate
                            />
                        )}
                    </ModalSearch>
                </div>
                {alreadyAdd && (
                    <span className="scd-company-create-update--form-card--field-error">{t(`${I18N_PREFIX}.validate.alreadyAdd`)}</span>
                )}
            </div>
        </article>
    );
};

export default CompanyOrganizationsCardCreate;
