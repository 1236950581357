import { HttpRequestStatus } from 'model/enums/http-request-status';
import { LegacyUpdateProgramTypes, LegacyUpdateProgramState } from 'reducer/program/legacy-update-program/types';
import { Reducer } from 'redux';

export const initialState: LegacyUpdateProgramState = {
    status: HttpRequestStatus.NOOP,
    program: undefined,
    error: undefined,
};

const reducer: Reducer<LegacyUpdateProgramState> = (state = initialState, action): LegacyUpdateProgramState => {
    switch (action.type) {
        case LegacyUpdateProgramTypes.LEGACY_UPDATE_PROGRAM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case LegacyUpdateProgramTypes.LEGACY_UPDATE_PROGRAM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, program: action.payload, error: undefined };
        }
        case LegacyUpdateProgramTypes.LEGACY_UPDATE_PROGRAM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case LegacyUpdateProgramTypes.LEGACY_UPDATE_PROGRAM_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as legacyUpdateProgramReducer };
