import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchTechnicalSuppliersTypes, SearchTechnicalSuppliersState } from './types';

const initialState: SearchTechnicalSuppliersState = {
    status: HttpRequestStatus.NOOP,
    technicalSuppliers: undefined,
    error: undefined,
};

const reducer: Reducer<SearchTechnicalSuppliersState> = (state = initialState, action): SearchTechnicalSuppliersState => {
    switch (action.type) {
        case SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, technicalSuppliers: action.payload, error: undefined };
        }
        case SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchTechnicalSuppliersTypes.SEARCH_TECHNICAL_SUPPLIERS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchTechnicalSuppliersReducer };
