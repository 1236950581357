import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { PaymentInstitutionAccountFilterRequest } from 'model/payment-institution-account';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UsePaymentInstitutionFilterChipsProps {
    filterActive: PaymentInstitutionAccountFilterRequest;
    setFilterActive: (value: PaymentInstitutionAccountFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

type PaymentInstitutionFilterRequestType = keyof Omit<PaymentInstitutionAccountFilterRequest, 'technicalSupplierId'>;

const usePaymentInstitutionFilterChips = (props: UsePaymentInstitutionFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: PaymentInstitutionAccountFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<PaymentInstitutionFilterRequestType, () => void> = useMemo(() => {
        return {
            name: () => clearFilterSupport({ ...filterActive, name: undefined }),
            status: () => clearFilterSupport({ ...filterActive, status: undefined }),
            type: () => clearFilterSupport({ ...filterActive, type: undefined }),
            technicalSupplierName: () =>
                clearFilterSupport({ ...filterActive, technicalSupplierName: undefined, technicalSupplierId: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: PaymentInstitutionFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.filter.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const paymentInstitutionFilterChips = useMemo(() => {
        const items: Record<PaymentInstitutionFilterRequestType, ChipFilterItem> = {
            name: {
                isActive: !!filterActive.name,
                onDelete: handleClearFilter['name'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('name')} component={filterActive.name} />,
            },
            status: {
                isActive: !!filterActive.status,
                onDelete: handleClearFilter['status'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('status')}
                        component={t(`${I18N_PREFIX}.inputs.status.options.${filterActive.status}`)}
                    />
                ),
            },
            type: {
                isActive: !!filterActive.type,
                onDelete: handleClearFilter['type'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('type')}
                        component={t(`${I18N_PREFIX}.inputs.type.options.${filterActive.type}`)}
                    />
                ),
            },
            technicalSupplierName: {
                isActive: !!filterActive.technicalSupplierName,
                onDelete: handleClearFilter['technicalSupplierName'],
                renderItem: (
                    <ChipRenderItemComponent chipTitle={chipTitle('technicalSupplier')} component={filterActive.technicalSupplierName} />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isPaymentInstitutionChipsActive: boolean = useMemo(() => {
        return paymentInstitutionFilterChips.some(it => !!it.isActive);
    }, [paymentInstitutionFilterChips]);

    return {
        paymentInstitutionFilterChips,
        isPaymentInstitutionChipsActive,
    };
};

export default usePaymentInstitutionFilterChips;
