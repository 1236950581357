import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { CompanyGroupCommissionPolicy } from 'model/enums/company-group-commission-policy';
import { CompanyGroupDisbursementPaymentPolicy } from 'model/enums/company-group-disbursement-payment-policy';
import { CompanyGroupMonthlyCommissionPolicy } from 'model/enums/company-group-monthly-commission-policy';
import { PolicyDisbursementGroupBy } from 'model/enums/policy-disbursement-group';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.company-group-policy.validate';

const COMPANY_GROUP_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.companyGroup.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.companyGroup.required`,
    },
};

export const validateCompanyGroupName = (value: string | undefined | null) =>
    ValidationUtils.validate(value, COMPANY_GROUP_NAME_VALIDATION);

const DISBURSEMENT_POLICY_VALIDATION: Validations<PolicyDisbursementGroupBy | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.disbursementPolicy.required`,
    },
};
export const validateDisbursementPolicy = (value: PolicyDisbursementGroupBy | undefined | null) =>
    ValidationUtils.validate(value, DISBURSEMENT_POLICY_VALIDATION);

const COMMISSION_POLICY_VALIDATION: Validations<CompanyGroupCommissionPolicy | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.commissionPolicy.required`,
    },
};
export const validateCommissionPolicy = (value: CompanyGroupCommissionPolicy | undefined | null) =>
    ValidationUtils.validate(value, COMMISSION_POLICY_VALIDATION);

const MONTHLY_COMMISSION_POLICY_VALIDATION: Validations<CompanyGroupMonthlyCommissionPolicy | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.monthlyCommissionPolicy.required`,
    },
};
export const validateMonthlyCommissionPolicy = (value: CompanyGroupMonthlyCommissionPolicy | undefined | null) =>
    ValidationUtils.validate(value, MONTHLY_COMMISSION_POLICY_VALIDATION);

const DISBURSEMENT_PAYMENT_POLICY_VALIDATION: Validations<CompanyGroupDisbursementPaymentPolicy | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.disbursementPaymentPolicy.required`,
    },
};

export const validateDisbursmentPaymentPolicy = (value: CompanyGroupDisbursementPaymentPolicy | undefined | null) =>
    ValidationUtils.validate(value, DISBURSEMENT_PAYMENT_POLICY_VALIDATION);

export const validateCompanyGroupPolicy = (value: ScdCompanyGroupPolicy | undefined | null): boolean => {
    if (!value) return true;
    return (
        validateCompanyGroupName(value.companyGroup?.name).isValid &&
        validateDisbursementPolicy(value.disbursementGroupBy).isValid &&
        validateCommissionPolicy(value.commissionPolicy).isValid &&
        validateMonthlyCommissionPolicy(value.monthlyCommissionPolicy).isValid &&
        validateDisbursmentPaymentPolicy(value.disbursementPaymentPolicy).isValid
    );
};
