import { ScdBatchSimple } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum SendBatchCnabDocumentTypes {
    SEND_BATCH_CNAB_DOCUMENT_REQUEST = '@@batch-send-cnab-document/SEND_BATCH_CNAB_DOCUMENT_REQUEST',
    SEND_BATCH_CNAB_DOCUMENT_SUCCESS = '@@batch-send-cnab-document/SEND_BATCH_CNAB_DOCUMENT_SUCCESS',
    SEND_BATCH_CNAB_DOCUMENT_ERROR = '@@batch-send-cnab-document/SEND_BATCH_CNAB_DOCUMENT_ERROR',
    SEND_BATCH_CNAB_DOCUMENT_RESET = '@@batch-send-cnab-document/SEND_BATCH_CNAB_DOCUMENT_RESET',
}

export interface SendBatchCnabDocumentState extends BaseStoreState {
    readonly batch?: ScdBatchSimple;
}
