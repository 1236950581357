import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ChunkCessionFinalizeBcoState, ChunkCessionFinalizeBcoTypes } from 'reducer-bco/batch-output/chunk-cession-finalize/types';

import { Reducer } from 'redux';

const initialState: ChunkCessionFinalizeBcoState = {
    status: HttpRequestStatus.NOOP,
    chunkCessionFinalizeBco: undefined,
    error: undefined,
};

const reducer: Reducer<ChunkCessionFinalizeBcoState> = (state = initialState, action): ChunkCessionFinalizeBcoState => {
    switch (action.type) {
        case ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, chunkCessionFinalizeBco: action.payload, error: undefined };
        }
        case ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as ChunkCessionFinalizeBcoReducer };
