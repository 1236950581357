import { BatchOutputsListBco } from 'model/bco/batch-output';
import { BaseStoreState } from 'reducer/base-store-state';

export enum BatchOutputsListBcoTypes {
    BATCH_OUTPUTS_LIST_BCO_REQUEST = '@@batch-outputs-list-bco/BATCH_OUTPUTS_LIST_BCO_REQUEST',
    BATCH_OUTPUTS_LIST_BCO_SUCCESS = '@@batch-outputs-list-bco/BATCH_OUTPUTS_LIST_BCO_SUCCESS',
    BATCH_OUTPUTS_LIST_BCO_ERROR = '@@batch-outputs-list-bco/BATCH_OUTPUTS_LIST_BCO_ERROR',
    BATCH_OUTPUTS_LIST_BCO_RESET = '@@batch-outputs-list-bco/BATCH_OUTPUTS_LIST_BCO_RESET',
}

export interface BatchOutputsListBcoState extends BaseStoreState {
    readonly batchOutputsList?: BatchOutputsListBco;
}
