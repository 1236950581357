import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramPolicySimple } from 'model/program-policy';
import {
    updateProgramPolicyError,
    updateProgramPolicySuccess,
    updateProgramPolicyRequest,
} from 'reducer/program-policy/update-program-policy/actions';
import { UpdateProgramPolicyTypes } from 'reducer/program-policy/update-program-policy/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programPolicyApi from 'services/api/program-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateProgramPolicy(action: ReturnType<typeof updateProgramPolicyRequest>) {
    try {
        const result: AxiosResponse<ScdProgramPolicySimple> = yield call(programPolicyApi.updateProgramPolicy, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateProgramPolicyError(mapped));
            return;
        }
        yield put(updateProgramPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateProgramPolicyError(mapped));
    }
}

function* watchUpdateProgramPolicy() {
    yield takeEvery(UpdateProgramPolicyTypes.UPDATE_PROGRAM_POLICY_REQUEST, handleUpdateProgramPolicy);
}

function* updateProgramPolicySaga() {
    yield all([fork(watchUpdateProgramPolicy)]);
}

export default updateProgramPolicySaga;
