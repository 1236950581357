import { BatchSignatureExportRequest } from 'model/batch-signature';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchSignatureExportTypes {
    BATCH_SIGNATURE_EXPORT_REQUEST = '@@batch-signature-export/BATCH_SIGNATURE_EXPORT_REQUEST',
    BATCH_SIGNATURE_EXPORT_SUCCESS = '@@batch-signature-export/BATCH_SIGNATURE_EXPORT_SUCCESS',
    BATCH_SIGNATURE_EXPORT_ERROR = '@@batch-signature-export/BATCH_SIGNATURE_EXPORT_ERROR',
    BATCH_SIGNATURE_EXPORT_RESET = '@@batch-signature-export/BATCH_SIGNATURE_EXPORT_RESET',
}

export interface BatchSignatureExportState extends BaseStoreState {
    readonly signatureExport?: BatchSignatureExportRequest
}