import { CessionToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum CessionToResumeBcoTypes {
    CESSION_TO_RESUME_BCO_REQUEST = '@@cession-to-resume-bco/CESSION_TO_RESUME_BCO_REQUEST',
    CESSION_TO_RESUME_BCO_SUCCESS = '@@cession-to-resume-bco/CESSION_TO_RESUME_BCO_SUCCESS',
    CESSION_TO_RESUME_BCO_ERROR = '@@cession-to-resume-bco/CESSION_TO_RESUME_BCO_ERROR',
    CESSION_TO_RESUME_BCO_RESET = '@@cession-to-resume-bco/CESSION_TO_RESUME_BCO_RESET',
}

export interface CessionToResumeBcoState extends BaseStoreState {
    readonly cessionToResume?: CessionToResumeBco;
}
