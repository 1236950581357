import { DebtorAccount } from 'model/debtor-account';
import { TechnicalSupplierAccountType } from 'model/enums/technical-supplier-account-type';
import { TechnicalSupplierStatus } from 'model/enums/technical-supplier-status';
import { TechnicalSupplierType } from 'model/enums/technical-supplier-type';

export interface ScdTechnicalSupplier {
    id?: string;
    name?: string;
    internalReference?: string;
    accountType?: TechnicalSupplierAccountType;
    type?: TechnicalSupplierType;
    status?: TechnicalSupplierStatus;
    debtorAccount?: DebtorAccount;
    isDefaultPaymentInstitution?: boolean;
}

export const defaultScdTechnicalSupplier: ScdTechnicalSupplier = {
    id: undefined,
    name: undefined,
    internalReference: undefined,
    accountType: undefined,
    type: undefined,
    status: undefined,
    debtorAccount: undefined,
    isDefaultPaymentInstitution: undefined,
};

export interface ScdTechnicalSupplierSimple {
    id: string;
}

export interface ScdTechnicalSupplierToPaymentInstitution {
    id: number;
    name: string;
}
