import { ScdBatchData } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchFilterPageable } from 'model/reducers';
import { ListBatchesTypes } from 'reducer/batch/list-batches/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listBatchesRequest = (request: BatchFilterPageable) => action(ListBatchesTypes.LIST_BATCHES_REQUEST, request);
export const listBatchesSuccess = (batches: Page<ScdBatchData>) => action(ListBatchesTypes.LIST_BATCHES_SUCCESS, batches);
export const listBatchesError = (error: GiroWebappError) => action(ListBatchesTypes.LIST_BATCHES_ERROR, error);
export const listBatchesResetState = () => action(ListBatchesTypes.LIST_BATCHES_RESET);
