import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ScdCompanyGroup } from 'model/company-group';
import { useTranslation } from 'react-i18next';

export interface CompanyGroupCardDetailProps {
    companyGroup: ScdCompanyGroup;
}

const I18N_PREFIX = 'pages.scd.program.card.companyGroups.input';

export const CompanyGroupCardDetail = (props: CompanyGroupCardDetailProps): JSX.Element => {
    const { companyGroup } = props;
    const { t } = useTranslation();

    return (
        <div>
            <ReadOnlyTextField label={t(`${I18N_PREFIX}.label`)} value={companyGroup?.name} />
        </div>
    );
};
