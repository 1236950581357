import { ScdMonthlyCommissionDocumentSimple } from 'model/monthly-commission';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum MonthlyCommissionDocumentTypes {
    MONTHLY_COMMISSION_UPLOAD_DOCUMENT_REQUEST = '@@monthly-commission-upload-document/MONTHLY_COMMISSION_UPLOAD_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_UPLOAD_DOCUMENT_SUCCESS = '@@monthly-commission-upload-document/MONTHLY_COMMISSION_UPLOAD_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_UPLOAD_DOCUMENT_ERROR = '@@monthly-commission-upload-document/MONTHLY_COMMISSION_UPLOAD_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_REQUEST = '@@monthly-commission-download-document/MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_SUCCESS = '@@monthly-commission-download-document/MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_ERROR = '@@monthly-commission-download-document/MONTHLY_COMMISSION_DOWNLOAD_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_DELETE_DOCUMENT_REQUEST = '@@monthly-commission-delete-document/MONTHLY_COMMISSION_DELETE_DOCUMENT_REQUEST',
    MONTHLY_COMMISSION_DELETE_DOCUMENT_SUCCESS = '@@monthly-commission-delete-document/MONTHLY_COMMISSION_DELETE_DOCUMENT_SUCCESS',
    MONTHLY_COMMISSION_DELETE_DOCUMENT_ERROR = '@@monthly-commission-delete-document/MONTHLY_COMMISSION_DELETE_DOCUMENT_ERROR',

    MONTHLY_COMMISSION_DOCUMENT_RESET = '@@monthly-commission-document/MONTHLY_COMMISSION_DOCUMENT_RESET',
}

export interface MonthlyCommissionDocumentState extends BaseStoreState {
    readonly uploadDocument?: ScdMonthlyCommissionDocumentSimple;
    readonly downloadDocument?: Uint8Array;
}
