import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListMonthlyCommissionsTypes, ListMonthlyCommissionsState } from 'reducer/monthly-commission/list-monthly-commissions/types';

const initialState: ListMonthlyCommissionsState = {
    status: HttpRequestStatus.NOOP,
    monthlyCommissions: undefined,
    error: undefined,
};

const reducer: Reducer<ListMonthlyCommissionsState> = (state = initialState, action): ListMonthlyCommissionsState => {
    switch (action.type) {
        case ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, monthlyCommissions: action.payload, error: undefined };
        }
        case ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listMonthlyCommissionsReducer };
