import { PaymentTransactionToSimple } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ManualDisbursementPaymentTransactionTypes {
    MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST = '@@payment-transactions-disbursement/MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST',
    MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transactions-disbursement/MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS',
    MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR = '@@payment-transactions-disbursement/MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR',
    MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET = '@@payment-transactions-disbursement/MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET',
}

export interface ManualDisbursementPaymentTransactionState extends BaseStoreState {
    paymentTransactionSimple?: PaymentTransactionToSimple
}
