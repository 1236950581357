import { Bank } from 'model/bank';
import { DebtorAccount } from 'model/debtor-account';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.technical-supplier.input.debtorAccount.validate';

const DEBTOR_ACCOUNT_AGENCY_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.agencyNumber.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.agencyNumber.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(16),
        errorMessage: `${I18N_PREFIX}.agencyNumber.maxLength`,
    },
};
export const validateDebtorAgencyNumber = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_AGENCY_NUMBER_VALIDATION);

const DEBTOR_ACCOUNT_ACCOUNT_NUMBER_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountNumber.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.accountNumber.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(16),
        errorMessage: `${I18N_PREFIX}.accountNumber.maxLength`,
    },
};
export const validateDebtorAccountNumber = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_ACCOUNT_NUMBER_VALIDATION);

const DEBTOR_ACCOUNT_ACCOUNT_DIGIT_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.accountDigit.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.accountDigit.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(8),
        errorMessage: `${I18N_PREFIX}.accountDigit.maxLength`,
    },
};
export const validateDebtorAccountDigit = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_ACCOUNT_DIGIT_VALIDATION);

const DEBTOR_ACCOUNT_FAVORED_NAME_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.favoredName.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.favoredName.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.favoredName.maxLength`,
    },
};
export const validateDebtorFavoredName = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_FAVORED_NAME_VALIDATION);

const DEBTOR_ACCOUNT_FAVORED_IDENTIFICATION_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.favoredIdentification.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(1),
        errorMessage: `${I18N_PREFIX}.favoredIdentification.required`,
    },
    isValid: {
        predicate: ValidationUtils.STRING.isValidCPForCNPJ,
        errorMessage: `${I18N_PREFIX}.favoredIdentification.valid`,
    },
};
export const validateDebtorFavoredIdentification = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_FAVORED_IDENTIFICATION_VALIDATION);

const DEBTOR_ACCOUNT_TYPE_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateDebtorAccountType = (value: string | undefined) => ValidationUtils.validate(value, DEBTOR_ACCOUNT_TYPE_VALIDATION);

const DEBTOR_ACCOUNT_BANK_ACCOUNT_TYPE_VALIDATION: Validations<string | undefined> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.bankAccountType.required`,
    },
};
export const validateDebtorBankAccountType = (value: string | undefined) =>
    ValidationUtils.validate(value, DEBTOR_ACCOUNT_BANK_ACCOUNT_TYPE_VALIDATION);

const DEBTOR_ACCOUNT_BANK_ID_VALIDATION: Validations<Bank | undefined> = {
    required: {
        predicate: ValidationUtils.OBJECT.isDefined,
        errorMessage: `${I18N_PREFIX}.bankId.required`,
    },
};

export const validateDebtorAccountBankId = (value: Bank | undefined) => ValidationUtils.validate(value, DEBTOR_ACCOUNT_BANK_ID_VALIDATION);

export const validateDebtorAccount = (value: DebtorAccount | undefined): boolean => {
    if (!value) return false;

    return (
        validateDebtorAgencyNumber(value.agencyNumber).isValid &&
        validateDebtorAccountNumber(value.accountNumber).isValid &&
        validateDebtorAccountDigit(value.accountDigit).isValid &&
        validateDebtorFavoredName(value.favoredName).isValid &&
        validateDebtorFavoredIdentification(value.favoredIdentification).isValid &&
        validateDebtorAccountBankId(value.bank).isValid &&
        validateDebtorAccountType(value.type).isValid &&
        validateDebtorBankAccountType(value.bankAccountType).isValid
    );
};
