import { BankFilterPageable, BankList } from 'model/bank';
import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';
import { ListBanksTypes } from './types';

export const listBanksRequest = (request: BankFilterPageable) => action(ListBanksTypes.LIST_BANKS_REQUEST, request);
export const listBanksSuccess = (banks: Page<BankList>) => action(ListBanksTypes.LIST_BANKS_SUCCESS, banks);
export const listBanksError = (error: GiroWebappError) => action(ListBanksTypes.LIST_BANKS_ERROR, error);
export const listBanksResetState = () => action(ListBanksTypes.LIST_BANKS_RESET_STATE);
