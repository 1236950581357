import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdOrganizationPage from 'entities/organization/organization';
import ScdOrganizationDetailPage from 'entities/organization/organization-detail/organization-detail';
import ScdOrganizationCreateUpdatePage from 'entities/organization/organization-create-update/organization-create-update';

interface MatchParams {
    url: string;
}

const OrganizationRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdOrganizationPage} />
        <PrivateRoute exact path={`${match.url}/new`} component={ScdOrganizationCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/edit/:organizationId`} component={ScdOrganizationCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:organizationId`} component={ScdOrganizationDetailPage} />
    </Switch>
);

export default OrganizationRoutes;
