import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.table';

const TransfeeraTransfersHeader = () => {
    const { t } = useTranslation();

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.createdDate`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.favoredName`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.agency`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.account`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.status`)} />
            <SortableColumn label={t(`${I18N_PREFIX}.value`)} />
        </tr>
    );
};

export default TransfeeraTransfersHeader;
