import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ProgramOutputPoliciesToGetAll } from 'model/program-output-policy';
import { markError } from 'reducer/application/error/actions';
import { listProgramOutputPoliciesRequest, listProgramOutputPoliciesError, listProgramOutputPoliciesSuccess } from 'reducer/program-output-policies/list-program-output-policies/actions';
import { ListProgramOutputPoliciesTypes } from 'reducer/program-output-policies/list-program-output-policies/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programOutputPoliciesApi from 'services/api/program-output-policies-api';
import ErrorUtils from 'shared/error/error-utils';


function* handleListProgramOutputPolicies(action: ReturnType<typeof listProgramOutputPoliciesRequest>) {
    try {
        const result: AxiosResponse<ProgramOutputPoliciesToGetAll[]> = yield call(programOutputPoliciesApi.listProgramOutputPolicy, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listProgramOutputPoliciesError(mapped));
            return;
        }
        yield put(listProgramOutputPoliciesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listProgramOutputPoliciesError(mapped))
    }
}

function* watchListProgramOutputPolicies() {
    yield takeEvery(ListProgramOutputPoliciesTypes.LIST_PROGRAM_OUTPUT_POLICIES_REQUEST, handleListProgramOutputPolicies);;
}

function* listProgramOutputPoliciesSaga() {
    yield all([fork(watchListProgramOutputPolicies)]);
}

export default listProgramOutputPoliciesSaga;