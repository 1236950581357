import MenuItem from '@material-ui/core/MenuItem';
import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import { SimpleSelectInput } from 'components/inputs/select-input/simple-select-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';

import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateOperationCollateralPolicy,
    validateProgramDisbursementAmendment,
    validateProgramPaymentFavoredPolicy,
    validateProgramPolicy,
    validateProgramPolicyCnabTemplate,
    validateProgramPolicyName,
    validateProgramPolicyOperationApprovalPolicy,
    validateProgramPolicyPaymentModePolicy,
    validateProgramPolicyTransferDispatch,
} from 'entities/program-policy/validation/program-policy-validation';
import { CnabTemplate } from 'model/enums/cnab-template';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ProductType } from 'model/enums/product-type';
import { ScdProgramOperationApprovalPolicy } from 'model/enums/program-operation-approval-policy-type';
import { ScdProgramPaymentFavoredPolicy } from 'model/enums/program-payment-favored-policy';
import { ScdProgramPaymentModePolicy } from 'model/enums/program-payment-mode-policy-type';
import { ScdProgramCommissionPolicy, ScdProgramPolicyType } from 'model/enums/program-policy-type';
import { ScdProgramTransferDispatchModePolicy } from 'model/enums/program-transfer-dispatch-mode';
import { ScdProgramSimple } from 'model/program';
import { ScdProgramPolicy, defaultScdProgramPolicy } from 'model/program-policy';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import {
    useCreateProgramPolicyState,
    useDetailProgramPolicyState,
    useGetProgramInfoState,
    useRootDispatch,
    useUpdateProgramPolicyState,
} from 'reducer/hooks';
import { createProgramPolicyRequest, createProgramPolicyResetState } from 'reducer/program-policy/create-program-policy/actions';
import { detailProgramPolicyRequest, detailProgramPolicyResetState } from 'reducer/program-policy/detail-program-policy/actions';
import { getProgramInfoRequest, getProgramInfoResetState } from 'reducer/program-policy/get-program-info/actions';
import { updateProgramPolicyRequest, updateProgramPolicyResetState } from 'reducer/program-policy/update-program-policy/actions';
import { searchProgramsRequest, searchProgramsResetState } from 'reducer/program/search-programs/actions';
import { useToast } from 'shared/hooks/use-toast';

import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { OperationCollateralPolicy } from 'model/enums/operation-collateral-policy';
import { ScdProgramDisbursementAmendmentolicy } from 'model/enums/program-disbursement-amendment-policy';
import './program-policy-create-update.scss';

export interface ScdProgramUpdateProps extends RouteComponentProps<{ programPolicyId: string; programId: string }> {}

const I18N_PREFIX = 'pages.scd.program-policy';

// TODO AB#5830 add Collateral Policy for Program
const ScdProgramCreateUpdatePage = (props: ScdProgramUpdateProps) => {
    const { t } = useTranslation();
    const [programPolicyId] = useState<string | undefined>(props.match?.params?.programPolicyId ?? undefined);
    const [programId] = useState<string | undefined>(props.match?.params?.programId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);
    const [isOpenModalWarning, setIsOpenModalWarning] = useState<boolean>(false);

    const { status: createStatus } = useCreateProgramPolicyState();
    const { status: updateStatus } = useUpdateProgramPolicyState();
    const { status: detailStatus, programPolicy: detailProgramPolicy } = useDetailProgramPolicyState();
    const { status: getProgramInfoStatus, programInfo } = useGetProgramInfoState();

    const { toastLoading, closeToast, toastSuccess } = useToast();

    const [scdProgramPolicy, setScdProgramPolicy] = useState<ScdProgramPolicy>(defaultScdProgramPolicy);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const automaticPaymentAvailable: boolean =
        scdProgramPolicy.scdProgram?.productType === ProductType.PERSONAL_CREDIT &&
        scdProgramPolicy.paymentFavoredPolicy === ScdProgramPaymentFavoredPolicy.DIRECT;

    const isAutomaticTransferDispatchModeAvailable: boolean = scdProgramPolicy.paymentModePolicy === ScdProgramPaymentModePolicy.AUTOMATIC;

    const automaticDisbursementAmendmentAvailable: boolean =
        scdProgramPolicy.scdProgram?.productType === ProductType.PERSONAL_CREDIT &&
        scdProgramPolicy.paymentFavoredPolicy === ScdProgramPaymentFavoredPolicy.DIRECT;

    const handleSave = () => {
        setShowValidation(true);

        const _scdProgramPolicy: ScdProgramPolicy = {
            ...scdProgramPolicy,
        };

        if (!validateProgramPolicy(_scdProgramPolicy)) return;

        if (programPolicyId) {
            dispatch(updateProgramPolicyRequest(_scdProgramPolicy));
        } else {
            dispatch(createProgramPolicyRequest(_scdProgramPolicy));
        }
    };

    const handleChange = (value: Partial<ScdProgramPolicy>) => {
        setScdProgramPolicy(ps => ({ ...ps, ...value }));
    };

    const handleCancel = () => {
        if (!programId) {
            setIsOpenModalWarning(true);
            return;
        }

        setModalCancel(true);
    };

    useEffect(() => {
        if (!programId) return;

        if (!programPolicyId && scdProgramPolicy.scdProgram?.id) {
            dispatch(getProgramInfoRequest(scdProgramPolicy.scdProgram?.id));
        }
    }, [dispatch, programPolicyId, scdProgramPolicy.scdProgram?.id, programId]);

    useEffect(() => {
        if (!programId) return;

        dispatch(getProgramInfoRequest(Number(programId)));
    }, [programId, dispatch]);

    useEffect(() => {
        if (programPolicyId) {
            dispatch(detailProgramPolicyRequest(programPolicyId));
        }
    }, [programPolicyId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailProgramPolicy !== undefined) {
            setScdProgramPolicy(detailProgramPolicy);
        }
    }, [detailStatus, detailProgramPolicy]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailProgramPolicy !== undefined) {
            setScdProgramPolicy(detailProgramPolicy);
        }
    }, [detailStatus, detailProgramPolicy]);

    useEffect(() => {
        if (!automaticPaymentAvailable) {
            setScdProgramPolicy(ps => ({ ...ps, paymentModePolicy: ScdProgramPaymentModePolicy.MANUAL }));
        }
    }, [automaticPaymentAvailable]);

    useEffect(() => {
        if (isAutomaticTransferDispatchModeAvailable) return;

        setScdProgramPolicy(ps => ({ ...ps, transferDispatchModePolicy: ScdProgramTransferDispatchModePolicy.MANUAL }));
    }, [isAutomaticTransferDispatchModeAvailable]);

    useEffect(() => {
        if (automaticDisbursementAmendmentAvailable) return;

        setScdProgramPolicy(ps => ({ ...ps, disbursementAmendmentPolicy: ScdProgramDisbursementAmendmentolicy.MANUAL }));
    }, [automaticDisbursementAmendmentAvailable]);

    useEffect(() => {
        if (getProgramInfoStatus === HttpRequestStatus.SUCCESS && programInfo !== undefined) {
            setScdProgramPolicy(ps => ({ ...ps, scdProgram: { ...scdProgramPolicy.scdProgram, ...programInfo } }));
            dispatch(getProgramInfoResetState());
        }
    }, [dispatch, getProgramInfoStatus, scdProgramPolicy.scdProgram, detailStatus, programInfo]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [updateStatus, toastLoading]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.SUCCESS) return;

        closeToast();
        toastSuccess(t(`${I18N_PREFIX}.toast.update.success`));
        history.goBack();
    }, [updateStatus, history, closeToast, t, toastSuccess]);

    useEffect(() => {
        if (updateStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [updateStatus, closeToast]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ON_GOING) return;

        toastLoading();
    }, [createStatus, toastLoading]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS && programId) {
            toastSuccess(t(`${I18N_PREFIX}.toast.create.success`));
            closeToast();
            history.push(`/programs/${programId}/company-groups`);
        }
    }, [createStatus, history, closeToast, toastSuccess, t, programId]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.SUCCESS) return;

        if (!programId) {
            closeToast();
            toastSuccess(t(`${I18N_PREFIX}.toast.create.success`));
            history.push('/program-policies');
        }
    }, [createStatus, history, closeToast, toastSuccess, t, programId]);

    useEffect(() => {
        if (createStatus !== HttpRequestStatus.ERROR) return;

        closeToast();
    }, [createStatus, closeToast]);

    useEffect(() => {
        return () => {
            dispatch(createProgramPolicyResetState());
            dispatch(updateProgramPolicyResetState());
            dispatch(detailProgramPolicyResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-program-policy-create-update__create">
            <ContextRibbon />
            <div className="scd-program-policy-create-update--container">
                <header className="scd-program-policy-create-update--header">
                    <h2 className="scd-program-policy-create-update--header-title">
                        {!programPolicyId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && programPolicyId ? (
                    <Loading />
                ) : (
                    <form className="scd-program-policy-create-update--form">
                        {!programPolicyId && (
                            <div className="scd-program-policy-create-update--form-field">
                                <ModalSearch<ScdProgramSimple>
                                    action={searchProgramsRequest}
                                    itemSize={SEARCH_ITEM_SIZE}
                                    modalTitle={t(`${I18N_PREFIX}.input.scdProgram.modal.title`)}
                                    modalLabel={t(`${I18N_PREFIX}.input.scdProgram.modal.label`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.input.scdProgram.modal.placeholder`)}
                                    onSelect={scdProgram => handleChange({ scdProgram })}
                                    renderItem={scdProgram => scdProgram.name}
                                    statusSelector={(state: IRootState) => state.searchPrograms.status}
                                    dataSelector={(state: IRootState) => state.searchPrograms.programs}
                                    resetState={searchProgramsResetState}
                                >
                                    {handleOpen => (
                                        <>
                                            <ValidSearchInput
                                                label={`${I18N_PREFIX}.input.scdProgram.label`}
                                                showValidation={showValidation}
                                                placeholder={`${I18N_PREFIX}.input.scdProgram.placeholder`}
                                                validate={validateProgramPolicyName}
                                                value={scdProgramPolicy.scdProgram?.name ?? ''}
                                                onClick={handleOpen}
                                                onFocus={handleOpen}
                                                disabled={!!programId}
                                                readOnly
                                                externalUpdate
                                            />
                                        </>
                                    )}
                                </ModalSearch>
                            </div>
                        )}
                        <div className="scd-program-policy-create-update--form-field">
                            <SimpleSelectInput
                                label={t(`${I18N_PREFIX}.input.policyType.label`)}
                                value={scdProgramPolicy?.policyType ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramPolicyType[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.policyType.placeholder`)}
                                onChange={(policyType: string) => handleChange({ policyType: ScdProgramPolicyType[policyType] })}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramPolicyType.CREDIT_CARD}>
                                    {t(`${I18N_PREFIX}.input.policyType.options.CREDIT_CARD`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramPolicyType.DIRECT_CONSUMER_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.policyType.options.DIRECT_CONSUMER_CREDIT`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramPolicyType.PERSONAL_CREDIT}>
                                    {t(`${I18N_PREFIX}.input.policyType.options.PERSONAL_CREDIT`)}
                                </MenuItem>
                            </SimpleSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.operationApprovalPolicy.label`)}
                                value={scdProgramPolicy?.operationApprovalPolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramOperationApprovalPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.operationApprovalPolicy.placeholder`)}
                                onChange={(operationApprovalPolicy: string) =>
                                    handleChange({ operationApprovalPolicy: ScdProgramOperationApprovalPolicy[operationApprovalPolicy] })
                                }
                                externalUpdate
                                showValidation={showValidation}
                                validate={validateProgramPolicyOperationApprovalPolicy}
                            >
                                <MenuItem value={ScdProgramOperationApprovalPolicy.BANKER_APPROVAL}>
                                    {t(`${I18N_PREFIX}.input.operationApprovalPolicy.options.BANKER_APPROVAL`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramOperationApprovalPolicy.NO_APPROVAL}>
                                    {t(`${I18N_PREFIX}.input.operationApprovalPolicy.options.NO_APPROVAL`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>

                        <div className="scd-program-policy-create-update--form-field">
                            <SimpleSelectInput
                                label={t(`${I18N_PREFIX}.input.commissionPolicy.label`)}
                                value={scdProgramPolicy?.commissionPolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramCommissionPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.commissionPolicy.placeholder`)}
                                onChange={(commissionPolicy: string) =>
                                    handleChange({ commissionPolicy: ScdProgramCommissionPolicy[commissionPolicy] })
                                }
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramCommissionPolicy.DAILY}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.DAILY`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramCommissionPolicy.MONTHLY}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.MONTHLY`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramCommissionPolicy.NO_COMMISSION}>
                                    {t(`${I18N_PREFIX}.input.commissionPolicy.options.NO_COMMISSION`)}
                                </MenuItem>
                            </SimpleSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.cnabTemplate.label`)}
                                value={scdProgramPolicy?.cnabTemplate ?? ''}
                                mapperFromString={_value => (_value ? CnabTemplate[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.cnabTemplate.placeholder`)}
                                onChange={(cnabTemplate: string) => handleChange({ cnabTemplate: CnabTemplate[cnabTemplate] })}
                                showValidation={showValidation}
                                validate={validateProgramPolicyCnabTemplate}
                                externalUpdate
                            >
                                <MenuItem value={CnabTemplate.EXATO_BRADESCO_400}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.EXATO_BRADESCO_400`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.GTSEC_BRADESCO_400}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.GTSEC_BRADESCO_400`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.TIDAS_BRADESCO_400}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.TIDAS_BRADESCO_400`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.LIMELIGHT_BRADESCO_400}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.LIMELIGHT_BRADESCO_400`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.ABA_CUSTOM_444}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.ABA_CUSTOM_444`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.TOP_ONE_CUSTOM_444}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.TOP_ONE_CUSTOM_444`)}
                                </MenuItem>

                                <MenuItem value={CnabTemplate.FINAXIS_500}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.FINAXIS_500`)}
                                </MenuItem>
                                <MenuItem value={CnabTemplate.OGOCHI_CUSTOM_500}>
                                    {t(`${I18N_PREFIX}.input.cnabTemplate.options.OGOCHI_CUSTOM_500`)}
                                </MenuItem>

                                <MenuItem value={CnabTemplate.NO_CNAB}>{t(`${I18N_PREFIX}.input.cnabTemplate.options.NO_CNAB`)}</MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.paymentFavored.label`)}
                                value={scdProgramPolicy?.paymentFavoredPolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramPaymentFavoredPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.paymentFavored.placeholder`)}
                                onChange={(paymentFavoredPolicy: string) =>
                                    handleChange({ paymentFavoredPolicy: ScdProgramPaymentFavoredPolicy[paymentFavoredPolicy] })
                                }
                                showValidation={showValidation}
                                validate={validateProgramPaymentFavoredPolicy}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramPaymentFavoredPolicy.DIRECT}>
                                    {t(`${I18N_PREFIX}.input.paymentFavored.options.DIRECT`)}
                                </MenuItem>
                                <MenuItem value={ScdProgramPaymentFavoredPolicy.MANAGED}>
                                    {t(`${I18N_PREFIX}.input.paymentFavored.options.MANAGED`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.operationCollateralPolicy.label`)}
                                value={scdProgramPolicy?.operationCollateralPolicy ?? ''}
                                mapperFromString={_value => (_value ? OperationCollateralPolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.operationCollateralPolicy.placeholder`)}
                                onChange={(operationCollateralPolicy: string) =>
                                    handleChange({
                                        operationCollateralPolicy: OperationCollateralPolicy[operationCollateralPolicy],
                                    })
                                }
                                showValidation={showValidation}
                                validate={validateOperationCollateralPolicy}
                                externalUpdate
                            >
                                <MenuItem value={OperationCollateralPolicy.ACTIVE_VALIDATION}>
                                    {t(`${I18N_PREFIX}.input.operationCollateralPolicy.options.ACTIVE_VALIDATION`)}
                                </MenuItem>
                                <MenuItem value={OperationCollateralPolicy.NO_VALIDATION}>
                                    {t(`${I18N_PREFIX}.input.operationCollateralPolicy.options.NO_VALIDATION`)}
                                </MenuItem>
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.paymentModePolicy.label`)}
                                value={scdProgramPolicy?.paymentModePolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramPaymentModePolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.paymentModePolicy.placeholder`)}
                                onChange={(paymentModePolicy: string) =>
                                    handleChange({ paymentModePolicy: ScdProgramPaymentModePolicy[paymentModePolicy] })
                                }
                                showValidation={showValidation}
                                validate={validateProgramPolicyPaymentModePolicy}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramPaymentModePolicy.MANUAL}>
                                    {t(`${I18N_PREFIX}.input.paymentModePolicy.options.MANUAL`)}
                                </MenuItem>
                                {automaticPaymentAvailable && (
                                    <MenuItem value={ScdProgramPaymentModePolicy.AUTOMATIC}>
                                        {t(`${I18N_PREFIX}.input.paymentModePolicy.options.AUTOMATIC`)}
                                    </MenuItem>
                                )}
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.transferDispatchPolicy.label`)}
                                value={scdProgramPolicy?.transferDispatchModePolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramTransferDispatchModePolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.transferDispatchPolicy.placeholder`)}
                                onChange={(transferDispatchModePolicy: string) =>
                                    handleChange({
                                        transferDispatchModePolicy: ScdProgramTransferDispatchModePolicy[transferDispatchModePolicy],
                                    })
                                }
                                showValidation={showValidation}
                                validate={validateProgramPolicyTransferDispatch}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramTransferDispatchModePolicy.MANUAL}>
                                    {t(`${I18N_PREFIX}.input.transferDispatchPolicy.options.MANUAL`)}
                                </MenuItem>
                                {isAutomaticTransferDispatchModeAvailable && (
                                    <MenuItem value={ScdProgramTransferDispatchModePolicy.AUTOMATIC}>
                                        {t(`${I18N_PREFIX}.input.transferDispatchPolicy.options.AUTOMATIC`)}
                                    </MenuItem>
                                )}
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-field">
                            <ValidSelectInput
                                label={t(`${I18N_PREFIX}.input.disbursementAmendmentPolicy.label`)}
                                value={scdProgramPolicy?.disbursementAmendmentPolicy ?? ''}
                                mapperFromString={_value => (_value ? ScdProgramDisbursementAmendmentolicy[_value] : undefined)}
                                placeholder={t(`${I18N_PREFIX}.input.disbursementAmendmentPolicy.placeholder`)}
                                onChange={(disbursementAmendmentPolicy: string) =>
                                    handleChange({
                                        disbursementAmendmentPolicy: ScdProgramDisbursementAmendmentolicy[disbursementAmendmentPolicy],
                                    })
                                }
                                showValidation={showValidation}
                                validate={validateProgramDisbursementAmendment}
                                externalUpdate
                            >
                                <MenuItem value={ScdProgramDisbursementAmendmentolicy.MANUAL}>
                                    {t(`${I18N_PREFIX}.input.disbursementAmendmentPolicy.options.MANUAL`)}
                                </MenuItem>
                                {automaticDisbursementAmendmentAvailable && (
                                    <MenuItem value={ScdProgramDisbursementAmendmentolicy.AUTOMATIC}>
                                        {t(`${I18N_PREFIX}.input.disbursementAmendmentPolicy.options.AUTOMATIC`)}
                                    </MenuItem>
                                )}
                            </ValidSelectInput>
                        </div>
                        <div className="scd-program-policy-create-update--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={handleCancel} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={programPolicyId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.goBack();
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
            {isOpenModalWarning && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.warning.title`)}
                    message={t(`${I18N_PREFIX}.warning.message`)}
                    onCancel={() => setIsOpenModalWarning(false)}
                    onAction={() => {
                        setIsOpenModalWarning(false);
                        history.goBack();
                    }}
                    onClose={() => setIsOpenModalWarning(false)}
                />
            )}
        </main>
    );
};

export default ScdProgramCreateUpdatePage;
