import Loading from 'components/loading/loading';
import TechnicalSuppliersRow, {
    TechnicalSuppliersRowProps,
} from 'entities/technical-supplier/components/technical-suppliers-row/technical-suppliers-row';
import { TechnicalSupplierMoreOptionsType } from 'entities/technical-supplier/technical-supplier';
import { ScdTechnicalSupplier } from 'model/technical-supplier';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface TechnicalSuppliersListProps {
    technicalSuppliers: ScdTechnicalSupplier[];
    setModalOptionType: (value: TechnicalSupplierMoreOptionsType | undefined) => void;
    setSelectedTechnicalSupplier: (value: ScdTechnicalSupplier | undefined) => void;
    selectedTechnicalSupplier: ScdTechnicalSupplier | undefined;
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.technical-supplier';

const TechnicalSuppliersList = (props: TechnicalSuppliersListProps) => {
    const { technicalSuppliers, setModalOptionType, selectedTechnicalSupplier, setSelectedTechnicalSupplier, isLoading } = props;

    const { t } = useTranslation();

    const technicalSuppliersRowObjectData: TechnicalSuppliersRowProps = {
        technicalSuppliers,
        setModalOptionType,
        selectedTechnicalSupplier,
        setSelectedTechnicalSupplier,
    };

    return (
        <>
            {isLoading && !technicalSuppliers.length && <Loading isTable />}
            {technicalSuppliers.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={technicalSuppliersRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={technicalSuppliers.length}
                            className="page-container--table-container"
                        >
                            {TechnicalSuppliersRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-technical-supplier__table-empty-filter">
                            <span className="scd-technical-supplier__table-empty-filter-image" />
                            <span className="scd-technical-supplier__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TechnicalSuppliersList;
