import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { Page } from 'services/page';
import { OriginatorsByPaymentInstitutionToSearch } from 'model/payment-institution-account';
import { searchOriginatorsByPaymentInstitutionAccountError, searchOriginatorsByPaymentInstitutionAccountRequest, searchOriginatorsByPaymentInstitutionAccountSuccess } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/actions';
import paymentInstitutionAccountApi from 'services/api/payment-institution-account-api';
import ErrorUtils from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchOriginatorsByPaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/search-originators-payment-institution-account/types';

function* handleSearchOriginatorsByPaymentInstitutionAccount(action: ReturnType<typeof searchOriginatorsByPaymentInstitutionAccountRequest>) {
    try {
        const result: AxiosResponse<Page<OriginatorsByPaymentInstitutionToSearch>> = yield call(paymentInstitutionAccountApi.searchOriginatorsByPaymentInstitutions, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchOriginatorsByPaymentInstitutionAccountError(mapped));
            return;
        }
        yield put(searchOriginatorsByPaymentInstitutionAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchOriginatorsByPaymentInstitutionAccountError(mapped));
    }
}

function* watchSearchOriginatorsByPaymentInstitutionAccount() {
    yield takeEvery(SearchOriginatorsByPaymentInstitutionAccountTypes.SEARCH_ORIGINATORS_BY_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, handleSearchOriginatorsByPaymentInstitutionAccount);
}

function* searchOriginatorsByPaymentInstitutionAccountSaga() {
    yield all([fork(watchSearchOriginatorsByPaymentInstitutionAccount)]);
}

export default searchOriginatorsByPaymentInstitutionAccountSaga;
