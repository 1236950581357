import { ScdCompanyItem, ScdCompanyItemSimple } from 'model/company-item';
import { GiroWebappError } from 'model/error';
import { CreateCompanyItemTypes } from 'reducer/company-item/create-company-item/types';
import { action } from 'typesafe-actions';

export const createCompanyItemRequest = (companyItem: ScdCompanyItem) =>
    action(CreateCompanyItemTypes.CREATE_COMPANY_ITEM_REQUEST, companyItem);
export const createCompanyItemSuccess = (createdCompanyItem: ScdCompanyItemSimple) =>
    action(CreateCompanyItemTypes.CREATE_COMPANY_ITEM_SUCCESS, createdCompanyItem);
export const createCompanyItemError = (error: GiroWebappError) => action(CreateCompanyItemTypes.CREATE_COMPANY_ITEM_ERROR, error);
export const createCompanyItemResetState = () => action(CreateCompanyItemTypes.CREATE_COMPANY_ITEM_RESET);
