import { MenuItem } from '@material-ui/core';
import ValidIdentificationInput from 'components/inputs/identification-input/valid-identification-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidSelectInput from 'components/inputs/select-input/valid-select-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import ValidTextNumberInput from 'components/inputs/text-number-input/valid-text-number-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateCompanyAccountFavoredName,
    validateCompanyAccountFavoredIdentification,
    validateCompanyAccountBankId,
    validateCompanyAccountAgencyDigit,
    validateCompanyAccountAgencyNumber,
    validateCompanyAccountNumber,
    validateCompanyAccountDigit,
    validateCompanyAccountPixKey,
    validateCompanyAccountType,
} from 'entities/company/validations/company-accounts-validation';
import { Bank } from 'model/bank';
import { CompanyBankAccount } from 'model/company-bank-account';
import { BankAccountType } from 'model/enums/bank-account-type';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchBanksRequest, searchBanksResetState } from 'reducer/bank/search-bank/actions';

interface CompanyAccountsCardCreateProps {
    entity: CompanyBankAccount;
    onChange: (companyBankAccount: Partial<CompanyBankAccount>) => void;
    showValidation: boolean;
    alreadyAdd?: boolean;
    handleAlreadyAdd: (companyBankAccount: CompanyBankAccount, index: number) => void;
    index: number;
    checkPixKey: (pixKey: string | undefined | null) => void;
    isPixKeyDuplicated: boolean;
}

const I18N_PREFIX = 'pages.scd.company.card.accounts';

const CompanyAccountsCardCreate = (props: CompanyAccountsCardCreateProps) => {
    const { entity, onChange, showValidation, alreadyAdd, handleAlreadyAdd, index, checkPixKey, isPixKeyDuplicated } = props;

    const { t } = useTranslation();

    useEffect(() => {
        handleAlreadyAdd(entity, index);
    }, [entity, handleAlreadyAdd, index]);

    return (
        <article className="scd-company-create-update__card-manager">
            <header className="card-edition__container--header">
                <span className="card-edition__container--header-name">{t(`${I18N_PREFIX}.name`, { number: index + 1 })}</span>
            </header>
            <div className="card-edition__container--content">
                <div className="scd-company-create-update--form-card-field">
                    <ValidTextInput
                        type="text"
                        label={t(`${I18N_PREFIX}.input.favoredName.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.favoredName.placeholder`)}
                        value={entity.favoredName ?? ''}
                        onChange={favoredName => onChange({ favoredName })}
                        validate={validateCompanyAccountFavoredName}
                        showValidation={showValidation}
                        externalUpdate
                    />
                </div>
                <div className="scd-company-create-update--form-card-field">
                    <ValidIdentificationInput
                        label={t(`${I18N_PREFIX}.input.favoredIdentification.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.favoredIdentification.placeholder`)}
                        value={entity.favoredIdentification ?? ''}
                        onChange={favoredIdentification => onChange({ favoredIdentification })}
                        validate={validateCompanyAccountFavoredIdentification}
                        showValidation={showValidation}
                        externalUpdate
                    />
                </div>
                <div className="scd-company-create-update--form-card-field">
                    <ModalSearch<Bank>
                        action={searchBanksRequest}
                        itemSize={SEARCH_ITEM_SIZE_LARGE}
                        modalTitle={t(`${I18N_PREFIX}.input.bankId.title`)}
                        modalLabel={t(`${I18N_PREFIX}.input.bankId.label`)}
                        modalPlaceholder={t(`${I18N_PREFIX}.input.bankId.placeholder`)}
                        onSelect={bank => {
                            onChange({
                                bankId: bank.id,
                                bankName: bank.name,
                            });
                        }}
                        renderItem={bank => `${bank.number} - ${bank.name}`}
                        statusSelector={(state: IRootState) => state.searchBanks.status}
                        dataSelector={(state: IRootState) => state.searchBanks.banks}
                        resetState={searchBanksResetState}
                    >
                        {handleOpen => (
                            <ValidSearchInput
                                value={entity.bankName ?? ''}
                                label={t(`${I18N_PREFIX}.input.bankId.label`)}
                                placeholder={t(`${I18N_PREFIX}.input.bankId.placeholder`)}
                                validate={validateCompanyAccountBankId}
                                showValidation={showValidation}
                                onClick={handleOpen}
                                onFocus={handleOpen}
                                readOnly
                                externalUpdate
                            />
                        )}
                    </ModalSearch>
                </div>
                <div className="scd-company-create-update--form-card-double-fields">
                    <ValidTextNumberInput
                        label={t(`${I18N_PREFIX}.input.agencyNumber.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.agencyNumber.placeholder`)}
                        value={entity.agencyNumber ?? ''}
                        onChange={agencyNumber => onChange({ agencyNumber })}
                        validate={validateCompanyAccountAgencyNumber}
                        showValidation={showValidation}
                        externalUpdate
                    />
                    <ValidTextNumberInput
                        label={t(`${I18N_PREFIX}.input.agencyDigit.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.agencyDigit.placeholder`)}
                        value={entity.agencyDigit ?? ''}
                        onChange={agencyDigit => onChange({ agencyDigit })}
                        validate={validateCompanyAccountAgencyDigit}
                        showValidation={showValidation}
                        externalUpdate
                    />
                </div>
                <div className="scd-company-create-update--form-card-double-fields">
                    <ValidTextNumberInput
                        label={t(`${I18N_PREFIX}.input.accountNumber.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.accountNumber.placeholder`)}
                        value={entity.accountNumber ?? ''}
                        onChange={accountNumber => onChange({ accountNumber })}
                        validate={validateCompanyAccountNumber}
                        showValidation={showValidation}
                        externalUpdate
                    />
                    <ValidTextNumberInput
                        label={t(`${I18N_PREFIX}.input.accountDigit.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.accountDigit.placeholder`)}
                        value={entity.accountDigit ?? ''}
                        onChange={accountDigit => onChange({ accountDigit })}
                        validate={validateCompanyAccountDigit}
                        showValidation={showValidation}
                        externalUpdate
                    />
                </div>
                <div className="scd-company-create-update--form-card-field">
                    <ValidTextInput
                        type="text"
                        label={t(`${I18N_PREFIX}.input.pixKey.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.pixKey.placeholder`)}
                        value={entity.pixKey ?? ''}
                        onChange={pixKey => {
                            checkPixKey && checkPixKey(pixKey);
                            onChange({ pixKey });
                        }}
                        validate={validateCompanyAccountPixKey}
                        showValidation={showValidation}
                        externalUpdate
                    />
                </div>
                <div className="scd-company-create-update--form-card-field">
                    <ValidSelectInput
                        label={t(`${I18N_PREFIX}.input.type.label`)}
                        placeholder={t(`${I18N_PREFIX}.input.type.placeholder`)}
                        value={entity.type ?? ''}
                        mapperFromString={_value => (_value ? BankAccountType[_value] : undefined)}
                        onChange={(type: string) => onChange({ type: BankAccountType[type] })}
                        validate={validateCompanyAccountType}
                        showValidation={showValidation}
                        externalUpdate
                    >
                        <MenuItem value={BankAccountType.CHECKING_ACCOUNT}>
                            {t(`${I18N_PREFIX}.input.type.options.CHECKING_ACCOUNT`)}
                        </MenuItem>
                        <MenuItem value={BankAccountType.SAVINGS_ACCOUNT}>
                            {t(`${I18N_PREFIX}.input.type.options.SAVINGS_ACCOUNT`)}
                        </MenuItem>
                    </ValidSelectInput>
                </div>
                {alreadyAdd && (
                    <span className="scd-company-create-update--form-card--field-error">{t(`${I18N_PREFIX}.validate.alreadyAdd`)}</span>
                )}
                {isPixKeyDuplicated && (
                    <span className="scd-company-create-update--form-card--field-error">
                        {t(`${I18N_PREFIX}.validate.duplicatedPixKey`)}
                    </span>
                )}
            </div>
        </article>
    );
};

export default CompanyAccountsCardCreate;
