import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationSimple } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { retryDefinePaymentImportedOperationError, retryDefinePaymentImportedOperationRequest, retryDefinePaymentImportedOperationSuccess } from './actions';
import { RetryDefinePaymentImportedOperationTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleRetryDefinePaymentImportedOperation(action: ReturnType<typeof retryDefinePaymentImportedOperationRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationSimple> = yield call(importedOperationApi.retryDefinePayment, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(retryDefinePaymentImportedOperationError(mapped));
            return;
        }
        yield put(retryDefinePaymentImportedOperationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(retryDefinePaymentImportedOperationError(mapped));
    }
}

function* watchRetryDefinePaymentImportedOperation() {
    yield takeLeading(RetryDefinePaymentImportedOperationTypes.RETRY_DEFINE_PAYMENT_IMPORTED_OPERATION_REQUEST, handleRetryDefinePaymentImportedOperation);
}

function* retryDefinePaymentImportedOperationSaga() {
    yield all([fork(watchRetryDefinePaymentImportedOperation)]);
}

export default retryDefinePaymentImportedOperationSaga;
