import { Checkbox, FormControlLabel } from '@material-ui/core';
import ValidLocalDateInput from 'components/inputs/date-input/valid-local-date-input';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ValidSessionTaxInput from 'entities/batch/components/inputs/valid-session-tax-input/valid-session-tax-input';
import {
    validateGenerateCnabSessionDate,
    validateGenerateCnabSessionTax,
    validateScdGenerateCnabSession,
} from 'entities/batch/components/validation/generate-cnab-session-validation';
import { CnabTemplate } from 'model/enums/cnab-template';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdSessionInfo } from 'model/session';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateCnabBatchRequest, generateCnabBatchResetState } from 'reducer/cnab/generate-cnab-batch/actions';
import { useGenerateCnabBatchReportState, useRootDispatch } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import './batch-generate-cnab-modal.scss';

interface BatchGenerateCnabModalProps {
    batchId: number | undefined;
    cnabTemplate?: CnabTemplate | undefined | null;
    isOpenModal: boolean;
    setIsOpenModal: (value: boolean) => void;
    onSuccess: () => void;
}

const I18N_PREFIX = 'pages.scd.batch.detail.modal.generate-cnab';

export const BatchGenerateCnabModal = (props: BatchGenerateCnabModalProps) => {
    const { isOpenModal, setIsOpenModal, batchId, cnabTemplate, onSuccess } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const { toastLoading, toastSuccess, closeToast } = useToast();

    const { status: generateCnabStatus } = useGenerateCnabBatchReportState();

    const [sessionInfo, setSessionInfo] = useState<ScdSessionInfo | null>(null);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [isSelectedSessionInfo, setIsSelectedSessionInfo] = useState<boolean>(false);

    useEffect(() => {
        if (!isSelectedSessionInfo) {
            setSessionInfo(null);
        }
    }, [isSelectedSessionInfo]);

    useEffect(() => {
        if (generateCnabStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
        }
        if (generateCnabStatus === HttpRequestStatus.ERROR) {
            closeToast();
            setIsOpenModal(false);
            setSessionInfo(null);
            setIsSelectedSessionInfo(false);
            dispatch(generateCnabBatchResetState());
        }
        if (generateCnabStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            dispatch(generateCnabBatchResetState());
            setIsOpenModal(false);
            setSessionInfo(null);
            setIsSelectedSessionInfo(false);
            toastSuccess(t('pages.scd.batch.generate-cnab.toast.success'));

            onSuccess();
        }
    }, [generateCnabStatus, toastSuccess, t, dispatch, toastLoading, closeToast, setIsOpenModal, onSuccess]);

    if (!batchId) {
        return <> </>;
    }

    const handleCloseModal = () => {
        setIsOpenModal(false);
    };

    const handleGenerateCnab = () => {
        if (isSelectedSessionInfo && !validateScdGenerateCnabSession(sessionInfo)) {
            return setShowValidation(true);
        }

        dispatch(generateCnabBatchRequest({ batchId, sessionInfo }));
    };

    const handleCheckbox = () => {
        setShowValidation(false);
        setIsSelectedSessionInfo(!isSelectedSessionInfo);
    };

    const isModalMessageButtonDisabled = generateCnabStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isOpenModal && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.title`)}
                    onClose={handleCloseModal}
                    onCancel={handleCloseModal}
                    onAction={handleGenerateCnab}
                    standardButtonLabel={t('entity.action.generate')}
                    disabled={isModalMessageButtonDisabled}
                >
                    <div className="batch-generate-report-modal--content">
                        <div className="batch-generate-report-modal--content-field__checkbox">
                            <FormControlLabel
                                control={<Checkbox onChange={() => handleCheckbox()} />}
                                label={t(`${I18N_PREFIX}.input.checkbox.label`)}
                            />
                        </div>
                        {isSelectedSessionInfo && (
                            <>
                                <div className="batch-generate-report-modal--content-field">
                                    <ValidLocalDateInput
                                        value={sessionInfo?.date ?? ''}
                                        label={t(`${I18N_PREFIX}.input.session-date.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.session-date.label`)}
                                        onChange={sessionDate => setSessionInfo({ ...sessionInfo, date: sessionDate })}
                                        showValidation={showValidation}
                                        validate={validateGenerateCnabSessionDate}
                                        disabled={!isSelectedSessionInfo}
                                    />
                                </div>
                                <div className="batch-generate-report-modal--content-field">
                                    <ValidSessionTaxInput
                                        value={sessionInfo?.tax!}
                                        label={t(`${I18N_PREFIX}.input.session-tax.label`)}
                                        placeholder={t(`${I18N_PREFIX}.input.session-tax.label`)}
                                        onChange={sessionTax => setSessionInfo({ ...sessionInfo, tax: sessionTax })}
                                        disabled={!isSelectedSessionInfo}
                                        validate={validateGenerateCnabSessionTax}
                                        showValidation={showValidation}
                                    />
                                </div>
                            </>
                        )}
                        {<div className="batch-generate-report-modal--content-message">{t(`${I18N_PREFIX}.message.${cnabTemplate}`)}</div>}
                    </div>
                </ModalMessageLegacy>
            )}
        </>
    );
};

export default BatchGenerateCnabModal;
