import { AxiosResponse } from 'axios';
import { ChunkStreamProcessResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import { chunkStreamProcessBcoError, chunkStreamProcessBcoRequest, chunkStreamProcessBcoSuccess } from 'reducer-bco/batch-output/chunk-stream-process/action';
import { ChunkStreamProcessBcoTypes } from 'reducer-bco/batch-output/chunk-stream-process/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleChunkStreamProcessBco(action: ReturnType<typeof chunkStreamProcessBcoRequest>) {
    try {
        const result: AxiosResponse<ChunkStreamProcessResponse> = yield call(batchOutputBcoApi.chunkStreamProcess, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(chunkStreamProcessBcoError(mapped));
            return;
        }
        yield put(chunkStreamProcessBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(chunkStreamProcessBcoError(mapped));
    }
}

function* watchChunkStreamProcessBcoSaga() {
    yield takeEvery(ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_REQUEST, handleChunkStreamProcessBco);
}

function* chunkStreamProcessBcoSaga() {
    yield all([fork(watchChunkStreamProcessBcoSaga)]);
}

export default chunkStreamProcessBcoSaga;
