import { ScdBatchToRelocateResponse } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum RelocateToExistingBatchTypes {
    RELOCATE_TO_EXISTING_BATCH_REQUEST = '@@relocate-to-existing-batch/RELOCATE_TO_EXISTING_BATCH_REQUEST',
    RELOCATE_TO_EXISTING_BATCH_SUCCESS = '@@relocate-to-existing-batch/RELOCATE_TO_EXISTING_BATCH_SUCCESS',
    RELOCATE_TO_EXISTING_BATCH_ERROR = '@@relocate-to-existing-batch/RELOCATE_TO_EXISTING_BATCH_ERROR',
    RELOCATE_TO_EXISTING_BATCH_RESET = '@@relocate-to-existing-batch/RELOCATE_TO_EXISTING_BATCH_RESET',
}

export interface RelocateToExistingBatchState extends BaseStoreState {
    readonly relocatedBatch?: ScdBatchToRelocateResponse;
}
