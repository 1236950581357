import Loading from 'components/loading/loading';
import CompanyGroupRow, { CompanyGroupRowProps } from 'entities/company-group/components/company-group-row/company-group-row';
import { ScdCompanyGroupSimple } from 'model/company-group';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface CompanyGroupListProps {
    companyGroup: ScdCompanyGroupSimple[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.company-group.table';

const CompanyGroupList = (props: CompanyGroupListProps) => {
    const { companyGroup, isLoading } = props;

    const { t } = useTranslation();

    const companyGroupRowObjectData: CompanyGroupRowProps = {
        companyGroup,
    };

    return (
        <>
            {isLoading && !companyGroup.length && <Loading isTable />}
            {companyGroup.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={companyGroupRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={companyGroup.length}
                            className="page-container--table-container"
                        >
                            {CompanyGroupRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-company-group__table-empty-filter">
                            <span className="scd-company-group__table-empty-filter-image" />
                            <span className="scd-company-group__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CompanyGroupList;
