import { IssuerPoliciesList } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ActivePoliciesSummaryTypes {
    ACTIVE_POLICIES_SUMMARY_REQUEST = '@@active-policies-summary/ACTIVE_POLICIES_SUMMARY_REQUEST',
    ACTIVE_POLICIES_SUMMARY_SUCCESS = '@@active-policies-summary/ACTIVE_POLICIES_SUMMARY_SUCCESS',
    ACTIVE_POLICIES_SUMMARY_ERROR = '@@active-policies-summary/ACTIVE_POLICIES_SUMMARY_ERROR',
    ACTIVE_POLICIES_SUMMARY_RESET_STATE = '@@active-policies-summary/ACTIVE_POLICIES_SUMMARY_RESET_STATE',
}

export interface ActivePoliciesSummaryState extends BaseStoreState {
    readonly issuerPolicy?: IssuerPoliciesList;
}
