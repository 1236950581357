import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListTaxesTypes, ListTaxesState } from 'reducer/tax/list-taxes/types';
import { Reducer } from 'redux';

export const initialState: ListTaxesState = {
    status: HttpRequestStatus.NOOP,
    taxes: undefined,
    error: undefined,
};

const reducer: Reducer<ListTaxesState> = (state = initialState, action): ListTaxesState => {
    switch (action.type) {
        case ListTaxesTypes.LIST_TAXES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListTaxesTypes.LIST_TAXES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, taxes: action.payload, error: undefined };
        }
        case ListTaxesTypes.LIST_TAXES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListTaxesTypes.LIST_TAXES_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listTaxesReducer };
