import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdPaymentTransactionsLegacyByBatch } from 'model/payment-transaction-by-batch';
import { markError } from 'reducer/application/error/actions';
import {
    listDisbursementTransactionsByBatchLegacyError,
    listDisbursementTransactionsByBatchLegacyRequest,
    listDisbursementTransactionsByBatchLegacySuccess,
} from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/actions';
import { ListPaymentTransactionsByBatchLegacyTypes } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import paymentTransactionByBatchAPI from 'services/api/payment-transaction-by-batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleListPaymentTransactionsLegacyByBatch(action: ReturnType<typeof listDisbursementTransactionsByBatchLegacyRequest>) {
    try {
        const result: AxiosResponse<ScdPaymentTransactionsLegacyByBatch> = yield call(
            paymentTransactionByBatchAPI.listPaymentTransactionsByBatchLegacy,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listDisbursementTransactionsByBatchLegacyError(mapped));
            return;
        }
        yield put(listDisbursementTransactionsByBatchLegacySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listDisbursementTransactionsByBatchLegacyError(mapped));
    }
}

function* watchListPaymentTransactionsLegacyByBatch() {
    yield takeEvery(ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST, handleListPaymentTransactionsLegacyByBatch);
}

function* listPaymentTransactionsByBatchLegacySaga() {
    yield all([fork(watchListPaymentTransactionsLegacyByBatch)]);
}

export default listPaymentTransactionsByBatchLegacySaga;
