import { Grid, GridSize, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import FormatterUtils from 'components/formatter/formatter-utils';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import DateRangePicker from 'components/inputs/date-input/date-range-picker/date-range-picker';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import SimpleTextInput from 'components/inputs/text-input/text-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalSearch from 'components/modals/modal-search/modal-search';
import { validateCompanyAccountBankId } from 'entities/company/validations/company-accounts-validation';
import isEqual from 'lodash/isEqual';
import { Bank } from 'model/bank';
import { PaymentTransactionMode } from 'model/enums/payment-transaction-mode';
import { PaymentTransactionStatus } from 'model/enums/payment-transaction-status';
import { PaymentTransactionType } from 'model/enums/payment-transaction-type';
import { PaymentTransactionDispatchMode } from 'model/enums/payment-transactions-dispatch-mode';
import { DateRange } from 'model/imported-operation';
import { ScdOriginatorSearch } from 'model/originator';
import { PaymentTransactionFilterRequest } from 'model/payment-transaction';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchBanksRequest, searchBanksResetState } from 'reducer/bank/search-bank/actions';
import { searchOriginatorsRequest } from 'reducer/originator/search-originators/actions';
import { valuesOfObject } from 'shared/util/object-utils';
import './payment-transaction-modal-filter.scss';

export type PaymentTransactionModalFilterType =
    | 'filters'
    | 'date'
    | 'amount'
    | 'favored'
    | 'status'
    | 'originatorId'
    | 'mode'
    | 'dispatchMode'
    | 'type'
    | 'bankIdentification';

export interface PaymentTransactionModalFilterProps {
    onClose: () => void;
    modalFilter: PaymentTransactionModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: PaymentTransactionFilterRequest;
    setFilterActive: (value: PaymentTransactionFilterRequest) => void;
    setPage: (value: number) => void;
    resetInfiniteScrollListing: () => void;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.filter.input';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    date: filter => !filter.date,
    amount: filter => !filter.amount,
    favored: filter => !filter.favored,
    status: filter => !filter.status,
    originatorId: filter => !filter.originatorId,
    mode: filter => !filter.mode,
    dispatchMode: filter => !filter.dispatchMode,
    type: filter => !filter.type,
    bankIdentification: filter => !filter.bankIdentification,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
} as Record<PaymentTransactionModalFilterType, (filter: PaymentTransactionFilterRequest) => boolean>;

export const PaymentTransactionModalFilter = (props: PaymentTransactionModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage, resetInfiniteScrollListing } = props;

    const [filterRequest, setFilterRequest] = useState<PaymentTransactionFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    const checkResetList = useCallback(() => {
        if (isEqual(filterActive, filterRequest)) return;

        resetInfiniteScrollListing();
    }, [filterActive, filterRequest, resetInfiniteScrollListing]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        checkResetList();
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<PaymentTransactionFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        amount: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, amount: undefined });
            setFilterRequest({ ...filterRequest, amount: undefined });
        },
        filters: () => {
            resetInfiniteScrollListing();
            setFilterRequest({});
            setFilterActive({});
        },
        date: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, date: undefined });
            setFilterRequest({ ...filterRequest, date: undefined });
        },
        favored: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, favored: undefined });
            setFilterRequest({ ...filterRequest, favored: undefined });
        },
        status: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, status: undefined });
            setFilterRequest({ ...filterRequest, status: undefined });
        },
        originatorId: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, originatorId: undefined, originatorName: undefined });
            setFilterRequest({ ...filterRequest, originatorId: undefined, originatorName: undefined });
        },
        mode: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, mode: undefined });
            setFilterRequest({ ...filterRequest, mode: undefined });
        },
        type: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, type: undefined });
            setFilterRequest({ ...filterRequest, type: undefined });
        },
        dispatchMode: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, dispatchMode: undefined });
            setFilterRequest({ ...filterActive, dispatchMode: undefined });
        },
        bankIdentification: () => {
            resetInfiniteScrollListing();
            setFilterActive({ ...filterActive, bankIdentification: undefined, bankName: undefined });
            setFilterRequest({ ...filterActive, bankIdentification: undefined, bankName: undefined });
        },
    } as Record<PaymentTransactionModalFilterType, () => void>;

    const isAllFiltersModalOpen: boolean = modalFilter === 'filters';

    const halfOrFullWidthFilter: GridSize = isAllFiltersModalOpen ? 6 : 12;

    const oneQuarterOrHalfWidthFilter: GridSize = isAllFiltersModalOpen ? 3 : 6;

    const handleChangeDate = (newDateRange: DateRange) => {
        setFilterRequest(prevState => ({
            ...prevState,
            date: {
                start: FormatterUtils.formatDateToLocalDate(newDateRange.start) ?? undefined,
                end: FormatterUtils.formatDateToLocalDate(newDateRange.end) ?? undefined,
            },
        }));
    };

    const FavoredFilter: JSX.Element = (
        <Grid item xs={halfOrFullWidthFilter}>
            <SimpleTextInput
                label={t(`${I18N_PREFIX}.favored`)}
                placeholder={t(`${I18N_PREFIX}.favoredPlaceholder`)}
                value={filterRequest?.favored}
                onChange={favored => handleChange({ favored })}
                externalUpdate
            />
        </Grid>
    );

    const DateFilter: JSX.Element = (
        <Grid item xs={halfOrFullWidthFilter}>
            <DateRangePicker
                dateRange={{
                    start: filterRequest.date?.start ? FormatterUtils.validDateWithoutTimezone(filterRequest.date.start) : null,
                    end: filterRequest.date?.end ? FormatterUtils.validDateWithoutTimezone(filterRequest.date.end) : null,
                }}
                setDateRange={handleChangeDate}
            />
        </Grid>
    );

    const OriginatorFilter: JSX.Element = (
        <Grid item xs={halfOrFullWidthFilter}>
            <ListSearchInput<ScdOriginatorSearch>
                action={searchOriginatorsRequest}
                itemSize={SEARCH_ITEM_SIZE_LARGE}
                onSelect={originator => {
                    handleChange({ originatorId: Number(originator.id), originatorName: originator.name });
                }}
                renderItem={originator => `${originator.id} ${originator?.name}`}
                statusSelector={(state: IRootState) => state.searchOriginators.status}
                dataSelector={(state: IRootState) => state.searchOriginators.originators}
                label={t(`${I18N_PREFIX}.originator`)}
                placeholder={t(`${I18N_PREFIX}.originator`)}
                value={filterRequest.originatorId ? `${filterRequest.originatorId} - ${filterRequest.originatorName}` : ''}
            />
        </Grid>
    );

    const BankIdentifierFilter: JSX.Element = (
        <ModalSearch<Bank>
            action={searchBanksRequest}
            itemSize={SEARCH_ITEM_SIZE_LARGE}
            modalTitle={t(`${I18N_PREFIX}.bankIdentification.title`)}
            modalLabel={t(`${I18N_PREFIX}.bankIdentification.label`)}
            modalPlaceholder={t(`${I18N_PREFIX}.bankIdentification.placeholder`)}
            onSelect={bank => {
                if (bank.identification) {
                    handleChange({ bankIdentification: bank.identification, bankName: bank.name });
                }
            }}
            renderItem={bank => (
                <Tooltip title={!bank.identification ? t(`${I18N_PREFIX}.bankIdentification.noISPB`) : ''}>
                    <span className={!bank.identification ? 'bank-tooltip' : ''}>{`${bank.number} - ${bank.name}`}</span>
                </Tooltip>
            )}
            statusSelector={(state: IRootState) => state.searchBanks.status}
            dataSelector={(state: IRootState) => state.searchBanks.banks}
            resetState={searchBanksResetState}
        >
            {handleOpen => (
                <ValidSearchInput
                    value={filterRequest.bankName ?? ''}
                    label={t(`${I18N_PREFIX}.bankIdentification.label`)}
                    placeholder={t(`${I18N_PREFIX}.bankIdentification.placeholder`)}
                    validate={validateCompanyAccountBankId}
                    showValidation={false}
                    onClick={handleOpen}
                    onFocus={handleOpen}
                    readOnly
                    externalUpdate
                />
            )}
        </ModalSearch>
    );

    const AmountFilter: JSX.Element = (
        <>
            <Grid item xs={oneQuarterOrHalfWidthFilter}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.minValue`)}
                    placeholder={t(`${I18N_PREFIX}.minValue`)}
                    onChange={minValue => handleChange({ amount: { ...filterRequest.amount, minValue } })}
                    value={filterRequest?.amount?.minValue}
                    externalUpdate
                />
            </Grid>
            <Grid item xs={oneQuarterOrHalfWidthFilter}>
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.maxValue`)}
                    placeholder={t(`${I18N_PREFIX}.maxValue`)}
                    onChange={maxValue => handleChange({ amount: { ...filterRequest.amount, maxValue } })}
                    value={filterRequest?.amount?.maxValue}
                    externalUpdate
                />
            </Grid>
        </>
    );

    const StatusFilter: JSX.Element = (
        <>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    PENDING: filterRequest.status === PaymentTransactionStatus.PENDING,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.PENDING })}
            >
                {t(`${I18N_PREFIX}.status.options.PENDING`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    VALIDATING: filterRequest.status === PaymentTransactionStatus.VALIDATING,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.VALIDATING })}
            >
                {t(`${I18N_PREFIX}.status.options.VALIDATING`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    ARCHIVED: filterRequest.status === PaymentTransactionStatus.ARCHIVED,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.ARCHIVED })}
            >
                {t(`${I18N_PREFIX}.status.options.ARCHIVED`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    WAITING: filterRequest.status === PaymentTransactionStatus.WAITING,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.WAITING })}
            >
                {t(`${I18N_PREFIX}.status.options.WAITING`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    CONFIRMED: filterRequest.status === PaymentTransactionStatus.CONFIRMED,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.CONFIRMED })}
            >
                {t(`${I18N_PREFIX}.status.options.CONFIRMED`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    ERROR: filterRequest.status === PaymentTransactionStatus.ERROR,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.ERROR })}
            >
                {t(`${I18N_PREFIX}.status.options.ERROR`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    INCONSISTENT: filterRequest.status === PaymentTransactionStatus.INCONSISTENT,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.INCONSISTENT })}
            >
                {t(`${I18N_PREFIX}.status.options.INCONSISTENT`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-status--label', {
                    UNRESOLVED: filterRequest.status === PaymentTransactionStatus.UNRESOLVED,
                })}
                onClick={() => handleChange({ status: PaymentTransactionStatus.UNRESOLVED })}
            >
                {t(`${I18N_PREFIX}.status.options.UNRESOLVED`).toLocaleUpperCase()}
            </div>
        </>
    );

    const ModeFilter: JSX.Element = (
        <>
            <div
                className={clsx('payment-transaction-modal-filter--container-mode--label', {
                    MANUAL: filterRequest.mode === PaymentTransactionMode.MANUAL,
                })}
                onClick={() => handleChange({ mode: PaymentTransactionMode.MANUAL })}
            >
                {t(`${I18N_PREFIX}.mode.options.MANUAL`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-mode--label', {
                    AUTOMATIC: filterRequest.mode === PaymentTransactionMode.AUTOMATIC,
                })}
                onClick={() => handleChange({ mode: PaymentTransactionMode.AUTOMATIC })}
            >
                {t(`${I18N_PREFIX}.mode.options.AUTOMATIC`).toLocaleUpperCase()}
            </div>
        </>
    );

    const DispatchModeFilter: JSX.Element = (
        <>
            <div
                className={clsx('payment-transaction-modal-filter--container-mode--label', {
                    MANUAL_DISPATCH: filterRequest.dispatchMode === PaymentTransactionDispatchMode.MANUAL,
                })}
                onClick={() => handleChange({ dispatchMode: PaymentTransactionDispatchMode.MANUAL })}
            >
                {t(`${I18N_PREFIX}.dispatchMode.options.MANUAL`).toLocaleUpperCase()}
            </div>
            <div
                className={clsx('payment-transaction-modal-filter--container-mode--label', {
                    AUTOMATIC_DISPATCH: filterRequest.dispatchMode === PaymentTransactionDispatchMode.AUTOMATIC,
                })}
                onClick={() => handleChange({ dispatchMode: PaymentTransactionDispatchMode.AUTOMATIC })}
            >
                {t(`${I18N_PREFIX}.dispatchMode.options.AUTOMATIC`).toLocaleUpperCase()}
            </div>
        </>
    );

    // DEPRECATED: MONTHLY_COMMISSION will be removed in the future
    const typeList: PaymentTransactionType[] = Object.values(PaymentTransactionType).filter(
        type => type !== PaymentTransactionType.MONTHLY_COMMISSION
    );
    // TODO: Bring a scenario of improvements and bug fixes in the selectable filters
    const TypeFilter: JSX.Element = (
        <>
            {typeList.map(type => (
                <button
                    key={type}
                    className={clsx('payment-transaction-modal-filter--container-type--label', {
                        [type]: filterRequest.type === PaymentTransactionType[type],
                    })}
                    onClick={() => handleChange({ type: !filterRequest.type ? PaymentTransactionType[type] : undefined })}
                >
                    {t(`${I18N_PREFIX}.type.options.${type}`).toLocaleUpperCase()}
                </button>
            ))}
        </>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);

    return (
        openModal && (
            <Modal isOpen toggle={onClose}>
                <div
                    className={clsx('payment-transaction-modal-filter--container', {
                        ALL_FILTERS_OPEN: isAllFiltersModalOpen,
                    })}
                >
                    <div className="payment-transaction-modal-filter--header">
                        <label className="payment-transaction-modal-filter--header-title">{title}</label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    filters: (
                                        <>
                                            {FavoredFilter}
                                            {DateFilter}
                                            {OriginatorFilter}
                                            {AmountFilter}
                                            <Grid item xs={12}>
                                                <span className="payment-transaction-modal-filter--label">
                                                    {t(`${I18N_PREFIX}.status.label`)}
                                                </span>
                                            </Grid>
                                            <div className="payment-transaction-modal-filter--status">{StatusFilter}</div>
                                            <Grid item xs={12}>
                                                <span className="payment-transaction-modal-filter--label">
                                                    {t(`${I18N_PREFIX}.type.label`)}
                                                </span>
                                            </Grid>
                                            {TypeFilter}
                                        </>
                                    ),
                                    date: DateFilter,
                                    amount: AmountFilter,
                                    favored: FavoredFilter,
                                    originatorId: OriginatorFilter,
                                    status: StatusFilter,
                                    mode: ModeFilter,
                                    type: TypeFilter,
                                    bankIdentification: BankIdentifierFilter,
                                } as Record<PaymentTransactionModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>
                    <div className="payment-transaction-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default PaymentTransactionModalFilter;
