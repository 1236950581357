import { ScdBatchDisbursementReport } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchDisbursementReportTypes } from 'reducer/batch/disbursement-report/types';
import { action } from 'typesafe-actions';

export const batchDisbursementReportRequest = (batchId: number) =>
    action(BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_REQUEST, batchId);
export const batchDisbursementReportSuccess = (batchDisbursementReport: ScdBatchDisbursementReport) =>
    action(BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_SUCCESS, batchDisbursementReport);
export const batchDisbursementReportError = (error: GiroWebappError) =>
    action(BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_ERROR, error);
export const batchDisbursementReportResetState = () => action(BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_RESET);
