import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const ManualDisbursementValidationError = () => {
    const { t } = useTranslation();

    return (
        <div className="scd-payment-transaction__detail--form-errors">
            <label className="scd-payment-transaction__detail--form-errors__label">{t(`${I18N_PREFIX}.validation-error`)}</label>
        </div>
    );
};

export default ManualDisbursementValidationError;
