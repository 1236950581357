import { ScdBanker } from 'model/banker';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailBankerTypes {
    DETAIL_BANKER_REQUEST = '@@banker-detail/DETAIL_BANKER_REQUEST',
    DETAIL_BANKER_SUCCESS = '@@banker-detail/DETAIL_BANKER_SUCCESS',
    DETAIL_BANKER_ERROR = '@@banker-detail/DETAIL_BANKER_ERROR',
    DETAIL_BANKER_RESET_STATE = '@@banker-detail/DETAIL_BANKER_RESET_STATE',
}

export interface DetailBankerState extends BaseStoreState {
    readonly banker?: ScdBanker;
}
