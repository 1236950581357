import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ExportBatchTypes, ExportBatchState } from 'reducer/batch/export-batch/types';

const initialState: ExportBatchState = {
    status: HttpRequestStatus.NOOP,
    exportedBatch: undefined,
    error: undefined,
};

const reducer: Reducer<ExportBatchState> = (state = initialState, action): ExportBatchState => {
    switch (action.type) {
        case ExportBatchTypes.EXPORT_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ExportBatchTypes.EXPORT_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, exportedBatch: action.payload, error: undefined };
        }
        case ExportBatchTypes.EXPORT_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ExportBatchTypes.EXPORT_BATCH_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as exportBatchReducer };
