import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Disbursement report
import { importedOperationDisbursementReportReducer } from 'reducer/imported-operation/disbursement-report/reducer';
import importedOperationDisbursementReportSaga from 'reducer/imported-operation/disbursement-report/sagas';
import { ImportedOperationDisbursementReportState } from 'reducer/imported-operation/disbursement-report/types';

// Batch
import { importedOperationsBatchReducer } from 'reducer/imported-operation/batch/reducer';
import importedOperationsBatchSaga from 'reducer/imported-operation/batch/sagas';
import { ImportedOperationsBatchState } from 'reducer/imported-operation/batch/types';

// Reexport
import { importedOperationsReexportReducer } from 'reducer/imported-operation/reexport/reducer';
import importedOperationsReexportSaga from 'reducer/imported-operation/reexport/sagas';
import { ImportedOperationsReexportState } from 'reducer/imported-operation/reexport/types';

// Reexport All
import { importedOperationsReexportAllReducer } from 'reducer/imported-operation/reexport-all/reducer';
import importedOperationsReexportAllSaga from 'reducer/imported-operation/reexport-all/sagas';
import { ImportedOperationsReexportAllState } from 'reducer/imported-operation/reexport-all/types';

// List
import { importedOperationsListReducer } from 'reducer/imported-operation/list-operations/reducer';
import importedOperationsListSaga from 'reducer/imported-operation/list-operations/sagas';
import { ImportedOperationsListState } from 'reducer/imported-operation/list-operations/types';

// Detail
import { importedOperationDetailReducer } from 'reducer/imported-operation/detail/reducer';
import importedOperationDetailSaga from 'reducer/imported-operation/detail/sagas';
import { ImportedOperationDetailState } from 'reducer/imported-operation/detail/types';

// Document
import { importedOperationDocumentReducer } from 'reducer/imported-operation/document/reducer';
import importedOperationDocumentSaga from 'reducer/imported-operation/document/sagas';
import { ImportedOperationDocumentState } from 'reducer/imported-operation/document/types';

// To create batch
import { importedOperationsToCreateBatchReducer } from 'reducer/imported-operation/to-create-batch/reducer';
import importedOperationsToCreateBatchSaga from 'reducer/imported-operation/to-create-batch/sagas';
import { ImportedOperationsToCreateBatchState } from 'reducer/imported-operation/to-create-batch/types';

// Errors Pending
import { getImportedOperationsErrorsPendingReducer } from 'reducer/imported-operation/get-errors-pending/reducer';
import getImportedOperationsErrorPendingSaga from 'reducer/imported-operation/get-errors-pending/sagas';
import { GetImportedOperationErrorsPendingState } from './get-errors-pending/types';

// Retry Preprocess
import { retryPreprocessImportedOperationReducer } from 'reducer/imported-operation/retry-preprocess-imported-operation/reducer';
import retryPreprocessImportedOperationSaga from 'reducer/imported-operation/retry-preprocess-imported-operation/sagas';
import { RetryPreprocessImportedOperationState } from './retry-preprocess-imported-operation/types';

// Retry Define Payment
import { retryDefinePaymentImportedOperationReducer } from 'reducer/imported-operation/retry-define-payment-imported-operation/reducer';
import retryDefinePaymentImportedOperationSaga from 'reducer/imported-operation/retry-define-payment-imported-operation/sagas';
import { RetryDefinePaymentImportedOperationState } from './retry-define-payment-imported-operation/types';

// Retry Define Flow
import { retryDefineFlowImportedOperationReducer } from 'reducer/imported-operation/retry-define-flow-imported-operation/reducer';
import retryDefineFlowImportedOperationSaga from 'reducer/imported-operation/retry-define-flow-imported-operation/sagas';
import { RetryDefineFlowImportedOperationState } from 'reducer/imported-operation/retry-define-flow-imported-operation/types';

// Archive One
import { importedOperationsArchiveOneReducer } from 'reducer/imported-operation/archive-one/reducer';
import importedOperationsArchiveOneSaga from 'reducer/imported-operation/archive-one/sagas';
import { ImportedOperationsArchiveOneState } from 'reducer/imported-operation/archive-one/types';

// Archive Many
import { importedOperationsArchiveManyReducer } from 'reducer/imported-operation/archive-many/reducer';
import importedOperationsArchiveManySaga from 'reducer/imported-operation/archive-many/sagas';
import { ImportedOperationsArchiveManyState } from 'reducer/imported-operation/archive-many/types';

// Retry process automatic payment
import { retryProcessAutomaticPaymentImportedOperationReducer } from 'reducer/imported-operation/retry-process-automatic-payment/reducer';
import retryProcessAutomaticPaymentImportedOperationSaga from 'reducer/imported-operation/retry-process-automatic-payment/sagas';
import { RetryProcessAutomaticPaymentImportedOperationState } from 'reducer/imported-operation/retry-process-automatic-payment/types';

// Monthly Processed Operations Report
import { monthlyProcessedOperationsReportReducer } from 'reducer/imported-operation/monthly-processed-operations-report/reducer';
import monthlyProcessedOperationsReportSaga from 'reducer/imported-operation/monthly-processed-operations-report/sagas';
import { MonthlyProcessedImportedOperationsReportState } from 'reducer/imported-operation/monthly-processed-operations-report/types';

// Fare Amount Report
import { fareAmountReportReducer } from 'reducer/imported-operation/fare-amount-report/reducer';
import fareAmountReportSaga from 'reducer/imported-operation/fare-amount-report/sagas';
import { FareAmountReportState } from 'reducer/imported-operation/fare-amount-report/types';
export interface ImportedOperationState {
    readonly importedOperationsDisbursementReport: ImportedOperationDisbursementReportState;
    readonly importedOperationsBatch: ImportedOperationsBatchState;
    readonly importedOperationReexport: ImportedOperationsReexportState;
    readonly importedOperationReexportAll: ImportedOperationsReexportAllState;
    readonly importedOperationsList: ImportedOperationsListState;
    readonly importedOperationDetail: ImportedOperationDetailState;
    readonly importedOperationDocument: ImportedOperationDocumentState;
    readonly importedOperationsToCreateBatch: ImportedOperationsToCreateBatchState;
    readonly importedOperationsErrorsPending: GetImportedOperationErrorsPendingState;
    readonly retryPreprocessImportedOperation: RetryPreprocessImportedOperationState
    readonly retryDefinePaymentImportedOperation: RetryDefinePaymentImportedOperationState,
    readonly retryDefineFlowImportedOperation: RetryDefineFlowImportedOperationState
    readonly importedOperationArchiveOne: ImportedOperationsArchiveOneState
    readonly importedOperationArchiveMany: ImportedOperationsArchiveManyState
    readonly retryProcessAutomaticPayment: RetryProcessAutomaticPaymentImportedOperationState
    readonly monthlyProcessedOperationsReport: MonthlyProcessedImportedOperationsReportState
    readonly fareAmountReport: FareAmountReportState
}

export const ImportedOperationReducer: ReducersMapObject<ImportedOperationState, AnyAction> = {
    importedOperationsDisbursementReport: importedOperationDisbursementReportReducer,
    importedOperationsBatch: importedOperationsBatchReducer,
    importedOperationReexport: importedOperationsReexportReducer,
    importedOperationReexportAll: importedOperationsReexportAllReducer,
    importedOperationsList: importedOperationsListReducer,
    importedOperationDetail: importedOperationDetailReducer,
    importedOperationDocument: importedOperationDocumentReducer,
    importedOperationsToCreateBatch: importedOperationsToCreateBatchReducer,
    importedOperationsErrorsPending: getImportedOperationsErrorsPendingReducer,
    retryPreprocessImportedOperation: retryPreprocessImportedOperationReducer,
    retryDefinePaymentImportedOperation: retryDefinePaymentImportedOperationReducer,
    retryDefineFlowImportedOperation: retryDefineFlowImportedOperationReducer,
    importedOperationArchiveOne: importedOperationsArchiveOneReducer,
    importedOperationArchiveMany: importedOperationsArchiveManyReducer,
    retryProcessAutomaticPayment: retryProcessAutomaticPaymentImportedOperationReducer,
    monthlyProcessedOperationsReport: monthlyProcessedOperationsReportReducer,
    fareAmountReport: fareAmountReportReducer

};

export function* importedOperationSagas() {
    yield all([
        fork(importedOperationDisbursementReportSaga),
        fork(importedOperationsBatchSaga),
        fork(importedOperationsReexportSaga),
        fork(importedOperationsReexportAllSaga),
        fork(importedOperationsListSaga),
        fork(importedOperationDetailSaga),
        fork(importedOperationDocumentSaga),
        fork(importedOperationsToCreateBatchSaga),
        fork(getImportedOperationsErrorPendingSaga),
        fork(retryPreprocessImportedOperationSaga),
        fork(retryDefinePaymentImportedOperationSaga),
        fork(retryDefineFlowImportedOperationSaga),
        fork(importedOperationsArchiveOneSaga),
        fork(importedOperationsArchiveManySaga),
        fork(retryProcessAutomaticPaymentImportedOperationSaga),
        fork(monthlyProcessedOperationsReportSaga),
        fork(fareAmountReportSaga)
    ]);
}
