import SquareButton from 'components/buttons/square-button/square-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import {
    detailCompanyGroupPolicyRequest,
    detailCompanyGroupPolicyResetState,
} from 'reducer/company-group-policy/detail-company-group-policy/actions';
import { useDetailCompanyGroupPolicyState, useRootDispatch } from 'reducer/hooks';
import './company-group-policy-detail.scss';

type ScdCompanyGroupPolicyDetailProps = RouteComponentProps<{ companyGroupPolicyId: string }>;

const I18N_PREFIX = 'pages.scd.company-group-policy.input';

const ScdCompanyGroupPolicyDetailPage = (props: ScdCompanyGroupPolicyDetailProps) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [companyGroupPolicyId] = useState<string>(props.match.params.companyGroupPolicyId);
    const { status, companyGroupPolicy } = useDetailCompanyGroupPolicyState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailCompanyGroupPolicyRequest(companyGroupPolicyId));
        return () => {
            dispatch(detailCompanyGroupPolicyResetState());
        };
    }, [dispatch, companyGroupPolicyId]);

    const handleUpdate = () => {
        return history.push(`${url}/edit`);
    };

    return (
        <main className="scd-company-group-policy__detail">
            <ContextRibbon>
                <SquareButton label={t('entity.action.update')} onClick={handleUpdate} />
            </ContextRibbon>
            <div className="scd-company-group-policy--container">
                <header className="scd-company-group-policy--header">
                    <h2 className="scd-company-group-policy--header-title">{t('pages.scd.company-group-policy.detail.title')}</h2>
                </header>
                <div className="scd-company-group-policy__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.companyGroup.label`)}
                                value={companyGroupPolicy?.companyGroup?.name}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.disbursementGroupBy.label`)}
                                value={
                                    companyGroupPolicy?.disbursementGroupBy
                                        ? t(`${I18N_PREFIX}.disbursementGroupBy.options.${companyGroupPolicy.disbursementGroupBy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.disbursementPaymentPolicy.label`)}
                                value={
                                    companyGroupPolicy?.disbursementPaymentPolicy
                                        ? t(
                                              `${I18N_PREFIX}.disbursementPaymentPolicy.options.${companyGroupPolicy.disbursementPaymentPolicy}`
                                          )
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.commissionPolicy.label`)}
                                value={
                                    companyGroupPolicy?.commissionPolicy
                                        ? t(`${I18N_PREFIX}.commissionPolicy.options.${companyGroupPolicy.commissionPolicy}`)
                                        : '-'
                                }
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.monthlyCommissionPolicy.label`)}
                                value={
                                    companyGroupPolicy?.monthlyCommissionPolicy
                                        ? t(`${I18N_PREFIX}.monthlyCommissionPolicy.options.${companyGroupPolicy.monthlyCommissionPolicy}`)
                                        : '-'
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdCompanyGroupPolicyDetailPage;
