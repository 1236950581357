import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List Request logs
import { listRequestLogsReducer } from 'reducer/request-log/list-request-log/reducer';
import listRequestLogSaga from 'reducer/request-log/list-request-log/sagas';
import { ListRequestLogState } from 'reducer/request-log/list-request-log/types';

// Detail Request logs
import { DetailRequestLogState } from 'reducer/request-log/detail-request-log/types';
import { detailRequestLogReducer } from 'reducer/request-log/detail-request-log/reducer';
import detailRequestLogSaga from 'reducer/request-log/detail-request-log/sagas';

export interface RequestLogState {
    readonly listRequestLog: ListRequestLogState;
    readonly detailRequestLog: DetailRequestLogState;
}

export const RequestLogReducer: ReducersMapObject<RequestLogState, AnyAction> = {
    listRequestLog: listRequestLogsReducer,
    detailRequestLog: detailRequestLogReducer,
};

export function* requestLogSagas() {
    yield all([fork(listRequestLogSaga), fork(detailRequestLogSaga)]);
}
