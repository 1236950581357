import { AxiosResponse } from 'axios';
import { BatchOperations } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchOperationsError, batchOperationsRequest, batchOperationsSuccess } from 'reducer/batch/batch-operations-summarize/actions';
import { BatchOperationsTypes } from 'reducer/batch/batch-operations-summarize/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchOperations(action: ReturnType<typeof batchOperationsRequest>) {
    try {
        const result: AxiosResponse<BatchOperations> = yield call(batchAPI.batchOperationsSummarize, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOperationsError(mapped));
            return;
        }
        yield put(batchOperationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOperationsError(mapped));
    }
}

function* watchBatchOperations() {
    yield takeEvery(BatchOperationsTypes.BATCH_OPERATIONS_REQUEST, handleBatchOperations);
}

function* batchOperationsSaga() {
    yield all([fork(watchBatchOperations)]);
}

export default batchOperationsSaga;
