import { AxiosResponse } from 'axios';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { SearchRequest } from 'model/reducers';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const paymentFavored = () => {
    const searchPaymentFavored = (request: SearchRequest): Promise<AxiosResponse<Page<ScdPaymentFavoredSearch>>> => {
        return api.get<Page<ScdPaymentFavoredSearch>>(`api/payment-favored/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    return {
        searchPaymentFavored,
    };
};

export default paymentFavored();
