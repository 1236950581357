import { PaymentInstitutionReference } from 'model/enums/payment-institution-reference';
import { ReactNode } from 'react';
import TransfersFallback from 'shared/external/payment-accounts-strategy/transfers/components/fallback/transfers-fallback';
import TransfeeraTransfers from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-transfers';
import { ExternalTransfersProps } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';
import { useResumePaymentInstitutionAccountState } from 'reducer/hooks';
import CelcoinTransfers from 'shared/external/payment-accounts-strategy/transfers/components/celcoin-transfers/celcoin-transfers';
import { HttpRequestStatus } from 'model/enums/http-request-status';

const TransfersBridge = (props: ExternalTransfersProps) => {
    const { technicalSupplierReference } = props;

    const { status } = useResumePaymentInstitutionAccountState();

    const isRequestCompleted = status === HttpRequestStatus.SUCCESS || status === HttpRequestStatus.ERROR;

    const bridgeComponent: Record<PaymentInstitutionReference, ReactNode> = {
        transfeera: <TransfeeraTransfers {...props} />,
        celcoin: <CelcoinTransfers {...props} />,
    };

    if (isRequestCompleted && technicalSupplierReference === undefined) {
        return <TransfersFallback {...props} />;
    }

    return <>{bridgeComponent[technicalSupplierReference] ?? <TransfersFallback {...props} />}</>;
};

export default TransfersBridge;
