import { AxiosResponse } from 'axios';
import { ScdCompanyGroup } from 'model/company-group';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    detailCompanyGroupError,
    detailCompanyGroupRequest,
    detailCompanyGroupSuccess,
} from 'reducer/company-group/detail-company-group/actions';
import { DetailCompanyGroupTypes } from 'reducer/company-group/detail-company-group/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyGroupAPI from 'services/api/company-group-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailCompanyGroup(action: ReturnType<typeof detailCompanyGroupRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyGroup> = yield call(companyGroupAPI.detailCompanyGroup, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailCompanyGroupError(mapped));
            return;
        }
        yield put(detailCompanyGroupSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailCompanyGroupError(mapped));
    }
}

function* watchDetailCompanyGroup() {
    yield takeEvery(DetailCompanyGroupTypes.DETAIL_COMPANY_GROUP_REQUEST, handleDetailCompanyGroup);
}

function* detailCompanyGroupSaga() {
    yield all([fork(watchDetailCompanyGroup)]);
}

export default detailCompanyGroupSaga;
