import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentTrasactionToProcessResponse } from 'model/payment-transaction';
import { markError } from 'reducer/application/error/actions';
import {
    processPaymentTransactionsRequest, processPaymentTransactionsError, processPaymentTransactionsSuccess
} from 'reducer/payment-transaction/process-payment-transactions/actions';
import { ProcessPaymentTransactionsTypes } from 'reducer/payment-transaction/process-payment-transactions/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleProcessPaymentTransactions(action: ReturnType<typeof processPaymentTransactionsRequest>) {
    try {
        const result: AxiosResponse<PaymentTrasactionToProcessResponse> = yield call(paymentTransactionAPI.processManualDispatchPaymentTransactions, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(processPaymentTransactionsError(mapped));
            return;
        }
        yield put(processPaymentTransactionsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(processPaymentTransactionsError(mapped));
    }
}

function* watchProcessPaymentTransactions() {
    yield takeLeading(ProcessPaymentTransactionsTypes.PROCESS_PAYMENT_TRANSACTIONS_REQUEST, handleProcessPaymentTransactions);
}

function* processPaymentTransactionsSaga() {
    yield all([fork(watchProcessPaymentTransactions)]);
}

export default processPaymentTransactionsSaga;
