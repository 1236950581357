import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanyGroup } from 'model/company-group';
import companyGroupAPI from 'services/api/company-group-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchCompanyGroupsTypes } from 'reducer/company-group/search-company-groups/types';
import {
    searchCompanyGroupsError,
    searchCompanyGroupsSuccess,
    searchCompanyGroupsRequest,
} from 'reducer/company-group/search-company-groups/actions';
import { Page } from 'services/page';
import { SearchRequest } from 'model/reducers';

function* handleSearchCompanyGroups(action: ReturnType<typeof searchCompanyGroupsRequest>) {
    try {
        const _payload: SearchRequest = { ...action.payload, search: `ci(contains(${action.payload.search}))` };
        const result: AxiosResponse<Page<ScdCompanyGroup>> = yield call(companyGroupAPI.searchCompanyGroups, _payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchCompanyGroupsError(mapped));
            return;
        }
        yield put(searchCompanyGroupsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchCompanyGroupsError(mapped));
    }
}

function* watchSearchCompanyGroups() {
    yield takeEvery(SearchCompanyGroupsTypes.SEARCH_COMPANY_GROUPS_REQUEST, handleSearchCompanyGroups);
}

function* searchCompanyGroupsSaga() {
    yield all([fork(watchSearchCompanyGroups)]);
}

export default searchCompanyGroupsSaga;
