export enum PaymentTransactionStatus {
    PENDING = 'PENDING',
    WAITING = 'WAITING',
    CONFIRMED = 'CONFIRMED',
    ERROR = 'ERROR',
    ARCHIVED = 'ARCHIVED',
    VALIDATING = 'VALIDATING',
    INCONSISTENT = 'INCONSISTENT',
    UNRESOLVED = 'UNRESOLVED',
}
