import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create company group
import { createCompanyItemReducer } from 'reducer/company-item/create-company-item/reducer';
import createCompanyItemSagas from 'reducer/company-item/create-company-item/sagas';
import { CreateCompanyItemState } from 'reducer/company-item/create-company-item/types';

// Update company group
import { updateCompanyItemReducer } from 'reducer/company-item/update-company-item/reducer';
import updateCompanyItemSagas from 'reducer/company-item/update-company-item/sagas';
import { UpdateCompanyItemState } from 'reducer/company-item/update-company-item/types';

// Detail company group
import { detailCompanyItemReducer } from 'reducer/company-item/detail-company-item/reducer';
import detailCompanyItemSagas from 'reducer/company-item/detail-company-item/sagas';
import { DetailCompanyItemState } from 'reducer/company-item/detail-company-item/types';

// List company groups
import { listCompanyItemsReducer } from 'reducer/company-item/list-company-items/reducer';
import listCompanyItemsSagas from 'reducer/company-item/list-company-items/sagas';
import { ListCompanyItemsState } from 'reducer/company-item/list-company-items/types';

// Search company groups
import { searchCompanyItemsReducer } from 'reducer/company-item/search-company-items/reducer';
import searchCompanyItemsSagas from 'reducer/company-item/search-company-items/sagas';
import { SearchCompanyItemsState } from 'reducer/company-item/search-company-items/types';

export interface CompanyItemState {
    readonly createCompanyItem: CreateCompanyItemState;
    readonly updateCompanyItem: UpdateCompanyItemState;
    readonly detailCompanyItem: DetailCompanyItemState;
    readonly listCompanyItems: ListCompanyItemsState;
    readonly searchCompanyItems: SearchCompanyItemsState;
}

export const CompanyItemReducer: ReducersMapObject<CompanyItemState, AnyAction> = {
    createCompanyItem: createCompanyItemReducer,
    updateCompanyItem: updateCompanyItemReducer,
    detailCompanyItem: detailCompanyItemReducer,
    listCompanyItems: listCompanyItemsReducer,
    searchCompanyItems: searchCompanyItemsReducer,
};

export function* companyItemSagas() {
    yield all([
        fork(createCompanyItemSagas),
        fork(updateCompanyItemSagas),
        fork(detailCompanyItemSagas),
        fork(listCompanyItemsSagas),
        fork(searchCompanyItemsSagas),
    ]);
}
