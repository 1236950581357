import { AxiosResponse } from 'axios';

import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { ScdBanker, ScdBankerList, ScdBankerSimple } from 'model/banker';

const bankers = () => {
    const listBankers = (request?: Pageable): Promise<AxiosResponse<Page<ScdBankerList>>> => {
        return api.get<Page<ScdBankerList>>(`${BASE_URL_ADMIN}/bankers`, { params: { ...request } });
    };

    const detailBanker = (bankerId: number): Promise<AxiosResponse<ScdBanker>> => {
        return api.get<ScdBanker>(`${BASE_URL_ADMIN}/bankers/${bankerId}`);
    };

    const createBanker = (banker: ScdBanker): Promise<AxiosResponse<ScdBankerSimple>> => {
        return api.post<ScdBankerSimple>(`${BASE_URL_ADMIN}/bankers`, banker);
    };

    const updateBanker = (banker: ScdBanker): Promise<AxiosResponse<ScdBankerSimple>> => {
        return api.post<ScdBankerSimple>(`${BASE_URL_ADMIN}/bankers/${banker.id}`, banker);
    };

    return {
        listBankers,
        detailBanker,
        createBanker,
        updateBanker,
    };
};

export default bankers();
