import clsx from 'clsx';
import DocumentIconButton from 'components/buttons/icon-buttons/document/document-icon-button';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { IssuerStatus } from 'model/enums/issuer-status';
import { PersonType } from 'model/enums/person-type';
import { ScdIssuerList } from 'model/issuer';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

const I18N_PREFIX = 'pages.scd.issuer';

export interface IssuerRowProps {
    issuer: ScdIssuerList[];
}

const IssuerRow = (props: ListChildComponentProps<IssuerRowProps>) => {
    const { url } = useRouteMatch();
    const { t } = useTranslation();
    const { index, data, style } = props;

    const { issuer } = data;

    const { id, name, identification, status } = issuer[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-title">
                {identification ? <IdentificationFormatter value={identification} type={PersonType.CORPORATION} /> : '-'}
            </td>
            <td className="page-container--table-cell-title">
                <span
                    className={clsx('page-container--table-status', {
                        ACTIVE: status === IssuerStatus.ACTIVE,
                        INACTIVE: status === IssuerStatus.INACTIVE,
                    })}
                >
                    {t(`${I18N_PREFIX}.input.status.options.${status}`)}
                </span>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/policies`}>
                    <DocumentIconButton title={`${I18N_PREFIX}.table.policies`} />
                </Link>
            </td>
        </tr>
    );
};

export default IssuerRow;
