import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { BatchOutputsListBco } from 'model/bco/batch-output';
import {
    batchOutputsListBcoError,
    batchOutputsListBcoRequest,
    batchOutputsListBcoSuccess,
} from 'reducer-bco/batch-output/list-batch-output/action';
import { BatchOutputsListBcoTypes } from 'reducer-bco/batch-output/list-batch-output/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleBatchOutputsListBco(action: ReturnType<typeof batchOutputsListBcoRequest>) {
    try {
        const result: AxiosResponse<BatchOutputsListBco> = yield call(batchOutputApi.listOutputs, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOutputsListBcoError(mapped));
            return;
        }
        yield put(batchOutputsListBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOutputsListBcoError(mapped));
    }
}

function* watchBatchOutputsListBco() {
    yield takeEvery(BatchOutputsListBcoTypes.BATCH_OUTPUTS_LIST_BCO_REQUEST, handleBatchOutputsListBco);
}

function* batchOutputsListBcoSaga() {
    yield all([fork(watchBatchOutputsListBco)]);
}

export default batchOutputsListBcoSaga;
