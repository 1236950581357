import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CompanyDetailsState, CompanyDetailsTypes } from './types';

const initialState: CompanyDetailsState = {
    status: HttpRequestStatus.NOOP,
    companyDetails: undefined,
    error: undefined,
};

const reducer: Reducer<CompanyDetailsState> = (state = initialState, action): CompanyDetailsState => {
    switch (action.type) {
        case CompanyDetailsTypes.COMPANY_DETAILS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CompanyDetailsTypes.COMPANY_DETAILS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyDetails: action.payload, error: undefined };
        }
        case CompanyDetailsTypes.COMPANY_DETAILS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CompanyDetailsTypes.COMPANY_DETAILS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as companyDetailsReducer };
