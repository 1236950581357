import { BaseStoreState } from 'reducer/base-store-state';
import { ScdRequestLogDetail } from 'model/request-log';

export type ApiResponse = Record<string, string>;

export enum DetailRequestLogTypes {
    DETAIL_REQUEST_LOG_REQUEST = '@@request-log-detail/DETAIL_REQUEST_LOG_REQUEST',
    DETAIL_REQUEST_LOG_SUCCESS = '@@request-log-detail/DETAIL_REQUEST_LOG_SUCCESS',
    DETAIL_REQUEST_LOG_ERROR = '@@request-log-detail/DETAIL_REQUEST_LOG_ERROR',
    DETAIL_REQUEST_LOG_RESET_STATE = '@@request-log-detail/DETAIL_REQUEST_LOG_RESET_STATE',
}

export interface DetailRequestLogState extends BaseStoreState {
    readonly requestLog?: ScdRequestLogDetail;
}
