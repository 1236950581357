import { ScdCompanyGroup } from 'model/company-group';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.program.validate';

const PROGRAM_GROUP_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.group.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.group.required`,
    },
};
export const validateProgramGroup = (value: string | undefined | null) => ValidationUtils.validate(value, PROGRAM_GROUP_VALIDATION);

export const validateProgramCompanyGroups = (value: ScdCompanyGroup | undefined): boolean => {
    if (!value) return true;
    return validateProgramGroup(value.name).isValid;
};
