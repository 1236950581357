import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import { UserActivated } from 'model/enums/user-activated';
import { UserFilterRequest } from 'model/user';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseUserFilterChipsProps {
    filterActive: UserFilterRequest;
    setFilterActive: (value: UserFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.users.filter';

type UserFilterRequestType = keyof Omit<UserFilterRequest, 'lastName'>;

const useUserFilterChips = (props: UseUserFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: UserFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<UserFilterRequestType, () => void> = useMemo(() => {
        return {
            firstName: () => clearFilterSupport({ ...filterActive, firstName: undefined }),
            login: () => clearFilterSupport({ ...filterActive, login: undefined }),
            activated: () => clearFilterSupport({ ...filterActive, activated: undefined }),
            authority: () => clearFilterSupport({ ...filterActive, authority: undefined }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: UserFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.buttons.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );
    const userFilterChips = useMemo(() => {
        const items: Record<UserFilterRequestType, ChipFilterItem> = {
            firstName: {
                isActive: !!filterActive.firstName,
                onDelete: handleClearFilter['firstName'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('name')} component={filterActive.firstName} />,
            },
            login: {
                isActive: !!filterActive.login,
                onDelete: handleClearFilter['login'],
                renderItem: <ChipRenderItemComponent chipTitle={chipTitle('login')} component={filterActive.login} />,
            },
            activated: {
                isActive: filterActive.activated !== undefined,
                onDelete: handleClearFilter['activated'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('activated')}
                        component={t(
                            `${I18N_PREFIX}.inputs.activated.options.${
                                filterActive.activated ? UserActivated.ACTIVE : UserActivated.INACTIVE
                            }`
                        )}
                    />
                ),
            },
            authority: {
                isActive: !!filterActive.authority,
                onDelete: handleClearFilter['authority'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('authority')}
                        component={t(`${I18N_PREFIX}.inputs.authority.options.${filterActive.authority}`)}
                    />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle, t]);

    const isUserChipsActive: boolean = useMemo(() => {
        return userFilterChips.some(it => !!it.isActive);
    }, [userFilterChips]);

    return {
        userFilterChips,
        isUserChipsActive,
    };
};

export default useUserFilterChips;
