import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './outlined-button-legacy.scss';

export interface OutlinedButtonLegacyProps {
    onClick?: () => void;
    label: string;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    startIcon?: React.ReactNode;
}

export const OutlinedButtonLegacy = (props: OutlinedButtonLegacyProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className={clsx('button__style--outlined-legacy', props.size)}>
            <Button variant="contained" onClick={props.onClick} disabled={props.disabled} startIcon={props.startIcon}>
                <span>{t(props.label)} </span>
            </Button>
        </div>
    );
};

export default OutlinedButtonLegacy;
