import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'components/inputs/number-mask';

const estimatedPercentMask = createNumberMask({
    prefix: '',
    suffix: ' %',
    integerLimit: 8,
    decimalLimit: 8,
    includeThousandsSeparator: false,
});

export const EstimatedPercentMask: React.ElementType<InputBaseComponentProps> = props => {
    // remove ref issue
    // https://github.com/text-mask/text-mask/issues/714
    const { inputRef, ...others } = props;
    return <MaskedInput {...others} mask={estimatedPercentMask} guide={false} />;
};

export default EstimatedPercentMask;
