import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdTechnicalSupplier } from 'model/technical-supplier';
import { detailTechnicalSupplierError, detailTechnicalSupplierSuccess, detailTechnicalSupplierRequest } from './actions';
import { DetailTechnicalSupplierTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import technicalSupplierApi from 'services/api/technical-supplier-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailTechnicalSupplier(action: ReturnType<typeof detailTechnicalSupplierRequest>) {
    try {
        const result: AxiosResponse<ScdTechnicalSupplier> = yield call(technicalSupplierApi.detailTechnicalSupplier, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailTechnicalSupplierError(mapped));
            return;
        }
        yield put(detailTechnicalSupplierSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailTechnicalSupplierError(mapped));
    }
}

function* watchDetailTechnicalSupplier() {
    yield takeEvery(DetailTechnicalSupplierTypes.DETAIL_TECHNICAL_SUPPLIER_REQUEST, handleDetailTechnicalSupplier);
}

function* detailTechnicalSupplierSaga() {
    yield all([fork(watchDetailTechnicalSupplier)]);
}

export default detailTechnicalSupplierSaga;
