import MonthlyProcessedOperationsReport from 'entities/monthly-processed-operations-report/monthly-processed-operations-report';
import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const MonthlyProcessedOperationsReportRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={MonthlyProcessedOperationsReport} />
    </Switch>
);

export default MonthlyProcessedOperationsReportRoutes;
