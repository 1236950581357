import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import SuitCaseIconButton from 'components/buttons/icon-buttons/suitcase/suitcase-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { ScdProgramList } from 'model/program';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import clsx from 'clsx';
import { ScdProgramStatus } from 'model/enums/program-status';
import DocumentIconButton from 'components/buttons/icon-buttons/document/document-icon-button';
import PolicyIconButtom from 'components/buttons/icon-buttons/policy/policy-icon-button';

export interface ProgramRowProps {
    programsItems: ScdProgramList[];
}

const I18N_PREFIX = 'pages.scd.program';

const ProgramRow = (props: ListChildComponentProps<ProgramRowProps>) => {
    const { data, index, style } = props;

    const { id, name, productType, status, policyId, organization } = data.programsItems[index];

    const { t } = useTranslation();
    const { url } = useRouteMatch();

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{name}</td>
            <td className="page-container--table-cell-title">
                <span>{t(`${I18N_PREFIX}.table.product.options.${productType}`).toUpperCase()}</span>
            </td>
            <td className="page-container--table-cell-title">
                <span
                    className={clsx('page-container--table-status', {
                        ACTIVE: status === ScdProgramStatus.ACTIVE,
                        INACTIVE: status === ScdProgramStatus.INACTIVE,
                    })}
                >
                    {t(`${I18N_PREFIX}.input.status.options.${status}`).toUpperCase()}
                </span>
            </td>
            <td className="page-container--table-cell-title">{organization.name}</td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/company-groups`}>
                    <SuitCaseIconButton title={t(`${I18N_PREFIX}.table.tooltip.groups`)} />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={`${url}/${id}/output-policies`}>
                    <DocumentIconButton title={t(`${I18N_PREFIX}.table.tooltip.output-policies`)} />
                </Link>
            </td>
            <td className="page-container--table-cell-icon">
                <Link to={policyId ? `/program-policies/${policyId}` : '#'}>
                    <PolicyIconButtom
                        title={policyId ? t(`${I18N_PREFIX}.table.tooltip.policy`) : t(`${I18N_PREFIX}.table.tooltip.policy-not-present`)}
                        disabled={!policyId}
                    />
                </Link>
            </td>
        </tr>
    );
};

export default ProgramRow;
