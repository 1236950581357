import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Calculate Session Interest Rate
import calculateSessionInterestRateSaga from 'reducer/session-interest-rate/calculate-session-interest-rate/sagas';
import { CalculateSessionInterestRateState } from './calculate-session-interest-rate/types';
import { calculateSessionInterestRateReducer } from 'reducer/session-interest-rate/calculate-session-interest-rate/reducer';

// Recalculate Session Interest Rate
import { RecalculateSessionInterestRateState } from 'reducer/session-interest-rate/recalculate-session-interest-rate/types';
import { recalculateSessionInterestRateReducer } from 'reducer/session-interest-rate/recalculate-session-interest-rate/reducer';
import recalculateSessionInterestRateSaga from 'reducer/session-interest-rate/recalculate-session-interest-rate/sagas';


// Detail Session Interest Rate
import { DetailSessionInterestRateState } from 'reducer/session-interest-rate/detail-session-interest-rate/types';
import { detailSessionInterestRateReducer } from 'reducer/session-interest-rate/detail-session-interest-rate/reducer';
import detailSessionInterestRateSaga from 'reducer/session-interest-rate/detail-session-interest-rate/sagas';


export interface SessionInterestRateState {
    readonly calculateSessionInterestRateResponse: CalculateSessionInterestRateState;
    readonly recalculateSessionInterestRateResponse: RecalculateSessionInterestRateState;
    readonly detailSessionInterestRateResponse: DetailSessionInterestRateState;
}

export const SessionInterestRateReducer: ReducersMapObject<SessionInterestRateState, AnyAction> = {
    calculateSessionInterestRateResponse: calculateSessionInterestRateReducer,
    detailSessionInterestRateResponse: detailSessionInterestRateReducer,
    recalculateSessionInterestRateResponse: recalculateSessionInterestRateReducer

};

export function* sessionInterestRateSagas() {
    yield all([fork(calculateSessionInterestRateSaga), fork(detailSessionInterestRateSaga), fork(recalculateSessionInterestRateSaga)]);
}
