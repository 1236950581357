import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { GiroWebappError } from 'model/error';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransfeeraExternalTransfersError } from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';
import { JsonUtils } from 'shared/external/util/json-util';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './transfeera-error-response.scss';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers.errors';

interface TransfeeraErrorProps {
    error: GiroWebappError | undefined;
    externalTransfers: ExternalTransfersResponse | undefined;
}

const TransfeeraErrorResponse = (props: TransfeeraErrorProps) => {
    const { error, externalTransfers } = props;
    const { t } = useTranslation();

    const parsedExternalErrorResponse = useMemo((): TransfeeraExternalTransfersError | undefined => {
        return JsonUtils.parseJsonString(externalTransfers?.responseError);
    }, [externalTransfers?.responseError]);

    const prettifiedTransfersErrorResponse = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(externalTransfers?.responseError);
    }, [externalTransfers]);

    const showExternalError: boolean = !!externalTransfers && !externalTransfers.isSuccessful;

    return (
        <>
            {error?.message && (
                <div className="transfeera-error">
                    <div className="transfeera-error--item">
                        <label>{t(`${I18N_PREFIX}.internalError`)}</label>
                        <span>{t(`${I18N_PREFIX}.internalErrorMessage`)}</span>
                    </div>
                    <div className="transfeera-error--item">
                        <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                        <span>{t(error.message ?? 'error.unknown')}</span>
                    </div>
                </div>
            )}
            {showExternalError && (
                <div className="transfeera-error">
                    <div className="transfeera-error--item">
                        <label>{t(`${I18N_PREFIX}.externalError`)}</label>
                        <span>{t(`${I18N_PREFIX}.checkRawResponse`)}</span>
                    </div>
                    <div className="transfeera-error--item">
                        <label>{t(`${I18N_PREFIX}.errorMessage`)}</label>
                        <span>{parsedExternalErrorResponse?.message ? parsedExternalErrorResponse?.message : '-'}</span>
                    </div>
                    <div className="transfeera-error--item">
                        <Accordion variant="outlined">
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <label>{t(`${I18N_PREFIX}.rawResponse`)}</label>
                            </AccordionSummary>
                            <AccordionDetails>
                                <pre>{prettifiedTransfersErrorResponse}</pre>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            )}
        </>
    );
};

export default TransfeeraErrorResponse;
