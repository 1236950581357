import { ChunkCessionFinalizeRequest, ChunkCessionFinalizeResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { ChunkCessionFinalizeBcoTypes } from 'reducer-bco/batch-output/chunk-cession-finalize/types';
import { action } from 'typesafe-actions';

export const chunkCessionFinalizeBcoRequest = (request: ChunkCessionFinalizeRequest) =>
    action(ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_REQUEST, request);
export const chunkCessionFinalizeBcoSuccess = (chunkCessionFinalizeBco: ChunkCessionFinalizeResponse) =>
    action(ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_SUCCESS, chunkCessionFinalizeBco);
export const chunkCessionFinalizeBcoError = (error: GiroWebappError) =>
    action(ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_ERROR, error);
export const chunkCessionFinalizeBcoResetState = () => action(ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_RESET);
