import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { externalBalancePaymentInstitutionsAccountsRequest, externalBalancePaymentInstitutionsAccountsSuccess, externalBalancePaymentInstitutionsAccountsError } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/actions';
import { ExternalBalancePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import externalStrategyApi from 'services/api/external-api';

import { ErrorUtils } from 'shared/error/error-utils';
import { ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';

function* handleExternalBalancePaymentInstitutionsAccounts(action: ReturnType<typeof externalBalancePaymentInstitutionsAccountsRequest>) {
    try {
        const result: AxiosResponse<ExternalBalanceResponse> = yield call(
            externalStrategyApi.externalBalancePaymentInstitutionAccount,
            action.payload
        );

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(externalBalancePaymentInstitutionsAccountsError(mapped, action.meta.accountId));
            return;
        }
        yield put(externalBalancePaymentInstitutionsAccountsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(externalBalancePaymentInstitutionsAccountsError(mapped, action.meta.accountId));
    }
}

function* watchExternalBalancePaymentInstitutionsAccounts() {
    yield takeEvery(ExternalBalancePaymentInstitutionsAccountsTypes.EXTERNAL_BALANCE_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, handleExternalBalancePaymentInstitutionsAccounts);
}

function* externalBalancePaymentInstitutionsAccountsSaga() {
    yield all([fork(watchExternalBalancePaymentInstitutionsAccounts)]);
}

export default externalBalancePaymentInstitutionsAccountsSaga;
