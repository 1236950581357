import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import ValidTextInput from 'components/inputs/text-input/valid-text-input';
import Loading from 'components/loading/loading';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalSearch from 'components/modals/modal-search/modal-search';
import {
    validateCompanyGroupName,
    validateCompanyGroupOrganization,
    validateScdCompanyGroup,
} from 'entities/company-group/validation/company-group-validation';
import { ScdCompanyGroup, defaultScdCompanyGroup } from 'model/company-group';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ScdOrganization } from 'model/organization';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { createCompanyGroupRequest, createCompanyGroupResetState } from 'reducer/company-group/create-company-group/actions';
import { detailCompanyGroupRequest, detailCompanyGroupResetState } from 'reducer/company-group/detail-company-group/actions';
import { updateCompanyGroupRequest, updateCompanyGroupResetState } from 'reducer/company-group/update-company-group/actions';
import { useCreateCompanyGroupState, useDetailCompanyGroupState, useRootDispatch, useUpdateCompanyGroupState } from 'reducer/hooks';
import { searchOrganizationsRequest, searchOrganizationsResetState } from 'reducer/organization/search-organizations/actions';
import './company-group-create-update.scss';

export interface ScdCompanyGroupUpdateProps extends RouteComponentProps<{ companyGroupId: string }> {}

const I18N_PREFIX = 'pages.scd.company-group';

const ScdCompanyGroupCreateUpdatePage = (props: ScdCompanyGroupUpdateProps) => {
    const { t } = useTranslation();
    const [companyGroupId] = useState<string | undefined>(props.match?.params?.companyGroupId ?? undefined);
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [modalCancel, setModalCancel] = useState<boolean>(false);

    const { status: createStatus } = useCreateCompanyGroupState();
    const { status: updateStatus } = useUpdateCompanyGroupState();
    const { status: detailStatus, companyGroup: detailCompanyGroup } = useDetailCompanyGroupState();

    const [scdCompanyGroup, setScdCompanyGroup] = useState<ScdCompanyGroup>(defaultScdCompanyGroup);

    const dispatch = useRootDispatch();
    const history = useHistory();

    const handleSave = () => {
        setShowValidation(true);

        const _scdCompanyGroup: ScdCompanyGroup = {
            ...scdCompanyGroup,
        };

        if (!validateScdCompanyGroup(_scdCompanyGroup)) return;

        if (companyGroupId) {
            dispatch(updateCompanyGroupRequest(_scdCompanyGroup));
        } else {
            dispatch(createCompanyGroupRequest(_scdCompanyGroup));
        }
    };

    const handleChange = (value: Partial<ScdCompanyGroup>) => {
        setScdCompanyGroup(ps => ({ ...ps, ...value }));
    };

    useEffect(() => {
        if (companyGroupId) {
            dispatch(detailCompanyGroupRequest(companyGroupId));
        }
    }, [companyGroupId, dispatch]);

    useEffect(() => {
        if (detailStatus === HttpRequestStatus.SUCCESS && detailCompanyGroup !== undefined) {
            setScdCompanyGroup(detailCompanyGroup);
        }
    }, [detailStatus, detailCompanyGroup]);

    useEffect(() => {
        if (updateStatus === HttpRequestStatus.SUCCESS) {
            history.push('/company-groups');
        }
    }, [updateStatus, history]);

    useEffect(() => {
        if (createStatus === HttpRequestStatus.SUCCESS) {
            history.push('/company-groups');
        }
    }, [createStatus, history]);

    useEffect(() => {
        return () => {
            dispatch(createCompanyGroupResetState());
            dispatch(updateCompanyGroupResetState());
            dispatch(detailCompanyGroupResetState());
        };
    }, [dispatch]);

    const loading = detailStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="scd-company-group-create__create">
            <ContextRibbon />
            <div className="scd-company-group-create--container">
                <header className="scd-company-group-create--header">
                    <h2 className="scd-company-group-create--header-title">
                        {!companyGroupId ? t(`${I18N_PREFIX}.create.title`) : t(`${I18N_PREFIX}.edit.title`)}
                    </h2>
                </header>
                {loading && companyGroupId ? (
                    <Loading />
                ) : (
                    <form className="scd-company-group-create--form">
                        <div className="scd-company-group-create--form-field">
                            <ValidTextInput
                                type="text"
                                label={t(`${I18N_PREFIX}.input.name.label`)}
                                value={scdCompanyGroup?.name ?? ''}
                                placeholder={t(`${I18N_PREFIX}.input.name.placeholder`)}
                                onChange={name => handleChange({ name })}
                                validate={validateCompanyGroupName}
                                showValidation={showValidation}
                                externalUpdate
                            />
                        </div>
                        {!companyGroupId && (
                            <div className="scd-company-group-create--form-field">
                                <ModalSearch<ScdOrganization>
                                    action={searchOrganizationsRequest}
                                    itemSize={SEARCH_ITEM_SIZE}
                                    modalTitle={t(`${I18N_PREFIX}.input.organization.modal.title`)}
                                    modalLabel={t(`${I18N_PREFIX}.input.organization.modal.label`)}
                                    modalPlaceholder={t(`${I18N_PREFIX}.input.organization.modal.placeholder`)}
                                    onSelect={organization => handleChange({ organization })}
                                    renderItem={organization => organization.name}
                                    statusSelector={(state: IRootState) => state.searchOrganizations.status}
                                    dataSelector={(state: IRootState) => state.searchOrganizations.organizations}
                                    resetState={searchOrganizationsResetState}
                                >
                                    {handleOpen => (
                                        <ValidSearchInput
                                            label={`${I18N_PREFIX}.input.organization.label`}
                                            showValidation={showValidation}
                                            placeholder={`${I18N_PREFIX}.input.organization.placeholder`}
                                            validate={validateCompanyGroupOrganization}
                                            value={scdCompanyGroup.organization?.name ?? ''}
                                            onClick={handleOpen}
                                            onFocus={handleOpen}
                                            readOnly
                                            externalUpdate
                                        />
                                    )}
                                </ModalSearch>
                            </div>
                        )}

                        <div className="scd-company-group-create--form-buttons">
                            <OutlinedButtonLegacy label={t('entity.action.back')} onClick={() => setModalCancel(true)} />
                            <StandardButtonLegacy label={t('entity.action.save')} onClick={handleSave} />
                        </div>
                    </form>
                )}
            </div>
            {modalCancel && (
                <ModalMessageLegacy
                    title={companyGroupId ? t('entity.cancel.edit') : t('entity.cancel.create')}
                    message={t('entity.cancel.info')}
                    onCancel={() => setModalCancel(false)}
                    onAction={() => {
                        setModalCancel(false);
                        history.push('/company-groups');
                    }}
                    onClose={() => setModalCancel(false)}
                />
            )}
        </main>
    );
};

export default ScdCompanyGroupCreateUpdatePage;
