import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.batch.detail.table';

export type BatchOperationSortableProperties =
    | 'createdDate'
    | 'acceptedDate'
    | 'totalValue'
    | 'companyIdentificationNumber'
    | 'identification'
    | 'ccbNumber';

interface ScdBatchOperationsHeaderProps {
    operations: ScdImportedOperationBatch[];
    handleSort: (property: BatchOperationSortableProperties, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort: boolean;
}

export const ScdBatchOperationsHeader = (props: ScdBatchOperationsHeaderProps) => {
    const { t } = useTranslation();
    const { operations, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: BatchOperationSortableProperties) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (operations && operations.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.acceptedDate`).toUpperCase()} sortable={mapSortable('acceptedDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.totalValue`).toUpperCase()} sortable={mapSortable('totalValue')} />
            <SortableColumn
                label={t(`${I18N_PREFIX}.companyIdentificationNumber`).toUpperCase()}
                sortable={mapSortable('companyIdentificationNumber')}
            />
            <SortableColumn label={t(`${I18N_PREFIX}.debtorIdentification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.ccbNumber`).toUpperCase()} sortable={mapSortable('ccbNumber')} />
        </tr>
    );
};

export default ScdBatchOperationsHeader;
