import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    LegacyProcessPaymentTransactionsTypes,
    LegacyProcessPaymentTransactionsState,
} from 'reducer/payment-transaction/legacy-process-payment-transactions/types';

const initialState: LegacyProcessPaymentTransactionsState = {
    status: HttpRequestStatus.NOOP,
    error: undefined,
};

const reducer: Reducer<LegacyProcessPaymentTransactionsState> = (state = initialState, action): LegacyProcessPaymentTransactionsState => {
    switch (action.type) {
        case LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, error: undefined };
        }
        case LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as legacyProcessPaymentTransactionsReducer };
