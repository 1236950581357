import { PaymentTransactionManualDisbursementValidation } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ValidateManualDisbursementPaymentTransactionTypes {
    VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST = '@@payment-transactions-disbursement/VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST',
    VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transactions-disbursement/VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS',
    VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR = '@@payment-transactions-disbursement/VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR',
    VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET = '@@payment-transactions-disbursement/VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET',
}

export interface ValidateManualDisbursementPaymentTransactionState extends BaseStoreState {
    manualDisbursementValidation?: PaymentTransactionManualDisbursementValidation
}
