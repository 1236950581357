import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import userApi from 'services/api/user-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { UserSimple } from 'model/user';
import { userUpdateError, userUpdateRequest, userUpdateSuccess } from 'reducer/user/update-user/actions';
import { UserUpdateTypes } from 'reducer/user/update-user/types';

function* handleUserUpdate(action: ReturnType<typeof userUpdateRequest>) {
    try {
        const result: AxiosResponse<UserSimple> = yield call(userApi.update, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(userUpdateError(mapped));
            return;
        }
        yield put(userUpdateSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(userUpdateError(mapped));
    }
}

function* watchUserUpdate() {
    yield takeLeading(UserUpdateTypes.USER_UPDATE_REQUEST, handleUserUpdate);
}

function* userUpdateSaga() {
    yield all([fork(watchUserUpdate)]);
}

export default userUpdateSaga;
