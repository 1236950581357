import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyItemSimple } from 'model/company-item';

export type ApiResponse = Record<string, any>;

export enum CreateCompanyItemTypes {
    CREATE_COMPANY_ITEM_REQUEST = '@@company-item-create/CREATE_COMPANY_ITEM_REQUEST',
    CREATE_COMPANY_ITEM_SUCCESS = '@@company-item-create/CREATE_COMPANY_ITEM_SUCCESS',
    CREATE_COMPANY_ITEM_ERROR = '@@company-item-create/CREATE_COMPANY_ITEM_ERROR',
    CREATE_COMPANY_ITEM_RESET = '@@company-item-create/CREATE_COMPANY_ITEM_RESET',
}

export interface CreateCompanyItemState extends BaseStoreState {
    readonly companyItem?: ScdCompanyItemSimple;
}
