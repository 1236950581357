import { Grid } from '@material-ui/core';
import ClearButton from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButton from 'components/buttons/standard-button/standard-button';
import BankNumberInput from 'components/inputs/bank-number-input/bank-number-input';
import BankIdentificationInput from 'components/inputs/bank-indentification-input/bank-identification-input';
import SimpleTextInput from 'components/inputs/text-input/simple-text-input';
import { BankFilterRequest } from 'model/bank';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import './bank-modal-filter.scss';

export type BankModalFilterType = 'filters' | keyof BankFilterRequest;

export interface BankModalFilterProps {
    onClose: () => void;
    modalFilter: BankModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: BankFilterRequest;
    setFilterActive: (value: BankFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.bank.filter.inputs';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    name: filter => !filter.name,
    number: filter => !filter.number,
    identification: filter => !filter.identification,
    filters: filter => valuesOfObject({ ...filter }).length === 0,
} as Record<BankModalFilterType, (filter: BankFilterRequest) => boolean>;

export const BankModalFilter = (props: BankModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<BankFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<BankFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        name: () => {
            setFilterRequest({ ...filterRequest, name: undefined });
            setFilterActive({ ...filterActive, name: undefined });
        },
        number: () => {
            setFilterRequest({ ...filterRequest, number: undefined });
            setFilterActive({ ...filterActive, number: undefined });
        },
        identification: () => {
            setFilterRequest({ ...filterRequest, identification: undefined });
            setFilterActive({ ...filterActive, identification: undefined });
        },
        filters: () => {
            setFilterRequest({});
            setFilterActive({});
        },
    } as Record<BankModalFilterType, () => void>;

    const NameFilter: JSX.Element = (
        <Grid item xs={12}>
            <SimpleTextInput
                label={t(`${I18N_PREFIX}.name`)}
                placeholder={t(`${I18N_PREFIX}.name`)}
                onChange={name => handleChange({ name: name })}
                value={filterRequest?.name}
                externalUpdate
            />
        </Grid>
    );

    const NumberFilter: JSX.Element = (
        <Grid item xs={12}>
            <BankNumberInput
                label={t(`${I18N_PREFIX}.number`)}
                placeholder={t(`${I18N_PREFIX}.number`)}
                onChange={number => handleChange({ number: number })}
                value={filterRequest?.number}
                externalUpdate
            />
        </Grid>
    );

    const IdentificationFilter: JSX.Element = (
        <Grid item xs={12}>
            <BankIdentificationInput
                label={t(`${I18N_PREFIX}.identification`)}
                placeholder={t(`${I18N_PREFIX}.identification`)}
                onChange={identification => handleChange({ identification: identification })}
                value={filterRequest?.identification}
                externalUpdate
            />
        </Grid>
    );

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    const filterComponents: Record<BankModalFilterType, React.ReactNode> = {
        filters: (
            <>
                {NameFilter}
                {NumberFilter}
                {IdentificationFilter}
            </>
        ),
        name: NameFilter,
        number: NumberFilter,
        identification: IdentificationFilter,
    };

    return (
        openModal && (
            <Modal isOpen>
                <div className="bank-modal-filter">
                    <div className="bank-modal-filter--header">
                        <label className="bank-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {filterComponents[modalFilter]}
                    </Grid>

                    <div className="bank-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButton label="entity.action.confirm" onClick={handleConfirmRequest} disabled={isConfirmButtonDisabled} />
                    </div>
                </div>
            </Modal>
        )
    );
};
