import { Pageable } from 'services/pageable';
import { LocalDatePeriodString } from 'model/local-date-period';
import { RequestLogType } from 'model/enums/request-log-type';
export interface ScdRequestLogData {
    id?: number;
    responseStatus?: number;
    organizationKey?: string;
    createdDate?: Date;
    route?: string;
}

export interface ScdRequestLogDetail {
    id: number;
    request: string;
    response: string;
    responseStatus: number;
    organizationKey: string;
    route: string;
    method: string;
    host: string;
    type: RequestLogType;
}

export interface ScdRequestLogFilterRequest {
    responseStatus?: number;
    organizationKey?: string;
    createdDate?: LocalDatePeriodString;
    route?: string;
}

export const defaultRequestLogFilterRequest: Readonly<ScdRequestLogFilterRequest> = {};

export interface ScdRequestLogFilterPageable {
    pageable?: Pageable;
    filter?: ScdRequestLogFilterRequest;
}
