import { GiroWebappError } from 'model/error';
import { ScdMonthlyCommissionData } from 'model/monthly-commission';
import { MonthlyCommissionFilterPageable } from 'model/reducers';
import { ListMonthlyCommissionsTypes } from 'reducer/monthly-commission/list-monthly-commissions/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const listMonthlyCommissionsRequest = (request: MonthlyCommissionFilterPageable) =>
    action(ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_REQUEST, request);
export const listMonthlyCommissionsSuccess = (monthlyCommissions: Page<ScdMonthlyCommissionData>) =>
    action(ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_SUCCESS, monthlyCommissions);
export const listMonthlyCommissionsError = (error: GiroWebappError) =>
    action(ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_ERROR, error);
export const listMonthlyCommissionsResetState = () => action(ListMonthlyCommissionsTypes.LIST_MONTHLY_COMMISSIONS_RESET);
