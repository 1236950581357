import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import BaseTextInput, { BaseTextInputProps } from 'components/inputs/base-text-input';

type SimpleTextNumberInputProps = Omit<BaseTextInputProps, 'error' | 'shouldShrinkLabel' | 'inputComponent' | 'changeMapper'>;

const INVALID_IDENTIFIER_CARACTERS_REGEX = new RegExp(/[^0-9]+/g);

export const SimpleTextNumberInput = (props: SimpleTextNumberInputProps) => {
    const { type, onChange, ...otherProps } = props;

    const handleChange = (value: string) => {
        const _value = value?.toLowerCase().replace(INVALID_IDENTIFIER_CARACTERS_REGEX, '');
        if (!onChange || _value.length > 255) return;

        onChange(_value);
    };

    return (
        <FormControl className={clsx('base-input', { dirty: props.value })} fullWidth>
            <BaseTextInput type={type ?? 'text'} onChange={handleChange} {...otherProps} hasExternalSanitization />
        </FormControl>
    );
};

export default SimpleTextNumberInput;
