import { ScdCompanyGroupSimple } from 'model/company-group';
import { CompanyGroupCommissionPolicy } from 'model/enums/company-group-commission-policy';
import { CompanyGroupDisbursementPaymentPolicy } from 'model/enums/company-group-disbursement-payment-policy';
import { CompanyGroupMonthlyCommissionPolicy } from 'model/enums/company-group-monthly-commission-policy';
import { PolicyDisbursementGroupBy } from 'model/enums/policy-disbursement-group';

export interface ScdCompanyGroupPolicy {
    id?: string;
    disbursementGroupBy?: PolicyDisbursementGroupBy;
    companyGroup?: ScdCompanyGroupSimple;
    commissionPolicy?: CompanyGroupCommissionPolicy;
    monthlyCommissionPolicy?: CompanyGroupMonthlyCommissionPolicy;
    disbursementPaymentPolicy?: CompanyGroupDisbursementPaymentPolicy;
}

export const defaultScdCompanyGroupPolicy: ScdCompanyGroupPolicy = {
    id: undefined,
    disbursementGroupBy: undefined,
    companyGroup: undefined,
    commissionPolicy: undefined,
    monthlyCommissionPolicy: undefined,
    disbursementPaymentPolicy: undefined,
};

export interface ScdCompanyGroupPolicySimple {
    id: string;
}
