import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ExternalTransfersPaymentInstitutionsAccountsState, ExternalTransfersPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/types';

const initialState: ExternalTransfersPaymentInstitutionsAccountsState = {
    status: HttpRequestStatus.NOOP,
    externalTransfers: undefined,
    error: undefined
};

const reducer: Reducer<ExternalTransfersPaymentInstitutionsAccountsState> = (state = initialState, action): ExternalTransfersPaymentInstitutionsAccountsState => {
    switch (action.type) {
        case ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, externalTransfers: action.payload, error: undefined };
        }
        case ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as externalTransfersPaymentInstitutionsAccountsReducer };
