import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionPendingList } from 'model/payment-transaction';
import { ListPendingPaymentTransactionsTypes } from 'reducer/payment-transaction/list-pending-payment-transactions/types';
import { action } from 'typesafe-actions';

export const listPendingPaymentTransactionsRequest = () =>
    action(ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_REQUEST);
export const listPendingPaymentTransactionsSuccess = (paymentTransactions: ScdPaymentTransactionPendingList) =>
    action(ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_SUCCESS, paymentTransactions);
export const listPendingPaymentTransactionsError = (error: GiroWebappError) =>
    action(ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_ERROR, error);
export const listPendingPaymentTransactionsResetState = () => action(ListPendingPaymentTransactionsTypes.LIST_PENDING_PAYMENT_TRANSACTIONS_RESET);
