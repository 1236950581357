import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ScdCompany } from 'model/company';
import { useTranslation } from 'react-i18next';

interface CompanyOrganizationCardDetailProps {
    entity: ScdCompany;
}

const I18N_PREFIX = 'pages.scd.company.card.organizations.input';

const CompanyOrganizationCardDetail = (props: CompanyOrganizationCardDetailProps) => {
    const { entity } = props;

    const { t } = useTranslation();

    return (
        <div className="card-edition__container--content">
            <div className="scd-company-create-update--form-card-field">
                <ReadOnlyTextField label={t(`${I18N_PREFIX}.organizationId.label`)} value={entity.name} />
            </div>
        </div>
    );
};

export default CompanyOrganizationCardDetail;
