import { AxiosResponse } from 'axios';
import { HemeraResumeIntegration } from 'features/limelight/send-backings/domain/models';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { limelightListOperationsRequest, limelightListOperationsError, limelightListOperationsSuccess } from 'reducer/provisional-limelight/provisional-limelight-list/action';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ErrorUtils from 'shared/error/error-utils';
import provisionalLimelightApi from 'services/api/provisional-limeliight'
import { ProvisionalLimelightTypes } from 'reducer/provisional-limelight/provisional-limelight-list/types';

function* handleLimelightListOperations(action: ReturnType<typeof limelightListOperationsRequest>) {
    try {
        const result: AxiosResponse<HemeraResumeIntegration> = yield call(provisionalLimelightApi.listOperations, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(limelightListOperationsError(mapped));
            return;
        }
        yield put(limelightListOperationsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(limelightListOperationsError(mapped));
    }
}

function* watchLimelightListOperations() {
    yield takeEvery(ProvisionalLimelightTypes.LIMELIGHT_LIST_OPERATIONS_REQUEST, handleLimelightListOperations);
}

function* limelightListOperationsSaga() {
    yield all([fork(watchLimelightListOperations)]);
}

export default limelightListOperationsSaga;