import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListIssuerPoliciesState, ListIssuerPoliciesTypes } from 'reducer/issuer-policy/list-issuer-policies/types';
import { Reducer } from 'redux';

export const initialState: ListIssuerPoliciesState = {
    status: HttpRequestStatus.NOOP,
    issuerPolicies: undefined,
    error: undefined,
};

const reducer: Reducer<ListIssuerPoliciesState> = (state = initialState, action): ListIssuerPoliciesState => {
    switch (action.type) {
        case ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, issuerPolicies: action.payload, error: undefined };
        }
        case ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListIssuerPoliciesTypes.LIST_ISSUER_POLICIES_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listIssuerPoliciesReducer };
