import { AxiosResponse } from 'axios';
import { ScdBatchToArchiveResponse } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    archiveBatchOperationRequest, archiveBatchOperationError, archiveBatchOperationSuccess
} from 'reducer/batch/archive-batch-operation/actions';
import { ArchiveBatchOperationTypes } from 'reducer/batch/archive-batch-operation/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleArchiveBatchOperation(action: ReturnType<typeof archiveBatchOperationRequest>) {
    try {
        const result: AxiosResponse<ScdBatchToArchiveResponse> = yield call(batchAPI.archiveBatchOperation, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(archiveBatchOperationError(mapped));
            return;
        }
        yield put(archiveBatchOperationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(archiveBatchOperationError(mapped));
    }
}

function* watchArchiveBatchOperation() {
    yield takeLeading(ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_REQUEST, handleArchiveBatchOperation);
}

function* archiveBatchOperationSaga() {
    yield all([fork(watchArchiveBatchOperation)]);
}

export default archiveBatchOperationSaga;
