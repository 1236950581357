import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useRootDispatch, useUserDetailState } from 'reducer/hooks';
import { userDetailRequest, userDetailResetState } from 'reducer/user/user-detail/actions';

import './user-detail.scss';
import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import FormatterUtils from 'components/formatter/formatter-utils';
import { UserDetailData } from 'model/user';

interface UserDetailProps extends RouteComponentProps<{ id: string }> {}

const I18N_PREFIX = 'pages.scd.users';

const UserDetail = (props: UserDetailProps) => {
    const { t } = useTranslation();
    const { match } = props;
    
    const dispatch = useRootDispatch();
    const [userId] = useState<string>(match.params.id);
    
    const { status, userDetail } = useUserDetailState();
    
    const isLoading: boolean = status !== HttpRequestStatus.SUCCESS;

    useEffect(() => {
        dispatch(userDetailRequest(Number(userId)));
    }, [dispatch, userId]);
  
    const ascertainPersonName =  (userDetail: UserDetailData | undefined): string => {
        if(!userDetail) return "-";
        if(!userDetail.firstName) return "-";
        if(!userDetail.lastName) return userDetail.firstName;

        return `${userDetail.firstName} ${userDetail.lastName}`;
    }

    useEffect(() => {
        return () => {
            dispatch(userDetailResetState());
        }
    }, [dispatch])

    return (
        <main className="user__detail">
            <ContextRibbon />
            <div className="user__detail--container">
                <header className="user__detail--header">
                    <h2 className="user__detail--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="user__detail--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.detail.inputs.name`}
                                value={ascertainPersonName(userDetail)}
                            />
                            <ReadOnlyTextField label={`${I18N_PREFIX}.detail.inputs.login`} value={userDetail?.login ?? "-"} />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.detail.inputs.activated`}
                                value={t(`${I18N_PREFIX}.status.options.${userDetail?.activated}`)}
                            />
                            <ReadOnlyTextField label={`${I18N_PREFIX}.detail.inputs.createdBy`} value={userDetail?.createdBy ?? "-"} />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.detail.inputs.createdDate`}
                                value={userDetail?.createdDate ? FormatterUtils.formatDateTimeUTC(userDetail?.createdDate) : "-"}
                            />
                            <ReadOnlyTextField label={`${I18N_PREFIX}.detail.inputs.lastModifiedBy`} value={userDetail?.lastModifiedBy ?? "-"} />
                            <ReadOnlyTextField
                                label={`${I18N_PREFIX}.detail.inputs.lastModifiedDate`}
                                value={userDetail?.lastModifiedDate ? FormatterUtils.formatDateTimeUTC(userDetail?.lastModifiedDate) : "-"}
                            />
                            {userDetail?.authorities && (
                                <div className="user__detail--card">
                                    <ReadOnlyCard name={t(`${I18N_PREFIX}.detail.inputs.authorities.label`)}>
                                        {userDetail.authorities.map(item => (
                                            <ReadOnlyTextField
                                                key={item}
                                                value={t(`${I18N_PREFIX}.detail.inputs.authorities.options.${item}`)}
                                            />
                                        ))}
                                    </ReadOnlyCard>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default UserDetail;
