import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramPolicy } from 'model/program-policy';
import {
    detailProgramPolicyError,
    detailProgramPolicySuccess,
    detailProgramPolicyRequest,
} from 'reducer/program-policy/detail-program-policy/actions';
import { DetailProgramPolicyTypes } from 'reducer/program-policy/detail-program-policy/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programPolicyApi from 'services/api/program-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleDetailProgramPolicy(action: ReturnType<typeof detailProgramPolicyRequest>) {
    try {
        const result: AxiosResponse<ScdProgramPolicy> = yield call(programPolicyApi.detailProgramPolicy, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(detailProgramPolicyError(mapped));
            return;
        }
        yield put(detailProgramPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(detailProgramPolicyError(mapped));
    }
}

function* watchDetailProgramPolicy() {
    yield takeEvery(DetailProgramPolicyTypes.DETAIL_PROGRAM_POLICY_REQUEST, handleDetailProgramPolicy);
}

function* detailProgramPolicySaga() {
    yield all([fork(watchDetailProgramPolicy)]);
}

export default detailProgramPolicySaga;
