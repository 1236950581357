import ScdProgramPage from 'entities/program/program';
import ScdProgramCreateUpdatePage from 'entities/program/program-create-update/program-create-update';
import ScdProgramDetailPage from 'entities/program/program-detail/program-detail';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';
import ScdProgramPolicyCreateUpdate from 'entities/program-policy/program-policy-create-update/program-policy-create-update';
import ProgramGroups from 'entities/program/program-groups/program-groups';
import ProgramsOutputPolicies from 'entities/program/program-output-policies/program-output-policies'

interface MatchParams {
    url: string;
}

const ProgramRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdProgramPage} />
        <PrivateRoute exact path={`${match.url}/new`} component={ScdProgramCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:programId`} component={ScdProgramDetailPage} />
        <PrivateRoute exact path={`${match.url}/:programId/edit`} component={ScdProgramCreateUpdatePage} />
        <PrivateRoute exact path={`${match.url}/:programId/program-policies/new`} component={ScdProgramPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:programId/company-groups`} component={ProgramGroups} />
        <PrivateRoute exact path={`${match.url}/:programId/output-policies`} component={ProgramsOutputPolicies} />
    </Switch>
);

export default ProgramRoutes;
