import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdateIntegrationIssuerPolicyState, UpdateIntegrationIssuerPolicyTypes } from 'reducer/issuer-policy/update-integration/types';
import { Reducer } from 'redux';

export const initialState: UpdateIntegrationIssuerPolicyState = {
    status: HttpRequestStatus.NOOP,
    policyIntegrationUpdate: undefined,
    error: undefined,
};

const reducer: Reducer<UpdateIntegrationIssuerPolicyState> = (state = initialState, action): UpdateIntegrationIssuerPolicyState => {
    switch (action.type) {
        case UpdateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_UPDATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case UpdateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_UPDATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, policyIntegrationUpdate: action.payload, error: undefined };
        }
        case UpdateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_UPDATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdateIntegrationIssuerPolicyTypes.ISSUER_POLICY_INTEGRATION_UPDATE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updateIssuerPolicyIntegrationReducer };
