import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListCompaniesTypes, ListCompaniesState } from './types';

const initialState: ListCompaniesState = {
    status: HttpRequestStatus.NOOP,
    companies: undefined,
    error: undefined,
};

const reducer: Reducer<ListCompaniesState> = (state = initialState, action): ListCompaniesState => {
    switch (action.type) {
        case ListCompaniesTypes.LIST_COMPANIES_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListCompaniesTypes.LIST_COMPANIES_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companies: action.payload, error: undefined };
        }
        case ListCompaniesTypes.LIST_COMPANIES_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListCompaniesTypes.LIST_COMPANIES_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listCompaniesReducer };
