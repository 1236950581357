import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { BatchSignatureDocumentsList } from 'model/batch-signature';

interface ScdBatchEndorsementImportsOptionsItemsProps {
    operationId?: number;
    handleGenerateContract: () => void;
    selectContract?: BatchSignatureDocumentsList;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementImportsOptionsItems = (props: ScdBatchEndorsementImportsOptionsItemsProps) => {
    const { operationId, handleGenerateContract, selectContract } = props;

    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.operation-detail`} link={`/ccb-operations/${operationId}`} />
            <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.download`} onClick={handleGenerateContract} show={!selectContract?.fileId ? false : true} />
        </>
    );
};

export default ScdBatchEndorsementImportsOptionsItems;
