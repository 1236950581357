import { Button, CircularProgress } from '@material-ui/core';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Page } from 'services/page';

import { INFINITE_SCROLL_PAGE_SIZE } from 'services/pageable';
import './infinite-scroll-legacy.scss';

interface InfiniteScroll<T> {
    handlePageChange: () => void;
    currentPage: number;
    dataSelector: (state: IRootState) => Page<T> | undefined;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    action: (request: any) => AnyAction;
    filterPageable: () => void;
    setPage: (value: number) => void;
    itemsPerPage?: number;
}

const useInfiniteScrollLegacy = <T extends object>(props: InfiniteScroll<T>) => {
    const { handlePageChange, currentPage, dataSelector, statusSelector, filterPageable, action, setPage, itemsPerPage } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const data = useSelector<IRootState, Page<T> | undefined>(dataSelector);
    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);

    const [items, setItems] = useState<Array<T>>(data?.content ?? []);

    const isLoading = useMemo(() => status === HttpRequestStatus.ON_GOING, [status]);

    const isDisabled = useMemo(() => currentPage + 1 === data?.totalPages, [currentPage, data]);

    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

    const resetInfiniteScrollListing = useCallback(() => setItems([]), []);

    const nextPage = useCallback(() => {
        if (!isDisabled) handlePageChange();
    }, [handlePageChange, isDisabled]);

    const handleRefresh = useCallback(() => {
        if (currentPage === 0) {
            setIsRefreshing(true);
            resetInfiniteScrollListing();
            return;
        }
        setPage(0);
        resetInfiniteScrollListing();
    }, [resetInfiniteScrollListing, setPage, currentPage]);

    useEffect(() => {
        if (isRefreshing) setIsRefreshing(false);
        if (!isRefreshing) dispatch(action(filterPageable()));
    }, [dispatch, action, filterPageable, isRefreshing]);

    useEffect(() => {
        if (status !== HttpRequestStatus.SUCCESS) return;
        const newData = data?.content ?? [];
        const newPage = data?.number === 0;
        setItems(prev => (newPage ? [...newData] : [...prev, ...newData]));
    }, [status, data]);

    const isMoreItemsButtonVisible: boolean = !isLoading && items.length >= (itemsPerPage ?? INFINITE_SCROLL_PAGE_SIZE);

    const InfiniteScrollButton = () => {
        return (
            <footer className="page-container--infinite-scroll">
                {isLoading && !!items.length && (
                    <div className="page-container--infinite-scroll--loading">
                        <CircularProgress size="35px" style={{ color: '#3f41d1' }} />
                    </div>
                )}

                {isMoreItemsButtonVisible && (
                    <Button variant="contained" onClick={nextPage} disabled={isDisabled} disableRipple>
                        {t('infinite-scroll.more')}
                    </Button>
                )}
            </footer>
        );
    };
    return { InfiniteScrollButton, items, resetInfiniteScrollListing, isLoading, handleRefresh, status };
};

export default useInfiniteScrollLegacy;
