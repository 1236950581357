import DashedButton from 'components/buttons/dashed-button/dashed-button';
import { OriginatorPaymentInstitution, defaultOriginatorPaymentInstitution } from 'model/originator-payment-institution';
import { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import CardEdition, { ICardEdition } from 'components/card/card-edition/card-edition';
import { OriginatorPaymentInstitutionCardCreate } from 'entities/originator/components/originator-payment-institution-card/originator-payment-institution-card-create/originator-payment-institution-card-create';
import { OriginatorPaymentInstitutionCardDetail } from 'entities/originator/components/originator-payment-institution-card/originator-payment-institution-card-detail/originator-payment-institution-card-detail';

import { useTranslation } from 'react-i18next';
import { validateScdOriginatorPaymentInstitution } from 'entities/originator/validation/originator-payment-institution-validation';

export interface OriginatorPaymentInstitutionEdition extends OriginatorPaymentInstitution, ICardEdition {
    uniqueKey: string;
}

interface OriginatorPaymentInstitutionManagerProps {
    originatorPaymentInstitutions: readonly OriginatorPaymentInstitution[] | undefined;
    isEditingOriginator: boolean;
    onUpdate: (originatorsPaymentInstitutionList: ReadonlyArray<OriginatorPaymentInstitution>) => void;
    editionUpdate?: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.originator.input.originatorPaymentInstitution';

const OriginatorPaymentInstitutionManager = (props: OriginatorPaymentInstitutionManagerProps) => {
    const { originatorPaymentInstitutions, isEditingOriginator, onUpdate, editionUpdate } = props;
    const [editing, setEditing] = useState(false);

    const { t } = useTranslation();
    const [originatorsPaymentInstitutionList, setOriginatorsPaymentInstitutionList] = useState<OriginatorPaymentInstitutionEdition[]>(
        originatorPaymentInstitutions?.map(it => ({
            ...it,
            isNew: false,
            uniqueKey: uuidV4(),
        })) ?? []
    );

    const updateEditing = (value: boolean) => {
        setEditing(value);
        if (editionUpdate) {
            editionUpdate(value);
        }
    };

    const handleAdd = (_event: any) => {
        setOriginatorsPaymentInstitutionList(ps => [...ps, { ...defaultOriginatorPaymentInstitution, isNew: true, uniqueKey: uuidV4() }]);
        updateEditing(true);
    };

    const handleSave = (_index: number, _update: OriginatorPaymentInstitutionEdition) => {
        const update = originatorsPaymentInstitutionList.map((it, index) => (index === _index ? { ..._update, isNew: false } : it));
        setOriginatorsPaymentInstitutionList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
        updateEditing(false);
    };

    const handleRemove = (_index: number) => {
        const update = originatorsPaymentInstitutionList.filter((_it, index) => index !== _index);

        setOriginatorsPaymentInstitutionList(update);
        onUpdate([...update.map(it => ({ ...it }))]);
    };

    const handleCancel = (_index: number) => {
        if (!originatorsPaymentInstitutionList[_index]) return;
        if (originatorsPaymentInstitutionList[_index].isNew) {
            setOriginatorsPaymentInstitutionList(ps => ps.filter((_it, index) => index !== _index));
        }
        updateEditing(false);
    };

    const validateCard = (value: OriginatorPaymentInstitutionEdition): boolean => {
        return validateScdOriginatorPaymentInstitution({
            originatorPaymentInstitution: value,
            originatorPaymentInstitutions: originatorsPaymentInstitutionList ?? [],
        });
    };

    return (
        <div className="scd-originator__manager">
            {originatorsPaymentInstitutionList &&
                originatorsPaymentInstitutionList.length > 0 &&
                originatorsPaymentInstitutionList.map((item, index) => (
                    <CardEdition
                        key={item.uniqueKey}
                        entity={item}
                        onSave={value => handleSave(index, value)}
                        validate={value => validateCard(value)}
                        onRemove={() => handleRemove(index)}
                        onCancel={() => handleCancel(index)}
                        editable={true}
                        removable={!isEditingOriginator}
                        name={t(`${I18N_PREFIX}.name`, { number: `${index + 1}` })}
                        deleteTitle={t(`${I18N_PREFIX}.modal.delete.title`)}
                        deleteMessage={t('global.modal.delete.message')}
                        cancelMessage={t('entity.cancel.info')}
                    >
                        {({ isEditing, onChange, entity, showValidation }) => (
                            <>
                                {isEditing ? (
                                    <OriginatorPaymentInstitutionCardCreate
                                        originatorPaymentInstitutionIndex={index}
                                        isNewPaymentInstitution={entity.isNew}
                                        onChange={onChange}
                                        entity={entity}
                                        originatorPaymentInstitutions={originatorsPaymentInstitutionList}
                                        isEditing={isEditingOriginator}
                                        showValidation={showValidation}
                                    />
                                ) : (
                                    <OriginatorPaymentInstitutionCardDetail originatorPaymentInstitution={item} />
                                )}
                            </>
                        )}
                    </CardEdition>
                ))}
            {!editing && (
                <div className="scd-originator__manager--button">
                    <DashedButton label={`${I18N_PREFIX}.dashed-button.title`} onClick={handleAdd} />
                </div>
            )}
        </div>
    );
};

export default OriginatorPaymentInstitutionManager;
