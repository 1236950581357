import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationErrorsPending } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { getImportedOperationErrorsPendingError, getImportedOperationErrorsPendingSuccess } from './actions';
import { GetImportedOperationErrorsPendingTypes } from './types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleGetImportedOperationsErrorsPending() {
    try {
        const result: AxiosResponse<ScdImportedOperationErrorsPending> = yield call(importedOperationApi.getErrorsPending);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(getImportedOperationErrorsPendingError(mapped));
            return;
        }
        yield put(getImportedOperationErrorsPendingSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getImportedOperationErrorsPendingError(mapped));
    }
}

function* watchImportedOperationsErrorsPending() {
    yield takeEvery(GetImportedOperationErrorsPendingTypes.GET_IMPORTED_OPERATION_ERRORS_PENDING_REQUEST, handleGetImportedOperationsErrorsPending);
}

function* getImportedOperationsErrorPendingSaga() {
    yield all([fork(watchImportedOperationsErrorsPending)]);
}

export default getImportedOperationsErrorPendingSaga;
