import Loading from 'components/loading/loading';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import TransfeeraStatementRow, {
    TransfeeraStatementRowProps,
} from 'shared/external/payment-accounts-strategy/statement/components/transfeera/components/transfeera-statement-row/transfeera-statement-row';
import { TransfeeraExternalStatementEntries } from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import { LIST_HEIGHT, LIST_ITEMS_SIZE } from 'shared/infinite-components/constants';

interface TransfeeraStatementListProps {
    externalStatementItems: readonly TransfeeraExternalStatementEntries[];
    setSelectedExternalStatement: (value: TransfeeraExternalStatementEntries) => void;
    isLoading: boolean;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement';

const TransfeeraStatementList = (props: TransfeeraStatementListProps) => {
    const { externalStatementItems, isLoading, setSelectedExternalStatement } = props;

    const { t } = useTranslation();

    const transfeeraStatementData: TransfeeraStatementRowProps = {
        externalStatementItems,
        setSelectedExternalStatement,
    };

    return (
        <>
            {isLoading && !externalStatementItems.length && <Loading isTable />}
            {externalStatementItems.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={transfeeraStatementData}
                            height={height}
                            width={width}
                            itemSize={LIST_ITEMS_SIZE}
                            itemCount={externalStatementItems.length}
                        >
                            {TransfeeraStatementRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="transfeera-statement__table-empty-filter">
                            <span className="transfeera-statement__table-empty-filter-image" />
                            <span className="transfeera-statement__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default TransfeeraStatementList;
