import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListPaymentInstitutionsAccountsState, ListPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/types';

const initialState: ListPaymentInstitutionsAccountsState = {
    status: HttpRequestStatus.NOOP,
    paymentInstitutionsAccounts: undefined,
    error: undefined,
};

const reducer: Reducer<ListPaymentInstitutionsAccountsState> = (state = initialState, action): ListPaymentInstitutionsAccountsState => {
    switch (action.type) {
        case ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentInstitutionsAccounts: action.payload, error: undefined };
        }
        case ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListPaymentInstitutionsAccountsTypes.LIST_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listPaymentInstitutionsAccountsReducer };
