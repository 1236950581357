import MenuItemLegacy, { MenuItemLegacyPropsList } from 'shared/navbar/components/menu-item-legacy/menu-item-legacy';
import { EnvironmentUtils } from 'shared/util/environment-utils';

const I18N_PREFIX = 'navbar-legacy.entities';

export const EntitiesMenuLegacy = () => {
    const entities: MenuItemLegacyPropsList[] = [
        {
            translation: `${I18N_PREFIX}.program.main`,
            visibility: true,
            submenu: [
                {
                    link: '/programs',
                    translation: `${I18N_PREFIX}.program.submenu.programs`,
                    visibility: true,
                },
                {
                    link: '/program-policies',
                    translation: `${I18N_PREFIX}.program.submenu.program-policies`,
                    visibility: true,
                },
                {
                    link: '/companies',
                    translation: `${I18N_PREFIX}.company.submenu.companies`,
                    visibility: true,
                },
                {
                    link: '/originators',
                    translation: `${I18N_PREFIX}.originator.submenu.originators`,
                    visibility: true,
                },
                {
                    link: '/bankers',
                    translation: `${I18N_PREFIX}.banker.submenu.bankers`,
                    visibility: true,
                },
                {
                    link: '/technical-suppliers',
                    translation: `${I18N_PREFIX}.technical-supplier.submenu.technical-suppliers`,
                    visibility: true,
                },
                {
                    link: '/organizations',
                    translation: `${I18N_PREFIX}.organizations.submenu.organizations`,
                    visibility: true,
                },
                {
                    link: '/company-groups',
                    translation: `${I18N_PREFIX}.company-group.submenu.company-groups`,
                    visibility: true,
                },
                {
                    link: '/company-group-policies',
                    translation: `${I18N_PREFIX}.company-group.submenu.company-group-policies`,
                    visibility: true,
                },
                {
                    link: '/issuers',
                    translation: `${I18N_PREFIX}.issuer.submenu.issuers`,
                    visibility: true,
                },
                {
                    link: '/payment-institutions-accounts',
                    translation: `${I18N_PREFIX}.payment-institutions-accounts.main`,
                    visibility: true,
                },
                {
                    link: '/users',
                    translation: `${I18N_PREFIX}.user.main`,
                    visibility: true,
                },
                {
                    link: '/banks',
                    translation: `${I18N_PREFIX}.banks.main`,
                    visibility: true,
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.report.main`,
            visibility: true,
            submenu: [
                {
                    link: '/disbursement-report',
                    translation: `${I18N_PREFIX}.report.submenu.disbursement-report`,
                    visibility: true,
                },
                {
                    link: '/monthly-processed-operations-report',
                    translation: `${I18N_PREFIX}.report.submenu.monthly-processed-operations-report`,
                    visibility: true,
                },
                {
                    link: '/fare-amount-report',
                    translation: `${I18N_PREFIX}.report.submenu.fare-amount-report`,
                    visibility: true,
                },
                {
                    link: '/request-log',
                    translation: `${I18N_PREFIX}.report.submenu.request-log`,
                    visibility: EnvironmentUtils.isStagingEnvironment(),
                },
                {
                    link: '/request-log/dev',
                    translation: `${I18N_PREFIX}.report.submenu.request-log`,
                    visibility: !EnvironmentUtils.isProductionEnvironment()
                }
            ]
        },
        {
            translation: `${I18N_PREFIX}.transactions.main`,
            visibility: true,
            submenu: [
                {
                    link: '/payment-transactions',
                    translation: `${I18N_PREFIX}.transactions.submenu.transactions`,
                    visibility: true,
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.batch.main`,
            visibility: true,
            submenu: [
                {
                    link: '/batches',
                    translation: `${I18N_PREFIX}.batch.submenu.batches`,
                    visibility: true,
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.operation.main`,
            visibility: true,
            submenu: [
                {
                    link: '/ccb-operations',
                    translation: `${I18N_PREFIX}.operation.submenu.operations`,
                    visibility: true,
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.tax.main`,
            visibility: true,
            submenu: [
                {
                    link: '/taxes',
                    translation: `${I18N_PREFIX}.tax.submenu.taxes`,
                    visibility: true,
                },
            ],
        },
        {
            translation: `${I18N_PREFIX}.monthly-commission.main`,
            visibility: false,
            submenu: [
                {
                    link: '/monthly-commissions',
                    translation: `${I18N_PREFIX}.monthly-commission.submenu.monthly-commissions`,
                    visibility: false,
                },
            ],
        },
        // TODO: PROVISORY LINK ITEM
        {
            translation: `${I18N_PREFIX}.provisoryLink.main`,
            visibility: !EnvironmentUtils.isProductionEnvironment(),
            submenu: [
                {
                    href: '/admin-payment-accounts',
                    translation: `${I18N_PREFIX}.provisoryLink.submenu.paymentAccount`,
                    visibility: !EnvironmentUtils.isProductionEnvironment(),
                },
            ],
        },
    ];

    return <MenuItemLegacy list={entities} menu={true} />;
};

export default EntitiesMenuLegacy;
