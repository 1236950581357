import { ScdRequestLogData, ScdRequestLogDetail } from 'model/request-log';
import { AxiosResponse } from 'axios';
import { ScdRequestLogFilterPageable } from 'model/request-log';
import { api } from 'services/api/api';
import { Page } from 'services/page';

const requestLog = () => {
    const listRequestLogs = (request: ScdRequestLogFilterPageable): Promise<AxiosResponse<Page<ScdRequestLogData>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.createdDate?.start && filter?.createdDate?.end
                ? `createdDate.start=${filter?.createdDate?.start}&createdDate.end=${filter?.createdDate?.end}`
                : undefined,
            filter?.createdDate?.start && !filter?.createdDate?.end ? `createdDate.start=${filter?.createdDate?.start}` : undefined,
            !filter?.createdDate?.start && filter?.createdDate?.end ? `createdDate.end=${filter?.createdDate?.end}` : undefined,

            filter?.responseStatus && `responseStatus=${filter?.responseStatus}`,

            filter?.route && `route=${filter.route}`,

            filter?.organizationKey && `organizationKey=${filter.organizationKey}`,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdRequestLogData>>(`api/admin/request-logs?${_requestFilters}`, { params: { ...pageable } });
    };

    const detailRequestLog = (requestLogId: number): Promise<AxiosResponse<ScdRequestLogDetail>> => {
        return api.get<ScdRequestLogDetail>(`api/admin/request-logs/${requestLogId}`);
    };

    return {
        listRequestLogs,
        detailRequestLog,
    };
};

export default requestLog();
