import { GiroWebappError } from 'model/error';
import { ExternalTransfersPaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/external-transfers-payment-institution-account/types';
import { ExternalTransfersRequest, ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';
import { action } from 'typesafe-actions';

export const externalTransfersPaymentInstitutionsAccountsRequest = (request: ExternalTransfersRequest) =>
    action(ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, request)

export const externalTransfersPaymentInstitutionsAccountsSuccess = (transfers: ExternalTransfersResponse) =>
    action(ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, transfers);

export const externalTransfersPaymentInstitutionsAccountsError = (error: GiroWebappError) =>
    action(ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error)

export const externalTransfersPaymentInstitutionsAccountsResetState = () =>
    action(ExternalTransfersPaymentInstitutionsAccountsTypes.EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
