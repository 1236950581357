import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdBankerSimple } from 'model/banker';
import { updateBankerError, updateBankerSuccess, updateBankerRequest } from './actions';
import { UpdateBankerTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import bankerApi from 'services/api/banker-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdateBanker(action: ReturnType<typeof updateBankerRequest>) {
    try {
        const result: AxiosResponse<ScdBankerSimple> = yield call(bankerApi.updateBanker, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updateBankerError(mapped));
            return;
        }
        yield put(updateBankerSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updateBankerError(mapped));
    }
}

function* watchUpdateBanker() {
    yield takeLeading(UpdateBankerTypes.UPDATE_BANKER_REQUEST, handleUpdateBanker);
}

function* updateBankerSaga() {
    yield all([fork(watchUpdateBanker)]);
}

export default updateBankerSaga;
