import { ChunkStreamProcessRequest, ChunkStreamProcessResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { ChunkStreamProcessBcoTypes } from 'reducer-bco/batch-output/chunk-stream-process/types';
import { action } from 'typesafe-actions';

export const chunkStreamProcessBcoRequest = (request: ChunkStreamProcessRequest) =>
    action(ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_REQUEST, request);
export const chunkStreamProcessBcoSuccess = (chunkStreamProcessBco: ChunkStreamProcessResponse) =>
    action(ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_SUCCESS, chunkStreamProcessBco);
export const chunkStreamProcessBcoError = (error: GiroWebappError) =>
    action(ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_ERROR, error);
export const chunkStreamProcessBcoResetState = () => action(ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_RESET);
