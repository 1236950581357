import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { Loading } from 'components/loading/loading';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { detailProgramRequest, detailProgramResetState } from 'reducer/program/detail-program/actions';
import { useDetailProgramState, useRootDispatch } from 'reducer/hooks';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import PercentageFormatter from 'components/formatter/percentage/percentage-formatter';

import './legacy-program-detail.scss';

type ScdProgramDetailProps = RouteComponentProps<{ programId: string }>;

const I18N_PREFIX = 'pages.scd.program.input';

const ScdLegacyProgramDetailPage = (props: ScdProgramDetailProps) => {
    const { t } = useTranslation();
    const [programId] = useState<string>(props.match.params.programId);
    const { status, program } = useDetailProgramState();

    const loading = status !== HttpRequestStatus.SUCCESS;

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailProgramRequest(programId));
        return () => {
            dispatch(detailProgramResetState());
        };
    }, [dispatch, programId]);

    return (
        <main className="scd-legacy-program__detail">
            <ContextRibbon />
            <div className="scd-legacy-program--container">
                <header className="scd-legacy-program--header">
                    <h2 className="scd-legacy-program--header-title">{t('pages.scd.program.detail.title')}</h2>
                </header>
                <div className="scd-legacy-program__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.name.label`)} value={program?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.identifier.label`)} value={program?.identifier ?? '-'} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.goodwill.label`)}
                                value={<PercentageFormatter value={program?.goodwill} />}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.status.label`)}
                                value={t(`${I18N_PREFIX}.status.options.${program?.status}`)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.productType.label`)}
                                value={t(`${I18N_PREFIX}.productType.options.${program?.productType}`)}
                            />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.organization.label`)} value={program?.organization?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.originator.label`)} value={program?.originator?.name} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.issuer.label`)} value={program?.issuer?.name} />

                            {program?.companyGroups && (
                                <div className="scd-legacy-program__detail--card">
                                    <ReadOnlyCard name={t('pages.scd.program.detail.companyGroups.title')}>
                                        {program?.companyGroups?.map(item => (
                                            <ReadOnlyTextField key={item.id} value={item?.name} />
                                        ))}
                                    </ReadOnlyCard>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdLegacyProgramDetailPage;
