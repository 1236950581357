import { ScdCompanyGroupPolicy, ScdCompanyGroupPolicySimple } from 'model/company-group-policy';
import { GiroWebappError } from 'model/error';
import { CreateCompanyGroupPolicyTypes } from 'reducer/company-group-policy/create-company-group-policy/types';
import { action } from 'typesafe-actions';

export const createCompanyGroupPolicyRequest = (companyGroupPolicy: ScdCompanyGroupPolicy) =>
    action(CreateCompanyGroupPolicyTypes.CREATE_COMPANY_GROUP_POLICY_REQUEST, companyGroupPolicy);

export const createCompanyGroupPolicySuccess = (createdCompanyGroupPolicy: ScdCompanyGroupPolicySimple) =>
    action(CreateCompanyGroupPolicyTypes.CREATE_COMPANY_GROUP_POLICY_SUCCESS, createdCompanyGroupPolicy);

export const createCompanyGroupPolicyError = (error: GiroWebappError) =>
    action(CreateCompanyGroupPolicyTypes.CREATE_COMPANY_GROUP_POLICY_ERROR, error);

export const createCompanyGroupPolicyResetState = () => action(CreateCompanyGroupPolicyTypes.CREATE_COMPANY_GROUP_POLICY_RESET);
