import clsx from 'clsx';
import EditIconButton from 'components/buttons/icon-buttons/edit/edit-icon-button';
import VisualizeIconButton from 'components/buttons/icon-buttons/visualize/visualize-icon-button';
import { UsersToGetAll } from 'model/user';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';

const I18N_PREFIX = 'pages.scd.users.table';

export interface UsersRowProps {
    users: UsersToGetAll[];
}

const UserRow = (props: ListChildComponentProps<UsersRowProps>) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();

    const { index, data, style } = props;

    const { users } = data;

    const { id, login, firstName, lastName, activated } = users[index];

    const ascertainPersonName = (): string => {
        if (!firstName) return '-';
        if (!lastName) return firstName;

        return `${firstName} ${lastName}`;
    };

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">{login ?? '-'}</td>
            <td className="page-container--table-cell-title">{ascertainPersonName()}</td>
            <td className="page-container--table-cell-title">
                <span className="page-container--table-cell-title">
                    <span
                        className={clsx('page-container--table-status', {
                            ACTIVE: activated?.toString() === 'true',
                            INACTIVE: activated?.toString() === 'false',
                        })}
                    >
                        {t(`${I18N_PREFIX}.status.options.${activated ? 'ACTIVE' : 'INACTIVE'}`)}
                    </span>
                </span>
            </td>
            <td className="page-container--table-cell-title">
                <Link to={`${url}/${id}`}>
                    <VisualizeIconButton />
                </Link>
            </td>
            <td className="page-container--table-cell-title">
                <Link to={`${url}/${id}/edit`}>
                    <EditIconButton />
                </Link>
            </td>
        </tr>
    );
};

export default UserRow;
