import { Pageable } from 'services/pageable';

export interface Bank {
    id?: number;
    name?: string;
    number?: string;
    identification?: string;
}

export interface BankList {
    id: number;
    name: string;
    number: string;
    identification: string;
}

export interface BankFilterRequest {
    name?: string;
    number?: string;
    identification?: string;
}

export interface BankFilterPageable {
    filter?: BankFilterRequest;
    pageable?: Pageable;
}

export const defaultBankFilterRequest: Readonly<BankFilterRequest> = {};
