import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentInstitutionAccountStatus } from 'model/enums/payment-institution-account-status';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useResumePaymentInstitutionAccountState, useRootDispatch } from 'reducer/hooks';
import { resumePaymentInstitutionAccountRequest } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/actions';
import TransfersError from 'shared/external/payment-accounts-strategy/transfers/components/transfers-error/transfers-error';

import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import { useCallback } from 'react';
import './transfers-fallback.scss';

const I18N_PREFIX = 'payment-accounts-strategy';

interface FallbackProps {
    paymentInstitutionAccountId: number;
}

const TransfersFallback = (props: FallbackProps) => {
    const { paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const { resumedPaymentInstitutionAccount, status } = useResumePaymentInstitutionAccountState();

    const history = useHistory();
    const dispatch = useRootDispatch();

    const handleTryAgain = useCallback(() => {
        dispatch(resumePaymentInstitutionAccountRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    const hasError: boolean = status === HttpRequestStatus.ERROR;

    return (
        <main className="transfers-fallback">
            <ContextRibbon />
            {hasError ? (
                <TransfersError handleRetry={handleTryAgain} />
            ) : (
                <section className="transfers-fallback__container">
                    <header className="transfers-fallback__header">
                        <div className="transfers-fallback__header-container">
                            <div className="transfers-fallback__header-container--align">
                                <h2 className="transfers-fallback__header-container--title">{resumedPaymentInstitutionAccount?.name}</h2>
                                <RefreshButton onClick={handleTryAgain} />
                            </div>
                            <div className="transfers-fallback__header-account-infos">
                                <span className="transfers-fallback__header-account-infos--item">
                                    {t(`${I18N_PREFIX}.type.${resumedPaymentInstitutionAccount?.type}`)}
                                </span>
                                <span className="transfers-fallback__header-account-infos--item">
                                    {resumedPaymentInstitutionAccount?.technicalSupplierName}
                                </span>
                                <span
                                    className={clsx('transfers-fallback__header-account-infos--status', {
                                        ACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.ACTIVE,
                                        INACTIVE: resumedPaymentInstitutionAccount?.status === PaymentInstitutionAccountStatus.INACTIVE,
                                    })}
                                >
                                    {t(`${I18N_PREFIX}.status.${resumedPaymentInstitutionAccount?.status}`).toUpperCase()}
                                </span>
                            </div>
                        </div>
                    </header>
                    <div className="transfers-fallback--container">
                        <span className="transfers-fallback--message">
                            <Trans
                                t={t}
                                i18nKey={`${I18N_PREFIX}.unsupported-external-transfers`}
                                values={{ name: resumedPaymentInstitutionAccount?.technicalSupplierName ?? '' }}
                            />
                        </span>
                        <BackButton label={t('entity.action.back')} onClick={() => history.push('/payment-institutions-accounts')} />
                    </div>
                </section>
            )}
        </main>
    );
};

export default TransfersFallback;
