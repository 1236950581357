import { GiroWebappError } from 'model/error';
import { ScdMonthlyCommissionDetail } from 'model/monthly-commission';
import { DetailMonthlyCommissionTypes } from 'reducer/monthly-commission/detail-monthly-commission/types';
import { action } from 'typesafe-actions';

export const detailMonthlyCommissionRequest = (monthlyCommissionId: number) =>
    action(DetailMonthlyCommissionTypes.DETAIL_MONTHLY_COMMISSION_REQUEST, monthlyCommissionId);
export const detailMonthlyCommissionSuccess = (monthlyCommission: ScdMonthlyCommissionDetail) =>
    action(DetailMonthlyCommissionTypes.DETAIL_MONTHLY_COMMISSION_SUCCESS, monthlyCommission);
export const detailMonthlyCommissionError = (error: GiroWebappError) =>
    action(DetailMonthlyCommissionTypes.DETAIL_MONTHLY_COMMISSION_ERROR, error);
export const detailMonthlyCommissionResetState = () => action(DetailMonthlyCommissionTypes.DETAIL_MONTHLY_COMMISSION_RESET);
