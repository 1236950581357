import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';
import ScdCompanyPage from 'entities/company/company';
import ScdCompanyCreateUpdate from 'entities/company/company-create-update/company-create-update';
import CompanyDetail from 'entities/company/company-detail/company-detail';

interface MatchParams {
    url: string;
}

const CompanyRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdCompanyPage} />
        <PrivateRoute exact path={`${match.url}/new`} component={ScdCompanyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyId/edit`} component={ScdCompanyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyId`} component={CompanyDetail} />
    </Switch>
);

export default CompanyRoutes;
