import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { archiveBatchOperationRequest } from 'reducer/batch/archive-batch-operation/actions';
import { deleteBatchOperationRequest } from 'reducer/batch/delete-batch-operation/actions';
import { exportBatchRequest } from 'reducer/batch/export-batch/actions';
import { sendBatchCcbDocumentsRequest } from 'reducer/batch/send-ccb-documents/actions';
import { sendBatchCnabDocumentRequest } from 'reducer/batch/send-cnab-document/actions';
import { useRootDispatch } from 'reducer/hooks';
import { importedOperationsReexportRequest } from 'reducer/imported-operation/reexport/actions';
import { getPersonType } from 'shared/util/person-type-utils';

export const enum BatchModalActionOptions {
    DELETE_OPERATION = 'DELETE_OPERATION',
    CANCEL_OPERATION = 'CANCEL_OPERATION',
    TRANSFER_OPERATION = 'TRANSFER_OPERATION',
    TRANSFER_OPERATION_LEGACY = 'TRANSFER_OPERATION_LEGACY',
    EXPORT_WEBCRED = 'EXPORT_WEBCRED',
    REEXPORT_WEBCRED = 'REEXPORT_WEBCRED',
    SEND_CCB_DOCUMENTS = 'SEND_CCB_DOCUMENTS',
    SEND_CNAB_DOCUMENT = 'SEND_CNAB_DOCUMENT',
    AUTOMATIC_PAYMENT = 'AUTOMATIC_PAYMENT',
    ARCHIVE_OPERATION = 'ARCHIVE_OPERATION',
}

const I18N_PREFIX = 'pages.scd.batch';

interface BatchModalActionProps {
    batchId: number | undefined;
    modalAction: BatchModalActionOptions | undefined;
    setModalAction: (value: BatchModalActionOptions | undefined) => void;
    operation?: ScdImportedOperationBatch | undefined;
    disabled: boolean;
}

const BatchModalAction = (props: BatchModalActionProps) => {
    const { modalAction, setModalAction, batchId, operation, disabled } = props;

    const { t } = useTranslation();
    const dispatch = useRootDispatch();
    const history = useHistory();

    if (!modalAction) {
        return <> </>;
    }

    const handleExportBatch = () => {
        if (!batchId) return;
        dispatch(exportBatchRequest(batchId));
    };

    // TODO replace window.open by history.push in task 471
    const handleTransferOperationsLegacy = () => {
        if (!batchId) return;
        window.open(`/admin/payment-transactions/in-series-legacy/disbursement/by-batch/${batchId}`)
    };

    const handleTransferOperations = () => {
        if (!batchId) return;
        history.push(`/payment-transactions/in-series/disbursement/by-batch/${batchId}`)
    };

    const handleReExportOperation = () => {
        if (!operation?.id) return;
        dispatch(importedOperationsReexportRequest(operation.id));
    };

    const handleSendCcbDocuments = () => {
        if (!batchId) return;
        dispatch(sendBatchCcbDocumentsRequest(batchId));
    };

    const handleSendCnabDocument = () => {
        if (!batchId) return;
        dispatch(sendBatchCnabDocumentRequest(batchId));
    };

    const handleRemoveOperation = () => {
        if (!batchId) return;
        const operationId = Number(operation?.id);
        dispatch(deleteBatchOperationRequest({ batchId, operationId }));
    };

    const handleArchiveOperation = () => {
        if (!batchId || !operation?.id) return;
        dispatch(archiveBatchOperationRequest({ id: batchId, operationId: operation.id }));
    };

    const modalTitle = {
        TRANSFER_OPERATION_LEGACY: t(`${I18N_PREFIX}.detail.modal.transfer.title-legacy`),
        TRANSFER_OPERATION: t(`${I18N_PREFIX}.detail.modal.transfer.title`),
        DELETE_OPERATION: t(`${I18N_PREFIX}.detail.modal.operation.delete.title`),
        EXPORT_WEBCRED: t(`${I18N_PREFIX}.detail.modal.export-webcred.title`),
        REEXPORT_WEBCRED: t(`${I18N_PREFIX}.detail.modal.reexport-webcred.title`),
        SEND_CCB_DOCUMENTS: t(`${I18N_PREFIX}.detail.modal.send-ccb-documents.title`),
        SEND_CNAB_DOCUMENT: t(`${I18N_PREFIX}.detail.modal.send-cnab-document.title`),
        AUTOMATIC_PAYMENT: t(`${I18N_PREFIX}.detail.modal.automatic-payment.title`),
        ARCHIVE_OPERATION: t(`${I18N_PREFIX}.detail.modal.archive-operation.title`),
    } as Record<BatchModalActionOptions, string>;

    const modalMessage = {
        DELETE_OPERATION: (
            <Trans
                t={t}
                i18nKey={`${I18N_PREFIX}.detail.modal.operation.delete.message`}
                values={{ ccbNumber: operation?.ccbNumber }}
                components={{
                    identification: (
                        <IdentificationFormatter
                            value={operation?.debtorIdentification}
                            type={operation?.debtorType ?? getPersonType(operation?.debtorIdentification)}
                        />
                    ),
                }}
            />
        ),
        EXPORT_WEBCRED: t(`${I18N_PREFIX}.detail.modal.export-webcred.message`),
        REEXPORT_WEBCRED: t(`${I18N_PREFIX}.detail.modal.reexport-webcred.message`),
        SEND_CCB_DOCUMENTS: t(`${I18N_PREFIX}.detail.modal.send-ccb-documents.message`),
        SEND_CNAB_DOCUMENT: t(`${I18N_PREFIX}.detail.modal.send-cnab-document.message`),
        AUTOMATIC_PAYMENT: t(`${I18N_PREFIX}.detail.modal.automatic-payment.message`),
        ARCHIVE_OPERATION: (
            <Trans
                t={t}
                i18nKey={`${I18N_PREFIX}.detail.modal.archive-operation.message`}
                values={{ ccbNumber: operation?.ccbNumber }}
                components={{
                    identification: (
                        <IdentificationFormatter
                            value={operation?.debtorIdentification}
                            type={operation?.debtorType ?? getPersonType(operation?.debtorIdentification)}
                        />
                    ),
                }}
            />
        ),
    } as Record<BatchModalActionOptions, ReactNode>;

    const modalOnAction = {
        TRANSFER_OPERATION_LEGACY: handleTransferOperationsLegacy,
        TRANSFER_OPERATION: handleTransferOperations,
        DELETE_OPERATION: handleRemoveOperation,
        EXPORT_WEBCRED: handleExportBatch,
        REEXPORT_WEBCRED: handleReExportOperation,
        SEND_CCB_DOCUMENTS: handleSendCcbDocuments,
        SEND_CNAB_DOCUMENT: handleSendCnabDocument,
        ARCHIVE_OPERATION: handleArchiveOperation,
    } as Record<BatchModalActionOptions, () => void>;

    const modalStandardButtonLabel = {
        TRANSFER_OPERATION_LEGACY: t('entity.action.export'),
        TRANSFER_OPERATION: t('entity.action.export'),
        DELETE_OPERATION: t('entity.action.remove'),
        EXPORT_WEBCRED: t('entity.action.send'),
        REEXPORT_WEBCRED: t('entity.action.reesend'),
        SEND_CCB_DOCUMENTS: t('entity.action.send'),
        SEND_CNAB_DOCUMENT: t('entity.action.send'),
    } as Record<BatchModalActionOptions, string>;

    return (
        modalAction && (
            <ModalMessageLegacy
                onClose={() => setModalAction(undefined)}
                onCancel={() => setModalAction(undefined)}
                disabled={disabled}
                standardButtonLabel={modalStandardButtonLabel[modalAction]}
                title={modalTitle[modalAction]}
                children={<p className="scd-batch__modal-message">{modalMessage[modalAction]}</p>}
                onAction={modalOnAction[modalAction]}
            />
        )
    );
};

export default BatchModalAction;
