import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UpdatePaymentInstitutionAccountTypes, UpdatePaymentInstitutionsAccountState } from 'reducer/payment-institutions-accounts/update-payment-institution-account/types';

const initialState: UpdatePaymentInstitutionsAccountState = {
    status: HttpRequestStatus.NOOP,
    paymentInstitutionAccount: undefined,
    error: undefined,
};

const reducer: Reducer<UpdatePaymentInstitutionsAccountState> = (state = initialState, action): UpdatePaymentInstitutionsAccountState => {
    switch (action.type) {
        case UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentInstitutionAccount: action.payload, error: undefined };
        }
        case UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as updatePaymentInstitutionAccountReducer };
