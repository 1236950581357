import { GiroWebappError } from 'model/error';
import { ScdImportedOperationDocumentRequest } from 'model/imported-operation';
import { ImportedOperationDocumentTypes } from 'reducer/imported-operation/document/types';
import { action } from 'typesafe-actions';

export const importedOperationDocumentRequest = (request: ScdImportedOperationDocumentRequest) =>
    action(ImportedOperationDocumentTypes.IMPORTED_OPERATION_DOCUMENT_REQUEST, request);
export const importedOperationDocumentSuccess = (data: Uint8Array) =>
    action(ImportedOperationDocumentTypes.IMPORTED_OPERATION_DOCUMENT_SUCCESS, data);
export const importedOperationDocumentError = (error: GiroWebappError) =>
    action(ImportedOperationDocumentTypes.IMPORTED_OPERATION_DOCUMENT_ERROR, error);
export const importedOperationDocumentResetState = () => action(ImportedOperationDocumentTypes.IMPORTED_OPERATION_DOCUMENT_RESET_STATE);
