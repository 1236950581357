import { ScdImportedOperationMonthlyProcessedReport } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum MonthlyProcessedImportedOperationsReportTypes {
    MONTHLY_PROCESSED_OPERATIONS_REPORT_REQUEST = '@@imported-operations-monthly-processed-report/MONTHLY_PROCESSED_OPERATIONS_REPORT_REQUEST',
    MONTHLY_PROCESSED_OPERATIONS_REPORT_SUCCESS = '@@imported-operations-monthly-processed-report/MONTHLY_PROCESSED_OPERATIONS_REPORT_SUCCESS',
    MONTHLY_PROCESSED_OPERATIONS_REPORT_ERROR = '@@imported-operations-monthly-processed-report/MONTHLY_PROCESSED_OPERATIONS_REPORT_ERROR',
    MONTHLY_PROCESSED_OPERATIONS_REPORT_RESET_STATE = '@@imported-operations-monthly-processed-report/MONTHLY_PROCESSED_OPERATIONS_REPORT_RESET_STATE',
}

export interface MonthlyProcessedImportedOperationsReportState extends BaseStoreState {
    readonly monthlyProcessedOperationsReportData?: ScdImportedOperationMonthlyProcessedReport[];
}
