import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdCompany } from 'model/company';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.company.table';

interface ScdCompanyHeaderProps {
    companies: ScdCompany[];
    handleSort: (property: keyof ScdCompany, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export const ScdCompanyHeader = (props: ScdCompanyHeaderProps) => {
    const { t } = useTranslation();
    const { companies, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof ScdCompany) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (companies && companies.length <= 1) ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`).toUpperCase()} sortable={mapSortable('name')} />
            <SortableColumn label={t(`${I18N_PREFIX}.identification`).toUpperCase()} sortable={mapSortable('identification')} />
            <SortableColumn label={t(`${I18N_PREFIX}.tradeName`).toUpperCase()} sortable={mapSortable('tradeName')} />
        </tr>
    );
};

export default ScdCompanyHeader;
