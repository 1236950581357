import { AxiosResponse } from 'axios';
import { ScdBatchToGenerateCessionResponse } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchPreviewCessionError, batchPreviewCessionRequest, batchPreviewCessionSuccess } from 'reducer/batch/preview-cession/actions';
import { BatchPreviewCessionTypes } from 'reducer/batch/preview-cession/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchPreviewCession(action: ReturnType<typeof batchPreviewCessionRequest>) {
    try {
        const result: AxiosResponse<ScdBatchToGenerateCessionResponse> = yield call(batchAPI.previewCession, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchPreviewCessionError(mapped));
            return;
        }
        yield put(batchPreviewCessionSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchPreviewCessionError(mapped));
    }
}

function* watchBatchPreviewCession() {
    yield takeLeading(BatchPreviewCessionTypes.BATCH_PREVIEW_CESSION_REQUEST, handleBatchPreviewCession);
}

function* batchPreviewCessionSaga() {
    yield all([fork(watchBatchPreviewCession)]);
}

export default batchPreviewCessionSaga;
