import { ScdOrganizationChangeTokenResponse } from 'model/organization';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ChangeOrganizationTokenTypes {
    CHANGE_TOKEN_ORGANIZATION_REQUEST = '@@organization-change-token/CHANGE_TOKEN_ORGANIZATION_REQUEST',
    CHANGE_TOKEN_ORGANIZATION_SUCCESS = '@@organization-change-token/CHANGE_TOKEN_ORGANIZATION_SUCCESS',
    CHANGE_TOKEN_ORGANIZATION_ERROR = '@@organization-change-token/CHANGE_TOKEN_ORGANIZATION_ERROR',
    CHANGE_TOKEN_ORGANIZATION_RESET_STATE = '@@organization-change-token/CHANGE_TOKEN_ORGANIZATION_RESET_STATE',
}

export interface ChangeTokenOrganizationState extends BaseStoreState {
    readonly organizationDataToken?: ScdOrganizationChangeTokenResponse;
}
