import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { SimplePaymentInstitutionAccountToWithdraw } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { withdrawExternalBalanceRequest, withdrawExternalBalanceSuccess, withdrawExternalBalanceError } from 'reducer/payment-institutions-accounts/withdraw-external-balance/actions';
import { WithdrawExternalBalanceTypes } from 'reducer/payment-institutions-accounts/withdraw-external-balance/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleWithdrawExternalBalance(action: ReturnType<typeof withdrawExternalBalanceRequest>) {
    try {
        const result: AxiosResponse<SimplePaymentInstitutionAccountToWithdraw> = yield call(
            paymentInstitutionsAccountsApi.withdrawPaymentInstitutionsAccountBalance,
            action.payload
        );

        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(withdrawExternalBalanceError(mapped));
            return;
        }
        yield put(withdrawExternalBalanceSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(withdrawExternalBalanceError(mapped));
    }
}

function* watchWithdrawExternalBalance() {
    yield takeLeading(WithdrawExternalBalanceTypes.WITHDRAW_EXTERNAL_BALANCE_REQUEST, handleWithdrawExternalBalance);
}

function* withdrawExternalBalanceSaga() {
    yield all([fork(watchWithdrawExternalBalance)]);
}

export default withdrawExternalBalanceSaga;
