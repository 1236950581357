import { DataChunkRetryRequest, DataChunkSimpleResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { DataChunkRetryBcoTypes } from 'reducer-bco/batch-output/retry-data-chunk/types';
import { action } from 'typesafe-actions';

export const dataChunkRetryBcoRequest = (request: DataChunkRetryRequest) =>
    action(DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_REQUEST, request);
export const dataChunkRetryBcoSuccess = (dataChunkRetryBco: DataChunkSimpleResponse) =>
    action(DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_SUCCESS, dataChunkRetryBco);
export const dataChunkRetryBcoError = (error: GiroWebappError) => action(DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_ERROR, error);
export const dataChunkRetryBcoResetState = () => action(DataChunkRetryBcoTypes.DATA_CHUNK_RETRY_BCO_RESET);
