import FormatterUtils from 'components/formatter/formatter-utils';
import { ScdBatchDisbursementReport } from 'model/batch';
import { ScdDisbursementToReport } from 'model/disbursement-report';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

type ReportData = ScdDisbursementToReport;
type ReportHeader = Record<keyof ReportData, string>;

const I18N_PREFIX = 'pages.scd.batch.detail.report.disbursement.headers';

interface BatchDisbursementReportProps {
    batchDisbursementReport: ScdBatchDisbursementReport;
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
}

const BatchDisbursementReport = (props: BatchDisbursementReportProps) => {
    const { batchDisbursementReport, csvLinkRef } = props;
    const { t } = useTranslation();

    const disbursementfilename = useMemo(() => {
        const formattedDate = FormatterUtils.formatDateByYear(batchDisbursementReport?.date);
        return `RelatorioDeDesembolso_${batchDisbursementReport?.organization?.name ?? 'giro'}_${
            batchDisbursementReport?.code
        }_${formattedDate}.csv`;
    }, [batchDisbursementReport]);

    const DISBURSEMENT_REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            identification: t(`${I18N_PREFIX}.identification`),
            name: t(`${I18N_PREFIX}.name`),
            value: t(`${I18N_PREFIX}.value`),
        };
    }, [t]);

    return (
        <>
            <CSVLink
                className="hidden"
                filename={disbursementfilename}
                ref={csvLinkRef}
                data={batchDisbursementReport?.disbursements ?? []}
                headers={Object.keys(DISBURSEMENT_REPORT_HEADERS).map(key => ({ key, label: DISBURSEMENT_REPORT_HEADERS[key] }))}
            />
        </>
    );
};

export default BatchDisbursementReport;
