import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramInfo } from 'model/program-policy';
import { getProgramInfoError, getProgramInfoSuccess, getProgramInfoRequest } from './actions';
import { GetProgramInfoTypes } from './types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programPolicyApi from 'services/api/program-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleGetProgramInfo(action: ReturnType<typeof getProgramInfoRequest>) {
    try {
        const result: AxiosResponse<ScdProgramInfo> = yield call(programPolicyApi.getProgramInfo, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(getProgramInfoError(mapped));
            return;
        }
        yield put(getProgramInfoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(getProgramInfoError(mapped));
    }
}

function* watchGetProgramInfo() {
    yield takeEvery(GetProgramInfoTypes.GET_PROGRAM_INFO_REQUEST, handleGetProgramInfo);
}

function* getProgramInfoSaga() {
    yield all([fork(watchGetProgramInfo)]);
}

export default getProgramInfoSaga;
