import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useRootDispatch } from 'reducer/hooks';
import { externalBalancePaymentInstitutionsAccountsRequest } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/actions';
import ExternalErrorResponse from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/components/external-error-response';
import InternalErrorResponse from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/components/internal-error-response';
import TransfeeraValueItem from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/components/transfeera-value-item';
import WithdrawBalance from 'shared/external/payment-accounts-strategy/balance/components/transfeera/request-details/components/withdraw-balance/withdraw-balance';
import { ExternalBalanceProps } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';
import useGetExternalBalanceStateById from 'shared/external/util/state-util';

import OutlinedButtonLegacy from 'components/buttons/outlined-button-legacy/outlined-button-legacy';
import './transfeera-request-details.scss';

interface TransfeeraRequestDetailsProps extends ExternalBalanceProps {
    openModal: boolean;
    onClose: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.balance-details';

const TransfeeraRequestDetails = (props: TransfeeraRequestDetailsProps) => {
    const { openModal, onClose, paymentInstitutionAccountId } = props;

    const { t } = useTranslation();

    const { balance, error, status } = useGetExternalBalanceStateById(paymentInstitutionAccountId);

    const isLoading: boolean = status === HttpRequestStatus.ON_GOING;

    const dispatch = useRootDispatch();

    const prettifiedBalanceResponse = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(balance?.response);
    }, [balance?.response]);

    const prettifiedBalanceErrorResponse = useMemo((): string | undefined => {
        return JsonUtils.prettifyJsonByString(balance?.responseError);
    }, [balance?.responseError]);

    const handleExternalBalanceRequest = useCallback(() => {
        dispatch(externalBalancePaymentInstitutionsAccountsRequest(paymentInstitutionAccountId));
    }, [dispatch, paymentInstitutionAccountId]);

    const RawResponse: JSX.Element = (
        <>
            {!error && (
                <Grid item xs={12}>
                    <Accordion variant="outlined">
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <label>{t(`${I18N_PREFIX}.rawResponse`)}</label>
                        </AccordionSummary>
                        <AccordionDetails>
                            {balance?.isSuccessful ? <pre>{prettifiedBalanceResponse}</pre> : <pre>{prettifiedBalanceErrorResponse}</pre>}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
        </>
    );

    return (
        <>
            {openModal && (
                <Modal isOpen>
                    <div className="transfeera-request-details">
                        <div className="transfeera-request-details--header">
                            <label className="transfeera-request-details--header-title">{t(`${I18N_PREFIX}.title`)}</label>
                            <CloseButton onClick={onClose} />
                        </div>
                        <Grid container spacing={2} className="transfeera-request-details--content">
                            {isLoading ? (
                                <div className="transfeera-request-details--loading">
                                    <CircularProgress size="50px" />
                                </div>
                            ) : (
                                <>
                                    <TransfeeraValueItem balance={balance} />
                                    <InternalErrorResponse error={error} />
                                    <ExternalErrorResponse balance={balance} />
                                    <WithdrawBalance
                                        balance={balance}
                                        accountId={paymentInstitutionAccountId}
                                        onSuccess={handleExternalBalanceRequest}
                                    />
                                    {RawResponse}
                                </>
                            )}
                        </Grid>
                        <div className="transfeera-request-details--buttons">
                            <OutlinedButtonLegacy label={t(`${I18N_PREFIX}.close`)} onClick={onClose} />
                            <StandardButtonLegacy label={t(`${I18N_PREFIX}.update`)} onClick={handleExternalBalanceRequest} />
                        </div>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default TransfeeraRequestDetails;
