import { HttpRequestStatus } from 'model/enums/http-request-status';

import { Reducer } from 'redux';
import { ProcessCessionOperationBcoState, ProcessCessionOperationBcoTypes } from 'reducer-bco/batch-output/process-cession-operation/types';

const initialState: ProcessCessionOperationBcoState = {
    status: HttpRequestStatus.NOOP,
    processCessionOperation: undefined,
    error: undefined,
};

const reducer: Reducer<ProcessCessionOperationBcoState> = (state = initialState, action): ProcessCessionOperationBcoState => {
    switch (action.type) {
        case ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_SUCCESS: {
            return {
                ...state,
                status: HttpRequestStatus.SUCCESS,
                processCessionOperation: action.payload,
                error: undefined,
            };
        }
        case ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ProcessCessionOperationBcoTypes.PROCESS_CESSION_OPERATION_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as ProcessCessionOperationBcoReducer };
