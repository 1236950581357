import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupSimple } from 'model/company-group';

export type ApiResponse = Record<string, any>;

export enum UpdateCompanyGroupTypes {
    UPDATE_COMPANY_GROUP_REQUEST = '@@company-group-update/UPDATE_COMPANY_GROUP_REQUEST',
    UPDATE_COMPANY_GROUP_SUCCESS = '@@company-group-update/UPDATE_COMPANY_GROUP_SUCCESS',
    UPDATE_COMPANY_GROUP_ERROR = '@@company-group-update/UPDATE_COMPANY_GROUP_ERROR',
    UPDATE_COMPANY_GROUP_RESET = '@@company-group-update/UPDATE_COMPANY_GROUP_RESET',
}

export interface UpdateCompanyGroupState extends BaseStoreState {
    readonly companyGroup?: ScdCompanyGroupSimple;
}
