import { GiroWebappError } from 'model/error';
import { ScdProgramPolicy, ScdProgramPolicySimple } from 'model/program-policy';
import { CreateProgramPolicyTypes } from 'reducer/program-policy/create-program-policy/types';
import { action } from 'typesafe-actions';

export const createProgramPolicyRequest = (programPolicy: ScdProgramPolicy) =>
    action(CreateProgramPolicyTypes.CREATE_PROGRAM_POLICY_REQUEST, programPolicy);
export const createProgramPolicySuccess = (createdProgramPolicy: ScdProgramPolicySimple) =>
    action(CreateProgramPolicyTypes.CREATE_PROGRAM_POLICY_SUCCESS, createdProgramPolicy);
export const createProgramPolicyError = (error: GiroWebappError) => action(CreateProgramPolicyTypes.CREATE_PROGRAM_POLICY_ERROR, error);
export const createProgramPolicyResetState = () => action(CreateProgramPolicyTypes.CREATE_PROGRAM_POLICY_RESET_STATE);
