import { AxiosResponse } from 'axios';
import { ScdCompanyGroupPolicySimple } from 'model/company-group-policy';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    createCompanyGroupPolicyError,
    createCompanyGroupPolicyRequest,
    createCompanyGroupPolicySuccess,
} from 'reducer/company-group-policy/create-company-group-policy/actions';
import { CreateCompanyGroupPolicyTypes } from 'reducer/company-group-policy/create-company-group-policy/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import companyGroupPolicyAPI from 'services/api/company-group-policy-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateCompanyGroupPolicy(action: ReturnType<typeof createCompanyGroupPolicyRequest>) {
    try {
        const result: AxiosResponse<ScdCompanyGroupPolicySimple> = yield call(
            companyGroupPolicyAPI.createCompanyGroupPolicy,
            action.payload
        );
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createCompanyGroupPolicyError(mapped));
            return;
        }
        yield put(createCompanyGroupPolicySuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createCompanyGroupPolicyError(mapped));
    }
}

function* watchCreateCompanyGroupPolicy() {
    yield takeEvery(CreateCompanyGroupPolicyTypes.CREATE_COMPANY_GROUP_POLICY_REQUEST, handleCreateCompanyGroupPolicy);
}

function* createCompanyGroupPolicySaga() {
    yield all([fork(watchCreateCompanyGroupPolicy)]);
}

export default createCompanyGroupPolicySaga;
