import { EMAIL_REGEX } from 'config/constants';
import { ScdBanker } from 'model/banker';
import { Validations, ValidationUtils } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.banker.validate';

const BANKER_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.name.maxLength`,
    },
};
export const validateBankerName = (value: string | undefined | null) => ValidationUtils.validate(value, BANKER_NAME_VALIDATION);

const BANKER_EMAIL_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.email.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.email.required`,
    },
    maxLength: {
        predicate: ValidationUtils.STRING.maxLength(255),
        errorMessage: `${I18N_PREFIX}.email.maxLength`,
    },
    validEmail: {
        predicate: ValidationUtils.STRING.pattern(EMAIL_REGEX),
        errorMessage: `${I18N_PREFIX}.email.invalid`,
    },
};
export const validateBankerEmail = (value: string | undefined | null) => ValidationUtils.validate(value, BANKER_EMAIL_VALIDATION);

const BANKER_ORGANIZATION_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.organization.required`,
    },
};
export const validateBankerOrganization = (value: string | undefined | null) =>
    ValidationUtils.validate(value, BANKER_ORGANIZATION_VALIDATION);

export const validateScdBanker = (value: ScdBanker | undefined): boolean => {
    if (!value) return true;

    return (
        validateBankerName(value.userName).isValid &&
        validateBankerEmail(value.userEmail).isValid &&
        validateBankerOrganization(value.organizationId?.toString()).isValid
    );
};
