import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    CreatePaymentTransactionsByBatchLegacyTypes,
    CreatePaymentTransactionsByBatchLegacyState,
} from 'reducer/payment-transaction-by-batch/create-payment-transactions-by-batch-legacy/types';

const initialState: CreatePaymentTransactionsByBatchLegacyState = {
    status: HttpRequestStatus.NOOP,
    paymentTransactionsByBatchLegacyErrors: undefined,
    error: undefined,
};

const reducer: Reducer<CreatePaymentTransactionsByBatchLegacyState> = (
    state = initialState,
    action
): CreatePaymentTransactionsByBatchLegacyState => {
    switch (action.type) {
        case CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS: {
            return {
                ...state,
                status: HttpRequestStatus.SUCCESS,
                paymentTransactionsByBatchLegacyErrors: action.payload,
                error: undefined,
            };
        }
        case CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreatePaymentTransactionsByBatchLegacyTypes.CREATE_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createPaymentTransactionsByBatchLegacyReducer };
