import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionsToProcess } from 'model/payment-transaction';
import { LegacyProcessPaymentTransactionsTypes } from 'reducer/payment-transaction/legacy-process-payment-transactions/types';
import { action } from 'typesafe-actions';

export const legacyProcessPaymentTransactionsRequest = (request: ScdPaymentTransactionsToProcess) =>
    action(LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_REQUEST, request);
export const legacyProcessPaymentTransactionsSuccess = () => action(LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_SUCCESS);
export const legacyProcessPaymentTransactionsError = (error: GiroWebappError) =>
    action(LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_ERROR, error);
export const legacyProcessPaymentTransactionsResetState = () => action(LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_RESET);
