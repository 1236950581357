import { ChunkStreamToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ChunkStreamToResumeBcoTypes {
    CHUNK_STREAM_TO_RESUME_BCO_REQUEST = '@@chunk-stream-to-resume-bco/CHUNK_STREAM_TO_RESUME_BCO_REQUEST',
    CHUNK_STREAM_TO_RESUME_BCO_SUCCESS = '@@chunk-stream-to-resume-bco/CHUNK_STREAM_TO_RESUME_BCO_SUCCESS',
    CHUNK_STREAM_TO_RESUME_BCO_ERROR = '@@chunk-stream-to-resume-bco/CHUNK_STREAM_TO_RESUME_BCO_ERROR',
    CHUNK_STREAM_TO_RESUME_BCO_RESET = '@@chunk-stream-to-resume-bco/CHUNK_STREAM_TO_RESUME_BCO_RESET',
}

export interface ChunkStreamToResumeBcoState extends BaseStoreState {
    readonly chunkStreamToResume?: ChunkStreamToResumeBco;
}
