import { GiroWebappError } from 'model/error';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { ImportedOperationDetailTypes } from 'reducer/imported-operation/detail/types';
import { action } from 'typesafe-actions';

export const importedOperationDetailRequest = (operationId: number) =>
    action(ImportedOperationDetailTypes.IMPORTED_OPERATION_DETAIL_REQUEST, operationId);
export const importedOperationDetailSuccess = (data: ScdImportedOperationDetail) =>
    action(ImportedOperationDetailTypes.IMPORTED_OPERATION_DETAIL_SUCCESS, data);
export const importedOperationDetailError = (error: GiroWebappError) =>
    action(ImportedOperationDetailTypes.IMPORTED_OPERATION_DETAIL_ERROR, error);
export const importedOperationDetailResetState = () => action(ImportedOperationDetailTypes.IMPORTED_OPERATION_DETAIL_RESET_STATE);
