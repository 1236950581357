import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdProgramSimple } from 'model/program';
import { legacyUpdateProgramRequest, legacyUpdateProgramError, legacyUpdateProgramSuccess } from 'reducer/program/legacy-update-program/actions';
import { LegacyUpdateProgramTypes } from 'reducer/program/legacy-update-program/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import programApi from 'services/api/program-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleLegacyUpdateProgram(action: ReturnType<typeof legacyUpdateProgramRequest>) {
    try {
        const result: AxiosResponse<ScdProgramSimple> = yield call(programApi.legacyUpdateProgram, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(legacyUpdateProgramError(mapped));
            return;
        }
        yield put(legacyUpdateProgramSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(legacyUpdateProgramError(mapped));
    }
}

function* watchLegacyUpdateProgram() {
    yield takeEvery(LegacyUpdateProgramTypes.LEGACY_UPDATE_PROGRAM_REQUEST, handleLegacyUpdateProgram);
}

function* legacyUpdateProgramSaga() {
    yield all([fork(watchLegacyUpdateProgram)]);
}

export default legacyUpdateProgramSaga;
