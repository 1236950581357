import clsx from 'clsx';
import { ScdBatchDetail } from 'model/batch';
import { BatchOutputEndorsementToResume } from 'model/batch-output-endorsement';
import { BatchStatus } from 'model/enums/batch-status';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd';

interface LimelightHeaderProps {
    batch?: ScdBatchDetail;
    outputEndorsement?: BatchOutputEndorsementToResume;
}

const LimelightHeader = (props: LimelightHeaderProps) => {
    const { batch } = props;
    const { t } = useTranslation();

    return (
        <header className="provisional-limelight__header">
            <div className="provisional-limelight__header-container">
                <div className="provisional-limelight__header-container--content">
                    <h2 className="provisional-limelight__header-container--title">
                        {`${t(`${I18N_PREFIX}.batch.product.options.${batch?.product || '.not-found'}`).toUpperCase()} ${batch?.code}`}
                    </h2>
                    <span className="page-container--table-status">
                        <span
                            className={clsx('page-container--table-status', {
                                CLOSED: batch?.status === BatchStatus.CLOSED,
                                CONCLUDED: batch?.status === BatchStatus.CONCLUDED,
                            })}
                        >
                            {batch?.status === BatchStatus.CLOSED || batch?.status === BatchStatus.CONCLUDED
                                ? t(`${I18N_PREFIX}.batch.status.options.${batch?.status}`).toUpperCase()
                                : '-'}
                        </span>
                    </span>
                </div>
                <div className="provisional-limelight__header-container--subtitle">
                    <span style={{ marginRight: '10px' }}>{t(`${I18N_PREFIX}.provisional-limelight.title`)}</span>
                </div>
            </div>
        </header>
    );
};

export default LimelightHeader;
