import { action } from 'typesafe-actions';
import { GiroWebappError } from 'model/error';
import { RequestLogFilterPageableBrs, RequestLogModelBrs } from 'model/brs/request-log-brs';
import { Page } from 'services/page';
import { RequestLogsListTypesBrs } from 'reducer-brs/request-log/request-log-list/types';

export const requestLogListBrsRequest = (request: RequestLogFilterPageableBrs) =>
    action(RequestLogsListTypesBrs.REQUEST_LOGS_LIST_BRS_REQUEST, request);
export const requestLogListBrsSuccess = (pageRequestLogList: Page<RequestLogModelBrs>) =>
    action(RequestLogsListTypesBrs.REQUEST_LOGS_LIST_BRS_SUCCESS, pageRequestLogList);
export const requestLogListBrsError = (error: GiroWebappError) => action(RequestLogsListTypesBrs.REQUEST_LOGS_LIST_BRS_ERROR, error);
export const requestLogListBrsResetState = () => action(RequestLogsListTypesBrs.REQUEST_LOGS_LIST_BRS_STATE);
