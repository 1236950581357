import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListOrganizationsTypes, ListOrganizationsState } from './types';
import { Reducer } from 'redux';

export const initialState: ListOrganizationsState = {
    status: HttpRequestStatus.NOOP,
    organizations: undefined,
    error: undefined,
};

const reducer: Reducer<ListOrganizationsState> = (state = initialState, action): ListOrganizationsState => {
    switch (action.type) {
        case ListOrganizationsTypes.LIST_ORGANIZATIONS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ListOrganizationsTypes.LIST_ORGANIZATIONS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, organizations: action.payload, error: undefined };
        }
        case ListOrganizationsTypes.LIST_ORGANIZATIONS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListOrganizationsTypes.LIST_ORGANIZATIONS_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listOrganizationsReducer };
