import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ChunkStreamProcessBcoState, ChunkStreamProcessBcoTypes } from 'reducer-bco/batch-output/chunk-stream-process/types';
import { Reducer } from 'redux';

const initialState: ChunkStreamProcessBcoState = {
    status: HttpRequestStatus.NOOP,
    chunkStreamProcessBco: undefined,
    error: undefined,
};

const reducer: Reducer<ChunkStreamProcessBcoState> = (state = initialState, action): ChunkStreamProcessBcoState => {
    switch (action.type) {
        case ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, chunkStreamProcessBco: action.payload, error: undefined };
        }
        case ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ChunkStreamProcessBcoTypes.CHUNK_STREAM_PROCESS_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as ChunkStreamProcessBcoReducer };
