import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ScdRequestLogData } from 'model/request-log';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

const I18N_PREFIX = 'pages.scd.request-log.table';

interface ScdRequestLogHeaderProps {
    requestLogs: ScdRequestLogData[];
    handleSort?: (property: keyof ScdRequestLogData, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
    disabledSort?: boolean;
}

export const ScdRequestLogHeader = (props: ScdRequestLogHeaderProps) => {
    const { t } = useTranslation();
    const { requestLogs, handleSort, sortedProperty, sortOrder, disabledSort } = props;

    const mapSortable = (_property: keyof ScdRequestLogData) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: disabledSort || (requestLogs && requestLogs.length <= 1) ? true : false,
            onSort: () => handleSort && handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.createdDate`).toUpperCase()} sortable={mapSortable('createdDate')} />
            <SortableColumn label={t(`${I18N_PREFIX}.responseStatus`).toUpperCase()} sortable={mapSortable('responseStatus')} />
            <SortableColumn label={t(`${I18N_PREFIX}.agent`).toUpperCase()} sortable={mapSortable('organizationKey')} />
            <SortableColumn label={t(`${I18N_PREFIX}.route`).toUpperCase()} sortable={mapSortable('route')} />
        </tr>
    );
};

export default ScdRequestLogHeader;
