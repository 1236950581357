import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdCompanyItemSearch } from 'model/company-item';
import companyItemAPI from 'services/api/company-item-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { SearchCompanyItemsTypes } from 'reducer/company-item/search-company-items/types';
import {
    searchCompanyItemsError,
    searchCompanyItemsSuccess,
    searchCompanyItemsRequest,
} from 'reducer/company-item/search-company-items/actions';
import { Page } from 'services/page';

function* handleSearchCompanyItems(action: ReturnType<typeof searchCompanyItemsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdCompanyItemSearch>> = yield call(companyItemAPI.searchCompanyItems, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(searchCompanyItemsError(mapped));
            return;
        }
        yield put(searchCompanyItemsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(searchCompanyItemsError(mapped));
    }
}

function* watchSearchCompanyItems() {
    yield takeEvery(SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_REQUEST, handleSearchCompanyItems);
}

function* searchCompanyItemsSaga() {
    yield all([fork(watchSearchCompanyItems)]);
}

export default searchCompanyItemsSaga;
