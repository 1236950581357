import { AxiosResponse } from 'axios';
import { UserAccount, UserNewPassword, UserPasswordResetRequest, UserSimple } from 'model/user';
import { api } from 'services/api/api';

const account = () => {
    const recoverAccount = (): Promise<AxiosResponse<UserAccount>> => {
        return api.get<UserAccount>('api/account');
    };

    const passwordResetInit = (payload: UserPasswordResetRequest): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>('api/account/reset-password/init', payload.mail, { headers: { 'Content-Type': 'text/plain' } });
    };

    const passwordResetFinish = (payload: UserNewPassword): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>('api/account/reset-password/finish', payload);
    };

    return {
        recoverAccount,
        passwordResetInit,
        passwordResetFinish,
    };
};

export default account();
