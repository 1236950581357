import { ScdCnabDownload } from 'model/cnab';
import { GiroWebappError } from 'model/error';
import { CnabBatchReportTypes } from 'reducer/cnab/cnab-batch-report/types';
import { action } from 'typesafe-actions';

export const cnabBatchReportRequest = (batchId: number) => action(CnabBatchReportTypes.CNAB_BATCH_REPORT_REQUEST, batchId);
export const cnabBatchReportSuccess = (cnabBatchReport: ScdCnabDownload) =>
    action(CnabBatchReportTypes.CNAB_BATCH_REPORT_SUCCESS, cnabBatchReport);
export const cnabBatchReportError = (error: GiroWebappError) => action(CnabBatchReportTypes.CNAB_BATCH_REPORT_ERROR, error);
export const cnabBatchReportResetState = () => action(CnabBatchReportTypes.CNAB_BATCH_REPORT_RESET);
