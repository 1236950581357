import { AxiosResponse } from 'axios';
import { HemeraProvisionalProxyResponse } from 'features/limelight/send-backings/domain/models';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import ErrorUtils from 'shared/error/error-utils';
import provisionalLimelightApi from 'services/api/provisional-limeliight'
import { limelightProxySendBackingError, limelightProxySendBackingRequest, limelightProxySendBackingSuccess } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/action';
import { ProvisionalLimelightProxySendBackingTypes } from 'reducer/provisional-limelight/provisional-limelight-proxy-send/types';

function* handleLimelightProxySendBackings(action: ReturnType<typeof limelightProxySendBackingRequest>) {
    try {
        const result: AxiosResponse<HemeraProvisionalProxyResponse> = yield call(provisionalLimelightApi.sendProxyHemeraBackings, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(limelightProxySendBackingError(mapped));
            return;
        }
        yield put(limelightProxySendBackingSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(limelightProxySendBackingError(mapped));
    }
}

function* watchLimelightProxySendBackings() {
    yield takeLeading(ProvisionalLimelightProxySendBackingTypes.LIMELIGHT_PROXY_SEND_BACKING_REQUEST, handleLimelightProxySendBackings);
}

function* limelightProxySendBackingsSaga() {
    yield all([fork(watchLimelightProxySendBackings)]);
}

export default limelightProxySendBackingsSaga;