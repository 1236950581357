import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ListBatchesToRelocateTypes, ListBatchesToRelocateState } from 'reducer/batch/list-batches-to-relocate/types';

const initialState: ListBatchesToRelocateState = {
    status: HttpRequestStatus.NOOP,
    batches: undefined,
    error: undefined,
};

const reducer: Reducer<ListBatchesToRelocateState> = (state = initialState, action): ListBatchesToRelocateState => {
    switch (action.type) {
        case ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batches: action.payload, error: undefined };
        }
        case ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case ListBatchesToRelocateTypes.LIST_BATCHES_TO_RELOCATE_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as listBatchesToRelocateReducer };
