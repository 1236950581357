import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchCompanyItemsTypes, SearchCompanyItemsState } from 'reducer/company-item/search-company-items/types';

const initialState: SearchCompanyItemsState = {
    status: HttpRequestStatus.NOOP,
    companyItems: undefined,
    error: undefined,
};

const reducer: Reducer<SearchCompanyItemsState> = (state = initialState, action): SearchCompanyItemsState => {
    switch (action.type) {
        case SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, companyItems: action.payload, error: undefined };
        }
        case SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchCompanyItemsTypes.SEARCH_COMPANY_ITEMS_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchCompanyItemsReducer };
