import { BaseStoreState } from 'reducer/base-store-state';
import { ScdOriginatorDetail } from 'model/originator';

export type ApiResponse = Record<string, string>;

export enum DetailOriginatorTypes {
    DETAIL_ORIGINATOR_REQUEST = '@@originator-detail/DETAIL_ORIGINATOR_REQUEST',
    DETAIL_ORIGINATOR_SUCCESS = '@@originator-detail/DETAIL_ORIGINATOR_SUCCESS',
    DETAIL_ORIGINATOR_ERROR = '@@originator-detail/DETAIL_ORIGINATOR_ERROR',
    DETAIL_ORIGINATOR_RESET_STATE = '@@originator-detail/DETAIL_ORIGINATOR_RESET_STATE',
}

export interface DetailOriginatorState extends BaseStoreState {
    readonly originator?: ScdOriginatorDetail;
}
