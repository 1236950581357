import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { Loading } from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { detailBankerRequest, detailBankerResetState } from 'reducer/banker/detail-banker/actions';
import { useDetailBankerState, useRootDispatch } from 'reducer/hooks';
import './banker-detail.scss';

type ScdOriginatorDetailProps = RouteComponentProps<{ bankerId: string }>;

const I18N_PREFIX = 'pages.scd.banker.detail';

const ScdBankerDetailPage = (props: ScdOriginatorDetailProps) => {
    const { t } = useTranslation();
    const [bankerId] = useState<number>(Number(props.match.params.bankerId));
    const { status, banker } = useDetailBankerState();

    const loading = status !== HttpRequestStatus.SUCCESS;
    const history = useHistory();

    const dispatch = useRootDispatch();

    useEffect(() => {
        dispatch(detailBankerRequest(bankerId));
        return () => {
            dispatch(detailBankerResetState());
        };
    }, [dispatch, bankerId]);

    return (
        <main className="scd-originator__detail">
            <ContextRibbon action={() => history.push('/bankers')} />
            <div className="scd-originator--container">
                <header className="scd-originator--header">
                    <h2 className="scd-originator--header-title">{t('pages.scd.banker.detail.title')}</h2>
                </header>
                <div className="scd-originator__detail--container">
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.userName`)} value={banker?.userName} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.email`)} value={banker?.userEmail} />
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.organizationName`)} value={banker?.organizationName} />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.hasApproval`)}
                                value={t(`${I18N_PREFIX}.${banker?.hasApprovalAuthority}`)}
                            />
                        </>
                    )}
                </div>
            </div>
        </main>
    );
};

export default ScdBankerDetailPage;
