import { IssuerPolicyIntegrationSimple } from 'model/issuer-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum UpdateIntegrationIssuerPolicyTypes {
    ISSUER_POLICY_INTEGRATION_UPDATE_REQUEST = '@@issuer-policy-integration-update/ISSUER_POLICY_INTEGRATION_UPDATE_REQUEST',
    ISSUER_POLICY_INTEGRATION_UPDATE_SUCCESS = '@@issuer-policy-integration-update/ISSUER_POLICY_INTEGRATION_UPDATE_SUCCESS',
    ISSUER_POLICY_INTEGRATION_UPDATE_ERROR = '@@issuer-policy-integration-update/ISSUER_POLICY_INTEGRATION_UPDATE_ERROR',
    ISSUER_POLICY_INTEGRATION_UPDATE_RESET_STATE = '@@issuer-policy-integration-update/ISSUER_POLICY_INTEGRATION_UPDATE_RESET_STATE',
}

export interface UpdateIntegrationIssuerPolicyState extends BaseStoreState {
    readonly policyIntegrationUpdate?: IssuerPolicyIntegrationSimple;
}