import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationDisbursementReport } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationDisbursementReportError,
    importedOperationDisbursementReportRequest,
    importedOperationDisbursementReportSuccess,
} from 'reducer/imported-operation/disbursement-report/actions';
import { ImportedOperationDisbursementReportTypes } from 'reducer/imported-operation/disbursement-report/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationDisbursementReport(action: ReturnType<typeof importedOperationDisbursementReportRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationDisbursementReport> = yield call(
            importedOperationApi.disbursementReportRequest,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationDisbursementReportError(mapped));
            return;
        }
        yield put(importedOperationDisbursementReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationDisbursementReportError(mapped));
    }
}

function* watchImportedOperationDisbursementReport() {
    yield takeEvery(
        ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_REQUEST,
        handleImportedOperationDisbursementReport
    );
}

function* importedOperationDisbursementReportSaga() {
    yield all([fork(watchImportedOperationDisbursementReport)]);
}

export default importedOperationDisbursementReportSaga;
