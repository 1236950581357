import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupPolicySimple } from 'model/company-group-policy';

export type ApiResponse = Record<string, any>;

export enum CreateCompanyGroupPolicyTypes {
    CREATE_COMPANY_GROUP_POLICY_REQUEST = '@@company-group-policy-create/CREATE_COMPANY_GROUP_POLICY_REQUEST',
    CREATE_COMPANY_GROUP_POLICY_SUCCESS = '@@company-group-policy-create/CREATE_COMPANY_GROUP_POLICY_SUCCESS',
    CREATE_COMPANY_GROUP_POLICY_ERROR = '@@company-group-policy-create/CREATE_COMPANY_GROUP_POLICY_ERROR',
    CREATE_COMPANY_GROUP_POLICY_RESET = '@@company-group-policy-create/CREATE_COMPANY_GROUP_POLICY_RESET',
}

export interface CreateCompanyGroupPolicyState extends BaseStoreState {
    readonly companyGroupPolicy?: ScdCompanyGroupPolicySimple;
}
