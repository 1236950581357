import { AxiosResponse } from 'axios';
import { ScdBatchOperationsReport } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    batchOperationsReportError,
    batchOperationsReportRequest,
    batchOperationsReportSuccess,
} from 'reducer/batch/operations-report/actions';
import { BatchOperationsReportTypes } from 'reducer/batch/operations-report/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleBatchOperationsReport(action: ReturnType<typeof batchOperationsReportRequest>) {
    try {
        const result: AxiosResponse<ScdBatchOperationsReport> = yield call(batchAPI.getOperationsReport, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchOperationsReportError(mapped));
            return;
        }
        yield put(batchOperationsReportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchOperationsReportError(mapped));
    }
}

function* watchBatchOperationsReport() {
    yield takeLeading(BatchOperationsReportTypes.BATCH_OPERATIONS_REPORT_REQUEST, handleBatchOperationsReport);
}

function* batchOperationsReportSaga() {
    yield all([fork(watchBatchOperationsReport)]);
}

export default batchOperationsReportSaga;
