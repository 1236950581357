import { action } from 'typesafe-actions';
import { SearchPaymentImportBatchesTypes } from 'reducer/batch/search-payment-import-batches/types';
import { ScdSearchPaymentImportBatch } from 'model/batch';
import { SearchRequest } from 'model/reducers';

import { GiroWebappError } from 'model/error';
import { Page } from 'services/page';

export const searchPaymentImportBatchesRequest = (request: SearchRequest) =>
    action(SearchPaymentImportBatchesTypes.SEARCH_PAYMENT_IMPORT_BATCHES_REQUEST, request);
export const searchPaymentImportBatchesSuccess = (batches: Page<ScdSearchPaymentImportBatch>) =>
    action(SearchPaymentImportBatchesTypes.SEARCH_PAYMENT_IMPORT_BATCHES_SUCCESS, batches);
export const searchPaymentImportBatchesError = (error: GiroWebappError) =>
    action(SearchPaymentImportBatchesTypes.SEARCH_PAYMENT_IMPORT_BATCHES_ERROR, error);
export const searchPaymentImportBatchesResetState = () => action(SearchPaymentImportBatchesTypes.SEARCH_PAYMENT_IMPORT_BATCHES_RESET);
