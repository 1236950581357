import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import { useTranslation } from 'react-i18next';
import './dashed-button.scss';

export interface DashedButtonProps {
    onClick: (value: any) => void;
    label: string;
    disabled?: boolean;
}

export const DashedButton = (props: DashedButtonProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="button__style--dashed">
            <Button
                variant="contained"
                onClick={props.onClick}
                startIcon={
                    <Icon>
                        <div className="button__style--dashed-icon" />
                    </Icon>
                }
                disabled={props.disabled}
            >
                <span className="button__style--dashed-label">{t(props.label)}</span>
            </Button>
        </div>
    );
};

export default DashedButton;
