import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import React, { useRef, useState } from 'react';
import { MODAL_SEARCH_HEIGHT, MODAL_SEARCH_WIDTH } from 'components/modals/constants';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { v4 as uuidV4 } from 'uuid';
import './complete-payment-transactions-favored-input-search.scss';
import { ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { debounce } from 'lodash';
import InfiniteLoader from 'react-window-infinite-loader';
import clsx from 'clsx';

interface FavoredInputSearchProps {
    label: string;
    placeholder: string;
    value: string;
    paymentTransactions: ScdPaymentTransactionPendingData[];
    itemSize: number;
    renderItem: (value: ScdPaymentTransactionPendingData) => React.ReactNode;
    onChange: (value: Partial<ScdPaymentTransactionPendingData>) => void;
}

const CompletePaymentTransactionsFavoredInputSeacrh = (props: FavoredInputSearchProps) => {
    const { label, placeholder, value, paymentTransactions, itemSize, renderItem, onChange } = props;

    const removeDuplicates = (paymentTransactions: ScdPaymentTransactionPendingData[]) => {
        return paymentTransactions.reduce((acc, item) => {
            if (acc.some(it => it.favoredIdentification === item.favoredIdentification)) {
                return acc;
            }

            return [...acc, item];
        }, [] as ScdPaymentTransactionPendingData[]);
    };

    const [valueSearch, setValueSearch] = useState<string>(value ?? '');
    const [filteredData, setFilteredData] = useState<ScdPaymentTransactionPendingData[]>(removeDuplicates(paymentTransactions));
    const [showData, setShowData] = useState<boolean>(false);

    const getFavoreds = (searchInput?: string) => {
        const filtered: ScdPaymentTransactionPendingData[] = removeDuplicates(paymentTransactions);

        const searchFilter = filtered.filter(item => {
            return item.favoredName.toLowerCase().includes(searchInput?.toLowerCase() ?? '') || item.favoredIdentification === searchInput;
        });

        setFilteredData(searchFilter);
        return searchFilter;
    };

    const debounceSearch = useRef(
        debounce(searchInput => {
            getFavoreds(searchInput);
        }, 500)
    );

    const handleOnChange = (searchInput: string) => {
        setValueSearch(searchInput);
        debounceSearch.current(searchInput);
    };

    const dataCount: number = filteredData?.length ?? 0;

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const handleSelect = (selectValue: ScdPaymentTransactionPendingData) => {
        setValueSearch(
            (selectValue?.favoredName || selectValue.favoredIdentification) &&
                `${selectValue?.favoredIdentification} ${selectValue?.favoredName}`
        );
        onChange(selectValue);
        setShowData(false);
    };

    const isItemLoaded = ({ index }: any) => !!filteredData[index];

    const loadMoreItems = (_startIndex: number, _stopIndex: number): any => {
        return;
    };

    return (
        <div
            className={clsx('select-option-search-favored-input-container', {
                active: showData,
            })}
        >
            <SimpleSearchInput
                label={label}
                value={valueSearch}
                placeholder={placeholder}
                onChange={handleOnChange}
                onClick={() => setShowData(true)}
                searchActive={showData}
            />
            {showData && (
                <AutoSizer defaultHeight={MODAL_SEARCH_HEIGHT} defaultWidth={MODAL_SEARCH_WIDTH}>
                    {({ height, width }) => {
                        return (
                            <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={dataCount}>
                                {({ onItemsRendered, ref }) => (
                                    <FixedSizeList
                                        onItemsRendered={onItemsRendered}
                                        className="select-option-search-favored-div-style"
                                        ref={ref}
                                        height={height}
                                        itemKey={itemKey}
                                        itemCount={dataCount}
                                        itemSize={itemSize}
                                        width={width}
                                        itemData={filteredData}
                                    >
                                        {({ index, style }) =>
                                            filteredData[index] !== undefined && filteredData.length !== 0 ? (
                                                <div
                                                    key={uuidV4()}
                                                    className="select-option-search-favored-item-container"
                                                    style={{
                                                        ...style,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    onClick={() => {
                                                        handleSelect(filteredData[index]);
                                                    }}
                                                >
                                                    {renderItem(filteredData[index] ?? '')}
                                                </div>
                                            ) : (
                                                <> </>
                                            )
                                        }
                                    </FixedSizeList>
                                )}
                            </InfiniteLoader>
                        );
                    }}
                </AutoSizer>
            )}
        </div>
    );
};
export default CompletePaymentTransactionsFavoredInputSeacrh;
