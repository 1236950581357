import { AxiosResponse } from 'axios';
import { ScdPaymentTransactionsInSeriesErrorsList, ScdPaymentTransactionsInSeriesToCreateList } from 'model/payment-transaction-in-series';
import { api } from 'services/api/api';

const paymentTransactionInSeries = () => {
    const createPaymentTransactionsInSeriesLegacy = (
        paymentTransactionsInSeries: ScdPaymentTransactionsInSeriesToCreateList
    ): Promise<AxiosResponse<ScdPaymentTransactionsInSeriesErrorsList>> => {
        return api.post<ScdPaymentTransactionsInSeriesErrorsList>(`api/payment-transactions/in-series`, paymentTransactionsInSeries);
    };

    return {
        createPaymentTransactionsInSeriesLegacy,
    };
};

export default paymentTransactionInSeries();
