import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { BatchSignatureForOutputList } from 'model/batch-signature';
import { BatchSignatureStatus } from 'model/enums/batch-signature-status';

interface ScdBatchEndorsementOptionsItemsProps {
    handleDownload: () => void;
    handleModalReExport: () => void;
    currentSignature?: BatchSignatureForOutputList;
}

const I18N_PREFIX = 'pages.scd.batch.batch-endorsement';

const ScdBatchEndorsementOptionsItems = (props: ScdBatchEndorsementOptionsItemsProps) => {

    const { currentSignature, handleDownload, handleModalReExport } = props;


    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.operation-detail`} link={`/ccb-operations/${currentSignature?.operation.id}`} />
            <ModalActionMenuItem titleKey={`${I18N_PREFIX}.buttons.menu-items.download`} onClick={handleDownload} />
            <ModalActionMenuItem titleKey="Reenviar" onClick={ handleModalReExport} show={currentSignature?.status === BatchSignatureStatus.SENT} />
        </>
    );
};

export default ScdBatchEndorsementOptionsItems;
