import { GiroWebappError } from 'model/error';
import { PaymentInstitutionAccountDetail } from 'model/payment-institution-account';
import { ResumePaymentInstitutionsAccountsTypes } from 'reducer/payment-institutions-accounts/resume-payment-institution-account/types';
import { action } from 'typesafe-actions';

export const resumePaymentInstitutionAccountRequest = (id: number) =>
    action(ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST, id);
export const resumePaymentInstitutionAccountSuccess = (paymentAccount: PaymentInstitutionAccountDetail) =>
    action(ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS, paymentAccount);
export const resumePaymentInstitutionAccountError = (error: GiroWebappError) =>
    action(ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR, error);
export const resumePaymentInstitutionAccountResetState = () =>
    action(ResumePaymentInstitutionsAccountsTypes.RESUME_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET);
