import H from 'history';
import profileSvg from 'images/profile.svg';
import { useHistory } from 'react-router-dom';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useRootDispatch } from 'reducer/hooks';
import AuthUtil from 'services/api/auth-util';
import MenuItemLegacy, {
    LinkActionLegacy,
    MenuItemLegacyPropsList
} from 'shared/navbar/components/menu-item-legacy/menu-item-legacy';

const I18N_PREFIX = 'navbar-legacy.account';

// TODO: AB#546
export const AccountMenuLegacy = () => {
    const history: H.History = useHistory();
    const dispatch = useRootDispatch();

    const handleLogout: LinkActionLegacy = () => {
        AuthUtil.removeToken();
        dispatch(loginResetState());
        dispatch(userAccountResetState());
        history.push('/');
    };

    const account: MenuItemLegacyPropsList[] = [
        {
            translation: `${I18N_PREFIX}.main`,
            visibility: true,
            submenu: [
                {
                    link: '/',
                    translation: `${I18N_PREFIX}.logout`,
                    visibility: true,
                    action: handleLogout,
                },
            ],
        },
    ];

    return <MenuItemLegacy list={account} icon={profileSvg} />;
};

export default AccountMenuLegacy;
