import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { ProgramGroupsToGetAll } from 'model/program-groups';
import { useTranslation } from 'react-i18next';
import { SortOrder } from 'services/sort';

interface ProgramGroupsHeaderProps {
    programGroupsItems: ProgramGroupsToGetAll[];
    handleSort: (property: keyof ProgramGroupsToGetAll, order: SortOrder) => void;
    sortedProperty: string | undefined;
    sortOrder: SortOrder;
}

const I18N_PREFIX = 'pages.scd.program-groups.table';

const ProgramGroupsHeader = (props: ProgramGroupsHeaderProps) => {
    const { handleSort, programGroupsItems, sortOrder, sortedProperty } = props;

    const { t } = useTranslation();

    const mapSortable = (_property: keyof ProgramGroupsToGetAll) => {
        return {
            active: _property === sortedProperty,
            order: sortOrder,
            property: _property,
            isLoading: false,
            disabled: programGroupsItems && programGroupsItems.length <= 1 ? true : false,
            onSort: () => handleSort(_property, sortOrder),
        };
    };

    return (
        <tr className="page-container--table-head">
            <SortableColumn label={t(`${I18N_PREFIX}.name`)} sortable={mapSortable('name')} />
        </tr>
    );
};

export default ProgramGroupsHeader;
