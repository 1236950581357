import { GiroWebappError } from 'model/error';
import { PaymentTransactionToManualDisbursement, PaymentTransactionToSimple, } from 'model/payment-transaction';
import { ManualDisbursementPaymentTransactionTypes } from 'reducer/payment-transaction/manual-disbursement-payment-transaction/types';
import { action } from 'typesafe-actions';

export const manualDisbursementPaymentTransactionRequest = (request: PaymentTransactionToManualDisbursement) =>
    action(ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST, request);
export const manualDisbursementPaymentTransactionSuccess = (paymentTransactionSimple: PaymentTransactionToSimple) =>
    action(ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS, paymentTransactionSimple);
export const manualDisbursementPaymentTransactionError = (error: GiroWebappError) =>
    action(ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR, error);
export const manualDisbursementPaymentTransactionResetState = () =>
    action(ManualDisbursementPaymentTransactionTypes.MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET);
