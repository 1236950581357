import { ScdBatchCreatedByOperations } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreateBatchByOperationsTypes {
    CREATE_BATCH_BY_OPERATIONS_REQUEST = '@@create-batch-by-operations/CREATE_BATCH_BY_OPERATIONS_REQUEST',
    CREATE_BATCH_BY_OPERATIONS_SUCCESS = '@@create-batch-by-operations/CREATE_BATCH_BY_OPERATIONS_SUCCESS',
    CREATE_BATCH_BY_OPERATIONS_ERROR = '@@create-batch-by-operations/CREATE_BATCH_BY_OPERATIONS_ERROR',
    CREATE_BATCH_BY_OPERATIONS_RESET = '@@create-batch-by-operations/CREATE_BATCH_BY_OPERATIONS_RESET',
}

export interface CreateBatchByOperationsState extends BaseStoreState {
    readonly createdBatch?: ScdBatchCreatedByOperations;
}
