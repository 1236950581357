import FareAmountReport from 'entities/fare-amount-report/fare-amount-report';
import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

export const FareAmountReportRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={FareAmountReport} />
    </Switch>
);

export default FareAmountReportRoutes;
