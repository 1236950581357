import { ScdBankerList } from 'model/banker';
import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import bankerAPI from 'services/api/banker-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListBankersTypes } from './types';
import { listBankersError, listBankersSuccess, listBankersRequest } from './actions';
import { Page } from 'services/page';

function* handleListBankers(action: ReturnType<typeof listBankersRequest>) {
    try {
        const result: AxiosResponse<Page<ScdBankerList>> = yield call(bankerAPI.listBankers, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listBankersError(mapped));
            return;
        }
        yield put(listBankersSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listBankersError(mapped));
    }
}

function* watchListBankers() {
    yield takeEvery(ListBankersTypes.LIST_BANKERS_REQUEST, handleListBankers);
}

function* listBankersSaga() {
    yield all([fork(watchListBankers)]);
}

export default listBankersSaga;
