import { Grid } from '@material-ui/core';
import BatchCard from 'entities/batch/components/batch-card/batch-card';
import { BatchDetailWithProgram } from 'model/batch';
import { BatchStatus } from 'model/enums/batch-status';
import { useTranslation } from 'react-i18next';

interface ReportCardProps {
    batch: BatchDetailWithProgram;
}

const I18N_PREFIX = 'pages.scd.batch.batch-card';

const BatchReportCard = (props: ReportCardProps) => {
    const { t } = useTranslation();

    const { batch } = props;

    const showCard: boolean = batch?.status === BatchStatus.CLOSED || batch?.status === BatchStatus.CONCLUDED;

    return (
        <Grid item hidden={!showCard}>
            <BatchCard
                title={t(`${I18N_PREFIX}.titles.reports`)}
                link={`/batches/${batch?.id}/reports`}
            />
        </Grid>
    );
};

export default BatchReportCard;
