import { AxiosResponse } from 'axios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import HttpStatus from 'model/enums/http-status';
import { ScdOriginatorList } from 'model/originator';
import originatorAPI from 'services/api/originator-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { markError } from 'reducer/application/error/actions';
import { ListOriginatorsTypes } from 'reducer/originator/list-originators/types';
import { listOriginatorsError, listOriginatorsSuccess, listOriginatorsRequest } from 'reducer/originator/list-originators/actions';
import { Page } from 'services/page';

function* handleListOriginators(action: ReturnType<typeof listOriginatorsRequest>) {
    try {
        const result: AxiosResponse<Page<ScdOriginatorList>> = yield call(originatorAPI.listOriginators, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listOriginatorsError(mapped));
            return;
        }
        yield put(listOriginatorsSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listOriginatorsError(mapped));
    }
}

function* watchListOriginators() {
    yield takeEvery(ListOriginatorsTypes.LIST_ORIGINATORS_REQUEST, handleListOriginators);
}

function* listOriginatorsSaga() {
    yield all([fork(watchListOriginators)]);
}

export default listOriginatorsSaga;
