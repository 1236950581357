import CloseButton from 'components/buttons/close-button/close-button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { TransfeeraExternalTransfersData } from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import { JsonUtils } from 'shared/external/util/json-util';

import './transfeera-transfers-details-modal.scss';

interface TransfeeraTransfersDetailsModalProps {
    externalTransference: TransfeeraExternalTransfersData;
    onClose: () => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-transfers';

const TransfeeraTransfersDetailsModal = (props: TransfeeraTransfersDetailsModalProps) => {
    const { externalTransference, onClose } = props;

    const { t } = useTranslation();

    const prettifiedResponse = useMemo((): string => {
        return JsonUtils.prettifyJsonByObject(externalTransference);
    }, [externalTransference]);

    return (
        <Modal isOpen toggle={onClose}>
            <div className="transfeera-transfers-details">
                <div className="transfeera-transfers-details--header">
                    <label className="transfeera-transfers-details--header-title"> {t(`${I18N_PREFIX}.details-title`)} </label>
                    <CloseButton onClick={onClose} />
                </div>
                <div className="transfeera-transfers-details--container">
                    <pre>{prettifiedResponse}</pre>
                </div>
            </div>
        </Modal>
    );
};

export default TransfeeraTransfersDetailsModal;
