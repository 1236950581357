import InputUtils from 'components/inputs/input-utils';
import { BRAZILIAN_LOCALE } from 'components/inputs/localization-utils';

const maskAmount = (amount?: number, scale: number = 2, decimal: boolean = true, locale: string = BRAZILIAN_LOCALE): string | undefined => {
    if (amount === undefined) {
        return undefined;
    }

    const options: Intl.NumberFormatOptions = {
        maximumFractionDigits: decimal ? scale : 0,
        minimumFractionDigits: decimal ? scale : 0,
    };

    return amount.toLocaleString(locale, options);
};

const formatCurrency = (amount?: number, scale: number = 2, decimal: boolean = true, locale: string = BRAZILIAN_LOCALE): string => {
    const formatted = maskAmount(amount, scale, decimal, locale);

    return formatted ?? '0';
};

const parseNumericValue = (value?: string, scale: number = 2): number => {
    const numericValue = InputUtils.removeNonDigits(value);
    const isEmptyString = numericValue === '' ? '0' : numericValue;

    return parseInt(isEmptyString, 10) / 10 ** scale;
};

const currencyFormatValueWithDecimal = (value?: string, scale: number = 2): string => {
    const centValue = parseNumericValue(value, scale);

    return formatCurrency(centValue, scale);
};

export const CurrencyUtils = {
    maskAmount,
    formatCurrency,
    parseNumericValue,
    currencyFormatValueWithDecimal,
};
