import { CessionToResumeBco, CessionToResumeRequest } from 'model/bco/batch-output-cession-integration-gtsec';
import { GiroWebappError } from 'model/error';
import { CessionToResumeBcoTypes } from 'reducer-bco/batch-output/cession-to-resume/types';
import { action } from 'typesafe-actions';

export const cessionToResumeBcoRequest = (request: CessionToResumeRequest) =>
    action(CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_REQUEST, request);
export const cessionToResumeBcoSuccess = (cessionToResumeBco: CessionToResumeBco) =>
    action(CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_SUCCESS, cessionToResumeBco);
export const cessionToResumeBcoError = (error: GiroWebappError) =>
    action(CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_ERROR, error);
export const cessionToResumeBcoResetState = () => action(CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_RESET);
