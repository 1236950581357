import { useMemo } from 'react';
import { useExternalStatementPaymentInstitutionAccountState } from 'reducer/hooks';
import { TransfeeraExternalStatementProxyResponse } from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import { JsonUtils } from 'shared/external/util/json-util';
import { Trans, useTranslation } from 'react-i18next';
import DateFormatter from 'components/formatter/date/date-formatter';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { renderToStaticMarkup } from 'react-dom/server';

import './transfeera-statement-show-balance.scss';

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.show-balance';

const TransfeeraStatementShowBalance = () => {
    const { externalStatement } = useExternalStatementPaymentInstitutionAccountState();

    const { t } = useTranslation();

    const parsedResponse = useMemo((): TransfeeraExternalStatementProxyResponse | undefined => {
        return JsonUtils.parseJsonString(externalStatement?.response) as TransfeeraExternalStatementProxyResponse;
    }, [externalStatement]);

    if (!parsedResponse) {
        return <></>;
    }

    return (
        <div className="transfeera-statement-show-balance">
            <div className="transfeera-statement-show-balance--item">
                <ReadOnlyTextField
                    label={renderToStaticMarkup(
                        <Trans
                            t={t}
                            i18nKey={`${I18N_PREFIX}.title`}
                            components={{
                                date: parsedResponse.prevBalance.date ? <DateFormatter date={parsedResponse?.prevBalance?.date} /> : <></>,
                            }}
                        />
                    )}
                    value={<CurrencyFormatter value={parsedResponse?.prevBalance.value} prefix />}
                />
            </div>
            <div className="transfeera-statement-show-balance--item">
                <ReadOnlyTextField
                    label={renderToStaticMarkup(
                        <Trans
                            t={t}
                            i18nKey={`${I18N_PREFIX}.title`}
                            components={{
                                date: parsedResponse.afterBalance.date ? <DateFormatter date={parsedResponse.afterBalance.date} /> : <></>,
                            }}
                        />
                    )}
                    value={<CurrencyFormatter value={parsedResponse?.afterBalance.value} prefix />}
                />
            </div>
        </div>
    );
};

export default TransfeeraStatementShowBalance;
