import { HttpRequestStatus } from 'model/enums/http-request-status';
import { GenerateCnabBatchState, GenerateCnabBatchTypes } from 'reducer/cnab/generate-cnab-batch/types';
import { Reducer } from 'redux';

const initialState: GenerateCnabBatchState = {
    status: HttpRequestStatus.NOOP,
    generateCnabBatch: undefined,
    error: undefined,
};

const reducer: Reducer<GenerateCnabBatchState> = (state = initialState, action): GenerateCnabBatchState => {
    switch (action.type) {
        case GenerateCnabBatchTypes.GENERATE_CNAB_BATCH_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case GenerateCnabBatchTypes.GENERATE_CNAB_BATCH_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, generateCnabBatch: action.payload, error: undefined };
        }
        case GenerateCnabBatchTypes.GENERATE_CNAB_BATCH_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case GenerateCnabBatchTypes.GENERATE_CNAB_BATCH_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as generateCnabBatchReducer };
