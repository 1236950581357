import { BaseStoreState } from 'reducer/base-store-state';
import { ScdProgramPolicySimple } from 'model/program-policy';

export type ApiResponse = Record<string, string>;

export enum UpdateProgramPolicyTypes {
    UPDATE_PROGRAM_POLICY_REQUEST = '@@program-update/UPDATE_PROGRAM_POLICY_REQUEST',
    UPDATE_PROGRAM_POLICY_SUCCESS = '@@program-update/UPDATE_PROGRAM_POLICY_SUCCESS',
    UPDATE_PROGRAM_POLICY_ERROR = '@@program-update/UPDATE_PROGRAM_POLICY_ERROR',
    UPDATE_PROGRAM_POLICY_RESET_STATE = '@@program-update/UPDATE_PROGRAM_POLICY_RESET_STATE',
}

export interface UpdateProgramPolicyState extends BaseStoreState {
    readonly programPolicy?: ScdProgramPolicySimple;
}
