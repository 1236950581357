import { MenuItem, Tooltip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import SimpleCurrencyInput from 'components/inputs/currency-input/simple-currency-input';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import SimpleSelectInput from 'components/inputs/select-input/simple-select-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import ModalTextArea from 'components/modals/modal-text-area/modal-text-area';
import { BANK_SVG_URL } from 'config/constants';
import { validateScdNewPaymentTransaction } from 'entities/payment-transaction/validation/new-payment-transaction-validation';
import invalidBankSvg from 'images/bank-default.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PersonType } from 'model/enums/person-type';
import { ScdPaymentFavoredSearch } from 'model/payment-favored';
import { ScdPaymentTransactionToCreate, defaulScdPaymentTransactionToCreate } from 'model/payment-transaction';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchFavoredBankAccountsRequest } from 'reducer/favored-bank-account/search-favored-bank-accounts/actions';
import { useCreatePaymentTransactionState, useRootDispatch, useSearchFavoredBankAccountsState } from 'reducer/hooks';
import { searchPaymentFavoredRequest } from 'reducer/payment-favored/search-payment-favored/actions';
import {
    createPaymentTransactionRequest,
    createPaymentTransactionResetState,
} from 'reducer/payment-transaction/create-payment-transaction/actions';
import { useToast } from 'shared/hooks/use-toast';

const I18N_PREFIX = 'pages.scd.payment-transaction.new-transaction';

enum PaymentTransactionModalActions {
    SEND = 'SEND',
    CANCEL = 'CANCEL',
}

interface ScdPaymentTransactionToCreateIdentification extends ScdPaymentTransactionToCreate {
    favoredName?: string;
    favoredIdentification?: string;
}

interface CreatePaymentTransactionProps {
    handleCloseNewTransaction: () => void;
    handleListTransactions: () => void;
    handleSummarizePendingTransactions?: () => void;
}

const CreatePaymentTransaction = (props: CreatePaymentTransactionProps) => {
    const { handleCloseNewTransaction, handleListTransactions, handleSummarizePendingTransactions } = props;

    const [newPaymentTransaction, setNewPaymentTransaction] =
        useState<ScdPaymentTransactionToCreateIdentification>(defaulScdPaymentTransactionToCreate);
    const [modalDescription, setModalDescription] = useState<boolean>(false);
    const [modalAction, setModalAction] = useState<PaymentTransactionModalActions | undefined>(undefined);

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { toastLoading, toastSuccess, closeToast } = useToast();

    const { favoredBankAccounts } = useSearchFavoredBankAccountsState();
    const { status: createStatus } = useCreatePaymentTransactionState();

    const handleChange = (value: Partial<ScdPaymentTransactionToCreateIdentification>) => {
        setNewPaymentTransaction({ ...newPaymentTransaction, ...value });
    };

    const handleCreatePaymentTransaction = () => {
        dispatch(createPaymentTransactionRequest(newPaymentTransaction));
    };

    const handleResetInputs = useCallback(() => {
        handleCloseNewTransaction();
        setNewPaymentTransaction(defaulScdPaymentTransactionToCreate);
    }, [handleCloseNewTransaction]);

    const handleSelectFavored = (favored: ScdPaymentFavoredSearch | undefined) => {
        handleChange({
            companyId: favored?.companyId,
            bankAccountId: undefined,
            favoredName: favored?.name,
            favoredIdentification: favored?.identification,
        });
        handleSelectBankAccount(
            favored?.companyId! || favored?.personId!,
            favored?.companyId ? PersonType.CORPORATION : PersonType.INDIVIDUAL
        );
    };

    const handleSelectBankAccount = (id: number, type: PersonType) => {
        if (!id) return;

        dispatch(searchFavoredBankAccountsRequest({ id, type }));
    };

    useEffect(() => {
        if (createStatus === HttpRequestStatus.ON_GOING) {
            toastLoading();
            return;
        }

        if (createStatus === HttpRequestStatus.ERROR) {
            closeToast();
            return;
        }

        if (createStatus === HttpRequestStatus.SUCCESS) {
            closeToast();
            handleResetInputs();
            handleListTransactions();
            handleSummarizePendingTransactions?.();
            toastSuccess(t(`${I18N_PREFIX}.toast.create`, { count: 1 }));
        }
    }, [
        createStatus,
        toastLoading,
        closeToast,
        handleListTransactions,
        handleResetInputs,
        toastSuccess,
        t,
        handleSummarizePendingTransactions,
    ]);

    useEffect(() => {
        return () => {
            dispatch(createPaymentTransactionResetState());
        };
    }, [dispatch]);

    const disabledButton = createStatus === HttpRequestStatus.ON_GOING;

    const modalMessageOnAction = {
        SEND: () => handleCreatePaymentTransaction(),
        CANCEL: () => handleResetInputs(),
    } as Record<PaymentTransactionModalActions, () => void>;

    const modalMessageStandardButtonLabel = {
        SEND: t('entity.action.create'),
        CANCEL: t('entity.action.cancel'),
    } as Record<PaymentTransactionModalActions, string>;

    return (
        <>
            <div className="scd-payment-transaction__table-fields-new-transaction__field-value">
                <SimpleCurrencyInput
                    label={t(`${I18N_PREFIX}.amount`)}
                    placeholder={t(`${I18N_PREFIX}.amount`)}
                    onChange={amount => handleChange({ amount })}
                    value={newPaymentTransaction.amount}
                    externalUpdate
                    autoFocus
                />
            </div>
            <div className="scd-payment-transaction__table-fields-new-transaction__field-favored">
                <div className="scd-payment-transaction__table-fields-new-transaction__field-favored-content">
                    <ListSearchInput<ScdPaymentFavoredSearch>
                        action={searchPaymentFavoredRequest}
                        itemSize={SEARCH_ITEM_SIZE_LARGE}
                        onSelect={handleSelectFavored}
                        renderItem={favored => `${favored?.identification} ${favored?.name}`}
                        statusSelector={(state: IRootState) => state.searchPaymentFavored.status}
                        dataSelector={(state: IRootState) => state.searchPaymentFavored.paymentFavored}
                        label={t(`${I18N_PREFIX}.favored`)}
                        placeholder={t(`${I18N_PREFIX}.favored`)}
                        disabled={!newPaymentTransaction.amount}
                        value={
                            newPaymentTransaction?.favoredIdentification && newPaymentTransaction?.favoredName
                                ? `${newPaymentTransaction?.favoredIdentification} ${newPaymentTransaction?.favoredName}`
                                : ''
                        }
                    />
                </div>
            </div>
            <div className="scd-payment-transaction__table-fields-new-transaction__field-account">
                <SimpleSelectInput
                    label={t(`${I18N_PREFIX}.account`)}
                    mapperFromString={_value => _value ?? ''}
                    value={newPaymentTransaction?.bankAccountId ?? ''}
                    placeholder={t(`${I18N_PREFIX}.account`)}
                    onChange={bankAccountId => handleChange({ bankAccountId: Number(bankAccountId) })}
                    externalUpdate
                    disabled={favoredBankAccounts?.length === 0 || !newPaymentTransaction.companyId || !newPaymentTransaction.amount}
                >
                    {favoredBankAccounts?.map(
                        ({ accountNumber, bankIdentification, bankName, agencyNumber, id, pixKey, agencyDigit, accountDigit }) => {
                            return (
                                <MenuItem value={id} key={id}>
                                    {pixKey ? (
                                        <>
                                            <span> {pixKey} </span>
                                        </>
                                    ) : (
                                        <>
                                            {bankIdentification && (
                                                <span style={{ marginRight: '4px' }}>
                                                    <Tooltip title={bankName}>
                                                        <img
                                                            src={`${BANK_SVG_URL}/${bankIdentification}.svg`}
                                                            alt={bankName ?? ''}
                                                            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                                                                ((e.target as HTMLImageElement).src = invalidBankSvg)
                                                            }
                                                        />
                                                    </Tooltip>
                                                </span>
                                            )}

                                            <span style={{ marginRight: '2px' }}>
                                                {agencyDigit ? `${agencyNumber}-${agencyDigit}` : agencyNumber}{' '}
                                            </span>
                                            <span style={{ marginRight: '2px' }}>
                                                {accountDigit ? `${accountNumber}-${accountDigit}` : accountNumber}{' '}
                                            </span>
                                        </>
                                    )}
                                </MenuItem>
                            );
                        }
                    )}
                </SimpleSelectInput>
            </div>
            <div className="scd-payment-transaction__table-fields-new-transaction__icons ">
                <Tooltip title={String(t('tooltips.description'))}>
                    <div
                        className={clsx(
                            'scd-payment-transaction__table-fields-new-transaction__icons-description scd-payment-transaction__table-fields-new-transaction__icons-icon',
                            {
                                active: newPaymentTransaction.description,
                            }
                        )}
                        onClick={() => (validateScdNewPaymentTransaction(newPaymentTransaction) ? setModalDescription(true) : undefined)}
                    />
                </Tooltip>
                <Tooltip title={String(t('tooltips.confirm'))}>
                    <div
                        className={clsx(
                            'scd-payment-transaction__table-fields-new-transaction__icons-check scd-payment-transaction__table-fields-new-transaction__icons-icon',
                            {
                                active: validateScdNewPaymentTransaction(newPaymentTransaction) && !disabledButton,
                                disabled: !validateScdNewPaymentTransaction(newPaymentTransaction) || disabledButton,
                            }
                        )}
                        onClick={() =>
                            validateScdNewPaymentTransaction(newPaymentTransaction) && !disabledButton
                                ? setModalAction(PaymentTransactionModalActions.SEND)
                                : undefined
                        }
                    >
                        <CheckIcon />
                    </div>
                </Tooltip>
                <div
                    className="scd-payment-transaction__table-fields-new-transaction__icons-icon"
                    onClick={() => setModalAction(PaymentTransactionModalActions.CANCEL)}
                >
                    <Tooltip title={String(t('tooltips.cancel'))}>
                        <ClearIcon />
                    </Tooltip>
                </div>
            </div>
            <ModalTextArea
                title={t(`${I18N_PREFIX}.description.title`)}
                label={t(`${I18N_PREFIX}.description.label`)}
                openModal={modalDescription}
                onClose={() => setModalDescription(false)}
                onChange={description => handleChange({ description })}
            />

            {modalAction && (
                <ModalMessageLegacy
                    onClose={() => setModalAction(undefined)}
                    onCancel={() => setModalAction(undefined)}
                    onAction={modalMessageOnAction[modalAction]}
                    title={t(`${I18N_PREFIX}.modal.${modalAction}.title`)}
                    message={t(`${I18N_PREFIX}.modal.${modalAction}.message`)}
                    standardButtonLabel={modalMessageStandardButtonLabel[modalAction]}
                    outlinedButtonLabel={t('entity.action.back')}
                />
            )}
        </>
    );
};

export default CreatePaymentTransaction;
