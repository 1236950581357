import { Button } from '@material-ui/core';
import clsx from 'clsx';
import './payment-transaction-pending-button.scss';

const LIMIT_PENDING = 99;

interface PendingButtonProps {
    label: string;
    onClick: () => void;
    numberPending: number;
    disabled?: boolean;
}

const PendingButton = (props: PendingButtonProps) => {
    const { label, onClick, numberPending, disabled } = props;

    return (
        <div className="pending-button__style--link-outlined">
            <Button variant="contained" onClick={onClick} disabled={disabled}>
                <span>{label}</span>
                <div
                    className={clsx('pending-button__style--link-outlined-circle', {
                        more: numberPending > LIMIT_PENDING,
                    })}
                >
                    <span>{numberPending <= LIMIT_PENDING ? numberPending : '+99'}</span>
                </div>
            </Button>
        </div>
    );
};

export default PendingButton;
