import Loading from 'components/loading/loading';
import CompanyGroupPolicyRow, {
    CompanyGroupPolicyRowProps,
} from 'entities/company-group-policy/components/company-group-policy-row/company-group-policy-row';
import { ScdCompanyGroupPolicy } from 'model/company-group-policy';

import { useTranslation } from 'react-i18next';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';

interface CompanyGroupPolicyListProps {
    companyGroupPolicy: ScdCompanyGroupPolicy[];
    isLoading: boolean;
}

const I18N_PREFIX = 'pages.scd.company-group-policy';

const CompanyGroupPolicyList = (props: CompanyGroupPolicyListProps) => {
    const { companyGroupPolicy, isLoading } = props;

    const { t } = useTranslation();

    const companyGroupPolicyRowObjectData: CompanyGroupPolicyRowProps = {
        companyGroupPolicy,
    };

    return (
        <>
            {isLoading && !companyGroupPolicy.length && <Loading isTable />}
            {companyGroupPolicy.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={companyGroupPolicyRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={companyGroupPolicy.length}
                            className="page-container--table-container"
                        >
                            {CompanyGroupPolicyRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                <>
                    {!isLoading && (
                        <div className="scd-company-group-policy__table-empty-filter">
                            <span className="scd-company-group-policy__table-empty-filter-image" />
                            <span className="scd-company-group-policy__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CompanyGroupPolicyList;
