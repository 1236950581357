import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import BankFilterButtons from 'entities/bank/components/bank-filter-buttons/bank-filter-buttons';
import { BankHeader, BankListType } from 'entities/bank/components/bank-header/bank-header';
import BanksList from 'entities/bank/components/bank-list/bank-list';
import { BankModalFilter, BankModalFilterType } from 'entities/bank/components/bank-modal-filter/bank-modal-filter';
import useBankFilterChips from 'entities/bank/hooks/use-bank-filter-chips';
import { BankFilterPageable, BankFilterRequest, BankList, defaultBankFilterRequest } from 'model/bank';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IRootState } from 'reducer';
import { listBanksRequest } from 'reducer/bank/list-banks/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScroll from 'shared/infinite-components/infinite-scroll/infinite-scroll';
import './bank.scss';

export interface BankLocation {
    filterActive: BankFilterRequest;
}

const I18N_PREFIX = 'pages.scd.bank';

const ScdBankPage = () => {
    const { t } = useTranslation();
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');

    const [filterActive, setFilterActive] = useState<BankFilterRequest>(defaultBankFilterRequest);
    const [modalFilter, setModalFilter] = useState<BankModalFilterType | undefined>();
    const [openModalFilter, setOpenModalFilter] = useState<boolean>(true);

    const { isBankChipsActive, bankFilterChips } = useBankFilterChips({ filterActive, setFilterActive, setPage });

    const location = useLocation<BankLocation>();

    const sortPageable = useCallback((): BankFilterPageable => {
        const pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const filters: BankFilterRequest = filterActive ?? {};

        return {
            pageable: pageable,
            filter: filters,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    useEffect(() => {
        if (location?.state?.filterActive) {
            setFilterActive(location.state.filterActive);
        }
    }, [location?.state?.filterActive]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
        handleRefresh,
    } = useInfiniteScroll<BankList>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listBanks.banks,
        statusSelector: (state: IRootState) => state.listBanks.status,
        action: listBanksRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleSort = (property: BankListType, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('id');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive({ ...defaultBankFilterRequest });
    };

    const hasError = listStatus === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.bank.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }
    return (
        <main className="scd-bank">
            <section className="scd-bank__container">
                <header className="scd-bank__header">
                    <div className="scd-bank__header-container">
                        <div style={{ display: 'flex' }}>
                            <h2 className="scd-bank__header-container--title">{t(`${I18N_PREFIX}.title`)}</h2>
                            <RefreshButton onClick={handleRefresh} />
                        </div>
                    </div>
                    <div className="scd-bank__header--buttons-content">
                        <BankFilterButtons
                            filterActive={filterActive}
                            setModalFilter={setModalFilter}
                            setOpenModalFilter={setOpenModalFilter}
                            handleResetFilterActive={handleResetFilterActive}
                        />
                    </div>
                    <ChipFilterActive filters={bankFilterChips} />
                </header>
                <div className="scd-bank__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isBankChipsActive,
                        })}
                    >
                        <thead>
                            <BankHeader banks={items} sortOrder={sortOrder} sortedProperty={sortedProperty} handleSort={handleSort} />
                        </thead>
                        <tbody>
                            <BanksList banks={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
                {modalFilter && (
                    <BankModalFilter
                        openModal={openModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setOpenModalFilter(false)}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                        setFilterActive={setFilterActive}
                        filterActive={filterActive}
                        setPage={setPage}
                    />
                )}
            </section>
        </main>
    );
};

export default ScdBankPage;
