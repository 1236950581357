import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentAccountRecipient } from 'model/payment-transaction';
import { useTranslation } from 'react-i18next';

interface PaymentAccountRecipientCardProps {
    paymentAccountRecipient: PaymentAccountRecipient | undefined;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const PaymentAccountRecipientCard = (props: PaymentAccountRecipientCardProps) => {
    const { paymentAccountRecipient } = props;

    const { t } = useTranslation();

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.label`)}>
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.bank`}
                value={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.bankName`, {
                    bankIdentification: paymentAccountRecipient?.bankIdentification,
                    bankName: paymentAccountRecipient?.bankName,
                })}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountNumberAndDigit.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountNumberAndDigit.value`, {
                    accountNumber: paymentAccountRecipient?.accountNumber,
                    accountDigit: paymentAccountRecipient?.accountDigit,
                })}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.agencyNumber`}
                value={paymentAccountRecipient?.agencyNumber}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.label`}
                value={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.accountType.options.${paymentAccountRecipient?.accountType}`)}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.label`}
                value={paymentAccountRecipient?.pixKey}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.type`}
                value={t(`${I18N_PREFIX}.inputs.paymentAccountRecipient.pixKey.options.${paymentAccountRecipient?.pixKeyType}`)}
            />
        </ReadOnlyCard>
    );
};

export default PaymentAccountRecipientCard;
