import { ScdBatchToArchiveResponse, ScdBatchToArchiveRequest } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { ArchiveBatchOperationTypes } from 'reducer/batch/archive-batch-operation/types';
import { action } from 'typesafe-actions';

export const archiveBatchOperationRequest = (request: ScdBatchToArchiveRequest) =>
    action(ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_REQUEST, request);
export const archiveBatchOperationSuccess = (response: ScdBatchToArchiveResponse) => action(ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_SUCCESS, response);
export const archiveBatchOperationError = (error: GiroWebappError) => action(ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_ERROR, error);
export const archiveBatchOperationResetState = () => action(ArchiveBatchOperationTypes.ARCHIVE_BATCH_OPERATION_RESET);
