import ModalMessage from 'components/modals/modal-message/modal-message';
import { ChunkStreamRetryRequest, DataChunk, DataChunkRetryRequest, Stream } from 'model/bco/batch-output-cession-integration-gtsec';
import { ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { chunkStreamRetryBcoRequest, chunkStreamRetryBcoResetState } from 'reducer-bco/batch-output/retry-chunk-stream/action';
import { dataChunkRetryBcoRequest, dataChunkRetryBcoResetState } from 'reducer-bco/batch-output/retry-data-chunk/action';
import { useCessionToResumeBco } from 'reducer-bco/hooks-bco';
import { useRootDispatch } from 'reducer/hooks';
import './modal-retry.scss';

interface ModalGtsecRetryProps {
    batchId?: number;
    outputId?: number;
    stream?: Stream | null;
    chunk?: DataChunk;
    type: ExportRequestType;
    setOpenModal: (value: boolean) => void;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.modal.retry';

const ModalGtecRetry = (props: ModalGtsecRetryProps) => {
    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { batchId, outputId, stream, chunk, type, setOpenModal } = props;

    const { cessionToResume } = useCessionToResumeBco();

    const handleRetryDataChunk = useCallback(() => {
        if (!batchId || !outputId || !stream?.id || !chunk?.id) return;
        const request: DataChunkRetryRequest = {
            batchId,
            outputId,
            streamId: stream.id,
            chunkId: chunk.id,
            type,
        };
        dispatch(dataChunkRetryBcoRequest(request));
        setOpenModal(false);
    }, [dispatch, batchId, outputId, stream?.id, chunk?.id, type]);

    const handleRetryChunkStream = useCallback(() => {
        if (!batchId || !outputId || !stream?.id) return;
        const request: ChunkStreamRetryRequest = {
            batchId,
            outputId,
            streamId: stream.id,
            type,
        };
        dispatch(chunkStreamRetryBcoRequest(request));
        setOpenModal(false);
    }, [dispatch, batchId, outputId, stream?.id, type]);

    const handleExportToGtsec = {
        CHUNK_INSTALLMENTS: handleRetryDataChunk,
        CHUNK_OPERATIONS: handleRetryDataChunk,
        STREAM_INSTALLMENTS: handleRetryChunkStream,
        STREAM_OPERATIONS: handleRetryChunkStream,
    } as Record<ExportRequestType, () => void>;

    const handleContent = {
        CHUNK_INSTALLMENTS: `${I18N_PREFIX}.message.chunk-installments`,
        CHUNK_OPERATIONS: `${I18N_PREFIX}.message.chunk-operations`,
        STREAM_INSTALLMENTS: `${I18N_PREFIX}.message.stream-installments`,
        STREAM_OPERATIONS: `${I18N_PREFIX}.message.stream-operations`,
    } as Record<ExportRequestType, string>;

    const handleCount = {
        CHUNK_INSTALLMENTS: cessionToResume?.numberOfInstallments ?? 0,
        CHUNK_OPERATIONS: cessionToResume?.numberOfOperations ?? 0,
        STREAM_INSTALLMENTS: stream?.numberOfInstallments ?? 0,
        STREAM_OPERATIONS: stream?.numberOfOperations ?? 0,
    } as Record<ExportRequestType, number>;

    useEffect(() => {
        return () => {
            dispatch(dataChunkRetryBcoResetState());
            dispatch(chunkStreamRetryBcoResetState());
        };
    }, [dispatch]);

    const count = handleCount[type];
    const partition = chunk?.index ?? 0;

    return (
        <ModalMessage
            title={t(`${I18N_PREFIX}.title`)}
            onClose={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
            onAction={handleExportToGtsec[type]}
            standardButtonLabel={'entity.action.reesend'}
            outlinedButtonLabel={'entity.action.back'}
        >
            <Trans i18nKey={handleContent[type]} values={{ count, partition }}>
                {t(handleContent[type], { count, partition })}
            </Trans>
        </ModalMessage>
    );
};

export default ModalGtecRetry;
