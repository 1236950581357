import { ScdCompanyGroupPolicy } from 'model/company-group-policy';
import { GiroWebappError } from 'model/error';
import { DetailCompanyGroupPolicyTypes } from 'reducer/company-group-policy/detail-company-group-policy/types';
import { action } from 'typesafe-actions';

export const detailCompanyGroupPolicyRequest = (companyGroupPolicyId: string) =>
    action(DetailCompanyGroupPolicyTypes.DETAIL_COMPANY_GROUP_POLICY_REQUEST, companyGroupPolicyId);

export const detailCompanyGroupPolicySuccess = (companyGroupPolicy: ScdCompanyGroupPolicy) =>
    action(DetailCompanyGroupPolicyTypes.DETAIL_COMPANY_GROUP_POLICY_SUCCESS, companyGroupPolicy);

export const detailCompanyGroupPolicyError = (error: GiroWebappError) =>
    action(DetailCompanyGroupPolicyTypes.DETAIL_COMPANY_GROUP_POLICY_ERROR, error);

export const detailCompanyGroupPolicyResetState = () => action(DetailCompanyGroupPolicyTypes.DETAIL_COMPANY_GROUP_POLICY_RESET);
