import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// Create company group policy
import { createCompanyGroupPolicyReducer } from 'reducer/company-group-policy/create-company-group-policy/reducer';
import createCompanyGroupPolicySagas from 'reducer/company-group-policy/create-company-group-policy/sagas';
import { CreateCompanyGroupPolicyState } from 'reducer/company-group-policy/create-company-group-policy/types';

// Update company group policy
import { updateCompanyGroupPolicyReducer } from 'reducer/company-group-policy/update-company-group-policy/reducer';
import updateCompanyGroupPolicySagas from 'reducer/company-group-policy/update-company-group-policy/sagas';
import { UpdateCompanyGroupPolicyState } from 'reducer/company-group-policy/update-company-group-policy/types';

// Detail company group policy
import { detailCompanyGroupPolicyReducer } from 'reducer/company-group-policy/detail-company-group-policy/reducer';
import detailCompanyGroupPolicySagas from 'reducer/company-group-policy/detail-company-group-policy/sagas';
import { DetailCompanyGroupPolicyState } from 'reducer/company-group-policy/detail-company-group-policy/types';

// List company group policies
import { listCompanyGroupPoliciesReducer } from 'reducer/company-group-policy/list-company-group-policies/reducer';
import listCompanyGroupPoliciesSagas from 'reducer/company-group-policy/list-company-group-policies/sagas';
import { ListCompanyGroupPoliciesState } from 'reducer/company-group-policy/list-company-group-policies/types';

export interface CompanyGroupPolicyState {
    readonly createCompanyGroupPolicy: CreateCompanyGroupPolicyState;
    readonly updateCompanyGroupPolicy: UpdateCompanyGroupPolicyState;
    readonly detailCompanyGroupPolicy: DetailCompanyGroupPolicyState;
    readonly listCompanyGroupPolicies: ListCompanyGroupPoliciesState;
}

export const CompanyGroupPolicyReducer: ReducersMapObject<CompanyGroupPolicyState, AnyAction> = {
    createCompanyGroupPolicy: createCompanyGroupPolicyReducer,
    updateCompanyGroupPolicy: updateCompanyGroupPolicyReducer,
    detailCompanyGroupPolicy: detailCompanyGroupPolicyReducer,
    listCompanyGroupPolicies: listCompanyGroupPoliciesReducer,
};

export function* companyGroupPolicySagas() {
    yield all([
        fork(createCompanyGroupPolicySagas),
        fork(updateCompanyGroupPolicySagas),
        fork(detailCompanyGroupPolicySagas),
        fork(listCompanyGroupPoliciesSagas),
    ]);
}
