import { BASE_URL_ADMIN } from './../page';
import { AxiosResponse } from 'axios';

import { api } from 'services/api/api';
import { Page } from 'services/page';
import { SearchRequest } from 'model/reducers';
import {
    ScdCompany,
    ScdCompanyCreateUpdateRequest,
    ScdCompanyFilterPageable,
    ScdCompanySearch,
    ScdCompanyToSimple,
    ScdCompanyDetails,
} from 'model/company';

const company = () => {
    const listCompanies = (request: ScdCompanyFilterPageable): Promise<AxiosResponse<Page<ScdCompany>>> => {
        const { filter, pageable } = request;

        const _requestFilters = [
            filter?.name && `name=${filter?.name}`,
            filter?.identification && `identification=${filter.identification}`,
        ]
            .filter(it => !!it)
            .join('&');

        return api.get<Page<ScdCompany>>(`${BASE_URL_ADMIN}/companies?${_requestFilters}`, { params: { ...pageable } });
    };

    const searchCompanies = (request: SearchRequest): Promise<AxiosResponse<Page<ScdCompanySearch>>> => {
        return api.get<Page<ScdCompanySearch>>(`api/companies/search?search=${encodeURIComponent(request.search)}`, {
            params: { ...request.pageable },
        });
    };

    const createCompany = (request: ScdCompanyCreateUpdateRequest): Promise<AxiosResponse<ScdCompanyToSimple>> => {
        return api.post<ScdCompanyToSimple>(`${BASE_URL_ADMIN}/companies`, request);
    };

    const detailCompany = (companyId: number): Promise<AxiosResponse<ScdCompanyDetails>> => {
        return api.get<ScdCompanyDetails>(`${BASE_URL_ADMIN}/companies/${companyId}`);
    };

    const updateCompany = (request: ScdCompanyCreateUpdateRequest): Promise<AxiosResponse<ScdCompanyToSimple>> => {
        return api.put<ScdCompanyToSimple>(`${BASE_URL_ADMIN}/companies/${request.id}`, request);
    };

    return {
        listCompanies,
        searchCompanies,
        createCompany,
        detailCompany,
        updateCompany,
    };
};

export default company();
