import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import {
    legacyProcessPaymentTransactionsRequest, legacyProcessPaymentTransactionsError, legacyProcessPaymentTransactionsSuccess
} from 'reducer/payment-transaction/legacy-process-payment-transactions/actions';
import { LegacyProcessPaymentTransactionsTypes } from 'reducer/payment-transaction/legacy-process-payment-transactions/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentTransactionAPI from 'services/api/payment-transaction-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleLegacyProcessPaymentTransactions(action: ReturnType<typeof legacyProcessPaymentTransactionsRequest>) {
    try {
        const result: AxiosResponse<void> = yield call(paymentTransactionAPI.legacyProcessPaymentTransactions, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(legacyProcessPaymentTransactionsError(mapped));
            return;
        }
        yield put(legacyProcessPaymentTransactionsSuccess());
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(legacyProcessPaymentTransactionsError(mapped));
    }
}

function* watchLegacyProcessPaymentTransactions() {
    yield takeLeading(LegacyProcessPaymentTransactionsTypes.LEGACY_PROCESS_PAYMENT_TRANSACTIONS_REQUEST, handleLegacyProcessPaymentTransactions);
}

function* processLegacyPaymentTransactionsSaga() {
    yield all([fork(watchLegacyProcessPaymentTransactions)]);
}

export default processLegacyPaymentTransactionsSaga;
