import { BaseStoreState } from 'reducer/base-store-state';
import { ScdCompanyGroupPolicySimple } from 'model/company-group-policy';

export type ApiResponse = Record<string, any>;

export enum UpdateCompanyGroupPolicyTypes {
    UPDATE_COMPANY_GROUP_POLICY_REQUEST = '@@company-group-policy-update/UPDATE_COMPANY_GROUP_POLICY_REQUEST',
    UPDATE_COMPANY_GROUP_POLICY_SUCCESS = '@@company-group-policy-update/UPDATE_COMPANY_GROUP_POLICY_SUCCESS',
    UPDATE_COMPANY_GROUP_POLICY_ERROR = '@@company-group-policy-update/UPDATE_COMPANY_GROUP_POLICY_ERROR',
    UPDATE_COMPANY_GROUP_POLICY_RESET = '@@company-group-policy-update/UPDATE_COMPANY_GROUP_POLICY_RESET',
}

export interface UpdateCompanyGroupPolicyState extends BaseStoreState {
    readonly companyGroupPolicy?: ScdCompanyGroupPolicySimple;
}
