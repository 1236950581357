import { Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import 'shared/header/sidebar/componentes/submenu-menu-list/submenu-menu-list.scss';
import { LinkAction, Submenu } from 'model/sidebar';
import SubmenuMenuItem from 'shared/header/sidebar/componentes/submenu-menu-item/submenu-menu-item';
import './submenu-menu-list.scss';

export interface SubmenuMenuListProps {
    iconSource?: string;
    translation: string;
    submenu?: Submenu[];
    action?: LinkAction;
    setIsOpen: (value?: number) => void;
    isOpen?: number;
    index?: number;
}

const SubmenuMenuList: FC<SubmenuMenuListProps> = ({ iconSource, translation, action, submenu, isOpen, setIsOpen, index }) => {
    const { t } = useTranslation();
    const local = useLocation().pathname;
    const [isActiveCollapsable, setIsActiveCollapsable] = useState<boolean>(false);

    const isCurrentSubmenuOpen = isOpen === index;

    const handleOpenItem = () => {
        if (action) {
            action();
        }
        setIsOpen(isCurrentSubmenuOpen ? undefined : index);
    };

    useEffect(() => {
        submenu?.some(subMenuItem => subMenuItem.link === local) ? setIsActiveCollapsable(true) : setIsActiveCollapsable(false);
    }, []);

    return (
        <li className={clsx('submenu-menu-list', { isOpen: isCurrentSubmenuOpen })}>
            <button className={clsx('submenu-menu-list--button', { active: isActiveCollapsable })} onClick={handleOpenItem}>
                <div className="submenu-menu-list--button--title-icon-box">
                    <img src={iconSource} alt={iconSource} />
                    <p className="submenu-menu-list--button--text">{t(translation)}</p>
                </div>
                {isCurrentSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
            </button>

            <Collapse in={isCurrentSubmenuOpen} timeout="auto" unmountOnExit>
                <ul className="submenu-menu-list--submenu-list">
                    {submenu
                        ?.filter(submenuItem => !submenuItem.isHidden)
                        ?.map(submenuItem => (
                            <SubmenuMenuItem
                                key={submenuItem.title}
                                title={submenuItem.title}
                                link={submenuItem.link}
                                isExternalLink={submenuItem.isExternalLink}
                                action={submenuItem.action}
                            />
                        ))}
                </ul>
            </Collapse>
        </li>
    );
};

export default SubmenuMenuList;
