import { GiroWebappError } from 'model/error';
import { ScdImportedOperationsArchiveManyRequest, ScdImportedOperationsArchiveManyResponse } from 'model/imported-operation';
import { ImportedOperationsArchiveManyTypes } from 'reducer/imported-operation/archive-many/types';
import { action } from 'typesafe-actions';

export const importedOperationsArchiveManyRequest = (request: ScdImportedOperationsArchiveManyRequest) =>
    action(ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_REQUEST, request);
export const importedOperationsArchiveManySuccess = (data: ScdImportedOperationsArchiveManyResponse) =>
    action(ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_SUCCESS, data);
export const importedOperationsArchiveManyError = (error: GiroWebappError) =>
    action(ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_ERROR, error);

export const importedOperationsArchiveManyResetState = () =>
    action(ImportedOperationsArchiveManyTypes.IMPORTED_OPERATIONS_ARCHIVE_MANY_RESET_STATE);
