import { ScdLegacyPaymentTransactionData } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreatePaymentTransactionTypes {
    CREATE_PAYMENT_TRANSACTION_REQUEST = '@@payment-transaction-create/CREATE_PAYMENT_TRANSACTION_REQUEST',
    CREATE_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transaction-create/CREATE_PAYMENT_TRANSACTION_SUCCESS',
    CREATE_PAYMENT_TRANSATION_ERROR = '@@payment-transaction-create/CREATE_PAYMENT_TRANSATION_ERROR',
    CREATE_PAYMENT_TRANSACTION_RESET = '@@payment-transaction-create/CREATE_PAYMENT_TRANSACTION_RESET',
}

export interface CreatePaymentTransactionState extends BaseStoreState {
    readonly paymentTransaction?: ScdLegacyPaymentTransactionData;
}
