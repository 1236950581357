import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionsLegacyByBatch } from 'model/payment-transaction-by-batch';
import { ListPaymentTransactionsByBatchLegacyTypes } from 'reducer/payment-transaction-by-batch/list-payment-transactions-by-batch-legacy/types';
import { action } from 'typesafe-actions';

export const listDisbursementTransactionsByBatchLegacyRequest = (batchId: string) =>
    action(ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_REQUEST, batchId);
export const listDisbursementTransactionsByBatchLegacySuccess = (disbursementTransactions: ScdPaymentTransactionsLegacyByBatch) =>
    action(ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_SUCCESS, disbursementTransactions);
export const listDisbursementTransactionsByBatchLegacyError = (error: GiroWebappError) =>
    action(ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_ERROR, error);
export const listDisbursementTransactionsByBatchLegacyResetState = () =>
    action(ListPaymentTransactionsByBatchLegacyTypes.LIST_PAYMENT_TRANSACTIONS_BY_BATCH_LEGACY_RESET);
