import { AnyAction, ReducersMapObject } from 'redux';
import { all, fork } from 'redux-saga/effects';

// List originators
import { listOriginatorsReducer } from 'reducer/originator/list-originators/reducer';
import listOriginatorsSagas from 'reducer/originator/list-originators/sagas';
import { ListOriginatorsState } from 'reducer/originator/list-originators/types';

// Create originator
import { createOriginatorReducer } from 'reducer/originator/create-originator/reducer';
import createOriginatorSagas from 'reducer/originator/create-originator/sagas';
import { CreateOriginatorState } from 'reducer/originator/create-originator/types';

// Update originator
import { updateOriginatorReducer } from 'reducer/originator/update-originator/reducer';
import updateOriginatorSagas from 'reducer/originator/update-originator/sagas';
import { UpdateOriginatorState } from 'reducer/originator/update-originator/types';

// Detail originator
import { detailOriginatorReducer } from 'reducer/originator/detail-originator/reducer';
import detailOriginatorSagas from 'reducer/originator/detail-originator/sagas';
import { DetailOriginatorState } from 'reducer/originator/detail-originator/types';

// Search originators
import { searchOriginatorsReducer } from 'reducer/originator/search-originators/reducer';
import searchOriginatorsSagas from 'reducer/originator/search-originators/sagas';
import { SearchOriginatorsState } from 'reducer/originator/search-originators/types';

export interface OriginatorState {
    readonly createOriginator: CreateOriginatorState;
    readonly updateOriginator: UpdateOriginatorState;
    readonly detailOriginator: DetailOriginatorState;
    readonly listOriginators: ListOriginatorsState;
    readonly searchOriginators: SearchOriginatorsState;
}

export const OriginatorReducer: ReducersMapObject<OriginatorState, AnyAction> = {
    createOriginator: createOriginatorReducer,
    updateOriginator: updateOriginatorReducer,
    detailOriginator: detailOriginatorReducer,
    listOriginators: listOriginatorsReducer,
    searchOriginators: searchOriginatorsReducer,
};

export function* originatorSagas() {
    yield all([
        fork(createOriginatorSagas),
        fork(updateOriginatorSagas),
        fork(detailOriginatorSagas),
        fork(listOriginatorsSagas),
        fork(searchOriginatorsSagas),
    ]);
}
