import { ScdIssuerSearch } from 'model/issuer';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, string>;

export enum SearchIssuersTypes {
    SEARCH_ISSUERS_REQUEST = '@@issuer-search/SEARCH_ISSUERS_REQUEST',
    SEARCH_ISSUERS_SUCCESS = '@@issuer-search/SEARCH_ISSUERS_SUCCESS',
    SEARCH_ISSUERS_ERROR = '@@issuer-search/SEARCH_ISSUERS_ERROR',
    SEARCH_ISSUERS_RESET_STATE = '@@issuer-search/SEARCH_ISSUERS_RESET_STATE',
}

export interface SearchIssuersState extends BaseStoreState {
    readonly issuers?: Page<ScdIssuerSearch>;
}
