import { PaymentTransactionDetail } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum DetailPaymentTransactionTypes {
    DETAIL_PAYMENT_TRANSACTION_REQUEST = '@@payment-transactions-detail/DETAIL_PAYMENT_TRANSACTION_REQUEST',
    DETAIL_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transactions-detail/DETAIL_PAYMENT_TRANSACTION_SUCCESS',
    DETAIL_PAYMENT_TRANSACTION_ERROR = '@@payment-transactions-detail/DETAIL_PAYMENT_TRANSACTION_ERROR',
    DETAIL_PAYMENT_TRANSACTION_RESET = '@@payment-transactions-detail/DETAIL_PAYMENT_TRANSACTION_RESET',
}

export interface DetailPaymentTransactionState extends BaseStoreState {
    paymentTransaction?: PaymentTransactionDetail
}
