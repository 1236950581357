import Loading from 'components/loading/loading';
import { BatchOutputBridgeProps } from 'entities/batch/batch-outputs-detail/bridge/batch-outputs-detail-bridge';
import CessionIntegrationGtsecHeader from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec-header/cession-integration-gtsec-header';
import CessionIntegrationFooter from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-footer/cession-integration-footer';
import CessionIntegrationListGtsec from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/cession-integration-list/cession-integration-list';
import WithoutPartition from 'entities/batch/batch-outputs-detail/components/gtsec/cession-integration-gtsec/components/without-partition/without-partition';
import { BatchOutputBcoRequest } from 'model/bco/batch-output';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { IntegrationPolicyStatus } from 'model/enums/integration-policy-status';
import { IssuerPolicyResumeAllActivesRequest } from 'model/issuer-policy';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { chunkStreamToResumeBcoRequest, chunkStreamToResumeBcoResetState } from 'reducer-bco/batch-output/chunk-stream-to-resume/action';
import { useCessionToResumeBco, useChunkStreamRetryBco, useChunkStreamToResumeBco, useDataChunkRetryBco } from 'reducer-bco/hooks-bco';
import { useActivePolicySummary, useRootDispatch } from 'reducer/hooks';
import { activePoliciesSummaryRequest, activePoliciesSummaryResetState } from 'reducer/issuer-policy/active-policies-summary/actions';

const CessionIntegrationGtsec: React.FC<BatchOutputBridgeProps> = ({ output, batchWithProgram }) => {
    const dispatch = useRootDispatch();

    const { chunkStreamToResume, status: chunkStreamToResumeStatus } = useChunkStreamToResumeBco();
    const { status: chunkRetryStatus } = useDataChunkRetryBco();
    const { status: streamRetryStatus } = useChunkStreamRetryBco();

    const isLoadingChunkStream = chunkStreamToResumeStatus !== HttpRequestStatus.SUCCESS;
    const { cessionToResume, status: cessionToResumeStatus } = useCessionToResumeBco();
    const isLoadingCessionToResume = cessionToResumeStatus !== HttpRequestStatus.SUCCESS;

    const { batchId, outputId } = useParams<{ batchId: string; outputId: string }>();

    const { issuerPolicy, status: issuerPolicyStatus } = useActivePolicySummary();
    const isLoadingIssuerPolicy = issuerPolicyStatus !== HttpRequestStatus.SUCCESS;

    const [integrationPolicyStatus, setIntegrationPolicyStatus] = useState<IntegrationPolicyStatus | undefined>();

    const handleChunkStreamToResume = useCallback(() => {
        const request: BatchOutputBcoRequest = {
            batchId: Number(batchId),
            outputId: Number(outputId),
        };
        dispatch(chunkStreamToResumeBcoRequest(request));
    }, [dispatch, batchId, outputId]);

    useEffect(() => {
        handleChunkStreamToResume();

        return () => {
            dispatch(chunkStreamToResumeBcoResetState());
        };
    }, [handleChunkStreamToResume, chunkRetryStatus, streamRetryStatus]);

    const handleActivePolicies = useCallback(() => {
        const issuer = output?.issuer;
        if (!issuer) {
            return;
        }

        const request: IssuerPolicyResumeAllActivesRequest = {
            issuerId: issuer.id,
            filter: {
                type: undefined,
                platformId: Number(output?.platform?.id),
            },
        };
        dispatch(activePoliciesSummaryRequest(request));
    }, [dispatch, output?.issuer.id]);

    useEffect(() => {
        handleActivePolicies();

        return () => {
            dispatch(activePoliciesSummaryResetState());
        };
    }, [handleActivePolicies]);

    useEffect(() => {
        if (isLoadingIssuerPolicy) return;

        const policies = issuerPolicy?.issuerPolicies;
        const hasOnePolicy = policies?.length === 1;

        const hasPolicyIntegrationConfigured = policies?.[0]?.isIntegrationConfigured;

        if (!policies?.length) {
            setIntegrationPolicyStatus(IntegrationPolicyStatus.PENDING);
        } else if (hasOnePolicy && hasPolicyIntegrationConfigured) {
            setIntegrationPolicyStatus(IntegrationPolicyStatus.CONFIGURED);
        } else if (hasOnePolicy && !hasPolicyIntegrationConfigured) {
            setIntegrationPolicyStatus(IntegrationPolicyStatus.PENDING);
        } else {
            setIntegrationPolicyStatus(IntegrationPolicyStatus.INCONSISTENT);
        }
    }, [issuerPolicy]);

    const renderContent = () => {
        if (!chunkStreamToResume?.hasPartition) {
            return <WithoutPartition />;
        }

        return (
            <>
                <CessionIntegrationListGtsec
                    chunkStreamToResumeBco={chunkStreamToResume}
                    batchId={Number(batchId)}
                    outputId={Number(outputId)}
                />
                <CessionIntegrationFooter />
            </>
        );
    };

    return (
        <>
            <CessionIntegrationGtsecHeader
                batchOutputDetails={output}
                batchWithProgram={batchWithProgram}
                cessionToResume={cessionToResume}
                issuerPolicyStatus={integrationPolicyStatus}
                handleRefresh={handleChunkStreamToResume}
                isLoadingCessionToResume={isLoadingCessionToResume}
                isLoadingIssuerPolicy={isLoadingIssuerPolicy}
            />
            {isLoadingChunkStream ? <Loading /> : <>{renderContent()}</>}
        </>
    );
};

export default CessionIntegrationGtsec;
