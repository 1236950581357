import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationSimple } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import { retryProcessAutomaticPaymentImportedOperationError, retryProcessAutomaticPaymentImportedOperationRequest, retryProcessAutomaticPaymentImportedOperationSuccess } from './actions';
import { RetryProcessAutomaticPaymentImportedOperationTypes } from './types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleRetryProcessAutomaticPaymentImportedOperation(action: ReturnType<typeof retryProcessAutomaticPaymentImportedOperationRequest>) {
    try {
        const result: AxiosResponse<ScdImportedOperationSimple> = yield call(importedOperationApi.retryProcessAutoPayment, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(retryProcessAutomaticPaymentImportedOperationError(mapped));
            return;
        }
        yield put(retryProcessAutomaticPaymentImportedOperationSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(retryProcessAutomaticPaymentImportedOperationError(mapped));
    }
}

function* watchRetryProcessAutomaticPaymentImportedOperation() {
    yield takeLeading(RetryProcessAutomaticPaymentImportedOperationTypes.RETRY_PROCESS_AUTOMATIC_PAYMENT_IMPORTED_OPERATION_REQUEST, handleRetryProcessAutomaticPaymentImportedOperation);
}

function* retryProcessAutomaticPaymentImportedOperationSaga() {
    yield all([fork(watchRetryProcessAutomaticPaymentImportedOperation)]);
}

export default retryProcessAutomaticPaymentImportedOperationSaga;
