import { ScdBatchToArchiveResponse } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum ArchiveBatchOperationTypes {
    ARCHIVE_BATCH_OPERATION_REQUEST = '@@batch-operation-archive/ARCHIVE_BATCH_OPERATION_REQUEST',
    ARCHIVE_BATCH_OPERATION_SUCCESS = '@@batch-operation-archive/ARCHIVE_BATCH_OPERATION_SUCCESS',
    ARCHIVE_BATCH_OPERATION_ERROR = '@@batch-operation-archive/ARCHIVE_BATCH_OPERATION_ERROR',
    ARCHIVE_BATCH_OPERATION_RESET = '@@batch-operation-archive/ARCHIVE_BATCH_OPERATION_RESET',
}

export interface ArchiveBatchOperationState extends BaseStoreState {
    readonly archivedOperation?: ScdBatchToArchiveResponse;
}
