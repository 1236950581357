import clsx from 'clsx';
import RemoveIconButton from 'components/buttons/icon-buttons/remove/remove-icon-button';
import { ProgramOutputPoliciesToGetAll } from 'model/program-output-policy';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

export interface ProgramsOutputPoliciesRowProps {
    programOutputPolicies: ProgramOutputPoliciesToGetAll[];
}

const I18N_PREFIX = 'pages.scd.programs-output-policies';

const ProgramsOutputPoliciesRow = (props: ListChildComponentProps<ProgramsOutputPoliciesRowProps>) => {
    const { data, index, style } = props;

    const { programOutputPolicies } = data;

    const policy = programOutputPolicies[index];

    const { t } = useTranslation();

    return (
        <tr className="page-container--table-cell" key={policy.id} style={style}>
            <td className="page-container--table-cell-title">{policy.name}</td>
            <td className="page-container--table-cell-title">
                <span className={clsx('page-container--table-cell-title--info-status', policy.status)}>
                    {t(`${I18N_PREFIX}.status.${policy.status}`).toUpperCase()}
                </span>
            </td>
            <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.type.${policy.type}`)}</td>
            <td className="page-container--table-cell-title">{policy.platformName}</td>
            <td className="page-container--table-cell-icon">
                <RemoveIconButton disabled />
            </td>
        </tr>
    );
};

export default ProgramsOutputPoliciesRow;
