export enum ScdProgramPolicyType {
    CREDIT_CARD = 'CREDIT_CARD',
    PERSONAL_CREDIT = 'PERSONAL_CREDIT',
    DIRECT_CONSUMER_CREDIT = 'DIRECT_CONSUMER_CREDIT',
}

export enum ScdProgramCommissionPolicy {
    DAILY = 'DAILY',
    MONTHLY = 'MONTHLY',
    NO_COMMISSION = 'NO_COMMISSION',
}
