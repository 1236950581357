import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import HistoryIcon from '@material-ui/icons/History';
import clsx from 'clsx';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import DateFormatter, { DateFormat } from 'components/formatter/date/date-formatter';
import Loading from 'components/loading/loading';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import MonthlyCommissionReleaseButton from 'entities/monthly-commission/monthly-commission-detail/components/buttons/monthly-commission-release-button/monthly-commission-release-button';
import MonthlyCommissionOperationsValueCard from 'entities/monthly-commission/monthly-commission-detail/components/cards/monthly-commission-operations-value-card/monthly-commission-operations-value-card';
import MonthlyCommissionOrganizationCard from 'entities/monthly-commission/monthly-commission-detail/components/cards/monthly-commission-organization-card/monthly-commission-organization-card';
import MonthlyCommissionOriginatorCard from 'entities/monthly-commission/monthly-commission-detail/components/cards/monthly-commission-originator-card/monthly-commission-originator-card';
import MonthlyCommissionValuesCard from 'entities/monthly-commission/monthly-commission-detail/components/cards/monthly-commission-values-card/monthly-commission-values-card';
import MonthlyCommissionRectifyTaxesModal from 'entities/monthly-commission/monthly-commission-detail/components/modals/monthly-commission-rectify-taxes-modal/monthly-commission-rectify-taxes-modal';
import MonthlyCommissionReportModal from 'entities/monthly-commission/monthly-commission-detail/components/modals/monthly-commission-report-modal/monthly-commission-report-modal';
import MonthlyCommissionDetailInfo from 'entities/monthly-commission/monthly-commission-detail/components/monthly-commision-detail-info/monthly-commission-detail-info';
import MonthlyCommissionDetailTaxInfo from 'entities/monthly-commission/monthly-commission-detail/components/monthly-commission-detail-tax-info/monthly-commission-detail-tax-info';
import { validateMonthlyCommissionDocumentsValidation } from 'entities/monthly-commission/monthly-commission-detail/validation/monthly-commission-documents-validation';
import errorSvg from 'images/error.svg';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { MonthlyCommissionStatus } from 'model/enums/monthly-commission-status';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import {
    useDetailMonthlyCommissionState,
    useMonthlyCommissionGenerateReportState,
    useMonthlyCommissionRectifyTaxesState,
    useReleaseMonthlyCommissionState,
    useRootDispatch,
} from 'reducer/hooks';
import {
    detailMonthlyCommissionRequest,
    detailMonthlyCommissionResetState,
} from 'reducer/monthly-commission/detail-monthly-commission/actions';
import { monthlyCommissionGenerateReportResetState } from 'reducer/monthly-commission/generate-report/actions';
import { monthlyCommissionRectifyTaxesResetState } from 'reducer/monthly-commission/rectify-taxes/actions';
import {
    releaseMonthlyCommissionRequest,
    releaseMonthlyCommissionResetState,
} from 'reducer/monthly-commission/release-monthly-commission/actions';
import { useToast } from 'shared/hooks/use-toast';
import './monthly-commission-detail.scss';

const I18N_PREFIX = 'pages.scd.monthly-commission-detail';

const fallbackValue = '-'.repeat(7);

type ScdMonthlyCommissionDetailProps = RouteComponentProps<{ monthlyCommissionId: string }>;

export enum MonthlyCommissionDetailModalOptions {
    GENERATE_REPORT = 'GENERATE_REPORT',
    PAYMENT = 'PAYMENT',
    RELEASE = 'RELEASE',
    RELEASE_ERROR = 'RELEASE_ERROR',
    RECTIFY = 'RECTIFY',
}

export const ScdMonthlyCommissionDetail = (props: ScdMonthlyCommissionDetailProps) => {
    const { t } = useTranslation();

    const [monthlyCommissionId] = useState<number>(Number(props.match.params.monthlyCommissionId));
    const [modalAction, setModalAction] = useState<MonthlyCommissionDetailModalOptions | undefined>(undefined);

    const { status: detailStatus, monthlyCommission } = useDetailMonthlyCommissionState();
    const { status: generateReportStatus } = useMonthlyCommissionGenerateReportState();
    const { status: rectifyTaxesStatus } = useMonthlyCommissionRectifyTaxesState();
    const { status: releaseStatus } = useReleaseMonthlyCommissionState();

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { toastSuccess, toastError } = useToast();

    const handleDetailMonthlyCommission = useCallback(() => {
        dispatch(detailMonthlyCommissionRequest(monthlyCommissionId));
    }, [dispatch, monthlyCommissionId]);

    useEffect(() => {
        handleDetailMonthlyCommission();
    }, [handleDetailMonthlyCommission]);

    useEffect(() => {
        if (
            validateMonthlyCommissionDocumentsValidation(monthlyCommission) &&
            monthlyCommission?.status === MonthlyCommissionStatus.WAITING
        ) {
            toastSuccess(t(`${I18N_PREFIX}.toast.documents.success`));
        }
    }, [monthlyCommission, toastSuccess, t]);

    useEffect(() => {
        if (generateReportStatus === HttpRequestStatus.ERROR) {
            dispatch(monthlyCommissionGenerateReportResetState());
        }

        if (generateReportStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast.generate-report.success`));
            setModalAction(undefined);
            handleDetailMonthlyCommission();
            dispatch(monthlyCommissionGenerateReportResetState());
        }
    }, [generateReportStatus, dispatch, toastSuccess, t, handleDetailMonthlyCommission]);

    useEffect(() => {
        if (rectifyTaxesStatus === HttpRequestStatus.ERROR) {
            toastError(t(`${I18N_PREFIX}.toast.rectify-taxes.error`));
            dispatch(monthlyCommissionRectifyTaxesResetState());
        }

        if (rectifyTaxesStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast.rectify-taxes.success`));
            setModalAction(undefined);
            handleDetailMonthlyCommission();
            dispatch(monthlyCommissionRectifyTaxesResetState());
        }
    }, [rectifyTaxesStatus, dispatch, toastSuccess, t, toastError, handleDetailMonthlyCommission]);

    useEffect(() => {
        if (releaseStatus === HttpRequestStatus.ERROR) {
            setModalAction(MonthlyCommissionDetailModalOptions.RELEASE_ERROR);
            dispatch(releaseMonthlyCommissionResetState());
        }

        if (releaseStatus === HttpRequestStatus.SUCCESS) {
            toastSuccess(t(`${I18N_PREFIX}.toast.release.success`));
            setModalAction(undefined);
            handleDetailMonthlyCommission();
            dispatch(releaseMonthlyCommissionResetState());
        }
    }, [releaseStatus, dispatch, toastSuccess, t, toastError, handleDetailMonthlyCommission]);

    useEffect(() => {
        return () => {
            dispatch(detailMonthlyCommissionResetState());
        };
    }, [dispatch]);

    const isLoading = detailStatus !== HttpRequestStatus.SUCCESS;

    return isLoading ? (
        <Loading />
    ) : (
        <main className="scd-monthly-commission-detail">
            <ContextRibbon action={() => history.push('/monthly-commissions')} />
            <section className="scd-monthly-commission-detail__container">
                <header className="scd-monthly-commission-detail__header">
                    <div className="scd-monthly-commission-detail__header--title">
                        <span> {t(`${I18N_PREFIX}.title`)} </span>
                        <div
                            className={clsx('scd-monthly-commission-detail__header--status', {
                                NEW: monthlyCommission?.status === MonthlyCommissionStatus.NEW,
                                WAITING: monthlyCommission?.status === MonthlyCommissionStatus.WAITING,
                                RELEASED: monthlyCommission?.status === MonthlyCommissionStatus.RELEASED,
                                TRANSFERRING: monthlyCommission?.status === MonthlyCommissionStatus.TRANSFERRING,
                                PAID: monthlyCommission?.status === MonthlyCommissionStatus.PAID,
                            })}
                        >
                            {t(`${I18N_PREFIX}.status.options.${monthlyCommission?.status}`).toLocaleUpperCase()}
                        </div>
                    </div>
                    <div className="scd-monthly-commission-detail__header--last-update">
                        <span className="scd-monthly-commission-detail__header--last-update-icon">
                            <HistoryIcon />
                        </span>
                        <span> {t(`${I18N_PREFIX}.lastModifiedDate`)} </span>
                        <span className="scd-monthly-commission-detail__header--last-update-datetime">
                            {monthlyCommission?.lastModifiedDate ? (
                                <DateFormatter date={monthlyCommission?.lastModifiedDate} format={DateFormat.DATETIME_UTC} />
                            ) : (
                                fallbackValue
                            )}
                        </span>
                    </div>
                    <div className="scd-monthly-commission-detail__header--action-buttons">
                        {monthlyCommission?.status &&
                            (
                                {
                                    NEW: (
                                        <>
                                            <span
                                                className="scd-monthly-commission-detail__header--action-buttons--link"
                                                onClick={() => setModalAction(MonthlyCommissionDetailModalOptions.GENERATE_REPORT)}
                                            >
                                                <AddIcon /> {t(`${I18N_PREFIX}.button.generate-report`).toLocaleUpperCase()}
                                            </span>
                                        </>
                                    ),
                                    WAITING: (
                                        <>
                                            <MonthlyCommissionReleaseButton
                                                onMenuClick={() => setModalAction(MonthlyCommissionDetailModalOptions.RECTIFY)}
                                                onButtonClick={() => setModalAction(MonthlyCommissionDetailModalOptions.RELEASE)}
                                            />
                                        </>
                                    ),
                                } as Record<MonthlyCommissionStatus, React.ReactNode>
                            )[monthlyCommission?.status]}
                    </div>
                </header>
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <MonthlyCommissionDetailInfo monthlyCommission={monthlyCommission} />
                    </Grid>
                    <Grid item xs={7}>
                        <MonthlyCommissionDetailTaxInfo monthlyCommission={monthlyCommission} />
                    </Grid>
                </Grid>
                <div className="scd-monthly-commission-detail__cards">
                    <MonthlyCommissionOriginatorCard monthlyCommission={monthlyCommission} />
                    <MonthlyCommissionOrganizationCard monthlyCommission={monthlyCommission} />
                    <MonthlyCommissionValuesCard monthlyCommission={monthlyCommission} />
                </div>
                <MonthlyCommissionOperationsValueCard
                    monthlies={monthlyCommission?.monthlies ?? []}
                    dailies={monthlyCommission?.dailies ?? []}
                    yearMonth={monthlyCommission?.yearMonth}
                />
            </section>
            <MonthlyCommissionReportModal
                openModal={modalAction === MonthlyCommissionDetailModalOptions.GENERATE_REPORT}
                onClose={() => setModalAction(undefined)}
                setOpenModal={setModalAction}
                monthlyCommissionId={monthlyCommissionId}
            />
            <MonthlyCommissionRectifyTaxesModal
                openModal={modalAction === MonthlyCommissionDetailModalOptions.RECTIFY}
                onClose={() => setModalAction(undefined)}
                setOpenModal={setModalAction}
                monthlyCommissionTaxesValues={monthlyCommission?.taxValues ?? []}
                monthlyCommissionId={monthlyCommissionId}
            />
            {modalAction === MonthlyCommissionDetailModalOptions.RELEASE && (
                <ModalMessageLegacy
                    title={t(`${I18N_PREFIX}.modal.RELEASE.title`)}
                    message={<Trans i18nKey={`${I18N_PREFIX}.modal.RELEASE.message`} />}
                    standardButtonLabel={t('entity.action.release')}
                    onClose={() => setModalAction(undefined)}
                    onCancel={() => setModalAction(undefined)}
                    onAction={() => dispatch(releaseMonthlyCommissionRequest(monthlyCommissionId))}
                />
            )}
            {modalAction === MonthlyCommissionDetailModalOptions.RELEASE_ERROR && (
                <ModalMessageLegacy
                    icon={<img src={errorSvg} alt="Error" style={{ height: '100px' }} />}
                    onClose={() => setModalAction(undefined)}
                    onCancel={() => setModalAction(undefined)}
                    title={t(`${I18N_PREFIX}.modal.RELEASE_ERROR.title`)}
                    message={<Trans i18nKey={`${I18N_PREFIX}.modal.RELEASE_ERROR.message`} />}
                    outlinedButtonLabel={t('entity.action.try-again')}
                    outlinedButtonSize={'large'}
                />
            )}
        </main>
    );
};

export default ScdMonthlyCommissionDetail;
