import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { listTaxesError, listTaxesSuccess, listTaxesRequest } from 'reducer/tax/list-taxes/actions';
import { ListTaxesTypes } from 'reducer/tax/list-taxes/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import taxApi from 'services/api/tax-api';
import { ErrorUtils } from 'shared/error/error-utils';
import { Page } from 'services/page';
import { ScdTaxList } from 'model/tax';

function* handleListTaxes(action: ReturnType<typeof listTaxesRequest>) {
    try {
        const result: AxiosResponse<Page<ScdTaxList>> = yield call(taxApi.listTaxes, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listTaxesError(mapped));
            return;
        }
        yield put(listTaxesSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listTaxesError(mapped));
    }
}

function* watchListTaxes() {
    yield takeEvery(ListTaxesTypes.LIST_TAXES_REQUEST, handleListTaxes);
}

function* listTaxesSaga() {
    yield all([fork(watchListTaxes)]);
}

export default listTaxesSaga;
