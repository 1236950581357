import { Grid } from '@material-ui/core';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransfeeraBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/components/transfeera/transfeera-balance.model';
import { ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { JsonUtils } from 'shared/external/util/json-util';

interface TransfeeraValueItemProps {
    balance: ExternalBalanceResponse | undefined | null;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.balance-details';

const TransfeeraValueItem = (props: TransfeeraValueItemProps): JSX.Element => {
    const { balance } = props;

    const { t } = useTranslation();

    const parsedSuccessResponse = useMemo((): TransfeeraBalanceResponse | undefined => {
        return JsonUtils.parseJsonString(balance?.response);
    }, [balance?.response]);

    if (!balance?.isSuccessful) {
        return <></>;
    }

    return (
        <>
            <Grid item xs={6}>
                <label>{t(`${I18N_PREFIX}.value`)}</label>
                <span>
                    {!_.isNil(parsedSuccessResponse?.value) ? <CurrencyFormatter value={parsedSuccessResponse?.value} prefix /> : '-'}
                </span>
            </Grid>
            <Grid item xs={6}>
                <label>{t(`${I18N_PREFIX}.waitingValue`)}</label>
                <span>
                    {!_.isNil(parsedSuccessResponse?.waiting_value) ? (
                        <CurrencyFormatter value={parsedSuccessResponse?.waiting_value} prefix />
                    ) : (
                        '-'
                    )}
                </span>
            </Grid>
        </>
    );
};

export default TransfeeraValueItem;
