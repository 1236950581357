import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchSignatureImportState, BatchSignatureImportTypes } from 'reducer/batch/batch-signature-import/types';

const initialState: BatchSignatureImportState = {
    status: HttpRequestStatus.NOOP,
    importedResponse: undefined,
    error: undefined
}

const reducer: Reducer<BatchSignatureImportState> = (state = initialState, action): BatchSignatureImportState => {
    switch (action.type) {
        case BatchSignatureImportTypes.BATCH_SIGNATURE_IMPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchSignatureImportTypes.BATCH_SIGNATURE_IMPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, importedResponse: action.payload, error: undefined };
        }
        case BatchSignatureImportTypes.BATCH_SIGNATURE_IMPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchSignatureImportTypes.BATCH_SIGNATURE_IMPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

export { reducer as batchSignatureImportReducer }