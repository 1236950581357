import { Authority } from 'model/enums/authority';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useRootDispatch, useUserAccountState } from 'reducer/hooks';
import AuthUtil from 'services/api/auth-util';

export const useRedirectToHome = () => {
    const history = useHistory();
    const dispatch = useRootDispatch();
    const userAccountState = useUserAccountState();

    const logout = useCallback(() => {
        AuthUtil.removeToken();
        dispatch(markError({ message: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
        dispatch(loginResetState());
        dispatch(userAccountResetState());
    }, [dispatch]);

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (userAccountState.account?.activated !== true) return;
        if (userAccountState.account.authorities?.every(it => it !== Authority.ROLE_ADMIN)) return logout();

        history.push('/home');
    }, [history, userAccountState, logout]);
};
