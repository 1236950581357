import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdCompanyGroupPolicy from 'entities/company-group-policy/company-group-policy';
import ScdCompanyGroupPolicyCreateUpdate from 'entities/company-group-policy/company-group-policy-create-update/company-group-policy-create-update';
import ScdCompanyGroupPolicyDetail from 'entities/company-group-policy/company-group-policy-detail/company-group-policy-detail';

interface MatchParams {
    url: string;
}

const CompanyGroupPolicyRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={ScdCompanyGroupPolicyCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:companyGroupPolicyId`} component={ScdCompanyGroupPolicyDetail} />
        <PrivateRoute exact path={`${match.url}/:companyGroupPolicyId/edit`} component={ScdCompanyGroupPolicyCreateUpdate} />

        <PrivateRoute exact path={`${match.url}`} component={ScdCompanyGroupPolicy} />
    </Switch>
);

export default CompanyGroupPolicyRoutes;
