import { AxiosResponse } from 'axios';
import { ChunkCessionFinalizeResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import { chunkCessionFinalizeBcoError, chunkCessionFinalizeBcoRequest, chunkCessionFinalizeBcoSuccess } from 'reducer-bco/batch-output/chunk-cession-finalize/action';
import { ChunkCessionFinalizeBcoTypes } from 'reducer-bco/batch-output/chunk-cession-finalize/types';

import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleChunkCessionFinalizeBco(action: ReturnType<typeof chunkCessionFinalizeBcoRequest>) {
    try {
        const result: AxiosResponse<ChunkCessionFinalizeResponse> = yield call(batchOutputBcoApi.chunkCessionFinalize, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(chunkCessionFinalizeBcoError(mapped));
            return;
        }
        yield put(chunkCessionFinalizeBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(chunkCessionFinalizeBcoError(mapped));
    }
}

function* watchChunkCessionFinalizeBcoSaga() {
    yield takeEvery(ChunkCessionFinalizeBcoTypes.CHUNK_CESSION_FINALIZE_BCO_REQUEST, handleChunkCessionFinalizeBco);
}

function* chunkCessionFinalizeBcoSaga() {
    yield all([fork(watchChunkCessionFinalizeBcoSaga)]);
}

export default chunkCessionFinalizeBcoSaga;
