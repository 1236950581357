import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export type LinkActionLegacy = () => void;

export interface MenuItemLegacyPropsList {
    translation: string;
    visibility: boolean;
    submenu: {
        // TODO: REMOVE HREF AND REMOVE OPTIONAL OPERATOR FROM LINK
        link?: string;
        href?: string;
        translation: string;
        visibility: boolean;
        action?: LinkActionLegacy;
    }[];
}

export interface MenuItemLegacyProps {
    icon?: string;
    list: MenuItemLegacyPropsList[];
    menu?: boolean;
}

// TODO: AB#546
export const MenuItemLegacy = (props: MenuItemLegacyProps) => {
    const local = useLocation().pathname;
    const [itemActive, setItemActive] = useState<number | null>(null);
    const [submenu, setSubmenu] = useState<boolean>(false);
    const menuRef = useRef(null);

    const { t } = useTranslation();
    const { icon, list, menu } = props;

    const handleSubMenuVisibility = () => (submenu ? setSubmenu(false) : setSubmenu(true));

    const handleActiveItem = (index: number) => {
        setItemActive(index);
        handleSubMenuVisibility();
    };

    const handleActive = useCallback(
        (path?: string) => {
            const activeIndex = props.list.findIndex(it => it.submenu?.find(_it => _it.link?.split('/')[1] === path));
            setItemActive(ps => (ps !== activeIndex ? activeIndex : ps));
        },
        [props.list]
    );

    const handleSubMenu = (action?: LinkActionLegacy) => {
        if (action) {
            action();
        }
        handleSubMenuVisibility();
        setItemActive(null);
    };

    const handleOutside = (ref: any) => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSubmenu(false);
                setItemActive(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    };

    useEffect(() => {
        if (itemActive === null) {
            handleActive(local.split('/')[1]);
        }
    }, [local, itemActive, handleActive]);

    useEffect(() => {
        handleOutside(menuRef);
    }, [menuRef]);

    useEffect(() => {
        return () => {
            setSubmenu(false);
            setItemActive(null);
        };
    }, [setSubmenu, setItemActive]);

    return (
        <ul className="page-header--menu-legacy-list" ref={menu ? menuRef : null}>
            {list
                ?.filter(it => it.visibility)
                .map((item, index) => {
                    return (
                        <li className="page-header--menu-legacy-list-item" key={index} ref={!menu ? menuRef : null}>
                            <div
                                className={clsx('page-header--menu-legacy-list-item-button', { active: itemActive === index })}
                                onClick={() => handleActiveItem(index)}
                            >
                                {icon && <img src={icon} alt={icon} />}
                                <span> {t(item.translation)}</span>
                            </div>
                            <ul className="page-header--submenu-list-legacy">
                                {itemActive === index &&
                                    submenu &&
                                    item.submenu
                                        ?.filter(it => it.visibility)
                                        .map(it =>
                                            // TODO: PROVISORY CONDITIONAL
                                            it.href ? (
                                                <li
                                                    key={it.href}
                                                    className={clsx('page-header--submenu-list-legacy-item', { active: it.href === local })}
                                                >
                                                    <a href={it.href} className={clsx('page-header--submenu-list-legacy-item-link')}>
                                                        <span>{t(it.translation)}</span>
                                                    </a>
                                                </li>
                                            ) : (
                                                <li
                                                    key={it.link}
                                                    className={clsx('page-header--submenu-list-legacy-item', { active: it.link === local })}
                                                >
                                                    <Link
                                                        to={it.link ?? ''}
                                                        className={clsx('page-header--submenu-list-legacy-item-link')}
                                                        onClick={() => handleSubMenu(it.action)}
                                                    >
                                                        <span>{t(it.translation)}</span>
                                                    </Link>
                                                </li>
                                            )
                                        )}
                            </ul>
                        </li>
                    );
                })}
        </ul>
    );
};

export default MenuItemLegacy;
