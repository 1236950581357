import { BaseExternalStoreState } from 'shared/external/external-strategy.model';
import { ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';

export type ApiResponse = Record<string, any>;

export enum ExternalStatementPaymentInstitutionsAccountsTypes {
    EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-statement/EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-statement/EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-statement/EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-statement/EXTERNAL_STATEMENT_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface ExternalStatementPaymentInstitutionsAccountsState extends BaseExternalStoreState {
    readonly externalStatement?: ExternalStatementResponse
}
