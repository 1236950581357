import { ScdImportedOperationMonthlyProcessedReport } from 'model/imported-operation';
import { useMemo } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

interface MonthlyProcessedOperationsReportCSVProps {
    csvLinkRef: React.RefObject<
        CSVLink &
            HTMLAnchorElement & {
                link: HTMLAnchorElement;
            }
    >;
    monthlyProcessedOperationsReportData: ScdImportedOperationMonthlyProcessedReport[];
    yearMonth: string;
}

type ReportHeader = Record<keyof ScdImportedOperationMonthlyProcessedReport, string>;

const I18N_PREFIX = 'pages.scd.monthly-processed-operations-report.headers';

const MonthlyProcessedOperationsReportCSV = (props: MonthlyProcessedOperationsReportCSVProps) => {
    const { csvLinkRef, monthlyProcessedOperationsReportData, yearMonth } = props;
    const { t } = useTranslation();

    const reportFileName: string = useMemo(() => {
        return `RelatorioMensalDeOperacoes_${yearMonth}.csv`;
    }, [yearMonth]);

    const REPORT_HEADERS: ReportHeader = useMemo(() => {
        return {
            originatorName: t(`${I18N_PREFIX}.originatorName`),
            yearMonth: t(`${I18N_PREFIX}.yearMonth`),
            numberOfOperations: t(`${I18N_PREFIX}.numberOfOperations`),
            disbursementAmount: t(`${I18N_PREFIX}.disbursementAmount`),
            debitAmount: t(`${I18N_PREFIX}.debitAmount`),
            retentionAmount: t(`${I18N_PREFIX}.retentionAmount`),
            iofAmount: t(`${I18N_PREFIX}.iofAmount`),
        };
    }, [t]);

    return (
        <>
            <CSVLink
                className="hidden"
                ref={csvLinkRef}
                data={monthlyProcessedOperationsReportData}
                filename={reportFileName}
                headers={Object.keys(REPORT_HEADERS).map(key => ({ key, label: REPORT_HEADERS[key] }))}
            />
        </>
    );
};

export default MonthlyProcessedOperationsReportCSV;
