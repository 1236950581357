import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdImportedOperationBatch } from 'model/imported-operation';
import { markError } from 'reducer/application/error/actions';
import {
    importedOperationsBatchError,
    importedOperationsBatchRequest,
    importedOperationsBatchSuccess,
} from 'reducer/imported-operation/batch/actions';
import { ImportedOperationsBatchTypes } from 'reducer/imported-operation/batch/types';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import importedOperationApi from 'services/api/imported-operation-api';
import { Page } from 'services/page';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleImportedOperationsBatch(action: ReturnType<typeof importedOperationsBatchRequest>) {
    try {
        const result: AxiosResponse<Page<ScdImportedOperationBatch>> = yield call(importedOperationApi.batchRequest, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(importedOperationsBatchError(mapped));
            return;
        }
        yield put(importedOperationsBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(importedOperationsBatchError(mapped));
    }
}

function* watchImportedOperationsBatch() {
    yield takeEvery(ImportedOperationsBatchTypes.IMPORTED_OPERATIONS_BATCH_REQUEST, handleImportedOperationsBatch);
}

function* importedOperationsBatchSaga() {
    yield all([fork(watchImportedOperationsBatch)]);
}

export default importedOperationsBatchSaga;
