import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import Loading from 'components/loading/loading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDetailIssuerPolicyState, useRootDispatch } from 'reducer/hooks';
import "./issuer-policies-detail.scss"
import { IssuerPolicyDetailRequest } from 'model/issuer-policy';
import { detailIssuerPolicyRequest, detailIssuerPolicyResetState } from 'reducer/issuer-policy/detail-policy/actions';
import { HttpRequestStatus } from 'model/enums/http-request-status';

type IssuerPolicyDetailProps = RouteComponentProps<{ issuerId: string, issuerPolicyId: string }>;

const I18N_PREFIX = 'pages.scd.issuer-policy';

const IssuerPolicyDetail = (props: IssuerPolicyDetailProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useRootDispatch();

    const [issuerId] = useState<number>(Number(props.match.params.issuerId));
    const [issuerPolicyId] = useState<number>(Number(props.match.params.issuerPolicyId));

    const { status: statusDetailPolicy, issuerPolicyDetail } = useDetailIssuerPolicyState();

    useEffect(() => {
        if (!issuerId || !issuerPolicyId) return;

        const request: IssuerPolicyDetailRequest = {
            issuerId,
            id: issuerPolicyId,
        };

        dispatch(detailIssuerPolicyRequest(request));
    }, [dispatch, issuerId, issuerPolicyId]);

    useEffect(() => {
        return () => {
            dispatch(detailIssuerPolicyResetState());
        }
    }, [dispatch])

    const isLoading = statusDetailPolicy === HttpRequestStatus.ON_GOING;

    return (
        <main className="issuer-policies__detail">
            <ContextRibbon action={() => history.goBack()} />
            <section className="issuer-policies--container">
                <header className="issuer-policies--header">
                    <h2 className="issuer-policies--header-title">{t(`${I18N_PREFIX}.detail.title`)}</h2>
                </header>
                <div className="issuer-policies__detail--container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ReadOnlyTextField 
                                label={t(`${I18N_PREFIX}.input.name.label`)} 
                                value={issuerPolicyDetail?.name ?? ''} 
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.status.label`)}
                                value={t(`${I18N_PREFIX}.input.status.options.${issuerPolicyDetail?.status}`)}
                            />
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.type.label`)}
                                value={t(`${I18N_PREFIX}.input.type.options.${issuerPolicyDetail?.type}`)}
                            />
                            {issuerPolicyDetail?.issuerIntegration && (
                                <>
                                    <ReadOnlyTextField 
                                        label={t(`${I18N_PREFIX}.input.integration.authorizationToken.label`)} 
                                        value={issuerPolicyDetail?.issuerIntegration.authorizationToken ?? ''} 
                                    />
                                    <ReadOnlyTextField 
                                        label={t(`${I18N_PREFIX}.input.integration.authorizationScheme.label`)} 
                                        value={t(`${I18N_PREFIX}.input.integration.authorizationScheme.options.${issuerPolicyDetail?.issuerIntegration.authorizationScheme}`)} 
                                    />
                                </>
                            )}
                            <ReadOnlyTextField
                                label={t(`${I18N_PREFIX}.input.platform.label`)}
                                value={issuerPolicyDetail?.platform?.name ?? ''}
                            />
                        </>
                    )}
                </div>
            </section>
        </main>
    )
}

export default IssuerPolicyDetail;