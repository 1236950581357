import ValidSearchInput from 'components/inputs/search-input/valid-search-input';
import { SEARCH_ITEM_SIZE } from 'components/modals/constants';
import { validateProgramGroup } from 'entities/legacy-program/validation/company-groups-validation';
import { ScdCompanyGroup } from 'model/company-group';
import { useTranslation } from 'react-i18next';
import { IRootState } from 'reducer';
import { searchCompanyGroupsRequest, searchCompanyGroupsResetState } from 'reducer/company-group/search-company-groups/actions';
import { LegacyScdProgram } from 'model/program';
import ModalSearch from 'components/modals/modal-search/modal-search';

export interface CompanyGroupCardCreateProps {
    entity: ScdCompanyGroup;
    onChange: (companyGroups: Partial<ScdCompanyGroup>) => void;
    showValidation: boolean;
    alreadyAdd?: boolean;
    handleAlreadyAdd?: (companyGroup: ScdCompanyGroup) => void;
    scdProgram: LegacyScdProgram;
}

const I18N_PREFIX = 'pages.scd.program.card.companyGroups';

export const CompanyGroupCardCreate = (props: CompanyGroupCardCreateProps) => {
    const { entity, onChange, showValidation, handleAlreadyAdd, alreadyAdd, scdProgram } = props;
    const { t } = useTranslation();

    return (
        <article className="scd-program__card-manager">
            <header className="card-edition__container--header">
                <span className="card-edition__container--header-name">{t(`${I18N_PREFIX}.name`)}</span>
            </header>
            <div className="card-edition__container--content">
                <div className="scd-program--form-card-field">
                    <ModalSearch<ScdCompanyGroup>
                        action={searchCompanyGroupsRequest}
                        itemSize={SEARCH_ITEM_SIZE}
                        modalTitle={t(`${I18N_PREFIX}.input.modal.title`)}
                        modalLabel={t(`${I18N_PREFIX}.input.modal.label`)}
                        modalPlaceholder={t(`${I18N_PREFIX}.input.modal.placeholder`)}
                        onSelect={companyGroup => {
                            handleAlreadyAdd && handleAlreadyAdd(companyGroup);
                            onChange({ ...companyGroup });
                        }}
                        renderItem={companyGroup => companyGroup.name}
                        statusSelector={(state: IRootState) => state.searchCompanyGroups.status}
                        dataSelector={(state: IRootState) => state.searchCompanyGroups.companyGroups}
                        resetState={searchCompanyGroupsResetState}
                        requestParameters={{ organizationId: scdProgram.organization?.id }}
                    >
                        {handleOpen => (
                            <>
                                <ValidSearchInput
                                    label={`${I18N_PREFIX}.input.label`}
                                    showValidation={showValidation}
                                    placeholder={`${I18N_PREFIX}.input.placeholder`}
                                    validate={validateProgramGroup}
                                    value={entity.name ?? ''}
                                    onClick={handleOpen}
                                    onFocus={handleOpen}
                                    readOnly
                                    externalUpdate
                                />
                                {alreadyAdd && (
                                    <span className="scd-program--form-card--field-error">{t(`${I18N_PREFIX}.validate.alreadyAdd`)}</span>
                                )}
                            </>
                        )}
                    </ModalSearch>
                </div>
            </div>
        </article>
    );
};
