import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import './header-operation-info-top.scss';

interface HeaderOperationInfoTopProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.headerInfo';

const HeaderOperationInfoTop = (props: HeaderOperationInfoTopProps) => {
    const {
        operation: { originator, programIdentifier, assetSubType },
    } = props;

    const { t } = useTranslation();

    return (
        <Grid item xs={12}>
            <div className="scd-header-operation-info-top">
                <div className="scd-header-operation-info-top--item">
                    <span className="scd-header-operation-info-top--item__label"> {t(`${I18N_PREFIX}.fields.originator`)} </span>
                    <Tooltip title={originator ?? '-'}>
                        <span className="scd-header-operation-info-top--item__value"> {originator ?? "-"} </span>
                    </Tooltip>
                </div>
                <div className="scd-header-operation-info-top--item">
                    <span className="scd-header-operation-info-top--item__label"> {t(`${I18N_PREFIX}.fields.program`)} </span>
                    <Tooltip title={programIdentifier ?? '-'}>
                        <span className="scd-header-operation-info-top--item__value"> {programIdentifier ?? "-"} </span>
                    </Tooltip>
                </div>
                <div className="scd-header-operation-info-top--item">
                    <span className="scd-header-operation-info-top--item__label"> {t(`${I18N_PREFIX}.fields.assetSubType`)} </span>
                    <Tooltip title={assetSubType ?? '-'}>
                        <span className="scd-header-operation-info-top--item__value"> {assetSubType ?? "-"} </span>
                    </Tooltip>
                </div>
            </div>
        </Grid>
    );
};

export default HeaderOperationInfoTop;
