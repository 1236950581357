import { GiroWebappError } from 'model/error';
import { ScdPaymentTransactionSummarize } from 'model/payment-transaction';
import { SummarizePaymentTransactionsTypes } from 'reducer/payment-transaction/summarize-payment-transactions/types';
import { action } from 'typesafe-actions';

export const summarizePaymentTransactionsRequest = () =>
    action(SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_REQUEST);
export const summarizePaymentTransactionsSuccess = (numberOfPendingTransactions: ScdPaymentTransactionSummarize) =>
    action(SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_SUCCESS, numberOfPendingTransactions);
export const summarizePaymentTransactionsError = (error: GiroWebappError) =>
    action(SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSATIONS_ERROR, error);
export const summarizePaymentTransactionsResetState = () => action(SummarizePaymentTransactionsTypes.SUMMARIZE_PAYMENT_TRANSACTIONS_RESET);
