import { BatchSignaturePreviewDocumentsImport } from 'model/batch-signature';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum BatchSignatureTypes {
    BATCH_SIGNATURE_REQUEST = '@@batch-signature/BATCH_SIGNATURE_REQUEST',
    BATCH_SIGNATURE_SUCCESS = '@@batch-signature/BATCH_SIGNATURE_SUCCESS',
    BATCH_SIGNATURE_ERROR = '@@batch-signature/BATCH_SIGNATURE_ERROR',
    BATCH_SIGNATURE_RESET = '@@batch-signature/BATCH_SIGNATURE_RESET',
}

export interface BatchSignatureState extends BaseStoreState {
    readonly batchSignature?: BatchSignaturePreviewDocumentsImport
}