import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchDisbursementReportTypes, BatchDisbursementReportState } from 'reducer/batch/disbursement-report/types';

const initialState: BatchDisbursementReportState = {
    status: HttpRequestStatus.NOOP,
    batchDisbursementReport: undefined,
    error: undefined,
};

const reducer: Reducer<BatchDisbursementReportState> = (state = initialState, action): BatchDisbursementReportState => {
    switch (action.type) {
        case BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchDisbursementReport: action.payload, error: undefined };
        }
        case BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchDisbursementReportTypes.BATCH_DISBURSEMENT_REPORT_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchDisbursementReportReducer };
