import { AxiosResponse } from 'axios';
import { CessionToResumeBco } from 'model/bco/batch-output-cession-integration-gtsec';
import HttpStatus from 'model/enums/http-status';
import { cessionToResumeBcoError, cessionToResumeBcoRequest, cessionToResumeBcoSuccess } from 'reducer-bco/batch-output/cession-to-resume/action';
import { CessionToResumeBcoTypes } from 'reducer-bco/batch-output/cession-to-resume/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';

function* handleCessionToResumeBco(action: ReturnType<typeof cessionToResumeBcoRequest>) {
    try {
        const result: AxiosResponse<CessionToResumeBco> = yield call(batchOutputBcoApi.cessionToResume, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(cessionToResumeBcoError(mapped));
            return;
        }
        yield put(cessionToResumeBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(cessionToResumeBcoError(mapped));
    }
}

function* watchCessionToResumeBcoSaga() {
    yield takeEvery(CessionToResumeBcoTypes.CESSION_TO_RESUME_BCO_REQUEST, handleCessionToResumeBco);
}

function* cessionToResumeBcoSaga() {
    yield all([fork(watchCessionToResumeBcoSaga)]);
}

export default cessionToResumeBcoSaga;
