import { ScdDisbursementReport } from 'model/disbursement-report';
import { GiroWebappError } from 'model/error';
import { ScdImportedOperationDisbursementReport } from 'model/imported-operation';
import { ImportedOperationDisbursementReportTypes } from 'reducer/imported-operation/disbursement-report/types';
import { action } from 'typesafe-actions';

export const importedOperationDisbursementReportRequest = (request: ScdDisbursementReport) =>
    action(ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_REQUEST, request);
export const importedOperationDisbursementReportSuccess = (disbursementReport: ScdImportedOperationDisbursementReport) =>
    action(ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_SUCCESS, disbursementReport);
export const importedOperationDisbursementReportError = (error: GiroWebappError) =>
    action(ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_ERROR, error);
export const importedOperationDisbursementReportResetState = () =>
    action(ImportedOperationDisbursementReportTypes.INPORTED_OPERATION_DISBURSEMENT_REPORT_RESET_STATE);
