import { ScdTechnicalSupplier, ScdTechnicalSupplierSimple } from 'model/technical-supplier';
import { GiroWebappError } from 'model/error';
import { CreateTechnicalSupplierTypes } from './types';
import { action } from 'typesafe-actions';

export const createTechnicalSupplierRequest = (technicalSupplier: ScdTechnicalSupplier) =>
    action(CreateTechnicalSupplierTypes.CREATE_TECHNICAL_SUPPLIER_REQUEST, technicalSupplier);
export const createTechnicalSupplierSuccess = (createdTechnicalSupplier: ScdTechnicalSupplierSimple) =>
    action(CreateTechnicalSupplierTypes.CREATE_TECHNICAL_SUPPLIER_SUCCESS, createdTechnicalSupplier);
export const createTechnicalSupplierError = (error: GiroWebappError) =>
    action(CreateTechnicalSupplierTypes.CREATE_TECHNICAL_SUPPLIER_ERROR, error);
export const createTechnicalSupplierResetState = () => action(CreateTechnicalSupplierTypes.CREATE_TECHNICAL_SUPPLIER_RESET_STATE);
