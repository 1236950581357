import { BaseStoreState } from 'reducer/base-store-state';
import { ScdTechnicalSupplierSimple } from 'model/technical-supplier';

export type ApiResponse = Record<string, string>;

export enum UpdateTechnicalSupplierTypes {
    UPDATE_TECHNICAL_SUPPLIER_REQUEST = '@@technical-supplier-update/UPDATE_TECHNICAL_SUPPLIER_REQUEST',
    UPDATE_TECHNICAL_SUPPLIER_SUCCESS = '@@technical-supplier-update/UPDATE_TECHNICAL_SUPPLIER_SUCCESS',
    UPDATE_TECHNICAL_SUPPLIER_ERROR = '@@technical-supplier-update/UPDATE_TECHNICAL_SUPPLIER_ERROR',
    UPDATE_TECHNICAL_SUPPLIER_RESET_STATE = '@@technical-supplier-update/UPDATE_TECHNICAL_SUPPLIER_RESET_STATE',
}

export interface UpdateTechnicalSupplierState extends BaseStoreState {
    readonly technicalSupplier?: ScdTechnicalSupplierSimple;
}
