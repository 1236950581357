import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { GiroScdErrorConstants } from 'model/enums/error-constants';
import { PaymentTrasactionToProcessResponse, ScdPaymentTransactionPendingData } from 'model/payment-transaction';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { valuesOfObject } from 'shared/util/object-utils';
import { getPersonType } from 'shared/util/person-type-utils';

import './process-payment-transactions-modal-error.scss';

const I18N_PREFIX = 'pages.scd.complete-payment-transactions.modal.PROCESS_ERROR';

interface ProcessPaymentTransactionsModalErrorProps {
    title: string;
    onClose: () => void;
    onAction?: () => void;
    processedPaymentTransactions: PaymentTrasactionToProcessResponse | undefined;
    pendingTransactions: ScdPaymentTransactionPendingData[] | undefined;
}

const ProcessPaymentTransactionsModalError = (props: ProcessPaymentTransactionsModalErrorProps) => {
    const { title, onClose, onAction, processedPaymentTransactions, pendingTransactions } = props;

    const { t } = useTranslation();

    const verifyErrorMessage = (message: string | undefined) => {
        const errors: string[] = valuesOfObject(GiroScdErrorConstants);

        return errors.some(value => value === message);
    };

    const getTransactionInformations = (id: number): JSX.Element => {
        const selectedTransaction = pendingTransactions?.find(operation => operation.id === id);

        if (!selectedTransaction) return <></>;

        const noOriginatorInfo: string = t(`${I18N_PREFIX}.no-originator`);

        return (
            <Trans
                i18nKey={`${I18N_PREFIX}.informationsComponent`}
                t={t}
                values={{ originatorName: selectedTransaction.originatorName ?? noOriginatorInfo }}
                components={{
                    favoredIdentification: (
                        <IdentificationFormatter
                            type={getPersonType(selectedTransaction.favoredIdentification)}
                            value={selectedTransaction.favoredIdentification}
                        />
                    ),
                    amount: <CurrencyFormatter value={selectedTransaction.amount} prefix />,
                }}
            />
        );
    };

    return (
        <Modal isOpen>
            <div className="process-transactions-modal-error">
                <article className="process-transactions-modal-error--content">
                    <header className="process-transactions-modal-error--header">
                        <CloseButton onClick={onClose} />
                    </header>
                    <div className="process-transactions-modal-error--message">
                        <p className="process-transactions-modal-error--title">{t(title)}</p>
                        <div className="process-transactions-modal-error--errors">
                            {processedPaymentTransactions?.errors?.map(({ id, message }) => (
                                <div className="process-transactions-modal-error--errors__text" key={id}>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.identificationInfo`)} </strong>
                                        {getTransactionInformations(id)}
                                    </span>
                                    <span>
                                        <strong> {t(`${I18N_PREFIX}.error-message`)} </strong>
                                        {message && verifyErrorMessage(message)
                                            ? t(message)
                                            : !!message
                                            ? message
                                            : t(`${I18N_PREFIX}.default-error-message`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {onAction && (
                        <div className="process-transactions-modal-error--actions">
                            {onAction && <StandardButtonLegacy label={'entity.action.back'} onClick={onAction} />}
                        </div>
                    )}
                </article>
            </div>
        </Modal>
    );
};

export default ProcessPaymentTransactionsModalError;
