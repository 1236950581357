import { ScdCompanyGroupPolicy, ScdCompanyGroupPolicySimple } from 'model/company-group-policy';
import { GiroWebappError } from 'model/error';
import { UpdateCompanyGroupPolicyTypes } from 'reducer/company-group-policy/update-company-group-policy/types';
import { action } from 'typesafe-actions';

export const updateCompanyGroupPolicyRequest = (companyGroupPolicy: ScdCompanyGroupPolicy) =>
    action(UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_REQUEST, companyGroupPolicy);
export const updateCompanyGroupPolicySuccess = (updatedCompanyGroupPolicy: ScdCompanyGroupPolicySimple) =>
    action(UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_SUCCESS, updatedCompanyGroupPolicy);
export const updateCompanyGroupPolicyError = (error: GiroWebappError) =>
    action(UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_ERROR, error);
export const updateCompanyGroupPolicyResetState = () => action(UpdateCompanyGroupPolicyTypes.UPDATE_COMPANY_GROUP_POLICY_RESET);
