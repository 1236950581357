import { ScdImportedOperationSimple } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum RetryPreprocessImportedOperationTypes {
    RETRY_PREPROCESS_IMPORTED_OPERATION_REQUEST = '@@retry-preprocess-imported-operation/RETRY_PREPROCESS_IMPORTED_OPERATION_REQUEST',
    RETRY_PREPROCESS_IMPORTED_OPERATION_SUCCESS = '@@retry-preprocess-imported-operation/RETRY_PREPROCESS_IMPORTED_OPERATION_SUCCESS',
    RETRY_PREPROCESS_IMPORTED_OPERATION_ERROR = '@@retry-preprocess-imported-operation/RETRY_PREPROCESS_IMPORTED_OPERATION_ERROR',
    RETRY_PREPROCESS_IMPORTED_OPERATION_RESET_STATE = '@@retry-preprocess-imported-operation/RETRY_PREPROCESS_IMPORTED_OPERATION_RESET_STATE',
}

export interface RetryPreprocessImportedOperationState extends BaseStoreState {
    readonly importedOperationSimple?: ScdImportedOperationSimple;
}
