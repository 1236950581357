import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchPaymentFavoredTypes, SearchPaymentFavoredState } from 'reducer/payment-favored/search-payment-favored/types';

const initialState: SearchPaymentFavoredState = {
    status: HttpRequestStatus.NOOP,
    paymentFavored: undefined,
    error: undefined,
};

const reducer: Reducer<SearchPaymentFavoredState> = (state = initialState, action): SearchPaymentFavoredState => {
    switch (action.type) {
        case SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, paymentFavored: action.payload, error: undefined };
        }
        case SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case SearchPaymentFavoredTypes.SEARCH_PAYMENT_FAVORED_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchPaymentFavoredReducer };
