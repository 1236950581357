import { AxiosResponse } from 'axios';
import { PaymentInstitutionAccountToResume } from 'model/payment-institution-account';
import api from 'services/api/api';
import { BASE_URL_ADMIN } from 'services/page';
import { ExternalBalanceResponse } from 'shared/external/payment-accounts-strategy/balance/external-balance.model';
import { ExternalStatementRequest, ExternalStatementResponse } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';
import { ExternalTransfersRequest, ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';

const PROXY_URL = `${BASE_URL_ADMIN}/external`

const externalStrategyApi = () => {
    const externalBalancePaymentInstitutionAccount = (paymentInstitutionAccountId: number): Promise<AxiosResponse<ExternalBalanceResponse>> => {
        return api.get<ExternalBalanceResponse>(`${PROXY_URL}/payment-institution-accounts/${paymentInstitutionAccountId}/balance`)
    }

    const externalTransfersPaymentInstitutionAccount = (request: ExternalTransfersRequest): Promise<AxiosResponse<ExternalTransfersResponse>> => {
        const { filter, pageable, accountId } = request
        const _requestFilters = [
            filter?.initialDate && `initialDate=${filter.initialDate}`,
            filter?.endDate && `endDate=${filter.endDate}`,
            filter?.status && `status=${filter.status}`,
            filter?.search && `search=${encodeURIComponent(filter.search)}`
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<ExternalTransfersResponse>(`${PROXY_URL}/accounts/${accountId}/transfers?${_requestFilters}`, {
            params: { ...pageable }
        })
    }

    const resumePaymentInstitutionAccount = (accountId: number): Promise<AxiosResponse<PaymentInstitutionAccountToResume>> => {
        return api.get<PaymentInstitutionAccountToResume>(`${PROXY_URL}/payment-institution-accounts/${accountId}/resume-for-external-transfers`)
    }

    const externalStatementPaymentInstitutionAccount = (request: ExternalStatementRequest): Promise<AxiosResponse<ExternalStatementResponse>> => {
        const { accountId, filter, pageable } = request

        const _requestFilters = [
            filter?.initialDate && `initialDate=${filter.initialDate}`,
            filter?.endDate && `endDate=${filter.endDate}`,
        ]
            .filter(it => !!it)
            .join('&')

        return api.get<ExternalStatementResponse>(`${PROXY_URL}/accounts/${accountId}/statement?${_requestFilters}`, {
            params: { ...pageable }
        })
    }

    return {
        externalBalancePaymentInstitutionAccount,
        externalTransfersPaymentInstitutionAccount,
        resumePaymentInstitutionAccount,
        externalStatementPaymentInstitutionAccount
    }
}

export default externalStrategyApi()