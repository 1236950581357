import { PaymentAccountToGetInternalRepayment } from 'model/payment-transaction';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum InternalRepaymentAccountPaymentTransactionTypes {
    INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_REQUEST = '@@payment-transactions-internal-repayment-account/INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_REQUEST',
    INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_SUCCESS = '@@payment-transactions-internal-repayment-account/INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_SUCCESS',
    INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_ERROR = '@@payment-transactions-internal-repayment-account/INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_ERROR',
    INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_RESET = '@@payment-transactions-internal-repayment-account/INTERNAL_REPAYMENT_ACCOUNT_PAYMENT_TRANSACTION_RESET',
}

export interface InternalRepaymentAccountPaymentTransactionState extends BaseStoreState {
    account?: PaymentAccountToGetInternalRepayment
}
