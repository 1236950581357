import { GiroWebappError } from 'model/error';
import { ListOperationsRequest, ScdImportedOperationsData } from 'model/imported-operation';
import { ImportedOperationsListTypes } from 'reducer/imported-operation/list-operations/types';
import { Page } from 'services/page';
import { action } from 'typesafe-actions';

export const importedOperationsListRequest = (request: ListOperationsRequest) =>
    action(ImportedOperationsListTypes.IMPORTED_OPERATIONS_LIST_REQUEST, request);
export const importedOperationsListSuccess = (data: Page<ScdImportedOperationsData>) =>
    action(ImportedOperationsListTypes.IMPORTED_OPERATIONS_LIST_SUCCESS, data);
export const importedOperationsListError = (error: GiroWebappError) =>
    action(ImportedOperationsListTypes.IMPORTED_OPERATIONS_LIST_ERROR, error);
export const importedOperationsListResetState = () => action(ImportedOperationsListTypes.IMPORTED_OPERATIONS_LIST_RESET_STATE);
