import { SimplePaymentInstitutionAccountToWithdraw } from 'model/payment-institution-account';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum WithdrawExternalBalanceTypes {
    WITHDRAW_EXTERNAL_BALANCE_REQUEST = '@@payment-institutions-accounts-balance/WITHDRAW_EXTERNAL_BALANCE_REQUEST',
    WITHDRAW_EXTERNAL_BALANCE_SUCCESS = '@@payment-institutions-accounts-balance/WITHDRAW_EXTERNAL_BALANCE_SUCCESS',
    WITHDRAW_EXTERNAL_BALANCE_ERROR = '@@payment-institutions-accounts-balance/WITHDRAW_EXTERNAL_BALANCE_ERROR',
    WITHDRAW_EXTERNAL_BALANCE_RESET = '@@payment-institutions-accounts-balance/WITHDRAW_EXTERNAL_BALANCE_RESET',
}

export interface WithdrawExternalBalanceState extends BaseStoreState {
    paymentInstitutionAccount?: SimplePaymentInstitutionAccountToWithdraw
}
