import Loading from 'components/loading/loading';
import BatchGenerateCnabModal from 'entities/batch/components/batch-generate-cnab-modal/batch-generate-cnab-modal';
import BatchReportModal from 'entities/batch/components/batch-report-modal/batch-report-modal';
import BatchesRow, { BatchesRowProps } from 'entities/batch/components/batches-row/batches-row';
import BatchModalAction, { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import BatchModalCloseDetails from 'entities/batch/components/modal-close-details/modal-close-details';
import { BatchFilterRequest, ScdBatchData } from 'model/batch';
import { CnabTemplate } from 'model/enums/cnab-template';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import { useExportBatchState } from 'reducer/hooks';
import { useToast } from 'shared/hooks/use-toast';
import { COMMOM_LIST_ITEMS_SIZE, LIST_HEIGHT } from 'shared/infinite-components/constants';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.batch';

interface ListBatchesProps {
    filterActive: BatchFilterRequest;
    isDisabledMenu: boolean;
    modalAction: BatchModalActionOptions | undefined;
    setModalAction: (value: BatchModalActionOptions | undefined) => void;
    handleRefreshList: () => void;
    batches: ScdBatchData[];
    isLoading: boolean;
}

const BatchesList = (props: ListBatchesProps) => {
    const { filterActive, isDisabledMenu, modalAction, setModalAction, handleRefreshList, batches, isLoading } = props;
    const { t } = useTranslation();
    const { status: exportBatchStatus } = useExportBatchState();

    const [selectedBatch, setSelectedBatch] = useState<ScdBatchData>({} as ScdBatchData);
    const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);
    const [isOpenGenerateCnabModal, setIsOpenGenerateCnabModal] = useState<boolean>(false);
    const [modalCloseDetails, setModalCloseDetails] = useState<boolean>(false);
    const isDisabledModalButton = exportBatchStatus === HttpRequestStatus.ON_GOING;
    const { toastError } = useToast();

    const handleOpenGenerateCnabModal = () => {
        if (!selectedBatch.cnabTemplate) {
            toastError(t(`${I18N_PREFIX}.generate-cnab.toast.${!selectedBatch.cnabTemplate}`));
            return;
        }
        if (selectedBatch?.cnabTemplate === CnabTemplate.NO_CNAB) {
            toastError(t(`${I18N_PREFIX}.generate-cnab.toast.${selectedBatch?.cnabTemplate}`));
            return;
        }
        setIsOpenGenerateCnabModal(true);
    };

    const batchesRowObjectData: BatchesRowProps = {
        batches,
        isDisabledMenu,
        setModalAction,
        selectedBatch,
        setSelectedBatch,
        setIsOpenReportModal,
        setModalCloseDetails,
        handleOpenGenerateCnabModal,
    };

    return (
        <>
            {isLoading && !batches.length && <Loading isTable />}
            {batches?.length !== 0 ? (
                <AutoSizer defaultHeight={LIST_HEIGHT} disableWidth>
                    {({ height, width }) => (
                        <FixedSizeList
                            itemData={batchesRowObjectData}
                            height={height}
                            width={width}
                            itemSize={COMMOM_LIST_ITEMS_SIZE}
                            itemCount={batches.length}
                            className="page-container--table-container"
                        >
                            {BatchesRow}
                        </FixedSizeList>
                    )}
                </AutoSizer>
            ) : (
                !isLoading && (
                    <div className="scd-batch__table-empty-filter">
                        <span className="scd-batch__table-empty-filter-image" />
                        <span className="scd-batch__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                        <span className="scd-batch__table-empty-filter-subtitle">
                            {valuesOfObject({ ...filterActive }).length !== 0 && t(`${I18N_PREFIX}.invalid-filter`)}
                        </span>
                    </div>
                )
            )}

            <BatchModalCloseDetails
                batchId={selectedBatch.id}
                setModalCloseDetails={setModalCloseDetails}
                modalCloseDetails={modalCloseDetails}
                batchCode={selectedBatch.code}
            />
            <BatchModalAction
                batchId={selectedBatch?.id}
                modalAction={modalAction}
                setModalAction={setModalAction}
                disabled={isDisabledModalButton}
            />
            <BatchReportModal batchId={selectedBatch?.id} isOpenModal={isOpenReportModal} setIsOpenModal={setIsOpenReportModal} />
            <BatchGenerateCnabModal
                batchId={selectedBatch?.id}
                cnabTemplate={selectedBatch?.cnabTemplate}
                isOpenModal={isOpenGenerateCnabModal}
                setIsOpenModal={setIsOpenGenerateCnabModal}
                onSuccess={handleRefreshList}
            />
        </>
    );
};

export default BatchesList;
