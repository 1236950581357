import { ScdBatchToRelocateResponse } from 'model/batch';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum CreateBatchToRelocateTypes {
    CREATE_BATCH_TO_RELOCATE_REQUEST = '@@create-batch-to-relocate/CREATE_BATCH_TO_RELOCATE_REQUEST',
    CREATE_BATCH_TO_RELOCATE_SUCCESS = '@@create-batch-to-relocate/CREATE_BATCH_TO_RELOCATE_SUCCESS',
    CREATE_BATCH_TO_RELOCATE_ERROR = '@@create-batch-to-relocate/CREATE_BATCH_TO_RELOCATE_ERROR',
    CREATE_BATCH_TO_RELOCATE_RESET = '@@create-batch-to-relocate/CREATE_BATCH_TO_RELOCATE_RESET',
}

export interface CreateBatchToRelocateState extends BaseStoreState {
    readonly createdBatchToRelocate?: ScdBatchToRelocateResponse;
}
