import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, any>;

export enum LegacyProcessPaymentTransactionsTypes {
    LEGACY_PROCESS_PAYMENT_TRANSACTIONS_REQUEST = '@@legacy-payment-transactions-process/LEGACY_PROCESS_PAYMENT_TRANSACTIONS_REQUEST',
    LEGACY_PROCESS_PAYMENT_TRANSACTIONS_SUCCESS = '@@legacy-payment-transactions-process/LEGACY_PROCESS_PAYMENT_TRANSACTIONS_SUCCESS',
    LEGACY_PROCESS_PAYMENT_TRANSACTIONS_ERROR = '@@legacy-payment-transactions-process/LEGACY_PROCESS_PAYMENT_TRANSACTIONS_ERROR',
    LEGACY_PROCESS_PAYMENT_TRANSACTIONS_RESET = '@@legacy-payment-transactions-process/LEGACY_PROCESS_PAYMENT_TRANSACTIONS_RESET',
}

export interface LegacyProcessPaymentTransactionsState extends BaseStoreState { }
