import clsx from 'clsx';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import EmptyCollectionAware from 'components/collection/empty-collection-aware';
import Loading from 'components/loading/loading';
import PaymentInstitutionAccountsFilterButtons from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-filter-buttons/payment-institutions-accounts-filter-buttons';
import PaymentInstitutionAccountsHeader from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-header/payment-institutions-accounts-header';
import PaymentInstitutionsAccountsList from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-list/payment-institutions-accounts-list';
import PaymentInstitutionAccountsModalFilter, {
    PaymentInstitutionAccountModalFilterType,
} from 'entities/payment-institutions-accounts/components/payment-institutions-accounts-modal-filter/payment-institutions-accounts-modal-filter';
import usePaymentInstitutionFilterChips from 'entities/payment-institutions-accounts/hooks/use-payment-institutions-filter-chips';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    defaultPaymentInstitutionsAccountsFilterRequest,
    PaymentInstitutionAccountFilterRequest,
    PaymentInstitutionAccountToGetAll,
} from 'model/payment-institution-account';
import { PaymentInstitutionAccountFilterPageable } from 'model/reducers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { usePaymentInstitutionsAccountsListState, useRootDispatch } from 'reducer/hooks';
import { externalBalancePaymentInstitutionsAccountsResetState } from 'reducer/payment-institutions-accounts/external-balance-payment-institution-account/actions';
import {
    listPaymentInstitutionsAccountsRequest,
    listPaymentInstitutionsAccountsResetState,
} from 'reducer/payment-institutions-accounts/list-payment-institution-accounts/actions';
import { LIST_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import Pagination from 'shared/pagination/pagination';
import './payment-institutions-accounts.scss';

const I18N_PREFIX = 'pages.scd.payment-institutions-accounts';

export type PaymentInstitutionsAccountsSortProperties = keyof PaymentInstitutionAccountToGetAll | 'createdDate';

const PaymentInstitutionsAccounts = () => {
    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<PaymentInstitutionsAccountsSortProperties>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
    const [filterActive, setFilterActive] = useState<PaymentInstitutionAccountFilterRequest>(
        defaultPaymentInstitutionsAccountsFilterRequest
    );
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false);
    const [modalFilter, setModalFilter] = useState<PaymentInstitutionAccountModalFilterType | undefined>(undefined);

    const { t } = useTranslation();

    const { paymentInstitutionsAccounts, status: listStatus } = usePaymentInstitutionsAccountsListState();

    const { isPaymentInstitutionChipsActive, paymentInstitutionFilterChips } = usePaymentInstitutionFilterChips({
        filterActive,
        setFilterActive,
        setPage,
    });

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();

    const handlePageChange = (value: { selected: number }) => {
        setPage(value.selected);
    };

    const filterPageable = useCallback((): PaymentInstitutionAccountFilterPageable => {
        const _pageable: Pageable = {
            page,
            size: LIST_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        const _filter: PaymentInstitutionAccountFilterRequest = filterActive ?? {};

        return {
            pageable: _pageable,
            filter: _filter,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handleListPaymentInstitutionsAccounts = useCallback(() => {
        dispatch(listPaymentInstitutionsAccountsRequest(filterPageable()));
    }, [filterPageable, dispatch]);

    const handleRefreshRequests = useCallback(() => {
        handleListPaymentInstitutionsAccounts();
        dispatch(externalBalancePaymentInstitutionsAccountsResetState());
    }, [handleListPaymentInstitutionsAccounts, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(listPaymentInstitutionsAccountsResetState());
            dispatch(externalBalancePaymentInstitutionsAccountsResetState());
        };
    }, [dispatch]);

    const handleSort = (property: PaymentInstitutionsAccountsSortProperties, order: SortOrder) => {
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    useEffect(() => {
        handleListPaymentInstitutionsAccounts();
    }, [handleListPaymentInstitutionsAccounts]);

    const handleResetFilterActive = () => {
        setPage(0);
        setFilterActive(defaultPaymentInstitutionsAccountsFilterRequest);
    };

    const isLoading: boolean = listStatus === HttpRequestStatus.ON_GOING;

    return (
        <main className="scd-payment-institutions-accounts">
            <section className="scd-payment-institutions-accounts__container">
                <header className="scd-payment-institutions-accounts__header">
                    <div className="scd-payment-institutions-accounts__header-container">
                        <h2 className="scd-payment-institutions-accounts__header--title">{t(`${I18N_PREFIX}.title`)}</h2>
                        <RefreshButton onClick={handleRefreshRequests} />
                        <div className="scd-payment-institutions-accounts__header-container--align-right">
                            <LinkButton label={t(`${I18N_PREFIX}.create-account`)} onClick={() => history.push(`${url}/new`)} />
                        </div>
                    </div>
                    <div className="scd-payment-institutions-accounts__header--buttons-content">
                        <PaymentInstitutionAccountsFilterButtons
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setIsOpenModalFilter={setIsOpenModalFilter}
                            setModalFilter={setModalFilter}
                        />
                    </div>
                </header>
                <ChipFilterActive filters={paymentInstitutionFilterChips} />

                <div className="scd-payment-institutions-accounts__table">
                    <table
                        className={clsx('page-container--table', {
                            CHIPS_ACTIVE: isPaymentInstitutionChipsActive,
                        })}
                    >
                        <thead>
                            <PaymentInstitutionAccountsHeader
                                paymentInstitutionsAccounts={paymentInstitutionsAccounts?.content ?? []}
                                handleSort={handleSort}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                            />
                        </thead>
                        <tbody>
                            {isLoading ? (
                                <Loading isTable />
                            ) : (
                                <EmptyCollectionAware
                                    collection={paymentInstitutionsAccounts?.content ?? []}
                                    label={{ key: `${I18N_PREFIX}.not-found` }}
                                    isTable
                                >
                                    <PaymentInstitutionsAccountsList
                                        paymentInstitutionsAccounts={paymentInstitutionsAccounts?.content ?? []}
                                    />
                                </EmptyCollectionAware>
                            )}
                        </tbody>
                    </table>
                </div>
                {!isLoading && (
                    <Pagination page={page} totalPages={paymentInstitutionsAccounts?.totalPages ?? 0} onChange={handlePageChange} />
                )}
                {modalFilter && (
                    <PaymentInstitutionAccountsModalFilter
                        filterActive={filterActive}
                        isOpenModalFilter={isOpenModalFilter}
                        modalFilter={modalFilter}
                        onClose={() => setIsOpenModalFilter(false)}
                        setFilterActive={setFilterActive}
                        setPage={setPage}
                        title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                    />
                )}
            </section>
        </main>
    );
};

export default PaymentInstitutionsAccounts;
