import { ValidationUtils, Validations } from 'shared/util/validation-utils';

interface WithdrawValueInterface {
    value: number | undefined
    maxValue: number | undefined
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.withdraw-credits.validations';

const WITHDRAW_BALANCE_VALUE_VALIDATION: Validations<WithdrawValueInterface | null | undefined> = {
    required: {
        predicate: it => ValidationUtils.NUMBER.isNotEmpty(it?.value),
        errorMessage: `${I18N_PREFIX}.required`
    },
    maxValue: {
        predicate: it => it?.value !== undefined && it?.maxValue !== undefined && it?.value <= it?.maxValue,
        errorMessage: `${I18N_PREFIX}.maxValue`
    }
}

export const validateWithdrawBalanceValue = (value: WithdrawValueInterface) => ValidationUtils.validate(value, WITHDRAW_BALANCE_VALUE_VALIDATION)

export const validateWithdrawBalance = (toValidate: WithdrawValueInterface): boolean => {
    if (!toValidate) return false

    return (
        validateWithdrawBalanceValue(toValidate).isValid
    )
}
