import UserCreateUpdate from 'entities/user/components/create-update/user-create-update';
import { RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from 'shared/routes/private-route';

import Users from 'entities/user/users';
import UserDetail from 'entities/user/user-detail/user-detail';

interface Matchparams {
    url: string;
}

const UserRoutes = ({ match }: RouteComponentProps<Matchparams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}/new`} component={UserCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:userId/edit`} component={UserCreateUpdate} />
        <PrivateRoute exact path={`${match.url}/:id`} component={UserDetail} />
        <PrivateRoute exact path={match.url} component={Users} />
    </Switch>
);

export default UserRoutes;
