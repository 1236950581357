import { UserSimple } from 'model/user';
import { BaseStoreState } from 'reducer/base-store-state';

export enum UserUpdateTypes {
    USER_UPDATE_REQUEST = '@@user-update/USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS = '@@user-update/USER_UPDATE_SUCCESS',
    USER_UPDATE_ERROR = '@@user-update/USER_UPDATE_ERROR',
    USER_UPDATE_RESET_STATE = '@@user-update/USER_UPDATE_RESET_STATE',
}

export interface UserUpdateState extends BaseStoreState {
    readonly userUpdate?: UserSimple;
}
