import { ChunkStreamProcessResponse } from 'model/bco/batch-output-cession-integration-gtsec';
import { BaseStoreState } from 'reducer/base-store-state';

export enum ChunkStreamProcessBcoTypes {
    CHUNK_STREAM_PROCESS_BCO_REQUEST = '@@chunk-stream-process-bco/CHUNK_STREAM_PROCESS_BCO_REQUEST',
    CHUNK_STREAM_PROCESS_BCO_SUCCESS = '@@chunk-stream-process-bco/CHUNK_STREAM_PROCESS_BCO_SUCCESS',
    CHUNK_STREAM_PROCESS_BCO_ERROR = '@@chunk-stream-process-bco/CHUNK_STREAM_PROCESS_BCO_ERROR',
    CHUNK_STREAM_PROCESS_BCO_RESET = '@@chunk-stream-process-bco/CHUNK_STREAM_PROCESS_BCO_RESET',
}

export interface ChunkStreamProcessBcoState extends BaseStoreState {
    readonly chunkStreamProcessBco?: ChunkStreamProcessResponse;
}
