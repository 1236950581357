import SortableColumn from 'components/listing/sortable-column/sortable-column';
import { useTranslation } from 'react-i18next';

const I18N_PREFIX = 'pages.scd.programs-output-policies.table';

const ProgramOutputPoliciesHeader = () => {
    const { t } = useTranslation();

    return (
        <>
            <tr className="page-container--table-head">
                <SortableColumn label={t(`${I18N_PREFIX}.name`)} />
                <SortableColumn label={t(`${I18N_PREFIX}.status`)} />
                <SortableColumn label={t(`${I18N_PREFIX}.type`)} />
                <SortableColumn label={t(`${I18N_PREFIX}.platformName`)} />
            </tr>
        </>
    );
};

export default ProgramOutputPoliciesHeader;
