import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DetailBatchWithProgramState, DetailBatchWithProgramTypes } from 'reducer/batch/batch-details-with-propgram/types';

const initialState: DetailBatchWithProgramState = {
    status: HttpRequestStatus.NOOP,
    batchWithProgram: undefined,
    error: undefined,
};

const reducer: Reducer<DetailBatchWithProgramState> = (state = initialState, action): DetailBatchWithProgramState => {
    switch (action.type) {
        case DetailBatchWithProgramTypes.DETAIL_BATCH_WITH_PROGRAM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case DetailBatchWithProgramTypes.DETAIL_BATCH_WITH_PROGRAM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, batchWithProgram: action.payload, error: undefined };
        }
        case DetailBatchWithProgramTypes.DETAIL_BATCH_WITH_PROGRAM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailBatchWithProgramTypes.DETAIL_BATCH_WITH_PROGRAM_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailBatchWithProgramReducer };
