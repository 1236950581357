import CurrencyFormatter from 'components/formatter/currency/currency-formatter';
import PercentageFormatterWithSixDecimals from 'components/formatter/percentage/percentage-formatter-six-decimals';
import { ScdImportedOperationDetail } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import './tax-operation-card.scss';

interface TaxOperationCardProps {
    operation: ScdImportedOperationDetail;
}

const I18N_PREFIX = 'pages.scd.ccb-operation-detail.card.tax';

const TaxOperationCard = (props: TaxOperationCardProps) => {
    const {
        operation: { tax },
    } = props;

    const { t } = useTranslation();

    return (
        <div className="scd-tax-operation-card">
            <h3 className="scd-tax-operation-card--title"> {t(`${I18N_PREFIX}.title`)} </h3>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.fareAmount`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={tax?.fareAmount} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.fee`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <PercentageFormatterWithSixDecimals value={tax?.fee} />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.loanYearlyTaxPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    {!_.isNil(tax?.loanYearlyTaxPercentage) ? (
                        <PercentageFormatterWithSixDecimals value={tax?.loanYearlyTaxPercentage} />
                    ) : (
                        '-'
                    )}
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.effectiveMonthlyCostPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    {!_.isNil(tax?.effectiveMonthlyCostPercentage) ? (
                        <PercentageFormatterWithSixDecimals value={tax?.effectiveMonthlyCostPercentage} />
                    ) : (
                        '-'
                    )}
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.effectiveYearlyCostPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    {!_.isNil(tax?.effectiveYearlyCostPercentage) ? (
                        <PercentageFormatterWithSixDecimals value={tax?.effectiveYearlyCostPercentage} />
                    ) : (
                        '-'
                    )}
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.tac`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={tax?.tac} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.iof`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <CurrencyFormatter value={tax?.iofValue} prefix />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.iofPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    <PercentageFormatterWithSixDecimals value={tax?.iofPercentage} />
                </span>
            </div>
            <div className="scd-tax-operation-card--item">
                <span className="scd-tax-operation-card--item__label"> {t(`${I18N_PREFIX}.fields.additionalIofPercentage`)} </span>
                <span className="scd-tax-operation-card--item__value">
                    {!_.isNil(tax?.additionalIofPercentage) ? (
                        <PercentageFormatterWithSixDecimals value={tax?.additionalIofPercentage} />
                    ) : (
                        '-'
                    )}
                </span>
            </div>
        </div>
    );
};

export default TaxOperationCard;
