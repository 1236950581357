import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import { Stream } from 'model/bco/batch-output-cession-integration-gtsec';
import { ExportRequestStatus, ExportRequestType } from 'model/bco/enums/batch-output-cession-integration-gtsec';
import { BatchExportType } from 'model/enums/batch-export-type';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

interface CessionIntegrationOptionsStreamProps {
    setSelectExportRequestType: (value: ExportRequestType) => void;
    setOpenModal: (value: boolean) => void;
    setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
    stream: Stream;
}

const I18N_PREFIX = 'pages.scd.batch-outputs.page-destination.cession-gtsec.with-partition.table.options-menu';

const CessionIntegrationOptionsStream = (props: CessionIntegrationOptionsStreamProps) => {
    const { t } = useTranslation();

    const { setSelectExportRequestType, setOpenModal, setAnchorEl, stream } = props;

    const handleOption = (type: ExportRequestType) => {
        setSelectExportRequestType(type);
        setOpenModal(true);
        setAnchorEl(null);
    };

    const hasInstallmentFailures = stream.exports.some(
        exportItem => exportItem.type === BatchExportType.STREAM_INSTALLMENTS && exportItem.status === ExportRequestStatus.ERROR
    );
    const hasOperationFailures = stream.exports.some(
        exportItem => exportItem.type === BatchExportType.STREAM_OPERATIONS && exportItem.status === ExportRequestStatus.ERROR
    );

    return (
        <>
            <ModalActionMenuItem
                titleKey={t(`${I18N_PREFIX}.installments`)}
                onClick={() => handleOption(ExportRequestType.STREAM_INSTALLMENTS)}
                show={hasInstallmentFailures}
            />
            <ModalActionMenuItem
                titleKey={t(`${I18N_PREFIX}.operations`)}
                onClick={() => handleOption(ExportRequestType.STREAM_OPERATIONS)}
                show={hasOperationFailures}
            />
        </>
    );
};

export default CessionIntegrationOptionsStream;
