import { ReadOnlyCard } from 'components/card/read-only-card/read-only-card';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import { PaymentTransactionFailureToGetFailureCategorization } from 'model/transaction-failure-categorization';
import { useTranslation } from 'react-i18next';

interface TransactionFailureCategorizationCardProps {
    transactionFailureCategorization?: PaymentTransactionFailureToGetFailureCategorization;
}

const I18N_PREFIX = 'pages.scd.payment-transaction.detail';

const TransactionFailureCategorizationCard = (props: TransactionFailureCategorizationCardProps) => {
    const { transactionFailureCategorization } = props;

    const { t } = useTranslation();

    if (!transactionFailureCategorization) {
        return <></>;
    }

    return (
        <ReadOnlyCard name={t(`${I18N_PREFIX}.inputs.transactionFailureCategorization.label`)}>
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.transactionFailureCategorization.type.label`}
                value={t(`${I18N_PREFIX}.inputs.transactionFailureCategorization.type.options.${transactionFailureCategorization?.type}`)}
            />
            <ReadOnlyTextField
                label={`${I18N_PREFIX}.inputs.transactionFailureCategorization.flow.label`}
                value={t(`${I18N_PREFIX}.inputs.transactionFailureCategorization.flow.options.${transactionFailureCategorization?.flow}`)}
            />
        </ReadOnlyCard>
    );
};

export default TransactionFailureCategorizationCard;
