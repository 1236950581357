import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Tooltip } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { useTranslation } from 'react-i18next';
import { CompletePaymentTransactionFilterData } from 'model/payment-transaction';
import { CompletePaymentTransactionFilterType } from 'entities/payment-transaction/complete-payment-transaction/components/complete-payment-transactions-modal-filter/complete-payment-transactions-modal-filter';
import { valuesOfObject } from 'shared/util/object-utils';

const I18N_PREFIX = 'pages.scd.complete-payment-transactions.filter.buttons';

interface CompletePaymentTransactionFilterButtonsProps {
    filterActive: CompletePaymentTransactionFilterData;
    handleResetFilterActive: () => void;
    setModalFilter: (value: CompletePaymentTransactionFilterType | undefined) => void;
    setOpenModalFilter: (value: boolean) => void;
}

const CompletePaymentTransactionFilterButtons = (props: CompletePaymentTransactionFilterButtonsProps) => {
    const { filterActive, handleResetFilterActive, setModalFilter, setOpenModalFilter } = props;

    const { t } = useTranslation();

    const arrowFilterIcon = (
        <React.Fragment>
            <ArrowDropDownIcon />
        </React.Fragment>
    );
    const showRemoveAllIcon = valuesOfObject({ ...filterActive }).some(item => item !== undefined);

    return (
        <>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('filters');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={
                        valuesOfObject({ ...filterActive }).length !== 0 &&
                        valuesOfObject({ ...filterActive }).some(value => value !== undefined)
                    }
                />
            </div>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('date');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.date`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.date ? true : false}
                />
            </div>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('amount');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.amount`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.amount ? true : false}
                />
            </div>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('favored');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.favored`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.favored ? true : false}
                />
            </div>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('originator');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.originator`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.originator ? true : false}
                />
            </div>
            <div className="scd-complete-payment-transactions__header--buttons-content-filter-button">
                <FilterButton
                    onClick={() => {
                        setModalFilter('mode');
                        setOpenModalFilter(true);
                    }}
                    label={t(`${I18N_PREFIX}.creation-mode`)}
                    icon={arrowFilterIcon}
                    isActiveFilter={filterActive.mode ? true : false}
                />
            </div>
            {showRemoveAllIcon ? (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-payment-transaction__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            ) : (
                <></>
            )}
        </>
    );
};

export default CompletePaymentTransactionFilterButtons;
