import { AxiosResponse } from 'axios';
import { BatchSignatureReExportResponse } from 'model/batch-signature';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { batchSignatureReExportError, batchSignatureReExportRequest, batchSignatureReExportSuccess } from 'reducer/batch/batch-signature-re-export/action';
import { BatchSignatureReExportTypes } from 'reducer/batch/batch-signature-re-export/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchSignatureApi from 'services/api/batch-signature-api';
import ErrorUtils from 'shared/error/error-utils';


function* handleBatchSignatureReExport(action: ReturnType<typeof batchSignatureReExportRequest>) {
    try {
        const result: AxiosResponse<BatchSignatureReExportResponse> = yield call(batchSignatureApi.reExportSignatures, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(batchSignatureReExportError(mapped));
            return;
        }
        yield put(batchSignatureReExportSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(batchSignatureReExportError(mapped));
    }
}

function* watchBatchSignatureReExport() {
    yield takeLeading(BatchSignatureReExportTypes.BATCH_SIGNATURE_RE_EXPORT_REQUEST, handleBatchSignatureReExport);
}

function* batchSignatureReExportSaga() {
    yield all([fork(watchBatchSignatureReExport)]);
}

export default batchSignatureReExportSaga;
