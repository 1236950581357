import { GiroWebappError } from 'model/error';
import { PaymentTransactionManualDisbursementValidation, } from 'model/payment-transaction';
import { ValidateManualDisbursementPaymentTransactionTypes } from 'reducer/payment-transaction/validate-manual-disbursement-payment-transaction/types';
import { action } from 'typesafe-actions';

export const validateManualDisbursementPaymentTransactionRequest = (paymentTransactionId: string) =>
    action(ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_REQUEST, paymentTransactionId);
export const validateManualDisbursementPaymentTransactionSuccess = (validation: PaymentTransactionManualDisbursementValidation) =>
    action(ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_SUCCESS, validation);
export const validateManualDisbursementPaymentTransactionError = (error: GiroWebappError) =>
    action(ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_ERROR, error);
export const validateManualDisbursementPaymentTransactionResetState = () =>
    action(ValidateManualDisbursementPaymentTransactionTypes.VALIDATE_MANUAL_DISBURSEMENT_PAYMENT_TRANSACTION_RESET);
