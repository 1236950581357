import { AxiosResponse } from 'axios';
import { BatchOutputHemera, BatchDocumentSimple, BatchDocumentProcessRequest, BatchDocumentExportRequest, BatchExportDetailRequest, BatchExport } from 'model/batch-output-hemera';
import { BASE_URL_ADMIN } from 'services/page'
import { api } from 'services/api/api';
import { BatchOutputEndorsementToResume } from 'model/batch-output-endorsement';

const batchOutputApi = () => {

    const outputHemera = (batchId: number): Promise<AxiosResponse<BatchOutputHemera>> => {
        return api.get<BatchOutputHemera>(`${BASE_URL_ADMIN}/provisional/batches/${batchId}/outputs/hemera`)
    }

    const processDocument = (request: BatchDocumentProcessRequest): Promise<AxiosResponse<BatchDocumentSimple>> => {
        return api.post<BatchDocumentSimple>(`${BASE_URL_ADMIN}/batches/${request.batchId}/outputs/${request.outputId}/documents/process`)
    }

    const exportDocument = (request: BatchDocumentExportRequest): Promise<AxiosResponse<BatchDocumentSimple>> => {
        return api.post<BatchDocumentSimple>(`${BASE_URL_ADMIN}/batches/${request.batchId}/documents/${request.documentId}/export`, {}, { timeout: 0 })
    }

    const outputEndorsement = (batchId: number): Promise<AxiosResponse<BatchOutputEndorsementToResume>> => {
        return api.get<BatchOutputEndorsementToResume>(`${BASE_URL_ADMIN}/provisional/batches/${batchId}/outputs/endorsement`);
    }

    const exportDetail = (request: BatchExportDetailRequest): Promise<AxiosResponse<BatchExport>> => {
        return api.get<BatchExport>(`${BASE_URL_ADMIN}/batches/${request.batchId}/exports/${request.exportId}`);
    }

    return {
        outputHemera,
        processDocument,
        exportDocument,
        outputEndorsement,
        exportDetail,
    }
}

export default batchOutputApi();