import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { CreateBankerTypes, CreateBankerState } from './types';

const initialState: CreateBankerState = {
    status: HttpRequestStatus.NOOP,
    banker: undefined,
    error: undefined,
};

const reducer: Reducer<CreateBankerState> = (state = initialState, action): CreateBankerState => {
    switch (action.type) {
        case CreateBankerTypes.CREATE_BANKER_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CreateBankerTypes.CREATE_BANKER_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, banker: action.payload, error: undefined };
        }
        case CreateBankerTypes.CREATE_BANKER_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CreateBankerTypes.CREATE_BANKER_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as createBankerReducer };
