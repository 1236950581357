import { AxiosResponse } from 'axios';
import { HemeraAuthorizationForSendBackingResponse } from 'features/limelight/send-backings/domain/models';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import ErrorUtils from 'shared/error/error-utils';
import provisionalLimelightApi from 'services/api/provisional-limeliight'
import { limelightAuthorizationSendBackingError, limelightAuthorizationSendBackingRequest, limelightAuthorizationSendBackingSuccess } from 'reducer/provisional-limelight/provisional-limelight-send/action';
import { ProvisionalLimelightSendBackingTypes } from 'reducer/provisional-limelight/provisional-limelight-send/types';

function* handleLimelightAuthorizationSendBackings(action: ReturnType<typeof limelightAuthorizationSendBackingRequest>) {
    try {
        const result: AxiosResponse<HemeraAuthorizationForSendBackingResponse> = yield call(provisionalLimelightApi.getAuthorizationForSendBackings, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(limelightAuthorizationSendBackingError(mapped));
            return;
        }
        yield put(limelightAuthorizationSendBackingSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(limelightAuthorizationSendBackingError(mapped));
    }
}

function* watchLimelightAuthorizationSendBackings() {
    yield takeEvery(ProvisionalLimelightSendBackingTypes.LIMELIGHT_AUTHORIZATION_SEND_BACKING_REQUEST, handleLimelightAuthorizationSendBackings);
}

function* limelightAuthorizationSendBackingsSaga() {
    yield all([fork(watchLimelightAuthorizationSendBackings)]);
}

export default limelightAuthorizationSendBackingsSaga;