import { ChipFilterItem } from 'components/chip-filter-active/chip-filter-active';
import ChipRenderItemComponent from 'components/chip-filter-active/chip-filter-render-item-component';
import DateFormatter from 'components/formatter/date/date-formatter';
import FormatterUtils from 'components/formatter/formatter-utils';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TransfeeraExternalStatementFilterRequest } from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-external-statement.model';
import { defaultTransfeeraExternalTransfersFilter } from 'shared/external/payment-accounts-strategy/transfers/components/transfeera-transfers/transfeera-external-transfers.model';
import ExternalStrategyDateUtils from 'shared/external/util/external-date-utils';
import { valuesOfObject } from 'shared/util/object-utils';

interface UseTransfeeraStatementFilterChipsProps {
    filterActive: TransfeeraExternalStatementFilterRequest;
    setFilterActive: (value: TransfeeraExternalStatementFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'payment-accounts-strategy.transfeera.external-statement.filters.inputs';

type TransfeeraStatementFilterRequestType = keyof TransfeeraExternalStatementFilterRequest;

const useTransfeeraStatementFilterChips = (props: UseTransfeeraStatementFilterChipsProps) => {
    const { filterActive, setPage, setFilterActive } = props;

    const { t } = useTranslation();

    const clearFilterSupport = useCallback(
        (value: TransfeeraExternalStatementFilterRequest) => {
            setFilterActive(value);
            setPage(0);
        },
        [setPage, setFilterActive]
    );

    const handleClearFilter: Record<TransfeeraStatementFilterRequestType, () => void> = useMemo(() => {
        return {
            initialDate: () => clearFilterSupport({ ...filterActive, initialDate: ExternalStrategyDateUtils.getFirstDayOfMonth() }),
            endDate: () => clearFilterSupport({ ...filterActive, endDate: ExternalStrategyDateUtils.getTodayDay() }),
        };
    }, [filterActive, clearFilterSupport]);

    const chipTitle = useCallback(
        (title: TransfeeraStatementFilterRequestType | string): string => {
            const titleTranslation: string = t(`${I18N_PREFIX}.${title}`);
            return `${titleTranslation}: `;
        },
        [t]
    );

    const transfeeraStatementFilterChips = useMemo(() => {
        const items: Record<TransfeeraStatementFilterRequestType, ChipFilterItem> = {
            initialDate: {
                isActive:
                    FormatterUtils.isValidDate(filterActive.initialDate) &&
                    !_.isEqual(filterActive.initialDate, defaultTransfeeraExternalTransfersFilter.initialDate),
                onDelete: handleClearFilter['initialDate'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('initialDate.label')}
                        component={<DateFormatter date={filterActive.initialDate} />}
                    />
                ),
            },
            endDate: {
                isActive:
                    FormatterUtils.isValidDate(filterActive.endDate) &&
                    !_.isEqual(filterActive.endDate, defaultTransfeeraExternalTransfersFilter.endDate),
                onDelete: handleClearFilter['endDate'],
                renderItem: (
                    <ChipRenderItemComponent
                        chipTitle={chipTitle('endDate.label')}
                        component={<DateFormatter date={filterActive.endDate} />}
                    />
                ),
            },
        };

        return valuesOfObject(items);
    }, [filterActive, handleClearFilter, chipTitle]);

    const isTransfeeraStatementChipsActive: boolean = useMemo(() => {
        return transfeeraStatementFilterChips.some(it => !!it.isActive);
    }, [transfeeraStatementFilterChips]);

    return {
        transfeeraStatementFilterChips,
        isTransfeeraStatementChipsActive,
    };
};

export default useTransfeeraStatementFilterChips;
