import { SearchPlatform } from 'model/platform';
import { BaseStoreState } from 'reducer/base-store-state';
import { Page } from 'services/page';

export type ApiResponse = Record<string, any>;

export enum SearchPlatformsTypes {
    SEARCH_PLATFORM_REQUEST = '@@platform-list/SEARCH_PLATFORM_REQUEST',
    SEARCH_PLATFORM_SUCCESS = '@@platform-list/SEARCH_PLATFORM_SUCCESS',
    SEARCH_PLATFORM_ERROR = '@@platform-list/SEARCH_PLATFORM_ERROR',
    SEARCH_PLATFORM_RESET = '@@platform-list/SEARCH_PLATFORM_RESET',
}

export interface SearchPlatformsState extends BaseStoreState {
    readonly platforms?: Page<SearchPlatform>;
}