import { Reducer } from 'redux';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UserByResetKeyActionTypes, UserByResetKeyState } from 'reducer/user/reset-key/types';

export const initialState: UserByResetKeyState = {
    status: HttpRequestStatus.NOOP,
    user: undefined,
    error: undefined,
};

const reducer: Reducer<UserByResetKeyState> = (state = initialState, action): UserByResetKeyState => {
    switch (action.type) {
        case UserByResetKeyActionTypes.USER_BY_RESET_KEY_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, user: undefined, error: undefined };
        }
        case UserByResetKeyActionTypes.USER_BY_RESET_KEY_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, user: action.payload, error: undefined };
        }
        case UserByResetKeyActionTypes.USER_BY_RESET_KEY_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case UserByResetKeyActionTypes.USER_BY_RESET_KEY_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as userByResetKeyReducer };
