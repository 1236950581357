import { RouteComponentProps, Switch } from 'react-router-dom';

import ScdBankPage from 'entities/bank/bank';
import PrivateRoute from 'shared/routes/private-route';

interface MatchParams {
    url: string;
}

const BankRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdBankPage} />
    </Switch>
);

export default BankRoutes;
