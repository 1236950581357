import VersionedComponent from 'components/versioned-component';
import H from 'history';
import { Authority } from 'model/enums/authority';
import { GiroWebappErrorConstants } from 'model/enums/error-constants';
import { HeaderType } from 'model/enums/header-type';
import { HttpRequestStatus } from 'model/enums/http-request-status';

import { ComponentType, useEffect } from 'react';
import { Route, RouteComponentProps, RouteProps, useHistory } from 'react-router-dom';
import { userAccountResetState } from 'reducer/account/user-account/actions';
import { markError } from 'reducer/application/error/actions';
import { loginResetState } from 'reducer/authentication/actions';
import { useAuthenticationState, useRootDispatch, useUserAccountState } from 'reducer/hooks';

import AuthUtil from 'services/api/auth-util';
import Footer, { FooterType } from 'shared/footer/footer';
import HeaderLegacy from 'shared/header-legacy/header-legacy';
import Header from 'shared/header/header';
import VersionSwitchComponent from '../version-switch-component/version-switch-component';

interface IPrivateRouteProps extends Omit<RouteProps, 'component'> {
    component: ComponentType<RouteComponentProps<any>> | ComponentType<any>;
    headerType?: HeaderType;
    footerType?: FooterType;
}

export const PrivateRoute = (props: IPrivateRouteProps) => {
    const { component: Component, footerType, ...rest } = props;
    const userAccountState = useUserAccountState();
    const dispatch = useRootDispatch();

    const isValidAccount = userAccountState.account?.activated && userAccountState.account?.authorities?.includes(Authority.ROLE_ADMIN);

    const history: H.History = useHistory();
    const authenticationState = useAuthenticationState();
    const hasToken = AuthUtil.isAuthenticated();
    useEffect(() => {
        if (!hasToken) {
            setTimeout(() => {
                dispatch(markError({ message: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
            }, 1000);
            history.push('/');
        }
    }, [history, authenticationState, hasToken]);

    useEffect(() => {
        if (userAccountState.status !== HttpRequestStatus.SUCCESS) return;
        if (isValidAccount) return;
        AuthUtil.removeToken();
        dispatch(loginResetState());
        dispatch(userAccountResetState());
        setTimeout(() => {
            dispatch(markError({ message: GiroWebappErrorConstants.HTTP_FORBIDDEN }));
        }, 1000);
        history.push('/');
    }, [userAccountState, dispatch, history]);

    const header = props.headerType ?? HeaderType.ADMIN;

    return (
        <div className="page__container">
            <Route
                {...rest}
                render={routeProps => (
                    <>
                        {/* TODO: AB#546 */}
                        <VersionedComponent
                            development={
                                <VersionSwitchComponent
                                    legacyComponent={<HeaderLegacy type={header} />}
                                    newComponent={<Header type={header} />}
                                    queryParamForLegacy={'legacy'}
                                />
                            }
                            production={<Header type={header} />}
                            staging={
                                <VersionSwitchComponent
                                    legacyComponent={<HeaderLegacy type={header} />}
                                    newComponent={<Header type={header} />}
                                    queryParamForLegacy={'legacy'}
                                />
                            }
                        />
                        {isValidAccount && (
                            <div className="page__content">
                                <Component {...routeProps} />
                            </div>
                        )}
                        <Footer type={footerType ?? FooterType.DEFAULT} />
                    </>
                )}
            />
        </div>
    );
};

export default PrivateRoute;
