import ModalActionMenuItem from 'components/more-options-menu/options-menu-items/modal-action-menu-item';
import RedirectMenuItem from 'components/more-options-menu/options-menu-items/redirect-menu-item';
import { BatchModalActionOptions } from 'entities/batch/components/modal-action/modal-action';
import { BatchStatus } from 'model/enums/batch-status';
import { OperationStatus } from 'model/enums/operation-status';
import { ScdImportedOperationBatch } from 'model/imported-operation';

interface BatchDetailMoreOptionsMenuItemsProps {
    handleCloseMenu: () => void;
    operation: ScdImportedOperationBatch | undefined;
    operationStatus: OperationStatus | undefined;
    setModalAction: (value: BatchModalActionOptions) => void;
    isAutomaticPaymentDirectFavoredOperation: boolean;
    handleDeleteOperationModal: () => void;
    setIsOpenRelocateModal: (value: boolean) => void;
    batchStatus: BatchStatus;
}

const I18N_PREFIX = 'pages.scd.batch.menu';

const BatchDetailMoreOptionsMenuItems = (props: BatchDetailMoreOptionsMenuItemsProps) => {
    const {
        handleCloseMenu,
        operation,
        operationStatus,
        setModalAction,
        isAutomaticPaymentDirectFavoredOperation,
        handleDeleteOperationModal,
        setIsOpenRelocateModal,
        batchStatus,
    } = props;

    const handleModalMessageAction = (action: () => void) => () => {
        handleCloseMenu();
        action();
    };

    return (
        <>
            <RedirectMenuItem titleKey={`${I18N_PREFIX}.show-detail`} link={`/ccb-operations/${operation?.id}`} />
            <ModalActionMenuItem
                show={operationStatus === OperationStatus.EXPORTED}
                titleKey={`${I18N_PREFIX}.reexport-webcred`}
                onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.REEXPORT_WEBCRED))}
            />
            {
                (
                    {
                        OPEN: (
                            <>
                                <ModalActionMenuItem onClick={handleDeleteOperationModal} titleKey={`${I18N_PREFIX}.delete-operation`} />
                                <ModalActionMenuItem
                                    show={isAutomaticPaymentDirectFavoredOperation}
                                    onClick={handleModalMessageAction(() => setIsOpenRelocateModal(true))}
                                    titleKey={`${I18N_PREFIX}.relocate-operation`}
                                />
                                <ModalActionMenuItem
                                    show={isAutomaticPaymentDirectFavoredOperation}
                                    onClick={handleModalMessageAction(() => setModalAction(BatchModalActionOptions.ARCHIVE_OPERATION))}
                                    titleKey={`${I18N_PREFIX}.archive-operation`}
                                />
                            </>
                        ),
                    } as Record<BatchStatus, React.ReactNode>
                )[batchStatus]
            }
        </>
    );
};

export default BatchDetailMoreOptionsMenuItems;
