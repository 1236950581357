import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { PaymentInstitutionAccountSimpleToUpdate } from 'model/payment-institution-account';
import { markError } from 'reducer/application/error/actions';
import { updatePaymentInstitutionAccountRequest, updatePaymentInstitutionAccountSuccess, updatePaymentInstitutionAccountError } from 'reducer/payment-institutions-accounts/update-payment-institution-account/actions';
import { UpdatePaymentInstitutionAccountTypes } from 'reducer/payment-institutions-accounts/update-payment-institution-account/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import paymentInstitutionsAccountsApi from 'services/api/payment-institution-account-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleUpdatePaymentInstitutionAccount(action: ReturnType<typeof updatePaymentInstitutionAccountRequest>) {
    try {
        const result: AxiosResponse<PaymentInstitutionAccountSimpleToUpdate> = yield call(
            paymentInstitutionsAccountsApi.updatePaymentInstitutionAccount,
            action.payload
        );
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(updatePaymentInstitutionAccountError(mapped));
            return;
        }
        yield put(updatePaymentInstitutionAccountSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(updatePaymentInstitutionAccountError(mapped));
    }
}

function* watchUpdatePaymentInstitutionAccount() {
    yield takeLeading(UpdatePaymentInstitutionAccountTypes.UPDATE_PAYMENT_INSTITUTION_ACCOUNT_REQUEST, handleUpdatePaymentInstitutionAccount);
}

function* updatePaymentInstitutionAccountSaga() {
    yield all([fork(watchUpdatePaymentInstitutionAccount)]);
}

export default updatePaymentInstitutionAccountSaga;
