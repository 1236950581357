import { HttpRequestStatus } from 'model/enums/http-request-status';
import { DetailProgramState, DetailProgramTypes } from 'reducer/program/detail-program/types';
import { Reducer } from 'redux';

export const initialState: DetailProgramState = {
    status: HttpRequestStatus.NOOP,
    program: undefined,
    error: undefined,
};

const reducer: Reducer<DetailProgramState> = (state = initialState, action): DetailProgramState => {
    switch (action.type) {
        case DetailProgramTypes.DETAIL_PROGRAM_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case DetailProgramTypes.DETAIL_PROGRAM_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, program: action.payload, error: undefined };
        }
        case DetailProgramTypes.DETAIL_PROGRAM_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case DetailProgramTypes.DETAIL_PROGRAM_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as detailProgramReducer };
