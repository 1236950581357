import DateFormatter from 'components/formatter/date/date-formatter';
import fromExponential from 'from-exponential';
import { ScdTaxList } from 'model/tax';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

export interface TaxRowProps {
    tax: ScdTaxList[];
}

const I18N_PREFIX = 'pages.scd.tax';

const TaxRow = (props: ListChildComponentProps<TaxRowProps>) => {
    const { t } = useTranslation();
    const { index, data, style } = props;

    const { tax } = data;

    const { id, date, type, value } = tax[index];

    return (
        <tr className="page-container--table-cell" key={id} style={style}>
            <td className="page-container--table-cell-title">
                <DateFormatter date={date} />
            </td>
            <td className="page-container--table-cell-title">{`${fromExponential(value)}%`}</td>
            <td className="page-container--table-cell-title">{t(`${I18N_PREFIX}.input.type.options.${type}`)}</td>
        </tr>
    );
};

export default TaxRow;
