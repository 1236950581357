import { ProgramOutputPoliciesToGetAll } from 'model/program-output-policy';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum ListProgramOutputPoliciesTypes {
    LIST_PROGRAM_OUTPUT_POLICIES_REQUEST = '@@program-output-policies-list/LIST_PROGRAM_OUTPUT_POLICIES_REQUEST',
    LIST_PROGRAM_OUTPUT_POLICIES_SUCCESS = '@@program-output-policies-list/LIST_PROGRAM_OUTPUT_POLICIES_SUCCESS',
    LIST_PROGRAM_OUTPUT_POLICIES_ERROR = '@@program-output-policies-list/LIST_PROGRAM_OUTPUT_POLICIES_ERROR',
    LIST_PROGRAM_OUTPUT_POLICIES_RESET_STATE = '@@program-output-policies-list/LIST_PROGRAM_OUTPUT_POLICIES_RESET_STATE',
}

export interface ListProgramOutputPoliciesState extends BaseStoreState {
    readonly programOutputPolicies?: ProgramOutputPoliciesToGetAll[];

}