import { InputBaseComponentProps } from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const MONTH_MASK = (value: string) => {
    if (value.startsWith('0')) {
        return [/[0]/, /[1-9]/];
    } else if (value.startsWith('1')) {
        return [/[1]/, /[0-2]/];
    }

    return [/[01]/, /\d/];
};

export const MonthMask: React.ElementType<InputBaseComponentProps> = props => {
    const { inputRef, ...otherProps } = props;

    return <MaskedInput mask={MONTH_MASK} guide={false} {...otherProps} />;
};
