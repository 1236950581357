import { Tooltip } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import FilterButton from 'components/buttons/filter-button/filter-button';
import { CompanyGroupModalFilterType } from 'entities/company-group/components/company-group-modal-filter/company-group-modal-filter';
import { CompanyGroupFilterRequest } from 'model/company-group';
import { useTranslation } from 'react-i18next';
import { valuesOfObject } from 'shared/util/object-utils';

interface CompanyGroupFiltersButtonsProps {
    filterActive: CompanyGroupFilterRequest;
    setIsOpenModalFilter: (value: boolean) => void;
    setModalFilter: (value: CompanyGroupModalFilterType | undefined) => void;
    handleResetFilterActive: () => void;
}

const I18N_PREFIX = 'pages.scd.company-group.filter';

const CompanyGroupFiltersButtons = (props: CompanyGroupFiltersButtonsProps) => {
    const { t } = useTranslation();

    const { filterActive, handleResetFilterActive, setIsOpenModalFilter, setModalFilter } = props;

    const ArrowFilterIcon = <ArrowDropDownIcon />;

    const isFilterActive: boolean = valuesOfObject({ ...filterActive }).some(it => it !== undefined);

    const handleModalFilter = (value: CompanyGroupModalFilterType) => () => {
        setIsOpenModalFilter(true);
        setModalFilter(value);
    };

    return (
        <>
            <div className="scd-company-group__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.filters`)}
                    icon={<FilterListIcon />}
                    isActiveFilter={isFilterActive}
                    onClick={handleModalFilter('filters')}
                />
            </div>
            <div className="scd-company-group__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.groupName`)}
                    icon={<ArrowDropDownIcon />}
                    isActiveFilter={!!filterActive.groupName}
                    onClick={handleModalFilter('groupName')}
                />
            </div>
            <div className="scd-company-group__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.organizationId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.organizationId}
                    onClick={handleModalFilter('organizationId')}
                />
            </div>
            <div className="scd-company-group__header--buttons-content-filter-button">
                <FilterButton
                    label={t(`${I18N_PREFIX}.buttons.companyId`)}
                    icon={ArrowFilterIcon}
                    isActiveFilter={!!filterActive.companyId}
                    onClick={handleModalFilter('companyId')}
                />
            </div>

            {isFilterActive && (
                <Tooltip title={String(t('global.remove-filter.title'))}>
                    <div className="scd-company-group__header--buttons-content-error-arrow" onClick={handleResetFilterActive} />
                </Tooltip>
            )}
        </>
    );
};

export default CompanyGroupFiltersButtons;
