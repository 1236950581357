import ClosedMenuItem from 'shared/header/sidebar/componentes/closed-menu-item/closed-menu-item';
import { EntitiesData } from 'shared/header/sidebar/data/entities';
import './closed-drawer.scss';
import { FC } from 'react';

interface ClosedDrawerProps {
    closedDrawer?: boolean;
    setSidebarIsOpen: (value: boolean) => void;

}

export const ClosedDrawer: FC<ClosedDrawerProps> = ({ closedDrawer, setSidebarIsOpen }) => {
    return closedDrawer ? (
        <button className="closed-drawer" onClick={() => setSidebarIsOpen(true)}>
            <div className="closed-drawer--closed-header">
                <div className="closed-drawer--closed-header--logo_box">
                    <img src="https://giro-logos.s3.amazonaws.com/giro/giro_ico_512.png" alt="logo" />
                </div>
            </div>
            <div className="closed-drawer--icon-box">
                <ClosedMenuItem menuList={EntitiesData} />
            </div>
        </button>
    ) : null;
};
export default ClosedDrawer;
