import BackButton from 'components/buttons/back-button/back-button';
import FilterButton from 'components/buttons/filter-button/filter-button';
import LinkButton from 'components/buttons/link-button/link-button';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ReadOnlyTextField from 'components/inputs/readonly-text-field/readonly-text-field';
import ModalFilter from 'components/modals/modal-filter/modal-filter';
import ScdCompanyItemHeader, {
    CompanyItemsSortable,
} from 'entities/company-group/company-item/components/company-item-header/company-item-header';
import CompanyItemList from 'entities/company-group/company-item/components/company-item-list/company-item-list';
import { ScdCompanyItemSearch } from 'model/company-item';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchRequestCompanyItem } from 'model/reducers';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { searchCompanyItemsRequest } from 'reducer/company-item/search-company-items/actions';
import { useRootDispatch } from 'reducer/hooks';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './company-item.scss';

type ScdCompanyItemProps = RouteComponentProps<{ companyGroupId: string }>;

const I18N_PREFIX = 'pages.scd.company-item';

const ScdCompanyItemPage = (props: ScdCompanyItemProps) => {
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const history = useHistory();
    const [page, setPage] = useState<number>(0);
    const dispatch = useRootDispatch();
    const [sortedProperty, setSortedProperty] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOrder>(undefined);
    const [modalFilter, setModalFilter] = useState<boolean>(false);
    const [companyItemFilter, setCompanyItemFilter] = useState<string>('');
    const [companyGroupId] = useState<string>(props.match.params.companyGroupId);

    const searchPageable = useCallback((): SearchRequestCompanyItem => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };
        const request: SearchRequestCompanyItem = {
            search: companyItemFilter,
            pageable: _pageable,
            companyGroupId: companyGroupId,
        };

        return request;
    }, [page, sortedProperty, sortOrder, companyGroupId, companyItemFilter]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const {
        InfiniteScrollButton,
        items,
        resetInfiniteScrollListing,
        isLoading,
        status: listStatus,
    } = useInfiniteScrollLegacy<ScdCompanyItemSearch>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.searchCompanyItems.companyItems,
        statusSelector: (state: IRootState) => state.searchCompanyItems.status,
        action: searchCompanyItemsRequest,
        filterPageable: searchPageable,
        setPage,
    });
    const hasError = listStatus === HttpRequestStatus.ERROR;

    const handleRefresh = () => {
        dispatch(searchCompanyItemsRequest(searchPageable()));
    };

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.company-item.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }

    const handleSort = (property: CompanyItemsSortable, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty(undefined);
            setSortOrder(undefined);
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const onSelectFilter = (value: string) => {
        if (value === '') {
            dispatch(searchCompanyItemsRequest(searchPageable()));
        }

        setSortedProperty(undefined);
        setSortOrder(undefined);
        setCompanyItemFilter(value);
    };

    return (
        <main className="scd-company-item">
            <ContextRibbon action={() => history.push('/company-groups')} />
            <section className="scd-company-item__container">
                <header className="scd-company-item__header">
                    <h2 className="scd-company-item__header--title"> {t(`${I18N_PREFIX}.title`)} </h2>
                    <div className="scd-company-item__header--buttons">
                        <FilterButton onClick={() => setModalFilter(true)} />
                        <div style={{ marginLeft: '20px' }}>
                            <LinkButton label={t(`${I18N_PREFIX}.create.title`)} onClick={() => history.push(`${url}/new`)} />
                        </div>
                    </div>
                </header>
                {companyItemFilter !== '' && (
                    <div className="page-container--header-filter">
                        <div className="filter">
                            <ReadOnlyTextField label={t(`${I18N_PREFIX}.subtitle`)} value={companyItemFilter} />
                        </div>
                    </div>
                )}
                <div className="scd-company-item__table">
                    <table className="page-container--table">
                        <thead>
                            <ScdCompanyItemHeader
                                companyItems={items}
                                sortOrder={sortOrder}
                                sortedProperty={sortedProperty}
                                handleSort={handleSort}
                            />
                        </thead>
                        <tbody>
                            <CompanyItemList companyItems={items} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalFilter && (
                <ModalFilter
                    action={searchCompanyItemsRequest}
                    title={t(`${I18N_PREFIX}.modal.filter.title`)}
                    placeholder={t(`${I18N_PREFIX}.modal.filter.placeholder`)}
                    label={t(`${I18N_PREFIX}.modal.filter.label`)}
                    onClose={() => setModalFilter(false)}
                    onSelect={value => onSelectFilter(value)}
                    filterValue={companyItemFilter}
                    requestParameters={{ companyGroupId }}
                />
            )}
        </main>
    );
};

export default ScdCompanyItemPage;
