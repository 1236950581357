import { HttpRequestStatus } from 'model/enums/http-request-status';
import { PaymentInstitutionReference } from 'model/enums/payment-institution-reference';
import { ReactNode } from 'react';
import { useResumePaymentInstitutionAccountState } from 'reducer/hooks';
import CelcoinStatement from 'shared/external/payment-accounts-strategy/statement/components/celcoin/celcoin-statement';
import StatementFallback from 'shared/external/payment-accounts-strategy/statement/components/fallback/statement-fallback';
import TransfeeraStatement from 'shared/external/payment-accounts-strategy/statement/components/transfeera/transfeera-statement';
import { ExternalStatementProps } from 'shared/external/payment-accounts-strategy/statement/external-statements.model';

const StatementBridge = (props: ExternalStatementProps) => {
    const { technicalSupplierReference } = props;

    const { status } = useResumePaymentInstitutionAccountState();

    const isRequestCompleted = status === HttpRequestStatus.SUCCESS || status === HttpRequestStatus.ERROR;

    const bridgeComponent: Record<PaymentInstitutionReference, ReactNode> = {
        celcoin: <CelcoinStatement {...props} />,
        transfeera: <TransfeeraStatement {...props} />,
    };

    if (isRequestCompleted && technicalSupplierReference === undefined) {
        return <StatementFallback {...props} />;
    }

    return <>{bridgeComponent[technicalSupplierReference] ?? <StatementFallback {...props} />}</>;
};

export default StatementBridge;
