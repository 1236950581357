import { MenuItem } from '@material-ui/core';
import { FileUploader } from 'react-drag-drop-files';
import { useTranslation } from 'react-i18next';
import './upload-file.scss';

const fileTypes = ['PDF'];
const I18N_PREFIX = 'pages.scd.provisional-limelight';

interface SelectOneFileProps {
    handleChange: (fileSelect: File) => void;
}

const SelectOneFile = (props: SelectOneFileProps) => {
    const { handleChange } = props;
    const { t } = useTranslation();

    return (
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={false} maxSize={300} >
            <MenuItem children={t(`${I18N_PREFIX}.buttons.imported`)} />
        </FileUploader>
    );
};

export default SelectOneFile;
