import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import clsx from 'clsx';
import React from 'react';
import BaseMaskInput, { BaseMaskInputProps } from 'components/inputs/base-mask-input';
import InputUtils from 'components/inputs/input-utils';
import ValidInput, { ValidInputProps } from 'components/inputs/validation/valid-input';
import PercentMask from 'components/inputs/percentage-input/percent-mask';

type BaseInput = Omit<BaseMaskInputProps, 'MaskInputComponent' | 'error' | 'onChange' | 'value' | 'type'>;
type BaseValidation = Omit<ValidInputProps<number>, 'children'>;
type Base = BaseInput & BaseValidation;

export interface PercentInput extends Base {
    onChange?: (value: number) => void;
    MaskInputComponent?: React.ElementType<InputBaseComponentProps>;
}

const ValidPercentInput = (props: PercentInput) => {
    const { validate, showValidation, value, onChange, MaskInputComponent, ...otherProps } = props;

    const handleOnChange = (_value: string) => {
        const normalized = InputUtils.percentStringToNumber(_value);

        if (onChange && !isNaN(normalized)) {
            onChange(normalized);
        }
    };

    return (
        <ValidInput
            value={value}
            validate={validate}
            showValidation={showValidation}
            className={clsx('base-input', { dirty: props.value })}
        >
            {({ error }) => (
                <BaseMaskInput
                    {...otherProps}
                    error={error}
                    value={InputUtils.formatNumberToPercentage(value)}
                    onChange={handleOnChange}
                    MaskInputComponent={MaskInputComponent ?? PercentMask}
                />
            )}
        </ValidInput>
    );
};

export default ValidPercentInput;
