import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { ScdOriginatorSimple } from 'model/originator';
import { createOriginatorError, createOriginatorSuccess, createOriginatorRequest } from 'reducer/originator/create-originator/actions';
import { CreateOriginatorTypes } from 'reducer/originator/create-originator/types';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import originatorApi from 'services/api/originator-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handleCreateOriginator(action: ReturnType<typeof createOriginatorRequest>) {
    try {
        const result: AxiosResponse<ScdOriginatorSimple> = yield call(originatorApi.createOriginator, action.payload);
        if (result.status !== HttpStatus.CREATED) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(createOriginatorError(mapped));
            return;
        }
        yield put(createOriginatorSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(createOriginatorError(mapped));
    }
}

function* watchCreateOriginator() {
    yield takeLeading(CreateOriginatorTypes.CREATE_ORIGINATOR_REQUEST, handleCreateOriginator);
}

function* createOriginatorSaga() {
    yield all([fork(watchCreateOriginator)]);
}

export default createOriginatorSaga;
