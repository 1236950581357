import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import FieldPassword from 'components/inputs/login/field-password/field-password';
import Loading from 'components/loading/loading';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { resetPasswordRequest } from 'reducer/account/reset-password/actions';
import { usePasswordResetState, useRootDispatch, useUserByResetKeyState } from 'reducer/hooks';
import { userByResetKeyRequest, userByResetKeyResetState } from 'reducer/user/reset-key/actions';
import { validatePassword, validatePasswordMatch, validatePasswordReset } from 'shared/validation/login-validations';
import ResetSuccess from './components/reset-success/reset-success';

import './password-reset.scss';

interface PasswordResetParams {
    key: string;
}

const PasswordReset = () => {
    const [password, setPassword] = useState<string>('');
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const { key } = useParams<PasswordResetParams>();
    const [showValidation, setShowValidation] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const history = useHistory();

    const { t } = useTranslation();
    const dispatch = useRootDispatch();

    const { status: passwordResetStatus } = usePasswordResetState();
    const { status: resetKeyStatus } = useUserByResetKeyState();

    const validateMatch = () => {
        return validatePasswordMatch({ password, confirm: passwordConfirm });
    };

    const handleValidSubmit = () => {
        setShowValidation(true);

        if (!validatePasswordReset({ password, confirm: passwordConfirm })) return;

        dispatch(resetPasswordRequest({ key, password, newPassword: passwordConfirm }));
    };

    const handleClose = () => {
        history.push('/');
    };

    useEffect(() => {
        if (passwordResetStatus === HttpRequestStatus.SUCCESS) {
            setShowModal(true);
        }
    }, [passwordResetStatus]);

    useEffect(() => {
        dispatch(userByResetKeyRequest(key));
        return () => {
            dispatch(userByResetKeyResetState());
        };
    }, [key, dispatch]);

    const isLoading = resetKeyStatus === HttpRequestStatus.ON_GOING;

    const error = resetKeyStatus === HttpRequestStatus.ERROR;

    return isLoading ? (
        <Loading />
    ) : (
        <main className="password__reset">
            <article className="password__reset--container">
                <header className="password__reset--header">
                    <div className="password__reset--field">
                        <p className="password__reset--header--title">{t('pages.password-reset.title')}</p>
                    </div>
                </header>
                {!error ? (
                    <div className="password__reset--content">
                        <div className="password__reset--field">
                            <FieldPassword
                                label={t('pages.password-reset.password.label')}
                                value={password}
                                onChange={setPassword}
                                validate={validatePassword}
                                autoComplete="off"
                                showValidation={showValidation}
                            />
                        </div>
                        <div className="password__reset--field">
                            <FieldPassword
                                value={passwordConfirm}
                                label={t('pages.password-reset.confirm-password.label')}
                                onChange={setPasswordConfirm}
                                validate={validateMatch}
                                showValidation={showValidation}
                                autoComplete="off"
                            />
                        </div>
                        <div className="password__reset--button">
                            <StandardButtonLegacy label="pages.password-reset.finish" onClick={handleValidSubmit} />
                        </div>
                    </div>
                ) : (
                    <p>{t('pages.password-reset.messages.key-missing')}</p>
                )}
            </article>
            <ResetSuccess open={showModal} onClose={handleClose} />
        </main>
    );
};

export default PasswordReset;
