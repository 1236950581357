import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './switch-today-only-button.scss';

interface SwitchTodayOnlyButtonProps {
    isDisabled?: boolean;
    isDefaultTodayOnly?: boolean;
    onSwitch: (value: boolean) => void;
}

export const SwitchTodayOnlyButton = (props: SwitchTodayOnlyButtonProps) => {
    const { isDisabled, isDefaultTodayOnly = true, onSwitch } = props;

    const { t } = useTranslation();

    const [checkedValue, setCheckedValue] = useState<boolean>(isDefaultTodayOnly);

    const handleSwitch = () => {
        if (isDisabled) return;

        setCheckedValue(!checkedValue);
        onSwitch(!checkedValue);
    };

    useEffect(() => {
        if (!isDefaultTodayOnly) {
            setCheckedValue(isDefaultTodayOnly);
        }
    }, [isDefaultTodayOnly]);

    return (
        <div className="switch-today-only-button">
            <div
                className={clsx('switch-today-only-button--content', {
                    active: checkedValue,
                    disabled: isDisabled,
                })}
                onClick={() => {
                    if (checkedValue) return;
                    handleSwitch();
                }}
            >
                {t('switch.today')}
            </div>
            <div
                className={clsx('switch-today-only-button--content', {
                    active: !checkedValue,
                    disabled: isDisabled,
                })}
                onClick={() => {
                    if (!checkedValue) return;
                    handleSwitch();
                }}
            >
                {t('switch.all')}
            </div>
        </div>
    );
};

export default SwitchTodayOnlyButton;
