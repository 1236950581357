import { RouteComponentProps, Switch } from 'react-router-dom';

import PrivateRoute from 'shared/routes/private-route';

import ScdDisbursementReport from 'entities/disbursement-report/disbursement-report';

interface MatchParams {
    url: string;
}

const DisbursementReportRoutes = ({ match }: RouteComponentProps<MatchParams>) => (
    <Switch>
        <PrivateRoute exact path={`${match.url}`} component={ScdDisbursementReport} />
    </Switch>
);

export default DisbursementReportRoutes;
