import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import LinkButton from 'components/buttons/link-button/link-button';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ChipFilterActive from 'components/chip-filter-active/chip-filter-active';
import UserFiltersButtons from 'entities/user/components/user-filters-buttons/user-filters-buttons';
import UserModalFilter, { UserModalFilterType } from 'entities/user/components/user-modal-filter/user-modal-filter';
import UsersListHeader from 'entities/user/components/users-list-header/users-list-header';
import UsersList from 'entities/user/components/users-list/users-list';
import useUserFilterChips from 'entities/user/hooks/use-user-filter-chips';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { UsersFilterPageable } from 'model/reducers';
import { UserFilterRequest, UsersToGetAll, defaultUserFilterRequest } from 'model/user';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { listUsersRequest, listUsersResetState } from 'reducer/user/list-users/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './users.scss';

export type UserDataSortableProperties = keyof UsersToGetAll | 'createdDate';

const I18N_PREFIX = 'pages.scd.users';

const ScdUsers = () => {
    const { t } = useTranslation();

    const dispatch = useRootDispatch();
    const history = useHistory();
    const { url } = useRouteMatch();

    const [sortedProperty, setSortedProperty] = useState<UserDataSortableProperties>('createdDate');
    const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
    const [page, setPage] = useState<number>(0);
    const [modalFilter, setModalFilter] = useState<UserModalFilterType | undefined>(undefined);
    const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(true);
    const [filterActive, setFilterActive] = useState<UserFilterRequest>(defaultUserFilterRequest);
    const { isUserChipsActive, userFilterChips } = useUserFilterChips({ filterActive, setFilterActive, setPage });

    const filterPageable = useCallback((): UsersFilterPageable => {
        const _pageable: Pageable = {
            page: page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: `${sortedProperty},${sortOrder}`,
        };

        const filter: UserFilterRequest = filterActive ?? {};

        return {
            filter,
            pageable: _pageable,
        };
    }, [sortedProperty, sortOrder, page, filterActive]);

    const handlePageChange = () => {
        setPage(page => page + 1);
    };

    const {
        InfiniteScrollButton,
        items: usersItems,
        resetInfiniteScrollListing,
        isLoading,
        handleRefresh,
        status: listStatus,
    } = useInfiniteScrollLegacy<UsersToGetAll>({
        currentPage: page,
        handlePageChange,
        dataSelector: (state: IRootState) => state.listUsers.users,
        statusSelector: (state: IRootState) => state.listUsers.status,
        action: listUsersRequest,
        filterPageable,
        setPage,
    });

    const hasError = listStatus === HttpRequestStatus.ERROR;

    useEffect(() => {
        return () => {
            dispatch(listUsersResetState());
        };
    }, [dispatch]);

    const handleSort = (property: UserDataSortableProperties, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();
        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (sortedProperty !== 'createdDate' && sortOrder === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };
    const handleResetFilterActive = () => {
        setPage(0);
        resetInfiniteScrollListing();
        setFilterActive(defaultUserFilterRequest);
    };

    return hasError ? (
        <div className="page__global-error">
            <div className="error-message">{t('global.scd.company-group-policy.error-message')}</div>
            <BackButton label={t('global.try-again')} onClick={handleRefresh} />
        </div>
    ) : (
        <main className="scd-users">
            <section className="scd-users__container">
                <header className="scd-users__header">
                    <div className="scd-users__header-container">
                        <h2 className="scd-users__header-container--title">
                            {t(`${I18N_PREFIX}.title`)}
                            <RefreshButton onClick={handleRefresh} />
                        </h2>
                        <div className="scd-users__header-container--buttons-content">
                            <LinkButton label={t(`${I18N_PREFIX}.buttom.label`)} onClick={() => history.push(`${url}/new`)} />
                        </div>
                    </div>
                    <div className="scd-program__header--buttons-content">
                        <UserFiltersButtons
                            filterActive={filterActive}
                            handleResetFilterActive={handleResetFilterActive}
                            setIsOpenModalFilter={setIsOpenModalFilter}
                            setModalFilter={setModalFilter}
                        />
                    </div>
                    <ChipFilterActive filters={userFilterChips} />
                </header>
                <div className="scd-users__table">
                    <table
                        className={clsx('page-container--table', {
                            EMPTY_COLLECTION: !usersItems.length,
                            CHIPS_ACTIVE: isUserChipsActive,
                        })}
                    >
                        <thead>
                            <UsersListHeader
                                users={usersItems}
                                handleSort={handleSort}
                                sortedProperty={sortedProperty}
                                sortOrder={sortOrder}
                            />
                        </thead>
                        <tbody>
                            <UsersList users={usersItems} isLoading={isLoading} />
                        </tbody>
                    </table>
                    <InfiniteScrollButton />
                </div>
            </section>
            {modalFilter && (
                <UserModalFilter
                    filterActive={filterActive}
                    isOpenModalFilter={isOpenModalFilter}
                    modalFilter={modalFilter}
                    onClose={() => setIsOpenModalFilter(false)}
                    resetInfiniteScrollListing={resetInfiniteScrollListing}
                    setFilterActive={setFilterActive}
                    setPage={setPage}
                    title={t(`${I18N_PREFIX}.filter.buttons.${modalFilter}`)}
                />
            )}
        </main>
    );
};

export default ScdUsers;
