export enum CnabTemplate {
    TOP_ONE_CUSTOM_444 = 'TOP_ONE_CUSTOM_444',
    EXATO_BRADESCO_400 = 'EXATO_BRADESCO_400',
    FINAXIS_500 = 'FINAXIS_500',
    OGOCHI_CUSTOM_500 = 'OGOCHI_CUSTOM_500',
    TIDAS_BRADESCO_400 = 'TIDAS_BRADESCO_400',
    GTSEC_BRADESCO_400 = 'GTSEC_BRADESCO_400',
    LIMELIGHT_BRADESCO_400 = 'LIMELIGHT_BRADESCO_400',
    ABA_CUSTOM_444 = 'ABA_CUSTOM_444',
    NO_CNAB = 'NO_CNAB',
}
