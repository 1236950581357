import { GiroWebappError } from 'model/error';
import { ScdImportedOperationSimple } from 'model/imported-operation';
import { ImportedOperationsArchiveOneTypes } from 'reducer/imported-operation/archive-one/types';
import { action } from 'typesafe-actions';

export const importedOperationsArchiveOneRequest = (operationId: number) =>
    action(ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_REQUEST, operationId);
export const importedOperationsArchiveOneSuccess = (data: ScdImportedOperationSimple) =>
    action(ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_SUCCESS, data);
export const importedOperationsArchiveOneError = (error: GiroWebappError) =>
    action(ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_ERROR, error);

export const importedOperationsArchiveOneResetState = () =>
    action(ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_RESET_STATE);
