import clsx from 'clsx';
import SimpleSearchInput from 'components/inputs/search-input/simple-search-input';
import { LIST_WINDOW_SIZE, MODAL_SEARCH_HEIGHT, MODAL_SEARCH_WIDTH, SEARCH_PAGE_SIZE_DEFAULT } from 'components/modals/constants';
import { debounce } from 'lodash';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { SearchRequest } from 'model/reducers';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { IRootState } from 'reducer';
import { useRootDispatch } from 'reducer/hooks';
import { AnyAction } from 'redux';
import { Page } from 'services/page';
import { Pageable } from 'services/pageable';
import { v4 as uuidV4 } from 'uuid';
import './list-search-input.scss';

export interface ListSearchInputProps<T> {
    label: string;
    placeholder: string;
    itemSize: number;
    action: (search: SearchRequest) => AnyAction;
    onSelect?: (value: T) => void;
    renderItem: (item: T) => React.ReactNode;
    dataSelector: (state: IRootState) => Page<T> | undefined;
    statusSelector: (state: IRootState) => HttpRequestStatus;
    requestParameters?: object;
    disabled?: boolean;
    value: string;
}

const ListSearchInput = <T extends object>(props: ListSearchInputProps<T>) => {
    const { onSelect, dataSelector, action, placeholder, label, statusSelector, renderItem, itemSize, requestParameters, disabled, value } =
        props;
    const dispatch = useRootDispatch();
    const [valueSearch, setValueSearch] = useState<string>(value ?? '');
    const [showData, setShowData] = useState<boolean>(false);

    const data = useSelector<IRootState, Page<T> | undefined>(dataSelector);
    const status = useSelector<IRootState, HttpRequestStatus>(statusSelector);

    const [filteredData, setFilteredData] = useState(data?.content ?? []);
    const listItemsRef = useRef<HTMLDivElement | null>(null);

    const isItemLoaded = ({ index }: any) => !!filteredData[index];
    const dataCount: number = filteredData?.length ?? 0;

    const itemKey = (index: number, data: any): string | number => {
        return (data && data[index]?.id) ?? index;
    };

    const onHandleScroll = (event: ListOnScrollProps) => {
        const clientHeight = document.querySelector('.list-select-option-search-div-style')?.scrollHeight;

        const bottom = Number(clientHeight) - LIST_WINDOW_SIZE - event.scrollOffset === 0;

        if (!bottom) return;

        requestNextPage();
    };

    const searchPageable = useCallback(
        (search: string) => {
            const _pageable: Pageable = { page: 0, size: SEARCH_PAGE_SIZE_DEFAULT };
            const request = { search: search, pageable: _pageable, ...requestParameters };
            return request;
        },
        [requestParameters]
    );

    const requestNextPage = () => {
        const _pageable: Pageable = { page: (data?.number ?? 0) + 1, size: SEARCH_PAGE_SIZE_DEFAULT };
        const request = { search: valueSearch, pageable: _pageable, ...requestParameters };
        if (data && !data.last) {
            dispatch(action(request));
        }
    };

    const loadMoreItems = () => {
        return;
    };

    const debounceSearch = useRef(
        debounce(value => {
            dispatch(action(searchPageable(value)));
        }, 500)
    );

    const handleOnChange = (searchInput: string) => {
        setValueSearch(searchInput);

        if (!searchInput.length) return;

        debounceSearch.current(searchInput);
    };

    const handleSelect = (selectValue: T) => {
        if (!onSelect) return;
        onSelect(selectValue);
        setFilteredData([]);
        setShowData(false);
    };

    const handleOutside = (ref: HTMLElement | null) => {
        const handleClickOutside = (event: any) => {
            if (ref && !ref.contains(event.target)) {
                setShowData(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    };

    useEffect(() => {
        if (status === HttpRequestStatus.SUCCESS) {
            const newData = data?.content ?? [];
            const newPage = data?.number === 0;
            setFilteredData(oldData => [...(!newPage && oldData ? oldData : []), ...newData]);
        }
    }, [status, data]);

    useEffect(() => {
        if (valueSearch.length === 0) {
            dispatch(action(searchPageable('')));
        }
    }, [dispatch, action, valueSearch, searchPageable]);

    useEffect(() => {
        setValueSearch(value);
    }, [value]);

    useEffect(() => {
        if (!showData && value.length !== 0) {
            setValueSearch(value);
        }
    }, [showData, value]);

    useEffect(() => {
        handleOutside(listItemsRef?.current);
    }, [listItemsRef]);

    return (
        <div className={clsx('list-select-option-search-input-container', { active: showData })} ref={listItemsRef}>
            <SimpleSearchInput
                searchActive={showData}
                label={label}
                value={valueSearch}
                placeholder={placeholder}
                onChange={handleOnChange}
                onClick={() => setShowData(true)}
                disabled={disabled}
                readOnly={showData ? false : true}
            />
            {showData && (
                <AutoSizer defaultHeight={MODAL_SEARCH_HEIGHT} defaultWidth={MODAL_SEARCH_WIDTH}>
                    {({ height, width }) => {
                        return (
                            <InfiniteLoader isItemLoaded={isItemLoaded} loadMoreItems={loadMoreItems} itemCount={dataCount}>
                                {({ onItemsRendered, ref }) => (
                                    <FixedSizeList
                                        onItemsRendered={onItemsRendered}
                                        className="list-select-option-search-div-style"
                                        ref={ref}
                                        height={height}
                                        itemKey={itemKey}
                                        itemCount={dataCount}
                                        itemSize={itemSize}
                                        width={width}
                                        itemData={filteredData}
                                        onScroll={onHandleScroll}
                                    >
                                        {({ index, style }) => (
                                            <div
                                                key={uuidV4()}
                                                className="list-select-option-search-item-container"
                                                style={{
                                                    ...style,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                onClick={() => {
                                                    handleSelect(filteredData[index]);
                                                }}
                                            >
                                                {renderItem(filteredData[index] ?? '')}
                                            </div>
                                        )}
                                    </FixedSizeList>
                                )}
                            </InfiniteLoader>
                        );
                    }}
                </AutoSizer>
            )}
        </div>
    );
};
export default ListSearchInput;
