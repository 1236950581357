import { AxiosResponse } from 'axios';
import { UsersFilterPageable } from 'model/reducers';
import { UserLogin, UsersToGetAll, UserDetailData, UserSimple, UserToCreateOrUpdate } from 'model/user';
import { api } from 'services/api/api';
import { BASE_URL_ADMIN, Page } from 'services/page'

const API_URL = `${BASE_URL_ADMIN}/users`

export const users = () => {
    const findByResetKey = (key: string): Promise<AxiosResponse<UserLogin>> => {
        return api.get<UserLogin>(`api/users/reset-key/${key}`);
    };

    const create = (user: UserToCreateOrUpdate): Promise<AxiosResponse<UserSimple>> => {
        return api.post<UserSimple>(`${API_URL}`, user)
    }

    const update = (user: UserToCreateOrUpdate): Promise<AxiosResponse<UserSimple>> => {
        return api.patch<UserSimple>(`${API_URL}/${user.id}`, user)
    }

    const listUsers = (request: UsersFilterPageable): Promise<AxiosResponse<Page<UsersToGetAll>>> => {
        const { pageable, filter } = request;
        const _requestFilters = [
            filter?.activated !== undefined ? `activated=${filter?.activated}` : undefined,
            filter?.login ? `login=${filter?.login}` : undefined,
            filter?.firstName ? `name=${filter?.firstName}` : undefined,
            filter?.authority ? `authority=${filter?.authority}` : undefined,
        ]
        .filter(it => !!it)
        .join('&')
        return api.get<Page<UsersToGetAll>>(`${API_URL}?${_requestFilters}`, { params: { ...pageable } })
    }

    const detailUser = (id: number): Promise<AxiosResponse<UserDetailData>> => {
        return api.get<UserDetailData>(`${API_URL}/${id}`)
    }

    return {
        findByResetKey,
        create,
        update,
        listUsers,
        detailUser
    };
};

export default users();
