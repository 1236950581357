import { HttpRequestStatus } from 'model/enums/http-request-status';
import {
    ImportedOperationsArchiveOneTypes,
    ImportedOperationsArchiveOneState,
} from 'reducer/imported-operation/archive-one/types';
import { Reducer } from 'redux';

export const initialState: ImportedOperationsArchiveOneState = {
    status: HttpRequestStatus.NOOP,
    archivedOperation: undefined,
    error: undefined,
};

const reducer: Reducer<ImportedOperationsArchiveOneState> = (
    state = initialState,
    action
): ImportedOperationsArchiveOneState => {
    switch (action.type) {

        case ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING };
        }
        case ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, archivedOperation: action.payload, error: undefined };
        }
        case ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }

        case ImportedOperationsArchiveOneTypes.IMPORTED_OPERATIONS_ARCHIVE_ONE_RESET_STATE: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as importedOperationsArchiveOneReducer };
