import JSZip from 'jszip';



const zipFiles = async (files: File[]): Promise<Uint8Array> => {
    const zip = new JSZip();
    files.forEach(file => {
        zip.file(file.name, file);
    });
    const generated = await zip.generateAsync({ type: "uint8array" });
    return generated;
}

const zipString = async (data: string, fileName: string): Promise<Uint8Array> => {
    const zip = new JSZip();

    const zipped = zip.file(fileName, data);
    const generated = await zipped.generateAsync({ type: "uint8array" });
    return generated;
}

const bytesToBase64 = (bytes: Uint8Array): string => {
    return btoa(bytes.reduce((data, byte) => data + String.fromCharCode(byte), ''));
}

const generateHashHexUsingSha256FromBytes = async (_crypto: Crypto, bytes: Uint8Array): Promise<string> => {
    const hash = await _crypto.subtle.digest('SHA-256', bytes);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}


export const BackingUtils = {
    zipFiles,
    zipString,
    bytesToBase64,
    generateHashHexUsingSha256FromBytes

}

export default BackingUtils;
