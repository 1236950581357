import { ScdBatchOperationsReport } from 'model/batch';
import { GiroWebappError } from 'model/error';
import { BatchOperationsReportTypes } from 'reducer/batch/operations-report/types';
import { action } from 'typesafe-actions';

export const batchOperationsReportRequest = (batchId: number) =>
    action(BatchOperationsReportTypes.BATCH_OPERATIONS_REPORT_REQUEST, batchId);
export const batchOperationsReportSuccess = (batchOperationsReport: ScdBatchOperationsReport) =>
    action(BatchOperationsReportTypes.BATCH_OPERATIONS_REPORT_SUCCESS, batchOperationsReport);
export const batchOperationsReportError = (error: GiroWebappError) =>
    action(BatchOperationsReportTypes.BATCH_OPERATIONS_REPORT_ERROR, error);
export const batchOperationsReportResetState = () => action(BatchOperationsReportTypes.BATCH_OPERATIONS_REPORT_RESET);
