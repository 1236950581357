import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type Base = Pick<CheckboxProps, 'color'>;

export interface BaseCheckboxInputProps extends Base {
    id?: string;
    label: string;
    value: boolean;
    externalUpdate?: boolean;
    onChange?: (value: boolean) => void;
}

export const BaseCheckboxInput = (props: BaseCheckboxInputProps) => {
    const { label, value: propsValue, onChange, id, externalUpdate, ...otherProps } = props;
    const [value, setValue] = React.useState<boolean>(propsValue ?? false);
    const { t } = useTranslation();

    useEffect(() => {
        if (externalUpdate) {
            setValue(ps => (ps === propsValue ? ps : propsValue ?? ''));
        }
    }, [externalUpdate, propsValue]);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _value = event?.target?.checked;
        if (_value === value) return;

        setValue(_value);
        if (onChange) {
            onChange(_value);
        }
    };

    return <FormControlLabel name={id} label={t(label)} control={<Checkbox checked={value} onChange={handleChange} {...otherProps} />} />;
};

export default BaseCheckboxInput;
