import { BaseStoreState } from 'reducer/base-store-state';
import { ScdBankerSimple } from 'model/banker';

export type ApiResponse = Record<string, any>;

export enum CreateBankerTypes {
    CREATE_BANKER_REQUEST = '@@banker-create/CREATE_BANKER_REQUEST',
    CREATE_BANKER_SUCCESS = '@@banker-create/CREATE_BANKER_SUCCESS',
    CREATE_BANKER_ERROR = '@@banker-create/CREATE_BANKER_ERROR',
    CREATE_BANKER_RESET_STATE = '@@banker-create/CREATE_BANKER_RESET_STATE',
}

export interface CreateBankerState extends BaseStoreState {
    readonly banker?: ScdBankerSimple;
}
