import { BaseExternalStoreState } from 'shared/external/external-strategy.model';
import { ExternalTransfersResponse } from 'shared/external/payment-accounts-strategy/transfers/external-transfers.model';

export type ApiResponse = Record<string, any>;

export enum ExternalTransfersPaymentInstitutionsAccountsTypes {
    EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST = '@@payment-institutions-accounts-transfers/EXTERNAL_TRASNFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_REQUEST',
    EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS = '@@payment-institutions-accounts-transfers/EXTERNAL_TRASNFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_SUCCESS',
    EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR = '@@payment-institutions-accounts-transfers/EXTERNAL_TRASNFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_ERROR',
    EXTERNAL_TRANSFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET = '@@payment-institutions-accounts-transfers/EXTERNAL_TRASNFERS_PAYMENT_INSTITUTIONS_ACCOUNTS_RESET',
}

export interface ExternalTransfersPaymentInstitutionsAccountsState extends BaseExternalStoreState {
    readonly externalTransfers?: ExternalTransfersResponse
}
