import clsx from 'clsx';
import BackButton from 'components/buttons/back-button/back-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ContextRibbon from 'components/context-ribbon/context-ribbon';
import ProgramGroupsAddModal from 'entities/program/program-groups/components/program-groups-add-modal/program-groups-add-modal';
import ProgramGroupsHeader from 'entities/program/program-groups/components/program-groups-header/program-groups-header';
import ProgramGroupsList from 'entities/program/program-groups/components/program-groups-list/program-groups-list';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import { ManageCompanyGroupToProgramRequest, ProgramGroupsRequest, ProgramGroupsToGetAll } from 'model/program-groups';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import {
    useAddProgramGroupState,
    useListProgramGroupsState,
    useProgramToResumeState,
    useRemoveProgramGroupState,
    useRootDispatch,
} from 'reducer/hooks';
import { listProgramGroupsRequest, listProgramGroupsResetState } from 'reducer/program-groups/list-program-groups/actions';
import { removeProgramGroupRequest, removeProgramGroupResetState } from 'reducer/program-groups/remove-program-group/actions';
import { programToResumeRequest, programToResumeResetState } from 'reducer/program-groups/resume-program/actions';
import { INFINITE_SCROLL_PAGE_SIZE, Pageable } from 'services/pageable';
import { SortOrder } from 'services/sort';
import { useToast } from 'shared/hooks/use-toast';

import BackDrop from 'components/backdrop/backdrop';
import RefreshButton from 'components/buttons/refresh-button/refresh-button';
import ModalMessageLegacy from 'components/modals/modal-message-legacy/modal-message-legacy';
import useInfiniteScrollLegacy from 'shared/infinite-components/infinite-scroll-legacy/infinite-scroll-legacy';
import './program-groups.scss';

type ProgramGroupsProps = RouteComponentProps<{ programId: string }>;

const I18N_PREFIX = 'pages.scd.program-groups';

const ProgramGroups = (props: ProgramGroupsProps) => {
    const { match } = props;

    const [page, setPage] = useState<number>(0);
    const [sortedProperty, setSortedProperty] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortOrder>(undefined);
    const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);

    const [programId] = useState<string>(match.params.programId);
    const [selectedGroup, setSelectedGroup] = useState<ProgramGroupsToGetAll | undefined>(undefined);
    const [isOpenModalWarning, setIsOpenModalWarning] = useState<boolean>(false);

    const { t } = useTranslation();
    const { toastSuccess } = useToast();

    const dispatch = useRootDispatch();
    const history = useHistory();

    const { status } = useListProgramGroupsState();
    const { resumedProgram } = useProgramToResumeState();
    const { status: addStatus } = useAddProgramGroupState();
    const { status: removeStatus } = useRemoveProgramGroupState();

    const removeProgramGroup = useCallback(() => {
        if (!programId || !selectedGroup?.id) return;

        const requestToRemove: ManageCompanyGroupToProgramRequest = {
            companyGroupId: selectedGroup.id.toString(),
            programId,
        };

        dispatch(removeProgramGroupRequest(requestToRemove));
    }, [programId, selectedGroup, dispatch]);

    const handlePageChange = () => {
        setPage(ps => ps + 1);
    };

    const sortPageable = useCallback((): ProgramGroupsRequest => {
        const pageable: Pageable = {
            page,
            size: INFINITE_SCROLL_PAGE_SIZE,
            sort: sortedProperty && sortOrder ? `${sortedProperty},${sortOrder}` : '',
        };

        return {
            pageable,
            programId,
        };
    }, [page, sortOrder, sortedProperty, programId]);

    const {
        InfiniteScrollButton,
        handleRefresh,
        isLoading,
        items: programGroupsItems,
        resetInfiniteScrollListing,
    } = useInfiniteScrollLegacy<ProgramGroupsToGetAll>({
        handlePageChange,
        currentPage: page,
        dataSelector: (state: IRootState) => state.listProgramGroups.programGroups,
        statusSelector: (state: IRootState) => state.listProgramGroups.status,
        action: listProgramGroupsRequest,
        filterPageable: sortPageable,
        setPage,
    });

    const handleRibbonAction = () => {
        if (!!programId && programGroupsItems.length === 0) {
            setIsOpenModalWarning(true);
            return;
        }

        history.push('/programs');
    };

    useEffect(() => {
        dispatch(programToResumeRequest(programId));
    }, [dispatch, programId]);

    useEffect(() => {
        if (addStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.add.success`));
        setIsOpenAddModal(false);
        handleRefresh();
    }, [addStatus, toastSuccess, t, handleRefresh]);

    useEffect(() => {
        if (removeStatus !== HttpRequestStatus.SUCCESS) return;

        toastSuccess(t(`${I18N_PREFIX}.toast.remove.success`));
        setSelectedGroup(undefined);
        handleRefresh();
        dispatch(removeProgramGroupResetState());
    }, [removeStatus, toastSuccess, t, handleRefresh, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(listProgramGroupsResetState());
            dispatch(programToResumeResetState());
        };
    }, [dispatch]);

    const handleSort = (property: keyof ProgramGroupsToGetAll, order: SortOrder) => {
        setPage(0);
        resetInfiniteScrollListing();

        if (property !== sortedProperty) {
            setSortedProperty(property);
            setSortOrder('asc');
        } else if (order === 'asc') {
            setSortedProperty(property);
            setSortOrder('desc');
        } else if (order === 'desc') {
            setSortedProperty('createdDate');
            setSortOrder('desc');
        } else {
            setSortedProperty(property);
            setSortOrder('asc');
        }
    };

    const hasError = status === HttpRequestStatus.ERROR;

    if (hasError) {
        return (
            <div className="page__global-error">
                <div className="error-message">{t('global.scd.company-group.error-message')}</div>
                <BackButton label={t('global.try-again')} onClick={handleRefresh} />
            </div>
        );
    }

    const isAddingOrRemovingGroup: boolean = addStatus === HttpRequestStatus.ON_GOING || removeStatus === HttpRequestStatus.ON_GOING;

    return (
        <>
            {isAddingOrRemovingGroup && <BackDrop open />}
            <main className="scd-program-groups">
                <ContextRibbon action={handleRibbonAction} />
                <section className="scd-program-groups__container">
                    <header className="scd-program-groups__header">
                        <div className="scd-program-groups__header-container">
                            <h2 className="scd-program-groups__header-container--title">
                                {t(`${I18N_PREFIX}.title`)}
                                <RefreshButton onClick={handleRefresh} />
                            </h2>
                            <StandardButtonLegacy label={t(`${I18N_PREFIX}.add.title`)} onClick={() => setIsOpenAddModal(true)} />
                        </div>
                        <div className="scd-program-groups__header-container--info">
                            <span className="scd-program-groups__header-container--info-item">{resumedProgram?.program?.name}</span>
                            <span className="scd-program-groups__header-container--info-item">
                                {t(`${I18N_PREFIX}.productType.${resumedProgram?.program?.productType}`)}
                            </span>
                            <span className={clsx('scd-program-groups__header-container--info-status', resumedProgram?.program?.status)}>
                                {t(`${I18N_PREFIX}.status.${resumedProgram?.program?.status}`).toUpperCase()}
                            </span>
                        </div>
                    </header>
                    <div className="scd-program-groups__table">
                        <table
                            className={clsx('page-container--table', {
                                EMPTY_COLLECTION: !programGroupsItems.length,
                            })}
                        >
                            <thead>
                                <ProgramGroupsHeader
                                    handleSort={handleSort}
                                    programGroupsItems={programGroupsItems}
                                    sortOrder={sortOrder}
                                    sortedProperty={sortedProperty}
                                />
                            </thead>
                            <tbody>
                                <ProgramGroupsList
                                    programGroupsItems={programGroupsItems}
                                    isLoading={isLoading}
                                    setSelectedGroup={setSelectedGroup}
                                />
                            </tbody>
                        </table>
                        <InfiniteScrollButton />
                    </div>
                </section>
                {isOpenAddModal && (
                    <ProgramGroupsAddModal
                        onClose={() => setIsOpenAddModal(false)}
                        programId={programId}
                        organizationId={resumedProgram?.program?.organizationId?.toString()}
                    />
                )}
                {selectedGroup && (
                    <ModalMessageLegacy
                        title={t(`${I18N_PREFIX}.remove-modal.title`)}
                        message={t(`${I18N_PREFIX}.remove-modal.message`, {
                            groupName: selectedGroup.name,
                            programName: resumedProgram?.program?.name,
                        })}
                        onClose={() => setSelectedGroup(undefined)}
                        onCancel={() => setSelectedGroup(undefined)}
                        onAction={removeProgramGroup}
                    />
                )}
                {isOpenModalWarning && (
                    <ModalMessageLegacy
                        title={t(`${I18N_PREFIX}.warning.title`)}
                        message={t(`${I18N_PREFIX}.warning.message`)}
                        onCancel={() => setIsOpenModalWarning(false)}
                        onAction={() => {
                            setIsOpenModalWarning(false);
                            history.push('/programs');
                        }}
                        onClose={() => setIsOpenModalWarning(false)}
                    />
                )}
            </main>
        </>
    );
};

export default ProgramGroups;
