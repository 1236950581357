import { IssuerPolicyStatus } from 'model/enums/issuer-policy-status';
import { IssuerPolicyType } from 'model/enums/issuer-policy-type';
import { IssuerPolicyCreateUpdate } from 'model/issuer-policy';

import { Validations, ValidationUtils, ValidResult } from 'shared/util/validation-utils';

const I18N_PREFIX = 'pages.scd.issuer-policy.validate';

const ISSUER_POLICY_NAME_VALIDATION: Validations<string | undefined | null> = {
    required: {
        predicate: ValidationUtils.STRING.isNotBlank,
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
    minLength: {
        predicate: ValidationUtils.STRING.minLength(0),
        errorMessage: `${I18N_PREFIX}.name.required`,
    },
};
export const validateIssuerPolicyName = (value: string | undefined | null) => 
    ValidationUtils.validate(value, ISSUER_POLICY_NAME_VALIDATION);

const ISSUER_POLICY_STATUS_VALIDATION: Validations<IssuerPolicyStatus | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.status.required`,
    },
};
export const validateIssuerPolicyStatus = (value: IssuerPolicyStatus | undefined | null) => 
    ValidationUtils.validate(value, ISSUER_POLICY_STATUS_VALIDATION);

const ISSUER_POLICY_TYPE_VALIDATION: Validations<IssuerPolicyType | undefined | null> = {
    required: {
        predicate: ValidationUtils.ENUMERATION.isDefinedEnum,
        errorMessage: `${I18N_PREFIX}.type.required`,
    },
};
export const validateIssuerPolicyType = (value: IssuerPolicyType | undefined | null) => 
    ValidationUtils.validate(value, ISSUER_POLICY_TYPE_VALIDATION);

const ISSUER_POLICY_PLATFORM_VALIDATION: Validations<number | undefined | null> = {
    required: {
        predicate: ValidationUtils.NUMBER.isNotEmpty,
        errorMessage: `${I18N_PREFIX}.platformId.required`,
    },
};
export const validateIssuerPolicyPlatform = (value: number | undefined | null, issuerPolicyId: number | undefined | null) => {
    if (!issuerPolicyId) {
        return ValidationUtils.validate(value, ISSUER_POLICY_PLATFORM_VALIDATION);
    }
    const validResult : ValidResult = {
        isValid: true
    }

    return validResult;
}

export const validateIssuerPolicy = (value: IssuerPolicyCreateUpdate | undefined): boolean => {
    if (!value) return true;
    return (
        validateIssuerPolicyName(value.name).isValid &&
        validateIssuerPolicyStatus(value.status).isValid &&
        validateIssuerPolicyType(value.type).isValid &&
        validateIssuerPolicyPlatform(value?.platformId, value.issuerPolicyId).isValid
    );
};