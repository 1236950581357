import { GiroWebappError } from 'model/error';
import {
    ScdMonthlyCommissionDeleteTaxDocumentRequest,
    ScdMonthlyCommissionDocumentSimple,
    ScdMonthlyCommissionDownloadTaxDocumentRequest,
    ScdMonthlyCommissionUploadTaxDocumentRequest,
} from 'model/monthly-commission';
import { MonthlyCommissionTaxDocumentTypes } from 'reducer/monthly-commission/tax-document/types';
import { action } from 'typesafe-actions';

export const monthlyCommissionUploadTaxDocumentRequest = (request: ScdMonthlyCommissionUploadTaxDocumentRequest) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_REQUEST, request);
export const monthlyCommissionUploadTaxDocumentSuccess = (document: ScdMonthlyCommissionDocumentSimple) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_SUCCESS, document);
export const monthlyCommissionUploadTaxDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_UPLOAD_TAX_DOCUMENT_ERROR, error);

export const monthlyCommissionDownloadTaxDocumentRequest = (request: ScdMonthlyCommissionDownloadTaxDocumentRequest) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_REQUEST, request);
export const monthlyCommissionDownloadTaxDocumentSuccess = (data: Uint8Array) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_SUCCESS, data);
export const monthlyCommissionDownloadTaxDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DOWNLOAD_TAX_DOCUMENT_ERROR, error);

export const monthlyCommissionDeleteTaxDocumentRequest = (request: ScdMonthlyCommissionDeleteTaxDocumentRequest) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_REQUEST, request);
export const monthlyCommissionDeleteTaxDocumentSuccess = () =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_SUCCESS);
export const monthlyCommissionDeleteTaxDocumentError = (error: GiroWebappError) =>
    action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_DELETE_TAX_DOCUMENT_ERROR, error);

export const monthlyCommissionTaxDocumentResetState = () => action(MonthlyCommissionTaxDocumentTypes.MONTHLY_COMMISSION_TAX_DOCUMENT_RESET);
