import { HttpRequestStatus } from 'model/enums/http-request-status';
import { BatchPreviewCessionState, BatchPreviewCessionTypes } from 'reducer/batch/preview-cession/types';
import { Reducer } from 'redux';

const initialState: BatchPreviewCessionState = {
    status: HttpRequestStatus.NOOP,
    cessionPreview: undefined,
    error: undefined,
};

const reducer: Reducer<BatchPreviewCessionState> = (state = initialState, action): BatchPreviewCessionState => {
    switch (action.type) {
        case BatchPreviewCessionTypes.BATCH_PREVIEW_CESSION_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case BatchPreviewCessionTypes.BATCH_PREVIEW_CESSION_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, cessionPreview: action.payload, error: undefined };
        }
        case BatchPreviewCessionTypes.BATCH_PREVIEW_CESSION_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case BatchPreviewCessionTypes.BATCH_PREVIEW_CESSION_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as batchPreviewCessionReducer };
