import { BaseStoreState } from 'reducer/base-store-state';
import { ScdPaymentTransactionPendingData } from 'model/payment-transaction';

export type ApiResponse = Record<string, any>;

export enum ListPendingPaymentTransactionsTypes {
    LIST_PENDING_PAYMENT_TRANSACTIONS_REQUEST = '@@pending-payment-transactions-list/LIST_PENDING_PAYMENT_TRANSACTIONS_REQUEST',
    LIST_PENDING_PAYMENT_TRANSACTIONS_SUCCESS = '@@pending-payment-transactions-list/LIST_PENDING_PAYMENT_TRANSACTIONS_SUCCESS',
    LIST_PENDING_PAYMENT_TRANSACTIONS_ERROR = '@@pending-payment-transactions-list/LIST_PENDING_PAYMENT_TRANSATIONS_ERROR',
    LIST_PENDING_PAYMENT_TRANSACTIONS_RESET = '@@pending-payment-transactions-list/LIST_PENDING_PAYMENT_TRANSACTIONS_RESET',
}

export interface ListPendingPaymentTransactionsState extends BaseStoreState {
    readonly pendingTransactions?: ScdPaymentTransactionPendingData[]
}
