import { AxiosResponse } from 'axios';
import { ScdBatchExport } from 'model/batch';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { exportBatchError, exportBatchRequest, exportBatchSuccess } from 'reducer/batch/export-batch/actions';
import { ExportBatchTypes } from 'reducer/batch/export-batch/types';
import { all, call, fork, put, takeLeading } from 'redux-saga/effects';
import batchAPI from 'services/api/batch-api';
import { ErrorUtils } from 'shared/error/error-utils';

function* handlExportBatch(action: ReturnType<typeof exportBatchRequest>) {
    try {
        const result: AxiosResponse<ScdBatchExport> = yield call(batchAPI.exportBatch, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(exportBatchError(mapped));
            return;
        }
        yield put(exportBatchSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(exportBatchError(mapped));
    }
}

function* watchExportBatch() {
    yield takeLeading(ExportBatchTypes.EXPORT_BATCH_REQUEST, handlExportBatch);
}

function* exportBatchSaga() {
    yield all([fork(watchExportBatch)]);
}

export default exportBatchSaga;
