import { ScdImportedOperationErrorsPending } from 'model/imported-operation';
import { BaseStoreState } from 'reducer/base-store-state';

export type ApiResponse = Record<string, string>;

export enum GetImportedOperationErrorsPendingTypes {
    GET_IMPORTED_OPERATION_ERRORS_PENDING_REQUEST = '@@get-imported-operation-errors-pending/GET_IMPORTED_OPERATION_ERRORS_PENDING_REQUEST',
    GET_IMPORTED_OPERATION_ERRORS_PENDING_SUCCESS = '@@get-imported-operation-errors-pending/GET_IMPORTED_OPERATION_ERRORS_PENDING_SUCCESS',
    GET_IMPORTED_OPERATION_ERRORS_PENDING_ERROR = '@@get-imported-operation-errors-pending/GET_IMPORTED_OPERATION_ERRORS_PENDING_ERROR',
    GET_IMPORTED_OPERATION_ERRORS_PENDING_RESET_STATE = '@@get-imported-operation-errors-pending/GET_IMPORTED_OPERATION_ERRORS_PENDING_RESET_STATE',
}

export interface GetImportedOperationErrorsPendingState extends BaseStoreState {
    readonly errorsPending?: ScdImportedOperationErrorsPending;
}
