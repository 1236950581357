import { GiroWebappError } from 'model/error';
import { ScdProgramResponse } from 'model/program';
import { ManageCompanyGroupToProgramRequest } from 'model/program-groups';
import { AddProgramGroupTypes } from 'reducer/program-groups/add-program-group/types';
import { action } from 'typesafe-actions';

export const addProgramGroupRequest = (request: ManageCompanyGroupToProgramRequest) => action(AddProgramGroupTypes.ADD_PROGRAM_GROUP_REQUEST, request);
export const addProgramGroupSuccess = (programSimple: ScdProgramResponse) => action(AddProgramGroupTypes.ADD_PROGRAM_GROUP_SUCCESS, programSimple);
export const addProgramGroupError = (error: GiroWebappError) => action(AddProgramGroupTypes.ADD_PROGRAM_GROUP_ERROR, error);
export const addProgramGroupResetState = () => action(AddProgramGroupTypes.ADD_PROGRAM_GROUP_RESET_STATE);
