import { HttpRequestStatus } from 'model/enums/http-request-status';

import { Reducer } from 'redux';
import { CessionOperationBcoState, CessionOperationBcoTypes } from 'reducer-bco/batch-output/cession-operation/types';

const initialState: CessionOperationBcoState = {
    status: HttpRequestStatus.NOOP,
    listCessionOperation: undefined,
    error: undefined,
};

const reducer: Reducer<CessionOperationBcoState> = (state = initialState, action): CessionOperationBcoState => {
    switch (action.type) {
        case CessionOperationBcoTypes.CESSION_OPERATION_BCO_REQUEST: {
            return { ...state, status: HttpRequestStatus.ON_GOING, error: undefined };
        }
        case CessionOperationBcoTypes.CESSION_OPERATION_BCO_SUCCESS: {
            return { ...state, status: HttpRequestStatus.SUCCESS, listCessionOperation: action.payload, error: undefined };
        }
        case CessionOperationBcoTypes.CESSION_OPERATION_BCO_ERROR: {
            return { ...state, status: HttpRequestStatus.ERROR, error: action.payload };
        }
        case CessionOperationBcoTypes.CESSION_OPERATION_BCO_RESET: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export { reducer as CessionOperationBcoReducer };
